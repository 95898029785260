import React from 'react';
import OnestockRiskAssessmentContainer from 'containers/JarvisOnestock/OnestockRiskAssessment';

function OnestockRiskAssessment () {
    return ( 
        <>
        <OnestockRiskAssessmentContainer />
        </>
     );
}

export default OnestockRiskAssessment;