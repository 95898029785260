export const jarvisRMSTransactionConstants = {
    
    JARVIS_RMS_RESET_TRANSACTION_DATA : "JARVIS_RMS_RESET_TRANSACTION_DATA",

    RMS_TRANSACTION_LIST_API: "rms/transaction",
    RMS_TRANSACTION_LIST_SAGA: "RMS_TRANSACTION_LIST_SAGA",
    RMS_TRANSACTION_LIST_REQUEST: "RMS_TRANSACTION_LIST_REQUEST",
    RMS_TRANSACTION_LIST_RESPONSE: "RMS_TRANSACTION_LIST_RESPONSE",

    RMS_TRANSACTION_DETAIL_API: "rms/portfolio/rms-call",
    RMS_TRANSACTION_DETAIL_SAGA: "RMS_TRANSACTION_DETAIL_SAGA",
    RMS_TRANSACTION_DETAIL_REQUEST: "RMS_TRANSACTION_DETAIL_REQUEST",
    RMS_TRANSACTION_DETAIL_RESPONSE: "RMS_TRANSACTION_DETAIL_RESPONSE",
};
