import React, { useState } from "react";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import JarvisCard from "components/JarvisCard";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Grid from "components/common/Grid";
import Avatar from "components/common/Avatar";
import Button from "components/common/Button";
import Link from "components/common/Link";
import ProfileMenu from "components/common/ProfileMenu";

import { makeStyles } from "@mui/styles";
import ChangePasswordDialog from "components/ChangePasswordDialog";
// import CancelPlanSubscription from "components/CancelPlanSubscription";
import PasswordChangedDialog from "components/PasswordChangedDialog";
import DeactivateAccountDialog from "./DeactivateAccountDialog";
import { localStore } from "stores/localStorage";
import { config } from "config/index";

const ProfileDetails = ({
  profilePic,
  email,
  mobileNo,
  fullName,
  isKYCCompleted,
  // onProfileUpdateClick,
  // reasonsDropdown,
  goToProfile,
  hasProfileButton = false,
  // disabledProfileButton = true,
  deactivateDialogProps = {},
  // customerSubscriptionId,
}) => {
  const classes = useStyles();
  const [passwordSuccessOpen, setPasswordSuccessOpen] = useState(false);
  const changePassword = () => {
    return (
      <ChangePasswordDialog
        passwordOpen={setPasswordSuccessOpen}
        openComponent={(onOpen) => (
          <Text
            variant="small"
            color={{ xs: "#e5e5e5", md: "#1D9BFF" }}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              color: "gray",
            }}
            onClick={onOpen}
          >
            Change Password
          </Text>
        )}
      />
    );
  };

  // const manageSubscription = () => {
  //   return (
  //     <CancelPlanSubscription
  //       customerSubscriptionId={customerSubscriptionId}
  //       openComponent={(onOpen) => (
  //         <Text
  //           variant="small"
  //           color={{ xs: "#e5e5e5", md: "#1D9BFF" }}
  //           sx={{
  //             textDecoration: "none",
  //             cursor: "pointer",
  //             color: "gray"
  //           }}
  //           onClick={onOpen}
  //         >
  //           Manage Subscription
  //         </Text>
  //       )}
  //       reasonsDropdown={reasonsDropdown}
  //     />
  //   );
  // };

  const deactivateAccount = () => {
    return (
      <DeactivateAccountDialog
        openComponent={(onOpen) => (
          <Text
            variant="small"
            color={{ xs: "#e5e5e5", md: "#1D9BFF" }}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              color: "gray",
            }}
            onClick={onOpen}
          >
            Deactivate Account
          </Text>
        )}
        isLoading={false}
        open={deactivateDialogProps.open}
        onClose={deactivateDialogProps.onClose}
        onConfirm={deactivateDialogProps.onConfirm}
        checkboxChecked={deactivateDialogProps.checkboxChecked}
        onCheckboxChange={deactivateDialogProps.onCheckboxChange}
      />
    );
  };

  const editProfile = () => {
    return (
      <Link
        variant="small"
        sx={{
          textDecoration: "none",
          cursor: "pointer",
          color: "gray",
        }}
        href="/customer/profile/edit-profile"
      >
        Edit Profile
      </Link>
    );
  };

  const viewProfile = () => {
    return (
      <Link
        variant="small"
        sx={{
          textDecoration: "none",
          cursor: "pointer",
          color: "gray",
        }}
        href="/customer/profile"
      >
        View Profile
      </Link>
    );
  };

  let menuOptions = [
    // {
    //   name: viewProfile(),
    // },
    {
      name: editProfile(),
    },
    {
      name: changePassword(),
    },
    // {
    //   name: manageSubscription(),
    // },
  ];
  // if(localStore.isSsoCustomer() && localStore.getSsoCustomerDetails()?.brokerName === config.broker.ssoPlatform.centrum){
  //   menuOptions= menuOptions.filter((o,indx)=> indx !== 0)
  // }

  if (deactivateDialogProps.isEnabled) {
    menuOptions.push({ name: deactivateAccount() });
  }

  const menuListSize = deactivateDialogProps.isEnabled ? 40 : 25;

  return (
    <JarvisCard className={classes.profileWrapper}>
      <Stack
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
          minHeight: "120px",
        }}
      >
        <Box className={classes.profileDetails}>
          {/* Avtar */}
          <Stack
            sx={{
              justifyContent: { xs: "left", md: "center" },
              mr: { xs: "1px", md: "10px" },
            }}
          >
            <Avatar
              alt={fullName}
              src={profilePic}
              title={fullName}
              sx={{
                width: { xs: "64px", sm: "70px", md: "90px" },
                height: { xs: "64px", sm: "70px", md: "90px" },
                mr: 2,
              }}
              text_sx={{ fontSize: { xs: "25px", sm: "35px" } }}
            />
          </Stack>
          {/* Profile Details */}
          <Stack sx={{ textAlign: { xs: "left", sm: "unset" }, width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Text className={classes.userName}>{fullName}</Text>
              {localStore.isSsoCustomer()?(<></>):(<ProfileMenu
                menuOptions={menuOptions}
                listSize={menuListSize}
                sx={{ color: { xs: "white", sm: "white", md: "black" } }}
              />)}
            </Stack>
            <Text variant="small" className={classes.userEmail}>
              {email}
            </Text>
            <Text
              variant="small"
              sx={{
                color: { xs: "#808591", md: "#808591" },
                fontSize: { xs: "11px", sm: "16px" },
                fontWeight: "400",
              }}
              pt={0.8}
            >
              {mobileNo}
            </Text>
            {isKYCCompleted ? (
              <Stack
                direction="row"
                sx={{ color: "#fff" }}
                mt={1}
              >
                <CheckCircleRoundedIcon
                  sx={{ fontSize: { xs: "14px", md: "22px" }, color:"#45AC70" }}
                />
                <Text
                  variant="medium"
                  sx={{
                    color: "#45AC70",
                    fontSize: { xs: "12px", md: "16px" },
                    mt: { xs: "-2px", md: "unset" },
                  }}
                >
                  &nbsp;KYC verified
                </Text>
              </Stack>
            ) : null}
            {hasProfileButton ? (
              <button
                className={classes.profileButton}
                onClick={() => goToProfile()}
              >
                View Profile
              </button>
            ) : // <Link
            //   variant="small"
            //   sx={{
            //     textDecoration: "none",
            //     cursor: "pointer",
            //     color: "gray"
            //   }}
            //   href="/customer/profile"
            // >
            //   View Profile
            // </Link>
            null}
            <PasswordChangedDialog
              open={passwordSuccessOpen}
              passwordChange={setPasswordSuccessOpen}
            />
          </Stack>
        </Box>
      </Stack>
    </JarvisCard>
  );
};

const useStyles = makeStyles((theme) => ({
  profileWrapper: {
    height: "100%",
    // minHeight: "153px",
    padding: "16px 16px 0 16px",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("sm")]: {
      minHeight: "auto",
      padding: "20px 30px",
    },
    [theme.breakpoints.up("md")]: {
      background: "#fff",
    },
  },

  profileDetails: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    placeItems: "flex-start",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      placeItems: "center",
    },
  },

  menuItems: {
    letterSpacing: "1px",
    fontSize: "33px",
    cursor: "pointer",
    lineHeight: "1",
    transform: "rotate(90deg) translate(20px, -25px)",
    width: "35px",
    height: "6px",
  },

  menuItemsOptions: {
    marginTop: "30px",
  },

  userName: {
    color: "##222B3E",
    fontWeight: "500",
    fontSize: "16px",
    marginBottom: theme.spacing(1),
    marginTop: "0",
    [theme.breakpoints.up("sm")]: {
      fontSize: "26px",
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      color: "#222B3E",
    },
  },
  userEmail: {
    color: "#808591",
    wordBreak: "break-word",
    fontSize: "16px",
    lineHeight: "16px",
    marginBottom: "3px",
    fontWeight: "400",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.spacing(2),
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: theme.spacing(1.6),
    },
    [theme.breakpoints.up("md")]: {
      color: "#808591",
    },
  },

  goToProfilePage: {
    fontSize: "18px",
    textTransform: "unset",
    boxShadow: "none !important",
    padding: "4px 22px",
    borderRadius: "25px",
    marginTop: "20px",
    color: "#fff",
    minWidth: "150px",
    whiteSpace: "nowrap",
    "&:disabled": {
      color: "#c1bfbf",
    },
  },

  profileButton: {
    backgroundColor: "#2B70C5",
    margin: "10px 0px",
    width: "110px",
    border: "none",
    borderRadius: "15px",
    padding: "5px 5px",
    color:"#fff"
  },
}));

export default ProfileDetails;
