import React,{useRef} from "react";

import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import TextField from "components/common/TextField";
import DatePicker from "components/common/DatePicker";

import JarvisButton from "components/JarvisButton";
// import CheckIcon from "@mui/icons-material/CheckCircle";
// import ErrorIcon from "@mui/icons-material/Error";
// import { validationHelper } from "helper/validation";
// import { onboardingApis } from "stores/onboarding/onboardingApis";
// import { dateTimeHelper } from "helper/dateTime";
// import { tracker } from "library/tracker";

// icons
import DoneIcon from "@mui/icons-material/Done";
import { config } from "config/index";

const CKYCmanual = (props) => {
  const {
    formData,
    formError,
    isVerified,
    isLoading,
    isImagesUploaded,
    imagesUploadingLoader,
    handleChange,
    onBrowseClick,
    handleReVerification,
    toggleKYCtype,
  } = props;

  const panCardRef= useRef();
  const aadharCardFrontRef = useRef();
  const aadharCardBackRef = useRef();


  const handleOnChange = (e,type)=>{
    debugger;
    const file = e.target.files[0];
    // const validExtensions = [
    //   "image/jpeg",
    //   "image/png",
    // ];
    // const fileExtension = file.type;
    // if (!validExtensions.includes(fileExtension)) {
    //   return alert("please upload a valid file");
    // }
    onBrowseClick({type,data:file})
  }
  const OnBrowseFileClick= (type)=>{
    if(config.platform === "WEB"){
      if(type==="panCard"){
        panCardRef.current.click();
      }
      else if(type === "aadharCardFront"){
        aadharCardFrontRef.current.click();
      }
      else if(type === "aadharCardBack"){
        aadharCardBackRef.current.click()
      }
    }
    else{
      onBrowseClick({type})
    }
  }
  return (
    <Box>
      <Text variant="subtitle2" sx={{ fontWeight: "bold", mb: 2.5 }}>
        Complete CKYC to start investing
      </Text>

      <Text variant="body2" sx={{ color: "#3A3A3A", mb: 3, maxWidth: "400px" }}>
        We need your Identity card photos to get <br /> you verified manually,
        <Text
          component="span"
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "blue",
            "&:hover": {
              color: "#e60b0b",
            },
          }}
          onClick={() => toggleKYCtype(false)}
        >
          {` `}Click here for Auto CKYC
        </Text>
      </Text>

      {/* Upload PAN */}
      <Stack mb={3}>
      <input
        type="file"
        ref={panCardRef}
        hidden
        multiple={false}
        onChange={(e)=>handleOnChange(e,'panCard')}
        onClick={(event) => {
            event.target.value = null;
        }}
        accept=".pdf, .png, .jpg, .jpeg"
      />
      <input
        type="file"
        ref={aadharCardFrontRef}
        hidden
        multiple={false}
        onChange={(e)=>handleOnChange(e,"aadharCardFront")}
        onClick={(event) => {
            event.target.value = null;
        }}
        accept=".pdf, .png, .jpg, .jpeg"
      />
      <input
        type="file"
        ref={aadharCardBackRef}
        hidden
        multiple={false}
        onChange={(e)=>handleOnChange(e,"aadharCardBack")}
        onClick={(event) => {
            event.target.value = null;
        }}
        accept=".pdf, .png, .jpg, .jpeg"
      />
        <Text>PAN Details</Text>
        <TextField
          type="text"
          label="PAN No."
          value={formData.panNumber}
          onChange={(e) =>
            handleChange({
              name: "panNumber",
              value: e.target.value.toUpperCase(),
            })
          }
          helperText={formError.panNumber}
          error={formError.panNumber}
          disabled={isVerified}
          sx={{ color: "#808591", maxWidth: 400, mb: "16px" }}
          inputProps={{ maxLength: 12 }}
        />
        <DatePicker
          label="Date of Birth"
          value={formData.dateOfBirth}
          onChange={(value) => handleChange({ name: "dateOfBirth", value })}
          helperText={formError.dateOfBirth}
          error={formError.dateOfBirth}
          disabled={isVerified}
          textFieldProps={{
            sx: { maxWidth: 400, mb: 2 },
          }}
        />
        <Stack
          sx={{
            height: "56px",
            maxWidth: 400,
            border: "1px dashed #E0E7FF",
            backgroundColor: "#FCFDFF",
            flexFlow: "wrap nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            mb:0.5,
            flexDirection: "row",
            padding: "20px 14px",
          }}
        >
          <Stack direction="row">
            <img
              src="/images/img-icon.svg"
              alt="icon"
              width="20px"
              style={{ marginRight: "7px" }}
            />
            <Text component="span" sx={{ color: "#808591", fontSize: "14px" }}>
              Upload PAN Photo
            </Text>
          </Stack>
          <Stack sx={{ flexFlow: "row wrap" }}>
            {isImagesUploaded.panCard ? (
              <DoneIcon sx={{marginRight: "8px", color:"#00db00", fontSize: "20px"}} />
            ) : null}
            <JarvisButton
              disableEndIcon
              variant="outlined"
              sx={{
                fontSize: "14px",
                padding: "6px 20px",
                borderRadius: "50px !important",
              }}
              onClick={() => OnBrowseFileClick("panCard")}
              loading={imagesUploadingLoader.panCard}
            >
              {isImagesUploaded.panCard ? "Change" : "Browse"}
            </JarvisButton>
          </Stack>
        </Stack>
        {formError.panCard && <Text variant="small" sx={{color:"#d32f2f",pl:1.5}}>{formError.panCard}</Text>}
      </Stack>

      {/* Upload Aadhar */}
      <Stack mb={3}>
        <Text>Aadhaar Details</Text>
        <TextField
          type="number"
          label="Aadhaar No."
          value={formData.aadharNumber}
          
          onChange={(e) =>
            handleChange({
              name: "aadharNumber",
              value: e.target.value.toUpperCase(),
            })
          }
          helperText={formError.aadharNumber}
          error={formError.aadharNumber}
          disabled={isVerified}
          sx={{ color: "#808591", maxWidth: "400px", mb: "16px" }}
          inputProps={{ maxLength: 12 }}
        />
        <Stack
          sx={{
            height: "56px",
            maxWidth: 400,
            border: "1px dashed #E0E7FF",
            backgroundColor: "#FCFDFF",
            flexFlow: "wrap nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            mb:0.5,
            flexDirection: "row",
            padding: "20px 14px",
          }}
        >
          <Stack direction="row">
            <img
              src="/images/img-icon.svg"
              alt="icon"
              width="20px"
              style={{ marginRight: "7px" }}
            />
            <Text component="span" sx={{ color: "#808591", fontSize: "14px"  }}>
              {" "}
              Upload Aadhaar Front
            </Text>
          </Stack>
          <Stack sx={{ flexFlow: "row wrap" }}>
            {isImagesUploaded.aadharCardFront ? (
              <DoneIcon sx={{marginRight: "8px", color:"#00db00", fontSize: "20px" }} />
            ) : null}
            <JarvisButton
              disableEndIcon
              variant="outlined"
              sx={{
                fontSize: "14px",
                padding: "6px 20px",
                borderRadius: "50px !important",
              }}
              onClick={() => OnBrowseFileClick("aadharCardFront")}
              loading={imagesUploadingLoader.aadharCardFront}
            >
              {isImagesUploaded.aadharCardFront ? "Change" : "Browse"}
            </JarvisButton>
          </Stack>
        </Stack>
        {formError.aadharCardFront && <Text variant="small" sx={{color:"#d32f2f",pl:1.5}}>{formError.aadharCardFront}</Text>}
        
        <Stack
          sx={{
            height: "56px",
            maxWidth: 400,
            border: "1px dashed #E0E7FF",
            backgroundColor: "#FCFDFF",
            flexFlow: "wrap nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            mb:0.5,
            mt:1.5,
            flexDirection: "row",
            padding: "20px 14px",
          }}
        >
          <Stack direction="row">
            <img
              src="/images/img-icon.svg"
              alt="icon"
              width="20px"
              style={{ marginRight: "7px" }}
            />
            <Text component="span" sx={{ color: "#808591", fontSize: "14px" }}>
              {" "}
              Upload Aadhaar Back
            </Text>
          </Stack>
          <Stack sx={{ flexFlow: "row wrap" }}>
            {isImagesUploaded.aadharCardBack ? (
              <DoneIcon sx={{marginRight: "8px", color:"#00db00", fontSize: "20px" }} />
            ) : null}
            <JarvisButton
              disableEndIcon
              variant="outlined"
              sx={{
                fontSize: "14px",
                padding: "6px 20px",
                borderRadius: "50px !important",
              }}
              onClick={() => OnBrowseFileClick("aadharCardBack")}
              loading={imagesUploadingLoader.aadharCardBack}
            >
              {isImagesUploaded.aadharCardBack ? "Change" : "Browse"}
            </JarvisButton>
          </Stack>
        </Stack>
        {formError.aadharCardBack && <Text variant="small" sx={{color:"#d32f2f",pl:1.5}}>{formError.aadharCardBack}</Text>}
      </Stack>

      {/* Submit re-verification */}
      <JarvisButton loading={isLoading} onClick={handleReVerification} sx={{mb: { xs: 8, md: 3}, width: { xs: "100%", md: "auto" }}}>
        Submit for re-verification
      </JarvisButton>
    </Box>
  );
};



export default CKYCmanual;
