import React from "react";
import DashboardOnboardedContainer from "./DashboardOnboarded";
import PortfolioOnboardingNotCompleted from "./PortfolioOnboardingNotCompleted"

class DashboardContainer extends React.Component {
    render() {
        if (!this.props.isOnboardingCompleted) {
            return (
                <>
                    <PortfolioOnboardingNotCompleted />
                </>
            )
        }
        return <DashboardOnboardedContainer />
    }
}

export default DashboardContainer;
