import React from "react";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Dialog from "components/common/Dialog";
import JarvisButton from "components/JarvisButton";

const ConfirmationActionDialog = (props) => {
  const { open, onConfirm, onClose, message } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title=""
      disableCloseIcon = {false}
      maxWidth="xs"
      sx={{ borderRadius: "10px" }}
      contentComponent={() => (
        <Box sx={{ maxWidth: "300px", margin: "0 auto", textAlign: "center" }}>
          <Text
            variant="subtitle1"
            sx={{ color: "#1E1E1E", lineHeight: 1.3 }}
          >
            {message}
          </Text>
        </Box>
      )}
      actionComponent={(onClose) => {
        return (
          <Box mb={2} textAlign="center">

            <JarvisButton
              onClick={() => onConfirm(onClose)}
              disableEndIcon={false}
              variant="outlined"
              m={0.5}
            >
              Yes, Continue
            </JarvisButton>
          </Box>
        );
      }}
    />
  );
};

export default ConfirmationActionDialog;
