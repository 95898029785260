import React from "react";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import { withRouter } from "react-router-dom";
import PageTitle from "components/PageTitle";
import TextField from "components/common/TextField";
import Avatar from "components/common/Avatar";
import InputAdornment from "components/common/InputAdornment";
import { validationHelper } from "helper/validation";
import OtpInput from "react-otp-input";
import Modal from "components/common/Modal";
import JarvisDialog from "components/JarvisDialog";
import JarvisButton from "components/JarvisButton";
import { config } from "config";
import Text from "components/common/Text";
import { customerApis } from "stores/customer/customerApis";
import JarvisCard from "components/JarvisCard";
import { localStore } from "stores/localStorage";


const EDIT_PROFILE_MESSAGES = {
    success: {
        title: "Congrats!!!",
        content: "OTP Verified Successfully",
        actionText: "Continue",
        icon: "/images/svgs/success.gif"
    },
    error: {
        title: "Oops!!!",
        content: "Something went wrong! Please try again later",
        actionText: "Go Back",
        icon: "/images/svgs/failed.gif"
    },
}
class EditProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openMobileModal: false,
            openOtpModal: false,
            newMobile: "",
            otp: "",
            validateMobile: "",
            openEmailModal: false,
            newEmail: "",
            validateEmail: "",
            dialogOpen: false,
            dialogContent: {},
        }
    }

    componentDidMount() {
        this.props.getProfileDataAction();
    }

    handleMobileDialog = () => {
        this.setState({ openMobileModal: true })
    }

    handleEmailDialog = () => {
        this.setState({ openEmailModal: true })
    }

    handleDialogClose = () => {
        this.setState({ openMobileModal: false, openEmailModal: false, openOtpModal: false, newMobile: "", newEmail: "" })
    }

    handleMobileUpdatedProfile = async () => {
        const { newMobile } = this.state
        if (!this.handleMobileValidation()) {
            return;
        }

        try {

            await customerApis.updateProfileDetails({
                mobile: newMobile,
            })

            this.setState({ openMobileModal: false, openOtpModal: true })
        }
        catch (err) {
            console.log(err)
        }
    }

    handleEmailUpdatedProfile = async () => {
        const { newEmail } = this.state
        if (!this.handleEmailValidation()) {
            return;
        }

        try {

            await customerApis.updateProfileDetails({
                email: newEmail
            })

            this.setState({ openEmailModal: false, openOtpModal: true })
        }
        catch (err) {
            console.log(err)
        }
    }

    handleVerifyProfile = async () => {
        const { newMobile, otp, newEmail } = this.state
        try {
            await customerApis.verifyProfileDetails({
                mobile: newMobile,
                email: newEmail,
                otp: otp
            })

            if (newEmail) {
                this.props.updateEmailAction({ email: newEmail });
            }
            this.props.getProfileDataAction();
            this.setState({ openOtpModal: false, otp: "", newEmail: "", newMobile: "", dialogOpen: true, dialogContent: EDIT_PROFILE_MESSAGES.success })
        }
        catch (err) {
            this.setState({ dialogContent: EDIT_PROFILE_MESSAGES.error })
            console.log(err)
        }
    }

    handleMobileValidation = () => {
        const { newMobile } = this.state

        const errorMobile = validationHelper.mobile(newMobile);
        this.setState({ validateMobile: errorMobile.message })

        return (
            errorMobile.isValid
        );
    };

    handleEmailValidation = () => {
        const { newEmail } = this.state

        const errorEmail = validationHelper.email(newEmail);
        this.setState({ validateEmail: errorEmail.message })

        return (
            errorEmail.isValid
        );
    };

    handleChange = (name, value) => {
        if (name === "newMobile") {
            this.setState({ newMobile: value })
        }
        else if (name === "newEmail") {
            this.setState({ newEmail: value })
        }
        else if (name === "otp") {
            this.setState({ otp: value })
        }
    };

    handleMessageDialogClose = () => {
        this.setState({ dialogOpen: false })
    }

    render() {

        const { profile } = this.props
        const { newMobile, validateMobile, openMobileModal, otp, openEmailModal, newEmail, validateEmail, dialogOpen, dialogContent } = this.state



        return (
            <>
                <JarvisCard
                    sx={{
                        maxWidth: "100%",
                        mb: { xs: 0, md: 2 },
                        mt: 2,
                        border: { xs: "1px solid #EEF0F2", md: "none" },
                    }}
                >
                    <Box pb={1}>
                        <PageTitle />
                    </Box>
                    <Stack>
                        <Avatar
                            alt={profile.fullName}
                            src={profile.profilePic}
                            title={profile.fullName}
                            sx={{
                                width: { xs: "60px", sm: "80px" },
                                height: { xs: "60px", sm: "80px" },
                                mr: 2,
                            }}
                            text_sx={{ fontSize: { xs: "25px", sm: "35px" } }}
                        />
                        <Stack
                            sx={{ width: 330, maxWidth: "100%" }}
                            mt={{ xs: 2, md: 2 }}
                            alignItems="center"
                            justifyContent="flex-start">
                            <TextField
                                type="text"
                                label="Full Name"
                                value={profile.fullName}
                                sx={{ width: 1, mb: 2 }}
                                disabled={true}
                            />
                            <TextField
                                label="Phone Number"
                                type="number"
                                value={profile.mobileNo}
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Text onClick={this.handleMobileDialog} sx={{ fontSize: "12px", color: "#2B70C5", cursor: "pointer" }}>change</Text>
                                            </InputAdornment>
                                        ),
                                    }
                                }
                                disabled={true}
                                sx={{ width: 1, mb: 2 }}
                            />
                            <TextField
                                type="email"
                                label="Email"
                                value={profile.email}
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Text onClick={this.handleEmailDialog} sx={{ fontSize: "12px", color: "#2B70C5", cursor: "pointer" }}>change</Text>
                                            </InputAdornment>
                                        ),
                                    }
                                }
                                disabled={true}
                                sx={{ width: 1, mb: 2 }}
                            />
                        </Stack>
                    </Stack>

                    <Modal
                        disableClose={false}
                        size="xs"
                        open={openMobileModal}
                        onClose={this.handleDialogClose}
                    >
                        <Text variant="body2" color="#202020" sx={{ textAlign: "center" }}>
                            <img src="/images/profile/mobile-icon-popup.png" alt="error" />
                        </Text>

                        <Text variant="body1" color="#2B70C5" sx={{ textAlign: "center", fontWeight: "500" }}>
                            Enter New Mobile number
                        </Text>

                        <TextField
                            label="Phone Number"
                            type="number"
                            value={newMobile}
                            onChange={(e) => {
                                this.handleChange("newMobile", e.target.value);
                            }}
                            error={validateMobile}
                            helperText={validateMobile}
                            sx={{ width: 1, mt: 3 }}
                        />

                        <Box mt={3} textAlign="center">
                            <JarvisButton
                                variant="contained"
                                sx={{ width: "100%" }}
                                onClick={() => this.handleMobileUpdatedProfile()}
                            >
                                Send OTP
                            </JarvisButton>
                        </Box>

                    </Modal>


                    <Modal
                        disableClose={false}
                        size="xs"
                        open={openEmailModal}
                        onClose={this.handleDialogClose}
                    >
                        <Text variant="body2" color="#202020" sx={{ textAlign: "center" }}>
                            <img src="/images/profile/mobile-icon-popup.png" alt="error" />
                        </Text>

                        <Text variant="body1" color="#2B70C5" sx={{ textAlign: "center", fontWeight: "500" }}>
                            Enter New Email Id
                        </Text>

                        <TextField
                            label="Email"
                            type="email"
                            value={newEmail}
                            onChange={(e) => {
                                this.handleChange("newEmail", e.target.value);
                            }}
                            error={validateEmail}
                            helperText={validateEmail}
                            sx={{ width: 1, mt: 3 }}
                        />

                        <Box mt={3} textAlign="center">
                            <JarvisButton
                                variant="contained"
                                sx={{ width: "100%" }}
                                onClick={() => this.handleEmailUpdatedProfile()}
                            >
                                Send OTP
                            </JarvisButton>
                        </Box>

                    </Modal>

                    <Modal
                        disableClose={false}
                        size="xs"
                        open={this.state.openOtpModal}
                        onClose={this.handleDialogClose}
                    >
                        <Text variant="body2" color="#202020" sx={{ textAlign: "center" }}>
                            <img src="/images/profile/otp-lock-icon.png" alt="error" />
                        </Text>

                        <Text variant="body1" color="#2B70C5" sx={{ textAlign: "center", fontWeight: "500" }}>
                            Enter OTP
                        </Text>
                        {
                            config.isMobileApp ?
                                <Stack justifyContent="center" alignItems="center">
                                    <OtpInput
                                        mt={3}
                                        style={{
                                            height: "56px",
                                            width: "100%",
                                        }}
                                        inputStyle={
                                            {
                                                height: "52px",
                                                width: "42px",
                                                border: "1px solid #E0E7FF",
                                                flex: "1",
                                            }
                                        }
                                        value={otp}
                                        numInputs={6}
                                        isInputNum={true}
                                        onChange={(value) => this.handleChange("otp", value)}
                                        separator={<span>&nbsp;</span>}
                                    />
                                </Stack>
                                :
                                <OtpInput
                                    mt={3}
                                    style={{
                                        height: "56px",
                                        width: "100%",
                                    }}
                                    inputStyle={
                                        {
                                            height: "56px",
                                            width: "57px",
                                            border: "1px solid #E0E7FF",
                                            flex: "1",
                                        }
                                    }
                                    value={otp}
                                    numInputs={6}
                                    isInputNum={true}
                                    onChange={(value) => this.handleChange("otp", value)}
                                    separator={<span>&nbsp;</span>}
                                />
                        }

                        <Box mt={3} textAlign="center">
                            <JarvisButton
                                variant="contained"
                                sx={{ width: "100%" }}
                                onClick={this.handleVerifyProfile}
                            >
                                Verify
                            </JarvisButton>
                        </Box>
                    </Modal>
                </JarvisCard>

                <JarvisDialog
                    disableCloseIcon
                    open={dialogOpen}
                    onClose={this.handleMessageDialogClose}
                    onConfirm={this.handleMessageDialogClose}
                    title={dialogContent.title}
                    content={dialogContent.content}
                    icon={{
                        src: dialogContent.icon,
                        height: 120,
                        width: 120
                    }}
                    button={{
                        text: dialogContent.actionText,
                    }}
                />
            </>
        )
    }
}

export default withRouter(EditProfileContainer);
