import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return  {
        newsData: state.common.newsArticles,
        isLoading: state.common.loading.newsArticles,
        totalArticles: state.common.totalArticles,
        isInitialCalled: state.common.initialCalled.newsArticles,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getNewsArticlesAction: commonActions.getNewsArticles,
    setNewsArticleContentAction: commonActions.setNewsArticleContent
},dispatch)

const NewsAndArticlesStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default NewsAndArticlesStore;
 