import React from "react";
import Help from "containers/HelpSupport";
import PageTitle from "components/PageTitle";
import { tracker } from "library/tracker";

const HelpSupport = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);
    return (
        <>
            <PageTitle heading="Help & Support" />
            <Help />
        </>
    );
};

export default HelpSupport;
