import _get from "lodash.get";

const jarvisOnestockTransactionParser = {};


jarvisOnestockTransactionParser.transactionList = (res) => {
  const data = {
    list: [],
    total: 0,
  };

  if (res && res.response) {
    res = res.response;
  }

  if (!res || !res.list) {
    return data;
  }


  data.list = res.list.map((d) => ({
    stockId: _get(d, "stockId", null),
    stockName: _get(d, "stockName", null),
    stockSymbol: _get(d, "stockSymbol", null),
    stockQuantity: _get(d, "stockQuantity", null),
    buyDate: _get(d, "buyDate", null),
    buyPrice: (_get(d, "buyPrice", null) || 0).toFixed(2),
    sellDate: _get(d, "sellDate", null),
    sellPrice: (_get(d, "sellPrice", null) || 0).toFixed(2),
    stockGainLoss: _get(d, "stockGainLoss", null),
    stockGainLossPercentage: (_get(d, "stockGainLossPercentage", null) || 0).toFixed(2),
    transactionStatus: _get(d, "transactionStatus", null),
    oneStockTransactionId: _get(d, "oneStockTransactionId", null),
    isTransactionEditable : _get(d,'isTransactionEditable',null),
  }));

  data.total = _get(res, "listCount", null);
  return data;
};



export { jarvisOnestockTransactionParser };
