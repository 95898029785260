export const commonConstants = {
    GLOBAL_RESET_TOAST_DATA: "GLOBAL_RESET_TOAST_DATA",
    GLOBAL_SET_TOAST_DATA: "GLOBAL_SET_TOAST_DATA",
    GLOBAL_LOADER_DATA: "GLOBAL_LOADER_DATA",

    GLOBAL_SET_DIALOG_DATA: "GLOBAL_SET_DIALOG_DATA",
    GLOBAL_RESET_DIALOG_DATA: "GLOBAL_RESET_DIALOG_DATA",

    BLOGS_API: "v1/blogs/educational",
    BLOGS_SAGA: "BLOGS_SAGA",
    BLOGS_REQUEST: "BLOGS_REQUEST",
    BLOGS_RESPONSE: "BLOGS_RESPONSE",

    DEVICE_ID_DATA: "DEVICE_ID_DATA",

    BROKERS_API: "v1/onboarding/broker-list",
    BROKERS_SAGA: "BROKERS_SAGA",
    BROKERS_REQUEST: "BROKERS_REQUEST",
    BROKERS_RESPONSE: "BROKERS_RESPONSE",

    RISK_ASSESSMENT_ANSWERS_API: "master/risk-assessment/assessment-answers",
    RISK_ASSESSMENT_QUESTIONS_API: "v1/masters/questions",
    RISK_ASSESSMENT_QUESTIONS_SAGA: "common/RISK_ASSESSMENT_QUESTIONS_SAGA",
    RISK_ASSESSMENT_QUESTIONS_REQUEST: "RISK_ASSESSMENT_QUESTIONS_REQUEST",
    RISK_ASSESSMENT_QUESTIONS_RESPONSE: "RISK_ASSESSMENT_QUESTIONS_RESPONSE",

    RISK_PROFILES_API: "v1/home/risk-profile",
    RISK_PROFILES_SAGA: "RISK_PROFILES_SAGA",
    RISK_PROFILES_REQUEST: "RISK_PROFILES_REQUEST",
    RISK_PROFILES_RESPONSE: "RISK_PROFILES_RESPONSE",

    MARKET_UPDATES_API: "/v1/blogs/global-news ",
    MARKET_UPDATES_SAGA: "MARKET_UPDATES_SAGA",
    MARKET_UPDATES_REQUEST: "MARKET_UPDATES_REQUEST",
    MARKET_UPDATES_RESPONSE: "MARKET_UPDATES_RESPONSE",

    NEWS_ARTICLES_API: "v1/blogs/news-article?perPage=:count&page=:page",
    NEWS_ARTICLES_SAGA: "NEWS_ARTICLES_SAGA",
    NEWS_ARTICLES_CONTENT: "NEWS_ARTICLES_CONTENT",
    NEWS_ARTICLES_REQUEST: "NEWS_ARTICLES_REQUEST",
    NEWS_ARTICLES_RESPONSE: "NEWS_ARTICLES_RESPONSE",

    RESET_COMMON_DATA: "RESET_COMMON_DATA",

    APP_WELCOME_SCREEN_SHOWN: "APP_WELCOME_SCREEN_SHOWN",
    APP_CONFIG_SAGA: "APP_CONFIG_SAGA",
    APP_CONFIG_API: "v1/customer/check-app-config",
    APP_CONFIG_REQUEST: "APP_CONFIG_REQUEST",
    APP_CONFIG_RESPONSE: "APP_CONFIG_RESPONSE",

    TRACKER_CONFIG_API: "v1/customer/tracker-config",
    TRACKER_CONFIG_SAGA: "TRACKER_CONFIG_SAGA",
    TRACKER_CONFIG_REQUEST: "TRACKER_CONFIG_REQUEST",
    TRACKER_CONFIG_RESPONSE: "TRACKER_CONFIG_RESPONSE",

    PAYMENT_METHODS_API: "/master/payment",
    PAYMENT_METHODS_SAGA: "PAYMENT_METHODS_SAGA",
    PAYMENT_METHODS_REQUEST: "PAYMENT_METHODS_REQUEST",
    PAYMENT_METHODS_RESPONSE: "PAYMENT_METHODS_RESPONSE",

    FEATURE_CONFIG_API: "v1/customer/feature-config",
    FEATURE_CONFIG_SAGA: "FEATURE_CONFIG_SAGA",
    FEATURE_CONFIG_REQUEST: "FEATURE_CONFIG_REQUEST",
    FEATURE_CONFIG_RESPONSE: "FEATURE_CONFIG_RESPONSE",
};
