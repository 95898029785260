/**
 *
 * IconButton
 *
 */

import React from "react";
import MIconButton from "@mui/material/IconButton";

const IconButton = (props) => {
  return <MIconButton {...props} />
}


export default React.memo(IconButton);
