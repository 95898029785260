import _get from "lodash/get";

const transactionParsers = {};

transactionParsers.transactionParser = (res) => {
    let data = [];

    if (res && res.data) {
        res = res.data;
    }

    if (!res || !res.length) {
        return data;
    }

    data = res.map((r) => ({
        stockName: _get(r, "stockName", ""),
        stockSymbol: _get(r, "stockSymbol", ""),
        quantity: _get(r, "suggestedQuantity", ""),
        pendingActionableQty: _get(r, "pendingActionableQty", ""),
        completedActionableQty: _get(r, "completedActionableQty", ""),
        hasActionTaken: _get(r, "hasActionTaken", ""),
        buyPrice: _get(r, "buyPrice", ""),
        totalBuyPrice: _get(r, "totalBuyPrice", ""),
        transactionDate: _get(r, "date", ""),
        transactionAction: _get(r, "action", ""),
        actionType: _get(r, "actionType", ""),
        actionMsg: _get(r, "actionMsg", ""),
        purchaseType: _get(r, "purchaseType", ""),
        actionColorCode: _get(r, "actionColorCode", ""),

    }));

    return data;
};



export { transactionParsers };
