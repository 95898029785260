import React from "react";
import { withRouter } from "react-router-dom";

// components
import ChoosePortfolioAction from "components/Dashboard/ChoosePortfolioAction";
import PortfolioTransationConfirmation from "components/Dashboard/PortfolioTransationConfirmation";
import TransationSuccessDialog from "components/Dashboard/TransationSuccessDialog";
import ImportantMsgDialog from "components/ImportantMsgDialog";
import ConfirmationActionDialog from "components/ConfirmationActionDialog";
import ConfirmationStockListForArchive from "components/Dashboard/ConfirmationStockListForArchive";
import Modal from "components/common/Modal";

import { dashboardApis } from "stores/dashboard/dashboardApis";
import { dashboardParsers } from "stores/dashboard/dashboardParsers";
import { tracker } from "library/tracker";





class RMSRebalanceAlertContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionModalOpen: false,
      transactionSuccessOpen: false,
      generateActionModalOpen: false,
      generateActionsLoading: false,
      confirmationListModalOpen: false,
      importantMsgDialogOpen: false,
      importantMessage: "",
      finalConfirmationLoading: false,
      archiveData: undefined,
      finalData: [],
      finalDataLoading: false,
    };
  }

  componentDidMount() {
    this.props.getPortfolioAlertAction();
    setTimeout(() => {
      // when rms call expired open portfolio modal
      if (this.props.portfolioAlert.hasExpired && this.props.isActionCenter) {
        this.props.toggleChoosePortfolioModal();
      }
    }, 1000);
  }

  toggelTransactionModalOpen = () => {
    this.setState((prev) => {
      return { transactionModalOpen: !prev.transactionModalOpen };
    });
  };

  toggelConfirmationListModalOpen = () => {
    this.setState((prev) => {
      return { confirmationListModalOpen: !prev.confirmationListModalOpen };
    });
  };

  onFinalConfirmationCancel = () => {
    this.setState({
      confirmationListModalOpen: false,
    })
  }

  onTransactionConfirmationSubmit = async (data) => {

    this.setState({
      confirmationListModalOpen: true,
      transactionModalOpen: false,
      archiveData: data,
      finalDataLoading: true,
    });

    try {

      //event-tracker: Archive Flow -> arch_submit
      tracker.archiveFlow.archSaveSubmit();
      const res = await dashboardApis.finalPortfolioConfirmation();
      //event-tracker: Archive Flow -> arch_submit_success
      tracker.archiveFlow.archSaveSubmitSuccess();
      const parseRes = dashboardParsers.finalPortfolioConfirmation(res);
      this.setState({
        finalData: parseRes
      })

    } catch {
      //event-tracker: Archive Flow -> arch_confirm_transaction_error
      tracker.archiveFlow.archSaveSubmitFailed();
    } finally {
      this.setState({ finalDataLoading: false })
    }
  }


  //   Modal: Choose portfolio Action
  onConfirmTransaction = () => {
    const { pendingStocks } = this.props.portfolioPendingTransaction;

    //event-tracker: Archive Flow -> arch_awesome,_go_back
    tracker.archiveFlow.confirmManuallyExecutedTransaction();

    this.props.setArchiveModalClose()

    this.setState({
      transactionModalOpen: true,
      archiveData: pendingStocks
    });
  };

  toggleConfirmGenerateActions = () => {
    //event-tracker: Expiry Complete Buy/Sell(Ex) -> generate_new_actions
    tracker.expiryCompleteBuySell.generateNewActions();
    this.setState((prev) => {
      return { generateActionModalOpen: !prev.generateActionModalOpen };
    });
  };

  onGenerateActions = async () => {
    this.setState({ generateActionsLoading: true });
    let res;
    //To-do : condition for check status and call respective events
    try {
      if (this.props.portfolioAlert.transactionStatus === "Pending") {
        //event-tracker: Expiry Complete Buy/Sell(EX) -> ex_yes,_continue
        tracker.expiryCompleteBuySell.exYesContinue();
        res = await dashboardApis.generateNewAction();
        //event-tracker: Expiry Complete Buy/Sell(EX) -> ex_yes,_continue_success
        tracker.expiryCompleteBuySell.exYesContinueSuccess();
      } else if (
        this.props.portfolioAlert.transactionStatus === "Partial" ||
        this.props.portfolioAlert.transactionStatus === "Repair"
      ) {


        //event-tracker: Repair Flow -> repair_yes,_continue
        tracker.repairFlow.repairYesContinue();
        res = await dashboardApis.repairTranaction();
        //event-tracker: Repair Flow -> repair_yes,_continue_success
        tracker.repairFlow.repairYesContinueSuccess();
      } else {

        //event-tracker: Repair Flow -> repair_yes,_continue_error
        tracker.repairFlow.repairYesContinueFailed({ error_msg: `transactionStatus value is not valid: '${this.props.portfolioAlert.transactionStatus}'` });
        console.error(
          `transactionStatus value is not valid: '${this.props.portfolioAlert.transactionStatus}'`
        );
        this.setState({ generateActionsLoading: false });
        return;
      }

      this.setState({ generateActionsLoading: false });

      if (res && res.hasPendingCall) {
        this.props.history.push("/jarvis-portfolio/action-centre");
        this.props.setToastAction({ message: res.message });
        this.props.setArchiveModalClose();
        this.props.pendingCallAction();
      } else if (res && !res.hasPendingCall) {
        //code: "JSSP001" -> same flow
        this.props.setArchiveModalClose()
        this.props.dashboardRefreshAction();
        this.toggleImportantMsgDialog(`${res.message}`);
      }
    } catch {
      if (this.props.portfolioAlert.transactionStatus === "Pending") {
        //event-tracker: Expiry Complete Buy/Sell(EX) -> ex_yes,_continue_success
        tracker.expiryCompleteBuySell.exYesContinueFailed();
      } else if (this.props.portfolioAlert.transactionStatus === "Partial" || this.props.portfolioAlert.transactionStatus === "Repair") {
        //event-tracker: Expiry Partial Buy/Sell(EX) -> repair_yes,_continue_failed
        tracker.partialBuySell.repairYesContinueFailed();
      }
      this.setState({ generateActionsLoading: false });
    }
  };

  //   Modal: Transaction Confirmation
  finalConfirmSubmit = async () => {
    // Flow:  Submit API call -> Dashboard refresh -> Close Modal -> Open confirm Dialog

    const data = this.state.archiveData;
    this.setState({ finalConfirmationLoading: true });
    try {
      //event-tracker: Archive Flow -> arch_confirm_transaction
      tracker.archiveFlow.archConfirmTransaction();
      const res = await dashboardApis.porfolioArchive({ data });
      //event-tracker: Archive Flow -> arch_confirm_transaction_success
      tracker.archiveFlow.archConfirmTransactionSuccess();

      if (res && res.status && res.status !== 400) {
        //event-tracker: Archive Flow -> arch_awesome,_go_back
        tracker.archiveFlow.archAwesomeGoBack();
        this.props.dashboardRefreshAction();
        this.props.pendingCallAction()
        this.setState({
          confirmationListModalOpen: false,
          transactionSuccessOpen: true,
        });

      } else {

        //event-tracker: Archive Flow -> arch_confirm_transaction_error
        tracker.archiveFlow.archConfirmTransactionFailed({});
        this.props.setToastAction({ message: res.message });
      }
    } catch {
      //event-tracker: Archive Flow -> arch_confirm_transaction_error
      tracker.archiveFlow.archConfirmTransactionFailed({});
      this.setState({
        confirmationListModalOpen: false,
        transactionModalOpen: true,
      });
    } finally {
      this.setState({ finalConfirmationLoading: false });
    }
  };

  //   Dialog: Transaction Confirm
  onTransactionConfirm = () => {
    //event-tracker: Archive Flow -> arch_awesome,_go_back_success
    tracker.archiveFlow.archAwesomeGoBackSuccess();
    this.setState({
      transactionSuccessOpen: false,
    });
  };

  // Dialog: Response Message
  toggleImportantMsgDialog = (msg = "") => {
    this.setState(
      (prev) => {
        return {
          importantMessage: msg,
          importantMsgDialogOpen: !prev.importantMsgDialogOpen,
        };
      },
      () => {
        if (!this.state.importantMsgDialogOpen) {
          this.setState({ importantMessage: "" });
        }
      }
    );
  };

  //   Render
  render() {
    const {
      transactionStatus,
      archiveMsg,
      newCallMsg,
      actionScreenTitle,
      actionScreenDescription,
      note,
    } = this.props.portfolioAlert;

    const { choosePortfolioModalOpen } = this.props;

    const { pendingStocks, completedStocks } =
      this.props.portfolioPendingTransaction;

    const portfolioLoading = this.props.portfolioPendingTransactionLoading;

    const {
      transactionModalOpen,
      transactionSuccessOpen,
      importantMsgDialogOpen,
      importantMessage,
      finalConfirmationLoading,
      generateActionModalOpen,
      confirmationListModalOpen,
      finalData,
      finalDataLoading,
    } = this.state;


    return (
      <>
        {/* Modal: Urgent Action */}
        <Modal
          size={"md"}
          open={choosePortfolioModalOpen}
          onClose={() => this.props.toggleChoosePortfolioModal()}
          sx={{ borderRadius: "10px", overflow: "hidden" }}
        >
          <ChoosePortfolioAction
            onConfirmTransaction={() => this.onConfirmTransaction()}
            onGenerateActions={() => this.toggleConfirmGenerateActions()}
            transactionStatus={transactionStatus}
            archiveMsg={archiveMsg}
            newCallMsg={newCallMsg}
            actionScreenTitle={actionScreenTitle}
            actionScreenDescription={actionScreenDescription}
            completedStocks={completedStocks}
            pendingStocks={pendingStocks}
            loading={portfolioLoading}
            generateActionsLoading={this.state.generateActionsLoading}
          />
        </Modal>

        {/* Modal: Transaction Confirmation */}
        <Modal
          size={"md"}
          open={transactionModalOpen}
          onClose={() => this.toggelTransactionModalOpen()}
          sx={{ borderRadius: "10px", overflow: "hidden" }}
        >
          <PortfolioTransationConfirmation
            noteText={note}
            pendingStocks={this.state.archiveData}
            onSubmit={(data) => this.onTransactionConfirmationSubmit(data)}
          />
        </Modal>

        {/* Modal: Stock final qty confirmation */}
        <Modal
          size={"xs"}
          maxWidth="500px"
          open={confirmationListModalOpen}
          onClose={() => this.onFinalConfirmationCancel()}
          disableCroseButton={finalConfirmationLoading}
          sx={{ borderRadius: "10px", overflow: "hidden" }}
        >
          <ConfirmationStockListForArchive
            onConfirm={() => this.finalConfirmSubmit()}
            finalData={finalData}
            submitLoading={finalConfirmationLoading}
            dataLoading={finalDataLoading}
          />
        </Modal>

        {/* Modal: Success */}
        <TransationSuccessDialog
          open={transactionSuccessOpen}
          onConfirm={() => this.onTransactionConfirm()}
        />

        {/* Modal: Confirmation Action */}
        <ConfirmationActionDialog
          open={generateActionModalOpen}
          message="Are you sure you want to continue?"
          onClose={() => {
            this.toggleConfirmGenerateActions();
          }}
          onConfirm={() => {
            this.onGenerateActions();
            this.toggleConfirmGenerateActions();
            setTimeout(()=>{
              window.location.reload();
            },5000)
          }}
        />

        {/* Modal: Import Msg */}
        <ImportantMsgDialog
          open={importantMsgDialogOpen}
          message={importantMessage}
          onConfirm={() => this.toggleImportantMsgDialog()}
        />
      </>
    );
  }
}

export default withRouter(RMSRebalanceAlertContainer);
