import React from "react";
import AppMoreContainer from "containers/AppMore"
import { tracker } from "library/tracker";

const AppMore = () =>{
    React.useEffect(() => {
        tracker.pageView();
    }, []);

    return (
        <AppMoreContainer />
    )
}

export default AppMore; 