import { config } from "../config";
const formatHelper = {};

formatHelper.currency = (
  value = 0,
  options = { config: {} },
  locale = "en-IN"
) => {
  // NOTE:
  //      refer config document from below link
  //      link: https://www.techonthenet.com/js/number_tolocalestring.php
  const currencyConfig = {
    currency: "INR",
    style: "currency",
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options.config,
  };

  if (options.config.minimumFractionDigits) {
    currencyConfig.minimumFractionDigits = options.config.minimumFractionDigits;
  }

  if (options.config.maximumFractionDigits) {
    currencyConfig.minimumFractionDigits = options.config.maximumFractionDigits;
  }

  if(value === 0){
    currencyConfig.maximumFractionDigits = 0;
    currencyConfig.minimumFractionDigits = 0;
  }

  let fixedValue = Number(Number(value).toFixed(currencyConfig.maximumFractionDigits));
  return fixedValue.toLocaleString(locale, currencyConfig);
};

formatHelper.getImageURI = (slug = null) => {
  if (!slug) {
    return;
  }
  let slash = "";
  if (slug.slice(0, 1) !== "/") {
    slash = "/";
  }
  return `${config.apiUrl}${slash}${slug}`;
};

formatHelper.getLegacyImageURI = (slug = null) => {
  if (!slug) {
    return;
  }
  let slash = "";
  if (slug.slice(0, 1) !== "/") {
    slash = "/";
  }
  return `${config.legacyApiUrl}${slash}${slug}`;
};

formatHelper.parseDecimal = (value = null) => {
  if (!value) {
    return;
  }
  return Number(Number(value).toFixed(2));
};

formatHelper.sentenceCase = (str) =>
  str
    .split(" ")
    .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`)
    .join(" ");

formatHelper.withZero = (n = 0) => {
  return `0${n}`.substr(-2);
};

formatHelper.getQtyInSlash = (qPending, qFrom, showSameQty = false ) => {
  let qtyString = "";
  let q1 = qFrom || qFrom === 0 ? qFrom : "";
  let q2 = qPending || qPending === 0 ? qPending : "";

  if (q1 <= q2 && showSameQty) {
    qtyString = q1;
  } else {
    qtyString = `${q2} / ${q1}`;
  }
  return qtyString;
};

formatHelper.checkVowel = (word) => {
  return ["a","e","i","o","u","A","E","I","O","U"].includes(word?.charAt(0));
}

formatHelper.nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
      case 1:
          return "st";
      case 2:
          return "nd";
      case 3:
          return "rd";
      default:
          return "th";
  }
};

formatHelper.zeroPrefix = (d) => d < 10 ? `0${d}` : d;

export { formatHelper };
