import React from "react";

import Stack from "components/common/Stack";
import Box from "components/common/Box";
import ImageComponent from "components/common/Image";
import Text from "components/common/Text";

const RMSBlockBuyCalls = (props) => {
    if (props.isCallEnabled) {
        return null
    }
    
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                position: "absolute",
                top: "60%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                p: "32px 15px",
                borderRadius: 2.5,
                boxShadow: { xs: "none", sm: "0 3px 26px #00000017" },
                backgroundColor: { xs: "transperant", sm: "background.paper" },
                textAlign: "center",
                width: "500px",
                maxWidth: "calc(100% - 30px)",
            }}
        >
            <ImageComponent src="/unlock-portfolio.svg" width="43px" />
            <Box sx={{ mt: 2.25, mb: 4 }}>
                <Text
                    variant="body1"
                    mb={"20px"}
                    sx={{ color: "#000", fontWeight: "500", fontSize: "20px" }}
                >
                    Blocked!
                </Text>
                <Text variant="body2" sx={{ maxWidth: "420px", m: "0 auto", color: "gray" }}>
                    Buy calls will be activated on the next trading day by our system.
                </Text>
            </Box>
        </Stack>
    );
};

export default RMSBlockBuyCalls;
