import React from "react";

import BackIcon from "@mui/icons-material/ArrowBackOutlined";
import { makeStyles, createStyles } from "@mui/styles";

import Stack from "components/common/Stack";
import IconButton from "components/common/IconButton";
import Text from "components/common/Text";
import Grid from "components/common/Grid";
import Divider from "components/common/Divider";

import SectionTitle from "components/SectionTitle";
import JarvisButton from "components/JarvisButton";
import ConfirmationActionDialog from "components/ConfirmationActionDialog";

import CouponCodeRow from "./CouponCodeRow";


import { formatHelper } from "helper/format";

const PortfolioPayment = ({
	onBackClick,
	onPaymentClick,
	pricingData,
	isPaymentLoading,
	isPaymentCompleted,
	confirmDialog: {
		open,
		onConfirm,
		onClose,
		message,
	},
	couponCodeProps: {
		onActionTrigger,
		viewTypes,
		activeViewType,
		formData,
		formError,
	},
}) => {
	const classes = useStyles();
	return (
		<Stack>
			<SectionTitle
				sx={{ mb: 2 }}
				heading="Pricing"
				startIcon={
					<IconButton onClick={onBackClick}>
						<BackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
					</IconButton>
				}
			/>

			{/* What do you GET */}
			<Stack
				className={classes.bdrWraper}
				py={{ xs: 2, sm: 3 }}
				px={{ xs: 3, sm: 4 }}
				mb="20px"
			>
				<Text
					variant="body2"
					sx={{ fontWeight: "500", color: "primary.main", mb: 1 }}
				>
					What Do You Get?
				</Text>

				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<Text className={classes.bullet}>
							<i />
							Customized Portfolio Recommendations
						</Text>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Text className={classes.bullet}>
							<i />
							Detailed And Insightful Dashboard To Track Your Investments
						</Text>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Text className={classes.bullet}>
							<i />
							Active Risk Management System With Regular Notifications Enabling You To Manage Your Investments Actively
						</Text>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Text className={classes.bullet}>
							<i />
							Make Additional Investments Through Lump Sum Or SIP Mode
						</Text>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Text className={classes.bullet}>
							<i />
							Timely Rebalancing During Changing Market Conditions
						</Text>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Text className={classes.bullet}>
							<i />
							Dedicated Customer Support Team To Address All Your Queries.
						</Text>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Text className={classes.bullet}>
							<i />
							One Touch Transaction Execution Through Extensive List Of Brokers.
						</Text>
					</Grid>
				</Grid>
			</Stack>

			{/* What do you PAY */}
			<Stack
				className={classes.bdrWraper}
				py={{ xs: 2, sm: 3 }}
				px={{ xs: 3, sm: 4 }}
			>
				<Text
					variant="body1"
					sx={{ fontWeight: "500", color: "#2C2C2C", mb: 3 }}
				>
					Investment amount :{" "}
					{formatHelper.currency(pricingData.investmentAmount, {
						config: { useGrouping: true },
					})}
				</Text>
				<Text
					variant="body2"
					sx={{ fontWeight: "500", color: "primary.main", mb: 0.5 }}
				>
					What Do You Pay?
				</Text>
				<Text
					variant="body2"
					sx={{ fontWeight: "bold", color: "#2C2C2C", mb: 2.5 }}
				>
					Advisory Fee of {pricingData.feesPercentage}% (Exclusive of taxes) of
					the investment value/portfolio value, charged{" "}
					{`${pricingData.feesTenure}`.toLowerCase()}. Check illustration for
					the details.
				</Text>

				<Stack sx={{ maxWidth: { xs: 1, md: 420 }, mb: 3.5 }}>
					<Stack
						direction="row"
						justifyContent="space-between"
						sx={{ mb: 0.75 }}
					>
						<Text sx={{ color: "#515151", fontSize: "small.fontSize" }}>Advisory Fees</Text>
						<Text sx={{ color: "#515151", fontSize: "small.fontSize" }}>
							{formatHelper.currency(pricingData.fees)}
						</Text>
					</Stack>

					<CouponCodeRow
						onActionTrigger={onActionTrigger}
						viewTypes={viewTypes}
						activeViewType={activeViewType}
						formData={formData}
						formError={formError}
						discountValue={pricingData.discountedAmount}
						pricingValue={pricingData.fees}
					/>

					<Stack
						direction="row"
						justifyContent="space-between"
						sx={{ mb: 0.75 }}
					>
						<Text sx={{ color: "#515151", fontSize: "small.fontSize" }}>
							GST ({pricingData.feesGSTPercentage}%)
						</Text>
						<Text sx={{ color: "#515151", fontSize: "small.fontSize" }}>
							{formatHelper.currency(pricingData.feesGST)}
						</Text>
					</Stack>
					

					<Stack direction="row" justifyContent="space-between" sx={{ 
						mt: 1.5,
						mb:1, 
                        ml:"-8px", 
                        mr:"-8px",
                        backgroundColor: "#f7f7f7",
                        p: "4px 8px",
						 }}>
						<Text sx={{ color: "#515151", fontSize: "small.fontSize", fontWeight: "medium" }}>
							Total Payable Amount
						</Text>
						<Text sx={{ color: "#515151", fontSize: "small.fontSize", fontWeight: "medium" }}>
							{formatHelper.currency(pricingData.feesTotal)}
						</Text>
					</Stack>
				</Stack>

				<Divider sx={{ mb: 3 }} />

				<Stack
					direction="row"
					justifyContent="space-between"
					sx={{ maxWidth: { xs: 1, md: 420 } }}
				>
					<Stack>
						<Text
							variant="subtitle2"
							sx={{ color: "#515151", fontWeight: "bold" }}
						>
							{formatHelper.currency(pricingData.feesTotal, {
								config: { useGrouping: true },
							})}
						</Text>
						<Text variant="caption1" sx={{ color: "#808080" }}>
							inclusive GST
						</Text>
					</Stack>
					<JarvisButton
						disableEndIcon={isPaymentCompleted}
						loading={isPaymentLoading}
						disabled={isPaymentCompleted}
						onClick={onPaymentClick}
					>
						{isPaymentCompleted ? "Payment Completed" : "Proceed to Pay"}
					</JarvisButton>
				</Stack>
			</Stack>

			{/* confirm Dialog */}
			<ConfirmationActionDialog
				open={open}
				onConfirm={onConfirm}
				onClose={onClose}
				message={message}
			/>
		</Stack>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		bullet: {
			position: "relative",
			paddingLeft: "15px",
			fontSize: "0.875rem",
			letterSpacing: "-0.011em",
			color: "#515151",
			"& > i": {
				display: "table",
				border: "2px solid #515151",
				borderRadius: "50%",
				position: "absolute",
				left: "0px",
				top: "8px",
			},
		},
		bdrWraper: {
			border: "1px solid #E8EBEE",
			flex: 1,
			borderRadius: "8px",
		}
	})
);
export default React.memo(PortfolioPayment);
