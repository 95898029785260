import React from "react";

import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Image from "components/common/Image";
import ButtonBase from "components/common/ButtonBase";
import JarvisButton from "components/JarvisButton";
import TextField from "components/common/TextField";
import { formatHelper } from "helper/format";

const CouponCodeRow = (props) => {
    const {
        onActionTrigger,
        viewTypes,
        activeViewType=viewTypes.DEFAULT,
        formData,
        formError,
        discountValue,
        disableRemoveCoupon = false,
        pricingValue,
    } = props;

    if (activeViewType === viewTypes.DEFAULT) {
        return (
            <>
            <Stack sx={{marginBottom:"2rem"}}>
                <Text sx={{fontWeight:"700", fontSize:"18px", color:"#212225",lineHeight:"23px"}}>Summary</Text>
            </Stack>
            <Stack
                direction="row"
                alignItems="stretch"
                justifyContent="space-between"
                mb={1}
            >
        
                <Stack direction="row" alignItems="center" >
                    <Image
                        height={20}
                        width={20}
                        src="/images/coupon_icon1.svg"
                    />
                    <Text sx={{ color: "secondary", marginLeft: 1 }}>
                        Coupon Discount
                    </Text>
                </Stack>

                <ButtonBase
                    variant="outlined"
                    sx={{ fontWeight: "medium", py: 1, px: 2.5, fontSize: '0.875rem !important' }}
                    onClick={() => onActionTrigger("COUPON_ADD")}
                >
                    Apply
                </ButtonBase>
            </Stack>
            </>
        );
    }

    if (activeViewType === viewTypes.EDIT) {
        return (
            <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ marginBottom: 1 }}
                >
                    <Stack direction="row" alignItems="center">
                        <Image
                            height={24}
                            width={24}
                            src="/images/coupon_icon.png"
                        />
                        <Text sx={{ color: "secondary", marginLeft: 1 }}>
                            Coupon Discount
                        </Text>
                    </Stack>

                    <ButtonBase
                        variant="text"
                        sx={{ fontWeight: "medium", py: 1, px: 2.5, fontSize: '0.875rem !important' }}
                        onClick={() => onActionTrigger("COUPON_CLEAR")}
                    >
                        Cancel
                    </ButtonBase>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    mb={1}
                    sx={{
                        border: `1px solid #E0E7FF`,
                        borderRadius: 1.25,
                        overflow: "hidden",
                        height: "50px",
                        backgroundColor:"#fff"
                    }}
                >
                    <TextField
                        label="Enter Coupon Code"
                        value={formData.coupon}
                        onChange={(e) =>
                            onActionTrigger("COUPON_CHANGE", {
                                name: "coupon",
                                value: `${e.target.value}`.toUpperCase(),
                            })
                        }
                        error={formError.coupon}
                        helperText={formError.coupon}
                        sx={{ maxWidth: { xs: "100%", md: 400 } }}
                        InputProps={{
                            sx: { border: `0 !important` },
                        }}
                    />
                    <JarvisButton
                        disableEndIcon={true}
                        onClick={() => onActionTrigger("COUPON_SUBMIT")}
                        sx={{
                            borderRadius: "0px !important"
                        }}
                    >
                        Apply
                    </JarvisButton>
                </Stack>
            </>
        );
    }

    if (activeViewType === viewTypes.APPLIED) {
        return (
            <Stack mb={1} direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                    <Text sx={{ color: "#515151", fontWeight: "medium" }}>
                        Coupon Applied
                    </Text>
                    <Text variant="small" sx={{ color: "#45AC70" }}>
                        You saved {formatHelper.currency(discountValue)}
                    </Text>
                </Stack>
                <Stack direction="row">
                    {disableRemoveCoupon ? null : (
                        <ButtonBase
                            variant="text"
                            sx={{ fontWeight: "medium", py: 1, px: 2.5, fontSize: '0.875rem !important' }}
                            onClick={() => onActionTrigger("COUPON_CLEAR")}
                        >
                            Remove
                        </ButtonBase>
                    )}
                </Stack>
            </Stack>
        );
    }

    return null;
};
export default CouponCodeRow;
