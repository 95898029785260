import React, { memo, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(() => {
  const borderWidth = (chartWidth, disableLabel) => {
    let w = 0;
    w = (chartWidth / 100) * 10;
    if (!disableLabel && w < 53) {
      w = 53;
    }
    return `${w}px`;
  }
  const needleHeight = (chartWidth, needleLenght) => {
    let needleL = 15;
    needleL = `${chartWidth / 2 - (chartWidth / 100) * 24}`;
    if(needleLenght){
      needleL = needleLenght;
    }
    return `${needleL}px`
  }
  const centerCircle = (chartWidth) => `${(chartWidth / 100) * 15}px`;

  return {
    wrap: (props) => ({
      height: `${props.chartWidth / 2}px`,
      overflow: "hidden",
    }),
    main: (props) => ({
      width: `${props.chartWidth}px`,
      height: `${props.chartWidth}px`,
      position: "relative",
      overflow: "hidden",
      marginBottom: "50px",
      marginTop: `-${props.chartWidth / 2}px`,
    }),
    needle: (props) => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) rotate(-90deg)",
      margin: "0px",
      transition: "all 2s ease-in-out",

      "& > i:first-child": {
        display: "table",
        width: `${props.needleWidth ? props.needleWidth + "px" : "7px"}`,
        height: `${needleHeight(props.chartWidth, props.needleLenght)}`,
        backgroundColor: "#000",
        borderRadius: "100% 100% 0px 0%",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0%)",
        bottom: "50%",
        zIndex: "-1",
        boxShadow: "-4px 1px 2px #c4c4c4",
      },
      "& > i:last-child": {
        display: "table",
        width: `${centerCircle(props.chartWidth)}`,
        height: `${centerCircle(props.chartWidth)}`,
        backgroundColor: "#202020",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "50px",
      },
    }),

    parent: (props) => ({
      position: "absolute",
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      transform: "translateY(50%)",
      "& > div": {
        flex: "1",
        height: `${props.chartWidth}px`,
        width: `${props.chartWidth}px`,
        border: `${borderWidth(props.chartWidth, props.disableLabel)} solid red`,
        borderColor: "transparent",
        borderRadius: "50%",
        position: "absolute",
        left: "0px",
        transition: "all 0.5s ease-in-out",
      },

      "& > div:nth-child(1)": {
        borderColor: "transparent transparent transparent #1c8d13",
        transform: "rotate(-15deg)",
        zIndex: "6",
      },
      "& > div:nth-child(2)": {
        borderColor: "transparent transparent transparent #83c605",
        transform: "rotate(15deg)",
        zIndex: "5",
      },
      "& > div:nth-child(3)": {
        borderColor: "transparent transparent transparent #bcd103",
        transform: "rotate(45deg)",
        zIndex: "4",
      },
      "& > div:nth-child(4)": {
        borderColor: "transparent transparent transparent #ecb004",
        transform: "rotate(75deg)",
        zIndex: "3",
      },
      "& > div:nth-child(5)": {
        borderColor: "transparent transparent transparent #df873a",
        transform: "rotate(105deg)",
        zIndex: "2",
      },
      "& > div:nth-child(6)": {
        borderColor: "transparent transparent transparent #d44c4c",
        transform: "rotate(135deg)",
        zIndex: "1",
      },
    }),

    lowToHeight: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& > p": {
        margin: 0,
        fontSize: "0.8rem",
        fontWeight: 500,
      },
      "& > p:first-child": {
        color: "#1c8d13",
      },
      "& > p:last-child": {
        color: "#d44c4c",
      }
    },
  };
});

const RiskProfileGraph = (props) => {
  const classes = useStyle(props);
  const [position, setPosition] = useState(-90);

  const riskProfile = { 1: -75, 2: -45, 3: -15, 4: 15, 5: 45, 6: 72 };

  useEffect(() => {
    setTimeout(setNeedle, 1000);
  }, [props.riskProfileId]);


  const setNeedle = () => {

    let newPosition = riskProfile[props.riskProfileId] ? riskProfile[props.riskProfileId] : -90;

    if (props.riskProfileId && newPosition !== position) {
      setPosition(newPosition);
    }
  }


  return (
    <div>
      <div className={classes.wrap}>

        <div className={classes.main}>
          <div className={classes.parent}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {props.disableNiddle ? null : (
              <p
                className={classes.needle}
                style={{
                  transform: `translate(-50%, -50%) rotate(${position}deg)`,
                }}
              >
                <i></i>
                <i></i>
              </p>
            )}
          </div>

        </div>
      </div>

      {props.disableLabel ? null : (
        <div className={classes.lowToHeight}>
          <p>Low Risk</p>
          <p>High Risk</p>
        </div>
      )}

    </div>
  );
};

RiskProfileGraph.defaultProps = {
  chartWidth: 200,
  disableNiddle: false,
  needleWidth: null
};

export default memo(RiskProfileGraph);
