import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { customerActions } from "stores/customer/customerActions";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return {
        currentStage: state.jarvisRMS.rmsOnboarding.currentStage,
        duplicateStages: state.jarvisRMS.rmsOnboarding.duplicateStages,
        rmsOnboardingSteps: state.jarvisRMS.rmsOnboarding.rmsOnboardingSteps,
        isOnboardingDataFetched: state.onboarding.isInitialFetched,
        isOnboardingDataLoading: state.onboarding.loading.onboardingData,
        isEmailVerified: state.customer.loginProfile.isEmailVerified,
        emailId: state.customer.loginProfile.email,
        name: state.customer.loginProfile.name,
        isPortfolioOnboardingCompleted: state.onboarding.isOnboardingCompleted,
        isPaymentCompleted: state.jarvisRMS.rmsOnboarding.isPaymentCompleted,

    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getOnboardingDataAction: jarvsiRMSOnboardingActions.getOnboardingData,
            updateEmailAction: customerActions.updateEmail,
            setToast: commonActions.setToast,
            logoutAction: customerActions.logout,
        },
        dispatch
    );

const RMSDashboardLayoutStore = (Container) => {
    return connect(mapStateToProps, mapDispatchToProps)(Container);
};

export default RMSDashboardLayoutStore;
