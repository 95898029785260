import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions"

const mapStateToProps = (state) => {

    return {
        isAppWelcomeScreenShown: state.common.isAppWelcomeScreenShown,
        deviceId: state.common.deviceId,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setAppWelcomeScreenShownAction: commonActions.setAppWelcomeScreenShown
}, dispatch);

const appWelcomeScreenStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);

export default appWelcomeScreenStore;