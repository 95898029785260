import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { helpSupportActions } from "stores/helpSupport/helpSupportAction"


const mapStateToProps = (state) => {
    return {
        categoryList: state.helpSupport.categoryList,
        categoryData:state.helpSupport.categoryData,
        categoryQuestion:state.helpSupport.categoryQuestions,
        isLoading: state.helpSupport.loading.categoryList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getCategoryListAction: helpSupportActions.getCategoryList,
    getCategoryDataAction:helpSupportActions.getCategoryData,
    getCategoryQuestionsAction:helpSupportActions.getCategoryQuestions
}, dispatch)

const HelpSupportStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default HelpSupportStore;