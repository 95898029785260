import React from "react";
import InvestmentStrategyCard from "components/Onboarding/InvestmentStrategyCard";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import OfflineCheckerButton from "components/OfflineCheckerButton";

const investmentStrategies = [
  {
    id: 0,
    heading: "Pure equity strategy",
    description:
      "Pure equity strategy-Jarvis will construct your portfolio with only stocks, based on your risk tolerance",
  },
  {
    id: 1,
    heading: "Asset allocation strategy",
    description:
      "Asset allocation strategy-Jarvis will diversify across Equity, Debt, &amp; Gold.",
  },
];

const InvestmentStrategyForm = (props) => {
  const { currentStrategyVal, setStrategy, onSubmit, loading } = props;

  return (
    <Box>
      <Stack  direction={{xs:"column",md : "row"}} spacing={2} justifyContent="center" mb="20px">
        {investmentStrategies.map((strategy, index) => (
          <InvestmentStrategyCard
            key={`strategy_${index}`}
            heading={strategy.heading}
            description={strategy.description}
            isSelected={currentStrategyVal === strategy.id}
            onClick={() => setStrategy(strategy.id)}
          />
        ))}
      </Stack>

      <Stack mb={2} alignItems="center">
        <OfflineCheckerButton
          offlineButtonProps={{
            text: "Confirm this investment strategy",
            loading: loading,
            sx: { textTransform: "none", width: "300px" },
          }}
          onlineButtonProps={{
            text: "Confirm this investment strategy",
            loading: loading,
            sx: { textTransform: "none", width: "300px" },
            onClick: onSubmit
          }}
        />
      </Stack>
    </Box>
  );
};

export default InvestmentStrategyForm;
