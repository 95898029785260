import React from "react";
import { localStore } from "stores/localStorage";
import { withRouter } from "react-router-dom";
import JarvisButton from "components/JarvisButton";
import Stack from "components/common/Stack";
import Image from "components/common/Image"
import Box from "components/common/Box";
import Text from "components/common/Text";
import InfoVideo from "components/ProductInfo/InfoVideo";

class JarvisInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // set user has seen this page once.
        localStore.setSeenJarvisInfo(1);
    }

    handleSubmit = () => {
        this.props.history.push("/customer/product-info");
    }

    render() {

        return (
            <>
                <Stack
                    sx={{
                        flex: 1, maxWidth: { lg: "1180px" },
                        ml: { xs: "unset", md: "5rem" }
                    }}
                >
                    <Stack direction="row" sx={{ mt: 3, mb: 2 }}>
                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            <Image src="/svgs/jarvis-logo.svg" width={140} />
                        </Box>
                        <Box sx={{ display: { xs: "block", md: "none" } }}>
                            <Image src="/svgs/jarvis-logo.svg" width={90} />
                        </Box>
                    </Stack>
                    <Stack
                        sx={{ maxWidth: { lg: "1160px" } }}
                    >
                        <Text
                            sx={{
                                color: "#676E76",
                                fontSize: { xs: "14px", md: "20px" },
                                mt: { xs: 1, md: 3 }
                            }}
                        >
                            For existing and new stock market investors who don't have the time and expertise to research 2000 plus stocks to make money in the stock market. Additionally, you lack the information necessary to effectively manage the risk in your equity portfolio and to appropriately rebalance to maximise returns
                        </Text>
                        <InfoVideo />
                    </Stack>
                    <Stack mt={3} sx={{ maxWidth: { lg: "1160px" }, alignItems: "flex-end" }}>
                        <JarvisButton
                            variant="contained"
                            sx={{ mt: { xs: 2, md: 3 }, width: { xs: "100%", md: "30%" } }}
                            onClick={() => this.handleSubmit()}
                        >
                            Start Investment Journey
                        </JarvisButton>
                    </Stack>
                </Stack>
            </>
        )
    }
}

export default withRouter(JarvisInfo);
