import _get from "lodash.get";

const jarvisOnestockExistingHoldingParser = {};


jarvisOnestockExistingHoldingParser.existingHoldingList = (res) => {
  if (res && res.data) {
    res = res.data;
  }

  if (!res) {
    return { data: [], totalRecords: 0 };
  }

  let data = [];
  if (res && res.list) {
    data = res.list;
  }

  data = data.map((d) => ({
    brokerId: _get(d, "brokerId", null),
    brokerName: _get(d, "brokerName", null),
    stockId: _get(d, "stockId", null),
    stockSymbol: _get(d, "stockSymbol", null),
    stockName: _get(d, "stockName", null),
    stockQuantity: _get(d, "stockQuantity", null),
    stockBuyDate: _get(d, "stockBuyDate", null),
    stockBuyPrice: _get(d, "stockBuyPrice", null),
    stockBuyValue: _get(d, "stockBuyValue", null),
    stockCurrentPrice: _get(d, "stockCurrentPrice", null),
    stockCurrentValue: _get(d, "stockCurrentValue", null),
    stockGainLoss: _get(d, "stockGainLoss", null),
    stockGainLossPercentage: _get(d, "stockGainLossPercentage", null) ? d.stockGainLossPercentage.toFixed(2) : null,
    marketCap: _get(d, "marketCap", null),
    riskScore: _get(d, "riskScore", null),
  }));

  return { data, totalRecords: res.records };
};



export { jarvisOnestockExistingHoldingParser };
