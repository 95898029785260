import { config } from "config";
const brokerHelper = {};

brokerHelper.getLogoUrl = () => {
    const brokerName = window.location.hostname.split(".")[0];
    const brokers = config.broker;
    const broker = brokers[brokerName] || {};
    return broker.logoUrl;

};

export { brokerHelper };
