import React from "react";
import cx from "classnames";
import { makeStyles, createStyles } from "@mui/styles";

import ButtonBase from "components/common/ButtonBase";
import Stack from "components/common/Stack";
import Text from "components/common/Text";

import CheckIcon from "@mui/icons-material/CheckCircle";
import { alpha } from "@mui/system";

const InvestmentStrategyCard = ({
    heading,
    description,
    descriptionSmall = "",
    isSelected,
    onClick,
}) => {
    const classes = useStyles();
    return (
        <Stack
            className={cx({
                [classes.root]: true,
                [classes.rootActive]: isSelected,
            })}
            onClick={onClick}>
            <Text
                className={classes.headingText}
                sx={{
                    color: isSelected ? "#1D9BFF" : "",
                }}>
                {heading}
            </Text>
            <Text className={classes.descriptionText}>
                {description}
                {descriptionSmall && (
                    <Text className={classes.descriptionSmall}>
                        {descriptionSmall}
                    </Text>
                )}
            </Text>
            <ButtonBase
                sx={{
                    maxWidth: "100%",
                    padding: "12px 10px",
                }}
                className={classes.button}
                variant="text"
                size="small"
                endIcon={
                    isSelected ? <CheckIcon sx={{ fontSize: 12 }} /> : null
                }
                onClick={onClick}>
                {isSelected ? "Strategy selected" : "Choose this strategy"}
            </ButtonBase>
        </Stack>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            borderRadius: 5,
            maxWidth: 250,
            padding: "15px",
            border: `1px solid #f1f1f1`,
            transition: `all 0.3s linear`,
            cursor: "pointer",
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.up("md")]: {
                padding: "22px 26px",
            },
        },
        rootActive: {
            borderColor: theme.palette.primary.main,
            backgroundColor: "#1D9BFF14",
        },
        headingText: {
            ...theme.typography.body1,
            fontWeight: "medium",
            lineHeight: 1.25,
            marginBottom: 16,
        },
        descriptionText: {
            ...theme.typography.small,
            lineHeight: 1.5,
            marginBottom: 24,
            flex: 1,
        },
        descriptionSmall: {
            color: "#c3c3c3",
            fontSize: "12px",
            marginTop: "7px",
        },
        button: {
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
        },
    })
);

export default InvestmentStrategyCard;
