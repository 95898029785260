import { thirdpartyConstants } from "./thirdpartyConstants";

const thirdpartyActions = {};

thirdpartyActions.getSubscriptionPlans = ({ productCode }) => ({
    type: thirdpartyConstants.SUBSCRIPTION_PLANS_SAGA,
    payload: { productCode },
});

thirdpartyActions.getTransactionHistoryList = ({
    productCode,
    page,
    limit,
    fromDate,
    toDate,
    transactionStatus,
}) => ({
    type: thirdpartyConstants.TRANSACTION_HISTORY_LIST_SAGA,
    payload: {
        productCode,
        page,
        limit,
        fromDate,
        toDate,
        transactionStatus,
    }
});
export { thirdpartyActions };
