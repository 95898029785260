import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";
import { thirdpartyActions } from "stores/thirdparty/thirdpartyActions";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
  
  return {
    profile: state.customer.profile,
    isProfileLoading: state.customer.loading.profile,
    renewalPricingDetails: state.customer.renewalPricingDetails,
    isLoading: state.customer.loading.renewalPricingDetails,

    subscriptionPlans: state.thirdparty.subscriptionPlans,
    isSubscriptionPlansLoading: state.thirdparty.loading.subscriptionPlans,
    isSubscriptionPlansCalled: state.thirdparty.isInitialCalled.subscriptionPlans,
    transactionHistory: state.thirdparty.transactionHistoryList,
    transactionHistoryCount: state.thirdparty.transactionHistoryListCount,
    transactionHistoryLoading: state.thirdparty.loading.transactionHistoryList,
    reasonsDropdown: state.customer.reasonsDropdown,
    customerSubscriptionId: state.onboarding.customerSubscriptionId,

    productSubscriptionDetails: state.customer.productSubscriptionDetails,
    isproductSubscriptionDetailsLoading: state.customer.loading.productSubscriptionDetails,

    transactionStatusFilter: [
      {
        label: "Completed",
        value: "COMPLETED",
      },
      {
        label: "Failed",
        value: "FAILED",
      },
      {
        label: "Refunded",
        value: "REFUNDED",
      },
      {
        label: "Partial Refund",
        value: "PARTIAL",
      },
    ],
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getProfileDataAction: customerActions.getProfile,
    getRenewSubscriptionAction: customerActions.getRenewalPricingDetails,
    getSubscriptionPlansAction: thirdpartyActions.getSubscriptionPlans,
    reasonsDropdownAction: customerActions.getReasonDropdownForCancellation,
    transactionHistoryAction: thirdpartyActions.getTransactionHistoryList,
    getProductsSubscriptionDetailsAction: customerActions.getProductsSubscriptionDetails,

    setDialog: commonActions.setDialog,
  },
  dispatch
);

const SubscriptionDetailsStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default SubscriptionDetailsStore;