import React from 'react';
import RMSOnboardingOverviewContainer from 'containers/RMSOnboardingOverview'

const RMSOnboardingOverview = () => {
    return (
        <>
            <RMSOnboardingOverviewContainer />
        </>);
}

export default RMSOnboardingOverview;