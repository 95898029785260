
// import { appsFlyerTracker } from "../appsFlyer";
// import { mixpanelTracker } from "../mixpanel";
// import { uxcamTracker } from "../uxcam";

const blogTracker = {}

blogTracker.blogs = () => {
    // const eventName = "blogs";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { }
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { }
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
}

blogTracker.blogsFailed = () => {
    // const eventName = "blogs_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,  
    // });

    // mixpanelTracker.trackEvents({
    //     eventName, 
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
}

blogTracker.blogsSuccess = () => {
    // const eventName = "blogs_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
        
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { }
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
}

blogTracker.blogReadMore = () => {
    // const eventName = "blog_read_more";

    // appsFlyerTracker.trackEvents({
    //     eventName,
        
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { }
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
}

blogTracker.blogReadMoreFailed = () => {
    // const eventName = "blog_read_more_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
        
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { }
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
}
blogTracker.blogReadMoreSuccess = () => {
    // const eventName = "blog_read_more_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
        
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { }
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
}


export { blogTracker };