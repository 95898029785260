import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { customerActions } from "stores/customer/customerActions";
import { onboardingActions } from "stores/onboarding/onboardingActions";

const mapStateToProps = (state) => {
    return {
        deviceId: state.common.deviceId,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateAuthDataAction: customerActions.updateAuthData,
    updateOnboardingDataAction: onboardingActions.updateOnboardingData,
    updateLoader: commonActions.updateLoader,
}, dispatch);

const SocialLoginStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default SocialLoginStore;
