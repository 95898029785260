import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
// import Popper from "@mui/material/Popper";

// import NotificationIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";

// import Box from "components/common/Box";
// import List from "components/common/List";
// import Text from "components/common/Text";
// import Link from "components/common/Link";
// import Paper from "components/common/Paper";
import Stack from "components/common/Stack";
// import Badge from "components/common/Badge";
// import Button from "components/common/Button";
import AppBar from "components/common/AppBar";
// import Divider from "components/common/Divider";
import ToolBar from "components/common/ToolBar";
// import ListItem from "components/common/ListItem";
import IconButton from "components/common/IconButton";
// import ListItemText from "components/common/ListItemText";
// import ListItemIcon from "components/common/ListItemIcon";
// import ListSubheader from "components/common/ListSubheader";

// import ArrowRightIcon from "@mui/icons-material/ArrowRightAltOutlined";
// import CircleIcon from "@mui/icons-material/Circle";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import { localStore } from "stores/localStorage";

const Header = (props) => {
    const {
        onLogoutClick,
        isDrawerExpanded,
        onDrawerToggle,

        // notifications = [],
        // onNotificationOpen,
        // onNotificationClose,
        // isNotificationDropdownOpen = false,
        // notificationAnchorEl = null,
    } = props;
    const classes = useStyles();

    return (
        <>
            <AppBar
                className={classes.appBarRoot}
                position="sticky"
                elevation={0}>
                <ToolBar sx={{ display: { xs: "auto", lg: "none" } }}>
                    {isDrawerExpanded ? null : (
                        <IconButton onClick={onDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                    )}
                </ToolBar>

                <ToolBar className={classes.toolBarRoot}>
                    {localStore.isSsoCustomer() ?<></>:(<Stack direction="row" spacing={1.5}>
                        {/* <img sx={{display:"none"}} src="/svgs/beta.svg" alt="BETA" /> */}

                        {/* NOTE: commented as feature is not developed */}
                        {/* <IconButton size="small" onClick={isNotificationDropdownOpen ? onNotificationClose : onNotificationOpen}>
                            <Badge overlap="circular" color="primary" variant="dot" invisible={!notifications.length}>
                                <NotificationIcon fontSize="small" />
                            </Badge>
                        </IconButton> */}

                        <IconButton size="small" onClick={onLogoutClick}>
                            <LogoutIcon fontSize="small" />
                        </IconButton>
                    </Stack>)}
                </ToolBar>
            </AppBar>
            {/* <Popper
                open={isNotificationDropdownOpen}
                anchorEl={notificationAnchorEl}
                position="bottom-end"
                style={{ zIndex: 9999 }}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                    },
                ]}
            >
                <Paper sx={{ width: 420, maxHeight: 380, overflow: "auto" }}>
                    <List sx={{ px: 2, pt: 0 }}>
                        <ListSubheader disableGutters sx={{ pt: 1 }}>
                            <Stack direction="row" spacing={1.5} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                <Text variant="body2" sx={{ fontWeight: "bold", color: "#222B3E" }}>Notifications</Text>
                                <Button style={{ fontSize: "12px", textTransform: "capitalize" }} variant="text" color="primary">Mark all as read</Button>
                            </Stack>
                            <Divider />
                        </ListSubheader>
                        {notifications.map((item, index) => (
                            [
                                <ListItem disableGutters alignItems="flex-start" key={`notification__${index}`}>
                                    <ListItemIcon sx={{ minWidth: 20, mt: 1.5 }}>
                                        <CircleIcon sx={{ fontSize: 8 }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Stack direction="row" spacing={1.5} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                                <Text variant="body2" sx={{ color: "#222B3E", fontWeight: item.isRead ? 400 : 700 }}>{item.title}</Text>
                                                {item.timestamp ? <Text variant="caption1" sx={{ color: "#A4BACB", flex: '0 0 auto' }}>{item.timestamp}</Text> : null}
                                            </Stack>
                                        }
                                        secondary={
                                            <Stack spacing={1}>
                                                {item.subtitle ? (
                                                    <Text variant="caption1" sx={{ color: "#838C92" }}>
                                                        {item.subtitle}
                                                    </Text>
                                                ) : null}
                                                {item.link ? (
                                                    <Link to={item.link} sx={{ display: "inline-flex", textDecoration: "none", alignItems: "center", justifyContent: "flex-start" }}>
                                                        <Text variant="caption1" sx={{ pr: 0.5, fontWeight: 500 }}>{item.linkText}</Text>
                                                        <ArrowRightIcon />
                                                    </Link>
                                                ) : null}
                                            </Stack>
                                        }
                                    />
                                </ListItem>,
                                index < notifications.length - 1 && <Divider key={`notification_divider__${index}`} />
                            ]
                        ))}
                    </List>
                </Paper>
            </Popper> */}
        </>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        appBarRoot: {
            backgroundColor: `${theme.palette.background.paper} !important`,
            color: "#1e1e1e !important",
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.up("lg")]: {
                justifyContent: "flex-end",
            },
            boxShadow: "0px 0px 10px #ebebeb",
        },

        toolBarRoot: {
            justifyContent: "flex-end",
        },
    })
);

export default React.memo(Header);
