import React from "react";
import { useSelector } from "react-redux";
import { commonApis } from "stores/common/commonApis";
import { commonParsers } from "stores/common/commonParsers";
import { localStore } from "stores/localStorage";
import { tracker } from "./index";

const ConfigProvider = (props) => {
    const isAuthenticated = useSelector(state => state.customer.isAuthenticated);
    const fetchTrackerConfig = async () => {
        const trackerConfig = localStore.getTrackerConfig();
        if (trackerConfig) {
            return;
        }
        try {
            const response = await commonApis.getTrackerConfig()
            const parsedResponse = commonParsers.trackerConfig(response);
            // set config in localstorage
            localStore.setTrackerConfig(parsedResponse);
            // update tracker files with config
            tracker.updateConfig(parsedResponse);

            tracker.initTracker();
        } catch (error) {
            console.error('unable to fetch tracker config', error)
        }
    }

    const fetchFeatureConfig = async () => {
        const featureConfig = localStore.getFeatureConfig();
        if (featureConfig) {
            return;
        }
        try {
            const response = await commonApis.getFeatureConfig()
            const parsedResponse = commonParsers.featureConfig(response);
            // set config in localstorage
            localStore.setFeatureConfig(parsedResponse);
        } catch (error) {
            console.error('unable to fetch feature config', error)
        }
    }

    React.useEffect(() => {
        fetchTrackerConfig();
        fetchFeatureConfig();
    }, [isAuthenticated])

    return props.children
}

export default ConfigProvider;