import React from "react";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Text from "components/common/Text";
import cx from "classnames";
import { makeStyles, createStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { localStore } from "stores/localStorage";
import { config } from "config";

const RMSOnboardingHeader = (props) => {
	const {
		currentStage,
		heading,
		location,
		onboardingStages,
		OnboardingSteps,
		isOnbordingHeaderVisible=true,
	} = props;
	const classes = useStyles();
	const stageNumber = OnboardingSteps.indexOf(currentStage) + 1;
	let ssoCustomerDetails = localStore.getSsoCustomerDetails()
	let isCentrumSsoCustomer = false
	if(localStore.isSsoCustomer() && ssoCustomerDetails?.broker === config?.broker?.ssoPlatform?.centrum ){
		isCentrumSsoCustomer = true
	}

	return (
		<>
			<Box
				sx={{
					display: isOnbordingHeaderVisible ? 'block' : 'none',
					px: { xs: 2, sm: 4, md: 8, lg: 12 },
					py: 3,
					borderBottom: "1px solid #EFEFEF",
				}}>
				<Stack
					direction="row"
					justifyContent="space-around"
					alignItems="center"
					sx={{ flex: 1 }}>
					{onboardingStages.map((item, index) => {
						
						const isInprogress = item.uri === location.pathname;
						const isCompleted =
							item.stageNumber === 3 && stageNumber > 3
								? true
								: item.stageNumber < stageNumber
								? true
								: false;

						return (
							<React.Fragment key={`header_index_${index}`}>
								{index !== 0 && (
									<Stack alignItems="center" sx={{ flex: 1 }}>
										<Box
											className={cx({
												[classes.dottedLine]: true,
												[classes.dottedLineActive]:
													isInprogress || isCompleted,
											})}
										/>
									</Stack>
								)}

								<Stack
									direction={{ xs: "column", md: "row" }}
									alignItems={{ xs: "center" }}
									spacing={1.5}>
									<Box
										className={cx({
											[classes.numBox]: true,
											[classes.numBoxActive]:
												isInprogress,
											[classes.numBoxComplete]:
												isCompleted,
										})}>
										<Text variant="small">
											{item.stageNumber}
										</Text>
									</Box>
									<Text
										variant="small"
										className={cx({
											[classes.text]: true,
											[classes.textActive]: isInprogress,
										})}>
										{isCentrumSsoCustomer && item.heading?.toLowerCase() === "ckyc & e-sign"?"E-Sign":item.heading}
									</Text>
								</Stack>
							</React.Fragment>
						);
					})}
				</Stack>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		dottedLine: {
			borderTop: "2px dashed #e5e5e5",
			width: "50%",
			height: 0,
		},
		dottedLineActive: {
			borderTop: "2px dashed #45AC70",
		},
		numBox: {
			color: "#A2A7AE",
			border: "1px solid #A2A7AE",
			borderRadius: "50%",
			height: "1.375rem",
			width: "1.375rem",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		numBoxActive: {
			backgroundColor: "#f0f8ff",
			color: theme.palette.primary.main,
			border: 0,
		},
		numBoxComplete: {
			backgroundColor: "#45AC70",
			color: "#fff",
		},
		text: {
			color: "#A2A7AE",
			whiteSpace: "noWrap",
			[theme.breakpoints.down("sm")]:{
                display: "none",
            }
		},
		textActive: {
			color: theme.palette.primary.main,
		},
		textCompleted: {},
	})
);

export default RMSOnboardingHeader;
