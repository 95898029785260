import React from "react";
import OnestockExistingHoldingContainer from "containers/JarvisOnestock/OnestockExistingHolding";

function ExistingHolding() {
    return (
        <>
            <OnestockExistingHoldingContainer />
        </>
    );
}

export default ExistingHolding;