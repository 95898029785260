import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisRMSTransactionConstants } from "./jarvisRMSTransactionConstants";

const initialState = {
    transactionList: {
        total: 0,
        list: [],
    },
    transactionDetail: {
        total: 0,
        list: [],
    },
    loading: {
        transactionList: 0,
        transactionDetail: 0,
    },
    initialCalled: {
        transactionList: 0,
        transactionDetail: 0,
    }
};

export const jarvisRMSTransactionReducers = persistReducer(
    {
        storage,
        key: "rmsTransaction",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_REQUEST:
                return {
                    ...state,
                    transactionList: initialState.transactionList,
                    loading: {
                        ...state.loading,
                        transactionList: 1,
                    },
                };

            case jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_RESPONSE:
                return {
                    ...state,
                    transactionList: {
                        list: payload.list,
                        total: payload.total,
                    },
                    loading: {
                        ...state.loading,
                        transactionList: 0,
                    },
                    initialCalled: {
                        ...state.initialCalled,
                        transactionList: 1,
                    }
                };

            case jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_REQUEST:
                return {
                    ...initialState,
                    transactionDetail: initialState.transactionDetail,
                    loading: {
                        transactionDetail: 1,
                    },
                };

            case jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_RESPONSE:
                return {
                    ...state,
                    transactionDetail: {
                        list: payload.list,
                        total: payload.total,
                    },
                    loading: {
                        transactionDetail: 0,
                    },
                };

            case jarvisRMSTransactionConstants.JARVIS_RMS_RESET_TRANSACTION_DATA: {
                return initialState;
            } 
            
            default:
                return state;
        }
    }
);
