import React from "react";
import _get from "lodash.get";

import BackIcon from "@mui/icons-material/ArrowBackOutlined";

import Stack from "components/common/Stack";
import IconButton from "components/common/IconButton";

import SectionTitle from "components/SectionTitle";
import InvestmentInfoCard from "components/Onboarding/InvestmentInfoCard";

const BackTesting = ({ onBackClick, investmentInfo, riskProfile, investmentStrategy }) => {
    return (
        <Stack>
            <SectionTitle
                sx={{ mb: 2 }}
                heading="Back Testing"
                startIcon={
                    <IconButton onClick={onBackClick}>
                        <BackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                    </IconButton>
                }
            />

            <InvestmentInfoCard
                investmentAmount={investmentInfo.investmentAmount}
                investmentTenure={investmentInfo.investmentTenure}
                riskProfile={_get(riskProfile, "name", "")}
                investmentStrategy={_get(investmentStrategy, "heading", "")}
            />
        </Stack>
    )
}

export default React.memo(BackTesting);