import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";

const mapStateToProps = (state) => {
  return {
    deviceId: state.common.deviceId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAuthDataAction: customerActions.updateAuthData,
      updateOnboardingDataAction: onboardingActions.updateOnboardingData,
      updateOnestockOnboardingDataAction: jarvsiOnestockOnboardingActions.updateOnestockOnboardingData,
    },
    dispatch
  );

const SignUpStore = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);
export default SignUpStore;
