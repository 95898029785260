import { jarvisOnestockActionCenterConstants } from "./jarvisOnestockActionCenterConstants";

const jarvisOnestockActionCenterActions = {};

jarvisOnestockActionCenterActions.getOnestockActionCenterData = () => {
	return {
		type: jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_SAGA,
	};
};

export { jarvisOnestockActionCenterActions };
