import React from "react";
import { withRouter } from "react-router-dom";
import { InputAdornment, Popover } from "@mui/material";
import { withStyles } from "@mui/styles";
import _get from "lodash.get";

import Grid from "components/common/Grid";
import IconButton from "components/common/IconButton";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import Table from "components/common/Table";
import TextField from "components/common/TextField";

import JarvisButton from "components/JarvisButton";
import JarvisCard from "components/JarvisCard";
import SectionTitle from "components/SectionTitle";
import JarvisDialog from "components/JarvisDialog";
import JarvisStockInfoCard from "components/JarvisStockInfoCard";
import RMSPortfolioSyncEditName from "components/RMSOnboarding/RMSPortfolioSyncEditName";
import RMSPortfolioSyncDelete from "components/RMSOnboarding/RMSPortfolioSyncDelete";
import RMSPortfolioSyncAddStock from "components/RMSOnboarding/RMSPortfolioSyncAddStock";
import RMSPortfolioSyncRemoveStock from "components/RMSOnboarding/RMSPortfolioSyncRemoveStock";


import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { appHelper } from "helper/app"
import { dateTimeHelper } from "helper/dateTime";
import { formatHelper } from "helper/format";
import { masterApis } from "stores/master/masterApis";
import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";
import { validationHelper } from "helper/validation";
import Pagination from "components/common/Pagination";

const successMessage = {
  icon: "/images/svgs/success.gif",
};

const ACTION_TYPES = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  ADD: "ADD"
}

const modalTimeOutTime = 2500;

class RMSConfirmPortfolioContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 25,
      refreshTable: false,
      portfolioId: null,
      portfolioActionList: [
        { id: 1, name: "Edit Portfolio Name" },
        { id: 2, name: "Remove Portfolio" },
      ],
      AnchorEl: null,
      masterStockListData: [],
      tableData: [],
      editPortfolioNameModalOpen: false,
      editPortfolioListModalOpen: false,
      removePortfolioModalOpen: false,
      addStockModalOpen: false,
      removeStockModalOpen: false,
      formData: {
        id: "",
        name: "",
      },
      stockData: null,
      addStockForm: {
        stockId: "",
        stockSymbol: "",
        stockName: "",
        stockQuantity: null,
        stockBuyPrice: null,
        stockBuyDate: "",
        index: "",
        defaultStockId: "",
      },
      formError: {
        name: "",
        stockName: "",
        stockQuantity: '',
        stockBuyPrice: '',
        stockBuyDate: "",
      },
      editPortfolio: "Edit Portfolio Name",
      deletePortfolioText: "Remove Portfolio?",
      removeStockText: "Remove Stock",
      addStockText: "Add a stock",
      editStockText: "Edit a stock",
      editText: true,
      isSuccessDialogOpen: false,
      apiSucessText: "",
      portfolioDetails: {},
      loading: false,
      dataChangedCounter: 0,

      filterStockName: "",
      isSearch: false,
      filterdData: [],
      actionType: '',
    }
  }

  componentDidMount() {
    this.getPortfolioDetails();
    this.getStockList();
    this.getStockListFromMaster();
  }

  getPortfolioDetails = async () => {
    if (!this.props.portfolioId) {
      return
    }
    try {
      let portfolioDetails = await jarvisRMSOnboardingApis.getPortfolioDetails({ portfolioId: this.props.portfolioId, isTemp: 1 });
      this.setState({
        formData: { name: portfolioDetails.portfolioName, id: portfolioDetails.portfolioId },
        portfolioDetails
      });
    } catch (err) {
      console.log("error", err);
    }
  }

  getStockList = async (page, rowsPerPage,) => {
    const { portfolioId } = this.props;
    if (!portfolioId) {
      return
    }
    this.setState({ loading: true })
    try {
      let listData = await jarvisRMSOnboardingApis.getStockList({page, rowsPerPage, portfolioId, isTemp: 1 });
      this.setState({ tableData: listData.list,dataChangedCounter:this.state.dataChangedCounter+1 });
    } catch (error) {
    } finally {
      this.setState({ loading: false })
    }
  }

  getStockListFromMaster = async () => {
    let stockList = await masterApis.getStockList();
    const { list } = stockList;
    this.setState({ masterStockListData: list });
  }

  handlePopoverOpenClick = (event) => {
    this.setState({ AnchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ AnchorEl: null });
  };

  handlePortfolioActionClick = (id) => {
    this.handlePopoverClose();
    switch (id) {
      case 1:
        this.setState({ editPortfolioNameModalOpen: true }, () => this.getPortfolioDetails());
        break;
      case 2:
        this.setState({ removePortfolioModalOpen: true });
        break;

      default:
        break;
    }
  };

  getSearchValue = (e) => {
    this.setState({ isLoading: true });
    const keyword = e.target.value;
    if (keyword !== '') {
      let filteredStockList = [...this.state.tableData]?.filter((lst) => { return lst?.stockName.toLowerCase().includes(keyword.toLowerCase()) || lst?.stockSymbol.toLowerCase().includes(keyword.toLowerCase()) })
      this.setState({ filterdData: filteredStockList, dataChangedCounter: this.state.dataChangedCounter + 1, isSearch: true }, () => console.log("keword blog", this.state.filterdData));
    }
    else {
      this.setState({ filterdData: this.state.tableData, dataChangedCounter: this.state.dataChangedCounter + 1 }, () => console.log("else blog", this.state.filterdData));
    }
    this.setState({ isLoading: false, filterStockName: keyword });
  }

  // NOTE: table add stock function
  formatStockListData = (stockList) => {
    let data = stockList?.map((data) => ({
      label: data?.stockName,
      value: data?.stockSymbol,
      stockId: data?.stockId,
    }));
    return data;
  };

  getSelectedValue = (data) => {
    const { stockName, stockId, defaultStockId, ...restStockData } = this.state.addStockForm;
    const { value } = data;
    this.setState({ addStockForm: { stockName: value, defaultStockId: data.stockId, stockId: data.stockId, ...restStockData } });
  };

  handleAddStockClick(val) {
    this.setState({ addStockModalOpen: true, actionType: ACTION_TYPES.ADD });
  }

  handleAddStockFormChange = ({ name, value, }) => {
    let { addStockForm } = this.state;
    addStockForm[name] = value;
    this.setState({ addStockForm });
  };

  // NOTE: table edit stock function
  handleEditStockClick(d, i) {
    this.setState({
      addStockModalOpen: true,
      actionType: ACTION_TYPES.EDIT,
      addStockForm: { ...d, index: d.portfolioStockId, defaultStockId: d.stockId },
      stockData: d.stockId,
    });
  }

  onClose = () => {
    this.setState({
      addStockModalOpen: false,
      addStockForm: { stockId: '', stockSymbol: '', stockBuyPrice: '', stockQuantity: '', defaultStockId: '', stockBuyDate: '' },
      formError: {
        name: "",
        stockName: "",
        stockQuantity: '',
        stockBuyPrice: '',
        stockBuyDate: "",
      },
      stockData: ''
    })
  }

  isFormValid = () => {
    const { addStockForm, formError } = this.state;

    const stockValidate = validationHelper.required(addStockForm.stockName);
    formError.stockName = stockValidate.message;

    const qtyValidate = validationHelper.inputNumber(addStockForm.stockQuantity);
    formError.stockQuantity = qtyValidate.message;

    const buyPriceValidate = validationHelper.inputNumber(addStockForm.stockBuyPrice);
    formError.stockBuyPrice = buyPriceValidate.message;

    const buyDateValidate = validationHelper.dateOfBirth(addStockForm.stockBuyDate);
    formError.stockBuyDate = buyDateValidate.message;

    this.setState({ formError });
    return (
      stockValidate.isValid &&
      buyPriceValidate.isValid &&
      qtyValidate.isValid && buyDateValidate.isValid
    );
  };

  handleAddEditStockSubmit = async () => {
    if (!this.isFormValid()) {
      return;
    }
    const { addStockForm, actionType } = this.state;
    const { portfolioId } = this.props;

    this.setState({ loading: true });
    try {
      let data;
      if (actionType === ACTION_TYPES.EDIT) {
        data = await jarvisRMSOnboardingApis.editStock({
          portfolioId,
          isTemp: 1,
          editPayload: [{
            index: addStockForm.index,
            portfolioStockId: addStockForm.stockId,
            stockId: addStockForm.stockId,
            stockSymbol: addStockForm.stockName,
            stockQuantity: parseInt(addStockForm.stockQuantity),
            stockBuyPrice: parseInt(addStockForm.stockBuyPrice),
            stockBuyDate: dateTimeHelper.sipDate(addStockForm.stockBuyDate),
          }],
        });
      }

      if (actionType === ACTION_TYPES.ADD) {
        data = await jarvisRMSOnboardingApis.addStock({
          portfolioId,
          isTemp: 1,
          stockId: addStockForm.stockId,
          stockSymbol: addStockForm.stockName,
          stockQuantity: parseInt(addStockForm.stockQuantity),
          stockBuyPrice: parseInt(addStockForm.stockBuyPrice),
          stockBuyDate: dateTimeHelper.sipDate(addStockForm.stockBuyDate),
        });
      }

      await this.getStockList();

      this.setState({
        addStockModalOpen: false,
        addStockForm: {
          stockId: "",
          stockSymbol: "",
          stockName: "",
          stockQuantity: null,
          stockBuyPrice: null,
          stockBuyDate: "",
          index: "",
          defaultStockId: "",
        },
        isSuccessDialogOpen: true,
        apiSucessText: data.message,
      }, () => {
        setTimeout(() => {
          this.setState({ isSuccessDialogOpen: false, dataChangedCounter: this.state.dataChangedCounter + 1, apiSucessText: "", })
          this.onClose();
        }, 1500);
      })
    } catch (err) {
      console.error('error at stock ' + actionType, err);
    } finally {
      this.setState({ loading: false })
    }
  };

  // NOTE: table remove stock function
  handleDeleteStockClick = (d) => {
    this.setState({ removeStockModalOpen: true, stockData: d.portfolioStockId })
  }

  handleDeleteStockClose = () => {
    this.setState({ removeStockModalOpen: false, stockData: null })
  }

  handleRemoveStock = async () => {
    let { stockData, formData, dataChangedCounter } = this.state;

    try {
      await jarvisRMSOnboardingApis.removeStock({
        portfolioId: formData.id,
        isTemp: 1,
        portfolioStockIds: [stockData],
      });
      await this.getStockList()
      this.setState({
        removeStockModalOpen: false,
        dataChangedCounter: dataChangedCounter + 1
      });
    } catch (err) {

    }
  };

  // NOTE: edit portfolioname modal functions
  handleEditPortfolioChange = (name, value) => {
    let { formData } = this.state;
    formData[name] = value;

    this.setState({ formData });
  };

  handleClose = async () => {
    this.setState({ editPortfolioNameModalOpen: false });
  };

  handleValidation = () => {
    const { formData, formError } = this.state;

    const nameValidation = validationHelper.portfolioName(formData.name);
    formError.name = nameValidation.message;

    this.setState({ formError });
    return nameValidation.isValid;
  };

  handleSubmitEditPortfolioName = async () => {
    if (!this.handleValidation()) {
      return;
    }
    let { formData } = this.state;
    let payload = {
      portfolioId: formData.id,
      portfolioName: formData.name,
    };

    try {
      let data = await jarvisRMSOnboardingApis.updatePortfolioName(payload);
      const { message } = data;
      await this.getPortfolioDetails()
      this.setState({
        editPortfolioNameModalOpen: false,
        apiSucessText: message,
        isSuccessDialogOpen: true,
      });
      setTimeout(() => {
        this.setState({
          apiSucessText: "",
          isSuccessDialogOpen: false,
        });
      }, modalTimeOutTime);

    }
    catch (err) {
      console.log(err);
    }

  };

  // NOTE: delete portfolio modal functions
  toggelDeletePortfolioModalOpen = () => {
    this.setState({ removePortfolioModalOpen: false });
  }

  handleDeletePortfolio = async () => {
    const { screenType } = this.props;
    const { formData } = this.state;
    try {
      let data = await jarvisRMSOnboardingApis.deletePortfolio({ portfolioId: formData.id, isTemp: 1 });
      if (data.result.portfolioId) {
        this.setState({
          apiSucessText: data.message,
          removePortfolioModalOpen: false,
          isSuccessDialogOpen: true
        });
      }
      setTimeout(() => {
        this.setState({
          isSuccessDialogOpen: false,
        });
        this.props.handleScreenChange(screenType.SYNC_PORTFOLIO);
      }, modalTimeOutTime);
    }
    catch (err) {
      console.log(err);
    }
  };

  handleConfirmPortfolio = async () => {
    this.setState({ loading: true, });
    const { portfolioId, screenType, processType = "" } = this.props;

    if (processType === "EXISTING_HOLDING") {
      const response = await jarvisRMSOnboardingApis.confirmPortfolioStock({ portfolioId });
      const confirmedPortfolioId = _get(response, "result.portfolioId", null);
      if (confirmedPortfolioId) {
        this.props.getRMSOnboardingDataAction();
        window.location.href = `/jarvis-rms/existing-holding/${confirmedPortfolioId}`;
        this.setState({ loading: false, });
        return
      }
      this.setState({ loading: false, });
      alert("Portfolio is not confirmed yet! Please contact support for help!")
    }
    this.setState({ loading: false, });
    return this.props.handleScreenChange(screenType.PLAN_SELECTION, { currentStockCount: this.state.tableData.length, currentPortfolioCount: 0 });
  }

  onPageChange(page, rowsPerPage) {
    this.setState({ page, rowsPerPage, refreshTable: false, dataChangedCounter: this.state.dataChangedCounter + 1 }, () => {
      this.getStockList(page, rowsPerPage,)
      window.scroll({ top: 0, behavior: 'smooth' })
    });
  }

  render() {
    const {
      portfolioDetails,
    } = this.state;
    const { classes } = this.props;
    const isMobileView = appHelper.isMobileView();

    const open = Boolean(this.state.AnchorEl);

    const id = open ? "simple-popover" : undefined;

    let tableData = this.state.filterdData
    if (!this.state.isSearch) {
      tableData = this.state.tableData;
    }

    const stockListHasError = this.state.tableData.find(d => d?.stockErrorMessage);

    const columns = [
      {
        field: "stockName", headerText: "Stock",
        bodyCellComponent: (v, d) => {
          return (
            <Stack>
              <Text variant="small">{v}</Text>
              <Stack direction="row">
                <Text variant="small">{d.stockSymbol}</Text>

              </Stack>
            </Stack>
          );
        },
        sortable: false,
      },
      { field: "stockBuyDate", headerText: "Buy Date", style: { width: "120px" }, bodyCellComponent: (v, d) => <Text variant="small">{dateTimeHelper.format(v, '$DD/$MM/$YYYY')}</Text> },
      { field: "stockQuantity", headerText: "Qty", style: { width: "80px" }, },
      {
        field: "stockBuyPrice", headerText: "Buy Price",
        style: { width: "120px" },
        bodyCellComponent: (v, d) => {
          return (
            <Stack>
              <Text variant="small" fontWeight="500" mb={1}>
                {formatHelper.currency(v)}
              </Text>
            </Stack>
          );
        },
      },
      {
        field: "stockBuyValue", headerText: "Buy value",
        style: { width: "120px" },
        bodyCellComponent: (v, d) => {
          return (
            <Stack>
              <Text variant="small" fontWeight="500" mb={1}>{formatHelper.currency(v)}</Text>
            </Stack>
          );
        },
      },
      {
        field: "stockCurrentPrice", headerText: "Current Price",
        style: { width: "120px" },
        bodyCellComponent: (v, d) => {
          return (
            <Stack>
              <Text variant="small" fontWeight="500" mb={1}>{formatHelper.currency(v)}</Text>
            </Stack>
          );
        },
      },
      {
        field: "stockChangeType", headerText: "Gain/Loss",
        style: { width: "120px" },
        bodyCellComponent: (v, d) => {
          return (
            <Stack>
              <Text variant="small" fontWeight="500" color={v === "GAIN" ? '#45AC70' : '#D44C4C'} mb={1}>{v === "GAIN" ? '+' : ''}{formatHelper.currency(d.stockChangePrice)} </Text>
              <Text variant="small" fontWeight="500" color={v === "GAIN" ? '#45AC70' : '#D44C4C'}>{v === "GAIN" ? '+' : ''}{d.stockChangePercentage} {v ? '%' : ''}</Text>
            </Stack>
          )
        }
      },
      {
        field: "",
        headerText: "Action",
        style: { width: "40px" },
        bodyCellComponent: (v, d, index) => {
          return (
            <>
              <Stack flex={1} maxWidth={0.5}>
                {d.stockRecommendedAction.includes("EDIT") ? (
                  <Button variant="text" onClick={() => this.handleEditStockClick(d, index)}><img alt="" src="/svgs/edit.svg" /></Button>
                ) : (<span sx={{ visibility: "hidden" }}>&nbsp;</span>)}
              </Stack>
              <Stack flex={1} maxWidth={0.5}>
                {d.stockRecommendedAction.includes("DELETE") ? (
                  <Button variant="text" onClick={() => this.handleDeleteStockClick(d, index)} ><img alt="" src="/svgs/delete_icon.svg" /></Button>
                ) : (<span sx={{ visibility: "hidden" }}>&nbsp;</span>)}
              </Stack>
            </>
          );
        },
      },
    ];

    return (
      <JarvisCard sx={{ px: { xs: 0, sm: 2, md: 3 } }}>
        <SectionTitle
          sx={{ mb: 3 }}
          heading="Your Portfolio"
          subheading="Your holdings are synced below cross check before proceeding"
        />

        <Grid container spacing={2} alignItems="center" mb={1}>
          <Grid item xs={12} md={6} sm={6}>
            <Stack direction="row" alignItems="center">
              <Text variant="body1" sx={{ fontWeight: 500, mr: 1 }}>
                {portfolioDetails?.portfolioName}
              </Text>
              <Text variant="body2" sx={{
                color: "#A2A7AE",
                mr: 2,
                flex: { xs: 1, md: 0 },
                whiteSpace: "nowrap"
              }}>
                ~ {portfolioDetails?.brokerDisplayName}
              </Text>

              <div>
                <IconButton
                  variant="text"
                  aria-describedby={id}
                  onClick={(e) => this.handlePopoverOpenClick(e)}
                >
                  <MoreHorizIcon
                    fontSize="small"
                    className={classes.horizonIcon}
                  ></MoreHorizIcon>
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.AnchorEl}
                  onClose={() => this.handlePopoverClose()}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Stack direction="column">
                    {this.state.portfolioActionList.map((data) => (
                      <Text
                        sx={{
                          padding: "10px 10px",
                          cursor: "pointer",
                          "&:hover": { color: "#2B70C5" },
                        }}
                        variant="small"
                        onClick={() =>
                          this.handlePortfolioActionClick(data.id)
                        }
                      >
                        {data.name}
                      </Text>
                    ))}
                  </Stack>
                </Popover>
              </div>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}
            // alignItems="center"
            >
              <Grid item xs={12} sm={6} md={6}>
                <TextField className={classes.root}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" sx={{ pl: 1 }}><img alt="" src="/svgs/search_icon.svg"></img></InputAdornment>,
                  }} placeholder="Search Stocks" value={this.state.filterStockName} onChange={this.getSearchValue} />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Stack
                  sx={{
                    direction: "row",
                    // alignItems: "center",
                    justifyContent: { xs: "flex-start", md: "flex-end" }
                  }}

                >
                  <JarvisButton
                    variant="outlined"
                    disableEndIcon
                    onClick={() => this.handleAddStockClick("add")}
                  >
                    + Add a stock
                  </JarvisButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {this.state.isSearch && !tableData.length ? (
          <Stack alignItems={'center'} sx={{ padding: { xs: 2, md: 8 } }}>
            <img alt="" src="/svgs/no_search_found.svg" style={{ maxWidth: '30%', minWidth: '22%', marginBottom: '22px' }}></img>
            <Text variant="subtitle1" color={"#676E76"}>No search result found</Text>
          </Stack>
        ) : null}

        {tableData.length ? (
          <Stack sx={{ mt: 1, mb: { xs: 3, md: "unset" } }}>
            {isMobileView ? (
              <Stack mb="50px">
                {tableData.map((item, i) => (
                  <React.Fragment key={`stock_card_${i}`}>
                    <JarvisStockInfoCard data={item} />
                    <Stack direction="row" mb={2}>
                      <JarvisButton disableEndIcon variant="outlined" size="small" sx={{ flex: 1, margin: "5px 5px" }} onClick={() => this.handleEditStockClick(item, i)}>Edit</JarvisButton>
                      <JarvisButton disableEndIcon variant="outlined" size="small" sx={{ flex: 1, margin: "5px 5px" }} onClick={() => this.handleDeleteStockClick(item, i)}>Delete</JarvisButton>
                    </Stack>
                  </React.Fragment>

                  // <JarvisStockInfoCard key={`stock_card_${i}`} data={item} isEdit={(data) => this.handleEditStockClick(data)} />
                ))}
                
                <Pagination
                localPagination
                 onPageChange={(page, rowsPerPage) => {
                  this.onPageChange(page, rowsPerPage);
                }}
                page={this.state.page - 1}
                count={tableData.length}
                classes={{root: classes.paginationRoot}}
                />
              </Stack>
            ) : (
              <>
                <Table
                  paginationBottom
                  localPagination = {true}
                  refreshTable={this.state.refreshTable}
                  page={this.state.page - 1}
                  rowsPerPage={this.state.rowsPerPage}
                  onPageChange={(page, rowsPerPage) => {
                    this.onPageChange(page, rowsPerPage);
                  }}
                  classNames={classes.tableBodyCell}
                  columns={columns}
                  data={tableData}
                  dataChangedCounter={this.state.dataChangedCounter}
                  shouldRenderSecondaryRow={(rowData) => rowData?.stockErrorMessage ? true : false}
                  getSecondaryRowContent={(rowData) => (
                    <Stack direction="row" alignItems={"center"} style={{ color: "#D44C4C", }} >
                      {rowData?.stockErrorMessage ? (
                        <InfoOutlinedIcon style={{ fontSize: "14px", mr: 1 }} />
                      ) : null}
                      &nbsp;&nbsp;{rowData?.stockErrorMessage}
                    </Stack>
                  )}
                  secondaryRow_tr_sx={{}}
                  secondaryRow_td_sx={{}}
                  th_sx={{
                    p: "16px 18px",
                    backgroundColor: "#EEF4FA",
                    color: "#A2A7AE",
                    borderBottom: "0",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  td_sx={{
                    p: "16px",
                    borderBottom: "none !important",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                />
              </>
            )}
          </Stack>
        ) : null}

        {this.state.isSearch && !tableData.length ? null : (
          <Stack
            sx={{
              background: "#fff",
              width: { xs: "100%", md: "unset" },
              justifyContent: { xs: "center", md: "unset" },
              alignItems: { xs: "center", md: "unset" },
            }}
          >
            <Stack
              className="bottomStickyButton"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: { xs: "center", md: "flex-end" },
                position: { xs: "fixed", md: "unset" },
                bottom: { xs: "10px", md: "unset" },
                width: { xs: "90vw", md: "unset" },
                mt: 1,
              }}
            >
              <JarvisButton
                disableEndIcon
                loading={this.state.loading}
                disabled={stockListHasError || !tableData.length}
                sx={{ boxShadow: "none", width: { xs: "100%", md: "unset" } }}
                onClick={() => this.handleConfirmPortfolio()}
              >
                Continue
              </JarvisButton>
            </Stack>
          </Stack>
        )}

        {/* NOTE: edit portfolio name modal */}
        <Modal
          size={"sm"}
          title={<SectionTitle heading={this.state.editPortfolio} />}
          open={this.state.editPortfolioNameModalOpen}
          onClose={() => this.setState({ editPortfolioNameModalOpen: false, formError: { name: '' } })}
          sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
        >
          <RMSPortfolioSyncEditName
            formData={this.state.formData}
            formError={this.state.formError}
            onChange={this.handleEditPortfolioChange}
            onSubmit={this.handleSubmitEditPortfolioName}
            onClose={this.handleClose}
          />
        </Modal>

        {/* NOTE: delete portfolio modal */}
        <Modal
          size={"xs"}
          title={<SectionTitle heading={this.state.deletePortfolioText} />}
          open={this.state.removePortfolioModalOpen}
          disableClose
          sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
        >
          <RMSPortfolioSyncDelete
            submitBtnText="Delete"
            onSubmit={this.handleDeletePortfolio}
            onClose={() => this.toggelDeletePortfolioModalOpen()}
          />
        </Modal>

        {/* NOTE: add/edit stock details modal */}
        <Modal
          size={"md"}
          title={
            <SectionTitle
              heading={this.state.actionType === ACTION_TYPES.EDIT ? this.state.editStockText : this.state.addStockText}
            />
          }
          open={this.state.addStockModalOpen}
          onClose={() => this.onClose()}
          sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
        >
          <RMSPortfolioSyncAddStock
            type="select"
            actionType={this.state.actionType}
            stockId={this.state.stockData}
            stockList={this.formatStockListData(this.state.masterStockListData)}
            getData={(data) => this.getSelectedValue(data)}
            formData={this.state.addStockForm}
            formError={this.state.formError}
            onSubmit={() => this.handleAddEditStockSubmit()}
            // onClose={() => this.onClose()}
            onChange={this.handleAddStockFormChange}
          />
        </Modal>

        {/* NOTE: confirm delete stock modal */}
        <Modal
          size={"xs"}
          title={<SectionTitle heading={this.state.removeStockText} />}
          open={this.state.removeStockModalOpen}
          disableClose
          sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
        >
          <RMSPortfolioSyncRemoveStock
            onSubmit={() => this.handleRemoveStock()}
            onClose={() => this.handleDeleteStockClose()}
          />
        </Modal>

        <JarvisDialog
          disableCloseIcon
          // maxWidth="xs"
          open={this.state.isSuccessDialogOpen}
          content={this.state.apiSucessText}
          icon={{
            src: successMessage.icon,
            height: 120,
            width: 120,
          }}
          button={{
            disableEndIcon: true,
          }}
        />
      </JarvisCard>
    )
  }
}

const style = (theme) => ({
  tableBodyCell: {
    borderBottom: "none !important",
  },
  root: {
    boxShadow: "none",
    border: '0px',
    "& .MuiInputBase-input": {
      padding: '12px 7px !important',
      boxShadow: "none !important",
      backgroundColor: "#db709300!important",
    },

    "& .MuiInputBase-root": {
      backgroundColor: " #F5F6F8 !important",
      border: '0px',

      "& .MuiInputAdornment-root": {
        marginTop: '0px !important',
      },
    },
  },

  horizonIcon: {
    color: "#A2A7AE",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
      textAlign: "right",
      width: "15px",
      transform: "rotate(90deg) scale(2)"
    }
  },
  gain: {
    color: "#45AC70",
  },
  paginationRoot: {
    overflow:"hidden",
    height: "100%",
  }
});

export default withStyles(style, { withTheme: true })(withRouter(RMSConfirmPortfolioContainer)); 
