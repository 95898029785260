import React from 'react';
import OnestockPaymentContainer from 'containers/JarvisOnestock/OnestockPayment';

function OnestockPayment() {
    return ( 
    <>
    <OnestockPaymentContainer />
    </> 
    );
}

export default OnestockPayment;