import { call, put, takeLatest } from "@redux-saga/core/effects";

import { jarvisOnestockExistingHoldingConstants } from "./jarvisOnestockExistingHoldingConstants";
import { jarvisOnestockExistingHoldingParser } from "./jarvisOnestockExistingHoldingParser"
import { jarvisOnestockExistingHoldingApis } from "./jarvisOnestockExistingHoldingApis";

function* existingHoldingListSaga() {
    try {
        yield put({ type: jarvisOnestockExistingHoldingConstants.ONESTOCK_EXISTING_HOLDING_REQUEST });
        const res = yield call(jarvisOnestockExistingHoldingApis.getExistingHoldingApi);
        const payload = jarvisOnestockExistingHoldingParser.existingHoldingList(res);
        yield put({
            type: jarvisOnestockExistingHoldingConstants.ONESTOCK_EXISTING_HOLDING_RESPONSE,
            payload,
        });
    } catch (error) {
        yield put({
            type: jarvisOnestockExistingHoldingConstants.ONESTOCK_EXISTING_HOLDING_RESPONSE,
            payload: { data: [], totalRecords: 0 },
        });
    }
}


export function* registerJarvisOnestockExistingHoldingSagas() {

    yield takeLatest(
        jarvisOnestockExistingHoldingConstants.ONESTOCK_EXISTING_HOLDING_SAGA,
        existingHoldingListSaga
    );

}
