import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { config } from "config/index"
import _get from "lodash.get";
import { customerActions } from "stores/customer/customerActions"
import { masterActions } from "stores/master/masterActions";
import { commonActions } from "stores/common/commonActions"
import { dashboardActions } from "stores/dashboard/dashboardActions"

const mapStateToProps = (state) => {
    return {
        loginProfile: state.customer.loginProfile,
        profilePic: state.customer.profile.profilePic,
        userName: state.customer.loginProfile.name,
        isOnboardingCompleted: state.onboarding.isOnboardingCompleted,
        RMSisOnboardingCompleted: state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
        activeProductList: _get(state.customer, 'loginProfile.activeProductList', [config.productCodes.PORTFOLIO, config.productCodes.RMS]),
        pendingCallList: state.master.pendingCall,
        isPendingCallListLoading: state.master.loading.pendingCall,
        isPendingListInitialCalled: state.master.initialCalled.pendingCall,
        portfolioAlert: state.dashboard.portfolioAlert,
        // verification
        emailId: state.customer.loginProfile.email,
        mobileNumber: state.customer.loginProfile.mobile,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logoutAction: customerActions.logout,
    getPortfolioPendingTransactionAction: dashboardActions.getPortfolioPendingTransaction,
    pendingCallAction: masterActions.getPendingCall,
    updateEmailAction: customerActions.updateEmail,
    setToast: commonActions.setToast,
}, dispatch)

const LayoutStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default LayoutStore;