import { axios } from "helper/axios";
import { helpSupportConstants } from "./helpSupportConstants";

const helpSupportApis = {};

helpSupportApis.categoryList = () => {
    return axios.get(helpSupportConstants.CATEGORY_LIST_API);
};

helpSupportApis.categoryData = ({ categoryID }) => {
    return axios.get(
        helpSupportConstants.CATEGORY_DATA_API.replace(":id", categoryID)
    );
};

helpSupportApis.categoryQuestions = ({ selectedCategoryID, questionID }) => {
    let str = helpSupportConstants.CATEGORY_QUESTIONS_API.replace(
        ":categoryId",
        selectedCategoryID
    );
    str = str.replace(":id", questionID);

    return axios.get(str);
};

export { helpSupportApis };
