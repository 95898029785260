import React from "react";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Grid from "components/common/Grid";
import Text from "components/common/Text";
import Stack from "components/common/Stack";

import DashboardDataCard from "components/Dashboard/DashboardDataCard";

import { formatHelper } from "helper/format";

class StatsContainer extends React.Component {
  componentDidMount() {
    this.props.getPortolioOverviewAction();
  }
  render() {
    const {
      portfolioOverviewData,
      portfolioOverviewLoading,
      halfWidth = false,
    } = this.props;


    return (
      <>
        <Grid container spacing={2} mr={0} width={halfWidth ? "100%" : "unset"}>
          {/* 1: Current value */}
          <Grid item xs={6} sm={5} md={halfWidth ? 5 : 3} xl={halfWidth ? 6 : 3}>
            <DashboardDataCard
            visibleIcon={true}
              sx={{
                borderRadius: { sm: "10px 0 0 10px !important" },
                borderRight: { sm: "0 !important" },
              }}
              icon={"/svgs/current_value.svg"}
              loading={portfolioOverviewLoading}
              value={formatHelper.currency(portfolioOverviewData.currentValue)}
              label="Current value"
              color="#E99514"
            />
          </Grid>

          {/* 2: Gain/ Loss */}
          <Grid item xs={12} sm={7} md={halfWidth ? 7 : 3} xl={halfWidth ? 6 : 3} 
            order={{ xs: "-1", sm: 0 }}
             pl={halfWidth?null:{ sm: "0px !important" }}
          >
            {portfolioOverviewData.profit < 0 ? (
              <DashboardDataCard
               visibleIcon={true}

                sx={{
                  borderRadius: { sm: "0 10px 10px 0 !important" },
                  borderLeft: { sm: "0px !important" },
                }}
                icon={"/svgs/gain_loss_red.svg"}
                loading={portfolioOverviewLoading}
                value={
                  <Stack direction="row" alignItems="center">
                    {formatHelper.currency(
                      Math.abs(portfolioOverviewData.profit)
                    ) || 0}

                    <Text
                      pl={3}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <ArrowDropDownIcon />
                      {(Math.abs(portfolioOverviewData.profitPercentage) || 0) + "%"}
                    </Text>
                  </Stack>
                }
                gainLoss={
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ display: { xs: "none" } }}
                  >
                    <ArrowDropDownIcon viewBox="1 5 18 15" fontSize="small" />
                    {(Math.abs(portfolioOverviewData.profitPercentage) || 0 ) + "%"}
                  </Stack>
                }
                label="Gain/Loss"
                color="#FF2D2D"
                hasInfoIcon
              />
            ) : (
              <DashboardDataCard
                sx={{
                  borderRadius: { sm: "0 10px 10px 0 !important" },
                  borderLeft: { sm: "0px !important" },
                }}
                visibleIcon={true}
                icon={"/svgs/gain_loss_green.svg"}
                loading={portfolioOverviewLoading}
                value={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {formatHelper.currency(portfolioOverviewData.profit, {
                      config: {
                        maximumFractionDigits: 2,
                      },
                    })}

                    <Text
                      pl={1}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <ArrowDropUpIcon />
                      {(Math.abs(portfolioOverviewData.profitPercentage) || 0 )+ "%"}
                    </Text>
                  </Stack>
                }
                gainLoss={
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ display: { xs: "none" } }}
                  >
                    <ArrowDropUpIcon viewBox="1 5 18 15" fontSize="small" />
                    {(Math.abs(portfolioOverviewData.profitPercentage) || 0) + "%"}
                  </Stack>
                }
                label="Gain/Loss"
                color="#45AC70"
                hasInfoIcon
              />
            )}
          </Grid>

          {/* 3: Invested */}
          <Grid item xs={6} sm={4}  md={halfWidth ? 4 : 2}>
            <DashboardDataCard
              visibleIcon={true}
              icon={"/svgs/rupee_icon.svg"}
              loading={portfolioOverviewLoading}
              value={formatHelper.currency(
                portfolioOverviewData.totalInvestment
              )}
              label="Invested"
              color="#1D9BFF"
            />
          </Grid>

          {/* 4: Holding value */}
          <Grid item xs={6} sm={4}  md={halfWidth ? 4 : 2}>
            <DashboardDataCard
              visibleIcon={true}
              icon={"/svgs/cash_value.svg"}
              loading={portfolioOverviewLoading}
              value={formatHelper.currency(portfolioOverviewData.holdingValue)}
              label="Holding Value"
              color="#1D9BFF"
            />
          </Grid>

          {/* 5: Cash Value */}
          <Grid item xs={6} sm={4}  md={halfWidth ? 4 : 2}>
            <DashboardDataCard
              visibleIcon={true}
              icon={"/svgs/cash_icon.svg"}
              loading={portfolioOverviewLoading}
              value={formatHelper.currency(portfolioOverviewData.cash)}
              label="Cash Value"
              color="#3A63D9"
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default StatsContainer;
