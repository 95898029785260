import { axios } from "helper/axios";
import { onboardingConstants } from "./onboardingConstants";

const onboardingApis = {};


onboardingApis.sendEmailVerificationLink = ({ actionType, email }) => {
  return axios.post(onboardingConstants.SEND_EMAIL_VERIFICATION_API, { actionType, email });
};

onboardingApis.getOnboardingData = () => {
  return axios.get(onboardingConstants.ONBOARDING_DATA_API);
};

onboardingApis.submitRiskAssessmentQuestions = ({ questionAnswers }) => {
  return axios.post(onboardingConstants.SUBMIT_RISK_ASSESSMENT_QUESTION_API, {
    questionAnswers,
  });
};

onboardingApis.submitRiskProfile = ({
  riskManuallyChecked,
  riskId,
  suggestedRisk,
  productCode
}) => {
  return axios.post(onboardingConstants.SUBMIT_RISK_PROFILE_API, {
    riskManuallyChecked,
    riskId, 
    suggestedRisk,
    productCode
  });
};

onboardingApis.submitInvestment = ({
  investmentAmount,
  investmentTenure,
  investmentStrategy,
  planId,
}) => {
  return axios.post(onboardingConstants.SUBMIT_INVESTMENT_API, {
    investmentAmount,
    investmentTenure,
    investmentStrategy,
    planId
  });
};

onboardingApis.getPortfolioData = () => {
  return axios.get(onboardingConstants.PORTFOLIO_DATA_API);
};

onboardingApis.getPricingData = ({ couponCode = "" } = {}) => {
  return axios.get(onboardingConstants.PRICING_DATA_API, {
      params: { couponCode },
  });
};

onboardingApis.verifyKYCData = ({
  customerId,
  dateOfBirth,
  inputIdType,
  inputIdNumber,
}) => {
  return axios.post(onboardingConstants.VERIFY_KYC_API, {
    customerId,
    dateOfBirth,
    inputIdType,
    inputIdNo: inputIdNumber,
  });
};

onboardingApis.uploadKYCImages = ({
  customerId,
  typeKey,
  file,
  fileName,
}) => {
  const fd = new FormData();

  fd.append("customerId", customerId);
  fd.append(typeKey, file, fileName);

  return axios.post(onboardingConstants.KYC_IMAGE_UPLOAD_API, fd);
};

onboardingApis.reVerifyKYCData = ({
    customerId,
    panCardNumber,
    aadharCardNumber,
    dateOfBirth,
}) => {
    const body = {
        customerId,
        panCardNumber,
        aadharCardNumber,
        dateOfBirth
    }
    return axios.post(onboardingConstants.REVERIFY_KYC_API ,body);
}

onboardingApis.confirmInvestmentInfo = ({
  riskProfileId,
  investmentStrategy,
  investmentHorizon,
  investmentAmount,
  planId,
}) => {
  return axios.post(onboardingConstants.CONFIRM_INVESTMENT_INFO_API, {
    riskProfileId,
    investmentStrategy,
    investmentHorizon,
    investmentAmount,
    planId,
  })
}

export { onboardingApis };
