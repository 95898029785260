import React, { useEffect } from "react";
import MChip from "@mui/material/Chip";
import { makeStyles, createStyles } from "@mui/styles";

import cx from "classnames";

// NOTE: default style should be here other it should be passed from the parant component.
const useStyles = makeStyles((theme) =>
    createStyles({
        portfolioTab: {
            background: "#FFFFFF",
            border: "1px solid #DEDEDE",
            borderRadius: "20px",
            padding: "3px 4px",
            fontSize: "15px",
            color: "#A2A7AE",
            height: "auto"
        },
        portfolioMobileTab: {
            ackground: "#FFFFFF",
            border: "1px solid #DEDEDE",
            borderRadius: "20px",
            padding: "6px 8px",
            fontSize: "14px",
            color: "#A2A7AE",
            height: "auto"
        },
        active: {
            backgroundColor: "#2B70C51A",
            color: `${theme.palette.primary.main} !important`,
            fontWeight: "500",
            borderColor: `${theme.palette.primary.main} !important`,
        },
    })
);

const JarvisChip = (props) => {
    const { data, isActive, handleChipClick, mobileChip = false } = props;
    const classes = useStyles();

    useEffect(() => {
    }, [])

    return (
        <>
            <MChip
                className={cx(
                    { [classes.portfolioTab]: !mobileChip },
                    { [classes.portfolioMobileTab]: mobileChip },
                    { [classes.active]: isActive }
                )}
                label={data.portfolioName}
                variant="outlined"
                onClick={() => handleChipClick(data)}
            // onClick={()=> handlePortfolioClick}
            />
        </>
    );
};

export default JarvisChip;
