import React from "react";

import { config } from "config";

import Grid from "components/common/Grid";
import Box from "components/common/Box";
import SignUpForm from "components/Auth/SignUpForm";
import AuthAsidePanel from "components/Auth/AuthAsidePanel";
import { validationHelper } from "helper/validation";
import { customerApis } from "stores/customer/customerApis";
import { customerParsers } from "stores/customer/customerParsers";
import { localStore } from "stores/localStorage";
import { tracker } from "library/tracker";

const otpRequestTimer = 30;
export class SignUpContainer extends React.Component {
  emailInterval = 0;
  mobileInterval = 0;

  constructor(props) {
    super(props);
    this.state = {
      formManage: {
        isRequestOtp: false,
        emailVerified: false,
        mobileVerified: false,
      },
      formData: {
        requestId: "",
        name: "",
        email: "",
        mobile: "",
        password: "",
        emailOTP: "",
        mobileOTP: "",
        loginType: "normal",
        deviceType: "Web",
      },
      formError: {
        name: "",
        email: "",
        mobile: "",
        password: "",
        emailOTP: "",
        mobileOTP: "",
      },
      emailResendTime: 0,
      mobileResendTime: 0,
      isLoading: false,
    };
  }

  componentWillUnmount = () => {
    clearInterval(this.emailInterval);
    clearInterval(this.mobileInterval);
  };

  componentDidMount() {
    this.setIntercomPosition();
  }

  setIntercomPosition = () => {
    // / set intercom position based on tab
    let intercomElem;
    intercomElem = document.getElementsByClassName("intercom-launcher")[0];
    if (!intercomElem) {
      intercomElem = document.getElementsByClassName("intercom-launcher-frame")[0];
    }
    if (!intercomElem) {
      intercomElem = document.getElementsByClassName("intercom-lightweight-app-launcher")[0];
    }

    if (!intercomElem) {
      console.log("intercom element not found");
    }
    else {
      intercomElem.classList.add("intercom-launcher-noTab");
      intercomElem.classList.remove("intercom-launcher-hasTab");
    }
  }

  handleBackToStep1 = () => {
    let { formData, formManage, emailResendTime, mobileResendTime, isAgreedConcentCheck, isAgreedTncCheck } =
      this.state;
    formData.requestId = "";
    formData.emailOTP = "";
    formData.mobileOTP = "";
    formManage.isRequestOtp = false;
    formManage.emailVerified = false;
    formManage.mobileVerified = false;
    emailResendTime = 0;
    mobileResendTime = 0;

    this.setState({ formData, formManage, emailResendTime, mobileResendTime,});
    clearInterval(this.emailInterval);
    clearInterval(this.mobileInterval);
  };

  handleInputChange = (name, value) => {
    if (name === "mobile" && isNaN(value)){
      return;
    }
    if(name === "name"){
      value = value ? value.trimStart() : '' ;
    }
    const { formData } = this.state;
  
    formData[name] = value;
    this.setState({ formData }, () => {
      // verfy email number
      if (name === "emailOTP" && value.length === 6) {
      // NOTE: event_tracker => registration 
      tracker.registration.regEnterEmailOtp({ 
        app_reg_id: formData.requestId,
      });
        // this.verifyOtp("email");
      }
      // verfy mobile number
      if (name === "mobileOTP" && value.length === 6) {
      // NOTE: event_tracker => registration 
       tracker.registration.regEnterMobileOtp({ 
        app_reg_id: formData.requestId,
      });
        // this.verifyOtp("mobile");
      }
    });
  };

  handleValidation = () => {
    const { formError, formData } = this.state;

    const nameValidation = validationHelper.name(formData.name);
    formError.name = nameValidation.message;

    const emailValidation = validationHelper.email(formData.email);
    formError.email = emailValidation.message;

    // NOTE: password based validtion is not being used.
    // const passwordValidation = validationHelper.password(formData.password);
    // formError.password = passwordValidation.message;

    const mobileValidation = validationHelper.mobile(formData.mobile);
    formError.mobile = mobileValidation.message;

    this.setState({ formError });
    return (
      nameValidation.isValid &&
      emailValidation.isValid &&
      mobileValidation.isValid
    );
  };


  // Stp-1: Continue
  /*
    NOTE: Flow
    - log Tracker: for continue click
    - Validate: name, email, mobile
    - Api hit: request OTP
    - set State: requestId 
    - Api hit: Submit Campaign Data
    - log Traker: for successfully submit.
  */
  handleRequestOtp = async () => {
    const { formData, formManage } = this.state;
    // NOTE: event_tracker => registration
    tracker.registration.regRequestOtp({ 
      platform: config.platform, 
      deviceId: this.props.deviceId,
      name: formData.name,
    });
    
    if (!this.handleValidation()) {
      return;
    }

    this.setState({ isLoading: true });

    // get gioLocation
    // const gioLoc = await localStore.getGeolocation();

    try {

      const res = await customerApis.initSignUp({
        deviceId: this.props.deviceId,
        deviceType: formData.deviceType,
        mobileNumber: formData.mobile,
        email: formData.email,
        name: formData.name
      });
      const parsedRes = customerParsers.requestOtp(res);

      // const res1 = await customerApis.requestOtp({
      //   mobile: formData.mobile,
      //   email: formData.email,
      //   loginType: formData.loginType,
      //   name: formData.name,
      //   latitude: gioLoc ? gioLoc.latitude : null,
      //   longitude: gioLoc ? gioLoc.longitude : null,
      // });

      if (parsedRes && parsedRes.requestId) {
        await this.submitCampaignData({ email: formData.email });

        // NOTE: event_tracker => registration 
        tracker.registration.regRequestOtpSuccess({ 
          app_reg_id: parsedRes.requestId,
          platform: config.platform,
          deviceId: this.props.deviceId,
          name: formData.name,
        });

        // state update
        formData.requestId = parsedRes.requestId;
        formManage.isRequestOtp = true;
        this.setState({ formData, formManage }, () => {
          // this.emailResendTimer();
          this.mobileResendTimer();
        });

      }
    } catch (error) {
       // NOTE: event_tracker => registration 
       tracker.registration.regRequestOtpError({ 
        app_reg_id: formData.requestId,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };


  // Stp-2: Confirm OTP
  /*
    NOTE: Flow
    - log Tracker: for confirm OTP click.
    - Validate: fields.
    - get Geo location for Lat Long.
    - Api Hit: signup.
    - Parse: signup data.
    - log Traker: for successfully submit.
    - LocaStore: Set auth token.
    - Action: update onboarding data.
  */
  handleFormSubmit = async () => {
    const { formData } = this.state;
    // NOTE: event_tracker => registration 
    tracker.registration.signUp({ 
      app_reg_id: formData.requestId,
    });
    
    if (!this.handleValidation()) {
      return;
    }

    this.setState({ isLoading: true });
    
    try {
      formData.deviceType = config.platform;
      formData.deviceId = this.props.deviceId;

      // get gioLocation
      const gioLoc = await localStore.getGeolocation();

      const res = await customerApis.completeSignup({
        deviceId: this.props.deviceId,
        deviceType: formData.deviceType,
        requestId: formData.requestId,
        otp: formData.mobileOTP,
        signupType: "normal",
        latitude: gioLoc ? gioLoc.latitude : null,
        longitude: gioLoc ? gioLoc.longitude : null,
      });

      // const res1 = await customerApis.signUp({
      //   requestId: formData.requestId,
      //   name: formData.name,
      //   password: formData.password,
      //   loginType: formData.loginType,
      //   deviceId: this.props.deviceId,
      //   deviceType: formData.deviceType,
      // });
      const result = customerParsers.login(res);

      // NOTE: event_tracker => registration 
      tracker.registration.signUpSuccess({ 
        name: formData.name,
        loginType: formData.loginType,
        deviceId: this.props.deviceId,
        deviceType: formData.deviceType,
        customerId: result.customerId,
      });

      localStore.setToken(result.token);
      localStore.setLegacyToken(result.legacyToken);
      if (result.lastActiveProduct) {
        localStore.setActiveProduct(result.lastActiveProduct);
      }

      this.props.updateOnestockOnboardingDataAction(result.onestockOnboardingData)
      this.props.updateOnboardingDataAction(result.onboardingData);
      this.props.updateAuthDataAction(result);

    } catch (e) {
      tracker.registration.signUpError({ 
        user_id: formData.requestId,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  submitCampaignData = async ({ email }) => {
    const { utm_source, utm_campaign, utm_coupon, utm_product, utm_lead_id, restEventData = {} } = localStore.getAppsflyerData() || {};
    if (!utm_source || !utm_campaign) {
      return;
    }
    await customerApis.submitCampaignData({ email, trackingInfo: { utm_source, utm_campaign, utm_coupon, utm_product, utm_lead_id, raw_json: {...restEventData} } });
    localStore.resetAppsflyerData()
  }

  verifyOtp = async (type) => {
    if (!["email", "mobile"].includes(type)) {
      return;
    }

    const { formData, formManage } = this.state;
    this.setState({ isLoading: true });

    try {
      await customerApis.verifyOtp({
        requestId: formData.requestId,
        userName: formData.name,
        otp: type === "email" ? formData.emailOTP : formData.mobileOTP,
        type: type,
      });
      if (type === "email") {
      // NOTE: event_tracker => registration 
       tracker.registration.regEnterEmailOtpSuccess({ 
        app_reg_id: formData.requestId,
      });
        formManage.emailVerified = true;
      }
      if (type === "mobile") {
      // NOTE: event_tracker => registration 
       tracker.registration.regEnterMobileOtpSuccess({ 
        app_reg_id: formData.requestId,
      });
        formManage.mobileVerified = true;
      }
      this.setState({ formManage });
    } catch (e) {
       // NOTE: event_tracker => registration 
       tracker.registration.regEnterEmailOtpError({ 
        app_reg_id: formData.requestId,
        error_msg: e.message,
      });
       // NOTE: event_tracker => registration 
       tracker.registration.regEnterMobileOtpFailed({ 
        app_reg_id: formData.requestId,
        error_msg: e.message,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  emailResendTimer = () => {
    const { emailResendTime } = this.state;
    if (emailResendTime > 0) {
      return;
    }
    this.setState({ emailResendTime: otpRequestTimer });

    this.emailInterval = setInterval(() => {
      const { emailResendTime } = this.state;
      let time = emailResendTime - 1;
      this.setState({ emailResendTime: time });
      time === 0 && clearInterval(this.emailInterval);
    }, 1000);
  };

  mobileResendTimer = () => {
    const { mobileResendTime } = this.state;
    if (mobileResendTime > 0) {
      return;
    }

    this.setState({ mobileResendTime: otpRequestTimer });
    this.mobileInterval = setInterval(() => {
      const { mobileResendTime } = this.state;
      let time = mobileResendTime - 1;
      this.setState({ mobileResendTime: time });
      time === 0 && clearInterval(this.mobileInterval);
    }, 1000);
  };

  handleResendOtp = async (type) => {
    if (
      !["email", "mobile"].includes(type) ||
      !this.state.formData.requestId
    ) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      const { formData } = this.state;
      const res = await customerApis.requestSignupOTP({
        requestId: this.state.formData.requestId,
        requestType: type,
      });
      const parsedRes = customerParsers.requestOtp(res);
      formData.requestId = parsedRes.requestId;
      this.setState({ formData });
      type === "email" ? this.emailResendTimer() : this.mobileResendTimer();
    } catch (e) {
    } finally {
      this.setState({ isLoading: false });
    }
  };



  render() {
    return (
      <Grid container>
        <Grid item md={6.5} lg={6.5} sx={{ display: { xs: "none", md: "block" } }}>
          <AuthAsidePanel />
        </Grid>
        <Grid item md={5.5} lg={5.5} sx={{ flex: 1 }}>
          <Box
            sx={{
              minHeight: "100vh",
              marginBottom: "80px",
            }}
          >
              <SignUpForm
              onChange={this.handleInputChange}
              onSubmit={this.handleFormSubmit}
              onRequestOtp={this.handleRequestOtp}
              onResendOtp={this.handleResendOtp}
              onBackToStep1={this.handleBackToStep1}
              formData={this.state.formData}
              formError={this.state.formError}
              formManage={this.state.formManage}
              emailResendTime={this.state.emailResendTime}
              mobileResendTime={this.state.mobileResendTime}
              isLoading={this.state.isLoading}
              loginUrl="/login"
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default SignUpContainer;
