import React, { Component } from 'react';

import JarvisCard from "components/JarvisCard";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import { formatHelper } from "helper/format";

let body = { productCode: "PORTFOLIO", }

class PortfolioHoldingContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.getPortfolioHoldingListActions(body);
    }

    render() {
        let { portfolioOverviewData } = this.props;
        return (
            <>

                <JarvisCard sx={{ border: '1px solid #E2E2E2', padding: "14px 12px", marginBottom: "16px" }} >
                    <Stack direction="row" justifyContent="space-between">
                        <Stack>
                            <Text variant="caption1" color="#A2A7AE">Invested</Text>
                            <Text variant="small">{ formatHelper.currency(portfolioOverviewData?.totalInvestment)}</Text>
                        </Stack>
                        <Stack>
                            <Text variant="caption1" color="#A2A7AE">Current value</Text>
                            <Text variant="small">{formatHelper.currency(portfolioOverviewData?.currentValue)}</Text>
                        </Stack>
                        <Stack>
                            <Text variant="caption1" color="#A2A7AE">Total return</Text>
                            <Text variant="small">{portfolioOverviewData?.profitPercentage} {portfolioOverviewData?.profitPercentage ? "%": ""}</Text>
                        </Stack>
                    </Stack>

                </JarvisCard>


                <Text sx={{ marginBottom: "16px", fontWeight: 500 }}>Stock Allocation</Text>
                {this.props.portfolioHoldingList.map((item) => (
                    <JarvisCard mb={1} sx={{ border: "1px solid #E2E2E2", padding: '14px 16px', mb:"5px" }}>
                        <Stack direction="row" mb={1} justifyContent="space-between">
                            <Text color={"#A2A7AE"} variant="caption1">Qty.<span style={{ color: "#303236" }}>{item.stockQuantity}</span></Text>
                            <Text color={!item.stockChangeType ? "#A2A7AE " : item.stockChangeType === 'GAIN' ? '#45AC70' : "#D44C4C"} variant="caption1">{formatHelper.currency(item.stockCurrentPrice)}({item.stockChangePercentage}%)</Text>
                        </Stack>
                        <Stack direction="row" mb={1} justifyContent="space-between">
                            <Text color={"#212225"} variant="small" sx={{ fontWeight: 500 }}>{item.stockName}</Text>
                            <Text color={"#212225"} variant="small" sx={{ fontWeight: 500 }}>{formatHelper.currency(item.stockBuyPrice)}</Text>
                        </Stack>
                        <Stack direction="row" mb={item.stockErrorMessage ? 2 : ''} justifyContent="space-between">
                            <Text color={"#A2A7AE"} variant="caption1">Current Value <span style={{ color: "#303236" }}>{formatHelper.currency(item.stockCurrentValue)}</span></Text>
                            <Text color={"#A2A7AE"} variant="caption1">Total Invested <span style={{ color: "#303236" }}>{formatHelper.currency(item.stockBuyValue)}</span></Text>
                        </Stack>
                  </JarvisCard>
                ))}


            </>
        );
    }
}

export default PortfolioHoldingContainer;