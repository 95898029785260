import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { masterConstants } from "./masterConstants";

const initialState = {
    riskAssessmentQuestions: [],
    riskProfiles: [],
    brokers: [],
    // onestockBrokers: [],
    pendingCall: {
        isMobileVerified:null,
        isEmailVerified:null,
        portfolioData:{},
        protectData:{},
        onestockData:{}
    },
    loading: {
        riskAssessmentQuestions: 0,
        riskProfiles: 0,
        brokers: 0,
        // onestockBrokers: 0,
        pendingCall: 0,
    },
    initialCalled: {
        riskAssessmentQuestions: 0,
        riskProfiles: 0,
        brokers: 0,
        // onestockBrokers: 0,
        pendingCall: 0,
    },
};

export const masterReducers = persistReducer(
    {
        storage,
        key: "master",
        whitelist: [],
    },

    (state = initialState, { type, payload }) => {
        switch (type) {
            case masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskAssessmentQuestions: 1,
                    },
                    riskAssessmentQuestions:
                        initialState.riskAssessmentQuestions,
                };
            }

            case masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskAssessmentQuestions: 0,
                    },
                    riskAssessmentQuestions: payload,
                };
            }

            case masterConstants.RMS_RISK_PROFILES_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskProfiles: 1,
                    },
                    riskProfiles: initialState.riskProfiles,
                };
            }

            case masterConstants.RMS_RISK_PROFILES_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskProfiles: 0,
                    },
                    riskProfiles: payload,
                };
            }

            case masterConstants.MASTER_BROKER_REQUEST: {
                return {
                    ...state,
                    brokers: initialState.brokers,
                    loading: {
                        ...state.loading,
                        brokers: 1
                    }
                }
            }

            case masterConstants.MASTER_BROKER_RESPONSE: {
                return {
                    ...state,
                    brokers: payload,
                    loading: {
                        ...state.loading,
                        brokers: 0,
                    },
                    initialCalled: {
                        ...state.initialCalled,
                        brokers: 1,
                    }
                }
            }

            // case masterConstants.ONESTOCK_MASTER_BROKER_REQUEST: {
            //     return {
            //         ...state,
            //         onestockBrokers: initialState.onestockBrokers,
            //         loading: {
            //             ...state.loading,
            //             onestockBrokers: 1
            //         }
            //     }
            // }

            // case masterConstants.ONESTOCK_MASTER_BROKER_RESPONSE: {
            //     return {
            //         ...state,
            //         onestockBrokers: payload,
            //         loading: {
            //             ...state.loading,
            //             onestockBrokers: 0,
            //         },
            //         initialCalled: {
            //             ...state.initialCalled,
            //             onestockBrokers: 1,
            //         }
            //     }
            // }

            case masterConstants.PENDING_CALL_REQUEST: {
                return {
                    ...state,
                    pendingCall: initialState.pendingCall,
                    loading: {
                        ...state.loading,
                        pendingCall: 1
                    }
                }
            }

            case masterConstants.PENDING_CALL_RESPONSE: {
                return {
                    ...state,
                    pendingCall: payload,
                    loading: {
                        ...state.loading,
                        pendingCall: 0,
                    },
                    initialCalled: {
                        ...state.initialCalled,
                        pendingCall: 1,
                    }
                }
            }

            default:
                return state;
        }
    }
);
