import React from "react";

import Modal from "components/common/Modal";
import Box from "components/common/Box";

import RiskProfileCard from "components/Profile/RiskProfileCard";
import RiskProfileForm from "components/Profile/RiskProfileForm";
import ConfirmTakeActionDialog from "components/Profile/ConfirmTakeActionDialog";

import { customerApis } from "stores/customer/customerApis";
import { withRouter } from "react-router-dom";
import { tracker } from "library/tracker";
import { localStore } from "stores/localStorage";


class RiskProfileContainer extends React.Component {

  componentDidMount() {
    if (!this.props.selectedRiskProfile && this.props.isProfileLoading) {
      this.props.getProfileAction();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      isRiskProfileSetFromProps: false,
      riskProfileFormModalOpen: false,
      isFormLoading: false,

      currentRiskProfileId: null,
      currentRiskProfileName: null,

      afterUpdateProfile: {
        dialogOpen: false,
        isRebalanceGenerated: false,
        resposeMsg: "",
      },
    };
  }

  componentDidUpdate = () => {
    const { isRiskProfileSetFromProps } = this.state;
    const { selectedRiskProfileId, selectedRiskProfileName } = this.props;

    if (!this.state.currentRiskProfileId && !isRiskProfileSetFromProps) {
      this.setState((s, p) => ({
        isRiskProfileSetFromProps: true,
        currentRiskProfileId: selectedRiskProfileId,
        currentRiskProfileName: selectedRiskProfileName,
      }));
    }
  };

  toggelRiskProfileFormOpen = () => {
    const { riskProfileFormModalOpen } = this.state;
    this.setState({ riskProfileFormModalOpen: !riskProfileFormModalOpen });
  };

  toggelActionDialog = () => {
    const { afterUpdateProfile } = this.state;
    afterUpdateProfile.dialogOpen = !afterUpdateProfile.dialogOpen;
    this.setState({ afterUpdateProfile });
  };

  handleRiskProfileChange = (onClose) => {
    if (!this.props.riskProfileList.length) {
      this.props.getRiskProfilesAction();
    }
    this.setState({
      currentRiskProfileId: this.props.selectedRiskProfileId,
      currentRiskProfileName: this.props.selectedRiskProfileName,
    });

    //event-tracker: Risk profile -> rp_continue
    tracker.riskProfile.rpContinue();

    this.toggelRiskProfileFormOpen();
    onClose();
  };

  setRiskProfile = ({ id, name }) => {
    this.setState({
      currentRiskProfileId: id,
      currentRiskProfileName: name,
    });
  };

  handleSubmit = async () => {
    this.setState({ isFormLoading: true });
    try {
      const body = {
        riskId: this.state.currentRiskProfileId,
        riskManuallyChecked: 1,
      };
      const actionbody = {
        riskProfileId: this.state.currentRiskProfileId,
        riskProfile: this.state.currentRiskProfileName,
      };
      //event-tracker: Change Risk Profile(RP) -> confirm_this_risk_profile
      tracker.riskProfile.confirmThisRiskProfile();
      const res = await customerApis.updateRiskProfile(body);

      // NOTE: Mock responce to test
      // let res = {
      //   code: "JSR022",
      //   isRebalanceGenerated: "",
      //   message:"Risk Profile Updated Successfully",
      //   status: 200
      // }

      //event-tracker: Change Risk Profile(RP) -> confirm_this_risk_profile_success
      tracker.riskProfile.confirmHisRiskProfileSuccess();

      this.toggelRiskProfileFormOpen();

      let isRebalanceGenerated = false;
      if (res.isRebalanceGenerated || res.status == 200) {
        this.props.updateRiskProfilesAction(actionbody);
        isRebalanceGenerated = true;
      }
      
      const { afterUpdateProfile } = this.state;
      afterUpdateProfile.resposeMsg = res.message;
      afterUpdateProfile.isRebalanceGenerated = isRebalanceGenerated;

      this.setState({ afterUpdateProfile }, () => this.toggelActionDialog());
    } catch (e) {
      //event-tracker: Change Risk Profile(RP) -> confirm_this_risk_profile_error
      tracker.riskProfile.changeMyRiskProfileFailed({ error_msg: e.message });
    } finally {
      this.setState({ isFormLoading: false });
    }
  };

  redirectToActionCenter = () => {
    this.props.history.push("/customer/profile");
  };

  render() {
    const {
      afterUpdateProfile,
      riskProfileFormModalOpen,
      currentRiskProfileId,
      isFormLoading,
    } = this.state;
    const {
      selectedRiskProfileName,
      riskProfileList,
      isRiskProfileListLoading,
      selectedRiskProfileId,
    } = this.props;

    const isMobileView = localStore.getMobileView();

    return (
      <Box
        height="100%"
        sx={{
          border: "1px solid #DEDEDE",
          borderRadius: "6px"
        }}
      >
        <RiskProfileCard
          riskProfile={selectedRiskProfileName}
          onChangeClick={this.handleRiskProfileChange}
          selectedRiskProfileId={selectedRiskProfileId}
          mobileScreen={isMobileView}
        />

        <Modal
          title={"Change Risk Profile"}
          size={"md"}
          open={riskProfileFormModalOpen}
          onClose={() => this.toggelRiskProfileFormOpen()}
          sx={{ pt: "15px" }}
        >
          <RiskProfileForm
            riskProfileList={riskProfileList}
            currentRiskProfileId={currentRiskProfileId}
            setRiskProfile={this.setRiskProfile}
            isRiskProfileListLoading={isRiskProfileListLoading}
            loading={isFormLoading}
            onSubmit={this.handleSubmit}
          />
        </Modal>

        <ConfirmTakeActionDialog
          open={afterUpdateProfile.dialogOpen}
          message={afterUpdateProfile.resposeMsg}
          isRebalanceGenerated={afterUpdateProfile.isRebalanceGenerated}
          onContinue={this.redirectToActionCenter}
          onClose={this.toggelActionDialog}
        />
      </Box>
    );
  }
}

export default withRouter(RiskProfileContainer);
