import { all } from "redux-saga/effects";
import { registerjarvisRMSDashboardonboardedSaga } from "./jarvisRMSDashboard/jarvisRMSDashboardonboardedSaga";

import { registerJarvisRMSOnboardingSagas } from "./jarvisRMSOnboarding/jarvisRMSOnboardingSaga";
import { registerJarvisRMSExistingHoldingSagas } from "./jarvisRMSExistingHolding/jarvisRMSExistingHoldingSaga";
import { registerJarvisRMSTransactionSagas } from "./jarvisRMSTransaction/jarvisRMSTransactionSaga";

function* registerJarvisRMSRootSaga() {
    yield all([
        registerJarvisRMSOnboardingSagas(),
        registerJarvisRMSExistingHoldingSagas(),
        registerJarvisRMSTransactionSagas(),
        registerjarvisRMSDashboardonboardedSaga(),
    ]);
}

export { registerJarvisRMSRootSaga };
