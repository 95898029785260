import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";


const mapStateToProps = (state)=>{
    return {
        profile: state.customer.profile,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {

    },
    dispatch
);

const jarvisInfoStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default jarvisInfoStore;