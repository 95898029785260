import React from "react";
import { withStyles } from "@mui/styles";

import Table from "components/common/Table";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Pagination from "components/common/Pagination";
import Loader from "components/common/Loader";
import MobileResponsive from "components/common/MobileResponsive";

import PageTitle from "components/PageTitle";
import StockCard from "components/StockCard";

import { formatHelper } from "helper/format";
import { dateTimeHelper } from "helper/dateTime";

import AutorenewIcon from "@mui/icons-material/Autorenew";

class TransactionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 25,
      refreshTable: false,
    };
  }

  componentDidMount() {
    this.getTransactionList();
  }

  getTransactionList() {
    const { page, rowsPerPage } = this.state;
    this.props.getTransactionListAction({ page, rowsPerPage });
  }

  onPageChange(page, rowsPerPage) {
    this.setState({ page, rowsPerPage, refreshTable: false }, () =>
      this.getTransactionList()
    );
  }

  refreshData = () => {
    this.setState({ refreshTable: true });
  };

  render() {
    const {
      classes,
      transactionList,
      transactionCount,
      isLoading,
      transactionListApiCounter,
    } = this.props;


    const columns = [
      {
        field: "stockName",
        headerText: "Shares / Stocks",
        bodyCellComponent: (v, d) => {
          return (
            <>
              <Text variant="body1" color="#202020">
                {v}
              </Text>
              <Text variant="small" color="#808591">
                {d.shareCode}
              </Text>
            </>
          );
        },
      },
      { field: "stockSymbol", headerText: "Stock Symbol" },
      {
        field: "quantity",
        headerText: "Qty",
        bodyCellComponent: (v, r) => {
          return (
            <div style={{ minWidth: "60px" }}>
              <Text variant="small">{formatHelper.getQtyInSlash(r.completedActionableQty, v, true)}</Text>
            </div>
          );
        },
      },
      {
        field: "buyPrice",
        headerText: "Buy / Sell Price",
        bodyCellComponent: (v) =>
          <Text variant="small">{formatHelper.currency(v, {
            config: { maximumFractionDigits: 2 },
          })}</Text>,
      },
      {
        field: "totalBuyPrice",
        headerText: "Buy / Sell Value",
        bodyCellComponent: (v) =>
          <Text variant="small">{formatHelper.currency(v, {
            config: { maximumFractionDigits: 2 },
          })}</Text>,
      },
      {
        field: "transactionDate",
        headerText: "Date",
        bodyCellComponent: (v) => <Text sx={{ whiteSpace: "nowrap" }} variant="small">{dateTimeHelper.displayDate(v)}</Text>,
      },
      {
        field: "actionMsg",
        headerText: "Action Taken",
        bodyCellComponent: (v, e) =>
          (<Text variant="small" style={{ textAlign: "center", color: e.actionColorCode }}>{v}</Text>)
      },
      {
        field: "purchaseType",
        headerText: "Action Type",
        bodyCellComponent: (v) =>
          v ? (
            <Text variant="small" style={{ textAlign: "center" }}>{v}</Text>
          ) : (
            <Text variant="small" style={{ textAlign: "center" }}>{v}</Text>
          ),
      },
      {
        field: "transactionAction",
        headerText: "Action",
        bodyCellComponent: (v, r) => {
          let valPrint = "";
          let textStyle = {};

          if (v === "B") {
            valPrint = "Buy";
            textStyle = classes.buyDiv;
          } else if (v === "S") {
            valPrint = "Sell";
            textStyle = classes.sellDiv;
          }

          return <Text variant="small" className={textStyle}>{valPrint}</Text>;
        },
      },
    ];

    console.log(transactionCount,"transactionCount")
    return (
      <Box mt={3} sx={{ position: "relative" }}>
        <Box className={classes.container}>
          <MobileResponsive
            web={() => (
              <>
                <Stack className={classes.buttonWrap}>
                  <PageTitle heading="All Transactions" />
                  <Button
                    className={classes.refreshButton}
                    onClick={() => this.refreshData()}
                  >
                    <AutorenewIcon />
                  </Button>
                </Stack>
                <Table
                  paginationBottom
                  columns={columns}
                  data={transactionList}
                  count={transactionCount}
                  onPageChange={(page, rowsPerPage) => {
                    this.onPageChange(page, rowsPerPage);
                  }}
                  dataChangedCounter={transactionListApiCounter}
                  refreshTable={this.state.refreshTable}
                  loading={isLoading}
                />
              </>
            )}
            mobile={() => (
              <Box>
                <Stack className={classes.buttonWrap}>
                  <Button
                    className={classes.refreshButton}
                    onClick={() => this.refreshData()}
                  >
                    <AutorenewIcon />
                  </Button>

                  <Pagination
                    count={transactionCount}
                    defaultRowsPerPage={this.state.rowsPerPage}
                    onPageChange={(page, rowsPerPage) => {
                      this.onPageChange(page, rowsPerPage);
                    }}
                    refreshTable={this.state.refreshTable}
                  />
                </Stack>

                {isLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      position: "relative",
                      minHeight: "calc(100vh - 175px)",
                      border: "1px solid #e9e9e9",
                      borderRadius: "10px",
                      overflow: "hidden"
                    }}
                  >
                    <Loader position="absolute" loading={true} />
                  </Box>
                ) :
                  <Stack sx={{ borderRadius: "10px", overflow: "hidden", border: "1px solid #e9e9e9" }}>
                    {transactionList.map((d, i) => {
                      return (
                        <Stack key={`trans_${i}`}>
                          <StockCard
                            sx={{ borderBottom: "1px solid #e9e9e9" }}
                            stockDetails={d}
                            cardType={"TRANSACTION"}
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                }
              </Box>
            )}
          />
        </Box>
      </Box>
    );
  }
}

const styles = (theme) => ({
  buttonWrap: {
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexWrap: "nowrap"
  },
  refreshButton: {
    width: "fit-content",
    height: "100%",
    textTransform: "unset",
    boxShadow: "none !important",
    margin: "15px 0",
    color: "#fff",
    textDecoration: "none",
  },
  container: {
    background: "#FFFFFF",
    borderRadius: "8px",
    padding: "20px 28px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 0px",
    },
  },
  buyDiv: {
    color: "#3CC480",
    borderRadius: "20px",
    fontWeight: "bold",

    height: "40px",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "40px",
  },
  sellDiv: {
    color: "#D44C4C",
    borderRadius: "20px",
    fontWeight: "bold",

    height: "40px",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "40px",
  },
});

export default withStyles(styles, { withTheme: true })(TransactionContainer);
