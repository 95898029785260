import { config } from "config";
import { AppsFlyer } from "appsflyer-capacitor-plugin";

const appsFlyerTracker = {};

appsFlyerTracker.enabled = false;
appsFlyerTracker.devKey = null;
appsFlyerTracker.appId = null;
appsFlyerTracker.isDebug = null;

appsFlyerTracker.updateConfig = ({
    platform = [],
    devKey = "",
    appId = {},
    isDebug = null,
}) => {
    appsFlyerTracker.enabled = platform.includes(config.platform);
    appsFlyerTracker.devKey = devKey;
    appsFlyerTracker.appId = appId[config.platform];
    appsFlyerTracker.isDebug = isDebug || config.debug;
};

appsFlyerTracker.init = async () => {
    console.log('app init', appsFlyerTracker)
    if (!appsFlyerTracker.enabled || !appsFlyerTracker.appId || !appsFlyerTracker.devKey) {
        return;
    }
    
    try {
        appsFlyerTracker.stop();

        const afConfig = {
            appID: appsFlyerTracker.appId, // replace with your app ID.
            devKey: appsFlyerTracker.devKey, // replace with your dev key.
            isDebug: appsFlyerTracker.isDebug || config.debug,
            waitForATTUserAuthorization: 10, // for iOS 14 and higher
            minTimeBetweenSessions: 6, // default 5 sec
            registerOnDeepLink: true,
            registerConversionListener: true,
            registerOnAppOpenAttribution: true,
            deepLinkTimeout: 4000, // default 3000 ms
            useReceiptValidationSandbox: true, // iOS only
            useUninstallSandbox: true, // iOS only
        };

        AppsFlyer.initSDK(afConfig);
        console.log('app init end', appsFlyerTracker)
    } catch (error) {
        console.error(`appsflyer initSDK failed`, error);
    }
};

appsFlyerTracker.trackEvents = ({ eventName, eventData = {} }) => {
    if (!appsFlyerTracker.enabled) {
        return;
    }

    try {
        const eventValue = {};
        Object.keys(eventData).forEach((key) => {
            eventValue[`af_${key}`] = eventData[key];
        });

        AppsFlyer.logEvent({ eventName, eventValue });
    } catch (error) {
        console.error("appsflyer logEvent failed", error);
    }
};

appsFlyerTracker.stop = () => {
    if (!appsFlyerTracker.enabled) {
        return;
    }

    try {
        AppsFlyer.stop();
    } catch (error) {
        console.error("appsflyer stop failed", error);
    }
};

appsFlyerTracker.setUserId = (userId) => {
    if (!appsFlyerTracker.enabled) {
        return;
    }

    try {
        AppsFlyer.setCustomerUserId(userId);
    } catch (error) {
        console.error("appsflyer setCustomerUserId failed", error);
    }
};

export { appsFlyerTracker };
