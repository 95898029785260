import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";

import { CustomerRoute } from "helper/route";
import LayoutContainer from "containers/Layout";

// pages
import Blogs from "pages/KnowledgeCentre/Blogs";
import MarketUpdates from "pages/KnowledgeCentre/MarketUpdates";
import NewsAndArticles from "pages/KnowledgeCentre/NewsAndArticles";
import NewsAndArticleContent from "pages/KnowledgeCentre/NewsAndArticleContent";
import HelpSupport from "pages/KnowledgeCentre/HelpSupport";


const CustomerNavigator = (props) => {
    const { match } = props;
    const isAuthenticated = useSelector((state) => state.customer.isAuthenticated);
    
    const isPortfolioOnboardingCompleted = useSelector((state) => state.onboarding.isOnboardingCompleted);
    const isRMSonboardingCompleted = useSelector((state) => state.jarvisRMS.rmsOnboarding.isOnboardingCompleted);
    const isOnestockonboardingCompleted = useSelector((state) => state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted);

    const anyOnboardingCompleted = (isPortfolioOnboardingCompleted || isRMSonboardingCompleted || isOnestockonboardingCompleted);

    return (
        <Switch>
            <Redirect exact from={match.path} to={`${match.path}/blogs`} />
            <LayoutContainer {...props}>
                <Switch>
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`/`}
                        path={`${match.path}/blogs`}
                        component={Blogs}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`/`}
                        path={`${match.path}/market-updates`}
                        component={MarketUpdates}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`/`}
                        path={`${match.path}/news-articles`}
                        component={NewsAndArticles}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`/`}
                        path={`${match.path}/news-articles/:articleId`}
                        component={NewsAndArticleContent}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`/`}
                        path={`${match.path}/help-support`}
                        component={HelpSupport}
                    />
                </Switch>
            </LayoutContainer>
            <Redirect to={`not-found`} />
        </Switch>
    );
};

export default CustomerNavigator;