import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import ReactPlayer from "react-player";


import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import JarvisButton from "components/JarvisButton";

import { tracker } from "library/tracker";

// Slider
import Slider from "react-slick";

import { config } from "config";

/* NOTE: 
  - mp4 formate video number 3 & 5 was not playing on android.
  - webm formate was not working on ios hence using mp4 for ios
*/

const sliderItems = [
  {
    index: 0,
    imgSrc: "/images/appWelcome/artificial-image.svg",
    videoUrl: "/videos/artificial.webm",
    videoUrlMp4: "/videos/artificial.mp4",
    head: "Artificial Intelligence in investing",
    text: `Create a portfolio of stocks with the help of AI with better accuracy and efficiency analyzing 12 million data points every day.`,
  },
  {
    index: 1,
    imgSrc: "/images/appWelcome/safeguard-image.svg",
    videoUrl: "/videos/fund-manager.webm",
    videoUrlMp4: "/videos/fund-manager.mp4",
    head: "Safeguard Your Investments",
    text: `Let AI “Protect” your investments from the vagaries of the stock market. Track multiple portfolios simultaneously across top brokers in the country.`,
  },
  {
    index: 2,
    imgSrc: "/images/appWelcome/superstar-image.svg",
    videoUrl: "/videos/Action-center.webm",
    videoUrlMp4: "/videos/Action-center.mp4",
    head: "Superstar Stock Picks",
    text: `High conviction stock picks on a weekly basis chosen specifically as per your risk appetite. Let AI pick the “One Stock” that can be the game changer.`,
  },
  {
    index: 3,
    imgSrc: "/images/appWelcome/jarvis-clock-image.svg",
    videoUrl: "/videos/save-time.webm",
    videoUrlMp4: "/videos/save-time.mp4",
    head: "Save your time and efforts with JARVIS",
    text: `Now get a seamless trade execution with the top 15 brokers and monitor your live portfolio from JARVIS dashboard.`,
  },
  {
    index: 4,
    imgSrc: "/images/appWelcome/invest-sit-image.svg",
    videoUrl: "/videos/sit-back.webm",
    videoUrlMp4: "/videos/sit-back.mp4",
    head: "Invest and sit back",
    text: `Let the AI track your portfolio 24×7 and book profits, exit dormant stocks while also rebalancing the portfolio whenever needed considering the opportunities and threats.`,
  },
];

const NextArrow = (props) => {
  const { className, style, onClick, visible } = props;

  return (
    <>
      {visible ? (
        <div
          className={className}
          style={{
            ...style,
            top: "unset",
            transform: "unset",
            bottom: "55px",
            left: "unset",
            right: "50px",
            zIndex: 999
          }}
          onClick={onClick}
        >
          <img src="/images/mobileApp/slideNext.svg" alt="next" />
        </div>
      ) : null}
    </>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick, visible } = props;
  return (
    <>
      {visible ? (
        <div
          className={className}
          style={{
            ...style,
            top: "unset",
            transform: "unset",
            bottom: "55px",
            left: "unset",
            right: "120px",
            zIndex: 999
          }}
          onClick={onClick}
        >
          <img src="/images/mobileApp/slideBack.svg" alt="next" />
        </div>
      ) : null}
    </>
  );
};

// Class
class AppWelcomeScreenContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlideIndex: 0,
      showReadyButton: false,
      isIntercomPositionUpdate: false,
    };
  }

  componentDidMount() {
    if (this.props.isAppWelcomeScreenShown || !config.isMobileApp) {
      this.props.history.replace("/login");
    }
    //NOTE :event_tracker => beforeRegistration
    tracker.beforeRegistration.appDownload({ device_id: this.props.deviceId, campaign: "", platform: "" });
    //NOTE :event_tracker => beforeRegistration
    tracker.beforeRegistration.spalshVideo({ device_id: this.props.deviceId });

    document.querySelector("html").classList.add("app-welcome-screen");
  }

  componentWillUnmount() {
    document.querySelector("html").classList.remove("app-welcome-screen");
  }


  onSlideChange(oldIndex, newIndex) {
    this.setState({ activeSlideIndex: newIndex });
    if (sliderItems.length - 1 !== newIndex) {
      this.setState({ showReadyButton: false });
    }

  }

  onAfterSlideChange(index) {
    if (sliderItems.length - 1 === index) {
      if (index == 1) {
        //NOTE :event_tracker => beforeRegistration
        tracker.beforeRegistration.splashVideo1({ device_id: this.props.deviceId });
      }
      if (index == 2) {
        //NOTE :event_tracker => beforeRegistration
        tracker.beforeRegistration.splashVideo2({ device_id: this.props.deviceId });
      }
      if (index == 3) {
        //NOTE :event_tracker => beforeRegistration
        tracker.beforeRegistration.splashVideo3({ device_id: this.props.deviceId });
      }
      if (index == 4) {
        //NOTE :event_tracker => beforeRegistration
        tracker.beforeRegistration.splashVideo4({ device_id: this.props.deviceId });
      }
      if (index == 5) {
        //NOTE :event_tracker => beforeRegistration
        tracker.beforeRegistration.splashVideo5({ device_id: this.props.deviceId });
      }
      this.setState({ showReadyButton: true });
    } else {
      this.setState({ showReadyButton: false });
    }
  }

  onReadyClicked() {
    // Seen Welcome screen
    this.props.setAppWelcomeScreenShownAction();
    this.props.history.replace("/signup");
  }

  render() {
    const { classes } = this.props;
    const { activeSlideIndex, showReadyButton } = this.state;
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "appWelcomeSlide",
      nextArrow: <NextArrow visible={!showReadyButton} />,
      prevArrow: <PrevArrow visible={(!showReadyButton && activeSlideIndex !== 0)} />,
    };

    const videoConfig = {
      file: {
        attributes: {
          preload: "auto",
          poster: "/videos/poster.png"
        }
      }
    }

    // Render return
    return (
      <>
        <Box className={classes.playerWrapper}>
          <Slider
            {...settings}
            beforeChange={(oldIndex, newIndex) =>
              this.onSlideChange(oldIndex, newIndex)
            }
            afterChange={(index) => this.onAfterSlideChange(index)}
          >
            {sliderItems.map((e, i) => (
              <Stack key={`player_${i}`} className={classes.slidContainer}>
                {/* <ReactPlayer
                  className={classes.slidPlayer}
                  url={(config.platform === "ANDROID") ? e.videoUrl : e.videoUrlMp4}
                  width="100%"
                  height="auto"
                  playing={activeSlideIndex === e.index}
                  config={videoConfig}
                  muted={true}
                  loop={true}
                  controls={false}
                  playsinline={true}
                /> */}
                <img 
                      alt="appWelcomeImg" 
                      style={{
                        marginTop:"10px",
                        padding: "10px",
                        width:"100%",
                        overflow: "hidden",
                        marginBottom: "20px",
                        maxWidth: "450px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }} 
                      src={e.imgSrc} 
                      />
                <Text component="h3">{e.head}</Text>
                <Text component="p">{e.text}</Text>

                {showReadyButton ? (
                  <Box className={classes.readyButton}>
                    <JarvisButton onClick={() => this.onReadyClicked()}>
                      Ready for Investing
                    </JarvisButton>
                  </Box>
                ) : null}
              </Stack>
            ))}
          </Slider>
        </Box>
      </>
    );
  }
}

const style = {
  playerWrapper: {
    overflow: "hidden",
  },
  reactPlayer: {
    position: "relative",
    minHeight: "100vh",
    overflow: "hidden",
    "& > video": {
      width: "100%",
      position: "absolute",
      top: "50%",
      bottom: "0",
      left: "50%",
      right: "0",
      transform: "translate(-50%, -50%)",
    },
  },
  slidContainer: {
    maxWidth: "450px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex !important",
    position: "relative",
    "& > h3": {
      padding: "0 24px",
      fontSize: "26px",
      fontWeight: "600",
      lineHeight: "31px",
      marginBottom: "20px",
    },
    "& > p": {
      padding: "0 24px",
      color: "#8F9297",
      fontSize: "15px",
      marginBottom: "15px",
    },
  },
  readyButton: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",

    "& > button": {
      padding: "12px 22px",
      borderRadius: "50px",
      zIndex: "99",
      marginRight: "15px",
      fontSize: "16px",
      marginBottom: "5px"
    },
  },

  slidPlayer: {
    padding: "10px",
    overflow: "hidden",
    marginBottom: "20px",
    maxWidth: "450px",
    marginLeft: "auto",
    marginRight: "auto",
    "& > video": {
      borderRadius: "14px",
    },
  },
};

export default withRouter(
  withStyles(style, { widthTheme: true })(AppWelcomeScreenContainer)
);
