import React from "react";

import Box from "components/common/Box";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import JarvisButton from "components/JarvisButton";
import TextField from "components/common/TextField";
import InputAdornment from "components/common/InputAdornment";
import { config } from "config/";
import { formatHelper } from "helper/format";

const LumpsumForm = ({
  onChange,
  onSubmit,
  formData,
  formError,
  minInvestment,
  isLoading,
}) => {
  return (
    <Stack>
      {/* Mobile App: to heading
      {config.isMobileApp ? (
        <Box mt={"10px"} mb={"15px"}>
          <Text
            component="h3"
            sx={{ fontWeight: "bold", fontSize: "bold", lineHeight: 1 }}
          >
            Invest More
          </Text>
          <Text component="div" sx={{ color: "#8b909a", fontSize: "16px" }}>
            How would you like to invest?
          </Text>
        </Box>
      ) : null} */}

      <TextField
        label="Enter Amount"
        type="number"
        value={formData.investmentAmount}
        onChange={(e) =>
          onChange({
            name: "investmentAmount",
            value: e.target.value,
          })
        }
        error={formError.investmentAmount}
        helperText={
          formError.investmentAmount ||
          `Minimum Lumpsum amount is ${formatHelper.currency(minInvestment)}`
        }
        InputProps={{
          endAdornment: <InputAdornment position="end">₹</InputAdornment>,
        }}
        inputProps={{ inputMode: "numeric" }}
        sx={{ maxWidth: { xs: "100%", md: 400 }, mb: { xs: 2, md: 9 } }}
      />

      <Text
        sx={{
          // mb: 2,
          // pt: { xs: 2, md: "72px" },
          typography: { xs: "small", md: "body2" },
          display: "inline",
        }}
        color="#808591"
      >
        By utilizing the "Invest More" function, you have the ability to continuously contribute funds to your portfolio whenever you have extra cash that won't be needed for a year or two. Nevertheless, each time you contribute, your portfolio will undergo rebalancing, which involves selling off the current holdings and generating a new portfolio with the additional funds. Failure to rebalance on the same day or the next day will result in the cancellation of the transaction from our end.
      </Text>

      <Text
        color="#808591"
        pt={3}
        sx={{
          mb: 2,
          typography: { xs: "small", md: "body2" },
          display: "inline",
        }}
      >
        By continuing, I agree to the{" "}
        <a href={config.disclaimerUrl} style={{ textDecoration: "none" }} target="_blank">
          <Text
            sx={{
              color: "#38a1ff",
              typography: { xs: "small", md: "body2" },
              display: "inline",
            }}
          >
            JARVIS Disclaimer
          </Text>
        </a>{" "}
        and{" "}
        <a
          href={config.tacUrl}
          style={{
            textDecoration: "none",
            display: "inline",
          }}
          target="_blank"
        >
          <Text
            sx={{
              color: "#38a1ff",
              typography: { xs: "small", md: "body2" },
              display: "inline",
            }}
          >
            T&amp;Cs.
          </Text>
        </a>
      </Text>

      <Stack alignItems="flex-start" sx={{ mb: 7.5 }}>
        <JarvisButton loading={isLoading} onClick={onSubmit}>
          Continue
        </JarvisButton>
      </Stack>
    </Stack>
  );
};

export default LumpsumForm;
