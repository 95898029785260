import React from "react";

import Text from "components/common/Text";
import Link from "components/common/Link";
import Stack from "components/common/Stack";
import Radio from "components/common/Radio";
import MenuItem from "components/common/MenuItem";
import TextField from "components/common/TextField";
import JarvisButton from "components/JarvisButton";
import Select from "components/common/Select";
import InputAdornment from "components/common/InputAdornment";

import { formatHelper } from "helper/format";
import { DateRangeOutlined as DateIcon } from "@mui/icons-material";

const SIPForm = ({ onChange, onSubmit, formData, formError, minInvestment, isLoading }) => {
    return (
        <Stack>
            <TextField
                label="Installment Amount"
                type="number"
                sx={{ maxWidth: 400, mb: 4 }}
                value={formData.sipAmount}
                onChange={(e) => onChange({ name: "sipAmount", value: e.target.value })}
                error={formError.sipAmount}
                helperText={formError.sipAmount || `Minimum SIP amount is ${formatHelper.currency(minInvestment)}`}
                InputProps={{
                    endAdornment: <InputAdornment position="end">₹</InputAdornment>
                }}
            />

            <Stack direction="row" alignItems="center" sx={{ border: `1px solid #E0E7FF`, pl: 2, borderRadius: 1.25, maxWidth: 400, mb: 4 }}>
                <DateIcon sx={{ color: "#565656", fontSize: 24 }} />
                <TextField
                    select
                    label="Select Monthly Installment Date"
                    value={formData.installmentDate || ""}
                    onChange={(e) => onChange({ name: "installmentDate", value: e.target.value })}
                    error={formError.installmentDate}
                    helperText={formError.installmentDate}
                    InputProps={{
                        sx: { border: 0 }
                    }}
                >
                    {[1, 2, 3, 4, 5].map((item) => <MenuItem key={`item_${item}`} value={item}>Menu Item {item}</MenuItem>)}
                </TextField>
            </Stack>

            <Text variant="body2" color="#202020" sx={{ mb: 2 }}>How long do you want this SIP to continue?</Text>

            <Stack direction="row" justifyContent="space-between" sx={{ mb: 6 }}>
                <Stack direction="row" alignItems="center">
                    <Radio
                        checked={formData.continueSIP === "UNTIL_STOPPED"}
                        onChange={() => onChange({ name: "continueSIP", value: "UNTIL_STOPPED" })}
                        sx={{ color: "#BCBCBC", mr: 1 }}
                    />
                    <Text>Until I Stop</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                    <Radio
                        checked={formData.continueSIP === "UNTIL_PERIOD"}
                        onChange={() => onChange({ name: "continueSIP", value: "UNTIL_PERIOD" })}
                        sx={{ color: "#BCBCBC", mr: 1 }}
                    />
                    <TextField
                        label="Enter Tenure"
                        value={formData.sipTenure}
                        onChange={(e) => onChange({ name: "sipTenure", value: e.target.value })}
                        error={formError.sipTenure}
                        sx={{ maxWidth: 200, mr: 2 }}
                    />
                    <Select
                        disableUnderline
                        variant="filled"
                        value={formData.sipTenureType}
                        onChange={(e) => onChange({ name: "sipTenureType", value: e.target.value })}
                        sx={{
                            backgroundColor: "grey.100",
                            px: 1.5,
                            py: 0.25,
                            borderRadius: 5.5,
                            fontSize: `1rem !important`,
                        }}
                    >
                        <option value="years">Years</option>
                        <option value="months">Months</option>
                    </Select>
                </Stack>
            </Stack>

            <Text
                variant="body2"
                color="#808591"
                sx={{ mb: 2 }}
                style={{ paddingTop: "72px" }}>
                By continuing, I agree to the{" "}
                <Link href="/" underline="hover" variant="body2">
                    JARVIS Disclaimer
                </Link>{" "}
                and{" "}
                <Link href="/" underline="hover" variant="body2">
                    T&amp;Cs.
                </Link>
            </Text>

            <Stack alignItems="flex-start" sx={{ mb: 7.5 }}>
                <JarvisButton loading={isLoading} onClick={onSubmit}>
                    Continue
                </JarvisButton>
            </Stack>
        </Stack>
    )
};

export default SIPForm;
