import React from "react";
import _get from "lodash.get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";

import IconButton from "components/common/IconButton";
import AlertTitle from '@mui/material/AlertTitle';
import Stack from "components/common/Stack";
import Grid from "components/common/Grid";
import Radio from "components/common/Radio";
import Text from "components/common/Text";
import JarvisButton from "components/JarvisButton";
import TextField from "components/common/TextField";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MenuItem from "components/common/MenuItem";
import Card from "components/common/Card";
import Box from "components/common/Box";
import Alert from "components/common/Alert";
import TooltipHover from "components/common/TooltipHover";

import SectionTitle from "components/SectionTitle";
import PaymentSummary from "components/Onboarding/PortfolioCreation/paymentSummary";

import BackIcon from "@mui/icons-material/ArrowBackOutlined";
import CheckIcon from "@mui/icons-material/Check";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { validationHelper } from "helper/validation";
import { formatHelper } from "helper/format";
import JarvisDialog from "components/JarvisDialog";
import CouponCodeRow from "components/Onboarding/PortfolioCreation/CouponCodeRow";
import { tracker } from "library/tracker";
import { masterApis } from "stores/master/masterApis";
import { masterParser } from "stores/master/masterParsers";
import Divider from "components/common/Divider";
import ConfirmationActionDialog from "components/ConfirmationActionDialog";
import UpiDrawer from "components/JarvisOnestock/UpiDrawer";
import { localStore } from "stores/localStorage";
import MobileResponsive from "components/common/MobileResponsive";
import Loader from "components/common/Loader";

const screenTypes = {
    PAYMENT_DETAILS: "PAYMENT_DETAILS",
    PAYMENT_METHOD_CONFIRMATION: "PAYMENT_METHODS_CONFIRMATION",
}

const planFrequencyLabels = {
    MONTHLY: "Monthly",
    HALF_YEARLY: "Half-Yearly",
    YEARLY: "Yearly",
    QUARTERLY: "Quarterly",
}

const planTypes = {
    MONTHLY: "month",
    HALF_YEARLY: "six months",
    QUARTERLY: "four months",
    YEARLY: "year",
}

const planTypes2 = {
    MONTHLY: "months",
    HALF_YEARLY: "six months",
    QUARTERLY: "four months",
    YEARLY: "years",
}

const confirmationMessage = {
    "MONTHLY": "Your subscription will renew automatically unless you cancel it on our website. Please keep in mind that you must cancel at least two days before the due date, or your cancellation will take effect after the following payment cycle.",
    "HALF_YEARLY": "Your subscription will renew automatically from 6 month onwards unless you cancel it on our website. Please keep in mind that you must cancel at least two days before the due date, or your cancellation will take effect after the following payment cycle.",
    "YEARLY": "Your subscription will renew automatically annually onwards unless you cancel it on our website. Please keep in mind that you must cancel at least two days before the due date, or your cancellation will take effect after the following payment cycle.",
    "QUARTERLY": "Your subscription will renew automatically from 4 month onwards unless you cancel it on our website. Please keep in mind that you must cancel at least two days before the due date, or your cancellation will take effect after the following payment cycle.",
}

const accountTypes = [{
    label: "Savings",
    value: "SAVINGS"
}, {
    label: "Current",
    value: "CURRENT",
}]

const couponViewTypes = {
    DEFAULT: "DEFAULT",
    EDIT: "EDIT",
    APPLIED: "APPLIED",
};

class PlanPaymentContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeScreenType: screenTypes.PAYMENT_DETAILS,  //change before commit
            selectedPlan: null,
            ssoCustomerDetails:{},
            availablePlans: [],
            confirmationDialogOpen: false,
            isLoading:false,
            upgradedPlanDiscountedPrice:null,
            formData: {
                planId: "",
                authType: "",
                paymentMethod: "",
                paymentMethodOption: "",
                accountNumber: "",
                accountType: "",
                accountHolderName: "",
                ifscCode: "",
                paymentMethodHandle: "",
            },
            formError: {
                planId: "",
                authType: "",
                paymentMethod: "",
                paymentMethodOption: "",
                accountNumber: "",
                accountType: "",
                accountHolderName: "",
                ifscCode: "",
                paymentMethodHandle: "",
            },

            verifiedUpi: false,
            upiDrawerOpen: false,

            couponActiveViewType: couponViewTypes.DEFAULT,
            confirmCouponDialog: false,
            appliedCouponData: {
                discountType: null,
                discountValue: null,
                discountPrice: null,
                discountedPrice: null,
                isValid: null,
                offerCode: null,
                offerId: null,
            },
            couponFormData: {
                coupon: "",
            },
            couponFormError: {
                coupon: "",
            },
        };
    }
    setSsoCouponCode=()=>{
        const ssoCustomerDetails = localStore.getSsoCustomerDetails();
        const{couponFormData} = this.state
        couponFormData['coupon']=ssoCustomerDetails.offerCode
        this.setState({couponFormData})
        
        return;

    }

    componentDidMount() {
        // this.fetchCustomerProfileData();
        this.setState({ssoCustomerDetails:localStore.getSsoCustomerDetails()})
        if(this.props.paymentType === "UPGRADE"){
            this.fetchPlanProratedAmount({planId: this.props.onboardingPlanId});
        }
        this.handlePlanChange({ planId: this.props.onboardingPlanId });
        if (!this.props.paymentMethods.length) {
            this.props.getPaymentMethodsAction();
        }
        if(!this.props.enableCouponDiscount){
            this.props.getFeatureConfigAction()
        }

        if (this.state.activeScreenType === screenTypes.PAYMENT_DETAILS) {
            const appContentBody = document.getElementById('appContentBody');
            if (appContentBody) {
                appContentBody.scrollTop = 0;
            }
        }

    }
    fetchCustomerProfileData = async ()=>{
        await this.props.getProfileDataAction();
        return;
    }

    componentDidUpdate(prevProps,prevState) {
        if (!this.state.availablePlans.length && this.props.onboardingPlanId) {
            this.handlePlanChange({ planId: this.props.onboardingPlanId })
        }
        if(this.state.selectedPlan && prevState.selectedPlan === null){
            if(this.state.ssoCustomerDetails?.offerCode){
                this.setSsoCouponCode()
                this.handleCouponSubmit()
            }
        }
    }
    fetchPlanProratedAmount = async ({customerId="",planId="",investMoreAmount=""})=>{
        const plan = this.props.subscriptionPlans.find(sp => sp.planId === planId);
        const productCode = plan.productMap[0].productCode
        if(!planId || !productCode){
            return;
        }
        this.setState({isLoading:true})
        let payload= {
            planId,
            productCode,            
        }
        if(investMoreAmount){
            payload['investMoreAmount'] = investMoreAmount;
        }
        try{
        let response = await masterApis.fetchPlanDiscountedPriceOnUpgrade(payload)
        let parsedResponse = masterParser.fetchPlanDiscountedPriceOnUpgrade(response)
        if(parsedResponse && parsedResponse?.finalPlanAmount){
            this.setState({upgradedPlanDiscountedPrice:parsedResponse?.finalPlanAmount});
        }
        else{
            this.setState({upgradedPlanDiscountedPrice:0});
            return this.props.navigatePreviousScreen()
            
        }
        }
        catch (error){
            console.error("failed to reterive the amount of upgraded plan price", error)
            this.setState({activeScreenType:screenTypes.PAYMENT_DETAILS})
            return this.props.navigatePreviousScreen();
        }
        finally{
            this.setState({isLoading:false})
        }
    }

    fetchCampaignAppliedOffer = async ({ plan }) => {
        if (!this.props.enableCouponDiscount) {
            return;
        }

        try {
            const productCode = plan.productMap[0].productCode;
            const response = await masterApis.getAppliedCampaignOffer({
                productCode,
                planId: plan.planId,
                offerType: this.props.paymentType,
            });
            const parsedResponse = masterParser.validateOffer(response);
            this.setState({ appliedCouponData: parsedResponse, couponActiveViewType: parsedResponse.isValid ? couponViewTypes.APPLIED : couponViewTypes.DEFAULT });
            // NOTE: event_tracker => coupan 
            tracker.coupon.applySuccess();
        } catch (error) {
            // NOTE: event_tracker => coupan 
            tracker.coupon.applyFailed({ error_msg: error.messgae });
            console.log(`Error fetching pricing data`, error);
        }
    }

    handlePlanChange = ({ planId }) => {
        const plan = this.props.subscriptionPlans.find(sp => sp.planId === planId);
        const availablePlans = this.props.subscriptionPlans.filter(sp => sp?.planCode === plan?.planCode).reverse();
        this.fetchCampaignAppliedOffer({ plan });
        this.setState({
            appliedCouponData: {
                discountType: null,
                discountValue: null,
                discountPrice: null,
                discountedPrice: null,
                isValid: null,
                offerCode: null,
                offerId: null,
            },
            selectedPlan: plan,
            couponActiveViewType: couponViewTypes.DEFAULT,
            availablePlans
        });
        this.handleFormChange({ name: "planId", value: planId });
    }

    isTPVEnabled = () => this.props.enableRazorpayTPV;

    handleFormChange = ({ name, value }) => {
        const { formData } = this.state;

        if (name === "paymentMethod" && formData.paymentMethod !== value) {
            formData[name] = value;
            formData["paymentMethodOption"] = "";
            formData["paymentMethodHandle"] = "";
            formData["authType"] = "";
            if (!this.isTPVEnabled()) {
                formData["accountNumber"] = "";
                formData["accountType"] = "";
                formData["accountHolderName"] = "";
                formData["ifscCode"] = "";
            }
        } else {
            formData[name] = value;
        }

        this.setState({ formData, verifiedUpi: false });
    }

    handleBackClick = () => {
        this.setState({ activeScreenType: screenTypes.PAYMENT_DETAILS, verifiedUpi: false });
    }

    handleContinuePayment = () => {
        // const {customerProfile} = this.props;
        // const {selectedPlan} = this.state;
        // const productCode = selectedPlan.productMap[0].productCode

        // if (customerProfile[productCode].paymentMethod !== "ONETIME") {
        //   if (this.state.couponActiveViewType === couponViewTypes.EDIT) {
        //     return this.setState({ confirmCouponDialog: true });
        //   }
        //   return this.setState({ confirmationDialogOpen: true });
        // }
        return this.handlePayNow()
    }

    handleValidation = () => {
        const { formError, formData } = this.state;
        let invalidCount = 0;

        const paymentMethodValidation = validationHelper.required(formData.paymentMethod);
        formError.paymentMethod = paymentMethodValidation.message;
        invalidCount = invalidCount + paymentMethodValidation.isValid ? 0 : 1;

        if (formData.paymentMethod === "emandate") {
            const paymentMethodOptionValidation = validationHelper.required(formData.paymentMethodOption);
            formError.paymentMethodOption = paymentMethodOptionValidation.message;
            invalidCount = invalidCount + paymentMethodOptionValidation.isValid ? 0 : 1;
        }


        if (this.isTPVEnabled() || formData.paymentMethod === "emandate") {
            const nameValidation = validationHelper.name(formData.accountHolderName);
            formError.accountHolderName = nameValidation.message;
            invalidCount = invalidCount + nameValidation.isValid ? 0 : 1;

            const ifscValidation = validationHelper.ifscCode(formData.ifscCode);
            formError.ifscCode = ifscValidation.message;
            invalidCount = invalidCount + ifscValidation.isValid ? 0 : 1;

            const accountNumberValidation = validationHelper.bankAccountNumber(formData.accountNumber);
            formError.accountNumber = accountNumberValidation.message;
            invalidCount = invalidCount + accountNumberValidation.isValid ? 0 : 1;
        }

        if (formData.paymentMethod === "emandate") {
            const authTypeValidation = validationHelper.required(formData.authType);
            formError.authType = authTypeValidation.message;
            invalidCount = invalidCount + authTypeValidation.isValid ? 0 : 1;
        }

        this.setState({ formError });
        return invalidCount === 0;
    };

    handlePayNow = () => {
        // const {customerProfile} = this.props
        // const {selectedPlan} = this.state
        // // const productCode = selectedPlan.productMap[0].productCode
        // // if(customerProfile[productCode].paymentMethod !== 'ONETIME'){
        // // if (!this.handleValidation()) {
        // //     return;
        // // }
    // }
        const { formData, appliedCouponData } = this.state;
        let paymentMethod = "upi";
        // if(customerProfile[productCode].paymentMethod !== 'ONETIME'){
        //     paymentMethod = formData.paymentMethod
        // }
        const payload = {
            planId: formData.planId,
            paymentMethod
        };
        
    //     if(customerProfile[productCode].paymentMethod !== 'ONETIME'){
    //     if (formData.paymentMethod === "upi") {
    //         payload["paymentMethodOption"] = formData.paymentMethodHandle;
    //     }

    //     if (formData.paymentMethod === "emandate") {
    //         payload["paymentMethodOption"] = formData.paymentMethodOption;
    //     }

        if (appliedCouponData.isValid) {
            payload["offerCode"] = appliedCouponData.offerCode;
        }

    //     if (formData.paymentMethod === "emandate") {
    //         payload["authType"] = formData.authType;
    //     }

    //     if (this.isTPVEnabled() || formData.paymentMethod === "emandate") {
    //         payload["accountNumber"] = formData.accountNumber;
    //         payload["accountType"] = formData.accountType;
    //         payload["accountHolderName"] = formData.accountHolderName;
    //         payload["ifscCode"] = formData.ifscCode;
    //     }
    // }

        this.props.onPayNowClick(payload)
    }

    handleUpiVerification = () => {
        const { formData, formError } = this.state

        if (this.props.paymentMethods.eligibleUpiHandles.includes(`@${formData.paymentMethodHandle.split("@")[1]}`)) {
            this.setState({ verifiedUpi: true, formError: {} })
        } else {
            if (formData.paymentMethodHandle === "") {
                formError.paymentMethodHandle = "This field is required"
                this.setState({ formError })
            }
            else if (!formData.paymentMethodHandle.includes("@")) {
                formError.paymentMethodHandle = "Invalid UPI"
                this.setState({ formError })
            }
            else {
                formError.paymentMethodHandle = "UPI handle not supported for autopay"
                this.setState({ formError })
            }
        }
    }

    getPlanType = (val) => {
        return planFrequencyLabels[val]
    }

    handleCancel = () => {
        this.setState({ confirmationDialogOpen: false });
    }

    handleConfirm = () => {
        this.setState({ confirmationDialogOpen: false, activeScreenType: screenTypes.PAYMENT_METHOD_CONFIRMATION });
    }

    handleCouponChange = ({ name, value }) => {
        const { couponFormData } = this.state;
        couponFormData[name] = value;
        this.setState({ couponFormData });
    };

    handleCouponFormValidation = () => {
        const { couponFormData, couponFormError } = this.state;

        const couponValidation = validationHelper.required(couponFormData.coupon);
        couponFormError.coupon = couponValidation.message;

        this.setState({ couponFormError });
        return couponValidation.isValid;
    };

    handleCouponSubmit = async () => {
        // NOTE: event_tracker => coupan 
        tracker.coupon.apply();
        if (!this.props.enableCouponDiscount || !this.handleCouponFormValidation()) {
            return;
        }

        try {
            const { couponFormData, selectedPlan } = this.state;
            let productCode;
            if(!this.state.ssoCustomerDetails?.productCode){
                productCode = selectedPlan.productMap[0].productCode;

            }
            else{
                productCode= this.state.ssoCustomerDetails?.productCode.toUpperCase()
            }
            

            const response = await masterApis.validateOffer({
                productCode,
                offerCode: couponFormData.coupon,
                planId: selectedPlan.planId,
                offerType: this.props.paymentType,
            });
            const parsedResponse = masterParser.validateOffer(response);
            this.setState({ appliedCouponData: parsedResponse, couponActiveViewType: couponViewTypes.APPLIED });
            // NOTE: event_tracker => coupan 
            tracker.coupon.applySuccess();
        } catch (error) {
            // NOTE: event_tracker => coupan 
            tracker.coupon.applyFailed({ error_msg: error.messgae });
            console.log(`Error fetching pricing data`, error);
        }
    };

    handleCouponAction = (action, payload) => {
        switch (action) {
            case "COUPON_ADD":
                // NOTE: event_tracker => coupan 
                tracker.coupon.apply();
                this.setState({
                    couponActiveViewType: couponViewTypes.EDIT,
                    couponFormData: { coupon: "" },
                    couponFormError: { coupon: "" },
                });
                break;

            case "COUPON_CLEAR":
                this.setState({
                    couponActiveViewType: couponViewTypes.DEFAULT,
                    couponFormData: { coupon: "" },
                    couponFormError: { coupon: "" },
                    appliedCouponData: {
                        discountType: null,
                        discountValue: null,
                        discountPrice: null,
                        discountedPrice: null,
                        isValid: null,
                        offerCode: null,
                        offerId: null,
                    },
                });
                break;

            case "COUPON_SUBMIT":
                // NOTE: event_tracker => coupan 
                this.handleCouponSubmit();
                break;

            case "COUPON_CHANGE":
                this.handleCouponChange(payload);
                break;

            default:
                console.log(`${action} is not defined`);
                break;
        }
    };

    handleUpiDrawerOpen = () => {
        this.setState({ upiDrawerOpen: true });
    }

    handleUpiDrawerClose = () => {
        this.setState({ upiDrawerOpen: false })
    }

    render() {
        const {
            activeScreenType,
            selectedPlan,
            availablePlans,
            formData,
            formError,
            confirmationDialogOpen,
            upiDrawerOpen,
            upgradedPlanDiscountedPrice,
            isLoading
        } = this.state;

        let payableAmount = this.props.paymentType === 'UPGRADE' && this.state.upgradedPlanDiscountedPrice?this.state.upgradedPlanDiscountedPrice:selectedPlan?.planPrice;
        if (selectedPlan?.planDiscountedPrice) {
            payableAmount = selectedPlan.planDiscountedPrice;
        }
        if (this.state.appliedCouponData.isValid) {
            payableAmount = this.state.appliedCouponData.discountedPrice;
        }
        const { paymentMethods, disableBackFromPaymentDetails = false, paymentType } = this.props;


        if (activeScreenType === screenTypes.PAYMENT_DETAILS) {
            return isLoading?(<Loader position="fixed" size="lg" loading={isLoading}/>):(

                <Stack sx={{ mb: { xs: 8, md: 0 } }}>
                    <SectionTitle
                        sx={{ mb: 2 }}
                        heading="Payment"
                        disableBreadcrumb
                        startIcon={
                            !disableBackFromPaymentDetails && <IconButton onClick={this.props.navigatePreviousScreen}>
                                <BackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                            </IconButton>
                        }
                    />

                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={5}>
                            {/* plan frequency */}
                            <Stack direction="row" alignItems="center" mb={4}>
                                {availablePlans.map(p => (
                                    <Stack direction="row" alignItems="center" key={p.planId} mr={3}>
                                        <Radio checked={p.planFrequency === selectedPlan.planFrequency} onChange={() => this.handlePlanChange({ planId: p.planId })} />
                                        <Text>{planFrequencyLabels[p.planFrequency]}</Text>
                                    </Stack>
                                ))}
                            </Stack>

                            {/* plan Card */}
                            <Card sx={{ border: '1px solid #E4E6E8', boxShadow: 'none', marginBottom: '40px' }}>
                                <Stack direction="row" justifyContent="space-between" sx={{ backgroundColor: '#E4E6E8' }} py={1.5} px={3}>
                                    <Text variant="small" color={'success.light'} sx={{ fontWeight: "medium" }}>Selected Plan</Text>
                                    {/* <Text variant="small" sx={{ fontWeight: 500 }}>Change Plan</Text> */}
                                </Stack>
                                <Stack my={3} mx={3}>
                                    <Box>
                                        <Text variant="subtitle1" sx={{ fontSize: '500' }}>{selectedPlan?.planName}</Text>
                                        {/* highlighted Price */}
                                        {selectedPlan?.planPrice && selectedPlan?.planDiscountPercentage ?
                                            <Stack>
                                                <Stack direction="row" alignItems="flex-end">
                                                    <Text sx={{ lineHeight: "1.1", fontSize: "subtitle1.fontSize", fontWeight: "600" }}>{formatHelper.currency(selectedPlan?.planDiscountedPrice)}&nbsp;</Text>
                                                    <Text sx={{ color: "#676E76", fontSize: "small.fontSize" }}><span style={{ textDecoration: "line-through" }}>{formatHelper.currency(paymentType !== "UPGRADE" && !upgradedPlanDiscountedPrice?selectedPlan?.planPrice:upgradedPlanDiscountedPrice)}</span>&nbsp;for the first {planTypes[selectedPlan?.planFrequency]}</Text>
                                                </Stack>
                                                <Text sx={{ color: "#676E76", fontSize: "small.fontSize" }}>and {formatHelper.currency(paymentType !== "UPGRADE" && !upgradedPlanDiscountedPrice?selectedPlan?.planPrice:upgradedPlanDiscountedPrice)} for the subsequent {planTypes2[selectedPlan?.planFrequency]}</Text>
                                            </Stack>
                                            :
                                            <Stack>
                                                <Stack direction="row" alignItems="center">
                                                    <Text sx={{ lineHeight: "1.1", fontSize: "32px", fontWeight: "600", marginRight: "5px" }}>{formatHelper.currency(paymentType !== "UPGRADE" && !upgradedPlanDiscountedPrice?selectedPlan?.planPrice:upgradedPlanDiscountedPrice)}</Text>/{planTypes[selectedPlan?.planFrequency]}
                                                </Stack>
                                            </Stack>
                                        }
                                    </Box>
                                </Stack>
                            </Card>

                            {/* plan check list */}
                            <Stack sx={{ flex: 1, mb: { xs: 2, md: 1 } }}>
                                <Text variant="body1" sx={{ fontWeight: "medium", mb: 2 }}>Your subscription unlocks</Text>
                                {selectedPlan?.planChecklist.map((check, index) => (
                                    <Stack key={`check_${index}`} direction="row" alignItems="flex-start" sx={{ mb: 1 }}>
                                        <CheckIcon sx={{ fontSize: 16, color: "#45AC70", mr: 1, mt: 0.45 }} />
                                        <Text variant="body2">{check}</Text>
                                    </Stack>
                                ))}
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={5}>
                            {this.props.enableCouponDiscount ? (
                                <>
                                    <CouponCodeRow
                                        onActionTrigger={this.handleCouponAction}
                                        viewTypes={couponViewTypes}
                                        activeViewType={this.state.couponActiveViewType}
                                        formData={this.state.couponFormData}
                                        formError={this.state.couponFormError}
                                        discountValue={this.state.appliedCouponData.discountPrice}
                                    />

                                    <Divider sx={{ my: 2 }} />
                                </>
                            ) : null}

                            <Text variant="body1" sx={{ fontWeight: "medium", mb: { xs: 2, md: 1 } }}>Price details</Text>
                            <PaymentSummary
                                selectedPlan={selectedPlan}
                                planType={this.getPlanType(selectedPlan?.planFrequency)}
                                appliedCouponData={this.state.appliedCouponData}
                                paymentType ={paymentType}
                                upgradePlanAmount = {upgradedPlanDiscountedPrice?upgradedPlanDiscountedPrice:''}
                            />
                            <Stack sx={{
                                background: "unset",
                                width: { xs: "100%", md: "unset" },
                                justifyContent: { xs: "center", md: "unset" },
                                alignItems: { xs: "center", md: "unset" },
                            }}>
                                <Stack
                                    sx={{
                                        position: { xs: "fixed", md: "unset" },
                                        bottom: { xs: "0px", md: "unset" },
                                        width: { xs: "100%", md: "unset" },
                                        backgroundColor: { xs: 'unset', md: 'none' },
                                        padding: { xs: "10px 14px", md: "0px" },
                                        paddingBottom: { xs: "calc(var(--safe-area-inset-bottom) + 12px) !important" },
                                    }}>
                                    <JarvisButton
                                        sx={{ marginBottom: 2 }}
                                        variant="contained"
                                        disableEndIcon
                                        // loading={Object.keys(this.props.customerProfile).length === 0}
                                        onClick={() => {
                                            // this.state.couponActiveViewType === couponViewTypes.EDIT ? this.setState({ confirmCouponDialog: true }) : this.handleContinuePayment()
                                            this.handleContinuePayment()
                                        }}
                                    >
                                        Pay {formatHelper.currency(payableAmount)}
                                    </JarvisButton>
                                    {/* {selectedPlan?.planName == "Jarvis Protect" ? (
                                        <Text sx={{ display: { md: 'none', xs: 'block' }, textAlign: "center" }} variant="caption1">Your Jarvis protect subscription auto-renews every month. You can canel cancel subscription any tiime.</Text>) : null} */}
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>

                    <JarvisDialog
                        open={confirmationDialogOpen}
                        onClose={this.handleCancel}
                        onConfirm={this.handleConfirm}
                        title={"<span style='color: #f44336;'>Confirmation required</span>"}
                        content={confirmationMessage[selectedPlan?.planFrequency]}
                        setHTML={true}
                        button={{
                            text: "I agree"
                        }}
                    />

                    <ConfirmationActionDialog
                        open={this.state.confirmCouponDialog}
                        onConfirm={() => {
                            this.setState({
                                confirmCouponDialog: false
                            }, () => { this.handleContinuePayment() })
                        }}
                        onClose={() => this.setState({ confirmCouponDialog: false })}
                        message={"Would you like to continue without a coupon discount?"}
                    />
                </Stack>
                
            )
        }

        if (activeScreenType === screenTypes.PAYMENT_METHOD_CONFIRMATION) {

            const selectedPaymentMethodData = paymentMethods.eligiblePaymentMethods.find(emp => emp.value === formData.paymentMethod) || {};

            const paymentMethodOptions = _get(paymentMethods, `${selectedPaymentMethodData.value}Options`, []);

            const selectedMethodOptionData = paymentMethodOptions.find(pmo => pmo.value === formData.paymentMethodOption) || {};

            const authTypeOptions = paymentMethods.eligibleAuthTypes.filter(eat => selectedMethodOptionData.supportedAuthTypes && selectedMethodOptionData.supportedAuthTypes.includes(eat.value));

            return (
                <Stack>
                    <SectionTitle
                        sx={{ mb: 2 }}
                        heading="Choose payment mode"
                        disableBreadcrumb
                        startIcon={
                            <IconButton onClick={this.handleBackClick}>
                                <BackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                            </IconButton>
                        }
                    />

                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={5}>
                            {/* payment method */}
                            <Stack mb={2}>
                                <Stack direction="row" alignItems="center" sx={{ flexWrap: "wrap" }}>
                                    {paymentMethods.eligiblePaymentMethods.map((emp, index) => (
                                        <Stack key={emp.value} direction="row" alignItems="center" sx={{ mr: index === paymentMethods.eligiblePaymentMethods.length - 1 ? 0 : 3 }}>
                                            <Radio name="paymentMethod" checked={emp.value === formData.paymentMethod} onChange={() => this.handleFormChange({ name: "paymentMethod", value: emp.value })} />
                                            <Text component="label" sx={{ color: emp.value === formData.paymentMethod ? '#2B70C5' : '#212225' }}>{emp.label}</Text>
                                        </Stack>
                                    ))}
                                </Stack>
                                {formError.paymentMethod && <Text variant="small" sx={{ color: "error.main" }}>{formError.paymentMethod}</Text>}

                                {selectedPaymentMethodData && selectedPaymentMethodData.helperText ? (
                                    <Stack>
                                        <Alert sx={{ my: 1.5 }} icon={<InfoRoundedIcon sx={{ color: "darkgrey", fontSize: "1.125rem" }} />}>
                                            <AlertTitle sx={{ mb: 0.2 }}><Text variant="small">Why {selectedPaymentMethodData.label}?</Text></AlertTitle>
                                            <Text variant="small" sx={{ color: "#5B6871" }}>{selectedPaymentMethodData.helperText}</Text>
                                        </Alert>
                                    </Stack>
                                ) : null}
                            </Stack>

                            {/* payment method options */}
                            {formData.paymentMethod === "upi" ? (
                                <>
                                    <Stack direction="row" justifyContent="flex-end" onClick={this.handleUpiDrawerOpen}>
                                        <Text variant="small" sx={{ color: "#2B70C5", cursor: "pointer" }}>How to find UPI ID?</Text>
                                    </Stack>
                                    <Stack mt={2} mb={3}>
                                        <Stack direction="row">
                                            <TextField
                                                label="Enter your UPI ID"
                                                value={formData.paymentMethodHandle}
                                                onChange={(e) => this.handleFormChange({ name: "paymentMethodHandle", value: e.target.value })}
                                                error={formError.paymentMethodHandle}
                                                helperText={formError.paymentMethodHandle}
                                                InputProps={{
                                                    sx: { border: 0 }
                                                }}
                                            />
                                            <JarvisButton
                                                size="small"
                                                sx={{
                                                    marginLeft: "10px",
                                                    maxHeight: "47px",
                                                }}
                                                variant="contained"
                                                disableEndIcon
                                                onClick={this.handleUpiVerification}
                                            >
                                                Verify
                                            </JarvisButton>
                                        </Stack>
                                        {
                                            this.state.verifiedUpi ?
                                                <Stack mt={1} direction="row">
                                                    <CheckCircleRoundedIcon
                                                        sx={{ fontSize: { xs: "14px", md: "22px" }, mt: { xs: "4px", md: "unset" }, color: "#45AC70" }}
                                                    />
                                                    <Text ml={1} variant="small" sx={{ color: "#45AC70" }}>Verified Successfully</Text>
                                                </Stack>
                                                : null
                                        }
                                    </Stack>
                                </>
                            ) : null}

                            {/* bank account details */}
                            {this.isTPVEnabled() || formData.paymentMethod === "emandate" ? (
                                <>
                                    <Stack mb={2}>
                                        <TextField
                                            select
                                            label={`Please choose ${selectedPaymentMethodData.label} option`}
                                            value={formData.paymentMethodOption}
                                            onChange={(e) => this.handleFormChange({ name: "paymentMethodOption", value: e.target.value })}
                                            error={formError.paymentMethodOption}
                                            helperText={formError.paymentMethodOption}
                                            InputProps={{
                                                sx: { border: 0 }
                                            }}
                                        >
                                            {paymentMethodOptions.map(pmo => <MenuItem key={pmo.value} value={pmo.value}>{pmo.label}</MenuItem>)}
                                        </TextField>

                                        {selectedMethodOptionData && selectedMethodOptionData.helperText ? (
                                            <Stack>
                                                <Alert sx={{ my: 1.5 }} icon={<InfoRoundedIcon sx={{ color: "darkgrey", fontSize: "1.125rem" }} />}>
                                                    <AlertTitle sx={{ mb: 0.2 }}><Text variant="small">Note: {selectedMethodOptionData.label}</Text></AlertTitle>
                                                    <Text variant="small" sx={{ color: "#5B6871" }}>{selectedMethodOptionData.helperText}</Text>
                                                </Alert>
                                            </Stack>
                                        ) : null}
                                    </Stack>
                                    <Stack mb={2}>
                                        <Stack direction="row">
                                            <Text variant="body1" sx={{ fontWeight: "500", mr: 0.5 }}>
                                                Account type
                                            </Text>
                                            <TooltipHover
                                                title="According to SEBI guidelines, you should give an eMandate only from your bank account. If it doesn't match your account number, your payment will be declined."
                                                sx={{ lineHeight: 0, marginLeft: "3px" }}
                                            >
                                                <InfoRoundedIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                                            </TooltipHover>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" sx={{ flexWrap: "wrap", mb: 2 }}>
                                            {accountTypes.map((at, index) => (
                                                <Stack key={at.value} direction="row" alignItems="center" sx={{ mr: index === accountTypes.length - 1 ? 0 : 3 }}>
                                                    <Radio name="accountType" checked={at.value === formData.accountType} onChange={() => this.handleFormChange({ name: "accountType", value: at.value })} />
                                                    <Text component="label" sx={{ color: at.value === formData.accountType ? '#2B70C5' : '#212225' }}>{at.label}</Text>
                                                </Stack>
                                            ))}
                                        </Stack>

                                        <TextField
                                            sx={{ mb: 2 }}
                                            label="Beneficiery name"
                                            value={formData.accountHolderName}
                                            onChange={(e) => this.handleFormChange({ name: "accountHolderName", value: e.target.value })}
                                            error={formError.accountHolderName}
                                            helperText={formError.accountHolderName}
                                        />

                                        <TextField
                                            sx={{ mb: 2 }}
                                            type="number"
                                            label="Account number"
                                            value={formData.accountNumber}
                                            onChange={(e) => this.handleFormChange({ name: "accountNumber", value: e.target.value })}
                                            error={formError.accountNumber}
                                            helperText={formError.accountNumber}
                                        />

                                        <TextField
                                            label="IFSC code"
                                            value={formData.ifscCode}
                                            onChange={(e) => this.handleFormChange({ name: "ifscCode", value: e.target.value })}
                                            error={formError.ifscCode}
                                            helperText={formError.ifscCode}
                                        />
                                    </Stack>
                                </>
                            ) : null}

                            {/* payment auth types */}
                            {formData.paymentMethodOption && authTypeOptions.length ? (
                                <Stack mb={2}>
                                    <Text variant="body1" sx={{ fontWeight: "500" }}>Choose verification method for {selectedPaymentMethodData.label}</Text>
                                    <Stack direction="row" alignItems="center" sx={{ flexWrap: "wrap" }}>
                                        {authTypeOptions.map((ato, index) => (
                                            <Stack key={ato.value} direction="row" alignItems="center" sx={{ mr: index === authTypeOptions.length - 1 ? 0 : 3 }}>
                                                <Radio name="authType" checked={ato.value === formData.authType} onChange={() => this.handleFormChange({ name: "authType", value: ato.value })} />
                                                <Text component="label" sx={{ color: ato.value === formData.authType ? '#2B70C5' : '#212225' }}>{ato.label}</Text>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            ) : null}

                            <Stack sx={{ mb: 2, display: { xs: 'none', md: 'flex' } }}>
                                <JarvisButton
                                    variant="contained"
                                    disabled={
                                        (formData.paymentMethod === 'emandate' || this.state.verifiedUpi) ? false : true
                                    }
                                    disableEndIcon onClick={this.handlePayNow}>
                                    Pay {formatHelper.currency(payableAmount)}
                                </JarvisButton>
                            </Stack>

                        </Grid>

                        <Grid item xs={12} md={5}>
                            {this.props.enableCouponDiscount && this.state.appliedCouponData.isValid ? (
                                <>
                                    <CouponCodeRow
                                        disableRemoveCoupon={true}
                                        onActionTrigger={this.handleCouponAction}
                                        viewTypes={couponViewTypes}
                                        activeViewType={this.state.couponActiveViewType}
                                        formData={this.state.couponFormData}
                                        formError={this.state.couponFormError}
                                        discountValue={this.state.appliedCouponData.discountPrice}
                                    />

                                    <Divider sx={{ my: 2 }} />
                                </>
                            ) : null}

                            <Text variant="body1" sx={{ fontWeight: "medium", mb: { xs: 2, md: 1 } }}>Price Details</Text>
                            <PaymentSummary
                                selectedPlan={selectedPlan}
                                planType={this.getPlanType(selectedPlan?.planFrequency)}
                                appliedCouponData={this.state.appliedCouponData}
                                paymentType ={paymentType}
                                upgradePlanAmount = {upgradedPlanDiscountedPrice?upgradedPlanDiscountedPrice:''}
                            />

                            <Stack sx={{
                                background: "unset",
                                width: { xs: "100%", md: "unset" },
                                justifyContent: { xs: "center", md: "unset" },
                                alignItems: { xs: "center", md: "unset" },
                                }}>
                                <Stack
                                    sx={{
                                        position: { xs: "fixed", md: "unset" },
                                        bottom: { xs: "10px", md: "unset" },
                                        width: { xs: "100%", md: "unset" },
                                        backgroundColor: { xs: 'unset', md: 'none' },
                                        padding: { xs: "10px 14px", md: "0px" },
                                        paddingBottom: { xs: "calc(var(--safe-area-inset-bottom) + 12px) !important" },
                                    }}>
                                    <MobileResponsive
                                    mobile={()=>(
                                    <JarvisButton
                                    variant="contained"
                                    disabled={(formData.paymentMethod === 'emandate' || this.state.verifiedUpi) ? false : true}
                                    disableEndIcon
                                    onClick={this.handlePayNow}
                                >
                                    Pay {formatHelper.currency(payableAmount)}
                                </JarvisButton>
                                )}
                                web={()=>(<></>)}
                                />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>

                    <UpiDrawer
                        open={upiDrawerOpen}
                        onClose={this.handleUpiDrawerClose}
                    />

                </Stack>
            )
        }

        return (
            <>{activeScreenType} is not defined</>
        )
    }
}

const style = (theme) => ({
    btns: {
        backgroundColor: '#fff !important',
        color: '#212225',
        border: '1px solid #d3d3d37a',
        padding: '8px 18px',
        marginBottom: '7px',
        marginRight: '7px',
        fontSize: '0.9rem',
        fontWeight: 500,
        minWidth: '11ch',
        boxShadow: 'none',
    },
    active: {
        color: '#2B70C5 !important',
        backgroundColor: '#EEF4FA !important',
        border: '0px !important',
    },
    planPrice: {
        lineHeight: "1.1",
        fontSize: "1.5rem",
        fontWeight: "600"
    }
})

export default withRouter(withStyles(style, { withTheme: true })(PlanPaymentContainer));
