import { call, put, takeLatest } from "@redux-saga/core/effects";

import { jarvisOnestockDashboardConstants } from "./jarvisOnestockDashboardConstants";
import { jarvisOnestockDashboardParser } from "./jarvisOnestockDashboardParser"
import { jarvisOnestockDashboardApis } from "./jarvisOnestockDashboardApis";

function* dashboardStatsSaga() {
    try {
        yield put({ type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_REQUEST });
        const res = yield call(jarvisOnestockDashboardApis.getDashboardStatsApi);
        const payload = jarvisOnestockDashboardParser.onestockDashboardStats(res);
        yield put({
            type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_RESPONSE,
            payload,
        });
    } catch (error) {
        yield put({
            type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_RESPONSE,
            payload: {},
        });
    }
}

function* dashboardRevealSaga() {
    try {
        yield put({ type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_REQUEST });
        const res = yield call(jarvisOnestockDashboardApis.getDashboardRevealApi);
        const payload = jarvisOnestockDashboardParser.dashboardReveal(res);
        yield put({
            type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_RESPONSE,
            payload,
        });
    } catch (error) {
        yield put({
            type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_RESPONSE,
            payload: {},
        });
    }
}


export function* registerJarvisOnestockDashboardSagas() {

    yield takeLatest(
        jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_SAGA,
        dashboardStatsSaga
    );

    yield takeLatest(
        jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_SAGA,
        dashboardRevealSaga
    );

}
