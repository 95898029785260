import _get from "lodash/get";

const onboardingParsers = {};

onboardingParsers.onboardingData = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        riskMasterId: _get(res, "riskMasterId", null),
        riskProfileName: _get(res, "riskProfile", null),
        suggestedRiskId: _get(res, "suggestedRiskId", null),
        investmentAmount: _get(res, "investmentAmount", null),
        minInvestmentAmount: _get(res, "minimumInvestmentAmount", null),
        minimumAdditionalInvestAmount: _get(res, "minimumAdditionalInvestAmount", null),
        investmentTenure: _get(res, "investmentDuration", null),
        investmentStrategy: _get(res, "investmentStrategy", null),
        isPaymentCompleted: _get(res, "paymentStatus", false) ? 1 : 0,
        currentStage: _get(res, "currentStage", null),
        isOnboardingCompleted: _get(res, "isOnboardingCompleted", null),
        portfolioGeneratedDate: _get(res, "portfolioGeneratedDate", null),
        expiryDate: _get(res, "expiryDate", null),
        dateOfBirth: _get(res, "dob", null),
        pan: _get(res, "pancardNumber", null),
        planId: _get(res, "planId", null),
        hasOnboardingInvestmentDetailsConfirmed: _get(res, "hasOnboardingInvestmentDetailsConfirmed", 0),
        customerSubscriptionId: _get(res, "customerSubscriptionId", null),
        isMobileVerified: _get(res, "isMobileVerified", null),
    };
};

onboardingParsers.portfolioData = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    const parsePortfolio = (portfolio) =>
        portfolio.map((p) => ({
            allocation: _get(p, "allocation", null),
            symbol: _get(p, "symbol", null),
            stockQuantity: _get(p, "stockQuantity", null),
            stockName: _get(p, "stockName", null),
            currentValue: _get(p, "currentValue", null),
            sector: _get(p, "sector", null),
            marketCap: _get(p, "marketCap", null),
        }));

    const parseMarketBreakup = (market) =>
        market.map((m) => ({
            label: _get(m, "cap", null),
            value: _get(m, "value", null),
        }));

    const parseSectorBreakup = (sector) =>
        sector.map((m) => ({
            label: _get(m, "sector", null),
            value: _get(m, "value", null),
        }));

    return {
        portfolioData: parsePortfolio(_get(res, "portfolio", [])),
        marketBreakupData: parseMarketBreakup(_get(res, "marketBreakup", [])),
        sectorBreakupData: parseSectorBreakup(_get(res, "sectorBreakup", [])),
    };
};

onboardingParsers.pricingData = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        investmentAmount: _get(res, "investmentAmount", null),
        feesPercentage: _get(res, "halfYearlyFeesPercentage", null),
        feesGSTPercentage: _get(res, "gstPercentage", null),
        feesTenure: _get(res, "feesTenure", null),
        fees: _get(res, "halfYearlyFees", null),
        feesGST: _get(res, "halfYearlyFeesGst", null),
        feesTotal: _get(res, "halfYearlyFeesTotal", null),
        discountedAmount: _get(res, "discountedAmount", null),
        discountedPercentage: _get(res, "discountedPercentage", null)
    };
};

onboardingParsers.sendEmailVerificationLink = (res) => {
    if (res && res.response) {
        res = res.response;
    }
    
    if (!res) {
        return {};
    }

    return {
        message: _get(res, "message", ""),
        magicLink: _get(res, "result.magicLink", ""),
    }
}

export { onboardingParsers };
