import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { portfolioHoldingConstants } from "./portfolioHoldingConstants";

const initialState = {
    portfolioHoldingList: [],
    loading: {
        portfolioHoldingList: 0,
    },
};

export const portfolioHoldingReducers = persistReducer(
    {
        storage,
        key: "portfolioHolding",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {

            case portfolioHoldingConstants.PORTFOLIO_HOLDING_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfolioHoldingList: 1,
                    },
                    portfolioHoldingList: initialState.portfolioHoldingList,
                };
            }

            case portfolioHoldingConstants.PORTFOLIO_HOLDING_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfolioHoldingList: 0,
                    },
                    portfolioHoldingList: payload,
                };
            }

            default:
                return state;
        }
    }
);
