/* eslint-disable no-throw-literal */
import React from "react";
import _get from "lodash.get";
import { withRouter } from "react-router-dom";
import { Checkout } from 'capacitor-razorpay';

import Text from "components/common/Text";
import Loader from "components/common/Loader";

import JarvisCard from "components/JarvisCard";
import JarvisDialog from "components/JarvisDialog";

import { config } from "config";
import { injectHelper } from "helper/inject";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import { thirdpartyParsers } from "stores/thirdparty/thirdpartyParsers";
import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";

const PAYMENT_STATUS = {
    FAILED: "FAILED",
    SUCCEED: "SUCCEED",
    CANCELLED: "CANCELLED",
    INPROGRESS: "INPROGRESS"
}

const PAYMENT_SUCCESS_MESSAGE = {
    initial: {
        title: "Congrats!!!",
        content: "Now you can view all the stocks allocated in your portfolio.",
        actionText: "View Portfolio",
        icon: "/images/svgs/success.gif"
    },
    default: {
        title: "Congrats!!!",
        content: "Payment successfull",
        actionText: "Continue",
        icon: "/images/svgs/success.gif"
    },
    takingTime: {
        title: "In Progress",
        content: "Please wait for 5-10 minutes until the transaction is processed. If your payment is not processed contact Jarvis Support.",
        actionText: "Continue",
        icon: "/images/svgs/not-confirmed.gif"
    },

}

const PAYMENT_ERROR_MESSAGE = {
    initial: {
        title: "Oops!!!",
        content: "Something went wrong! Please try again later",
        actionText: "Go Back",
        icon: "/images/svgs/failed.gif"
    },
    default: {
        title: "Oops!!!",
        content: "Something went wrong! Please try again later",
        actionText: "Go Back",
        icon: "/images/svgs/failed.gif"
    }
}

const PAYMENT_CANCELLED_MESSAGE = {
    initial: {
        title: "Cancelled",
        content: "Please complete initial payment to proceed with profile completion",
        actionText: "Go Back",
        icon: "/images/svgs/failed.gif"
    },
    default: {
        title: "Cancelled",
        content: "Please complete initial payment to proceed with profile completion",
        actionText: "Go Back",
        icon: "/images/svgs/failed.gif"
    }
}


const PAYMENT_INPROGRESS_MESSAGE = {
    initial: {
        title: "In Progress",
        content: "Your payment is under process please wait for sometime",
        actionText: "",
        icon: "/images/svgs/inprogress.gif"
    },
    default: {
        title: "In Progress",
        content: "Your payment is under process please wait for sometime",
        actionText: "",
        icon: "/images/svgs/inprogress.gif"
    }
}

class SubscriptionPaymentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogContent: {
                icon: "",
                title: "",
                content: "",
                actionText: "",
            },
            dialogOpen: false,
            paymentStatus: "",
            isLoading: false,
            isPaymentInProcess: false,
            hasSameAmount: this.props.location.state.hasSameAmount ?? false,
            paymentType: this.props.location.state.paymentType,
        }
    }

    componentDidMount() {
        const planId = _get(this.props.location, 'state.planId', null);
        const productCode = _get(this.props.location, "state.productCode", null);
         // alert(`productCode, planId =>${JSON.stringify({ productCode, planId })}`);
        if (planId && productCode) {
            this.initProcess({ productCode, planId });
        } else {
            this.handleError({ reason: "Payment details not found", origin: "componentDidMount > not found = planId || productCode" })
        }
    }

    // Init pay process: from didMount
    initProcess = async ({ productCode, planId }) => {
        try {
            this.setState({ isLoading: true });
            // if (["RMS", "JRMS"].includes(this.props.location.state.productCode)) {
            //     const initPaymentResponse = await jarvisRMSOnboardingApis.createRMSSubscription({ planId: this.props.location.state.planId });
            //     response = jarvisRMSOnboardingParser.rmsSubscription(initPaymentResponse);
            // }

            let response = await thirdpartyApis.createSubscription({ productCode, planId });
            response = thirdpartyParsers.createSubscription(response);

            const { gateway, gatewaySubscriptionId, customerSubscriptionId } = response;

            if (gateway === config.payment.gateway.razorpay) {

                if (config.isMobileApp) {
                    await this.initMobileRazorpay({
                        gatewaySubscriptionId,
                        customerSubscriptionId
                    });
                } else {
                    await this.initRazorpay({
                        gatewaySubscriptionId,
                        customerSubscriptionId
                    });
                }
                return;
            }

            this.handleError({ reason: "Payment gateway not found", origin: "initProcess > not found = gateway" });
        } catch (error) {
            this.handleError({ reason: "Something went wrong! Please try again later!", origin: "initProcess > catch block", error });
        } finally {
            this.setState({ isLoading: false })
        }
    }

    // Web: Init Razorpay 
    initRazorpay = async ({ gatewaySubscriptionId, customerSubscriptionId }) => {
        try {
            const { loginProfile } = this.props;

            await injectHelper.script({ src: config.payment.razorpay.scriptURI });

            const rzp = new window.Razorpay({
                subscription_id: gatewaySubscriptionId,
                prefill: {
                    name: loginProfile.name,
                    email: loginProfile.email,
                    contact: loginProfile.mobile
                },
                handler: (r) => {
                    // show pending dialog and then show success popup
                    // having this timeout to allow server to process razorpay callback
                    this.setState({
                        isPaymentInProcess: true,
                        dialogOpen: true,
                        paymentStatus: PAYMENT_STATUS.INPROGRESS,
                        dialogContent: PAYMENT_INPROGRESS_MESSAGE["default"],
                    });

                    setTimeout(() => {
                        this.handleSuccess({ customerSubscriptionId, paymentId: r.razorpay_payment_id, orderId: r.razorpay_order_id, paymentSignature: r.razorpay_signature, rawResponse: r });
                    }, 5000)
                },

                key: config.payment.razorpay.key,
                name: config.companyName,
                image: config.companyLogo,
                modal: {
                    escape: false,
                    confirm_close: true,
                    ondismiss: this.handleCancel,
                }
            });

            rzp.on('payment.failed', ({ error }) => this.handleError({ reason: error.reason, origin: "initRazorpay > payment failed", error }))
            rzp.open();
        } catch (error) {
            this.handleError({ reason: "Something went wrong! Please try again later!", origin: "initRazorpay > catch block", error });
        }
    }

    // App: Init Razorpay
    initMobileRazorpay = async ({ gatewaySubscriptionId, customerSubscriptionId }) => {
        try {
            const { loginProfile } = this.props;

            // option config for Android
            let options = {
                subscription_id: gatewaySubscriptionId,
                prefill: {
                    name: loginProfile.name,
                    email: loginProfile.email,
                    contact: loginProfile.mobile
                },
                key: config.payment.razorpay.key,
                name: config.companyName,
                image: config.companyLogo,
                modal: {
                    escape: false,
                    confirm_close: true,
                    ondismiss: this.handleCancel,
                }
            }
            
            // option config for IOS
            if(config.isMobileApp && config.platform === "IOS"){
                delete options.modal.ondismiss;
            }

            // alert(`initMobileRazorpay options ${JSON.stringify(options)}`)

            const data = await Checkout.open(options)
            // alert(`initMobileRazorpay data ${JSON.stringify(data)}`)
            const r = data.response;
            this.setState({
                isPaymentInProcess: true,
                dialogOpen: true,
                paymentStatus: PAYMENT_STATUS.INPROGRESS,
                dialogContent: PAYMENT_INPROGRESS_MESSAGE["default"],
            });

            setTimeout(() => {
                this.handleSuccess({ customerSubscriptionId, paymentId: r.razorpay_payment_id, orderId: r.razorpay_order_id, paymentSignature: r.razorpay_signature, rawResponse: r });
            }, 5000)
        } catch (error) {
            // alert(`initMobileRazorpay error ${JSON.stringify(error)}`)
            let res = JSON.parse(error.message);
            let message = res.error.description;
            this.handleError({
                reason: (message || "Something went wrong! Please try again later!"),
                origin: "initMobileRazorpay > catch block", error
            });
        }
    }

    // scenario: payment completed successfully
    handleSuccess = async ({ customerSubscriptionId }) => {
        try {
            // const planId = _get(this.props.location, 'state.planId', null);
            const productCode = _get(this.props.location, "state.productCode", null);
            // await thirdpartyApis.captureSubscription({ productCode, planId, customerSubscriptionId });

            const checkPayStatus = (delayTime) => {
                let statusRes;
                return new Promise(
                    (paid) => {
                        setTimeout(async () => {
                            // try {
                            //     if (["RMS", "JRMS"].includes(this.props.location.state.productCode)) {
                            //         const apiRes = await jarvisRMSOnboardingApis.captureRMSSubscription({ customerSubscriptionId, planId: this.props.location.state.planId });
                            //         statusRes = jarvisRMSOnboardingParser.captureRMSSubscription(apiRes);
                            //     }
                            // } catch (e) {
                            // } finally {
                            //     paid(statusRes);
                            // }
                            const res = await thirdpartyApis.checkSubscriptionStatus({ productCode, customerSubscriptionId });
                            statusRes = thirdpartyParsers.checkSubscriptionStatus(res);
                            paid(statusRes);
                        }, (delayTime))
                    }
                )
            }

            let isPaid = false;
            let checkResponce;
            try {
                checkResponce = await checkPayStatus(10000);
                isPaid = (checkResponce.subscriptionStatus.toLowerCase() === "activated") ? true : false;
                if (!isPaid) {
                    checkResponce = await checkPayStatus(5000);
                    isPaid = (checkResponce.subscriptionStatus.toLowerCase() === "activated") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(5000);
                    isPaid = (checkResponce.subscriptionStatus.toLowerCase() === "activated") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(5000);
                    isPaid = (checkResponce.subscriptionStatus.toLowerCase() === "activated") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(5000);
                    isPaid = (checkResponce.subscriptionStatus.toLowerCase() === "activated") ? true : false;
                }

            } catch (e) {
            }

            // Set popup state.
            if (isPaid) {
                const portfolioId = _get(this.props, 'rmsOnboardingData.portfolioId', null);
                if (checkResponce.subscriptionStatus.toLowerCase() === "activated") {
                    await jarvisRMSOnboardingApis.confirmPortfolioStock({ portfolioId });
                }
                this.setState({
                    isPaymentInProcess: false,
                    dialogOpen: true,
                    paymentStatus: PAYMENT_STATUS.SUCCEED,
                    dialogContent: PAYMENT_SUCCESS_MESSAGE["default"],
                });
            } else {
                this.setState({
                    isPaymentInProcess: false,
                    dialogOpen: true,
                    paymentStatus: PAYMENT_STATUS.SUCCEED,
                    dialogContent: PAYMENT_SUCCESS_MESSAGE["takingTime"],
                });

            }
        } catch (error) {
            this.handleError({ reason: "Something went wrong! Please try again later!", origin: "handleSuccess > catch block", error });
        } finally {
            // empty
        }
    }

    // scenario: payment failed and error raised during any phase
    handleError = ({ reason, origin, error }) => {
        if (origin && error) {
            console.error(`an error occured origin => ${origin}, error =>`, error);
        }

        const dialogContent = PAYMENT_ERROR_MESSAGE["default"];
        if (reason) {
            dialogContent.content = (<Text>{reason}</Text>);
        }

        this.setState({
            dialogOpen: true,
            paymentStatus: PAYMENT_STATUS.FAILED,
            dialogContent,
        })
    }

    // scenario: payment cancelled by user
    handleCancel = () => {
        this.setState({
            dialogOpen: true,
            paymentStatus: PAYMENT_STATUS.CANCELLED,
            dialogContent: PAYMENT_CANCELLED_MESSAGE["default"]
        })
    }

    handleDialogClose = () => {
        const currentStatus = this.state.paymentStatus;

        let returnURI = _get(this.props.location, "state.returnURI", "/customer/overview");

        // set returnURI on secceed 
        const returnURIOnSuccess = _get(this.props.location, "state.returnURIOnSuccess", "");
        if (returnURIOnSuccess && currentStatus === "SUCCEED") {
            returnURI = returnURIOnSuccess;
        }

        this.setState({ isPaymentInProcess: true });
        setTimeout(
            () => {
                this.setState({ dialogOpen: false, isPaymentInProcess: false }, () => {
                    this.props.history.replace({
                        pathname: returnURI,
                        state: {
                            paymentStatus: currentStatus
                        }
                    });
                });
                // console.log("URI & Delay => ", returnURI, " & ", returnDelayMiliSec);

                // Overflwo issue after cancell payment on onBoarding flow.
                setTimeout(() => {
                    document.body.style.overflow = "unset";
                }, 1500);
            }, 6000)

    }

    render() {
        const {
            dialogOpen,
            dialogContent,
            isLoading,
            isPaymentInProcess
        } = this.state;
        return (
            <JarvisCard>
                <Loader loading={isLoading} />

                <Text>Process payment here...</Text>

                <JarvisDialog
                    disableCloseIcon
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    onConfirm={this.handleDialogClose}
                    title={dialogContent.title}
                    content={dialogContent.content}
                    icon={{
                        src: dialogContent.icon,
                        height: 120,
                        width: 120
                    }}
                    button={{
                        text: dialogContent.actionText,
                        loading: isPaymentInProcess,
                    }}
                />
            </JarvisCard>

        )
    }
}

export default withRouter(SubscriptionPaymentContainer);
