import React from "react";

import MChip from "@mui/material/Chip";
import { alpha } from "@mui/material";

import { makeStyles, createStyles } from "@mui/styles";

const JarvisChip = (props) => {
    const { label, icon = null, variant = "contained", color = 'default', ...restProps } = props;
    const classes = useStyles();

    return (
        <>
            <MChip
                label={label}
                icon={icon}
                variant={variant}
                classes={{
                    root: classes[color],
                }}
                {...restProps}
            />
        </>
    );
};


const useStyles = makeStyles((theme) =>
    createStyles({
        success: {
            backgroundColor: alpha(theme.palette.success.main, 0.08),
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
        },
        warning: {
            backgroundColor: alpha(theme.palette.warning.main, 0.08),
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
        },
        error: {
            backgroundColor: alpha(theme.palette.error.main, 0.08),
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
        default: {
            backgroundColor: alpha(theme.palette.grey[900], 0.08),
            color: theme.palette.grey[900],
            borderColor: theme.palette.grey[900],
        },
        primary: {
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        info: {
            backgroundColor: "rgba(222, 222, 222, 1)",
            color: "#676E76",
        },
        marketCap: {
            backgroundColor: "#fff",
            color: "#A2A7AE",
            border: "1px solid #DEDEDE"
        },
    })
);

export default React.memo(JarvisChip);
