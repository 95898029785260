import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";

import TextField from "components/common/TextField";
import Grid from "components/common/Grid";
import Text from "components/common/Text";
import InputAdornment from "components/common/InputAdornment";
import Modal from "components/common/Modal";
import Loader from "components/common/Loader";

import JarvisDialog from "components/JarvisDialog";
import SectionTitle from "components/SectionTitle";
import ChooseBrokerIcon from "components/Onboarding/ChooseBrokerIcon";
import RMSSyncPortfolioUpload from "components/RMSSyncPortfolio/RMSSyncPortfolioUpload";
import RMSSyncPortfolioUploadForm from "components/RMSSyncPortfolio/RMSSyncPortfolioUploadForm";
import RMSSyncPortfolioModal from "components/RMSSyncPortfolio/RMSSyncPortfolioModal";

import { validationHelper } from "helper/validation";
import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";
import { config } from "config";
import JarvisCard from "components/JarvisCard";
import Stack from "components/common/Stack";


const successMessage = {
  title: "Awesome!",
  content: "Your portfolio has been uploaded successfully.",
  actionText: "Go to Portfolio",
  icon: "/images/svgs/success.gif",
};

const REDIRECTION_URI = {
  EXISTING_HOLDING: {
    returnURI: "/jarvis-rms/existing-holding/add-portfolio",
    goBackURI: "/jarvis-rms/existing-holding/add-portfolio",
  },
  ONBOARDING: {
    returnURI: "/jarvis-rms/onboarding/sync-portfolio",
    goBackURI: "/jarvis-rms/onboarding/overview",
  }
}

class RMSSyncPortfolioContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        portfolioName: "",
        brokerId: "",
        customerBrokerId: "",
        portfolioAttachment: null,
      },
      formError: {
        portfolioName: "",
        brokerId: "",
      },
      isValidFile: true,
      fileSizeValidationError: false,
      selectedFile: null,
      searchTerm: "",
      isUploadModalOpen: false,
      isUploadLoading: false,

      isSyncPortfolioModalOpen: false,
      syncPortfolioNameError: '',
      syncPortfolioName: "",
      // brokerEnvironment: false
    }
  }

  componentDidMount() {
    if (!this.props.brokerList?.length) {
      this.props.getBrokerListAction({ isFetchHoldingEnabled: 1, productCode: config.productCodes.RMS });
    }

    if (!this.props.masterBrokers.length && !this.props.isMasterBrokersInitialCalled) {
      this.props.getMasterBrokersAction({ productCode: config.productCodes.RMS });
    }
  }

  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value })
  }

  handleUploadModalOpen = () => {
    this.setState({ isUploadModalOpen: true });
  }

  handleUploadModalClose = () => {
    this.setState({
      isUploadModalOpen: false,
      selectedFile: null,
      isUploadLoading: false,
      formData: {
        portfolioName: "",
        brokerId: "",
        customerBrokerId: "",
        portfolioAttachment: null,
      }
    });
  };

  // handleBrokerEnvironment = () =>{
  //   this.setState({ brokerEnvironment: true })
  // }

  filterBrokerList = () => {
    const { brokerList = [] } = this.props;
    const { searchTerm } = this.state;
    return brokerList?.filter(b => b.brokerDisplayName.toLowerCase().includes(searchTerm.toLowerCase()) || b.brokerName.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  formatBrokerListData = (brokerList) => {
    let data = brokerList?.map((data) => ({
      label: data?.brokerDisplayName,
      value: data?.brokerId,
    }));
    return data;
  }

  getSelectedValue = (data) => {
    const { brokerId, ...restBrokerData } = this.state.formData;
    this.setState({ formData: { brokerId: data.value, ...restBrokerData } });
  }

  handleValidation = () => {
    const { formError, formData } = this.state;
    const nameValidation = validationHelper.portfolioName(formData.portfolioName);
    formError.portfolioName = nameValidation.message;

    const brokerIdValidation = validationHelper.required(formData.brokerId);
    formError.brokerId = brokerIdValidation.message;

    this.setState({ formError });
    return (
      nameValidation.isValid && brokerIdValidation.isValid
    );
  };

  handleUploadFormSubmit = async () => {
    if (!this.handleValidation()) {
      return;
    }

    const { formData } = this.state;
    this.setState({ isUploadLoading: true });
    try {
      const payloadFormdata = new FormData();
      payloadFormdata.append("brokerId", formData.brokerId);
      payloadFormdata.append("customerBrokerId", formData.customerBrokerId);
      payloadFormdata.append("portfolioName", formData.portfolioName);
      payloadFormdata.append("portfolioAttachment", formData.portfolioAttachment);

      let res = await jarvisRMSOnboardingApis.uploadPortfolio(payloadFormdata);
      if (res.result) {
        this.props.updatePortfolioId({ portfolioId: res.result.portfolioId });
        this.props.getOnboardingDataAction();
        this.setState({ isUploadModalOpen: false, isPortfolioUploaded: true, isUploadLoading: false });
      }
    }
    catch (error) {
      this.setState({ isUploadLoading: false });
    }
  }

  handleFormChange = ({ name, value }) => {
    let { formData } = this.state;
    formData[name] = value;
    this.setState({ formData });
  };

  handleChange = (e) => {
    const file = e.target.files[0];
    const validExtensions = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "csv",
    ];
    const fileExtension = file.type;
    if (!validExtensions.includes(fileExtension)) {
      return alert("please upload a valid file");
    }

    const validFileSize = this.fileSizeValidation(e.target.files[0]);
    const { formData } = this.state;
    formData.portfolioAttachment = e.target.files[0];
    if (validFileSize) {
      this.setState({ selectedFile: e.target.files[0], formData });
    } else {
      this.setState({ fileSizeValidationError: !false });
    }
  };

  deleteSelectedFile = () => {
    this.setState({
      selectedFile: null, formData: {
        portfolioName: "",
        brokerId: "",
        customerBrokerId: "",
        portfolioAttachment: null,
      }
    });
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  fileSizeValidation = (e) => {
    let size = e.size;      // byte
    size = e.size / 1000;   // KB

    if (size <= config.RMSUploadFileSize) {
      this.setState({ isValidFile: true });
      return true;
    }

    this.setState({ isValidFile: false });
    return false;
  };

  handleSyncPortfolioValue = (e) => {
    this.setState({ syncPortfolioName: e.target.value });
  };

  handleSave = async () => {
    if (!this.handleValidationAddPortfolioName()) {
      return;
    }

    try {
      await jarvisRMSOnboardingApis.updateSyncPortfolioName({
        portfolioName: this.state.syncPortfolioName,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleScreenChange = () => {
    const { screenType } = this.props;
    this.props.handleScreenChange(screenType.CONFIRM_PORTFOLIO);
  }

  handleBrokerClick = (broker) => {
    // this.handleBrokerEnvironment()
    // setTimeout(()=>{
    this.props.history.push({
      pathname: "/customer/broker",
      state: {
        returnURI: REDIRECTION_URI[this.props.processType].returnURI,
        goBackURI: REDIRECTION_URI[this.props.processType].goBackURI,
        brokerName: broker.brokerName,
        brokerPlatform: broker.brokerPlatform,
        productCode: config.productCodes.RMS,
        executionType: config.broker.executionType.FETCH_HOLDING,
        brokerId: broker.brokerId,
      }
    });
  // },2000)
  };

  render() {
    const { searchTerm, isUploadModalOpen, isUploadLoading, isPortfolioUploaded } = this.state;
    const { classes, isBrokerListLoading, isBrokerListInitialCalled, protectPortfolioUploadTemplate, masterBrokers, centrumReferalURL } = this.props;
    const filteredBrokerList = this.filterBrokerList();
    const centrumBroker = filteredBrokerList.filter((value)=>{
      return value.brokerName === 'centrum'
    });
    const isCentrumTextVisible = !searchTerm || "centrum".includes(searchTerm)
    return (
      <JarvisCard sx={{padding:{xs:"0px", md:2}}}>
        <SectionTitle
          sx={{ mb: 3, mt: 3 }}
          heading="Sync your portfolio"
          subheading="Select a broker from the list below or upload manually."
        />

        <TextField className={classes.root}
          InputProps={{
            startAdornment: <InputAdornment position="start" sx={{ pl: 1 }}><img alt="" src="/svgs/search_icon.svg"></img></InputAdornment>,
          }}
          placeholder="Search"
          value={searchTerm}
          onChange={this.handleSearchChange}
        />

        <RMSSyncPortfolioUpload
          downloadSampleFileLink={protectPortfolioUploadTemplate}
          handleUploadModalOpen={this.handleUploadModalOpen}
        />
        <>
         <Text variant="medium" sx={{ fontWeight: 500, mb: "20px", mt:"20px" }}>
              List of Brokers
        </Text>
        <Grid
              container
              spacing={2.5}
              sx={{
                justifyContent: "flex-start",
                flex: 1,
                alignItems: "center",
                mb: { xs: "-1.25rem", md: "-0.5rem" },
                gap:"2rem",
                paddingBottom: "25px",
                marginBottom: "20px",
              }}
        >
        {isBrokerListInitialCalled && centrumBroker.length ? (
                centrumBroker.map((b, index) => (
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={3} key={index}>
                    <ChooseBrokerIcon
                      image={b.brokerIcon}
                      title={b.brokerDisplayName}
                      onClick={() => this.handleBrokerClick(b)}
                    />
                  </Grid>
                ))
              ) : (
                <></>
              )}
              {isCentrumTextVisible && <Stack direction={{xs:"row",md:"column"}}
              justifyContent={{xs:"space-between", md:"flex-start"}}
              width={{xs:"100%",md:"fit-content"}}
              pl={{xs:"1.25rem",md:0}}
              >
              <Text
                variant="small"
                sx={{ fontWeight: 700, placeSelf: "center" }}
              >
                Don't have a Centrum account?
              </Text>
              <Text
                variant="small"
                sx={{
                  fontWeight: 500,
                  color: "#2B70C5",
                  cursor: "pointer",
                  "&::selection": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={() => {
                  window.open(centrumReferalURL, "_blank");
                }}
              >
                Open New Account
              </Text>
              </Stack>}
              <Stack direction="row" ml="1.25rem" width="100%" border="1px solid #F5F5F5"></Stack>
            </Grid>
            <Grid container spacing={2.5} sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center", mb: { xs: 8, md: 2.5 }, mt: { xs: 1, md: 0 } }}>
          {(isBrokerListLoading || !isBrokerListInitialCalled) && <Loader />}
          {isBrokerListInitialCalled && !filteredBrokerList.length ? (
            <Grid item>
              <Text>Broker with search term <b>{searchTerm}</b> not found/integrated yet. Please try with manual update.</Text>
            </Grid>
          ) : filteredBrokerList.map((b, index) => (
            <>
            {
              b.brokerName === 'centrum' ? (<></>) :
            (
              <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={index}>
              <ChooseBrokerIcon
                image={b.brokerIcon}
                title={b.brokerDisplayName}
                onClick={() => this.handleBrokerClick(b)}
              />
            </Grid>
            )
            }
            </>
          ))
          }
        </Grid>
        </>
{/*         <Grid container spacing={2.5} sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center", mb: { xs: 8, md: 2.5 }, mt: { xs: 4, md: 2.5 } }}>
          {(isBrokerListLoading || !isBrokerListInitialCalled) && <Loader />}
          {isBrokerListInitialCalled && !filteredBrokerList.length ? (
            <Grid item>
              <Text>Broker with search term <b>{searchTerm}</b> not found/integrated yet. Please try with excel upload.</Text>
            </Grid>
          ) : filteredBrokerList.map((b, index) => (
            <Grid item xs={6} sm={4} md={3} lg={2.4} xl={2} key={index}>
              <ChooseBrokerIcon
                image={b.brokerIcon}
                title={b.brokerDisplayName}
                onClick={() => this.handleBrokerClick(b)}
              />
            </Grid>
          ))
          }
        </Grid> */}

        <Modal
          size={"md"}
          open={isUploadModalOpen}
          onClose={this.handleUploadModalClose}
          sx={{ p: 2 }}
          disableClose
        >
          <Loader loading={isUploadLoading} />
          <RMSSyncPortfolioUploadForm
            downloadSampleFileLink={protectPortfolioUploadTemplate}
            brokerList={this.formatBrokerListData(masterBrokers)}
            getData={(d) => this.getSelectedValue(d)}
            onSubmit={() => this.handleUploadFormSubmit()}
            formData={this.state.formData}
            formError={this.state.formError}
            selectedFile={this.state.selectedFile}
            onChange={this.handleChange}
            onFormChange={this.handleFormChange}
            onDelete={this.deleteSelectedFile}
            isValidFile={this.state.isValidFile}
            onClose={this.handleUploadModalClose}
          />
        </Modal>

        <Modal open={this.state.isSyncPortfolioModalOpen} onClose={() => this.setState({ syncPortfolioName: '', syncPortfolioNameError: '' })} disableCroseButton={false} title={<Text sx={{ textAlign: "left" }} variant="subtitle2">Name Your Portfolio</Text>}>
          <RMSSyncPortfolioModal
            syncPortfolioName={this.state.syncPortfolioName}
            error={this.state.syncPortfolioNameError}
            onChange={this.handleSyncPortfolioValue}
            handleSave={this.handleSave}
            formError={this.state.formError}
          />
        </Modal>

        <JarvisDialog
          disableCloseIcon
          open={isPortfolioUploaded}
          onClose={this.handleScreenChange}
          onConfirm={this.handleScreenChange}
          title={successMessage.title}
          content={successMessage.content}
          icon={{
            src: successMessage.icon,
            height: 120,
            width: 120,
          }}
          button={{
            text: successMessage.actionText,
            disableEndIcon: true,
          }}
        />
        {/* Pop-up for showing leaving jarvis website to broker website */}
{/*         <Modal
          open={this.state.brokerEnvironment}
          disableClose={true}
          size={"sm"}
          sx={{ width:"100%", height:"75%" }}
        >
        <Text sx={{ mt: {xs:"75%", sm: "35%"}, mb: 0.8, fontSize: "22px", textAlign: "center" }}>You are leaving our environment</Text>
        </Modal> */}
      </JarvisCard>  
    )
  }
}

const style = (theme) => ({
  root: {
    boxShadow: "none",
    border: '0px',
    "& .MuiInputBase-input": {
      padding: '12px 7px !important',
      boxShadow: "none !important",
      backgroundColor: "#db709300!important",
    },

    "& .MuiInputBase-root": {
      backgroundColor: " #F5F6F8 !important",
      border: '0px',

      "& .MuiInputAdornment-root": {
        marginTop: '0px !important',
      },
    },
  }
});

export default withStyles(style, { withTheme: true })(withRouter(RMSSyncPortfolioContainer));
