import React from "react";

import RiskAssessmentContainer from "containers/Onboarding/RiskAssessment";

const PortfolioRiskAssessment = (props) => {
    return (
        <>
            <RiskAssessmentContainer />
        </>
    )
};

export default PortfolioRiskAssessment;