import React, { Component } from "react";

//Common Components
import JarvisCard from "components/JarvisCard";
import Divider from "components/common/Divider";
import PageTitle from "components/PageTitle";
import NotAvailableData from "components/JarvisOnestock/NotAvailableData";
import { config } from "config/index";

// Onestock Action Center Store
import OnestockActionCenterWebCard from "components/JarvisOnestock/OnestockActionCenterWebCard";
import OnestockActionCenterMobCard from "components/JarvisOnestock/OnestockActionCenterMobCard";
import MobileResponsive from "components/common/MobileResponsive";

export default class OnestockActionCenterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedData: {},
    };
  }

  componentDidMount() {
    this.props.getOnestockActionCenterDataAction();
      this.props.getBrokerListAction({ productCode: config.productCodes.ONESTOCK });
  }

  handleProceedOnestock = (data) => {
    const selectedData = { ...data };
    const newStockData = selectedData.stockData.map((item) => {
      return {
        isSelected: true,
        ...item,
      };
    });
    selectedData.stockData = newStockData;
    this.setState({
      selectedData: selectedData,
    });
  };

  render() {

    const { oneStockActionCenterDataStore } = this.props

    return (
      <>
        <PageTitle heading="Action Centre" disableBreadcrumb />

        <MobileResponsive
          web={() =>
            <JarvisCard sx={{ px: 0, py: 0 }}>
              {oneStockActionCenterDataStore.length === 0 ? (
                <NotAvailableData
                  actionType="ACTIONCENTRE"
                />
              ) : null}

              {oneStockActionCenterDataStore.length > 0 ? (
                <>
                  {
                    oneStockActionCenterDataStore.map((data, index) => {
                      if (data.actionType === "BUY") {
                        return (
                          <React.Fragment key={`${index}_`}>
                            <OnestockActionCenterWebCard
                              handleProceedOnestockWeb={(data) => this.handleProceedOnestock(data)}
                              actionStockData={data}
                              buyCall
                              brokerList={this.props.brokerList}
                              isBrokerListLoading={this.props.isBrokerListLoading}
                              isBrokerListInitialCalled={this.props.isBrokerListInitialCalled}
                              pendingAction={()=>this.props.pendingCallAction()}
                              centrumReferalURL={this.props.centrumReferalURL}
                            />
                            {index + 1 < oneStockActionCenterDataStore.length && (
                              <Divider sx={{ mt: 2, mb: 2 }} />
                            )}
                          </React.Fragment>
                        );
                      }
                      else if (data.actionType === "SELL") {
                        return (
                          <React.Fragment key={`${index}_`}>
                            <OnestockActionCenterWebCard
                              handleProceedOnestockWeb={(data) => this.handleProceedOnestock(data)}
                              actionStockData={data}
                              sellCall
                              brokerList={this.props.brokerList}
                              isBrokerListLoading={this.props.isBrokerListLoading}
                              isBrokerListInitialCalled={this.props.isBrokerListInitialCalled}
                              pendingAction={()=>this.props.pendingCallAction()}
                              centrumReferalURL={this.props.centrumReferalURL}
                            />
                            {index + 1 < oneStockActionCenterDataStore.length && (
                              <Divider sx={{ mt: 2, mb: 2 }} />
                            )}
                          </React.Fragment>
                        );
                      }
                      return null
                    })
                  }
                </>
              ) : null}

            </JarvisCard>
          }
          mobile={() =>
            <JarvisCard sx={{ p: 1 }}>
              {oneStockActionCenterDataStore.length === 0 ? (
                <NotAvailableData
                  actionType="ACTIONCENTRE"
                />
              ) : null}

              {oneStockActionCenterDataStore.length > 0 ? (
                <>
                  {
                    oneStockActionCenterDataStore.map((data, index) => {
                      if (data.actionType === "BUY") {
                        return (
                          <React.Fragment key={`${index}_`}>
                            <OnestockActionCenterMobCard
                              handleProceedOnestockWeb={(data) => this.handleProceedOnestock(data)}
                              actionStockData={data}
                              buyCall
                              brokerList={this.props.brokerList}
                              isBrokerListLoading={this.props.isBrokerListLoading}
                              isBrokerListInitialCalled={this.props.isBrokerListInitialCalled}
                              pendingAction={()=>this.props.pendingCallAction()}
                              centrumReferalURL={this.props.centrumReferalURL}
                            />
                            {index + 1 < oneStockActionCenterDataStore.length && (
                              <Divider sx={{ mt: 2, mb: 2 }} />
                            )}
                          </React.Fragment>
                        );
                      }
                      else if (data.actionType === "SELL") {
                        return (
                          <React.Fragment key={`${index}_`}>
                            <OnestockActionCenterMobCard
                              handleProceedOnestockWeb={(data) => this.handleProceedOnestock(data)}
                              actionStockData={data}
                              sellCall
                              brokerList={this.props.brokerList}
                              isBrokerListLoading={this.props.isBrokerListLoading}
                              isBrokerListInitialCalled={this.props.isBrokerListInitialCalled}
                              pendingAction={()=>this.props.pendingCallAction()}
                              centrumReferalURL={this.props.centrumReferalURL}
                            />
                            {index + 1 < oneStockActionCenterDataStore.length && (
                              <Divider sx={{ mt: 2, mb: 2 }} />
                            )}
                          </React.Fragment>
                        );
                      }
                      return null
                    })
                  }
                </>
              ) : null}

            </JarvisCard>
          }

        />

      </>
    );
  }
}
