import React from "react";

import Stack from "components/common/Stack";
import Avatar from "components/common/Avatar";
import Header from "components/Layout/Header";
import Sidebar from "components/Layout/Sidebar";

import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import PortfolioIcon from '@mui/icons-material/BusinessCenterOutlined';
import JarvisProtectIcon from '@mui/icons-material/ShieldOutlined';
import OneStockIcon from '@mui/icons-material/ShowChartOutlined';
import KnowledgeCentreIcon from '@mui/icons-material/ImportContactsOutlined';
import RMSRebalanceAlert from "containers/Dashboard/DashboardOnboarded/RMSRebalanceAlert";
import PendingActionCalls from "components/PendingActionCalls";

// import RMSIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import InvestMoreIcon from "@mui/icons-material/StackedLineChartOutlined";
// import TransactionIcon from "@mui/icons-material/StickyNote2Outlined";
// import BlogIcon from "@mui/icons-material/EventNoteOutlined";
// import MarketUpdateIcon from "@mui/icons-material/ArticleOutlined";
// import NewsArticleIcon from "@mui/icons-material/MenuBookOutlined";
// import HelpSupportIcon from "@mui/icons-material/SupportOutlined";
import { brokerHelper } from "helper/broker";

// tracker
import { tracker } from "library/tracker";

// config
import { config } from "config";

// storage
import { localStore } from "stores/localStorage";
import { masterApis } from "stores/master/masterApis";
import { onboardingApis } from "stores/onboarding/onboardingApis";
import { onboardingParsers } from "stores/onboarding/onboardingParsers";
import { validationHelper } from "helper/validation";
import { customerApis } from "stores/customer/customerApis";
import { withRouter } from "react-router-dom";

// Static Data: Routes
let PRODUCT_ROUTES = {
    [config.productCodes.PORTFOLIO]: "jarvis-portfolio",
    [config.productCodes.RMS]: "jarvis-rms",
    [config.productCodes.ONESTOCK]: "jarvis-onestock"
}


// const portfolioid = 1;

const routes = {
    // customer routes
    overview: `/customer/overview`,
    referJarvis: `/customer/refer-jarvis`,
    profile: `/customer/profile`,
    profileSubscription: `/customer/profile/subscription`,
    editProfile: `/customer/profile/edit-profile`,
    payment: `/customer/payment`,
    broker: `/customer/broker`,
    documentSigning: `/customer/document-signing`,

    // jarvis portfolio
    portfolioDashboard: `/jarvis-portfolio/dashboard`,
    portfolioActionCentre: `/jarvis-portfolio/action-centre`,
    portfolioInvestMore: `/jarvis-portfolio/invest-more`,
    portfolioStartSIP: `/jarvis-portfolio/start-sip`,
    portfolioTransaction: `/jarvis-portfolio/transactions`,

    // jarvis portfolio onboarding
    portfolioOnboarding: `/jarvis-portfolio/onboarding`,
    portfolioRiskAssessment: "/jarvis-portfolio/onboarding/risk-assessment",
    portfolioInvestmentPreference: "/jarvis-portfolio/onboarding/investment-preference",
    portfolioCreation: "/jarvis-portfolio/onboarding/portfolio-creation",
    portfolioCKYC: "/jarvis-portfolio/onboarding/ckyc",
    portfolioChooseBroker: "/jarvis-portfolio/onboarding/choose-broker",

    // jarvis protect (rms)
    rmsDashboard: `/jarvis-rms/dashboard`,
    rmsActionCentre: `/jarvis-rms/action-centre`,
    rmsExistingHolding: `/jarvis-rms/existing-holding`,
    rmsExistingHoldingById: `/jarvis-rms/existing-holding/:portfolioid`,
    rmsTransactions: `/jarvis-rms/transactions`,

    // jarvis protect (rms) onboarding
    rmsOnboarding: `/jarvis-rms/onboarding`,
    rmsRiskAssessment: "/jarvis-rms/onboarding/risk-assessment",
    rmsSyncPortfolio: "/jarvis-rms/onboarding/sync-portfolio",
    rmsCKYC: "/jarvis-rms/onboarding/ckyc",

    // one stock
    onestockDashboard: `/jarvis-onestock/dashboard`,
    onestockActionCentre: `/jarvis-onestock/action-centre`,
    onestockExistingHolding: `/jarvis-onestock/existing-holding`,
    onestockTransaction: `/jarvis-onestock/transactions`,
    onestockOnboarding: `/jarvis-onestock/onboarding`,

    // knowledge center
    knowledgeCentreBlogs: `/knowledge-centre/blogs`,
    knowledgeCentreMarketUpdates: `/knowledge-centre/market-updates`,
    knowledgeCentreNewsArticles: `/knowledge-centre/news-articles`,
    knowledgeCentreNewsArticlesById: `/knowledge-centre/news-articles/:articleId`,
    knowledgeCentreHelpSupport: `/knowledge-centre/help-support`,
};

const dropdowns = {
    portfolio: [
        routes.portfolioDashboard,
        routes.portfolioActionCentre,
        routes.portfolioInvestMore,
        routes.portfolioStartSIP,
        routes.portfolioTransaction,

        routes.portfolioOnboarding,
        routes.portfolioRiskAssessment,
        routes.portfolioInvestmentPreference,
        routes.portfolioCreation,
        routes.portfolioCKYC,
        routes.portfolioChooseBroker
    ],
    rms: [
        routes.rmsDashboard,
        routes.rmsActionCentre,
        routes.rmsExistingHolding,
        routes.rmsExistingHoldingById,
        routes.rmsTransactions,

        routes.rmsOnboarding,
        routes.rmsRiskAssessment,
        routes.rmsSyncPortfolio,
        routes.rmsCKYC,
    ],
    onestock: [
        routes.onestockDashboard,
        routes.onestockActionCentre,
        routes.onestockExistingHolding,
        // routes.onestockExistingHoldingById,
        routes.onestockTransaction,
        routes.onestockOnboarding,
    ],
    knowledgeCentre: [
        routes.knowledgeCentreBlogs,
        routes.knowledgeCentreMarketUpdates,
        routes.knowledgeCentreNewsArticles,
        routes.knowledgeCentreNewsArticlesById,
        routes.knowledgeCentreHelpSupport
    ]
}

class LayoutWebContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerExpanded: false,

            isNotificationDropdownOpen: false,
            notificationAnchorEl: null,
            openPendingCall: false,
            isEditEmailModal: false,
            isEditMobileModal: false,
            isVerifyMobileModal: false,
            choosePortfolioModalOpen: false,
            actionType: '',
            formData: {
                email: this.props.emailId,
                emailError: '',
                mobile: this.props.mobileNumber,
                mobileError: '',
                mobileOtp: '',
            },
        };
    }

    componentDidMount() {
        this.updateDrawerToggle();
        window.addEventListener("resize", this.updateDrawerToggle.bind(this));

        // Change Active product based on initial path
        const urlPath = this.props.location.pathname;
        Object.keys(PRODUCT_ROUTES).some(p => {
            if (urlPath.search(PRODUCT_ROUTES[p]) !== -1) {
                localStore.setActiveProduct(p);
                this.setState({ activeProduct: p });
                return true;
            }
            return false;
        });

        this.props.pendingCallAction();
    }

    updateDrawerToggle = () => {
        if (window.innerWidth > 1200) {
            this.setState({ isDrawerExpanded: true });
        } else {
            this.setState({ isDrawerExpanded: false });
        }
    };

    handleDrawerToggle = (isDrawerExpanded) => {
        this.setState({ isDrawerExpanded });
    };

    handleNotificationOpen = (e) => {
        this.setState({
            notificationAnchorEl: e.currentTarget,
            isNotificationDropdownOpen: true,
        });
    };

    handleNotificationClose = () => {
        this.setState({
            notificationAnchorEl: null,
            isNotificationDropdownOpen: false,
        });
    };

    handleLogoutClick = async () => {
        await masterApis.updateLastActiveProduct();
        this.props.logoutAction();
        localStore.removeTakeTureToken();
        localStore.removeSSOCustomer();
        localStore.removeSsoClientId();
        setTimeout(() => {
            this.props.history.replace("/login");
        }, 1000);
    };

    handleSidebarRedirection = (pathname) => {
        if (window.innerWidth < 900) {
            this.handleDrawerToggle(false);
        }
        if (pathname === routes.investMore) {
            // NOTE: event_tracker => investMore 
            tracker.investMore.investMoreTab();
        }

        if (pathname === routes.marketUpdates) {
            // NOTE : event_tracker => marketUpdate
            tracker.marketUpdate.marketUpdate();
        }

        if (pathname === routes.helpSupport) {
            // NOTE : event_tracker => helpAndSupport
            tracker.helpAndSupport.helpAndSupportViewAll();
        }
        this.props.history.push(pathname);
    };

    isActiveRoute = (pathnames = []) => {
        if (pathnames.filter(p => this.props.location.pathname.includes(p)).length || pathnames.includes(this.props.location.pathname)) {
            return true
        }
        return false;
    }

    getSidebarMenuConfig = () => {
        const {
            isOnboardingCompleted,
            isRMSOnboardingCompleted,
            isOnestockOnboardingCompleted,
            loginProfile,
            profilePic,
            activeProductList,
        } = this.props;

        const sidebarMenu = []
        if (activeProductList.includes(config.productCodes.PORTFOLIO)) {
            if (isOnboardingCompleted) {
                sidebarMenu.push({
                    type: "dropdown",
                    primaryText: "Jarvis Portfolio",
                    icon: (
                        <PortfolioIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute(dropdowns.portfolio)
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    isActive: this.isActiveRoute(dropdowns.portfolio),
                    children: [{
                        type: "item",
                        primaryText: "Dashboard",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.portfolioDashboard])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.portfolioDashboard),
                        isActive: this.isActiveRoute([routes.portfolioDashboard]),
                    }, {
                        type: "item",
                        primaryText: "Action Centre",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.portfolioActionCentre])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.portfolioActionCentre),
                        isActive: this.isActiveRoute([routes.portfolioActionCentre]),
                        isDisabled: !isOnboardingCompleted,
                    },
                    {
                        type: "item",
                        primaryText: "Invest More",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.portfolioInvestMore])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.portfolioInvestMore),
                        isActive: this.isActiveRoute([routes.portfolioInvestMore]),
                        isDisabled: !isOnboardingCompleted,
                    },
                    {
                        type: "item",
                        primaryText: "Transactions",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.portfolioTransaction])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.portfolioTransaction),
                        isActive: this.isActiveRoute([routes.portfolioTransaction]),
                        isDisabled: !isOnboardingCompleted,
                    },]
                })
            } else {
                sidebarMenu.push({
                    type: "item",
                    primaryText: "Jarvis Portfolio",
                    icon: (
                        <PortfolioIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute(dropdowns.portfolio)
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    onClick: () => this.handleSidebarRedirection(routes.portfolioDashboard),
                    isActive: this.isActiveRoute([routes.portfolioDashboard]),
                })
            }
        }

        if (activeProductList.includes(config.productCodes.RMS)) {
            if (isRMSOnboardingCompleted) {
                sidebarMenu.push({
                    type: "dropdown",
                    primaryText: "Jarvis Protect",
                    icon: (
                        <JarvisProtectIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute(dropdowns.rms)
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    isActive: this.isActiveRoute(dropdowns.rms),
                    children: [{
                        type: "item",
                        primaryText: "Dashboard",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.rmsDashboard])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.rmsDashboard),
                        isActive: this.isActiveRoute([routes.rmsDashboard]),
                    }, {
                        type: "item",
                        primaryText: "Action Centre",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.rmsActionCentre])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.rmsActionCentre),
                        isDisabled: !isRMSOnboardingCompleted,
                        isActive: this.isActiveRoute([routes.rmsActionCentre]),
                    },
                    {
                        type: "item",
                        primaryText: "Holdings",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.rmsExistingHolding, routes.rmsExistingHoldingById])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.rmsExistingHolding),
                        isDisabled: !isRMSOnboardingCompleted,
                        isActive: this.isActiveRoute([routes.rmsExistingHolding, routes.rmsExistingHoldingById]),
                    },
                    {
                        type: "item",
                        primaryText: "Transactions",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.rmsTransactions])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.rmsTransactions),
                        isDisabled: !isRMSOnboardingCompleted,
                        isActive: this.isActiveRoute([routes.rmsTransactions]),
                    },]
                })
            } else {
                sidebarMenu.push({
                    type: "item",
                    primaryText: "Jarvis Protect",
                    icon: (
                        <JarvisProtectIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute(dropdowns.rmsDashboard)
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    onClick: () => this.handleSidebarRedirection(routes.rmsDashboard),
                    isActive: this.isActiveRoute([routes.rmsDashboard]),
                })
            }
        }

        if (activeProductList.includes(config.productCodes.ONESTOCK)) {
            if (isOnestockOnboardingCompleted) {
                sidebarMenu.push({
                    type: "dropdown",
                    primaryText: "Jarvis One Stock",
                    icon: (
                        <OneStockIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute(dropdowns.onestock)
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    isActive: this.isActiveRoute(dropdowns.onestock),
                    children: [{
                        type: "item",
                        primaryText: "Dashboard",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.onestockDashboard])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.onestockDashboard),
                        isActive: this.isActiveRoute([routes.onestockDashboard]),
                    }, {
                        type: "item",
                        primaryText: "Action Centre",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.onestockActionCentre])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.onestockActionCentre),
                        isDisabled: !isOnestockOnboardingCompleted,
                        isActive: this.isActiveRoute([routes.onestockActionCentre]),
                    },
                    {
                        type: "item",
                        primaryText: "Holdings",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.onestockExistingHolding])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.onestockExistingHolding),
                        isDisabled: !isOnestockOnboardingCompleted,
                        isActive: this.isActiveRoute([routes.onestockExistingHolding]),
                    },
                    {
                        type: "item",
                        primaryText: "Transactions",
                        icon: (
                            <DashboardIcon
                                sx={{
                                    fontSize: 18,
                                    color: this.isActiveRoute([routes.onestockTransaction])
                                        ? "primary.main"
                                        : "",
                                }}
                            />
                        ),
                        onClick: () => this.handleSidebarRedirection(routes.onestockTransaction),
                        isDisabled: !isOnestockOnboardingCompleted,
                        isActive: this.isActiveRoute([routes.onestockTransaction]),
                    },]
                })
            } else {
                sidebarMenu.push({
                    type: "item",
                    primaryText: "Jarvis One Stock",
                    icon: (
                        <OneStockIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute(dropdowns.onestockDashboard)
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    onClick: () => this.handleSidebarRedirection(routes.onestockDashboard),
                    isActive: this.isActiveRoute([routes.onestockDashboard]),
                })
            }
        }

        sidebarMenu.push(
            {
                type: "dropdown",
                primaryText: "Knowledge Centre",
                icon: (
                    <KnowledgeCentreIcon
                        sx={{
                            fontSize: 18,
                            color: this.isActiveRoute(dropdowns.knowledgeCentre)
                                ? "primary.main"
                                : "",
                        }}
                    />
                ),
                isActive: this.isActiveRoute(dropdowns.knowledgeCentre),
                children: [{
                    type: "item",
                    primaryText: "Blog",
                    icon: (
                        <DashboardIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute([routes.knowledgeCentreBlogs])
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    onClick: () => this.handleSidebarRedirection(routes.knowledgeCentreBlogs),
                    isActive: this.isActiveRoute([routes.knowledgeCentreBlogs]),
                }, {
                    type: "item",
                    primaryText: "Market Updates",
                    icon: (
                        <DashboardIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute([routes.knowledgeCentreMarketUpdates])
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    onClick: () => this.handleSidebarRedirection(routes.knowledgeCentreMarketUpdates),
                    isActive: this.isActiveRoute([routes.knowledgeCentreMarketUpdates]),
                },
                {
                    type: "item",
                    primaryText: "News & Articles ",
                    icon: (
                        <DashboardIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute([routes.knowledgeCentreNewsArticles, routes.knowledgeCentreNewsArticlesById])
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    onClick: () => this.handleSidebarRedirection(routes.knowledgeCentreNewsArticles),
                    isActive: this.isActiveRoute([routes.knowledgeCentreNewsArticles, routes.knowledgeCentreNewsArticlesById]),
                },
                {
                    type: "item",
                    primaryText: "Help & Support ",
                    icon: (
                        <DashboardIcon
                            sx={{
                                fontSize: 18,
                                color: this.isActiveRoute([routes.knowledgeCentreHelpSupport])
                                    ? "primary.main"
                                    : "",
                            }}
                        />
                    ),
                    onClick: () => this.handleSidebarRedirection(routes.knowledgeCentreHelpSupport),
                    isActive: this.isActiveRoute([routes.knowledgeCentreHelpSupport]),
                }]
            },
            {
                type: "spacer",
            },
            {
                type: "divider",
            },
            {
                type: "item",
                primaryText: loginProfile.name,
                secondaryText: loginProfile.email,
                avatar: (
                    <Avatar
                        sx={{
                            width: 32,
                            height: 32,
                        }}
                        src={profilePic}
                        variant="body1"
                        title={loginProfile.name}></Avatar>
                ),
                onClick: () => this.handleSidebarRedirection(routes.profile),
                isDisabled: !(isOnboardingCompleted || isRMSOnboardingCompleted || isOnestockOnboardingCompleted),
                isActive: this.isActiveRoute([routes.profile]),
            }
        )

        return sidebarMenu;
    }

    handlePendingActions = (name) => {
        if (name === "open") {
            this.setState({ openPendingCall: true })
        }
        else if (name === "close") {
            this.setState({ openPendingCall: false })
        }
        return
    }

    handleInputChange = ({ name, value }) => {
        const { formData } = this.state;
        formData[name] = value;
        this.setState({ formData });
    };

    handleEditEmailModal = (val, actionType) => {
        const { formData } = this.state
        formData.emailError = ''
        actionType === 'EDIT' ? this.setState({ isEditEmailModal: val, actionType: actionType, })
            : actionType === 'CANCEL' ? this.setState({ isEditEmailModal: val, actionType: actionType, email: this.props.emailId, formData })
                : this.setState({ actionType: actionType }, () => this.handleSendLink());
    }

    handleEmailValidation = () => {
        const { formData } = this.state;
        const emailValidation = validationHelper.email(formData.email);
        formData.emailError = emailValidation.message
        this.setState({ formData })
        return emailValidation.isValid;
    }

    handleMobileValidation = () => {
        const { formData } = this.state;
        const mobileValidation = validationHelper.mobile(formData.mobile);
        formData.mobileError = mobileValidation.message
        this.setState({ formData })
        return mobileValidation.isValid;
    }

    handleMobileModal = (action) => {
        const { formData } = this.state
        if (action === 'open') {
            this.setState({ isEditMobileModal: true })
        }
        else if (action === 'close') {
            formData.mobileError = ''
            this.setState({ isEditMobileModal: false, formData })
        }
        else if (action === 'otp-close') {
            this.setState({ isVerifyMobileModal: false })
        }
    }

    handleMobileOtp = async () => {
        const { formData } = this.state
        if (!this.handleMobileValidation()) {
            return;
        }
        try {
            const res = await customerApis.mobileRequestOtp({
                mobile: formData.mobile,
            })
            this.props.setToast({ message: res.message });
            formData.mobileError = ''
            this.setState({ isEditMobileModal: false, isVerifyMobileModal: true, formData })
        }
        catch (err) {
            this.props.setToast({ message: err.data.message });
        }
    }

    handleVerifyOtp = async () => {
        const { formData } = this.state
        try {
            const res = await customerApis.verifyMobileOtp({
                otp: formData.mobileOtp
            })
            this.props.setToast({ message: res.message });
            this.setState({ isVerifyMobileModal: false, formData })
            this.props.pendingCallAction();
        }
        catch (err) {
            this.props.setToast({ message: err.data.message });
        }
    }

    handleSendLink = async () => {
        const { formData, actionType } = this.state;
        const email = formData.email
        if (!this.handleEmailValidation()) {
            return;
        }
        try {
            const res = await onboardingApis.sendEmailVerificationLink({ actionType, email });
            const parsedRes = onboardingParsers.sendEmailVerificationLink(res);
            if (actionType === "EDIT") {
                this.props.updateEmailAction({ email })
            }
            this.props.setToast({ message: parsedRes.message });
            formData.emailError = ''
            this.setState({ isEditEmailModal: false, formData });
        }
        catch (err) {
            this.props.setToast({ message: err.data.message });
        }
    }

    toggleChoosePortfolioModal = () => {
        if (this.props.portfolioAlert.hasExpired) {
            this.setState((prev) => {
                return { choosePortfolioModalOpen: !prev.choosePortfolioModalOpen };
            });
            //event-tracker: Partial Buy/Sell -> invest_more_now
            tracker.partialBuySell.investMoreNow();
            this.props.getPortfolioPendingTransactionAction();
        } else {
            this.props.history.push("/jarvis-portfolio/action-centre");
        }
    };

    setArchiveModalClose = () => {
        this.setState({ choosePortfolioModalOpen: false })
    }

    componentWillUnmount() {
        window.removeEventListener(
            "resize",
            this.updateDrawerToggle.bind(this)
        );
    }

    checkIsPendingActionAllowed = () => {
        const currentRoute = this.props.history.location.pathname

        const notAllowedRoutes = [
            routes.knowledgeCentreBlogs,
            routes.knowledgeCentreMarketUpdates,
            routes.knowledgeCentreNewsArticles,
            routes.knowledgeCentreNewsArticlesById,
            routes.knowledgeCentreHelpSupport,
            routes.profile,
            routes.profileSubscription,
            routes.editProfile,
            routes.overview,
            routes.referJarvis,
            routes.payment,
            routes.broker,
            routes.documentSigning,
        ]
        if (notAllowedRoutes.includes(currentRoute)) {
            return false
        }
        return true
    }

    render() {
        const {
            isDrawerExpanded,
            isNotificationDropdownOpen,
            notificationAnchorEl,
            openPendingCall,
            isEditEmailModal,
            formData,
            isEditMobileModal,
            isVerifyMobileModal,
        } = this.state;
        const {
            children,
            notifications = [],
            pendingCallList,
            activeProductList,
            isPendingCallListLoading,
        } = this.props;

        const brokerLogoUrl = brokerHelper.getLogoUrl();

        const sidebarMenuConfig = this.getSidebarMenuConfig();

        const isPendingActionsAllowed = this.checkIsPendingActionAllowed();

        if (brokerLogoUrl) {
            const brokerLogo = {
                type: "broker",
                logoUrl: "/jarvis-logo.svg",
            };

            sidebarMenuConfig.push(brokerLogo);
        }

        return (
            <Stack direction="row" sx={{ minHeight: "100vh" }}>
                <Sidebar
                    menuConfig={sidebarMenuConfig}
                    isDrawerExpanded={isDrawerExpanded}
                    onDrawerToggle={this.handleDrawerToggle}
                />

                <Stack
                    sx={{
                        flex: 1,
                        backgroundColor: {
                            xs: "background.paper",
                            md: "grey.100",
                        },
                        width: "100%",
                    }}>
                    <Header
                        onLogoutClick={this.handleLogoutClick}
                        onDrawerToggle={this.handleDrawerToggle}
                        isDrawerExpanded={isDrawerExpanded}
                        notifications={notifications}
                        onNotificationOpen={this.handleNotificationOpen}
                        onNotificationClose={this.handleNotificationClose}
                        isNotificationDropdownOpen={isNotificationDropdownOpen}
                        notificationAnchorEl={notificationAnchorEl}
                    />
                    {
                        isPendingActionsAllowed ?
                            <Stack px={2} py={1} sx={{ maxWidth: "100%" }} >
                                <PendingActionCalls
                                    handlePendingActions={this.handlePendingActions}
                                    openPendingCall={openPendingCall}
                                    pendingCallList={pendingCallList}
                                    activeProductList={activeProductList}
                                    isEditEmailModal={isEditEmailModal}
                                    email={formData.email}
                                    emailError={formData.emailError}
                                    mobile={formData.mobile}
                                    mobileError={formData.mobileError}
                                    handleSendLink={this.handleSendLink}
                                    handleInput={this.handleInputChange}
                                    handleEditEmailModal={this.handleEditEmailModal}
                                    handleMobileModal={this.handleMobileModal}
                                    isEditMobileModal={isEditMobileModal}
                                    isVerifyMobileModal={isVerifyMobileModal}
                                    handleMobileOtp={this.handleMobileOtp}
                                    handleVerifyOtp={this.handleVerifyOtp}
                                    mobileOtp={formData.mobileOtp}
                                    isLoading={isPendingCallListLoading}
                                    handleArchiveJourney={this.toggleChoosePortfolioModal}
                                />
                                <RMSRebalanceAlert
                                    toggleChoosePortfolioModal={this.toggleChoosePortfolioModal}
                                    choosePortfolioModalOpen={this.state.choosePortfolioModalOpen}
                                    setArchiveModalClose={this.setArchiveModalClose}
                                />
                            </Stack>
                            : null
                    }
                    <Stack
                        sx={{
                            position: "relative",
                            flex: 1,
                            maxWidth: "100%",
                            px: { xs: 2, md: 3 },
                            py: 0,
                            pb: { xs: 2, md: 3 },
                        }}>
                        {children}
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}

export default withRouter(LayoutWebContainer);
