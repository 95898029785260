import * as React from "react";

import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import { dateTimeHelper } from "helper/dateTime";
import JarvisCard from "./JarvisCard";


export default function NewsArticleCard({ title, caption, date, onCardClick}) {
    return (
        <Box sx={{mb:"15px"}}>
            <JarvisCard sx={{
                    border: {xs:"1px solid #e4e4e4", md: "none"}, 
                    padding: {xs: "15px 12px", sm: "20px"},
                    cursor: "pointer"
                    }} 
                    onClick={onCardClick}>
                <Stack direction="column">
                    <Text
                        variant="small"
                        sx={{ 
                            typography: { md: "subtitle2" },
                            color:'#202020', 
                            mb: "10px",
                            fontWeight: "500" }}
                        component="div">
                        {title}
                    </Text>

                    <Text 
                        variant="caption1"
                        sx={{ typography: { md: "body2" }, mb:"10px"}}>
                        {caption}
                    </Text>

                    <Text 
                        variant="body2"
                        fontSize="12px"
                        color={{xs:"#707070",md:"#929292"}}>
                        
                        By Admin  <span style={{borderLeft: "1px solid #929292", margin:"0 7px"}} />  {dateTimeHelper.format(date)}
                    </Text>
                </Stack>
            </JarvisCard>
        </Box>

        
    );
}
