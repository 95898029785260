import React from "react";
import ReferContainer from "containers/Refer";
import PageTitle from "components/PageTitle";

const Refer = () => {
    return (
        <>
            <PageTitle heading="Refer Jarvis" />
            <ReferContainer />
        </>
    )
}

export default Refer;