import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";

const mapStateToProps = (state) => {
    return {
        loginProfile: state.customer.loginProfile,
        rmsOnboardingData: state.jarvisRMS.rmsOnboarding,
        onestockOnboardingData: state.jarvisOnestock.onestockOnboarding,

    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOnboardingDataAction: onboardingActions.getOnboardingData,
    getRMSOnboardingDataAction: jarvsiRMSOnboardingActions.getOnboardingData,
    getOnestockOnboardingAction: jarvsiOnestockOnboardingActions.getOnboardingData,

}, dispatch);

const PaymentStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PaymentStore;
