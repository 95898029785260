import React from "react";
import SignUpContainer from "containers/SignUp";
import { tracker } from "library/tracker";

const SignUp = () => {
  React.useEffect(() => {
    tracker.pageView();
  }, []);

  return <SignUpContainer />;
};

export default SignUp;
