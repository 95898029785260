// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Action
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";
import { customerActions } from "stores/customer/customerActions";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return {
        // onboarding
        currentStage: state.jarvisOnestock.onestockOnboarding.currentStage,
        duplicateStages: state.jarvisOnestock.onestockOnboarding.duplicateStages,
        onestockOnboardingSteps: state.jarvisOnestock.onestockOnboarding.onestockOnboardingSteps,
        isPaymentCompleted: state.jarvisOnestock.onestockOnboarding.isPaymentCompleted,


        // user
        isEmailVerified: state.customer.loginProfile.isEmailVerified,
        emailId: state.customer.loginProfile.email,
        name: state.customer.loginProfile.name,

        // other's
        isOnboardingDataFetched: state.onboarding.isInitialFetched,
        isOnboardingDataLoading: state.onboarding.loading.onboardingData,
        isPortfolioOnboardingCompleted: state.onboarding.isOnboardingCompleted,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getOnboardingDataAction: jarvsiOnestockOnboardingActions.getOnboardingData,
            updateEmailAction: customerActions.updateEmail,
            setToast: commonActions.setToast,
            logoutAction: customerActions.logout,
        },
        dispatch
    );

const OnestockOnboardingLayoutStore = (Container) => {
    return connect(mapStateToProps, mapDispatchToProps)(Container);
};

export default OnestockOnboardingLayoutStore;
