import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { config } from "config";
import { commonConstants } from "./commonConstants";

const initialState = {
    deviceId: null,
    toast: {
        timeout: config.toastTimeout,
        open: false,
        message: "",
    },
    dialog: {
        open: false,
        message: "",
        heading: "",
        showIntercom: false
    },
    blogs: [],
    brokers: [],
    investmentStrategies: [
        {
            id: 0,
            heading: "Pure equity strategy",
            description:
                "Jarvis will construct your portfolio with only stocks, based on your risk tolerance",
        },
        {
            id: 1,
            heading: "Asset allocation strategy",
            description:
                "Jarvis will diversify across Equity, Debt, & Gold.",
        },
    ],
    riskAssessmentQuestions: [],
    riskProfiles: [],
    marketUpdates: [],
    totalArticles: 0,
    newsArticles: [],
    selectedNewsContent: {},
    appConfig: {
        forceUpdate: false,
        message: "",
        status: null,
    },
    paymentMethods: [],
    featureConfig: {
        enableRazorpayTPV: false,
        enableAccountDeactivation: false,
        protectPortfolioUploadTemplate: '',
        centrumReferalURL: '',
        enableCouponDiscount: false,
    },

    loading: {
        globalLoader: 0,
        blogs: 0,
        brokers: 0,
        riskAssessmentQuestions: 0,
        riskProfiles: 0,
        marketUpdates: 0,
        newsArticles: 0,
        paymentMethods: 0,
    },
    initialCalled: {
        globalLoader: 0,
        blogs: 0,
        brokers: 0,
        riskAssessmentQuestions: 0,
        riskProfiles: 0,
        marketUpdates: 0,
        newsArticles: 0,
        paymentMethods: 0,
    },
    isAppWelcomeScreenShown: 0,
};

export const commonReducers = persistReducer(
    {
        storage,
        key: "common",
        whitelist: ["deviceId", "riskProfiles", "riskAssessmentQuestions", "blogs", "marketUpdates", "newsArticles", "isAppWelcomeScreenShown", "paymentMethods", "featureConfig"],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case commonConstants.RESET_COMMON_DATA: {
                return {
                    ...initialState,
                    deviceId: state.deviceId,
                    isAppWelcomeScreenShown: state.isAppWelcomeScreenShown,
                };
            }

            case commonConstants.APP_WELCOME_SCREEN_SHOWN: {
                return {
                    ...state,
                    isAppWelcomeScreenShown: 1,
                }
            }

            case commonConstants.GLOBAL_SET_TOAST_DATA: {
                if (payload.message) {
                    return {
                        ...state,
                        toast: {
                            open: true,
                            message: payload.message,
                            timeout: payload.timeout || state.toast.timeout,
                        },
                    };
                } else {
                    return {
                        ...state,
                        toast: initialState.toast,
                    };
                }
            }

            case commonConstants.GLOBAL_RESET_TOAST_DATA: {
                return {
                    ...state,
                    toast: initialState.toast,
                };
            }

            case commonConstants.GLOBAL_SET_DIALOG_DATA: {
                return {
                    ...state,
                    dialog: {
                        open: true,
                        message: payload?.message || "",
                        onCloseTakeToIntercom: payload?.onCloseTakeToIntercom || false,
                        heading: payload.heading || ""
                    },
                };
            }

            case commonConstants.GLOBAL_RESET_DIALOG_DATA: {
                return {
                    ...state,
                    dialog: initialState.dialog,
                };
            }

            case commonConstants.BLOGS_REQUEST: {
                return {
                    ...state,
                    blogs: initialState.blogs,
                    loading: {
                        ...state.loading,
                        blogs: 1,
                    },
                };
            }

            case commonConstants.BLOGS_RESPONSE: {
                return {
                    ...state,
                    blogs: payload,
                    initialCalled: {
                        ...state.initialCalled,
                        blogs: 1,
                    },
                    loading: {
                        ...state.loading,
                        blogs: 0,
                    },
                };
            }

            case commonConstants.DEVICE_ID_DATA: {
                return {
                    ...state,
                    deviceId: payload,
                };
            }

            case commonConstants.RISK_ASSESSMENT_QUESTIONS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskAssessmentQuestions: 1,
                    },
                    riskAssessmentQuestions:
                        initialState.riskAssessmentQuestions,
                };
            }

            case commonConstants.RISK_ASSESSMENT_QUESTIONS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskAssessmentQuestions: 0,
                    },
                    riskAssessmentQuestions: payload,
                };
            }

            case commonConstants.RISK_PROFILES_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskProfiles: 1,
                    },
                    riskProfiles: initialState.riskProfiles,
                };
            }

            case commonConstants.RISK_PROFILES_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        riskProfiles: 0,
                    },
                    riskProfiles: payload,
                };
            }

            // Market Update
            case commonConstants.MARKET_UPDATES_REQUEST: {
                return {
                    ...state,
                    marketUpdates: initialState.marketUpdates,
                    loading: {
                        ...state.loading,
                        marketUpdates: 1,
                    },
                };
            }
            case commonConstants.MARKET_UPDATES_RESPONSE: {
                return {
                    ...state,
                    marketUpdates: payload,
                    initialCalled: {
                        ...state.initialCalled,
                        marketUpdates: 1,
                    },
                    loading: {
                        ...state.loading,
                        marketUpdates: 0,
                    },
                };
            }

            // News & Articles
            case commonConstants.NEWS_ARTICLES_CONTENT: {
                return {
                    ...state,
                    selectedNewsContent: payload,
                };
            }
            case commonConstants.NEWS_ARTICLES_REQUEST: {
                return {
                    ...state,
                    newsArticles: initialState.newsArticles,
                    loading: {
                        ...state.loading,
                        newsArticles: 1,
                    },
                };
            }
            case commonConstants.NEWS_ARTICLES_RESPONSE: {
                return {
                    ...state,
                    newsArticles: payload.newsList || initialState.newsArticles,
                    totalArticles: payload.count || initialState.totalArticles,
                    initialCalled: {
                        ...state.initialCalled,
                        newsArticles: 1,
                    },
                    loading: {
                        ...state.loading,
                        newsArticles: 0,
                    },
                };
            }
            case commonConstants.BROKERS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        brokers: 1,
                    },
                    brokers: initialState.brokers,
                };
            }

            case commonConstants.BROKERS_RESPONSE: {
                return {
                    ...state,
                    initialCalled: {
                        ...state.initialCalled,
                        brokers: 1,
                    },
                    loading: {
                        ...state.loading,
                        brokers: 0,
                    },
                    brokers: payload,
                };
            }

            // App config
            case commonConstants.APP_CONFIG_REQUEST: {
                return {
                    ...state,
                    appConfig: initialState.appConfig,
                };
            }

            case commonConstants.APP_CONFIG_RESPONSE: {
                return {
                    ...state,
                    appConfig: { ...state.appConfig, ...payload },
                };
            }

            case commonConstants.PAYMENT_METHODS_REQUEST: {
                return {
                    ...state,
                    paymentMethods: [],
                    initialCalled: {
                        ...state.initialCalled,
                        paymentMethods: 1,
                    },
                    loading: {
                        ...state.loading,
                        paymentMethods: 1,
                    }
                }
            }
            case commonConstants.PAYMENT_METHODS_RESPONSE: {
                return {
                    ...state,
                    paymentMethods: payload,
                    loading: {
                        ...state.loading,
                        paymentMethods: 0,
                    }
                }
            }

            // Feature config
            case commonConstants.FEATURE_CONFIG_REQUEST: {
                return {
                    ...state,
                    featureConfig: initialState.featureConfig,
                };
            }

            case commonConstants.FEATURE_CONFIG_RESPONSE: {
                return {
                    ...state,
                    featureConfig: {
                        ...state.featureConfig,
                        ...payload
                    },
                };
            }

            default:
                return state;
        }
    }
);
