import React from "react";

import Text from "components/common/Text";
import Grid from "components/common/Grid";
import Loader from "components/common/Loader";

import BlogCard from "components/BlogCard"
import JarvisCard from "components/JarvisCard";
import { tracker } from "library/tracker";

class BlogsContainer extends React.Component {
    componentDidMount() {
        if (!this.props.blogsData.length) {
            this.props.getBlogsAction();
        }
    }

    readMoreTrack = () => {
        // NOTE: event_tracker => blog
        tracker.blog.blogReadMore();
    }

    render() {
        const { isLoading, blogsData, isInitialCalled } = this.props;
        return (
            <>
                <Loader position="absolute" loading={isLoading} />

                {isInitialCalled && !blogsData.length ? (
                    <JarvisCard>
                        <Text>Sorry! We are unable to fetch blogs. Please try again later.</Text>
                    </JarvisCard>
                ) : null}

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    my={2}
                >
                    {this.props.blogsData.map((blogs, index) => (
                        <Grid item xs={12} sm={6} md={4}>
                            <BlogCard
                                key={`blog_${index}`}
                                title={blogs.title}
                                imageUrl={blogs.imageUrl}
                                description={blogs.description}
                                author={blogs.author}
                                readMoreLink={blogs.readMoreLink}
                                readMoreTrack={this.readMoreTrack}
                            />
                        </Grid>
                    ))}
                </Grid>
            </>
        )
    }
}

export default BlogsContainer;