import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisOnestockOnboardingConstants } from "./jarvisOnestockOnboardingConstants";

const initialState = {
    currentStage: "",
    isOnboardingCompleted: 0,
    isPaymentCompleted: 0,
    riskProfileId: "",
    panNumber: "",
    dateOfBirth: "",
    aadharNumber: "",
    isManualKYC: 0,
    isPanUploaded: 0,
    isAadhaarFrontUploaded: 0,
    isAadhaarBackUploaded: 0,
    duplicateStages: [],
    onestockOnboardingSteps: [
        "RISK_ASSESSMENT",
        "PAYMENT",
        "CKYC",
        "DOCUMENT_SIGNING",
    ],

    brokerRecords: 0,

    isInitialFetched: 0,
    loading: {
        onboardingData: 0,
    },
    initialCalled: {
        onboardingData: 0,
    },
    portfolioId: "",
};

export const jarvisOnestockOnboardingReducer = persistReducer(
    {
        storage,
        key: "jarvisOnestockOnboarding",
        blacklist: ["onestockOnboardingSteps", "isInitialFetched", "loading"],
    },

    (state = initialState, { type, payload }) => {
        switch (type) {
            case jarvisOnestockOnboardingConstants.ONESTOCK_UPDATE_ONBOARDING_DATA: {
                return {
                    ...state,
                    currentStage: payload.currentStage || state.currentStage,
                    isOnboardingCompleted:
                        payload.isOnboardingCompleted ||
                        state.isOnboardingCompleted,
                    isPaymentCompleted:
                        payload.isPaymentCompleted ||
                        state.isPaymentCompleted,

                    riskProfileId: payload.riskProfileId || state.riskProfileId,
                    panNumber: payload.panNumber || state.panNumber,
                    dateOfBirth: payload.dateOfBirth || state.dateOfBirth,
                    aadharNumber: payload.aadharNumber || state.aadharNumber,
                    isManualKYC: payload.isManualKYC || state.isManualKYC,
                    isPanUploaded: payload.isPanUploaded || state.isPanUploaded,
                    isAadhaarFrontUploaded:
                        payload.isAadhaarFrontUploaded ||
                        state.isAadhaarFrontUploaded,
                    isAadhaarBackUploaded:
                        payload.isAadhaarBackUploaded ||
                        state.isAadhaarBackUploaded,
                    duplicateStages:
                        payload.duplicateStages || state.duplicateStages,
                    isInitialFetched:
                        payload.isInitialFetched || state.isInitialFetched,
                    portfolioId: payload.portfolioId || null,
                };
            }




            case jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        onboardingData: 1,
                    },
                };
            }

            case jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_RESPONSE: {
                return {
                    ...state,
                    isInitialFetched: 1,
                    currentStage: payload.currentStage || state.currentStage,
                    isOnboardingCompleted:
                        payload.isOnboardingCompleted ||
                        state.isOnboardingCompleted,

                    isPaymentCompleted:
                        payload.isPaymentCompleted ||
                        state.isPaymentCompleted,

                    riskProfileId: payload.riskProfileId || state.riskProfileId,
                    panNumber: payload.panNumber || state.panNumber,
                    dateOfBirth: payload.dateOfBirth || state.dateOfBirth,
                    aadharNumber: payload.aadharNumber || state.aadharNumber,
                    isManualKYC: payload.isManualKYC || state.isManualKYC,
                    isPanUploaded: payload.isPanUploaded || state.isPanUploaded,
                    isAadhaarFrontUploaded:
                        payload.isAadhaarFrontUploaded ||
                        state.isAadhaarFrontUploaded,
                    isAadhaarBackUploaded:
                        payload.isAadhaarBackUploaded ||
                        state.isAadhaarBackUploaded,
                    duplicateStages:
                        payload.duplicateStages || state.duplicateStages,
                    portfolioId: payload.portfolioId || null,
                    loading: {
                        ...state.loading,
                        onboardingData: 0,
                    },
                };
            }


            case jarvisOnestockOnboardingConstants.ONESTOCK_RESET_ONBOARDING_DATA: {
                return {
                    currentStage: "",
                    isOnboardingCompleted: 0,
                    riskProfileId: "",
                    panNumber: "",
                    dateOfBirth: "",
                    aadharNumber: "",
                    isManualKYC: 0,
                    isPanUploaded: 0,
                    isAadhaarFrontUploaded: 0,
                    isAadhaarBackUploaded: 0,
                    duplicateStages: [],
                    onestockOnboardingSteps: [
                        "RISK_ASSESSMENT",
                        "SYNC_PORTFOLIO",
                        "CKYC",
                        "DOCUMENT_SIGNING",
                    ],
                    brokerRecords: 0,

                    isInitialFetched: 0,
                    loading: {
                        onboardingData: 0,
                    },
                    initialCalled: {
                        onboardingData: 0,
                    },
                    portfolioId: "",
                };
            }

            default:
                return state;
        }
    }
);
