import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { customerActions } from "stores/customer/customerActions"
import { commonActions } from "stores/common/commonActions"

const mapStateToProps = (state) => {
    const { riskProfile, riskProfileId } = state.customer.profile;

    return {

        selectedRiskProfileName: riskProfile || "",
        selectedRiskProfileId: riskProfileId,
        isProfileLoading: state.customer.loading.profile,

        riskProfileList: state.common.riskProfiles,
        isRiskProfileListLoading: state.common.loading.riskProfiles,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getProfileAction: customerActions.getProfile,
    getRiskProfilesAction: commonActions.getRiskProfiles,
    updateRiskProfilesAction: customerActions.updateRiskProfile,
}, dispatch)

const RiskProfileStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default RiskProfileStore;