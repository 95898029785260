// import { config } from "config";


const renewTracker = {}

renewTracker.renew = () => {
    // const eventName = "renew";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}

renewTracker.renewFailed  = () => {
    // const eventName = "renew_failed";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewSuccess  = () => {
    // const eventName = "renew_success";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewContinue  = () => {
    // const eventName = "renew_continue";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewContinueFailed  = ({ error_msg }) => {
    // const eventName = "renew_continue_failed";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: { error_msg }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: { error_msg }
    // });
}
renewTracker.renewContinueSuccess  = () => {
    // const eventName = "renew_continue_success";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewWithDifferentAmount  = () => {
    // const eventName = "renew_with_different_amount";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewWithDifferentAmountFailed  = () => {
    // const eventName = "renew_with_different_amount_failed";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewWithDifferentAmountSuccess = () => {
    // const eventName = "renew_with_different_amount_success";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewCalculateFees = () => {
    // const eventName = "renew_calculate_fees_";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewCalculateFeesFailed  = ({ error_msg }) => {
    // const eventName = "renew_calculate_fees_failed";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: { error_msg }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: { error_msg }
    // });
}
renewTracker.renewCalculateFeesSuccess  = () => {
    // const eventName = "renew_calculate_fees_success";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewContinue  = () => {
    // const eventName = "renew_continue";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewContinueFailed  = () => {
    // const eventName = "renew_continue_failed";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}
renewTracker.renewContinueSuccess  = () => {
    // const eventName = "renew_continue_success";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}

renewTracker.renewWithSameAmount  = () => {
    // const eventName = "renew_with_same_amount";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}

renewTracker.renewWithSameAmountFailed  = ({ error_msg }) => {
    // const eventName = "renew_with_same_amount_failed";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: { error_msg }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: { error_msg }
    // });
}
renewTracker.renewWithSameAmountSuccess  = () => {
    // const eventName = "renew_with_same_amount_success";

    // appsFlyer.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });

    // mixPanel.trackEvents({
    //     eventName,
    //     eventData: {  }
    // });
}

export { renewTracker };
