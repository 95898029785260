import React from "react";

import { useSelector } from "react-redux";
import { intercom } from "library/tracker/intercom";

import Box from "./common/Box";
import Dialog from "./common/Dialog";
import Text from "./common/Text";
import JarvisButton from "./JarvisButton";

const OfflineCheckerButton = ({ offlineButtonProps, onlineButtonProps }) => {
    const registrationType = useSelector(state => state.customer.loginProfile.registrationType)
    const [modalOpen, setModalOpen] = React.useState(false);

    if (registrationType.toUpperCase() === "ONLINE") {
        const { text, ...props } = onlineButtonProps;
        return <JarvisButton {...props}>{text}</JarvisButton>
    }

    const { text, onClick = () => setModalOpen(true), ...props } = offlineButtonProps;
    return (
        <>
            <JarvisButton {...props} onClick={onClick}>{text}</JarvisButton>

            <Dialog
                onClose={() => setModalOpen(false)}
                open={modalOpen}
                title=""
                disableCloseIcon={false}
                maxWidth="sm"
                sx={{ borderRadius: "10px" }}
                contentComponent={() => (
                    <Box sx={{ maxWidth: "480px", margin: "0 auto" }}>
                        <Text
                            variant="subtitle2"
                            sx={{ color: "#1E1E1E", lineHeight: 1.3, textAlign: "center" }}
                        >
                            You are currently marked as an offline user. Your trades are carried out by your financial advisor. Incase you wish to do the trades, you can convert to online by clicking Continue
                        </Text>
                    </Box>
                )}
                actionComponent={() => (
                    <Box mb={2} textAlign="center">
                        <JarvisButton
                            onClick={() => { setModalOpen(false); intercom.show(); }}
                            disableEndIcon={false}
                            variant="outlined"
                            m={0.5}
                        >
                            Yes, Continue
                        </JarvisButton>
                    </Box>
                )}
            />
        </>
    )
}

export default OfflineCheckerButton;