import React from "react";

import Stack from "components/common/Stack";
import JarvisButton from "components/JarvisButton";

const RMSSoldInform = (props) => {
    return (
        <Stack alignItems="center" style={{ padding: "20px" }}>
            <div >
                <div style={{ fontWeight: "bold", display: "inline-block", marginLeft: "10px", verticalAlign: "middle" }}><span > Awesome! </span></div>
            </div>

            <p style={{ fontSize: "14px", textAlign: "center" }}> <span>Stocks Sold Successfully</span> </p>
            <p style={{ marginTop: "20px", fontSize: "14px", textAlign: "center" }}>
                <span >
                    Come back day after tomorrow to execute the buying of stocks recommended by our system
                </span>
            </p>
            <Stack alignItems="center" style={{ marginTop: "25px" }} >
                <JarvisButton onClick={props.handleAlertContinueClick}>
                    Continue
                </JarvisButton>
            </Stack>

        </Stack>
    )
};

export default RMSSoldInform;
