import React from "react";

import Stack from "components/common/Stack";
import JarvisButton from "components/JarvisButton";
import Text from "components/common/Text";


const RMSSellConfirmation = (props) => {
  return (
    <Stack
      alignItems="center"
      style={{
        padding: "20px",
        margin: "0 auto -33px auto",
        position: "relative",
        top: "-23px",
        maxWidth: "330px",
      }}
    >
      <div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <img src="/images/svgs/note_icon.svg" alt="img" />
        </div>
        <div
          style={{
            fontWeight: "bold",
            display: "inline-block",
            marginLeft: "10px",
            verticalAlign: "middle",
          }}
        >
          <span> Note </span>
        </div>
      </div>

      <Text style={{ fontSize: "14px", textAlign: "center"}}>
        You will be <b>selling/buying few stocks</b> which are recommended by
        our system.
      </Text>

      {
        !props.isBuyCallEnabled ? 
          <Text style={{  
              textAlign: "center", 
              marginTop: "0px",
              padding: "10px",
              backgroundColor: "#edf5ff", 
              color: "#2a71c5", 
              fontSize: "12px", 
              border: "1px solid #add2ff", 
              borderRadius: "6px"}}>
              You will be selling a few stocks and you will realize the 100% of the proceeds on the next trading day. Buy calls will be activated on the next trading day by our system.
          </Text>
        : null
      }

      {
        props.isBuyCallEnabled && props.hasSellCall ? 
          <Text style={{  
              textAlign: "center", 
              marginTop: "0px",
              padding: "10px",
              backgroundColor: "#edf5ff", 
              color: "#2a71c5", 
              fontSize: "12px", 
              border: "1px solid #add2ff", 
              borderRadius: "6px"}}>
              We’ve activated the Buy Calls!
              <br></br>
              You have pending Sell Calls recommended by the system.
              <br></br><br></br>
              You may execute the Sell Calls today and continue with the Buy Calls tomorrow OR You may proceed with the Buy Calls today itself. However, you may fall short on cash. You can add extra cash to continue.
          </Text>
        : null
      }

      {
        props.isBuyCallEnabled && !props.hasSellCall ? 
          <Text style={{  
              textAlign: "center", 
              marginTop: "0px",
              padding: "10px",
              backgroundColor: "#edf5ff", 
              color: "#2a71c5", 
              fontSize: "12px", 
              border: "1px solid #add2ff", 
              borderRadius: "6px"}}>
              We’ve activated the Buy Calls!
              <br></br>
              You must have received the proceeds from the Sell Call executed yesterday. You can continue with the Buy Calls today.
          </Text>
        : null
      }

      <Stack alignItems="center" style={{ marginTop: "25px" }}>
        <JarvisButton onClick={props.handleContinueClick}>
          Continue
        </JarvisButton>
      </Stack>
    </Stack>
  );
};

export default RMSSellConfirmation;
