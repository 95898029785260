import _get from "lodash/get";

const jarvisOnestockActionCenterParsers = {};

jarvisOnestockActionCenterParsers.getOnestockActionCenterDataParser = (res) => {
    let data = [];
    if (res && res.response) {
        res = res.response;
    }
    if (!res || res.constructor !== Array) {
        return data;
    }

    const parserStockData = (stockArray) =>
        stockArray.map((e) => ({
            riskScore: _get(e, "riskScore", ""),
            stockId: _get(e, "stockId", 0),
            stockMarketCap: _get(e, "stockMarketCap", ""),
            stockName: _get(e, "stockName", ""),
            stockQuantity: _get(e, "stockQuantity", 0),
            stockSector: _get(e, "stockSector", ""),
            stockSymbol: _get(e, "stockSymbol", ""),
            suggestedPrice: _get(e, "suggestedPrice", 0),
            whySellMessage: _get(e, "reason", ""),
            brokerName: _get(e, "brokerName", ""),
            isin:_get(e,"isin",null),
            stockSymbol: _get(e,"stockSymbol",null)
        }));

    data = res.map((e) => ({
        oneStockCallId: _get(e, "oneStockCallId", "0"),
        actionType: _get(e, "actionType", ""),
        callExpiryDate: _get(e, "callExpiryDate", ""),
        callStatus: _get(e, "callStatus", ""),
        customerId: _get(e, "customerId", ""),
        stockData: parserStockData(_get(e, "stockData", [])),
    }));

    return data;
};

export { jarvisOnestockActionCenterParsers };
