import React from "react";
import _get from "lodash.get";
import { AFConstants, AppsFlyer } from "appsflyer-capacitor-plugin";
import { localStore } from "stores/localStorage";
import { useHistory } from "react-router-dom";
import { config } from "config";

const AppUrlListener = () => {
    const [initiated, setInitiated] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        let conversionListener,
            oaoaListener,
            udlListener = null;

        const handleRedirection = (deep_link_value) => {
            if (!deep_link_value) {
                return;
            }

            let pathname = "";
            if (deep_link_value === "signup_deep_link_value") {
                pathname = "/signup";
            }

            if (pathname === history.location.pathname) {
                return;
            }
            history.push(pathname);
        };

        if (!initiated) {
            if (config.isMobileApp) {
                // deferred link conversion - triggered on first app install with onelink
                conversionListener = AppsFlyer.addListener(
                    AFConstants.CONVERSION_CALLBACK,
                    (event) => {
                        console.log(
                            AFConstants.CONVERSION_CALLBACK,
                            event.data
                        );
                        // alert(`${AFConstants.CONVERSION_CALLBACK} ${JSON.stringify(event.data)}`)
                        if (
                            event.callbackName ===
                            AFConstants.onConversionDataSuccess
                        ) {
                            if (event.data.af_status === "Non-organic") {
                                if (event.data.is_first_launch === true) {
                                    const {
                                        deep_link_value,
                                        campaign,
                                        media_source,
                                        ...restEventData
                                    } = event.data;

                                    const campaignCoupon = _get(restEventData, "utm_coupon", null);
                                    const campaignProductCode = _get(restEventData, "utm_product", null);
                                    const campaignLeadId = _get(restEventData, "utm_lead_id", null);

                                    if (campaignProductCode) {
                                        localStore.setActiveProduct(campaignProductCode);
                                    }

                                    // to handle redirection based on deep_link_value provided
                                    handleRedirection(deep_link_value);

                                    localStore.setAppsflyerData({
                                        deep_link_value: deep_link_value,
                                        utm_campaign: campaign,
                                        utm_source: media_source,
                                        utm_coupon: campaignCoupon,
                                        utm_product: campaignProductCode,
                                        utm_lead_id: campaignLeadId,
                                        restEventData,
                                        callbackType:
                                            AFConstants.CONVERSION_CALLBACK,
                                    });
                                } else if (
                                    event.data.is_first_launch === false
                                ) {
                                    // NOTE: can add tracking if it is not app's first launch
                                    console.log(
                                        "conversion non_organic first_launch",
                                        event.data
                                    );
                                }
                            }
                        } else if (
                            event.callbackName ===
                            AFConstants.onConversionDataFail
                        ) {
                            console.error(
                                "conversion error: ",
                                event.errorMessage,
                                event.data
                            );
                            localStore.setAppsflyerData({});
                        }
                    }
                );

                // on app open attribution - trigger if app is already installed and user opens the app
                oaoaListener = AppsFlyer.addListener(
                    AFConstants.OAOA_CALLBACK,
                    (event) => {
                        console.log(AFConstants.OAOA_CALLBACK, event.data);
                        // alert(`${AFConstants.OAOA_CALLBACK} ${JSON.stringify(event.data)}`)
                        if (
                            event.callbackName ===
                            AFConstants.onAppOpenAttribution
                        ) {
                            const {
                                deep_link_value,
                                campaign,
                                media_source,
                                ...restEventData
                            } = event.data;

                            const campaignCoupon = _get(restEventData, "utm_coupon", null);
                            const campaignProductCode = _get(restEventData, "utm_product", null);
                            const campaignLeadId = _get(restEventData, "utm_lead_id", null);

                            if (campaignProductCode) {
                                localStore.setActiveProduct(campaignProductCode);
                            }

                            // to handle redirection based on deep_link_value provided
                            handleRedirection(deep_link_value);

                            localStore.setAppsflyerData({
                                deep_link_value: deep_link_value,
                                utm_campaign: campaign,
                                utm_source: media_source,
                                utm_coupon: campaignCoupon,
                                utm_product: campaignProductCode,
                                utm_lead_id: campaignLeadId,
                                restEventData,
                                callbackType: AFConstants.OAOA_CALLBACK,
                            });
                        } else if (
                            event.callbackName ===
                            AFConstants.onAttributionFailure
                        ) {
                            console.error("oaoa error: " + event.errorMessage);
                            localStore.setAppsflyerData({});
                        }
                    }
                );

                // on app open attribution - trigger if app is already installed and user opens the app
                udlListener = AppsFlyer.addListener(
                    AFConstants.UDL_CALLBACK,
                    (event) => {
                        console.log(AFConstants.UDL_CALLBACK, event.deepLink);
                        // alert(`${AFConstants.UDL_CALLBACK} ${JSON.stringify(event.deepLink)}`)
                        if (event.status === "FOUND") {
                            const {
                                deep_link_value,
                                campaign,
                                media_source,
                                ...restEventData
                            } = event.deepLink;

                            const campaignCoupon = _get(restEventData, "utm_coupon", null);
                            const campaignProductCode = _get(restEventData, "utm_product", null);
                            const campaignLeadId = _get(restEventData, "utm_lead_id", null);

                            if (campaignProductCode) {
                                localStore.setActiveProduct(campaignProductCode);
                            }

                            // to handle redirection based on deep_link_value provided
                            handleRedirection(deep_link_value);

                            localStore.setAppsflyerData({
                                deep_link_value: deep_link_value,
                                utm_campaign: campaign,
                                utm_source: media_source,
                                utm_coupon: campaignCoupon,
                                utm_product: campaignProductCode,
                                utm_lead_id: campaignLeadId,
                                restEventData,
                                callbackType: AFConstants.OAOA_CALLBACK,
                            });
                        } else if (event.status === "ERROR") {
                            console.error("udl error: " + event.error);
                            localStore.setAppsflyerData({});
                        }
                    }
                );
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const { deep_link_value, c, pid, ...restEventData } =
                    Object.fromEntries(urlParams);
                const campaignCoupon = _get(restEventData, "utm_coupon", null);
                const campaignProductCode = _get(restEventData, "utm_product", null);
                const campaignLeadId = _get(restEventData, "utm_lead_id", null);

                // to handle redirection based on deep_link_value provided
                handleRedirection(deep_link_value);

                if (campaignProductCode) {
                    localStore.setActiveProduct(campaignProductCode);
                }
                localStore.setAppsflyerData({
                    deep_link_value: deep_link_value,
                    utm_campaign: c,
                    utm_source: pid,
                    utm_coupon: campaignCoupon,
                    utm_product: campaignProductCode,
                    utm_lead_id: campaignLeadId,
                    restEventData,
                    callbackType: "WEB_QUERY_PARAMS",
                });
            }

            setInitiated(true);
        }

        return () => {
            if (config.isMobileApp) {
                // conversionListener.remove();
                // oaoaListener.remove();
                // udlListener.remove();
            }
        };
    }, [initiated, history]);
    return null;
};

export default AppUrlListener;
