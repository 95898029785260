import React, { Component } from "react";
import { config } from "config";

import Stack from "components/common/Stack";
import Dialog from "components/common/Dialog";
import Text from "components/common/Text";

import MobileVerification from "components/SocialLogin/MobileVerification";
import AgreeConsent from "components/SocialLogin/AgreeConsent";
import SocialLogin from "components/SocialLogin/SocialLogin";

import { localStore } from "stores/localStorage";
import { customerApis } from "stores/customer/customerApis";
import { customerParsers } from "stores/customer/customerParsers";
import { tracker } from "library/tracker";

class SocialLoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isConsentDialogOpen: false,
            isVerifyDialogOpen: false,
            formManage: {
                isRequestOtp: false,
                email: "",
            },
            google: {
                accessToken: "",
                socialId: "",
                name: "",
            },
            facebook: {
                accessToken: "",
                socialId: "",
                name: "",
            },
            formData: {
                appRegistrationId: "",
                loginType: "",
                deviceType: "web",
            },
            isLoading: false,
            mobileResendTime: 0,

        };
    }

    // Handling Social Button Click
    // this function is not being used now as now this trigger is getting handled inside 
    // social login component
    handleSocialClick = (triggerLogin, loginType) => {
        const { formData } = this.state;
        const data = this.state[loginType];
        formData.loginType = loginType;
        this.setState({ formData });
        if (data.accessToken && data.socialId && data.name) {
            this.handleLoginRequest();
        } else {
            triggerLogin();
        }
    };

    //Here we are hitting the api sending a login request to server
    handleLoginRequest = async () => {
        const { formData, formManage } = this.state;
        const postData = {};

        const data = this.state[formData.loginType];

        postData.deviceId = this.props.deviceId;
        postData.deviceType = config.platform;
        postData.loginType = formData.loginType;
        postData.socialId = data.socialId;
        postData.accessToken = data.accessToken;
        postData.idToken = data.idToken;

        try {
            this.props.updateLoader(true);
            // loader for google/FB login start
            const response = await customerApis.socialLogin(postData);
            if (response.initialSignUpFlow) {
                formManage.email = response.customers.email;
                formData.appRegistrationId = response.customers.customerId;
                this.submitCampaignData({ email: response.customers.email });

                this.setState({ formData, formManage, isConsentDialogOpen: true });
            } else {
                const result = customerParsers.login(response);
                this.submitCampaignData({ email: result.email });
                localStore.setToken(result.token);
                localStore.setLegacyToken(result.legacyToken);

                // NOTE: event_tracker => Login 
                tracker.login.loginSuccess({
                    loginType: postData.loginType,
                    customerId: result.customerId,
                    emailId:result.email, 
                    deviceType: formData.deviceType,
                    userName: result.name,
                    initUXCam: result.initUXCam,
                    isOnboardingCompleted: result.onboardingData.isOnboardingCompleted,
                });

                this.props.updateOnboardingDataAction(result.onboardingData);
                this.props.updateAuthDataAction(result);
            }
        } catch (err) {
            console.log(err);
        }finally{
            this.props.updateLoader(false);
            // loader for google login End's
        }
    };

    submitCampaignData = async ({ email }) => {
        const { utm_source, utm_campaign, utm_coupon, utm_product, utm_lead_id, restEventData = {}} = localStore.getAppsflyerData() || {};
        if (!utm_source || !utm_campaign) {
          return;
        }
        await customerApis.submitCampaignData({ email, trackingInfo: { utm_source, utm_campaign, utm_coupon, utm_product, utm_lead_id, raw_json: {...restEventData} } });
        localStore.resetAppsflyerData()
    }

    //After clicking on social button if we get Successfully got data from Social SDK
    handleSocialLogin = (user) => {
        const { formData } = this.state;
        formData.loginType = user.provider;
        const data = this.state[user.provider];
        data.socialId = user.socialId;
        data.accessToken = user.accessToken;
        data.idToken = user.idToken;
        data.name = user.name;
        console.log("Social on success =-> ", user);
        this.setState({ formData, [user._provider]: data });
        this.handleLoginRequest();
    };

    //After clicking on social button if SDK return Failure
    handleSocialLoginFailure = (err) => {
        console.error("Social on Fail =-> ",err);
    };

    // Requesting Mobile Otp
    handleRequestOtp = async (mobile, successCb) => {
        const { formData, formManage } = this.state;
        this.setState({ isLoading: true });

        const postData = {};
        postData.mobile = mobile;
        postData.appRegistrationId = formData.appRegistrationId;

        try {
            await customerApis.requestSocialOtp(postData);
            formManage.isRequestOtp = true;
            // NOTE: event_tracker => registration 
            tracker.registration.socialVerificationOtp({ platform: config.platform, deviceId: this.props.deviceId, name: ""  })
            this.setState({ formData, formManage }, () => {
                successCb();
            });
        } catch (error) {
            console.log("error in social request otp", error)
        } finally {
            this.setState({ isLoading: false });
        }
    };

    // Requesting Resend Otp
    handleResendOtp = async (mobile, successCb) => {
        const { formData } = this.state;
        this.setState({ isLoading: true });

        const postData = {};
        postData.mobile = mobile;
        postData.appRegistrationId = formData.appRegistrationId;
        try {
            await customerApis.requestSocialOtp(postData);
            successCb();
        } catch (e) {
            console.log("error in social request otp", e)
        } finally {
            this.setState({ isLoading: false });
        }
    };

    //Final Verify Otp
    verifyOtp = async (mobile, otp) => {
        const { formData } = this.state;
        this.setState({ isLoading: true });
        const data = this.state[formData.loginType];
        const postData = {};
        postData.otp = otp;
        postData.appRegistrationId = formData.appRegistrationId;
        postData.mobile = mobile;
        postData.name = data.name;
        postData.accessToken = data.accessToken;
        postData.idToken = data.idToken;
        postData.socialId = data.socialId;
        postData.loginType = formData.loginType;

        try {
            const response = await customerApis.verifySocialOtp(postData);
            const result = customerParsers.login(response);
            // NOTE: event_tracker => registration 
            tracker.registration.signUpSuccess({ 
                name: postData.name,
                loginType: postData.loginType,
                deviceId: this.props.deviceId,
                deviceType: config.platform,
                customerId: result.customerId,
            });
            
            localStore.setToken(result.token);
            localStore.setLegacyToken(result.legacyToken);
            if (result.lastActiveProduct) {
                localStore.setActiveProduct(result.lastActiveProduct);
            }
            this.props.updateOnboardingDataAction(result.onboardingData);
            this.props.updateAuthDataAction(result);
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleDialogClose = () => {
        const { formManage, formData } = this.state;

        formManage.isRequestOtp = false;
        formData.appRegistrationId = "";

        this.setState({
            isVerifyDialogOpen: false,
            isConsentDialogOpen: false,
            formManage,
        });
    };

    handleAgreedAndSignup = () =>{
        this.setState({ isVerifyDialogOpen: true });
    }

    getSocialDetails = () =>{
        const { loginType } = this.state.formData;
        let details = { socialIcon: "", Displayname: ""};
        if(loginType === "google"){
            details.socialIcon = "/images/google.png";
            details.Displayname = "Google";
        }else if( loginType === "facebook"){
            details.socialIcon = "/images/facebook.png";
            details.Displayname = "Facebook";
        }
        return details;
    }
	

    render() {
        const { socialIcon, Displayname} = this.getSocialDetails();

        return (
            <>
                <Dialog
                    open={this.state.isVerifyDialogOpen}
                    onClose={this.handleDialogClose}
                    maxWidth="xs"
                    title={
                        <Text
                            variant="subtitle1"
                            sx={{ textAlign: "center", width: "100%" }}>
                            Confirm Mobile
                        </Text>
                    }>
                    <MobileVerification
                        onRequestOtp={this.handleRequestOtp}
                        onResendOtp={this.handleResendOtp}
                        onSubmit={this.verifyOtp}
                        formManage={this.state.formManage}
                        isLoading={this.state.isLoading}
                    />
                </Dialog>
                <Dialog
                    open={this.state.isConsentDialogOpen}
                    onClose={this.handleDialogClose}
                    maxWidth="xs"
                    title={
                        <Stack direction="row" alignItems= "center"
                            sx={{maxWidth: "360px", ml: "auto", mr: "auto"}}>
                            <img src={socialIcon} width="24px" style={{marginRight: "10px"}} alt="G" />
                            <Text variant="body1">Register with {Displayname}</Text>
                        </Stack>
                    }>
                        <AgreeConsent 
                        agreedAndSignup={this.handleAgreedAndSignup}/>
                </Dialog>
                <Stack
                    direction="row"
                    alignItems="center"
                    // justifyContent="space-between" enable this line if enabling fb
                    justifyContent="center"
                    sx={{ width: "100%", maxWidth: "330px", marginLeft: "auto", marginRight: "auto" }}>

                    {!config.isMobileApp || true ?
                        <SocialLogin
                            socialType="facebook"
                            appId={config.loginAppId.facebook}
                            onLoginSuccess={this.handleSocialLogin}
                            onLoginFailure={this.handleSocialLoginFailure}
                            startIcon={
                                <img src="/images/facebook.png" width="24px" alt="FB" style={{marginRight: "10px"}} />
                            }
                            title="Facebook"
                            handleSocialClick={() => {
                                console.log("Facebook clicked");
                                // this.handleSocialClick
                            }}
                        />
                        : null}
                    <SocialLogin
                        socialType="google"
                        appId={config.loginAppId.google}
                        onLoginSuccess={this.handleSocialLogin}
                        onLoginFailure={this.handleSocialLoginFailure}
                        startIcon={
                            <img src="/images/google.png" width="24px" alt="G" style={{marginRight: "10px"}} />
                        }
                        title="Google"
                        handleSocialClick={() => {
                            console.log("Google clicked");
                            // this.handleSocialClick
                        }}
                    />
                </Stack>
            </>
        );
    }
}

export default SocialLoginContainer;
