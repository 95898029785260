import { config } from "config";

const uxcamTracker = {};

uxcamTracker.enabled = false;
uxcamTracker.apiKey = null;

uxcamTracker.updateConfig = ({ platform = [], apiKey = "" }) => {
    uxcamTracker.enabled = platform.includes(config.platform);
    uxcamTracker.apiKey = apiKey;
};

/*
  Note: init UXCam is from native side.
*/
uxcamTracker.trackEvents = ({ eventName, eventData = {} }) => {
    if (!uxcamTracker.enabled) {
        return;
    }

    try {
        eventData = eventData || {};

        // const data = {
        //     eventName: eventName,
        //     properties: {
        //         ...eventData,
        //     },
        // };
        // UXCamPlugin.logEvent(data);
    } catch (err) {
        console.log("UXCam__trackevents_error", err);
    }
};

uxcamTracker.tagScreenName = (screenName) => {
    if (!uxcamTracker.enabled) {
        return;
    }

    try {
        // UXCamPlugin.tagScreenName({ screenName });
    } catch (error) {
        console.error("uxcamTracker tagScreenName error", error);
    }
};

uxcamTracker.startWithKey = () => {
    if (!uxcamTracker.enabled || !uxcamTracker.apiKey) {
        return;
    }
    console.log("ux init", uxcamTracker);

    try {
        // UXCamPlugin.startWithKey({ UXCamKey: uxcamTracker.apiKey });
        console.log("ux init done", uxcamTracker);
    } catch (error) {
        console.error("uxcamTracker startWithKey error", error);
    }
};

uxcamTracker.setUserIdentity = ({ userId }) => {
    if (!uxcamTracker.enabled) {
        return;
    }

    try {
        // UXCamPlugin.setUserIdentity({ userIdentity: userId });
    } catch (error) {
        console.error("uxcamTracker setUserIdentity error", error);
    }
};

uxcamTracker.setUserProperty = (propertyData) => {
    if (!uxcamTracker.enabled) {
        return;
    }

    try {
        // UXCamPlugin.setUserProperty(propertyData);
    } catch (error) {
        console.error("uxcamTracker setUserProperty error", error);
    }
};

uxcamTracker.setAutomaticScreenNameTagging = ({ enable }) => {
    if (!uxcamTracker.enabled) {
        return;
    }

    try {
        // UXCamPlugin.setAutomaticScreenNameTagging({ enable });
    } catch (error) {
        console.error(
            "uxcamTracker setAutomaticScreenNameTagging error",
            error
        );
    }
};

//Note: pending to call on complte onboarding flow event
uxcamTracker.stopSession = () => {
    if (!uxcamTracker.enabled || !config.isMobileApp) {
        return;
    }

    try {
        // UXCamPlugin.stopSession();
    } catch (error) {
        console.error("uxcamTracker stopSession error", error);
    }
};

uxcamTracker.startSession = () => {
    if (!uxcamTracker.enabled || !config.isMobileApp) {
        return;
    }

    uxcamTracker.setAutomaticScreenNameTagging({ enable: false });
    uxcamTracker.startWithKey();
};

export { uxcamTracker };
