import { portfolioHoldingConstants } from "./portfolioHoldingConstants";

const portfolioHoldingActions = {};

portfolioHoldingActions.getPortfolioHoldingList = (payload) =>  ({
    type: portfolioHoldingConstants.PORTFOLIO_HOLDING_SAGA, 
    payload
});

export { portfolioHoldingActions };
