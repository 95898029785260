
export const jarvisRMSDashboardOnboardedConstants = {

    JARVIS_RMS_RESET_DASHBOARD_ONBOARDED_DATA : "JARVIS_RMS_RESET_DASHBOARD_ONBOARDED_DATA",

    JARVIS_RMS_PORTFOLIO_STATS_API: "rms/dashboard/portfolio-stats",
    JARVIS_RMS_PORTFOLIO_STATS_SAGA: "JARVIS_RMS_PORTFOLIO_STATS_SAGA",
    JARVIS_RMS_PORTFOLIO_STATS_REQUEST: "JARVIS_RMS_PORTFOLIO_STATS_REQUEST",
    JARVIS_RMS_PORTFOLIO_STATS_RESPONSE: "JARVIS_RMS_PORTFOLIO_STATS_RESPONSE",

    JARVIS_RMS_RMS_CALL_STATS_API: "rms/dashboard/rms-call-stats",
    JARVIS_RMS_RMS_CALL_STATS_SAGA: "JARVIS_RMS_RMS_CALL_STATS_SAGA",
    JARVIS_RMS_RMS_CALL_STATS_REQUEST: "JARVIS_RMS_RMS_CALL_STATS_REQUEST",
    JARVIS_RMS_RMS_CALL_STATS_RESPONSE: "JARVIS_RMS_RMS_CALL_STATS_RESPONSE",

    JARVIS_RMS_MARKET_CAP_API: "rms/dashboard/portfolio-by-market-cap",
    JARVIS_RMS_MARKET_CAP_SAGA: "JARVIS_RMS_MARKET_CAP_SAGA",
    JARVIS_RMS_MARKET_CAP_REQUEST: "JARVIS_RMS_MARKET_CAP_REQUEST",
    JARVIS_RMS_MARKET_CAP_RESPONSE: "JARVIS_RMS_MARKET_CAP_RESPONSE",

    JARVIS_RMS_SECTOR_DIVERSIFICATION_API: "rms/dashboard/portfolio-by-sector",
    JARVIS_RMS_SECTOR_DIVERSIFICATION_SAGA: "JARVIS_RMS_SECTOR_DIVERSIFICATION_SAGA",
    JARVIS_RMS_SECTOR_DIVERSIFICATION_REQUEST: "JARVIS_RMS_SECTOR_DIVERSIFICATION_REQUEST",
    JARVIS_RMS_SECTOR_DIVERSIFICATION_RESPONSE: "JARVIS_RMS_SECTOR_DIVERSIFICATION_RESPONSE",

}