import React from "react";
import { makeStyles } from "@mui/styles";

import Box from "components/common/Box";
import Text from "components/common/Text";
import Stack from "components/common/Stack";

const useStyles = makeStyles((theme) => ({
    container: {
        background: "#FFFFFF",
        padding: "24px 36px",
        borderRadius: "10px",
        height: "100%",
        [theme.breakpoints.down("md")]: {
            border: "1px solid #EEF0F2",
            marginBottom:"16px",
            padding: "16px",
            width:"100%"
        },
    },
    itemLabel: {
        minHeight: "57px",
        display: "flex",
        alignItems: "center",
    },
    itemHeader: {
        minHeight: "40px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px"
    },
    itemValue: {
        minHeight: "57px",
        display: "flex",
        alignItems: "center",
        background: "#EEF7FF",
        borderRadius: "4px",
        paddingLeft: "20px"
    }
}));

const PortfolioPerformanceCard = (props) => {
    const classes = useStyles();
    const { data } = props;

    return (
        <Box className={classes.container}>
            <Text variant="" sx={{typography:{xs:"body2",md:"subtitle2"}}} >
                Portfolio Performance
            </Text>

            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>

                <Stack spacing={2} sx={{ flex: 1 }}>
                    <Box className={classes.itemHeader}></Box>

                    <Box className={classes.itemLabel}>
                        <Text sx={{typography:{xs:"caption1",md:"body2"}}} >Month to Date</Text>
                    </Box>
                    <Box className={classes.itemLabel}>
                        <Text sx={{typography:{xs:"caption1",md:"body2"}}}>Quarter to Date</Text>
                    </Box>
                    <Box className={classes.itemLabel}>
                        <Text sx={{typography:{xs:"caption1",md:"body2"}}}>Year to Date</Text>
                    </Box>
                </Stack>

                <Stack spacing={2} sx={{ flex: 1 }}>
                    <Box className={classes.itemHeader}>
                        <Text sx={{typography:{xs:"caption1",md:"body2"}}} color="#1D9BFF" fontWeight="bold">Portfolio</Text>
                    </Box>
                    <Box className={classes.itemValue}>
                        <Text variant="subtitle2">{data.mtdPortfolio || '-'}</Text>
                    </Box>
                    <Box className={classes.itemValue}>
                        <Text variant="subtitle2">{data.qtdPortfolio || '-'}</Text>
                    </Box>
                    <Box className={classes.itemValue}>
                        <Text variant="subtitle2">{data.ytdPortfolio || '-'}</Text>
                    </Box>
                </Stack>

                <Stack spacing={2} sx={{ flex: 1 }}>
                    <Box className={classes.itemHeader}>
                        <Text sx={{typography:{xs:"caption1",md:"body2"}}} color="#1D9BFF" fontWeight="bold">NIFTY50</Text>
                    </Box>
                    <Box className={classes.itemValue}>
                        <Text variant="subtitle2">{data.mtdNifty || '-'}</Text>
                    </Box>
                    <Box className={classes.itemValue}>
                        <Text variant="subtitle2">{data.qtdNifty || '-'}</Text>
                    </Box>
                    <Box className={classes.itemValue}>
                        <Text variant="subtitle2">{data.ytdNifty || '-'}</Text>
                    </Box>
                </Stack>

            </Stack>
        </Box>
    );
};

export default PortfolioPerformanceCard;
