import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return {
        loginProfile: state.customer.loginProfile,
        brokersList: state.common.brokers,
        isBrokersListLoading: state.common.loading.brokers,
        isBrokersListInitialCalled: state.common.initialCalled.brokers,
        centrumReferalURL: state.common.featureConfig.centrumReferalURL
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getBrokersAction: commonActions.getBrokers,
        },
        dispatch
    );

const ChooseBrokerStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default ChooseBrokerStore;
