import React from "react";

import Text from "components/common/Text";
import Dialog from "components/common/Dialog";
import JarvisButton from "components/JarvisButton";
import Box from "components/common/Box";

const ConfirmTakeActionDialog = (props) => {
  const { open, message, isRebalanceGenerated, onContinue, onClose } = props;
  let srcImage = isRebalanceGenerated
    ? "/images/svgs/success.gif"
    : "/images/svgs/failed.gif";

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title=""
      maxWidth="xs"
      sx={{ borderRadius: "10px" }}
      contentComponent={() => (
        <Box sx={{ maxWidth: "300px", margin: "0 auto", textAlign: "center" }}>
          <img src={srcImage} width="100px" alt="success" />
          <Text
            variant="subtitle1"
            sx={{ color: "#1E1E1E", mb: "16px", lineHeight: 1.3 }}
          >
            {message}
          </Text>
        </Box>
      )}
      actionComponent={(onClose) => {
        return isRebalanceGenerated ? (
          <Box m={2} textAlign="center">
            <JarvisButton
              onClick={() => (onContinue(), onClose())}
              disableEndIcon={true}
              variant="outlined"
            >
              Continue
            </JarvisButton>
          </Box>
        ) : (
          <Box m={2} textAlign="center">
            <JarvisButton
              onClick={() => onClose()}
              disableEndIcon={true}
              variant="outlined"
            >
              OK, Got it
            </JarvisButton>
          </Box>
        );
      }}
    />
  );
};

export default ConfirmTakeActionDialog;
