import React from "react";
import RMSTransactionContainer from "containers/JarvisRMS/RMSTransactions";

const RMSTransactions = (props) => {

	return (
		<RMSTransactionContainer />
	)
}

export default RMSTransactions;
