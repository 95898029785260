// import "@capacitor-community/uxcam"

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { BrowserRouter, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import SafeAreaInsetsProvider from "library/safearea";
import AppUrlListener from "library/appUrlListener";
import ConfigProvider from "library/tracker/ConfigProvider";
import TrackerProvider from "library/tracker/TrackerProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";

import store from "stores";
// import { LocalizationProvider } from "@mui/x-date-pickers";
let persistor = persistStore(store);

const ScrollToTop = (props) => {
    const location = useLocation();
    React.useEffect(() => {
        window.scroll({ top: 0, behavior: 'smooth' })
        // window.document.querySelector('#appContentBody').scroll({ top: 0, behavior: 'smooth' })
    }, [location])
    return props.children
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ConfigProvider>
                        <SafeAreaInsetsProvider>
                            <TrackerProvider>
                                <BrowserRouter basename="/">
                                    <AppUrlListener />
                                    <ScrollToTop>
                                        <App />
                                    </ScrollToTop>
                                </BrowserRouter>
                            </TrackerProvider>
                        </SafeAreaInsetsProvider>
                    </ConfigProvider>
                </LocalizationProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
