import { Camera, CameraResultType } from "@capacitor/camera";
import { config } from "config/";

const cameraHelper = {};
const cameraOptions = {
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.Uri,
    saveToGallery: false,
    height: 400,
    width: 400,
};

cameraHelper.getPhoto = async ({
    labelHeader,
    labelCancel,
    labelPhoto,
    labelPicture,
} = {}) => {
    try{
        const permissionStatus = await cameraHelper.permissionChecker();
        if (!permissionStatus) {
            return;
        }

        const image = await Camera.getPhoto({
            ...cameraOptions,
            promptLabelHeader: labelHeader,
            promptLabelCancel: labelCancel,
            promptLabelPhoto: labelPhoto,
            promptLabelPicture: labelPicture,
        });
        return image;
    } catch (error) {
        return undefined;
    }
};

cameraHelper.pickImages = async ({
    labelHeader,
    labelCancel,
    labelPhoto,
    labelPicture,
} = {}) => {
    const permissionStatus = await cameraHelper.permissionChecker();
    if (!permissionStatus) {
        return;
    }

    const images = await Camera.pickImages({
        ...cameraOptions,
        promptLabelHeader: labelHeader,
        promptLabelCancel: labelCancel,
        promptLabelPhoto: labelPhoto,
        promptLabelPicture: labelPicture,
    });
    return images;
};

cameraHelper.permissionChecker = async () => {
    // check permission only for android platform
     if (config.platform !== "ANDROID") {
        return true;
    }

    // check permission status
    let { camera: cameraPermission, photos: photosPermission } =
        await Camera.checkPermissions();

    try {
        // if not granted then ask for the permission
        if (cameraPermission !== "granted") {
            const cameraPermissionStatus = await Camera.requestPermissions([
                "camera",
            ]);
            cameraPermission = cameraPermissionStatus.camera;
        }
    } catch (e) {
        console.error("cameraPermission error", e);
    }

    try {
        // if not granted then ask for the permission
        if (photosPermission !== "granted") {
            const photosPermissionStatus = await Camera.requestPermissions([
                "photos",
            ]);
            photosPermission = photosPermissionStatus.photos;
        }
    } catch (e) {
        console.error("photosPermission error", e);
    }
    // if both permissions are provided then proceed further
    if (cameraPermission === "granted" && photosPermission === "granted") {
        return true;
    }

    // if both permissions are not provided then display alert message and stop functionality
    alert(
        "Some functionality might break, since camera permissions are not provided"
    );
    return false;
};

export { cameraHelper };
