import _get from "lodash.get";
import { formatHelper } from "helper/format"
import { config } from "config/index";

const customerParsers = {};

customerParsers.login = (res) => {

  if (res && res.result) {
    res = res.result;
  }

  if (!res) {
    return {};
  }

  const PORTFOLIO_STAGE_MAP = {
    RISK_ASSESSMENT: 0,
    INVESTMENT_PREFERENCE: 1,
    GENERATE_PORTFOLIO: 2,
    CKYC: 3,
    DOCUMENT_SIGNING: 4,
    BROKER_TRANSACTION: 5,
  }

  const data = {
    token: _get(res, "token", null),
    isSso: _get(res,"customers.isSso",null),
    isMobileVerified: _get(res, "customers.isMobileVerified", false),
    isEmailVerified: _get(res, "customers.isEmailVerified", false),
    legacyToken: _get(res, "customers.phpToken", null) || _get(res, "customers.legacyToken", null),
    email: _get(res, "customers.email", null),
    clientId: _get(res, "customers.clientId", null),
    isManualAgreement: _get(res, "customers.isManualAgreement", null),
    ssoClientId: _get(res, "customers.ssoClientId", null),
    lastLogin: _get(res, "customers.lastLogin", null),
    mobile: _get(res, "customers.mobile", null),
    name: _get(res, "customers.name", "User"),
    profile: _get(res, "customers.profile", null),
    userId: _get(res, "customers.userId", null),
    customerId: _get(res, "customers.customerId", null),
    dob: _get(res, "customers.dob", null),
    pan: _get(res, "customers.pancardNumber", null),
    registrationType: _get(res, "customers.registrationType", null),
    initUXCam: _get(res, "customers.initUXCam", false),
    isExistingCustomer: _get(res, "customers.isExistingCustomer", null),
    isKYCCompleted: _get(res, "isKYCCompleted", ""),
    lastActiveProduct: _get(res, "customers.lastActiveProduct", null),
    activeProductList: [],
    riskProfileId: _get(res,"customers.riskProfileId",null),
    brokerName: _get(res,"customers.brokerName",null),
    onboardingData: {
      currentStage: PORTFOLIO_STAGE_MAP[_get(res, "customers.PORTFOLIO.currentStage", "RISK_ASSESSMENT")],
      isOnboardingCompleted: _get(res, "customers.PORTFOLIO.isOnboardingCompleted", 0),
      isPaymentCompleted: _get(res, "customers.PORTFOLIO.isPaymentCompleted", 0) ? 1 : 0,
      isProductActive: _get(res, "customers.PORTFOLIO.isActive", 0) ? 1 : 0,
    },
    rmsOnboardingData: {
      currentStage: _get(res, "customers.RMS.currentStage", null),
      isOnboardingCompleted: _get(res, "customers.RMS.isOnboardingCompleted", 0),
      isPaymentCompleted: _get(res, "customers.RMS.isPaymentCompleted", 0) ? 1 : 0,
      isProductActive: _get(res, "customers.RMS.isActive", 0) ? 1 : 0,
    },
    onestockOnboardingData: {
      currentStage: _get(res, "customers.ONESTOCK.currentStage", null),
      isOnboardingCompleted: _get(res, "customers.ONESTOCK.isOnboardingCompleted", 0),
      isPaymentCompleted: _get(res, "customers.ONESTOCK.isPaymentCompleted", 0) ? 1 : 0,
      isProductActive: _get(res, "customers.ONESTOCK.isActive", 0) ? 1 : 0,
    },
  };

  if (_get(res, "customers.PORTFOLIO.isActive", 0)) {
    data.activeProductList.push(config.productCodes.PORTFOLIO);
  }

  if (_get(res, "customers.RMS.isActive", 0)) {
    data.activeProductList.push(config.productCodes.RMS);
  }

  if (_get(res, "customers.ONESTOCK.isActive", 0)) {
    data.activeProductList.push(config.productCodes.ONESTOCK);
  }
  return data;
};

customerParsers.profile = (res) => {

  if (res && res.response) {
    res = res.response;
  }

  if (!res) {
    return {};
  }
  const parseProduct = (p) =>({
    expiryDate: _get( p , "expiryDate", ""),
    isExpired: _get( p , "isExpired", ""),
    isExpiring: _get( p , "isExpiring", ""),
    isKYCCompleted: _get( p , "isKYCCompleted", ""),
    paymentMethod: _get( p , "paymentMethod", ""),
    showRenewalButton: _get( p , "showRenewalButton", ""),
    customerSubscriptionId: _get( p , "customerSubscriptionId", ""),
    customerPlanId: _get( p , "customerPlanId", ""),
    planTotalPrice: _get( p , "planTotalPrice", 0),
    paymentFrequency: _get( p , "paymentFrequency", null),
    initialPaymentDate: _get( p , "initialPaymentDate", null),
    investmentAmount: _get( p , "investmentAmount", null),
    annualFeesPercentage: _get( p , "annualFeesPercentage", null),
  });

  const data = {
    email: _get(res, "email", ""),
    mobileNo: _get(res, "mobileNo", ""),
    alternateNo: _get(res, "alternateNo", ""),
    fullName: _get(res, "fullName", ""),
    brokerProfilePic: formatHelper.getLegacyImageURI(_get(res, "brokerImage", "")),
    fundsRemaining: _get(res, "fundsRemaining", ""),
    brokerName: _get(res, "brokerName", ""),
    brokerPlatform: _get(res, "brokerPlatform", ""),
    brokerId: _get(res, "brokerId", ""),
    brokerClientId: _get(res, "brokerClientId", ""),
    riskProfile: _get(res, "riskProfile", ""),
    riskProfileId: _get(res, "riskProfileId", ""),
    riskLevelValue: _get(res, "riskLevelValue", ""),
    investmentStrategy: _get(res, "investmentStrategy", ""),
    isKYCCompleted: _get(res, "isKYCCompleted", ""),
    isExpired: _get(res, "isExpired", ""),
    expiryDate: _get(res, "expiryDate", ""),
    showRenewalButton: _get(res, "showRenewalButton", false),
    paymentMethod: _get(res, "paymentMethod", null),
    isExpiring: _get(res, "isExpiring", false),
    ONESTOCK: parseProduct(_get(res, "ONESTOCK", {})),
    PORTFOLIO: parseProduct(_get(res, "PORTFOLIO", {})),
    RMS: parseProduct(_get(res, "RMS", {})),
  };
  return data;
};

customerParsers.reasonDropdownForCancellation = (res) => {
  if (res && res.response) {
    res = res.response;
  }

  if (!res || !res.length) {
    return [];
  }
  let data = res.map((r) => ({
    label: _get(r, "label", ""),
    value: _get(r, "value", ""),
  }));

  return data;
}

customerParsers.subscriptionParser = (res) => {

  if (res && res.response) {
    res = res.response;
  }

  if (!res || !res.length) {
    return [];
  }
  let data = res.map((r) => ({
    investmentType: _get(r, "investmentType", ""),
    investmentAmount: _get(r, "investmentAmount", ""),
    investmentDate: _get(r, "investmentDate", "").split(" ").join("T"),
    feesPaid: _get(r, "feesPaid", ""),
  }));

  return data;
};

customerParsers.renewalPricingDetails = (res) => {

  if (res && res.response) {
    res = res.response;
  }

  if (!res) {
    return {};
  }

  let data = {
    investmentAmount: _get(res, "investmentAmount", ""),
    currentPortfolioCount: _get(res, "currentPortfolioCount", ""),
    currentStockCount: _get(res, "currentStockCount", ""),
    annualFeesPercentage: _get(res, "annualFeesPercentage", ""),
    gstPercentage: _get(res, "gstPercentage", ""),
    halfYearlyFeesPercentage: _get(res, "halfYearlyFeesPercentage", ""),
    feesTenure: _get(res, "feesTenure", ""),
    halfYearlyFees: _get(res, "halfYearlyFees", ""),
    halfYearlyFeesGst: _get(res, "halfYearlyFeesGst", ""),
    halfYearlyFeesTotal: _get(res, "halfYearlyFeesTotal", ""),
  };
  return data;
};

customerParsers.requestOtp = (res) => {
  if (res && res.response) {
    res = res.response;
  }

  if (res && res.result) {
    res = res.result;
  }

  if (!res) {
    return {};
  }

  return {
    requestId: _get(res, "requestId", ""),
  }
}

customerParsers.productSubscriptionListParser = (res) => {

  if (res && res.response) {
    res = res.response;
  }

  if (!res) {
    return {};
  }

  let rmsData = _get(res, "RMS", []);
  if (rmsData && rmsData.length) {
    rmsData = rmsData[0];
  }
  let portfolioData = _get(res, "PORTFOLIO", []);
  if (portfolioData && portfolioData.length) {
    portfolioData = portfolioData[0];
  }
  let onestockData = _get(res, "ONESTOCK", []);
  if (onestockData && onestockData.length) {
    onestockData = onestockData[0];
  }

  let data = {
    rmsData: {
      planName: _get(rmsData, "planName", null),
      planPrice: _get(rmsData, "planPrice", null),
      planStatus: _get(rmsData, "planStatus", null),
      paymentFrequency: _get(rmsData, "paymentFrequency", null),
      expiryDate: _get(rmsData, "expiryDate", null),
      autoPayEnabled: _get(portfolioData, "autoPayEnabled", null),
      productMap: _get(rmsData, "productMap", []).map((pm) => ({
        portfolioLimit: _get(pm, "portfolio_limit", null),
        stockLimit: _get(pm, "stock_limit", null),
        productCode: _get(pm, "product_code", null),
      })),
    },
    portfolioData: {
      planName: _get(portfolioData, "planName", null),
      planPrice: _get(portfolioData, "planPrice", null),
      planStatus: _get(portfolioData, "planStatus", null),
      paymentFrequency: _get(portfolioData, "paymentFrequency", null),
      expiryDate: _get(portfolioData, "expiryDate", null),
      autoPayEnabled: _get(portfolioData, "autoPayEnabled", null),
      productMap: _get(portfolioData, "productMap", []).map((pm) => ({
        portfolioLimit: _get(pm, "portfolio_limit", null),
        stockLimit: _get(pm, "stock_limit", null),
        productCode: _get(pm, "product_code", null),
      })),
    },
    onestockData: {
      planName: _get(onestockData, "planName", null),
      planPrice: _get(onestockData, "planPrice", null),
      planStatus: _get(onestockData, "planStatus", null),
      paymentFrequency: _get(onestockData, "paymentFrequency", null),
      expiryDate: _get(onestockData, "expiryDate", null),
      autoPayEnabled: _get(portfolioData, "autoPayEnabled", null),
      productMap: _get(onestockData, "productMap", []).map((pm) => ({
        portfolioLimit: _get(pm, "portfolio_limit", null),
        stockLimit: _get(pm, "stock_limit", null),
        productCode: _get(pm, "product_code", null),
      })),
    },
  };
  return data;
};
customerParsers.productSubscriptionDetailstParser = (res) => {
  if (res && res.response) {
    res = res.response;
  }

  if (!res || !res.length) {
    return [];
  }

  const calculateUsage = (limit, balance) => {
    if (!limit) {
      return 0;
    }
    if (limit === -1) {
      return balance;
    }
    return limit - balance;
  }

  let data = res.map((r) => ({
    planId: _get(r, "planId", ""),
    planName: _get(r, "planName", ""),
    planPrice: _get(r, "planPrice", ""),
    expiryDate: _get(r, "expiryDate", ""),
    planStatus: _get(r, "planStatus", ""),
    portfolioLimit: _get(r, "portfolioLimit", ""),
    portfolioLimitBalance: _get(r, "portfolioLimitBalance", ""),
    portfolioLimitUsage: calculateUsage(_get(r, "portfolioLimit", ""), _get(r, "portfolioLimitBalance", "")),
    aumLimit: _get(r, "aumLimit", ""),
    aumLimitBalance: _get(r, "aumLimitBalance", ""),
    aumLimitUsage: calculateUsage(_get(r, "aumLimit", ""), _get(r, "aumLimitBalance", "")),
    stockLimit: _get(r, "stockLimit", ""),
    stockLimitBalance: _get(r, "stockLimitBalance", ""),
    stockLimitUsage: calculateUsage(_get(r, "stockLimit", ""), _get(r, "stockLimitBalance", "")),
    paymentFrequency: _get(r, "paymentFrequency", ""),
    productCode: _get(r, "productCode", ""),
    autoPayEnabled: _get(r, "autoPayEnabled", null),
  }));

  return data;
};

export { customerParsers };
