import React from "react";
import InvestMoreContainer from "containers/InvestMore";
import PageTitle from "components/PageTitle";
// import Link from "components/common/Link";
// import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import { tracker } from "library/tracker";


const PortfolioInvestMore = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);


    // const rightData = () => (
    //     <>
    //         <HistoryIcon sx={{ fontSize: 20, color: "primary.main" }} />
    //         <Link
    //             href="/customer/profile"
    //             variant="small"
    //             sx={{ fontWeight: "medium", textDecoration: "none" }}>
    //             Transaction
    //         </Link>
    //     </>
    // );

    return (
            <>
                <PageTitle
                    heading="Invest More"
                    subheading="How would you like to invest?"
                // endContent={rightData}
                />
                <InvestMoreContainer investmentType="LUMPSUM" />
            </>
    );
};

export default PortfolioInvestMore;
