import React from "react";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import Text from "components/common/Text";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import Grid from "components/common/Grid";

import { formatHelper } from "helper/format";

const PortfolioValue = ({lable, value}) =>{
    return (
        <Stack mb={1}>
            <Text variant="small" 
                sx={{
                    fontSize: {xs: "11px", sm:"14px"}, 
                    color:"#808591" }} >{lable}</Text>
            <Text variant="body1" 
                sx={{
                    color:"#2E384D",
                    fontSize: {xs: "15px", sm: "18px"}, 
                }}>{value}</Text>
        </Stack>
    )
} 

const InvestmentInfoCard = ({ investmentAmount, investmentTenure, investmentStrategy, riskProfile, editInvestmentAmount, isPaymentCompleted }) => {
    return (
        <Stack spacing={2}  sx={{ 
            position: "relative",
            border: '1px solid #E8EBEE', 
            backgroundColor: { xs: '#EAF3FBB5', sm: "#fff"},
            flex: 1, 
            py: {xs: 1, sm: 3}, 
            px: {xs: 1, sm: 4}, 
            borderRadius: 2.5 }}>
                {!isPaymentCompleted ? 
                    <Button sx={{
                                textTransform:'none', 
                                fontSize:"12px" , 
                                py:0, 
                                position:"absolute",
                                top:{xs : "13px", sm: "30px"},
                                right: {xs: "5px", sm: "18px"}
                            }} 
                            variant="text" size="small" 
                            endIcon={<BorderColorOutlinedIcon variant="small" fontSize="10px" />} 
                            onClick={editInvestmentAmount} 
                            title="Edit Amount"> Edit
                    </Button>
                :null}

                <Grid container spacing={1} mt="0 !important">
                    <Grid item xs={5} sm={4}>
                       <PortfolioValue lable="Investment Amount" value={formatHelper.currency(investmentAmount, { config: { useGrouping: true } })} />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                       <PortfolioValue lable="Tenure Selected" value={investmentTenure +" Yrs"} />
                    </Grid>
                   
                    <Grid item xs={8} sm={12} order={{xs: 3, sm:4}}>
                        <PortfolioValue lable="Investment Strategy" value={investmentStrategy} />
                    </Grid>
                    <Grid item xs={4} sm={3} order={3}>
                        <PortfolioValue lable="Risk Profile" value={riskProfile} />
                    </Grid>
                </Grid>

        </Stack>
    )
}

export default InvestmentInfoCard;