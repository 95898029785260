import React, { Component } from "react";
import Stack from "components/common/Stack";
import RMSDashboardOnboardedContainer from "../RMSDashboardOnboarded";
import RMSOnboardingNotCompleted from "../RMSOnboardingNotCompleted";
export default class RMSDashboardContainer extends Component {
	render() {
		return (
			<>
				<Stack sx={{ maxWidth: "100%" }}>
					{(!this.props.isOnboardingCompleted) ?
						<>
							<RMSOnboardingNotCompleted />
						</>
						:
						<RMSDashboardOnboardedContainer />
					}
				</Stack>
			</>
		);
	}
}
