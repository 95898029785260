import React from "react";

import { config } from "config";

import LayoutMobileApp from "./LayoutMobileApp";
import LayoutWeb from "./LayoutWeb";

import _get from "lodash.get"

import Stack from "components/common/Stack";



// Pages list which does't have layout.
const withoutLayoutPages = [
    "/customer/jarvis-info",
    "/customer/product-info",
    "/customer/product-list",
    "/customer/broker",
    "/customer/document-signing",
    "/customer/subscription",
    "/customer/payment",
    "/customer/onetime-payment",
];



// Render pages without layout handling.
const WithoutLayout = (props) => {
    const { children } = props;

    return (
        <Stack
            sx={{
                px: 2,
                py: 2,
                minHeight: "calc(100vh - (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)))"
            }}>
            {children}
        </Stack>
    )
}


class LayoutContainer extends React.Component {
        constructor(props){
            super(props);
            this.state = {}
        }

    componentDidMount(){
    }


    render() {
        const pathname = _get(this.props, "location.pathname", "");
        const noLayout = withoutLayoutPages.includes(pathname);

        if (noLayout) {
            return <WithoutLayout {...this.props} />
        }

        if (config.isMobileApp) {
            return <LayoutMobileApp {...this.props} />;
        }

        return <LayoutWeb {...this.props} />;
    }
}

export default LayoutContainer;
