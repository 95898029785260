import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { customerActions } from "stores/customer/customerActions";
import { thirdpartyActions } from "stores/thirdparty/thirdpartyActions";

const mapStateToProps = (state, props) => {
    let onboardingPlanId = props.onboardingPlanId || state.onboarding.planId;
    return {
        subscriptionPlans: state.thirdparty.subscriptionPlans,
        isSubscriptionPlansLoading: state.thirdparty.loading.subscriptionPlans,
        isSubscriptionPlansCalled:
            state.thirdparty.isInitialCalled.subscriptionPlans,

        paymentMethods: state.common.paymentMethods,
        isPaymentMethodsLoading: state.common.loading.paymentMethods,
        isPaymentMethodsCalled: state.common.initialCalled.paymentMethods,
        
        onboardingPlanId: onboardingPlanId,
        enableRazorpayTPV: state.common.featureConfig.enableRazorpayTPV,
        enableCouponDiscount: state.common.featureConfig.enableCouponDiscount,
        loginProfile: state.customer.loginProfile,
        customerProfile : state.customer.profile,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getSubscriptionPlansAction: thirdpartyActions.getSubscriptionPlans,
            getPaymentMethodsAction: commonActions.getPaymentMethods,
            getFeatureConfigAction: commonActions.getFeatureConfig,
            getProfileDataAction: customerActions.getProfile,

        },
        dispatch
    );

const PlanPaymentStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PlanPaymentStore;
