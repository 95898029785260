import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const loginTracker = {};

loginTracker.login = () => {
    // const eventName = "login";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

loginTracker.loginFailed = ({ error_msg }) => {
    // const eventName = "login_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

loginTracker.loginSuccess = ({ customerId, deviceType, loginType, userName, isOnboardingCompleted, initUXCam }) => {
    let eventName = "login_success";

    appsFlyerTracker.setUserId(customerId);
    appsFlyerTracker.trackEvents({
        eventName: eventName,
        eventData: { login_type: loginType, device_type: deviceType, user_id: customerId, is_onboarding_completed: isOnboardingCompleted, name: userName },
    });

    mixpanelTracker.identify(customerId);
    mixpanelTracker.trackEvents({
        eventName: eventName,
        eventData: { login_type: loginType, device_type: deviceType, user_id: customerId, is_onboarding_completed: isOnboardingCompleted, name: userName },
    });

    uxcamTracker.setUserIdentity({ userId: customerId });
    uxcamTracker.setUserProperty({ device_type: deviceType, user_id: customerId, is_onboarding_completed: isOnboardingCompleted, name: userName });
    uxcamTracker.trackEvents({
        eventName: eventName,
        eventData: { login_type: loginType, device_type: deviceType, user_id: customerId, is_onboarding_completed: isOnboardingCompleted, name: userName },
    });

    if (!isOnboardingCompleted || initUXCam) {
        uxcamTracker.startSession();
    }
};

export { loginTracker };
