export const onboardingConstants = {
    RESET_ONBOARDING_DATA: "RESET_ONBOARDING_DATA",
    UPDATE_ONBOARDING_DATA: "UPDATE_ONBOARDING_DATA",

    ONBOARDING_DATA_API: "v1/onboarding/details",
    ONBOARDING_DATA_SAGA: "ONBOARDING_DATA_SAGA",
    ONBOARDING_DATA_REQUEST: "ONBOARDING_DATA_REQUEST",
    ONBOARDING_DATA_RESPONSE: "ONBOARDING_DATA_RESPONSE",

    PORTFOLIO_DATA_API: "v1/investment/generate-portfolio",
    PORTFOLIO_DATA_SAGA: "PORTFOLIO_DATA_SAGA",
    PORTFOLIO_DATA_REQUEST: "PORTFOLIO_DATA_REQUEST",
    PORTFOLIO_DATA_RESPONSE: "PORTFOLIO_DATA_RESPONSE",

    PRICING_DATA_API: "v1/portfolio/pricing-details",
    PRICING_DATA_SAGA: "PRICING_DATA_SAGA",
    PRICING_DATA_REQUEST: "PRICING_DATA_REQUEST",
    PRICING_DATA_RESPONSE: "PRICING_DATA_RESPONSE",

    SUBMIT_RISK_ASSESSMENT_QUESTION_API:
        "v1/onboarding/submit-risk-assessment-questions",

    SUBMIT_RISK_PROFILE_API: "v1/onboarding/confirm-risk-profile",

    SUBMIT_INVESTMENT_API: "v1/investment/submit-investment-details",

    VERIFY_KYC_API: "v1/onboarding/verify-kyc",

    KYC_IMAGE_UPLOAD_API: "v1/onboarding/upload-kyc-docs",
    REVERIFY_KYC_API: "v1/onboarding/check-kyc-docs",

    INITIATE_DOCUMENT_SIGNING_API: "api/initate-sign",
    SEND_EMAIL_VERIFICATION_API:"customer/signup/send-email-verification-link",

    CONFIRM_INVESTMENT_INFO_API: "advisory/onboarding/confirm-investment",
};
