import { axios } from "helper/axios";
import { rmsConstants } from "./rmsConstants";

const rmsApis = {};

rmsApis.getRMSDetails = () => {
    return axios.get(rmsConstants.RMS_API);
}



export { rmsApis };