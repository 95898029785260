import React, { Component } from 'react'
import PageTitle from "components/PageTitle";
import JarvisCard from 'components/JarvisCard';
import HoldingTableStockField from 'components/JarvisOnestock/HoldingTableStockField';
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import { formatHelper } from 'helper/format';
import Table from 'components/common/Table';
import { dateTimeHelper } from 'helper/dateTime';
import Loader from 'components/common/Loader';
import NotAvailableData from 'components/JarvisOnestock/NotAvailableData';
import MobileResponsive from 'components/common/MobileResponsive';
import MobileOnestockHolding from 'components/JarvisOnestock/MobileOnestockHolding';

const columns = [
    {
        field: "stockName",
        headerText: "Stock",
        bodyCellComponent: (v, d) =>
            <>
                <HoldingTableStockField
                    stockCompany={v}
                    brokerName={d.brokerName}
                    companyName={d.stockSymbol}
                    companyType={d.marketCap}
                />
            </>
    },
    {
        field: "stockBuyDate",
        headerText: "Buy Date",
        bodyCellComponent: (v) => <Text variant="small">{v ? dateTimeHelper.format(v, '$DD/$MM/$YYYY') : null}</Text>
    },
    {
        field: "stockQuantity",
        headerText: "Qty.",
    },
    {
        field: "stockBuyPrice",
        headerText: "Buy Price & Value",
        bodyCellComponent: (v, d) =>
            <>
                <Stack>
                    <Text variant="small" sx={{ fontWeight: 500 }}>{formatHelper.currency(v, {
                        config: { maximumFractionDigits: 2 },
                    })}</Text>
                    <Text variant="caption1" sx={{ color: "#A2A7AE" }}>{formatHelper.currency(d.stockBuyValue, {
                        config: { maximumFractionDigits: 2 },
                    })}</Text>
                </Stack>
            </>
    },
    {
        field: "stockCurrentPrice",
        headerText: "Current Price & Value",
        bodyCellComponent: (v, d) =>
            <>
                <Stack>
                    <Text variant="small" sx={{ fontWeight: 500 }}>{formatHelper.currency(v, {
                        config: { maximumFractionDigits: 2 },
                    })}</Text>
                    <Text variant="caption1" sx={{ color: "#A2A7AE" }}>{formatHelper.currency(d.stockCurrentValue, {
                        config: { maximumFractionDigits: 2 },
                    })}</Text>
                </Stack>
            </>
    },
    {
        field: "stockGainLoss",
        headerText: "Gain/Loss",
        bodyCellComponent: (v, d) =>
            <>
                <Stack>
                    {
                        v < 0 ?
                            <Text variant="small" sx={{ fontWeight: 500, color: "#D44C4C" }}>{formatHelper.currency(v, {
                                config: { maximumFractionDigits: 2 },
                            })}</Text>
                            :
                            <Text variant="small" sx={{ fontWeight: 500, color: "#45AC70" }}>{formatHelper.currency(v, {
                                config: { maximumFractionDigits: 2 },
                            })}</Text>
                    }
                    {
                        d.stockGainLossPercentage < 0 ?
                            <Text variant="caption1" sx={{ color: "#D44C4C" }}>
                                {(d.stockGainLossPercentage || 0) + "%"}
                            </Text>
                            :
                            <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                {(d.stockGainLossPercentage || 0) + "%"}
                            </Text>
                    }
                </Stack>
            </>
    },
]
export class OnestockExistingHoldingContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.getExistingHoldingListAction()
    }

    render() {
        const { existingHoldingListStore, existingHoldingLoading } = this.props

        return (
            <>
                <PageTitle
                    heading="Holdings"
                    disableBreadcrumb
                />

                <Loader loading={existingHoldingLoading} />

                {
                    existingHoldingListStore && existingHoldingListStore.length > 0 ? (
                        <>
                            <MobileResponsive
                                web={() => (
                                    <JarvisCard>
                                        <Table
                                            columns={columns}
                                            data={existingHoldingListStore}
                                            tbl_wrapper_sx={{
                                                border: "1px solid #e3e3e3",
                                                borderRadius: "8px",
                                            }}
                                        />
                                    </JarvisCard>
                                )}
                                mobile={() => (
                                    <JarvisCard sx={{ border: "1px solid #EEF0F2", padding: "0", height: "auto" }}>
                                        {
                                            existingHoldingListStore?.map((data) => {
                                                return (
                                                    <>
                                                        <Text sx={{ padding: "15px 10px" }}>
                                                            <MobileOnestockHolding
                                                                holdingData={data}
                                                            />
                                                        </Text>
                                                        <Text sx={{ borderBottom: "1px solid #E2E2E2", padding: "0px" }}></Text>
                                                    </>
                                                );
                                            })
                                        }
                                    </JarvisCard>
                                )}
                            />
                        </>
                    )
                        :
                        <NotAvailableData
                            actionType="HOLDINGS"
                        />
                }
            </>
        )
    }
}

export default OnestockExistingHoldingContainer