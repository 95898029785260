import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import OnboardedRMSOverview from "components/Overview/OnboardedRMSOverview";

class OverviewContainer extends Component {
    componentDidMount() {
        this.props.getOnboardingDataAction();
    }

    componentDidUpdate() {
        if (this.props.isRMSOnboardingCompleted && !this.props.isRMSOnboardingDataLoading && !this.props.isRMSPortfolioStatsLoading && !this.props.isRMSPortfolioStatsInitialCalled) {
            this.props.getPortfolioStatsAction();
        }

        if (this.props.isRMSOnboardingCompleted && !this.props.isRMSOnboardingDataLoading && !this.props.isRMSCallStatsLoading && !this.props.isRMSCallStatsInitialCalled) {
            this.props.getRmsCallStatsAction();
        }
    }

    render() {
        const {
            rmsPortfolioStats,
            rmsCallStats,
            isRMSCallStatsLoading,
            isRMSPortfolioStatsLoading,
            isRMSOnboardingCompleted,
            isRMSPortfolioStatsInitialCalled,
            isRMSCallStatsInitialCalled,
        } = this.props;

        return (
            <>
                {isRMSOnboardingCompleted && isRMSCallStatsInitialCalled && isRMSPortfolioStatsInitialCalled ? (
                    <OnboardedRMSOverview
                        rmsPortfolioStats={rmsPortfolioStats}
                        rmsCallStats={rmsCallStats}
                        isRMSCallStatsLoading={isRMSCallStatsLoading}
                        isRMSPortfolioStatsLoading={isRMSPortfolioStatsLoading}
                        history={this.props.history}
                    />
                ) : null}
            </>
        );
    }
}

export default withRouter(OverviewContainer);
