import { jarvisOnestockOnboardingConstants } from "./jarvisOnestockOnboardingConstants";

const jarvsiOnestockOnboardingActions = {};

jarvsiOnestockOnboardingActions.updateOnestockOnboardingData = ({
    currentStage,
    riskProfileId,
    panNumber,
    dateOfBirth,
    aadharNumber,
    isManualKYC,
    isPanUploaded,
    isAadhaarFrontUploaded,
    isAadhaarBackUploaded,
    portfolioId,
    isOnboardingCompleted,
    isPaymentCompleted
}) => ({
    type: jarvisOnestockOnboardingConstants.ONESTOCK_UPDATE_ONBOARDING_DATA,
    payload: {
        currentStage,
        riskProfileId,
        panNumber,
        dateOfBirth,
        aadharNumber,
        isManualKYC,
        isPanUploaded,
        isAadhaarFrontUploaded,
        isAadhaarBackUploaded,
        portfolioId,
        isOnboardingCompleted,
        isPaymentCompleted
    },
});


jarvsiOnestockOnboardingActions.getOnboardingData = () => ({
  type: jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_SAGA,
});

export { jarvsiOnestockOnboardingActions };
