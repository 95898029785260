import React from "react";
import NewsAndArticlesContainer from "containers/NewsAndArticles";
import PageTitle from "components/PageTitle";
import { tracker } from "library/tracker";

const NewsAndArticles = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);
    return (
        <>
            <PageTitle heading="News & Articles" sx={{pb: 0}} />
            <NewsAndArticlesContainer />
        </>
    );
};

export default NewsAndArticles;
