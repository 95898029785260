import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "components/common/Box";
import Grid from "components/common/Grid";
import ImageComponent from "components/common/Image";
import Text from "components/common/Text";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import JarvisButton from "components/JarvisButton";
import { formatHelper } from "helper/format";

const referlLink = "https://www.jarvisinvest/jkhjasdjkjlkjaljas";

const useStyles = makeStyles((theme) => ({
  containerDiv: {
    backgroundColor: "#fff",
    padding: "36px 30px 56px 60px",
    borderRadius: "7px",
    [theme.breakpoints.down("sm")]: {
      padding: "36px 16px 56px 16px",
    },
  },
  referInfo: {
    [theme.breakpoints.down("sm")]: {
      width: "210px",
    },
  },

  image: {
    height: "248px",
    width: "241px",

    [theme.breakpoints.down("sm")]: {
      height: "128px",
      width: "78px",
    },
  },
  imageContainer: {
    marginLeft: "35px",
    bgcolor: { xs: "primary.dark", sm: "secondary.dark" },
    [theme.breakpoints.down("sm")]: {
      height: "50%",
      marginLeft: 0,
    },
  },

  inviteFriends: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    paddingTop: "30px",
    [theme.breakpoints.down("md")]: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  },
  descDiv: {
    width: "65%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  referEarnings: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #F1F1F1",
    borderRadius: "6px",
    width:"250px",
    padding: "22px 60px 22px 36px",
    marginRight:"1rem",
    [theme.breakpoints.down("md")]: {
      padding: "12px",
      display: "flex",
    },
  },
  referalDiv: {
    width: "35%",
    border: "1px solid #E8EBEE",
    borderRadius: "6px",
    padding: "31px",
    margin: "36px 30px 56px 60px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      padding: 10,
      display: "flex",
      flexDirection: "column !important",
      justifyContent: "center",
    },
  },
  referalLinkText: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E3EBF2",
    borderRadius: "10px",
  },
  copyReferalButton: {
    backgroundColor: "#1D9BFF",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },

  buttonContainer: {
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "20px",
    },
  },
}));

const ReferCard = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.containerDiv}>
      <Box className={classes.root}>
        <Grid className={classes.descDiv}>
          <Grid className={classes.inviteFriends}>
            {/* invite Freiends */}
            <Grid>
              <Text sx={{ fontSize: { sm: "16px", md: "22px" } }}>
                Invite Friends &amp;
              </Text>
              <Text
                sx={{
                  color: "#1D9BFF",
                  fontSize: { sm: "28px", md: "36px" },
                  height: { sm: "50%", md: "auto" },
                }}
              >
                Start Earning!
              </Text>
              <Text
                sx={{
                  color: "#808591",
                  fontSize: "14px",
                  marginTop: "8px",
                  padding: { xs: "0 5px 0 0 ", md: "auto" },
                }}
              >
                Invite a friend to JARVIS, and enjoy a lifetime of <br></br>{" "}
                earnings from their activity.
              </Text>
              <Grid className={classes.referInfo}>
                <Text style={{ marginTop: "28px" }}>Earn</Text>
                <Text style={{ color: "#808591", fontSize: "14px" }}>
                  {" "}
                  <span style={{ fontSize: "18px", color: "#45AC70" }}>
                    {formatHelper.currency(100)}
                  </span>{" "}
                  on every successful <br></br> registration from you referrals
                </Text>{" "}
              </Grid>
              <br></br>
            </Grid>

            {/* Mobile Image */}
            <Grid className={classes.imageContainer}>
              <ImageComponent
                src="/images/svgs/mobile.svg"
                className={classes.image}
              />
            </Grid>
          </Grid>

          {/* Referall Statistics */}
          <Grid
            sx={{
              // maxWidth: { xs: "100%", md: "60%" },
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "30px",
              marginBottom: { xs: 3, md: "auto" },
            }}
          >
            <Grid className={classes.referEarnings}>
              <MonetizationOnOutlinedIcon style={{ marginTop: "5px" }} />
              <Grid sx={{ marginLeft: "15px" }}>
                <Text
                  sx={{
                    color: "#1D9BFF",
                    fontSize: { xs: "16px", md: "24px" },
                  }}
                >
                  {formatHelper.currency(200)}
                </Text>
                <Text sx={{ fontSize: { xs: "12px", md: "16px" } }}>
                  Total Referral <br></br> Earnings
                </Text>
              </Grid>
            </Grid>

            <Grid className={classes.referEarnings}>
              <MonetizationOnOutlinedIcon style={{ marginTop: "5px" }} />
              <Grid sx={{ marginLeft: "15px" }}>
                <Text
                  sx={{
                    color: "#1D9BFF",
                    fontSize: { xs: "16px", md: "24px" },
                  }}
                >
                  {formatHelper.currency(200)}
                </Text>
                <Text sx={{ fontSize: { xs: "12px", md: "16px" } }}>
                  Total Referral <br></br> Earnings
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.referalDiv}>
          <Text variant="subtitle1" style={{ marginBottom: "20px" }}>
            Start Referring
          </Text>

          {/* Link Input    */}
          <Grid container direction="row" className={classes.referalLinkText}>
            <Grid xs={10}>
              <Grid
                style={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "row",
                  flexShrink: 1,
                }}
              >
                <Text color="#202020" style={{ padding: " 20px 0 20px 20px " }}>
                  Link
                </Text>
                <Text
                  color="#38A1FF"
                  style={{ padding: " 20px 20px 20px 20px " }}
                >
                  {referlLink.length > 16
                    ? `${referlLink.substring(0, 16)}…`
                    : referlLink}
                </Text>
              </Grid>
            </Grid>
            {/* Copy Button */}
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              xs={2}
              className={classes.copyReferalButton}
            >
              <Grid
                style={{
                  display: "flex",
                }}
              >
                {" "}
                <ContentCopyIcon sx={{ color: "#fff" }} />{" "}
              </Grid>
            </Grid>
          </Grid>

          {/* Code Input     */}
          <Grid
            container
            direction="row"
            className={classes.referalLinkText}
            style={{ marginTop: 11.5 }}
          >
            <Grid xs={10}>
              <Grid
                style={{ width: "85%", display: "flex", flexDirection: "row" }}
              >
                <Text color="#202020" style={{ padding: " 20px 0 20px 20px " }}>
                  Code
                </Text>
                <Text
                  color="#38A1FF"
                  style={{ padding: " 20px 20px 20px 20px " }}
                >
                  kdjoi23
                </Text>
              </Grid>
            </Grid>
            {/* Copy Button */}
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              xs={2}
              className={classes.copyReferalButton}
            >
              <Grid
                style={{
                  display: "flex",
                }}
              >
                {" "}
                <ContentCopyIcon sx={{ color: "#fff" }} />{" "}
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.buttonContainer}>
            <JarvisButton
              sx={{
                width: { xs: "50%", md: "auto" },
                marginBottom: { xs: "2px", md: "65px" },
                marginTop: { xs: "15px", md: "70px" },
              }}
            >
              Share
            </JarvisButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ReferCard;
