import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvisRMSTransactionActions } from "stores/jarvisRMS/jarvisRMSTransaction/jarvisRMSTransactionActions";
import {jarvisRMSExistingHoldingAction} from "stores/jarvisRMS/jarvisRMSExistingHolding/jarvisRMSExistingHoldingAction" ;

const mapStateToProps = (state) => {
    return {
        transactionDetailList:
            state.jarvisRMS.rmsTransaction.transactionDetail.list,
        transactionDetailLoading:
            state.jarvisRMS.rmsTransaction.loading.transactionDetail,
        transactionDetailTotal:
            state.jarvisRMS.rmsTransaction.transactionDetail.total,
        portfolioListStore: state.jarvisRMS.rmsExistingHolding.portfolioList,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRMSTransactionDetailAction:
                jarvisRMSTransactionActions.getRMSTransactionDetail,
            getPortfolioList: jarvisRMSExistingHoldingAction.getPortfolioList,
        },
        dispatch
    );

const RMSTransactionDetailStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSTransactionDetailStore;
