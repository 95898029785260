import React from "react";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
const RMSSyncPortfolioUpload = (props) => {
  const { handleUploadModalOpen, downloadSampleFileLink } = props;
  return (
    <>
      <Stack
        backgroundColor="#F4F8FC"
        mt={4}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        {/* for web */}
        <Stack sx={{display:{xs:"none" , md:"contents"}}} >
        <Stack>
          <Text variant={"small"} sx={{ fontWeight: "500" }}>Can't find your broker?</Text>
          <Stack direction="row" alignItems="center">
            <Text variant={"caption1"} color="#A2A7AE">
              Upload excel with your holding,&nbsp; please refer to the sample file
            </Text>
            <FileDownloadOutlinedIcon
              sx={{ color: "#6F9ED7", marginLeft: 1, fontSize: 18, marginRight: 0.5 }}
            />
            <a
              href={downloadSampleFileLink}
              style={{ textDecoration: "none", color: "#6F9ED7", fontWeight: "400" }}
              download
            >
              <Text variant="caption1">Download Here</Text>
            </a>
          </Stack>
        </Stack>
        <Stack>
          <Button
            size="small"
            sx={{ color: "white", textTransform: "capitalize", padding: "10px 24px", backgroundColor: '#2B70C5' }}
            onClick={handleUploadModalOpen}
          >
            Upload Excel
          </Button>
        </Stack>
      </Stack>
      {/* for mobile */}
      <Stack sx={{display:{xs:"flex" , md:"none"}}} >
        <Stack direction="column" mb={2}>
          <Text variant={"small"} sx={{ fontWeight: "500" }}>Can't find your broker?</Text>
          <Text variant={"caption1"} color="#A2A7AE">
            Upload excel with your holding,&nbsp; please refer to the sample file
          </Text>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <a
            href={downloadSampleFileLink}
            style={{ textDecoration: "none", color: "#6F9ED7", fontWeight: "400",display:"flex" }}
            download
          >
            <FileDownloadOutlinedIcon
              sx={{ color: "#6F9ED7", marginLeft: 1, fontSize: 18, marginRight: 0.5 }}
            />
            <Text variant="caption1">Download Here</Text>
          </a>
          <Button
            size="small"
            sx={{ color: "white", textTransform: "capitalize", padding: "10px 24px", backgroundColor: '#2B70C5' }}
            onClick={handleUploadModalOpen}
          >
            Upload Excel
          </Button>
        </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default RMSSyncPortfolioUpload;
