import React from "react";
import { withRouter } from "react-router-dom";
import Text from "components/common/Text";
import JarvisCard from "components/JarvisCard";
import JarvisDialog from "components/JarvisDialog";
import { config } from "config";
import Stack from "components/common/Stack";
import Container from "components/common/Container";
import Box from "components/common/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/common/IconButton";
import JarvisButton from "components/JarvisButton";
import { axios } from "helper/axios";
import { localStore } from "stores/localStorage";
import Loader from "components/common/Loader";
import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";
import { jarvisRMSOnboardingParser } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingParser";
import { jarvisOnestockOnboardingApis } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingApis";
import { jarvisOnestockOnboardingParser } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingParser";
import { onboardingApis } from "stores/onboarding/onboardingApis";
import { onboardingParsers } from "stores/onboarding/onboardingParsers";

const VERIFICATION_STATUS = {
  SUCCEED: "SUCCEED",
  FAILED: "FAILED",
  PENDING: "PENDING",
};

const REDIRECTION_URIS = {
  RMS_INITIAL: {
    SUCCEED: "/jarvis-rms/existing-holding",
    FAILED: "/jarvis-rms/onboarding/ckyc",
    CANCELLED: "/jarvis-rms/onboarding/ckyc",
  },
  PORTFOLIO_INITIAL: {
    SUCCEED: "/jarvis-portfolio/onboarding/choose-broker",
    FAILED: "/jarvis-portfolio/onboarding/ckyc",
    CANCELLED: "/jarvis-portfolio/onboarding/ckyc",
  },
  ONESTOCK_INITIAL: {
    SUCCEED: "/jarvis-onestock/dashboard",
    FAILED: "/jarvis-onestock/onboarding/ckyc",
    CANCELLED: "/jarvis-onestock/onboarding/ckyc",
  },
};

class ManualDocumentSigningContainer extends React.Component {
  interval = null;
  constructor(props) {
    super(props);
    this.state = {
      isCustomerAgreeByTndC: false,
      agreementFilePath:"docs/client_agreement.html",
      dialogContent: {
        icon: "",
        title: "",
        content: "",
        actionText: "",
    },
    dialogOpen: false,
    isLoading: true,
    verificationStatus: "",
    isSigningInProcess: false,
    returnUrl:'/',
    dataUrl : ''
    };
  }
  async componentDidMount(){
    if(this.props.location.state.productCode === config.productCodes.ONESTOCK){
      let agreement = "docs/client_agreement_onestock.html"
      this.setState({agreementFilePath:agreement});
    }
    await this.props.getProfileDataAction()
    

  }
  async componentDidUpdate(prevProps,prevState){
    let customerProfileDataLength = Object.keys(this.props.customerProfile).length
    if(Object.keys(prevProps.customerProfile).length !== customerProfileDataLength && customerProfileDataLength > 0){
      await this.setTemplateParams()
    }
  }
  setTemplateParams = async()=>{
    const {productCode} = this.props.location.state
    const {customerProfile} = this.props
    let templateParams = {};
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    if(productCode  === config.productCodes.ONESTOCK){
      const{ONESTOCK}= customerProfile;
      const feeCharged = ONESTOCK.paymentFrequency === 'MONTHLY'?ONESTOCK.planTotalPrice:'NA'
      const feeChargedHalfYearly = ONESTOCK.paymentFrequency === 'HALF_YEARLY'?ONESTOCK.planTotalPrice:'NA'
       templateParams = {
        '${agreement_date}' : new Date().toLocaleDateString('en-IN',options),
        '${customer_name}' : customerProfile.fullName,
        '${investment_amount}' : 'NA',
        '${fees_charged}' : feeCharged,
        '${fees_charged_half_yearly}' : feeChargedHalfYearly,
        '${signature}'                 :'/images/signature.jpg'
      }
    }
    else if(productCode  === config.productCodes.RMS){
      const{RMS}= customerProfile;
      const feesCharged = RMS.paymentFrequency === 'MONTHLY'?RMS.planTotalPrice:'NA'
      const feesChargedHalfYearly = RMS.paymentFrequency === 'HALF_YEARLY'?RMS.planTotalPrice:'NA'
      let subscriptionStartingDate = RMS.initialPaymentDate?RMS.initialPaymentDate:'-'
      let subscriptionEndDate = RMS.expiryDate?RMS.expiryDate:'-';
      if(subscriptionStartingDate !== '-'){
        subscriptionStartingDate = new Date(subscriptionStartingDate).toLocaleDateString('en-IN',options);
      }
      if(subscriptionEndDate !== '-'){
        subscriptionEndDate = new Date(subscriptionEndDate).toLocaleDateString('en-IN',options);
      }
       templateParams = {
        '${agreement_date}'             : new Date().toLocaleDateString('en-IN',options),
        '${customer_name}'              : customerProfile.fullName,
        '${investment_amount}'          : 'NA',
        '${fees_charged}'               : feesCharged,
        '${fees_charged_half_yearly}'   : feesChargedHalfYearly,
        '${subscription_starting_date}' : subscriptionStartingDate,
        '${subscription_end_date}'      : subscriptionEndDate,
        '${annual_fees_percent}'        : 'NA',
        '${half_yearly_fees_percent}'   : 'NA',
        '${signature}'                  : '/images/signature.jpg'
      }
      

    }
    else if(productCode  === config.productCodes.PORTFOLIO){
      const{PORTFOLIO}= customerProfile;
      const feesCharged = PORTFOLIO.paymentFrequency === 'MONTHLY'?PORTFOLIO.planTotalPrice:'NA'
      const feesChargedHalfYearly = PORTFOLIO.paymentFrequency === 'HALF_YEARLY'?PORTFOLIO.planTotalPrice:'NA'
      const investmentAmount = PORTFOLIO.investmentAmount?PORTFOLIO.investmentAmount:0;
      let subscriptionStartingDate = PORTFOLIO.initialPaymentDate?PORTFOLIO.initialPaymentDate:'-';
      let subscriptionEndDate = PORTFOLIO.expiryDate?PORTFOLIO.expiryDate:'-'; 
      let annualFeesPercentage = PORTFOLIO.annualFeesPercentage?PORTFOLIO.annualFeesPercentage:0;
      let halfYearlyFeesPercentage = PORTFOLIO.annualFeesPercentage?PORTFOLIO.annualFeesPercentage / 2: 0
       
      if(subscriptionStartingDate !== '-'){
        subscriptionStartingDate= new Date(subscriptionStartingDate).toLocaleDateString('en-IN',options);
      }
      if(subscriptionEndDate !== '-'){
        subscriptionEndDate = new Date(subscriptionEndDate).toLocaleDateString('en-IN',options);
      }
       templateParams = {
        '${agreement_date}'             : new Date().toLocaleDateString('en-IN',options),
        '${customer_name}'              : customerProfile.fullName,
        '${investment_amount}'          : investmentAmount,
        '${fees_charged}'               : feesCharged,
        '${fees_charged_half_yearly}'   : feesChargedHalfYearly,
        '${subscription_starting_date}' : subscriptionStartingDate,
        '${subscription_end_date}'      : subscriptionEndDate,
        '${annual_fees_percent}'        : annualFeesPercentage,
        '${half_yearly_fees_percent}'   : halfYearlyFeesPercentage,
        '${signature}'                  : `/images/signature.jpg`
      }
    }
    if(Object.keys(templateParams).length > 0){
      const fileResponse = await fetch(process.env.PUBLIC_URL + this.state.agreementFilePath);
      let content = await fileResponse.text();
      if(content){
        const templateParamsArray = Object.keys(templateParams);
        for(let param of templateParamsArray ){
          content = content.replaceAll(param,templateParams[param])
        }
        // const dataUrl = `data:text/html;charset=utf-8,${encodeURIComponent(content)}`;
        const dataUrl = content;
        this.setState({dataUrl},()=>{
          this.setState({isLoading:false});
        })
      }
    }
  }
  handleSubmit = async () => {

    if (!this.props.location.state.productCode) {
      this.props.location.state.productCode = 'PORTFOLIO';
    }
    let productCode = this.props.location.state.productCode
    let dialogContent =this.generateSigningStatusContent(VERIFICATION_STATUS['PENDING'])
    this.setState({isSigningInProcess:true, dialogOpen:true,verificationStatus:VERIFICATION_STATUS['PENDING'],dialogContent})
    const payload= {
      productCode
    }
    try{
      const res = await axios.post('v1/onboarding/manual-agreement-signing',payload);
      if(res.status === 200 || res.status === 201){
        let verificationStatus = VERIFICATION_STATUS.PENDING;
        if (["RMS", "JRMS"].includes(this.props.location.state.productCode)) {
          const onboardingResponse = await jarvisRMSOnboardingApis.getOnboardingData();
          const parsedOnboardingResponse = jarvisRMSOnboardingParser.onboardingData(onboardingResponse);
          this.props.updateRMSOnboardingDataAction(parsedOnboardingResponse);
          this.props.getProfileDataAction();
          verificationStatus = parsedOnboardingResponse.isOnboardingCompleted ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
      }else if (["ONESTOCK"].includes(this.props.location.state.productCode)) {
          const onboardingResponse = await jarvisOnestockOnboardingApis.getOnboardingData();
          const parsedOnboardingResponse2 = jarvisOnestockOnboardingParser.onboardingData(onboardingResponse);
          this.props.updateOnestockOnboardingDataAction(parsedOnboardingResponse2);
          this.props.getProfileDataAction();
          verificationStatus = parsedOnboardingResponse2.isOnboardingCompleted ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
      } else {
          // validate verification status
          const onboardingResponse = await onboardingApis.getOnboardingData();
          const parsedOnboardingResponse = onboardingParsers.onboardingData(onboardingResponse);
          this.props.updatePortfolioOnboardingDataAction(parsedOnboardingResponse);
  
          verificationStatus = parsedOnboardingResponse.currentStage === 5 ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
      }
        let dialogContent =this.generateSigningStatusContent(verificationStatus)
        this.setState({dialogContent, verificationStatus:verificationStatus, dialogOpen:true})
      }
      else{
        let returnUrl = REDIRECTION_URIS[productCode+'_INITIAL']['FAILED']
        let verification =VERIFICATION_STATUS['FAILED']
        let dialogContent =this.generateSigningStatusContent(verification)
        this.setState({verificationStatus:verification,returnUrl,dialogContent,dialogOpen:true})
      }
      }
      catch (error){
        let verificationStatus = VERIFICATION_STATUS.PENDING;
        if (["RMS", "JRMS"].includes(this.props.location.state.productCode)) {
          const onboardingResponse = await jarvisRMSOnboardingApis.getOnboardingData();
          const parsedOnboardingResponse = jarvisRMSOnboardingParser.onboardingData(onboardingResponse);
          this.props.updateRMSOnboardingDataAction(parsedOnboardingResponse);
          this.props.getProfileDataAction();
          verificationStatus = parsedOnboardingResponse.isOnboardingCompleted ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
      }else if (["ONESTOCK"].includes(this.props.location.state.productCode)) {
          const onboardingResponse = await jarvisOnestockOnboardingApis.getOnboardingData();
          const parsedOnboardingResponse2 = jarvisOnestockOnboardingParser.onboardingData(onboardingResponse);
          this.props.updateOnestockOnboardingDataAction(parsedOnboardingResponse2);
          this.props.getProfileDataAction();
          verificationStatus = parsedOnboardingResponse2.isOnboardingCompleted ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
      } else {
          // validate verification status
          const onboardingResponse = await onboardingApis.getOnboardingData();
          const parsedOnboardingResponse = onboardingParsers.onboardingData(onboardingResponse);
          this.props.updatePortfolioOnboardingDataAction(parsedOnboardingResponse);
  
          verificationStatus = parsedOnboardingResponse.currentStage === 5 ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
      }
        let dialogContent =this.generateSigningStatusContent(verificationStatus)
        this.setState({dialogContent, verificationStatus:verificationStatus, dialogOpen:true})
      }
      finally{
        this.setState({isSigningInProcess:false})
      }
    }

    generateSigningStatusContent = (verificationStatus) => {
      const { dialogContent } = this.state;

      if (verificationStatus === VERIFICATION_STATUS.SUCCEED) {
          dialogContent.icon = "/images/svgs/success.gif";
          dialogContent.title = "Process Completed";
          dialogContent.content = (<Text>
              Congratulations! Document signing process is completed successfully!<br />
              {
                  (localStore.getActiveProduct() === "PORTFOLIO") ?
                  "Please proceed with linking your demat account. This is the most critical of all the steps to experiencing the full potential of Jarvis.": ""
              }
          </Text>);
          dialogContent.actionText = "Continue";
      }
      if (verificationStatus === VERIFICATION_STATUS.FAILED) {
          dialogContent.icon = "/images/svgs/failed.gif";
          dialogContent.title = "Process Failed";
          dialogContent.content = (<Text>Oops! Document signing process failed. Please try again later!</Text>);
          dialogContent.actionText = "Go Back";
      }
      if (verificationStatus === VERIFICATION_STATUS.PENDING) {
        dialogContent.icon = "/images/svgs/inprogress.gif";
        dialogContent.title = "Process Pending";
        dialogContent.content = (<Text>Process is not completed yet. Please wait for sometime.</Text>);
        dialogContent.actionText = "";
    }

      return dialogContent;
  }
  handleBackClick = ()=>{
    if (!this.props.location.state.productCode) {
      this.props.location.state.productCode = 'PORTFOLIO';
    }
    let productCode = this.props.location.state.productCode
    this.props.history.replace({
      pathname: REDIRECTION_URIS[productCode + '_INITIAL']['CANCELLED'],
  })
  }
  handleDialogClose = () => {
    const { verificationStatus } = this.state;
    if (!this.props.location.state.productCode) {
      this.props.location.state.productCode = 'PORTFOLIO';
    }
    let productCode = this.props.location.state.productCode
    const redirectionURI = REDIRECTION_URIS[`${productCode}_INITIAL`][verificationStatus];

    this.setState({
        dialogOpen: false
    }, () => {    
        this.props.history.replace({
            pathname: redirectionURI,
            state: {
                status: verificationStatus
            }
        });
    })
}
  

  render() {
    const { dialogOpen, dialogContent,isLoading, isSigningInProcess } = this.state;
    return (
      <>

        { !isLoading && <Container
          maxWidth={false}
          disableGutters
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#e1e1e1",
          }}
        >
          <Stack
            direction="row"
            sx={{
              height: "100%",
              width: { xs: "100%", md: "36rem" },
              flexWrap: "wrap",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                flex: "0 auto",
              }}
              // px={2}
            >
              <Box
                spacing={{ xs: 2, sm: 4 }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 16px",
                  borderBottom: "1px solid rgba(51, 51, 51, 0.10)",
                  gap: "8px",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <IconButton onClick={() => this.handleBackClick()}>
                    <ArrowBackIcon fontSize="medium" />
                  </IconButton>
                </Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Text
                    sx={{
                      color: "#212225",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "normal",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                    }}
                  >
                    User Agreement
                  </Text>
                </Stack>
              </Box>
              <Stack
                direction="column"
                sx={{
                  marginTop: "17px",
                  marginBottom: "25px",
                  marginX: "16px",
                  gap: "28px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "24px",
                    width: "100%",
                  }}
                >
                  <Stack direction="column">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "8px",
                      }}
                    >
                      <Text
                        sx={{
                          color: "var(--Black, #303236)",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "24px",
                        }}
                      >
                        Advisory Agreement
                      </Text>
                      <Text
                        sx={{
                          color: "var(--Dark-Grey, #676E76)",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        As Per Regulatory Compliance You Need To Sign The
                        Advisory Agreement To Get Personalized Financial
                        Advisory From Us.
                      </Text>
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                      gap: "32px",
                      padding: "18px 19px",
                      borderRadius: "8px",
                      // background:"#FBFBFB",
                    }}
                  >
                    {/* <Box
                      component="iframe"
                      src={this.state.dataUrl}
                      width="100%"
                      height={{
                        xs: `calc(${window.innerHeight}px - 150px)`,
                        sm: "250px",
                      }}
                      title="Client Agreement"
                      frameBorder="0"
                    ></Box> */}
                    <div dangerouslySetInnerHTML={{__html:this.state.dataUrl}} />
                  </Box>
                </Box>
                <Stack direction="column" gap="24px">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <input
                      type="checkbox"
                      Style={`width: 16px;
                            height: 16px;
                            flex-shrink: 0;
                            border-radius: 4px;
                            border: 1px solid var(--gray-300, #D0D5DD);
                            background: var(--White, #FFF);`}
                      checked={this.state.isCustomerAgreeByTndC}
                      onChange={(event) =>
                        this.setState({
                          isCustomerAgreeByTndC: event.target.checked,
                        })
                      }
                    />
                    <Text
                      sx={{
                        color: "var(--Black, #303236)",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                      }}
                    >
                      I Have Read All The Terms & Conditions
                    </Text>
                  </Box>
                  <JarvisButton
                    disabled={!this.state.isCustomerAgreeByTndC}
                    onClick={this.handleSubmit}
                  >
                    Accept and Continue
                  </JarvisButton>
                </Stack>
              </Stack>
              <Box
                sx={{
                  display: "flex",

                  width: { xs: "100%", md: "36rem" },
                  height: "34px",
                  padding: "10px 0px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  background: "#FFF",
                  boxShadow: "1px 0px 8px 0px rgba(0, 0, 0, 0.16)",
                  position: "fixed",
                  bottom: 0,
                  zIndex: 2,
                }}
              >
                <Text
                  sx={{
                    color: "var(--Black, #303236)",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Powered by
                </Text>
                <img
                  src="/jarvis-text.png"
                  width="64px"
                  height="24px"
                  alt="jarvis"
                />
              </Box>
            </Stack>
          </Stack>
        </Container>}
        <JarvisCard>
          <Loader loading={isLoading} />
          <JarvisDialog
            disableCloseIcon
            open={dialogOpen}
            onClose={this.handleDialogClose}
            onConfirm={this.handleDialogClose}
            title={dialogContent.title}
            content={dialogContent.content}
            icon={{
              src: dialogContent.icon,
              height: 120,
              width: 120,
            }}
            button={{
              text: dialogContent.actionText,
              disableEndIcon: true,
              loading: isSigningInProcess,
            }}
          />
        </JarvisCard>
      </>
    );
  }
}

export default withRouter(ManualDocumentSigningContainer);
