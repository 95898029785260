import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { transactionConstants } from "./transactionConstants";

const initialState = {
    transactionList: [],
    transactionCount: 0,
    transactionListApiCounter: 0,
    loading: {
        transactionList: 0,
    },
};

export const transactionReducers = persistReducer(
    {
        storage,
        key: "transaction",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case transactionConstants.RESET_TRANSACTION_DATA: {
                return initialState;
            }

            case transactionConstants.TRANSACTION_LIST_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        transactionList: 1,
                    },
                    transactionList: initialState.transactionList,
                    transactionCount: initialState.transactionCount,
                };
            }

            case transactionConstants.TRANSACTION_LIST_RESPONSE: {
                const { data, count } = payload;
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        transactionList: 0,
                    },
                    transactionList: data,
                    transactionCount: count,
                    transactionListApiCounter: state.transactionListApiCounter + 1,
                };
            }

            default:
                return state;
        }
    }
);
