import React from "react";
import JarvisButton from "components/JarvisButton";
import Stack from "components/common/Stack";
import Text from "components/common/Text";

const RMSPortfolioSyncDelete = ({submitBtnText="save", onSubmit, onClose }) => {
  return (
    <Stack sx={{ borderRadius: "10px" }}>
      <Stack sx={{ flex: 1 }}>
        <Text
          variant={"subheading"}
          sx={{ textAlign: "center", color: "#808591 !important" }}
        >
          Are you sure you want to delete this portfolio, you can't undo it
          later.
        </Text>
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: 2,
        }}
      >
        <JarvisButton
          variant="outlined"
          disableEndIcon
          sx={{ width: "100px",mr:2 }}
          onClick={onClose}
        >
          Cancel
        </JarvisButton>

        <JarvisButton
          disableEndIcon
          sx={{ width: "100px", boxShadow: "none" }}
          onClick={onSubmit}
        >
          {submitBtnText}
        </JarvisButton>
      </Stack>
    </Stack>
  );
};

export default RMSPortfolioSyncDelete;
