import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { rmsActions } from "stores/rms/rmsActions";

const mapStateToProps = (state) => {
  const {
    sell,
    buy,
    isLoading,
    hasRmsData,
    isBuyCallEnabled,
  } = state.rms;
  return {
    sell,
    buy,
    isLoading,
    hasRmsData,
    isBuyCallEnabled,

    profileData: state.customer.profile,

    brokersList: state.common.brokers,
    isBrokersListLoading: state.common.loading.brokers,
    isBrokersListInitialCalled: state.common.initialCalled.brokers,
    centrumReferalURL: state.common.featureConfig.centrumReferalURL
  }
  
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        getRMSDetailsAction: rmsActions.getRMSDetails,
        getBrokersAction: commonActions.getBrokers,
    },
    dispatch
  );

const RMSStore = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSStore;
