// import { appsFlyerTracker } from "../appsFlyer";
// import { mixpanelTracker } from "../mixpanel";
// import { uxcamTracker } from "../uxcam";

const archiveFlowTracker = {};

archiveFlowTracker.confirmManuallyExecutedTransaction = () => {
    // const eventName = "confirm_manually__executed_transaction";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.confirmManuallyExecutedTransactionFailed = () => {
    // const eventName = "confirm_manually__executed_transaction_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.confirmManuallyExecutedTransactionSuccess = () => {
    // const eventName = "confirm_manually__executed_transaction_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archEdit = () => {
    // const eventName = "arch_edit";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archEditFailed = ({ error_msg }) => {
    // const eventName = "arch_edit_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

archiveFlowTracker.archEditSuccess = () => {
    // const eventName = "arch_edit_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archSaveSubmit = () => {
    // const eventName = "arch_save_submit";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archSaveSubmitFailed = () => {
    // const eventName = "arch_save_submit_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archSaveSubmitSuccess = () => {
    // const eventName = "arch_save_submit_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archConfirmTransaction = () => {
    // const eventName = "arch_confirm_transaction";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archConfirmTransactionFailed = () => {
    // const eventName = "arch_confirm_transaction_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archConfirmTransactionSuccess = () => {
    // const eventName = "arch_confirm_transaction_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archAwesomeGoBack = () => {
    // const eventName = "arch_awesome,_go_back";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

archiveFlowTracker.archAwesomeGoBackSuccess = () => {
    // const eventName = "arch_awesome,_go_back_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

export { archiveFlowTracker };
