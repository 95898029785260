import React from 'react'
import Stack from 'components/common/Stack'
import Text from 'components/common/Text'
import Image from "components/common/Image";

const NotAvailableData = (props) => {

    const {
        actionType,
    } = props

    return (
        <>
            <Stack mt={5} textAlign="center" justiftContent="center">
                <Text variant="body1" sx={{ color: "#2B70C5" }}>Please note:</Text>
                {
                    actionType === "HOLDINGS" ?
                        <>
                            <Text variant="body1" sx={{ color: "#676E76" }}>You don't have any holdings now. Holdings will reflect once you buy a stock.</Text>
                            <Image src="/images/onestock/not-available.svg" height={300} />
                        </>
                        : null
                }
                {
                    actionType === "TRANSACTIONS" ?
                        <>
                            <Text variant="body1" sx={{ color: "#676E76" }}>You don't have any transactions yet.</Text>
                            <Image src="/images/onestock/transaction-not-available.svg" height={300} />
                        </>
                        : null
                }
                {
                    actionType === "ACTIONCENTRE" ?
                        <>
                            <Text variant="body1" sx={{ color: "#676E76" }}>You don't have any stocks to take actions.</Text>
                            <Image src="/images/onestock/not-available.svg" height={300} />
                        </>
                        : null
                }
            </Stack>
        </>
    )
}

export default NotAvailableData