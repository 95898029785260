import React from "react";
import _get from "lodash.get";

import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";

import ProfileDetails from "components/Profile/ProfileDetails";
import MenuItems from "./MenuItems";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

import { appHelper } from "helper/app";
import { config } from "config";
import { customerApis } from "stores/customer/customerApis";
import { masterApis } from "stores/master/masterApis";
import { localStore } from "stores/localStorage";

class AppMoreContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versionName: "",
      deactivateDialogOpen: false,
      deactivateSubmitLoading: false,
      deactivateCheckboxChecked: false,
    };

  }

  async componentDidMount() {
    if (
      !this.props.profile.length ||
      _get(this.props.location, "state.paymentStatus", "") === "SUCCEED"
    ) {
      this.props.getProfileDataAction();
    }

    const v = await appHelper.getVersion();
    this.setState({versionName: v})

  }

  handleLogout = async () => {
    await masterApis.updateLastActiveProduct();
    this.props.logoutAction();
  };

  goToProfile = () => {
    this.props.history.push("/customer/profile");
  };

  handleDeactivateDialogConfirm = async () => {
    this.setState({ deactivateSubmitLoading: true });
    try {
      await masterApis.updateLastActiveProduct();
      await customerApis.deactivateAccount();
      this.props.logoutAction();
    } catch (error) {
      console.error('deactivate account api failed', error);
    } finally {
      this.setState({ deactivateSubmitLoading: false });
    }
  }

  render() {
    const { profile, isOnboardingCompleted, enableAccountDeactivation } = this.props;
    const { deactivateDialogOpen, deactivateCheckboxChecked, deactivateSubmitLoading } = this.state;
  
    return (
      <Stack spacing={1.5}>
        {/* Profile Component */}
        <Box mt={1.5}>
          <ProfileDetails
            hasProfileButton = {config.isMobileApp}
            disabledProfileButton={!isOnboardingCompleted}
            profilePic={profile.profilePic}
            email={profile.email}
            mobileNo={profile.mobileNo}
            fullName={profile.fullName}
            isKYCCompleted={profile.isKYCCompleted}
            onProfileUpdateClick={() => this.toggelEditFormOpen()}
            goToProfile={() => this.goToProfile()}
            deactivateDialogProps={{
              isEnabled: enableAccountDeactivation,
              open: deactivateDialogOpen,
              onOpen: () => this.setState({ deactivateDialogOpen: true }),
              onClose: () => this.setState({ deactivateDialogOpen: false }),
              onConfirm: this.handleDeactivateDialogConfirm,
              isLoading: deactivateSubmitLoading,
              checkboxChecked: deactivateCheckboxChecked,
              onCheckboxChange: () => this.setState({ deactivateCheckboxChecked: !deactivateCheckboxChecked }),
            }}
          />
        </Box>

        {/* Broker Details */}
        {/* {profile.brokerName && (
          <BrokerDetails
            brokerProfilePic={profile.brokerProfilePic}
            brokerName={profile.brokerName}
            brokerClientId={profile.brokerClientId}
            fundsRemaining={profile.fundsRemaining}
          />
        )} */}

        {/* Menu list */}
        <MenuItems />

        {/* Logout */}
        {localStore.isSsoCustomer()? <></>:(<Stack alignItems="center" my="10px">
          <Text
            onClick={this.handleLogout}
            sx={{
              color: "#2B70C5",
              width: "fit-content",
              mb: "30px !important",
              cursor: "pointer",
            }}
          >
            Logout
          </Text>
        </Stack>)}

        <Stack>
          <Text sx={{ color: "#88929B", textAlign: "center" }} variant="small">
            SEBI Registered Investment Advisor
          </Text>
          <Stack sx={{flexDirection: "row", justifyContent: "center", alignItems: "center", my: "10px"}}>
            <img 
              src="/images/sebi_logo_gray.png" 
              alt="Sebi"
              width="32px"
              style={{marginRight: "10px"}}
              />
            <Text variant="caption1" sx={{ color:"#88929B" }}>
              INA 000013235
            </Text>
          </Stack>
          {
            config.isMobileApp ?
              <Text variant="small" sx={{textAlign: "center", color:"#88929B"}}>
                {this.state.versionName ? `V ${this.state.versionName}` : ""}
              </Text>
            : null
          }
        </Stack>
      </Stack>
    );
  }
}

export default withRouter(AppMoreContainer);
