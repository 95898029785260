import { call, put, takeLatest } from "@redux-saga/core/effects";
import { masterApis } from "./masterApis";
import { masterParser } from "./masterParsers";
import { masterConstants } from "./masterConstants";

function* getRMSRiskAssessmentQuestionsSaga() {
    try {
        yield put({ type: masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_REQUEST });
        const res = yield call(masterApis.getRMSRiskAssessmentQuestions);
        const payload = masterParser.rmsRiskAssessmentQuestions(res);
        yield put({
            type: masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_RESPONSE,
            payload: [],
        });
    }
}

function* getRMSRiskProfilesSaga() {
    try {

        yield put({ type: masterConstants.RMS_RISK_PROFILES_REQUEST });
        const res = yield call(masterApis.getRMSRiskProfiles);
        const payload = masterParser.rmsRiskProfiles(res);
        yield put({ type: masterConstants.RMS_RISK_PROFILES_RESPONSE, payload });

    } catch (e) {

        yield put({
            type: masterConstants.RMS_RISK_PROFILES_RESPONSE,
            payload: [],
        });
    }
}

function* getMasterBrokersSaga({ payload }) {
    try {
        const { isFetchHoldingEnabled, isIntegrated, searchTerm, productCode } = payload;
        yield put({ type: masterConstants.MASTER_BROKER_REQUEST });

        const res = yield call(masterApis.getMasterBrokers, { isFetchHoldingEnabled, isIntegrated, searchTerm, productCode });
        const parsedRes = masterParser.getMasterBrokers(res);

        yield put({ type: masterConstants.MASTER_BROKER_RESPONSE, payload: parsedRes });
    } catch (e) {
        yield put({ type: masterConstants.MASTER_BROKER_RESPONSE, payload: [] });
    }
}

// function* getOnestockMasterBrokersSaga({ payload }) {
//     try {
//         const { isFetchHoldingEnabled, isIntegrated, searchTerm, productCode } = payload;
//         yield put({ type: masterConstants.ONESTOCK_MASTER_BROKER_REQUEST });

//         const res = yield call(masterApis.getOnestockMasterBrokers, { isFetchHoldingEnabled, isIntegrated, searchTerm, productCode });
//         const parsedRes = masterParser.getOnestockMasterBrokers(res);

//         yield put({ type: masterConstants.ONESTOCK_MASTER_BROKER_RESPONSE, payload: parsedRes });
//     } catch (e) {
//         yield put({ type: masterConstants.ONESTOCK_MASTER_BROKER_RESPONSE, payload: [] });
//     }
// }

function* getPendingCallSaga() {
    try {

        yield put({ type: masterConstants.PENDING_CALL_REQUEST });
        const res = yield call(masterApis.getPendingCall);
        const payload = masterParser.pendingCall(res);
        yield put({ type: masterConstants.PENDING_CALL_RESPONSE, payload });

    } catch (e) {

        yield put({
            type: masterConstants.PENDING_CALL_RESPONSE,
            payload: {},
        });
    }
}

export function* registerMasterSagas() {
    yield takeLatest(
        masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_SAGA,
        getRMSRiskAssessmentQuestionsSaga
    );
    yield takeLatest(
        masterConstants.RMS_RISK_PROFILES_SAGA,
        getRMSRiskProfilesSaga
    );
    yield takeLatest(
        masterConstants.MASTER_BROKER_SAGA,
        getMasterBrokersSaga,
    );
    // yield takeLatest(
    //     masterConstants.ONESTOCK_MASTER_BROKER_SAGA,
    //     getOnestockMasterBrokersSaga,
    // );
    yield takeLatest(
        masterConstants.PENDING_CALL_SAGA,
        getPendingCallSaga,
    );
}
