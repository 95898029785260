import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const registrationTracker = {};

// Analytics_tracker_active
registrationTracker.socialVerificationOtp = ({ platform, name, deviceId }) => {
    const eventName = "social_request_otp";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { user_id: deviceId, platform, device_id: deviceId, name },
    // });

    // mixpanelTracker.identify({ userId: deviceId });
    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { platform, device_id: deviceId, name },
    // });

    uxcamTracker.startSession();
    uxcamTracker.setUserIdentity({ userId: deviceId });
    uxcamTracker.setUserProperty({ userName: name });
    uxcamTracker.trackEvents({
        eventName,
        eventData: { platform, device_id: deviceId, name },
    });
};


registrationTracker.regRequestOtp = ({ platform, name, deviceId }) => {
    const eventName = "reg_request_otp";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { user_id: deviceId, platform, device_id: deviceId, name },
    // });

    // mixpanelTracker.identify({ userId: deviceId });
    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { platform, device_id: deviceId, name },
    // });

    uxcamTracker.startSession();
    uxcamTracker.setUserIdentity({ userId: deviceId });
    uxcamTracker.setUserProperty({ userName: name });
    uxcamTracker.trackEvents({
        eventName,
        eventData: { platform, device_id: deviceId, name },
    });
};

registrationTracker.regRequestOtpSuccess = ({ app_reg_id, platform, deviceId, name }) => {
    const eventName = "reg_request_otp_success";

    appsFlyerTracker.setUserId(app_reg_id);
    appsFlyerTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id, platform, device_id: deviceId, name },
    });

    mixpanelTracker.identify(app_reg_id);
    mixpanelTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id, platform, device_id: deviceId, name },
    });

    uxcamTracker.setUserIdentity({ userId: app_reg_id });
    uxcamTracker.setUserProperty({ user_id: app_reg_id, name });
    uxcamTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id, platform, device_id: deviceId, name },
    });
};

registrationTracker.regRequestOtpError = ({ app_reg_id }) => {
    // const eventName = "reg_request_otp_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });
    
};

registrationTracker.regEnterMobileOtp = ({ app_reg_id }) => {
    // const eventName = "reg_enter_mobile_otp";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });
};

registrationTracker.regEnterMobileOtpSuccess = ({ app_reg_id }) => {
    const eventName = "reg_enter_mobile_otp_success";

    appsFlyerTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id },
    });

    mixpanelTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id },
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id },
    });
};

registrationTracker.regEnterMobileOtpFailed = ({ app_reg_id, error_msg }) => {
    // const eventName = "reg_enter_mobile_otp_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id, error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id, error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id, error_msg },
    // });
};

registrationTracker.regEnterEmailOtp = ({ app_reg_id }) => {
    // const eventName = "reg_enter_email_otp";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });
    
};

registrationTracker.regEnterEmailOtpError = ({ app_reg_id, error_msg }) => {
    // const eventName = "reg_enter_email_otp_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id, error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id, error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id, error_msg },
    // });
};

registrationTracker.regEnterEmailOtpSuccess = ({ app_reg_id }) => {
    const eventName = "reg_enter_email_otp_success";

    appsFlyerTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id },
    });

    mixpanelTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id },
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { user_id: app_reg_id },
    });
};

// signup

registrationTracker.signUp = ({ app_reg_id }) => {
    // const eventName = "sign_up";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });
};

registrationTracker.signUpError = ({ app_reg_id }) => {
    // const eventName = "sign_up_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { app_reg_id },
    // });
};

// Analytics_tracker_active
registrationTracker.signUpSuccess = ({
    name,
    loginType,
    deviceId,
    deviceType,
    customerId,
}) => {
    const eventName = "sign_up_success";

    appsFlyerTracker.trackEvents({
        eventName,
        eventData: { name, login_type: loginType, device_id: deviceId, device_type: deviceType, user_id: customerId },
    });

    mixpanelTracker.trackEvents({
        eventName,
        eventData: { name, login_type: loginType, device_id: deviceId, device_type: deviceType, user_id: customerId },
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { name, login_type: loginType, device_id: deviceId, device_type: deviceType, user_id: customerId },
    });
};

export { registrationTracker };
