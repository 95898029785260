import React from 'react'
import Stack from 'components/common/Stack'
import Text from 'components/common/Text'
import { makeStyles } from "@mui/styles"
import { formatHelper } from "helper/format";

const HoldingTableStockField = (props) => {
    const { stockCompany, brokerName, companyName, companyType, riskType, rowData } = props
    const classes = useStyles();

    return (
        <>
            {
                rowData ?
                    <Stack mt={1.5} py={.8} px={2} direction={{ xs: "column", md: "row" }} justifyContent="space-between" className={classes.secondaryRowContainer}>
                        <Stack direction="row">
                            <Stack direction="row" mr={3}>
                                <Text variant="caption1">
                                    Overall AI Score : &nbsp;
                                </Text>
                                <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                    {(Math.abs(rowData?.overallAiScore) || 0) + "%"}
                                </Text>
                            </Stack>
                            <Stack direction="row" mr={3}>
                                <Text variant="caption1">
                                    Fundamental Score : &nbsp;
                                </Text>
                                <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                    {(Math.abs(rowData?.fundamentalScore) || 0) + "%"}
                                </Text>
                            </Stack>
                            <Stack direction="row" mr={3}>
                                <Text variant="caption1">
                                    Technical Score : &nbsp;
                                </Text>
                                <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                    {(Math.abs(rowData?.technicalScore) || 0) + "%"}
                                </Text>
                            </Stack>
                            <Stack direction="row" mr={3}>
                                <Text variant="caption1">
                                    Sentiment Score : &nbsp;
                                </Text>
                                <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                    {(Math.abs(rowData?.sentimentScore) || 0) + "%"}
                                </Text>
                            </Stack>
                        </Stack>
                        <Stack direction="row">
                            <Stack direction="row" mr={2}>
                                <Text variant="caption1">
                                    52W H : &nbsp;
                                </Text>
                                <Text variant="caption1">
                                    {formatHelper.currency(
                                        Math.abs(rowData?.Wh)
                                    ) || 0}
                                    <span style={{ color: "#45AC70" }}>
                                        ({(Math.abs(rowData?.WhPercent) || 0) + "%"})
                                    </span>
                                </Text>
                            </Stack>
                            <Stack direction="row">
                                <Text variant="caption1">
                                    52W L : &nbsp;
                                </Text>
                                <Text variant="caption1">
                                    {formatHelper.currency(
                                        Math.abs(rowData?.Wl)
                                    ) || 0}
                                    <span style={{ color: "#45AC70" }}>
                                        ({(Math.abs(rowData?.WlPercent) || 0) + "%"})
                                    </span>
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                    :
                    <Stack sx={{ width: "75%" }}>
                        <Text variant="caption1">{brokerName}</Text>
                        <Text variant="small" sx={{ fontWeight: 500 }}>{stockCompany}</Text>
                        <Stack direction="row">
                            <Text variant="caption1">{companyName}</Text>
                            {companyType ?

                                <Text ml={2} variant="caption2"
                                    sx={{
                                        border: "1px solid #DEDEDE",
                                        borderRadius: "12px",
                                        padding: "0px 5px",
                                    }}
                                >
                                    {companyType}
                                </Text>
                                :
                                null
                            }
                        </Stack>
                    </Stack>
            }
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    secondaryRowContainer: {
        border: "1px solid #EBEBEB",
        borderRadius: "30px",
        background: "#EBEBEB",
    }
}));

export default HoldingTableStockField