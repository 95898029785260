import React from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import JarvisCard from "components/JarvisCard";

const DashboardChartCard = (props) => {
    const { title, renderChart, sx, isLoading = 0 } = props;
    return (
        <JarvisCard sx={{ px: 0, py: 0, ...sx }}>
            <Stack>
                <Stack
                    sx={{ borderBottom: "1px solid #F5F5F5" }}
                    alignItems="start"
                    justifyContent="center"
                    px={3}
                    py={2}
                >
                    <Text sx={{fontWeight:"bold"}}>{title}</Text>
                </Stack>
                {isLoading ? (
                    <Stack justifyContent="center" alignItems="center">
                        <Text>Loading ...</Text>
                    </Stack>
                ) : (
                    <Box sx={{ width: "100%", p: 2 }}>
                        {renderChart ? renderChart : props.children}
                    </Box>
                )}
            </Stack>
        </JarvisCard>
    );
};

export default DashboardChartCard;
