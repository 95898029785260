import { App } from '@capacitor/app';


const appHelper = {}

appHelper.getVersion = async () => {

    try {
        const { version } = await App.getInfo();
        
        return version;
        
    } catch (error) {
        return false;
    }
}

appHelper.isMobileView = () => window?.innerWidth <= 768;

export { appHelper };