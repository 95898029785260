import React, { Component } from 'react';
import { Chip } from '@mui/material';
import { alpha } from "@mui/material/styles";
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisButton from 'components/JarvisButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { withRouter } from "react-router";
import Box from 'components/common/Box';
import Image from "components/common/Image";
import { withStyles } from "@mui/styles";

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TooltipHover from "components/common/TooltipHover"
import { localStore } from 'stores/localStorage';
import { config } from 'config';

const onboardingStages = [
    {
        stageId: [0],
        stageNumber: 1,
        uri: "/jarvis-portfolio/onboarding/risk-assessment",
    },
    {
        stageId: [1],
        stageNumber: 2,
        uri: "/jarvis-portfolio/onboarding/investment-preference",
    },
    {
        stageId: [2],
        stageNumber: 3,
        uri: "/jarvis-portfolio/onboarding/portfolio-creation",
    },
    {
        stageId: [3, 4],
        stageNumber: 4,

        uri: "/jarvis-portfolio/onboarding/ckyc",
    },
    {
        stageId: [5],
        stageNumber: 5,
        uri: "/jarvis-portfolio/onboarding/choose-broker",
    },
];

class OverviewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openId: null,
        }
    }

    getActiveStage = () => {
        const { currentStage } = this.props;
        console.log(currentStage);
        return onboardingStages.find((o) =>
            o.stageId.includes(currentStage)
        )
    };


    handleStageRedirection = ({ actionType = "DEFAULT" } = {}) => {
        const activeStage = this.getActiveStage();
        console.log(activeStage);

        if (actionType !== "DEFAULT" && activeStage && activeStage.stageNumber > 0) {
            this.props.history.replace(activeStage.uri);
            return;
        }

        if (activeStage && activeStage.stageNumber > 3) {
            this.props.history.replace("/jarvis-portfolio/onboarding/portfolio-creation");
            return;
        }

        if (activeStage && activeStage.stageNumber > 0) {
            this.props.history.replace(activeStage.uri);
            return;
        }

        this.props.history.replace("/jarvis-portfolio/onboarding");
        return;
    }



    handleTooltip = (id, type = 'open') => {
        if (type === 'open') {
            this.setState({ openId: id });
        } else {
            this.setState({ openId: null });
        }
    }

    render() {
        const { classes } = this.props;
        const activeStage = this.getActiveStage();
        console.log(activeStage);
        // let onboardingStages =[]
        let isCentrumSsoCustomer = false;
        let ssoCustomerDetails = localStore.getSsoCustomerDetails()
        if(localStore.isSsoCustomer() && ssoCustomerDetails?.broker === config?.broker?.ssoPlatform?.centrum ){
            isCentrumSsoCustomer = true;
            // onboardingStages = this.props.portfolioOverviewList.filter(v=>v.name !== "CKYC");
        }
        return (
            <>
                <Text sx={{ fontWeight: '500', fontSize: { xs: "subtitle2.fontSize", md: "subtitle1.fontSize" }, textTransform: 'capitalize', mt: 2, mb: { xs: 0.5, md: 2 } }}>
                    Hi {this.props.name},
                </Text>
                <Text sx={{ fontSize: { xs: '0.875rem', md: '1.125rem' }, color: "#676E76" }} mb={{ xs: 3, md: 4 }}>
                    Jarvis wants to get to know you well before generating an equity portfolio for you. Just like how doctors diagnose before suggesting appropriate medicines. Hence, please select the appropriate answer.
                </Text>
                <Text variant="body1" sx={{ fontSize: { xs: 'subtitle2.fontSize', md: '2rem' }, fontWeight: 500, lineHeight: { xs: '23px', md: '41px' } }} color={'#212225'} mb={4.5}>
                    You are just<span style={{ color: '#00DB99' }}> 6 simple steps</span> away from hiring Jarvis to help you <br></br> create wealth in the long run.
                </Text>
                <ClickAwayListener onClickAway={() => this.handleTooltip('', 'close')}>
                    <Stack direction={{ xs: 'column', md: 'row' }} className={classes.toolTipss}>
                        {this.props.overviewList.map((item, index) =>
                            <Stack key={item.id} direction={'row'} alignItems={'center'} mb={2} >
                                <Chip label={item.id} size='small' sx={{ color: item.stageNumber < activeStage?.stageNumber ? 'white' : 'primary.dark', backgroundColor: item.stageNumber < activeStage?.stageNumber ? alpha('#2e7d32', 0.8) : alpha('#38a1ff', 0.5), fontWeight: '500', fontSize: { xs: 'caption2.fontSize', md: 'caption1.fontSize' }, maxHeight: '24px', minWidth: '24px' }} />
                                <Text ml={0.5} sx={{ fontSize: { xs: 'small.fontSize', md: 'body1.fontSize' }, color: '#212225', fontWeight: '400', }}>
                                    {isCentrumSsoCustomer && item.name?.toLowerCase() === "ckyc"?"E-Sign":item.name}
                                </Text>

                                <TooltipHover
                                    title={!isCentrumSsoCustomer?item.toolTipText : item.toolTipText.replaceAll('CKYC','E-Sign')}
                                    sx={{ lineHeight: 0, ml: 0.5, mr: index === this.props.overviewList.length - 1 ? 0 : 1.5 }}
                                >
                                    <InfoRoundedIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                                </TooltipHover>
                            </Stack>
                        )}
                    </Stack>
                </ClickAwayListener>
                <Text style={{ display: 'flex', alignItems: 'center' }} sx={{ fontSize: { xs: '0.75rem', md: '1rem' } }} my={{ xs: 2, md: 1.2 }}>
                    <Image
                        src="/svgs/aadhaar.svg"
                        width={60}
                    />
                    <span style={{ marginLeft: "15px" }}>Keep your Aadhaar{isCentrumSsoCustomer?" ":" & PAN card "}handy, as you will need it for {isCentrumSsoCustomer?"E-Sign":"C-KYC"}.</span>
                </Text>
                <Box>
                    <JarvisButton sx={{ maxWidth: { xs: '1', md: 'auto' }, width: { xs: '1', md: 'auto' } }} disableEndIcon={true} onClick={() => this.handleStageRedirection()}>
                        {activeStage?.stageNumber > 1 ? "Complete onboarding" : "Start now"}
                    </JarvisButton>
                </Box>
            </>

        );
    }
}

const style = (theme) => ({
    toolTipss: {
        '& .MuiTooltip-tooltip': {
            backgroundColor: '#fff !important',
            color: '#000 !important',
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            padding: '14px',
        },
    }


})

export default withRouter(withStyles(style, { withTheme: true })(OverviewContainer));