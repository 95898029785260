import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisOnestockTransactionConstants } from "./jarvisOnestockTransactionConstants";


const initialState = {
    transactionList: {
        total: 0,
        list: [],
    },
    loading: {
        transactionList: 0,
    },
    initialCalled: {
        transactionList: 0,
    }
};

export const jarvisOnestockTransactionReducer = persistReducer(
    {
        storage,
        key: "onestockTransaction",
        whitelist: [],
    },

    (state = initialState, { type, payload }) => {
        switch (type) {
            case jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_REQUEST: {
                return {
                    ...state,
                    transactionList: initialState.transactionList,
                    loading: {
                        ...state.loading,
                        transactionList: 1,
                    },
                };
            }

            case jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_RESPONSE: {
                return {
                    ...state,
                    transactionList: {
                        list: payload.list,
                        total: payload.total,
                    },
                    loading: {
                        ...state.loading,
                        transactionList: 0,
                    },
                    initialCalled: {
                        ...state.initialCalled,
                        transactionList: 1,
                    }
                };
            }

            default:
                return state;
        }
    }
);
