import React, { useState } from 'react'
import Stack from 'components/common/Stack'
import Box from 'components/common/Box'
import Text from 'components/common/Text'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { makeStyles } from "@mui/styles"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from 'components/common/IconButton'
import JarvisButton from 'components/JarvisButton'
import Button from 'components/common/Button'
import JarvisCard from 'components/JarvisCard'
import Image from "components/common/Image"
import { formatHelper } from 'helper/format'
import InputAdornment from 'components/common/InputAdornment'
import TextField from 'components/common/TextField'
import JarvisChip from "components/JarvisChipNew"
import Grid from 'components/common/Grid'
import Modal from 'components/common/Modal'
import Loader from "components/common/Loader";
import { config } from "config";
import ChooseBrokerIcon from "components/Onboarding/ChooseBrokerIcon";
import { dateTimeHelper } from 'helper/dateTime'
import { jarvisOnestockDashboardApis } from 'stores/jarvisOnestock/jarvisOnestockDashboard/jarvisOnestockDashboardApis'
import { validationHelper } from 'helper/validation'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MobileResponsive from "components/common/MobileResponsive";
import JarvisNote from 'components/JarvisNote'
import InfoIcon from '@mui/icons-material/Info';
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";

const OnestockDashboardCard = (props) => {
    const {
        handleInputChange,
        formData,
        brokerList = [],
        isBrokerListLoading = 0,
        isBrokerListInitialCalled = 0,
        revealData,
        isRevealActivated,
        resetForm,
        handleOnBlur,
        pendingActionCall
    } = props

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [knowMore, setKnowMore] = useState(false);
    const [buyStocks, setBuyStocks] = useState(false);
    const [updateStock, setUpdateStock] = useState(false);
    const [showAwesome, setShowAwesome] = useState(false);
    const [overallPerformance, setOverallPerformace] = useState(false);
    const [hideOverallPerformance, setHideOverallPerformace] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [revealTime, setRevealTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
    const [brokerQuantityModal, setBrokerQuantityModal] = useState(false);
    const [selectedBroker, setSelectedBroker]= useState({})
    const [marketStatusNote, setMarketStatusNote] = useState(false)
    const [marketStatusMessage, setMarketStatusMessage] = useState("");
    // const [brokerEnvironment, setBrokerEnvironment] = useState(false);

    const history = useHistory();

    // Form Error
    const [errorBuyQuantity, setErrorBuyQuantity] = useState("");
    const [errorBuyStocks, setErrorBuyStocks] = useState("");
    

    React.useEffect(() => {
        let revealTimer

        if (revealData?.revealTime && !revealTimer) {
            const countDownTime = new Date(`${revealData.revealTime}`.toUpperCase().replace("Z", "")).getTime();
            revealTimer = setInterval(() => {
                const currentTime = new Date().getTime();
                const diff = countDownTime - currentTime;

                const days = Math.floor((diff % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
                const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);

                if (diff < 0) {
                    clearInterval(revealTimer);
                }

                setRevealTime({ days, hours, minutes, seconds });
            }, 1000);
        }

        return () => {
            clearInterval(revealTimer);
        }
    })

    const handleValidation = () => {
        const errorForQuantity = validationHelper.positiveNumber({value:formData.buyQuantity});
        setErrorBuyQuantity(errorForQuantity.message);
        const errorForBuyStocks = validationHelper.positiveNumber({value:formData.buyStockPrice});
        setErrorBuyStocks(errorForBuyStocks.message);
        return (
            errorForBuyStocks.isValid && errorForQuantity.isValid
        );
    };

    const handleModalOpen = (name) => {
        if (name === "knowMore") {
            setKnowMore(true)
        }
        if (name === "updateStocks") {
            setUpdateStock(true)
        }
    }

    const handleModalClose = (name) => {
        if (name === "knowMore") {
            setKnowMore(false)
        }
        if (name === "updateStocks") {
            setUpdateStock(false)
            resetForm();
        }
        if (name === "buyStocks") {
            setBuyStocks(false)
            resetForm();
        }
        if(name === 'stockQuantityModal'){
            setBrokerQuantityModal(false)
          }
    }


    const handleBuyStocks = () => {
        setBuyStocks(true)
        setKnowMore(false)
    }

    const updateBuyStocks = () => {
        setBuyStocks(false)
        setUpdateStock(true)
    }

    const handleUpdateStocksModal = async () => {
        if (!handleValidation()) {
            return;
        }
        setIsLoading(true)
        try {
            await jarvisOnestockDashboardApis.onestockStocksCallApi({
                stockBuyPrice: formData.buyStockPrice,
                stockBuyQuantity: formData.buyQuantity,
                oneStockCallId: revealData?.oneStockCallId,
            });
            pendingActionCall()
            setUpdateStock(true)
            setShowAwesome(true)
            setUpdateStock(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const closeAwesomeModal = () => {
        setShowAwesome(false)
        history.push("/jarvis-onestock/existing-holding");

    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const filterBrokerList = () => {
        return brokerList?.filter(b => b.brokerDisplayName.toLowerCase().includes(searchTerm.toLowerCase()) || b.brokerName.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    const filteredBrokerList = filterBrokerList();

    const handleMarketStatusModalClose = () => {
      setMarketStatusNote(false);
      setMarketStatusMessage("");
    };

    const handleBrokerClick = async ({broker,modalFlag= false}) => {
        if(broker.brokerPlatform!==config.broker.platform.smallcase  && modalFlag){
          setBuyStocks(false)
          setSelectedBroker(broker)
           return setBrokerQuantityModal(modalFlag)
        }
        if (config.environment !== "DEVELOPMENT") {
          try {
              if (!marketStatusNote) {
                const a = await thirdpartyApis.marketOpenHRStatus();
                if (a.status === false) {
                  setMarketStatusNote(true);
                  setMarketStatusMessage(a.msg)
                  return;
                }
              }
    
          } catch (error) {
              console.log(error);
          }
      }
        // setBrokerEnvironment(true)
        // setTimeout(()=>{
          history.push({
            pathname: "/customer/broker",
            state: {
                returnURI: "/jarvis-onestock/existing-holding",
                goBackURI: "/jarvis-onestock/dashboard",
                brokerName: broker.brokerName,
                brokerPlatform: broker.brokerPlatform,
                productCode: config.productCodes.ONESTOCK,
                brokerId: broker.brokerId,
                onestockCallId: revealData?.oneStockCallId,
                executionType: config.broker.executionType.TRANSACTION,
                stockQuantity: formData.buyQuantity,
            }
        });
        // },2000)
    };

    const showOverallPerformance = () => {
        setOverallPerformace(true)
        setHideOverallPerformace(false)
    }
    const hideOverallPerformances = () => {
        setHideOverallPerformace(true)
        setOverallPerformace(false)
    }
    const centrumBroker = filteredBrokerList.filter((value)=>{
        return value.brokerName === 'centrum'
    });
    const isCentrumTextVisible = !searchTerm || "centrum".includes(searchTerm)
    return (
      <>
        <MobileResponsive
          web={() => (
            <Stack>
              {revealData?.revealStatus === "ACTIVATED" ? (
                isRevealActivated === 1 ? (
                  <>
                    <Box p={1.5} mb={3} className={classes.infoText2}>
                      <Text variant="small">
                        <Grid container>
                          <Grid item md={1} sx={{ fontWeight: 500 }}>
                            {revealData?.revealNote.slice(0, 12)}
                          </Grid>
                          <Grid item md={11}>
                            {revealData?.revealNote.slice(13)}
                          </Grid>
                        </Grid>
                      </Text>
                    </Box>
                    <Stack mb={3} className={classes.cardWrapper}>
                      <Text className={classes.cardTimer}>Revealing...</Text>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Box p={1.5} mb={3} className={classes.infoText2}>
                      <Text variant="small">
                        <Grid container>
                          <Grid item md={1} sx={{ fontWeight: 500 }}>
                            {revealData?.revealNote.slice(0, 12)}
                          </Grid>
                          <Grid item md={11}>
                            {revealData?.revealNote.slice(13)}
                          </Grid>
                        </Grid>
                      </Text>
                    </Box>
                    <Stack mb={3} className={classes.stockCardWrapper}>
                      <JarvisCard>
                        <Stack
                          direction={{ xs: "column", md: "row" }}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Text variant="subtitle2">
                            {revealData?.stockName}
                          </Text>
                          <Text
                            sx={{
                              marginRight: "25px",
                              color: "#676E76",
                              fontSize: "13px",
                            }}
                          >
                            {revealData?.isin}
                          </Text>
                          {/* <Stack
                                                            direction="row"
                                                            ml={1.5} px={1.5}
                                                            className={classes.knowWhyText}
                                                            onClick={() => handleModalOpen("knowMore")}
                                                        >
                                                            <Text mr={0.5} variant="caption1" sx={{ paddingTop: "6px" }}>
                                                                Know why
                                                            </Text>
                                                            <Image src="/images/onestock/know-why-icon.svg" width={12} />
                                                        </Stack> */}
                        </Stack>
                        <Stack
                          direction={{ xs: "column", md: "row" }}
                          justifyContent="space-between"
                        >
                          <Text variant="body2">{revealData?.stockSymbol}</Text>
                          <Stack direction="row">
                            <Stack mr={3} direction="row">
                              <Text mr={0.8} variant="body1">
                                LTP
                              </Text>
                              <Text variant="body1" sx={{ fontWeight: 600 }}>
                                {revealData?.lTP}
                              </Text>
                            </Stack>
                            {/* hide for now since data is not available */}
                            {/* <Stack direction="row">
                                                                <Text mr={0.8} variant="body1">Target</Text>
                                                                <Text
                                                                    variant="body1"
                                                                    sx={{ color: "#45AC70", fontWeight: 500 }}
                                                                >
                                                                    {
                                                                        `${revealData?.targetPrice} (${revealData?.targetPricePercentage}%)`
                                                                    }
                                                                </Text>
                                                            </Stack> */}
                          </Stack>
                        </Stack>
                        <Stack>
                          <Text variant="small">
                            {revealData?.stockExchange},{" "}
                            {dateTimeHelper.displayDate(revealData?.revealTime)}
                          </Text>
                        </Stack>
                        <Stack
                          pt={3}
                          direction={{ xs: "column", md: "row" }}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            justifyContent="space-between"
                          >
                            <JarvisChip
                              className={classes.chips}
                              sx={{ color: "#fff" }}
                              size="small"
                              label={revealData?.marketCap}
                              color="marketCap"
                            />
                            {/* hide for now since data not comoing from api */}
                            {/* <JarvisChip
                                                className={classes.chips}
                                                size="small"
                                                label={revealData?.riskScore}
                                                color="error"
                                            /> */}

{/*                             <JarvisChip
                              className={classes.chips}
                              size="small"
                              label={
                                <>
                                  1Y CAGR{" "}
                                  <span
                                    style={{
                                      color:
                                        revealData.oneYearCAGRPercentage > 0
                                          ? "#45AC70"
                                          : "#D44C4C",
                                    }}
                                  >
                                    {revealData?.oneYearCAGRPercentage}%
                                  </span>
                                </>
                              }
                              // color={revealData.oneYearCAGRPercentage > 0 ? 'success' : 'error'}
                            /> */}
                          </Stack>
                          <Stack alignItems="center">
                            <Button
                              size="small"
                              variant="contained"
                              sx={{ width: "200px" }}
                              onClick={handleBuyStocks}
                            >
                              Buy Now
                            </Button>
                            <Stack mt={1.5} direction="row">
                              <Text variant="small" sx={{ color: "#676E76" }}>
                                Already purchased? &nbsp;
                              </Text>
                              <Text
                                variant="small"
                                sx={{ color: "#2B70C5", cursor: "pointer" }}
                                onClick={() => handleModalOpen("updateStocks")}
                              >
                                Update Now
                              </Text>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack
                          mt={1.5}
                          direction={{ xs: "column", md: "row" }}
                          justifyContent="space-between"
                        >
                          <Stack direction="row">
                            {/* <Stack direction="row" mr={2}>
                                                <Text variant="caption1">
                                                    Overall AI Score : &nbsp;
                                                </Text>
                                                <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                                    {(Math.abs(revealData?.overallAIScorePercentage) || 0) + "%"}
                                                </Text>
                                            </Stack> */}
                            {/* <Stack direction="row" mr={2}>
                              <Text variant="caption1">
                                Fundamental Score : &nbsp;
                              </Text>
                              {revealData?.fundamentalScorePercentage > 0 ? (
                                <Text
                                  variant="caption1"
                                  sx={{ color: "#45AC70" }}
                                >
                                  {revealData?.fundamentalScorePercentage + "%"}
                                </Text>
                              ) : (
                                <Text
                                  variant="caption1"
                                  sx={{ color: "#D44C4C" }}
                                >
                                  {revealData?.fundamentalScorePercentage + "%"}
                                </Text>
                              )}
                            </Stack> */}
                            {/* <Stack direction="row" mr={2}>
                                                                <Text variant="caption1">
                                                                    Technical Score : &nbsp;
                                                                </Text>
                                                                <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                                                    {(Math.abs(revealData?.technicalScorePercentage) || 0) + "%"}
                                                                </Text>
                                                            </Stack> */}
                            {/* hide for now since data not comoing from api */}
                            {/* <Stack direction="row" mr={2}>
                                                <Text variant="caption1">
                                                    Sentiment Score : &nbsp;
                                                </Text>
                                                <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                                    {(Math.abs(revealData?.sentimentScorePercentage) || 0) + "%"}
                                                </Text>
                                            </Stack> */}
                          </Stack>
                          <Stack direction="row">
                            <Stack direction="row" mr={2}>
                              <Text variant="caption1">52W H : &nbsp;</Text>
                              <Text variant="caption1">
                                {formatHelper.currency(
                                  Math.abs(revealData?.weekHigh)
                                ) || 0}{" "}
                                <span style={{ color: "#45AC70" }}>
                                  ({(revealData?.weekHighPercentage || 0) + "%"}
                                  )
                                </span>
                              </Text>
                            </Stack>
                            <Stack direction="row">
                              <Text variant="caption1">52W L : &nbsp;</Text>
                              <Text variant="caption1">
                                {formatHelper.currency(
                                  Math.abs(revealData?.weekLow)
                                ) || 0}{" "}
                                <span style={{ color: "#45AC70" }}>
                                  ({(revealData?.weekLowPercentage || 0) + "%"})
                                </span>
                              </Text>
                            </Stack>
                          </Stack>
                        </Stack>
                      </JarvisCard>
                    </Stack>
                  </>
                )
              ) : null}
              {revealData?.revealStatus === "PENDING" ? (
                <>
                  <Box p={1.5} mb={3} className={classes.infoText}>
                    <Text variant="small">
                      <span style={{ color: "#2B70C5", fontWeight: 500 }}>
                        {revealData?.revealNote.slice(0, 13)}
                      </span>
                      {revealData?.revealNote.slice(13)}
                    </Text>
                  </Box>
                  <Stack mb={3} className={classes.cardWrapper}>
                    <Text
                      sx={{ fontSize: "30px" }}
                      className={classes.cardTimer}
                    >
                      Unlock in{" "}
                      <span style={{ fontWeight: "700" }} id="revealSpan">
                        {revealTime.days > 0 &&
                          `${formatHelper.zeroPrefix(revealTime.days)} Days `}
                        {revealTime.hours > 0 &&
                          `${formatHelper.zeroPrefix(revealTime.hours)} Hours `}
                        {revealTime.minutes > 0 &&
                          `${formatHelper.zeroPrefix(
                            revealTime.minutes
                          )} Mins. `}
                        {revealTime.seconds > 0 &&
                          `${formatHelper.zeroPrefix(
                            revealTime.seconds
                          )} Secs. `}
                      </span>
                    </Text>
                  </Stack>
                </>
              ) : null}
              {revealData?.revealStatus === "HALTED" ? (
                <>
                  <JarvisNote
                    color="default"
                    description={`Next unlock will happen in ${
                      dateTimeHelper.diff(new Date(), revealData?.revealTime)
                        .days
                    } day(s)`}
                  />
                </>
              ) : null}
              {revealData?.revealStatus === "No Stocks" ? (
                <>
                  <Stack
                    mb={3}
                    sx={{ maxHeight: "250px", borderRadius: "10px" }}
                  >
                    <JarvisCard sx={{ background: "#004fc6" }}>
                      <Stack direction={{ xs: "column", md: "row" }}>
                        <Text variant="subtitle2" sx={{ color: "#fff" }}>
                          {revealData?.revealNote}
                        </Text>
                      </Stack>
                    </JarvisCard>
                  </Stack>
                </>
              ) : null}
            </Stack>
          )}
          mobile={() => (
            <Stack>
              {revealData?.revealStatus === "ACTIVATED" ? (
                isRevealActivated === 1 ? (
                  <>
                    <Box p={1.5} mb={3} className={classes.infoText2}>
                      <Text variant="small">
                        <Grid container>
                          <Grid item md={1} sx={{ fontWeight: 500 }}>
                            {revealData?.revealNote.slice(0, 12)}
                          </Grid>
                          <Grid item md={11}>
                            {revealData?.revealNote.slice(13)}
                          </Grid>
                        </Grid>
                      </Text>
                    </Box>
                    <Stack mb={3} className={classes.mobileCardWrapper}>
                      <Text className={classes.cardTimer}>Revealing...</Text>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Box p={1.5} mb={3} className={classes.infoText2}>
                      <Text variant="small">
                        <Grid container>
                          <Grid item md={1} sx={{ fontWeight: 500 }}>
                            {revealData?.revealNote.slice(0, 12)}
                          </Grid>
                          <Grid item md={11}>
                            {revealData?.revealNote.slice(13)}
                          </Grid>
                        </Grid>
                      </Text>
                    </Box>
                    <Stack mb={3} className={classes.mobileStockCardWrapper}>
                      <JarvisCard>
                        <Stack direction="row" justifyContent="space-between">
                          <Text variant="caption1" sx={{ color: "#676E76" }}>
                            Stock of the week
                          </Text>
                          {/* <Stack
                                                            direction="row"
                                                            className={classes.mobileKnowWhyText}
                                                            onClick={() => handleModalOpen("knowMore")}
                                                        >
                                                            <Image src="/images/onestock/know-why-icon.svg" width={12} />
                                                            <Text ml={0.5} variant="caption1">
                                                                Know why
                                                            </Text>
                                                        </Stack> */}
                        </Stack>
                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "7px 0px 3px 0px",
                          }}
                          direction="row"
                        >
                          <Text
                            sx={{
                              fontSize: "10px",
                              fontWeight: "500",
                            }}
                          >
                            {revealData?.stockSymbol}
                          </Text>
                          <Text
                            style={{
                              color: "#676E76",
                              fontSize: "13px"
                            }}
                          >
                            {revealData?.isin}
                          </Text>
                        </Stack>
                        <Text variant="subtitle2">{revealData?.stockName}</Text>
                        <Stack mt={0.5} direction="row">
                          <Text
                            variant="caption1"
                            sx={{
                              padding: "1px 6px",
                              border: "1px solid #DEDEDE",
                              borderRadius: "30px",
                            }}
                          >
                            {revealData?.marketCap}
                          </Text>
                        </Stack>
                        <Text
                          my={2}
                          sx={{ borderBottom: "1px solid #DEDEDE" }}
                        ></Text>
                        <Stack direction="row" justifyContent="space-between">
                          <Stack>
                            <Text variant="caption1">Current Price</Text>
                            <Text variant="caption1" sx={{ fontWeight: 500 }}>
                              {formatHelper.currency(
                                Math.abs(revealData?.lTP)
                              ) || 0}
                            </Text>
                          </Stack>
                          {/* <Stack>
                                                            <Text variant="caption1">Target in 1 month</Text>
                                                            <Text
                                                                variant="caption1"
                                                                sx={{ color: "#45AC70", fontWeight: 500 }}
                                                            >
                                                                {
                                                                    `${revealData?.targetPrice} (${revealData?.targetPricePercentage}%)`
                                                                }
                                                            </Text>
                                                        </Stack> */}
                          {/* <Stack>
                            <Text variant="caption1">1Y CAGR</Text>
                            {revealData?.oneYearCAGRPercentage > 0 ? (
                              <Text
                                variant="caption1"
                                sx={{ fontWeight: 500, color: "#45AC70" }}
                              >
                                {revealData?.oneYearCAGRPercentage}%
                              </Text>
                            ) : (
                              <Text
                                variant="caption1"
                                sx={{ fontWeight: 500, color: "#D44C4C" }}
                              >
                                {revealData?.oneYearCAGRPercentage}%
                              </Text>
                            )}
                          </Stack> */}
                        </Stack>
                        <Stack mt={1.5}>
                          {hideOverallPerformance ? (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              sx={{ fontWeight: 500, cursor: "pointer" }}
                              onClick={showOverallPerformance}
                            >
                              <Text
                                variant="caption1"
                                sx={{ color: "#2B70C5" }}
                              >
                                Overall Performance
                              </Text>
                              <Stack mt={0.2}>
                                <Image
                                  src="/images/onestock/down-arrow.png"
                                  width={12}
                                />
                              </Stack>
                            </Stack>
                          ) : null}
                          {overallPerformance ? (
                            <>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ fontWeight: 500, cursor: "pointer" }}
                                onClick={hideOverallPerformances}
                              >
                                <Text
                                  variant="caption1"
                                  sx={{ color: "#2B70C5" }}
                                >
                                  Overall Performance
                                </Text>
                                <Stack mt={0.2}>
                                  <Image
                                    src="/images/onestock/arrow.png"
                                    width={15}
                                  />
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack
                                  mt={1.5}
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  <Stack
                                    direction="row"
                                    sx={{
                                      border: "1px solid #DEDEDE",
                                      borderRadius: "30px",
                                      padding: "3px 5px",
                                      background: "#DEDEDE",
                                    }}
                                  >
                                    <Text
                                      variant="caption2"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      52W H : &nbsp;
                                    </Text>
                                    <Stack direction="row">
                                      <Text variant="caption2">
                                        {formatHelper.currency(
                                          Math.abs(revealData?.weekHigh)
                                        ) || 0}
                                      </Text>
                                      <Text
                                        variant="caption2"
                                        sx={{
                                          display: "flex",
                                          color: "#45AC70",
                                        }}
                                      >
                                        (
                                        {(revealData?.weekHighPercentage || 0) +
                                          "%"}
                                        )
                                      </Text>
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    sx={{
                                      border: "1px solid #DEDEDE",
                                      borderRadius: "30px",
                                      padding: "3px 5px",
                                      background: "#DEDEDE",
                                    }}
                                  >
                                    <Text
                                      variant="caption2"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      52W L : &nbsp;
                                    </Text>
                                    <Stack direction="row">
                                      <Text variant="caption2">
                                        {formatHelper.currency(
                                          Math.abs(revealData?.weekLow)
                                        ) || 0}
                                      </Text>
                                      <Text
                                        variant="caption2"
                                        sx={{
                                          display: "flex",
                                          color: "#45AC70",
                                        }}
                                      >
                                        (
                                        {(revealData?.weekLowPercentage || 0) +
                                          "%"}
                                        )
                                      </Text>
                                    </Stack>
                                  </Stack>
                                </Stack>
                                <Stack
                                  mt={0.5}
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  {/* <Stack direction="row">
                                                                                <Text variant="caption2" sx={{ fontWeight: 500 }}>Overall AI Score :</Text>
                                                                                <Text variant="caption2" sx={{ fontWeight: 500, color: "#45AC70" }}>{revealData?.overallAIScorePercentage}%</Text>
                                                                            </Stack> */}
                                  {/* <Stack direction="row">
                                    <Text
                                      variant="caption2"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      Fundamental Score :
                                    </Text>
                                    {revealData?.fundamentalScorePercentage >
                                    0 ? (
                                      <Text
                                        variant="caption2"
                                        sx={{
                                          fontWeight: 500,
                                          color: "#45AC70",
                                        }}
                                      >
                                        {revealData?.fundamentalScorePercentage}
                                        %
                                      </Text>
                                    ) : (
                                      <Text
                                        variant="caption2"
                                        sx={{
                                          fontWeight: 500,
                                          color: "#D44C4C",
                                        }}
                                      >
                                        {revealData?.fundamentalScorePercentage}
                                        %
                                      </Text>
                                    )}
                                  </Stack> */}
                                </Stack>
                                {/* <Stack mt={0.5} direction="row" justifyContent="space-between">
                                                                            <Stack direction="row">
                                                                                <Text variant="caption2" sx={{ fontWeight: 500 }}>Technical Score :</Text>
                                                                                <Text variant="caption2" sx={{ fontWeight: 500, color: "#45AC70" }}>{revealData?.technicalScorePercentage}%</Text>
                                                                            </Stack>
                                                                            <Stack direction="row">
                                                                                <Text variant="caption2" sx={{ fontWeight: 500 }}>Sentiment Score :</Text>
                                                                                <Text variant="caption2" sx={{ fontWeight: 500, color: "#45AC70" }} >{revealData?.sentimentScorePercentage}%</Text>
                                                                            </Stack>
                                                                        </Stack> */}
                              </Stack>
                            </>
                          ) : null}
                        </Stack>
                        <Stack mt={2} alignItems="center">
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ width: "100%" }}
                            onClick={handleBuyStocks}
                          >
                            Buy Now
                          </Button>
                          <Stack mt={1.5} direction="row">
                            <Text variant="small" sx={{ color: "#676E76" }}>
                              Already purchased? &nbsp;
                            </Text>
                            <Text
                              variant="small"
                              sx={{ color: "#2B70C5", cursor: "pointer" }}
                              onClick={() => handleModalOpen("updateStocks")}
                            >
                              Update Now
                            </Text>
                          </Stack>
                        </Stack>
                      </JarvisCard>
                    </Stack>
                  </>
                )
              ) : null}
              {revealData?.revealStatus === "PENDING" ? (
                <>
                  <Box p={1.5} mb={3} className={classes.infoText}>
                    <Text variant="small">
                      <span style={{ color: "#2B70C5", fontWeight: 500 }}>
                        {revealData?.revealNote.slice(0, 13)}
                      </span>
                      {revealData?.revealNote.slice(13)}
                    </Text>
                  </Box>
                  <Stack mb={3} className={classes.mobileCardWrapper}>
                    <Text variant="subtitle1" className={classes.cardTimer}>
                      Unlock in{" "}
                      <span style={{ fontWeight: "700" }} id="revealSpan">
                        {revealTime.days > 0 &&
                          `${formatHelper.zeroPrefix(revealTime.days)} Days `}
                        {revealTime.hours > 0 &&
                          `${formatHelper.zeroPrefix(revealTime.hours)} Hours `}
                        {revealTime.minutes > 0 &&
                          `${formatHelper.zeroPrefix(
                            revealTime.minutes
                          )} Mins. `}
                        {revealTime.seconds > 0 &&
                          `${formatHelper.zeroPrefix(
                            revealTime.seconds
                          )} Secs. `}
                      </span>
                    </Text>
                  </Stack>
                </>
              ) : null}
              {revealData?.revealStatus === "HALTED" ? (
                <>
                  <JarvisNote
                    color="default"
                    description={`Next unlock will happen in ${
                      dateTimeHelper.diff(new Date(), revealData?.revealTime)
                        .days
                    } day(s)`}
                  />
                </>
              ) : null}
              {revealData?.revealStatus === "No Stocks" ? (
                <>
                  <Stack
                    mb={3}
                    sx={{ maxHeight: "250px", borderRadius: "10px" }}
                  >
                    <JarvisCard sx={{ background: "#004fc6" }}>
                      <Stack direction={{ xs: "column", md: "row" }}>
                        <Text variant="subtitle2" sx={{ color: "#fff" }}>
                          {revealData?.revealNote}
                        </Text>
                      </Stack>
                    </JarvisCard>
                  </Stack>
                </>
              ) : null}
            </Stack>
          )}
        />
        {/* Know More Pop up */}
        <Modal open={knowMore} size={"sm"} disableClose={true}>
          <Stack direction="row" justifyContent="space-between">
            <Text
              sx={{
                color: "#222B3E",
                fontWeight: 500,
              }}
            >
              Know why OneStock recommend you this stock
            </Text>
            <IconButton
              className={classes.close}
              onClick={() => handleModalClose("knowMore")}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack mt={2}>
            <Text mb={1.5}>
              <li>{revealData?.reason}</li>
            </Text>
            <Button
              size="small"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={handleBuyStocks}
            >
              Buy Now
            </Button>
          </Stack>
        </Modal>

        {/* Update Now */}
        <Modal open={updateStock} size={"sm"} disableClose={true}>
          <Stack direction="row" justifyContent="space-between">
            <Text variant="h6">Update Stock</Text>
            <IconButton
              className={classes.close}
              onClick={() => handleModalClose("updateStocks")}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack mt={2}>
            <Stack>
              <Stack>
                <TextField
                  type="text"
                  label="Stock Price"
                  value={formData.buyStockPrice}
                  onBlur={()=>handleOnBlur("stockPrice")}
                  onChange={(e) =>
                    handleInputChange({
                      name: "buyStockPrice",
                      value: e.target.value,
                    })
                  }
                  helperText={errorBuyStocks}
                  error={errorBuyStocks}
                  sx={{ width: 1, mb: 2 }}
                />
                <TextField
                  type="text"
                  label="Enter quantity"
                  value={formData.buyQuantity}
                  onBlur={()=>handleOnBlur("stockQuantity")}
                  onChange={(e) =>
                    handleInputChange({
                      name: "buyQuantity",
                      value: e.target.value.replace(/\D/g, ""),
                    })
                  }
                  helperText={errorBuyQuantity}
                  error={errorBuyQuantity}
                  sx={{ width: 1, mb: 2 }}
                />
              </Stack>
            </Stack>
            <Stack mb={2} direction="row">
              <Text sx={{ fontSize: "15px", color: "#676E76" }}>
                Total amount: &nbsp;
              </Text>
              <Text sx={{ fontSize: "15px", fontWeight: 500 }}>
                {(formData.buyStockPrice * formData.buyQuantity).toFixed(2)}
              </Text>
            </Stack>
            <Stack flex={0} direction="row" alignItems="center" sx={{ mb: 2, px: 1.75, py: 1, borderRadius: "3px", backgroundColor: "#EEF4FA" }}>
              <InfoIcon sx={{ mt: { xs:"-2.5rem", sm: "-1.375rem" }, mr: 1, fontSize: "20px", color: "#B0BABF" }} />
              <Text variant="small" sx={{ mt: { xs:"-2.5rem", sm: "-1.375rem" }, mr: 1, fontWeight: "medium", color: "#252C32", whiteSpace:"nowrap" }}>Please Note:</Text>
              <Text variant="small" sx={{ color: "#303236", whiteSpace:"prewrap" }}>Transaction will get updated in jarvis account and not in your broker account.</Text>
            </Stack>
            <JarvisButton
              loading={isLoading}
              disableEndIcon
              size="small"
              variant="contained"
              sx={{ width: "100%", textTransform: "uppercase" }}
              onClick={handleUpdateStocksModal}
            >
              Update
            </JarvisButton>
          </Stack>
        </Modal>

        {/* Awesome Pop Up */}
        <Modal open={showAwesome} size={"xs"} disableClose={true}>
          <Stack alignItems="center">
            <Text mb={2} variant="subtitle1">
              Awesome!
            </Text>
            <Text mb={2} variant="body1">
              Stock is added to your portfolio
            </Text>
            <JarvisButton
              variant="outlined"
              onClick={closeAwesomeModal}
              sx={{ border: "1px solid #DEDEDE" }}
            >
              Go to Holdings
            </JarvisButton>
          </Stack>
        </Modal>

        {/* Buy Now Stocks */}
        <Modal open={buyStocks} disableClose={true} size="sm">
          <Stack direction="row" justifyContent="space-between">
            <Text variant="h6">Buy Stock</Text>
            <IconButton
              className={classes.close}
              onClick={() => handleModalClose("buyStocks")}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack mt={2} alignItems="center">
            <Grid container>
              <Grid
                item
                md={5.5}
                sx={{
                  marginRight: "20px",
                  marginLeft: { xs: "unset", md: "15px" },
                }}
              >
                <Stack>
                  <TextField
                    type="text"
                    label="Stock Price"
                    value={formData.buyStockPrice}
                    sx={{ width: 1, mb: 2 }}
                    disabled={true}
                  />
                </Stack>
              </Grid>
              <Grid item md={5.5}>
                <Stack>
                  <TextField
                    type="text"
                    label="Enter quantity"
                    value={formData.buyQuantity}
                    onChange={(e) =>
                      handleInputChange({
                        name: "buyQuantity",
                        value: e.target.value.replace(/\D/g, ""),
                      })
                    }
                    helperText={errorBuyQuantity}
                    error={errorBuyQuantity}
                    sx={{ width: 1, mb: 2 }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Grid container>
            <Grid
              item
              md={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Stack>
                <Stack direction="row" alignItems="end">
                  <Text
                    variant="body2"
                    sx={{
                      color: "#676E76",
                    }}
                  >
                    Total amount:&nbsp;
                  </Text>
                  <Text variant="body2" sx={{ fontWeight: 500 }}>
                    {(formData.buyStockPrice * formData.buyQuantity).toFixed(2)}
                  </Text>
                </Stack>
                <Text variant="caption1" sx={{ color: "#A2A7AE" }}>
                  Make sure your account has sufficient funds.
                </Text>
              </Stack>
            </Grid>
          </Grid>

          {config.isMobileApp ? (
            <Stack mt={2} direction="row" justifyContent="space-between">
              <Text variant="small" sx={{ fontWeight: 500, color: "#676E76" }}>
                If you don't find your broker, update manually
              </Text>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  border: "#2B70C5",
                  background: "#EEF4FA",
                  textTransform: "capitalize",
                  fontSize: "13px",
                  borderRadius: "8px",
                  minWidth: "100px",
                }}
                onClick={updateBuyStocks}
              >
                Update
              </Button>
            </Stack>
          ) : (
            <Stack
              mt={3}
              mb={2}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Text mt={1} mb={1} sx={{ fontWeight: 500, color: "#212225" }}>
                If you don't find your broker, update manually
              </Text>
              <Button
                size="small"
                sx={{
                  border: "#2B70C5",
                  background: "#EEF4FA",
                  fontSize: "13px",
                  borderRadius: "10px",
                  padding: { xs: "10px", md: "0px 10px" },
                  textTransform: "capitalize",
                }}
                variant="outlined"
                onClick={updateBuyStocks}
              >
                Update Now
              </Button>
            </Stack>
          )}

          <Grid container mt={2}>
            <Grid item md={5.5} sx={{ marginRight: "5px", marginLeft: "15px" }}>
              <Stack>
                <Text variant="body1" sx={{ fontWeight: 500 }}>
                  Link Broker
                </Text>
                <Text variant="small" sx={{ color: "#A2A7AE" }}>
                  Select a broker from the list below
                </Text>
              </Stack>
            </Grid>
            <Grid item mt={1.5} md={6} sx={{ width: { xs: "100%" } }}>
              <TextField
                type="text"
                placeholder="Search broker"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ width: 1, mb: 2, padding: "10px 0" }}
                inputProps={{
                  className: classes.searchBroker,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ pl: 1, margin: "15px 0" }}
                    >
                      <img alt="" src="/svgs/search_icon.svg"></img>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Text variant="medium" sx={{ fontWeight: 500, mb: "20px" }}>
            List of Brokers
          </Text>
          <Grid
            container
            spacing={2.5}
            sx={{
              justifyContent: "flex-start",
              flex: 1,
              alignItems: "center",
              mb: { xs: "-1.25rem", md: "-0.5rem" },
              gap: "2rem",
              paddingBottom: "25px",
              marginBottom: "20px",
            }}
          >
            {isBrokerListInitialCalled && centrumBroker.length ? (
              centrumBroker.map((b, index) => (
                b.brokerIcon ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={3} key={index}>
                  <ChooseBrokerIcon
                    image={b.brokerIcon}
                    title={b.brokerDisplayName}
                    onClick={() =>
                      handleBrokerClick({ broker: b, modalFlag: true })
                    }
                  />
                </Grid>
                ) : null
              ))
            ) : (
              <></>
            )}
            {isCentrumTextVisible && <Stack
              direction={{ xs: "row", md: "column" }}
              justifyContent={{ xs: "space-between", md: "flex-start" }}
              width={{ xs: "100%", md: "fit-content" }}
              pl={{ xs: "1.25rem", md: "20px" }}
            >
              <Text
                variant="small"
                sx={{ fontWeight: 700, placeSelf: "center" }}
              >
                Don't have a Centrum account?
              </Text>
              <Text
                variant="small"
                sx={{
                  fontWeight: 500,
                  color: "#2B70C5",
                  cursor: "pointer",
                  "&::selection": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={() => {
                  window.open(props?.centrumReferalURL, "_blank");
                }}
              >
                Open New Account
              </Text>
            </Stack>}
            <Stack
              direction="row"
              ml="1.25rem"
              width="100%"
              border="1px solid #F5F5F5"
            ></Stack>
          </Grid>
          <Grid
            container
            spacing={2.5}
            sx={{
              justifyContent: "flex-start",
              flex: 1,
              alignItems: "center",
              mb: { xs: 8, md: 2.5 },
              mt: { xs: 1, md: 0 },
            }}
          >
            {(isBrokerListLoading || !isBrokerListInitialCalled) && <Loader />}
            {isBrokerListInitialCalled && !filteredBrokerList.length ? (
              <Grid item>
                <Text>
                  Broker with search term <b>{searchTerm}</b> not
                  found/integrated yet. Please try with manual update.
                </Text>
              </Grid>
            ) : (
              filteredBrokerList.map((b, index) => (
                <>
                  {b.brokerName === "centrum" ? (
                    <></>
                  ) : (
                    <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={index}>
                      <ChooseBrokerIcon
                        image={b.brokerIcon}
                        title={b.brokerDisplayName}
                        onClick={() =>
                          handleBrokerClick({ broker: b, modalFlag: true })
                        }
                      />
                    </Grid>
                  )}
                </>
              ))
            )}
          </Grid>
        </Modal>
        {/* stock quantity modal for symphony broker platform stocks */}
        <Modal open={brokerQuantityModal} disableClose={true} size="sm">
          <Stack direction="row" justifyContent="space-between">
            <Text
              variant="h6"
              sx={{
                fontWeight: "bold",
              }}
            >
              Buy
            </Text>
            <IconButton
              className={classes.close}
              onClick={() => handleModalClose("stockQuantityModal")}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction="row" mt={2}>
            <Text
              variant="h6"
              sx={{
                fontWeight: "bold",
              }}
            >
              {revealData?.stockName}
            </Text>
          </Stack>
          <Stack
            mt={2}
            mb={2}
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
          >
            <TextField
              type="text"
              label="Stock Price"
              value={revealData?.lTP}
              sx={{ width: 1, mb: 2 }}
              disabled={true}
            />
            <TextField
              type="number"
              label="Enter quantity"
              value={formData.buyQuantity}
              onChange={(e) => {
                handleInputChange({
                  name: "buyQuantity",
                  value: e.target.value,
                });
              }}
              helperText={errorBuyQuantity}
              error={errorBuyQuantity}
              sx={{ width: 1, mb: 2 }}
            />
          </Stack>
          <Stack direction="column" mt={2}>
            <Stack
              direction="row"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Text
                variant="body2"
                sx={{
                  color: "#676E76",
                }}
              >
                Total value:&nbsp;
              </Text>
              <Text variant="body2" sx={{ fontWeight: 500 }}>
                {(revealData.lTP * formData.buyQuantity).toFixed(2)}
              </Text>
            </Stack>
            <Stack
              direction="row"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Text variant="caption1" sx={{ color: "#A2A7AE" }}>
                Make sure your account has sufficient funds.
              </Text>
            </Stack>
          </Stack>
          <Stack direction="row" marginY="1.5rem">
            <JarvisButton
              disableEndIcon
              sx={{ width: "100%", boxShadow: "none", mt: 3 }}
              onClick={() => handleBrokerClick({ broker: selectedBroker })}
            >
              Proceed to Buy
            </JarvisButton>
          </Stack>
        </Modal>
        {/* Pop-up for showing leaving jarvis website to broker website */}
{/*         <Modal
          open={brokerEnvironment}
          disableClose={true}
          size={"sm"}
          sx={{ width:"100%", height:"75%" }}
        >
        <Text sx={{ mt: {xs:"75%", sm: "35%"}, mb: 0.8, fontSize: "22px", textAlign: "center", whiteSpace: "prewrap" }}>You are leaving our environment</Text>
        </Modal> */}
        {/* Section: market Status Modal */}
      <Modal
        open={marketStatusNote}
        disableClose={true}
        size={"xs"}
        sx={{ padding: "16px 0px" }}
      >
        <Stack alignItems="center" width={1}>
          <img src="/images/market-closed.svg" alt="" width={100}></img>
          <Text
            sx={{
              mt: 2,
              mb: 0.8,
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            {marketStatusMessage}
          </Text>
          <Text
            sx={{ mb: 3, color: "#808591cc", textAlign: "center" }}
            variant="small"
          >
            Try again on the next market day{" "}
          </Text>
          <JarvisButton
            variant="outlined"
            disableEndIcon="true"
            onClick={() => handleMarketStatusModalClose()}
          >
            Okay{" "}
          </JarvisButton>
        </Stack>
      </Modal>
      </>
    );

}

const useStyles = makeStyles((theme) => ({
    infoText: {
        background: "#fff",
        borderRadius: "8px",
    },
    infoText2: {
        background: "#FFFCF5",
        borderRadius: "8px",
        border: "1px solid #E0953D",
    },
    infoText3: {
        background: "#fff",
        borderRadius: "8px",
        border: "1px solid #E0953D",
    },
    cardWrapper: {
        backgroundImage: `url(${"/images/onestock/onestock-card-bg.svg"})`,
        height: "250px",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        border: "5px solid #fff",
        borderRadius: "10px",
    },
    mobileCardWrapper: {
        backgroundImage: `url(${"/images/onestock/mobile-onestock-card-bg.svg"})`,
        height: "250px",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        border: "5px solid #fff",
        borderRadius: "10px",
    },
    cardTimer: {
        fontWeight: "500",
        textAlign: "center",
    },
    stockCardWrapper: {
        height: "250px",
        borderRadius: "10px",
        background: "#fff",

    },
    mobileStockCardWrapper: {
        borderRadius: "10px",
        background: "#fff",

    },
    knowWhyText: {
        cursor: "pointer",
        background: "#EEF4FA",
        color: "#2B70C5",
        borderRadius: "6px",
        fontWeight: 500,
    },
    mobileKnowWhyText: {
        cursor: "pointer",
        color: "#2B70C5",
        borderRadius: "6px",
        fontWeight: 500,
    },
    chips: {
        borderRadius: "30px",
        border: "1px solid #DEDEDE",
        padding: "15px 8px",
        fontWeight: "400",
        background: "#fff",
        marginRight: "20px",
    },
    close: {
        marginTop: "-7px",
    },
    searchBroker: {
        padding: "10px 0",
    }
}));

export default withRouter(OnestockDashboardCard)