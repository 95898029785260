import React from "react";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import MobileResponsive from "components/common/MobileResponsive";
import DashboardHeader from "../DashboardHeader";
import PortfolioPerformanceCard from "components/Dashboard/PortfolioPerformanceCard";
import StatsContainer from "./Stats";
import StatsMobileAppContainer from "./StatsMobileApp";
import PortfolioAllocationContainer from "./PortfolioAllocation";
import PerformanceChartContainer from "./PerformanceChart";
import SectorDiversificationContainer from "./SectorDiversification";
import MarketCapDiversificationContainer from "./MarketCapDiversification";
import { dateTimeHelper } from "helper/dateTime";
import { tracker } from "library/tracker";
import { withRouter } from "react-router-dom";
import Button from "components/common/Button";

class DashboardOnboardedContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpiring: false,
      expireInDays: undefined,
    }
  }
  componentDidMount() {
    this.props.getPortfolioPerformanceAction();

    if (!this.props.isOnboardingInitiallyFetched) {
      this.props.getOnboardingDataAction();
    }
    if (!Object.keys(this.props.profile).lenght) {
      this.props.getProfileAction();
    }
    //event-tracker: Initial -> dashboard_loaded
    tracker.initial.dashboardLoaded();

  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profile.expiryDate !== this.props.profile.expiryDate) {
      const { profile } = this.props;
      const { isExpiring, expireInDays } = dateTimeHelper.isSubscriptionExpiring(profile.expiryDate);
      this.setState({ isExpiring, expireInDays });
    }
  }

  getAlertWidth = () => {
    const { isMobileVerified, isExpired } = this.props;
    let v = 12;
    if (!isMobileVerified & isExpired) {
      v = 6;
    }
    return v;

  };

  handlePlanAndPricingClick = () => {
    this.props.history.push({
      pathname: "/customer/plan-and-pricing",
      state: {
        returnURI: "/customer/dashboard",
        paymentType: "RENEWAL",
      },
    });
  }

  redirecToInvestMore = () => {
    this.props.history.push("/jarvis-portfolio/invest-more");
  }



  render() {
    const {
      profile,
      portfolioPerformance,
      hasPortfolio,
    } = this.props;
    return (
      <Stack sx={{ maxWidth: "100%" }} mb={2}>
        <MobileResponsive
          web={() => (
            <>
              <DashboardHeader />

              
                  <Grid container spacing={hasPortfolio?2:0} columns={hasPortfolio?20:0}>
                      <Grid  sx={{border:{xs:"1px solid #EEF0F2", md:"0px solid #EEF0F2" }, borderRadius:{xs:"10px"}}} item xs={hasPortfolio ?0:12} md={hasPortfolio ? 12 : 12} mb={2}>
                        <StatsContainer halfWidth={hasPortfolio} />
                      </Grid>
                { hasPortfolio ? (
                  <Grid item xs={0} md={8} mb={2} sx={{width:"100%", paddingLeft:{xs:"0px", md:"16px"}}}>
                    <PortfolioPerformanceCard data={portfolioPerformance} />
                  </Grid>     
                 ): null} 
                  </Grid>
            </>
          )}

          app={() => (
            <>
              <StatsMobileAppContainer
                heading={`Hi, ${profile.fullName || " User"}`}
              />

              <Grid container>
                {hasPortfolio ? (
                  <Grid item xs={12} md={6} mb={2}>
                    <PortfolioPerformanceCard data={portfolioPerformance} />
                  </Grid>
                ) : null}
              </Grid>
            </>
          )}
        /> {/* <- responsive Ends here */}


        {/* nifty data is not coming properly from server */}
        <PerformanceChartContainer />
        <PortfolioAllocationContainer />

        {/* Circle Charts */}
        <Grid container spacing={2} mt={{ md: 0 }}>
          <Grid item xs={12} md={6}>
            <SectorDiversificationContainer />
          </Grid>
          <Grid item xs={12} md={6}>
            <MarketCapDiversificationContainer />
          </Grid>
        </Grid>

        {/* App invest more click */}
        <MobileResponsive
          app={() => (
            <Button sx={{
              backgroundColor: "#2B70C5",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "max-content",
              padding: "5px 21px",
              borderRadius: "8px",
              position: "fixed",
              bottom: "calc(70px + var(--safe-area-inset-bottom))",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "99",
              cursor: "pointer",
              textTransform: "capitalize",
            }}
              onClick={this.redirecToInvestMore}>
              <img src="/images/mobileApp/invest-more.svg" style={{ marginRight: "8px" }} alt="icon" />
              Invest More
            </Button>
          )}
        />

      </Stack>
    );
  }
}

export default withRouter(DashboardOnboardedContainer);
