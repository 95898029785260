import NotAccessForPlan from "components/common/NotAccessForPlan";

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { config } from "config/index";


// to handle redirection from public route
// Ex.
// An authenticated user tries to load public pages like Login, Register, etc.
// it will redirect to defined path
export const PublicRoute = (props) => {
    const { redirectTo = "/", auth, ...restProps } = props;

    if (auth.isAuthenticated) {
        return <Redirect to={redirectTo} />;
    }

    return <Route {...restProps} />;
};

// to handle redirection from private/restricted route
// Ex.
// A non authenticated user tries to load restricted pages like Profile, Dashboard, etc.
// it will redirect to defined path
export const PrivateRoute = (props) => {
    const { redirectTo = "/", auth, ...restProps } = props;
    if (!auth.isAuthenticated) {
        return <Redirect to={redirectTo} />;
    }

    return <Route {...restProps} />;
};

// to handle redirection for onboarding customers
// Ex.
// if customer is not authenticated then redirect to login screen
// if customer is authenticated and onboarding completed then redirect to customer dashboard
// else handle all onboarding routes
export const OnboardingRoute = (props) => {
    const {
        redirectUnAuthenticated = "/login",
        redirectOnboardingIncomplete = "/customer/dashboard",
        isAuthenticated,
        isOnboardingCompleted,
        ...restProps
    } = props;

    if (!isAuthenticated) {
        return <Redirect to={redirectUnAuthenticated} />;
    }

    if (isAuthenticated && isOnboardingCompleted) {
        return <Redirect to={redirectOnboardingIncomplete} />;
    }

    return <Route {...restProps} />;
};

// to handle redirection for onboarding completed customers
// Ex.
// if customer is not authenticated then redirect to login screen
// if customer is authenticated and onboarding not completed then redirect to onboarding screen
// else handle all customer onboarding completed routes
export const CustomerRoute = (props) => {
    const {
        redirectUnAuthenticated = "/login",
        redirectOnboardingCompleted = "/onboarding/overview",
        isAuthenticated,
        isOnboardingCompleted,
        ...restProps
    } = props; 

    if (!isAuthenticated) {
        return <Redirect to={redirectUnAuthenticated} />;
    }

    if(isAuthenticated && !isOnboardingCompleted && config.isMobileApp){
       return <NotAccessForPlan />; 
    }

    if (isAuthenticated && !isOnboardingCompleted && !config.isMobileApp) {
        return <Redirect to={redirectOnboardingCompleted} />;
    }

    return <Route {...restProps} />;
};
