import _get from "lodash.get";
import { formatHelper } from "helper/format";
const dashboardParsers = {};

dashboardParsers.portfolioAllocation = (res) => {
  if (res && res.data) {
    res = res.data;
  }
  if (!res) {
    return [];
  }

  let data = res.map((p) => ({
    stockName: _get(p, "stockName", null),
    shareCode: _get(p, "stockSymbol", null),
    quantity: _get(p, "quantity", 0) || 0,
    pendingActionableQty: 0,
    buyPrice: formatHelper.parseDecimal(_get(p, "buyPrice", 0)) || 0,
    totalBuyPrice: formatHelper.parseDecimal(_get(p, "totalBuyPrice", 0)) || 0,
    currentPrice: formatHelper.parseDecimal(_get(p, "currentPrice", 0)) || 0,
    totalCurrentPrice: formatHelper.parseDecimal(_get(p, "totalCurrentPrice", 0)) || 0,
    allocationPercentage: formatHelper.parseDecimal(_get(p, "allocationPercentage", 0)) || 0,
    profitPrice: formatHelper.parseDecimal(_get(p, "profitPrice", 0)) || 0,
    profitPercentage: formatHelper.parseDecimal(_get(p, "profitPercentage", 0)) || 0,
    profitType: _get(p, "profitType", null),
    capCategory: _get(p, "capCategory", null),
  }));

  return data;
};

dashboardParsers.portfolioOverview = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return {};
  }
  let data = {
    holdingValue: _get(res, "holdingValue", 0),
    totalInvestment: _get(res, "totalInvestment", 0),
    currentValue: formatHelper.parseDecimal(_get(res, "currentValue", null)) || 0,
    profit: formatHelper.parseDecimal(_get(res, "profit", null)) || 0,
    profitPercentage: formatHelper.parseDecimal(
      _get(res, "profitPercentage", null)
    ) || 0,
    cash: formatHelper.parseDecimal(_get(res, "cash", null)),
  };
  return data;
};

dashboardParsers.portfolioPerformance = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return {};
  }

  const roundDecimal = (val) => Number(Number(val).toFixed(2));
  const hasDataAny = (d) => (
    !Object.values(d).every((v) => ((!v || v === "-") ? true : false))
  );

  let data = {
    mtdPortfolio: roundDecimal(_get(res, "mtdPortfolio", "")),
    qtdPortfolio: roundDecimal(_get(res, "qtdPortfolio", "")),
    ytdPortfolio: roundDecimal(_get(res, "ytdPortfolio", "")),
    mtdNifty: roundDecimal(_get(res, "mtdNifty", "")),
    qtdNifty: roundDecimal(_get(res, "qtdNifty", "")),
    ytdNifty: roundDecimal(_get(res, "ytdNifty", "")),
    hasPortfolio: hasDataAny(res),
  };
  return data
};

dashboardParsers.portfolioChart = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return {};
  }

  const portfolioArr = (portfolio) =>
    portfolio.map((e) => [Date.parse(e.date), e.value]);
  const niftyArr = (nifty) => nifty.map((e) => [Date.parse(e.date), e.value]);

  return {
    portfolio: portfolioArr(_get(res, "portfolio", [])),
    nifty: niftyArr(_get(res, "nifty", [])),
  };
};

dashboardParsers.portfolioSector = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return [];
  }

  return res.map((e) => ({
    sector: _get(e, "sector", ""),
    amount: _get(e, "amount", 0),
  }));
};

dashboardParsers.portfolioMarket = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return [];
  }
  return res.map((e) => ({
    name: _get(e, "displayName", ""),
    amount: _get(e, "amount", 0),
  }));
};

dashboardParsers.welcomeVideoList = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return [];
  }

  return res.map((e) => ({
    description: _get(e, "description", ""),
    sequence: _get(e, "sequence", ""),
    link: _get(e, "link", ""),
    thumbnail: _get(e, "thumbnail", ""),
    title: _get(e, "title", ""),
  }));
};

dashboardParsers.customerStoryList = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return [];
  }

  return res.map((e) => ({
    description: _get(e, "description", ""),
    name: _get(e, "name", ""),
    ratings: _get(e, "ratings", ""),
    thumbnail: _get(e, "thumbnail", ""),
  }));
};

// RMS/Rebalance
dashboardParsers.portfolioAlert = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return {};
  }

  return {
    pendingTransaction: _get(res, "pendingTransaction", ""),
    title: _get(res, "title", ""),
    description: _get(res, "description", ""),
    tracsactionType: _get(res, "tracsactionType", ""),
    transactionStatus: _get(res, "transactionStatus", ""),
    hasExpired: _get(res, "hasExpired", ""),
    archiveMsg: _get(res, "archiveMsg", ""),
    newCallMsg: _get(res, "newCallMsg", ""),
    actionScreenTitle: _get(res, "actionScreenTitle", ""),
    actionScreenDescription: _get(res, "actionScreenDescription", ""),
    note: _get(res, "note", ""),
  };
};

dashboardParsers.portfolioPendingTransaction = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return {};
  }

  const sortStocks = (d, sort) =>
    d.filter((d) => d.actionTakenByUser === sort).map((s) => ({
      symbol: _get(s, "symbol", ""),
      quantity: _get(s, "qty", ""),
      suggested_quantity: _get(s, "suggested_quantity", ""),
      tracsactionType: _get(s, "tracsactionType", ""),
      action: _get(s, "action", ""),
      rate: _get(s, "rate", ""),
      actionTakenByUser: _get(s, "actionTakenByUser", ""),
    }));

  return {
    transactionDate: _get(res, "transactionDate", ""),
    completedStocks: sortStocks(_get(res, "stockDtls", []), 1),
    pendingStocks: sortStocks(_get(res, "stockDtls", []), 0),
  };
};

dashboardParsers.finalPortfolioConfirmation = (res) => {
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return [];
  }

  const d = res.map((p) => ({
    quantity: _get(p, "qty", ""),
    symbol: _get(p, "symbol", ""),
    name: _get(p, "stockName", ""),
  }));

  return d;

}

export { dashboardParsers };
