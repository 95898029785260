import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const googleLoginTracker = {};

googleLoginTracker.googleLogin = () => {
    // const eventName = "g_login";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

googleLoginTracker.googleLoginFailed = ({ error_msg }) => {
    // const eventName = "g_login_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: {error_msg },
    // });
};

googleLoginTracker.googleLoginSuccess = () => {
    const eventName = "g_login_success";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { },
    });
};

export { googleLoginTracker };
