import { Box } from "@mui/system";
import React from "react";
import Text from "components/common/Text";
import JarvisButton from "components/JarvisButton";
import Container from "components/common/Container";
import { makeStyles } from "@mui/styles";
import Link from "components/common/Link";
const NotFoundContainer = () => {
    const classes = useStyles();
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                margin: "margin: 0 auto -5px",
                pt: 17,
                pb: 6,
            }}>
            <Container align="center">
                <Text variant="h1" color="" className={classes.mainText}>
                    404
                </Text>

                <Text variant="h3" mt={4}>
                    Oops! Page Not Found
                </Text>
                <Box mt={8} align="center">
                    <Link to="/" sx={{textDecoration:"none"}}>
                        <JarvisButton >Go to Dashboard</JarvisButton>
                    </Link>
                </Box>
            </Container>
        </Box>
    );
};
const useStyles = makeStyles((theme) => ({
    mainText: {
        whiteSpace: "nowrap",
        textDecoration: "underline",
        fontStyle: "italic",
        background: "linear-gradient(117deg, #1d9bff 0%, #3fe7e0 100%)",
        backgroundClip: "text",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        position: "relative",
    },
}));

export default NotFoundContainer;
