import { config } from "config";
const intercom = {};

intercom.init = ({ isLoggedIn, loginProfile }) => {
    intercom.stop();
    if (isLoggedIn) {
        window.Intercom("boot", {
            api_base: config.intercomId.api_base,
            app_id: config.intercomId.app_id,
            name: loginProfile.name,
            email: loginProfile.email,
            mobile: loginProfile.mobile,
        });
    } else {
        window.Intercom("boot", {
            api_base: config.intercomId.api_base,
            app_id: config.intercomId.app_id,
        });
    }
 
};

intercom.stop = () => {
    window.Intercom("shutdown")
}

intercom.show = ({ message = "" } = {}) => {
    if (message) {
        window.Intercom("showNewMessage", message);
    } else {
        window.Intercom("show");
    }
}

intercom.hide = () => {
    window.Intercom("hide");
}

export { intercom };
