import Button from "components/common/Button";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import React from "react";

const RMSSyncPortfolioModal = (props) => {
  const {syncPortfolioName,onChange,handleSave, error} = props;
  return (
    <Stack spacing={2}>
      <TextField
        label="Enter Your Portfolio Name"
        type="text"
        value={syncPortfolioName}
        onChange={onChange}
        helperText={error}
        error={error}
      />
      <Button sx={{ color: "white", textTransform: "none" }} onClick={handleSave}>Save</Button>
    </Stack>
  );
};

export default RMSSyncPortfolioModal;
