import React, { useState } from "react";
import { config } from "config";

import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Box from "components/common/Box";
import JarvisButton from "components/JarvisButton";

import Checkbox from 'components/common/CheckboxTick';

const AgreeConsent = (props) => {
    const { agreedAndSignup } = props;
    const [hasAgreedConcent, setHasAgreedConcent] = useState(false);
    const [hasAgreedTnc, setHasAgreedTnc] = useState(false);

    const handleAgreementClick = (type, e) => {
        if (type === "consent") {
            setHasAgreedConcent(e.target.checked);
        } else if (type === "tnc") {
            setHasAgreedTnc(e.target.checked);
        }
        disabledToProceed();
    }

    const disabledToProceed = () => {
        if (hasAgreedTnc) {
            if (hasAgreedConcent) {
                return false;
            }
        }
        return true;

    }

    return (
        <Stack
            sx={{
                maxWidth: "360px",
                m: "10px auto 0px auto",
            }}>

            <Text fontSize="14px" color="#878787" mb="10px">
                <Checkbox onChange={(e) => handleAgreementClick("tnc", e)} />
                I agree to the{" "}
                <a href={`${config.disclaimerUrl}`} target="_blank">JARVIS Disclaimer</a> and
                <a href={`${config.websiteUrl}/terms-and-conditions`} target="_blank"> T&Cs.</a>
            </Text>
            <Stack fontSize="14px" color="#878787" direction="row">
                <Checkbox onChange={(e) => handleAgreementClick("consent", e)} />
                <Box>I give consent for communication over Call, WhatsApp, SMS and Email.</Box>
            </Stack>
            <JarvisButton
                sx={{
                    mt: 3,
                    width: "fit-content",
                    fontSize: "14px",
                    padding: "9px 30px"
                }}
                disableEndIcon={true}
                onClick={agreedAndSignup}
                disabled={disabledToProceed()}>
                Create Account
            </JarvisButton>

        </Stack>
    )
}

export default AgreeConsent;