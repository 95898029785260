import React from "react";

import JarvisCard from "components/JarvisCard";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";

import JarvisDialog from "components/JarvisDialog";

import RiskProfileGraph from "./RiskProfileGraph";
import { tracker } from "library/tracker";

import MobileResponsive from "components/common/MobileResponsive";

const RiskProfileCard = ({
  selectedRiskProfileId,
  riskProfile,
  onChangeClick,
  mobileScreen,
}) => {
  const rPColors = {
    1: "#1c8d13",
    2: "#83c605",
    3: "#bcd103",
    4: "#ecb004",
    5: "#df873a",
    6: "#d44c4c",
  };
  const riskProfileColor = rPColors[selectedRiskProfileId]
    ? rPColors[selectedRiskProfileId]
    : "#202020";

  const changeMyRiskProfile = () => {
    return (
      <JarvisDialog
        openComponent={(onOpen) => (
          <Text
            variant="small"
            color="#2B70C5"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: { xs: "12px", sm: "12px", md: "14px" },
            }}
            onClick={(e) => {
              onOpen(e);
              console.log("called event");
            }}
          >
            Change my risk profile
          </Text>
        )}
        title={"Change Risk Profile?"}
        content={
          <>
            <span style={{ fontWeight: 500 }}>
              Rebalancing Portfolio is needed
            </span>{" "}
            when you change your
            <br />
            current risk profile to another one.
          </>
        }
        note={
          <>
            Note : You can only change risk profile
            <br /> once in 6 months
          </>
        }
        onConfirm={(onClose) => onChangeClick(onClose)}
      />
    );
  };

  return (
    <>
      <JarvisCard
        sx={{
          height: "100%",
          padding: { xs: "20px 0 20px 0", md: "20px 24px 20px 24px" },
        }}
      >
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: { xs: "row-reverse", md: "row" },
            marginLeft: { xs: 2, md: 0 },
          }}
        >
          <Stack
            sx={{
              justifyContent: { xs: "space-around", md: "space-between" },
              mr: { xs: 1, sm: 3, md: "unset" },
            }}
          >
            <Box>
              <Text
                color="#808591"
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: { xs: "10.5px", sm: "14px", md: "16px" },
                }}
              >
                Your risk profile
              </Text>
              <Text
                color={riskProfileColor}
                sx={{
                  fontSize: { xs: "14px", sm: "18px", md: "26px" },
                  lineHeight: 1,
                  marginBottom: "20px",
                  fontWeight: "bold",
                }}
              >
                {riskProfile}
              </Text>
            </Box>

            {changeMyRiskProfile()}
          </Stack>
          <Box sx={{ alignSelf: { xs: "flex-start", md: "flex-end" } }}>
            <Box>
              <MobileResponsive
                mobile={() => (
                  <RiskProfileGraph
                    riskProfileId={selectedRiskProfileId}
                    chartWidth={140}
                    disableLabel
                  ></RiskProfileGraph>
                )}
                web={() => (
                  <RiskProfileGraph
                    riskProfileId={selectedRiskProfileId}
                    needleLenght={43}
                  ></RiskProfileGraph>
                )}
              />
            </Box>
          </Box>
        </Stack>
      </JarvisCard>
    </>
  );
};

export default RiskProfileCard;
