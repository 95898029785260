import React from 'react'
import BrokerSSOLoginContainer from 'containers/BrokerSSOLogin';

const BrokerSSOLogin = (props) => {
  return (
    <BrokerSSOLoginContainer {...props}/>
  )
}

export default BrokerSSOLogin;
