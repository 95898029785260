import React from "react";

import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Table from "components/common/Table";
import Loader from "components/common/Loader";
import { formatHelper } from "helper/format";
import OfflineCheckerButton from "components/OfflineCheckerButton";

const ChoosePortfolioAction = (props) => {
  const {
    onConfirmTransaction,
    onGenerateActions,
    archiveMsg,
    newCallMsg,
    actionScreenTitle,
    actionScreenDescription,

    completedStocks = [],
    pendingStocks = [],
    loading = false,
    generateActionsLoading = false,
    transactionStatus,
  } = props;

  //   Static Data

  const columns = [
    { field: "symbol", headerText: "Symbol" },
    {
      field: "quantity",
      headerText: "Quantity",
      bodyCellComponent: (v, row) => {
        let print = "-";
        if (row.actionTakenByUser === 1) {
          print = row.suggested_quantity || row.suggested_quantity === 0 ? row.suggested_quantity : "-";

        } else if (row.actionTakenByUser === 0) {
          print = formatHelper.getQtyInSlash(v, row.suggested_quantity, true);
        }
        return <Text variant="small">{print}</Text>;
      },
    },
    {
      field: "action",
      headerText: "Action",
      bodyCellComponent: (v) => {
        const color = v === "S" ? "#D44C4C" : "#45AC70";
        const value = v === "S" ? "Sell" : "Buy";
        return <Text variant="small" style={{ color: color }}>{value}</Text>;
      },
    },
  ];

  //   Common Components: Table
  const TransactionTable = ({ columns, stocks, title }) =>
    stocks.length ? (
      <Box>
        <Text variant="body2" fontWeight="500">
          {title}
        </Text>
        <Box
          sx={{
            maxHeight: "200px",
            minHeight: "75px",
            overflow: "auto",
            mb: "20px",
            border: "1px solid #e0e0e0",
            borderRadius: "6px",
          }}
        >
          <Table
            columns={columns}
            data={stocks}
            sx={{
              // minWidth: "400px",
            }}
            th_sx={{
              p: "1px 10px",
              backgroundColor: "#F1F1F1",
              color: "#808591",
              borderRight: "1px solid #F1F1F1",
              fontWeight: "unset",
              fontSize: "12px",
            }}
            td_sx={{
              p: "10px",
              borderRight: "1px solid #F1F1F1",
              borderBottom: "none",
              fontSize: "14px",
              fontWeight: "500",
            }}
          />
        </Box>
      </Box>
    ) : null;

  const ActionCard = ({
    actionMessage,
    buttonText,
    onClick,
    btnDisabled,
    btnLoading = false,
  }) => (
    <Stack
      sx={{
        border: "1px solid #1D9BFF",
        borderRadius: "10px",
        p: "20px",
        margin: "7px",
        flexBasis: "50%",
        textAlign: { xs: "center", sm: "unset" },
      }}
    >
      <Text variant="small" mb="20px" fontWeight="500" sx={{ flex: 1 }}>
        {actionMessage}
      </Text>

      <OfflineCheckerButton
        offlineButtonProps={{
          sx: { fontSize: "16px", p: "8px 25px" },
          text: buttonText,
          disabled: btnDisabled,
          loading: btnLoading,
        }}
        onlineButtonProps={{
          sx: { fontSize: "16px", p: "8px 25px" },
          text: buttonText,
          disabled: btnDisabled,
          loading: btnLoading,
          onClick: onClick
        }}
      />
    </Stack>
  );

  const getActionButtonText = () => {
    let buttonName = "";
    switch (transactionStatus) {
      case "Pending": {
        buttonName = "Generate New Actions";
        break;
      }
      case "Partial": {
        buttonName = "Complete your pending transactions";
        break;
      }
      case "Repair": {
        buttonName = "Complete your pending transactions";
        break;
      }
    }
    return buttonName;
  };

  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          maxWidth: "415px",
          m: "0 auto 30px auto",
        }}
      >
        <img src="/images/icon-warning.png" alt="alert-icon" width="28px" />

        <Text variant="subtitle2" sx={{ color: "#D44C4C", mb: "20px" }}>
          {actionScreenTitle}
        </Text>
        <Text variant="body1" sx={{ color: "#1D9BFF" }}>
          {actionScreenDescription}
        </Text>
      </Box>

      {loading ? (
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            position: "relative",
            minHeight: "110px",
            borderRadius: "6px",
            mb: "20px",
            overflow: "hidden",
          }}
        >
          {/* <Loader position="absolute" loading={loading} /> */}
        </Box>
      ) : (
        <>
{/*           <TransactionTable
            columns={columns}
            stocks={completedStocks}
            title="Completed Transaction"
          />
          <TransactionTable
            columns={columns}
            stocks={pendingStocks}
            title="Pending Transaction"
          /> */}
        </>
      )}

      <Stack
        sx={{
          flexDirection: { xs: "columns", sm: "row" },
          justifyContent: "space-around",
          p: "10px",
        }}
      >
        <ActionCard
          actionMessage={newCallMsg}
          buttonText={getActionButtonText()}
          onClick={onGenerateActions}
          btnDisabled={loading}
          btnLoading={generateActionsLoading}
        />

        <ActionCard
          actionMessage={archiveMsg}
          buttonText="Confirm manually executed transactions"
          onClick={onConfirmTransaction}
          btnDisabled={loading || pendingStocks.length < 1}
        />

      </Stack>
    </Box>
  );
};

export default ChoosePortfolioAction;
