import React from "react";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";

import { formatHelper } from "helper/format";
import { withStyles } from "@mui/styles";

import InfoIcon from '@mui/icons-material/Info';
import TooltipHover from "components/common/TooltipHover"

const GetGainLoss = ({ profit, profitPercentage, loading }) => {
  const p = profit < 0;
  const color = p ? "#ED3833" : "#45AC70";
  const arrowIcon = p ? (
    <ArrowDropDownIcon sx={{ color: color }} />
  ) : (
    <ArrowDropUpIcon sx={{ color: color }} />
  );

  if (loading) {
    return <Text sx={{ fontSize: "16px" }}>Loading...</Text>;
  }
  return (
    <>
      {arrowIcon}
      <Text variant="body1" sx={{ color: color }}>
        {" "}
        {formatHelper.currency(Math.abs(profit))}{" "}
      </Text>
      {arrowIcon}
      <Text variant="small" sx={{ color: color }}>
        {Math.abs(profitPercentage || 0) + "%"}
      </Text>
      <TooltipHover
        title="The gains/ loss does not include brokerage , taxes and advisory fees."
        sx={{ lineHeight: 0, marginLeft: "3px" }}
      >
        <InfoIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
      </TooltipHover>
    </>
  );
};

class StatsContainer extends React.Component {
  componentDidMount() {
    this.props.getPortolioOverviewAction();
  }
  render() {
    const {
      heading,
      portfolioOverviewData,
      portfolioOverviewLoading,
      classes,
    } = this.props;

    return (
      <Stack className={classes.mainWrapper} direction="column">

        {/* NOTE: remove as per new design */}
        {/* Heading */}
        {/* <Text className={classes.heading} variant="body2">
          {heading}
        </Text> */}
        {/* <div className={classes.middleBorder} /> */}

        {/* Stats Values */}
        <Box sx={{ width: "100%", mb: "-32px", zIndex: 1 }}>
          <Stack className={classes.currentValueWrapper}>
            {/* Current & Invested Value */}
            <Stack sx={{ flexFlow: "row nowrap", alignItems: "flex-end" }}>
              <Box flex="2">
                <Text variant="subtitle2" sx={{ fontWeight: "600" }}>
                  {portfolioOverviewLoading
                    ? "Loading..."
                    : formatHelper.currency(portfolioOverviewData.currentValue)}
                </Text>
                <Text variant="caption1" sx={{ color: "#808591" }}>
                  Current value
                </Text>
              </Box>
              <Box flex="1">
                <Text variant="body2" sx={{ fontWeight: "500" }}>
                  {portfolioOverviewLoading
                    ? "Loading..."
                    : formatHelper.currency(
                      portfolioOverviewData.totalInvestment
                    )}
                </Text>
                <Text variant="caption1" sx={{ color: "#808591" }}>
                  Invested
                </Text>
              </Box>
            </Stack>

            <Box className={classes.midBorder} />

            {/* Gain/ Loss */}
            <Stack sx={{ flexDirection: "row" }}>
              <Text variant="caption1" sx={{ color: "#808591", flex: "1" }}>
                Gain/Loss
              </Text>
              <Stack className={classes.gainLossWrapper}>
                <GetGainLoss
                  profit={portfolioOverviewData.profit}
                  profitPercentage={portfolioOverviewData.profitPercentage}
                  loading={portfolioOverviewLoading}
                />
              </Stack>
            </Stack>
          </Stack>

          {/* holdings and Cash        */}
          <Stack sx={{ marginTop: "5px" }}>
            <Stack className={classes.holdingValueWrapper}>
              <Stack sx={{ flex: 2 }}>
                <Text sx={{ fontSize: "16px" }}>
                  {portfolioOverviewLoading
                    ? "Loading..."
                    : formatHelper.currency(portfolioOverviewData.holdingValue)}
                </Text>
                <Text variant="caption1" sx={{ color: "#808591" }}>
                  Holding
                </Text>
              </Stack>
              <Stack sx={{ flex: 1 }}>
                <Text sx={{ fontSize: "16px" }}>
                  {portfolioOverviewLoading
                    ? "Loading..."
                    : formatHelper.currency(portfolioOverviewData.cash)}
                </Text>
                <Text variant="caption1" sx={{ color: "#808591" }}>
                  Cash
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    );
  }
}

const style = (theme) => ({
  mainWrapper: {
    // background: "#2B70C5",
    margin: "-1px -16px 0 -16px;",
    marginTop:"10px",
    display: "flex",
    alignItems: "flex-start",
    padding: "0px 15px",
    // borderTop: "3px solid #2f7fde",
    marginBottom: "calc(16px + 32px)",
  },
  heading: {
    padding: "16px 0",
    fontWeight: "bold",
    textAlign: "left",
    color: "#ffffff",
  },
  middleBorder: {
    width: "calc(100% + 32px)",
    margin: "0 0px 12px -17px",
    borderBottom: "1px solid #2f7fde",
  },
  currentValueWrapper: {
    backgroundColor: "#fff",
    padding: "16px 16px",
    borderRadius: "10px",
    border: "1px solid #eef0f2",
  },
  midBorder: {
    margin: "16px -13px",
    borderBottom: "1px solid #F5F5F5",
    width: "calc(100% + 26px)",
  },
  gainLossWrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  holdingValueWrapper: {
    flexDirection: "row",
    backgroundColor: "#fff",
    border: "1px solid #EEF0F2",
    padding: "15px",
    borderRadius: "10px",
  },
});

export default withStyles(style)(StatsContainer);
