import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { dashboardActions } from "stores/dashboard/dashboardActions"
const mapStateToProps = (state) => {
    return {
        portfolioChart: state.dashboard.portfolioChart
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPortfolioChartAction: dashboardActions.getPortfolioChart, 
}, dispatch)

const PerformanceChartStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default PerformanceChartStore;