import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";
import { thirdpartyActions } from "stores/thirdparty/thirdpartyActions";

const mapStateToProps = (state) => {
    return {
        profile: state.customer.profile,
        isProfileLoading: state.customer.loading.profile,
        productSubscriptionList: state.customer.productSubscriptionList,
        isproductSubscriptionListLoading: state.customer.loading.productSubscriptionList,
        renewalPricingDetails: state.customer.renewalPricingDetails,
        isLoading: state.customer.loading.renewalPricingDetails,

        subscriptionPlans: state.thirdparty.subscriptionPlans,
        isSubscriptionPlansLoading: state.thirdparty.loading.subscriptionPlans,
        isSubscriptionPlansCalled: state.thirdparty.isInitialCalled.subscriptionPlans,

        isRMSOnboardingCompleted: state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
        isPortfolioOnboardingCompleted: state.onboarding.isOnboardingCompleted,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getProfileDataAction: customerActions.getProfile,
        getRenewSubscriptionAction: customerActions.getRenewalPricingDetails,
        getSubscriptionPlansAction: thirdpartyActions.getSubscriptionPlans,
        getProductsSubscriptionAction: customerActions.getProductsSubscriptionList,
    },
    dispatch
);

const profileStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default profileStore;