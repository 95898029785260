import React from 'react';
import IconButton from 'components/common/IconButton';
import SectionTitle from 'components/SectionTitle';
import Drawer from "../common/Drawer";
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import Image from "components/common/Image";
import { withRouter } from "react-router-dom";
import { Close as CloseIcon } from "@mui/icons-material";

const UpiDrawer = (props) => {
    
    const {
        open = false,
        onClose,
    } = props;

    const upiApps = [
        {
            upiAppTitle: "Google Pay",
            upiAppIcon: "/images/onestock/gpay.png",
            forAndroid: [
                "Open Google Pay App.",
                "Tap on your profile picture on top right corner of the screen.",
                "You will find your UPI ID below your phone number.",
            ],
        },
        {
            upiAppTitle: "PhonePe",
            upiAppIcon: "/images/onestock/phone-pay.png",
            forAndroid: [
                "Open PhonePe App.",
                "Tap on your profile picture on top left corner of the screen.",
                "Tap on My UPI ID to find your UPI ID.",
            ],
        },
        {
            upiAppTitle: "Paytm",
            upiAppIcon: "/images/onestock/paytm.png",
            forAndroid: [
                "Open Paytm App.",
                "Tap on your profile picture on top left corner of the screen.",
                "You will find your UPI ID below your name.",
            ],
        },
        {
            upiAppTitle: "BHIM App",
            upiAppIcon: "/images/onestock/bhim-app.png",
            forAndroid: [
                "Open BHIM App.",
                "You will find your UPI ID on top left corner of the screen.",
            ],
        },
        {
            upiAppTitle: "Amazon Pay",
            upiAppIcon: "/images/onestock/amazon-pay.png",
            forAndroid: [
                "Open the Amazon Pay app.",
                "Swipe left to open the menu.",
                "Go to the Amazon Pay section.",
                "Click on Amazon Pay UPI.",
                "Your Amazon Pay UPI ID will look like this: 123456789@apl.",
            ],
        },
        {
            upiAppTitle: "Freecharge",
            upiAppIcon: "/images/onestock/freecharge.png",
            forAndroid: [
                "Open the Freecharge app.",
                "Go to the top left corner of the app home page.",
                "Your Freecharge UPI ID will look like this: mobilenumber@freecharge.",
            ],
        },
        {
            upiAppTitle: "Mobikwik",
            upiAppIcon: "/images/onestock/mobiki.png",
            forAndroid: [
                "Open the Mobikwik app.",
                "Tap the Bank Account button on the top right.",
                "Your Mobikwik ID will look like this: yourmobilenumber@ikwik.",
            ],
        },
    ]

    return (
        <Drawer open={open} anchor="right" >
            <Stack>
                <Stack py={0.5} px={1.5} sx={{ background: "#F6F8F9" }}>
                    <SectionTitle
                        heading="How to find UPI ID"
                        endContent={() => <IconButton onClick={onClose}><CloseIcon /></IconButton>}
                    />
                </Stack>
                <Stack py={2} px={3}>
                    {upiApps.map((app) => {
                        return (
                            <Stack mb={2} >
                                <Stack direction="row">
                                    <Stack mt={-0.5} mr={1}>
                                        <Image src={app.upiAppIcon} width={20} />
                                    </Stack>
                                    <Text variant="body1" sx={{ fontWeight: "500" }}>{app.upiAppTitle}</Text>
                                </Stack>
                                {app.forAndroid.map((data, index) => (
                                    <Stack mt={1} ml={1}>
                                        <Text variant="small" sx={{ color: "#676E76" }}>
                                            {`${index + 1}. ${data}`}
                                        </Text>
                                    </Stack>
                                ))}
                            </Stack>
                        )
                    })}
                </Stack>
            </Stack>
        </Drawer >
    )
}

export default withRouter(UpiDrawer);