import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisRMSExistingHoldingConstants } from "./jarvisRMSExistingHoldingConstants";


const initialState = {
	portfolioList: [],
	stockList:[],
    loading: {
        portfolioList: 0,
        stockList: 0,
    },
    initialCalled: {
        portfolioList: 0,
        stockList: 0,
    }
};

export const jarvisRMSExistingHoldingReducer = persistReducer(
	{
		storage,
		key: "rmsExistingHolding",
		whitelist: [],
	},

	(state = initialState, { type, payload }) => {
		switch (type) {
			case jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfolioList: 1,
                    },
                    portfolioList: state.portfolioList,
                };
            }

            case jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfolioList: 0,
                    },
                    initialCalled: {
                        ...state.initialCalled,
                        portfolioList: 1,
                    },
                    portfolioList: payload,
                };
            }

			// STOCK LIST

			case jarvisRMSExistingHoldingConstants.STOCK_LIST_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        stockList: 1,
                    },
                    stockList: initialState.stockList,
                };
            }

            case jarvisRMSExistingHoldingConstants.STOCK_LIST_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        stockList: 0,
                    },
                    initialCalled: {
                        ...state.initialCalled,
                        stockList: 1,
                    },
                    stockList: payload,
                };
            }
            case jarvisRMSExistingHoldingConstants.JARVIS_RMS_RESET_EXISTING_HOLDING_DATA : {
                return initialState
            }
			
			default:
				return state;
		}
	}
);
