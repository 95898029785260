import React from "react";
import _get from "lodash.get";

import Text from "components/common/Text";
import Loader from "components/common/Loader";

import { config } from "config";
import { injectHelper } from "helper/inject";
import { thirdpartyParsers } from "stores/thirdparty/thirdpartyParsers";
import { tracker } from "library/tracker";

let scgateway;
const ERROR_MESSAGES = [
    {
        "code": "user_cancelled",
        "message": "User has closed the order journey.",
    },
    {
        "code": "market_closed",
        "message": "Try connecting to your preferred broker during market hours.",
    },
    {
        "code": "order_pending",
        "message": "Last order by the user is still pending",
    },
    {
        "code": "transaction_expired",
        "message": "Previous transaction is expired",
    },
]

class BrokerSmallCaseContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        const brokerName = _get(this.props.location, "state.brokerName", "");
        const brokerPlatform = _get(this.props.location, "state.brokerPlatform", "");
        const processType = _get(this.props.location, "state.processType", "");
        const productCode = _get(this.props.location, "state.productCode", "");
        const brokerId = _get(this.props.location, "state.brokerId", "");
        const onestockCallId = _get(this.props.location, "state.onestockCallId", "");
        const stockQuantity = _get(this.props.location, "state.stockQuantity", null);

        if (
            brokerName ||
            brokerPlatform ||
            brokerPlatform !== config.broker.platform.smallcase ||
            (productCode === config.productCodes.PORTFOLIO && processType) ||
            (productCode === config.productCodes.ONESTOCK && onestockCallId)) {
            this.initProcess({ brokerName, brokerPlatform, processType, productCode, brokerId, onestockCallId, stockQuantity });
        } else {
            this.props.onError({ reason: 'No Broker selected', origin: "componentDidMount > not found = brokerName | brokerPlatform | processType" });
        }
    }

    initProcess = async ({ brokerName, brokerPlatform, processType, productCode, brokerId, onestockCallId, stockQuantity }) => {
        try {
            this.setState({ isLoading: true });

            let token, transactionId;
            if (productCode === "RMS") {
                const resp = await this.props.fetchHoldingData({ brokerName, brokerPlatform, productCode, brokerId });
                token = resp.token;
                transactionId = resp.transactionId;
            } else {
                const resp = await this.props.getPreOrderData({ brokerName, brokerPlatform, processType, productCode, onestockCallId, stockQuantity })
                token = resp.token;
                transactionId = resp.transactionId;
            }

            if (config.isMobileApp) {
                await this.initSmallCaseMobile({ token, transactionId, brokerName, productCode });
            } else {
                await this.initSmallCase({ token, transactionId, brokerName, productCode });
            }

        } catch (error) {
            this.props.onError({ reason: "Something went wrong! Please try again later!", origin: "initProcess > catch block", error });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    initSmallCase = async ({ token, transactionId, brokerName, productCode }) => {
        try {
            await injectHelper.script({ src: config.broker.smallcase.scriptURI });

            const scGateway = new window.scDK({
                smallcaseAuthToken: token,
                gateway: config.broker.smallcase.gateway,
                config: config.broker.smallcase.config
            });

            const response = await scGateway.triggerTransaction({ transactionId, brokers: [] });
            const platformResponse = thirdpartyParsers.smallCaseTransaction(response);
            //NOTE : event_tracker => broker
            tracker.broker.brokerLoginSuccess({ broker_name: brokerName });
            scGateway.brokerLogout();
            this.props.onSuccess({ platformResponse, transactionId });
        } catch (error) {
            //NOTE : event_tracker => broker
            tracker.broker.brokerLoginFailed({ broker_name: brokerName, error_msg: error.message });
            let reasonMesg = ""
            if (error && error.message) {
                reasonMesg = ERROR_MESSAGES.find((m) => m.code === error.message);
            }
            console.log("Holding error => ", error)
            this.props.onError({ reason: reasonMesg ? reasonMesg.message : "Something went wrong! Please try again later!", origin: "initSmallCase > catch block", error });
        }
    }

    initSmallCaseMobile = async ({ token, transactionId, brokerName }) => {
        try {
            scgateway = window.scgateway;

            const initSc = () => new Promise((resolve, reject) => {
                const environment = scgateway.ENVIRONMENT.PRODUCTION;
                const gateway = config.broker.smallcase.gateway;
                const leprechaunEnabled = config.broker.smallcase.config.leprechaunEnabled;
                const amoEnabled = config.broker.smallcase.config.amo;
                const brokersList = [];

                scgateway.setConfigEnvironment(
                    function (data) {
                        //success callback
                        //environment setup complete, proceed with initialisation
                        scgateway.initSCGateway(function (data) {
                            //success
                            //NOTE : event_tracker => broker
                            tracker.broker.brokerLoginSuccess({ broker_name: "" });
                            resolve(data);
                        }, function (error) {
                            console.log("smallcase 1", error)
                            reject("error while initializing small case")
                            //NOTE : event_tracker => broker
                            tracker.broker.brokerLoginFailed({ broker_name: "", error_msg: error.message });
                        }, [token]);
                    },
                    function (error) {
                        //NOTE : event_tracker => broker
                        tracker.broker.brokerLoginFailed({ broker_name: "", error_msg: error.message });
                        console.log("smallcase 2", error)
                        reject("error while sending config to small case")
                    }, [environment, gateway, leprechaunEnabled, brokersList, amoEnabled]
                );
            });

            const triggerTransactionSC = () => new Promise((resolve, reject) => {
                scgateway.triggerTransaction(function (data) {
                    //success
                    resolve(data)
                }, function (error) {
                    //error
                    console.log("smallcase 3", error)
                    if(error && error.errorMessage){
                        reject({message: error.errorMessage})    
                    }else{
                        reject({message: "error while triggering small case"})
                    }
                }, [transactionId]);
            });



            await initSc();
            const response = await triggerTransactionSC();
            if (response.success || (response.data && response.data.transactionId)) {
                const smResponse = { orderBatches: response.data.data.batches };
                const platformResponse = thirdpartyParsers.smallCaseTransaction(smResponse);
                this.props.onSuccess({ platformResponse, transactionId });
                scgateway.logout();
            } else {
                let reasonMesg = "";
                if (response && response.error) {
                    const errorObject = ERROR_MESSAGES.find((m) => m.code === response.error);

                    if (errorObject) {
                        reasonMesg = errorObject.message;
                    } else {
                        reasonMesg = response.error;
                    }
                } else {
                    reasonMesg = "Something went wrong! Please try again later!";
                }
                this.props.onError({ reason: reasonMesg, origin: "initSmallCase > user error", error: {} });
            }
            
        } catch (error) {
            console.log("Error msg => ", error);
            let reasonMesg = "";
            if (error && error.message) {
                const errorObject = ERROR_MESSAGES.find((m) => m.code === error.message);

                if (errorObject) {
                    reasonMesg = errorObject.message;
                } else {
                    reasonMesg = error.message;
                }
            } else {
                reasonMesg = "Something went wrong! Please try again later!";
            }
            this.props.onError({ reason: reasonMesg, origin: "initSmallCase > catch block", error });
            // this.props.onError({ reason: "Something went wrong! Please try again later!", origin: "initSmallCase > catch block", error });
        }
    }

    render() {
        const { isLoading } = this.state;
        const brokerName = _get(this.props.location, "state.brokerName", "");

        return (
            <>
                <Loader loading={isLoading} />

                <Text>{brokerName ? `Selected broker: ${brokerName.toUpperCase()}` : `No Broker Selected`}</Text>
            </>
        )
    }
}

export default React.memo(BrokerSmallCaseContainer);