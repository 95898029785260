import { axios, axiosNT } from "helper/axios";
import { localStore } from "stores/localStorage";
import { masterConstants } from "./masterConstants";

const masterApis = {};

masterApis.getRMSRiskAssessmentQuestions = () => {
    return axios.get(masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_API);
};

masterApis.getRMSRiskProfiles = () => {
    return axios.get(masterConstants.RMS_RISK_PROFILES_API);
};

masterApis.getStockList = () => {
    let queryParam = `?search=${""}`
    return axios.get(`${masterConstants.RMS_FETCH_STOCKS}${queryParam}`);
};

masterApis.verfyAutoKYC = ({ dateOfBirth, panNumber }) => {
    return axios.post(masterConstants.VERIFY_AUTO_KYC_API, {
        dateOfBirth,
        panNumber,
    });
};

masterApis.verifyKycDocument = ({ panCard,
    panCard_fileName,
    aadharCardFront,
    aadharCardFront_fileName,
    aadharCardBack,
    aadharCardBack_fileName, }) => {

    const fd = new FormData();
    if (panCard && panCard_fileName) {
        fd.append("panCard", panCard, panCard_fileName);
    }

    if (aadharCardFront && aadharCardFront_fileName) {
        fd.append("aadharCardFront", aadharCardFront, aadharCardFront_fileName);
    }

    if (aadharCardBack && aadharCardBack_fileName) {
        fd.append("aadharCardBack", aadharCardBack, aadharCardBack_fileName);
    }

    return axios.post(masterConstants.VERIFY_KYC_DOCUMENT_API, fd);
};

masterApis.verifyManualKyc = ({ dateOfBirth, panNumber, aadhaarNumber, productCode }) => {
    return axios.post(masterConstants.VERIFY_MANUAL_KYC_API, {
        dateOfBirth,
        panNumber,
        aadhaarNumber,
        productCode,
    });
};

masterApis.getMasterBrokers = ({ isFetchHoldingEnabled, isIntegrated, searchTerm, productCode } = {}) => {
    const params = {};

    if (searchTerm) {
        params["search"] = searchTerm;
    }

    if (![null, undefined].includes(isIntegrated)) {
        params["isIntegrated"] = isIntegrated;
    }

    if (![null, undefined].includes(isFetchHoldingEnabled)) {
        params["isFetchHoldingEnabled"] = isFetchHoldingEnabled;
    }

    if (productCode) {
        params["productCode"] = productCode;
    }

    return axios.get(masterConstants.MASTER_BROKER_API, { params });
}

// masterApis.getOnestockMasterBrokers = ({ isFetchHoldingEnabled, isIntegrated, searchTerm, productCode } = {}) => {
//     const params = {};

//     if (searchTerm) {
//         params["search"] = searchTerm;
//     }

//     if (![null, undefined].includes(isIntegrated)) {
//         params["isIntegrated"] = isIntegrated;
//     }

//     if (![null, undefined].includes(isFetchHoldingEnabled)) {
//         params["isFetchHoldingEnabled"] = isFetchHoldingEnabled;
//     }

//     if (productCode) {
//         params["productCode"] = productCode;
//     }

//     return axios.get(masterConstants.ONESTOCK_MASTER_BROKER_API, { params });
// }

masterApis.validateOffer = ({ offerCode, productCode, planId, offerType }) => {
    return axios.post(masterConstants.VALIDATE_OFFER_API, { offerCode, productCode, planId, offerType });
}

masterApis.updateLastActiveProduct = ({ productCode } = {}) => {
    if (!productCode) {
        productCode = localStore.getActiveProduct();
        localStore.resetActiveProduct();
    }
    return axios.post(masterConstants.UPDATE_LAST_ACTIVE_PRODUCT_API, { productCode });
}

masterApis.getAppliedCampaignOffer = ({ productCode, planId, offerType }) => {
    return axiosNT.post(masterConstants.APPLIED_CAMPAIGN_OFFER_API, { productCode, planId, offerType });
}

masterApis.getPendingCall = () => {
    return axios.get(masterConstants.PENDING_CALL_API);
};

masterApis.fetchPlanDiscountedPriceOnUpgrade = async ({
    planId = '',
    productCode = '',
    investMoreAmount ='',
})=>{
    const headers ={
        "Content-Type":"application/json"
    }
    let payload={
        newPlanId:planId,
        productCode,
    }
    if(investMoreAmount){
        payload.investMoreAmount = investMoreAmount;
    }
    payload = JSON.stringify(payload);
    return await axios.post(masterConstants.UPGRADTION_AMOUNT_API,payload,{headers})

}

export { masterApis };
