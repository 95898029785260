import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { commonActions } from "stores/common/commonActions"

const mapStateToProps = (state) => {
    return {
        blogsData: state.common.blogs,
        isLoading: state.common.loading.blogs,
        isInitialCalled: state.common.initialCalled.blogs,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getBlogsAction: commonActions.getBlogs
}, dispatch)

const BlogsStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default BlogsStore;