import React from 'react';
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import { formatHelper } from 'helper/format';
import { config } from 'config/index';

const planTypes = {
    "Monthly": "month",
    "Half-Yearly": "six months",
    "Quarterly": "four months",
    "Yearly": "year",
}

const subscriptionFeesLabel = {
    "Monthly": "Monthly subscription fee",
    "Half-Yearly": "Half yearly subscription fee",
    "Quarterly": "Quarterly subscription fee",
    "Yearly": "Yearly subscription fee",
}

const offerDiscountLabel = {
    "Monthly": "First $offerIteration month$plural subscription fee",
    "Half-Yearly": "First $offerIteration billing cycle$plural fee",
    "Quarterly": "First $offerIteration billing cycle$plural fee",
    "Yearly": "First $offerIteration billing cycle$plural fee",
}

const remainingDiscountLabel = {
    "Monthly": "$count month onwards",
    "Half-Yearly": "$count billing cycle onwards",
    "Quarterly": "$count billing cycle onwards",
    "Yearly": "$count billing cycle onwards",
}

const PaymentSummary = ({ selectedPlan, planType, appliedCouponData, paymentType,upgradePlanAmount }) => {
    let payableAmount = paymentType === 'UPGRADE' && upgradePlanAmount?upgradePlanAmount:selectedPlan?.planPrice;
    if (selectedPlan?.planDiscountedPrice) {
        payableAmount = selectedPlan.planDiscountedPrice;
    }
    if (appliedCouponData.isValid) {
        payableAmount = appliedCouponData.discountedPrice;
    }

    const { offerIteration } = appliedCouponData
    
    // const productType = selectedPlan?.productMap[0].productCode
    let amountMb = 14

    // if(productType === config.productCodes.PORTFOLIO){
    //    amountMb = 14
    // }

    return (
        <>
            <Stack py={2} sx={{ borderBottom: "1px solid #d3d3d3" }}>
                <Stack mb={1.2} direction="row" justifyContent="space-between">
                    {appliedCouponData.isValid === true ?
                        <Text sx={{ fontWeight: 500, fontSize: { xs: "13px", md: "17px" } }}>{`${offerDiscountLabel[planType]}`.replace('$offerIteration', offerIteration > 1 ? offerIteration : '').replace('$plural', offerIteration > 1 ? 's' : '')}</Text>
                        :
                        <Text sx={{ fontWeight: 500, fontSize: { xs: "13px", md: "17px" } }}>{subscriptionFeesLabel[planType]}</Text>
                    }
                    <Text sx={{ fontWeight: "500", fontSize: { xs: "13px", md: "17px" } }}>
                        {formatHelper.currency(paymentType === 'UPGRADE' && upgradePlanAmount?upgradePlanAmount:selectedPlan?.planPrice)}/{planTypes[planType]}
                    </Text>
                </Stack>

                {appliedCouponData.isValid ? (
                    <Stack mb={1} direction="row" justifyContent="space-between">
                        <Text sx={{ fontWeight: 500, fontSize: { xs: "13px", md: "17px" } }}>
                            Coupon discount
                        </Text>
                        <Text color="#45AC70" sx={{ fontSize: { xs: "13px", md: "17px" } }}>
                            {`-${formatHelper.currency(appliedCouponData.discountPrice)}/${planTypes[planType]}`}
                        </Text>
                    </Stack>
                ) : (
                    <Stack mb={1} direction="row" justifyContent="space-between">
                        <Text sx={{ fontWeight: 500, fontSize: { xs: "13px", md: "17px" } }}>
                            {selectedPlan?.planDiscountPrice ? `Offer discount for the first ${planTypes[planType]}` : `Offer discount`}
                        </Text>
                        <Text color="#45AC70" sx={{ fontSize: { xs: "13px", md: "17px" } }}>
                            {!selectedPlan?.planDiscountPrice ? 'NA' : `-${formatHelper.currency(selectedPlan?.planDiscountPrice)}/${planTypes[planType]}`}
                        </Text>
                    </Stack>
                )}
            </Stack>

            {appliedCouponData.isValid ? (
                <Stack direction={'column'} py={2} sx={{ borderBottom: "0px solid #d3d3d3" }}>
                    <Stack mb={1.2} direction="row" justifyContent="space-between" sx={{ textAlign: 'right' }}>
                        <Text sx={{ fontWeight: 500, fontSize: { xs: "13px", md: "17px" } }}>{`${offerDiscountLabel[planType]}`.replace('$offerIteration', offerIteration > 1 ? offerIteration : '').replace('$plural', offerIteration > 1 ? 's' : '')}</Text>
                        <Text sx={{ fontWeight: "500", fontSize: { xs: "13px", md: "17px" } }}>
                            {formatHelper.currency(payableAmount)}/{planTypes[planType]}
                            <br></br>
                            <Text variant="caption1" sx={{ fontWeight: 700 }}>Incl. {selectedPlan?.planGSTPercentage}% GST</Text>
                        </Text>
                    </Stack>
                    <Stack mb={config.isMobileApp ? amountMb:1.2} direction="row" justifyContent="space-between" sx={{ textAlign: 'right' }}>
                        <Text sx={{ fontSize: { xs: "13px", md: "17px" } }}>{`${remainingDiscountLabel[planType]}`.replace('$count', `${offerIteration + 1}${formatHelper.nth(offerIteration + 1)}`)}</Text>
                        <Text sx={{ fontSize: { xs: "13px", md: "17px" } }}>
                            {/* {formatHelper.currency(paymentType === 'UPGRADE' && upgradePlanAmount?upgradePlanAmount:selectedPlan?.planPrice)}/{planTypes[planType]} */}
                            {formatHelper.currency(selectedPlan?.planPrice)}/{planTypes[planType]}
                        </Text>
                    </Stack>
                </Stack>
            ) : (
                <Stack direction={'column'} py={2} sx={{ borderBottom: "0px solid #d3d3d3" }}>
                    <Stack mb={config.isMobileApp ? amountMb : 1.2} direction="row" justifyContent="space-between" sx={{ textAlign: { xs: "center", md: 'right' } }}>
                        <Text sx={{ fontWeight: 500, fontSize:{xs:"13px", md:"17px"} }}>Amount payable</Text>
                        <Text sx={{ fontWeight: "500", fontSize:{xs:"13px", md:"17px"} }}>
                            {formatHelper.currency(payableAmount)}/{planTypes[planType]}
                            <br></br>
                            <Text variant="caption1" sx={{ fontWeight: 700 }}>Incl. {selectedPlan?.planGSTPercentage}% GST</Text>
                        </Text>
                    </Stack>
                </Stack>
            )
            }
        </>
    );
}

export default PaymentSummary;