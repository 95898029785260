import React from "react";

import Stack from "components/common/Stack";
import Grid from "components/common/Grid";

import SectionTitle from "components/SectionTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/common/IconButton";

import JarvisButton from "components/JarvisButton";
import StockCard from "components/StockCard";
import Box from "components/common/Box";
import Text from "components/common/Text";
import { config } from "config";

import SectorDiversification from "components/Onboarding/SectorDiversification";
import MarketCapDiversification from "components/Onboarding/MarketCapDiversification";
import OfflineCheckerButton from "components/OfflineCheckerButton";
import { formatHelper } from "helper/format";
import ImageComponent from "components/common/Image";
import { localStore } from "stores/localStorage";

const proceedBtnText = {
	3: "Proceed to CKYC",
	4: "Proceed to client agreement",
	5: "Proceed to broker",
}

const ViewPortfolio = ({
	onStartInvestingClick,
	onUnlockPortfolioClick,
	// onBackTestingClick,
	investmentInfo,
	selectedPlan,
	portfolioData,
	isPaymentCompleted,
	marketBreakupData,
	sectorBreakupData,
	currentStage,
	onBackClick
}) => {

	// added 3rd data as blurry
	if (!isPaymentCompleted && portfolioData && portfolioData.length === 2) {
		portfolioData.push(portfolioData[0]);
	}

	// Blurry Data list
	let blurryPortfolioList = Array(9).fill({
		marketCap: "Market Cap",
		currentValue: 111,
		riskStatus: "",
		sector: "Sector",
		stockName: "Stock Name",
		stockQuantity: 10,
		symbol: "SN",
		allocation: 3,
	});
	const proceedBttonText = (currentStage)=>{
		if(localStore.isSsoCustomer() && localStore.getSsoCustomerDetails()?.brokerName === config.broker.ssoPlatform.centrum && currentStage === 3){
			return "Proceed to client agreement";
		}
		else{
			return proceedBtnText[currentStage]?proceedBtnText[currentStage]:proceedBtnText[3]
		}
	}

	return (
		<Stack sx={{ mt: { xs: 3, md: 0 }, mb: 3 }}>
			{/* Heading */}
			<Stack
				sx={{
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				 <SectionTitle
                        sx={{ display: { xs: "flex", md: 'none' } }}
                        heading="Portfolio Stocks &amp; Weights "
                        startIcon={
                            <IconButton onClick={() => { onBackClick()}}>
                                <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                            </IconButton>
                        }

                    />
					<Box  sx={{ display: { xs: "none", md: 'flex' } }}></Box>
				{portfolioData.length && isPaymentCompleted ? (
					<OfflineCheckerButton 
						offlineButtonProps={{
							sx: {
								padding: "8px 20px",
								fontSize: "14px",
								"@media(max-width: 450px)": {
									display: "none",
								},
							},
							text: "Start Investing",
							onClick: currentStage === 5 ? undefined : onStartInvestingClick,
						}}
						onlineButtonProps={{
							sx: {
								padding: "8px 20px",
								fontSize: "16px",
								marginBottom:'15px',
								"@media(max-width: 450px)": {
									display: "none",
								},
							},
							text: proceedBttonText(currentStage),
							onClick: onStartInvestingClick,
						}}
					/>
				) : null}
			</Stack>

			{/* Portfolio not generated MSG */}
			{!portfolioData.length ? (
				<Text>Portfolio data is not generated...</Text>
			) : null}


			{/* Portfolio generated*/}
			{portfolioData.length ? (
				<>
					{/* Before Payment */}
					{!isPaymentCompleted ?
						<>
							{/* 2 portfolio data */}
							<Grid container >
								{portfolioData.slice(0, 3).map((p, i) => (
									<Grid
										item
										key={`stock_${i}`}
										xs={12} sm={6} md={4}
										sx={{
											display: {
												xs: `${i === 2 ? "none" : "flex"}`,
												md: "flex"
											}
										}}
									>
										<Box sx={{
											border: `1px solid #e2e2e2`,
											borderTopLeftRadius: i === 0 ? 8 : 0,
											borderTopRightRadius: {
												xs: i === 0 ? 8 : 0,
												sm: i === 1 ? 8 : 0,
												md: i === 2 ? 8 : 0,
											},
											width: "100%",
											overflow: "hidden",
										}}>
											<StockCard
												sx={{
													backgroundColor: "background.paper",
													borderRightColor: {
														xs: "#e2e2e2",
														sm: i === 0 ? "transparent" : `#e2e2e2`,
													},
													filter: !isPaymentCompleted && i === 2 ? "blur(9px)" : "",
												}}
												cardType="INITIAL-PORTFOLIO"
												stockDetails={{
													marketCap: p.marketCap,
													currentPrice: p.currentValue,
													buyPrice: p.currentValue,
													riskStatus: "",
													sector: p.sector,
													stockName: p.stockName,
													quantity: p.stockQuantity,
													symbol: p.symbol,
													allocationPercentage: Number(p.allocation).toFixed(2),
													hasBlureValues: true
												}}
											/>
										</Box>
									</Grid>
								))}
							</Grid>

							{/* portfolio Blurry data */}
							<Box sx={{
								position: "relative",
								border: `1px solid #e2e2e2`,
								borderTop: 0,
								overflow: "hidden",
								borderBottomLeftRadius: 8,
								borderBottomRightRadius: 8,
							}}>
								<Grid container>
									{blurryPortfolioList.map((p, i) => (
										<Grid
											item
											key={`stock_${i}`}
											xs={12} sm={6} md={4}
											sx={{
												display: {
													xs: `${i > 2 ? "none" : "flex"}`,
													sm: `${i > 5 ? "none" : "flex"}`,
													md: "flex"
												}
											}}
										>
											<StockCard
												sx={{
													filter: isPaymentCompleted ? "" : "blur(9px)",
													backgroundColor: "background.paper",
													border: `1px solid #e2e2e2`,
													width: "100%"
												}}
												cardType="INITIAL-PORTFOLIO"
												stockDetails={{
													cap: p.marketCap,
													currentPrice: p.currentValue,
													buyPrice: p.currentValue,
													riskStatus: "",
													sector: p.sector,
													stockName: p.stockName,
													quantity: p.stockQuantity,
													symbol: p.symbol,
													allocationPercentage: Number(p.allocation).toFixed(2),
												}}
											/>
										</Grid>
									))}

									{/* Unlock portfolio popup  */}
									<Stack
										justifyContent="center"
										alignItems="center"
										sx={{
											position: "absolute",
											top: "50%",
											left: "50%",
											transform: "translateX(-50%) translateY(-50%)",
											p: "32px 15px",
											borderRadius: 2.5,
											boxShadow: { xs: "none", sm: "0 3px 26px #00000017" },
											backgroundColor: { xs: "transperant", sm: "background.paper" },
											textAlign: "center",
											width: "460px",
											maxWidth: "calc(100% - 30px)",
										}}
									>
										<ImageComponent src="/unlock-portfolio.svg" width="43px" />
										<Box sx={{ mt: 2.25, mb: 4 }}>
											<Text
												variant="body1"
												mb={"20px"}
												sx={{ color: "#000", fontWeight: "500", fontSize: "20px" }}
											>
												Unlock Portfolio!
											</Text>
											<Text variant="body2" sx={{ maxWidth: "370px", m: "0 auto", color: "gray" }}>
												Jarvis has created an AI-powered portfolio, all you need to do is pay {formatHelper.currency(selectedPlan?.planDiscountedPrice || selectedPlan?.planPrice)} to reveal the full portfolio. Unlock now!
											</Text>
										</Box>
										<JarvisButton
											onClick={onUnlockPortfolioClick}
											sx={{ padding: "6px 25px" }}
										>
											<Text
												sx={{
													whiteSpace: "nowrap",
													textTransform: "unset !important",
													typography: { xs: "small", md: "button" },
												}}
											>
												Unlock now
											</Text>
										</JarvisButton>
									</Stack>


								</Grid>
							</Box>
						</>
						: null
					}


					{/* After Payment */}
					{isPaymentCompleted ? (
						<Box sx={{
							overflow: "hidden",
							border: "1px solid #dddddd",
							borderRadius: "8px",
						}}>
							{/* All Portfoli data */}
							<Grid container>
								{portfolioData.map((p, i) => (
									<Grid
										item
										key={`stock_${i}`}
										xs={12} sm={6} md={4}
									>
										<StockCard
											sx={{
												backgroundColor: "background.paper",
												width: "100%",
												borderRight: `1px solid #e2e2e2`,
												borderBottom: `1px solid #e2e2e2`,
											}}
											cardType="INITIAL-PORTFOLIO"
											stockDetails={{
												cap: p.marketCap,
												currentPrice: p.currentValue,
												buyPrice: p.currentValue,
												riskStatus: "",
												sector: p.sector,
												stockName: p.stockName,
												quantity: p.stockQuantity,
												symbol: p.symbol,
												allocationPercentage: Number(p.allocation).toFixed(2),
											}}
										/>
									</Grid>
								))}
							</Grid>
						</Box>
					) : null}

					{/* Graph: Sector & Market */}
					<Grid container spacing={2} mt={2}>
						<Grid item xs={12} md={6} mb={4}>
							<SectorDiversification chartData={sectorBreakupData} />
						</Grid>
						<Grid item xs={12} md={6} mb={6}>
							<MarketCapDiversification chartData={marketBreakupData} />
						</Grid>
					</Grid>
					
					{/* Button: Start Investing */}
					{isPaymentCompleted ? (
						<Stack
							sx={{ mb: { xs: 6, md: 2 } }}
							direction="row"
							justifyContent="center"
							spacing={2}
						>
							{/* <JarvisButton variant="outlined" onClick={onBackTestingClick}>Back Testing</JarvisButton> */}
							<OfflineCheckerButton
								offlineButtonProps={{
									text: "Start Investing",
									onClick: currentStage === 5 ? undefined : onStartInvestingClick,
								}}
								onlineButtonProps={{
									text: proceedBttonText(currentStage),
									onClick: onStartInvestingClick,
								}}
							/>
						</Stack>
					) : null}

				</>
			) : null}
		</Stack>
	);
};

export default React.memo(ViewPortfolio);
