import React from "react";
import AppWelcomeScreenContainer from "containers/AppWelcomeScreen";
import { tracker } from "library/tracker";

const AppWelcomeScreen = () => {

    React.useEffect(() => {
        tracker.pageView();
    }, []);

    return (
        <AppWelcomeScreenContainer />
    )

};

export default AppWelcomeScreen;
