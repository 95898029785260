import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import Stack from "components/common/Stack";
import Text from "components/common/Text";

const SectionTitle = ({
    heading,
    sxHeading = {},
    subheading = "",
    endContent = () => { },
    sx,
    startIcon,
}) => {
    const classes = useStyles();
    return (
        <Stack sx={sx}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
            >
                {startIcon ? startIcon : null}
                <Stack className={classes.contentWrapper}>
                    {heading ? (
                        <Text
                            sx={{
                                typography: {
                                    xs: "subtitle2",
                                    md: "subtitle2",
                                },
                                ...sxHeading
                            }}
                            className={`${classes.headingText}`}>
                            {heading}
                        </Text>
                    ) : null}
                    {subheading ? (
                        <Text
                            variant="small"
                            className={`${classes.subHeadingText}`}>
                            {subheading}
                        </Text>
                    ) : null}
                </Stack>

                <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ display: { xs: "flex" } }}
                >
                    {endContent()}
                </Stack>
            </Stack>
        </Stack>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        headingText: {
            color: "#222B3E !important",
            fontWeight: "bold !important",
            lineHeight: "1.25 !important",
        },
        subHeadingText: {
            color: "#808591 !important",
            lineHeight: "1.25 !important",
        },

        contentWrapper: {
            flex: 1,
        },
    })
);

export default SectionTitle;
