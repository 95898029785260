import * as React from 'react';
import Stack from './Stack';
import Text from './Text';
import Image from "components/common/Image";
import { useHistory, withRouter } from "react-router-dom";
import JarvisButton from 'components/JarvisButton';

import { localStore } from "stores/localStorage";

const NotAccessForPlan = ({ productCode = "" }) => {

    const history = useHistory();

    let handleSubmitForPortfolio = () => {
        history.push("/jarvis-portfolio/dashboard");
    }
    let handleSubmitForProtect = () => {
        history.push("/jarvis-rms/dashboard");
    }
    let handleSubmitForOnestock = () => {
        history.push("/jarvis-onestock/dashboard");
    }

    if (!productCode) {
        const activeProduct = localStore.getActiveProduct();
        productCode = activeProduct;
    }

    return (
        <>
            {
                productCode === "PORTFOLIO" ?
                    <Stack
                        mt={3}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Image src="/images/product-info/notAccesImage.svg" width={300} height={250} />
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text
                                sx={{
                                    fontWeight: "600",
                                    fontSize: { xs: "16px", md: "26px" },
                                    color: "#212225",
                                    lineHeight: "1.5",
                                }}
                            >
                                You haven't purchased any plan yet
                            </Text>
                            <Text
                                sx={{
                                    fontWeight: "500",
                                    fontSize: { xs: "12px", md: "19px" },
                                    color: "#A2A7AE",
                                }}
                            >
                                Click on purchase plan to access Jarvis Portfolio
                            </Text>
                        </Stack>
                        <JarvisButton
                            variant="contained"
                            sx={{ mt: 2, fontSize: { xs: "14px", md: "18px" } }}
                            onClick={() => handleSubmitForPortfolio()}
                        >
                            Purchase Plan
                        </JarvisButton>
                    </Stack >
                    : productCode === "RMS" ?
                        <Stack
                            mt={3}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Image src="/images/product-info/notAccesImage.svg" width={300} />
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Text
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: { xs: "16px", md: "26px" },
                                        color: "#212225",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    You haven't purchased any plan yet
                                </Text>
                                <Text
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: { xs: "12px", md: "19px" },
                                        color: "#A2A7AE",
                                    }}
                                >
                                    Click on purchase plan to access Jarvis Protect
                                </Text>
                            </Stack>
                            <JarvisButton
                                variant="contained"
                                sx={{ mt: 2, fontSize: { xs: "14px", md: "18px" } }}
                                onClick={() => handleSubmitForProtect()}
                            >
                                Purchase Plan
                            </JarvisButton>
                        </Stack >
                        : productCode === "ONESTOCK" ?
                            <Stack
                                mt={3}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Image src="/images/product-info/notAccesImage.svg" width={300} />
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Text
                                        sx={{
                                            fontWeight: "600",
                                            fontSize: { xs: "16px", md: "26px" },
                                            color: "#212225",
                                            lineHeight: "1.5",
                                        }}
                                    >
                                        You haven't purchased any plan yet
                                    </Text>
                                    <Text
                                        sx={{
                                            fontWeight: "500",
                                            fontSize: { xs: "12px", md: "19px" },
                                            color: "#A2A7AE",
                                        }}
                                    >
                                        Click on purchase plan to access Jarvis Onestock
                                    </Text>
                                </Stack>
                                <JarvisButton
                                    variant="contained"
                                    sx={{ mt: 2, fontSize: { xs: "14px", md: "18px" } }}
                                    onClick={() => handleSubmitForOnestock()}
                                >
                                    Purchase Plan
                                </JarvisButton>
                            </Stack >
                            : null
            }
        </>
    );
}

export default withRouter(NotAccessForPlan);