import { intercom } from "./intercom";

// Event Trackers
import { registrationTracker } from "./eventTracker/registrationTracker";
import { loginTracker } from "./eventTracker/loginTracker";
import { facebookLoginTracker } from "./eventTracker/facebookLoginTracker";
import { googleLoginTracker } from "./eventTracker/googleLoginTracker";
import { forgetPasswordTracker } from "./eventTracker/forgetPasswordTracker";
import { couponTracker } from "./eventTracker/couponTracker";
import { beforeRegistrationTracker } from "./eventTracker/beforeRegistrationTracker";
import { investMoreTracker } from "./eventTracker/investMoreTracker";
import { actionCenterInvestMoreTracker } from "./eventTracker/actionCenterInvetMoreTracker";
import { brokerTracker } from "./eventTracker/brokerTracker";
import { blogTracker } from "./eventTracker/blogTracker";
import { marketUpdateTracker } from "./eventTracker/marketUpdateTracker";
import { helpAndSupportTracker } from "./eventTracker/helpAndSupportTracker";
import { verifyMobileTracker } from "./eventTracker/verifyMobileTracker";

import { profileTracker } from "./eventTracker/profileTracker";
import { onboardingTracker } from "./eventTracker/onboardingTracker";
import { initialTracker } from "./eventTracker/initialTracker";
import { partialBuySellTracker } from "./eventTracker/partialBuySellTracker";
import { expiryBuySellTracker } from "./eventTracker/expiryBuySellTracker";
import { expiryCompleteBuySellTracker } from "./eventTracker/expiryCompleteBuySellTracker";
import { repairFlowTracker } from "./eventTracker/repariFlowTracker";
import { archiveFlowTracker } from "./eventTracker/archiveFlowTracker";
import { riskProfileTracker } from "./eventTracker/riskProfileTracker";
import { investmentStrategyTracker } from "./eventTracker/investmentStrategyTracker";
import { renewTracker } from "./eventTracker/renewalTracker";
import { onCancelPaymentTracker } from "./eventTracker/onCancelPaymentTracker";
import { verifyEmailTracker } from "./eventTracker/verifyEmailTracker";

const tracker = {};
// used to update config for individual tracking platform
tracker.updateConfig = (config) => {
    // mixpanelTracker.updateConfig(config.mixpanel);
    // appsFlyerTracker.updateConfig(config.appsflyer);
    // uxcamTracker.updateConfig(config.uxcam)
};

// TODO: update naming
tracker.init = ({ isLoggedIn = false, loginProfile = {}, deviceId = null }) => {
    try {
        // google.init(isLoggedIn, loginProfile);
        intercom.init({ isLoggedIn, loginProfile });
    } catch (err) {
        console.log(err);
    }
};

tracker.initTracker = () => {
    // appsFlyerTracker.init();
    // mixpanelTracker.init();
}

tracker.unmountApp = () => {
    // mixpanelTracker.reset();
    // uxcamTracker.stopSession();
};

tracker.logout = () => {
    // mixpanelTracker.reset();
    // uxcamTracker.stopSession();
};

tracker.pageView = () => {
    try {
        // const pathName = window.location.pathname;

        // google.pageView(pathName);

        // appsFlyerTracker.trackEvents({
        //     eventName: "page_view",
        //     eventData: { path_name: pathName },
        // });

        // uxcamTracker.tagScreenName(pathName);
    } catch (err) {
        console.log(err);
    }
};

// Onboarding events
tracker.onboarding = {};

tracker.onboarding.signup = (mobile) => {
    try {
        // google.trackEvents(
        //     "ONBOARDING",
        //     "SIGNUP",
        //     "Mobile",
        //     parseInt(`0${mobile}`)
        // );
    } catch (err) {
        console.log(err);
    }
};

tracker.onboarding.riskAssessment = (mobile) => {
    try {
        // google.trackEvents(
        //     "ONBOARDING",
        //     "Risk Assessment",
        //     "Mobile",
        //     parseInt(`0${mobile}`)
        // );
    } catch (err) {
        console.log(err);
    }
};

tracker.onboarding.investmentPreference = (mobile) => {
    try {
        // google.trackEvents(
        //     "ONBOARDING",
        //     "Investment Preference",
        //     "Mobile",
        //     parseInt(`0${mobile}`)
        // );
    } catch (err) {
        console.log(err);
    }
};

tracker.onboarding.portfolioCreation = (mobile) => {
    try {
        // google.trackEvents(
        //     "ONBOARDING",
        //     "PortFolio Creation",
        //     "Mobile",
        //     parseInt(`0${mobile}`)
        // );
    } catch (err) {
        console.log(err);
    }
};

tracker.onboarding.ckyc = (mobile) => {
    try {
        // google.trackEvents(
        //     "ONBOARDING",
        //     "Customer KYC",
        //     "Mobile",
        //     parseInt(`0${mobile}`)
        // );
    } catch (err) {
        console.log(err);
    }
};

tracker.onboarding.documentSigning = (mobile) => {
    try {
        // google.trackEvents(
        //     "ONBOARDING",
        //     "Customer KYC",
        //     "Mobile",
        //     parseInt(`0${mobile}`)
        // );
    } catch (err) {
        console.log(err);
    }
};

tracker.onboarding.brokerSelection = (mobile) => {
    try {
        // google.trackEvents(
        //     "ONBOARDING",
        //     "Broker Selection",
        //     "Mobile",
        //     parseInt(`0${mobile}`)
        // );
    } catch (err) {
        console.log(err);
    }
};

/** Event Tracker's Listing **/
tracker.registration = registrationTracker;
tracker.login = loginTracker;
tracker.facebookLogin = facebookLoginTracker;
tracker.googleLogin = googleLoginTracker;
tracker.forgotPassowrd = forgetPasswordTracker;
tracker.coupon = couponTracker;
tracker.beforeRegistration = beforeRegistrationTracker;
tracker.investMore = investMoreTracker;
tracker.actionCenterInvestMore = actionCenterInvestMoreTracker;
tracker.broker = brokerTracker;
tracker.blog = blogTracker;
tracker.marketUpdate = marketUpdateTracker;
tracker.helpAndSupport = helpAndSupportTracker;
tracker.verifyMobile = verifyMobileTracker;
tracker.verifyEmail = verifyEmailTracker;


tracker.profile = profileTracker;
tracker.Onboarding = onboardingTracker;
tracker.initial = initialTracker;
tracker.partialBuySell = partialBuySellTracker;
tracker.expiryBuySell = expiryBuySellTracker;
tracker.expiryCompleteBuySell = expiryCompleteBuySellTracker;
tracker.repairFlow = repairFlowTracker;
tracker.archiveFlow = archiveFlowTracker;
tracker.riskProfile = riskProfileTracker;
tracker.investmentStrategy = investmentStrategyTracker;
tracker.renewal = renewTracker;
tracker.onCancelPayment = onCancelPaymentTracker;

export { tracker };
