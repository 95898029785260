import { axios } from "helper/axios";
import { jarvisRMSExistingHoldingConstants } from "./jarvisRMSExistingHoldingConstants";

const jarvisRMSExistingHoldingApis = {};


jarvisRMSExistingHoldingApis.getPortfolioList = () => {
	return axios.get(
		jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_PORTFOLIO_LIST_API
	)
	// return  [{
	// 	portfolioId: 1,
	// 	portfolioName: "Portfolio 1",
	// 	brokerName: "abhiraak",
	// 	brokerPlatform: "jirodha",
	// 	portfolioRisk: "LOW",
	// 	portfolioRiskScore: 4,
	// },
	// {
	// 	portfolioId: 2,
	// 	portfolioName: "Portfolio 2",
	// 	brokerName: "monaak",
	// 	brokerPlatform: "ubn",
	// 	portfolioRisk: "MED",
	// 	portfolioRiskScore: 6,
	// },
	// {
	// 	portfolioId: 3,
	// 	portfolioName: "Portfolio 3",
	// 	brokerName: "monaak",
	// 	brokerPlatform: "ubn",
	// 	portfolioRisk: "HIGH",
	// 	portfolioRiskScore: 9,
	// },];
};

jarvisRMSExistingHoldingApis.getStockList = ({ portfolioId, isTemp }) => {
	const uri = `${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_EDIT_STOCK_API}/${portfolioId}/stock`;
	return axios.get(uri, { params: { temp: isTemp } })
	// let list;
	// return list = [
	// 	{
	// 		portfolioStockId: 1,
	// 		stockId: 10,
	// 		stockName: 'ITC LTD.',
	// 		stockSymbol: 'ITC',
	// 		stockBuyDate: new Date(),
	// 		stockMarketCap: "Mid Cap",
	// 		stockSector: "Banking",
	// 		stockBuyPrice: 100,
	// 		stockQuantity: 10,
	// 		stockBuyValue: 1000,
	// 		stockCurrentPrice: 110,
	// 		stockCurrentValue: 1100,
	// 		stockChangePrice: 100,
	// 		stockChangePercentage: 10,
	// 		stockChangeType: 'LOSS',
	// 		rmsCallStatus: "HOLD",
	// 		rmsCallReasion: "dummy Text",
	// 		stockRecommendedAction: "EDIT",
	// 		stockErrorMessage: "",

	// 		scoreByAI: [{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: '52W H',
	// 			value: '17%',
	// 			amount: "5312"
	// 		},
	// 		{
	// 			label: '52W L',
	// 			value: '17%',
	// 			amount: "3058"
	// 		}],
	// 	},
	// 	{
	// 		portfolioStockId: 2,
	// 		stockId: 10,
	// 		stockName: 'HDFC LTD.',
	// 		stockSymbol: 'HDFC',
	// 		stockBuyDate: new Date(),
	// 		stockMarketCap: "Mid Cap",
	// 		stockSector: "Banking",
	// 		stockBuyPrice: 100,
	// 		stockQuantity: 10,
	// 		stockBuyValue: 1000,
	// 		stockCurrentPrice: 110,
	// 		stockCurrentValue: 1100,
	// 		stockChangePrice: 100,
	// 		stockChangePercentage: 10,
	// 		stockChangeType: 'LOSS',
	// 		stockRecommendedAction: "EDIT",
	// 		rmsCallStatus: "SELL",
	// 		rmsCallReasion: "dummy Text",
	// 		stockErrorMessage: "this is error msg",

	// 		scoreByAI: [{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Fundamental Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Technical Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Sentiment Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: '52W H',
	// 			value: '17%',
	// 			amount: "5312"
	// 		},
	// 		{
	// 			label: '52W L',
	// 			value: '17%',
	// 			amount: "3058"
	// 		}
	// 	],
	// 	},
	// 	{
	// 		portfolioStockId: 3,
	// 		stockId: 10,
	// 		stockName: 'KOTAK LTD.',
	// 		stockSymbol: 'KOTAK',
	// 		stockBuyDate: new Date(),
	// 		stockMarketCap: "Lrg Cap",
	// 		stockSector: "Banking",
	// 		stockBuyPrice: 100,
	// 		stockQuantity: 10,
	// 		stockBuyValue: 1000,
	// 		stockCurrentPrice: 110,
	// 		stockCurrentValue: 1100,
	// 		stockChangePrice: 100,
	// 		stockChangePercentage: 10,
	// 		stockChangeType: 'GAIN',
	// 		stockRecommendedAction: "EDIT",
	// 		rmsCallStatus: "HOLD",
	// 		rmsCallReasion: "dummy Text",
	// 		stockErrorMessage: "",

	// 		scoreByAI: [{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: '52W H',
	// 			value: '17%',
	// 			amount: "5312"
	// 		},
	// 		{
	// 			label: '52W L',
	// 			value: '17%',
	// 			amount: "3058"
	// 		}],
	// 	},
	// 	{
	// 		portfolioStockId: 4,
	// 		stockId: 10,
	// 		stockName: 'LIC LTD.',
	// 		stockSymbol: 'LIC',
	// 		stockBuyDate: new Date(),
	// 		stockMarketCap: "Mid Cap",
	// 		stockSector: "Banking",
	// 		stockBuyPrice: 100,
	// 		stockQuantity: 10,
	// 		stockBuyValue: 1000,
	// 		stockCurrentPrice: 110,
	// 		stockCurrentValue: 1100,
	// 		stockChangePrice: 100,
	// 		stockChangePercentage: 10,
	// 		stockChangeType: 'GAIN',
	// 		stockRecommendedAction: "EDIT",
	// 		rmsCallStatus: "SELL",
	// 		rmsCallReasion: "dummy Text",
	// 		stockErrorMessage: "",

	// 		scoreByAI: [{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: 'Overall AI Score',
	// 			value: '17%',
	// 		},
	// 		{
	// 			label: '52W H',
	// 			value: '17%',
	// 			amount: "5312"
	// 		},
	// 		{
	// 			label: '52W L',
	// 			value: '17%',
	// 			amount: "3058"
	// 		}],
	// 	},
	// ]
}

jarvisRMSExistingHoldingApis.updatePortfolioName = ({ portfolioId, portfolioName, isTemp }) => {
	return axios.patch(
		`${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_PORTFOLIO_LIST_API}/${portfolioId}`,
		{ portfolioName },
		{ params: { temp: isTemp } }
	);
}

jarvisRMSExistingHoldingApis.removePortfolio = ({ portfolioId, isTemp }) => {
	return axios.delete(
		`${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_REMOVE_PORTFOLIO_API}/${portfolioId}`,
		{ params: { temp: isTemp } }
	);
}

jarvisRMSExistingHoldingApis.addStockToPortfolio = ({ portfolioId, isTemp, stockId, stockSymbol, stockQuantity, stockBuyPrice, stockBuyDate }) => {
	return axios.post(
		`${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_ADD_STOCK_TO_PORTFOLIO_API}/${portfolioId}/stock`,
		{ stockId, stockSymbol, stockQuantity, stockBuyPrice, stockBuyDate },
		{ params: { temp: isTemp } }
	);
}

jarvisRMSExistingHoldingApis.removeStockToPortfolio = (body, portfolioId) => {
	let temp = 0;
	return axios.patch(`${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_REMOVE_STOCK_TO_PORTFOLIO_API}/${portfolioId}/stock?temp=${temp}`, body);
}

jarvisRMSExistingHoldingApis.deleteStock = ({ portfolioId, isTemp, deletePayload = [] }) => {
	return axios.patch(
		`${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_REMOVE_STOCK_TO_PORTFOLIO_API}/${portfolioId}/stock`,
		{ portfolioStockIds: deletePayload },
		{ params: { temp: isTemp } }
	);
}

jarvisRMSExistingHoldingApis.editStock = ({ portfolioId, isTemp, editPayload = [] }) => {
    return axios.put(
		`${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_EDIT_STOCK_API}/${portfolioId}/stock`,
		{ stockData: editPayload },
		{ params: { temp: isTemp } }
	);
};

jarvisRMSExistingHoldingApis.editStockList = (body, portfolioId) => {
	let temp = 0;
	return axios.put(`${jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_EDIT_STOCK_API}/${portfolioId}?temp=${temp}`, body);
}





export { jarvisRMSExistingHoldingApis };
