import React from "react";
import cx from "classnames";
import { makeStyles, createStyles } from "@mui/styles";

import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Box from "components/common/Box";

import CheckIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from '@mui/icons-material/CircleOutlined';
import { alpha } from "@mui/system";
import ImageComponent from "components/common/Image";

const InvestmentStrategyCard = ({
    heading,
    description,
    descriptionSmall = "",
    isSelected,
    onClick = ()=>{},
}) => {
    const classes = useStyles();
    return (
        <Stack
            className={cx({
                [classes.root]: true,
                [classes.rootActive]: isSelected,
            })}
            onClick={onClick}>
            <Stack
                className={classes.headingText}
                sx={{
                    // color: isSelected ? "#1D9BFF" : "",
                    alignItems: "center", 
                    flexFlow: "row nowrap"
                }}>
                    <ImageComponent src="/strategy-icon.png" width="30px" />
                    <Text ml={1.5} flex={1}>{heading}</Text>
                    {/* <Box className={classes.roundCircle}>
                        {
                            isSelected?
                                <CheckIcon sx={{ 
                                    color: "primary.main"
                                }} />
                            : <CircleIcon
                                sx={{
                                    color: "#D0D5DD"
                                }}
                            />
                        }
                    </Box> */}
            </Stack>
            <Text className={classes.descriptionText}>
                {description}
                {descriptionSmall && (
                    <Text className={classes.descriptionSmall}>
                        {descriptionSmall}
                    </Text>
                )}
            </Text>
        </Stack>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            borderRadius: "7px",
            maxWidth: "100%",
            width: "400px",
            padding: "15px",
            border: `1px solid #DEDEDE`,
            // cursor: "pointer",
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.up("md")]: {
                padding: "22px 26px",
            },
        },
        rootActive: {
            borderColor: "#0177d5",
        },
        headingText: {
            ...theme.typography.body1,
            fontWeight: "medium",
            lineHeight: 1.25,
            marginBottom: "22px",
            
        },
        roundCircle:{
            maxWidth: 24,
            alignSelf: "start"
        },
        descriptionText: {
            ...theme.typography.small,
            maxWidth: "330px",
            lineHeight: 1.5,
            flex: 1,
            color:"#676E76",
        },
        descriptionSmall: {
            color: "#c3c3c3",
            fontSize: "12px",
            marginTop: "7px",
        },
        button: {
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
        },
    })
);

export default InvestmentStrategyCard;
