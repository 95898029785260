import React from "react";

import Stack from "components/common/Stack";
import SectionTitle from "components/SectionTitle";
import Avatar from "components/common/Avatar";
import Chart from "components/common/Chart";

import { alpha } from "@mui/system";
import { AccountTreeOutlined as ChartIcon } from "@mui/icons-material";

const MarketCapDiversification = ({ chartData }) => {
    const [isScreenTypeLaptop,setIsScreenTypeLaptop] = React.useState();

    React.useEffect(()=>{
        window.addEventListener('resize', (event) => {
            if (window.innerWidth > 900) {
                setIsScreenTypeLaptop(true);
            } else {
                setIsScreenTypeLaptop(false);
            }
        });
    },[])
    
    const chartOptions = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        style: {
            fontFamily: "'DM Sans',sans-serif"
        },
        title: {
            text: ''
        },
        tooltip: {
            headerFormat: "",
            pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.percentage:.1f}%</b> of total<br/>',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                showInLegend: true,
                dataLabels: {
                    enabled: isScreenTypeLaptop,
                    format: "{point.name}: {point.percentage:.1f}%",
                },
            },
        },
        series: [{
            data: chartData.map(c => ({ name: c.label, y: Number(c.value) }))
        }]
    };

    return (
        <Stack sx={{ border: '1px solid #E8EBEE', flex: 1, py: 3, px: 4, borderRadius: 2.5 }}>
            <SectionTitle
                heading="Market Cap Diversification"
                startIcon={<Avatar sx={{ backgroundColor: alpha("#1D9BFF", 0.08) }}><ChartIcon sx={{ fontSize: 20, color: "primary.main" }} /></Avatar>}
            />
            <Chart options={chartOptions} />
        </Stack>
    )
}

export default MarketCapDiversification;