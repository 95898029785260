import React from "react";
import JarvisButton from "components/JarvisButton";
import Stack from "components/common/Stack";
import Text from "components/common/Text";

const RMSPortfolioSyncRemoveStock = ({ onSubmit, onClose }) => {
  return (
    <Stack sx={{ borderRadius: "10px" }}>
      <Stack sx={{ flex: 1 }}>
        <Text
          variant={"subheading"}
          sx={{ textAlign: "center", color: "#808591 !important" }}
        >
          Are you sure you want to remove this stock from this portfolio?
        </Text>
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: 2,
        }}
      >
        <JarvisButton
          variant="outlined"
          disableEndIcon
          sx={{ width: "100px", boxShadow: "none", border: "1px solid #2196f3", mr: "10px" }}
          onClick={onClose}
        >
          Cancel
        </JarvisButton>

        <JarvisButton
          disableEndIcon
          sx={{ width: "180px", boxShadow: "none" }}
          onClick={onSubmit}
        >
          Yes, Remove
        </JarvisButton>
      </Stack>
    </Stack>
  );
};

export default RMSPortfolioSyncRemoveStock;
