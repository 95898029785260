import React from "react";

//mui
import { makeStyles } from "@mui/styles";

//common components
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";

//helpers
import { formatHelper } from "helper/format";

const JarvisRMSStockCard = (props) => {
    const { currentIndex, handleProceed, portfolio, sx = {} } = props;

    const {
        stockName = "",
        stockQuantity = 0,
        stockPrice = 0,
        suggestedSellPrice = 0,
        stockMarketCap = "",
        stockSector = "",
    } = props.stockDetails;

    const classes = useStyles();

    return (
        <>
            {currentIndex < 8 ? (
                <Box sx={{ p: 2, border: `1px solid #e2e2e2`, ...sx }}>
                    <Box sx={{ width: "auto" }}>
                        <Stack mb={1.5} direction={'row'} alignItems={'center'}>
                            <Text
                                variant="caption1"
                                component="span"
                                sx={{ color: "#808591" }}
                            >
                                Qty. <span style={{ fontWeight: 500, color: "#222B3E" }}>{stockQuantity}</span>
                            </Text>
                            {/* <Stack
                                direction="row"
                                ml={2}
                            >
                                <li style={{ color: "#808591" }}></li>
                                <Text variant="caption1" mt={0.5} ml={-1} sx={{ color: "#808591" }}>Weight&nbsp; <span style={{ fontWeight: 500, color: "#222B3E" }}>10%</span></Text>
                            </Stack> */}
                            {/* <Text className={classes.SellChip}>{`Sell`}</Text> */}

                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            mb={1.5}
                            sx={{ flexWrap: 'nowrap' }}
                        >
                            <Text variant="body2" title={stockName} className={classes.stockName} sx={{ fontWeight: 700 }}>
                                {stockName}
                            </Text>
                            <Text variant="small" sx={{ fontWeight: 700 }}>
                                {formatHelper.currency(suggestedSellPrice, {
                                    config: { maximumFractionDigits: 0 },
                                })}
                            </Text>
                        </Stack>
                        <Stack
                            direction="row"
                        >
                            <Text title={stockMarketCap} className={classes.infoChip}>
                                {stockMarketCap}
                            </Text>
                            <Text title={stockSector} className={classes.infoChip}>
                                {stockSector}
                            </Text>
                            <Text className={classes.SellChip}>{`Sell`}</Text>
                        </Stack>
                        {/* <Stack
                            direction="row"
                            justifyContent="flex-start"
                            sx={{ flexWrap: "wrap" }}
                            mb={1}
                        >
                            <Text title={stockMarketCap} className={classes.infoChip}>
                                {stockMarketCap}
                            </Text>
                            <Text title={stockSector} className={classes.infoChip}>
                                {stockSector}
                            </Text>
                            <Text className={classes.SellChip}>{`Sell`}</Text> 
                        </Stack> */}
                    </Box>
                </Box>
            ) : (
                <Box
                    p={2}
                    bgcolor="#EEF4FA"
                    className={classes.moreBox}
                    onClick={() => {
                        handleProceed(portfolio);
                    }}
                >
                    <Text varinat="small">{`+${portfolio.stockData.length - 8
                        } more`}</Text>
                </Box>
            )}
        </>
    );
};

export default JarvisRMSStockCard;

const useStyles = makeStyles(() => ({
    stockName: {
        color: "#202020",
        fontWeight: "600",
        textOverflow: "ellipsis",
        marginRight: "10px",
        fontSize: "18px",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingRight: '12px',
    },

    infoChip: {
        fontSize: "11px",
        marginRight: "8px",
        border: "1px solid #F5F5F5",
        borderRadius: "10px",
        padding: "2px 5px",
        textTransform: "uppercase",
        color: "#000000",
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: "center",
        marginTop: "4px",
        // padding: "2px 12px",
        // borderRadius: "10px",
        // border: "2px solid #F5F5F5",
        // fontSize: "11px",
        // textTransform: "uppercase",
        // color: "#000000",
        // backgroundColor: "#fff",
        // whiteSpace: "nowrap",
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // margin: "0.5rem 0.5rem 0 0",
    },
    SellChip: {
        fontSize: "14px",
        borderRadius: "10px",
        border: "1px solid rgba(212, 76, 76, 0.08)",
        background: "rgba(212, 76, 76, 0.08)",
        color: "#D44C4C",
        padding: "2px 5px",
        whiteSpace: "nowrap",
        textAlign: "center",
        marginTop: "3px",
        // padding: "0px 12px",
        // borderRadius: "10px",
        // fontSize: "12px",
        // textTransform: "uppercase",
        // color: "#D44C4C",
        // backgroundColor: "#D44C4C14",
        // whiteSpace: "nowrap",
    },
    moreBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid #e2e2e2`,
        height: "100%",
        cursor: "pointer",
    },
}));
