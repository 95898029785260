import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { masterActions } from "stores/master/masterActions";
import { jarvisOnestockActionCenterActions } from "stores/jarvisOnestock/jarvisOnestockActionCenter/jarvisOnestockActionCenterActions"

const mapStateToProps = (state) => {
    return {
        // broker
        brokerList: state.master.brokers,
        isBrokerListLoading: state.master.loading.brokers,
        isBrokerListInitialCalled: state.master.initialCalled.brokers,
       
        // action centre
        oneStockActionCenterDataStore: state.jarvisOnestock.onestockActionCenter.actionCenterCalls,
        // Centrum referal URL
        centrumReferalURL: state.common.featureConfig.centrumReferalURL
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getBrokerListAction: masterActions.getMasterBrokers,
    getOnestockActionCenterDataAction: jarvisOnestockActionCenterActions.getOnestockActionCenterData,
    pendingCallAction: masterActions.getPendingCall,

}, dispatch);

const OnestockActionCenterStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockActionCenterStore;

