import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";
import { jarvisRMSExistingHoldingAction } from "stores/jarvisRMS/jarvisRMSExistingHolding/jarvisRMSExistingHoldingAction";

import { commonActions } from "stores/common/commonActions";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";

const mapStateToProps = (state) => {

    return {
        portfolioList: state.jarvisRMS.rmsExistingHolding.portfolioList,
        isPortfolioListLoading: state.jarvisRMS.rmsExistingHolding.loading.portfolioList,
        isPortfolioListInitialCalled: state.jarvisRMS.rmsExistingHolding.initialCalled.portfolioList,
        isStockListLoading: state.jarvisRMS.rmsExistingHolding.loading.stockList,
        isStockListInitialCalled: state.jarvisRMS.rmsExistingHolding.initialCalled.stockList,
        portfolioStockList: state.jarvisRMS.rmsExistingHolding.stockList,
        rmsIsOnboardingCompleted: state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
        productSubscriptionDetails: state.customer.productSubscriptionDetails,

        renewalPricingDetails: state.customer.renewalPricingDetails,
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getRenewSubscriptionAction: customerActions.getRenewalPricingDetails,
    getPortfolioListAction: jarvisRMSExistingHoldingAction.getPortfolioList,
    getStockListAction: jarvisRMSExistingHoldingAction.getStockList,
    getProductsSubscriptionDetailsAction: customerActions.getProductsSubscriptionDetails,
    setDialog: commonActions.setDialog,
    getRMSOnobardingDataAction: jarvsiRMSOnboardingActions.getOnboardingData,

}, dispatch);

const RMSExistingHoldingStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSExistingHoldingStore;


