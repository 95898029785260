import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";

const mapStateToProps = (state) => {
  return {
    portfolioId : state.jarvisRMS.rmsOnboarding.portfolioId
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getRMSOnboardingDataAction: jarvsiRMSOnboardingActions.getOnboardingData,
}, dispatch);

const RMSConfirmPortfolioStore = (Container) => {
  return connect(mapStateToProps, mapDispatchToProps)(Container);
};

export default RMSConfirmPortfolioStore;
