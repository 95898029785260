import { Box } from "@mui/system";
import React from "react";
import Grid from "components/common/Grid";
import ImageComponent from "components/common/Image";
import Text from "../common/Text";

const ReferredUserCard = () => {
    return (
        <Box sx={{ marginBottom: 10 }}>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    padding: { xs: "16px", md: "40px" },
                    margin:"30px 0px",
                    borderRadius: "10px",
                }}>
                <Text
                    variant="subtitle2"
                    fontWeight="bold"
                    style={{ margin: "0 0 15px 0" }}>
                    Referred Users
                </Text>

                <Grid
                    container
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}>
                    {[1, 2].map((item) => {
                        return (
                            <Grid
                                key={`referred_user_${item}`}
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                                item
                                xs={12}
                                md={6}>
                                <Grid
                                    sx={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: { xs: "5px 15px", md: "20px" },
                                        border:{xs:"none",md:"0.4px solid #E2E2E2"},
                                        borderRadius: "5px",
                                    }}>
                                    <ImageComponent
                                        src="/images/dummy1.png"
                                        height="46px"
                                        width="46px"
                                    />
                                    <Text
                                        sx={{
                                            fontSize: {
                                                xs: "14px",
                                                md: "20px",
                                            },
                                        }}
                                        fontWeight="bold">
                                        Praveen Maurya
                                    </Text>
                                    <Text
                                        sx={{
                                            fontSize: {
                                                xs: "10px",
                                                md: "14px",
                                            },
                                        }}
                                        fontWeight="bold">
                                        23 - 10 - 2021
                                    </Text>
                                    <Text
                                        sx={{
                                            fontSize: {
                                                xs: "14px",
                                                md: "18px",
                                            },
                                        }}
                                        fontWeight="bold"
                                        color="#1D9BFF">
                                        ₹ 100
                                    </Text>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
};

export default ReferredUserCard;
