import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";

import { CustomerRoute, PrivateRoute } from "helper/route";
import LayoutContainer from "containers/Layout";

// pages
import Overview from "pages/Customer/Overview";
import Payment from "pages/Customer/Payment";
import Broker from "pages/Customer/Broker";
import DocumentSigning from "pages/Customer/DocumentSigning";
import Refer from "pages/Customer/Refer";
import Profile from "pages/Customer/Profile";
import AppMore from "pages/Customer/AppMore";
import ProductList from "pages/Customer/ProductList";
import JarvisInfo from "pages/Customer/JarvisInfo";
import SubscriptionPayment from "pages/Customer/SubscriptionPayment";
import PlanAndPricing from "pages/PlanAndPricing";
import HelpSupport from "containers/HelpSupport";
import SubscriptionDetails from '../pages/Customer/SubscriptionDetails';
import EditProfile from "pages/Customer/EditProfile";
import ProductInfo from "pages/ProductInfo/ProductInfo";
import OneTimePayment from "pages/Customer/OneTimePayment";

const CustomerNavigator = (props) => {
    const { match } = props;
    const isAuthenticated = useSelector((state) => state.customer.isAuthenticated);
    
     // Product onboaridng completed
     const isPortfolioOnboardingCompleted = useSelector((state) => state.onboarding.isOnboardingCompleted);
     const isRMSonboardingCompleted = useSelector((state) => state.jarvisRMS.rmsOnboarding.isOnboardingCompleted);
     const isOnestockonboardingCompleted = useSelector((state) => state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted);
 
     const anyOnboardingCompleted = (isPortfolioOnboardingCompleted || isRMSonboardingCompleted || isOnestockonboardingCompleted);

    return (
        <Switch>
            {/* 1 - Redirection to */}
            <Redirect exact from={match.path} to={`${match.path}/profile`} />

            <LayoutContainer {...props}>
                <Switch>
                {/* 2 -  Without layout pages */}
                    {/* for first visit customer */}
                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/jarvis-info`}
                        component={JarvisInfo}
                    />

                    {/* switching product */}
                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/product-info`}
                        component={ProductInfo}
                    />

                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/product-list`}
                        component={ProductList}
                    />


                    {/* Routes accessible for onboarding and customer */}
                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/broker`}
                        component={Broker}
                    />
                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/document-signing`}
                        component={DocumentSigning}
                    />
                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/subscription`}
                        component={SubscriptionPayment}
                    />
                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/payment`}
                        component={Payment}
                    />
                    <PrivateRoute
                        exact
                        auth={{ isAuthenticated }}
                        path={`${match.path}/onetime-payment`}
                        component={OneTimePayment}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/app-more`}
                        component={AppMore}
                    />




                {/* 3 - With layout pages */}
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/overview`}
                        component={Overview}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/refer-jarvis`}
                        component={Refer}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/help-support`}
                        component={HelpSupport}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/profile`}
                        component={Profile}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/plan-and-pricing`}
                        component={PlanAndPricing}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/profile/subscription`}
                        component={SubscriptionDetails}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        path={`${match.path}/profile/edit-profile`}
                        component={EditProfile}
                    />
                </Switch>
            </LayoutContainer>

            <Redirect to={`not-found`} />
        </Switch>
    );
};

export default CustomerNavigator;