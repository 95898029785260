export const jarvisRMSOnboardingConstants = {
    RMS_RESET_ONBOARDING_DATA: "RMS_RESET_ONBOARDING_DATA",
    RMS_UPDATE_ONBOARDING_DATA: "RMS_UPDATE_ONBOARDING_DATA",

    // TODO: move to master
    RMS_RISK_ASSESSMENT_API: "master/risk/assessment",

    RMS_CONFIRM_RISK_PROFILE_API: "rms/onboarding/risk-assessment",
    
    RMS_ONBOARDING_DATA_API: "rms/onboarding/details",
    RMS_ONBOARDING_DATA_SAGA: "RMS_ONBOARDING_DATA_SAGA",
    RMS_ONBOARDING_DATA_REQUEST: "RMS_ONBOARDING_DATA_REQUEST",
    RMS_ONBOARDING_DATA_RESPONSE: "RMS_ONBOARDING_DATA_RESPONSE",

    RMS_CONFIRM_CKYC_DATA_API: "/rms/onboarding/confirm-kyc",

    RMS_INITIATE_DOCUMENT_SIGNING_API: `rms/onboarding/initiate-signing`,

    RMS_UPLOAD_PORTFOLIO_API: "rms/portfolio/upload",
    RMS_PORTFOLIO_DETAILS_API: "rms/portfolio/portfolioId",
    RMS_STOCK_LIST_API: "rms/portfolio/portfolioId/stock",
    RMS_ADD_STOCK_API: "rms/portfolio/portfolioId/stock",
    RMS_EDIT_PORTFOLIO_NAME_API: "rms/portfolio/portfolioId",
    RMS_DELETE_PORTFOLIO_API: "rms/portfolio/portfolioId",
    RMS_REMOVE_STOCK_API: "rms/portfolio/portfolioId/stock",
    RMS_EDIT_STOCK_API: "rms/portfolio/portfolioId/stock",
    RMS_CONFIRM_STOCK_API: "rms/portfolio/confirm",

	BROKER_LIST_RESPONSE: "BROKER_LIST_RESPONSE",
    BROKER_LIST_REQUEST: "BROKER_LIST_REQUEST",
    BROKER_LIST_SAGA: "BROKER_LIST_SAGA",
    BROKER_LIST_API: "broker/list",

    RMS_PLAN_API: "rms/payment/plan",
    RMS_PLAN_REQUEST: "RMS_PLAN_REQUEST",
    RMS_PLAN_RESPONSE: "RMS_PLAN_RESPONSE",
    RMS_PLAN_SAGA: "RMS_PLAN_SAGA",
    RMS_PLAN_DATA: "RMS_PLAN_DATA",

    RMS_PAYMENT_SUBSCRIPTION_API: "rms/payment/subscription",
    RMS_CAPTURE_PAYMENT_SUBSCRIPTION_API: "rms/payment/capture-subscription",
};
