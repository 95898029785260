import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Stack from 'components/common/Stack';
import Box from 'components/common/Box';
import { config } from "config/index";
import { thirdpartyApis } from 'stores/thirdparty/thirdpartyApis';
import JarvisSubscriptionPlans from 'components/JarvisSubscriptionPlans';

class ProtectInfoContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFrequency: null,
            subscriptionPlans: [],
        }
    }

    componentDidMount() {
        this.props.getRMSOnboardingData();
        this.getSubscriptionPlanDetails()

        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();
        const firstFrequecy = subscriptionFrequencies[0];
        if (
            subscriptionFrequencies.length &&
            !this.state.selectedFrequency
        ) {
            this.handleFrequencyChange({ selectedFrequency: firstFrequecy })
        }
    }

    componentDidUpdate() {
        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();
        if (
            subscriptionFrequencies.length &&
            !this.state.selectedFrequency
        ) {
            this.handleFrequencyChange({ selectedFrequency: subscriptionFrequencies[0] });
        }
    }

    getSubscriptionPlanDetails = async () => {
        let res = await thirdpartyApis.getSubscriptionPlans({ productCode: config.productCodes.RMS });
        this.setState({ subscriptionPlans: res })
    }

    getSubscriptionPlanFrequencies = () => {
        const { subscriptionPlans } = this.state
        const planFrequency = [];
        if (subscriptionPlans && subscriptionPlans.length) {
            subscriptionPlans.forEach(plan => {
                if (!planFrequency.includes(plan.planFrequency)) {
                    planFrequency.push(plan.planFrequency);
                }
            })
        }
        return planFrequency.reverse();
    }

    handleFrequencyChange = ({ selectedFrequency }) => {
        this.setState({ selectedFrequency });
    }

    render() {

        const { subscriptionPlans, selectedFrequency, confirmDialogOpen } = this.state

        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();

        return (
            <>
                <Stack mt={2}>
                    <Box mt={3}>
                        <Stack>
                            <JarvisSubscriptionPlans
                                subscriptionPlans={subscriptionPlans}
                                subscriptionFrequencies={subscriptionFrequencies}
                                onFrequencyChange={this.handleFrequencyChange}
                                selectedFrequency={selectedFrequency}
                                currentPortfolioCount={0}
                                currentStockCount={0}
                                productTitle={"Jarvis Protect Pricing"}
                                confirmDialog={{
                                    open: confirmDialogOpen,
                                }}
                                diasbleCircle
                            />
                        </Stack>
                    </Box>
                </Stack>
            </>
        )
    }
}

export default withRouter(ProtectInfoContainer);
