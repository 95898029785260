import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { customerConstants } from "./customerConstants";

const initialState = {
    isAuthenticated: false,
    loginProfile: {},
    isProfileCompleted: false,
    reasonsDropdown: [],
    profile: {},
    subscriptionList: [],
    renewalPricingDetails: {},
    productSubscriptionList: {},
    productSubscriptionDetails: [],
    deactiveAccount: {
        message: "",
        email: "",
        isDialogOpen: false,
    },
    loading: {
        profile: 0,
        subscriptionList: 0,
        renewalPricingDetails: 0,
        productSubscriptionList: 0,
        productSubscriptionDetails: 0,
    },
};

export const customerReducers = persistReducer(
    {
        storage,
        key: "customer",
        whitelist: ["isAuthenticated", "loginProfile", "profile"],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case customerConstants.LOGIN_DATA: {
                return {
                    ...state,
                    isAuthenticated: true,
                    loginProfile: { ...payload },
                };
            }
            case customerConstants.UPDATE_LOGIN_EMAIL: {
                return {
                    ...state,
                    loginProfile: {
                        ...state.loginProfile,
                        email: payload.email
                    }
                }
            }
            case customerConstants.UPDATE_EMAIL_VERIFIED: {
                return {
                    ...state,
                    loginProfile: {
                        ...state.loginProfile,
                        isEmailVerified: payload,
                    },

                }
            }
            case customerConstants.RESET_AUTH_DATA: {
                return initialState;
            }
            case customerConstants.PROFILE_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        profile: 1,
                    },
                    profile: initialState.profile,
                };
            }

            case customerConstants.PROFILE_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        profile: 0,
                    },
                    profile: { ...payload },
                };
            }

            case customerConstants.UPDATE_RISK_PROFILE_DATA: {
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        riskProfileId: payload.riskProfileId,
                        riskProfile: payload.riskProfile,
                    },
                };
            }

            case customerConstants.UPDATE_INVESTMEN_STRATEGY_DATA: {
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        investmentStrategy: payload.investmentStrategy,
                    },
                };
            }

            case customerConstants.UPDATE_PROFILE_DATA: {
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        fullName: payload.fullName,
                        // email: payload.email,
                        // mobileNo: payload.mobileNo,
                        alternateNo: payload.alternateNo,
                        profilePic: payload.profilePic,
                    },
                };
            }

            case customerConstants.SUBSCRIPTION_LIST_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        subscriptionList: 1,
                    },
                    subscriptionList: initialState.subscriptionList,
                };
            }

            case customerConstants.SUBSCRIPTION_LIST_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        subscriptionList: 0,
                    },
                    subscriptionList: payload,
                };
            }

            case customerConstants.RENEWAL_PRICING_DETAILS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        renewalPricingDetails: 1,
                    },
                    renewalPricingDetails: initialState.renewalPricingDetails,
                };
            }

            case customerConstants.RENEWAL_PRICING_DETAILS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        renewalPricingDetails: 0,
                    },
                    renewalPricingDetails: payload,
                };
            }

            case customerConstants.REASON_FOR_CANCELLATION_RESPONSE: {
                return {
                    ...state,
                    reasonsDropdown: payload,
                };
            }

            case customerConstants.SET_DEACTIVE_ACCOUNT_DATA: {
                return {
                    ...state,
                    deactiveAccount: {
                        message: payload.deactiveAccountMessage,
                        email: payload.deactiveAccountEmail,
                        isDialogOpen: true,
                    },
                }
            }

            case customerConstants.RESET_DEACTIVE_ACCOUNT_DATA: {
                return {
                    ...state,
                    deactiveAccount: initialState.deactiveAccount,
                }
            }


            case customerConstants.PRODUCT_SUBSCRIPTION_LIST_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        productSubscriptionList: 1,
                    },
                    productSubscriptionList: initialState.productSubscriptionList,
                };
            }

            case customerConstants.PRODUCT_SUBSCRIPTION_LIST_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        productSubscriptionList: 0,
                    },
                    productSubscriptionList: payload,
                };
            }

            case customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        productSubscriptionDetails: 1,
                    },
                    productSubscriptionDetails: initialState.productSubscriptionDetails,
                };
            }

            case customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        productSubscriptionDetails: 0,
                    },
                    productSubscriptionDetails: payload,
                };
            }

            default:
                return state;
        }
    }
);
