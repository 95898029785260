import React from "react";
import PageTitle from "components/PageTitle";
import PaymentContainer from "containers/Payment";

const Payment = (props) => {
    return (
        <>
            <PageTitle heading="Payment" />
            <PaymentContainer {...props} />
        </>
    )
}

export default Payment