import React from "react";
import MarketUpdatesContainer from "containers/MarketUpdates";
import PageTitle from "components/PageTitle";
import { tracker } from "library/tracker";


const MarketUpdates = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);

    return (
        <>
            <PageTitle heading="Market Updates" />
            <MarketUpdatesContainer />
        </>
    )
}

export default MarketUpdates;