import React from "react";

import Checkbox from "components/common/Checkbox";
import Dialog from "components/common/Dialog";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import JarvisButton from "components/JarvisButton";

const DeactivateAccountDialog = ({
    isLoading = false,
    open = false,
    icon = {},
    openComponent,
    onClose,
    onConfirm,
    checkboxChecked,
    onCheckboxChange,
}) => {
    return (
        <Dialog
            open={open}
            icon={icon}
            onClose={onClose}
            maxWidth="xs"
            title={<></>}
            openComponent={openComponent}
            contentComponent={() => (
                <Stack sx={{ alignItems: "center" }}>
                    <Text textAlign="center">
                        Are you sure you want to deactivate your account? <br />
                    </Text>
                    <br />
                    <Text textAlign="center">
                        Deactivating the account would result in immediate suspension of all RMS / Rebalancing communications which might have a material impact on your portfolio.
                    </Text>
                    <Checkbox
                        color={"#38a1ff"}
                        label="I Understand"
                        checked={checkboxChecked}
                        onChange={onCheckboxChange}
                    />
                </Stack>
            )}
            actionComponent={() => (
                <JarvisButton
                    variant="contained"
                    disabledEndIcon
                    disabled={!checkboxChecked}
                    loading={isLoading}
                    onClick={onConfirm}
                >
                    Confirm
                </JarvisButton>
            )}
        />
    )
}

export default React.memo(DeactivateAccountDialog);