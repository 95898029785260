import React, { memo, useState } from "react";

import Dialog from "components/common/Dialog";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import JarvisButton from "components/JarvisButton";
import TextField from "components/common/TextField";
import { validationHelper } from "helper/validation";
import { customerApis } from "stores/customer/customerApis";
import { tracker } from "library/tracker";

const ChangePasswordDialog = ({
  open = false,
  icon = {},
  passwordOpen,
  formData = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
  formError = {},
  openComponent,
  button = {
    text: "Submit",
    variant: "contained",
    disableEndIcon: false,
    disabled: false,
  },
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPass] = useState("");
  const [errorcurrentPassword, setErrorCurrentPassword] = useState("");
  const [errornewPassword, setErrorNewPassword] = useState("");
  const [errorconfirmNewPassword, setErrorConfirmNewPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (name, e) => {
    if (name == "currentPassword") {
      setCurrentPassword(e);
    } else if (name == "newPassword") {
      setNewPassword(e);
    } else {
      setConfirmNewPass(e);
    }
  };

  const resetForm = () =>{
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPass("");
  }

  const handleValidation = () => {
    const errorcurrentPassword = validationHelper.password(currentPassword);
    setErrorCurrentPassword(errorcurrentPassword.message);

    const errornewPassword = validationHelper.password(newPassword);
    setErrorNewPassword(errornewPassword.message);

    const errorconfirmNewPassword = validationHelper.confirmPassword(
      confirmNewPassword,
      newPassword
    );
    setErrorConfirmNewPass(errorconfirmNewPassword.message);

    return (
      errorcurrentPassword.isValid &&
      errornewPassword.isValid &&
      errorconfirmNewPassword.isValid
    );
  };

  return (
    <Dialog
      open={open}
      icon={icon}
      onClose={resetForm}
      maxWidth="xs"
      title={
        <Text
          mt={2}
          sx={{ fontWeight: 500, width: "100%", textAlign: "center" }}
          variant="h5"
        >
          Change Password
        </Text>
      }
      openComponent={openComponent}
      contentComponent={() => (
        <Stack>
          <Text variant="body2" color="#202020" sx={{ textAlign: "center" }}>
            <Stack alignItems="center" sx={{ px: 2, pt: 1 }}>
              <TextField
                label="Enter current password"
                type="password"
                value={currentPassword}
                onChange={(e) => {
                  handleInputChange("currentPassword", e.target.value);
                }}
                helperText={errorcurrentPassword}
                error={errorcurrentPassword}
                sx={{ width: 1, mb: 2 }}
              />
              <TextField
                label="Enter new password"
                type="password"
                value={newPassword}
                onChange={(e) => {
                  handleInputChange("newPassword", e.target.value);
                }}
                helperText={errornewPassword}
                error={errornewPassword}
                sx={{ width: 1, mb: 2 }}
              />
              <TextField
                label="Confirm new password"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => {
                  handleInputChange("confirmNewPassword", e.target.value);
                }}
                helperText={errorconfirmNewPassword}
                error={errorconfirmNewPassword}
                sx={{ width: 1, mb: 2 }}
              />
            </Stack>
          </Text>
        </Stack>
      )}
      actionComponent={(onClose) => {
        if (!button.text) {
          return <Box m={2}></Box>;
        }

        return (
          <Box m={2} textAlign="center">
            <JarvisButton
              variant={button.variant}
              onClick={async () => {
                if (!handleValidation()) {
                  return;
                }
                setIsLoading(true);
                try {
                  //event-tracker: Change password
                  tracker.profile.changePassword();
                  var a = await customerApis.changePassword({
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                    confirmNewPassword: confirmNewPassword,
                  });
                  if (a.status === 200) {
                    onClose();
                    //event-tracker: change password success
                    tracker.profile.changePasswordSuccess();
                    passwordOpen(true);
                  }
                  setIsLoading(false);
                } catch (error) {
                  //event-tracker: Change password error
                  tracker.profile.changePasswordFailed({error_msg:error.message});
                  setIsLoading(false);
                } finally {
                  setIsLoading(false);
                }
              }}
              disableEndIcon={button.disableEndIcon}
              disabled={button.disabled}
              loading={isLoading}
            >
              {button.text}
            </JarvisButton>
          </Box>
        );
      }}
      actionComponentProps={{
        sx: {
          justifyContent: "center",
          marginBottom: "1rem",
        },
      }}
    />
  );
};

export default memo(ChangePasswordDialog);
