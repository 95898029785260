import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOnestockOnboardingDataAction: jarvsiOnestockOnboardingActions.getOnboardingData,
}, dispatch);

const OnestockInfoStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockInfoStore;
