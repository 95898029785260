import React from "react";
import { config } from "config";
import { withRouter } from "react-router";

import Loader from "components/common/Loader";
import Grid from "components/common/Grid";
import Modal from "components/common/Modal";
import Stack from "components/common/Stack";
import JarvisButton from "components/JarvisButton";
import Text from "components/common/Text";

import ChooseBrokerIcon from "components/Onboarding/ChooseBrokerIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/common/IconButton";

import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import { tracker } from "library/tracker";

import { localStore } from "stores/localStorage";
import SectionTitle from "components/SectionTitle";

class ChooseBrokerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBroker: {},
            marketStatusNote: false,
            marketStatusMessage: "",
            brokerStatusModal: false,
            brokerStatusMessage: "",
            // brokerEnvironment: false
        }
    }

    componentDidMount() {
        localStore.setWentBrokerPage(false);
        //event-tracker: Initial -> broker_page_load
        tracker.initial.brokerListing();

        if (!this.props.brokersList.length) {
            this.props.getBrokersAction({ domain: config.broker.platform.smallcase, productCode: config.productCodes.PORTFOLIO });
        }
    }

    componentDidUpdate() {
        if (this.props.brokersList.length === 1) {
            if (!this.state.brokerStatusModal && !this.state.marketStatusNote) {
              this.handleBrokerClick(this.props.brokersList[0]);
            }
        }
    }

    handleMarketStatusModalClose = () => {
        this.setState({ marketStatusNote: false });
        this.setState({ marketStatusMessage: "" })
    }

    handleBorkerStatusModalClose = () => {
        this.setState({ brokerStatusModal: false, brokerStatusMessage: "" });
    }

    // handleBrokerEnvironment = () =>{
    //   this.setState({ brokerEnvironment: true },()=>{
    //     setTimeout(() => {}, 5000)
    //   })
    // }

    handleBrokerClick = async (broker) => {
        try {
            // this.handleBrokerEnvironment();
            await thirdpartyApis.brokerSelected({ selectedBroker: broker.name })
        } catch (e) { }

        if (broker.hasAlert) {
            this.setState({ brokerStatusModal: true, brokerStatusMessage: broker.alertMessage });
            return;
        }

        //event-tracker: Initial -> broker_selected
        tracker.initial.brokerSelected();

        if (config.environment !== "DEVELOPMENT") {
            try {
                if (!this.state.marketStatusNote) {
                  const a = await thirdpartyApis.marketOpenHRStatus();
                  if (a.status === false) {
                    this.setState({
                      marketStatusNote: true,
                      marketStatusMessage: a.msg,
                    });
                    return;
                  }
                }

            } catch (error) {
                console.log(error);
            }
        }

        this.setState({ selectedBroker: broker });
        this.props.history.push({
            pathname: "/customer/broker",
            state: {
                returnURI: "/jarvis-portfolio/dashboard",
                goBackURI: "/jarvis-portfolio/onboarding/choose-broker",
                brokerName: broker.name,
                brokerPlatform: broker.platform,
                processType: "initial",
                productCode: config.productCodes.PORTFOLIO,
                executionType: config.broker.executionType.TRANSACTION,
                brokerId: broker.id,
            }
        });
    }

    render() {
        const { brokersList, isBrokersListLoading, isBrokersListInitialCalled, history, centrumReferalURL } = this.props;
        const { selectedBroker } = this.state;
        const centrumBroker = brokersList.filter((value)=>{
            return value.name === 'centrum'
        });

        return (
            <>
                {/* Back button */}
                <SectionTitle
                    sx={{ display: { xs: "flex", md: 'none' }, mb: 2 }}
                    heading="Link broker"
                    startIcon={
                        <IconButton onClick={() => this.props.history.replace('/jarvis-portfolio/onboarding/overview')}>
                            <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                        </IconButton>
                    }

                />
                <>
                <Text variant="medium" sx={{ fontWeight: 500, mb: "20px" }}> List of Brokers
                </Text>
                <Grid
              container
              spacing={2.5}
              sx={{
                justifyContent: "flex-start",
                flex: 1,
                alignItems: "center",
                mb: { xs: "0.5rem", md: "0.5rem" },
                gap:"2rem",
                paddingBottom: "25px",
                marginBottom: "20px",
              }}
            >
              {isBrokersListInitialCalled && centrumBroker.length ? (
                centrumBroker.map((b, index) => (
                //   <Grid item xs={6} sm={4} md={3} lg={2.4} xl={2} key={index}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                    <ChooseBrokerIcon
                      image={b.imageUrl}
                      title={b.displayName}
                      onClick={() => this.handleBrokerClick(b)}
                      isSelected={b.name === selectedBroker.name}
                    />
                  </Grid>
                ))
              ) : (
                <></>
              )}
              <Stack direction={{xs:"row",md:"column"}}
              justifyContent={{xs:"space-between", md:"flex-start"}}
              width={{xs:"100%",md:"fit-content"}}
              pl={{xs:"1.25rem",md:0}}
              >
              <Text
                variant="small"
                sx={{ fontWeight: 700, placeSelf: "center",}}
              >
                Don't have a Centrum account?
              </Text>
              <Text
                variant="small"
                sx={{
                  fontWeight: 500,
                  color: "#2B70C5",
                  cursor: "pointer",
                  "&::selection": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={() => {
                  window.open(centrumReferalURL, "_blank");
                }}
              >
                Open New Account
              </Text>
              </Stack>
              <Stack direction="row" ml="1.25rem" width="100%" border="1px solid #F5F5F5"></Stack>
            </Grid>
            <Grid
              container
              spacing={2.5}
              sx={{
                justifyContent: "flex-start",
                flex: 1,
                alignItems: "center",
                mb: { xs: 8, md: 2.5 }
              }}

            >
              {isBrokersListInitialCalled && brokersList.length ? (
                brokersList.map((b, index) => (
                  <>
                    {b.name === "centrum" ? (
                      <></>
                    ) : (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2.4}
                        xl={2}
                        key={index}
                      >
                        <ChooseBrokerIcon
                          image={b.imageUrl}
                          title={b.displayName}
                          onClick={() => this.handleBrokerClick(b)}
                          isSelected={b.name === selectedBroker.name}
                        />
                      </Grid>
                    )}
                  </>
                ))
              ) : (
                <Grid item>
                  <Text>Brokers not found</Text>
                </Grid>
              )}
              </Grid>
                </>
                {/* Section: market Status Modal */}
                <Modal
                    open={this.state.marketStatusNote}
                    disableClose={true}
                    size={"xs"}
                    sx={{ padding: "16px 0px" }}>
                    <Stack alignItems="center" width={1}>
                        <img src="/images/market-closed.svg" alt="" width={100}></img>
                        <Text sx={{ mt: 2, mb: 0.8, fontWeight: "bold", fontSize: "22px", textAlign: "center" }}>{this.state.marketStatusMessage}</Text>
                        <Text sx={{ mb: 3, color: "#808591cc", textAlign: "center" }} variant="small">Try again on the next market day </Text>
                        <JarvisButton variant="outlined" disableEndIcon="true" onClick={() => this.handleMarketStatusModalClose()} >Okay </JarvisButton>
                    </Stack>

                </Modal>

                {/* Section: broker status modal */}
                <Modal
                    open={this.state.brokerStatusModal}
                    disableClose={true}
                    size={"xs"}
                    sx={{ padding: "16px 0px" }}>
                    <Stack alignItems="center" width={1}>
                        <img src="/images/market-closed.svg" alt="" width={100}></img>
                        <Text sx={{ mt: 2, mb: 0.8, fontWeight: "bold", fontSize: "22px", textAlign: "center" }}>Broker Platform Alert</Text>
                        <Text sx={{ mb: 3, color: "#808591cc", textAlign: "center" }} variant="small">{this.state.brokerStatusMessage}</Text>
                        <JarvisButton variant="outlined" disableEndIcon="true" onClick={() => this.handleBorkerStatusModalClose()}>Okay </JarvisButton>
                    </Stack>
                </Modal>
                <Loader loading={isBrokersListLoading} />

                {/* Pop-up for showing leaving jarvis website to broker website */}
{/*                 <Modal
                open={this.state.brokerEnvironment}
                disableClose={true}
                size={"xs"}
                sx={{ width:"100%", height: { xs:"75%" } }}
                >
                <Text sx={{ mt: {xs:"75%", sm: "35%"}, mb: 0.8, fontSize: "22px", textAlign: "center" }}>You are leaving our environment</Text>
                </Modal> */}
            </>
        )
    }
}

export default withRouter(ChooseBrokerContainer);
