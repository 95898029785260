import { transactionConstants } from "./transactionConstants";

const transactionActions = {};

transactionActions.getTransactionList = ({ page, rowsPerPage }) => ({
    type: transactionConstants.TRANSACTION_LIST_SAGA,
    payload: { page, rowsPerPage },
});

export { transactionActions };
