import * as coreAxios from "axios";
import { customerActions } from "stores/customer/customerActions";
import { commonActions } from "stores/common/commonActions";
import { config } from "../config";
import { localStore } from "../stores/localStorage";

import _get from "lodash.get";

// Apis of new server
export const axios = coreAxios.default.create({
    baseURL: config.apiUrl,
});

export const axiosNT = coreAxios.default.create({
    baseURL: config.apiUrl,
});

const readRequestDataFromError = (error) => {
    if (error.config.headers["Content-Type"] === "application/json") {
        return JSON.parse(error.config.data);
    }
    return {}
}

export const axiosInterceptor = (dispatch) => {
    axiosNT.interceptors.request.use(async (request) => {
        const authToken = localStore.getToken();
        const legacyAuthToken = localStore.getLegacyToken();

        if (authToken) {
            request["headers"]["Authorization"] = `${authToken}`;
        }   

        if (legacyAuthToken) {
            request["headers"]["Authorization02"] = `${legacyAuthToken}`;
        }

        return request;
    });

    //response interceptors
    axiosNT.interceptors.response.use(
        async (response) => {
            if (!response.data) {
                return response;
            }

            return response.data;
        },
        (error) => {

            const { response } = error;
            const errorCode = _get(response, "data.code", "");
            const dialogMessage = _get(response, "data.message", "");
            const avoidToastError = ["JEI012", "JSU017"];

            if (response) {
                const { status, data } = response;
                if (status === 400) {
                    // toastMessage = data.message;
                } else if (status === 401) {
                    // toastMessage = data.message;
                    dispatch(customerActions.logout());
                }
            }

            if (response && response.status !== 401 && !(avoidToastError.includes(errorCode))) {
                
            } else {
                if (errorCode === "JSU017") {
                    const { email = "" } = readRequestDataFromError(error);
                    dispatch(customerActions.setDeactiveAccountData({ deactiveAccountEmail: email, deactiveAccountMessage: response.data.message }))
                }
            }

            if (errorCode === "JEAIPHP016") {
                dispatch(commonActions.setToast({ message: "" }));
                dispatch(commonActions.setDialog({ message: dialogMessage }))
            }

            return Promise.reject(error);
        }
    );

    axios.interceptors.request.use(async (request) => {
        const authToken = localStore.getToken();
        const legacyAuthToken = localStore.getLegacyToken();

        if (authToken) {
            request["headers"]["Authorization"] = `${authToken}`;
        }   

        if (legacyAuthToken) {
            request["headers"]["Authorization02"] = `${legacyAuthToken}`;
        }

        return request;
    });

    //response interceptors
    axios.interceptors.response.use(
        async (response) => {
            if (!response.data) {
                return response;
            }

            return response.data;
        },
        (error) => {

            const { response } = error;
            const errorCode = _get(response, "data.code", "");
            const dialogMessage = _get(response, "data.message", "");
            let toastMessage = _get(response, "data.message", "Something went wrong! Please contact support team");
            const avoidToastError = ["JEI012", "JSU017", "JRMSSE006"];

            if (response) {
                const { status, data } = response;
                if (status === 400) {
                    // toastMessage = data.message;
                } else if (status === 401) {
                    // toastMessage = data.message;
                    dispatch(customerActions.logout());
                }
            }

            if (response && response.status !== 401 && !(avoidToastError.includes(errorCode))) {
                dispatch(commonActions.setToast({ message: toastMessage }));
            } else {
                if (errorCode === "JSU017") {
                    const { email = "" } = readRequestDataFromError(error);
                    dispatch(customerActions.setDeactiveAccountData({ deactiveAccountEmail: email, deactiveAccountMessage: response.data.message }))
                }
            }

            if (errorCode === "JEAIPHP016") {
                dispatch(commonActions.setToast({ message: "" }));
                dispatch(commonActions.setDialog({ message: dialogMessage }));
            }

            // NOTE: Show popup if stock limit exeed. API: /rms/portfolio/confirm
            if(errorCode === "JRMSSE006"){
                const message = `According to your current subscription plan, you have reached the limit of adding more stocks. If you wish to add more stocks we recommend upgrading your current plan.`;
                dispatch(commonActions.setDialog({ message, open: true, onCloseTakeToIntercom: true, heading: "Exceed subscription plan limit"  }));
            }

             // NOTE: Show popup if portfolio limit exeed. API: /rms/portfolio/confirm
            if(errorCode === "JRMSPE005"){
                const message = `According to your current subscription plan, you have reached the limit of adding more portfolios. If you wish to add more portfolios to your profile, we recommend upgrading your current plan.`;
                dispatch(commonActions.setDialog({ message, open: true, onCloseTakeToIntercom: true, heading: "Exceed subscription plan limit"  }));
            }

            return Promise.reject(error);
        }
    );
};

// Apis of legacy server
export const axiosLegacy = coreAxios.default.create({
    baseURL: config.legacyApiUrl,
});

export const axiosLegacyInterceptor = (dispatch) => {
    axiosLegacy.interceptors.request.use(async (request) => {
        const authToken = localStorage.getItem("token");

        if (authToken) {
            request["headers"]["Authorization"] = `Bearer ${authToken}`;
        }

        return request;
    });

    //response interceptors
    axiosLegacy.interceptors.response.use(
        async (response) => {
            if (!response.data) {
                return response;
            }

            return response.data;
        },
        (error) => {
            console.log("Api Error", { error });
            // commonResponse(error.response);
            return Promise.reject(error);
        }
    );
};
