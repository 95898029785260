import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvisOnestockExistingHoldingAction } from "stores/jarvisOnestock/jarvisOnestockExistingHolding/jarvisOnestockExistingHoldingAction";

const mapStateToProps = (state) => {
    return {
        existingHoldingListStore: state.jarvisOnestock.onestockExistingHolding.existingHoldingList,
        existingHoldingLoading: state.jarvisOnestock.onestockExistingHolding.loading.existingHoldingList,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getExistingHoldingListAction: jarvisOnestockExistingHoldingAction.getExistingHoldingList,
}, dispatch);

const OnestockExistingHoldingStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockExistingHoldingStore;
