import { call, put, takeLatest } from "@redux-saga/core/effects";

import { helpSupportApis } from "./helpSupportApis";
import { helpSupportParser } from "./helpSupportParsers";
import { helpSupportConstants } from "./helpSupportConstants";
import { tracker } from "library/tracker";

function* getCategoryList() {
    try {
        yield put({ type: helpSupportConstants.CATEGORY_LIST_REQUEST });

        const res = yield call(helpSupportApis.categoryList);

        const payload = helpSupportParser.categoryList(res);
        //NOTE : event_tracker => helpAndSupport
        tracker.helpAndSupport.helpAndSupportViewAllSuccess();
        yield put({
            type: helpSupportConstants.CATEGORY_LIST_RESPONSE,
            payload,
        });
    } catch (e) {
        //NOTE : event_tracker => helpAndSupport
        tracker.helpAndSupport.helpAndSupportViewAllSuccess();
        yield put({
            type: helpSupportConstants.CATEGORY_LIST_RESPONSE,
            payload: [],
        });
    }
}

function* getCategoryData({ payload }) {
    const { categoryID } = payload;
    try {
        yield put({ type: helpSupportConstants.CATEGORY_DATA_REQUEST });
        const res = yield call(helpSupportApis.categoryData, { categoryID });

        const payload = helpSupportParser.categoryData(res);
        
        yield put({
            type: helpSupportConstants.CATEGORY_DATA_RESPONSE,
            payload: {categoryID, data: payload},
        });
    } catch (e) {
        yield put({
            type: helpSupportConstants.CATEGORY_DATA_RESPONSE,
            payload: {},
        });
    }
}

function* getCategoryQuestions({ payload }) {
    const { selectedCategoryID, questionID } = payload;
    try {
        yield put({ type: helpSupportConstants.CATEGORY_QUESTIONS_REQUEST });

        const res = yield call(helpSupportApis.categoryQuestions, {
            selectedCategoryID,
            questionID,
        });

        const payload = helpSupportParser.categoryQuestion(res);

        yield put({
            type: helpSupportConstants.CATEGORY_QUESTIONS_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: helpSupportConstants.CATEGORY_QUESTIONS_RESPONSE,
            payload: [],
        });
    }
}

export function* registerhelpSupportSaga() {
    yield takeLatest(helpSupportConstants.CATEGORY_LIST_SAGA, getCategoryList);
    yield takeLatest(helpSupportConstants.CATEGORY_DATA_SAGA, getCategoryData);
    yield takeLatest(
        helpSupportConstants.CATEGORY_QUESTIONS_SAGA,
        getCategoryQuestions
    );
}
