import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";
import { thirdpartyActions } from "stores/thirdparty/thirdpartyActions";

const mapStateToProps = (state) => {
    return {
        renewalPricingDetails: state.customer.renewalPricingDetails,
        isLoading: state.customer.loading.renewalPricingDetails,

        subscriptionPlans: state.thirdparty.subscriptionPlans,
        isSubscriptionPlansLoading: state.thirdparty.loading.subscriptionPlans,
        isSubscriptionPlansCalled:
            state.thirdparty.isInitialCalled.subscriptionPlans,

        // Investment Amount.
        minInvestmentAmount: state.onboarding.minInvestmentAmount,
        onBoardingInvestmentAmount: state.onboarding.investmentAmount,
        customerProfileData: state.customer.profile,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRenewSubscriptionAction:
                customerActions.getRenewalPricingDetails,
            getSubscriptionPlansAction: thirdpartyActions.getSubscriptionPlans,
            getProfileDataAction : customerActions.getProfile,
        },
        dispatch
    );

const PlanAndPricingStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PlanAndPricingStore;
