import React from "react";
import { withStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";

import Stack from "components/common/Stack";

class MenuItemsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMenuList = () => {
    const { isOnboardingCompleted } = this.props;
    return [
      // {
      //   menuName: "Transactions",
      //   icon: "/images/mobileApp/more-transactions.svg",
      //   redirectLink: "/customer/transactions",
      //   isDisabled: !isOnboardingCompleted,
      // },
      {
        menuName: "Blogs",
        icon: "/images/mobileApp/more-blogs.svg",
        redirectLink: "/knowledge-centre/blogs",
      },
      {
        menuName: "Market Updates",
        icon: "/images/mobileApp/more-market-updates.svg",
        redirectLink: "/knowledge-centre/market-updates",
      },
      {
        menuName: "News & Articles",
        icon: "/images/mobileApp/more-news-articles.svg",
        redirectLink: "/knowledge-centre/news-articles",
      },
      // {
      //   menuName: "Refer JARVIS",
      //   icon: "/images/mobileApp/more-refer.svg",
      //   redirectLink: "/customer/refer-jarvis",
      // },
      {
        menuName: "Help & Support",
        icon: "/images/mobileApp/more-help-support.svg",
        redirectLink: "/knowledge-centre/help-support",
      },
    ];
  };

  handleRedirect = (pathName) => {
    this.props.history.push(pathName);
  };

  // Render
  render() {
    const { classes } = this.props;

    return (
      <Stack spacing={1.5} className={classes.itemWrap}>
        {this.getMenuList().map((m, i) => (
          <button
            key={`menuItem_${i}`}
            onClick={() => this.handleRedirect(m.redirectLink)}
            disabled={m.isDisabled}
          >
            <Stack direction="row">
              <img src={m.icon} alt="icon" />
              <span>{m.menuName}</span>
            </Stack>
            <i />
          </button>
        ))}
      </Stack>
    );
  }
	
} // Class Ends


const style = (theme) => ({
  itemWrap: {
    "& > button": {
			display: "flex",
      flexFlow: "row nowrap",
      border: "1px solid #F1F1F1",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 13px",
      borderRadius: "10px",
      backgroundColor: "#FFFFFF",
      fontSize: "16px",
      fontWeight: "600",
      cursor: "pointer",
      outline: "none",
      "& img": {
        width: "18px",
        marginRight: "15px",
      },
      "& span": {
        color: "#252C32",
      },
      "& > i": {
        width: "13px",
        height: "13px",
        border: "2px solid #32384b",
        borderStyle: "solid solid none none",
        transform: "rotate(45deg)",
        borderRadius: "0 5px 0 0",
        marginRight: "3px",
      },
    },
    "& button[disabled]": {
      cursor: "not-allowed",
    },
    "& button[disabled] > div": {
      opacity: 0.3,
    },
    "& button[disabled] > i": {
      opacity: 0.3,
    },
  },
});

export default withRouter(
  withStyles(style, { withTheme: true })(MenuItemsContainer)
);
