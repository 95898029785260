import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return {
        currentStage: state.jarvisOnestock.onestockOnboarding.currentStage,
        panNumber: state.jarvisOnestock.onestockOnboarding.panNumber,
        dateOfBirth: state.jarvisOnestock.onestockOnboarding.dateOfBirth,
        isPanUploaded: state.jarvisOnestock.onestockOnboarding.isPanUploaded,
        isAadhaarFrontUploaded:
            state.jarvisOnestock.onestockOnboarding.isAadhaarFrontUploaded,
        isAadhaarBackUploaded:
            state.jarvisOnestock.onestockOnboarding.isAadhaarBackUploaded,
        isManualKYC: state.jarvisOnestock.onestockOnboarding.isManualKYC,
        loginProfile: state.customer.loginProfile,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getOnboardingDataAction:jarvsiOnestockOnboardingActions.getOnboardingData,
            updateOnestockOnboardingData:jarvsiOnestockOnboardingActions.updateOnestockOnboardingData,
            setToast: commonActions.setToast,
        },
        dispatch
    );

const OneStockCkycStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OneStockCkycStore;
