import { call, put, takeLatest } from "@redux-saga/core/effects";
import { rmsConstants } from "./rmsConstants";
import { rmsApis } from "./rmsApis";
import { rmsParsers } from "./rmsParsers";
import { tracker } from "library/tracker";


function* rmsSaga(){
    try{
        yield put({type: rmsConstants.RMS_REQUEST});
        const resData = yield call(rmsApis.getRMSDetails);
        const ParsedData = rmsParsers.rmsDetails(resData);
        yield put({type: rmsConstants.RMS_RESPONSE, payload: ParsedData});
        //NOTE event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.portfolioGenerationSuccess();
    }catch(e){
        yield put({type: rmsConstants.RMS_RESPONSE, payload: []});
        //NOTE event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.portfolioGenerationFailed({error_msg:e.message});
    }
}

export function* registerRMSSaga(){
    yield takeLatest(rmsConstants.RMS_SAGA, rmsSaga);
}

