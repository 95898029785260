import _get from "lodash/get";

const helpSupportParser = {};

helpSupportParser.categoryList = (res) => {
  let data = [];

  if (res && res.response) {
    res = res.response;
  }

  if (!res || !res.length) {
    return data;
  }

  const parseOptions = (options) =>
    options.map((o) => ({
      title: _get(o, "title", null),
      id: _get(o, "id", null),
    }));

  data = res.map((r) => ({
    id: _get(r, "id", null),
    category: _get(r, "category", null),
    options: parseOptions(_get(r, "options", [])),
  }));

  return data;
};

helpSupportParser.categoryData = (res) => {
  let data = [];

  if (res && res.response) {
    res = res.response;
  }

  if (!res || !Object.keys(res).length) {
    return data;
  }

  data = res.map((r) => ({
    id: _get(r, "id", null),
    title: _get(r, "title", null),
    description: _get(r, "description", null),
  }));

  return data;
};

helpSupportParser.categoryQuestion = (res) => {
  let data = {};

  if (res && res.response) {
    res = res.response;
  }

  if (!res || !Object.keys(res).length) {
    return data;
  }

  data = {
    title: _get(res, "title", null),
    description: _get(res, "description", null),
  };

  return data;
};

export { helpSupportParser };
