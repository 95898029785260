import React from "react";
import _get from "lodash.get";
import { withRouter } from "react-router-dom";

import JarvisDialog from "components/JarvisDialog";

import { customerApis } from "stores/customer/customerApis";
import { customerParsers } from "stores/customer/customerParsers";

import { localStore } from "stores/localStorage";
import { tracker } from "library/tracker";
import { config } from "config";
import { masterApis } from "stores/master/masterApis";

const SSO_LOGIN_STATUS = {
    FAILED: "FAILED",
    SUCCEED: "SUCCEED",
    INPROGRESS: "INPROGRESS",
    SUCCEED_ROBO: "SUCCEED_ROBO",
}



const SSO_LOGIN_MESSAGE = {
    FAILED: {
        title: "Oops!!!",
        content: "Something went wrong! Please contact our support",
        actionText: "Go Back",
        icon: "/images/svgs/failed.gif"
    },
    SUCCEED: {
        title: "Congrats!!!",
        content: "You are just 6 simple steps away from hiring Jarvis' AI-powered stock advisory service.",
        actionText: "Complete signup",
        icon: "/images/svgs/success.gif"
    },
    ONESTOCK_SUCCEED: {
        title: "Congrats!!!",
        content: "You are just 3 simple steps away from hiring Jarvis' AI-powered stock advisory service.",
        actionText: "Complete signup",
        icon: "/images/svgs/success.gif"
    },
    SUCCEED_ROBO: {
        title: "Congrats!!!",
        content: "You are just 3 simple steps away from hiring Jarvis' AI-powered stock advisory service.",
        actionText: "Proceed to payment",
        icon: "/images/svgs/success.gif"
    },
    INPROGRESS: {
        title: "In Progress",
        content: "You will be redirected to Jarvis Invest to complete your signup journey",
        actionText: "",
        icon: "/images/svgs/inprogress.gif"
    },
}

class BrokerSSOLoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogContent: {
                title: "",
                content: "",
                actionText: "",
                icon: "",
            },
            ssoLoginStatus: "",
            dialogOpen: true,
            loginResponse: {},
        }
    }

    componentDidMount() {
        // masterApis.updateLastActiveProduct();
        // this.props.logoutAction();
        this.handleSSOLogin();
    }

    getValueFromLocation = (key) => {
        let v = _get(this.props.location.state, key, "");
        if (!v) {
          const qParams = new URLSearchParams(this.props.location.search);
          v = qParams.get(key);
        }
        return v;
      }

    handleSSOLogin = async () => {
        let redirectToProductInfoPage = false
        let token = this.getValueFromLocation("token")
        let broker = this.getValueFromLocation("utm_source")
        let productCode = this.getValueFromLocation('product')
        let offerCode = this.getValueFromLocation('offer_code')
        let bnrathiToken =!token && !broker && !productCode && !offerCode?window.location.search.replace('?',''):''
        if(broker?.toLowerCase() === 'bigul' && !productCode){
            redirectToProductInfoPage = true;
        }
        if(bnrathiToken){
            redirectToProductInfoPage = true
        }
        productCode= productCode?productCode:"portfolio";
        offerCode = offerCode?offerCode:"";
        if(productCode?.toLowerCase()==="protect"){
            productCode="rms"
        }
        if ((!token || !broker) && !bnrathiToken) {
            return this.handleDialogContentChange({ ssoLoginStatus: SSO_LOGIN_STATUS.FAILED });
        }
        if(bnrathiToken){
            token = bnrathiToken;
            broker='bnrathi'
            productCode= '';
        }
        
        try {
            localStore.setSsoCustomerDetails({token,broker,productCode,offerCode });
            this.handleDialogContentChange({ ssoLoginStatus: SSO_LOGIN_STATUS.INPROGRESS });
            
            const response = await customerApis.brokerSSOLoginApi({token,broker,isSso:1});
            const parsedResponse = customerParsers.login(response);
            localStore.setIsSsoCustomer(parsedResponse.isSso?true:false);
            const ssoCustomerDetails=localStore.getSsoCustomerDetails()
            ssoCustomerDetails.clientCode=parsedResponse.clientId
            ssoCustomerDetails.brokerName = parsedResponse.brokerName;
            localStore.setSsoCustomerDetails(ssoCustomerDetails);
            console.log(parsedResponse, "parsed response");

            localStore.setToken(parsedResponse.token);
            localStore.setLegacyToken(parsedResponse.legacyToken);
            localStore.setActiveProduct(parsedResponse.lastActiveProduct);
            this.props.updateOnboardingDataAction(parsedResponse.onboardingData);
            this.props.updateRMSOnboardingDataAction(parsedResponse.rmsOnboardingData);
            this.props.updateOnestockOnboardingDataAction(parsedResponse.onestockOnboardingData);

            // call protect onboarding details update
            this.props.updateAuthDataAction(parsedResponse);
            this.setState({ loginResponse: parsedResponse })

            // NOTE: event_tracker => registration 
            tracker.registration.signUpSuccess({
                name: parsedResponse.name,
                loginType: "BROKER_SSO",
                deviceId: this.props.deviceId,
                deviceType: config.platform,
                customerId: parsedResponse.customerId,
            });
            if (parsedResponse.isExistingCustomer) {
                // if the product code is not mentioned in sso url then the page is redirected to the product info page for bigul and bnrathi
                if(redirectToProductInfoPage){
                    return this.props.history.replace({ pathname: "/customer/product-info"})
                }
                if (productCode.toUpperCase() === config.productCodes.PORTFOLIO) {
                    if (parsedResponse.onboardingData.isOnboardingCompleted) {
                        return this.props.history.replace({ pathname: "/jarvis-portfolio/dashboard" });
                    }
                    if(parsedResponse.brokerName === config.broker.ssoPlatform.centrum) {
                        return this.props.history.replace({ pathname: "/jarvis-portfolio/onboarding", state: { riskProfileId: parsedResponse.riskProfileId }});
                    }
                    return this.props.history.replace({ pathname: "/jarvis-portfolio/onboarding/overview" });
                }
                if(bnrathiToken && broker === 'bnrathi'){
                    return this.props.history.replace({ pathname: "/customer/product-info"})
                }

                if (productCode.toUpperCase() === config.productCodes.RMS) {
                    if (parsedResponse.rmsOnboardingData.isOnboardingCompleted) {
                        return this.props.history.replace({ pathname: "/jarvis-rms/dashboard" });
                    }
                    if(parsedResponse.brokerName === config.broker.ssoPlatform.centrum) {
                        return this.props.history.replace({ pathname: "/jarvis-rms/onboarding", state: { riskProfileId: parsedResponse.riskProfileId } });
                    }
                    return this.props.history.replace({ pathname: "/jarvis-rms/onboarding/overview" });
                }
                if (productCode.toUpperCase() === config.productCodes.ONESTOCK) {
                    if (parsedResponse.onestockOnboardingData.isOnboardingCompleted) {
                        return this.props.history.replace({ pathname: "/jarvis-onestock/dashboard" });
                    }
                    if(parsedResponse.brokerName === config.broker.ssoPlatform.centrum) {
                        return this.props.history.replace({ pathname: "/jarvis-onestock/onboarding", state: { riskProfileId: parsedResponse.riskProfileId } });
                    }
                    return this.props.history.replace({ pathname: "/jarvis-onestock/onboarding/overview"});
                }
            } else {
                let ssoLoginStatus = SSO_LOGIN_STATUS.SUCCEED;
                if(redirectToProductInfoPage){
                    return this.props.history.replace({ pathname: "/customer/product-info"})
                }
                if (productCode.toUpperCase() === config.productCodes.PORTFOLIO) {
                    if(parsedResponse.brokerName === config.broker.ssoPlatform.centrum){
                        return this.props.history.replace({ pathname: "/jarvis-portfolio/onboarding", state: { riskProfileId: parsedResponse.riskProfileId } });
                    }
                    return this.props.history.replace({ pathname: "/jarvis-portfolio/onboarding/overview" });
                }
                if (productCode.toUpperCase() === config.productCodes.RMS) {
                    if(parsedResponse.brokerName === config.broker.ssoPlatform.centrum){
                        return this.props.history.replace({ pathname: "/jarvis-rms/onboarding", state: { riskProfileId: parsedResponse.riskProfileId } });
                    }
                    return this.props.history.replace({ pathname: "/jarvis-rms/onboarding/overview" });
                }
                if (productCode.toUpperCase() === config.productCodes.ONESTOCK) {
                    if(parsedResponse.brokerName === config.broker.ssoPlatform.centrum){
                        return this.props.history.replace({ pathname: "/jarvis-onestock/onboarding", state: { riskProfileId: parsedResponse.riskProfileId } });
                    }
                    return this.props.history.replace({ pathname: "/jarvis-onestock/onboarding/overview" });
                }
                this.handleDialogContentChange({ ssoLoginStatus });
            }
        } catch (error) {
            this.handleDialogContentChange({ ssoLoginStatus: SSO_LOGIN_STATUS.FAILED });
        }
    }

    handleDialogContentChange = ({ ssoLoginStatus }) => {
        const { loginResponse } = this.state
        const ssoCustomerDetails = localStore.getSsoCustomerDetails()
        let dialogContent ="";
        let productCode;
        if(ssoCustomerDetails!==null){
            productCode= ssoCustomerDetails?.productCode
            
        }
        
        if(productCode === config.productCodes.ONESTOCK && ssoLoginStatus == "SUCCEED"){
            dialogContent = SSO_LOGIN_MESSAGE["ONESTOCK_SUCCEED"];    
        }else{
            dialogContent = SSO_LOGIN_MESSAGE[ssoLoginStatus];
        }

        this.setState({ ssoLoginStatus, dialogContent });
    }

    handleDialogClose = () => {
        const { loginResponse } = this.state
        setTimeout(() => {
            this.setState({
                dialogContent: {
                    title: "",
                    content: "",
                    actionText: "",
                    icon: "",
                },
                ssoLoginStatus: "",
                dialogOpen: true,
            }, () => {
                document.body.style.overflow = "unset";
                if (loginResponse.lastActiveProduct === config.productCodes.PORTFOLIO) {
                    if (loginResponse.onboardingData.currentStage === 2) {
                        return this.props.history.replace({ pathname: "/jarvis-portfolio/onboarding/portfolio-creation" });
                    }
                    return this.props.history.replace({ pathname: "/jarvis-portfolio/onboarding/overview" });
                }

                if (loginResponse.lastActiveProduct === config.productCodes.RMS) {
                    return this.props.history.replace({ pathname: "/jarvis-rms/onboarding/overview" });
                }

                if (loginResponse.lastActiveProduct === config.productCodes.ONESTOCK) {
                    return this.props.history.replace({ pathname: "/jarvis-onestock/onboarding/overview" });
                }

                return this.props.history.replace({ pathname: "/customer/product-info" });
            });
        }, 1000)
    }

    render() {
        const { dialogOpen, dialogContent, ssoLoginStatus } = this.state;
        return (
            <JarvisDialog
                disableCloseIcon
                open={dialogOpen}
                onClose={this.handleDialogClose}
                onConfirm={this.handleDialogClose}
                title={dialogContent.title}
                content={dialogContent.content}
                icon={{
                    src: dialogContent.icon,
                    height: 120,
                    width: 120
                }}
                button={{
                    text: dialogContent.actionText,
                    loading: ssoLoginStatus === SSO_LOGIN_STATUS.INPROGRESS,
                }}
            />
        )
    }
}

export default withRouter(BrokerSSOLoginContainer);