import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const initialTracker = {};

initialTracker.iProceedToPay = () => {
    const eventName = "i_proceed_to_pay";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
    });
};

// Analytics_tracker_active
initialTracker.iPaymentCompleted = () => {
    const eventName = "i_payment_completed";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { },
    });
};

initialTracker.iPaymentFailed = ({ reason }) => {
    // const eventName = "i_payment_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { reason },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { reason },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: {reason },
    // });
};

initialTracker.portfolioViewed = () => {
    // const eventName = "portfolio_viewed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

initialTracker.startInvestingAfterPayment = () => {
    // const eventName = "start_investing_after_payment";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

initialTracker.submitCkyc = () => {
    // const eventName = "submit_ckyc";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

initialTracker.submitCkycError = ({ error_msg }) => {
    // const eventName = "submit_ckyc_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

// Analytics_tracker_active
initialTracker.submitCkycSuccess = () => {
    const eventName = "submit_ckyc_success";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: {  },
    });
};

initialTracker.submitManualCkyc = () => {
    // const eventName = "submit_manual_ckyc";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

initialTracker.submitManualCkycError = ({ error_msg }) => {
    // const eventName = "submit_manual_ckyc_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

// Analytics_tracker_active
initialTracker.submitManualCkycSuccess = () => {
    const eventName = "submit_manual_ckyc_success";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { },
    });
};

initialTracker.proceedToClientAgreement = () => {
    // const eventName = "proceed_to_client_agreement";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

initialTracker.proceedToClientAgreementError = ({ error_msg }) => {
    // const eventName = "proceed_to_client_agreement_error_";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

// Analytics_tracker_active
initialTracker.proceedToClientAgreementSuccess = () => {
    const eventName = "proceed_to_client_agreement_success";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });
    uxcamTracker.trackEvents({
        eventName,
        eventData: {},
    });
};

initialTracker.brokerListing = () => {
    // const eventName = "broker_listing";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

initialTracker.brokerSelected = () => {
    const eventName = "broker_selected";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { },
    });
};

initialTracker.brokerPageLoad = () => {
    // const eventName = "broker_page_load";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

// Analytics_tracker_active
initialTracker.stockTransactionSuccess = ({ msg } = {}) => {
    const eventName = "stock_transaction_success";

    appsFlyerTracker.trackEvents({
        eventName,
        eventData: { msg },
    });

    mixpanelTracker.trackEvents({
        eventName,
        eventData: { msg },
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { msg },
    });

    uxcamTracker.stopSession();  // stop session on complete first transaction.     
};

initialTracker.stockTransactionError = ({ error_msg }) => {
    // const eventName = "stock_transaction_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
    
    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

// Analytics_tracker_active
initialTracker.dashboardLoaded = () => {
    // const eventName = "dashboard_loaded";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });

};

export { initialTracker };
