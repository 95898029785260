import { call, put, takeLatest } from "@redux-saga/core/effects";

import { jarvisRMSExistingHoldingConstants } from "./jarvisRMSExistingHoldingConstants";
import { jarvisRMSExistingHoldingParser } from "./jarvisRMSExistingHoldingParser"
import { jarvisRMSExistingHoldingApis } from "./jarvisRMSExistingHoldingApis";

function* portfolioListSaga() {
    try {
        yield put({ type: jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_REQUEST });
        const res = yield call(jarvisRMSExistingHoldingApis.getPortfolioList);
        const payload = jarvisRMSExistingHoldingParser.portfolioList(res);
        yield put({
            type: jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_RESPONSE,
            payload,
        });
    } catch (error) {
        yield put({
            type: jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_RESPONSE,
            payload: [],
        });
    }
}

function* stockListSaga({ payload }) {
    try {
        yield put({ type: jarvisRMSExistingHoldingConstants.STOCK_LIST_REQUEST });
        const res = yield call(jarvisRMSExistingHoldingApis.getStockList, { portfolioId: payload.portfolioId, isTemp: payload.isTemp });
        const parsedRes = jarvisRMSExistingHoldingParser.stockList(res);
        yield put({
            type: jarvisRMSExistingHoldingConstants.STOCK_LIST_RESPONSE,
            payload: parsedRes,
        });
    } catch (error) {
        yield put({
            type: jarvisRMSExistingHoldingConstants.STOCK_LIST_RESPONSE,
            payload: [],
        });
    }
}


export function* registerJarvisRMSExistingHoldingSagas() {

    yield takeLatest(
        jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_SAGA,
        portfolioListSaga
    );

    yield takeLatest(
        jarvisRMSExistingHoldingConstants.STOCK_LIST_SAGA,
        stockListSaga
    );

}
