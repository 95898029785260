import React from "react";
import PageTitle from "components/PageTitle";
import ManualDocumentSigningContainer from "containers/ManualDocumentSigning";

const ManualDocumentSigning = (props) => {
    return (
        <>
            {/* <PageTitle heading="Document Signing" /> */}
            <ManualDocumentSigningContainer {...props} />
        </>
    )
}

export default ManualDocumentSigning;