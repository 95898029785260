import React from "react";

import { formatHelper } from "helper/format";

import Stack from "components/common/Stack";
import Text from "components/common/Text";

import JarvisButton from "components/JarvisButton";
import SectionTitle from "components/SectionTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/common/IconButton";

const PreviewInvestmentDetails = ({
    investmentInfo,
    selectedRiskProfile,
    selectedInvestmentStrategy,
    onEdit,
    onConfirm,
    onBackClick,
}) => {
    return (
        <Stack>
            {/* <SectionTitle sx={{ mb: 2 }} heading="Preview" disableBreadcrumb /> */}
            {/* Back button */}
            <SectionTitle
                sx={{ display: { xs: "flex", md: 'none' } }}
                heading="Preview"
                startIcon={
                    <IconButton onClick={() => { onBackClick()}}>
                        <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                    </IconButton>
                }
            />

            <Text component="p" sx={{ mb: 1.2, color: "text.secondary", mt: 2}}>
                I want to create portfolio worth {" "}<Text component="span" sx={{ color: "text.primary", fontWeight: "medium" }}>{formatHelper.currency(investmentInfo.investmentAmount)}</Text>
            </Text>
            <Text component="p" sx={{ mb: 1.2, color: "text.secondary" }}>
                I will stay invested for <Text component="span" sx={{ color: "text.primary", fontWeight: "medium" }}>{investmentInfo.investmentTenure} year{investmentInfo.investmentTenure > 1 && "s"}</Text>
            </Text>
            <Text component="p" sx={{ mb: 1.2, color: "text.secondary" }}>
                I am <Text component="span" sx={{ color: "text.primary", fontWeight: "medium" }}>{formatHelper.checkVowel(selectedRiskProfile?.name) ? "an" : "a"} {selectedRiskProfile?.name}</Text> investor
            </Text>
            <Text component="p" sx={{ mb: 1.2, color: "text.secondary" }}>
                My investment strategy is <Text component="span" sx={{ color: "text.primary", fontWeight: "medium" }}>{selectedInvestmentStrategy.heading}</Text>
            </Text>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" mt={2} sx={{
                 position: { md: "static"}, 
                 bottom: "16px", 
                 width: { md: "auto"}
            }}>
                <JarvisButton onClick={onEdit} variant="outlined" disableEndIcon sx={{ mr: 2, width:{xs:'calc(100%)', md:'auto'} }}>Edit</JarvisButton>
                <JarvisButton onClick={onConfirm} variant="contained" sx={{width:{xs:'calc(100%)', md:'auto'}}}>Confirm</JarvisButton>
            </Stack>
        </Stack>
    )
}

export default PreviewInvestmentDetails;