import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
    return {
        loginProfile: state.customer.loginProfile,
        rmsOnboardingData: state.jarvisRMS.rmsOnboarding,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const SubscriptionPaymentStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default SubscriptionPaymentStore;
