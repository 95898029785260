import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";
import { onboardingActions } from "stores/onboarding/onboardingActions";

const mapStateToProps = (state) => {
	return {
		isOnboardingCompleted: state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const RMSDashboardStore = (Container) =>
	connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSDashboardStore;
