import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";

const mapStateToProps = (state) => {
  return {
    profile: state.customer.profile,
    isProfileLoading: state.customer.loading.profile,
    isOnboardingCompleted: state.onboarding.isOnboardingCompleted,
    enableAccountDeactivation: state.common.featureConfig.enableAccountDeactivation,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProfileDataAction: customerActions.getProfile,
      updateProfileAction: customerActions.updateProfile,
      logoutAction: customerActions.logout,
    },
    dispatch
  );

const AppMoreStore = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default AppMoreStore;
