import React, { useState } from 'react';
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import JarvisCard from "components/JarvisCard";
import { formatHelper } from "helper/format";
import Loader from "components/common/Loader";
import { Box, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InfoIcon from '@mui/icons-material/Info';
import Modal from "components/common/Modal";
import IconButton from "components/common/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const yourInvestment = {
  "Current Investment:":"Amount currently invested in open calls.",
  "Market Value (Unrealized):":"The current market value of the amount invested in open calls.",
  "Gain/Loss:":"The overall gain or loss made since the beginning of the investment, including both open and closed calls.",
  "Open Gain/Loss:":" Unrealized profit or loss on current open investments.",
  "Closed Gain/Loss:":"Realized profit or loss from closed investments.",
  "Executed Calls:":"No. of recommendations that have been executed by the customer.",
}

const jarvisOneStockPerformence = {
  "Total Calls:":"Total number of calls recommended by Jarvis.",
  "Open Calls:":"Number of calls recommended by Jarvis that have not been sold yet.",
  "Closed Calls:":"Number of calls recommended by Jarvis that have been sold.",
  "Open Calls Gain/Loss (in %):" :"Unrealized profit or loss on current open investments recommended by Jarvis.",
  "Close Calls Gain/Loss (in %):":"Realized profit or loss on current close investments recommended by Jarvis."
}




const MobileOnestockDashboardStats = (props) => {
  const {
    onestockDashboardStats,
    onestockDashboardLoading,
    handleRealizedReturnSwitch,
    handleCallReturnSwitch,
    RealizedDownArrowBtn,
    realizedUpArrowBtn,
    realisedReturnStatus,
    callReturnDownArrowBtn,
    callReturnUpArrowBtn,
    callReturnStatus,
  } = props;

  const [isYourInvestMentPopup, setIsYourInvestMentPopup] = useState(false);
  const [isJarvisOneStockMentPopup, setIsJarvisOneStockMentPopup] = useState(false);

  const customerCards = [
    {
      // code: "Invested amount",
      code: "Current Investment",
      value: formatHelper.currency(onestockDashboardStats.investedAmount || 0),
      hoverText: "Amount currently invested in open calls."
    },
    {
      // code: "Total Value",
      code: "Market Value",
      value: formatHelper.currency(onestockDashboardStats.currentValue || 0),
      hoverText: "The current market value of the amount invested in open calls."
    },
    // {
    //   code: "Executed calls",
    //   value: onestockDashboardStats.executedCalls,
    //   hoverText: "No. of recommendations that have been executed by the customer."
    // },
  ];

  const oneStockCards = [
    // {
    //   code: "Total calls",
    //   value: onestockDashboardStats.totalCalls,
    //   hoverText: "Total number of calls Recommended by Jarvis."
    // },
    {
      code: "Open calls",
      value: onestockDashboardStats.openCalls,
      hoverText: "Number of calls recommended by Jarvis that have not been sold yet."
    },
    {
      code: "Closed calls",
      value: onestockDashboardStats.closedCalls,
      hoverText: "Number of calls recommended by Jarvis that have been sold."
    },
  ];

  
  const yourInvestmentPopUpHandler = () => {
    setIsYourInvestMentPopup(!isYourInvestMentPopup); // Toggles the modal open/close state
  };

  const jarvisOneStockPopUpHandler= () =>{
    setIsJarvisOneStockMentPopup(!isJarvisOneStockMentPopup)
  }



  return (
    <>
      {/* Your Investments Box */}
      <Stack>
      <Box>
        <Box sx={{display:"flex", justifyContent: "space-between", alignItems:"center", marginBottom: "10px"}} >
          <Text sx={{ color:"secondary",fontWeight: "500",fontSize: "1.125rem",}}>
            Your Investments
          </Text>
          
          <InfoIcon 
            ml={1} 
            sx={{ width: "20px", color: "#a39e9e" }} 
            onClick={yourInvestmentPopUpHandler}
          />
        </Box>
        
        <Modal
          childStyle={{
              padding:"16px 18px 16px 18px",
              maxHeight:"326px",
              overflow: "hidden",
              marginBottom:"5px"
              
          }} 
          open={isYourInvestMentPopup} size={"xs"} disableClose={true}>
          <Stack direction="row" justifyContent="space-between" alignItems = "center" marginBottom="-5px" >
            <Text sx={{fontWeight:"500", fontSize:"14px"}}> Definitions </Text>
            <IconButton 
              sx={{ marginRight: "-11px",marginTop:"-9px;"}}
              onClick={yourInvestmentPopUpHandler}> 
              <CloseIcon fontSize="small"/> 
            </IconButton>
          </Stack>

          <Stack>
            <ul style={{paddingLeft:"28px"}} >
              {Object.entries(yourInvestment).map(([key, value])=>(
                <li style={{lineHeight:"16px", marginBottom:"10px", fontSize:"12px", color:"#303236"}}>
                  <span style={{fontWeight:"700", paddingRight:"4px" }} > {key} </span>
                  <span>{value}</span>
                </li>
              ))}
            </ul>
          </Stack>
        </Modal>
      </Box>

        <Loader loading={onestockDashboardLoading} />
        <JarvisCard
          sx={{
            border: "1px solid #EEF0F2",
            padding: {
              xs: "16px 16px",
              md: "10px 24px",
            },
            marginBottom: "20px",
          }}
        >
          
          <Loader loading={onestockDashboardLoading} />

          <Stack>
            {/* Total values, Invested amounts card */}

            <Stack
              sx={{
                paddingBottom: "5px",
                borderBottom: "1px solid #F5F5F5",
                marginBottom: "12px",
              }}
            >
              <Grid container spacing={0}>
                {customerCards.map((card, index) => {
                  return (<Grid
                    item  key={index}
                    xs={
                      {
                        0: 6,
                        1: 6,
                      }[index]
                    }
                  >

                    <Box sx={{marginBottom: "10px"}} >
                      <Text
                        sx={{
                          color: "#000000",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        {card.value}
                      </Text>
                  
                      <Text
                        variant="caption1"
                        sx={{ color: "#808591", fontSize: "12px", whiteSpace: "nowrap" }}
                      >
                        {card.code}
                      </Text>
                    </Box>

                  </Grid>);
                })}

              </Grid>
            </Stack>

            {/* Total returns, Unrealized/ Realized cards */}
            <Stack sx={{ paddingBottom: "12px", borderBottom: "1px solid #F5F5F5", marginBottom: "12px",}}>
              <Grid container spacing={0}>

                {/* Unrealized/Realized cards */}
                <Grid item xs={6}  >
                  <Stack sx={{ display: "flex", flexDirection: "column",}}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>

                      {/* Price of Unrealized/Realized */}
                      <Text
                        sx={{
                          color: realisedReturnStatus
                            ? onestockDashboardStats.customerRealisedReturnsValue > 0 ?
                              "#45AC70" : "#D44C4C"
                            : onestockDashboardStats.customerUnrealisedReturnsValue > 0 ?
                              "#45AC70" : "#D44C4C",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {realisedReturnStatus ? (
                          onestockDashboardStats.customerRealisedReturnsValue == 0 ? (
                            <>
                              <>
                                ({formatHelper.currency(
                                  Number(onestockDashboardStats.customerRealisedReturnsValue || 0).toFixed(2)
                                )})
                              </>
                            </>
                          ) : (
                            <>
                              {onestockDashboardStats.customerRealisedReturnsValue > 0 ? "+" : ""}
                              {formatHelper.currency(Number(
                                onestockDashboardStats.customerRealisedReturnsValue || 0).toFixed(2))}
                            </>
                          )
                        ) :
                          onestockDashboardStats.customerUnrealisedReturnsValue == 0 ? (
                            <>
                              {`${formatHelper.currency(
                                Number(onestockDashboardStats.customerUnrealisedReturnsValue || 0).toFixed(2)
                              )}`}
                            </>
                          ) : (
                            <>
                            {onestockDashboardStats.customerUnrealisedReturnsValue > 0 ? "+" : ""}
                            {formatHelper.currency(
                              Number( onestockDashboardStats.customerUnrealisedReturnsValue || 0 ).toFixed(2))
                            }
                          </>
                        )}
                      </Text>
                    </Box>

                    {/* Name of Box With changer switch*/}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <Text
                          sx={{
                            fontSize: "12px",
                            color: "#808591",
                          }}
                        >
                          {/* {realisedReturnStatus ? "Realised returns" : "Unrealised returns"} */}
                          {realisedReturnStatus ? "Closed calls returns" : "Open calls returns"}
                        </Text>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "7px",
                        }}
                      >
                        <ArrowDropUpIcon
                          style={{
                            backgroundColor: "white",
                            fill: realizedUpArrowBtn ? "black" : "#A2A7AE",
                            fontSize: "50px",
                            width: "16px",
                            height: "16px",
                            margin: "0px 0px -6px 0px",
                            padding: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleRealizedReturnSwitch(true);
                          }}
                        />
                        <ArrowDropDownIcon
                          style={{
                            backgroundColor: "white",
                            fill: RealizedDownArrowBtn ? "black" : "#A2A7AE",
                            fontSize: "50px",
                            width: "16px",
                            height: "16px",
                            margin: "0px 0px 0px 0px",
                            padding: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleRealizedReturnSwitch(false);
                          }}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>

                {/*Total returns card */}
                <Grid item xs={6} sx={{display:"flex",}} >
                  <Box>
                    <Text sx={{
                      color: onestockDashboardStats.customerTotalReturns > 0 ? "#45AC70" : "#D44C4C",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}>
                      {onestockDashboardStats.customerTotalReturns == 0 ? (
                        <Text sx={{ fontSize: "14px", fontWeight: 500 }}>
                          {formatHelper.currency(onestockDashboardStats.customerTotalReturns || 0)}
                        </Text>
                      ) : (
                        <Text sx={{ fontSize: "14px", fontWeight: 500 }}>
                          {`${onestockDashboardStats.customerTotalReturns > 0 ? "+" : ""}
                          ${formatHelper.currency(onestockDashboardStats.customerTotalReturns || 0)}`}
                        </Text>
                      )}
                    </Text>
                    <Text sx={{ fontSize: "12px",fontWeight: 400, color: "#808591",}}>Total returns </Text> 
                  </Box>
                </Grid>

              </Grid>
            </Stack>

            {/*Executed calls card */}
            <Stack direction="row" sx={{
              display:"flex",
              alignItems:"center",
              gap:"3px"
            }} >
              <Text sx={{ color: "#000000", fontWeight: "700", fontSize: "14px",}}>{onestockDashboardStats.executedCalls}</Text>
              <Text sx={{ fontSize: "12px", fontWeight: 400, color: "#808591",}}> Executed calls </Text>
            </Stack>
          </Stack>
        </JarvisCard>
      </Stack>

      {/* Jarvis One Stock Performance */}
      <Stack>
        <Box>
          <Box sx={{display:"flex", justifyContent: "space-between", alignItems:"center", marginBottom: "10px"}} >
            <Text sx={{ color:"secondary",fontWeight: "500",fontSize: "1.125rem",}}>
              Jarvis One Stock Performance
            </Text>
            <InfoIcon 
              ml={1} 
              sx={{ width: "20px", color: "#a39e9e" }} 
              onClick={jarvisOneStockPopUpHandler}
            />
          </Box>

          <Modal 
           childStyle={{
            padding:"16px 18px 16px 18px",
            marginBottom:"5px",
            maxHeight: "271px",
            overflow: "hidden"

          }} 
            open={isJarvisOneStockMentPopup} size={"xs"} disableClose={true}>
            <Stack direction="row" justifyContent="space-between" alignItems = "center" marginBottom="-5px" >
              <Text sx={{fontWeight:"500", fontSize:"16px"}} > Definitions </Text>
              <IconButton 
                sx={{ marginRight: "-11px",marginTop:"-9px;"}}
                onClick={jarvisOneStockPopUpHandler}> 
                <CloseIcon fontSize="small" /> 
              </IconButton>
            </Stack>

            <Stack>
              <ul style={{paddingLeft:"28px"}} >
                {Object.entries(jarvisOneStockPerformence).map(([key, value])=>(
                  <li style={{lineHeight:"16px", marginBottom:"10px", fontSize:"12px", color:"#303236"}}>
                    <span style={{fontWeight:"700", paddingRight:"4px" }} > {key} </span>
                    <span>{value}</span>
                  </li>
                ))}
              </ul>
            </Stack>
          </Modal>
        </Box>
        <JarvisCard
          sx={{
            border: "1px solid #EEF0F2",
            padding: {
              xs: "16px 16px",
              md: "10px 24px",
            },
            marginBottom: "20px",
          }}
        >
          <Loader loading={onestockDashboardLoading} />
          <Stack>

           {/* Total Calls, Open calls returns/Closed call return Cards */}
           <Stack 
            sx={{
              paddingBottom: "10px",
              borderBottom: "1px solid #F5F5F5",
              marginBottom: "10px",
            }}
           >
              <Grid container spacing={0}>
                {/* Total returns card */}
                {/*                 <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "start" }}
                >
                  <Box>
                    <Stack
                      sx={{
                        color:
                          onestockDashboardStats.totalReturnsPercentage > 0 ? "#45AC70" : "#D44C4C",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {onestockDashboardStats.totalReturnsPercentage == 0 ? (
                        <Text sx={{ fontSize: "14px", fontWeight: 500 }}>
                          {`${Number(onestockDashboardStats.totalReturnsPercentage || 0).toFixed(2)}%`}
                        </Text>
                      ) : (
                        <Text sx={{ fontSize: "14px", fontWeight: 500 }}>
                          {`${onestockDashboardStats.totalReturnsPercentage > 0 ? "+" : ""} 
                          ${Number(onestockDashboardStats.totalReturnsPercentage || 0).toFixed(2)}%`}
                        </Text>
                      )}
                    </Stack>

                    <Stack>
                      <Box sx={{ display: 'flex', alignItems: "center" }} >
                        <Box>
                          <Text
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#808591",
                            }}
                          >
                            Total returns
                          </Text>
                        </Box>
                        <Box>
                          <TooltipHover
                            title={"Total Returns of all stocks   Recommended by Jarvis"}
                            sx={{
                              lineHeight: 0, 
                              marginLeft: "3px",
                              marginTop:"3px"
                            }}
                            title_sx={true}
                            >
                            <InfoIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                          </TooltipHover>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </Grid> */}
                {
                  <Grid item xs={6} sx={{ display: "flex", justifyContent: "start" }}>
                    <Box>
                      <Stack>
                        {
                          <Text
                            sx={{
                              color: "#000000",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            {onestockDashboardStats.totalCalls}
                          </Text>
                        }
                      </Stack>

                      <Stack>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box>
                            <Text sx={{ fontSize: "12px", fontWeight: 400, color: "#808591",}}> Total Calls </Text>
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                }

                {/* Open calls returns/Closed call return Cards */}
                <Grid item xs={6} sx={{ display: "flex",}}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* Price in Percentage of Open Call Returns/Closed calls return */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Text
                        sx={{
                          display: "flex",
                          fontSize: "14px",
                          color: callReturnStatus
                            ? onestockDashboardStats.realisedReturnsPercentage >
                              0
                              ? "#45AC70"
                              : "#D44C4C"
                            : onestockDashboardStats.unrealisedReturnsPercentage >
                              0
                            ? "#45AC70"
                            : "#D44C4C",
                          fontWeight: "500",
                        }}
                      >
                        {callReturnStatus ? (
                          onestockDashboardStats.realisedReturnsPercentage ==
                          0 ? (
                            <>
                              {Number(
                                onestockDashboardStats.realisedReturnsPercentage ||
                                  0
                              ).toFixed(2)}
                              %
                            </>
                          ) : (
                            <>
                              {onestockDashboardStats.realisedReturnsPercentage >
                              0
                                ? "+"
                                : ""}
                              {Number(
                                onestockDashboardStats.realisedReturnsPercentage
                              ).toFixed(2)}
                              %
                            </>
                          )
                        ) : onestockDashboardStats.unrealisedReturnsPercentage >
                          0 ? (
                          <>
                            {Number(
                              onestockDashboardStats.unrealisedReturnsPercentage
                            ).toFixed(2)}
                            %
                          </>
                        ) : (
                          <>
                            {onestockDashboardStats.unrealisedReturnsPercentage >
                            0
                              ? "+"
                              : ""}
                            {Number(
                              onestockDashboardStats.unrealisedReturnsPercentage ||
                                0
                            ).toFixed(2)}
                            %
                          </>
                        )}
                      </Text>
                    </Box>

                    {/* Name of card with changing switch */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <Text
                          sx={{
                            fontSize: "12px",
                            color: "#808591",
                          }}
                        >
                          {callReturnStatus
                            ? "Closed calls returns"
                            : "Open calls returns"}
                        </Text>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "7px",
                        }}
                      >
                        <ArrowDropUpIcon
                          style={{
                            backgroundColor: "white",
                            fill: callReturnUpArrowBtn ? "black" : "#A2A7AE",
                            // fontSize: "px",
                            width: "16px",
                            height: "16px",
                            margin: "0px 0px -6px 0px",
                            padding: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleCallReturnSwitch(true);
                          }}
                        />

                        <ArrowDropDownIcon
                          style={{
                            backgroundColor: "white",
                            fill: callReturnDownArrowBtn ? "black" : "#A2A7AE",
                            fontSize: "50px",
                            width: "16px",
                            height: "16px",
                            marginBottom: "-2px",
                            padding: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleCallReturnSwitch(false);
                          }}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
           </Stack>


            {/*  Open calls and Closed calls Cards */}
            <Stack>
              <Grid container spacing={0.2}
              sx={{
                display:"flex",
                justifyContent:"space-between"
              }}
              >
                {oneStockCards.map((card, index) => {
                  return (
                    <Grid key={index} item xs={
                      {
                        0: 6,
                        1: 5.8,
                      }[index]
                    }
                    >
                      <Box>
                        <Text
                          sx={{
                            color: "#000000",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          {Math.abs(card.value).toFixed(0) || 0}
                        </Text>
                        <Box sx={{ display: "flex", alignItems: "center" }} >
                          <Box>
                            <Text
                              variant="caption1"
                              sx={{ color: "#808591", fontSize: "12px" }}
                            >
                              {card.code}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Stack>
        </JarvisCard>
      </Stack>
    </>
  );
};

export default MobileOnestockDashboardStats;
