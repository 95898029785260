import React from "react";
import BlogsContainer from "containers/Blogs";
import PageTitle from "components/PageTitle";
import { tracker } from "library/tracker";

const MarketUpdates = () => {
    React.useEffect(() => {
       tracker.pageView();
    }, []);
    return (
        <>
            <PageTitle heading="Blogs" />
            <BlogsContainer />
        </>
    );
};

export default MarketUpdates;
