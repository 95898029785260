import React from "react";
import { withRouter } from "react-router-dom";

import Table from "components/common/Table";
import Text from "components/common/Text";
import Stack from "components/common/Stack";

import PageTitle from "components/PageTitle";
import JarvisChip from "components/JarvisChipNew";
import PortfolioChip from "components/JarvisChip";
import JarvisCard from "components/JarvisCard";
import MobileRMSTransaction from "components/RMSTransaction/MobileRMSTransaction";

import { FilterList as FilterListIcon } from "@mui/icons-material"
import { dateTimeHelper } from "helper/dateTime";
import { formatHelper } from "helper/format";
import { localStore } from "stores/localStorage";

// import { useSearchParams } from 'react-router-dom';

class RMSTransactionDetailContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataChangedCounter: 0,
        }



    }
    componentDidMount() {
        this.fetchTransactionDetail();
        setTimeout(() => {

        }, 1000);

        this.props.getPortfolioList();
    }

    fetchTransactionDetail = () => {
        const rmsCallId = this.props.match.params.id;
        this.props.getRMSTransactionDetailAction({ rmsCallId });
    }

    render() {
        const {
            location,
            transactionDetailList,
            transactionDetailLoading,
            transactionDetailTotal,
            portfolioListStore,
        } = this.props;


        const isMobileView = localStore.getMobileView();

        const queryParams = location.search;
        const portfolioName = new URLSearchParams(queryParams).get('portfolioname');

        const columns = [
            {
                field: 'stockName',
                headerText: 'Stock',
                bodyCellComponent: (v, d) => {
                    return (
                        <Stack>
                            <Text variant="small" fontWeight="medium">{v}</Text>
                            <Text variant="small" color="text.secondary">{d.stockSymbol}</Text>
                        </Stack>
                    )
                }
            },
            {
                field: 'stockSellDate',
                headerText: 'Sell Date',
                bodyCellComponent: (v) => <Text variant="small">{dateTimeHelper.displayDate(v)}</Text>
            },
            {
                field: 'stockQuantity',
                headerText: 'Qty.',
                bodyCellComponent: (v) => <Text variant="small">{v < 10 ? `0${v}` : v}</Text>
            },
            {
                field: 'stockSellPrice',
                headerText: 'Sell Price',
                bodyCellComponent: (v) => <Text variant="small">{formatHelper.currency(v, { config: { maximumFractionDigits: 2 } })}</Text>
            },
            {
                field: 'changeInPrice',
                headerText: 'Change In Price',
                bodyCellComponent: (v) => <Text variant="small">{formatHelper.currency(v, { config: { maximumFractionDigits: 2 } })}</Text>
            },
            {
                field: 'valueSaved',
                headerText: 'Value Saved',
                bodyCellComponent: (v, d) => {
                    const isSaved = d.executionStatus.toUpperCase() === "EXECUTED";
                    return (
                        <Stack>
                            <Stack direction="row" alignItems="center">
                                <Text variant="small" color={isSaved ? 'success.main' : 'warning.main'}>{formatHelper.currency(v, { config: { maximumFractionDigits: 2 } })}</Text>
                                <Text variant="caption1" color={isSaved ? 'success.main' : 'warning.main'}>&nbsp; ({d.valueSavedPercentage}%)</Text>
                            </Stack>
                            <Text variant="caption1" color={isSaved ? 'success.main' : 'warning.main'}>{isSaved ? 'Saved' : 'Opportunity Lost'}</Text>
                        </Stack>
                    )
                }
            },
            {
                field: 'executionStatus',
                headerText: 'Call Execution Status',
                bodyCellComponent: (v) => {
                    if (v.toUpperCase() === 'EXECUTED') {
                        return <JarvisChip sx={{ padding: "8px" }} size="small" label="Executed" color="success" />
                    }
                    if (v.toUpperCase() === 'NOT_EXECUTED') {
                        return <JarvisChip sx={{ padding: "8px" }} size="small" label="Not Executed" color="warning" />
                    }
                    return null;
                }
            },
        ];

        return (
            <>
                {
                    !isMobileView ?
                        <>
                            <PageTitle heading={`Transactions - ${portfolioName}`} />

                            <Table
                                paginationBottom
                                localPagination
                                columns={columns}
                                data={transactionDetailList}
                                count={transactionDetailTotal}
                                loading={transactionDetailLoading}
                                dataChangedCounter={this.state.dataChangedCounter}
                                onPageChange={() => this.setState({ dataChangedCounter: this.state.dataChangedCounter + 1 })}
                            />
                        </>
                        :
                        <>
                            <Stack mb={2} mt={2} direction="row" sx={{ justifyContent: "space-between" }}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{ overflow: "auto", maxWidth: "90%", paddingBottom: "5px" }}
                                >
                                    {portfolioListStore?.map((data) => {
                                        return (
                                            <PortfolioChip
                                                data={data}
                                                mobileChip
                                            // isActive={data.portfolioId == this.getParams() ? true : false}
                                            // handleChipClick={() => this.handlePortfolioTabClick(data)}
                                            />
                                        );
                                    })}
                                </Stack>
                                <Text mt={1} sx={{ color: "black" }}>
                                    <FilterListIcon sx={{ fontSize: "20px" }} />
                                </Text>
                            </Stack>
                            <PageTitle heading={`Transactions - ${portfolioName}`} />
                            <JarvisCard sx={{ border: "1px solid #EEF0F2", padding: "0", height: "auto" }}>
                                {transactionDetailList?.map((data) => {
                                    return (
                                        <>
                                            <Text sx={{ padding: "15px 10px" }}>
                                                <MobileRMSTransaction
                                                    detailsData={data}
                                                />
                                            </Text>
                                            <Text sx={{ borderBottom: "1px solid #E2E2E2", padding: "0px" }}></Text>
                                        </>
                                    );
                                })}
                            </JarvisCard>
                        </>
                }
            </>
        )
    }
}

export default withRouter(RMSTransactionDetailContainer);