import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
    const basePrice=window.location.href.includes('bnrathi')?'1,000':'3,999'
    return {
        name: state.customer.loginProfile.name,
        currentStage: state.onboarding.currentStage,

        portfolioOverviewList: [
            { id: 1, stageNumber: 1, isOpen: false, name: 'Risk assessment', toolTipText: 'Jarvis will understand your risk-taking ability and will suggest an equity portfolio accordingly' },
            { id: 2, stageNumber: 2, isOpen: false, name: 'Investment horizon and amount', toolTipText: 'Jarvis will understand how long you want to stay invested, the amount, and strategy, and will suggest an equity portfolio accordingly.' },
            { id: 3, stageNumber: 3, isOpen: false, name: 'Personalised portfolio', toolTipText: 'If you are unique, then why can’t your portfolio be? Jarvis will create an exclusive and personalised portfolio based on your risk-taking capacity and your investment horizon.' },
            { id: 4, stageNumber: 3, isOpen: false, name: 'Payment ', toolTipText: `All you have to do is pay Rs. ${basePrice} to reveal the portfolio generated by Jarvis with the help of artificial intelligence.` },
            { id: 5, stageNumber: 4, isOpen: false, name: 'CKYC', toolTipText: ' An initiative by the Indian government to set up a system that will help you perform your KYC just once before interacting with different companies in the financial industry, like us.' },
            { id: 6, stageNumber: 5, isOpen: false, name: 'Link broker', toolTipText: " Now that your portfolio has been generated and CKYC is done, it's time for you to link your broking account and place the order to buy the stocks recommended by Jarvis." },
        ],
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const PortfolioOnboardingNotCompletedStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PortfolioOnboardingNotCompletedStore;
