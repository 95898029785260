import React from "react";
import { alpha } from "@mui/material/styles";

import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from '@mui/icons-material/CircleOutlined';
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import Avatar from "components/common/Avatar";

const ChangeRiskProfileCard = (props) => {
    const {
        id = "",
        title = "",
        description = "",
        onClick = () => null,
        isSelected = false,
    } = props;

    const styles = {
        1: "#1C8D13",
        2: "#83C605",
        3: "#BCD103",
        4: "#ECB004",
        5: "#DF873A",
        6: "#D44C4C",
    };

    return (
        <Stack
            sx={{
                height: "100%",
                p: { xs: "15px", md: "26px" },
                cursor: "pointer",
                borderRadius: "14px",
                border: 1,
                borderColor: isSelected ? styles[id] : "#DEDEDE",
            }}
            onClick={onClick}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" justifyContent="flex-start">
                    <Avatar
                        sx={{
                            bgcolor: alpha(styles[id], 0.2),
                            width: 30,
                            height: 30,
                        }}
                    >
                        <LayersOutlinedIcon
                            size="small"
                            sx={{
                                fontSize: 20,
                                color: styles[id],
                            }}
                        />
                    </Avatar>

                    <Text ml={2} variant="body1" fontWeight="bold">
                        {title}
                    </Text>
                </Stack>
                {isSelected ? (
                    <CheckCircleIcon
                        sx={{
                            color: styles[id],
                        }}
                    />
                ) : (
                    <CircleIcon
                        sx={{
                            color: "#D0D5DD"
                        }}
                    />
                )}
            </Stack>

            <Text variant="small" pt={2}>
                {description}
            </Text>
        </Stack>
    );
};

export default ChangeRiskProfileCard;
