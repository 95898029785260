import React from "react";
import PageTitle from "components/PageTitle";
import BrokerContainer from "containers/Broker";

const Broker = (props) => {
    return (
        <>
            <PageTitle heading="Broker" />
            <BrokerContainer {...props} />
        </>
    )
}

export default Broker;