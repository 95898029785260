import { Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button from "components/common/Button";
import JarvisButton from "components/JarvisButton";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import React from "react";
import TextField from "components/common/TextField";
import JarvisAutoSeach from "components/JarvisAutoSearch";
import Grid from "components/common/Grid";
import Box from "components/common/Box";
import IconButton from "components/common/IconButton";
import cx from "classnames";
import CloseIcon from "@mui/icons-material/Close";
import { alpha } from "@mui/material";
import JarvisNote from "components/JarvisNote";

const useStyles = makeStyles((theme) => ({
    formFileView: {
        width: "100%",
        textAlign: "center",
        minHeight: "40vh",
        justifyContent: "center",
        alignItems: "center",
        margin: `15px 0`,
        cursor: "pointer",
        display: "flex",
        borderRadius: "10px",
    },
    formFileUpload: {
        outlineStyle: 'dashed',
        outlineWidth: '2px',
        outlineColor: '#A2A7AE',
        backgroundColor: "#F8F8F8",
        transitionDuration: '350ms',
        transitionProperty: "all",

        '&:hover': {
            outlineColor: alpha(theme.palette.primary.main, 0.6),
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },
    },
    formFileUploaded: {
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
        minHeight: "30vh !important",
        cursor: 'initial !important',

    },
    inputFileUpload: {
        visibility: 'hidden',

    },

}));

const RMSSyncPortfolioUploadForm = (props) => {
    const classes = useStyles();
    const inputRef = React.useRef(null);
    const handleDragAndDrop = React.useRef(null);

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const {
        selectedFile,
        // fileSizeValidationError,
        portfolioName,
        portfolioId,
        onChange,
        onDelete,
        isValidFile,
        formData = {},
        formError = {},
        brokerList,
        getData,
        onFormChange,
        onSubmit,
        onChangeValue,
        onClose,
        downloadSampleFileLink,
    } = props;

    console.log(brokerList);


    return (
        <Box>
            <Stack
                direction="row"
                alignItems="flex-start"

            >
                <Box>
                    <Text variant={"subtitle2"}>Upload Portfolio</Text>
                    <Text variant={"caption1"} color="#A2A7AE" sx={{
                        display: "block",
                        lineHeight: "15px",
                        mt: "10px",
                        mb: "8px"
                    }} component="span">
                        Upload excel with your holding, please refer to the
                        sample file {""}
                        <span >
                            <a style={{
                                fontSize: "0.75rem",
                                textTransform: "capitalize",
                                fontWeight: "500",
                                color: "#6f9ed7",
                                textDecoration: "none",
                                marginTop: "-1px",
                                padding: "0px 8px"
                            }}
                                href={downloadSampleFileLink}
                            >
                                Download Here
                            </a>
                        </span>
                    </Text>
                </Box>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>

            <JarvisNote description="We do not accept BSE stocks. Please add only NSE stocks in the excel file." />

            <Stack className={cx({
                [classes.formFileView]: true,
                [classes.formFileUpload]: !selectedFile,
                [classes.formFileUploaded]: selectedFile,
            })}>
                {selectedFile ? (
                    <Stack alignItems="center" spacing={2}>
                        <img src="/svgs/excel_file.svg" alt="img" height={36} />

                        <Text variant="small" fontWeight="500">
                            {selectedFile.name} ({selectedFile.size / 1000}K)
                        </Text>
                        <Text
                            color="#BEC1C6"
                            variant="small"
                            onClick={onDelete}
                            sx={{ color: "#BEC1C6", '&:hover': { color: '#2B70C5', cursor: "pointer" } }}
                        >
                            Delete
                        </Text>
                    </Stack>
                ) : (
                    <>
                        <form
                            style={{ width: 'inherit', minHeight: 'inherit' }}
                            ref={handleDragAndDrop}
                            onClick={onButtonClick}
                            // onDrop={dropFile}
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}

                        >
                            <input
                                type="file"
                                ref={inputRef}
                                className={classes.inputFileUpload}
                                multiple={false}
                                onChange={onChange}
                                onClick={(event) => {
                                    event.target.value = null;
                                }}
                                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            />
                            <Stack alignItems="center" justifyContent="center" sx={{ width: 'inherit', minHeight: 'inherit' }}>
                                <svg className="uploadFileIcon" style={{ marginBottom: '22px' }} xmlns="http://www.w3.org/2000/svg" width="44" height="32" viewBox="0 0 44 32" fill="">
                                    <path d="M8.88802 11.2979L9.05976 11.2714L9.09485 11.1013C9.75043 7.92171 11.2881 5.33067 13.7098 3.31805C16.1312 1.30569 18.9245 0.300781 22.1 0.300781C25.7985 0.300781 28.8906 1.63118 31.3927 4.29688C33.8983 6.96618 35.15 10.1629 35.15 13.9008V15.1008V15.3578L35.407 15.3507C37.7422 15.2858 39.7095 16.0372 41.326 17.6052C42.9408 19.1716 43.75 21.147 43.75 23.5508C43.75 25.7827 42.9443 27.7029 41.3232 29.324C39.7022 30.9451 37.7819 31.7508 35.55 31.7508H23.5C22.7706 31.7508 22.1331 31.4803 21.5768 30.924C21.0205 30.3677 20.75 29.7301 20.75 29.0008V16.1008V15.4972L20.3232 15.924L16.35 19.8972L14.5536 18.1008L22 10.6543L29.4465 18.1008L27.65 19.8972L23.6768 15.924L23.25 15.4972V16.1008V29.0008V29.2508H23.5H35.55C37.1167 29.2508 38.4635 28.6908 39.5768 27.5776C40.69 26.4643 41.25 25.1175 41.25 23.5508C41.25 21.9841 40.69 20.6373 39.5768 19.524C38.4635 18.4108 37.1167 17.8508 35.55 17.8508H32.65V13.9008C32.65 10.871 31.6177 8.25933 29.5567 6.07904C27.4922 3.89516 24.9346 2.80078 21.9 2.80078C18.8655 2.80078 16.2999 3.89509 14.219 6.0783C12.1984 8.19828 11.1583 10.7265 11.1024 13.6508H10.4C8.26756 13.6508 6.45322 14.4018 4.97221 15.9C3.49028 17.3992 2.75 19.255 2.75 21.4508C2.75 23.5839 3.50977 25.4219 5.02223 26.9516C6.53536 28.4819 8.36584 29.2508 10.5 29.2508H17.25V31.7508H10.5C7.70108 31.7508 5.29759 30.7448 3.27678 28.724C1.25597 26.7032 0.25 24.2997 0.25 21.5008C0.25 18.9587 1.05486 16.7237 2.66719 14.7857C4.27733 12.8502 6.34703 11.6888 8.88802 11.2979Z" fill="#A2A7AE" stroke="white" stroke-width="0.5" />
                                </svg>
                                {/* <img src="/svgs/upload_file.svg" ></img> */}
                                {/* <Text color="#A2A7AE">Drag and drop to upload </Text> */}
                                {/* <Link underline="none" fontWeight={800} color="#2B70C5"><span style={{ color: '#A2A7AE', fontWeight: "normal" }}>or</span> Browse File</Link> */}
                                <Link underline="none" fontWeight={800} color="#2B70C5">Browse File</Link>
                                {!isValidFile ? (
                                    <Text color="red">
                                        Please Select file less than 1MB
                                    </Text>
                                ) : null}
                            </Stack>
                        </form>


                    </>

                )}
            </Stack>
            {selectedFile ? (
                <Box sx={{ mb: 2 }}>
                    <Grid columnSpacing={1} rowSpacing={1}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                type="text"
                                value={formData.portfolioName}
                                onChange={(e) =>
                                    onFormChange({
                                        name: "portfolioName",
                                        value: e.target.value,
                                    })
                                }
                                label={"Name your Portfolio"}
                                helperText={formError.portfolioName}
                                error={formError.portfolioName}
                                sx={{ mb: formError.brokerId && !formError.portfolioName ? 5 : 2 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <JarvisAutoSeach
                                label="Choose Broker"
                                type="select"
                                value={formData.brokerId}
                                optionList={brokerList}
                                onChangeValue={getData}
                                helperText={formError.brokerId}
                                error={formError.brokerId}
                                sx={{ mb: !formError.brokerId && formError.portfolioName ? 5 : 2 }}

                            // onChangeValue={(data,type) => getData(data,type)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                type="text"
                                value={formData.customerBrokerId}
                                onChange={(e) =>
                                    onFormChange({
                                        name: "customerBrokerId",
                                        value: e.target.value,
                                    })
                                }
                                label={"Enter Client ID"}
                                sx={{ color: "red !important", mb: formError.brokerId || formError.portfolioName ? 5 : 2 }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : null}

            <Stack mt={2}>
                <JarvisButton sx={{ color: "white" }} onClick={() => onSubmit()} disableEndIcon={true} disabled={!selectedFile || !formData.brokerId || !formData.portfolioName}>
                    Submit
                </JarvisButton>
            </Stack>
        </Box>
    );
};

export default RMSSyncPortfolioUploadForm;
