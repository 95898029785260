import React from 'react';
import OneStockCkycContainer from 'containers/JarvisOnestock/OneStockCkyc';

function OnestockCkyc() {
    return ( 
        <>
        <OneStockCkycContainer />
        </>
     );
}

export default OnestockCkyc;