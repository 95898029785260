import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { customerActions } from "stores/customer/customerActions"
import { onboardingActions } from "stores/onboarding/onboardingActions"
import { dashboardActions } from "stores/dashboard/dashboardActions";

const mapStateToProps = (state) => {
    return {
        profile: state.customer.profile,
        isOnboardingInitiallyFetched: state.onboarding.isInitialFetched,
        hasPortfolio: state.dashboard.portfolioPerformance.hasPortfolio || false,
        portfolioPerformance: state.dashboard.portfolioPerformance,
        
        
        isExpired: state.customer.profile.isExpired,
        isExpiring: state.customer.profile.isExpiring,
        isMobileVerified: state.customer.loginProfile.isMobileVerified,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOnboardingDataAction: onboardingActions.getOnboardingData,
    getProfileAction: customerActions.getProfile,
    getPortfolioPerformanceAction: dashboardActions.getPortfolioPerformance,
}, dispatch)

const DashboardOnboardedStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default DashboardOnboardedStore;