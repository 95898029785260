import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
    const basePrice=window.location.href.includes('bnrathi')?'1,000':'1,999'
    return {
        name: state.customer.loginProfile.name,
        currentStageForProtect: state.jarvisRMS.rmsOnboarding.currentStage,

        protectOverviewList: [
            { id: 1, stageNumber: 1, isOpen: false, name: 'Risk assessment', toolTipText: 'Jarvis will understand your risk-taking ability and will suggest sell calls on your equity portfolio accordingly' },
            { id: 2, stageNumber: 2, isOpen: false, name: 'Upload or Sync Portfolio', toolTipText: 'Upload Your Portfolio.' },
            { id: 3, stageNumber: 3, isOpen: false, name: 'Payment ', toolTipText: `All you have to do is pay Rs. ${basePrice}  to reveal the list of sell call recommendations on your equity portfolio generated by Jarvis with the help of artificial intelligence.` },
            { id: 4, stageNumber: 4, isOpen: false, name: 'CKYC', toolTipText: ' An initiative by the Indian government to set up a system that will help you perform your KYC just once before interacting with different companies in the financial industry, like us.' },
        ],
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const RMSOnboardingNotCompletedStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSOnboardingNotCompletedStore;
