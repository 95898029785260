export const thirdpartyConstants = {
    RESET_THIRDPARTY_DATA: "RESET_THIRDPARTY_DATA",
    // PAYMENT
    INITIATE_PAYMENT_API: `v1/payment`,
    INITIATE_RECURRING_PAYMENT_API: "/v1/payment/initiate-recurring-payment",
    INITIATE_ONE_TIME_PAYMENT_API: "/v1/payment/initiate-onetime-payment",
    CAPTURE_PAYMENT_API: `v1/payment/payment-capture`,
    CAPTURE_RECURRING_PAYMENT_API: "/v1/payment/capture-recurring-payment",
    CHECK_PAYMENT_STATUS_API: "/v1/payment/check-payment-status",
    CHECK_RECURRING_PAYMENT_STATUS_API: "/v1/payment/check-recurring-payment-status",
    CHECK_ONETIME_PAYMENT_STATUS_API: "/v1/payment/check-onetime-payment-status",
    CAPTURE_ONETIME_PAYMENT_API: "/webhook/razorpay-onetime-payment/payment-captured",
    ONETIME_PAYMENT_FAILURE:"webhook/razorpay-onetime-payment/payment-failure",

    PORTFOLIO_SUBSCRIPTION_PLANS_API: "/portfolio/subscription/plans",
    SUBSCRIPTION_PLANS_SAGA: "SUBSCRIPTION_PLANS_SAGA",
    SUBSCRIPTION_PLANS_REQUEST: "SUBSCRIPTION_PLANS_REQUEST",
    SUBSCRIPTION_PLANS_RESPONSE: "SUBSCRIPTION_PLANS_RESPONSE",

    CREATE_PORTFOLIO_SUBSCRIPTION_API: "/portfolio/subscription/create",
    CAPTURE_PORTFOLIO_SUBSCRIPTION_API: "/portfolio/subscription/capture",
    CHECK_SUBSCRIPTION_STATUS_API: "/portfolio/subscription/status",

    // Transaction History
    TRANSACTION_HISTORY_LIST_REQUEST: "TRANSACTION_HISTORY_LIST_REQUEST",
    TRANSACTION_HISTORY_LIST_RESPONSE: "TRANSACTION_HISTORY_LIST_RESPONSE",
    TRANSACTION_HISTORY_LIST_SAGA: "TRANSACTION_HISTORY_LIST_SAGA",
    TRANSACTION_HISTORY_LIST_API: "/master/profile/plan-transaction-history",

    // DIGIO
    INITIATE_DOCUMENT_SIGNING_API: `v1/onboarding/initate-sign`,

    // BROKER
    // BROKER_PRE_ORDER_API: "v1/onboarding/pre-order",
    BROKER_PRE_ORDER_API : 'v1/broker/pre-order',
    BROKER_TRANSACTION_STATUS_API: "v1/onboarding/transaction-status",
    BROKER_TRANSACTION_STATUS_ODIN_API: "v1/onboarding/transaction-status-odin",
    // BROKER FETCH HOLDING
    BROKER_FETCH_HOLDING_API: "/broker/fetch-holding",
    BROKER_FETCH_HOLDING_CHECK_TRANSACTION_STATUS_API: "/broker/fetch-holding/check-transaction-status",
    BROKER_HOLDING_VALIDATE_ACCESS_TOKEN_API: "/broker/validate-access-token",
    BROKER_HOLDING_LOGIN_API: "/broker/login",
    BROKER_HOLDING_PRELOGIN_API: "/broker/pre-login",
    BROKER_HOLDING_GENERATE_ACCESS_TOKEN_API: "/broker/generate-access-token",

    // BROKER ODIN
    BROKER_PRE_LOGIN_API: "v1/broker/pre-login",

    // BROKER -> NON SDK and NON Webhook
    BROKER_LOGIN_API: "v1/broker/login",
    BROKER_NEW_ACCESS_TOKEN_API: "v1/broker/new-access-token",
    BROKER_PREVIOUS_ACCESS_TOKEN_API: "v1/broker/prev-access-token",
    BROKER_PLACE_ORDER_API: "v1/broker/place-order",
    BROKER_CHECK_ORDER_STATUS_API: "v1/broker/check-order-status",
    BROKER_GENERATE_ACCESS_TOKEN: "v1/broker/generate-access-token",

    //MARKET -> CHECK MARKET IS OPEN OR NOT
    BROKER_OPEN_HR_STATUS_API: "v1/investment/check-market-hour",
    BROKER_SELECTED_API: "v1/onboarding/update-selected-broker",
};
