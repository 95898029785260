import React from "react";
import OnestockDashboardContainer from "containers/JarvisOnestock/OnestockDashboard";

function Dashboard() {
	return (
		<>
			<OnestockDashboardContainer />
		</>
	);
}

export default Dashboard;
