import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisOnestockDashboardConstants } from "./jarvisOnestockDashboardConstants";


const initialState = {
    dashboardStats: {},
    dashboardReveal: {},
    loading: {
        dashboardStats: 0,
        dashboardReveal: 0,
    },
};

export const jarvisOnestockDashboardReducer = persistReducer(
    {
        storage,
        key: "onestockDashboard",
        whitelist: [],
    },

    (state = initialState, { type, payload }) => {
        switch (type) {
            case jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        dashboardStats: 1,
                    },
                    dashboardStats: state.dashboardStats,
                };
            }

            case jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        dashboardStats: 0,
                    },
                    dashboardStats: payload,
                };
            }

            case jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        dashboardReveal: 1,
                    },
                    dashboardReveal: state.dashboardReveal,
                };
            }

            case jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        dashboardReveal: 0,
                    },
                    dashboardReveal: payload,
                };
            }

            default:
                return state;
        }
    }
);
