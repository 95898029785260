import _get from "lodash/get";

const jarvisRMSTransactionParsers = {};

jarvisRMSTransactionParsers.rmsTransactionList = (res) => {
    const data = {
        list: [],
        total: 0,
    };

    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.list) {
        return data;
    }

    data.list = res.list.map((d) => ({
        stockName: _get(d, "stockName", null),
        stockSymbol: _get(d, "stockSymbol", null),
        portfolioName: _get(d, "portfolioName", null),
        sellDate: _get(d, "sellDate", null),
        sellQuantity: _get(d, "sellQuantity", null),
        sellPrice: _get(d, "sellPrice", null),
        changePrice: _get(d, "changedPrice", null),
        valueSaved: _get(d, "valueSaved", null),
        valueSavedPercentage: _get(d, "valueSavedPercentage", null),
        transactionStatus: _get(d, "transactionStatus", null),
    }));

    data.total = _get(res, "listCount", null);

    return data;
};

jarvisRMSTransactionParsers.rmsTransactionDetail = (res) => {
    const data = {
        list: [],
        total: 0,
    };

    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.list) {
        return data;
    }

    data.list = res.list.map((d) => ({
        stockId: _get(d, "stockId", null),
        stockName: _get(d, "portfolioStockName", null),
        stockSymbol: _get(d, "portfolioStockSymbol", null),
        stockQuantity: _get(d, "portfolioStockQuantity", null),
        stockBuyPrice: _get(d, "stockBuyPrice", null),
        stockBuyValue: _get(d, "stockBuyValue", null),
        stockSellPrice: _get(d, "portfolioStockSellPrice", null),
        stockSellDate: _get(d, "portfolioStockSellDate", null),
        changeInPrice: _get(d, "changeInPrice", null),
        changeInPriceDuration: _get(d, "changeInPriceDuration", null),
        valueSaved: _get(d, "valueSaved", null),
        valueSavedPercentage: _get(d, "valueSavedPercentage", null),
        executionStatus: _get(d, "RMSCallExecuted", null),
    }));

    data.total = _get(res, "records", null);

    return data;
};

export { jarvisRMSTransactionParsers };
