import React from "react";
import LoginContainer from "containers/Login";
import { tracker } from "library/tracker";

const Login = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);

    return (
        <LoginContainer />
    )
}

export default Login;