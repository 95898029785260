import React from 'react';
import OnestockOnboardingOverviewContainer from 'containers/JarvisOnestock/OnestockOnboardingOverview'

const OnestockOnboardingOverview = () => {
    return (
        <>
            <OnestockOnboardingOverviewContainer />
        </>);
}

export default OnestockOnboardingOverview;