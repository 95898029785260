import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onboardingActions } from "stores/onboarding/onboardingActions";

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPortfolioOnboardingDataAction: onboardingActions.getOnboardingData,
}, dispatch);

const PortfolioInfoStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PortfolioInfoStore;
