import React from "react";
import { makeStyles, createStyles } from "@mui/styles";

import ArrowRightIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackOutlined";

import Button from "components/common/ButtonBase";
import { config } from "config";

const JarvisButton = ({
    children,
    variant = "contained",
    size = "medium",
    sx = {},
    disableStartIcon = true,
    disableEndIcon = false,
    endIcon = null,
    startIcon = null,
    disabled = false,
    animate = true,
    onClick = () => { },
    loading = false,
}) => {
    const classes = useStyles();

    const getEndIcon = () => {
        if (disableEndIcon) {
            return null;
        }

        if (endIcon) {
            return endIcon;
        }

        return (<ArrowRightIcon sx={{ fontSize: "16px !important" }} />);
    }

    const getStartIcon = () => {
        if (disableStartIcon) {
            return null;
        }

        if (startIcon) {
            return startIcon;
        }

        return <ArrowLeftIcon sx={{ fontSize: "16px !important" }} />;
    }

    return (
        <Button
            disableRipple={false}
            disabled={disabled}
            variant={variant}
            startIcon={getStartIcon()}
            endIcon={getEndIcon()}
            sx={{
                boxShadow: "none !important",
                // borderRadius: `30px !important`,
                ...sx
            }}
            classes={{
                root: animate ? classes.rootAnimate : classes.root,
                contained: config.isMobileApp ? classes.mobileContained : classes.contained,
                outlined: classes.outlined,
                callsContained: classes.callsContained,
                callsContained2: classes.callsContained2,
            }}
            onClick={onClick}
            size={size}
            children={children}
            loading={loading}
        />
    )
};

const useStyles = makeStyles((theme) => createStyles({
    root: {
        // borderRadius: `25px !important`,
    },
    rootAnimate: {
        "& .MuiButton-endIcon": {
            transition: "all 0.2s linear",
        },
        "& .MuiButton-startIcon": {
            transition: "all 0.2s linear",
        },
        "&:hover": {
            "& .MuiButton-endIcon": {
                transform: "rotate(-45deg)",
            },
            "& .MuiButton-startIcon": {
                transform: "rotate(45deg)",
            }
        }
    },
    mobileContained: {
        backgroundColor: "#2B70C5",
        color: "white",
        "&:disabled":{
            backgroundColor: "#dcdcdc !important"
        }
    },
    contained: {
        backgroundColor: "#2B70C5",
        color: "white",
        "&:hover": {
            backgroundColor: "#202020",
            color: "white",
        },
        "&:disabled":{
            backgroundColor: "#dcdcdc !important"
        }
    },
    outlined: {

    },
    callsContained: {
        backgroundColor: "#D44C4C",
        color: "white",
        border: "none",
        "&:hover": {
            backgroundColor: "#D44C4C",
            color: "white",
            border: "none",
        },
    },
    callsContained2: {
        backgroundColor: "#2B70C5",
        width: "25%",
        color: "white",
        "&:hover": {
            backgroundColor: "#202020",
            color: "white",
        },
        "&:disabled":{
            backgroundColor: "#dcdcdc !important"
        }
    },
}));

export default React.memo(JarvisButton);