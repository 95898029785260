import React from "react";
import { withRouter } from "react-router";

import _get from "lodash.get";
import { config } from "config";
import Stack from "components/common/Stack";
import Loader from "components/common/Loader";
import SectionTitle from "components/SectionTitle";
import RMSOnboardingHeader from "components/RMSOnboarding/RMSOnboardingHeader";
import Modal from "components/common/Modal";
import { onboardingApis } from "stores/onboarding/onboardingApis";
import { validationHelper } from "helper/validation";
import { onboardingParsers } from "stores/onboarding/onboardingParsers";
import Image from "components/common/Image";
// import Box from "components/common/Box";
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import IconButton from "components/common/IconButton";
import JarvisButton from "components/JarvisButton";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Avatar from "components/common/Avatar";
import Button from "components/common/Button";
import { masterApis } from "stores/master/masterApis";
import { intercom } from "library/tracker/intercom";
import { localStore } from "stores/localStorage";

const onboardingStages = [
	{
		stageId: ["RISK_ASSESSMENT"],
		stageNumber: 1,
		uri: "/jarvis-rms/onboarding/risk-assessment",
		heading: "Risk Assessment",
		subheading: "Answer these questions to know your risk profile",
	},
	{
		stageId: ["SYNC_PORTFOLIO"],
		stageNumber: 2,
		heading: "Sync your portfolio",
		uri: "/jarvis-rms/onboarding/sync-portfolio",
		subheading: "Select a broker from the list below or upload manually.",
	},
	{
		stageId: ["CKYC", "DOCUMENT_SIGNING"],
		stageNumber: 3,
		heading: "CKYC & E-sign",
		uri: "/jarvis-rms/onboarding/ckyc",
		subheading: "Complete your KYC",
	},
];

class RMSOnboardingLayoutContainer extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isOnbordingHeaderVisible: true,
			isEditEmailModal: false,
			email: this.props.emailId,
			emailError: '',
			actionType: '',
		}
	}

	componentDidMount() {
		if (
			!this.props?.isOnboardingDataFetched ||
			!this.props?.isOnboardingDataLoading ||
			_get(this.props?.location, "state.paymentStatus", "") === "SUCCEED"
		) {
			this.props.getOnboardingDataAction();
		}

		const activeStage = this.getActiveStage();
		if (this.props.isOnboardingCompleted) {
			this.props.history.replace("/jarvis-rms/dashboard");
		} else if (!['/jarvis-rms/onboarding/overview', activeStage?.uri].includes(this.props.location.pathname)) {
			const stateParams = this.props.history?.location?.state;
			this.props.history.replace({ pathname: activeStage?.uri, state: stateParams });
		}
		
		document.querySelector("html").classList.add("no-tab");
        document.querySelector("html").classList.remove("has-tab");
	}

	componentDidUpdate() {
		if (this.state.isOnbordingHeaderVisible && this.props.location.pathname === '/jarvis-rms/onboarding/overview') {
			this.setState({ isOnbordingHeaderVisible: false });
		}

		if (!this.state.isOnbordingHeaderVisible && this.props.location.pathname !== '/jarvis-rms/onboarding/overview') {
			this.setState({ isOnbordingHeaderVisible: true });
		}
	}

	getActiveStage = () => {
		const { currentStage, isPaymentCompleted } = this.props;
		let activeStage;
		if(!isPaymentCompleted){
			activeStage = onboardingStages.find((o) => 
			o.stageId.includes(currentStage)
		);
		}
		else{
		activeStage = onboardingStages[2];
		}
		return activeStage;
	};

	getActiveStage2 = () => {
		const uri = this.props.location.pathname;
		return (
			onboardingStages.find((o) => o.uri === uri) || onboardingStages[0]
		);
	};

	handleLogoutClick = async () => {
		await masterApis.updateLastActiveProduct();
		this.props.logoutAction();
		localStore.removeSSOCustomer();
        localStore.removeSsoClientId();
		setTimeout(() => {
			this.props.history.replace("/login");
		}, 1000);
	};

	handleEditEmailModal = (val, actionType) => {  //val: true|false actionType:EDIT|RESEND/CANCEL
		actionType === 'EDIT' ? this.setState({ isEditEmailModal: val, actionType: actionType, }) : actionType === 'CANCEL' ? this.setState({ isEditEmailModal: val, actionType: actionType, email: this.props.emailId, emailError: '' }) : this.setState({ actionType: actionType }, () => this.handleSendLink());
	}

	handleSendLink = async () => {
		const { email, actionType } = this.state;

		if (!this.handleValidation()) {
			return;
		}

		try {
			const res = await onboardingApis.sendEmailVerificationLink({ actionType, email });
			const parsedRes = onboardingParsers.sendEmailVerificationLink(res);
			if (actionType === "EDIT") {
				this.props.updateEmailAction({ email })
			}
			this.props.setToast({ message: parsedRes.message });
			this.setState({ isEditEmailModal: false, emailError: '' });
		}
		catch (err) {
			this.props.setToast({ message: err.data.message });
		}

	}

	handleValidation = () => {
		const { email } = this.state;
		const emailValidation = validationHelper.email(email);
		this.setState({ emailError: emailValidation.message })
		return emailValidation.isValid;

	}

	render() {
		const { rmsOnboardingSteps, currentStage, isEmailVerified, emailId } = this.props;
		const { isOnbordingHeaderVisible, isEditEmailModal, email, emailError } = this.state;
		const activeStage = this.getActiveStage2();
		return (
			<>
				{
					!isEmailVerified && (
						<Stack sx={{ backgroundColor: '#2B70C5', pl: 1, position: "sticky", top: 0, zIndex: 9 }} direction="row" alignItems={'center'} justifyContent={'center'} p={0.4}>
							<Text color="#ffff" sx={{ flex: '1', fontSize: { xs: "caption1.fontSize", md: "small.fontSize" }, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: { xs: "left", md: "center" } }} >
								Verify your Email to proceed, Check on the link sent to {emailId}
								<Avatar alt="Remy Sharp" onClick={() => this.handleEditEmailModal(true, 'EDIT')} sx={{ display: { xs: 'none', md: 'inline-flex' }, backgroundColor: '#ff000000' }}>
									<Image src="/svgs/edit-file.svg" height={16} width={16} imgStyle={{ cursor: "pointer" }} />
								</Avatar>
							</Text>
							<div>
								<Avatar alt="Remy Sharp" onClick={() => this.handleEditEmailModal(true, 'EDIT')} sx={{ display: { xs: 'inline-flex', md: 'none' }, backgroundColor: '#ff000000' }}>
									<Image src="/svgs/edit-file.svg" height={16} width={16} imgStyle={{ cursor: "pointer" }} />
								</Avatar>
								<Button size="small" onClick={() => this.handleEditEmailModal(true, 'RESEND')} sx={{ display: { xs: 'none', md: 'block' }, color: "#ffff", textTransform: "capitalize", fontSize: "0.875rem" }} variant="text">Resend</Button>
							</div>
						</Stack>

					)
				}
				<Stack sx={{ flex: 1, maxWidth: { lg: "1280px" }, margin: "0 auto 16px" }}>
					<Stack direction="row" justifyContent="space-between" sx={{ p: 2, borderBottom: 1, borderColor: "#DEDEDE" }}>
						{/* <Box sx={{ display: { xs: "none", md: "block" } }}>
							<Image src="/svgs/jarvis-logo.svg" width={140} />
						</Box>
						<Box sx={{ display: { xs: "block", md: "none" } }}>
							<Image src="/svgs/jarvis-logo.svg" width={90} />
						</Box> */}
						<SectionTitle
							sx={{ display: { xs: "flex" } }}
							subheading={this.props.isPortfolioOnboardingCompleted ? "Back to Dashboard" : "Back to Product Page"}
							startIcon={
								<IconButton
									onClick={() => {
										if (this.props.isPortfolioOnboardingCompleted) {
											this.props.history.replace('/jarvis-rms/dashboard')
										} else {
											let ssoCustomerDetails = localStore.getSsoCustomerDetails()
											if(localStore.isSsoCustomer() && ssoCustomerDetails?.broker === config?.broker?.ssoPlatform?.centrum){ 
												return window.location.href = config?.CENTRUM_REDIRECTION_URL							
											}
											this.props.history.replace('/customer/product-info')
										}
									}}
								>
									<ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
								</IconButton>
							}
						/>
						{
							config.isMobileApp ?
								<Stack direction="row">
									<Stack
										sx={{
											cursor: "pointer",
											marginRight: "10px",
										}}
										onClick={() => intercom.show()}>
										<Image src="/images/msg-icon.svg" alt="icon" />
									</Stack>
									<IconButton size="small" onClick={this.handleLogoutClick}>
										<LogoutIcon fontSize="small" />
									</IconButton>
								</Stack>
								:
								<IconButton size="small" onClick={this.handleLogoutClick}>
									<LogoutIcon fontSize="small" />
								</IconButton>
						}
					</Stack>
					<Stack
						sx={{
							flex: 1,
							py: 0,
							mb: { xs: 0, md: 3 },
							borderRadius: 1,
							backgroundColor: "background.paper",
						}}
					>
						{/* <SectionTitle
                            sx={{ display: { xs: "flex" } }}
                            subheading={this.props.isPortfolioOnboardingCompleted ? "Back to Dashboard" : "Back to Product Page"}
                            startIcon={
                                <IconButton
									onClick={() => { 
										if (this.props.isPortfolioOnboardingCompleted) {
											this.props.history.replace('/jarvis-rms/dashboard')
										} else {
											this.props.history.replace('/customer/product-info')
										}
									}}
								>
                                    <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                                </IconButton>
                            }
                        /> */}

						<RMSOnboardingHeader
							isOnbordingHeaderVisible={isOnbordingHeaderVisible}
							currentStage={currentStage}
							heading={activeStage.heading}
							location={this.props.location}
							onboardingStages={onboardingStages}
							OnboardingSteps={rmsOnboardingSteps}
						/>
						<Stack
							sx={{
								px: { xs: 2, md: 4.5 },
								py: { xs: 0, md: 3 },
								flex: 1,
								position: "relative",
							}}>
							<Loader
								loading={this.props.isOnboardingDataLoading}
							/>
							{this.props.isOnboardingDataLoading
								? null
								: this.props.children}
						</Stack>
					</Stack>
				</Stack >
				<Modal
					open={isEditEmailModal}
					size={'xs'}
					disableClose={true}
				>
					<Text sx={{ fontWeight: 500 }} mb={2}>Edit Email Address</Text>
					<TextField
						label="Email"
						type="email"
						value={email}
						onChange={(e) => this.setState({ email: e.target.value })}
						helperText={emailError}
						error={emailError}
						sx={{ width: 1, mb: { xs: 4, md: 2 } }}
					/>
					<Stack direction={'row'} justifyContent="flex-end">
						<JarvisButton variant="outlined" disableEndIcon={true} sx={{ marginRight: '15px', fontSize: "1rem", color: '#212225', p: '10px 35px', fontWeight: '700', border: '1px solid #212225', boxShadow: "none", "&:hover": { color: "#38a1ff", } }} onClick={() => this.handleEditEmailModal(false, 'CANCEL')}>Cancel</JarvisButton>
						<JarvisButton disableEndIcon={true} onClick={() => this.handleSendLink()} sx={{ fontSize: "1rem", p: '10px 35px' }}>Send link</JarvisButton>
					</Stack>

				</Modal>
			</>
		);
	}
}

export default withRouter(RMSOnboardingLayoutContainer);
