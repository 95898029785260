import React from "react";
import { withRouter } from "react-router-dom";

import Text from "components/common/Text";
// import Link from "components/common/Link";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
// import Loader from "components/common/Loader";
import { config } from "config";

import JarvisCard from "components/JarvisCard";
import SIPForm from "components/InvestMore/SIPForm";
import InvestMoreInfo from "components/InvestMore/InvestMoreInfo";
import LumpsumForm from "components/InvestMore/LumpsumForm";
import PricingDetails from "components/InvestMore/PricingDetails";

import InvestMoreRebalanceContainer from "./InvestMoreRebalance/InvestMoreRebalance";
import Box from "components/common/Box";

// tracker
import { tracker } from "library/tracker";

// import ArrowIcon from "@mui/icons-material/ArrowForwardOutlined";
// import HistoryIcon from "@mui/icons-material/HistoryOutlined";

import { validationHelper } from "helper/validation";
import { investMoreApis } from "stores/investMore/investMoreApis";
import { investMoreParsers } from "stores/investMore/investMoreParsers";
import { dateTimeHelper } from "helper/dateTime";
import JarvisDialog from "components/JarvisDialog";

const screenTypes = {
  INVESTMENT_FORM: "INVESTMENT_FORM",
  INVESTMENT_PAYMENT: "INVESTMENT_PAYMENT",
  INVESTMENT_REBALANCE: "INVESTMENT_REBALANCE",
};

class InvestMoreContainer extends React.Component {
  inprogressTimer = 0;

  constructor(props) {
    super(props);
    this.state = {
      activeScreenType: screenTypes.INVESTMENT_FORM,
      investmentPricingData: {},
      formData: {
        investmentAmount: null,
        sipDate: new Date(),
      },
      formError: {
        investmentAmount: "",
      },
      isLoadingPricingData: false,
      inprogressTimerStarted: false,
      submitInvestMore: false,
      confirmDialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.getInvestmentStocksAction();
    // if (
    //   !Object.keys(this.props.investmentStocksData).length ||
    //   _get(this.props.location, "state.paymentStatus", "") === "SUCCEED" ||
    //   ["Completed", "NotConfirmed"].includes(
    //     _get(this.props.location, "state.brokerStatus", "")
    //   )
    // ) {
    // }
  }

  componentDidUpdate() {
    clearInterval(this.timerInProgress);


    // call api every 5 sec until it changes from inprogress to stocks
    if (this.props.investmentStocksData && this.props.investmentStocksData.investMoreCase === 2) {
      if (!this.state.inprogressTimerStarted) {
        this.setState({ inprogressTimerStarted: true });
      }
      this.timerInProgress = setInterval(() => {
        this.props.getInvestmentStocksAction();
      }, 8000)
    }
  }



  componentWillUnmount() {
    clearInterval(this.timerInProgress);
  }

  handleInputChange = ({ name, value }) => {
    if (name === "investmentAmount" && isNaN(value)) {
      return;
    }
    const { formData } = this.state;
    formData[name] = value;
    this.setState({ formData });
  };

  handleLumpsumValidation = () => {
    const { formData, formError } = this.state;

    const investmentAmountValidation = validationHelper.minInvestment(
      formData.investmentAmount,
      this.props.minInvestmentAmount
    );
    formError.investmentAmount = investmentAmountValidation.message;

    this.setState({ formError });
    return investmentAmountValidation.isValid;
  };

  handleLumpsumSubmit = () => {
    //NOTE : event_tracker => investMore
    //tracker.investMore.investMoreFeesCalculation();
    if (!this.handleLumpsumValidation()) {
      return;
    }

    this.setState({ confirmDialogOpen: true })

  };

  handleConfirmClose = () => {
    this.setState({ confirmDialogOpen: false });
  }

  handleConfirmSubmit = async () => {
    const { formData } = this.state;
    this.setState({ submitInvestMore: true });

    try {
      const res = await investMoreApis.submitInvestMore({
        investmentAmount: formData.investmentAmount,
      });
      const payload = investMoreParsers.submitInvestMoreResponse(res);
      await this.props.getInvestmentStocksAction();
      this.props.pendingCallAction()
      this.setState({
        submitInvestMore: false,
      });

    } catch (error) {
      //NOTE : event_tracker => investMore
      tracker.investMore.investMoreFeesCalculationFailed({ error_msg: error.message });
      // handle error
    } finally {
      this.setState({
        submitInvestMore: false,
        confirmDialogOpen: false,
      });
    }
  }

  handleSIPValidation = () => {
    const { formError } = this.state;

    this.setState({ formError });
    return true;
  };

  handleSIPSubmit = async () => {
    if (!this.handleSIPValidation()) {
      return;
    }

    // const { formData } = this.state;
    this.setState({ isLoadingPricingData: true });

    try {
      // handle api call
    } catch (error) {
      // handle error
    } finally {
      this.setState({ isLoadingPricingData: false });
    }
  };

  handlePaymentBackClick = () => {
    this.setState({
      activeScreenType: screenTypes.INVESTMENT_FORM,
      investmentPricingData: {},
    });
  };

  handlePaymentContinueClick = () => {
    const { formData } = this.state;

    const stateParams = {
      paymentType: "additionalInvestment",
      paymentAmount: formData.investmentAmount,
      returnURI: this.props.location.pathname,
      // returnURIDelaySecond: 25000,
    };

    if (this.props.investmentType === "SIP") {
      stateParams["additionalInvestmentType"] = "sip";
      stateParams["sipDate"] = dateTimeHelper.sipDate(formData.sipDate);
    } else {
      stateParams["additionalInvestmentType"] = "lumpsum";
    }

    //NOTE event_tracker => investMore
    tracker.investMore.investMoreProceedtoPay();
    this.props.history.push({
      pathname:"/customer/onetime-payment",
      state: stateParams,
    });
  };

  render() {
    const {
      formData,
      formError,
      activeScreenType,
      investmentPricingData,
      isLoadingPricingData,
      inprogressTimerStarted,
      submitInvestMore,
    } = this.state;
    const {
      minInvestmentAmount,
      investmentType,
      isInvestmentStocksLoading,
      investmentStocksData,
      profileData,
      brokersList,
      isBrokersListLoading,
      isBrokersListInitialCalled,
      getBrokersAction,
      pendingCallAction
    } = this.props;

    // show loader when broker list calling
    if (isInvestmentStocksLoading && !inprogressTimerStarted) {
      return (
        <JarvisCard
          sx={{
            maxHeight: 420,
            pb: 10,
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* <Loader
            position="absolute"
            loading={true}
          /> */}
          <Box sx={{ minHeight: { xs: "100%", md: "50vh" }, display: "flex", alignItems: "center", justifyContent: "center", }}>
            <img
              src="/images/portfolio-loader-video.gif"
              alt="loader"
              style={{ maxWidth: "320px", borderRadius: 3 }}
            />
          </Box>
        </JarvisCard>
      );
    }
    if (submitInvestMore) {
      return (
        <JarvisCard
          sx={{
            maxHeight: 420,
            pb: 10,
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* <Loader
            position="absolute"
            loading={true}
          /> */}
          <Box sx={{ minHeight: { xs: "100%", md: "50vh" }, display: "flex", alignItems: "center", justifyContent: "center", }}>
            <img
              src="/images/portfolio-loader-video.gif"
              alt="loader"
              style={{ maxWidth: "320px", borderRadius: 3 }}
            />
          </Box>
        </JarvisCard>
      );
    }

    return (
      <>
        {/* NOTE:
                investMoreCase 0:
                User is allowed for additional investment */}
        {investmentStocksData.investMoreCase === 0 ? (
          <JarvisCard
            sx={{
              height: "100%",
              px: { xs: 0, md: 4 },
              py: { xs: 1.5, md: 3 },
            }}
          >
            {activeScreenType === screenTypes.INVESTMENT_PAYMENT ? (
              <PricingDetails
                onBackClick={this.handlePaymentBackClick}
                onContinueClick={this.handlePaymentContinueClick}
                pricingData={investmentPricingData}
              />
            ) : null}

            {activeScreenType === screenTypes.INVESTMENT_FORM ? (
              <>
                <Grid container>
                  <Grid item lg={7}>
                    <Stack sx={{ pr: { xs: 0, md: 5 } }}>
                      {investmentType === "LUMPSUM" ? (
                        <LumpsumForm
                          onChange={this.handleInputChange}
                          onSubmit={this.handleLumpsumSubmit}
                          formError={formError}
                          formData={formData}
                          minInvestment={minInvestmentAmount}
                          isLoading={isLoadingPricingData}
                        />
                      ) : (
                        <SIPForm
                          onChange={this.handleInputChange}
                          onSubmit={this.handleSIPSubmit}
                          formError={formError}
                          formData={formData}
                          minInvestment={5000}
                          isLoading={isLoadingPricingData}
                        />
                      )}

                      {/* NOTE: Temprary hide history button */}
                      {/* <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{
                        mb: 2,
                        display: {
                          xs: "none",
                          md: "flex",
                        },
                      }}
                    >
                      <HistoryIcon
                        sx={{
                          fontSize: 26,
                          color: "primary.main",
                        }}
                      />
                      <Link
                        href="/customer/profile"
                        variant="subtitle2"
                        sx={{ fontWeight: "medium" }}
                      >
                        Investment History
                        <ArrowIcon
                          sx={{
                            fontSize: 16,
                            ml: 1,
                            color: "primary.main",
                          }}
                        />
                      </Link>
                    </Stack> */}
                    </Stack>
                  </Grid>

                  <Grid item lg={5}>
                    <InvestMoreInfo minimumAmount={minInvestmentAmount} />
                  </Grid>
                </Grid>
                {/* Modal: Confirmation of Plan Selection */}
                <JarvisDialog
                  open={this.state.confirmDialogOpen}
                  onClose={this.handleConfirmClose}
                  onConfirm={this.handleConfirmSubmit}
                  title={"Confirmation Required"}
                  content="Your portfolio will be rebalanced. Do you want to continue?"
                />
              </>
            ) : null}
          </JarvisCard>
        ) : null}

        {/*
                    NOTE:
                        investMoreCase 1:
                        User has already done an investment and can not be done for 7 days
                */}
        {investmentStocksData.investMoreCase === 1 ? (
          <JarvisCard sx={{ maxHeight: 420, pb: 10, background: config.isMobileApp ? "unset" : "#fff" }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ flex: 1, textAlign: "center" }}
              spacing={2}
            >
              <Text variant="subtitle1"> Awesome! </Text>
              <Text variant="small" sx={{ maxWidth: "550px" }}>
                {/* You Have Already Made Investment Few Day Before.
                <br />
                You Can't Add More Invest Before{" "}
                {investmentStocksData.nextInvestmentDate
                  ? dateTimeHelper.displayDate(
                      investmentStocksData.nextInvestmentDate
                    )
                  : "Completing 7 Days Period."}
                . */}
                {
                  investmentStocksData.investMoreMsg ?
                    investmentStocksData.investMoreMsg :
                    "You can certainly invest more funds. However, if you have just invested / rebalanced your portfolio, you will have to wait for 7 days to invest more."
                }
              </Text>
            </Stack>
          </JarvisCard>
        ) : null}

        {/*
                    NOTE:
                        investMoreCase 2:
                        User has completed payment recently and rebalance is not completed yet
                */}
        {investmentStocksData.investMoreCase === 2 ? (
          <JarvisCard sx={{ maxHeight: 420, background: config.isMobileApp ? "unset" : "#fff" }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ flex: 1, textAlign: "center", minHeight: { xs: "100%", md: "50vh" } }}
              spacing={2}
            >
              <img src="/images/portfolio-loader-video.gif" alt="portfolio" />
            </Stack>
          </JarvisCard>
        ) : null}

        {investmentStocksData.investMoreCase === 3 ||
          activeScreenType === screenTypes.INVESTMENT_REBALANCE ? (
          <InvestMoreRebalanceContainer
            investmentStocksData={investmentStocksData}
            profileData={profileData}
            isBrokersListInitialCalled={isBrokersListInitialCalled}
            brokersList={brokersList}
            getBrokersAction={getBrokersAction}
            isBrokersListLoading={isBrokersListLoading}
            pendingCallAction={pendingCallAction}
          />
        ) : null}
      </>
    );
  }
}

export default withRouter(InvestMoreContainer);
