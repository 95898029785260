import { combineReducers } from "redux";

import { commonReducers } from "./common/commonReducers";
import { customerReducers } from "./customer/customerReducers";
import { onboardingReducers } from "./onboarding/onboardingReducers";
import { transactionReducers } from "./transactions/transactionReducers";
import { rmsReducers } from "./rms/rmsReducers";
import { helpSupportReducer } from "./helpSupport/helpSupportReducers";
import { dashboardReducers } from "./dashboard/dashboardReducers";
import { investMoreReducers } from "./investMore/investMoreReducers";
import { masterReducers } from "./master/masterReducers";
import { thirdpartyReducers } from "./thirdparty/thirdpartyReducers";
import { portfolioHoldingReducers } from "./portfolioHolding/portfolioHoldingReducers";

// product's
import { jarvisRMSRootReducer } from "./jarvisRMS/jarvisRMSRootReducer";
import { jarvisOnestockRootReducer } from "./jarvisOnestock/jarvisOnestockRootReducer";
 

const rootReducers = combineReducers({
    common: commonReducers,
    customer: customerReducers,
    dashboard: dashboardReducers,
    onboarding: onboardingReducers,
    investMore: investMoreReducers,
    transaction: transactionReducers,
    rms: rmsReducers,
    helpSupport: helpSupportReducer,
    master: masterReducers,
    thirdparty: thirdpartyReducers,
    portfolioHolding:portfolioHoldingReducers,

    // product's
    jarvisRMS: jarvisRMSRootReducer,
    jarvisOnestock: jarvisOnestockRootReducer,
});

export default rootReducers;
