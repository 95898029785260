import React from 'react'
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisChipNew from 'components/JarvisChipNew';
import { formatHelper } from 'helper/format';
import { dateTimeHelper } from "helper/dateTime";

const MobileRMSTransaction = (props) => {
    const { detailsData } = props

    let textColor;

     if(detailsData?.valueSaved >= 0){
        textColor="#2E7D32"
     } else if(detailsData?.valueSaved < 0){
        textColor="#D44C4C"
        }



    return (
        <>
            <Stack sx={{ fontWeight: "400" }}>
                <Stack mb={1} direction="row" sx={{ justifyContent: "space-between" }}>
                    <Stack direction="row" sx={{ maxWidth: "50%" }}>
                        <Text variant="caption1" mr={1}><span style={{ color: "#A2A7AE" }}>Qty. </span>{detailsData?.sellQuantity}</Text>
                        {
                            detailsData?.transactionStatus === "Executed" ?
                                <JarvisChipNew sx={{ padding: "0px", marginTop: "-2px", fontSize: "0.65rem" }} size="small" label="Saved" color="success" />
                                :
                                <JarvisChipNew sx={{ padding: "0px", marginTop: "-2px", fontSize: "0.65rem" }} size="small" label="Opportunity lost" color="warning" />
                        }
                    </Stack>
                    <Stack sx={{ fontSize: "0.65rem", color: textColor, fontWeight: "500" }} direction="row">
                        {formatHelper.currency(detailsData?.valueSaved
                        ) || 0}
                        {/* <Text sx={{ fontSize: "0.65rem" }}>({detailsData?.valueSavedPercentage}%)</Text> */}
                    </Stack>
                </Stack>
                <Stack mb={1} direction="row" sx={{ justifyContent: "space-between", fontWeight: "500" }}>
                    <Text variant="small" sx={{ maxWidth: "50%" }}>{detailsData?.stockName}</Text>
                    <Text variant="small">
                        {formatHelper.currency(Math.abs(detailsData?.sellPrice)) || 0}
                    </Text>
                </Stack>
                <Stack mb={1} direction="row" sx={{ justifyContent: "space-between" }}>
                    <Text variant="caption1">
                        <span style={{ color: "#A2A7AE" }}>Sell Date </span>{dateTimeHelper.format(detailsData?.sellDate, '$DD/$MM/$YYYY')}
                    </Text>
                    <Text variant="caption1" sx={{ maxWidth: "50%" }}>
                        {
                            detailsData?.transactionStatus === "Executed" ?
                                <Text variant="caption1" sx={{ color: "#45AC70" }}>Executed</Text>
                                :
                                <Text variant="caption1" sx={{ color: "#ed6c02" }}>Not-Executed</Text>
                        }
                    </Text>
                </Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                    <Text variant="caption1" sx={{ maxWidth: "50%" }}>Change Price &nbsp; {formatHelper.currency(
                        Math.abs(detailsData?.changePrice)
                    ) || 0} </Text>
                </Stack>
            </Stack>
        </>
    )
}
export default MobileRMSTransaction;

