import { axios } from "helper/axios";
import { jarvisOnestockTransactionConstants } from "./jarvisOnestockTransactionConstants";
import { dateTimeHelper } from "helper/dateTime";

const jarvisOnestockTransactionApis = {};

jarvisOnestockTransactionApis.getTransactionApi = ({
	page,
	perPage,
	dateFrom,
	dateTo,
	transactionStatus,
}) => {

	const params = { page, perPage };
	if (dateFrom) {
		params["fromDate"] = dateTimeHelper.format(dateFrom, '$YYYY-$MM-$DD');
	}
	if (dateTo) {
		params["toDate"] = dateTimeHelper.format(dateTo, '$YYYY-$MM-$DD');
	}
	if (transactionStatus) {
		params["status"] = transactionStatus;
	}

	return axios.get(
		jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_API, {
		params
	});
};

jarvisOnestockTransactionApis.updateTransactionData= async ({
	oneStockTransactionId,
	sellPrice,
	buyPrice,
	stockQuantity,
	stockSymbol,
    transactionStatus,
	buyDate,
	sellDate
})=>{

	const payload={
		oneStockTransactionId,
		sellPrice,
		buyPrice,
		stockQuantity,
		stockSymbol,
        transactionStatus,
		buyDate,
		sellDate,
	}
	return axios.post(jarvisOnestockTransactionConstants.ONESTOCK_UPDATE_TRANSACTION_API,payload);
};
export { jarvisOnestockTransactionApis };
