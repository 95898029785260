export const helpSupportConstants = {
  CATEGORY_LIST_API: "v1/faqs",
  CATEGORY_LIST_SAGA: "CATEGORY_LIST_SAGA",
  CATEGORY_LIST_REQUEST: "CATEGORY_LIST_REQUEST",
  CATEGORY_LIST_RESPONSE: "CATEGORY_LIST_RESPONSE",

  CATEGORY_DATA_API: "v1/faqs/category/:id",
  CATEGORY_DATA_SAGA: "CATEGORY_DATA_SAGA",
  CATEGORY_DATA_REQUEST: "CATEGORY_DATA_REQUEST",
  CATEGORY_DATA_RESPONSE: "CATEGORY_DATA_RESPONSE",

  CATEGORY_QUESTIONS_API: "v1/faqs/category/:categoryId/question/:id",
  CATEGORY_QUESTIONS_SAGA: "CATEGORY_QUESTIONS_SAGA",
  CATEGORY_QUESTIONS_REQUEST: "CATEGORY_QUESTIONS_REQUEST",
  CATEGORY_QUESTIONS_RESPONSE: "CATEGORY_QUESTIONS_RESPONSE",
};
