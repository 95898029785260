// import { devConfig as getConfig } from "./dev";
// import { uatConfig as getConfig } from "./uat";
import { prodConfig as getConfig } from "./prod";
// import { qa1Config as getConfig } from "./qa1";
// import { qa2Config as getConfig } from "./qa2";
// import { qa3Config as getConfig } from "./qa3";

const platform = "WEB"; // to toggle between deployment platforms: WEB | ANDROID | IOS
const config = getConfig(platform)

export { config }
