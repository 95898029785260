import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return  {
        newsData: state.common.newsArticles,
        selectedNewsContent:state.common.selectedNewsContent,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    
},dispatch)

const NewsArticleContentStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default NewsArticleContentStore;
 