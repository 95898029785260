import React from 'react';

import Text from "components/common/Text";
import Stack from "components/common/Stack";

import CircleIcon from "@mui/icons-material/CircleRounded";
import RiskProfileGraph from 'components/Profile/RiskProfileGraph';

const RiskProfileMeter = ({ riskProfiles, selectedRiskId, chartWidth = 500 , disableLabel= false}) => {
    if (!selectedRiskId) {
        return null;
    }

    const selectedRiskProfileData = riskProfiles.find((p) => p.id === selectedRiskId);

    const riskProfileColors = {
        1: "#1C8D13", 2: "#83C605", 3: "#BCD103", 4: "#ECB004", 5: "#DF873A", 6: "#D44C4C"
    }

    return (
        <Stack alignItems="center">
            <RiskProfileGraph  riskProfileId={selectedRiskId} chartWidth={chartWidth} disableLabel={disableLabel}/>
        </Stack>
    )
}


export default RiskProfileMeter;