import React from "react";
import ForgotPasswordContainer from "containers/ForgotPassword";

const ForgotPassword = () => {
    return (
        <>
            <ForgotPasswordContainer />
        </>
    );
};

export default ForgotPassword;
