import * as React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import Card from "components/common/Card";
import CardContent from "components/common/CardContent";
import CardMedia from "components/common/CardMedia";
import Text from "components/common/Text";
import Box from "components/common/Box";

import { dataHelper } from "helper/data";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    // maxWidth: '100%',
    // marginBottom: "20px",
    borderRadius: "8px 8px 0 0",
    // [theme.breakpoints.up("md")]: {
    //     maxWidth: "50%",
    //     borderRadius: 0
    // },
    // [theme.breakpoints.up("lg")]: {
    //     maxWidth: "33.33%",
    // },
  },
  mediaImageWrapper:{
    backgroundColor: "#f9f9f9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "130px",
    [theme.breakpoints.up("sm")]:{
      height: "240px",
    }
  },

  mediaImage: {
    objectFit: "cover",
  },
  marketImage: {
    objectFit: "cover",
  },
  postInfoIcon: {
    fill: "white",
    padding: "2px 5px",
    background: "#6CA4DC",
  },
  postInfoText: {
    wordSpacing: "-2px",
    fontSize: "12px",
  },
  flexAndCenter: {
    display: "flex",
    alignItems: "center",
  },
  cardContentWrapper: {
    // padding: "24px",
  },
  cardContentRead: {
    marginTop: "16px",
  },
  cardContentTextFont: {
    fontFamily: "DM Sans",
  },
  commonSvgFill: {
    fill: "#929292",
  },
  commonColorFill: {
    color: "#929292",
  },
  bottomTagContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      paddingRight: "10px",
    },
  },
  bottomTagText: {
    fontSize: "12px",
  },
}));



export default function BlogCard(props) {
  // postCategoryTag = "",
  // postCommentCount = "",
  // postShare = "",
  // postDate = null,
  // postOwner = null,

  const classes = useStyles();
  const { title, imageUrl, description, author, readMoreLink, readMoreTrack } = props;
  return (
    <div className={classes.cardWrapper}>
      <Card>
        <Box
          className={classes.mediaImageWrapper}>
          <CardMedia
            component="img"
            image={imageUrl}
            className={classes.mediaImage}
          />
        </Box>
        <CardContent className={classes.cardContentWrapper}>
          <div className={classes.flexAndCenter}>
            <InsertDriveFileOutlinedIcon className={classes.postInfoIcon} />
            <Text
              ml={2}
              className={classNames(
                classes.commonColorFill,
                classes.postInfoText
              )}
            >
              {/* {postDate} BY {postOwner} */}
              {author === 1 && `BY ADMIN`}
            </Text>
          </div>
          <Text
            variant={"h4"}
            mt={3}
            sx={{ minHeight: "55px" }}
            color={"#202020"}
            pr={3}
          >
            {title}
          </Text>
          <Text
            variant={"body2"}
            sx={{ minHeight: { xs: "75px", md: "90px" }, mt: { xs: 2, md: 3 } }}
            className={classNames(
              classes.commonColorFill,
              classes.cardContentTextFont
            )}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: `${dataHelper.removeHTML(description)}...`,
              }}
            />
          </Text>
          <a
            href={readMoreLink}
            onClick={readMoreTrack}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <div
              className={classNames(
                classes.flexAndCenter,
                classes.cardContentRead
              )}
            >
              <Text className={classes.commonColorFill} variant={"caption"}>
                READ MORE{" "}
              </Text>
              <ArrowRightAltIcon className={classes.commonSvgFill} />
            </div>
          </a>
          {/* <div className={classes.bottomTagContainer}>
            <div className={classNames(classes.flexAndCenter, classes.cardContentRead)}>
              <FolderIcon className={classNames(classes.commonSvgFill, classes.bottomTagText)}/>
              <Text 
                className={classNames(classes.commonColorFill, classes.bottomTagText)} 
                ml={0.7}
              >
                {postCategoryTag}
              </Text>
            </div>
            <div className={classNames(classes.flexAndCenter, classes.cardContentRead)}>
              <ChatBubbleIcon className={classNames(classes.commonSvgFill, classes.bottomTagText)}/>
              <Text 
                className={classNames(classes.commonColorFill, classes.bottomTagText)} 
                ml={0.7}
              >
                {postCommentCount}
            </Text>
            </div>
            <div className={classNames(classes.flexAndCenter, classes.cardContentRead)}>
              <ShareIcon className={classNames(classes.commonSvgFill, classes.bottomTagText)}/>
              <Text 
                className={classNames(classes.commonColorFill, classes.bottomTagText)} 
                ml={0.7}>
                  {postShare}
              </Text>
            </div>
          </div> */}
        </CardContent>
      </Card>
    </div>
  );
}
