import React from "react";

import DashboardHeader from "components/Dashboard/DashboardHeader";

class DashboardHeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: "",
      subheading: "",
    };
  }

  componentDidMount() {
    const { isOnboardingCompleted, username } = this.props;
    let { heading, subheading } = this.state;

    if (isOnboardingCompleted) {
      heading = `Hi, ${username || "User"}`;
      subheading = `This is your Dashboard`;
    } else {
      heading = `Hi, ${username || ""}`;
      subheading = `Complete these steps to start Investing`;
    }

    this.setState({ heading, subheading });
  }

  render() {
    const { heading, subheading } = this.state;

    return <DashboardHeader heading={heading} subheading={subheading} />;
  }
}

export default DashboardHeaderContainer;
