import React from "react";
import OnestockActionCenterContainer from "containers/JarvisOnestock/OnestockActionCenter";

const OnestockActionCenter = () => {

	return (
		<OnestockActionCenterContainer />
	);
};

export default OnestockActionCenter;
