import { axios } from "../../helper/axios";
import { transactionConstants } from "./transactionConstants";

const transactionApis = {};


transactionApis.getTransactionList = ({ page, rowsPerPage })=>{
    return axios.get(transactionConstants.TRANSACTION_LIST_API, { 
        params: { 
            page, 
            limit: rowsPerPage,
            sort_direction: 'desc'
        } 
    });
}

export { transactionApis };
