import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { customerActions } from "stores/customer/customerActions";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import { thirdpartyActions } from "stores/thirdparty/thirdpartyActions";

const mapStateToProps = (state) => {
    const lockAmountInput = state.onboarding.isPaymentCompleted ? true : false;
    return {
        customerProfile: state.customer.profile,
        currentStage: state.onboarding.currentStage,
        loginProfile: state.customer.loginProfile,
        riskProfiles: state.common.riskProfiles,
        investmentStrategies: state.common.investmentStrategies,
        subscriptionPlans: state.thirdparty.subscriptionPlans,

        portfolioData: state.onboarding.portfolioData,
        marketBreakupData: state.onboarding.marketBreakupData,
        sectorBreakupData: state.onboarding.sectorBreakupData,

        isRiskProfilesLoading: state.common.loading.riskProfiles,
        isPortfolioDataLoading: state.onboarding.loading.portfolioData,
        isPaymentCompleted: state.onboarding.isPaymentCompleted,
        isSubscriptionPlansLoading: state.thirdparty.loading.subscriptionPlans,
        isSubscriptionPlansCalled: state.thirdparty.isInitialCalled.subscriptionPlans,
        hasOnboardingInvestmentDetailsConfirmed: state.onboarding.hasOnboardingInvestmentDetailsConfirmed,

        investmentInfo: {
            investmentAmount: state.onboarding.investmentAmount,
            investmentTenure: state.onboarding.investmentTenure,
            investmentStrategy: state.onboarding.investmentStrategy,
            riskProfile: state.onboarding.riskMasterId,
            planId: state.onboarding.planId,
        },
        // 
        lockAmountInput: false,
        investmentAmount: state.onboarding.investmentAmount,
        investmentTenure: state.onboarding.investmentTenure,
        investmentStrategy: state.onboarding.investmentStrategy,

        minInvestmentAmount: state.onboarding.minInvestmentAmount,
        // Tenure & strategy
        tenurePreferences: [
            { id: 2, label: "2 Yr", value: 2 },
            { id: 3, label: "3 Yr", value: 3 },
            { id: 5, label: "5 Yr", value: 5 },
            { id: 7, label: "7 Yr", value: 7 },
            { id: 11, label: "Other", value: "other" },
        ],
        tenurePreferencesList: [
            {year: 1, display: "1 Year"},
            {year: 4, display: "4 Year"},
            {year: 6, display: "6 Year"},
            {year: 8, display: "8 Year"},
            {year: 9, display: "9 Year"},
            {year: 10, display: "10 Year"},
        ],

        // Amont & plan
        investmentPlanList: [
             30000,
             100000,
             300000,
             5000000,
             "other"
        ]
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRiskProfilesAction: commonActions.getRiskProfiles,
            getPortfolioDataAction: onboardingActions.getPortfolioData,
            updateOnboardingDataAction: onboardingActions.updateOnboardingData,
            getOnboardingDataAction: onboardingActions.getOnboardingData,
            setToastAction: commonActions.setToast,
            getSubscriptionPlansAction: thirdpartyActions.getSubscriptionPlans,
            getProfileDataAction: customerActions.getProfile
        },
        dispatch
    );

const PortfolioCreationStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PortfolioCreationStore;
