import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const onboardingTracker = {};

onboardingTracker.startInvestingOnboardingCard = () => {
    // const eventName = "start_investing_onboarding_card";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });
    
    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

onboardingTracker.riskAssessmentStarted = () => {
    const eventName = "risk_assessment_started";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
    });
};

onboardingTracker.riskAssessmentCompleted = () => {
    // const eventName = "risk_assessment_completed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

onboardingTracker.riskProfileChange = () => {
    // const eventName = "risk_profile_change";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

onboardingTracker.riskProfileManuallyChange = () => {
    // const eventName = "risk_profile_manually_change";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

onboardingTracker.riskProfileConfirm = () => {
    // const eventName = "risk_profile_confirm";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

onboardingTracker.riskProfileConfirmError = ({ error_msg }) => {
    // const eventName = "risk_profile_confirm_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

// Analytics_tracker_active
onboardingTracker.riskProfileConfirmSuccess = () => {
    const eventName = "risk_profile_confirm_success";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
    });
};

onboardingTracker.investmentDetailsSubmitted = () => {
    // const eventName = "investment_details_submitted";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

onboardingTracker.investmentDetailsSubmittedError = ({ error_msg }) => {
    // const eventName = "investment_details_submitted_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

// Analytics_tracker_active
onboardingTracker.investmentDetailsSubmittedSuccess = () => {
    const eventName = "investment_details_submitted_success";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
    });
};

// Analytics_tracker_active
onboardingTracker.portfolioGenerated = () => {
    const eventName = "portfolio_generated";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
    });
};

onboardingTracker.unlockFullPortfolio = () => {
    // const eventName = "unlock_full_portfolio";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

export { onboardingTracker };
