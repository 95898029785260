export const prodConfig = (platform) => {
  const isMobileApp = platform !== "WEB";

  return {
    // togglers
    platform, // to toggle between deployment platforms: WEB | ANDROID | IOS
    environment: "PRODUCTION", // to toggle between environments: DEVELOPMENT | PRODUCTION
    isMobileApp: isMobileApp, // to toggle between mobile and web view: true | false
    debug: false, // true | false

    // Trakers toggler
    enableMixpanel: true,
    enableAppsflyer: true && isMobileApp,
    enableUxcam: true && isMobileApp, // true | false

    // endpoints and tokens
    apiUrl: "https://api4.jarvisinvest.com/", // node api server endpoint
    // apiUrl: "https://api5.jarvisinvest.com/", // node api server endpoint( note: current on live)
    legacyApiUrl: "https://api2.jarvisinvest.com/", // php api server endpoint
    websiteUrl: "https://www.jarvisinvest.com/", // front-end website base url
    tacUrl: "http://www.jarvisinvest.com/terms-and-conditions", // front-end website T&C
    disclaimerUrl: "http://www.jarvisinvest.com/disclaimer", // front-end website disclaimer

    // general config
    companyName: "Jarvis", // app name
    companyLogo:
      "https://jarvisinevst.b-cdn.net/assets/images/jarvis_header_logo.png", // company logo
    localStorageKeys: {
      tokenKey: "token", // node api token
      legacyTokenKey: "legacyToken", // php api token
      appsflyerData: "appsflyerData", // store campaign related data
      geoLocation: "geoLocation", // store lat and long data
      trackerConfig: "trackerConfig", // store tracker config
      featureConfig: "featureConfig", // store feature enable disable config
      activeProduct: "activeProduct", // stoer Active product
      seenJarvisInfo: "seenJarvisInfo", // new customer if see Jarvis infor page
      paymentInitiated: "paymentInitiated", // store if payment initiated or not
      isMobileView: "isMobileView", // used for mobile responsive view check
      ssoCustomerDetails: "ssoCustomerDetails", // used for storing the data of the sso customer.      
      isSsoCustomer: "isSsoCustomer", // used for storing the data of the sso customer.    
      ssoClientId: "ssoClientId", // used for storing the data of the sso customer.  
    },
    toastTimeout: 4000, // toast timeout
    productCodes: {
      RMS: "RMS",
      PORTFOLIO: "PORTFOLIO",
      ONESTOCK: "ONESTOCK",
    },

    // interceom config
    intercomId: {
      api_base: "https://api-iam.intercom.io",
      app_id: "l0kky5nw",
    },

    // google analytics config
    googleAnalytics: "UA-128216506-2",
    gtmId: "GTM-TMNTF8F",

    // app ids for social login
    loginAppId: {
      facebook: "2561521274138877",
      google:
        "779958761423-v38fgo12esla4bej2u8uts0brln0umkh.apps.googleusercontent.com",
      googleApp:
        "496928712576-cth57m2lo1uuk40mddsn2rila0ug72ou.apps.googleusercontent.com",
    },

    // appsFlyer config
    appsFlyer: {
      androidAppId: "com.neolite.jarvis.monitree",
      iosAppId: "id1619963534",
      devKey: "Lnf4eaC8VEerM4AiomXnqd",
    },

    // uxcam config
    uxcam: {
      apiKey: "tz6bfylbtiw9rbb",
    },

    // mixpanel config
    mixpanel: {
      token: "281a0eda24c094b6e1bb5e1f20fe4eb3",
    },

    // used for payment gateway integration
    payment: {
      gateway: {
        razorpay: "RAZORPAY",
      },
      razorpay: {
        scriptURI: "https://checkout.razorpay.com/v1/checkout.js",
        // OLD JARVIS
        // key: "rzp_live_DjAwPX57uxnddz",
        // NEW JARVIS
        key: "rzp_live_vJwzc2SYE0Dgct",
        onetime_key: "rzp_live_DjAwPX57uxnddz",
      },
    },

    // used for document signing process
    documentSigning: {
      gateway: {
        digio: "DIGIO",
      },
      digio: {
        scriptURI: "https://app.digio.in/sdk/v11/digio.js",
        environment: "production",
        method: "otp",
        isIframe: true,
      },
    },

    // used for broker integration
    broker: {
      executionType: {
        FETCH_HOLDING: "FETCH_HOLDING",
        TRANSACTION: "TRANSACTION",
      },
      statusIterationCount: 5,
      odinBrokerRedirectUrl: "https://api2.jarvisinvest.com/api/odin-call-back",
      kleverTradebrokerRedirectUrl:
        "https://api2.jarvisinvest.com/api/arham-wealth-call-back",
      duckBrokerRedirectUrl: "https://api2.jarvisinvest.com/api/duck-call-back",
      masterTrustbrokerRedirectUrl:
        "https://api2.jarvisinvest.com/api/master-trust-call-back",
      platform: {
        smallcase: "small_case",
        mastertrust: "mastertrust",
        saral: "saral",
        bazaarindicator: "bazaarindicator",
        arham: "arham",
        bigul: "bigul",
        odin: "odin",
        wealthmunshi: "wealthmunshi",
        arhamshare: "arhamshare",
        tradecircle: "tradecircle",
        klevertrade: "klevertrade",
        duck: "duck",
        iooa: "iooa",
        orchidResearch: "orchidResearch",
        moneyMantra: "moneyMantra",
        lifeSkill: "lifeSkill",
        symphony: "symphony",
        kambala: "kambala",
        sagarfin: "sagarfin",
        findoc: "findoc",
        phillip: "phillip",
      },
      ssoPlatform: {
        centrum: 'centrum',
      },
      smallcase: {
        scriptURI: "https://gateway.smallcase.com/scdk/2.0.0/scdk.js",
        gateway: "jarvisinvest",
        config: {
          amo: false,
          leprechaunEnabled: false,
        },
        logoUrl: "",
      },
      arham: {

          logoUrl: "/images/brokers/arhamshares.png",

      },
      bigul: {
        logoUrl: "/images/brokers/bigul.png",
      },
      saral: {
        kunvarji: {
          logoUrl: "/images/brokers/kunvarji_logo.png",
        },
      },

      // sub-domain
      mastertrust: {
        logoUrl: "/images/brokers/master_trust_logo.png",
      },
      "demo-mastertrust": {
        logoUrl: "/images/brokers/master_trust_logo.png",
      },
      bazaarindicator: {
        logoUrl: "/images/brokers/bazaar-indicator.png",
      },
      "demo-bazaarindicator": {
        logoUrl: "/images/brokers/bazaar-indicator.png",
      },
      wealthmunshi: {
        logoUrl: "/images/brokers/wealthmunshi.png",
      },
      "demo-wealthmunshi": {
        logoUrl: "/images/brokers/wealthmunshi.png",
      },
      arhamshare: {
        logoUrl: "/images/brokers/arhamshares.png",
      },
      "demo-arhamshare": {
        logoUrl: "/images/brokers/arhamshares.png",
      },
      tradecircle: {
        logoUrl: "/images/brokers/tradeCirlce.png",
      },
      "demo-tradecircle": {
        logoUrl: "/images/brokers/tradeCirlce.png",
      },
      klevertrade: {
        logoUrl: "/images/brokers/klevertrade_login.png",
      },
      duck: {
        logoUrl: "/images/brokers/duck_login.png",
      },
      iooa: {
        logoUrl: "/images/brokers/iooa_login.png",
      },
      orchidresearch: {
        logoUrl: "/images/brokers/orchidresearch_login.png",
      },
      moneymantra: {
        logoUrl: "/images/brokers/moneymantra_login.png",
      },
      lifeskill: {
        logoUrl: "/images/brokers/lifeskill_login.png",
      },
      banksathi: {
        logoUrl: "/images/brokers/banksathi_login.png",
      },
      naviamarkets: {
        logoUrl: "/images/brokers/naviamarkets_login.png",
      },
      gillbroking: {
        logoUrl: "/images/brokers/gillbroking_login.png",
      },
      kalpataru: {
        logoUrl: "/images/brokers/kalpataru_login.png",
      },
      jainambroking: {
        logoUrl: "/images/brokers/jainambroking_login.png",
      },
      phillip: {
        logoUrl: "/images/brokers/phillip_login.png",
      },
      sagarfin: {
        logoUrl: "/images/brokers/sagarfin_login.png",
      },
      findoc: {
        logoUrl: "/images/brokers/findoc_login.png",
      },
      shoonya: {
        logoUrl: "/images/brokers/shoonya_login.png",
      },
      bnrathi: {
        logoUrl: "/images/brokers/bnrathi_login.png",
      },
      centrum: {
        logoUrl: "/images/brokers/centrum_login.png",
      },
      tradesmart: {
        logoUrl: "/images/brokers/tradeSmart_login.png",
      },
    },
    RMSUploadFileSize: 1024, // 1MB
    CENTRUM_REDIRECTION_URL: "https://connect.centrumgalaxc.com/fintechs/landing",
  };
};
