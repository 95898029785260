import _get from "lodash/get";
import { config } from "config";

const thirdpartyParsers = {};

thirdpartyParsers.initiatePayment = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        orderId: _get(res, "razorpay_order_id", null),
        amount: _get(res, "subscriptionFees", null),
        currency: _get(res, "currency", null),
        gateway: _get(res, "gateway", "RAZORPAY"),
    };
};

thirdpartyParsers.initiateDocumentSigning = (res) => {
    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        documentId: _get(res, "document_id", null),
        accessToken: _get(res, "access_token", null),
        identifier: _get(res, "identifier", null),
        gateway: _get(res, "gateway", "DIGIO"),
    };
};

thirdpartyParsers.transactionHistoryList = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {
            list: [],
            listCount: 0,
        };
    }

    return {
        list: _get(res, 'list', []).map((e) => ({
            planId: _get(e, "planId", ""),
            planName: _get(e, "planName", ""),
            planStatus: _get(e, "planStatus", ""),
            paymentStatus: _get(e, "paymentStatus", ""),
            paymentAmount: _get(e, "paymentAmount", ""),
            createdAt: _get(e, "createdAt", ""),
            customerPaymentId: _get(e, "customerPaymentId", ""),
        })),
        listCount: _get(res, 'listCount', 0),
    };
};

thirdpartyParsers.smallCaseTransaction = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    const orderBatch = _get(res, "orderBatches[0]", null);
    if (!orderBatch) {
        return {};
    }

    return {
        batchId: _get(orderBatch, "batchId", null),
        status: _get(orderBatch, "status", null),
        quantity: _get(orderBatch, "quantity", null),
        filled: _get(orderBatch, "filled", null),
        buyAmount: _get(orderBatch, "buyAmount", null),
        sellAmount: _get(orderBatch, "sellAmount", null),
    };
};

thirdpartyParsers.brokerPreOrder = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        token: _get(res, "Auth.token", null),
        accessToken: _get(res, "Auth.access_token", null),
        transactionId: _get(res, "TransactionID", null),
        brokerPlatform: _get(res, "BrokerPlatform", null),
        stockDetails: _get(res, "StockDtls", []).map((e) => ({
            symbol: _get(e, "symbol", ""),
            quantity: _get(e, "qty", ""),
            stockName: _get(e, "companyName", ""),
            sector: _get(e, "sector", ""),
            currentPrice: Number(Number(_get(e, "price", "")).toFixed(2)),
            cap: _get(e, "cap", ""),
            riskStatus: _get(e, "stockRisk", null),
            transactionAction: _get(e, "action", null),
        })),
    };
};

thirdpartyParsers.brokerTransactionStatus = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        brokerTransactionStatus: _get(res, "transaction_status", "Pending"),
        completedStocks: _get(res, "completed_stocks", null),
        totalStocks: _get(res, "total_stocks", null),
    };
};

thirdpartyParsers.brokerLogin = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    const parseSecurityQuestions = (questions) =>
        questions.map((q) => ({
            questionText: _get(q, "question", null),
            questionId: _get(q, "question_id", null),
            questionName: _get(q, "question_name", "year"),
        }));

    return {
        securityQuestions: parseSecurityQuestions(_get(res, "questions", [])),
        token: _get(res, "token", null),
    };
};

thirdpartyParsers.brokerPreviousAccessToken = (res, productCode) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        valid: _get(res, productCode === config.productCodes.PORTFOLIO ? "valid" : "isValid", null),
        clientId: _get(res, productCode === config.productCodes.PORTFOLIO ? "client_id" : "clientId", null),
        balance: _get(res, "balance", null),
    };
};

const parseBrokerOrder = (o) => ({
    orderStatus: _get(o, "orderStatus", null),
    orderQuantity: _get(o, "orderQty", null),
    symbol: _get(o, "symbol", null),
    type: _get(o, "type", null),
    price: _get(o, "price", null),
    filledQuantity: _get(o, "filledQty", null),
    exchangeOrderId: _get(o, "exchangeOrderId", null),
    exchange: _get(o, "exchange", null),
    brokerOrderId: _get(o, "brokerOrderId", null),
    status: _get(o, "status", null),
    displayStatus: _get(o, "display", null),
});

thirdpartyParsers.brokerPlaceOrder = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.length) {
        return [];
    }

    res = {
        list: res.map((e) => ({
            symbol: _get(e, "symbol", ""),
            quantity: _get(e, "orderQty", 0),
            pendingActionableQty:
                _get(e, "orderQty", 0) - _get(e, "filledQty", 0),
            stockName: _get(e, "companyName", _get(e, "symbol", "")),
            sector: _get(e, "sector", ""),
            currentPrice: Number(Number(_get(e, "price", "")).toFixed(2)),
            cap: _get(e, "cap", ""),
            riskStatus: _get(e, "stockRisk", ""),
            status: _get(e, "brokerOrderStatus", ""),
            transactionAction: _get(e, "type", null) === "BUY" ? "B" : "S",
        })),
        completedStocks: _get(res, "completed_stocks", null),
        totalStocks: _get(res, "total_stocks", null),
    };

    if (!res.completedStocks) {
        res.completedStocks = res.list.filter(
            (r) =>
                `${r.status}`.toLowerCase() === "completed" ||
                `${r.status}`.toLowerCase() === "complete"
        ).length;
    }
    if (!res.totalStocks) {
        res.totalStocks = res.list.length;
    }
    return res;
};

thirdpartyParsers.brokerOrderStatus = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.length) {
        return [];
    }

    return res.map(parseBrokerOrder);
};

thirdpartyParsers.marketOpenHRStatus = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }
};

thirdpartyParsers.subscriptionPlans = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.length) {
        return [];
    }

    return res.map((plan) => ({
        planId: _get(plan, "planId", null),
        planName: _get(plan, "planName", null),
        planCode: _get(plan, "planCode", null),
        planChecklist: _get(plan, "planChecklist", null),
        planPrice: _get(plan, "planPrice", null),
        planDiscountPercentage: _get(plan, "planDiscountPercentage", null),
        planGSTPercentage: _get(plan, "planGSTPercentage", 18),
        planFrequency: _get(plan, "planFrequency", null),
        planSequence: _get(plan, "planSequence", null),

        planDiscountPrice: _get(plan, "planDiscountPrice", null),
        planDiscountedPrice: _get(plan, "planDiscountedPrice", null),

        tagLabel: _get(plan, "tagLabel", null),
        tagColor: _get(plan, "tagColor", null),
        tagBackgroundColor: _get(plan, "tagBackgroundColor", null),

        productMap: _get(plan, "productMap", []).map((pm) => ({
            productCode: _get(pm, "productCode", null),
            productName: _get(pm, "productName", null),
            productDescription: _get(pm, "productDescription", null),
            stockLimit: _get(pm, "stockLimit", null),
            portfolioLimit: _get(pm, "portfolioLimit", null),
            aumLimit: _get(pm, "aumLimit", null),
        })),
    }));
};

thirdpartyParsers.fetchHolding = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        transactionId: _get(res, "data.TransactionID", null),
        portfolioId: _get(res, "portfolioId", null),
        status: _get(res, "status", null),
        token: _get(res, "data.Token", null),
    }
}

thirdpartyParsers.checkSmallCaseHoldingTransactinStatus = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        brokerTransactionStatus: _get(res, "transactionStatus", null),
        portfolioId: _get(res, "portfolioId", null),
    }
}

thirdpartyParsers.captureSubscription = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        subscriptionStatus: _get(res, "subscriptionStatus", null),
        customerSubscriptionId: _get(res, "customerSubscriptionId", null),
    };
};


thirdpartyParsers.checkSubscriptionStatus = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        subscriptionStatus: _get(res, "subscriptionStatus", null),
        customerSubscriptionId: _get(res, "customerSubscriptionId", null),
    };
};

thirdpartyParsers.initiateRecurringPayment = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        gateway: _get(res, "gateway", "RAZORPAY"),
        gatewayPayload: {
            currency: "INR",
            customer_id: _get(res, "customer_id", ""),
            order_id: _get(res, "order_id", ""),
            recurring: _get(res, "recurring", ""),
            description: _get(res, "description", ""),
        },
        customerSubscriptionId: _get(res, "customer_subscription_id", ""),
    };
};
thirdpartyParsers.initiateOneTimePayment = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        gateway: _get(res, "gateway", "RAZORPAY"),
        gatewayPayload: {
            currency: "INR",
            order_id: _get(res, "order_id", ""),
            description: _get(res, "description", ""),
        },
        customerSubscriptionId: _get(res, "customer_subscription_id", ""),
        prefill: _get(res,'prefill',{}),
    };
};

thirdpartyParsers.checkRecurringPaymentStatus = (res) => {
    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        status: _get(res, "status", null)
    }
}
thirdpartyParsers.checkOnetimePaymentStatus = (res) => {
    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        status: _get(res, "status", null)
    }
}

export { thirdpartyParsers };
