import React from "react";
import OnestockTransactionContainer from "containers/JarvisOnestock/OnestockTransaction";

function Transaction() {
    return (
        <>
            <OnestockTransactionContainer />
        </>
    );
}

export default Transaction;