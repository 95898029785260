import React from "react";

import ChecklistIcon from "@mui/icons-material/CheckCircle";

import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Box from "components/common/Box";

import { config } from "config/index";
import { formatHelper } from "helper/format";


import CheckIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from '@mui/icons-material/CircleOutlined';
import { makeStyles } from "@mui/styles";

import cx from "classnames";

const planTab = {
    MONTHLY: "Monthly",
    HALF_YEARLY: "Half-Yearly",
    YEARLY: "Yearly",
    QUARTERLY: "Quarterly",
}

const planTypes = {
    MONTHLY: "month",
    HALF_YEARLY: "six months",
    QUARTERLY: "four months",
    YEARLY: "year",
}

const planTypes2 = {
    MONTHLY: "months",
    HALF_YEARLY: "six months",
    QUARTERLY: "four months",
    YEARLY: "years",
}


const JarvisSubscriptionPlanCard = (props) => {
    const classes = useStyles();

    const { diasbleCircle = false, forInfo } = props
    const {
        planId,
        planName,
        planChecklist,
        tagLabel,
        tagColor,
        tagBackgroundColor,
        planPrice,
        planDiscountPercentage,
        planDiscountedPrice,
        planFrequency,

        productMap,
    } = props.planData;


    let isOutOfLimitPlan = false;
    productMap.forEach(product => {
        if (forInfo) {
            return isOutOfLimitPlan = true;
        }
        if (product.productCode === config.productCodes.PORTFOLIO) {
            if (product.aumLimit === -1 || Number(props.currentInvestmentAmount) <= (product.aumLimit)) {
                return isOutOfLimitPlan = true;
            }
        }
        if (product.productCode === config.productCodes.RMS) {
            if (forInfo) {
                return isOutOfLimitPlan = true;
            }
            if (
                (product.portfolioLimit === -1 && product.stockLimit === -1) ||
                (
                    (Number(props.currentPortfolioCount) <= Number(product.portfolioLimit)) &&
                    (Number(props.currentStockCount) <= Number(product.stockLimit))
                )
            ) {
                return isOutOfLimitPlan = true;
            }
        }
        if (product.productCode === config.productCodes.ONESTOCK) {
            if (forInfo) {
                return isOutOfLimitPlan = true;
            }
            if (
                (product.portfolioLimit === -1 && product.stockLimit === -1)
            ) {
                return isOutOfLimitPlan = true;
            }
        }
    })

    let isSelectedPlan = false;
    let isPlanDisabled = false;
    isSelectedPlan = (props.selectedPlanData?.planId === planId);
    isPlanDisabled = (props.disabledPlanData?.planId === planId);


    return (
        // card Wrapper
        <Stack alignItems="flex-start"
            className={cx(
                classes.cardWrapper,
                !diasbleCircle ? { [classes.selectedCard]: isSelectedPlan } : "",
                { [classes.disabledCard]: !isOutOfLimitPlan || isPlanDisabled }
            )}
            onClick={() => isOutOfLimitPlan && !isPlanDisabled ? props.onPlanSelect() : ""}
        >
            {/* circle  */}
            {
                diasbleCircle ? ""
                    :
                    (
                        <Stack className={classes.circleTag}>
                            <Box >
                                {
                                    isSelectedPlan ?
                                        <CheckIcon sx={{
                                            color: "primary.main",
                                        }} /> : (
                                            <CircleIcon
                                                sx={{ color: "#D0D5DD" }}
                                            />
                                        )
                                }
                            </Box>
                        </Stack>
                    )
            }

            {/* Plan Name */}
            <Text variant="subtitle2" >{planName}</Text>
            <Box
                sx={{
                    visibility: tagLabel ? "visible" : "hidden",
                }}
            >
                <Text
                    sx={{
                        backgroundColor: tagBackgroundColor || "#e0953d1a",
                        borderRadius: "20px",
                        color: tagColor || "#E0953D",
                        padding: "4px 11px",
                        fontSize: "12px",
                        fontWeight: 700,
                    }}
                >
                    {tagLabel}
                </Text>
            </Box>

            {/* Plan check list */}
            <Stack sx={{ flex: 1, mt: 1.5, mb: 1 }}>
                {planChecklist.map((check, index) => (
                    <Stack key={`check_${index}`} direction="row" alignItems="flex-start" sx={{ mb: 1 }}>
                        <ChecklistIcon sx={{ fontSize: 16, color: "#45AC70", mr: 1, mt: 0.45 }} />
                        <Text variant="small">{check}</Text>
                    </Stack>
                ))}
            </Stack>

            {/* highlighted Price */}
            {props.paymentType !== "RENEWAL" && planPrice && planDiscountPercentage ?
                <Stack className={classes.priceWrapper}>
                    <Stack direction="row" alignItems="center">
                        <Text className={classes.planPrice}>{formatHelper.currency(planDiscountedPrice)}&nbsp;</Text>
                        <Text sx={{ color: "#676E76" }}><span style={{ textDecoration: "line-through" }}>{formatHelper.currency(planPrice)}</span>&nbsp;for the first {planTypes[planFrequency]}</Text>
                    </Stack>
                    <Text sx={{ color: "#676E76" }}>and {formatHelper.currency(planPrice)} for the subsequent {planTypes2[planFrequency]}</Text>
                </Stack>
                :
                <Stack>
                    <Stack direction="row" alignItems="center">
                        <Text className={classes.planPrice} >{formatHelper.currency(planPrice)}</Text>/{planTab[planFrequency]}
                    </Stack>
                </Stack>
            }
        </Stack>
    )
}


// Styles
const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        position: "relative",
        flex: "0 0 290px",
        padding: "30px 24px",
        borderRadius: "12px",
        height: "100%",
        border: "1px solid #DEDEDE",
        background: "#fff",
        cursor: "pointer",
    },
    selectedCard: {
        borderColor: "#1566D6 !important",
    },
    disabledCard: {
        filter: "grayscale(1)",
        cursor: "not-allowed",
    },
    circleTag: {
        position: "absolute",
        top: 12,
        right: 12,
    },
    planPrice: {
        lineHeight: "1.1",
        fontSize: "1.5rem",
        fontWeight: "600"
    }
}))



export default JarvisSubscriptionPlanCard;