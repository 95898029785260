import React from "react";

import Paper from "components/common/Paper";

const JarvisCard = ({ sx = {}, ...props }) => {
  const _sx = {
    borderRadius: 2.5,
    backgroundColor: "background.paper",
    boxShadow: 0,
    px: 3,
    py: 2.5,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    ...sx
  };

  return (
    <Paper sx={_sx} {...props} />
  );
};

export default JarvisCard;
