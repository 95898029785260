import React from "react";
import JarvisCard from "components/JarvisCard";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Chart from "components/common/Chart";

class PerformanceChartContainer extends React.Component {
    componentDidMount() {
        this.props.getPortfolioChartAction();
    }
    render() {
        const { portfolioChart } = this.props;
        const { portfolio = [], nifty = [] } = portfolioChart;

        const options = {
            chart: {
                type: "spline",
                zoomType: "x",
            },
            title: {
                text: "",
            },
            xAxis: {
                type: "datetime",
            },
            yAxis: {
                title: {
                    text: "",
                },
            },
            series: [
                {
                    name: "Portfolio",
                    data: portfolio,
                },
                {
                    name: "NIFTY50",
                    data: nifty,
                },
            ],
        };

        return (
            <JarvisCard
                sx={{
                    maxWidth: "100%",
                    mb: { xs: 0, md: 2 },
                    border: { xs: "1px solid #EEF0F2", md: "none" },
                }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 2, minHeight: "38px" }}>
                    <Text variant="subtitle2">Performance Chart</Text>

                    {/* Last 12 Months input will come here */}
                </Stack>

                {nifty.length || portfolio.length ? (
                    <Chart options={options} />
                ) : (
                    <Text variant="small">No data in chart</Text>
                )}
            </JarvisCard>
        );
    }
}

export default PerformanceChartContainer;
