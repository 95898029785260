import { config } from "config";
import mixpanel from "mixpanel-browser";

const mixpanelTracker = {};

mixpanelTracker.instance = null;
mixpanelTracker.distinctId = null;
mixpanelTracker.enabled = false;
mixpanelTracker.token = null;

mixpanelTracker.updateConfig = ({ platform = [], token = "" }) => {
    mixpanelTracker.enabled = platform.includes(config.platform);
    mixpanelTracker.token = token;
};

mixpanelTracker.init = () => {
    if (!mixpanelTracker.enabled || mixpanelTracker.instance || !mixpanelTracker.token) {
        return;
    }

    mixpanelTracker.instance = mixpanel.init(
        mixpanelTracker.token,
        {
            loaded: (m) => {
                mixpanelTracker.distinctId = m.get_distinct_id();
            },
        },
        "mixpanel"
    );
};

mixpanelTracker.alias = (userId) => {
    if (!mixpanelTracker.enabled) {
        return;
    }

    mixpanel.alias(userId);
};

mixpanelTracker.identify = (userId) => {
    if (!mixpanelTracker.enabled) {
        return;
    }

    mixpanel.identify(userId);
};

mixpanelTracker.register = (properties) => {
    if (!mixpanelTracker.enabled) {
        return;
    }

    mixpanel.people.set(properties);
};

mixpanelTracker.trackEvents = ({ eventName, eventData = {} }) => {
    if (!mixpanelTracker.enabled) {
        return;
    }

    if (Object.keys(eventData).length) {
        mixpanelTracker.register(eventData);
    }

    mixpanel.track(eventName, eventData);
};

mixpanelTracker.reset = () => {
    if (!mixpanelTracker.enabled) {
        return;
    }

    mixpanel.reset();
};

export { mixpanelTracker };
