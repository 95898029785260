import React from "react";
import Modal from "components/common/Modal";
import InvestmentStrategyForm from "components/Profile/InvestmentStrategyForm";
import { customerApis } from "stores/customer/customerApis";
import InvestmentStrategyCard from "components/Profile/InvestmentStrategyCard";
import ConfirmTakeActionDialog from "components/Profile/ConfirmTakeActionDialog";

import { withRouter } from "react-router-dom";
import { tracker } from "library/tracker";
class InvestmentStrategyContainer extends React.Component {
  componentDidMount() {
    if (!this.props.investmentStrategy && this.props.isProfileLoading) {
      this.props.getProfileAction();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentStrategyVal: this.props.selectedStrategyVal,
      formModalOpen: false,
      isFormLoading: false,

      afterUpdateStrategy: {
        dialogOpen: false,
        isRebalanceGenerated: false,
        resposeMsg: "",
      },
    };
  }

  handleInvestmentStrategy = (onClose) => {
    
    //event-tracker: Inestment Strategy(IS) -> is_continue
    tracker.investmentStrategy.isContinue();
    this.setState({ currentStrategyVal: this.props.selectedStrategyVal });
    this.toggelFormOpen();
    onClose();
  };

  toggelFormOpen = () => {
    const { formModalOpen } = this.state;
    this.setState({ formModalOpen: !formModalOpen });
  };

  toggelActionDialog = () => {
    const { afterUpdateStrategy } = this.state;
    afterUpdateStrategy.dialogOpen = !afterUpdateStrategy.dialogOpen;
    this.setState({ afterUpdateStrategy });
  };

  setStrategy = (val) => {
    this.setState({ currentStrategyVal: val });
  };

  handleSubmit = async () => {
    this.setState({ isFormLoading: true });
    try {
      const body = {
        investmentStrategy: this.state.currentStrategyVal,
      };
      //event-tracker: Investment Startegy -> confirm_this_investment_strategy
      tracker.investmentStrategy.confirmThisInvestmentStrategy();
      const res = await customerApis.updateInvestmenStrategy(body);
      //event-tracker: Investment Startegy -> confirm_this_investment_strategy_success
      tracker.investmentStrategy.confirmThisInvestmentStrategySuccess();
      // const res = {
      //   message: "Your strategy is updated successfully",
      //   isRebalanceGenerated: true,
      // };
      this.toggelFormOpen();

      if (res.isRebalanceGenerated) {
        this.props.updateInvestmenStrategyAction(body);
      }

      const { afterUpdateStrategy } = this.state;
      afterUpdateStrategy.resposeMsg = res.message;
      afterUpdateStrategy.isRebalanceGenerated = res.isRebalanceGenerated;

      this.setState({ afterUpdateStrategy }, () => this.toggelActionDialog());
    } catch (e) {
      //event-tracker: Investment Startegy -> confirm_this_investment_strategy_failed
      tracker.investmentStrategy.confirmThisInvestmentStrategyFailed({error_msg:e.message});
    } finally {
      this.setState({ isFormLoading: false });
    }
  };

  redirectToActionCenter = () => {
    this.props.history.push("/customer/profile");
  };

  render() {
    const { selectedStrategyVal } = this.props;
    const { afterUpdateStrategy, formModalOpen } = this.state;

    return (
      <>
        <InvestmentStrategyCard
          investmentStrategyVal={selectedStrategyVal}
          onChangeClick={this.handleInvestmentStrategy}
        />

        <Modal
          title={"Change Investment Strategy"}
          size={"sm"}
          open={formModalOpen}
          onClose={() => this.toggelFormOpen()}
          sx={{ p: "15px 0px;" }}
        >
          <InvestmentStrategyForm
            currentStrategyVal={this.state.currentStrategyVal}
            setStrategy={this.setStrategy}
            onSubmit={this.handleSubmit}
            loading={this.state.isFormLoading}
          />
        </Modal>

        <ConfirmTakeActionDialog
          open={afterUpdateStrategy.dialogOpen}
          message={afterUpdateStrategy.resposeMsg}
          isRebalanceGenerated={afterUpdateStrategy.isRebalanceGenerated}
          onContinue={this.redirectToActionCenter}
          onClose={this.toggelActionDialog}
        />
      </>
    );
  }
}

export default withRouter(InvestmentStrategyContainer);
