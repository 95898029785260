import React from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import JarvisCard from "components/JarvisCard";
import Image from "components/common/Image";
import { formatHelper } from "helper/format";

const MobileRMSDashboardStats = (props) => {
    const { portfolioStats, rmsCallStats } = props;
    const { totalAUM, totalStocks } = portfolioStats;
    const {
        totalRMSCalls,
        totalDownfallSaved,
        totalDownfallSavedPercentage,
        totalOpportunityLost,
        // totalOpportunityLostPercentage,
    } = rmsCallStats;

    return (
        <>
            <JarvisCard sx={{ border: "1px solid #EEF0F2", marginBottom: "12px" }}>
                <Stack>
                    <Text sx={{ color: "#000000", fontWeight: "700", fontSize: "subtitle2" }}>{formatHelper.currency(Math.abs(totalAUM).toFixed(0)) || 0}</Text>
                    <Text variant="caption1" sx={{ color: "#808591" }}>AUM under monitor</Text>
                    <Text mt={2} mb={2} sx={{ border: "1px solid #F5F5F5" }}></Text>
                    <Stack direction="row">
                        <Stack sx={{ minWidth: "60%" }}>
                            <Stack direction="row" alignItems="center" sx={{ color: "#45AC70" }} >
                                <Image src="/images/product-info/mobile-average-downfall.png" width={10} />
                                {formatHelper.currency(
                                    Math.abs(totalDownfallSaved)
                                ) || 0}

                                {/* <Text
                                    pl={3}
                                    sx={{
                                        display: "flex"
                                    }}
                                >
                                    {(Math.abs(totalDownfallSavedPercentage) || 0) +
                                        "%"}
                                </Text> */}
                            </Stack>
                            <Text variant="caption1" sx={{ color: "#808591" }}>Average downfall saved</Text>
                        </Stack>
                        <Stack sx={{ minWidth: "40%" }}>
                            <Stack direction="row" alignItems="center" sx={{ color: "#E0953D" }}>
                                {formatHelper.currency(
                                    Math.abs(totalOpportunityLost)
                                ) || 0}

                                {/* <Text
                                    pl={3}
                                    sx={{
                                        display: "flex"
                                    }}
                                >
                                    {(Math.abs(totalOpportunityLostPercentage) ||
                                        0) + "%"}
                                </Text> */}
                            </Stack>
                            <Text variant="caption1" sx={{ color: "#808591" }}>Opportunity lost</Text>
                        </Stack>
                    </Stack>
                </Stack>
            </JarvisCard >
            <JarvisCard sx={{ border: "1px solid #EEF0F2", marginBottom: "20px" }}>
                <Stack direction="row">
                    <Stack sx={{ minWidth: "60%" }}>
                        <Stack>
                            <Text sx={{ fontWeight: "700", color: "#222B3E" }}>{totalStocks}</Text>
                            <Text variant="caption1" sx={{ color: "#808591" }}>Stocks under monitor</Text>
                        </Stack>
                    </Stack>
                    <Stack sx={{ minWidth: "40%" }}>
                        <Stack>
                            <Text sx={{ fontWeight: "700", color: "#222B3E" }}>{totalRMSCalls}</Text>
                            <Text variant="caption1" sx={{ color: "#808591" }}>Total sell calls</Text>
                        </Stack>
                    </Stack>
                </Stack>
            </JarvisCard>
        </>
    );
};

export default MobileRMSDashboardStats;
