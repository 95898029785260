import React, { useState } from 'react';
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import { formatHelper } from "helper/format";
import { Box, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InfoIcon from '@mui/icons-material/Info';
import JarvisCard from "components/JarvisCard";
import Modal from "components/common/Modal";
import IconButton from "components/common/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const yourInvestment = {
  "Current Investment:":"Amount currently invested in open calls.",
  "Market Value (Unrealized):":"The current market value of the amount invested in open calls.",
  "Gain/Loss:":"The overall gain or loss made since the beginning of the investment, including both open and closed calls.",
  "Open Gain/Loss:":" Unrealized profit or loss on current open investments.",
  "Closed Gain/Loss:":"Realized profit or loss from closed investments.",
  "Executed Calls:":"No. of recommendations that have been executed by the customer.",
}

const jarvisOneStockPerformence = {
  "Total Calls:":"Total number of calls recommended by Jarvis.",
  "Open Calls:":"Number of calls recommended by Jarvis that have not been sold yet.",
  "Closed Calls:":"Number of calls recommended by Jarvis that have been sold.",
  "Open Calls Gain/Loss (in %):" :"Unrealized profit or loss on current open investments recommended by Jarvis.",
  "Close Calls Gain/Loss (in %):":"Realized profit or loss on current close investments recommended by Jarvis."
}

const OnestockDashboardStatsContainer = (props) => {
  const {
    onestockDashboardStats,
    onestockDashboardLoading,
    handleRealizedReturnSwitch,
    handleCallReturnSwitch,
    RealizedDownArrowBtn,
    realizedUpArrowBtn,
    realisedReturnStatus,
    callReturnDownArrowBtn,
    callReturnUpArrowBtn,
    callReturnStatus,
  } = props;

  const [isYourInvestMentPopup, setIsYourInvestMentPopup] = useState(false);
  const [isJarvisOneStockMentPopup, setIsJarvisOneStockMentPopup] = useState(false);

  const customerCards = [
    {
      // code: "Invested amount",
      code:"Current Investment",
      value: onestockDashboardStats.investedAmount || 0,
      hoverText: "Amount currently invested in open calls.",
      switch: false,
      colorChanger: false,
      sign: false,
      currency: true,
    },
    {
      // code: "Total Value",
      code:"Market Value",
      value: onestockDashboardStats.currentValue || 0,
      hoverText: "The current market value of the amount invested in open calls.",
      switch: false,
      colorChanger: false,
      sign: false,
      currency: true,
    },
    realisedReturnStatus
      ? {
        // code: "Realized returns",
        code:"Closed calls returns",
        value: onestockDashboardStats.customerRealisedReturnsValue || 0,
        // per: `${Number(onestockDashboardStats.customerRealisedReturnsPercentage || 0).toFixed(2) || 0}`,
        hoverText: " Realized profit or loss from closed investments.",
        switch: true,
        colorChanger: true,
        sign: true,
        currency: true,
      }
      : {
        // code: "Unrealized returns",
        code: "Open calls returns",
        value: onestockDashboardStats.customerUnrealisedReturnsValue || 0,
        // per: `${Number(onestockDashboardStats.customerUnrealisedReturnsPercentage || 0).toFixed(2)}` || 0,
        hoverText: "Unrealized profit or loss on current open investments.",
        switch: true,
        colorChanger: true,
        sign: true,
        currency: true,
      },
    {
      code: "Total returns",
      value: onestockDashboardStats.customerTotalReturns || 0,
      // per: `${Number(onestockDashboardStats.customerTotalReturnsPercentage || 0).toFixed(2)}`,
      hoverText: "The overall gain or loss made since the beginning of the investment, including both open and closed calls.",
      switch: false,
      colorChanger: true,
      sign: true,
      currency: true,
    },
    {
      code: "Executed calls",
      value: onestockDashboardStats.executedCalls || 0,
      per: "",
      hoverText: "No. of recommendations that have been executed by the customer.",
      switch: false,
      colorChanger: false,
      sign: false,
      currency: false,
    },
  ];

  const oneStockCards = [
    {
      code: "Total calls",
      value: onestockDashboardStats.totalCalls || 0,
      hoverText: "Total number of calls Recommended by Jarvis.",
      switch: false,
      colorChanger: false,
      sign: false,
      perSign: false,
    },
    // {
    //   code: "Total returns",
    //   value: `${Number(onestockDashboardStats.totalReturnsPercentage || 0).toFixed(2)}`,
    //   hoverText: "Total Returns of all stocks Recommended by Jarvis",
    //   switch: false,
    //   colorChanger: true,
    //   sign: true,
    //   perSign: true,
    // },
    callReturnStatus
      ?
      {
        code: "Closed calls returns",
        value: `${Number(onestockDashboardStats.realisedReturnsPercentage || 0).toFixed(2)}`,
        hoverText: "Realized profit or loss on current close investments recommended by Jarvis.",
        switch: true,
        colorChanger: true,
        sign: true,
        perSign: true,
      } :
      {
        code: "Open calls returns",
        value: `${Number(onestockDashboardStats.unrealisedReturnsPercentage || 0).toFixed(2)}`,
        hoverText: "Unrealized profit or loss on current open investments recommended by Jarvis.",
        switch: true,
        colorChanger: true,
        sign: true,
        perSign: true,
      },
    {
      code: "Open calls",
      value: onestockDashboardStats.openCalls || 0,
      per: "",
      hoverText: "Number of calls recommended by Jarvis that have not been sold yet.",
      switch: false,
      colorChanger: false,
      sign: false,
      perSign: false,
    },
    {
      code: "Closed calls",
      value: onestockDashboardStats.closedCalls || 0,
      per: "",
      hoverText: "Number of calls recommended by Jarvis that have been sold.",
      switch: false,
      colorChanger: false,
      sign: false,
      perSign: false,
    },
  ];

   
  const yourInvestmentPopUpHandler = () => {
    setIsYourInvestMentPopup(!isYourInvestMentPopup); // Toggles the modal open/close state
  };

  const jarvisOneStockPopUpHandler= () =>{
    setIsJarvisOneStockMentPopup(!isJarvisOneStockMentPopup)
  }


  return (
    <>
      {/* Your Investments */}
      <Stack>
        <Box>
          <Box sx={{display:"flex", justifyContent: "space-between", alignItems:"center", marginBottom: "10px"}} >
            <Text sx={{ color:"secondary",fontWeight: "500",fontSize: "1.125rem",}}>
              Your Investments
            </Text>
            
            <InfoIcon 
              ml={1} 
              sx={{ width: "20px", color: "#a39e9e" }} 
              onClick={yourInvestmentPopUpHandler}
            />
          </Box>
        
          <Modal 
            childStyle={{
              padding:"0px, 18px, 0px, 18px",
              maxHeight: "295px",
              maxWidth:"482px",
              overflow: "hidden"
            }}

            open={isYourInvestMentPopup} size={"xs"} disableClose={true}>
            <Stack direction="row" justifyContent="space-between" alignItems = "center" marginBottom="-5px" >
              <Text sx={{fontWeight:"500", fontSize:"18px"}}> Definitions </Text>
              <IconButton 
                sx={{ marginRight: "-11px",marginTop:"-9px;"}}
                onClick={yourInvestmentPopUpHandler}> 
                <CloseIcon fontSize="small" /> 
              </IconButton>
            </Stack>

            <Stack>
              <ul style={{paddingLeft:"28px"}} >
                {Object.entries(yourInvestment).map(([key, value])=>(
                  <li style={{lineHeight:"16px", marginBottom:"10px", fontSize:"12px", color:"#303236"}}>
                    <span style={{fontWeight:"700", paddingRight:"4px" }} > {key} </span>
                    <span>{value}</span>
                  </li>
                ))}
              </ul>
            </Stack>
          </Modal>
        </Box>
        <Grid container spacing={1}>
          {customerCards.map((card, index) => {
            return (
              <Grid item xs={2.4} key={index}>
                <JarvisCard
                  sx={{
                    flexGrow: 1,
                    gap: "4px",
                    marginBottom: "50px",
                    maxHeight: "108px",
                    paddingRight: "0px",
                    paddingLeft: card.code == "Realized returns" || 
                                 card.code =="unrealized returns" ? "5.7px" : "10px"
                  }}>

                  <Stack direction="row" alignItems="center">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Text
                        sx={{
                          color: card.colorChanger ? (card.value > 0 ? "#45AC70" : "#D44C4C") :
                            "black",
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      >
                        {
                          <>
                            {card.sign ? (card.value > 0 ? "+" : "") : ""}
                            {card.currency ? formatHelper.currency(card.value) || 0 : card.value}
                          </>
                        }
                      </Text>

                      {card.per ? (
                        <Text
                          sx={{
                            display: "flex",
                            fontSize: "14px",
                            paddingLeft: "6px",
                            color: card.colorChanger ? (card.per > 0 ? "#45AC70" : "#D44C4C") :
                              "black",
                            fontWeight: "500",
                          }}
                        >
                          {`(${card.sign ? (card.per > 0 ? "+" : "") : ""}${card.per}%)`}
                        </Text>
                      ) : null}
                    </Box>
                  </Stack>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Text sx={{ fontSize: "15.7px" }} >{card.code}</Text>
                    </Box>
                    {card.switch ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "6px",
                        }}
                      >
                        <ArrowDropUpIcon
                          style={{
                            backgroundColor: "white",
                            fill: realizedUpArrowBtn ? "black" : "#A2A7AE",
                            fontSize: "50px",
                            width: "16px",
                            height: "16px",
                            margin: "0px 0px -6px 0px",
                            padding: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleRealizedReturnSwitch(true);
                          }}
                        />

                        <ArrowDropDownIcon
                          style={{
                            backgroundColor: "white",
                            fill: RealizedDownArrowBtn ? "black" : "#A2A7AE",
                            fontSize: "50px",
                            width: "16px",
                            height: "16px",
                            marginBottom: "-2px",
                            padding: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleRealizedReturnSwitch(false);
                          }}
                        />
                      </Box>
                    ) : null}
                  </Box>

                </JarvisCard>
              </Grid>
            );
          })}
        </Grid>
      </Stack>

      {/* Jarvis One Stock Performance */}
      <Stack>
      
      <Box sx={{display:"flex", justifyContent: "space-between", alignItems:"center", marginBottom: "10px"}} >
          <Text sx={{ color:"secondary",fontWeight: "500",fontSize: "1.125rem",}}>
            Jarvis One Stock Performance
          </Text>
          <InfoIcon 
            ml={1} 
            sx={{ width: "20px", color: "#a39e9e" }} 
            onClick={jarvisOneStockPopUpHandler}
          />
        </Box>

        <Modal 
          childStyle={{
              padding:"16px, 18px, 0px, 18px",
              maxHeight: "255px",
              maxWidth:"482px",
              overflow: "hidden"
            }}

          open={isJarvisOneStockMentPopup} size={"xs"} disableClose={true}>
          <Stack direction="row" justifyContent="space-between" alignItems = "center" marginBottom="-5px" >
            <Text sx={{fontWeight:"500", fontSize:"18px"}}> Definitions </Text>
            <IconButton 
              sx={{ marginRight: "-11px",marginTop:"-9px;"}}
              onClick={jarvisOneStockPopUpHandler}> 
              <CloseIcon fontSize="small"/> 
            </IconButton>
          </Stack>  

          <Stack>
            <ul style={{paddingLeft:"28px"}} >
              {Object.entries(jarvisOneStockPerformence).map(([key, value])=>(
                <li style={{lineHeight:"16px", marginBottom:"10px", fontSize:"12px", color:"#303236"}}>
                  <span style={{fontWeight:"700", paddingRight:"4px" }} > {key} </span>
                  <span>{value}</span>
                </li>
              ))}
            </ul>
          </Stack>
        </Modal>

        <Grid container spacing={1} sx={{
          display:"grid",
          gridTemplateColumns:"repeat(4,1fr)",
        }}>
          {oneStockCards.map((card, index) => {
            return (
              <Grid item xs={2.4} key={index} sx={{
                maxWidth:"100% !important"
              }}>
                <JarvisCard
                  sx={{
                    flexGrow: 1,
                    gap: "2px",
                    marginBottom: "50px",
                    maxHeight: "108px",
                    paddingRight: "0px",
                    paddingLeft: card.code == "Open calls returns" || 
                                 card.code =="Closed calls returns" ? "5.7px" : "10px"
                  }}>
                  
                    <Stack direction="row" alignItems="center">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Text
                          sx={{
                            color: card.colorChanger ? (card.value > 0 ? "#45AC70" : "#D44C4C") :
                              "black",
                            fontSize: "24px",
                            fontWeight: "700",
                          }}
                        >
                          {card.sign ? (card.value > 0 ? "+" : "") : ""}
                          {card.value}
                          {card.perSign ? "%" : ""}
                        </Text>

                        {card.per ? (
                          <Text
                            pl={1}
                            sx={{
                              display: "flex",
                              fontSize: "16px",
                              color: card.colorChanger
                                ? card.per > 0
                                  ? "#45AC70"
                                  : "#D44C4C"
                                : "black",
                              fontWeight: "500",
                            }}
                          >
                            {`(${card.sign ? (card.per > 0 ? "+" : "") : ""}${card.per}%)`}
                          </Text>
                        ) : null}
                      </Box>
                    </Stack>
                           
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box>
                        <Text sx={{ fontSize: "15.7px" }} >{card.code}</Text>
                      </Box>
                      {card.switch ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              backgroundColor: "white",
                              fill: callReturnUpArrowBtn ? "black" : "#A2A7AE",
                              fontSize: "50px",
                              width: "16px",
                              height: "16px",
                              margin: "0px 0px -6px 0px",
                              padding: "0",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleCallReturnSwitch(true);
                            }}
                          />

                          <ArrowDropDownIcon
                            style={{
                              backgroundColor: "white",
                              fill: callReturnDownArrowBtn ? "black" : "#A2A7AE",
                              fontSize: "50px",
                              width: "16px",
                              height: "16px",
                              marginBottom: "-2px",
                              padding: "0",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleCallReturnSwitch(false);
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  
                    </JarvisCard>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </>
  );
};

export default OnestockDashboardStatsContainer;
