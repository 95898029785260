import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { jarvisOnestockTransactionAction } from "stores/jarvisOnestock/jarvisOnestockTransaction/jarvisOnestockTransactionAction";

const mapStateToProps = (state) => {
    return {
        transactionCallsList:
            state.jarvisOnestock.onestockTransaction.transactionList.list,
        transactionCallsLoading:
            state.jarvisOnestock.onestockTransaction.loading.transactionList,
        transactionCallsInitialCalled:
            state.jarvisOnestock.onestockTransaction.initialCalled.transactionList,
        transactionCallsTotal:
            state.jarvisOnestock.onestockTransaction.transactionList.total,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTransactionListAction: jarvisOnestockTransactionAction.getTransactionList,
    setToast: commonActions.setToast
}, dispatch);

const OnestockTransactionStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockTransactionStore;
