
import React from "react";

import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Loader from "components/common/Loader";

import JarvisCard from "components/JarvisCard";
import NewsArticleCard from "components/NewsArticleCard";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Box from "components/common/Box";
import Pagination from "components/common/Pagination";

class NewsAndArticlesContainer extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          page: 1,
          rowsPerPage: 25,
          refreshTable: false,
        };
      }
    componentDidMount() {
    this.getArticlesList();  
    }

    getArticlesList() {
        const { page, rowsPerPage } = this.state;
        this.props.getNewsArticlesAction({ page:page, count:rowsPerPage });
    }
    
    onPageChange(page, rowsPerPage) {
        this.setState({ page, rowsPerPage }, 
            () => this.getArticlesList()
        );
    }

    onCardClick(content){
        this.props.setNewsArticleContentAction(content);
        this.props.history.push({
            pathname: `/knowledge-centre/news-articles/${content.id}`,
        });
    }


    render() {
        const { isLoading, isInitialCalled = false, newsData } = this.props;
        // const changePage = (page,rows)=>{
        //     if(page>1 || rows > 10){
        //         this.props.getNewsArticlesAction({
        //         count:rows,
        //         page:page
        //     });
        //     }
        // }
        
        return (
            <>
                {isLoading ? 
                    <Box sx={{  position: "relative", 
                    minHeight: "calc(100vh - 320px)" }}>
                        <Loader position="absolute" loading={isLoading} />
                    </Box>
                : null}

                {(isInitialCalled && !newsData.length && !isLoading) ? (
                    <JarvisCard>
                        <Text>Sorry! We are unable to fetch data. Please try again later.</Text>
                    </JarvisCard>
                ) : null}

                <Stack sx={{height: "100%"}}>
                    <Box>
                        <Pagination
                            onPageChange={(page, rowsPerPage) => {
                                this.onPageChange(page, rowsPerPage);
                            }}
                            defaultRowsPerPage={this.state.rowsPerPage}
                            refreshTable={this.state.refreshTable}
                            count={this.props.totalArticles}
                        />
                    </Box>

                    <Stack flexGrow="1" mt={{ xs:"10px", sm: 0}}>
                        {newsData.map((n, index) => (
                            <Box key={`news_${index}`} >
                                <NewsArticleCard
                                    title={n.title}
                                    date={n.date}
                                    caption={n.caption}
                                    onCardClick={() => this.onCardClick(n)}
                                ></NewsArticleCard>
                            </Box>
                        ))}
                    </Stack>

                  
                 </Stack>

            </>
        )
    }
}

export default withRouter(NewsAndArticlesContainer);