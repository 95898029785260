import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { masterActions } from "stores/master/masterActions";

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
	pendingCallAction: masterActions.getPendingCall,
}, dispatch);

const RMSActionCenterStore = (Container) =>
	connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSActionCenterStore;
