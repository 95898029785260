import React, { useState } from 'react'
import Stack from './common/Stack'
import Text from './common/Text'
import Image from "components/common/Image"
import JarvisButton from './JarvisButton'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { makeStyles } from "@mui/styles"
import Modal from 'components/common/Modal'
import IconButton from './common/IconButton'
import CloseIcon from "@mui/icons-material/Close"
import JarvisChip from './JarvisChipNew'
import { config } from 'config/index'
import { useHistory } from "react-router-dom"
import { dateTimeHelper } from 'helper/dateTime'
import TextField from './common/TextField'
import OtpInput from "react-otp-input";

const PendingActionCalls = (props) => {

    const classes = useStyles();

    const history = useHistory();

    const [activeTab, setActiveTab] = useState(null);

    const {
        openPendingCall,
        handlePendingActions = () => { },
        pendingCallList = {},
        isEditEmailModal,
        email,
        emailError,
        handleSendLink = () => { },
        handleInput = () => { },
        handleEditEmailModal = () => { },
        handleMobileModal = () => { },
        handleMobileOtp = () => { },
        handleVerifyOtp = () => { },
        handleArchiveJourney = () => { },
        isEditMobileModal,
        mobile,
        mobileError,
        mobileOtp,
        isVerifyMobileModal,
        activeProductList,
        isLoading,
    } = props

    const {
        isMobileVerified,
        isEmailVerified,
        portfolioData = {},
        protectData = {},
        onestockData = {},
        totalPendingCount,
        portfolioPendingCount,
        protectPendingCount,
        onestockPendingCount,
        mandatoryCount,
        portfolioPendingCallType,
        protectPendingCallType,
        onestockPendingCallType,
    } = pendingCallList

    const pendingCallData = [
        {
            displayName: "All",
            productCode: null,
            noOfCount: totalPendingCount,
            isActive: activeProductList.includes(config.productCodes.PORTFOLIO, config.productCodes.RMS),
        },
        {
            displayName: "Portfolio",
            productCode: config.productCodes.PORTFOLIO,
            noOfCount: portfolioPendingCount,
            isActive: activeProductList.includes(config.productCodes.PORTFOLIO),
        },
        {
            displayName: "Protect",
            productCode: config.productCodes.RMS,
            noOfCount: protectPendingCount,
            isActive: activeProductList.includes(config.productCodes.RMS),
        },
        {
            displayName: "One Stock",
            productCode: config.productCodes.ONESTOCK,
            noOfCount: onestockPendingCount,
            isActive: activeProductList.includes(config.productCodes.ONESTOCK),
        },
    ]

    const onTabChange = (productCode) => {
        setActiveTab(productCode);
    }

    const notificationAction = ({ type, productCode }) => {
        if (type === "pending") {
            if (productCode === config.productCodes.PORTFOLIO) {
                handleArchiveJourney()
            }
            else if (productCode === config.productCodes.RMS) {
                history.push("/jarvis-rms/action-centre");
            }
            else if (productCode === config.productCodes.ONESTOCK) {
                history.push("/jarvis-onestock/action-centre");
            }
        }
        else if (type === "expiry") {
            if (productCode === config.productCodes.PORTFOLIO) {
                history.push({
                    pathname: `/customer/profile/subscription`,
                    state: {
                        productCode: config.productCodes.PORTFOLIO
                    }
                });
            }
            else if (productCode === config.productCodes.RMS) {
                history.push({
                    pathname: `/customer/profile/subscription`,
                    state: {
                        productCode: config.productCodes.RMS
                    }
                });
            }
            else if (productCode === config.productCodes.ONESTOCK) {
                history.push({
                    pathname: `/customer/profile/subscription`,
                    state: {
                        productCode: config.productCodes.ONESTOCK
                    }
                });
            }
        }
        handlePendingActions("close")
    }

    const mandatoryNotificationAction = ({ type }) => {
        if (type === "email") {
            handleEditEmailModal(true, 'EDIT')
        }
        if (type === "mobile") {
            handleMobileModal('open')
        }
        handlePendingActions("close")
    }

    const notifications = ({ portfolio, protect, onestock }) => {
        let list = [{
            productDispayName: "Portfolio",
            productCode: config.productCodes.PORTFOLIO,
            notificationType: "pending",
            hasAlert: portfolio.isPendingCall,
            notificationMsg: `You have ${portfolioPendingCallType} call pending`,
            callExpiry: portfolio.callExpiry,
            isCallExpired: portfolio.isCallExpired,
            btnText: "Act now",
            action: notificationAction,
        },
        {
            productDispayName: "Protect",
            productCode: config.productCodes.RMS,
            notificationType: "pending",
            hasAlert: protect.isPendingCall,
            notificationMsg: `You have ${protectPendingCallType} call pending`,
            callExpiry: protect.callExpiry,
            isCallExpired: protect.isCallExpired,
            btnText: "Act now",
            action: notificationAction,
        },
        {
            productDispayName: "One Stock",
            productCode: config.productCodes.ONESTOCK,
            notificationType: "pending",
            hasAlert: onestock.isPendingCall,
            notificationMsg: `You have ${onestockPendingCallType} call pending`,
            callExpiry: onestock.callExpiry,
            isCallExpired: onestock.isCallExpired,
            btnText: "Act now",
            action: notificationAction,
        },
        {
            productDispayName: "Portfolio",
            productCode: config.productCodes.PORTFOLIO,
            notificationType: "expiry",
            hasAlert: portfolio.isPlanExpired,
            notificationMsg: `Jarvis portfolio plan expired`,
            callExpiry: portfolio.callExpiry,
            btnText: "Act now",
            action: notificationAction,
        },
        {
            productDispayName: "Protect",
            productCode: config.productCodes.RMS,
            notificationType: "expiry",
            hasAlert: protect.isPlanExpired,
            notificationMsg: `Jarvis protect plan expired`,
            callExpiry: protect.callExpiry,
            btnText: "Act now",
            action: notificationAction,
        },
        {
            productDispayName: "One Stock",
            productCode: config.productCodes.ONESTOCK,
            notificationType: "expiry",
            hasAlert: onestock.isPlanExpired,
            notificationMsg: `Jarvis one stock plan expired`,
            callExpiry: onestock.callExpiry,
            btnText: "Act now",
            action: notificationAction,
        }];
        if (activeTab) {
            return list.filter(data => data.productCode === activeTab);
        }
        return list;
    };

    const mandatoryNotifications = [
        {
            notificationType: "mobile",
            hasAlert: !isMobileVerified,
            notificationMsg: "Mobile verification pending",
            action: mandatoryNotificationAction,
        },
        {
            notificationType: "email",
            hasAlert: !isEmailVerified,
            notificationMsg: "Email verification pending",
            action: mandatoryNotificationAction,
        },
    ]

    const remainingCounts = portfolioPendingCount + protectPendingCount + onestockPendingCount

    const mandatoryCounts = mandatoryCount

    return (
        <>
            {
                isLoading ?
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        p={1.5}
                        mt={1}
                        className={classes.infoText}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Text mr={1}>
                                <Image src="/images/onestock/loader-icon.svg" width={25} />
                            </Text>
                            <Text variant="small">Fetching pending actions</Text>
                        </Stack>
                    </Stack>
                    :
                    null
            }
            {
                totalPendingCount > 0 ?
                    <Stack
                        direction={{ xs: "column", sm: "row", md: "row" }}
                        justifyContent="space-between"
                        p={1.5}
                        mt={1}
                        className={classes.infoText3}
                    >
                        <Stack direction="row" mb={{ xs: 2, sm: "unset", md: "unset" }} >
                            <Text mt={-1.5} mr={1}>
                                <Image src="/images/onestock/timer-icon.svg" width={25} />
                            </Text>
                            <Stack>
                                <Text variant="small">You have pending actions</Text>
                                <Text variant="caption1" sx={{ color: "#676E76" }}>
                                    {
                                        (!isMobileVerified || !isEmailVerified) ?
                                            `${mandatoryCounts} Mandatory Action & ` : null
                                    }
                                    {remainingCounts} Actions are pending
                                </Text>
                            </Stack>
                        </Stack>
                        <JarvisButton
                            size="small"
                            variant="callsContained"
                            onClick={() => handlePendingActions("open")}
                        >
                            Act Now
                        </JarvisButton>
                    </Stack>
                    : null
            }
            {
                totalPendingCount === 0 ?
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        p={1.5}
                        mt={1}
                        className={classes.infoText}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Text mr={1}>
                                <Image src="/images/onestock/tick-icon.svg" width={17} />
                            </Text>
                            <Text variant="small">You have no pending actions</Text>
                        </Stack>
                    </Stack>
                    :
                    null
            }

            {/* Pending Call */}
            <Modal
                open={openPendingCall}
                size={"sm"}
                disableClose={true}
            >
                <Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            sx={{ overflow: "auto", width: "100%", paddingBottom: "5px", minHeight: "50px" }}
                        >
                            {pendingCallData?.map((data, index) => (
                                <>
                                    {
                                        data.isActive ?
                                            < Stack sx={{ cursor: "pointer" }}>
                                                <JarvisChip
                                                    key={`callId${index}`}
                                                    label={`${data.displayName} (${data.noOfCount})`}
                                                    variant="outlined"
                                                    color={data.productCode === activeTab ? "primary" : "marketCap"}
                                                    sx={{ fontSize: { xs: "11px", md: "14px" } }}
                                                    onClick={() => onTabChange(data.productCode)}
                                                />
                                            </Stack>
                                            : null
                                    }
                                </>
                            ))}
                        </Stack>
                        <Stack alignItems="center" justifyContent="center">
                            <IconButton
                                className={classes.close}
                                onClick={() => handlePendingActions("close")}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Stack>
                        {(!isMobileVerified || !isEmailVerified) ?
                            <Stack>
                                <Text
                                    variant="small"
                                    sx={{
                                        color: "#2B70C5",
                                        fontWeight: 500,
                                    }}
                                >
                                    Mandatory
                                </Text>
                                {
                                    mandatoryNotifications.map(data => (
                                        <>
                                            {data.hasAlert ?
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    sx={{
                                                        borderBottom: "1px solid #EBEBEB"
                                                    }}
                                                    pb={1}
                                                    mt={1}
                                                >
                                                    <Text
                                                        sx={{
                                                            color: "#303236",
                                                            fontWeight: 500,
                                                            fontSize: { xs: "13px", md: "17px" },
                                                        }}
                                                    >
                                                        {data.notificationMsg}
                                                    </Text>
                                                    <JarvisButton
                                                        size="small"
                                                        variant="callsContained2"
                                                        onClick={() => data.action({ type: data.notificationType })}
                                                        sx={{ fontSize: { xs: "11px", md: "13px" } }}
                                                    >
                                                        Verify
                                                    </JarvisButton>
                                                </Stack>
                                                : null}
                                        </>
                                    ))
                                }
                            </Stack>
                            : null}
                        <Stack>
                            <Text
                                variant="small"
                                sx={{
                                    color: "#2B70C5",
                                }}
                                mt={2}
                            >
                                <span style={{ fontWeight: 500 }}> Other notifications </span>
                                {
                                    !activeTab && ((!portfolioData.isPendingCall && !portfolioData.isPlanExpired) &&
                                        (!protectData.isPendingCall && !protectData.isPlanExpired) &&
                                        (!onestockData.isPendingCall && !onestockData.isPlanExpired))
                                        ?
                                        <Text variant="caption1" sx={{ color: "#A2A7AE", }} mt={1}>
                                            No pending actions
                                        </Text>
                                        : null
                                }
                            </Text>

                            {notifications({
                                portfolio: portfolioData, protect: protectData, onestock: onestockData
                            }).map(data => (
                                <>
                                    {data.hasAlert ?
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            sx={{
                                                borderBottom: "1px solid #EBEBEB"
                                            }}
                                            pb={1}
                                            mt={1}
                                        >
                                            <Stack>
                                                <Text
                                                    sx={{
                                                        color: "#303236",
                                                        fontWeight: 500,
                                                        fontSize: { xs: "13px", md: "17px" },
                                                    }}
                                                >
                                                    {data.notificationMsg}
                                                </Text>
                                                <Stack direction="row">
                                                    <Text mr={1} sx={{ color: "#676E76", fontSize: { xs: "10px", md: "12px" } }}>
                                                        Jarvis <span style={{ fontWeight: 500 }}>{data.productDispayName}</span>
                                                    </Text>
                                                    {
                                                        data.notificationType === "pending" ?
                                                            <>
                                                                {
                                                                    data.isCallExpired ?
                                                                        <Text sx={{ color: "#D44C4C", fontSize: { xs: "10px", md: "12px" } }}>
                                                                            Expired on {dateTimeHelper.displayDate(data.callExpiry)}
                                                                        </Text>
                                                                        :
                                                                        <Text sx={{ color: "#D44C4C", fontSize: { xs: "10px", md: "12px" } }}>
                                                                            Expiring on {dateTimeHelper.displayDate(data.callExpiry)}
                                                                        </Text>
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                </Stack>
                                            </Stack>
                                            <JarvisButton
                                                size="small"
                                                variant="callsContained2"
                                                onClick={() => data.action({
                                                    type: data.notificationType,
                                                    productCode: data.productCode
                                                })}
                                                sx={{ fontSize: { xs: "10px", md: "13px" }, whiteSpace: "nowrap" }}
                                            >
                                                {data.btnText}
                                            </JarvisButton>
                                        </Stack>
                                        : null
                                    }
                                </>
                            ))}

                            {
                                activeTab === "PORTFOLIO" && (!portfolioData.isPendingCall && !portfolioData.isPlanExpired) ||
                                    activeTab === "RMS" && (!protectData.isPendingCall && !protectData.isPlanExpired) ||
                                    activeTab === "ONESTOCK" && (!onestockData.isPendingCall && !onestockData.isPlanExpired)
                                    ?
                                    <Text variant="caption1" sx={{ color: "#A2A7AE", }} mt={1}>
                                        No pending actions
                                    </Text>
                                    : null
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>

            {/* Email Verification */}
            <Modal
                open={isEditEmailModal}
                size={'xs'}
                disableClose={true}
            >
                <Text sx={{ fontWeight: 500 }} mb={2}>Edit Email Address</Text>
                <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => handleInput({
                        name: "email",
                        value: e.target.value,
                    })}
                    helperText={emailError}
                    error={emailError}
                    sx={{ width: 1, mb: { xs: 4, md: 2 } }}
                />
                <Stack direction={'row'} justifyContent="flex-end">
                    <JarvisButton
                        variant="outlined"
                        disableEndIcon={true}
                        className={classes.cancelBtn}
                        sx={{ marginRight: '15px' }}
                        onClick={() => handleEditEmailModal(false, 'CANCEL')}>Cancel</JarvisButton>
                    <JarvisButton
                        disableEndIcon={true}
                        onClick={() => handleSendLink()}
                        sx={{ fontSize: "1rem", p: '10px 35px' }}
                    >
                        Send link
                    </JarvisButton>
                </Stack>
            </Modal>

            {/* Mobile Verification */}
            <Modal
                open={isEditMobileModal}
                size={'xs'}
                disableClose={true}
            >
                <Text sx={{ fontWeight: 500 }} mb={2}>Edit Mobile Number</Text>
                <TextField
                    label="Mobile"
                    type="number"
                    value={mobile}
                    onChange={(e) => handleInput({
                        name: "mobile",
                        value: e.target.value,
                    })}
                    helperText={mobileError}
                    error={mobileError}
                    sx={{ width: 1, mb: { xs: 4, md: 2 } }}
                />
                <Stack direction={'row'} justifyContent="flex-end">
                    <JarvisButton
                        variant="outlined"
                        disableEndIcon={true}
                        className={classes.cancelBtn}
                        sx={{ marginRight: '15px' }}
                        onClick={() => handleMobileModal('close')}>Cancel</JarvisButton>
                    <JarvisButton
                        disableEndIcon={true}
                        onClick={() => handleMobileOtp()}
                        sx={{ fontSize: "1rem", p: '10px 35px' }}
                    >
                        Send otp
                    </JarvisButton>
                </Stack>
            </Modal>

            {/* Verify OTP */}
            <Modal
                open={isVerifyMobileModal}
                size={'xs'}
                disableClose={true}
            >
                <Text sx={{ fontWeight: 500 }} mb={2}>Verify Mobile Number</Text>
                <OtpInput
                    mt={3}
                    style={{ height: "56px", width: "100%" }}
                    inputStyle={{
                        height: "56px",
                        width: "57px",
                        border: "1px solid #E0E7FF",
                        flex: "1",
                    }}
                    value={mobileOtp}
                    numInputs={6}
                    isInputNum={true}
                    onChange={(e) => handleInput({
                        name: "mobileOtp",
                        value: e,
                    })}
                    separator={<span>&nbsp;</span>}
                />
                <Stack mt={2} direction={'row'} justifyContent="flex-end">
                    <JarvisButton
                        variant="outlined"
                        disableEndIcon={true}
                        className={classes.cancelBtn}
                        sx={{ marginRight: '15px' }}
                        onClick={() => handleMobileModal('otp-close')}>Cancel</JarvisButton>
                    <JarvisButton
                        disableEndIcon={true}
                        onClick={() => handleVerifyOtp()}
                        sx={{ fontSize: "1rem", p: '10px 35px' }}
                    >
                        Verify otp
                    </JarvisButton>
                </Stack>
            </Modal>
        </>
    )
}

const useStyles = makeStyles(() => ({
    infoText3: {
        background: "#fff",
        borderRadius: "8px",
        border: "1px solid #E0953D",
    },
    infoText: {
        background: "#fff",
        borderRadius: "8px",
        border: "1px solid #c0d4ee",
    },
    close: {
        marginTop: "-7px",
    },
    cancelBtn: {
        fontSize: "1rem",
        color: '#212225',
        p: '10px 35px',
        fontWeight: '700',
        border: '1px solid #212225',
        boxShadow: "none",
        "&:hover": {
            color: "#38a1ff",
        }
    }
}));

export default withRouter(PendingActionCalls)