import { formatHelper } from "helper/format";
import _get from "lodash.get";

const jarvisOnestockOnboardingParser = {};

jarvisOnestockOnboardingParser.brokerList = (res) => {
    if (res && res.data) {
        res = res.data;
    }
    if (!res) {
        return [];
    }

    res.list = res.list.map((p) => ({
        brokerId: _get(p, "brokerId", null),
        brokerName: _get(p, "brokerName", null),
        brokerDisplayName: _get(p, "brokerDisplayName", null),
        brokerPlatform: _get(p, "brokerPlatform", null),
        brokerIcon: formatHelper.getLegacyImageURI(_get(p, "brokerIcon", null)),
        brokerCode: _get(p, "brokerCode", null),
    }));

    res.records = _get(res, "records", 0);
    return res;
};


jarvisOnestockOnboardingParser.onboardingData = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        currentStage: _get(res, "currentOnboardingStage", null),
        isOnboardingCompleted: _get(res, "isOnboardingCompleted", null),
        riskProfileId: _get(res, "riskProfileId", null),
        riskProfileName: _get(res, "riskProfileName", null),
        panNumber: _get(res, "panNumber", null),
        dateOfBirth: _get(res, "dateOfBirth", null),
        aadharNumber: _get(res, "aadharNumber", null),
        isManualKYC: _get(res, "isManualKYC", null),
        isPanUploaded: _get(res, "isPanUploaded", null),
        isAadhaarFrontUploaded: _get(res, "isAadhaarFrontUploaded", null),
        isAadhaarBackUploaded: _get(res, "isAadhaarBackUploaded", null),
        duplicateStages: _get(res, "duplicateStages", null),
        portfolioId: _get(res, "tempCustomerPortfolioId", null),
    };
};

jarvisOnestockOnboardingParser.submitRiskAssessmentQuestion = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        riskProfileId: _get(res, "riskProfileId", null),
        riskProfileName: _get(res, "riskProfileName", null),
    };
};



export { jarvisOnestockOnboardingParser };
