import { connect } from "react-redux"
import { bindActionCreators } from "redux"

const mapStateToProps = (state) => {
    return {
        isOnboardingCompleted: state.onboarding.isOnboardingCompleted,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    
}, dispatch)

const DashboardStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default DashboardStore;