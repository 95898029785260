import {axios} from "helper/axios";
import { jarvisRMSActionCenterConstants } from "./jarvisRMSActionCenterConstants";

const jarvisRMSActionCenterApis = {};

jarvisRMSActionCenterApis.submitRMSActionCenterData = (payload, rmsCallId) => {
    return axios.post(`${jarvisRMSActionCenterConstants.GET_RMS_ACTION_CENTER_DATA_APIS}/${rmsCallId}`,payload)
};

jarvisRMSActionCenterApis.getRMSActionCenterData = () => {
    // return [
    //     {
    //         rmsCallId: 0,
    //         rmsCallExpiryDate: "",
    //         rmsCallStatus: "",
    //         portfolioId: 1,
    //         portfolioName: "Zerodha",
    //         brokerPlatform: "",
    //         brokerName: "Zerodha",

    //         stockData: [
    //             {
    //                 stockId: 1,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Faisal Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 2,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Faisal Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 3,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Amir Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 4,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Amir Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 5,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Salman Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 6,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Salman Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 7,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Munaf Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 8,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Munaf Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 9,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Rehan Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 10,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Rehan Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 11,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Tausif Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //             {
    //                 stockId: 12,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Tausif Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //         ],
    //     },
    //     {
    //         rmsCallId: 0,
    //         rmsCallExpiryDate: "",
    //         rmsCallStatus: "",
    //         portfolioId: 2,
    //         portfolioName: "A&G Stock Broking & C",
    //         brokerPlatform: "",
    //         brokerName: "A&G Stock Broking & C",
    //         stockData: [
    //             {
    //                 stockId: 0,
    //                 stockTransactionId: 0,
    //                 suggestedSellPrice: 0,
    //                 suggestedSellValue: 0,
    //                 stockName: "Indian Oil Corporation Ltd",
    //                 stockSymbol: "",
    //                 stockQuantity: 90,
    //                 stockPrice: "304.20",
    //                 stockValue: 0,
    //                 stockMarketCap: "Large Cap",
    //                 stockSector: "Consumer Staples",
    //             },
    //         ],
    //     },
    // ];

    return axios.get(jarvisRMSActionCenterConstants.GET_RMS_ACTION_CENTER_DATA_APIS);
};

export { jarvisRMSActionCenterApis };
