import React from "react";

import Box from "components/common/Box";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import JarvisCard from "components/JarvisCard";

import { dataHelper } from "helper/data";
import { dateTimeHelper } from "helper/dateTime";

import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmTakeActionDialog from "components/Profile/ConfirmTakeActionDialog";
import { config } from "config/";

class NewsArticleContentContainer extends React.Component {

    componentDidMount() {
        //Redirect to previous page when no data found.

        if (this.props.selectedNewsContent.title == undefined) {
            this.props.history.goBack();
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { title, description, date, caption } = this.props.selectedNewsContent;


        return (
            <>
                {!config.isMobileApp ? 
                    <Stack sx={{
                        margin: "15px 0 0 0px",
                        minHeight: "50px",
                        justifyContent: "center"
                    }}>

                        <img
                            src="/images/mobileApp/back-arrow.svg"
                            alt="back"
                            height={"30px"}
                            width={"30px"}
                            style={{ cursor: "pointer" }}
                            onClick={this.goBack} />
                    </Stack>
                :null}

                {this.props.selectedNewsContent.title == undefined ? (
                    <JarvisCard>
                        <Stack sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100%",
                        }}>
                            No Data Found.
                        </Stack>
                    </JarvisCard>
                ) : (<>

                    <Stack flexDirection='column' mt={"20px"}>
                        <Text
                            fontSize="24px"
                            fontWeight="bold"
                        >{title} </Text>
                        <Text
                            variant="body2"
                            fontSize="12px"
                            component="div"
                            color={{ xs: "#707070", md: "#929292" }}
                            mt="5px">
                            {dateTimeHelper.format(date)}
                        </Text>
                        <JarvisCard sx={{mt:4, p:{xs: 0, sm: "20px 24px"}}}>
                            <Text
                                variant="body2"
                                fontSize="18px"
                                component="div"
                                color={{ xs: "#707070", md: "#929292" }}
                                mt={3}>
                                {dataHelper.removeHTML(description)}
                            </Text>
                        </JarvisCard>
                    </Stack>
                </>)}
            </>
        );
    }
}

export default withRouter(NewsArticleContentContainer);