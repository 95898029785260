import React from "react";

import Grid from "components/common/Grid";
import AuthAsidePanel from "components/Auth/AuthAsidePanel";
import ForgotPasswordForm from "components/Auth/ForgotPasswordForm";
import { customerApis } from "stores/customer/customerApis";
import { withRouter } from "react-router-dom";

import { validationHelper } from "helper/validation";

// appsFlyer
import { tracker } from "library/tracker"

class ForgotPasswordContainer extends React.Component {
    resendInterval = 0;
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                email: "",
                otp: "",
                password: "",
                confirmPassword: "",
            },
            formError: {
                email: "",
                otp: "",
                password: "",
                confirmPassword: "",
            },
            status: {
                isOtpSend: false,
                isOtpVerified: false,
            },
            token: "",
            isLoading: false,
            resendTime: 0,
        };
    }

    handleChange = ({ name, value }) => {
        const { formData } = this.state;
        formData[name] = value;
        this.setState({ formData });
    };

    handleRequestOtp = async () => {
        // NOTE: event_tracker => forgetpassword 
        tracker.forgotPassowrd.forgetPasswordRequestOtp();
        const { formData, formError, status } = this.state;

        const emailValidation = validationHelper.email(formData.email);
        formError.email = emailValidation.message;
        this.setState({ formError });

        if (!emailValidation.isValid) {
            return;
        }
        this.setState({ isLoading: true });

        try {
            const res = await customerApis.forgotPassword({
                userName: formData.email,
            });
            if (res) {
                status.isOtpSend = true;
                this.setState({ status }, this.ResendTimer());
            }
        } catch (e) {
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleOtpVerification = async () => {
         // NOTE: event_tracker => forgetpassword 
         tracker.forgotPassowrd.forgetPasswordVerifyotp();
        const { status, formData, formError } = this.state;

        const otpValidation = validationHelper.otp(formData.otp);
        formError.otp = otpValidation.message;
        this.setState({ formError });

        if (!otpValidation.isValid) {
            return;
        }
        this.setState({ isLoading: true });

        try {
            const response = await customerApis.forgotPasswordVerifyOtp({
                otp: formData.otp,
                userName: formData.email,
            });
            // NOTE: event_tracker => forgetpassword 
            tracker.forgotPassowrd.forgetPasswordVerifyotpSuccess();
            status.isOtpVerified = true;
            this.setState({ status, token: response.token });
        } catch (e) {
            // NOTE: event_tracker => forgetpassword 
            tracker.forgotPassowrd.forgetPasswordVerifyotpFailed({error_msg : e.message});

        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleChangePasswordValidation = () => {
        const { formError, formData } = this.state;

        const passwordValidation = validationHelper.password(formData.password);
        formError.password = passwordValidation.message;

        const confirmPasswordValidation = validationHelper.confirmPassword(
            formData.confirmPassword,
            formData.password
        );
        formError.confirmPassword = confirmPasswordValidation.message;

        this.setState({ formError });
        return passwordValidation.isValid && confirmPasswordValidation.isValid;
    };

    handleChangePassword = async () => {
         // NOTE: event_tracker => forgetpassword 
         tracker.forgotPassowrd.forgetPasswordChangePassword();

        if (!this.handleChangePasswordValidation()) {
            return;
        }

        const { formData, token } = this.state;
        this.setState({ isLoading: true });

        try {
            await customerApis.resetPassword({
                password: formData.password,
                token,
            });
            // NOTE: event_tracker => forgetpassword 
            tracker.forgotPassowrd.forgetPasswordChangePasswordSuccess();
            this.setState({ isLoading: false }, () => {
                this.props.history.push("/login");
            });
        } catch (error) {
            // NOTE: event_tracker => forgetpassword 
            tracker.forgotPassowrd.forgetPasswordChangePasswordError({error_msg : error.message});
        } finally {
            this.setState({ isLoading: false });
        }
    };

    ResendTimer = () => {
        const { resendTime } = this.state;
        if (resendTime > 0) {
            return;
        }

        this.setState({ resendTime: 30 });

        this.resendInterval = setInterval(() => {
            const { resendTime } = this.state;
            let time = resendTime - 1;
            this.setState({ resendTime: time });
            time === 0 && clearInterval(this.resendInterval);
        }, 1000);
    };

    render() {
        return (
            <Grid
                container
                sx={{
                    minHeight: "100vh",
                }}>
                <Grid
                    item
                    md={6.5}
                    lg={6.5}
                    sx={{ display: { xs: "none", md: "block" } }}>
                    <AuthAsidePanel />
                </Grid>
                <Grid sx={{ flex: 1 }} item md={5.5} lg={5.5}>
                    <ForgotPasswordForm
                        status={this.state.status}
                        formData={this.state.formData}
                        formError={this.state.formError}
                        handleChange={this.handleChange}
                        handleRequestOtp={this.handleRequestOtp}
                        handleOtpVerification={this.handleOtpVerification}
                        handleChangePassword={this.handleChangePassword}
                        isLoading={this.state.isLoading}
                        resendTime={this.state.resendTime}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default withRouter(ForgotPasswordContainer);
