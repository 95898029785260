import { axios } from "helper/axios";
import { jarvisOnestockActionCenterConstants } from "./jarvisOnestockActionCenterConstants";

const jarvisOnestockActionCenterApis = {};

jarvisOnestockActionCenterApis.getOnestockActionCenterData = () => {
  return axios.get(
    jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_APIS
  );
};

export { jarvisOnestockActionCenterApis };
