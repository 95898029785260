import React from "react";

import CKYCContainer from "containers/Onboarding/CKYC";

const PortfolioCKYC = (props) => {
    return (
        <>
            <CKYCContainer />
        </>
    )
};

export default PortfolioCKYC;