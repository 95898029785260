import { call, put, takeLatest } from "@redux-saga/core/effects";
import { jarvisRMSDashboardOnboardedApis } from "./jarvisRMSDashboardonboardedApis";
import { jarvisRMSDashboardOnboardedConstants } from "./jarvisRMSDashboardonboardedConstants";
import { jarvisRMSDashboardOnboardedParsers } from "./jarvisRMSDashboardonboardedParsers";

function* getPortfolioStatsSaga(type) {
    try {
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_REQUEST,
        });
        const res = yield call(
            jarvisRMSDashboardOnboardedApis.getPortfolioStatsApi
        );
        const parsedResponse =
            jarvisRMSDashboardOnboardedParsers.getPortfolioStatsParser(res);
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_RESPONSE,
            payload: parsedResponse,
        });
    } catch (err) {
        console.log(err);
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_RESPONSE,
            payload: {},
        });
    }
}

function* getRmsCallStatsSaga(type) {
    try {
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_REQUEST,
        });
        const res = yield call(
            jarvisRMSDashboardOnboardedApis.getRmsCallStatsApi
        );
        const parsedResponse =
            jarvisRMSDashboardOnboardedParsers.getRmsCallStatsParser(res);
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_RESPONSE,
            payload: parsedResponse,
        });
    } catch (err) {
        console.log(err);
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_RESPONSE,
            payload: {},
        });
    }
}

function* getMarketCapSaga() {
    try {
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_REQUEST,
        });
        const res = yield call(jarvisRMSDashboardOnboardedApis.getMarketCapApi);

        const parsedResponse =
            jarvisRMSDashboardOnboardedParsers.getMarketCapParser(res);

        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_RESPONSE,
            payload: parsedResponse,
        });
    } catch (err) {
        console.log("error : ", err);
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_RESPONSE,
            payload: {},
        });
    }
}

function* getSectorDiversificationSaga() {
    try {
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_REQUEST,
        });
        const res = yield call(
            jarvisRMSDashboardOnboardedApis.getSectorDiversificationApi
        );
        const parsedResponse =
            jarvisRMSDashboardOnboardedParsers.getSectorDiversificationParser(
                res
            );
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_RESPONSE,
            payload: parsedResponse,
        });


    } catch (err) {
        console.log("error : ", err);
        yield put({
            type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_RESPONSE,
            payload: {},
        });
    }
}

export function* registerjarvisRMSDashboardonboardedSaga() {
    yield takeLatest(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_SAGA,
        getPortfolioStatsSaga
    );
    yield takeLatest(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_SAGA,
        getRmsCallStatsSaga
    );
    yield takeLatest(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_SAGA,
        getMarketCapSaga
    );
    yield takeLatest(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_SAGA,
        getSectorDiversificationSaga
    );
}
