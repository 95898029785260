import React from "react";
import TransactionContainer from "containers/Transaction";
import { tracker } from "library/tracker";

const PortfolioTransaction = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);

    return (
        <TransactionContainer />
    )
}

export default PortfolioTransaction;