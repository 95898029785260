import React from "react";

import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Text from "components/common/Text";

const InvestMoreInfo = (props) => {
  const { minimumAmount = "" } = props;
  const info = [
    {
      iconSrc: "/images/investMore/icon_market.png",
      heading: "Take advantage of market volatility",
    },
    {
      iconSrc: "/images/investMore/icon_funds.png",
      heading: "Add funds* at your convenience",
      subheading: minimumAmount ? `(Min of INR  ${minimumAmount})` : "" ,
    },
    {
      iconSrc: "/images/investMore/icon_pay.png",
      heading: "Pay fees on a pro-rata basis.",
    },
    {
      iconSrc: "/images/investMore/icon_rebalance.png",
      heading:
        "Rebalance your portfolio, if recommended, to capitalize on the performing sectors.",
    },
  ];
  return (
    <Box
      sx={{
        border: "1px solid #E8EBEE",
        borderRadius: 2,
        px: { xs: 2, md: 4 },
        py: { xs: 2, md: 3 },
      }}
    >
      <Text
        sx={{
          color: "#222B3E",
          fontWeight: "bold",
          typography: { xs: "body2", md: "subtitle1" },
        }}
      >
        Invest More
      </Text>

      {info.map((item, index) => [
        <Stack
          key={`investMore_${index}`}
          direction="row"
          p="22px 0px"
          borderBottom={index < info.length - 1 ? "1px solid #E6E6E6" : "none"}
        >
          <Box sx={{ flex: "0 0 70px" }}>
            <img
              src={item.iconSrc}
              alt="icon"
              style={{ borderRadius: "10px", width: "45px" }}
            />
          </Box>
          <Box sx={{ alignSelf: "center" }}>
            <Text variant="body2" sx={{ fontWeight: "600", lineHeight: "1.2" }}>
              {item.heading}
            </Text>
            {item.subheading && (
              <Text variant="small" sx={{ color: "#808591" }}>
                {item.subheading}
              </Text>
            )}
          </Box>
        </Stack>,
      ])}
    </Box>
  );
};

export default InvestMoreInfo;
