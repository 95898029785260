import _get from "lodash.get";
import { formatHelper } from "helper/format";

const investMoreParsers = {};

investMoreParsers.investmentStocks = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res) {
        return {};
    }

    const parseTransactions = (t) =>
        t.map((e) => ({
            symbol: _get(e, "symbol", ""),
            stockName: _get(e, "stockName", ""),
            sector: _get(e, "sector", ""),
            cap: _get(e, "cap", ""),
            riskStatus: _get(e, "stockRisk", ""),
            quantity: _get(e, "suggestedQuantity", ""),
            pendingActionableQty: _get(e, "pendingActionableQty", ""),
            buyPrice: formatHelper.parseDecimal(_get(e, "buyPrice", 0)) || 0,
            totalBuyPrice: formatHelper.parseDecimal(_get(e, "totalBuyPrice", 0)) || 0,
            currentPrice: formatHelper.parseDecimal(_get(e, "currentPrice", 0)) || 0,
            totalCurrentPrice: formatHelper.parseDecimal(_get(e, "totalCurrentPrice", 0)) || 0,
            allocationPercentage: formatHelper.parseDecimal(_get(e, "allocationPercentage", 0)) || 0,
            profitPrice: formatHelper.parseDecimal(_get(e, "profitPrice", 0)) || 0,
            profitPercentage: formatHelper.parseDecimal(_get(e, "profitPercentage", 0)) || 0,
            profitType: _get(e, "profitType", null),

        }));

    const data = {
        investMoreCase: _get(res, "investMoreCase", null),
        investMoreMsg: _get(res, "investMoreMsg", null),
        nextInvestmentDate: _get(res, "nextInvestmentDate", null),
        isBuyCallEnabled: _get(res, "isBuyCallEnabled", null),
        sell: parseTransactions(_get(res, "sell", [])),
        buy: parseTransactions(_get(res, "buy", [])),
    };
    return data;
};

investMoreParsers.investmentPricingDetails = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        investmentAmount: _get(res, "investmentAmount", null),
        feesPercentage: _get(res, "annualFeesPercentage", null),
        feesGSTPercentage: _get(res, "gstPercentage", null),
        feesPercentageHalfYearly: _get(res, "halfYearlyFeesPercentage", null),
        feesTenure: _get(res, "feesTenure", null),
        fees: _get(res, "feesAmount", null),
        feesGST: _get(res, "gstAmount", null),
        feesTotal: _get(res, "totalAmount", null),
    };
};

investMoreParsers.submitInvestMoreResponse = (res) => {
    if (!(res && res.statusCode)) {
        return {};
    }

    return {
        message: _get(res, "response", null),
        statusCode: _get(res, "statusCode", null),
    };
};

export { investMoreParsers };
