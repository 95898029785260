import React from "react";

import Stack from "components/common/Stack";
import Text from "components/common/Text";

import InfoIcon from '@mui/icons-material/Info';

const JarvisNote = ({ color = "primary", heading = 'Please Note:', description = "" }) => {
    const colors = {
        primary: {
            iconColor: "#B0BABF",
            backgroundColor: "#EEF4FA",
            headingColor: "#252C32",
            descriptionColor: "#303236",
        },
        default: {
            iconColor: "#B0BABF",
            backgroundColor: "#FFF",
            headingColor: "#2B70C5",
            descriptionColor: "#252C32",
        }
    }
    return (
        <Stack flex={0} direction="row" alignItems="center" sx={{ flexWrap: "wrap", maxWidth: "fit-content", mb: 2, px: 1.75, py: 1, borderRadius: "3px", backgroundColor: colors[color]['backgroundColor'] }}>
            <InfoIcon sx={{ mr: 1, fontSize: "20px", color: colors[color]['iconColor'] }} />
            <Text variant="small" sx={{ mr: 1, fontWeight: "medium", color: colors[color]['headingColor'] }}>{heading}</Text>
            <Text variant="small" sx={{ color: colors[color]['descriptionColor'] }}>{description}</Text>
        </Stack>
    )
}

export default React.memo(JarvisNote);