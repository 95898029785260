import React, { useState, useEffect } from "react";

//mui
import { makeStyles } from "@mui/styles";

//common components
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Modal from "components/common/Modal";
import CloseIcon from "@mui/icons-material/Close"
import IconButton from 'components/common/IconButton'
import Button from "components/common/Button";
import Grid from "components/common/Grid";
import TextField from "components/common/TextField";
import JarvisButton from "components/JarvisButton";
import InputAdornment from 'components/common/InputAdornment'
import Loader from "components/common/Loader";
import { config } from "config";
import ChooseBrokerIcon from "components/Onboarding/ChooseBrokerIcon";
import InfoIcon from '@mui/icons-material/Info';

//helpers
import { formatHelper } from "helper/format";
import { validationHelper } from "helper/validation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { jarvisOnestockDashboardApis } from "stores/jarvisOnestock/jarvisOnestockDashboard/jarvisOnestockDashboardApis";
import { withRouter } from "react-router-dom";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";

const JarvisOnestockStockCard = (props) => {
  const {
    stockDetails,
    sellCall,
    actionType,
    data,
    sx = {},
    brokerList = [],
    isBrokerListLoading = 0,
    isBrokerListInitialCalled = 0,
    pendingAction
  } = props;

  const history = useHistory()

  const [knowMore, setKnowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buyStock, setBuyStock] = useState(false);
  const [sellMainModalOpen, setSellMainModalOpen] = useState(false);
  const [sellStock, setSellStock] = useState(false);
  const [updateStock, setUpdateStock] = useState(false);
  const [brokerQuantityModal, setBrokerQuantityModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [showHoldingAwesome, setShowHoldingAwesome] = useState(false);
  const [showDashboardAwesome, setShowDashboardAwesome] = useState(false);
  const [selectedBroker, setSelectedBroker]= useState({})
  const [marketStatusNote, setMarketStatusNote] = useState(false)
  const [marketStatusMessage, setMarketStatusMessage] = useState("");
  // const [brokerEnvironment, setBrokerEnvironment] = useState(false);

  useEffect(() => {
    if (stockDetails.stockQuantity) {
      setBuyQuantity(stockDetails.stockQuantity);
    }
    if (stockDetails.suggestedPrice) {
      if (sellCall) {
        setSellPrice(stockDetails.suggestedPrice);
      } else {
        setBuyStockPrice(stockDetails.suggestedPrice);
      }
    }
  }, [])

  const holdingRedirectModal = () => {
    setShowHoldingAwesome(false)
    history.push("/jarvis-onestock/existing-holding");
  }

  const dashboardRedirectModal = () => {
    setShowDashboardAwesome(false)
    history.push("/jarvis-onestock/dashboard");
  }

  const handleModalOpen = (name) => {
    if (name === "knowMore") {
      setKnowMore(true)
    }
    if (name === "buyStocks") {
      resetForm()
      setBuyStock(true)
    }
    if (name === "sellStocks") {
      setSellMainModalOpen(false)
      resetForm()
      setSellStock(true)
    }
    if (name === "sellStocksMainModal") {
      setSellMainModalOpen(true)
    }
  }

  const handleModalClose = (name) => {
    if (name === "knowMore") {
      setKnowMore(false)
    }
    if (name === "buyStocks") {
      setBuyStock(false)
      resetForm();
    }
    if (name === "sellStocks") {
      setSellStock(false)
      resetForm();
    }
    if (name === "sellStocksMainModal") {
      setSellMainModalOpen(false)
    }
    if (name === "updateStocks") {
      setUpdateStock(false)
      resetForm();
    }
    if(name === 'stockQuantityModal'){
      setBrokerQuantityModal(false)
    }
  }

  const resetForm = () => {
    setBuyStockPrice(stockDetails.suggestedPrice)
    setBuyQuantity(1)
    setBuyQuantity(stockDetails.stockQuantity ? stockDetails.stockQuantity : 1);
    if (stockDetails.suggestedPrice) {
      if (sellCall) {
        setSellPrice(stockDetails.suggestedPrice);
      } else {
        setBuyStockPrice(stockDetails.suggestedPrice);
      }
    }
  }

  // Form
  const [buyQuantity, setBuyQuantity] = useState(1);
  const [errorBuyQuantity, setErrorBuyQuantity] = useState("");

  const [buyStockPrice, setBuyStockPrice] = useState(stockDetails?.suggestedPrice);
  const [errorBuyStockPrice, setErrorBuyStockPrice] = useState("");

  let [sellPrice, setSellPrice] = useState("");
  const [errorSellPrice, setErrorSellPrice] = useState("");

  const handleInputChange = (name, e) => {
    if (name === "quantity") {
      setBuyQuantity(e.replace(/\D/g, ""));
    }
    if (name === "sellPrice") {
      setSellPrice(e);
    }
    if (name === "buyStockPrice") {
      setBuyStockPrice(e);
    }
  };

  const handleValidationForBuy = () => {
    const errorForQuantity = validationHelper.positiveNumber({value:buyQuantity});
    setErrorBuyQuantity(errorForQuantity.message);
    const errorForBuyPrice = validationHelper.positiveNumber({value:buyStockPrice});
    setErrorBuyStockPrice(errorForBuyPrice.message);
    return (
      errorForQuantity.isValid && errorForBuyPrice.isValid
    );
  };

  const handleUpdateBuyStocks = async () => {
    if (!handleValidationForBuy()) {
      return;
    }
    setIsLoading(true)
    try {
      await jarvisOnestockDashboardApis.onestockStocksCallApi({
        stockBuyPrice: buyStockPrice,
        stockBuyQuantity: buyQuantity,
        oneStockCallId: data?.oneStockCallId,
      });
      pendingAction()
      
      setUpdateStock(false)
      setShowHoldingAwesome(true)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const updateBuyStocks = () => {
    setBuyStock(false)
    setUpdateStock(true)
  }

  const handleValidationForSell = () => {
    const errorForPrice = validationHelper.positiveNumber({value:sellPrice});
    setErrorSellPrice(errorForPrice.message);
    return (
      errorForPrice.isValid
    );
  };

  const handleUpdateSellStocks = async () => {
    if (!handleValidationForSell()) {
      return;
    }
    try {
      await jarvisOnestockDashboardApis.onestockStocksCallApi({
        stockSellPrice: sellPrice,
        stockSellQuantity: stockDetails?.stockQuantity,
        oneStockCallId: data?.oneStockCallId,
      });

      pendingAction()

      setSellStock(false)
      setSellMainModalOpen(false)
      setShowDashboardAwesome(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSellForKnowMore = () => {
    setKnowMore(false)
    setSellMainModalOpen(true)
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filterBrokerList = () => {
    return brokerList?.filter(b => b.brokerDisplayName.toLowerCase().includes(searchTerm.toLowerCase()) || b.brokerName.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  const filteredBrokerList = filterBrokerList();
  const centrumBroker = filteredBrokerList.filter((value)=>{
    return value.brokerName === 'centrum'
  });

  const handleMarketStatusModalClose = () => {
    setMarketStatusNote(false);
    setMarketStatusMessage("");
  };

  const handleBrokerClick = async ({broker,modalFlag= false}) => {
    if(broker.brokerPlatform!==config.broker.platform.smallcase  && modalFlag){
      setBuyStock(false)
      setSelectedBroker(broker)
       return setBrokerQuantityModal(modalFlag)
    }
    if (config.environment !== "DEVELOPMENT") {
      try {
          if (!marketStatusNote) {
            const a = await thirdpartyApis.marketOpenHRStatus();
            if (a.status === false) {
              setMarketStatusNote(true);
              setMarketStatusMessage(a.msg)
              return;
            }
          }

      } catch (error) {
          console.log(error);
      }
  }
    // setBrokerEnvironment(true)
    // setTimeout(()=>{
    props.history.push({
      pathname: "/customer/broker",
      state: {
        returnURI: "/jarvis-onestock/existing-holding",
        goBackURI: "/jarvis-onestock/dashboard",
        brokerName: broker.brokerName,
        brokerPlatform: broker.brokerPlatform,
        productCode: config.productCodes.ONESTOCK,
        brokerId: broker.brokerId,
        onestockCallId: data?.oneStockCallId,
        executionType: config.broker.executionType.TRANSACTION,
        stockQuantity: sellCall ? stockDetails?.stockQuantity : buyQuantity,
      }
    });
  // },2000)
  };

  const {
    stockName = "",
    stockQuantity = 1,
    suggestedPrice = 0,
    stockMarketCap = "",
    brokerName = "",
    isin="",
    stockSymbol=""
  } = stockDetails;

  const classes = useStyles();
  const isCentrumTextVisible = !searchTerm || "centrum".includes(searchTerm)

  return (
    <>
      <Box sx={{ p: 2, border: `1px solid #e2e2e2`, ...sx }}>
        <Box sx={{ width: "auto" }}>
          {
            sellCall ?
              <Stack mb={1.5} direction={"row"} alignItems={"center"}>
                {brokerName ? (
                  <Text
                    variant="body2"
                    title={brokerName}
                    className={classes.brokerName}
                    sx={{ fontWeight: 400 }}
                  >
                    {brokerName}
                  </Text>
                ) : null}

                <Text className={classes.dot}></Text>

                <Text
                  variant="caption1"
                  component="span"
                  sx={{ color: "#808591" }}
                >
                  Qty.{" "}
                  <span style={{ fontWeight: 500, color: "#222B3E" }}>
                    {stockQuantity}
                  </span>
                </Text>
              </Stack>
              : null
          }
          <Stack sx={{
              justifyContent:"space-between",
              alignItems:"center",
              margin:"7px 0px 8px 0px",
          }}
            direction="row"
          >
              <Text sx={{
                  fontSize:"10px",
                  fontWeight:"500"
              }} >{stockSymbol}</Text>
              <Text style={{
                  color: "#676E76",
                  fontSize: "13px",
              }} >{isin}</Text>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            mb={1.5}
            sx={{ flexWrap: "nowrap" }}
          >
            <Text
              variant="body2"
              title={stockName}
              className={classes.stockName}
              sx={{ fontWeight: 700 }}
            >
              {stockName}
            </Text>
            <Text variant="small" sx={{ fontWeight: 700 }}>
              {formatHelper.currency(suggestedPrice, {
                config: { maximumFractionDigits: 2 },
              })}
            </Text>
          </Stack>
          
          <Stack my={0.5} direction="row">
            <Text title={stockMarketCap} className={classes.infoChip}>
              {stockMarketCap}
            </Text>
            {
              actionType === "SELL" ?
                <Text className={classes.SellChip}>{`SELL`}</Text>
                :
                <Text className={classes.BuyChip}>{`BUY`}</Text>
            }
          </Stack>
        </Box>
        {
          sellCall ?
            <Stack direction="row" justifyContent="space-between" mt={1.5}>
              <Text className={classes.whyChip} onClick={() => handleModalOpen("knowMore")}>
                know why sell?
              </Text>
              <Text className={classes.sellButton} onClick={() => handleModalOpen("sellStocksMainModal")} >Proceed to Sell</Text>
            </Stack>
            :
            <Text className={classes.buyButton} onClick={() => handleModalOpen("buyStocks")}>Proceed to Buy</Text>
        }
      </Box>

      {/* Know More Pop up */}
      <Modal
        open={knowMore}
        size={"sm"}
        disableClose={true}
      >
        <Stack direction="row" justifyContent="space-between">
          <Text sx={{
            color: "#222B3E",
            fontWeight: 500,

          }}>
            Know why OneStock recommend you this stock
          </Text>
          <IconButton className={classes.close} onClick={() => handleModalClose("knowMore")}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack mt={2}>
          <Text mb={1.5}>
            <li>
              {stockDetails.whySellMessage}
            </li>

          </Text>
          <Button size="small" variant="contained" sx={{ width: "100%" }} onClick={handleSellForKnowMore}>
            Sell Now
          </Button>
        </Stack>
      </Modal>

      {/* Main Sell Modal */}
      <Modal
        open={sellMainModalOpen}
        size="sm"
        disableClose
      >
        <Stack direction="row" justifyContent="space-between">
          <Text variant="h6">
            Sell Stock
          </Text>
          <IconButton className={classes.close} onClick={() => handleModalClose("sellStocksMainModal")}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack mt={2} alignItems="center">
          <Grid container>
            <Grid item md={5.5} sx={{ marginRight: "20px", marginLeft: "15px" }} >
              <Stack>
                <TextField
                  type="text"
                  label="Stock Price"
                  value={suggestedPrice}
                  disabled
                  sx={{ width: 1, mb: 2 }}
                />
              </Stack>
            </Grid>
            <Grid item md={5.5}>
              <Stack>
                <TextField
                  type="text"
                  label="Enter quantity"
                  value={stockDetails?.stockQuantity}
                  disabled={true}
                  sx={{ width: 1, mb: 2 }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        <Stack mt={3} mb={2} direction={{ xs: "column", md: "row" }} justifyContent="space-between">
          <Text mt={1} mb={1} sx={{ fontWeight: 500, color: "#212225" }}>
            If you don't find your broker, update manually
          </Text>
          <Button size="small"
            sx={{
              border: "#2B70C5",
              background: "#EEF4FA",
              fontSize: "13px",
              borderRadius: "10px",
              padding: "0px 10px",
              textTransform: "capitalize"
            }}
            variant="outlined"
            onClick={() => handleModalOpen("sellStocks")}>
            Update Now
          </Button>
        </Stack>

        <Grid container mt={2}>
          <Grid item md={5.5} sx={{ marginRight: "5px", marginLeft: "15px" }}>
            <Stack>
              <Text variant="body1" sx={{ fontWeight: 500 }}>Execute through broker</Text>
              <Text variant="small" sx={{ color: "#A2A7AE" }}>
                Select a broker from the list below
              </Text>
            </Stack>
          </Grid>
          <Grid item mt={1.5} md={6} sx={{ width: { xs: "100%" } }}>
            <TextField
              type="text"
              placeholder="Search broker"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ width: 1, mb: 2 }}
              inputProps={{
                className: classes.searchBroker
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start" sx={{ pl: 1, margin: "15px 0" }}><img alt="" src="/svgs/search_icon.svg"></img></InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <>
         <Text variant="medium" sx={{ fontWeight: 500, mb: "20px" }}>
              List of Brokers
        </Text>
        <Grid
              container
              spacing={2.5}
              sx={{
                justifyContent: "flex-start",
                flex: 1,
                alignItems: "center",
                mb: { xs: 8, md: "-0.5rem" },
                gap:"2rem",
                paddingBottom: "25px",
                marginBottom: "20px",
              }}
        >
        {isBrokerListInitialCalled && centrumBroker.length ? (
                centrumBroker.map((b, index) => (
                  b.brokerIcon ? (
                    <Grid item xs={6} sm={4} md={4} lg={6} xl={3} key={index}>
                      <ChooseBrokerIcon
                        image={b.brokerIcon}
                        title={b.brokerDisplayName}
                        onClick={() => handleBrokerClick({broker:b,modalFlag:true})}
                      />
                    </Grid>
                  ) : null
                ))
              ) : (
                <></>
              )}
              {isCentrumTextVisible && <Stack direction={{xs:"row",md:"column"}}
              justifyContent={{xs:"space-between", md:"flex-start"}}
              width={{xs:"100%",md:"fit-content"}}
              pl={{xs:"1.25rem",md:"20px"}}
              >
              <Text
                variant="small"
                sx={{ fontWeight: 700, placeSelf: "center" }}
              >
                Don't have a Centrum account?
              </Text>
              <Text
                variant="small"
                sx={{
                  fontWeight: 500,
                  color: "#2B70C5",
                  cursor: "pointer",
                  "&::selection": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={() => {

                  window.open(props?.centrumReferalURL, "_blank");
                }}
              >
                Open New Account
              </Text>
              </Stack>}
              <Stack direction="row" ml="1.25rem" width="100%" border="1px solid #F5F5F5"></Stack>
            </Grid>
            <Grid container spacing={2.5} sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center", mb: { xs: 8, md: 2.5 }, mt: { xs: 1, md: 0 } }}>
          {(isBrokerListLoading || !isBrokerListInitialCalled) && <Loader />}
          {isBrokerListInitialCalled && !filteredBrokerList.length ? (
            <Grid item>
              <Text>Broker with search term <b>{searchTerm}</b> not found/integrated yet. Please try with manual update.</Text>
            </Grid>
          ) : filteredBrokerList.map((b, index) => (
            <>
            {
              b.brokerName === 'centrum' ? (<></>) :
            (
              <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={index}>
              <ChooseBrokerIcon
                image={b.brokerIcon}
                title={b.brokerDisplayName}
                onClick={() => handleBrokerClick({broker:b})}
              />
            </Grid>
            )
            }
            </>
          ))
          }
        </Grid>
        </>
{/*         <Grid container spacing={2.5} sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center", mb: { xs: 8, md: 2.5 }, mt: { xs: 1, md: 0 } }}>
          {(isBrokerListLoading || !isBrokerListInitialCalled) && <Loader />}
          {isBrokerListInitialCalled && !filteredBrokerList.length ? (
            <Grid item>
              <Text>Broker with search term <b>{searchTerm}</b> not found/integrated yet. Please try with manual update.</Text>
            </Grid>
          ) : filteredBrokerList.map((b, index) => (
            <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={index}>
              <ChooseBrokerIcon
                image={b.brokerIcon}
                title={b.brokerDisplayName}
                onClick={() => handleBrokerClick({broker:b})}
              />
            </Grid>
          ))
          }
        </Grid> */}

      </Modal>

      {/* Buy Modal  */}
      <Modal
        open={buyStock}
        size={"sm"}
        disableClose={true}
      >
        <Stack direction="row" justifyContent="space-between">
          <Text variant="h6">
            Buy Stock
          </Text>
          <IconButton className={classes.close} onClick={() => handleModalClose("buyStocks")}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack mt={2} alignItems="center">
          <Grid container>
            <Grid item md={5.5} sx={{ marginRight: "20px", marginLeft: { xs: "unset", md: "15px" } }} >
              <Stack>
                <TextField
                  type="text"
                  label="Stock Price"
                  value={buyStockPrice}
                  sx={{ width: 1, mb: 2 }}
                  disabled={true}
                />
              </Stack>
            </Grid>
            <Grid item md={5.5}>
              <Stack>
                <TextField
                  type="text"
                  label="Enter quantity"
                  value={buyQuantity}
                  onChange={(e) => handleInputChange("quantity", e.target.value)}
                  helperText={errorBuyQuantity}
                  error={errorBuyQuantity}
                  sx={{ width: 1, mb: 2 }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Grid container>
          <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Stack>
              <Stack direction="row" alignItems="end">
                <Text
                  variant="body2"
                  sx={{
                    color: "#676E76"
                  }}
                >
                  Total amount:&nbsp;
                </Text>
                <Text variant="body2" sx={{ fontWeight: 500 }}>
                  {(buyStockPrice * buyQuantity).toFixed(2)}
                </Text>
              </Stack>
              <Text variant="caption1" sx={{ color: "#A2A7AE" }}>
                Make sure your account has sufficient funds.
              </Text>
            </Stack>
          </Grid>
        </Grid>

        <Stack mt={3} mb={2} direction={{ xs: "column", md: "row" }} justifyContent="space-between">
          <Text mt={1} mb={1} sx={{ fontWeight: 500, color: "#212225" }}>
            If you don't find your broker, update manually
          </Text>
          <Button size="small"
            sx={{
              border: "#2B70C5",
              background: "#EEF4FA",
              fontSize: "13px",
              borderRadius: "10px",
              padding: "0px 10px",
              textTransform: "capitalize"
            }}
            variant="outlined"
            onClick={updateBuyStocks}>
            Update Now
          </Button>
        </Stack>

        <Grid container mt={2}>
          <Grid item md={5.5} sx={{ marginRight: "5px", marginLeft: "15px" }}>
            <Stack>
              <Text variant="body1" sx={{ fontWeight: 500 }}>Link Broker</Text>
              <Text variant="small" sx={{ color: "#A2A7AE" }}>
                Select a broker from the list below
              </Text>
            </Stack>
          </Grid>
          <Grid item mt={1.5} md={6} sx={{ width: { xs: "100%" } }}>
            <TextField
              type="text"
              placeholder="Search broker"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ width: 1, mb: 2 }}
              inputProps={{
                className: classes.searchBroker
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start" sx={{ pl: 1, margin: "15px 0" }}><img alt="" src="/svgs/search_icon.svg"></img></InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
         <>
         <Text variant="medium" sx={{ fontWeight: 500, mb: "20px" }}>
              List of Brokers
        </Text>
        <Grid
              container
              spacing={2.5}
              sx={{
                justifyContent: "flex-start",
                flex: 1,
                alignItems: "center",
                mb: { xs: 8, md: "-0.5rem" },
                gap:"2rem",
                paddingBottom: "25px",
                marginBottom: "20px",
              }}
        >
        {isBrokerListInitialCalled && centrumBroker.length ? (
                centrumBroker.map((b, index) => (
                  b.brokerIcon ? (
                      <Grid item xs={6} sm={4} md={4} lg={6} xl={3} key={index}>
                        <ChooseBrokerIcon
                          image={b.brokerIcon}
                          title={b.brokerDisplayName}
                          onClick={() => handleBrokerClick({broker:b,modalFlag:true})}
                        />
                      </Grid>
                    ) : null  
                ))
              ) : (
                <></>
              )}
              {isCentrumTextVisible && <Stack direction={{xs:"row",md:"column"}}
              justifyContent={{xs:"space-between", md:"flex-start"}}
              width={{xs:"100%",md:"fit-content"}}
              pl={{xs:"1.25rem",md:"20px"}}
              >
              <Text
                variant="small"
                sx={{ fontWeight: 700, placeSelf: "center" }}
              >
                Don't have a Centrum account?
              </Text>
              <Text
                variant="small"
                sx={{
                  fontWeight: 500,
                  color: "#2B70C5",
                  cursor: "pointer",
                  "&::selection": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={() => {
                  window.open(props?.centrumReferalURL, "_blank");
                }}
              >
                Open New Account
              </Text>
              </Stack>}
              <Stack direction="row" ml="1.25rem" width="100%" border="1px solid #F5F5F5"></Stack>
            </Grid>
            <Grid container spacing={2.5} sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center", mb: { xs: 8, md: 2.5 }, mt: { xs: 1, md: 0 } }}>
          {(isBrokerListLoading || !isBrokerListInitialCalled) && <Loader />}
          {isBrokerListInitialCalled && !filteredBrokerList.length ? (
            <Grid item>
              <Text>Broker with search term <b>{searchTerm}</b> not found/integrated yet. Please try with manual update.</Text>
            </Grid>
          ) : filteredBrokerList.map((b, index) => (
            <>
            {
              b.brokerName === 'centrum' ? (<></>) :
            (
              <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={index}>
              <ChooseBrokerIcon
                image={b.brokerIcon}
                title={b.brokerDisplayName}
                onClick={() => handleBrokerClick({broker:b,modalFlag:true})}
              />
            </Grid>
            )
            }
            </>
          ))
          }
        </Grid>
         </>
      </Modal>

      {/* Sell Modal  */}
      <Modal
        open={sellStock}
        size={"sm"}
        disableClose={true}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Text variant="subtitle1">
              Sell Stock
            </Text>
            <Text ml={1.5} mt={1.3} variant="small">~ {stockDetails?.stockName}</Text>
          </Stack>
          <IconButton className={classes.close} onClick={() => handleModalClose("sellStocks")}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack mt={2} alignItems="center">
          <Grid container>
            <Grid item md={5.5} sx={{ marginRight: "20px", marginLeft: "15px" }} >
              <Stack>
                <TextField
                  type="text"
                  label="Stock Price"
                  value={sellPrice}
                  onChange={(e) => handleInputChange("sellPrice", e.target.value)}
                  helperText={errorSellPrice}
                  error={errorSellPrice}
                  sx={{ width: 1, mb: 2 }}
                />
              </Stack>
            </Grid>
            <Grid item md={5.5}>
              <Stack>
                <TextField
                  type="text"
                  label="Enter quantity"
                  value={stockDetails?.stockQuantity}
                  disabled={true}
                  sx={{ width: 1, mb: 2 }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        {/* <Grid container>
          <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Stack>
              <Stack direction="row" alignItems="end">
                <Text
                  variant="body2"
                  sx={{
                    color: "#676E76"
                  }}
                >
                  Total amount:&nbsp;
                </Text>
                <Text variant="body2" sx={{ fontWeight: 500 }}>
                  {(sellPrice * stockDetails?.stockQuantity).toFixed(2)}
                </Text>
              </Stack>
              <Text variant="caption1" sx={{ color: "#A2A7AE" }}>
                Make sure your account has sufficient funds.
              </Text> 
            </Stack>
          </Grid>
        </Grid> */}
        <Stack flex={0} direction="row" alignItems="center" sx={{ mb: 2, ml:"14px", mr:"11px", px: 1.75, py: 1, borderRadius: "3px", backgroundColor: "#EEF4FA" }}>
            <InfoIcon sx={{ mt:"-1.375rem", mr: 1, fontSize: "20px", color: "#B0BABF" }} />
            <Text variant="small" sx={{ mt:"-1.375rem", mr: 1, fontWeight: "medium", color: "#252C32", whiteSpace:"nowrap" }}>Please Note:</Text>
            <Text variant="small" sx={{ color: "#303236", whiteSpace:"prewrap" }}>Transaction will get updated in jarvis account and not in your broker account.</Text>
        </Stack>
        <Stack mt={3}>
          <Button size="small" variant="contained" sx={{ width: "100%", textTransform: "unset" }} onClick={handleUpdateSellStocks}>
            Update Price
          </Button>
        </Stack>
      </Modal>

      {/* Buy Awesome Pop Up */}
      <Modal
        open={showHoldingAwesome}
        size={"xs"}
        disableClose={true}
      >
        <Stack alignItems="center">
          <Text mb={2} variant="subtitle1">
            Awesome!
          </Text>
          <Text mb={2} variant="body1">
            Stock is added to your portfolio
          </Text>
          <JarvisButton
            variant="outlined"
            onClick={holdingRedirectModal}
            sx={{ border: "1px solid #DEDEDE" }}
          >
            Go to Holdings
          </JarvisButton>
        </Stack>
      </Modal>

      {/* Sell Awesome Pop Up */}
      <Modal
        open={showDashboardAwesome}
        size={"xs"}
        disableClose={true}
      >
        <Stack alignItems="center">
          <Text mb={2} variant="subtitle1">
            Awesome!
          </Text>
          <Text mb={2} variant="body2" color={'#989797'}>
            Your portfolio has been updated successfully
          </Text>
          <JarvisButton
            variant="outlined"
            onClick={dashboardRedirectModal}
            sx={{ border: "1px solid #DEDEDE" }}
            disableEndIcon={true}
          >
            Go to Dashboard
          </JarvisButton>
        </Stack>
      </Modal>

      {/* Update Now */}
      <Modal
        open={updateStock}
        size={"sm"}
        disableClose={true}
      >
        <Stack direction="row" justifyContent="space-between">
          <Text variant="h6">
            Update Stock
          </Text>
          <IconButton className={classes.close} onClick={() => handleModalClose("updateStocks")}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack mt={2}>
          <Stack>
            <Stack>
              <TextField
                type="text"
                label="Stock Price"
                value={buyStockPrice}
                sx={{ width: 1, mb: 2 }}
                onChange={(e) => handleInputChange("buyStockPrice", e.target.value)}
                helperText={errorBuyStockPrice}
                error={errorBuyStockPrice}
              />
              <TextField
                type="text"
                label="Enter quantity"
                value={buyQuantity}
                onChange={(e) => handleInputChange("quantity", e.target.value)}
                helperText={errorBuyQuantity}
                error={errorBuyQuantity}
                sx={{ width: 1, mb: 2 }}
              />
            </Stack>
          </Stack>
          <Stack mb={2} direction="row">
            <Text sx={{ fontSize: "15px", color: "#676E76" }}>Total amount: &nbsp;</Text>
            <Text sx={{ fontSize: "15px", fontWeight: 500 }}>{(buyStockPrice * buyQuantity).toFixed(2)}</Text>
          </Stack>
          <Stack flex={0} direction="row" alignItems="center" sx={{ mb: 2, px: 1.75, py: 1, borderRadius: "3px", backgroundColor: "#EEF4FA" }}>
            <InfoIcon sx={{ mt:"-1.375rem", mr: 1, fontSize: "20px", color: "#B0BABF" }} />
            <Text variant="small" sx={{ mt:"-1.375rem", mr: 1, fontWeight: "medium", color: "#252C32", whiteSpace:"nowrap" }}>Please Note:</Text>
            <Text variant="small" sx={{ color: "#303236", whiteSpace:"prewrap" }}>Transaction will get updated in jarvis account and not in your broker account.</Text>
          </Stack>
          <JarvisButton loading={isLoading} disableEndIcon size="small" variant="contained" sx={{ width: "100%", textTransform: "uppercase" }} onClick={handleUpdateBuyStocks}>
            Update
          </JarvisButton>
        </Stack>
      </Modal>

{/* stock quantity modal for symphony broker platform stocks */}
      <Modal
      open={brokerQuantityModal}
      disableClose={true}
      
      size='sm'
    >
      <Stack direction="row" justifyContent="space-between">
        <Text variant="h6" sx={{
          fontWeight:"bold",
        }}>
          Buy
        </Text>
        <IconButton className={classes.close} onClick={() => handleModalClose("stockQuantityModal")}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" mt={2}>
        <Text variant="h6" sx={{
          fontWeight:"bold",
        }}>
          {stockName}
        </Text>
      </Stack>
        <Stack mt={2} mb={2} spacing={2} direction={{ xs: "column", sm: "row" }}>

          <TextField
            type="text"
            label="Stock Price"
            value={buyStockPrice}
            sx={{ width: 1, mb: 2 }}
            disabled={true}
          />
          <TextField
            type="number"
            label="Enter quantity"
            value={buyQuantity}
            onChange={(e) => handleInputChange("quantity", e.target.value)}
            helperText={errorBuyQuantity}
            error={errorBuyQuantity}
            sx={{ width: 1, mb: 2 }}
          />
        </Stack>
        <Stack direction="column" mt={2}>
          <Stack direction="row"style={{display:"flex", justifyContent:"flex-end"}}>
            <Text
              variant="body2"
              sx={{
                color: "#676E76",
              }}
            >
              Total value:&nbsp;
            </Text>
            <Text variant="body2" sx={{ fontWeight: 500 }}>
              {(buyStockPrice * buyQuantity).toFixed(2)}
            </Text>
          </Stack>
          <Stack direction="row"style={{display:"flex", justifyContent:"flex-end"}}>
          <Text variant="caption1" sx={{ color: "#A2A7AE" }}>
            Make sure your account has sufficient funds.
          </Text>
          </Stack>
        </Stack>
      <Stack direction='row' marginY='1.5rem'>
        <JarvisButton
          disableEndIcon
          sx={{ width: "100%", boxShadow: "none", mt: 3 }}
          onClick={()=>handleBrokerClick({broker:selectedBroker})}
        >
          Proceed to Buy
        </JarvisButton>
      </Stack>

    </Modal>
        {/* Pop-up for showing leaving jarvis website to broker website */}
{/*         <Modal
          open={brokerEnvironment}
          disableClose={true}
          size={"sm"}
          sx={{ width:"100%", height:"75%" }}
        >
        <Text sx={{ mt: {xs:"75%", sm: "35%"}, mb: 0.8, fontSize: "22px", textAlign: "center", whiteSpace: "prewrap" }}>You are leaving our environment</Text>
        </Modal> */}
      {/* Section: market Status Modal */}
      <Modal
        open={marketStatusNote}
        disableClose={true}
        size={"xs"}
        sx={{ padding: "16px 0px" }}
      >
        <Stack alignItems="center" width={1}>
          <img src="/images/market-closed.svg" alt="" width={100}></img>
          <Text
            sx={{
              mt: 2,
              mb: 0.8,
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            {marketStatusMessage}
          </Text>
          <Text
            sx={{ mb: 3, color: "#808591cc", textAlign: "center" }}
            variant="small"
          >
            Try again on the next market day{" "}
          </Text>
          <JarvisButton
            variant="outlined"
            disableEndIcon="true"
            onClick={() => handleMarketStatusModalClose()}
          >
            Okay{" "}
          </JarvisButton>
        </Stack>
      </Modal>
    </>
  );
};

export default withRouter(JarvisOnestockStockCard);

const useStyles = makeStyles(() => ({
  stockName: {
    color: "#202020",
    fontWeight: "600",
    textOverflow: "ellipsis",
    marginRight: "10px",
    fontSize: "18px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingRight: "12px",
  },

  infoChip: {
    fontSize: "11px",
    marginRight: "8px",
    border: "1px solid #F5F5F5",
    borderRadius: "10px",
    padding: "2px 5px",
    textTransform: "uppercase",
    color: "#000000",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "center",
    marginTop: "4px",
  },
  riskChip: {
    fontSize: "11px",
    borderRadius: "10px",
    border: "1px solid rgba(212, 76, 76, 0.08)",
    color: "#D44C4C",
    padding: "2px 5px",
    whiteSpace: "nowrap",
    textAlign: "center",
    marginTop: "3px",
    marginRight: "8px",
  },

  SellChip: {
    fontSize: "14px",
    borderRadius: "10px",
    border: "1px solid rgba(212, 76, 76, 0.08)",
    background: "rgba(212, 76, 76, 0.08)",
    color: "#D44C4C",
    padding: "2px 5px",
    whiteSpace: "nowrap",
    textAlign: "center",
    marginTop: "3px",
  },
  BuyChip: {
    fontSize: "14px",
    borderRadius: "10px",
    border: "1px solid rgba(212, 76, 76, 0.08)",
    background: "#F0F8F4",
    color: "#1C8D13",
    padding: "2px 5px",
    whiteSpace: "nowrap",
    textAlign: "center",
    marginTop: "3px",
  },
  moreBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid #e2e2e2`,
    height: "100%",
    cursor: "pointer",
  },
  brokerName: {
    marginRight: "8px",
  },
  dot: {
    backgroundColor: "#808591",
    height: "5px",
    width: "5px",
    marginRight: "8px",
    borderRadius: "10px",
  },
  whyChip: {
    fontSize: "12px",
    borderRadius: "3px",
    cursor: "pointer",
    border: "1px solid rgba(212, 76, 76, 0.08)",
    color: "#2B70C5",
    textAlign: "center",
    backgroundColor: "#2B70C51F",
    padding: "6px 9px",
    whiteSpace: "nowrap",
    marginTop: "3px",
    marginRight: "8px",
    width: "100%",
    textTransform: "uppercase",
  },
  sellButton: {
    fontSize: "12px",
    borderRadius: "3px",
    border: "1px solid rgba(212, 76, 76, 0.08)",
    color: "#ffff",
    textAlign: "center",
    backgroundColor: "#2B70C5",
    padding: "6px 9px",
    whiteSpace: "nowrap",
    marginTop: "3px",
    marginRight: "8px",
    cursor: "pointer",
    width: "100%",
    textTransform: "uppercase",

  },
  buyButton: {
    fontSize: "12px",
    borderRadius: "3px",
    border: "1px solid rgba(212, 76, 76, 0.08)",
    color: "#ffff",
    textAlign: "center",
    backgroundColor: "#2B70C5",
    padding: "6px 9px",
    marginTop: "15px",
    marginRight: "8px",
    cursor: "pointer",
    width: "100%",
    textTransform: "uppercase",

  },
  searchBroker: {
    padding: "10px 0",
  }
}));
