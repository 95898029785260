import React from "react";

import JarvisCard from "components/JarvisCard";
import Text from "components/common/Text";
import Chart from "components/common/Chart";

class SectorDiversificationContainer extends React.Component {
    
    constructor(props) {
      super(props)
      this.state = {
          isScreenTypeLaptop : false ,   
      }
    }
    

    componentDidMount() {
        this.props.getPortfolioSectorAction();
        window.addEventListener('resize', (event) => {
            if (window.innerWidth > 900) {
                this.setState({ isScreenTypeLaptop: true  });
            } else {
                this.setState({ isScreenTypeLaptop: false });
            }
        });
        
    }

    render() {
        const { portfolioSector } = this.props;
        let sectorData = [];

        portfolioSector.forEach((e) => {
            if ([undefined, null, 0].includes(e.amount)) {
                return null;
            }
            sectorData.push({ name: e.sector, y: e.amount || 0 });
        });

        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: "",
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.percentage:.1f}%</b> of total<br/>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    showInLegend: true,
                    dataLabels: {
                        enabled: this.state.isScreenTypeLaptop,
                        format: '{point.name}: {point.percentage:.1f}%',
                    },
                },
            },
            series: [
                {
                    data: sectorData,
                },
            ],
        };

        return (
            <JarvisCard  sx={{
                border: { xs: "1px solid #EEF0F2", md: "none" },
            }}>
                <Text variant="subtitle2" sx={{ mb: 2 }}>
                    Sector Diversification
                </Text>
                {sectorData.length ? (
                    <Chart options={options} />
                ) : (
                    <Text variant="small">No data in chart</Text>
                )}
            </JarvisCard>
        );
    }
}

export default SectorDiversificationContainer;
