import { call, put, takeLatest } from "@redux-saga/core/effects";

import { transactionApis } from "./transactionApis";
import { transactionParsers } from "./transactionParsers";
import { transactionConstants } from "./transactionConstants";



function* transactionListSaga({ payload }) {
    const { page, rowsPerPage } = payload;
    try {
        yield put({ type: transactionConstants.TRANSACTION_LIST_REQUEST });

        const res = yield call(transactionApis.getTransactionList, { page, rowsPerPage });
        const data = transactionParsers.transactionParser(res);
        
        let count = 0;
        if (res.count) {
            count = res.count;
        }

        yield put({ type: transactionConstants.TRANSACTION_LIST_RESPONSE, payload: { data, count } });

    } catch (error) {
        yield put({ type: transactionConstants.TRANSACTION_LIST_RESPONSE, payload: [] });
    }
}

export function* registerTransactionListSaga() {
    yield takeLatest(transactionConstants.TRANSACTION_LIST_SAGA, transactionListSaga);
}