import { call, put, takeLatest } from "@redux-saga/core/effects";
import { thirdpartyConstants } from "./thirdpartyConstants";
import { thirdpartyApis } from "./thirdpartyApis";
import { thirdpartyParsers } from "./thirdpartyParsers";

function* subscriptionPlansSaga({ payload }) {
    try {
        const { productCode } = payload;

        yield put({ type: thirdpartyConstants.SUBSCRIPTION_PLANS_REQUEST });

        const res = yield call(thirdpartyApis.getSubscriptionPlans, {
            productCode,
        });

        const parsedRes = thirdpartyParsers.subscriptionPlans(res);

        yield put({
            type: thirdpartyConstants.SUBSCRIPTION_PLANS_RESPONSE,
            payload: parsedRes,
        });
    } catch (e) {
        yield put({
            type: thirdpartyConstants.SUBSCRIPTION_PLANS_RESPONSE,
            payload: [],
        });
    }
}

function* getTransactionHistoryListSaga({ payload }) {
    try {
        yield put({ type: thirdpartyConstants.TRANSACTION_HISTORY_LIST_REQUEST });

        const res = yield call(thirdpartyApis.getTransactionHistoryList, payload);
        const parsedRes = thirdpartyParsers.transactionHistoryList(res);
        console.log({ parsedRes });

        yield put({
            type: thirdpartyConstants.TRANSACTION_HISTORY_LIST_RESPONSE,
            payload: parsedRes,
        });
    } catch (e) {
        yield put({
            type: thirdpartyConstants.TRANSACTION_HISTORY_LIST_RESPONSE,
            payload: [],
        });
    }
}

export function* registerThirdpartySagas() {
    yield takeLatest(
        thirdpartyConstants.SUBSCRIPTION_PLANS_SAGA,
        subscriptionPlansSaga
    );
    yield takeLatest(
        thirdpartyConstants.TRANSACTION_HISTORY_LIST_SAGA,
        getTransactionHistoryListSaga
    );
}
