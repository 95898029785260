import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const beforeRegistrationTracker = {};

// Analytics_tracker_active
beforeRegistrationTracker.appDownload = ({ device_id, campaign, platform }) => {
    const eventName = "app_download";

    appsFlyerTracker.trackEvents({
        eventName,
        eventData: { device_id, campaign, platform },
    });

    mixpanelTracker.trackEvents({
        eventName,
        eventData: { device_id, campaign, platform },
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { device_id, campaign, platform },
    });
};

beforeRegistrationTracker.spalshVideo = ({ device_id }) => {
    // const eventName = "splash_video";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });
};

beforeRegistrationTracker.splashVideo1 = ({ device_id }) => {
    // const eventName = "splash_video_1";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id},
    // });
};

beforeRegistrationTracker.splashVideo2 = ({ device_id }) => {
    // const eventName = "splash_video_2";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });
    
    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });
};

beforeRegistrationTracker.splashVideo3 = ({ device_id }) => {
    // const eventName = "splash_video_3";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });
};

beforeRegistrationTracker.splashVideo4 = ({ device_id }) => {
    // const eventName = "splash_video_4";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });
};

beforeRegistrationTracker.splashVideo5 = ({ device_id }) => {
    // const eventName = "splash_video_5";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { device_id },
    // });
};

export { beforeRegistrationTracker };
