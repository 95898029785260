import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { customerActions } from "stores/customer/customerActions";

const mapStateToProps = (state) => {
    return {
        loginProfile: state.customer.loginProfile,
        isMobileVerified: state.customer.loginProfile.isMobileVerified,

        isOnboardingCompleted: state.onboarding.isOnboardingCompleted,

        username: state.customer.loginProfile.name,
        profile: state.customer.profile,
        profileLoading: state.customer.loading.profile,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateAuthDataAction: customerActions.updateAuthData,
}, dispatch)

const DashboardHeaderStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default DashboardHeaderStore;