import _get from "lodash.get";

const jarvisRMSExistingHoldingParser = {};


jarvisRMSExistingHoldingParser.portfolioList = (res) => {
    if (res && res.data) {
      res = res.data;
    }
    if (!res) {
      return [];
    }

    let data = res.map((p) => ({
      portfolioId: _get(p, "portfolioId", null),
      portfolioName: _get(p, "portfolioName", null),
      brokerName: _get(p, "brokerName", null),
      brokerDisplayName: _get(p, "brokerDisplayName", null),
      brokerPlatform: _get(p, "brokerPlatform", null),
      portfolioRisk: _get(p, "portfolioRisk", null),
      portfolioRiskScore: _get(p, "portfolioRiskScore", null),
    }));
  
    return data;
  };
  

  jarvisRMSExistingHoldingParser.stockList = (res) => {
    if (res && res.data) {
      res = res.data;
    }
    if (!res) {
      return [];
    }

    let listing = [];
    if(Array.isArray(res)){
      listing = res;
    }
    if(listing.length <= 0){
      listing = _get(res, "list", [])
    }

    let data = listing.map((s) => ({
      portfolioStockId: _get(s, "portfolioStockId", null),
      stockId: _get(s, "stockId", null),
      stockName: _get(s, "stockName", null),
      stockSymbol: _get(s, "stockSymbol", null),
      stockBuyDate: _get(s, "stockBuyDate", null),
      stockMarketCap: _get(s, "stockMarketCap", null),
      stockSector: _get(s, "stockSector", null),
      stockBuyPrice: _get(s, "stockBuyPrice", null),
      stockQuantity: _get(s, "stockQuantity", null),
      stockBuyValue: _get(s, "stockBuyValue", null),
      stockCurrentPrice: _get(s, "stockCurrentPrice", null),
      stockCurrentValue: _get(s, "stockCurrentValue", null),
      stockChangePrice: _get(s, "stockChangePrice", null),
      stockChangePercentage: _get(s, "stockChangePercentage", null),
      stockChangeType: _get(s, "stockChangeType", null),
      stockRecommendedAction: _get(s, "stockRecommendedAction", null),
      rmsCallStatus: _get(s, "rmsCallStatus", null),
      stockErrorMessage: _get(s, "stockErrorMessage", null),

      scoreByAI: _get(s, "scoreByAI", null),
      rmsCallMessage: _get(s, "rmsCallMessage", null),
    }));
  
    return data;
  };

export { jarvisRMSExistingHoldingParser };
