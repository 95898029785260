import IconButton from 'components/common/IconButton';
import SectionTitle from 'components/SectionTitle';
import React from 'react';

import Drawer from "../common/Drawer";

import { Close as CloseIcon } from "@mui/icons-material";
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisChipNew from 'components/JarvisChipNew';
import DatePicker from 'components/common/DatePicker';
import JarvisButton from 'components/JarvisButton';

const RMSTransactionListFilter = (props) => {
  const {
    open = false,
    onClose,
    onChange,
    onReset,
    onSubmit,
    filterData,

    portfolioOptions,
    executionStatusOptions,
    transactionStatus = [],

    screen
  } = props;

  if (screen === "portfolio") {
    return (
      <Drawer open={open} anchor="right">
        <Stack
          justifyContent="space-between"
          sx={{ flex: 1, px: 2, py: 2, maxWidth: "320px" }}
        >
          <Stack>
            <SectionTitle
              heading="Filter"
              endContent={() => (
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              )}
            />

            {/* Transaction Status */}
            <Stack sx={{ mb: 2, pt: 3 }}>
              <Text
                variant="small"
                fontWeight="medium"
                sx={{ mb: 1, color: "#212225" }}
              >
                Transaction Status
              </Text>
              {transactionStatus.length ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ flexWrap: "wrap" }}
                >
                  {transactionStatus.map((p, i) => (
                    <JarvisChipNew
                      key={`portfolio_${p.value}`}
                      label={p.label}
                      variant="outlined"
                      sx={{ mr: i < transactionStatus.length - 1, mb: 1 }}
                      color={
                        filterData.transactionStatus === p.value
                          ? "primary"
                          : "default"
                      }
                      onClick={() =>
                        onChange({ name: "transactionStatus", value: p.value })
                      }
                    />
                  ))}
                </Stack>
              ) : null}
            </Stack>

            {/* From Date Picker */}
            <Stack sx={{ mb: 2 }}>
              <Text
                variant="small"
                fontWeight="medium"
                sx={{ mb: 1, color: "#212225" }}
              >
                Date Range
              </Text>
              <DatePicker
                label="From Date"
                disableFuture={true}
                value={filterData.fromDate}
                onChange={(value) => onChange({ name: "fromDate", value })}
              />
            </Stack>

            {/* To Date Picker */}
            <Stack sx={{ mb: 2 }}>
              <DatePicker
                label="To Date"
                value={filterData.toDate}
                disableFuture={true}
                onChange={(value) => onChange({ name: "toDate", value })}
              />
            </Stack>
          </Stack>

          {/* Action buttons */}
          <Stack>
            <JarvisButton disableEndIcon sx={{ mb: 1 }} onClick={onSubmit}>
              Apply Filters
            </JarvisButton>
            <JarvisButton disableEndIcon variant="text" onClick={onReset}>
              Reset All Filters
            </JarvisButton>
          </Stack>
        </Stack>
      </Drawer>
    );
  }

  return (
    <Drawer open={open} anchor="right" >
      <Stack justifyContent="space-between" sx={{ flex: 1, px: 2, py: 2, maxWidth: "320px" }}>
        <Stack>
          <SectionTitle
            heading="Filter"
            endContent={() => <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>}
          />

          <Stack sx={{ mb: 2 }}>
            <Text variant="small" fontWeight="medium" sx={{ mb: 1 }}>Portfolio Name</Text>
            <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap' }}>
              {portfolioOptions.map((p, i) => (
                <JarvisChipNew
                  key={`portfolio_${p.value}`}
                  label={p.label}
                  variant="outlined"
                  sx={{ mr: i < portfolioOptions.length - 1, mb: 1 }}
                  color={filterData.portfolioIds.includes(p.value) ? 'primary' : 'default'}
                  onClick={() => onChange({ name: 'portfolioIds', value: p.value })}
                />
              ))}
            </Stack>
          </Stack>

          <Stack sx={{ mb: 2 }}>
            <Text variant="small" fontWeight="medium" sx={{ mb: 1 }}>From Date</Text>
            <DatePicker
              label="From Date"
              disableFuture ={true}
              value={filterData.fromDate}
              onChange={(value) => onChange({ name: "fromDate", value })}
            />
          </Stack>

          <Stack sx={{ mb: 2 }}>
            <Text variant="small" fontWeight="medium" sx={{ mb: 1 }}>To Date</Text>
            <DatePicker
              label="To Date"
              value={filterData.toDate}
              disableFuture ={true}
              onChange={(value) => onChange({ name: "toDate", value })}
            />

          </Stack>

          <Stack sx={{ mb: 2 }}>
            <Text variant="small" fontWeight="medium" sx={{ mb: 1 }}>Call Execution Status</Text>
            <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap' }}>
              {executionStatusOptions.map((e, i) => (
                <JarvisChipNew
                  key={`execution_status_${e.value}`}
                  label={e.label}
                  variant="outlined"
                  sx={{ mr: 1, mb: 1 }}
                  color={filterData.executionStatus === e.value ? 'primary' : e.color}
                  onClick={() => onChange({ name: 'executionStatus', value: e.value })}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <JarvisButton disableEndIcon sx={{ mb: 1 }} onClick={onSubmit}>Apply Filters</JarvisButton>
          <JarvisButton disableEndIcon variant="text" onClick={onReset}>Reset All Filters</JarvisButton>
        </Stack>

      </Stack>
    </Drawer>
  )
}

export default RMSTransactionListFilter;