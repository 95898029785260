/* Default Values

- hasTab = false
- headerDisabled = false

*/
let portfolioPath = "jarvis-portfolio";
let rmsPath = "jarvis-rms";
let onestock = "jarvis-onestock";

export const routesConfig = {

  // Portfolio
  dashboard: {
    path: `/${portfolioPath}/dashboard`,
    header: "Dashboard",
    hasTab: true,
    hasProductTab: true,
    // headerDisabled: false,  // Note: Here reset it based on isOnboardingCompleted key.
  },
  holdings: {
    path: `/${portfolioPath}/existing-holding`,
    header: "Holdings",
    hasTab: true,
    hasProductTab: true,
  },
  actionCenter: {
    path: `/${portfolioPath}/action-centre`,
    header: "Action Centre",
    hasTab: true,
    hasProductTab: true,
  },
  transactions: {
    path: `/${portfolioPath}/transactions`,
    header: "Transactions",
    hasTab: true,
    hasProductTab: true,
  },

  investMore: {                                         // only for portfolio
    path: `/${portfolioPath}/invest-more`,
    header: "Invest More",
  },
  

  // RMS | Protect
  RMSdashboard: {
    path: `/${rmsPath}/dashboard`,
    header: "Dashboard",
    hasTab: true,
    hasProductTab: true,
  },
  RMSholdings: {
    path: `/${rmsPath}/existing-holding`,
    header: "Holdings",
    hasTab: true,
    hasProductTab: true,
  },
  RMSactionCenter: {
    path: `/${rmsPath}/action-centre`,
    header: "Action Centre",
    hasTab: true,
    hasProductTab: true,
  },
  RMStransactions: {
    path: `/${rmsPath}/transactions`,
    header: "Transactions",
    hasTab: true,
    hasProductTab: true,
  },

  // OneStock
  onestockDashboard: {
    path: `/${onestock}/dashboard`,
    header: "Dashboard",
    hasTab: true,
    hasProductTab: true,
  },
  onestockHoldings: {
    path: `/${onestock}/existing-holding`,
    header: "Holdings",
    hasTab: true,
    hasProductTab: true,
  },
  onestockActionCenter: {
    path: `/${onestock}/action-centre`,
    header: "Action Centre",
    hasTab: true,
    hasProductTab: true,
  },
  onestockTransactions: {
    path: `/${onestock}/transactions`,
    header: "Transactions",
    hasTab: true,
    hasProductTab: true,
  },


  // common path
  more: {
    path: `/customer/app-more`,
    header: "More",
    hasTab: false,
  },
  // startSIP: {
  //   path: "/customer/start-sip",
  //   header: "Start SIP",
  //   hasTab: true,
  // },
  morappForceUpdatee: {
    path: `/customer/appForceUpdate`,
    header: "Invest More",
  },

  blogs: {
    path: `/knowledge-centre/blogs`,
    header: "Blogs",
  },
  marketUpdate: {
    path: `/knowledge-centre/market-updates`,
    header: "Market Updates",
  },
  newsArticles: {
    path: `/knowledge-centre/news-articles`,
    header: "News & Articles",
  },
  help: {
    path: `/knowledge-centre/help-support`,
    header: "Help & Support",
  },
  refer: {
    path: `/customer/refer-jarvis`,
    header: "Refer JARVIS",
  },
  profile: {
    path: `/customer/profile`,
    header: "Profile",
  },
  ristAccessment: {
    path: `/onboarding/risk-assessment`,
    header: "Risk profile",
  },
};
