import React from "react";

//comoon components
import Modal from "components/common/Modal";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import IconButton from "components/common/IconButton";
import Table from "components/common/Table";
import Button from "components/JarvisButton";
import Checkbox from "components/common/Checkbox";
import Image from "components/common/Image";
import TextField from "components/common/TextField";
import InputAdornment from "components/common/InputAdornment";

//mui
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

//helper
import { dateTimeHelper } from "helper/dateTime";
import JarvisNote from "components/JarvisNote";

const JarvisRMSActionCenterSell = (props) => {
    const {
        isDialogOpen,
        portfolioData,
        handleMarkSold,
        handleSelectedStockChange,
        selectedPortfolioStockDetails,
        tableRefreshCount,
        handleDataChange,
        finalPortfolioDetails,
        isStockSelectionComplete,
        handleGoBack,
        handleDialogClose,
        handleAllSelectChange,
        isAllStocksSelected,
        handleSelectionSearch,
        stockSearch,
        handleSubmit
    } = props;

    const columns = [
        {
            field: "isSelected",
            headerComponent: () => {
                return (
                    <Checkbox
                        defaultChecked
                        checked={isAllStocksSelected}
                        onChange={(e) => {
                            handleAllSelectChange(e);
                        }}
                        color="primary"
                        size="small"
                    />
                );
            },
            bodyCellComponent: (data, row) => {
                return (
                    <Checkbox
                        checked={data}
                        onChange={(e) =>
                            handleSelectedStockChange(e, row.stockId)
                        }
                        color="primary"
                        size="small"
                    />
                );
            },
        },
        {
            field: "stockName",
            headerText: "Stock Name",
            style: { minWidth: "120px" },
            bodyCellComponent: (data, row) => {
                return <Text variant="small">{data}</Text>;
            },
        },
        {
            field: "suggestedSellPrice",
            headerText: "Price",
            style: { minWidth: "120px" },
            bodyCellComponent: (data, row) => {
                return <Text variant="small">{data}</Text>;
            },
        },
        {
            field: "stockQuantity",
            headerText: "Qty.",
            style: { minWidth: "60px" },
            bodyCellComponent: (data, row) => {
                return <Text variant="small">{data}</Text>;
            },
        },
    ];

    const confimTableColumns = [
        {
            field: "stockName",
            headerText: "Stock Name",
            style: { minWidth: "120px" },
            bodyCellComponent: (data, row) => {
                return <Text variant="small">{data}</Text>;
            },
        },
        {
            field: "stockQuantity",
            headerText: "Qty.",
            style: { minWidth: "60px" },
            bodyCellComponent: (data, row) => {
                return <Text variant="small">{data}</Text>;
            },
        },
        {
            field: "",
            headerText: "Sell Date",
            style: { minWidth: "120px" },
            bodyCellComponent: () => {
                return <Text variant="small">{dateTimeHelper.dateOfBirth(new Date())}</Text>;
            },
        },
        {
            field: "suggestedSellPrice",
            headerText: "Sell Price",
            style: { minWidth: "120px" },
            bodyCellComponent: (data, row) => {
                return <Text variant="small">{data}</Text>;
            },
            editable: true,
        },
    ];
    const selectedStockData = portfolioData?.stockData.filter(s => s.isSelected);

    return (
        <>
            <Modal
                open={isDialogOpen}
                onClose={handleDialogClose}
                size="md"
                disableClose
            >
                {/* Header */}

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text sx={{ whiteSpace: "nowrap", flex: 0.5 }}>
                        <span style={{ fontWeight: "700" }}>Portfolio Name</span> ~ {portfolioData.portfolioName}
                    </Text>
                    {/* <Stack
                        direction="row"
                        justifyContent={
                            isStockSelectionComplete
                                ? "flex-end"
                                : "space-between"
                        }
                        alignItems="center"
                        sx={{ flex: 0.5 }}
                    >
                        {!isStockSelectionComplete && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    // border: `1px solid #E0E7FF`,
                                    backgroundColor: "#f0f0f0",
                                    pl: 2,
                                    borderRadius: 1.25,
                                    maxWidth: 400,
                                }}
                            >
                                <SearchIcon
                                    sx={{ color: "#565656", fontSize: 24 }}
                                />
                                <TextField
                                    name="stockSearch"
                                    label="Search Stock"
                                    value={stockSearch.selectionStock}
                                    onChange={handleSelectionSearch}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment>
                                //             <CloseIcon
                                //                 onClick={(e) => {
                                //                     handleSelectionSearch(
                                //                         e,
                                //                         true
                                //                     );
                                //                 }}
                                //                 sx={{
                                //                     fontSize: 20,
                                //                     cursor: "pointer",
                                //                 }}
                                //             />
                                //         </InputAdornment>
                                //     ),
                                //     classes: {
                                //         root: {
                                //             border: 0,
                                //         },
                                //     },
                                // }}
                                />
                            </Stack>
                        )}

                    </Stack> */}
                    <IconButton onClick={handleDialogClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>

                <JarvisNote description="Selected stocks will be marked sold based on the entered price" />

                {/* Modal Body Table */}
                {
                    stockSearch.selectionPortfolioData.stockData && stockSearch.selectionPortfolioData.stockData.length === 0 ?
                        (<Stack py={2} sx={{ maxHeight: "200px", overflow: "auto" }}>
                            <Text sx={{ ml: 4 }}>Stocks not found !</Text>
                        </Stack>) :
                        (
                            <>
                                <Stack py={2} sx={{ maxHeight: "300px", overflow: "auto" }}>
                                    {isStockSelectionComplete ? (
                                        <Table
                                            columns={confimTableColumns}
                                            data={finalPortfolioDetails.stockData}
                                            onDataChange={handleDataChange}
                                            stickyHeader
                                        />
                                    ) : (
                                        <Table
                                            columns={columns}
                                            data={
                                                stockSearch.selectionStock
                                                    ? stockSearch.selectionPortfolioData.stockData
                                                    : selectedPortfolioStockDetails

                                            }
                                            dataChangedCounter={tableRefreshCount}
                                            tableProps={{ stickyHeader: true }}
                                        />
                                    )}
                                </Stack>
                            </>
                        )
                }


                {/* Action  */}

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    {isStockSelectionComplete ? (
                        <>
                            <Button
                                variant="outlined"
                                disableEndIcon
                                animate={false}
                                onClick={handleGoBack}
                                sx={{ padding: { xs: "10px 16px", md: "10px 30px" }, fontSize: { xs: "14px", md: "15px" } }}
                            >
                                Go Back
                            </Button>
                            <Button
                                disableEndIcon
                                onClick={handleSubmit}
                                sx={{ padding: { xs: "10px 16px", md: "10px 30px" }, fontSize: { xs: "14px", md: "15px" } }}
                            >{`Confirm & save Transaction`}</Button>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="outlined"
                                endIcon={<InfoIcon />}
                                animate={false}
                                disabled={selectedStockData.length ? false : true}
                                onClick={handleMarkSold}
                                sx={{ padding: { xs: "10px 16px", md: "10px 30px" }, fontSize: { xs: "14px", md: "15px" } }}
                            >
                                Mark already sold
                            </Button>
                            {/* <Button disableEndIcon disabled>
                                Sell through Broker
                            </Button> */}
                        </>
                    )}
                </Stack>
            </Modal>
        </>
    );
};

export default JarvisRMSActionCenterSell;
