import { call, put, takeLatest } from "@redux-saga/core/effects";
import { jarvisOnestockOnboardingApis } from "./jarvisOnestockOnboardingApis";
import { jarvisOnestockOnboardingParser } from "./jarvisOnestockOnboardingParser";
import { jarvisOnestockOnboardingConstants } from "./jarvisOnestockOnboardingConstants";

function* getOnboardingDataSaga() {
    try {
        yield put({
            type: jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_REQUEST,
        });

        const res = yield call(jarvisOnestockOnboardingApis.getOnboardingData);
        const payload = jarvisOnestockOnboardingParser.onboardingData(res);

        yield put({
            type: jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_RESPONSE,
            payload: {},
        });
    }
}

export function* registerJarvisOnestockOnboardingSagas() {
    
    yield takeLatest(
        jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_SAGA,
        getOnboardingDataSaga
    );
    
}
