import React, { useState } from 'react'
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisChipNew from 'components/JarvisChipNew';
import { IconButton,Box } from '@mui/material';
import { formatHelper } from 'helper/format';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { dateTimeHelper } from "helper/dateTime";
import Image from "components/common/Image";
import JarvisOnestockTransactionsEditMobModal from './JarvisOnestockTransactionsEditMobModal';
import Modal from 'components/common/Modal';
import SectionTitle from 'components/SectionTitle';

const MobileOnestockTransaction = (props) => {
    const { transactionData,updateTransactionData} = props
    const [isOpenModal, setIsOpenModal] = useState(false)

    const checkEditableField= (transactionData)=>{
        if(transactionData.hasOwnProperty('isTransactionEditable')){
            return transactionData.isTransactionEditable
        }
        return false
    }

    return (
        <>
            <Stack>
                <Stack mb={1} direction="row" justifyContent="space-between">
                    <Stack direction="row" style={{
                        alignItems:"center"
                    }}>
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE" }}>Qty.</span>
                            <span style={{ color: "#303236" }}>{transactionData?.stockQuantity}</span>
                        </Text>
                    </Stack>
                    <Stack direction="row" spacing={1} style={{
                        alignItems:"center",
                    }}>
                        {
                            transactionData?.transactionStatus === "CLOSED" ?
                                <Text variant="caption1" sx={{ color: "#45AC70" }}>Closed</Text>
                                : null
                        }
                        {
                            transactionData?.transactionStatus === "SYSTEM_EXECUTED" ?
                                <Text variant="caption1" sx={{ color: "#D32F2F" }}>System Executed</Text>
                                : null
                        }
                        {
                            transactionData?.transactionStatus === "OPEN" ?
                                <Text variant="caption1" sx={{ color: "#E0953D" }}>Open</Text>
                                : null
                        }
                        {/* {checkEditableField(transactionData) && <IconButton onClick={() => { setIsOpenModal(true) }}>
                            <Box
                                component="img"
                                alt="edit"
                                src="/edit.png"
                            />
                        </IconButton>} */}
                    </Stack>
                </Stack>
                <Stack mb={1} direction="row" justifyContent="space-between">
                    <Text variant="small" sx={{ fontWeight: 500 }}>
                        {transactionData?.stockName}
                    </Text>
                    <Stack direction="row">
                        <Stack direction="row" sx={{ color: ((transactionData?.stockGainLossPercentage) || 0) >= 0 ? "#45AC70" : "#D44C4C" }} >
                            <Text variant="caption1">
                                {((transactionData?.stockGainLossPercentage) || 0) +
                                    "%"}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack mb={1} direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE", marginRight: "3px" }}>Buy Price</span>
                            <span style={{ color: "303236" }}>
                                {formatHelper.currency(
                                    Math.abs(transactionData?.buyPrice)
                                ) || 0}
                            </span>
                        </Text>
                    </Stack>
                    <Stack direction="row">
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE", marginRight: "3px" }}>Sell Price</span>
                            <span style={{ color: "303236" }}>
                                {formatHelper.currency(
                                    Math.abs(transactionData?.sellPrice)
                                ) || 0}
                            </span>
                        </Text>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE", marginRight: "3px" }}>Buy Date</span>
                            <span style={{ color: "303236" }}>
                                {dateTimeHelper.format(transactionData?.buyDate, '$DD/$MM/$YYYY')}
                            </span>
                        </Text>
                    </Stack>
                    <Stack direction="row">
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE", marginRight: "3px" }}>Sell Date</span>
                            <span style={{ color: "303236" }}>
                                {
                                    transactionData?.sellDate ?
                                        dateTimeHelper.format(transactionData?.sellDate, '$DD/$MM/$YYYY')
                                        : null
                                }
                            </span>
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
            <Modal
                open={isOpenModal}
                title={<SectionTitle heading={"Update Stock"} sx={{
                    textAlign:"start",
                    marginTop:"1rem"
                }}/>}
                children={
                    <JarvisOnestockTransactionsEditMobModal
                    transactionData={transactionData}
                    updateTransactionData={updateTransactionData}
                    setIsOpenModal={setIsOpenModal}
                    />
                }
                onClose={() => {
                    setIsOpenModal(!isOpenModal)
                }}
            >
            </Modal>
        </>
    )
}
export default MobileOnestockTransaction;

