import React, { useState } from 'react'
import TextField from 'components/common/TextField'
import {Stack } from '@mui/material'
import JarvisButton from 'components/JarvisButton'
import Text from 'components/common/Text'
const JarvisOnestockTransactionsEditMobModal = (props) => {
    const {
        sellPrice = 0,
        buyPrice = 0,
        stockQuantity = 0,
        transactionStatus,

    } = props.transactionData
    const { updateTransactionData } = props
    const [sellPriceValue, setSellPriceValue] = useState(sellPrice)
    const [buyPriceValue, setBuyPriceValue] = useState(buyPrice)
    const [stockQtyValue, setStockQtyValue] = useState(stockQuantity)
    const [errorMessage, setErrorMessage]= useState({buyPriceErr:'',sellPriceErr:'',stockQuantityErr:''})

    const handleOnChange = (event) => {
        if (event.target.id === "buyPrice") {
            setBuyPriceValue(event.target.value);
        }
        else if (event.target.id === 'sellPrice') {
            setSellPriceValue(event.target.value)
        }
        else if (event.target.id === "stockQuantity") {
            setStockQtyValue(event.target.value.replace(/\D/g,''))
        }
    }
    const DataUpdated = async ([{rowData}])=>{
        const {transactionData}= props
        for(let key of Object.keys(rowData) ){
            if(rowData[key]=== transactionData[key]){
                continue;
            }
            else{
                return true                
            }
        }
        return false;
    }
    const isValidAmount = ()=>{
        const errors={}
        if(transactionStatus === "OPEN"){
            if(Number(buyPriceValue) <= 0 ){
                errors["buyPriceErr"]="Buy Price should be more than 0!"
            }
            if(Number(stockQtyValue)<=0){
                errors['stockQuantityErr']="Quantity Should be more than 0!"
            }            
        }
        else{
            if(Number(sellPriceValue) <=0 ){
                errors['sellPriceErr']= "Sell Price should be more than 0!"
            }
        }
        return errors;
    }
    const handleUpdate = async () => {
        const { transactionData,setIsOpenModal } = props;
        const errors= isValidAmount();
        if(Object.keys(errors).length){
            return setErrorMessage({...errors})
        }
        const updatedData = [
            { rowData: { ...transactionData, buyPrice: buyPriceValue, sellPrice: sellPriceValue, stockQuantity: stockQtyValue } }
        ]
        const isDataUpdated= await DataUpdated(updatedData)
        if(isDataUpdated)
            return updateTransactionData(updatedData,setIsOpenModal)
        return setIsOpenModal(false);
    }

    return (
        <>
            <Stack direction="column" spacing={2} width="100%">
                {transactionStatus === "OPEN" ?
                    (
                        <>
                            <Stack direction="column" spacing={1}>
                                <TextField
                                    label="Enter Buy Price per stock"
                                    id="buyPrice"
                                    type= "number"
                                    onChange={handleOnChange}
                                    value={buyPriceValue}
                                    helperText={errorMessage.buyPriceErr?errorMessage.buyPriceErr : ''}
                                    error={errorMessage.buyPriceErr?true:false}
                                />
                                <Text sx={{
                                    fontSize:"12px",
                                }}>
                                <Text sx={{
                                    fontWeight:"bold",
                                    fontSize:"1em",
                                    display:"inline",
                                }}>
                                    Note
                                </Text>
                                :- Price should not be more than or less than 20% of the current stock price.
                                </Text>
                            </Stack>

                            <Stack direction="column" spacing={2}>
                                <TextField
                                    label="Enter quantity"
                                    type= "number"
                                    id="stockQuantity"
                                    onChange={handleOnChange}
                                    value={stockQtyValue}
                                    helperText={errorMessage.stockQuantityErr?errorMessage.stockQuantityErr : ''}
                                    error={errorMessage.stockQuantityErr?true:false}
                                />
                            </Stack>
                        </>
                    ) :
                    (       <Stack direction={"column"} spacing={1}>
                            <TextField
                                label="Enter Sell Price per stock"
                                id="sellPrice"
                                type= "number"
                                onChange={handleOnChange}
                                value={sellPriceValue}
                                helperText={errorMessage.sellPriceErr?errorMessage.sellPriceErr : ''}
                                error={errorMessage.sellPriceErr?true:false}
                            />
                            <Text sx={{
                                fontSize:"12px",
                            }}>
                                <Text sx={{
                                    fontWeight:"bold",
                                    fontSize:"1em",
                                    display:"inline",
                                }}>
                                    Note
                                </Text>
                                :- Price should not be more than or less than 20% of the current stock price.
                            </Text>
                            </Stack>
                    )
                }
            </Stack>
            <Stack direction='row' spacing={4} margin="10px" justifyContent="end" marginY='1.5rem'>
            <JarvisButton
                disableEndIcon
                sx={{ width: "100%", boxShadow: "none", mt: 3 }}
                onClick={handleUpdate}
            >
                Update
          </JarvisButton>
            </Stack>
        </>
    )
}

export default JarvisOnestockTransactionsEditMobModal
