import { investMoreConstants } from "./investMoreConstants";

const investMoreActions = {};

investMoreActions.getInvestmentStocks = () => {
    return {
        type: investMoreConstants.INVESTMENT_STOCKS_SAGA,
    };
};

export { investMoreActions };
