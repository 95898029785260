import React from "react";
import ReferCard from "components/Refer/ReferCard";
import ReferredUserCard from "components/Refer/ReferredUsersCard";
import Box from "components/common/Box";

class ReferContainer extends React.Component {
    render() {
        return (
            <Box>
                <ReferCard />
                <ReferredUserCard />
            </Box>
        );
    }
}

export default ReferContainer;
