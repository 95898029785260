import React from "react";

import Dialog from "components/common/Dialog";
import Text from "components/common/Text";

import JarvisButton from "components/JarvisButton";
import { intercom } from "library/tracker/intercom";

const DeactiveAccountMessageDialog = (props) => {
    const {
        isOpen,
        email,
        message,
        onClose,
    } = props;

    const handleAskSupportClick = () => {
        intercom.show(`Need support to activate account linked to email ${email}`)
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="xs"
            title={<></>}
            actionComponent={() => (
                <JarvisButton onClick={handleAskSupportClick}>
                    Ask Support
                </JarvisButton>
            )}
        >
            <Text textAlign="center">{message}</Text>
        </Dialog>

    )
}

export default DeactiveAccountMessageDialog;