import React from "react";

import Stack from "components/common/Stack";
import Text from "components/common/Text";

import JarvisCard from "components/JarvisCard";
import DashboardDataCard from "components/Dashboard/DashboardDataCard";

import { formatHelper } from "helper/format";

const OnboardedRMSOverview = (props) => {
    const {
        isRMSPortfolioStatsLoading,
        isRMSCallStatsLoading,
        rmsPortfolioStats,
        rmsCallStats,
        history,
    } = props;
    
    return (
        <JarvisCard sx={{ px: 0, py: 0, height: "auto" }}>
            <Stack
                sx={{ borderBottom: "1px solid #F5F5F5" }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                px={3}
                py={2}
            >
                <Text variant="subtitle2">Jarvis Protect</Text>
                <Text
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        history.push("/jarvis-rms");
                    }}
                >
                    View Details
                </Text>
            </Stack>

            <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                px={2}
            >
                <DashboardDataCard
                    icon={"/svgs/rupee_icon.svg"}
                    loading={isRMSPortfolioStatsLoading}
                    value={
                        formatHelper.currency(
                            Math.abs(rmsPortfolioStats.totalAUM)
                        ) || 0
                    }
                    label="AUM under monitor"
                    color="#000000"
                    sx={{ flexGrow: 1 }}
                />
                <DashboardDataCard
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/monitor.svg"}
                    loading={isRMSPortfolioStatsLoading}
                    value={rmsPortfolioStats.totalStocks}
                    label="Stocks under monitor"
                    color="#000000"
                />
                <DashboardDataCard
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/graph.svg"}
                    loading={isRMSPortfolioStatsLoading}
                    value={rmsCallStats.totalRMSCalls}
                    label="Total sell calls"
                    color="#000000"
                />
                <DashboardDataCard
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/down_trend.svg"}
                    loading={isRMSCallStatsLoading}
                    value={
                        <Stack direction="row" alignItems="center">
                            {formatHelper.currency(
                                Math.abs(
                                    rmsCallStats.totalDownfallSaved
                                )
                            ) || 0}

                            <Text
                                pl={3}
                                sx={{
                                    display: "flex",
                                }}
                            >
                                {(Math.abs(
                                    rmsCallStats.totalDownfallSavedPercentage
                                ) || 0) + "%"}
                            </Text>
                        </Stack>
                    }
                    label="Average downfall Saved"
                    color="#45AC70"
                />

                <DashboardDataCard
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/uptrend.svg"}
                    loading={isRMSCallStatsLoading}
                    value={
                        <Stack direction="row" alignItems="center">
                            {formatHelper.currency(
                                Math.abs(
                                    rmsCallStats.totalOpportunityLost
                                )
                            ) || 0}

                            <Text
                                pl={3}
                                sx={{
                                    display: "flex",
                                }}
                            >
                                {(Math.abs(
                                    rmsCallStats.totalOpportunityLostPercentage
                                ) || 0) + "%"}
                            </Text>
                        </Stack>
                    }
                    label="Opportunity Lost"
                    color="#E0953D"
                />
            </Stack>
        </JarvisCard>
    )
}

export default OnboardedRMSOverview;