import React from "react";
import PageTitle from "components/PageTitle";
import SubscriptionPaymentContainer from "containers/SubscriptionPayment";

const SubscriptionPayment = (props) => {
    return (
        <>
            <PageTitle heading="Subscription Payment" />
            <SubscriptionPaymentContainer {...props} />
        </>
    )
}

export default SubscriptionPayment