import { axios } from "../../helper/axios";
import { customerConstants } from "./customerConstants";

const customerApis = {};
// ***  Auth New APIs End's

// SignUp-step: 1
customerApis.initSignUp = ({
    deviceId,
    deviceType,
    mobileNumber,
    email,
    name
}) => {
    const postData = {
        deviceId,
        deviceType,
        mobileNumber,
        email,
        name
    }
    return axios.post(customerConstants.INIT_SIGNUP_API, postData);
}

// Singup-step: 1.1
customerApis.requestSignupOTP = ({ requestType, requestId }) => {
    return axios.post(customerConstants.RESEND_SIGNUP_OTP_API, {
        requestType,
        requestId
    })
}

// SignUp-step: 2
customerApis.completeSignup = ({
    deviceId,
    deviceType,
    requestId,
    otp,
    latitude,
    longitude,
    signupType
}) => {
    const postData = {
        deviceId,
        deviceType,
        requestId,
        otp,
        latitude,
        longitude,
        signupType
    }
    return axios.post(customerConstants.CONFIRM_SIGNUP_API, postData);
}

// login-step: 1
customerApis.requestLoginOtp = ({
    username,
    loginType
}) => {

    const postData = {
        username,
        loginType
    }
    return axios.post(customerConstants.REQUEST_LOGIN_OTP, postData);
}


// login-step: 2
customerApis.confirmLoginOtp = ({
    username,
    loginType,
    deviceId,
    deviceType,
    latitude,
    longitude,
    otp
}) => {
    const postData = {
        username,
        loginType,
        deviceId,
        deviceType,
        latitude,
        longitude,
        otp
    }
    return axios.post(customerConstants.VERIFY_LOGIN_OTP, postData);
}


// Auth old API's
customerApis.login = ({ deviceId, deviceType, email, loginType, password, latitude, longitude }) => {
    const postData = {
        deviceId,
        deviceType,
        email,
        loginType,
        password,
        latitude,
        longitude
    };

    return axios.post(customerConstants.LOGIN_API, postData);
};

customerApis.socialLogin = ({
    socialId,
    accessToken,
    idToken,
    loginType,
    deviceId,
    deviceType,
}) => {
    const postData = {
        socialId,
        accessToken,
        idToken,
        loginType,
        deviceId,
        deviceType,
    };

    return axios.post(customerConstants.SOCIAL_LOGIN_API, postData);
};

customerApis.requestSocialOtp = ({ mobile, appRegistrationId }) => {
    const postData = { mobile, appRegistrationId };

    return axios.post(customerConstants.SOCIAL_REQUEST_OTP, postData);
};

customerApis.verifySocialOtp = ({
    otp,
    name,
    socialId,
    accessToken,
    idToken,
    appRegistrationId,
    mobile,
    loginType
}) => {
    const postData = { otp, name, socialId, accessToken, idToken, appRegistrationId, mobile, loginType };

    return axios.post(customerConstants.SOCIAL_VERIFY_OTP, postData);
};

customerApis.requestOtp = ({ mobile, email, loginType, latitude, longitude, name }) => {
    const postData = {
        mobile,
        email,
        loginType,
        latitude,
        longitude,
        name,
    };
    return axios.post(customerConstants.REQUEST_OTP_API, postData);
};

customerApis.verifyOtp = ({ appRegistrationId, otp, type, userName }) => {
    const PostData = {
        appRegistrationId,
        otp,
        type,
        userName,
    };
    return axios.post(customerConstants.VERIFY_OTP_API, PostData);
};

customerApis.signUp = ({
    appRegistrationId,
    name,
    password,
    loginType,
    deviceId,
    deviceType,
}) => {
    const postData = {
        appRegistrationId,
        name,
        password,
        loginType,
        deviceId,
        deviceType,
    };
    return axios.post(customerConstants.SIGNUP_API, postData);
};

customerApis.resendOtp = ({ appRegistrationId, type }) => {
    const postData = {
        appRegistrationId,
        type,
    };
    return axios.post(customerConstants.RESEND_OTP_API, postData);
};

customerApis.forgotPassword = ({ userName }) => {
    return axios.post(customerConstants.FORGOT_PASSWORD_API, { userName });
};

customerApis.resetPassword = ({ password, token }) => {
    return axios.post(
        customerConstants.RESET_PASSWORD_API,
        { password },
        {
            headers: {
                Authorization: `${token}`,
            },
        }
    );
};
customerApis.changePassword = ({ currentPassword, newPassword, confirmNewPassword }) => {
    return axios.post(
        customerConstants.CHANGE_PASSWORD_API,
        {
            currentPassword,
            newPassword,
            confirmNewPassword
        },
    );
};

customerApis.forgotPasswordVerifyOtp = ({ otp, userName }) => {
    return axios.post(customerConstants.FORGOT_PASSWORD_VERIFY_OTP_API, {
        otp,
        userName,
    });
};


// Auth APIs End's

customerApis.profile = () => {
    return axios.get(customerConstants.PROFILE_API);
};

customerApis.updateRiskProfile = ({ riskId, riskManuallyChecked }) => {
    const body = {
        riskId,
        riskManuallyChecked,
    };
    return axios.patch(customerConstants.UPDATE_RISK_PROFILE_API, body);
};

customerApis.updateProfile = ({
    image,
    fullName,
    email,
    mobileNo,
    alternateNo,
}) => {
    const body = {
        image,
        fullName,
        // email,
        // mobileNo,
        alternateNo,
    };

    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => formData.append(key, value));

    return axios.post(customerConstants.UPDATE_PROFILE_API, formData);
};

customerApis.updateInvestmenStrategy = ({ investmentStrategy }) => {
    const body = {
        investmentStrategy,
    };
    return axios.patch(customerConstants.UPDATE_INVESTMEN_STRATEGY_API, body);
};

customerApis.getSubscriptionList = () => {
    return axios.get(customerConstants.SUBSCRIPTION_LIST_API);
};

customerApis.getReasonDropdownForCancellationApi = () => {
    return axios.get(customerConstants.REASON_FOR_CANCELLATION_API);
};

customerApis.getRenewalPricingDetails = (newAmount = undefined) => {
    let qParam = newAmount ? `?renewalAmount=${newAmount}` : "";
    return axios.get(customerConstants.RENEWAL_PRICING_DETAILS_API + qParam);
};

// Verify Mobile Number flow
customerApis.mobileRequestOtp = ({ mobile }) => {
    const body = {
        mobile
    }
    return axios.post(customerConstants.MOBILE_REQUEST_OTP, body);
}

customerApis.verifyMobileOtp = (mobileOtp) => {
    const body = {
        otp: mobileOtp
    }
    return axios.post(customerConstants.VERIFY_MOBILE_OTP, body);
}

// Verify email flow
customerApis.emailRequestOtp = ({ email, mobile }) => {
    const body = {
        email,
        mobile
    }
    return axios.post(customerConstants.EMAIL_REQUEST_OTP, body);
}

customerApis.verifyEmailOtp = ({ emailOtp }) => {
    const body = {
        otp: emailOtp
    }
    return axios.post(customerConstants.VERIFY_EMAIL_OTP, body);
}

customerApis.verifyEmailLink = ({ requestedId, verificationCode }) => {
    return axios.post(customerConstants.VERIFY_EMAIL, { requestedId, verificationCode });
}


customerApis.submitCampaignData = ({ email, trackingInfo }) => {
    return axios.post(customerConstants.CAMPAIGN_TRACKING_API, {
        email_id: email,
        tracking_info: trackingInfo,
    });
};

customerApis.campaignSSOLogin = ({ authkey }) => {
    return axios.post(`${customerConstants.CAMPAIGN_SSO_LOGIN_API}/${authkey}`);
};

customerApis.cancelSubscription = ({ platform, reason, customerSubscriptionId }) => {
    return axios.post(`${customerConstants.CANCEL_SUBSCRIPTION_API}`, { platform, reason, customerSubscriptionId })
}

customerApis.deactivateAccount = () => {
    return axios.delete(customerConstants.DEACTIVATE_ACCOUNT_API);
}

customerApis.updateProfileDetails = ({ mobile, email }) => {
    return axios.post(customerConstants.UPDATE_PROFILE_DETAILS, {
        mobile: mobile,
        email: email,
    });
};

customerApis.verifyProfileDetails = ({ mobile, email, otp }) => {
    return axios.post(customerConstants.VERIFY_PROFILE_DETAILS, {
        email: email,
        mobile: mobile,
        otp: otp,
    });
};

// Products Subscription

customerApis.productSubscriptionListApi = () => {
    return axios.get(customerConstants.PRODUCT_SUBSCRIPTION_LIST_API);
};

//NOTE: same API can also return respective product details based on product code. 
//      here all product data is being sored.
customerApis.productSubscriptionDetailsApi = ({ productCode = "" } = {}) => {
    return axios.post(customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_API);
};
customerApis.brokerSSOLoginApi = ({token,broker, isSso=0}={}) => {
    let url = `${customerConstants.SSO_CUSTOMER_DETAILS_API}?token=${token}&broker=${broker}&isSso=${isSso}`
    return axios.get(url);
};



export { customerApis };
