import { axios } from "helper/axios";
import { thirdpartyConstants } from "./thirdpartyConstants";
import { config } from "config";
import { dateTimeHelper } from "helper/dateTime";

const thirdpartyApis = {};

// PAYMENT
thirdpartyApis.initiatePayment = ({
  paymentAmount,
  paymentType,
  additionalInvestmentType,
  sipDate,
  couponCode,
}) => {
  const payload = {
    paymentType,
  };
  if (paymentAmount) {
    payload["paymentAmt"] = paymentAmount;
  }
  if (additionalInvestmentType) {
    payload["additionalInvestmentType"] = additionalInvestmentType;
  }
  if (sipDate) {
    payload["sipDate"] = sipDate;
  }
  if (couponCode) {
    payload["couponCode"] = couponCode;
  }
  return axios.post(thirdpartyConstants.INITIATE_PAYMENT_API, payload);
};

thirdpartyApis.capturePayment = ({
  paymentId,
  orderId,
  paymentSignature,
  rawResponse,
}) => {
  const payload = {
    paymentId,
    orderId,
    rawResponse,
  };
  if (paymentSignature) {
    payload["paymentSignature"] = paymentSignature;
  }
  return axios.post(thirdpartyConstants.CAPTURE_PAYMENT_API, payload);
};
thirdpartyApis.captureOnetimePayment = ({
  paymentId,
  orderId,
  paymentSignature,
  rawResponse,
}) => {
  const payload = {
    paymentId,
    orderId,
    rawResponse,
  };
  if (paymentSignature) {
    payload["paymentSignature"] = paymentSignature;
  }
  return axios.post(thirdpartyConstants.CAPTURE_ONETIME_PAYMENT_API, payload);
};

thirdpartyApis.checkPaymentStatus = ({ orderId }) => {
  const body = {
    orderId: orderId,
  };
  return axios.post(thirdpartyConstants.CHECK_PAYMENT_STATUS_API, body);
};

thirdpartyApis.getSubscriptionPlans = ({ productCode }) => {
  return axios.get(
    `${thirdpartyConstants.PORTFOLIO_SUBSCRIPTION_PLANS_API}?productCode=${productCode}`
  );
};

thirdpartyApis.getTransactionHistoryList = ({
  productCode,
  page,
  fromDate,
  toDate,
  transactionStatus,
  limit,
}) => {
  const params = {
    page,
    limit,
  };

  if (productCode) {
    params["productCode"] = productCode;
  }

  if (fromDate) {
    params["fromDate"] = dateTimeHelper.format(fromDate, "$YYYY-$MM-$DD");
  }

  if (toDate) {
    params["toDate"] = dateTimeHelper.format(toDate, "$YYYY-$MM-$DD");
  }

  if (transactionStatus) {
    params["transactionStatus"] = transactionStatus;
  }

  return axios.get(thirdpartyConstants.TRANSACTION_HISTORY_LIST_API, {
    params,
  });
};

thirdpartyApis.createSubscription = ({ productCode, planId }) => {
  if (productCode === config.productCodes.PORTFOLIO) {
    return axios.post(thirdpartyConstants.CREATE_PORTFOLIO_SUBSCRIPTION_API, {
      planId,
    });
  }
  alert("product code not found");
};

thirdpartyApis.captureSubscription = ({
  productCode,
  customerSubscriptionId,
  planId,
}) => {
  if (productCode === config.productCodes.PORTFOLIO) {
    return axios.post(thirdpartyConstants.CAPTURE_PORTFOLIO_SUBSCRIPTION_API, {
      planId,
      customerSubscriptionId,
    });
  }
  alert("product code not found");
};

thirdpartyApis.checkSubscriptionStatus = ({
  productCode,
  customerSubscriptionId,
}) => {
  if (productCode === config.productCodes.PORTFOLIO) {
    return axios.post(thirdpartyConstants.CHECK_SUBSCRIPTION_STATUS_API, {
      customerSubscriptionId,
    });
  }
  alert("product code not found");
};

thirdpartyApis.brokergenerateAccessToken = ({
  authCode,
  brokerPlatform,
  brokerName,
}) => {
  const body = {
    AuthCode: authCode,
    BrokerPlatform: brokerPlatform,
    BrokerName: brokerName,
  };
  return axios.post(thirdpartyConstants.BROKER_GENERATE_ACCESS_TOKEN, body);
};

// DIGIO
thirdpartyApis.initiateDocumentSigning = () => {
  return axios.post(thirdpartyConstants.INITIATE_DOCUMENT_SIGNING_API);
};

// BROKER
thirdpartyApis.brokerPreOrder = ({
  brokerPlatform,
  selectedBroker,
  type,
  productCode,
  onestockCallId,
  stockQuantity,
}) => {
  if (productCode === config.productCodes.PORTFOLIO || !productCode) {
    return axios.post(thirdpartyConstants.BROKER_PRE_ORDER_API, {
      brokerPlatform,
      selectedBroker,
      type,
    });
  }
  if (productCode === config.productCodes.ONESTOCK) {
    return axios.post(thirdpartyConstants.BROKER_PRE_ORDER_API, {
      brokerPlatform,
      selectedBroker,
      productCode,
      oneStockCallId: onestockCallId,
      stockQuantity,
    });
  }
};

// PRE_LOGIN_API
thirdpartyApis.brokerPreLogin = ({
  brokerPlatform,
  brokerName,
  processType,
  productCode,
  oneStockCallId,
  stockQuantity,
}) => {
  return axios.post(thirdpartyConstants.BROKER_PRE_LOGIN_API, {
    brokerPlatform,
    selectedBroker: brokerName,
    type: processType,
    productCode,
    oneStockCallId,
    stockQuantity,
  });
};

thirdpartyApis.brokerTransactionStatus = ({
  customerId,
  transactionId,
  stocks,
  productCode,
}) => {
  if (productCode === config.productCodes.PORTFOLIO) {
    return axios.post(thirdpartyConstants.BROKER_TRANSACTION_STATUS_API, {
      customerId,
      transactionId,
      stocks,
    });
  }
  if (productCode === config.productCodes.ONESTOCK) {
    return axios.post(thirdpartyConstants.BROKER_TRANSACTION_STATUS_API, {
      customerId,
      transactionId,
      stocks,
      productCode,
    });
  }
};

thirdpartyApis.brokerOdinTransactionStatus = ({
  customerId,
  transactionId,
  stocks,
  productCode,
}) => {
  if (productCode === config.productCodes.PORTFOLIO) {
    return axios.post(thirdpartyConstants.BROKER_TRANSACTION_STATUS_ODIN_API, {
      customerId,
      transactionId,
      stocks,
    });
  }
  if (productCode === config.productCodes.ONESTOCK) {
    return axios.post(thirdpartyConstants.BROKER_TRANSACTION_STATUS_ODIN_API, {
      customerId,
      transactionId,
      productCode,
    });
  }
};

// BROKER -> NON SDK and NON Webhook
thirdpartyApis.brokerLogin = ({
  brokerPlatform,
  username,
  password,
  brokerName,
  verificationCode = null,
  productCode,
  brokerId,
  otp
}) => {
  if (
    productCode === config.productCodes.PORTFOLIO ||
    productCode === config.productCodes.ONESTOCK
  ) {
    return axios.post(thirdpartyConstants.BROKER_LOGIN_API, {
      brokerPlatform,
      clientId: username,
      password,
      brokerName,
      verificationCode,
      brokerId,
      otp
    });
  }
  if (productCode === config.productCodes.RMS) {
    return axios.post(thirdpartyConstants.BROKER_HOLDING_LOGIN_API, {
      username,
      password,
      securityCode: verificationCode,
      brokerId,
      otp
    });
  }
  return Promise.reject({ message: `Invalid ${productCode} provided` });
};

thirdpartyApis.brokerNewAccessToken = ({
  brokerName,
  brokerPlatform,
  ...securityQuestionAnswers
}) => {
  return axios.post(thirdpartyConstants.BROKER_NEW_ACCESS_TOKEN_API, {
    brokerName,
    brokerPlatform,
    ...securityQuestionAnswers,
  });
};

thirdpartyApis.brokerPreviousAccessToken = ({
  brokerPlatform,
  productCode,
  brokerId,
}) => {
  if (
    productCode === config.productCodes.PORTFOLIO ||
    productCode === config.productCodes.ONESTOCK
  ) {
    return axios.post(thirdpartyConstants.BROKER_PREVIOUS_ACCESS_TOKEN_API, {
      brokerPlatform,
    });
  }
  if (productCode === config.productCodes.RMS) {
    return axios.post(
      thirdpartyConstants.BROKER_HOLDING_VALIDATE_ACCESS_TOKEN_API,
      {
        brokerId,
      }
    );
  }
  return Promise.reject({ message: `Invalid ${productCode} provided` });
};

thirdpartyApis.brokerPlaceOrder = ({
  brokerName,
  brokerPlatform,
  type,
  brokerId,
  oneStockCallId,
  productCode,
}) => {
  if (productCode === config.productCodes.ONESTOCK) {
    return axios.post(thirdpartyConstants.BROKER_PLACE_ORDER_API, {
      brokerPlatform,
      selectedBroker: brokerName,
      brokerId,
      productCode,
      oneStockCallId,
    });
  }
  return axios.post(thirdpartyConstants.BROKER_PLACE_ORDER_API, {
    brokerName,
    brokerPlatform,
    type,
    brokerId,
  });
};

thirdpartyApis.brokerCheckOrderStatus = ({
  brokerPlatform,
  brokerName,
  type,
  stocks,
  brokerId,
  productCode,
  oneStockCallId,
}) => {
  if (
    productCode === config.productCodes.ONESTOCK
  ) {
    return axios.post(thirdpartyConstants.BROKER_CHECK_ORDER_STATUS_API, {
      brokerPlatform,
      selectedBroker: brokerName,
      brokerId,
      productCode,
      oneStockCallId,
    });
  }
  return axios.post(thirdpartyConstants.BROKER_CHECK_ORDER_STATUS_API, {
    brokerPlatform,
    brokerName,
    type,
    stocks,
    brokerId,
  });
};

thirdpartyApis.marketOpenHRStatus = () => {
  return axios.get(thirdpartyConstants.BROKER_OPEN_HR_STATUS_API);
};

thirdpartyApis.fetchHolding = ({
  brokerName,
  brokerPlatform,
  productCode,
  brokerId,
  clientId,
  odinFetchHoldingToken
}) => {
  if(productCode === config.productCodes.RMS && brokerPlatform === config.broker.platform.odin){
    return axios.post(thirdpartyConstants.BROKER_FETCH_HOLDING_API,{
      brokerId,
      productCode,
      holdingToken: odinFetchHoldingToken
    })
  }
  return axios.post(thirdpartyConstants.BROKER_FETCH_HOLDING_API, {
    selectedBroker: brokerName,
    brokerPlatform,
    productCode,
    brokerId,
    clientId,
  });
};

thirdpartyApis.checkSmallCaseHoldingTransactinStatus = ({ transactionId }) => {
  return axios.post(
    thirdpartyConstants.BROKER_FETCH_HOLDING_CHECK_TRANSACTION_STATUS_API,
    { transactionId }
  );
};

thirdpartyApis.brokerSelected = ({ selectedBroker }) => {
  return axios.post(thirdpartyConstants.BROKER_SELECTED_API, {
    selectedBroker,
  });
};

thirdpartyApis.initiateRecurringPayment = ({
  planId,
  offerId = "",
  paymentMethod,
  paymentMethodOption,
  paymentMethodDetails = {},
  productCode,
  offerCode,
  offerType,
}) => {
  const body = {
    planId,
    paymentMethod,
    productCode,
  };
  if (offerId) {
    body["offerId"] = offerId;
  }
  if (offerCode) {
    body["offerCode"] = offerCode;
  }
  if (offerType) {
    body["offerType"] = offerType;
  }
  if (paymentMethodDetails) {
    body["paymentMethodDetails"] = paymentMethodDetails;
  }
  if (paymentMethodOption) {
    body["paymentMethodOption"] = paymentMethodOption;
  }
  return axios.post(thirdpartyConstants.INITIATE_RECURRING_PAYMENT_API, body);
};
thirdpartyApis.initiateOneTimePayment = ({
  planId,
  offerId = "",
  paymentMethod,
  paymentMethodOption,
  paymentMethodDetails = {},
  productCode,
  offerCode,
  offerType,
}) => {
  const body = {
    planId,
    paymentMethod,
    productCode,
  };
  if (offerId) {
    body["offerId"] = offerId;
  }
  if (offerCode) {
    body["offerCode"] = offerCode;
  }
  if (offerType) {
    body["offerType"] = offerType;
  }
  if (paymentMethodDetails) {
    body["paymentMethodDetails"] = paymentMethodDetails;
  }
  if (paymentMethodOption) {
    body["paymentMethodOption"] = paymentMethodOption;
  }
  return axios.post(thirdpartyConstants.INITIATE_ONE_TIME_PAYMENT_API, body);
};

thirdpartyApis.captureRecurringPayment = ({
  paymentId,
  orderId,
  paymentSignature,
}) => {
  return axios.post(thirdpartyConstants.CAPTURE_RECURRING_PAYMENT_API, {
    paymentId,
    orderId,
    paymentSignature,
  });
};

thirdpartyApis.checkRecurringPaymentStatus = ({
  orderId,
  customerSubscriptionId,
}) => {
  return axios.post(thirdpartyConstants.CHECK_RECURRING_PAYMENT_STATUS_API, {
    orderId,
    customerSubscriptionId,
  });
};
thirdpartyApis.checkOnetimePaymentStatus = ({
  orderId,
  customerSubscriptionId,
}) => {
  return axios.post(thirdpartyConstants.CHECK_ONETIME_PAYMENT_STATUS_API, {
    orderId,
    customerSubscriptionId,
  });
};

thirdpartyApis.brokerHoldingPreLogin = ({ brokerId }) => {
  return axios.post(thirdpartyConstants.BROKER_HOLDING_PRELOGIN_API, {
    brokerId,
  });
};

thirdpartyApis.brokerGenerateAccessToken = ({ authCode, brokerId }) => {
  return axios.post(thirdpartyConstants.BROKER_HOLDING_LOGIN_API, {
    authCode,
    brokerId,
  });
};

export { thirdpartyApis };
