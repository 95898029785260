import React from "react";

import Stack from "components/common/Stack";
import Image from "components/common/Image";
import Text from "components/common/Text";

const ChooseBrokerIcon = (props) => {
    const { image = '', title = '', fontSize=0.875, isSelected = false, onClick = () => null,  height =50 } = props;
    return (
        <Stack
            alignItems="center"
            spacing={0.5}
            sx={{
                flex: 1,
                py: 4,
                px: 1,
                cursor: "pointer",
                borderRadius: 1,
                borderWidth: 1,
                borderStyle: "solid",
                transition: 'border 0.25s ease-out',
                borderColor: isSelected ? 'primary.main' : 'grey.200',
            }}
            onClick={onClick}
        >
            <Image
                src={image}
                height={height}
                width="auto"
                objectFit="contain"
                imgStyle={{height:"auto", maxHeight: "100%", maxWidth: "100%"}}
            />
            <Text
                sx={{textAlign : 'center', whiteSpace:"nowrap", textOverflow:'ellipsis', overflow:'hidden' ,fontSize:`${fontSize}rem`, transition: 'color 0.25s ease-out', color: isSelected ? "primary.main" : "#1E1E1E" }}
            >{title}</Text>
        </Stack>
    )
}

export default ChooseBrokerIcon;