import React, { Component } from "react";
import Grid from "components/common/Grid";
import Box from "components/common/Box";
import Text from "components/common/Text";
import ProductListCard from "components/ProductListCard";
import AuthAsidePanel from "components/Auth/AuthAsidePanel";
import { withRouter } from "react-router-dom";

const productDetails = [
    {
        productName: "JARVIS Portfolio",
        productDesc: [
            "All analytics features",
            "Up to 250,000 tracked visits",
            "Normal support",
        ],
        route: "/jarvis-portfolio",
    },
    {
        productName: "JARVIS Protect",
        productDesc: [
            "All analytics features",
            "Up to 250,000 tracked visits",
            "Normal support",
        ],
        route: "/jarvis-rms",
    },
    {
        productName: "One Stock",
        productDesc: [
            "All analytics features",
            "Up to 250,000 tracked visits",
            "Normal support",
        ],
        route: "/jarvis-onestock",
    },
];
class ProductListContainer extends Component {
    handleNavigation = (route) => {
        this.props.history.replace(route);
    };

    render() {
        return (
            <>
                <Grid container>
                    <Grid
                        item
                        md={6.5}
                        lg={6.5}
                        sx={{ display: { xs: "none", md: "block" } }}
                    >
                        <AuthAsidePanel />
                    </Grid>
                    <Grid item md={5.5} lg={5.5} sx={{ flex: 1 }}>
                        <Box
                            sx={{
                                minHeight: "100vh",
                                px: 6,
                                pt: 8,
                            }}
                        >
                            <Box mb={6}>
                                <Text variant="subtitle1">JARVIS Product</Text>
                                <Text variant="body2" color="#808591">
                                    Choose any of the JARVIS Product to proceed
                                </Text>
                            </Box>
                            {productDetails.map((item,index) => (
                                <React.Fragment key={`product_card_${index}`}>
                                    <ProductListCard
                                        details={item}
                                        handleNavigation={this.handleNavigation}
                                    />
                                </React.Fragment>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </>
        );
    }
}
export default withRouter(ProductListContainer);
