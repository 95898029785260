import React from "react";
import _get from "lodash.get";

import Text from "components/common/Text";
import Loader from "components/common/Loader";

import { config } from "config";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";

//tracker
import { tracker } from "library/tracker";

import { localStore } from "stores/localStorage";
let inAppBrowserRef;
class BrokerOdinContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        const brokerName = this.props.getValueFromLocation("brokerName");
        const brokerPlatform = this.props.getValueFromLocation("brokerPlatform");
        const processType = this.props.getValueFromLocation("processType");
        const status = this.props.getValueFromLocation("status");
        const transactionId = this.props.getValueFromLocation("transactionId");
        const productCode = this.props.getValueFromLocation("productCode");
        const brokerId = this.props.getValueFromLocation("brokerId");
        const oneStockCallId = this.props.getValueFromLocation('onestockCallId');
        const stockQuantity = this.props.getValueFromLocation('stockQuantity');
        if (status == 0) {
            //NOTE : event_tracker => broker
            localStore.setWentBrokerPage(false);
            tracker.broker.brokerLoginFailed({broker_name:brokerName, error_msg:""});
            this.props.onError({
                reason: "Something went wrong! Please try again later!",
                origin: "compomentDidMount > Status fail",
            });
            return;
        }
        if (status == 1) {
            localStore.setWentBrokerPage(false);
            if(productCode=== config.productCodes.RMS){
                const odinFetchHoldingToken =this.props.getValueFromLocation('token');
                this.props.fetchHoldingData({brokerName,brokerPlatform,productCode,brokerId, odinFetchHoldingToken})
                return;
            }
            else{
            this.props.onSuccess({ platformResponse: {}, transactionId });
            }
            //NOTE : event_tracker => broker
            tracker.broker.brokerLoginSuccess({broker_name:brokerName});
            return;
        }
        
        if (brokerName || brokerPlatform || (productCode === config.productCodes.PORTFOLIO && processType) || brokerPlatform !== config.broker.platform.smallcase) {
            let fromBrokerPage = localStore.getWentBrokerPage();
            if(fromBrokerPage){
                this.setState({ isLoading: false });
                localStore.setWentBrokerPage(false);
                this.props.onCancel();
            }
            else{
                this.initProcess({ brokerName, brokerPlatform, processType, productCode, brokerId,oneStockCallId,stockQuantity });
            }
            
        } else {
            this.props.onError({ reason: 'No Broker selected', origin: "componentDidMount > not found = brokerName | brokerPlatform | processType" });
        }
    }

    async componentWillUnmount() {

        if (inAppBrowserRef) {
            inAppBrowserRef.removeEventListener("beforeload", this.handleInAppBrowser);
            inAppBrowserRef.removeEventListener("loaderror", this.handleInAppBrowser);
            inAppBrowserRef.removeEventListener("exit", this.handleInAppBrowser);
        }
    }

    handleInAppBrowser = async (eventType, e) => {
        
        const inAppClose = (event) => {
            if (Object.keys(event).includes("url")) {
                localStore.setWentBrokerPage(false);
            }
            let fromBrokerPage = localStore.getWentBrokerPage();
            if (event && event.type === "exit" && fromBrokerPage) {
                this.props.onError({
                    reason: "You have close the broker transaction!",
                    origin: "compomentDidMount > Status fail",
                });
            } else {
                inAppBrowserRef.close();
                if(!event || event.type == "loaderror"){
                    this.props.onError({
                        reason: "Something went wrong! Please try again later!",
                        origin: "compomentDidMount > Status fail",
                    });
                }
            }
        }

        switch (eventType) {
            case "loaderror":
                inAppClose(e);
                break;
            case "exit":
                inAppClose(e);
                break;
            }
            
        if (inAppBrowserRef == undefined) {
            inAppClose(e);
        }
        
        if (e && Object.keys(e).includes("url")) {
            let urlParam = new URLSearchParams(e.url.slice(e.url.indexOf('?') + 1));
            let status = urlParam.get("status");
            let transactionId = urlParam.get("transactionId");
            const brokerName = this.props.getValueFromLocation("brokerName");
            const brokerPlatform = this.props.getValueFromLocation("brokerPlatform");
            const productCode = this.props.getValueFromLocation("productCode");
            const brokerId = this.props.getValueFromLocation("brokerId");

            if (status) {
                inAppClose(e);
                if(productCode === config.productCodes.RMS){
                    const odinFetchHoldingToken = urlParam.get('token')
                    this.props.fetchHoldingData(brokerId,brokerPlatform,productCode,brokerName,odinFetchHoldingToken)
                    return;
                    
                }
                else{
                this.props.onSuccess({ platformResponse: {}, transactionId });
                }
            }
        }
        

    }

    

    initProcess = async ({ brokerName, brokerPlatform, processType, productCode, brokerId,oneStockCallId,stockQuantity }) => {
        // Odin Flow
        // 1) prelogin API hit & get redirect url
        // 2) Redirect to URL 
        // 3) Return back: and check With Status API every 5sec 

        try {
            this.setState({ isLoading: true });
            const preLogin = await thirdpartyApis.brokerPreLogin({ brokerName, brokerPlatform, processType, productCode,oneStockCallId,stockQuantity });
            const brokerLoginUrl = _get(preLogin.response, "broker_login_page_url", "");
            const transactionId = _get(preLogin.response, "transaction_id", "");
            const callBackUrl = `${window.location.origin}/customer/broker`;
            if (!transactionId) {
                this.props.onError({
                    reason: 'Transaction id not found',
                    origin: "initProcess > Transaction id not found"
                });
                return;
            }

            const redirectTo = `${config.broker.odinBrokerRedirectUrl}?brokerName=${brokerName}&brokerPlatform=${brokerPlatform}&processType=${processType}&transactionId=${transactionId}&callBackUrl=${callBackUrl}&brokerLoginUrl=${encodeURIComponent(brokerLoginUrl)}&brokerId=${brokerId}&productCode=${productCode}`;
            console.log("odinBrokerRedirectUrl => ", redirectTo);
            localStore.setWentBrokerPage(true);
            if (config.isMobileApp) {
                const option = `location=no,closebuttoncaption=Close,closebuttoncolor=#ffffff,footercolor=#2B70C5,zoom=no`;
                inAppBrowserRef = window.cordova.InAppBrowser.open(redirectTo, '_blank', option);
                inAppBrowserRef.addEventListener("beforeload", (e) => this.handleInAppBrowser('beforeload', e));
                inAppBrowserRef.addEventListener("loaderror", (e) => this.handleInAppBrowser('loaderror', e));
                inAppBrowserRef.addEventListener("exit", (e) => this.handleInAppBrowser('exit', e));
            } else {
                window.location.href = redirectTo;
            }

        } catch (e) {
            this.props.onError({
                reason: 'Odin redirect URL not found.',
                origin: "initProcess > Odin redirect URL not found."
            });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { isLoading } = this.state;
        const brokerName = this.props.getValueFromLocation("brokerName");

        return (
            <>
                <Loader loading={isLoading} />
                <Text>{brokerName ? `Selected broker: ${brokerName.toUpperCase()}` : `No Broker Selected`}</Text>
            </>
        )
    }
}

export default React.memo(BrokerOdinContainer);