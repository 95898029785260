import { all } from "redux-saga/effects";
import { registerCustomerSagas } from "./customer/customerSagas";
import { registerCommonSagas } from "./common/commonSagas";
import { registerDashboardSagas } from "./dashboard/dashboardSagas";
import { registerhelpSupportSaga } from "./helpSupport/helpSupportSagas";
import { registerOnboardingSagas } from "./onboarding/onboardingSagas";
import { registerInvestMoreSagas } from "./investMore/investMoreSagas";
import { registerTransactionListSaga } from "./transactions/transactionSagas";
import { registerRMSSaga } from "./rms/rmsSagas";
import { registerMasterSagas } from "./master/masterSagas";
import { registerThirdpartySagas } from "./thirdparty/thirdpartySagas";
import { registerPortfolioHoldingSaga } from "./portfolioHolding/portfolioHoldingSagas";

// product's
import { registerJarvisRMSRootSaga } from "./jarvisRMS/jarvisRMSRootSaga";
import { registerJarvisOnestockRootSaga } from "./jarvisOnestock/jarvisOnestockRootSaga";

function* rootSaga() {
        yield all([
                registerCustomerSagas(),
                registerCommonSagas(),
                registerOnboardingSagas(),
                registerDashboardSagas(),

                registerTransactionListSaga(),
                registerPortfolioHoldingSaga(),
                registerRMSSaga(),
                registerInvestMoreSagas(),
                registerhelpSupportSaga(),
                registerThirdpartySagas(),
                registerTransactionListSaga(),

                registerTransactionListSaga(),
                registerRMSSaga(),
                registerInvestMoreSagas(),
                registerhelpSupportSaga(),

                registerMasterSagas(),

                // product's
                registerJarvisRMSRootSaga(),
                registerJarvisOnestockRootSaga(),
        ]);
}

export default rootSaga;
