import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";

import { CustomerRoute, OnboardingRoute } from "helper/route";
import LayoutContainer from "containers/Layout";
import RMSOnboardingNavigator from "navigator/RMSOnboardingNavigator";

// pages
import ExistingHolding from "pages/JarvisRMS/RMSExistingHolding";
import RMSAddPortfolio from "containers/JarvisRMS/RMSAddPortfolio";
import Dashboard from "pages/JarvisRMS/RMSDashboard";
import RMSActionCenter from "pages/JarvisRMS/RMSActionCenter";
import RMSTransactions from "pages/JarvisRMS/RMSTransactions";
import RMSTransactionDetail from "pages/JarvisRMS/RMSTransactionDetail";

const JarvisRMSNavigator = (props) => {
    const { match } = props;
    const isAuthenticated = useSelector((state) => state.customer.isAuthenticated);
    const isPortfolioOnboardingCompleted = useSelector((state) => state.onboarding.isOnboardingCompleted);
    const isRMSonboardingCompleted = useSelector((state) => state.jarvisRMS.rmsOnboarding.isOnboardingCompleted);
    const isOnestockonboardingCompleted = useSelector((state) => state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted);

    const anyOnboardingCompleted = (isPortfolioOnboardingCompleted || isRMSonboardingCompleted || isOnestockonboardingCompleted);


    const redirectTo = ()=>{
        let restPath = "dashboard";
        if(!isRMSonboardingCompleted){
            restPath = "onboarding";
        }
        return `${match.path}/${restPath}`;
    }
    

    return (
        <Switch>
            {/* 1 - Redirection to */}
            <Redirect exact from={match.path} to={redirectTo()} />

            {/* 2 -  Child Navigator */}
            <OnboardingRoute
                isAuthenticated={isAuthenticated}
                isOnboardingCompleted={isRMSonboardingCompleted}
                redirectOnboardingIncomplete={`${match.path}/dashboard`}
                path={`${match.path}/onboarding`}
                component={RMSOnboardingNavigator}
            />

            {/* 3 - With layout pages */}
            <LayoutContainer {...props}>
                <Switch>
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/dashboard`}
                        component={Dashboard}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isRMSonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/action-centre`}
                        component={RMSActionCenter}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isRMSonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/transactions`}
                        component={RMSTransactions}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isRMSonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/transactions/:id`}
                        component={RMSTransactionDetail}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isRMSonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/existing-holding/add-portfolio`}
                        component={RMSAddPortfolio}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isRMSonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/existing-holding/:portfolioId`}
                        component={ExistingHolding}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isRMSonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/existing-holding`}
                        component={ExistingHolding}
                    />
                </Switch>
            </LayoutContainer>
            <Redirect to={`not-found`} />
        </Switch>
    );
};

export default JarvisRMSNavigator;