import React from "react";
// import SocialLogin from "react-social-login";
import { OldSocialLogin as WebSocialLogin } from "react-social-login";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from "@capacitor-community/facebook-login";

import Button from "components/common/ButtonBase";
import Text from "components/common/Text";
import MobileResponsive from "components/common/MobileResponsive";

// appsFlayer
import { tracker } from "library/tracker";

const SocialButtonUI = (props) => { 

	const { triggerLogin, title, socialType, handleSocialClick, appId, onLoginSuccess, onLoginFailure, ...restProps } =
	props;

	
	return (
		<Button
			variant="outlined"
			sx={{
				borderColor: "#BED0DE",
				color: "#222B3E",
				width: "155px !important",
				maxWidth: "100%",
				m: "5px",
				backgroundColor: "#fff !important",
				"&:hover": {
					backgroundColor: "#fff",
				},
				borderRadius: "10px",
				"@media(max-width: 350px)": {
					width: "140px",
				},
				height: "50px"
			}}
			onClick={(e) => {
				handleSocialClick(triggerLogin, socialType);
			}}
			{...restProps}>
				<Text variant="body2" color="#222B3E" sx={{lineHeight: 1}}>
					<Text sx={{fontSize: "9px", textAlign: "left"}}>Login with</Text>
					{title}
				</Text>
		</Button>
	);
}

class SocialLogin extends React.Component {

	componentDidMount() {
		
	}

	insideWebSocialCallback(e) {
		if(e) {
			const user = {
				provider: e._provider,
				socialId: e._profile.id,
				accessToken: e._token.accessToken,
				name: e._profile.name,
				email: e._profile.email,
			}
			this.props.onLoginSuccess(user)
		}else {
			this.props.onLoginFailure(e)
		}
	}
	
	async insideAppSocialClick(triggerLogin, socialTypee) {
		const { socialType } = this.props;
		
		if(socialType === "facebook") {
			// NOTE: event_tracker => Facebook 
			// tracker.facebookLogin.facebookLogin();
			
			try{
				const FACEBOOK_PERMISSIONS = ['email'];
				const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
				console.log("FB__ result ->", result);
				if(result && result.accessToken && result.accessToken.userId) {
					const profile = await FacebookLogin.getProfile({ fields: ['email', 'name'] });
					console.log("FB__profile -> ", profile);
		
					const user = {
						provider: socialType,
						socialId: result.accessToken.userId,
						accessToken: result.accessToken.token,
						name: profile.name,
						email: profile.email,
					}
					this.props.onLoginSuccess(user)
					// NOTE: event_tracker => Facebook 
					// tracker.facebookLogin.facebookLoginSuccess();
				} else {
					this.props.onLoginFailure("");
					// NOTE: event_tracker => Facebook 
					// tracker.facebookLogin.facebookLoginFailed({error_msg: ""});
				}

			}catch(e){
				this.props.onLoginFailure(e);
				console.log("FB Login error => ", e);
			}finally{
				const result2 =	await FacebookLogin.logout();
				console.log("FB__Logout -> ", result2);
			}

			
		// Google
		} else {
			// NOTE: event_tracker => google 
			tracker.googleLogin.googleLogin();
			const result = await GoogleAuth.signIn();
			const result2 = await GoogleAuth.signOut();
			console.log("google response => ", result);
			if(result && result.id) {
				const user = {
					provider: socialType,
					socialId: result.id,
					idToken: result.authentication.idToken,
					name: result.displayName,
					email: result.email,
				}
				this.props.onLoginSuccess(user)
				// NOTE: event_tracker => google 
				tracker.googleLogin.googleLoginSuccess();
			} else {
				// this.props.onLoginFailure(e)
				this.props.onLoginFailure("")

				// NOTE: event_tracker => google 
				// tracker.googleLogin.googleLoginFailed({error_msg: e.message});
				tracker.googleLogin.googleLoginFailed({error_msg: ""});
			}

		}
	}

	render() {

		return (<MobileResponsive 
			web={() => {
				return (
				<WebSocialLogin 
					provider={this.props.socialType}
					appId={this.props.appId}
					callback={this.insideWebSocialCallback.bind(this)} >
					<SocialButtonUI {...this.props} />
				</WebSocialLogin>
				)
			}} 
			app={() => (<SocialButtonUI {...this.props} handleSocialClick={(triggerLogin, socialType) => this.insideAppSocialClick(triggerLogin, socialType)} />)}
		/>);
	}
}

export default SocialLogin;
