import React from "react";
import { withRouter } from "react-router-dom";

import Stack from "components/common/Stack";
import CKYCauto from "components/Onboarding/CKYCauto";
import CKYCmanual from "components/Onboarding/CKYCmanual";

// import MenuItem from "components/common/MenuItem";

import { validationHelper } from "helper/validation";
import JarvisButton from "components/JarvisButton";
import { dateTimeHelper } from "helper/dateTime";
import { cameraHelper } from "helper/camera";
import { tracker } from "library/tracker";

import { onboardingApis } from "stores/onboarding/onboardingApis";
import { localStore } from "stores/localStorage";
import { config } from "config";

class CKYCContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        panNumber: "",
        aadharNumber: "",
        dateOfBirth: "",
        inputIdType: "C",
      },
      manageForm: {
        docType: "",
        docVerify: false,
      },
      formError: {
        aadharNumber: "",
        panNumber: "",
        dateOfBirth: "",
      },

      isImagesUploaded: {
        panCard: false,
        aadharCardFront: false,
        aadharCardBack: false,
      },

      imagesUploadingLoader: {
        panCard: false,
        aadharCardFront: false,
        aadharCardBack: false,
      },

      isVerified: false,
      isLoading: false,

      enableManually: false,
      hasFirstAttempError: false,
    };
  }

  componentDidMount() {
    if(localStore.isSsoCustomer() && localStore.getSsoCustomerDetails()?.brokerName === config.broker.ssoPlatform.centrum){
      this.props.history.replace({
          pathname: '/customer/document-signing',
          state: {
              returnURI: "/jarvis-portfolio/dashboard",
              callOrigin: "JARVIS_PORTFOLIO",
              productCode: config.productCodes.PORTFOLIO
          },
      });
  }
    const isSsoCustomer = localStore.isSsoCustomer();
    const { currentStage, pan, dateOfBirth} = this.props;
    if(isSsoCustomer && currentStage === 3){
      const {formData}= this.state
      formData.panNumber=pan;
      formData.dateOfBirth = dateOfBirth;
      this.setState({ formData /*isVerified: true*/ });
      
  }
    if (currentStage === 4) {
      // NOTE: Temp have to remove
      this.setState({
        formData: {
          panNumber: pan,
          dateOfBirth: dateOfBirth,
        },
        isVerified: true,
      });
    }
  }

  toggleKYCtype = (val) => {
    this.setState({ enableManually: val });
  };

  handleChange = ({ name, value }) => {
    const { formData, manageForm } = this.state;
    if (name === "docType") {
      manageForm[name] = value;
    } else {
      formData[name] = value;
    }
    this.setState({ formData, manageForm });
  };

  handleValidation = () => {
    const { formError, formData } = this.state;

    const panValidation = validationHelper.pan(formData.panNumber);
    formError.panNumber = panValidation.message;

    const dobValidation = validationHelper.dateOfBirth(formData.dateOfBirth);
    formError.dateOfBirth = dobValidation.message;

    this.setState({ formError });
    return panValidation.isValid && dobValidation.isValid;
  };

  //Verification Click
  handleVerification = async () => {
    if (!this.handleValidation()) {
      return;
    }

    const { formData } = this.state;
    this.setState({ isLoading: true });

    try {
      //event-tracker: Initial -> submit_ckyc
      tracker.initial.submitCkyc();
      const res = await onboardingApis.verifyKYCData({
        customerId: this.props.loginProfile.customerId,
        dateOfBirth: dateTimeHelper.dateOfBirth(formData.dateOfBirth),
        inputIdType: formData.inputIdType,
        inputIdNumber: formData.panNumber,
      });

      if (res && res.isVerified) {
        this.setState({ isVerified: true }, () => {
          this.props.getOnboardingDataAction();
        });
        //event-tracker: Initial -> submit_ckyc_success
        tracker.initial.submitCkycSuccess();

        tracker.onboarding.ckyc(this.props.loginProfile.mobile);
      } else {
        this.setState({ hasFirstAttempError: true });
      }
      this.setImagesUploaded("panCard", res.panCardUploaded);
      this.setImagesUploaded("aadharCardFront", res.aadharCardFrontUploaded);
      this.setImagesUploaded("aadharCardBack", res.aadharCardBackUploaded);

    } catch (e) {
      //event-tracker: Initial -> submit_ckyc_error
      tracker.initial.submitCkycError({ error_msg: e.message });
      this.setState({ hasFirstAttempError: true });
      this.setImagesUploaded("panCard", e.panCardUploaded);
      this.setImagesUploaded("aadharCardFront", e.aadharCardFrontUploaded);
      this.setImagesUploaded("aadharCardBack", e.aadharCardBackUploaded);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleValidationReVerification = () => {
    const { formError, formData } = this.state;

    const panValidation = validationHelper.pan(formData.panNumber);
    formError.panNumber = panValidation.message;

    const dobValidation = validationHelper.dateOfBirth(formData.dateOfBirth);
    formError.dateOfBirth = dobValidation.message;

    const aadharValidation = validationHelper.aadhar(formData.aadharNumber);
    formError.aadharNumber = aadharValidation.message;

    this.setState({ formError });
    return (
      panValidation.isValid && dobValidation.isValid && aadharValidation.isValid
    );
  };

  // Re-verification Click
  handleReVerification = async () => {
    if (!this.handleValidationReVerification()) {
      return;
    }

    const { formData } = this.state;
    this.setState({ isLoading: true });

    try {
      //event-tracker: Initial -> submit_manual_ckyc
      tracker.initial.submitManualCkyc();
      const res = await onboardingApis.reVerifyKYCData({
        customerId: this.props.loginProfile.customerId,
        panCardNumber: formData.panNumber,
        aadharCardNumber: formData.aadharNumber,
        dateOfBirth: formData.dateOfBirth,
        productCode: config.productCodes.PORTFOLIO,
      });

      if (res && res.isVerified) {
        this.setState({ isVerified: true }, () => {
          this.props.getOnboardingDataAction();
        });
        //event-tracker: Initial -> submit_manual_ckyc_success
        tracker.initial.submitManualCkycSuccess();

        tracker.onboarding.ckyc(this.props.loginProfile.mobile);
      } else {
        this.props.setToast({ message: res.message });
      }
    } catch (e) {
      //event-tracker: Initial -> submit_manual_ckyc_success
      tracker.initial.submitCkycError({ error_msg: e.message });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleProceedDocumentSigningClick = async () => {
    let pathname = "/customer/document-signing"
        if(this.props.loginProfile.isManualAgreement === 1){
            pathname = "/manual-document-signing"
        }
    this.props.history.push({
      pathname: pathname,
      state: {
        productCode: config.productCodes.PORTFOLIO,
        returnURI: "/jarvis-portfolio/onboarding",
      },
    });
  };

  readFileAsBlob= async (file)=> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        // Define onload event handler
        reader.onload = function(event) {
            const fileContent = event.target.result;
            const blob = new Blob([fileContent],{type:file.type})
            resolve(blob); // Resolve with the result when reading is complete
        };
        
        // Define onerror event handler
        reader.onerror = function(event) {
            reject(event.target.error); // Reject with the error if reading fails
        };
        
        // Start reading the file
        reader.readAsArrayBuffer(file);
    });
  }
  //   Uploading Image
  onBrowseClick = async ({type, data=null}) => {
    let fileData= data;
    if(fileData?.name){
      const fileName = fileData.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      fileData.format = fileExtension;
    }
    if(config.platform !=="WEB"){
      fileData = await cameraHelper.getPhoto();
      console.log(fileData)
    }

    if (!fileData) {
      return;
    }

    // convert to blob based on file webpath
    let file;
    if(config.platform !== "WEB"){
      file = await fetch(fileData.webPath).then((r) => r.blob());
    }
    else{
      file = await this.readFileAsBlob(fileData);
    }

    const fileKB = (file.size / 1024); // Converted in KB.
    if (fileKB > 2000) {
      const fileMB = (fileKB / 1000).toFixed(2);

      // if images are more than 2mb
      this.props.setToast({ message: `Image must be less than 2MB, your Uploaded image is ${fileMB} MB` });
      return;
    }

    this.setImagesUploadingLoader(type, true);
    try {
      const res = await onboardingApis.uploadKYCImages({
        customerId: this.props.loginProfile.customerId,
        file,
        typeKey: type,
        fileName: `${type}_${this.props.loginProfile.customerId}_${dateTimeHelper.now().getTime()}.${fileData.format}`
      });

      if (res && res.isVerified) {
        this.setImagesUploaded(type, true);
      }
    } catch (e) {
    } finally {
      this.setImagesUploadingLoader(type, false);
    }
  };

  setImagesUploaded = (type, value) => {
    const { isImagesUploaded } = this.state;
    isImagesUploaded[type] = value;
    this.setState({ isImagesUploaded });
  };

  setImagesUploadingLoader = (type, value) => {
    const { imagesUploadingLoader } = this.state;
    imagesUploadingLoader[type] = value;
    this.setState({ imagesUploadingLoader });
  };

  render() {
    const {
      formData,
      formError,
      isVerified,
      isLoading,
      enableManually,
      isImagesUploaded,
      imagesUploadingLoader,
      hasFirstAttempError,
    } = this.state;

    return (
      <Stack sx={{ flex: 1 }}>
        {/* CKYC Manual */}
        {enableManually ? (
          <CKYCmanual
            formData={formData}
            formError={formError}
            isVerified={isVerified}
            isLoading={isLoading}
            isImagesUploaded={isImagesUploaded}
            imagesUploadingLoader={imagesUploadingLoader}
            handleChange={this.handleChange}
            onBrowseClick={this.onBrowseClick}
            handleReVerification={this.handleReVerification}
            toggleKYCtype={this.toggleKYCtype}
          />
        ) : (
          // CKYC Auto
          <CKYCauto
            formData={formData}
            formError={formError}
            isVerified={isVerified}
            isLoading={isLoading}
            hasFirstAttempError={hasFirstAttempError}
            handleChange={this.handleChange}
            handleVerification={this.handleVerification}
            toggleKYCtype={this.toggleKYCtype}
          />
        )}

        {/* Submit: Doc signing */}
        {isVerified ? (
          <Stack
            direction="row"
            justifyContent={{ xs: "center", md: "flex-start" }}
            mb={3}
            mt={6}
            alignItems="center"
            sx={{ width: { xs: "100%", md: "auto" }, background: "#fff" }}

          >
            <Stack
              sx={{
                position: { xs: "fixed", md: "unset" },
                bottom: { xs: "calc(10px + var(--safe-area-inset-bottom)) !important", md: "unset" },
                width: { xs: "90vw", md: "unset" },
              }}
            >
              <JarvisButton
                loading={isLoading}
                onClick={this.handleProceedDocumentSigningClick}
                sx={{ width: { xs: "100%", md: "auto" } }}
              >
                Proceed to Client Agreement
              </JarvisButton>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    );
  }
}

export default withRouter(CKYCContainer);
