import React from "react";
import TextField from "components/common/TextField";
import JarvisButton from "components/JarvisButton";
import Stack from "components/common/Stack";
import JarvisAutoSeach from "components/JarvisAutoSearch";
import Grid from "components/common/Grid";
import DatePicker from "components/common/DatePicker";
import { makeStyles } from "@mui/styles";
import { dateTimeHelper } from "helper/dateTime";

const useStyles = makeStyles((theme) => ({
  // root:{
  //   "& input":{
  //     padding:"1rem !important"
  //   }
  // },

}));
const RMSPortfolioSyncAddStock = ({
  actionType,
  editableFields,
  formData = {},
  formError = {},
  onSubmit,
  onChange,
  stockList,
  getData,
  onClose,
  clickType,
  type,
  stockId,
  isDisabled = false,
  loading = false
}) => {
  console.log(actionType, "action type");
  const classes = useStyles();
  return (
    <>
      <Stack sx={{ borderRadius: "10px", mt: 2 }}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          {actionType === "ADD" || (actionType === "EDIT" && formData?.defaultStockId === null) ? (
            <div style={{ flex: 1 }}>
              <JarvisAutoSeach
                type={type}
                label="Stock Name"
                value={formData.stockName}
                optionList={stockList}
                onChangeValue={getData}
                helperText={formError.stockName}
                error={formError.stockName}
                sx={{ width: 1, mb: 2 }}
              />
            </div>
          ) : null}

          {["EDIT", "EDIT_HOLDING"].includes(actionType) && formData.stockId !== null ? (
            <div style={{ flex: 1 }}>
              <TextField disabled label="Stock Name" value={formData.stockName} sx={{ width: 1, mb: 2 }} />
            </div>
          ) : null}

          <div style={{ flex: 1, marginTop: 0 }}>
            <TextField
              label="Quantity"
              type="number"
              value={formData.stockQuantity}
              onChange={(e) => onChange({ name: "stockQuantity", value: e.target.value })}
              helperText={formError.stockQuantity}
              error={formError.stockQuantity}
              sx={{ width: 1, mb: 2 }}
              disabled={actionType === "EDIT_HOLDING" && !editableFields.includes("stockQuantity")}
            />

          </div>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <div style={{ flex: 1 }}>
            <TextField
              type="number"
              value={formData.stockBuyPrice}
              onChange={(e) =>
                onChange({ name: "stockBuyPrice", value: e.target.value, })
              }
              label="Purchase price"
              sx={{ width: 1, mb: 2 }}
              helperText={formError.stockBuyPrice}
              error={formError.stockBuyPrice}
              disabled={actionType === "EDIT_HOLDING" && !editableFields.includes("stockBuyPrice")}

            />
          </div>
          <div style={{ flex: 1, marginTop: 0 }}>
            <DatePicker
              label="Purchase Date"
              value={formData.stockBuyDate}
              onChange={(e) => onChange({ name: "stockBuyDate", value: e })}
              sx={{ width: 1, mb: 2 }}
              disableFuture={true}
              helperText={formError.stockBuyDate}
              error={formError.stockBuyDate}
              disabled={actionType === "EDIT_HOLDING" && !editableFields.includes("stockBuyDate")}
            />
          </div>
          {/* {formError.stockBuyDate ? <span style={{ paddingLeft: "10%", color: "red" }}>{formError.stockBuyDate}</span> : null} */}
        </Stack>
      </Stack>
      <Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {/* <JarvisButton
            variant="outlined"
            disableEndIcon
            sx={{ width: "100px", boxShadow: "none", border: "none" }}
            onClick={onClose}
          >
            Cancel
          </JarvisButton> */}

          <JarvisButton
            disableEndIcon
            sx={{ width: "100%", boxShadow: "none", mt: 3 }}
            onClick={onSubmit}
            disabled={isDisabled}
            loading={loading}
          >
            Save
          </JarvisButton>
        </Stack>
      </Stack>
    </>
  );
};

export default RMSPortfolioSyncAddStock;
