import { commonConstants } from "./commonConstants";

const commonActions = {};

commonActions.getBlogs = () => ({
    type: commonConstants.BLOGS_SAGA,
});

commonActions.updateDeviceId = (payload) => ({
    type: commonConstants.DEVICE_ID_DATA,
    payload,
});

commonActions.setToast = (payload) => ({
    type: commonConstants.GLOBAL_SET_TOAST_DATA,
    payload,
});

commonActions.resetToast = () => ({
    type: commonConstants.GLOBAL_RESET_TOAST_DATA,
});

commonActions.setDialog = (payload) => ({
    type: commonConstants.GLOBAL_SET_DIALOG_DATA,
    payload,
});

commonActions.resetDialog = () => ({
    type: commonConstants.GLOBAL_RESET_DIALOG_DATA,
});

commonActions.updateLoader = ({ loading }) => ({
    type: commonConstants.GLOBAL_LOADER_DATA,
    payload: { loading },
});

commonActions.getRiskAssessmentQuestions = () => ({
    type: commonConstants.RISK_ASSESSMENT_QUESTIONS_SAGA,
});

commonActions.getRiskProfiles = () => ({
    type: commonConstants.RISK_PROFILES_SAGA,
});

commonActions.getBrokers = ({ domain, productCode }) => ({
    type: commonConstants.BROKERS_SAGA,
    payload: { domain, productCode },
});

commonActions.getMarketUpdate = (payload) => ({
    type: commonConstants.MARKET_UPDATES_SAGA,
    payload,
});

commonActions.getNewsArticles = (payload) => ({
    type: commonConstants.NEWS_ARTICLES_SAGA,
    payload,
});

commonActions.setNewsArticleContent = (payload) => ({
    type: commonConstants.NEWS_ARTICLES_CONTENT,
    payload,
});

commonActions.setAppWelcomeScreenShown = () => ({
    type: commonConstants.APP_WELCOME_SCREEN_SHOWN,
})

commonActions.getAppConfig = ({ appVersion, operatingSystem }) => ({
    type: commonConstants.APP_CONFIG_SAGA,
    payload: {
        appVersion,
        operatingSystem
    }
})

commonActions.getPaymentMethods = () => ({
    type: commonConstants.PAYMENT_METHODS_SAGA,
})

commonActions.getFeatureConfig = () => ({
    type: commonConstants.FEATURE_CONFIG_SAGA,
})

export { commonActions };
