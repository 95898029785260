import React from "react";

import RMSSyncPortfolioContainer from "containers/JarvisRMS/RMSSyncPortfolio";
import RMSConfirmPortfolioContainer from "containers/JarvisRMS/RMSConfirmPortfolio";
import PlanAndPricing from "containers/PlanAndPricing";

import { config } from "config/index";

const screenType = {
    SYNC_PORTFOLIO: "SYNC_PORTFOLIO",
    CONFIRM_PORTFOLIO: "CONFIRM_PORTFOLIO",
    PLAN_SELECTION: "PLAN_SELECTION",
};

// when user want to add more portfolio from existing holding screen 
// we take url and check if existing-holding includes if true then on continue btn redireted to existing holding


export default class RMSAddPortfolioContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreenType: props.portfolioId ? screenType.CONFIRM_PORTFOLIO : screenType.SYNC_PORTFOLIO,
            currentStockCount: 0,
            currentPortfolioCount: 0,
        };
    }

    componentDidMount() {
        if (this.props.portfolioId && this.state.activeScreenType !== screenType.CONFIRM_PORTFOLIO) {
            this.setState({ activeScreenType: screenType.CONFIRM_PORTFOLIO, })
        }
    }

    // when user coming from existing holding to add portfolio after add portfolio 
    // we redireted to existingholding screen else we change screen type 
    handleScreenChange = (requiredScreen, { currentStockCount, currentPortfolioCount } = {}) => {
        this.setState({ activeScreenType: screenType[requiredScreen], currentStockCount, currentPortfolioCount })
    };

    render() {
        const { activeScreenType, currentPortfolioCount, currentStockCount } = this.state;
        const { processType, isRMSOnboardingCompleted } = this.props;

        if (activeScreenType === screenType.SYNC_PORTFOLIO) {
            return (
                <RMSSyncPortfolioContainer
                    processType={isRMSOnboardingCompleted ? 'EXISTING_HOLDING' : 'ONBOARDING'}
                    screenType={screenType}
                    handleScreenChange={this.handleScreenChange}
                />
            )
        }

        if (activeScreenType === screenType.CONFIRM_PORTFOLIO) {
            return (
                <RMSConfirmPortfolioContainer
                    processType={isRMSOnboardingCompleted ? 'EXISTING_HOLDING' : 'ONBOARDING'}
                    screenType={screenType}
                    handleScreenChange={this.handleScreenChange}
                />
            )
        }

        if (activeScreenType === screenType.PLAN_SELECTION) {
            return (
                <PlanAndPricing
                    currentPortfolioCount={currentPortfolioCount}
                    currentStockCount={currentStockCount}
                    productCode={config.productCodes.RMS}
                    paymentType="INITIAL"
                    screenType={screenType}
                    handleScreenChange={this.handleScreenChange}
                />
            )
        }
        return `active screen type is not defined for ${activeScreenType}`
    }
}
