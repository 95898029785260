import { dashboardConstants } from "./dashboardConstants";

const dashboardActions = {};

dashboardActions.getPortfolioAllocations = () => ({
  type: dashboardConstants.PORTFOLIO_ALLOCATIONS_SAGA,
});

dashboardActions.getPortfolioOverview = () => ({
  type: dashboardConstants.PORTFOLIO_OVERVIEW_SAGA,
});

dashboardActions.getPortfolioPerformance = () => ({
  type: dashboardConstants.PORTFOLIO_PERFORMANCE_SAGA,
});
dashboardActions.getCustomerStories = () => ({
  type: dashboardConstants.CUSTOMER_STORIES_SAGA,
});

dashboardActions.getWelcomeVideosList = () => ({
  type: dashboardConstants.WELCOME_VIDEO_SAGA,
});

dashboardActions.getPortfolioChart = () => ({
  type: dashboardConstants.PORTFOLIO_CHART_SAGA,
});

dashboardActions.getPortfolioSector = () => ({
  type: dashboardConstants.PORTFOLIO_SECTOR_SAGA,
});

dashboardActions.getPortfolioMarket = () => ({
  type: dashboardConstants.PORTFOLIO_MARKET_SAGA,
});

dashboardActions.getPortfolioAlert = () => ({
  type: dashboardConstants.PORTFOLIO_ALERT_SAGA,
});

dashboardActions.getPortfolioPendingTransaction = () => ({
  type: dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_SAGA,
});

dashboardActions.dashboardRefresh = () => ({
  type: dashboardConstants.DASHBOARD_REFRESH_SAGA,
});

export { dashboardActions };
