import _get from "lodash/get";

const portfolioHoldingParsers = {};

portfolioHoldingParsers.portfolioHoldingParser = (res) => {
    let data = [];

    if (res && res.list) {
        res = res.list;
    }

    const roundDecimal = (val) => Number(Number(val).toFixed(2));

    data = res.map((r) => ({
        stockName: _get(r, "stockName", ""),
        stockSymbol: _get(r, "stockSymbol", ""),
        stockBuyDate: _get(r, "stockBuyDate", ""),
        stockBuyPrice: roundDecimal(_get(r, "stockBuyPrice", "")),
        stockBuyValue: roundDecimal(_get(r, "stockBuyValue", "")),
        stockCurrentPrice: roundDecimal(_get(r, "stockCurrentPrice", "")),
        stockCurrentValue: roundDecimal(_get(r, "stockCurrentValue", "")),
        stockChangePrice: roundDecimal(_get(r, "stockChangePrice", "")),
        stockChangePercentage: roundDecimal(_get(r, "stockChangePercentage", "")),
        stockChangeType: _get(r, "stockChangeType", ""),
        stockId: _get(r, "stockId", ""),
        stockQuantity: _get(r, "stockQuantity", ""),
        allocationPercentage: roundDecimal(_get(r, "allocationPercentage", "")),
    }));

    return data;
};



export { portfolioHoldingParsers };
