import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Container from '@mui/material/Container';
import Stack from 'components/common/Stack';
import Image from "components/common/Image";
import Text from 'components/common/Text';
import ProtectInfoContainer from 'containers/ProductInfo/ProtectInfo';
import { localStore } from "stores/localStorage";
import { config } from "config/index";
import JarvisCard from 'components/JarvisCard';
import JarvisButton from 'components/JarvisButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from 'components/common/Button';
import { withStyles } from "@mui/styles";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Chip } from '@mui/material';
import { alpha } from "@mui/material/styles";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TooltipHover from "components/common/TooltipHover"

const protectProduct = {
    id: 'protect', name: 'Jarvis Protect', description: "To help you book profits and cut losses from the stocks from your underperforming stock portfolio", icon: "/images/product-info/protect-bag.svg", investNow: "", banner: "/images/product-info/protectBanner.svg",
}

const Tabs = {
    portfolio: [
        { id: "portfolio_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "For existing and new investors who don’t have the time and expertise to manage their portfolio" },
        { id: "portfolio_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "Between 4 to 5 years regardless of market condition" },
        { id: "portfolio_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "Your demant account should have a minimum balance of Rs 30,000" },
        { id: "portfolio_4", isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: "text update here" },
    ],
    protect: [
        { id: "protect_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "Investors with underperforming stocks portfolio and don't know what to do with it" },
        { id: "protect_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "There is no minimum tenure commitment required" },
        { id: "protect_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "Your portfolio should have at least 5 stocks" },
        {
            id: "protect_4", hasChipUI: true, isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: ["Short-term profit booking", "Cut losses from underperforming stocks", "24X7 risk management process",
                "AI-based stock selection", "Risk profiling", "Personalized alerts based on your investing style", "Timely rebalancing", "No human bias & error"]
        },
    ],

    onestock: [
        { id: "onestock_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "text update here" },
        { id: "onestock_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "text update here" },
        { id: "onestock_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "text update here" },
        { id: "onestock_4", isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: "text update here" },
    ],
}

const protectOnboardingStages = [
    {
        stageId: ["RISK_ASSESSMENT"],
        stageNumber: 1,
        uri: "/jarvis-rms/onboarding/risk-assessment",
        heading: "Risk Assessment",
        subheading: "Answer these questions to know your risk profile",
    },
    {
        stageId: ["SYNC_PORTFOLIO"],
        stageNumber: 2,
        heading: "Sync your portfolio",
        uri: "/jarvis-rms/onboarding/sync-portfolio",
        subheading: "Select a broker from the list below or upload manually.",
    },
    {
        stageId: ["CKYC", "DOCUMENT_SIGNING"],
        stageNumber: 3,
        heading: "CKYC & E-sign",
        uri: "/jarvis-rms/onboarding/ckyc",
        subheading: "Complete your KYC",
    }
];
class RMSOnboardingNotCompletedContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActivePortfolio: false,
            portfolioKnowMoreSection: true,
            protectKnowMoreSection: false,
            onestockKnowMoreSection: false,
            protectOpenId: null,
        }
    }

    handleTabClick = (data, type) => {
        Tabs[type].forEach(element => {
            data.id == element.id ? (element.isActive = true) : (element.isActive = false);
        });
        this.setState({ isActivePortfolio: true })
    }

    handleKnowMore = (type) => {
        const { portfolioKnowMoreSection, protectKnowMoreSection, onestockKnowMoreSection } = this.state;
        if (type == "portfolio") {
            this.setState({ portfolioKnowMoreSection: !portfolioKnowMoreSection })
        }
        if (type == "protect") {
            this.setState({ protectKnowMoreSection: !protectKnowMoreSection })
        }
        if (type == "onestock") {
            this.setState({ onestockKnowMoreSection: !onestockKnowMoreSection });
        }

    }

    handleInvestNow = (type) => {
        if (type == "portfolio") {
            localStore.setActiveProduct(config.productCodes.PORTFOLIO);
            this.props.history.push(`/jarvis-portfolio/onboarding/overview`);
        }
        if (type == "protect") {
            localStore.setActiveProduct(config.productCodes.RMS);
            this.props.history.push(`/jarvis-rms/onboarding/overview`);
        }
        if (type == "onestock") {
            localStore.setActiveProduct(config.productCodes.ONESTOCK);
            this.props.history.push(`/jarvis-onestock/onboarding/overview`);
        }
    }

    // For Protect Steps
    getActiveStageForProtect = () => {
        const { currentStageForProtect } = this.props;
        return protectOnboardingStages.find((o) =>
            o.stageId.includes(currentStageForProtect)
        )
    };

    handleProtectTooltip = (id, type = 'open') => {
        if (type === 'open') {
            this.setState({ protectOpenId: id });
        } else {
            this.setState({ protectOpenId: null });
        }
    }

    render() {
        const { classes } = this.props;
        const { portfolioKnowMoreSection, protectKnowMoreSection, onestockKnowMoreSection } = this.state;
        const activeProtectStage = this.getActiveStageForProtect();
        let ssoCustomerDetails = localStore.getSsoCustomerDetails();
        let isCentrumSsoCustomer = false
        if(localStore.isSsoCustomer() && ssoCustomerDetails?.broker === config?.broker?.ssoPlatform?.centrum ){
            isCentrumSsoCustomer = true
        }

        return (

            <Stack mt={3}>
                {/* <Container maxWidth="lg"> */}
                    <JarvisCard sx={{ border: "1px solid #d3d3d3", position: "relative", paddingBottom: "60px", marginBottom: "20px" }}>
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                            <Stack sx={{ flex: { xs: "1", md: "2" } }} >
                                <Stack direction="row">
                                    <img src={protectProduct.icon} style={{ marginRight: "18px" }} />
                                    <div>
                                        <Text variant="subtitle1" color={'#2B70C5'} sx={{ fontWeight: 500 }}>{protectProduct.name}</Text>
                                        <Text variant="body2" color={'#676E76'}>{protectProduct.description}</Text>
                                    </div>
                                </Stack>
                                {/* tab */}
                                <Stack alignItems="start" mt={4} direction="row" sx={{ overflow: "auto", cursor: "pointer" }}>
                                    {Tabs[protectProduct.id].map((data) => (
                                        <Stack direction="column" alignItems="center" sx={{ flex: "1 1 auto", minWidth: "125px", borderBottom: ((data.isActive) ? '3px solid #2B70C5' : '') }} onClick={() => this.handleTabClick(data, `${protectProduct.id}`)}>
                                            <img src={data.img} width={"36px"}></img>
                                            <Text variant="body2" mb={1} sx={{ whiteSpace: "noWrap" }}>{data.title}</Text>
                                        </Stack>
                                    )
                                    )}
                                </Stack>
                                <Stack mt={2}>
                                    {Tabs[protectProduct.id].map((data) => {
                                        return !data.isActive ? null : data.hasChipUI ? (
                                            <>
                                                <Stack
                                                    direction="row"
                                                    sx={{
                                                        flexWrap: "wrap",
                                                        gap: "0.8rem"
                                                    }}
                                                >
                                                    {data.text.map(d => (
                                                        <Stack
                                                            className={classes.chipUi}
                                                        >
                                                            {d}
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </>
                                        ) : (
                                            <Text variant="body2" color={"#676E76"} >{data.text}</Text>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                            <Stack sx={{ flex: 1 }}>
                                <img src={protectProduct.banner} width={'100%'} style={{ marginBottom: "20px" }} />
                                <JarvisButton disableEndIcon={true} onClick={() => this.handleInvestNow(protectProduct.id)}>Invest Now</JarvisButton>
                            </Stack>
                        </Stack>
                        {
                            protectProduct.id == "protect" && (
                                this.props.currentStageForProtect === "SYNC_PORTFOLIO" ||
                                this.props.currentStageForProtect === "CKYC" ||
                                this.props.currentStageForProtect === "DOCUMENT_SIGNING"
                            ) ?
                                <ClickAwayListener onClickAway={() => this.handleProtectTooltip('', 'close')}>
                                    <Stack direction={{ xs: 'column', md: 'row' }} className={classes.toolTipss}>
                                        {this.props.protectOverviewList.map((item, index) => <Stack mt={4} key={item.id} direction={'row'} alignItems={'center'}>
                                                <Chip label={item.id} size='small' sx={{ color: item.stageNumber < activeProtectStage?.stageNumber ? 'white' : 'primary.dark', backgroundColor: item.stageNumber < activeProtectStage?.stageNumber ? alpha('#2e7d32', 0.8) : alpha('#38a1ff', 0.5), fontWeight: '500', fontSize: { xs: 'caption2.fontSize', md: 'caption1.fontSize' }, maxHeight: '24px', minWidth: '24px' }} />
                                                <Text ml={0.5} sx={{ fontSize: "14px", color: '#212225', fontWeight: '400', }}>
                                                    {isCentrumSsoCustomer && item.name?.toLowerCase() === "ckyc"?"E-Sign":item.name}
                                                </Text>

                                                <TooltipHover
                                                    title={!isCentrumSsoCustomer?item.toolTipText : item.toolTipText.replaceAll('CKYC','E-Sign')}
                                                    sx={{ lineHeight: 0, ml: 0.5, mr: index === this.props.protectOverviewList.length - 1 ? 0 : 1.5 }}
                                                >
                                                    <InfoRoundedIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                                                </TooltipHover>
                                            </Stack>)}
                                    </Stack>
                                </ClickAwayListener>
                                : null
                        }
                        {
                            protectProduct.id == "protect" && protectKnowMoreSection ?
                                (<ProtectInfoContainer />)
                                : null
                        }
                        <Button className={classes.btns} onClick={() => this.handleKnowMore(`${protectProduct.id}`)} endIcon={protectKnowMoreSection ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                            {
                                (protectProduct.id == "protect" && !protectKnowMoreSection) ? "Know Pricing"
                                    : "Hide Pricing"
                            }
                        </Button>
                    </JarvisCard>
                {/* </Container> */}
            </Stack>
        );
    }
}

const style = (theme) => ({
    btns: {
        backgroundColor: '#EEF4FA',
        color: '#2B70C5',
        fontSize: '0.875rem !important',
        fontWeight: 500,
        boxShadow: 'none',
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        bborderBottomLeftRadius: "0px",
        bborderBottomRightRadius: "0px",
        textTransform: "capitalize",
        '&:hover': {
            color: "white",
        }
    },
    chipUi: {
        color: "#2B70C5",
        border: "1px solid #DCDCDC",
        padding: "0.25rem 0.5rem",
        fontSize: "12px",
        borderRadius: "24px",
        backgroundColor: "#FFFFFF",
        fontWeight: 500,
    }

})

export default withRouter(withStyles(style, { withTheme: true })(RMSOnboardingNotCompletedContainer));

