import React from "react";
import RMSCkycContainer from "containers/JarvisRMS/RMSCkyc";

const CKYC = () => {
  return (
    <>
      <RMSCkycContainer />
    </>
  );
};

export default CKYC;
