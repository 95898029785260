import React from "react";
import { tracker } from "library/tracker";
import SubscriptionDetailsContainer from 'containers/Profile/SubscriptionDetails';

const SubscriptionDetails = () => {
    React.useEffect(() => {
      tracker.pageView();
    }, []);
    return <SubscriptionDetailsContainer/>
};

export default SubscriptionDetails;
