import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { helpSupportConstants } from "./helpSupportConstants";

const initialState = {
    categoryList: [],
    categoryData: [],
    categoryQuestions: [],
    loading: {
        categoryList: 0,
        categoryData: 0,
        categoryQuestions: 0,
    },
};

export const helpSupportReducer = persistReducer(
    {
        storage,
        key: "helpSupport",
        whitelist: ["categoryList"],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case helpSupportConstants.CATEGORY_LIST_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        categoryList: 1,
                    },
                    categoryList: initialState.categoryList,
                };
            }

            case helpSupportConstants.CATEGORY_LIST_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        categoryList: 0,
                    },
                    categoryList: payload,
                };
            }

            case helpSupportConstants.CATEGORY_DATA_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        categoryData: 1,
                    },
                    categoryData: state.categoryData,
                };
            }

            case helpSupportConstants.CATEGORY_DATA_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        categoryData: 0,
                    },
                    categoryData: {
                        ...state.categoryData,
                        [payload.categoryID]: payload.data,
                    },
                };
            }

            case helpSupportConstants.CATEGORY_QUESTIONS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        categoryQuestions: 1,
                    },
                    categoryQuestions: initialState.categoryQuestions,
                };
            }

            case helpSupportConstants.CATEGORY_QUESTIONS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        categoryQuestions: 0,
                    },
                    categoryQuestions: payload,
                };
            }

            default:
                return state;
        }
    }
);
