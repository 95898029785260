import React from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Image from "components/common/Image";
import { brokerHelper } from "helper/broker";

import { makeStyles, createStyles } from "@mui/styles";
import cx from "classnames";

const useStyle = makeStyles((theme) => createStyles({
	bgWraper: {
		backgroundImage: "url('/images/login-background1.png')",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		backgroundRepeat: "no-repeat",
		height: "100%",

	},
	mainWrapper: {
		// height: "100%",
		// paddingTop: theme.spacing(16),
		// paddingBottom: theme.spacing(6)
		height: "calc(100vh - 56px)",
		alignItems: "center",
		justifyContent: "center",
	},
	borkerBgWrapper: {
		backgroundImage: "none",
		backgroundColor: "#fbfbfb",
	}
}));

const AuthAsidePanel = () => {
	const brokerLogoUrl = brokerHelper.getLogoUrl();

	const classes = useStyle();
	return (
		<Stack className={cx({ [classes.bgWraper]: true, [classes.borkerBgWrapper]: brokerLogoUrl })}>
			<Stack className={classes.mainWrapper}>

				{/* Top logo */}
				{brokerLogoUrl ? (
					<Stack mt={4}>
						<img src={brokerLogoUrl} width={200} height="auto" alt="img" />
					</Stack>
				) :
					(<Image
						src="/images/jarvis-white-logo.svg"
						width={180}
						height={70}
					/>)
				}
				
				{/* Center Image */}
				<Stack mt={8} alignItems="center" sx={{ maxHeight: "100%", height: "360px", justifyContent: "center" }}>
					<Image
						src="/images/login-background-stats.png"
						height={220}
					/>

					{brokerLogoUrl ?
						(<Stack>
							<Text
								variant="body2"
								color="#9EA6AE"
								sx={{
									textAlign: "center", fontSize: "11px", textTransform: "uppercase",
									letterSpacing: "0.8px", mb: "5px", mt: "20px"
								}}>
								Partnered With
							</Text>
							<Image
								src="/jarvis-logo.svg"
								width={122}
								height={"auto"}
							/>
						</Stack>)
						: null}
				</Stack>

				{/* SEBI Bottom */}
				<Stack
					direction="row"
					alignItems="flex-end"
					justifyContent="center"
					sx={{ height: "55px" }}
				>
					<Text sx={{ px: 2, color: brokerLogoUrl ? "#88929B" : "#fff" }} variant="body2">
						SEBI Registered Investment Advisor
					</Text>
					<Stack
						direction="row"
						alignItems="center"
						sx={{
							px: 2,
							borderLeft: "1px solid black",
							borderColor: (brokerLogoUrl ? "#88929B" : "#fff"),
						}}>
						<Image
							src={brokerLogoUrl ? `/images/sebi_logo_gray.png` : `/images/sebi_logo.png`}
							height={30}
						/>
					</Stack>
					<Text variant="caption1" sx={{ color: brokerLogoUrl ? "#88929B" : "#fff" }}>
						INA 000013235
					</Text>
				</Stack>
			</Stack>


		</Stack>
	);
};

export default AuthAsidePanel;
