import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";

const mapStateToProps = (state) => {
    return {
        profile: state.customer.profile,
        enableAccountDeactivation: state.common.featureConfig.enableAccountDeactivation,

        renewalPricingDetails: state.customer.renewalPricingDetails,
        isLoading: state.customer.loading.renewalPricingDetails,

        customerSubscriptionId: state.onboarding.customerSubscriptionId,
        reasonsDropdown: state.customer.reasonsDropdown
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getProfileDataAction: customerActions.getProfile,
            updateProfileAction: customerActions.updateProfile,
            reasonsDropdownAction: customerActions.getReasonDropdownForCancellation,
            logoutAction: customerActions.logout,
        },
        dispatch
    );

const ProfileStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default ProfileStore;
