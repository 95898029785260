import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvisRMSDashboardOnboardedActions } from "stores/jarvisRMS/jarvisRMSDashboard/jarvisRMSDashboardonboardedActions";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";

const mapStateToProps = (state) => {
    return {
        rmsPortfolioStats:
            state.jarvisRMS.RMSDashboardOnboarded.portfoliosStats,
        isRMSPortfolioStatsLoading:
            state.jarvisRMS.RMSDashboardOnboarded.loading.portfoliosStats,
        isRMSPortfolioStatsInitialCalled:
            state.jarvisRMS.RMSDashboardOnboarded.isInitialCalled
                .portfoliosStats,

        rmsCallStats: state.jarvisRMS.RMSDashboardOnboarded.rmsStats,
        isRMSCallStatsLoading:
            state.jarvisRMS.RMSDashboardOnboarded.loading.rmsStats,
        isRMSCallStatsInitialCalled:
            state.jarvisRMS.RMSDashboardOnboarded.isInitialCalled.rmsStats,

        isRMSOnboardingCompleted:
            state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
        isRMSOnboardingDataLoading:
            state.jarvisRMS.rmsOnboarding.loading.onboardingData,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getPortfolioStatsAction:
                jarvisRMSDashboardOnboardedActions.getPortfolioStatsDataAction,
            getRmsCallStatsAction:
                jarvisRMSDashboardOnboardedActions.getRmsStatsDataAction,
            getOnboardingDataAction:
                jarvsiRMSOnboardingActions.getOnboardingData,
        },
        dispatch
    );

const OverviewStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OverviewStore;
