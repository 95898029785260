import React, { Component } from "react";
import _get from "lodash.get";

import Text from "components/common/Text";
import Loader from "components/common/Loader";
import Modal from "components/common/Modal";
import Stack from "components/common/Stack";
import Image from "components/common/Image";
import TextField from "components/common/TextField";

import JarvisButton from "components/JarvisButton";
import StockCard from "components/StockCard";

import { config } from "config";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import { thirdpartyParsers } from "stores/thirdparty/thirdpartyParsers";
import { validationHelper } from "helper/validation";

import { withRouter } from "react-router-dom";

// tracker
import { tracker } from "library/tracker";
import { localStore } from "stores/localStorage";
import OtpInput from "react-otp-input";
import Button from "components/common/Button";
class BrokerBigulContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            // auth related keys
            isAuthLoading: false,
            authFormModalOpen: false,
            otpSentLoading: false,
            otpSuccessResponse: "",
            otpFailureResponse: "",
            authFormData: {
                username: "",
                password: "",
                verificationCode: "",
                otp: ""
            },
            authFormError: {
                username: "",
                password: "",
                verificationCode: "",
                otp: ""
            },
            // stocks data related keys
            processDataModalOpen: false,
            isOrderLoading: false,
            isOrderPlaced: false,
            processData: {
                brokerName: "",
                brokerPlatform: "",
                processType: "",
                productCode: "",
                brokerId: "",
                oneStockCallId: "",
            },
        };
    }

    componentDidMount() {
        this.setProcessData();
    }


    // Step 1 - set process Data from location
    setProcessData = () => {
        const brokerName = _get(this.props.location, "state.brokerName", "");
        const brokerPlatform = _get(this.props.location, "state.brokerPlatform", "");
        const processType = _get(this.props.location, "state.processType", "");
        const productCode = _get(this.props.location, "state.productCode", "");
        const brokerId = _get(this.props.location, "state.brokerId", "");
        const oneStockCallId = _get(this.props.location, "state.onestockCallId", "")
        const stockQuantity = _get(this.props.location, "state.stockQuantity", "")

        if (brokerName || brokerPlatform || (productCode !== config.productCodes.PORTFOLIO && processType)) {
            this.setState(
                { processData: { brokerName, brokerPlatform, processType, productCode, brokerId, oneStockCallId, stockQuantity } },
                () => {
                    this.initProcess();
                }
            );
        } else {
            this.props.onError({
                reason: "No Broker selected",
                origin: "componentDidMount > not found = brokerName | brokerPlatform | processType",
            });
        }
    }


    // Step 2 - Check if broker login
    initProcess = async () => {
        try {
            const { processData } = this.state;
            const {loginProfile} = this.props
            this.setState({ isLoading: true });
            const isSsoCustomer= localStore.isSsoCustomer()
            const ssoCustomerDetails= localStore.getSsoCustomerDetails();

            const prevTokenResponse =
                await thirdpartyApis.brokerPreviousAccessToken({
                    brokerPlatform: processData.brokerPlatform,
                    productCode: processData.productCode,
                    brokerId: processData.brokerId,
                });
            const { valid, clientId } =
                thirdpartyParsers.brokerPreviousAccessToken(prevTokenResponse, processData.productCode);

            if (!valid && !isSsoCustomer) {
                this.setState({ authFormModalOpen: true });
                return;
            }
            let clientID = clientId
            if(!clientId && isSsoCustomer)
            clientID= loginProfile.ssoClientId?loginProfile.ssoClientId:''

            await this.initOrderProcess({ clientId:clientID });
        } catch (error) {
            this.props.onError({
                reason: "Something went wrong! Please try again later!",
                origin: "initProcess > catch block",
                error,
            });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    // Step 4 - After login init order process
    initOrderProcess = async ({ clientId } = {}) => {
        const { processData } = this.state;
        if (processData.productCode === config.productCodes.RMS) {
            await this.props.fetchHoldingData({
                brokerName: processData.brokerName,
                brokerPlatform: processData.brokerPlatform,
                productCode: processData.productCode,
                brokerId: processData.brokerId,
                clientId,
            });
            return;
        }

        //NOTE : event_tracker => broker
        tracker.broker.brokerStockList({ broker_name: processData.brokerName });

        const preOrderData = await this.props.getPreOrderData({
            brokerName: processData.brokerName,
            brokerPlatform: processData.brokerPlatform,
            processType: processData.processType,
            productCode: processData.productCode,
            brokerId: processData.brokerId,
            onestockCallId: processData.oneStockCallId,
            stockQuantity: processData.stockQuantity,
        });
        //NOTE : event_tracker => broker
        tracker.broker.brokerStockListSuccess({ broker_name: processData.brokerName });
        if (!preOrderData) {
            //NOTE : event_tracker => broker
            tracker.broker.brokerStockListFailed({ broker_name: processData.brokerName, error_msg: "" });
            return;
        }

        this.setState({ processDataModalOpen: true });
        // this.handleAuthFormModalClose({ process: false });
    };


    // close Stock list modal
    handleOrderdataModalClose = () => {
        this.setState({
            processDataModalOpen: false
        });
        this.props.onCancel();
    }

    // handle auth form field value change
    handleAuthFormChange = ({ name, value }) => {
        const { authFormData } = this.state;
        authFormData[name] = value;
        this.setState({ authFormData });
    };

    // handle auth form validation
    handleAuthFormValidation = () => {
        const { authFormData, authFormError } = this.state;

        const usernameValidation = validationHelper.required(
            authFormData.username
        );
        authFormError.username = usernameValidation.message;

        const passwordValidation = validationHelper.required(
            authFormData.password
        );
        authFormError.password = passwordValidation.message;

        // const verificationCodeValidation = validationHelper.required(
        //     authFormData.verificationCode
        // );
        // authFormError.verificationCode = verificationCodeValidation.message;

        this.setState({ authFormError });
        return (
            usernameValidation.isValid &&
            passwordValidation.isValid
            // verificationCodeValidation.isValid
        );
    };

    // Step 3 - Broker Login submit
    handleAuthFormSubmit = async (value) => {
        if (!this.handleAuthFormValidation()) {
            return;
        }
        const { authFormData, processData } = this.state;
        //NOTE : event_tracker => borker
        tracker.broker.brokerLogin({ broker_name: processData.brokerName });
        if(value === 'SEND_OTP'){
            this.setState({ otpSentLoading: true });
        }

        if(value === 'LOGIN'){
            this.setState({ isAuthLoading: true });
        }
        try {
            const responseMessage = await thirdpartyApis.brokerLogin({
                brokerPlatform: processData.brokerPlatform,
                brokerName: processData.brokerName,
                username: authFormData.username,
                password: authFormData.password,
                // verificationCode: authFormData.verificationCode,
                productCode: processData.productCode,
                brokerId: processData.brokerId,
                otp: authFormData.otp ? authFormData.otp : ""
            });
            if(responseMessage === "Login successful."){
               await this.initOrderProcess({ clientId: authFormData.username });
            }
            if(responseMessage === "OTP Sent Successfully."){
                this.setState({ otpSuccessResponse: responseMessage });
            }
            
            //NOTE : event_tracker => borker
            tracker.broker.brokerLoginSuccess({ broker_name: processData.brokerName });
            

        } catch (error) {
            //NOTE : event_tracker => borker
            tracker.broker.brokerLoginFailed({ broker_name: processData.brokerName, error_msg: error.message });
            this.props.onError({
                reason: "Something went wrong! Please try again later!",
                origin: "handleAuthFormSubmit > catch block",
                error,
            });
        } finally {
            this.setState({ isAuthLoading: false });
            this.setState({ otpSentLoading: false });
        }
    };

    // Clear Broker login from
    handleAuthFormModalClose = ({ process } = {}) => {
        this.setState({
            authFormModalOpen: false,
            authFormData: {
                username: "",
                password: "",
                verificationCode: "",
            },
            authFormError: {
                username: "",
                password: "",
                verificationCode: "",
            },
        });
        if (!process) {
            this.props.history.goBack();
        }
    };

    // Step 5 - Order place/ check status/ finish [onSuccess()]
    handleOrderProcessClick = async (action) => {
        const { processData } = this.state;
        const { updateStocksInPreOrder, preOrderData, setPostOrderData } = this.props;
        if (action === "PLACE_ORDER") {
            //NOTE : event_tracker => broker
            tracker.broker.brokerPlaceNewOrder({ broker_name: processData.brokerName });
            this.setState({ isOrderLoading: true });
            try {
                const placeOrderResponse =
                    await thirdpartyApis.brokerPlaceOrder({
                        brokerPlatform: processData.brokerPlatform,
                        brokerName: processData.brokerName,
                        type: processData.processType,
                        brokerId: processData.brokerId,
                        oneStockCallId:processData.oneStockCallId || null,
                        productCode: processData.productCode,
                    });

                const placeOrderParsed =
                    thirdpartyParsers.brokerPlaceOrder(placeOrderResponse);

                setPostOrderData({
                    totalStocks: placeOrderParsed.totalStocks,
                    completedStocks: placeOrderParsed.completedStocks
                });
                updateStocksInPreOrder(placeOrderParsed.list);
                this.setState({ isOrderPlaced: true });
                //NOTE : event_tracker => broker
                tracker.broker.brokerPlaceNewOrdersuccess({ broker_name: processData.brokerName });
            } catch (error) {
                //NOTE : event_tracker => broker
                tracker.broker.brokerPlaceNewOrderFailed({ broker_name: processData.brokerName, error_msg: error.message });
                this.props.onError({
                    reason: "Something went wrong! Please try again later!",
                    origin: "handleOrderProcessClick > PLACE_ORDER",
                    error,
                });
            } finally {
                this.setState({ isOrderLoading: false });
            }
            return;
        }

        if (action === "CHECK_ORDER_STATUS") {
            //NOTE : event_tracker => broker
            tracker.broker.checkOrderStatus({ broker_name: processData.brokerName });
            this.setState({ isOrderLoading: true });
            try {
                const stocksSymbol = preOrderData.stockDetails.map(s => s.symbol)

                const checkOrderStatusResponse =
                    await thirdpartyApis.brokerCheckOrderStatus({
                        brokerPlatform: processData.brokerPlatform,
                        brokerName: processData.brokerName,
                        brokerId: processData.brokerId,
                        type: processData.processType,
                        stocks: stocksSymbol,
                        oneStockCallId: processData.oneStockCallId || null,
                        productCode: processData.productCode
                    });
                //NOTE : event_tracker => broker
                tracker.broker.checkOrderStatusSuccess({ broker_name: processData.brokerName });
                const checkOrderStatusParsed = thirdpartyParsers.brokerPlaceOrder(
                    checkOrderStatusResponse
                );

                setPostOrderData({
                    totalStocks: checkOrderStatusParsed.totalStocks,
                    completedStocks: checkOrderStatusParsed.completedStocks
                });
                updateStocksInPreOrder(checkOrderStatusParsed.list);
            } catch (error) {
                //NOTE : event_tracker => broker
                tracker.broker.checkOrderStatusFailed({ broker_name: processData.brokerName });
                // No need of error logging
            } finally {
                this.setState({ isOrderLoading: false });
            }
            return;
        }

        if (action === "FINISH_ORDER") {
            //NOTE : event_tracker => broker
            tracker.broker.finish({ broker_name: processData.brokerName });
            const isRejected = preOrderData.stockDetails.every(
                (sd) => sd.status == "rejected"
            );

            this.setState({ processDataModalOpen: false }, () => {
                if (isRejected) {
                    this.props.onError({
                        reason: "Order has been rejected by platform.",
                    });
                    return;
                }
                this.props.onSuccess();
                //NOTE : event_tracker => broker
                tracker.broker.finishSuccess({ broker_name: processData.brokerName });
            });
        }
    };

    // UI: login form
    renderLoginForm() {
        const {
            isAuthLoading,
            authFormData,
            authFormError,
            processData: { brokerName },
            otpSentLoading,
            otpSuccessResponse,
            otpFailureResponse
        } = this.state;
        const imageSrc = _get(config.broker[brokerName], `logoUrl`, "");
        const otpContainer = {
          height: "56px",
          width: "100%",
        };
        const otpInput = {
          height: "56px",
          width: "57px",
          border: "1px solid #E0E7FF",
          flex: "1",
        };

        return (
            <Stack alignItems="center" justifyContent="center">
                <Stack justifyContent="center">
                    <Image src={imageSrc} alt={brokerName} height="auto" width={120} />
                </Stack>
                <Text variant="subtitle2" sx={{ my: { xs: 1, md: 2 } }}>
                    Login to {brokerName.toUpperCase()}
                </Text>
                <TextField
                    label="Username"
                    value={authFormData.username}
                    onChange={(e) =>
                        this.handleAuthFormChange({
                            name: "username",
                            value: e.target.value,
                        })
                    }
                    helperText={authFormError.username}
                    error={authFormError.username}
                    sx={{ width: 1, mb: { xs: 4, md: 2 } }}
                />

                <TextField
                    type="password"
                    label="Password"
                    value={authFormData.password}
                    onChange={(e) =>
                        this.handleAuthFormChange({
                            name: "password",
                            value: e.target.value,
                        })
                    }
                    helperText={authFormError.password}
                    error={authFormError.password}
                    sx={{ width: 1, mb: { xs: 4, md: 2 } }}
                />

                {/* <TextField
                    label="Verification Code"
                    value={authFormData.verificationCode}
                    onChange={(e) =>
                        this.handleAuthFormChange({
                            name: "verificationCode",
                            value: e.target.value,
                        })
                    }
                    helperText={authFormError.verificationCode}
                    error={authFormError.verificationCode}
                    sx={{ width: 1, mb: 4 }}
                /> */}
            <OtpInput
              className={otpContainer}
              inputStyle={otpInput}
              value={authFormData.otp}
              numInputs={6}
              isInputNum={true}
              onChange={(e) =>
                this.handleAuthFormChange({
                  name: "otp",
                  value: e,
                })
              }
              separator={<span>&nbsp;</span>}
            />
            {/* Send OTP button */}
            <Stack
              variant="caption1"
              sx={{
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexFlow: "row wrap",
                fontSize: "15px",
              }}
            >
              <Button
                component="span"
                sx={{
                  fontSize: "15px",
                  color: "#72aaff",
                  background: "transparent !important",
                  padding: "0",
                  boxShadow: "none !important",
                  display: "inline",
                  textTransform: "none",
                  cursor: "pointer",
                  minWidth: "auto",
                  marginRight: "8px",
                }}
                onClick={()=> this.handleAuthFormSubmit('SEND_OTP')}
                loading={otpSentLoading}
              >
                Send OTP
              </Button>
            </Stack>
            { otpSuccessResponse ? (<Text>{otpSuccessResponse}</Text>): null}
            { otpFailureResponse ? (<Text>{otpFailureResponse}</Text>): null}
            <br />
            <JarvisButton
              sx={{ mb: 3 }}
              onClick={() => this.handleAuthFormSubmit('LOGIN')}
              loading={isAuthLoading}
              disabled={authFormData.otp.length !== 6 ? true : false}
            >
              Login
            </JarvisButton>
          </Stack>
        );
    }

    render() {
        const {
            isLoading,
            isOrderLoading,
            isOrderPlaced,
            authFormModalOpen,
            processDataModalOpen,
            processData: { brokerName },
        } = this.state;
        const { preOrderData } = this.props;

        return (
            <>
                <Loader loading={isLoading} />

                <Text>
                    {brokerName
                        ? `Selected broker: ${brokerName.toUpperCase()}`
                        : "No Broker Selected"}
                </Text>

                <Modal
                    disableClose={false}
                    disableExcapeKeyDown={true}
                    size="xs"
                    open={authFormModalOpen}
                    onClose={this.handleAuthFormModalClose}
                >
                    {this.renderLoginForm()}
                </Modal>

                <Modal
                    disableClose={isOrderPlaced || isOrderLoading}
                    disableExcapeKeyDown={true}
                    title="Order Data"
                    size="lg"
                    open={processDataModalOpen}
                    onClose={this.handleOrderdataModalClose}
                >
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        justifyContent="flex-start"
                        sx={{ position: "relative", flexWrap: "wrap" }}
                    >

                        {preOrderData.stockDetails.map((sd, i) => (
                            <Stack
                                key={`stock_s_${i}`}
                                sx={{ flexGrow: 0, width: { xs: 1, sm: 0.5 } }}
                            >
                                <StockCard
                                    sx={{ border: `1px solid #e2e2e2` }}
                                    stockDetails={sd}
                                    cardType="SARAL"
                                />
                            </Stack>
                        ))}
                    </Stack>

                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="right"
                        width={1}
                        mt={3}
                        spacing={2}
                    >
                        {isOrderPlaced ? (
                            <>
                                <JarvisButton
                                    loading={isOrderLoading}
                                    disableEndIcon
                                    variant={"outlined"}
                                    onClick={() =>
                                        this.handleOrderProcessClick(
                                            "CHECK_ORDER_STATUS"
                                        )
                                    }
                                >
                                    Check Order Status
                                </JarvisButton>
                                <JarvisButton
                                    loading={isOrderLoading}
                                    disableEndIcon
                                    onClick={() =>
                                        this.handleOrderProcessClick(
                                            "FINISH_ORDER"
                                        )
                                    }
                                >
                                    Finish
                                </JarvisButton>
                            </>
                        ) : (
                            <JarvisButton
                                loading={isOrderLoading}
                                onClick={() =>
                                    this.handleOrderProcessClick("PLACE_ORDER")
                                }
                            >
                                Place Order Now
                            </JarvisButton>
                        )}
                    </Stack>
                </Modal>
            </>
        );
    }

}

export default withRouter(React.memo(BrokerBigulContainer));