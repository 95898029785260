const masterConstants = {
    
    RMS_RISK_ASSESSMENT_QUESTIONS_API: "master/risk/assessment-questionnaire",
    RMS_RISK_ASSESSMENT_QUESTIONS_SAGA: "RMS_RISK_ASSESSMENT_QUESTIONS_SAGA",
    RMS_RISK_ASSESSMENT_QUESTIONS_REQUEST: "RMS_RISK_ASSESSMENT_QUESTIONS_REQUEST",
    RMS_RISK_ASSESSMENT_QUESTIONS_RESPONSE: "RMS_RISK_ASSESSMENT_QUESTIONS_RESPONSE",

    RMS_RISK_PROFILES_API: "master/risk/profile",
    RMS_RISK_PROFILES_SAGA: "RMS_RISK_PROFILES_SAGA",
    RMS_RISK_PROFILES_REQUEST: "RMS_RISK_PROFILES_REQUEST",
    RMS_RISK_PROFILES_RESPONSE: "RMS_RISK_PROFILES_RESPONSE",

    RMS_FETCH_STOCKS:"master/stock",

    VERIFY_AUTO_KYC_API : "master/kyc/verify",
    VERIFY_KYC_DOCUMENT_API:"master/kyc/upload-documents",
    VERIFY_MANUAL_KYC_API:"master/kyc/manual-verify",

    MASTER_BROKER_API: "broker/list",
    MASTER_BROKER_SAGA: "MASTER_BROKER_SAGA",
    MASTER_BROKER_REQUEST: "MASTER_BROKER_REQUEST",
    MASTER_BROKER_RESPONSE: "MASTER_BROKER_RESPONSE",

    // ONESTOCK_MASTER_BROKER_API: "broker/product",
    // ONESTOCK_MASTER_BROKER_SAGA: "ONESTOCK_MASTER_BROKER_SAGA",
    // ONESTOCK_MASTER_BROKER_REQUEST: "ONESTOCK_MASTER_BROKER_REQUEST",
    // ONESTOCK_MASTER_BROKER_RESPONSE: "ONESTOCK_MASTER_BROKER_RESPONSE",

    VALIDATE_OFFER_API: "master/offer/validate-offer",
    APPLIED_CAMPAIGN_OFFER_API: "master/offer/fetch-campaign-offer",

    UPDATE_LAST_ACTIVE_PRODUCT_API: "master/update-last-active-product",

    PENDING_CALL_API: "master/customer-pending-actions",
    PENDING_CALL_SAGA: "PENDING_CALL_SAGA",
    PENDING_CALL_REQUEST: "PENDING_CALL_REQUEST",
    PENDING_CALL_RESPONSE: "PENDING_CALL_RESPONSE",
    UPGRADTION_AMOUNT_API: 'upgrade-product-plan'

}


export { masterConstants };
