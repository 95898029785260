import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";

import { CustomerRoute, OnboardingRoute } from "helper/route";
import LayoutContainer from "containers/Layout";
import JarvisPortfolioOnboardingNavigator from "./JarvisPortfolioOnboardingNavigator";

// pages
import PortfolioDashboard from "pages/JarvisPortfolio/PortfolioDashboard";
import PortfolioInvestMore from "pages/JarvisPortfolio/PortfolioInvestMore";
import PortfolioStartSIP from "pages/JarvisPortfolio/PortfolioStartSIP";
import PortfolioTransaction from "pages/JarvisPortfolio/PortfolioTransaction";
import PortfolioActionCentre from "pages/JarvisPortfolio/PortfolioActionCentre";
import PortfolioExistingHolding from "pages/JarvisPortfolio/PortfolioExistingHolding";



const JarvisPortfolioNavigator = (props) => {
    const { match } = props;
    const isAuthenticated = useSelector((state) => state.customer.isAuthenticated);

    // Product onboaridng completed
    const isPortfolioOnboardingCompleted = useSelector((state) => state.onboarding.isOnboardingCompleted);
    const isRMSonboardingCompleted = useSelector((state) => state.jarvisRMS.rmsOnboarding.isOnboardingCompleted);
    const isOnestockonboardingCompleted = useSelector((state) => state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted);

    const anyOnboardingCompleted = (isPortfolioOnboardingCompleted || isRMSonboardingCompleted || isOnestockonboardingCompleted);

    const redirectTo = ()=>{
        let restPath = "dashboard";
        if(!isPortfolioOnboardingCompleted){
            restPath = "onboarding";
        }

        return `${match.path}/${restPath}`;
    }

    return (
        <Switch>
            {/* 1 - Redirection to */}
            <Redirect exact from={match.path} to={redirectTo()} />
            
            {/* 2 -  Child Navigator */}
            <OnboardingRoute
                isAuthenticated={isAuthenticated}
                isOnboardingCompleted={isPortfolioOnboardingCompleted}
                redirectOnboardingIncomplete={`${match.path}/dashboard`}
                path={`${match.path}/onboarding`}
                component={JarvisPortfolioOnboardingNavigator}
            />
            
            {/* 3 - With layout pages */}
            <LayoutContainer {...props}>
                <Switch>
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/dashboard`}
                        component={PortfolioDashboard}
                    />

                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isPortfolioOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/action-centre`}
                        component={PortfolioActionCentre}
                    />

                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isPortfolioOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/invest-more`}
                        component={PortfolioInvestMore}
                    />

                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isPortfolioOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/start-sip`}
                        component={PortfolioStartSIP}
                    />

                    <CustomerRoute
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isPortfolioOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/transactions`}
                        component={PortfolioTransaction}
                    />
                    <CustomerRoute
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isPortfolioOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/existing-holding`}
                        component={PortfolioExistingHolding}
                    />
                </Switch>
            </LayoutContainer>
            <Redirect to={`not-found`} />
        </Switch>
    );
};

export default JarvisPortfolioNavigator;