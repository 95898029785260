import { call, put, takeLatest } from "@redux-saga/core/effects";
import { jarvisRMSTransactionConstants } from "./jarvisRMSTransactionConstants";
import { jarvisRMSTransactionParsers } from "./jarvisRMSTransactionParsers";
import { jarvisRMSTransactionApis } from "./jarvisRMSTransactionApis";

function* rmsTransactionListSaga({ payload }) {
    try {
        yield put({
            type: jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_REQUEST,
        });
        const res = yield call(
            jarvisRMSTransactionApis.getRMSTransactionList,
            payload
        );
        const parsedRes = jarvisRMSTransactionParsers.rmsTransactionList(res);
        yield put({
            type: jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_RESPONSE,
            payload: parsedRes,
        });

    } catch (e) {
        yield put({
            type: jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_RESPONSE,
            payload: {},
        });
    }
}

function* rmsTransactionCallDetailSaga(payload) {
    try {
        yield put({
            type: jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_REQUEST,
        });
        const res = yield call(
            jarvisRMSTransactionApis.getRMSTransactionDetail,
            payload
        );
        const parsedRes = jarvisRMSTransactionParsers.rmsTransactionDetail(res);
        yield put({
            type: jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_RESPONSE,
            payload: parsedRes,
        });
    } catch (e) {
        console.log("error in saga");
        yield put({
            type: jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_RESPONSE,
            payload: {},
        });
    }
}

export function* registerJarvisRMSTransactionSagas() {
    yield takeLatest(
        jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_SAGA,
        rmsTransactionListSaga
    );
    yield takeLatest(
        jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_SAGA,
        rmsTransactionCallDetailSaga
    );
}
