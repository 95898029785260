import React, { Component } from "react";
import OnestockDashboardStats from "components/JarvisOnestock/OnestockDashboardStats";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Table from "components/common/Table";
import HoldingTableStockField from "components/JarvisOnestock/HoldingTableStockField";
import { formatHelper } from "helper/format";
import OnestockDashboardCard from "components/JarvisOnestock/OnestockDashboardCard";
import { dateTimeHelper } from "helper/dateTime";
import { localStore } from "stores/localStorage";
import MobileOnestockDashboardStats from "components/JarvisOnestock/MobileOnestockDashboardStats";
import MobileResponsive from "components/common/MobileResponsive";
import MobileOnestockHolding from "components/JarvisOnestock/MobileOnestockHolding";
import JarvisCard from "components/JarvisCard";
import { config } from "config/index";
import Box from "components/common/Box";

const columns = [
  {
    field: "stockName",
    headerText: "Stock",
    bodyCellComponent: (v, d) => (
      <>
        <HoldingTableStockField
          stockCompany={v}
          brokerName={d.brokerName}
          companyName={d.stockSymbol}
          companyType={d.marketCap}
        />
      </>
    ),
  },
  {
    field: "stockBuyDate",
    headerText: "Buy Date",
    bodyCellComponent: (v) => (
      <Text variant="small">
        {v ? dateTimeHelper.format(v, "$DD/$MM/$YYYY") : null}
      </Text>
    ),
  },
  {
    field: "stockQuantity",
    headerText: "Qty.",
  },
  {
    field: "stockBuyPrice",
    headerText: "Buy Price & Value",
    bodyCellComponent: (v, d) => (
      <>
        <Stack>
          <Text variant="small" sx={{ fontWeight: 500 }}>
            {formatHelper.currency(v, {
              config: { maximumFractionDigits: 2 },
            })}
          </Text>
          <Text variant="caption1" sx={{ color: "#A2A7AE" }}>
            {formatHelper.currency(d.stockBuyValue, {
              config: { maximumFractionDigits: 2 },
            })}
          </Text>
        </Stack>
      </>
    ),
  },
  {
    field: "stockCurrentPrice",
    headerText: "Current Price & Value",
    bodyCellComponent: (v, d) => (
      <>
        <Stack>
          <Text variant="small" sx={{ fontWeight: 500 }}>
            {formatHelper.currency(v, {
              config: { maximumFractionDigits: 2 },
            })}
          </Text>
          <Text variant="caption1" sx={{ color: "#A2A7AE" }}>
            {formatHelper.currency(d.stockCurrentValue, {
              config: { maximumFractionDigits: 2 },
            })}
          </Text>
        </Stack>
      </>
    ),
  },
  {
    field: "stockGainLoss",
    headerText: "Gain/Loss",
    bodyCellComponent: (v, d) => (
      <>
        <Stack>
          {
            v < 0 ?
              <Text variant="small" sx={{ fontWeight: 500, color: "#D44C4C" }}>
                {formatHelper.currency(v, {
                  config: { maximumFractionDigits: 2 },
                })}
              </Text>
              :
              <Text variant="small" sx={{ fontWeight: 500, color: "#45AC70" }}>
                {formatHelper.currency(v, {
                  config: { maximumFractionDigits: 2 },
                })}
              </Text>

          }
          {
            d.stockGainLossPercentage < 0 ?
              <Text variant="caption1" sx={{ color: "#D44C4C" }}>
                {(d.stockGainLossPercentage || 0) + "%"}
              </Text>
              :
              <Text variant="caption1" sx={{ color: "#45AC70" }}>
                {(d.stockGainLossPercentage || 0) + "%"}
              </Text>
          }
        </Stack>
      </>
    ),
  },
];

export class OnestockDashboardOnboardedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRevealActivated: 1,
      formData: {
        buyQuantity: 1,
        buyStockPrice: null,
      },
      isDataFetch: 0,
    };
  }

  componentDidMount() {
    const { dashboardRevealStore } = this.props;
    if (!this.props.brokerList?.length) {
      this.props.getBrokerListAction({ productCode: config.productCodes.ONESTOCK });
    }
    this.props.getDashboardStatsAction();

    this.props.getDashboardRevealAction();

    localStore.setRevealCardStatus(dashboardRevealStore?.revealStatus);

    if (dashboardRevealStore) {
      setTimeout(this.onReveal, 5000);
    }

    this.props.getExistingHoldingListAction();
  }

  componentDidUpdate() {
    const { dashboardRevealStore } = this.props;
    const { formData, isDataFetch } = this.state;

    if (formData.buyStockPrice === null && dashboardRevealStore.lTP) {
      formData.buyStockPrice = dashboardRevealStore.lTP
    }

    if (!isDataFetch) {
      this.setState({ formData, isDataFetch: 1 })
    }
  }

  handleOnBlur = (field)=>{
    const {formData} = this.state
    const {dashboardRevealStore} = this.props
    if(field === "stockPrice"){
      if(!formData.buyStockPrice){
        formData.buyStockPrice = dashboardRevealStore.lTP
      }
      
    }
    if(field === 'stockQuantity'){
      if(!formData.buyQuantity){
        formData.buyQuantity = 1
      }
    }
    this.setState({formData});
  }

  resetForm = () => {
    const { formData } = this.state
    formData.buyQuantity = 1
    formData.buyStockPrice = this.props.dashboardRevealStore.lTP
    this.setState({ formData })
  }

  onReveal = () => {
    this.setState({ isRevealActivated: 2 });
  };

  handleInputChange = ({ name, value }) => {
    const { formData } = this.state;
    formData[name] = value;
    this.setState({ formData });
  };
  // -------------------------------------------------------------------
  handleRealizedReturnSwitch = (flag) => {
    if (flag == true) {
      this.setState({
        realisedReturnStatus: true,
        RealizedDownArrowBtn: false,
        realizedUpArrowBtn: true,
      });
    }
    if (flag == false) {
      this.setState({
        realisedReturnStatus: false,
        RealizedDownArrowBtn: true,
        realizedUpArrowBtn: false,
      });
    }
  };

  handleCallReturnSwitch = (flag) => {
    if (flag == true) {
      this.setState({
        callReturnStatus: true,
        callReturnUpArrowBtn: true,
        callReturnDownArrowBtn: false,
      });
    }
    if (flag == false) {
      this.setState({
        callReturnStatus: false,
        callReturnUpArrowBtn: false,
        callReturnDownArrowBtn: true,
      });
    }
  };
  render() {
    const {
      existingHoldingListStore,
      dashboardStatsStoreLoader,
      dashboardStatsStore,
    } = this.props;
    const {
      realisedReturnStatus,
      realizedUpArrowBtn,
      RealizedDownArrowBtn,

      callReturnStatus,
      callReturnDownArrowBtn,
      callReturnUpArrowBtn,
      
    } = this.state

    const { isRevealActivated } = this.state;


    return (
      <MobileResponsive
        web={() => (
          <Stack sx={{ maxWidth: "100%", marginTop: "10px" }} mb={2}>
            <OnestockDashboardCard
              brokerList={this.props.brokerList}
              isBrokerListLoading={this.props.isBrokerListLoading}
              isBrokerListInitialCalled={this.props.isBrokerListInitialCalled}
              revealData={this.props.dashboardRevealStore}
              isRevealActivated={isRevealActivated}
              handleInputChange={this.handleInputChange}
              resetForm={this.resetForm}
              handleOnBlur= {this.handleOnBlur}
              formData={this.state.formData}
              pendingActionCall={() => this.props.pendingCallAction()}
              centrumReferalURL = {this.props.centrumReferalURL}
            />
            {/* <Stack mb={3} sx={{ maxHeight: "250px", borderRadius: "10px" }}>
              <JarvisCard SX={{
                padding:"16px 24px",
                display:"flex",
                justifyContent:"space-between",
                alignItems:'center',
                alignSelf:"stretch",
              }}>
                <Stack direction="row" gap="16px" >
                  <Box sx={{
                    display: "flex",
                    width: "42px",
                    height: "42px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6.72px",
                    flexShrink: "0",
                    borderRadius:" 26.88px",
                    background: "rgba(69, 172, 112, 0.10)",
                  }}
                  >
                    <img src={dashboardStatsStore?.overAllProfitAveragePercentage > 0 ? "/svgs/profit-booked.svg" : "/svgs/loss.svg"}width="40px" height="40px" alt= "over all profit"/>
                  </Box>
                  <Box
                  sx={{
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"center",
                    gap:"20px",
                  }}>
                    <Text sx={{
                      color:" #222B3E",
                      fontFamily:" DM Sans",
                      fontSize:" 20px",
                      fontStyle:" normal",
                      fontWeight:" 700",
                      lineHeight:" normal",
                    }}>Overall Profit & Loss</Text>
                    <Box sx={{
                      display:"flex",
                      alignItems:"center",
                      gap:"10px"
                    }}>
                      <Text sx={{
                         color:dashboardStatsStore?.overAllProfitAveragePercentage > 0?" var(--Green, #45AC70)":"#D44C4C",
                         fontFamily:" DM Sans",
                         fontSize:" 20px",
                         fontStyle:" normal",
                         fontWeight:" 500",
                         lineHeight:" normal",
                      }}>{dashboardStatsStore?.overAllProfitAveragePercentage || 0}%</Text>
                    </Box>
                  </Box>
                </Stack>
              </JarvisCard>
            </Stack> */}
            {dashboardStatsStore.investedAmount !== null ? (
              <OnestockDashboardStats
                onestockDashboardStats={dashboardStatsStore}
                onestockDashboardLoading={dashboardStatsStoreLoader}
                handleRealizedReturnSwitch = {this.handleRealizedReturnSwitch}
                handleCallReturnSwitch = {this.handleCallReturnSwitch}

                realisedReturnStatus = {realisedReturnStatus}
                RealizedDownArrowBtn = {RealizedDownArrowBtn}
                realizedUpArrowBtn = {realizedUpArrowBtn}

                callReturnStatus = {callReturnStatus}
                callReturnUpArrowBtn = {callReturnUpArrowBtn}
                callReturnDownArrowBtn = {callReturnDownArrowBtn}

              />
            ) : null}
            {existingHoldingListStore && existingHoldingListStore.length > 0 ? (
              <Stack mt={3}>
                <Text mb={1} sx={{ color: "secondary", fontWeight: 500 }}>
                  Holdings
                </Text>
                <Table
                  columns={columns}
                  data={existingHoldingListStore}
                  tbl_wrapper_sx={{
                    border: "1px solid #e3e3e3",
                    borderRadius: "8px",
                    background: "#fff",
                  }}
                />
              </Stack>
            ) : null}
          </Stack>
        )}
        mobile={() => (
          <Stack sx={{ maxWidth: "100%", marginTop: "10px" }} mb={2}>
            <OnestockDashboardCard
              brokerList={this.props.brokerList}
              isBrokerListLoading={this.props.isBrokerListLoading}
              isBrokerListInitialCalled={this.props.isBrokerListInitialCalled}
              revealData={this.props.dashboardRevealStore}
              isRevealActivated={isRevealActivated}
              handleInputChange={this.handleInputChange}
              resetForm={this.resetForm}
              handleOnBlur= {this.handleOnBlur}
              formData={this.state.formData}
              pendingActionCall={() => this.props.pendingCallAction()}
              centrumReferalURL = {this.props.centrumReferalURL}
            />
            {/* <Stack mb={3} sx={{ maxHeight: "250px", border: "1px solid #EEF0F2", borderRadius: "10px" }}>
              <JarvisCard SX={{
                padding:"16px 24px",
                display:"flex",
                justifyContent:"space-between",
                alignItems:'center',
                alignSelf:"stretch",
              }}>
                <Stack direction="row" gap="16px" >
                  <Box sx={{
                    display: "flex",
                    width: "42px",
                    height: "42px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6.72px",
                    flexShrink: "0",
                    borderRadius:" 26.88px",
                    background: "rgba(69, 172, 112, 0.10)",
                  }}
                  >
                    <img src={dashboardStatsStore?.overAllProfitAveragePercentage > 0 ? "/svgs/profit-booked.svg" : "/svgs/loss.svg"}width="40px" height="40px" alt= "over all profit"/>
                  </Box>
                  <Box
                  sx={{
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"center",
                    gap:"20px",
                  }}>
                    <Text sx={{
                      color:" #222B3E",
                      fontFamily:" DM Sans",
                      fontSize:" 20px",
                      fontStyle:" normal",
                      fontWeight:" 700",
                      lineHeight:" normal",
                    }}>Overall Profit & Loss</Text>
                    <Box sx={{
                      display:"flex",
                      alignItems:"center",
                      gap:"10px"
                    }}>
                      <Text sx={{
                         color:dashboardStatsStore?.overAllProfitAveragePercentage > 0?" var(--Green, #45AC70)":"#D44C4C",
                         fontFamily:" DM Sans",
                         fontSize:" 20px",
                         fontStyle:" normal",
                         fontWeight:" 500",
                         lineHeight:" normal",
                      }}>{dashboardStatsStore?.overAllProfitAveragePercentage || 0}%</Text>
                    </Box>
                  </Box>
                </Stack>
              </JarvisCard>
            </Stack> */}
            {dashboardStatsStore.investedAmount !== null ? (
              <MobileOnestockDashboardStats
                onestockDashboardStats={dashboardStatsStore}
                onestockDashboardLoading={dashboardStatsStoreLoader}
                handleRealizedReturnSwitch = {this.handleRealizedReturnSwitch}
                handleCallReturnSwitch = {this.handleCallReturnSwitch}

                realisedReturnStatus = {realisedReturnStatus}
                RealizedDownArrowBtn = {RealizedDownArrowBtn}
                realizedUpArrowBtn = {realizedUpArrowBtn}

                callReturnStatus = {callReturnStatus}
                callReturnUpArrowBtn = {callReturnUpArrowBtn}
                callReturnDownArrowBtn = {callReturnDownArrowBtn}
              />
            ) : null}
            {existingHoldingListStore && existingHoldingListStore.length > 0 ? (
              <>
                <Text mb={1} sx={{ color: "secondary", fontWeight: 500 }}>
                  Holdings
                </Text>
                <JarvisCard
                  sx={{
                    border: "1px solid #EEF0F2",
                    padding: "0",
                    height: "auto",
                  }}
                >
                  {existingHoldingListStore?.map((data, i) => {
                    return (
                      <>
                        <Text
                          key={`holding_listing_${i}`}
                          sx={{ padding: "15px 10px" }}
                        >
                          <MobileOnestockHolding holdingData={data} />
                        </Text>
                        <Text
                          sx={{
                            borderBottom: "1px solid #E2E2E2",
                            padding: "0px",
                          }}
                        ></Text>
                      </>
                    );
                  })}
                </JarvisCard>
              </>
            ) : null}
          </Stack>
        )}
      />
    );
  }
}

export default OnestockDashboardOnboardedContainer;
