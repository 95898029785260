import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { config } from "config";

import PrimaryTheme from "theme/primaryTheme";
import Snackbar from "components/common/Snackbar";
import JarvisDialog from "components/JarvisDialog";
import Loader from "components/common/Loader";

import CustomerNavigator from "navigator/CustomerNavigator";
import JarvisRMSNavigator from "navigator/JarvisRMSNavigator";
import JarvisPortfolioNavigator from "navigator/JarvisPortfolioNavigator";
import JarvisOneStockNavigator from "navigator/JarvisOneStockNavigator";
import KnowledgeCentreNavigator from "navigator/KnowledgeCentreNavigator";

import AppWelcomeScreen from "pages/AppWelcomeScreen";
import VerifyLink from "pages/VerifyLink";
import Login from "pages/Login";
import SignUp from "pages/SignUp";
import NotFound from "pages/NotFound";
import ForgotPassword from "pages/ForgotPassword";
import AppForceUpdate from "pages/AppForceUpdate";
import CampaignSSOLogin from "pages/CampaignSSOLogin";
import BrokerSSOLogin from "pages/BrokerSSOLogin";

import { PrivateRoute, PublicRoute } from "helper/route";

import { commonActions } from "stores/common/commonActions";

import AppHOC from "AppHOC";

// storage
import { localStore } from "stores/localStorage";

// App
import { App } from "@capacitor/app";

// Lib
import { pushNotification } from "library/pushNotification";
import { branchio } from "library/branchio";
import { tracker } from "library/tracker";
// import { uxcam } from "library/tracker/uxcam";
import { Geolocation } from '@capacitor/geolocation';
import DeactiveAccountMessageDialog from "components/Auth/DeactiveAccountMessageDialog";
import { customerActions } from "stores/customer/customerActions";
import { intercom } from "library/tracker/intercom";
import ManualDocumentSigning from "pages/Customer/ManualDocumentSigning";

const PRODUCT_ROUTES = {
    [config.productCodes.PORTFOLIO]: "jarvis-portfolio",
    [config.productCodes.RMS]: "jarvis-rms",
    [config.productCodes.ONESTOCK]: "jarvis-onestock"
}

const MainApp = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // Store: customer
    const auth = useSelector((state) => state.customer);
    const deactiveAccountInfo = useSelector((state) => state.customer.deactiveAccount);

    // Store: Common
    const hasRiskProfiles = useSelector((state) => state.common.riskProfiles.length > 0);
    const deviceId = useSelector((state) => state.common.deviceId);
    const toastData = useSelector((state) => state.common.toast);
    const dialogData = useSelector((state) => state.common.dialog);
    const appConfig = useSelector((state) => state.common.appConfig);
    const globalLoader = useSelector((state) => state.common.loading.globalLoader);
    const isAppWelcomeScreenShown = useSelector((state) => state.common.isAppWelcomeScreenShown);

    // Store: onboarding completed?
    const isOnboardingCompleted = useSelector((state) => state.onboarding.isOnboardingCompleted);
    const RMSisOnboardingCompleted = useSelector((state) => state.jarvisRMS.rmsOnboarding.isOnboardingCompleted);
    const onestockOnboardingCompleted = useSelector((state) => state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted);
    
    const anyOnboardingCompleted = (isOnboardingCompleted || RMSisOnboardingCompleted || onestockOnboardingCompleted);

    const location = useLocation();
    let appDoubleBack = 0;
    let locationStateKey = "";


    // Effect 1: On didMount
    React.useEffect(() => {
        // Check if dev environment
        if (config.environment === "DEVELOPMENT" && config.isMobileApp) {
            // alert("This is for test app");
        }
        if (!hasRiskProfiles) {
            dispatch(commonActions.getRiskProfiles());
        }

        // ask once when app opens
        pushNotification.init();
        branchio.init();
        appBackClick();             // App: Init event to handle double back click exit 
        // uxcam.startSession();
        getCurrentLocation();       // Set current location to local storage.
        changeProductOnPath();      // Change Active product based on initial path

        return () => {
            tracker.unmountApp();
        }

    }, []);

    // Effect 2: On Path Change
    React.useEffect(() => {
        if (auth.isAuthenticated) {
            redirectIfAnyOnboardingCompleted();
        }
        redirectIfForceUpdate();
    }, [location.pathname]);

    // Effect 3: On Auth Change
    React.useEffect(() => {
        tracker.init({
            isLoggedIn: auth.isAuthenticated,
            loginProfile: auth.loginProfile,
            deviceId: deviceId,
        });

        if (auth.isAuthenticated) {
            tracker.initTracker();
        }

        const getAppConfig = async () => {
            if (auth.isAuthenticated && config.isMobileApp) {
                const { version } = await App.getInfo();
                const operatingSystem = config.platform;
                dispatch(
                    commonActions.getAppConfig({
                        appVersion: version,
                        operatingSystem,
                    })
                );
            }
        };
        getAppConfig();
    }, [auth.isAuthenticated, auth.loginProfile, deviceId, dispatch]);


    // Effect 3: On deviceId change.
    React.useEffect(() => {
        const updateDeviceId = async () => {
            if (!deviceId) {
                await pushNotification.getToken((token) =>
                    dispatch(commonActions.updateDeviceId(token))
                );
                // const deviceId =
                // dispatch(commonActions.updateDeviceId(deviceId));
            }
        }

        updateDeviceId();
    }, [deviceId, dispatch]);


    // change product path in local storage based on first URL hit.
    const changeProductOnPath = () => {
        // set Active product based on direct path.
        const urlPath = location.pathname;
        Object.keys(PRODUCT_ROUTES).some(p => {
            if (urlPath.search(PRODUCT_ROUTES[p]) !== -1) {
                localStore.setActiveProduct(p);
                return true;
            }
            return false;
        });
    }


    // Redirect if force update
    const redirectIfForceUpdate = () => {
        if (
            auth.isAuthenticated &&
            config.isMobileApp &&
            appConfig.forceUpdate
        ) {
            history.push("/appForceUpdate");
        }
    }

    // Redorect if onboarding completed
    const redirectIfAnyOnboardingCompleted = () => {
        const urlPath = location.pathname;
        const restrictUrls = ["/customer/product-info"];
        let redirectPath = "";

        if (anyOnboardingCompleted) {
            if (restrictUrls.includes(urlPath)) {
                const activeProduct = localStore.getActiveProduct();

                if (activeProduct && PRODUCT_ROUTES[activeProduct]) {
                    redirectPath = `/${PRODUCT_ROUTES[activeProduct]}/dashboard`
                } else if (isOnboardingCompleted) {
                    redirectPath = "/jarvis-portfolio/dashboard";
                } else if (RMSisOnboardingCompleted) {
                    redirectPath = "/jarvis-rms/dashboard";
                } else if (onestockOnboardingCompleted) {
                    redirectPath = "/jarvis-onestock/dashboard";
                }
            }
        }

        if (auth.isAuthenticated && redirectPath) {
            history.push(redirectPath);
        }
    }

    // App : Handle double click exit.
    const appBackClick = () => {
        if (!config.isMobileApp) {
            return false;
        }

        App.addListener("backButton", (e) => {
            if (appDoubleBack) {
                let confirm = window.confirm("Do you want to exit the app?");
                if (confirm) {
                    App.exitApp();
                }
            } else {
                window.history.back();
                if (
                    window.history.state &&
                    window.history.state.key === locationStateKey
                ) {
                    let confirm = window.confirm(
                        "Do you want to exit the app?"
                    );
                    if (confirm) {
                        App.exitApp();
                    }
                }
                locationStateKey = window.history.state.key;
            }

            appDoubleBack = 1;
            setTimeout(() => {
                appDoubleBack = 0;
            }, 350);
        });
    };


    const getInitialPath = () => {
        let path = "/login";
        if (!isAppWelcomeScreenShown && config.isMobileApp) {
            path = "/appWelcome";
        }
        return path;
    };

    //** Set publicRedirectTo path
    const getPublicRedirectTo = () => {
        // default path
        let publicRedirectTo = "/customer/profile";

        // Set Active product through URL params
        const urlParams = new URLSearchParams(window.location.search);
        const productType = urlParams.get("product");
        if (productType) {
            localStore.setActiveProduct(productType);
        }

        if (!auth.isAuthenticated) {
            return '/';
        }

        // get active product
        const activeProduct = localStore.getActiveProduct();

        // Jarvis Info Page for new customer.
        const seenJarvisInfoPage = localStore.getSeenJarvisInfo();  // undefined | 0 | 1
        
        if (!activeProduct && !seenJarvisInfoPage) {
            return '/customer/product-info'
        }

        // Active product path
        let productPath = "";
        if (activeProduct) {
            switch (activeProduct.toUpperCase()) {
                case config.productCodes.PORTFOLIO:
                    productPath = "/jarvis-portfolio";
                    break;
                case config.productCodes.RMS:
                    productPath = "/jarvis-rms";
                    break;
                case config.productCodes.ONESTOCK:
                    productPath = "/jarvis-onestock";
                    break;

                default:
            }
        }

        // Selete based on any onboarding completed.
        if (anyOnboardingCompleted) {
            if (isOnboardingCompleted) {
                publicRedirectTo = `/jarvis-portfolio/dashboard`;
            } else if (RMSisOnboardingCompleted) {
                publicRedirectTo = `/jarvis-rms/dashboard`;
            } else if (onestockOnboardingCompleted) {
                publicRedirectTo = `/jarvis-onestock/dashboard`;
            } else if (activeProduct && productPath) {
                publicRedirectTo = `${productPath}/dashboard`;
            }

        } else {

            // Redirect Case 1: has active product 
            if (activeProduct && productPath) {
                publicRedirectTo = `${productPath}/onboarding/overview`;
            }

            // Redirect Case 2: no active product & first visit
            if (!activeProduct && !seenJarvisInfoPage) {
                // publicRedirectTo = "/customer/jarvis-info";              //NOTE: now onwards this page is ignore.
                publicRedirectTo = "/customer/product-info";
                localStore.setSeenJarvisInfo(1);
            }

            // Redirect Case 3: no active product & no first visit
            if (!activeProduct && seenJarvisInfoPage) {
                publicRedirectTo = "/customer/product-info";
            }
        }


        return publicRedirectTo
    }
    const publicRedirectTo = getPublicRedirectTo();

    const getCurrentLocation = async () => {
        const gl = await Geolocation.getCurrentPosition();
        if (gl) {
            localStore.setGeolocation({
                latitude: gl.coords.latitude,
                longitude: gl.coords.longitude
            });
        }
    };

    const handleDeactiveAccountMessageClose = () => {
        dispatch(customerActions.resetDeactiveAccountData());
    }

    const handleDialogClose = () => {
        dispatch(commonActions.resetDialog());
    }
    const handleDialogConfirm = () => {
        if (dialogData && dialogData.onCloseTakeToIntercom) {
            intercom.show();
        }
       dispatch(commonActions.resetDialog());
    }

    return (
        <div
            style={{
                paddingTop: config.isMobileApp
                    ? `var(--safe-area-inset-top)`
                    : 0,
                paddingBottom: config.isMobileApp
                    ? `var(--safe-area-inset-bottom)`
                    : 0,
                paddingLeft: config.isMobileApp
                    ? `var(--safe-area-inset-left)`
                    : 0,
                paddingRight: config.isMobileApp
                    ? `var(--safe-area-inset-right)`
                    : 0,
                position: "relative",
            }}
        >
            {config.isMobileApp && <div style={{ height: `var(--safe-area-inset-top)`, backgroundColor: "white", position: "fixed", zIndex: 99999, top: 0, left: 0, width: "100%" }}></div>}
            <AppHOC>
                <PrimaryTheme>
                    <Loader position="fixed" size="lg" loading={globalLoader} />
                    <CssBaseline />

                    <JarvisDialog
                        disableCloseIcon
                        open={dialogData.open}
                        onClose={handleDialogClose}
                        onConfirm={handleDialogConfirm}
                        title={dialogData.heading}
                        content={dialogData.message}
                    />

                    <Snackbar
                        open={toastData.open}
                        onClose={() => dispatch(commonActions.resetToast())}
                        message={toastData.message}
                        autoHideDuration={toastData.timeout}
                    />
                    <DeactiveAccountMessageDialog
                        isOpen={deactiveAccountInfo.isDialogOpen}
                        onClose={handleDeactiveAccountMessageClose}
                        message={deactiveAccountInfo.message}
                        email={deactiveAccountInfo.email}
                    />
                    <Switch>
                        <Route
                            exact
                            path="/sso/:authkey"
                            component={CampaignSSOLogin}
                            auth={auth}
                        />
                        <Route
                            exact
                            path="/broker-sso"
                            component={BrokerSSOLogin}
                            auth={auth}
                        />
                        <PublicRoute
                            exact
                            path="/login"
                            component={Login}
                            auth={auth}
                            redirectTo={publicRedirectTo}
                        />
                        <Route
                            exact
                            path="/verify-email"
                            component={VerifyLink}
                            auth={auth}
                        />
                        <PublicRoute
                            exact
                            path="/appWelcome"
                            component={AppWelcomeScreen}
                            auth={auth}
                            redirectTo={publicRedirectTo}
                        />
                        <PublicRoute
                            exact
                            path="/signup"
                            component={SignUp}
                            auth={auth}
                            redirectTo={publicRedirectTo}
                        />
                        <PublicRoute
                            exact
                            path="/forgot-password"
                            component={ForgotPassword}
                            auth={auth}
                            redirectTo={publicRedirectTo}
                        />

                        <Route
                            exact
                            path={`/appForceUpdate`}
                            component={AppForceUpdate}
                        />

                        {/* customer details related navigators */}
                        <PrivateRoute
                            path="/customer"
                            auth={auth}
                            component={CustomerNavigator}
                        />

                        {/* product jarvis portfolio (advisory) related navigators */}
                        <PrivateRoute
                            path="/jarvis-portfolio"
                            auth={auth}
                            component={JarvisPortfolioNavigator}
                        />
                        {/* product jarvis portfolio (advisory) related navigators */}
                        <PrivateRoute
                            path="/manual-document-signing"
                            auth={auth}
                            component={ManualDocumentSigning}
                        />

                        {/* product jarvis rms (protect) related navigators */}
                        <PrivateRoute
                            path="/jarvis-rms"
                            auth={auth}
                            component={JarvisRMSNavigator}
                        />

                        {/* product jarvis onestock related navigators */}
                        <PrivateRoute
                            path="/jarvis-onestock"
                            auth={auth}
                            component={JarvisOneStockNavigator}
                        />

                        {/* knowledge centre related navigators */}
                        <PrivateRoute
                            path="/knowledge-centre"
                            auth={auth}
                            component={KnowledgeCentreNavigator}
                        />

                        <Redirect exact from="/" to={getInitialPath()} />
                        <Route component={NotFound} />

                    </Switch>
                </PrimaryTheme>
            </AppHOC>
        </div>
    );
};

// disable consoles for non debug mode
if (!config.debug) {
    if (!window.console) window.console = {};
    var methods = ["log", "debug", "info", "table"];
    for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function () { };
    }
}

export default MainApp;
