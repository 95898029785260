import React, { Component } from "react";

//Common Components
import JarvisCard from "components/JarvisCard";
import SectionTitle from "components/SectionTitle";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Divider from "components/common/Divider";
import Box from "components/common/Box";
import JarvisButton from "components/JarvisButton";
import JarvisDialog from "components/JarvisDialog";
import PageTitle from "components/PageTitle";
import PortfolioChip from "components/JarvisChip";

//Action Center Components
import JarvisRMSStockCard from "components/jarvisRMS/JarvisRMSStockCard";
import JarvisRMSActionCenterSell from "components/jarvisRMS/JarvisRMSActionCenterSell";

// RMS Action Center Store
import { jarvisRMSActionCenterApis } from "stores/jarvisRMS/jarvisRMSActionCenter/jarvisRMSActionCenterApis";
import { jarvisRMSActionCenterParsers } from "stores/jarvisRMS/jarvisRMSActionCenter/jarvisRMSActionCenterParsers";
import { localStore } from "stores/localStorage";

const successMessage = {
    title: "Awesome!",
    content: "Your portfolio has been updated successfully.",
    actionText: "Continue",
    icon: "/images/svgs/success.gif",
};

export default class RMSActionCenterContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rmsReblanceSellData: [],
            selectedPortfolio: {},
            finalPortfolioDetails: {},
            stockSearch: {
                selectionPortfolioData: {},
                selectionStock: "",
            },
            tableRefreshCount: 0,
            isSellDialogOpen: false,
            isAllStocksSelected: true,
            isStockSelectionComplete: false,
            isSuccessDialogOpen: false,
            apiSucessMsg: "",

            activePortfolioId: null,
        };
    }

    componentDidMount() {
        this.getActionCenterData();
    }

    getActionCenterData = async () => {
        let data = [];
        let parsedData = [];

        try{
            data = await jarvisRMSActionCenterApis.getRMSActionCenterData();
            parsedData = jarvisRMSActionCenterParsers.getRMSActionCenterDataParser(data);
        }catch(e){
            parsedData = [];
            console.log("No Data", e);
        }

        let activePortfolioId =null;
        if(parsedData && parsedData.length > 0){
            activePortfolioId = parsedData[0].portfolioId;
        }
        
        this.setState({ rmsReblanceSellData: parsedData, activePortfolioId:  activePortfolioId });
    };

    handleProceedRms = (portfolio) => {
        const selectedPortfolio = { ...portfolio };
        const newStockData = selectedPortfolio.stockData.map((item) => {
            return {
                isSelected: true,
                ...item,
            };
        });
        selectedPortfolio.stockData = newStockData;
        this.setState({
            selectedPortfolio: selectedPortfolio,
            isSellDialogOpen: true,
        });
    };

    handleAllSelectChange = (eventValue) => {
        if (eventValue) {
            const currentPortfolio = this.state.selectedPortfolio;
            const newStockData = currentPortfolio.stockData.map((item) => {
                item.isSelected = true;
                return item;
            });
            currentPortfolio.stockData = newStockData;
            this.setState({
                selectedPortfolio: currentPortfolio,
                tableRefreshCount: this.state.tableRefreshCount + 1,
                isAllStocksSelected: true,
            });
        } else {
            const { selectedPortfolio } = this.state;
            const currentPortfolio = selectedPortfolio;
            const newStockData = currentPortfolio.stockData.map((item) => {
                item.isSelected = false;
                return item;
            });
            currentPortfolio.stockData = newStockData;
            this.setState({
                selectedPortfolio: currentPortfolio,
                tableRefreshCount: this.state.tableRefreshCount + 1,
                isAllStocksSelected: false,
            });
        }
    };

    handleSelectedStockChange = (eventValue, stockId) => {
        if (eventValue) {
            const currentPortfolio = this.state.selectedPortfolio;
            const newStockData = currentPortfolio.stockData.map((item) => {
                console.log("item : ", item);
                if (item.stockId === stockId) {
                    item.isSelected = true;
                    return item;
                } else {
                    return item;
                }
            });
            currentPortfolio.stockData = newStockData;
            this.setState({
                selectedPortfolio: currentPortfolio,
                tableRefreshCount: this.state.tableRefreshCount + 1,
            });
        } else {
            const currentPortfolio = this.state.selectedPortfolio;
            const newStockData = currentPortfolio.stockData.map((item) => {
                if (item.stockId === stockId) {
                    item.isSelected = false;
                    return item;
                } else {
                    return item;
                }
            });
            currentPortfolio.stockData = newStockData;
            this.setState({
                selectedPortfolio: currentPortfolio,
                tableRefreshCount: this.state.tableRefreshCount + 1,
                isAllStocksSelected: false,
            });
        }
    };

    handleMarkSold = () => {
        const currentPortfolio = { ...this.state.selectedPortfolio };
        const newStockDetails = currentPortfolio.stockData.filter(
            (item, index) => item.isSelected
        );
        currentPortfolio.stockData = newStockDetails;
        this.setState({
            finalPortfolioDetails: currentPortfolio,
            isStockSelectionComplete: true,
        });
    };

    handleDataChange = (updatedData) => {
        const currentConfirmedPortfolio = this.state.finalPortfolioDetails;
        currentConfirmedPortfolio.stockData = updatedData;
        this.setState({ finalPortfolioDetails: currentConfirmedPortfolio });
    };

    handleGoBack = () => {
        this.setState({ isStockSelectionComplete: false });
    };

    handleDialogClose = () => {
        this.setState({
            isSellDialogOpen: false,
            tableRefreshCount: 0,
            selectedPortfolio: {},
            finalPortfolioDetails: {},
            isAllStocksSelected: true,
            isStockSelectionComplete: false,
        });
    };

    handleSelectionSearch = (e, isCloseClicked = false) => {
        const { stockSearch, tableRefreshCount } = this.state;
        const currentSearchStock = { ...stockSearch };

        //Clear filter if cancel button is clicked
        if (isCloseClicked) {
            currentSearchStock.selectionStock = "";
            currentSearchStock.selectionPortfolioData = {};
            this.setState({
                stockSearch: currentSearchStock,
                tableRefreshCount: tableRefreshCount + 1,
            });
            return;
        }

        const searchValue = e.target.value;
        const currentPortfolio = { ...this.state.selectedPortfolio };

        const filteredStock = currentPortfolio.stockData.filter((item) =>
            item.stockName.toLowerCase().includes(searchValue.toLowerCase())
        );

        currentPortfolio.stockData = filteredStock;
        currentSearchStock.selectionStock = searchValue;
        currentSearchStock.selectionPortfolioData = currentPortfolio;

        this.setState({
            stockSearch: currentSearchStock,
            tableRefreshCount: tableRefreshCount + 1,
        });
    };

    handleSubmit = async () => {
        const { finalPortfolioDetails } = this.state;
        const { stockData, rmsCallId } = finalPortfolioDetails;

        const payload = stockData.map((item) => {
            return {
                stockTransactionId: item.stockTransactionId,
                stockSellPrice: item.suggestedSellPrice,
                stockSellDate: new Date(),
            };
        });

        try {
            const response = await jarvisRMSActionCenterApis.submitRMSActionCenterData(payload, rmsCallId);
            this.props.pendingCallAction()
            this.setState({
                isSuccessDialogOpen: true,
                isSellDialogOpen: false,
                apiSucessMsg: response.message,
                tableRefreshCount: 0,
                selectedPortfolio: {},
                finalPortfolioDetails: {},
                isAllStocksSelected: true,
                isStockSelectionComplete: false,
            });
            this.getActionCenterData();
        } catch (err) {
            console.log(err);
        }
    };
    handleSuccessDialogClose = () => {
        this.getActionCenterData();
        this.setState({ isSuccessDialogOpen: false });
    };

    handlePortfolioTabClick = (portfolio) => {
        this.setState({ activePortfolioId: portfolio.portfolioId })
    }

    render() {
        const {
            rmsReblanceSellData,
            isSellDialogOpen,
            selectedPortfolio,
            tableRefreshCount,
            finalPortfolioDetails,
            isStockSelectionComplete,
            isAllStocksSelected,
            isSuccessDialogOpen,
            stockSearch,
            apiSucessMsg,
        } = this.state;

        const isMobileView = localStore.getMobileView();

        const activeCallData = rmsReblanceSellData.find(rms => rms.portfolioId === this.state.activePortfolioId);

        return (
            <>
                {isSellDialogOpen && (
                    <JarvisRMSActionCenterSell
                        handleSelectedStockChange={
                            this.handleSelectedStockChange
                        }
                        handleGoBack={this.handleGoBack}
                        handleDialogClose={this.handleDialogClose}
                        handleDataChange={this.handleDataChange}
                        handleMarkSold={this.handleMarkSold}
                        handleAllSelectChange={this.handleAllSelectChange}
                        handleSelectionSearch={this.handleSelectionSearch}
                        handleSubmit={this.handleSubmit}
                        stockSearch={stockSearch}
                        isDialogOpen={isSellDialogOpen}
                        portfolioData={selectedPortfolio}
                        finalPortfolioDetails={finalPortfolioDetails}
                        tableRefreshCount={tableRefreshCount}
                        isStockSelectionComplete={isStockSelectionComplete}
                        isAllStocksSelected={isAllStocksSelected}
                        selectedPortfolioStockDetails={
                            selectedPortfolio.stockData
                        }
                    />
                )}

                {
                    !isMobileView ?
                        <PageTitle
                            heading="Action Centre"
                            subheading="Sell calls alerts will appear here"
                        />
                        : null
                }

                <JarvisCard sx={{ px: 0, py: 0 }}>
                    {
                        rmsReblanceSellData.length === 0 ?
                            (
                                <Stack sx={{ height: "100%" }} alignItems={'center'} justifyContent={'center'}>
                                    <Text variant="h6">Awesome!</Text>
                                    <Text >No sell calls pending</Text>
                                </Stack>
                            )
                            :
                            <>
                                {
                                    !isMobileView ?
                                        rmsReblanceSellData.map((portfolio, index) => {
                                            return (
                                                <React.Fragment key={`${index}_`}>
                                                    <Box sx={{ px: 3, pt: 2, pb: 2.5, }}>
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            mb={2}
                                                        >
                                                            <SectionTitle
                                                                sx={{ mb: 1.75 }}
                                                                heading={
                                                                    <>
                                                                        Portfolio Name ~
                                                                        <Text
                                                                            component="span"
                                                                            color="#A2A7AE"
                                                                            variant="subtitle2"
                                                                            mx={1}
                                                                        >
                                                                            {
                                                                                portfolio.portfolioName
                                                                            }
                                                                        </Text>
                                                                    </>
                                                                }
                                                            />
                                                            <JarvisButton
                                                                size="small"
                                                                onClick={() => {
                                                                    this.handleProceedRms(
                                                                        portfolio
                                                                    );
                                                                }}
                                                            >
                                                                Proceed to Sell
                                                            </JarvisButton>
                                                        </Stack>

                                                        <Stack
                                                            direction="row"
                                                            alignItems="stretch"
                                                            justifyContent="flex-start"
                                                            sx={{
                                                                position: "relative",
                                                                flexWrap: "wrap",
                                                            }}
                                                        >
                                                            {portfolio.stockData.map(
                                                                (stockdetails, index) => {
                                                                    return (
                                                                        <React.Fragment
                                                                            key={`stock_${index}`}
                                                                        >
                                                                            {index < 9 ? (
                                                                                <Stack
                                                                                    sx={{
                                                                                        flexGrow: 0,
                                                                                        width: {
                                                                                            xs: 1,
                                                                                            sm: 0.5,
                                                                                            md: 0.33,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    <JarvisRMSStockCard
                                                                                        stockDetails={
                                                                                            stockdetails
                                                                                        }
                                                                                        portfolio={
                                                                                            portfolio
                                                                                        }
                                                                                        currentIndex={
                                                                                            index
                                                                                        }
                                                                                        handleProceed={
                                                                                            this.handleProceedRms
                                                                                        }

                                                                                    />
                                                                                </Stack>
                                                                            ) : null}
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </Stack>
                                                    </Box>
                                                    {index + 1 < rmsReblanceSellData.length && (
                                                        <Divider sx={{ mt: 2, mb: 2 }} />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })
                                        :
                                        <>
                                            <Stack mb={2} mt={2} sx={{ height: "100%" }}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={1}
                                                    sx={{ overflow: "auto", width: "100%", paddingBottom: "5px", minHeight:"50px" }}
                                                >
                                                    {rmsReblanceSellData?.map((data, index) => {
                                                        return (
                                                            <PortfolioChip
                                                                key={`Stock_list_${index}`}
                                                                data={data}
                                                                mobileChip
                                                                isActive={data.portfolioId === this.state.activePortfolioId}
                                                                handleChipClick={() => this.handlePortfolioTabClick(data)}
                                                            />
                                                        );
                                                    })}
                                                </Stack>

                                                <Stack flex={1} mt={2}>
                                                    {activeCallData && activeCallData.stockData.map((stockdetails, index) => {

                                                        return (
                                                            <>
                                                                <React.Fragment
                                                                    key={`stock_mobile_${index}`}
                                                                >
                                                                    {index < 9 ? (
                                                                        <Stack
                                                                            sx={{
                                                                                flexGrow: 0,
                                                                                width: {
                                                                                    xs: 1,
                                                                                    sm: 0.5,
                                                                                    md: 0.33,
                                                                                },
                                                                            }}
                                                                        >
                                                                            <JarvisRMSStockCard
                                                                                stockDetails={
                                                                                    stockdetails
                                                                                }
                                                                                portfolio={
                                                                                    activeCallData
                                                                                }
                                                                                currentIndex={
                                                                                    index
                                                                                }
                                                                                handleProceed={
                                                                                    this.handleProceedRms
                                                                                }

                                                                            />
                                                                        </Stack>
                                                                    ) : null}
                                                                </React.Fragment>

                                                            </>
                                                        );
                                                    })}

                                                </Stack>

                                                <Stack mt={2}>
                                                    {
                                                        activeCallData ?
                                                            <Stack>
                                                                <JarvisButton
                                                                    // size="small"
                                                                    sx={{ width: "100%" }}
                                                                    onClick={() => {
                                                                        this.handleProceedRms(
                                                                            activeCallData
                                                                        );
                                                                    }}
                                                                >
                                                                    Proceed to Sell
                                                                </JarvisButton>
                                                            </Stack>
                                                            : ""
                                                    }
                                                </Stack>
                                            </Stack>
                                        </>
                                }
                            </>
                    }


                </JarvisCard>

                <JarvisDialog
                    disableCloseIcon
                    open={isSuccessDialogOpen}
                    onClose={this.handleSuccessDialogClose}
                    onConfirm={this.handleSuccessDialogClose}
                    title={apiSucessMsg}
                    content={successMessage.content}
                    icon={{
                        src: successMessage.icon,
                        height: 120,
                        width: 120,
                    }}
                    button={{
                        text: successMessage.actionText,
                        disableEndIcon: true,
                    }}
                />
            </>
        );
    }
}
