import _get from "lodash/get";

const jarvisRMSActionCenterParsers = {};

jarvisRMSActionCenterParsers.getRMSActionCenterDataParser = (res) => {
    let data = [];
    if (res && res.response) {
        res = res.response;
    }
    if (!res || res.constructor !== Array) {
        return data;
    }

    const parserStockData = (stockArray) =>
        stockArray.map((e) => ({
            stockId: _get(e, "stockId", 0),
            stockTransactionId: _get(e, "stockTransactionId", 0),
            stockName: _get(e, "stockName", ""),
            stockSymbol: _get(e, "stockSymbol", ""),
            stockQuantity: _get(e, "stockQuantity", 0),
            stockPrice: _get(e, "stockPrice", 0),
            stockValue: _get(e, "stockValue", 0),
            suggestedSellPrice: _get(e, "suggestedSellPrice", 0),
            suggestedSellValue: _get(e, "suggestedSellValue", 0),
            stockMarketCap: _get(e, "stockMarketCap", ""),
            stockSector: _get(e, "stockSector", ""),
        }));

    data = res.map((e) => ({
        rmsCallId: _get(e, "rmsCallId", 0),
        rmsCallExpiryDate: _get(e, "rmsCallExpiryDate", ""),
        rmsCallStatus: _get(e, "rmsCallStatus", ""),
        portfolioId: _get(e, "portfolioId", ""),
        portfolioName: _get(e, "portfolioName", ""),
        brokerPlatform: _get(e, "brokerPlatform", ""),
        brokerName: _get(e, "brokerName", ""),
        stockData: parserStockData(_get(e, "stockData", [])),
    }));

    return data;
};

export { jarvisRMSActionCenterParsers };
