export const customerConstants = {
    // 
    LOGIN_DATA: "LOGIN_DATA",
    LOGIN_API: "v1/auth/login",
    RESET_AUTH_DATA: "RESET_AUTH_DATA",
    UPDATE_LOGIN_EMAIL: "UPDATE_LOGIN_EMAIL",
    UPDATE_EMAIL_VERIFIED: "UPDATE_EMAIL_VERIFIED",

    FORGOT_PASSWORD_API: "v1/auth/forgot-password",
    FORGOT_PASSWORD_VERIFY_OTP_API: "v1/auth/forgot-password/verify-otp",
    RESET_PASSWORD_API: "v1/auth/forgot-password/reset",
    CHANGE_PASSWORD_API: "v1/auth/change-password",

    SOCIAL_LOGIN_API: "v1/auth/social/step-1/login",
    SOCIAL_REQUEST_OTP: "v1/auth/social/step-2/request-otp",
    SOCIAL_VERIFY_OTP: "v1/auth/social/step-3/complete",

    // Signup
    LOGOUT_SAGA: "LOGOUT_SAGA",
    REQUEST_OTP_API: "v1/auth/signup/step-1/request-otp",
    VERIFY_OTP_API: "v1/auth/signup/step-2/verify-otp",
    RESEND_OTP_API: "v1/auth/signup/step-1/resend-otp",
    SIGNUP_API: "v1/auth/signup/step-3/complete",

    // Signup new
    INIT_SIGNUP_API: "/customer/signup/initiate-signup",
    RESEND_SIGNUP_OTP_API: "/customer/signup/request-otp",
    CONFIRM_SIGNUP_API: "/customer/signup/complete-signup",

    // Login new 
    REQUEST_LOGIN_OTP: "/customer/login/request-otp",
    VERIFY_LOGIN_OTP: "/customer/login/verify-otp",


    PROFILE_SAGA: "PROFILE_SAGA",
    PROFILE_REQUEST: "PROFILE_REQUEST",
    PROFILE_RESPONSE: "PROFILE_RESPONSE",
    PROFILE_API: "v1/customer/profile",

    UPDATE_RISK_PROFILE_API: "/v1/onboarding/risk-strategy",
    UPDATE_RISK_PROFILE_DATA: "UPDATE_RISK_PROFILE_DATA",

    UPDATE_PROFILE_API: "/v1/profile/",
    UPDATE_PROFILE_DATA: "UPDATE_PROFILE_DATA",

    UPDATE_INVESTMEN_STRATEGY_API: "/v1/onboarding/risk-strategy",
    UPDATE_INVESTMEN_STRATEGY_DATA: "UPDATE_INVESTMEN_STRATEGY_DATA",

    SUBSCRIPTION_LIST_SAGA: "SUBSCRIPTION_LIST_SAGA",
    SUBSCRIPTION_LIST_REQUEST: "SUBSCRIPTION_LIST_REQUEST",
    SUBSCRIPTION_LIST_RESPONSE: "SUBSCRIPTION_LIST_RESPONSE",
    SUBSCRIPTION_LIST_API: "v1/customer/subscription",

    RENEWAL_PRICING_DETAILS_SAGA: "RENEWAL_PRICING_DETAILS_SAGA",
    RENEWAL_PRICING_DETAILS_REQUEST: "RENEWAL_PRICING_DETAILS_REQUEST",
    RENEWAL_PRICING_DETAILS_RESPONSE: "RENEWAL_PRICING_DETAILS_RESPONSE",
    RENEWAL_PRICING_DETAILS_API: "v1/portfolio/pricing-details-renewal",

    MOBILE_REQUEST_OTP: "/v1/customer/mobile-request-otp",
    VERIFY_MOBILE_OTP: "/v1/customer/verify-mobile-otp",
    VERIFY_EMAIL: "/customer/signup/email-verification",


    EMAIL_REQUEST_OTP: '/v1/auth/login/request-otp',
    VERIFY_EMAIL_OTP: '/v1/customer/verify-email-otp',
    CAMPAIGN_TRACKING_API: "v1/customer/campaign",

    DEACTIVATE_ACCOUNT_API: "v1/customer/account",
    SET_DEACTIVE_ACCOUNT_DATA: "SET_DEACTIVE_ACCOUNT_DATA",
    RESET_DEACTIVE_ACCOUNT_DATA: "RESET_DEACTIVE_ACCOUNT_DATA",
    CAMPAIGN_SSO_LOGIN_API: "v1/auth/ssoLogin",

    CANCEL_SUBSCRIPTION_API: "/customer/subscription/cancel",

    // For Cancellation
    REASON_FOR_CANCELLATION_REQUEST: "REASON_FOR_CANCELLATION_REQUEST",
    REASON_FOR_CANCELLATION_RESPONSE: "REASON_FOR_CANCELLATION_RESPONSE",
    REASON_FOR_CANCELLATION_SAGA: "REASON_FOR_CANCELLATION_SAGA",
    REASON_FOR_CANCELLATION_API: "master/profile/subscription-cancel-reason",

    // Edit Profile
    UPDATE_PROFILE_DETAILS: "customer/profile/update-contact-details",
    VERIFY_PROFILE_DETAILS: "customer/profile/verify-otp",

    // Products Subscription
    PRODUCT_SUBSCRIPTION_LIST_SAGA: "PRODUCT_SUBSCRIPTION_LIST_SAGA",
    PRODUCT_SUBSCRIPTION_LIST_REQUEST: "PRODUCT_SUBSCRIPTION_LIST_REQUEST",
    PRODUCT_SUBSCRIPTION_LIST_RESPONSE: "PRODUCT_SUBSCRIPTION_LIST_RESPONSE",
    PRODUCT_SUBSCRIPTION_LIST_API: "/master/profile/plan-details",

    PRODUCT_SUBSCRIPTION_DETAILS_SAGA: "PRODUCT_SUBSCRIPTION_DETAILS_SAGA",
    PRODUCT_SUBSCRIPTION_DETAILS_REQUEST: "PRODUCT_SUBSCRIPTION_DETAILS_REQUEST",
    PRODUCT_SUBSCRIPTION_DETAILS_RESPONSE: "PRODUCT_SUBSCRIPTION_DETAILS_RESPONSE",
    PRODUCT_SUBSCRIPTION_DETAILS_API: "/master/profile/plan-stats",

    SSO_CUSTOMER_DETAILS_API:"/sso/sso-authenticate",

};
