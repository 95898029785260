import { jarvisRMSDashboardOnboardedConstants } from "./jarvisRMSDashboardonboardedConstants";

const jarvisRMSDashboardOnboardedActions = {};

jarvisRMSDashboardOnboardedActions.getPortfolioStatsDataAction = () => ({
    type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_SAGA,
});

jarvisRMSDashboardOnboardedActions.getRmsStatsDataAction = () => ({
    type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_SAGA,
});

jarvisRMSDashboardOnboardedActions.getMarketCapAction = () => ({
    type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_SAGA,
});

jarvisRMSDashboardOnboardedActions.getSectorDiversificationAction = () => ({
    type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_SAGA,
});


export { jarvisRMSDashboardOnboardedActions };
