import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { dashboardActions } from "stores/dashboard/dashboardActions"
import { commonActions } from "stores/common/commonActions"
import { masterActions } from "stores/master/masterActions";

const mapStateToProps = (state) => {
    return {
        portfolioAlert: state.dashboard.portfolioAlert,
        portfolioPendingTransaction: state.dashboard.portfolioPendingTransaction,
        portfolioPendingTransactionLoading: state.dashboard.loading.portfolioPendingTransaction,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    // Get API
    getPortfolioAlertAction: dashboardActions.getPortfolioAlert,
    getPortfolioPendingTransactionAction: dashboardActions.getPortfolioPendingTransaction,
    pendingCallAction: masterActions.getPendingCall,
    
    // Dashboard Refresh
    dashboardRefreshAction: dashboardActions.dashboardRefresh,

    // Set Toast Message
    setToastAction: commonActions.setToast,
}, dispatch)

const RMSRebalanceAlertStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSRebalanceAlertStore;