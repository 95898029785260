
const jarvisOnestockDashboardConstants = {

    // Dashboard Stats Card

    JARVIS_ONESTOCK_DASHBOARD_STATS_SAGA: "JARVIS_ONESTOCK_DASHBOARD_STATS_SAGA",
    JARVIS_ONESTOCK_DASHBOARD_STATS_REQUEST: "JARVIS_ONESTOCK_DASHBOARD_STATS_REQUEST",
    JARVIS_ONESTOCK_DASHBOARD_STATS_RESPONSE: "JARVIS_ONESTOCK_DASHBOARD_STATS_RESPONSE",
    JARVIS_ONESTOCK_DASHBOARD_STATS_API: "/onestock/dashboard/portfolio-stats",

    // Dashboard Portfolio Reveal

    JARVIS_ONESTOCK_DASHBOARD_REVEAL_SAGA: "JARVIS_ONESTOCK_DASHBOARD_REVEAL_SAGA",
    JARVIS_ONESTOCK_DASHBOARD_REVEAL_REQUEST: "JARVIS_ONESTOCK_DASHBOARD_REVEAL_REQUEST",
    JARVIS_ONESTOCK_DASHBOARD_REVEAL_RESPONSE: "JARVIS_ONESTOCK_DASHBOARD_REVEAL_RESPONSE",
    JARVIS_ONESTOCK_DASHBOARD_REVEAL_API: "/onestock/dashboard/portfolio-reveal",

    // For Buy Call

    JARVIS_ONESTOCK_CALL_API: "/onestock/action-centre"

};

export { jarvisOnestockDashboardConstants };
