import { axios } from "helper/axios";
import { commonConstants } from "./commonConstants";

const commonApis = {};

commonApis.getBlogs = () => {
    return axios.get(commonConstants.BLOGS_API);
};

commonApis.getRiskAssessmentQuestions = () => {
    return axios.get(commonConstants.RISK_ASSESSMENT_QUESTIONS_API);
};

commonApis.getRiskProfiles = () => {
    return axios.get(commonConstants.RISK_PROFILES_API);
};

commonApis.getBrokers = ({ domain, productCode }) => {
    return axios.post(commonConstants.BROKERS_API, { domain, productCode });
};

commonApis.getMarketUpdate = () => {
    return axios.get(commonConstants.MARKET_UPDATES_API);
}

commonApis.getNewsArticles = (payload) => {
    const {count,page} = payload;
    var str = commonConstants.NEWS_ARTICLES_API.replace(":count", count);
    str = str.replace(":page",page);
    return axios.get(str);
}

commonApis.getAppConfig = ({ appVersion, operatingSystem }) => {
    operatingSystem = `${operatingSystem}`.toLowerCase();
    return axios.post(commonConstants.APP_CONFIG_API, { appVersion, operatingSystem });
}

commonApis.getTrackerConfig = () => {
    return axios.get(commonConstants.TRACKER_CONFIG_API);
}

commonApis.getFeatureConfig = () => {
    return axios.get(commonConstants.FEATURE_CONFIG_API);
}

commonApis.getRiskAssessmentAnswers = () => {
    return axios.get(commonConstants.RISK_ASSESSMENT_ANSWERS_API);
}

commonApis.getPaymentMethods = () => {
    return axios.get(commonConstants.PAYMENT_METHODS_API);
}

commonApis.getFeatureConfig = () => {
    return axios.get(commonConstants.FEATURE_CONFIG_API);
}

export { commonApis };
