import React from "react";

import { config } from "config";

import { withRouter } from "react-router-dom";
import Grid from "components/common/Grid";
import Box from "components/common/Box";
import Text from "components/common/Text";
import LoginForm from "components/Auth/LoginForm";
import AuthAsidePanel from "components/Auth/AuthAsidePanel";
import { customerParsers } from "stores/customer/customerParsers";
import { customerApis } from "stores/customer/customerApis";
import { validationHelper } from "helper/validation";
import { localStore } from "stores/localStorage";

import Dialog from "components/common/Dialog";
import MobileVerification from "components/SocialLogin/MobileVerification";


// appsFlyer
import { tracker } from "library/tracker"
import CampaignVerification from "components/SocialLogin/CampaignVerification";

const viewTypes = {
  LOGIN_WITH_PASSWORD: "LOGIN_WITH_PASSWORD",
  LOGIN_WITH_OTP: "LOGIN_WITH_OTP",
}

const otpRequestTimer = 30;
class LoginContainer extends React.Component {
  emailInterval = 0;
  mobileInterval = 0;
  loginOtpInterval = 0;

  constructor(props) {
    super(props);
    this.state = {
      activeViewType: viewTypes.LOGIN_WITH_OTP,

      //-> Normal Login
      formData: {
        userName: "",
        email: "",
        password: "",
        loginType: "normal",
        deviceType: "web",
        deviceId: props.deviceId,
        loginOtp: "",
      },

      formError: {
        userName: "",
        email: "",
        password: "",
      },

      requestId: "",
      isLoginRequestPassword: false,
      isLoginRequestOtp: false,
      isLoading: false,
      loginOtpResendTime: 0,

      //-> Social Login
      mobileVerifyPopup: false,
      campaignVerifyPopup: false,

      // Mobile verify
      mobileVerifyManage: {
        isRequestOtp: false,
        email: "",
      },

      //campaign verify
      campaignVerify: {
        isRequestOtp: false,
        email: '',
        mobile: 0,
        appRegistrationId: "",
      },
      emailResendTime: 0,
      mobileResendTime: 0,

      customerDetails: {},

      loginResponce: {},
    };
  }

  componentWillUnmount = () => {
    clearInterval(this.emailInterval);
    clearInterval(this.mobileInterval);
    clearInterval(this.loginOtpInterval);
  };

  componentDidMount() {
    this.setIntercomPosition();
  }

  setIntercomPosition = () => {
    // / set intercom position based on tab
    let intercomElem;
    intercomElem = document.getElementsByClassName("intercom-launcher")[0];
    if (!intercomElem) {
      intercomElem = document.getElementsByClassName("intercom-launcher-frame")[0];
    }
    if (!intercomElem) {
      intercomElem = document.getElementsByClassName("intercom-lightweight-app-launcher")[0];
    }

    if (!intercomElem) {
      console.log("intercom element not found");
    }
    else {
      intercomElem.classList.add("intercom-launcher-noTab");
      intercomElem.classList.remove("intercom-launcher-hasTab");
    }
  }
  letUserLogin = () => {
    const { loginResponce } = this.state;
    this.props.updateOnboardingDataAction(loginResponce.onboardingData);
    this.props.updateRMSOnboardingDataAction(loginResponce.rmsOnboardingData);
    this.props.updateOnestockOnboardingDataAction(loginResponce.onestockOnboardingData);


    // TODO: add onestock onboarding data action here
    this.props.updateAuthDataAction(loginResponce);
  };

  handleInputChange = (type, value) => {
    const { formData } = this.state;
    formData[type] = value;
    this.setState({ formData });
  }

  validateUserName = () => {
    let { formError, formData } = this.state;
    let isValid = false;
    formError.userName = "";

    const mobileValidation = validationHelper.mobile(formData.userName);
    const emailValidation = validationHelper.email(formData.userName);
    if (mobileValidation.isValid) {
      isValid = true;
    } else if (emailValidation.isValid) {
      isValid = true;
    } else {
      formError.userName = "Invalid Email or Phone";
    }

    this.setState({ formError });
    return isValid;
  };

  // Login-step: 1
  sendLoginOtpHandle = async ({ isFirstRequest = false } = {}) => {
    const { formData } = this.state;
    if (!this.validateUserName()) {
      return;
    }

    try {
      if (isFirstRequest) {
        await this.setState({ isLoading: true });
      }

      const reqData = {
        username: formData.userName,
        loginType: "normal"
      }
      const res = await customerApis.requestLoginOtp(reqData);
      const parsedRes = customerParsers.requestOtp(res);
      if (parsedRes.requestId) {
        this.setState({ requestId: parsedRes.requestId, isLoginRequestOtp: true }, () => {
          this.loginResendTimer();
        });
      } else {
        console.error(`requestId did not find => `, res);
      }

    } catch (e) {
      console.error(`login request OTP error => `, e);
      this.setState({ isLoading: false });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // Login-step: 2
  handleConfirmOTP = async () => {
    // NOTE: event_tracker => Login 
    tracker.login.login();

    if (!this.validateUserName()) {
      return;
    }

    const { formData } = this.state;
    this.setState({ isLoading: true });

    // get gioLocation
    const gioLoc = await localStore.getGeolocation();

    try {
      formData.deviceId = this.props.deviceId;
      formData.deviceType = config.platform;

      const loginBody = {
        username: formData.userName,
        loginType: formData.loginType,
        deviceId: formData.deviceId,
        deviceType: formData.deviceType,
        latitude: gioLoc ? gioLoc.latitude : null,
        longitude: gioLoc ? gioLoc.longitude : null,
        otp: formData.loginOtp,
      }
      const response = await customerApis.confirmLoginOtp(loginBody);
      await this.handleLoginProcess(response);

    } catch (e) {
      console.log(`login error => `, e);
      // on success not required to off loader as it will go to next page
      this.setState({ isLoading: false });

      // NOTE: event_tracker => Login 
      tracker.login.loginFailed({ error_msg: e.message });
    }
  };

  handleValidation = () => {
    const { formError, formData } = this.state;

    const emailValidation = validationHelper.email(formData.email);
    formError.email = emailValidation.message;

    const passwordValidation = validationHelper.password(formData.password);
    formError.password = passwordValidation.message;

    this.setState({ formError });
    return emailValidation.isValid && passwordValidation.isValid;
  };

  handleFormSubmit = async () => {
    if (!this.handleValidation()) {
      return;
    }

    // NOTE: event_tracker => Login 
    tracker.login.login();

    const { formData } = this.state;
    this.setState({ isLoading: true });

    // get gioLocation
    const gioLoc = await localStore.getGeolocation();

    try {
      formData.deviceId = this.props.deviceId;
      formData.deviceType = config.platform;
      formData.latitude = gioLoc ? gioLoc.latitude : null;
      formData.longitude = gioLoc ? gioLoc.longitude : null;

      const response = await customerApis.login(formData);
      await this.handleLoginProcess(response);
    } catch (e) {
      // on success not required to off loader as it will go to next page
      this.setState({ isLoading: false });

      // NOTE: event_tracker => Login 
      tracker.login.loginFailed({ error_msg: e.message });
    }
  };

  handleLoginProcess = async (response) => {
    const result = customerParsers.login(response);
    localStore.setToken(result.token);
    localStore.setLegacyToken(result.legacyToken);
    localStore.setIsSsoCustomer(result.isSso?true:false);
    localStore.setSsoClientId(result.ssoClientId?result.ssoClientId:null);


    if (result.lastActiveProduct) {
      console.log("login success ", result.lastActiveProduct);
      localStore.setActiveProduct(result.lastActiveProduct);
    }

    this.setState({ customerDetails: result })

    await this.submitCampaignData({ email: result.email });
    let { isLoading, campaignVerifyPopup, mobileVerifyPopup, loginResponce, campaignVerify, mobileVerifyManage } = this.state;

    if (result && !result.isEmailVerified && !result.isMobileVerified) {
      isLoading = false;
      campaignVerifyPopup = true;
      loginResponce = result;
      campaignVerify = { email: result.email, mobile: parseInt(result.mobile), appRegistrationId: result.customerId }
    } else if (result && !result.isMobileVerified) {
      isLoading = false;
      mobileVerifyPopup = true;
      loginResponce = result;
      mobileVerifyManage = { email: result.email }
    } else {
      this.setState({ loginResponce: result }, () => this.letUserLogin());
    }

    this.setState({
      isLoading, campaignVerifyPopup, mobileVerifyPopup, loginResponce, campaignVerify, mobileVerifyManage
    })

    // NOTE: event_tracker => Login 
    tracker.login.loginSuccess({
      customerId: result.customerId,
      emailId: result.email,
      deviceType: this.state.formData.deviceType,
      userName: result.name,
      initUXCam: result.initUXCam,
      isOnboardingCompleted: result.onboardingData.isOnboardingCompleted,
    });
  }

  submitCampaignData = async ({ email }) => {
    const { utm_source, utm_campaign, utm_coupon, utm_product, utm_lead_id, restEventData = {} } = localStore.getAppsflyerData() || {};
    if (!utm_source || !utm_campaign) {
      return;
    }
    await customerApis.submitCampaignData({ email, trackingInfo: { utm_source, utm_campaign, utm_coupon, utm_product, utm_lead_id, raw_json: {...restEventData} } });
    localStore.resetAppsflyerData()
  }

  handleBackToStep1 = () => {
    let {
      formData,
      formError,
    } =
      this.state;

    formData.requestId = "";
    formData.loginOtp = "";
    formError.userName = ""


    this.setState({
      formData,
      formError,
      isLoginRequestOtp: false,
      isLoading: false,
      loginOtpResendTime: false,
    });

    clearInterval(this.loginOtpInterval);

  };

  handleRequestOtp = async (mobile, successCb) => {
    // NOTE : event_tracker => verifyMobile
    tracker.verifyMobile.verifyMobileRequestOTP();
    this.setState({ isLoading: true });
    try {
      await customerApis.mobileRequestOtp({ mobile });
      this.setState(
        { isLoading: false, mobileVerifyManage: { isRequestOtp: true } },
        successCb()
      );
      // NOTE : event_tracker => verifyMobile
      tracker.verifyMobile.verifyMobileRequestOTPSuccess();
    } catch (error) {
      // NOTE : event_tracker => verifyMobile
      tracker.verifyMobile.verifyMobileRequestOTPFailed({ error_msg: error.message });
      this.setState({ isLoading: false });
      console.error("Error Request OTP: ", error);
    }
  };

  handleVerifyOtp = async (mobile, otp) => {
    // NOTE : event_tracker => verifyMobile
    tracker.verifyMobile.verifyMobileUpdateNow();
    this.setState({ isLoading: true });
    try {
      await customerApis.verifyMobileOtp(otp);
      const { loginResponce } = this.state;
      loginResponce.isMobileVerified = true;
      loginResponce.mobile = mobile;
      this.setState(
        { isLoading: false, mobileVerifyPopup: false, loginResponce },
        () => this.letUserLogin()
      );
      // NOTE : event_tracker => verifyMobile
      tracker.verifyMobile.verifyMobileUpdateNowSuccess();
    } catch (error) {
      // NOTE : event_tracker => verifyMobile
      tracker.verifyMobile.verifyMobileUpdateNowFailed({ error_msg: error.message });
      console.error(" Error Verify OTP: ", error);
      this.setState({ isLoading: false });
    }
  };

  handleCampaignRequestOtp = async (mobile, email, successCb) => {
    // NOTE : event_tracker => verifyMobile verify email

    tracker.verifyMobile.verifyMobileRequestOTP();

    tracker.verifyEmail.verifyEmailRequestOTP();

    this.setState({ isLoading: true });
    try {
      await customerApis.emailRequestOtp({ email, mobile })

      this.setState(
        { isLoading: false, campaignVerify: { isRequestOtp: true, email: email, mobile: mobile, ...this.state.campaignVerify } },
        successCb()
      );
      // NOTE : event_tracker => verifyMobile verifyEmail
      tracker.verifyEmail.verifyEmailRequestOTPSuccess();
      tracker.verifyMobile.verifyMobileRequestOTPSuccess();
    } catch (error) {
      // NOTE : event_tracker => verifyMobile
      tracker.verifyMobile.verifyMobileRequestOTPFailed({ error_msg: error.message });
      tracker.verifyEmail.verifyEmailRequestOTPFailed({ error_msg: error.message });

      this.setState({ isLoading: false });
      console.error("Error Request OTP: ", error);
    }
  };

  handleCampaignVerifyOtp = async (mobile, email, mobileOtp, emailOtp) => {
    // NOTE : event_tracker => verifyMobile
    tracker.verifyMobile.verifyMobileUpdateNow();
    tracker.verifyEmail.verifyEmailUpdateNow();

    this.setState({ isLoading: true });
    try {
      await customerApis.verifyMobileOtp({ mobileOtp });
      await customerApis.verifyEmailOtp({ emailOtp });


      const { loginResponce } = this.state;
      loginResponce.isMobileVerified = true;
      loginResponce.isEmailVerified = true;

      loginResponce.mobile = mobile;
      loginResponce.email = email;


      this.setState(
        { isLoading: false, mobileVerifyPopup: false, loginResponce },
        () => this.letUserLogin()
      );
      // NOTE : event_tracker => verifyMobile verify Email
      tracker.verifyMobile.verifyMobileUpdateNowSuccess();
      tracker.verifyEmail.verifyEmailUpdateNowSuccess();


    } catch (error) {
      // NOTE : event_tracker => verifyMobile verify email
      tracker.verifyMobile.verifyMobileUpdateNowFailed({ error_msg: error.message });
      tracker.verifyEmail.verifyEmailUpdateNowFailed({ error_msg: error.message });

      console.error(" Error Verify OTP: ", error);
      this.setState({ isLoading: false });
    }
  };

  loginResendTimer = () => {
    const { loginOtpResendTime } = this.state;
    if (loginOtpResendTime > 0) {
      return;
    }
    this.setState({ loginOtpResendTime: otpRequestTimer });

    this.loginOtpResendTime = setInterval(() => {
      const { loginOtpResendTime } = this.state;
      let time = loginOtpResendTime - 1;
      this.setState({ loginOtpResendTime: time });
      time === 0 && clearInterval(this.loginOtpResendTime);
    }, 1000);
  };

  emailResendTimer = () => {
    const { emailResendTime } = this.state;
    if (emailResendTime > 0) {
      return;
    }
    this.setState({ emailResendTime: otpRequestTimer });

    this.emailInterval = setInterval(() => {
      const { emailResendTime } = this.state;
      let time = emailResendTime - 1;
      this.setState({ emailResendTime: time });
      time === 0 && clearInterval(this.emailInterval);
    }, 1000);
  };

  mobileResendTimer = () => {
    const { mobileResendTime } = this.state;
    if (mobileResendTime > 0) {
      return;
    }

    this.setState({ mobileResendTime: otpRequestTimer });
    this.mobileInterval = setInterval(() => {
      const { mobileResendTime } = this.state;
      let time = mobileResendTime - 1;
      this.setState({ mobileResendTime: time });
      time === 0 && clearInterval(this.mobileInterval);
    }, 1000);
  };

  handleResendOtp = async (type) => {
    console.log("typeee", type, this.state.campaignVerify.appRegistrationId)
    if (
      !["email", "mobile"].includes(type) ||
      !this.state.campaignVerify.appRegistrationId
    ) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      await customerApis.resendOtp({
        appRegistrationId: this.state.campaignVerify.appRegistrationId,
        type: type,
      });
      type === "email" ? this.emailResendTimer() : this.mobileResendTimer();
    } catch (e) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleMobileVerifyPopupClose() {
    this.setState({ mobileVerifyPopup: false });
  }

  handleCampaignVerifyPopupClose() {
    this.setState({ campaignVerifyPopup: false });
  }

  handleChangeViewType = (viewType) => {
    this.setState({ activeViewType: viewType });
  }

  render() {
    const { customerDetails, activeViewType } = this.state;
    return (
      <>
        <Grid container>
          <Grid
            item
            md={6.5}
            lg={6.5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <AuthAsidePanel />
          </Grid>
          <Grid item md={5.5} lg={5.5} sx={{ flex: 1 }}>
            <Box
              sx={{
                minHeight: "100vh",
              }}
            >
              <LoginForm
                viewTypes={viewTypes}
                activeViewType={activeViewType}
                onChangeViewType={this.handleChangeViewType}
                onChange={this.handleInputChange}
                onSubmit={this.handleFormSubmit}
                onConfirmOTP={this.handleConfirmOTP}
                formData={this.state.formData}
                formError={this.state.formError}
                isLoading={this.state.isLoading}
                forgotPwdUrl="/forgot-password"
                isLoginRequestPassword={this.state.isLoginRequestPassword}
                isLoginRequestOtp={this.state.isLoginRequestOtp}
                loginOtpResendTime={this.state.loginOtpResendTime}
                loginSendOtp={this.sendLoginOtpHandle}
                onBackToStep1={this.handleBackToStep1}
              />
            </Box>
          </Grid>
        </Grid>

        {
          !customerDetails.isEmailVerified && !customerDetails.isMobileVerified ? (
            <Dialog
              open={this.state.campaignVerifyPopup}
              onClose={() => {
                this.handleCampaignVerifyPopupClose();
              }}
              maxWidth="xs"
              title={
                <Text
                  variant="subtitle1"
                  sx={{ textAlign: "center", width: "100%" }}
                >
                  Verify Details
                </Text>
              }
            >
              <CampaignVerification
                onRequestOtp={this.handleCampaignRequestOtp}
                onResendOtp={this.handleResendOtp}
                onSubmit={this.handleCampaignVerifyOtp}
                emailResendTimerFunction={this.emailResendTimer}
                mobileResendTimerFunction={this.mobileResendTimer}

                emailResendTime={this.state.emailResendTime}
                mobileResendTime={this.state.mobileResendTime}
                formManage={this.state.campaignVerify}
                isLoading={this.state.isLoading}
              />


            </Dialog>
          ) : (
            <Dialog
              open={this.state.mobileVerifyPopup}
              onClose={() => {
                this.handleMobileVerifyPopupClose();
              }}
              maxWidth="xs"
              title={
                <Text
                  variant="subtitle1"
                  sx={{ textAlign: "center", width: "100%" }}
                >
                  Confirm Mobile
                </Text>
              }
            >
              <MobileVerification
                onRequestOtp={this.handleRequestOtp}
                onResendOtp={this.handleRequestOtp}
                onSubmit={this.handleVerifyOtp}
                formManage={this.state.mobileVerifyManage}
                isLoading={this.state.isLoading}
                hasSkipOption
                onSkipMobile={this.letUserLogin}
              />
            </Dialog>
          )
        }



      </>
    );
  }
}

export default withRouter(LoginContainer);
