import React, { Component } from 'react';
import Loader from 'components/common/Loader';
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisButton from 'components/JarvisButton';
import { withRouter } from "react-router";
import { customerApis } from 'stores/customer/customerApis';

class VerifyLinkContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isEmailVerified: false,
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true, });
        let requestedId = new URLSearchParams(this.props.location.search).get("requestId");
        let verificationCode = new URLSearchParams(this.props.location.search).get("verificationCode");
        this.setState({ isLoading: false, })

           this.verifyEmailLink(requestedId , verificationCode);
    }

    verifyEmailLink = async (requestedId, verificationCode) => {
        try {
            let res;
            res = await customerApis.verifyEmailLink({ requestedId, verificationCode });
            this.setState({isEmailVerified : res} , () => this.state.isEmailVerified , "is email verified");
             this.props.setEmailVerified({res});
        }
        catch (err) {
            console.log(err);
            this.setState({isEmailVerified : false});

        }
    }
    render() {
        let { isEmailVerified, } = this.state;
        return (<>
            <Loader loading={this.state.isLoading} />
            {isEmailVerified ? (
                <Stack alignItems="center" sx={{ height: '100vh' }} justifyContent='center'>
                    <img src='/images/svgs/green-check.svg' alt="" style={{ maxWidth: '200px', marginBottom: '20px' }}></img>
                    <Text sx={{ fontSize: '1.75rem', fontWeight: '500', mb: 1 }}>Verified!</Text>
                    <Text sx={{ mb: 2 }}>Your email has been successfully verified</Text>
                    <JarvisButton disableEndIcon={true} onClick={() => this.props.history.replace("/login")}> OK</JarvisButton>
                </Stack>
            ) : (
                <Stack alignItems="center" sx={{ height: '100vh' }} justifyContent='center'>
                    <img src='/images/svgs/envelope.svg' alt="" style={{ maxWidth: '200px', marginBottom: '20px' }}></img>
                    <Text sx={{ fontSize: '1.75rem', fontWeight: '500', mb: 1.5, maxWidth:'238px', textAlign:"center", lineHeight:1.3 }}>Email verification link expired!</Text>
                    <Text sx={{ mb: 2.2 }} textAlign="center">Looks like the verification link has expired. <br></br>
                        Not to worry, you can verify your link after you login to the portal</Text>
                    <JarvisButton disableEndIcon={true} onClick={() =>  this.props.history.replace("/login")} > Go To Login</JarvisButton>
                </Stack>
            )}
        </>);
    }
}

export default withRouter(VerifyLinkContainer);