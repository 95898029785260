import React from "react";

import Text from "components/common/Text";
import Grid from "components/common/Grid";
import Loader from "components/common/Loader";

import BlogCard from "components/BlogCard";
import JarvisCard from "components/JarvisCard";
import { tracker } from "library/tracker";

class MarketUpdatesContainer extends React.Component {
  componentDidMount() {

    if (!this.props.marketUpdatesData.length) {
      this.props.getMarketUpdateAction();
    }
  }

  readMoreTrack = () => {
    //NOTE : event_tracker => marketUpdate
    tracker.marketUpdate.marketUpdateReadMore();
  }

  render() {
    const { isLoading, isInitialCalled, marketUpdatesData } = this.props;

    return (
      <>
        <Loader position="absolute" loading={isLoading} />

        {isInitialCalled && !marketUpdatesData.length ? (
          <JarvisCard>
            <Text>
              Sorry! We are unable to fetch data. Please try again later.
            </Text>
          </JarvisCard>
        ) : null}

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={{xs: 2, sm:3}}
          mt="0px !important"
        >
          {marketUpdatesData.map((marketUpdates, index) => (
            <Grid item md={6} key={`market_update_${index}`}>
              <BlogCard
                title={marketUpdates.title}
                imageUrl={marketUpdates.imageUrl}
                description={marketUpdates.description}
                author={marketUpdates.author}
                readMoreLink={marketUpdates.readMoreLink}
                readMoreTrack={this.readMoreTrack}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}

export default MarketUpdatesContainer;
