import { jarvisRMSTransactionConstants } from "./jarvisRMSTransactionConstants";

const jarvisRMSTransactionActions = {};

jarvisRMSTransactionActions.getRMSTransactionList = ({
    page,
    perPage,
    fromDate: dateFrom,
    toDate: dateTo,
    portfolioId,
    executionStatus,
    customerId,
    
}) => ({
    type: jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_SAGA,
    payload: { page, perPage, dateFrom, dateTo, portfolioId, executionStatus,customerId },
});

jarvisRMSTransactionActions.getRMSTransactionDetail = ({ rmsCallId }) => ({
    type: jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_SAGA,
    payload: { rmsCallId },
});

export { jarvisRMSTransactionActions };
