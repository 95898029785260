import React from "react";

import Box from "components/common/Box";
import Modal from "components/common/Modal";
import Grid from '../../../components/common/Grid';

import ProfileDetails from "components/Profile/ProfileDetails";
import ProfileEditForm from "components/Profile/ProfileEditForm";

import { customerApis } from "stores/customer/customerApis";
import { validationHelper } from "helper/validation";
import { tracker } from "library/tracker";
import { masterApis } from "stores/master/masterApis";

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFormModal: false,
      isFormLoading: false,
      formData: {
        profilePic: "",
        image: "",
        fullName: "",
        email: "",
        mobileNo: "",
        alternateNo: "",
      },
      formError: {
        image: "",
        fullName: "",
        email: "",
        mobileNo: "",
        alternateNo: "",
      },
      deactivateDialogOpen: false,
      deactivateSubmitLoading: false,
      deactivateCheckboxChecked: false,
    };
  }

  componentDidMount() {
    this.props.reasonsDropdownAction()
  }

  toggelEditFormOpen() {
    let { editFormModal, formData } = this.state;
    editFormModal = !editFormModal;

    if (editFormModal) {
      const { fullName, alternateNo, email, mobileNo, profilePic } =
        this.props.profile;
      formData = {
        profilePic,
        image: null,
        fullName,
        email,
        mobileNo,
        alternateNo,
      };
    }

    this.setState({ editFormModal, formData });
  }

  handleChange = (name, value) => {
    let { formData } = this.state;
    formData[name] = value;
    this.setState({ formData });
  };

  handleValidation = () => {
    const { formData, formError } = this.state;

    const nameValidation = validationHelper.required(formData.fullName);
    formError.fullName = nameValidation.message;

    const mobileValidation = validationHelper.mobile(formData.alternateNo);
    formError.alternateNo = mobileValidation.message;

    this.setState({ formError });
    return nameValidation.isValid && mobileValidation.isValid;
  };

  handleSubmit = async () => {
    if (!this.handleValidation()) {
      return;
    }
    this.setState({ isFormLoading: true });
    try {
      //event-tracker: profile submit
      tracker.profile.profileSubmit();
      await customerApis.updateProfile(this.state.formData);
      //event-tracker: profile submit success
      tracker.profile.profileSubmitSuccess();
      this.props.updateProfileAction(this.state.formData)
      this.toggelEditFormOpen()
    } catch (e) {
      //event-tracker: profile failed
      tracker.profile.profileSubmitFailed({ error_msg: e.message });
    } finally {
      this.setState({ isFormLoading: false });
    }
  };

  handleDeactivateDialogConfirm = async () => {
    this.setState({ deactivateSubmitLoading: true });
    try {
      await masterApis.updateLastActiveProduct();
      await customerApis.deactivateAccount();
      this.props.logoutAction();
    } catch (error) {
      console.error('deactivate account api failed', error);
    } finally {
      this.setState({ deactivateSubmitLoading: false });
    }
  }

  render() {
    const { editFormModal, formData, formError, isFormLoading, deactivateDialogOpen, deactivateCheckboxChecked, deactivateSubmitLoading } = this.state;
    const { profile, enableAccountDeactivation, customerSubscriptionId, reasonsDropdown } = this.props;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} >
            <Box
              height="100%"
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "center" }}
              sx={{ border: "1px solid #DEDEDE", borderRadius: "8px" }}
            >
              <ProfileDetails 
                profilePic={profile.profilePic}
                email={profile.email}
                mobileNo={profile.mobileNo}
                fullName={profile.fullName}
                reasonsDropdown={reasonsDropdown}
                isKYCCompleted={profile.isKYCCompleted}
                onProfileUpdateClick={() => this.toggelEditFormOpen()}
                customerSubscriptionId={customerSubscriptionId}
                deactivateDialogProps={{
                  isEnabled: enableAccountDeactivation,
                  open: deactivateDialogOpen,
                  onOpen: () => this.setState({ deactivateDialogOpen: true }),
                  onClose: () => this.setState({ deactivateDialogOpen: false }),
                  onConfirm: this.handleDeactivateDialogConfirm,
                  isLoading: deactivateSubmitLoading,
                  checkboxChecked: deactivateCheckboxChecked,
                  onCheckboxChange: () => this.setState({ deactivateCheckboxChecked: !deactivateCheckboxChecked }),
                }}
              />

              <Modal
                title={"Edit Profile"}
                size={"sm"}
                open={editFormModal}
                onClose={() => this.toggelEditFormOpen()}
              >
                <ProfileEditForm
                  formData={formData}
                  formError={formError}
                  loading={isFormLoading}
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                />
              </Modal>
            </Box>
          </Grid>
        </Grid>
      </>
      
    );
  }
}

export default ProfileContainer;
