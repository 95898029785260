import React, { useState, useEffect} from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Button from "components/common/Button";


import { App } from '@capacitor/app';
import { useSelector } from "react-redux";

import { config } from "config";

const AppForceUpdate = () => {
    const appConfig = useSelector((state) => state.common.appConfig);
    const [appVersion, setAppVersion] = useState("");
    
    const getAppVersion = async () =>{
        const { version } = await App.getInfo();
        setAppVersion(version);
    }

    useEffect(() =>{
        if(config.isMobileApp){
            getAppVersion();
        }
    },[appVersion]);

    const redirec = () =>{
        console.log("redirecting...");
        window.location.href = "https://play.google.com/store/apps/details?id=com.neolite.jarvis.monitree";
    }

    return (
        <Stack sx={{
            padding: "16px",
            height: "100vh",
            maxHeight: "100%",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Stack sx={{ width: "100%" }}>
                <img src="/images/app-update-available.svg" alt="update" style={{
                    width: "200px",
                    margin: "0 auto",
                    marginBottom: "20px",
                }} />
                <Text variant="subtitle2" mb="4px">Update available</Text>
                <Text variant="small" color="#9FA5AB" mb="24px">Current version {" "}{appVersion}</Text>
                <Text variant="small" sx={{
                    maxWidth: "244px",
                    ml: "auto", mr: "auto", mb: "40px", color: "#3A3A3A"
                }}>
                    {appConfig.message ?
                        appConfig.message :
                        "It's time to upgrade to the latest appVersion of our application to get the best experience"
                    }
                </Text>
                <Button sx={{
                    textTransform: "none",
                    color: "#fff",
                    letterSpacing: "1.2px",
                    height: "48px"
                }}
                onClick={redirec}>Update now</Button>
            </Stack>
        </Stack>
    )
}

export default AppForceUpdate; 