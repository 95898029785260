import React from "react";

//Common Components

import Stack from "components/common/Stack";
import Box from "components/common/Box";
import SectionTitle from "components/SectionTitle";
import Text from "components/common/Text";

//Action Center Components
import JarvisOnestockMobCard from "components/JarvisOnestock/JarvisOnestockMobCard";

const OnestockActionCenterMobCard = (props) => {
  const {
    actionStockData,
    sellCall,
    buyCall,
    brokerList = [],
    isBrokerListLoading = 0,
    isBrokerListInitialCalled = 0,
    pendingAction,
    centrumReferalURL
  } = props;

  return (
    <>
      {
        sellCall ?
          <Box>
            {actionStockData.brokerName ? (
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <SectionTitle
                  sx={{ mb: 1.75 }}
                  heading={
                    <>
                      Broker ~
                      <Text
                        component="span"
                        color="#A2A7AE"
                        variant="subtitle2"
                        mx={1}
                      >
                        {actionStockData.brokerName}
                      </Text>
                    </>
                  }
                />
              </Stack>
            ) : null}

            <Stack
              direction="row"
              alignItems="stretch"
              justifyContent="flex-start"
              sx={{
                position: "relative",
                flexWrap: "wrap",
              }}
            >
              {actionStockData?.stockData.map((stockdetails, index) => {
                return (
                  <React.Fragment key={`stock_${index}`}>
                    <Stack
                      sx={{
                        flexGrow: 0,
                        width: { xs: 1, sm: 0.5, md: 0.33, },
                      }}
                    >
                      <JarvisOnestockMobCard
                        stockDetails={stockdetails}
                        actionType={actionStockData.actionType}
                        data={actionStockData}
                        sellCall
                        brokerList={brokerList}
                        isBrokerListLoading={isBrokerListLoading}
                        isBrokerListInitialCalled={isBrokerListInitialCalled}
                        pendingAction={pendingAction}
                        centrumReferalURL={centrumReferalURL}

                      />
                    </Stack>
                  </React.Fragment>
                );
              })}
            </Stack>
          </Box>
          : null
      }
      {
        buyCall ?
          <Box>
            <Stack
              direction="row"
              alignItems="stretch"
              justifyContent="flex-start"
              sx={{
                position: "relative",
                flexWrap: "wrap",
              }}
            >
              {actionStockData?.stockData.map((stockdetails, index) => {
                return (
                  <React.Fragment key={`stock_${index}`}>
                    <Stack
                      sx={{
                        flexGrow: 0,
                        width: { xs: 1, sm: 0.5, md: 0.33, },
                      }}
                    >
                      <JarvisOnestockMobCard
                        stockDetails={stockdetails}
                        data={actionStockData}
                        brokerList={brokerList}
                        isBrokerListLoading={isBrokerListLoading}
                        isBrokerListInitialCalled={isBrokerListInitialCalled}
                        pendingAction={pendingAction}
                        centrumReferalURL={centrumReferalURL}

                      />
                    </Stack>
                  </React.Fragment>
                );
              })}
            </Stack>
          </Box>
          : null
      }
    </>
  );
};
export default OnestockActionCenterMobCard;
