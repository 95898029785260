import React from "react";
import RMSRiskAssessmentContainer from "containers/JarvisRMS/RMSRiskAssessment";

const RiskAssessment = () => {
	return (
		<>
			<RMSRiskAssessmentContainer />
		</>
	);
};

export default RiskAssessment;
