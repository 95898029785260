import React, { Component } from "react";
import OnestockDashboardOnboardedContainer from "../OnestockDashboardOnboarded";
import PageTitle from "components/PageTitle";
import OnestockOnboardingNotCompleted from "../OnestockOnboardingNotCompleted";

export default class OnestockDashboard extends Component {
    render() {
        if (!this.props.isOnboardingCompleted) {
            return (
                <>
                    <OnestockOnboardingNotCompleted />
                </>
            )
        }
        return (
            <>
                <PageTitle
                    heading="Jarvis One Stock Dashboard"
                    disableBreadcrumb
                />
                <OnestockDashboardOnboardedContainer />
            </>
        );
    }
}
