import React from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@mui/styles";

import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Grid from "components/common/Grid";
import Button from "./common/Button";

import JarvisSubscriptionPlanCard from "./JarvisSubscriptionPlanCard";
import JarvisDialog from "./JarvisDialog";

import { dataHelper } from "helper/data";
import JarvisSubscriptionPlanCardHorizontal from "./JarvisSubscriptionPlanCardHorizontal";

const planTab = {
    "MONTHLY": "Monthly",
    "HALF_YEARLY": "Half-Yearly",
    "QUARTERLY": "Quarterly",
    "YEARLY": "Yearly",
};

const JarvisSubscriptionPlans = ({
    subscriptionPlans = [],
    subscriptionFrequencies = [],
    onPlanSelect,
    onFrequencyChange,
    selectedFrequency,
    currentInvestmentAmount,
    currentPortfolioCount,
    currentStockCount,
    paymentType,
    confirmDialog: {
        open,
        onConfirm,
        onCancel,
        title,
        content,
    },
    productTitle,
    diasbleCircle,
    selectedPlanData = {},
    disabledPlanData ={},
    onestockData = false,
    forInfo,
}) => {
    const classes = useStyles();
    const subscriptionPlansByFrequencey = subscriptionPlans.filter(p => p.planFrequency === selectedFrequency).sort((a, b) => dataHelper.sorter(a.planSequence, b.planSequence, "number"));

    return (
        <>
            <Stack
                sx={{ flex: 1, maxWidth: { xs: "90vw", md: "unset" }, mb: { xs: "50px", md: "unset" }, ml: { xs: "15px", md: "unset" } }}
            >
                <Stack
                    justifyContent="space-between"
                    direction={{ xs: "column", md: "row" }}
                    mb="10px"
                >
                    <Text>{productTitle}</Text>
                    {subscriptionFrequencies.length && (
                        <Stack
                            direction="row"
                            className={cx(classes.tabRoot)}
                            sx={{
                                marginTop: { xs: "5px", md: "unset" },
                                alignSelf: { xs: "center", md: "unset" },
                                width: { xs: "100%", md: "fit-content" },
                            }}
                        >
                            {subscriptionFrequencies.map(frequency => (
                                <Button
                                    key={frequency}
                                    onClick={() => onFrequencyChange({ selectedFrequency: frequency })}
                                    disableEndIcon
                                    className={cx(classes.tab, {
                                        [classes.activeTab]: selectedFrequency === frequency,
                                    })}
                                    sx={{ flex: { xs: "1", md: "unset" }, padding: { xs: "6px 0", md: "3px 22px" } }}
                                >
                                    {planTab[frequency]}
                                </Button>
                            ))}
                        </Stack>
                    )}
                </Stack>

                {
                    onestockData ? "Dummy Onestock" :
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                flexWrap: { xs: "nowrap", md: "unset" },
                                overflow: "auto",
                                maxWidth: { xs: "100vw", md: "unset" },
                                paddingBottom: { xs: "6px", md: "unset" },
                            }}
                        >
                            {subscriptionPlansByFrequencey.length === 1 ?

                                subscriptionPlansByFrequencey.map((planData, index) => (

                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        sx={{ minWidth: { xs: "vw", md: "unset" } }}
                                        key={`plan_${planData.planId}`}
                                    >
                                        <JarvisSubscriptionPlanCardHorizontal
                                            onPlanSelect={() => onPlanSelect({ ...planData })}
                                            planData={planData}
                                            planIndex={index}
                                            currentPortfolioCount={currentPortfolioCount}
                                            currentStockCount={currentStockCount}
                                            currentInvestmentAmount={currentInvestmentAmount}
                                            selectedPlanData={selectedPlanData}
                                            disabledPlanData={disabledPlanData}
                                            paymentType={paymentType}
                                            diasbleCircle={diasbleCircle ? diasbleCircle : ""}
                                            forInfo={forInfo ? forInfo : ""}
                                        />
                                    </Grid>
                                )) :
                                subscriptionPlansByFrequencey.map((planData, index) => (
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        sx={{ minWidth: { xs: "83vw", md: "unset" } }}
                                        key={`plan_${planData.planId}`}
                                    >
                                        <JarvisSubscriptionPlanCard
                                            onPlanSelect={() => onPlanSelect({ ...planData })}
                                            planData={planData}
                                            planIndex={index}
                                            currentPortfolioCount={currentPortfolioCount}
                                            currentStockCount={currentStockCount}
                                            currentInvestmentAmount={currentInvestmentAmount}
                                            selectedPlanData={selectedPlanData}
                                            disabledPlanData={disabledPlanData}
                                            paymentType={paymentType}
                                            diasbleCircle={diasbleCircle ? diasbleCircle : ""}
                                            forInfo={forInfo ? forInfo : ""}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                }
            </Stack>

            {/* Modal: Confirmation of Plan Selection */}
            <JarvisDialog
                disableCloseIcon
                open={open}
                onClose={onCancel}
                onConfirm={onConfirm}
                title={title}
                content={content}
                setHTML={true}
            />
        </>
    );
};
const useStyles = makeStyles((theme) =>
    createStyles({
        tabRoot: {
            // width: "fit-content",
            border: `1px solid #EEF4FA`,
            borderRadius: "25px",
            backgroundColor: "#EEF4FA",
        },
        tab: {
            transition: "all 0.3s ease-in-out",
            backgroundColor: "transparent",
            boxShadow: "none !important",
            color: "gray",
            textTransform: "capitalize",
            // padding: "3px 22px",
            fontSize: "14px",
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        activeTab: {
            backgroundColor: "#2d70c5 !important",
            color: "#fff",
            borderRadius: "50px",
        },
    })
);
export default JarvisSubscriptionPlans;
