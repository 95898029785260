import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Container from '@mui/material/Container';
import Stack from 'components/common/Stack';
import Image from "components/common/Image";
import Text from 'components/common/Text';
import PortfolioInfoContainer from 'containers/ProductInfo/PortfolioInfo';
import { localStore } from "stores/localStorage";
import { config } from "config/index";
import JarvisCard from 'components/JarvisCard';
import JarvisButton from 'components/JarvisButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from 'components/common/Button';
import { withStyles } from "@mui/styles";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Chip } from '@mui/material';
import { alpha } from "@mui/material/styles";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TooltipHover from "components/common/TooltipHover"

const portfolioProduct = {
    id: 'portfolio', name: 'Jarvis Portfolio', description: "To build and manage a AI-powered equity portfolio with an inbuilt 24X7 risk management process", icon: "/images/product-info/portfolio-bag.svg", investNow: "", banner: "/images/product-info/portfolioBanner.svg",
}


const Tabs = {
    portfolio: [
        { id: "portfolio_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "For existing and new investors who don't have the time and expertise to manage their portfolio" },
        { id: "portfolio_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "Between 4 to 5 years regardless of market condition" },
        { id: "portfolio_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "Your demant account should have a minimum balance of Rs 30,000" },
        {
            id: "portfolio_4", hasChipUI: true, isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: ["AI-based stock selection", "Personalised portfolio", "Timely rebalancing", "24X7 risk management process", "No human error", "No human bias",
                "Ongoing stock recommendations", "Ease of transactions"]
        },
    ],
    protect: [
        { id: "protect_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "Investors with underperforming stocks portfolio and don't know what to do with it" },
        { id: "protect_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "There is no minimum tenure commitment required" },
        { id: "protect_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "Your portfolio should have at least 5 stocks" },
        { id: "protect_4", isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: "text update here" },
    ],
    onestock: [
        { id: "onestock_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "text update here" },
        { id: "onestock_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "text update here" },
        { id: "onestock_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "text update here" },
        { id: "onestock_4", isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: "text update here" },
    ],
}

const portfolioOnboardingStages = [
    {
        stageId: [0],
        stageNumber: 1,
        uri: "/jarvis-portfolio/onboarding/risk-assessment",
    },
    {
        stageId: [1],
        stageNumber: 2,
        uri: "/jarvis-portfolio/onboarding/investment-preference",
    },
    {
        stageId: [2],
        stageNumber: 3,
        uri: "/jarvis-portfolio/onboarding/portfolio-creation",
    },
    {
        stageId: [3, 4],
        stageNumber: 4,

        uri: "/jarvis-portfolio/onboarding/ckyc",
    },
    {
        stageId: [5],
        stageNumber: 5,
        uri: "/jarvis-portfolio/onboarding/choose-broker",
    },
];
class PortfolioOnboardingNotCompletedContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActivePortfolio: false,
            portfolioKnowMoreSection: true,
            protectKnowMoreSection: false,
            onestockKnowMoreSection: false,
            portfolioOpenId: null,
        }
    }

    handleTabClick = (data, type) => {
        Tabs[type].forEach(element => {
            data.id == element.id ? (element.isActive = true) : (element.isActive = false);
        });
        this.setState({ isActivePortfolio: true })
    }

    handleKnowMore = (type) => {
        const { portfolioKnowMoreSection, protectKnowMoreSection, onestockKnowMoreSection } = this.state;
        if (type == "portfolio") {
            this.setState({ portfolioKnowMoreSection: !portfolioKnowMoreSection })
        }
        if (type == "protect") {
            this.setState({ protectKnowMoreSection: !protectKnowMoreSection })
        }
        if (type == "onestock") {
            this.setState({ onestockKnowMoreSection: !onestockKnowMoreSection });
        }
    }

    handleInvestNow = (type) => {
        if (type == "portfolio") {
            localStore.setActiveProduct(config.productCodes.PORTFOLIO);
            this.props.history.push(`/jarvis-portfolio/onboarding/overview`);
        }
        if (type == "protect") {
            localStore.setActiveProduct(config.productCodes.RMS);
            this.props.history.push(`/jarvis-rms/onboarding/overview`);
        }
        if (type == "onestock") {
            localStore.setActiveProduct(config.productCodes.ONESTOCK);
            this.props.history.push(`/jarvis-onestock/onboarding/overview`);
        }
    }

    // For Portfolio Steps
    getActiveStageForPortfolio = () => {
        const { currentStage } = this.props;
        return portfolioOnboardingStages.find((o) =>
            o.stageId.includes(currentStage)
        )
    };

    handlePortfolioTooltip = (id, type = 'open') => {
        if (type === 'open') {
            this.setState({ portfolioOpenId: id });
        } else {
            this.setState({ portfolioOpenId: null });
        }
    }


    render() {
        const { classes } = this.props;
        const { portfolioKnowMoreSection, protectKnowMoreSection, onestockKnowMoreSection } = this.state;
        const activePortfolioStage = this.getActiveStageForPortfolio();
        let isCentrumSsoCustomer = false;
        let ssoCustomerDetails = localStore.getSsoCustomerDetails()
        if(localStore.isSsoCustomer() && ssoCustomerDetails?.broker === config?.broker?.ssoPlatform?.centrum ){
            isCentrumSsoCustomer = true;
            // onboardingStages = this.props.portfolioOverviewList.filter(v=>v.name !== "CKYC");
        }

        return (

            <Stack mt={3}>
                {/* <Container maxWidth="lg"> */}
                    {/* {productlist.map(item => ( */}
                    <JarvisCard sx={{ border: "1px solid #d3d3d3", position: "relative", paddingBottom: "60px", marginBottom: "20px" }}>
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                            <Stack sx={{ flex: { xs: "1", md: "2" } }} >
                                <Stack direction="row">
                                    <img src={portfolioProduct.icon} style={{ marginRight: "18px" }} />
                                    <div>
                                        <Text variant="subtitle1" color={'#2B70C5'} sx={{ fontWeight: 500 }}>{portfolioProduct.name}</Text>
                                        <Text variant="body2" color={'#676E76'}>{portfolioProduct.description}</Text>
                                    </div>
                                </Stack>
                                {/* tab */}
                                <Stack alignItems="start" mt={4} direction="row" sx={{ overflow: "auto", cursor: "pointer" }}>
                                    {Tabs[portfolioProduct.id].map((data) => (
                                        <Stack direction="column" alignItems="center" sx={{ flex: "1 1 auto", minWidth: "125px", borderBottom: ((data.isActive) ? '3px solid #2B70C5' : '') }} onClick={() => this.handleTabClick(data, `${portfolioProduct.id}`)}>
                                            <img src={data.img} width={"36px"}></img>
                                            <Text variant="body2" mb={1} sx={{ whiteSpace: "noWrap" }}>{data.title}</Text>
                                        </Stack>
                                    )
                                    )}
                                </Stack>
                                <Stack mt={2}>
                                    {Tabs[portfolioProduct.id].map((data) => {
                                        return !data.isActive ? null : data.hasChipUI ? (
                                            <>
                                                <Stack
                                                    direction="row"
                                                    sx={{
                                                        flexWrap: "wrap",
                                                        gap: "0.8rem"
                                                    }}
                                                >
                                                    {data.text.map(d => (
                                                        <Stack
                                                            className={classes.chipUi}
                                                        >
                                                            {d}
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </>
                                        ) : (
                                            <Text variant="body2" color={"#676E76"} >{data.text}</Text>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                            <Stack sx={{ flex: 1 }}>
                                <img src={portfolioProduct.banner} width={'100%'} style={{ marginBottom: "20px" }} />
                                <JarvisButton disableEndIcon={true} onClick={() => this.handleInvestNow(portfolioProduct.id)}>Create Portfolio Now</JarvisButton>
                            </Stack>
                        </Stack>
                        {
                            portfolioProduct.id == "portfolio" && this.props.currentStage > 0 ?
                                <ClickAwayListener onClickAway={() => this.handlePortfolioTooltip('', 'close')}>
                                    <Stack direction={{ xs: 'column', md: 'row' }} className={classes.toolTipss}>
                                        {this.props.portfolioOverviewList.map((item, index) =>
                                            <Stack mt={4} key={item.id} direction={'row'} alignItems={'center'}>
                                                <Chip label={item.id} size='small' sx={{ color: item.stageNumber < activePortfolioStage?.stageNumber ? 'white' : 'primary.dark', backgroundColor: item.stageNumber < activePortfolioStage?.stageNumber ? alpha('#2e7d32', 0.8) : alpha('#38a1ff', 0.5), fontWeight: '500', fontSize: { xs: 'caption2.fontSize', md: 'caption1.fontSize' }, maxHeight: '24px', minWidth: '24px' }} />
                                                <Text ml={0.5} sx={{ fontSize: "14px", color: '#212225', fontWeight: '400', }}>
                                                    {isCentrumSsoCustomer && item.name?.toLowerCase() === "ckyc"?"E-Sign":item.name}
                                                </Text>

                                                <TooltipHover
                                                    title={!isCentrumSsoCustomer?item.toolTipText : item.toolTipText.replaceAll
                                                    ('CKYC','E-Sign')}
                                                    sx={{ lineHeight: 0, ml: 0.5, mr: index === this.props.portfolioOverviewList.length - 1 ? 0 : 1.5 }}
                                                >
                                                    <InfoRoundedIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                                                </TooltipHover>
                                            </Stack>
                                        )}
                                    </Stack>
                                </ClickAwayListener>
                                : null
                        }
                        {
                            portfolioProduct.id == "portfolio" && portfolioKnowMoreSection ?
                                <PortfolioInfoContainer />
                                : null
                        }
                        <Button className={classes.btns} onClick={() => this.handleKnowMore(`${portfolioProduct.id}`)} endIcon={portfolioKnowMoreSection ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                            {
                                (portfolioProduct.id == "portfolio" && !portfolioKnowMoreSection) ? "Know Pricing"
                                    : "Hide Pricing"
                            }
                        </Button>
                    </JarvisCard>
                {/* </Container> */}

            </Stack>
        );
    }
}

const style = (theme) => ({
    btns: {
        backgroundColor: '#EEF4FA',
        color: '#2B70C5',
        fontSize: '0.875rem !important',
        fontWeight: 500,
        boxShadow: 'none',
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        bborderBottomLeftRadius: "0px",
        bborderBottomRightRadius: "0px",
        textTransform: "capitalize",
        '&:hover': {
            color: "white",
        }
    },
    chipUi: {
        color: "#2B70C5",
        border: "1px solid #DCDCDC",
        padding: "0.25rem 0.5rem",
        fontSize: "12px",
        borderRadius: "24px",
        backgroundColor: "#FFFFFF",
        fontWeight: 500,
    }

})

export default withRouter(withStyles(style, { withTheme: true })(PortfolioOnboardingNotCompletedContainer));

