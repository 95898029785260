import React  from 'react';
import OverviewContainer from 'containers/Onboarding/Overview';

const Overview = () => {
    return (
        <>
            <OverviewContainer />
        </>);
}

export default Overview;