import _get from "lodash/get";
import { formatHelper } from "helper/format";

const rmsParsers = {};

rmsParsers.rmsDetails = (res) => {
  let d = {};
  if (res && res.response) {
    res = res.response;
  }
  if (!res) {
    return d;
  }

  const parseTransactions = (t) => t.map((e) => ({
    symbol: _get(e, "symbol", ""),
    stockName: _get(e, "stockName", ""),
    sector: _get(e, "sector", ""),
    cap: _get(e, "cap", ""),
    riskStatus: _get(e, "stockRisk", ""),
    quantity: _get(e, "suggestedQuantity", ""),
    pendingActionableQty: _get(e, "pendingActionableQty", ""),
    buyPrice: formatHelper.parseDecimal(_get(e, "buyPrice", 0)) || 0,
    totalBuyPrice: formatHelper.parseDecimal(_get(e, "totalBuyPrice", 0)) || 0,
    currentPrice: formatHelper.parseDecimal(_get(e, "currentPrice", 0)) || 0,
    totalCurrentPrice: formatHelper.parseDecimal(_get(e, "totalCurrentPrice", 0)) || 0,
    allocationPercentage: formatHelper.parseDecimal(_get(e, "allocationPercentage", 0)) || 0,
    profitPrice: formatHelper.parseDecimal(_get(e, "profitPrice", 0)) || 0,
    profitPercentage: formatHelper.parseDecimal(_get(e, "profitPercentage", 0)) || 0,
    profitType: _get(e, "profitType", null),

  }))

  d = {
    isBuyCallEnabled: _get(res, "isBuyCallEnabled", null),
    sell: parseTransactions(_get(res, "sell", [])),
    buy: parseTransactions(_get(res, "buy", [])),
  };

  return d;
};

export { rmsParsers };
