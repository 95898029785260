export const investMoreConstants = {
    INVESTMENT_PRICING_DETAILS_API: "v1/investment/additional-investment-fees",
    SUBMIT_INVEST_MORE_API: "v1/investment/submit-invest-more",
    INVESTMENT_STOCKS_REQUEST: "INVESTMENT_STOCKS_REQUEST",
    INVESTMENT_STOCKS_RESPONSE: "INVESTMENT_STOCKS_RESPONSE",
    INVESTMENT_STOCKS_SAGA: "INVESTMENT_STOCKS_SAGA",
    INVESTMENT_STOCKS_API: "v1/investment/additional-investment/stocks",

    RESET_INVEST_MORE_DATA: "RESET_INVEST_MORE_DATA"
};
  