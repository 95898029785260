import React from "react";
import JarvisButton from "components/JarvisButton";
import Box from "components/common/Box";
import Text from "components/common/Text";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        padding: "16px 24px",
        margin: "16px 0",
        border: "1px solid #EBEBEB",
        borderRadius: "8px",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    buttonWrapper: {
        flex: "0.5",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

const ProductListCard = (props) => {
    const classes = useStyles();
    const { details,handleNavigation } = props;
    return (
        <>
            <Box className={classes.cardWrapper}>
                <Box sx={{ flex: "0.5" }}>
                    <Text variant="body1" sx={{ fontWeight: 500 }}>
                        {details.productName}
                    </Text>

                    <ul style={{ margin: 0, paddingLeft: "30px" }}>
                        {details.productDesc.map((item,index) => (
                            <li style={{ color: "#676E76" }} key={`product_details_${index}`}>
                                <Text variant="small" color="#676E76">
                                    {item}
                                </Text>
                            </li>
                        ))}
                    </ul>
                </Box>
                <Box className={classes.buttonWrapper}>
                    <JarvisButton onClick={()=>handleNavigation(details.route)} disableEndIcon>Start Investing</JarvisButton>
                </Box>
            </Box>
        </>
    );
};

export default ProductListCard;
