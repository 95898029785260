import { ArrowBack } from "@mui/icons-material";
import React from "react";
import Button from "../common/Button";
import Stack from "../common/Stack";
import Text from "../common/Text";
import Box from "../common/Box";


const HelpSupportQuestionDetails = (props) => {
  const { questionsData, onBackClick } = props;
  return (
    <Box
    sx={{
      backgroundColor: "#fff",
    }}
    role="presentation"
  >
    <Stack
      direction="row"
      p="10px 8px"
      sx={{ alignItems: "center", borderBottom: "1px solid #0000001f" }}
    >
      <Button
        variant="text"
        onClick={onBackClick}
        sx={{ color: "#1D9BFF !important", fontWeight: "500" }}
      >
        {<ArrowBack fontSize="small" color="primary" />}
      </Button>
      <Text fontWeight="bold" color="#3A3A3A">
        {questionsData ? questionsData.title : ""}
      </Text>
    </Stack>

    <Box p="15px 15px 50px 15px" sx={{ height: "calc(100% - 57px)", overflow: "auto" }}>
      <section
        dangerouslySetInnerHTML={{
          __html: questionsData ? questionsData.description : "",
        }}
      ></section>
    </Box>
  </Box>
    
  );
};

export default HelpSupportQuestionDetails;
