import React from "react";
import { tracker } from "library/tracker";
import EditProfileContainer from "containers/Profile/EditProfile";

const EditProfile = () => {
  React.useEffect(() => {
    tracker.pageView();
  }, []);
  return <EditProfileContainer />
};

export default EditProfile;
