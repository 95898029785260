import { formatHelper } from "helper/format";
import _get from "lodash/get";

const masterParser = {};

masterParser.rmsRiskAssessmentQuestions = (res) => {
    let data = [];

    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.length) {
        return data;
    }

    const parseOptions = (options) =>
        options.map((o) => ({
            questionId: _get(o, "questionId", null),
            id: _get(o, "optionId", null),
            slug: _get(o, "optionSlug", null),
            name: _get(o, "optionName", null),
        }));

    data = res.map((r) => ({
        id: _get(r, "questionId", null),
        slug: _get(r, "questionSlug", null),
        name: _get(r, "questionName", null),
        options: parseOptions(_get(r, "options", [])),
    }));

    return data;
};

masterParser.rmsRiskProfiles = (res) => {
    let data = [];

    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.length) {
        return data;
    }

    data = res.map((r) => ({
        id: _get(r, "riskProfileId", null),
        name: _get(r, "riskProfileName", null),
        riskLevelValue: _get(r, "riskProfileValue", null),
        description: _get(r, "riskProfileDescription", null),
        scoreAlert: _get(r, "riskReturnScoreAlert", null),
        scoreMessage: _get(r, "riskReturnScoreMessage", null),
    }));

    return data;
};

masterParser.getMasterBrokers = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.list) {
        res = res.list;
    }

    if (!res || !res.length) {
        return [];
    }

    return res.map(r => ({
        brokerId: _get(r, "brokerId", null),
        brokerName: _get(r, "brokerName", null),
        brokerDisplayName: _get(r, "brokerDisplayName", null),
        brokerPlatform: _get(r, "brokerPlatform", null),
        brokerIcon: formatHelper.getLegacyImageURI(_get(r, "brokerIcon", null)),
        brokerCode: _get(r, "brokerCode", null),
    }))
}

// masterParser.getOnestockMasterBrokers = (res) => {
//     if (res && res.response) {
//         res = res.response;
//     }

//     if (res && res.list) {
//         res = res.list;
//     }

//     if (!res || !res.length) {
//         return [];
//     }

//     return res.map(r => ({
//         brokerId: _get(r, "brokerId", null),
//         brokerName: _get(r, "brokerName", null),
//         brokerDisplayName: _get(r, "brokerDisplayName", null),
//         brokerPlatform: _get(r, "brokerPlatform", null),
//         brokerIcon: formatHelper.getLegacyImageURI(_get(r, "brokerIcon", null)),
//         brokerCode: _get(r, "brokerCode", null),
//     }))
// }

masterParser.validateOffer = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        discountType: _get(res, 'discountType', null),
        discountValue: _get(res, 'discountValue', null),
        discountPrice: _get(res, "discountPrice", null),
        discountedPrice: _get(res, 'discountedPrice', null),
        isValid: _get(res, 'isValid', null),
        offerCode: _get(res, 'offerCode', null),
        offerId: _get(res, 'offerId', null),
        offerIteration: _get(res, 'offerIteration', null),
    }
}

masterParser.pendingCall = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    let count = 0
    let portfolioPendingCount = 0
    let protectPendingCount = 0
    let onestockPendingCount = 0

    if (res.isMobileVerified === 0) {
        count = count + 1
    }

    if (res.isEmailVerified === 0) {
        count = count + 1
    }

    if (res.PORTFOLIO.isPendingCall === 1) {
        portfolioPendingCount = portfolioPendingCount + 1
    }
    
    if (res.PORTFOLIO.isPlanExpired === 1) {
        portfolioPendingCount = portfolioPendingCount + 1
    }

    if (res.RMS.isPendingCall === 1) {
        protectPendingCount = protectPendingCount + 1
    }
    
    if (res.RMS.isPlanExpired === 1) {
        protectPendingCount = protectPendingCount + 1
    }

    if (res.ONESTOCK.isPendingCall === 1) {
        onestockPendingCount = onestockPendingCount + 1
    }
    
    if (res.ONESTOCK.isPlanExpired === 1) {
        onestockPendingCount = onestockPendingCount + 1
    }

    let totalPendingCount = count + portfolioPendingCount + protectPendingCount + onestockPendingCount

    return {
        isMobileVerified: _get(res, 'isMobileVerified', null),
        isEmailVerified: _get(res, 'isEmailVerified', null),
        portfolioData: _get(res, "PORTFOLIO", {}),
        protectData: _get(res, 'RMS', {}),
        onestockData: _get(res, 'ONESTOCK', {}),
        portfolioPendingCallType: _get(res, "PORTFOLIO.pendingCallType", null).replace("_"," ").toLowerCase(),
        protectPendingCallType: _get(res, "RMS.pendingCallType", null).replace("_"," ").toLowerCase(),
        onestockPendingCallType: _get(res, "ONESTOCK.pendingCallType", null).replace("_"," ").toLowerCase(),
        totalPendingCount: totalPendingCount,
        portfolioPendingCount: portfolioPendingCount,
        protectPendingCount: protectPendingCount,
        onestockPendingCount: onestockPendingCount,
        mandatoryCount: count,
    }
}
masterParser.fetchPlanDiscountedPriceOnUpgrade = (res)=>{
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }
    return res;
}
export { masterParser };
