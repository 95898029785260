import RMSTransactionDetailContainer from "containers/JarvisRMS/RMSTransactionDetails";

function RMSTransactionDetail() {
	return (
		<>
			<RMSTransactionDetailContainer />
		</>
	);
}

export default RMSTransactionDetail;
