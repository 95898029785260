import { combineReducers } from "redux";

// onestock reducers
import { jarvisOnestockOnboardingReducer } from "./jarvisOnestockOnboarding/jarvisOnestockOnboardingReducer";
import { jarvisOnestockExistingHoldingReducer } from "./jarvisOnestockExistingHolding/jarvisOnestockExistingHoldingReducer";
import { jarvisOnestockTransactionReducer } from "./jarvisOnestockTransaction/jarvisOnestockTransactionReducer";
import { jarvisOnestockActionCenterReducer } from "./jarvisOnestockActionCenter/jarvisOnestockActionCenterReducers";
import { jarvisOnestockDashboardReducer } from "./jarvisOnestockDashboard/jarvisOnestockDashboardReducer";

const jarvisOnestockRootReducer = combineReducers({
    onestockOnboarding: jarvisOnestockOnboardingReducer,
    onestockExistingHolding: jarvisOnestockExistingHoldingReducer,
    onestockTransaction: jarvisOnestockTransactionReducer,
    onestockActionCenter: jarvisOnestockActionCenterReducer,
    onestockDashboard: jarvisOnestockDashboardReducer,
});

export { jarvisOnestockRootReducer };
