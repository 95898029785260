import React, { useState, useEffect } from "react";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import JarvisButton from "components/JarvisButton";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import { validationHelper } from "helper/validation";

const useStyles = makeStyles(() => ({
  otpContainer: {
    height: "56px",
    width: "100%",
  },
  otpInput: {
    height: "56px",
    width: "57px",
    border: "1px solid #E0E7FF",
    flex: "1",
  },
  otpResend: {
    color: "#72aaff",
    background: "transparent !important",
    padding: "0",
    boxShadow: "none !important",
    fontSize: "12px",
    display: "inline",
    textTransform: "none",
    cursor: "pointer",
    minWidth: "auto",
    marginRight: "8px",
  },
}));

const CampaignVerification = (props) => {
  const classes = useStyles();
  const {

    onRequestOtp,
    onSubmit,
    onResendOtp,
    formManage,
    emailResendTime,
    mobileResendTime,
    emailResendTimerFunction,
    mobileResendTimerFunction,
    isLoading,

  } = props;

  const [resendOtpInterval, setResendOtpInterval] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [formData, setFormData] = useState({
    emailOtp: "",
    mobileOtp: "",
  });
  const [formError, setFormError] = useState({
    emailOtp: "",
    mobileOtp: "",
  });
  const [resendTime, setResendTime] = useState(0);
  const otpRequestTimer = 30;

  useEffect(() => {
    setResendTime((prev) => prev - 1);
    resendTime <= 0 && clearInterval(resendOtpInterval);
  }, [refresh]);

  const handleChange = (name, value) => {
    if (name === "mobile" && isNaN(value)) {
      return;
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRequestOtp = () => {
    const mobileValidation = validationHelper.mobile(formManage.mobile);
    const emailValidation = validationHelper.email(formManage.email);

    if (mobileValidation.isValid && emailValidation.isValid) {
      setFormError((prev) => ({
        ...prev,
        mobile: mobileValidation.message,
        email: emailValidation.message,
      }));
      onRequestOtp(formManage.mobile, formManage.email, () => {
        emailResendTimerFunction();
        mobileResendTimerFunction();
      });
    } else {
      setFormError((prev) => ({
        ...prev,
        mobile: mobileValidation.message,
        email: emailValidation.message,

      }));
    }
  };


  const resendTimer = () => {
    if (resendTime > 0) {
      return;
    }
    setResendTime(otpRequestTimer);
    const interval = setInterval(() => {
      setRefresh(Math.random());
    }, 1000);
    setResendOtpInterval(interval);
  };
  return (
    <Stack alignItems="center" sx={{ px: 2, pt: 1 }}>
      {!formManage.isRequestOtp && formManage.email ? (
        <TextField
          disabled={true}
          label="Email"
          value={formManage.email}
          sx={{ width: 1, mb: "20px" }}
        />
      ) : null}

      {!formManage.isRequestOtp && formManage.mobile ? (
        <TextField
          disabled={true}
          label="Mobile"
          value={formManage.mobile}
          sx={{ width: 1, mb: "20px" }}
        />
      ) : null}


      {formManage.isRequestOtp && !formManage.mobileVerified && !formManage.emailVerified ? (
        <Stack sx={{ width: 1, mt: 3 }}>

          <Stack direction="row" justifyContent="space-between">
            <Text
              variant="caption1"
              sx={{
                wordBreak: "break-word",
                marginRight: "10px",
              }}
            >
              Enter OTP sent on {formManage.email}
            </Text>
            <Text
              variant="caption1"
              className={classes.resendText}
              sx={{
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
              }}
            >
              <Button
                component="span"
                className={classes.otpResend}
                onClick={() => onResendOtp("email")}
                disabled={
                  emailResendTime > 0 ? true : false
                }
                loading={isLoading}>
                Resend
              </Button>
              {emailResendTime > 0
                ? ` in 00:${emailResendTime}`
                : null}
            </Text>
          </Stack>

          <OtpInput
            className={classes.otpContainer}
            inputStyle={classes.otpInput}
            value={formData.emailOtp}
            numInputs={6}
            isInputNum={true}
            onChange={(value) => handleChange("emailOtp", value)}
            separator={<span>&nbsp;</span>}
          />
          <Stack direction="row" justifyContent="space-between">
            <Text
              variant="caption1"
              sx={{
                wordBreak: "break-word",
                marginRight: "10px",
              }}
            >
              Enter OTP sent on {formManage.mobile}
            </Text>
            <Text
              variant="caption1"
              className={classes.resendText}
              sx={{
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
              }}
            >
              <Button
                component="span"
                className={classes.otpResend}
                onClick={() => onResendOtp("mobile")}
                disabled={
                  mobileResendTime > 0 ? true : false
                }
                loading={isLoading}>
                Resend
              </Button>
              {mobileResendTime > 0
                ? ` in 00:${mobileResendTime}`
                : null}
            </Text>
          </Stack>
          <OtpInput
            className={classes.otpContainer}
            inputStyle={classes.otpInput}
            value={formData.mobileOtp}
            numInputs={6}
            isInputNum={true}
            onChange={(value) => handleChange("mobileOtp", value)}
            separator={<span>&nbsp;</span>}
          />
        </Stack>
      ) : null}
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%", mt: 3 }}
      >
        {formManage.isRequestOtp ? (
          <JarvisButton
            sx={{ mt: 3 }}
            onClick={() => onSubmit(formManage.mobile, formManage.email, formData.mobileOtp, formData.emailOtp)}
            loading={isLoading}
            disabled={formData?.emailOtp?.length !== 6 && formData?.mobileOtp?.length !== 6}
          >
            Verify Otp
          </JarvisButton>
        ) : (
          <Stack
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <JarvisButton
              sx={{ mt: 3 }}
              onClick={handleRequestOtp}
              loading={isLoading}
            >
              Request OTP
            </JarvisButton>


          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CampaignVerification;
