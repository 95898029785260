import { call, put, takeLatest } from "@redux-saga/core/effects";

import { commonApis } from "./commonApis";
import { commonParsers } from "./commonParsers";
import { commonConstants } from "./commonConstants";

// tracker
import { tracker } from "library/tracker";

function* blogsSaga({ type }) {
    try {
        yield put({ type: commonConstants.BLOGS_REQUEST });
        
        const res = yield call(commonApis.getBlogs);
        const payload = commonParsers.blogs(res);
        // NOTE : event_tracker => blog
         tracker.blog.blogsSuccess();
        yield put({ type: commonConstants.BLOGS_RESPONSE, payload });
    } catch (error) {
        // NOTE : event_tracker => blog
        tracker.blog.blogsFailed();
    }
}

function* getRiskAssessmentQuestionsSaga() {
    try {
        yield put({ type: commonConstants.RISK_ASSESSMENT_QUESTIONS_REQUEST });

        const res = yield call(commonApis.getRiskAssessmentQuestions);

        const payload = commonParsers.riskAssessmentQuestions(res);

        yield put({
            type: commonConstants.RISK_ASSESSMENT_QUESTIONS_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: commonConstants.RISK_ASSESSMENT_QUESTIONS_RESPONSE,
            payload: [],
        });
    }
}

function* getRiskProfilesSaga() {
    try {
        yield put({ type: commonConstants.RISK_PROFILES_REQUEST });

        

        const res = yield call(commonApis.getRiskProfiles);

        //event-tracker: Risk profile -> change_my_risk_profile_success
        tracker.riskProfile.changeMyRiskProfileSuccess({})

        const payload = commonParsers.riskProfiles(res);

        yield put({ type: commonConstants.RISK_PROFILES_RESPONSE, payload });
    } catch (e) {
        //event-tracker: Risk profile -> change_my_risk_profile_failed
        tracker.riskProfile.changeMyRiskProfileFailed({})
        yield put({
            type: commonConstants.RISK_PROFILES_RESPONSE,
            payload: [],
        });
    }
}

function* getBrokersSaga({ payload }) {
    const { domain, productCode } = payload;
    try {
        yield put({ type: commonConstants.BROKERS_REQUEST });

        const res = yield call(commonApis.getBrokers, { domain, productCode });
        const payload = commonParsers.brokers(res);

        yield put({ type: commonConstants.BROKERS_RESPONSE, payload });
    } catch (error) {
        yield put({ type: commonConstants.BROKERS_RESPONSE, payload: [] });
    }
}

function* marketUpdatesSaga() {
    try {
        yield put({ type: commonConstants.MARKET_UPDATES_REQUEST });
        const res = yield call(commonApis.getMarketUpdate);
        const payload = commonParsers.marketUpdate(res);
        //NOTE : event_tracker => marketUpdate
        tracker.marketUpdate.marketUpdateSuccess();
        yield put({ type: commonConstants.MARKET_UPDATES_RESPONSE, payload });
    } catch (error) {
        //NOTE : event_tracker => marketUpdate
        tracker.marketUpdate.marketUpdateFailed();
        yield put({
            type: commonConstants.MARKET_UPDATES_RESPONSE,
            payload: [],
        });
    }
}

function* newsArticlesSaga(payloadData) {
    try {
        yield put({ type: commonConstants.NEWS_ARTICLES_REQUEST });
        const res = yield call(commonApis.getNewsArticles,payloadData.payload);
        const payload = commonParsers.newsArticles(res);
        yield put({ type: commonConstants.NEWS_ARTICLES_RESPONSE, payload });
    } catch (error) {
        yield put({
            type: commonConstants.NEWS_ARTICLES_RESPONSE,
            payload: {},
        });
    }
}

function* appConfigSaga({payload}) {
    const { appVersion, operatingSystem } = payload;
    try {
        yield put({ type: commonConstants.APP_CONFIG_REQUEST });
        const res = yield call(commonApis.getAppConfig,{appVersion, operatingSystem});
        const payload = commonParsers.appConfig(res);
        yield put({ type: commonConstants.APP_CONFIG_RESPONSE, payload });
    } catch (error) {
        yield put({
            type: commonConstants.APP_CONFIG_RESPONSE,
            payload: {},
        });
    }
}

function* getPaymentMethodsSaga() {
    try {
        yield put({ type: commonConstants.PAYMENT_METHODS_REQUEST });
        const res = yield call(commonApis.getPaymentMethods);
        const payload = commonParsers.getPaymentMethods(res);
        yield put({ type: commonConstants.PAYMENT_METHODS_RESPONSE, payload });
    } catch (error) {
        yield put({
            type: commonConstants.PAYMENT_METHODS_RESPONSE,
            payload: [],
        });
    }
}

function* getFeatureConfigSaga() {
    try {
        yield put({ type: commonConstants.FEATURE_CONFIG_REQUEST });
        const res = yield call(commonApis.getFeatureConfig);
        const payload = commonParsers.getFeatureConfig(res);
        yield put({ type: commonConstants.FEATURE_CONFIG_RESPONSE, payload });
    } catch(error) {
        yield put({ type: commonConstants.FEATURE_CONFIG_RESPONSE, payload: {} })
    }
}

export function* registerCommonSagas() {
    yield takeLatest(commonConstants.BLOGS_SAGA, blogsSaga);
    yield takeLatest(commonConstants.BROKERS_SAGA, getBrokersSaga);
    yield takeLatest(
        commonConstants.RISK_ASSESSMENT_QUESTIONS_SAGA,
        getRiskAssessmentQuestionsSaga
    );
    yield takeLatest(commonConstants.RISK_PROFILES_SAGA, getRiskProfilesSaga);
    yield takeLatest(commonConstants.MARKET_UPDATES_SAGA, marketUpdatesSaga);
    yield takeLatest(commonConstants.NEWS_ARTICLES_SAGA, newsArticlesSaga);
    yield takeLatest(commonConstants.APP_CONFIG_SAGA, appConfigSaga);
    yield takeLatest(commonConstants.PAYMENT_METHODS_SAGA, getPaymentMethodsSaga);
    yield takeLatest(commonConstants.FEATURE_CONFIG_SAGA, getFeatureConfigSaga);
}
