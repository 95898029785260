import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { onboardingActions } from "stores/onboarding/onboardingActions";

const mapStateToProps = (state) => {
    return {
        currentStage: state.onboarding.currentStage,
        riskProfiles: state.common.riskProfiles,
        riskAssessmentQuestions: state.common.riskAssessmentQuestions,
        loginProfile : state.customer.loginProfile,
        isRiskProfileLoading: state.common.loading.riskProfiles,
        isRiskAssessmentLoading: state.common.loading.riskAssessmentQuestions,
        suggestedRiskId: state.onboarding.suggestedRiskId,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateOnboardingDataAction: onboardingActions.updateOnboardingData,
    getRiskAssessmentQuestionsAction: commonActions.getRiskAssessmentQuestions,
    getRiskProfilesAction: commonActions.getRiskProfiles,
    getOnboardingDataAction: onboardingActions.getOnboardingData,
}, dispatch);

const RiskAssessmentStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default RiskAssessmentStore;
