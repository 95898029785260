import { axios } from "helper/axios";
import { dashboardConstants } from "./dashboardConstants";

const dashboardApis = {};

dashboardApis.getPortfolioAllocations = () => {
  return axios.get(dashboardConstants.PORTFOLIO_ALLOCATIONS_API);
};

dashboardApis.getPortfolioOverview = () => {
  return axios.get(dashboardConstants.PORTFOLIO_OVERVIEW_API);
};
dashboardApis.getCustomerStoriesList = () => {
  return axios.get(dashboardConstants.CUSTOMER_STORIES_API);
};

dashboardApis.getWelcomeVideoList = () => {
  return axios.get(dashboardConstants.WELCOME_VIDEO_API);
};

dashboardApis.getPortfolioPerformance = () => {
  return axios.get(dashboardConstants.PORTFOLIO_PERFORMANCE_API);
};

dashboardApis.getPortfolioChart = () => {
  let qeuryParam = `?durationOfMonth=${12}`;
  return axios.get(`${dashboardConstants.PORTFOLIO_CHART_API}${qeuryParam}`);
};

dashboardApis.getPortfolioSector = () => {
  return axios.get(dashboardConstants.PORTFOLIO_SECTOR_API);
};

dashboardApis.getPortfolioMarket = () => {
  return axios.get(dashboardConstants.PORTFOLIO_MARKET_API);
};

dashboardApis.getPortfolioAlert = () => {
  return axios.post(dashboardConstants.PORTFOLIO_ALERT_API);
};

dashboardApis.getPortfolioPendingTransaction = async () => {
  return axios.post(dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_API);
};

dashboardApis.generateNewAction = () => {
  return axios.post(dashboardConstants.PORTFOLIO_GENERATE_API);
};

dashboardApis.repairTranaction = () => {
  return axios.post(dashboardConstants.REPAIR_TRANSACTION_API);
};

dashboardApis.porfolioArchive = ({ data }) => {
  let d = data.map((e) => ({
    action: e.action,
    actionTakenByUser: e.actionTakenByUser,
    qty: e.quantity,
    rate: e.rate,
    symbol: e.symbol,
    tracsactionType: e.tracsactionType,
  }));

  let body = {
    stockDtls: d,
  };
  return axios.post(dashboardConstants.PORTFOLIO_ARCHIVE_API, body);
};

dashboardApis.finalPortfolioConfirmation = () =>{
  return axios.post(dashboardConstants.FINAL_PORTFOLIO_CONFIRMATION);
}

export { dashboardApis };
