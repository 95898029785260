import React, { memo, useState } from "react";

import Dialog from "components/common/Dialog";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import TextField from "components/common/TextField";
import { validationHelper } from "helper/validation";
import JarvisButton from "./JarvisButton";
import { customerApis } from "stores/customer/customerApis";
import { config } from "config/index";
import MenuItem from "./common/MenuItem";
import Box from "./common/Box";
import JarvisDialog from "./JarvisDialog";

const CancelPlanSubscription = ({
  open = false,
  icon = {},
  openComponent,
  customerSubscriptionId,
  reasonsDropdown = [],
}) => {
  const [reasonForCancellation, setReasonForCancellation] = useState("");
  const [reasonDropdownForCancellation, setReasonDropdownForCancellation] = useState("");
  const [showTextForOtherReason, setShowTextForOtherReason] = useState(false);
  const [errorReasonForCancellation, setErrorReasonCancellation] = useState("");
  const [errorReasonForDropdown, setErrorReasonDropdown] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const CANCEL_SUBSCRIPTION_MESSAGE = {
    success: {
      title: "Congrats!!!",
      content: "Your cancellation request submitted successfully",
      actionText: "Continue",
      icon: "/images/svgs/success.gif"
    },
    error: {
      title: "Oops!!!",
      content: "Something went wrong! Please try again later",
      actionText: "Go Back",
      icon: "/images/svgs/failed.gif"
    },
  }

  const handleInputChange = (name, value) => {
    if (name === "reasonForCancellation") {
      setReasonForCancellation(value);
    }
    if (name === "reasonDropdownForCancellation") {
      setReasonDropdownForCancellation(value);
      if (value === "OTHERS") {
        setShowTextForOtherReason(true)
      }
      else {
        setShowTextForOtherReason(false)
      }
    }
  };

  const resetForm = () => {
    setReasonForCancellation("");
    setErrorReasonCancellation("");
    setReasonDropdownForCancellation("");
    setShowTextForOtherReason(false);
  }

  const handleValidation = () => {
    const errorReasonForCancellation = validationHelper.required(reasonForCancellation);
    setErrorReasonCancellation(errorReasonForCancellation.message);

    const errorReasonForDropdown = validationHelper.required(reasonDropdownForCancellation);
    setErrorReasonDropdown(errorReasonForDropdown.message);

    return (errorReasonForCancellation.isValid, errorReasonForDropdown.isValid);
  };

  const handleSubmit = async (onClose) => {
    if (!handleValidation()) {
      return;
    }

    setIsLoading(true);

    try {
      await customerApis.cancelSubscription({
        reason: showTextForOtherReason ? reasonForCancellation : reasonDropdownForCancellation,
        platform: config.platform,
        customerSubscriptionId: customerSubscriptionId || 0,
      });
      onClose();
      setDialogContent(CANCEL_SUBSCRIPTION_MESSAGE.success)
      setDialogOpen(true)
    } catch (error) {
      setDialogContent(CANCEL_SUBSCRIPTION_MESSAGE.error)
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        icon={icon}
        onClose={resetForm}
        maxWidth="xs"
        openComponent={openComponent}
        contentComponent={() => (
          <Stack>
            <Text variant="body2" color="#202020" sx={{ textAlign: "center", marginTop: ".8rem" }}>
              <img src="/images/profile/cancel-plan.png" alt="Cancel Plan Subscription" />
            </Text>
            <Text variant="h5" color="#202020" sx={{ textAlign: "center", fontWeight: "bold" }}>
              Cancel Plan
            </Text>
            <Text variant="body2" color="#202020" sx={{ textAlign: "center", marginTop: ".8rem" }}>
              Let us know your reason to cancel plan
            </Text>
            <TextField
              select
              label="Select reason"
              sx={{ marginTop: "1rem" }}
              value={reasonDropdownForCancellation}
              onChange={(e) => {
                handleInputChange("reasonDropdownForCancellation", e.target.value);
              }}
              error={errorReasonForDropdown}
              helperText={errorReasonForDropdown}
            >
              {reasonsDropdown.map((item) =>
                <MenuItem sx={{ fontSize: "small.fontSize", whiteSpace: "unset !important" }} key={item.label} value={item.value === "OTHERS" ? item.value : item.label}>
                  {item.label}
                </MenuItem>
              )}
            </TextField>
            {
              showTextForOtherReason ?
                <TextField
                  label="Reason for cancelation"
                  type="text"
                  value={reasonForCancellation}
                  multiline
                  rows={2}
                  onChange={(e) => {
                    handleInputChange("reasonForCancellation", e.target.value);
                  }}
                  helperText={errorReasonForCancellation}
                  error={errorReasonForCancellation}
                  sx={{ width: "80%", mb: 2, mt: 2 }}
                />
                : null
            }
          </Stack>
        )}
        actionComponent={(onClose) => {
          return (
            <Box m={2} textAlign="center">
              <JarvisButton
                variant="contained"
                onClick={() => handleSubmit(onClose)}
                loading={isLoading}
              >
                Submit cancel request
              </JarvisButton>
            </Box>
          );
        }}
        actionComponentProps={{
          sx: {
            justifyContent: "center",
            marginBottom: "1rem",
          },
        }}
      />

      <JarvisDialog
        disableCloseIcon
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogClose}
        title={dialogContent.title}
        content={dialogContent.content}
        icon={{
          src: dialogContent.icon,
          height: 120,
          width: 120
        }}
        button={{
          text: dialogContent.actionText,
        }}
      />
    </>
  );
};

export default memo(CancelPlanSubscription);
