import React from 'react'
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisChip from 'components/JarvisChipNew';
import { formatHelper } from 'helper/format';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const MobileOnestockHolding = (props) => {
    const { holdingData } = props

    return (
        <>
            <Stack>
                <Stack mb={1} direction="row" sx={{ justifyContent: "space-between" }}>
                    <Stack direction="row">
                        <JarvisChip
                            sx={{ padding: "0px", marginTop: "-2px", fontSize: "0.65rem" }}
                            size="small"
                            label={holdingData?.marketCap}
                            color="marketCap"
                        />
                    </Stack>
                </Stack>
                <Stack mb={1} direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        {
                            holdingData?.brokerName !== null ?
                                <Text
                                    variant="caption1"
                                    mr={2}
                                    sx={{ color: "#A2A7AE" }}
                                >
                                    {holdingData?.brokerName}
                                </Text>
                                : null
                        }
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE" }}>Qty.</span>
                            <span style={{ color: "303236" }}>{holdingData?.stockQuantity}</span>
                        </Text>
                    </Stack>
                    <Stack direction="row">
                        <Stack direction="row">
                            {holdingData?.stockGainLoss < 0 ?
                                <>
                                    <ArrowDropDownIcon sx={{ color: "#D44C4C", fontSize: "20px" }} />
                                    <Text variant="caption1" sx={{ color: "#D44C4C" }}>
                                        {formatHelper.currency(
                                            holdingData?.stockGainLoss
                                        ) || 0}
                                    </Text>
                                </>
                                :
                                <>
                                    <ArrowDropUpIcon sx={{ color: "#45AC70", fontSize: "20px" }} />
                                    <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                        {formatHelper.currency(
                                            holdingData?.stockGainLoss
                                        ) || 0}
                                    </Text>
                                </>
                            }
                            {
                                holdingData?.stockGainLossPercentage < 0 ?
                                    <Text variant="caption1" sx={{ color: "#D44C4C" }}>
                                        ({(holdingData?.stockGainLossPercentage || 0) +
                                            "%"})
                                    </Text>
                                    :
                                    <Text variant="caption1" sx={{ color: "#45AC70" }}>
                                        ({(holdingData?.stockGainLossPercentage || 0) +
                                            "%"})
                                    </Text>
                            }
                        </Stack>
                    </Stack>
                </Stack>
                <Stack mb={1} direction="row" justifyContent="space-between">
                    <Text variant="small" sx={{ fontWeight: 500 }}>
                        {holdingData?.stockName}
                    </Text>
                    <Text variant="small" sx={{ fontWeight: 500 }}>
                        {formatHelper.currency(
                            Math.abs(holdingData?.stockBuyPrice)
                        ) || 0}
                    </Text>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE", marginRight: "3px" }}>Current Value</span>
                            <span style={{ color: "303236" }}>
                                {formatHelper.currency(
                                    Math.abs(holdingData?.stockCurrentValue)
                                ) || 0}
                            </span>
                        </Text>
                    </Stack>
                    <Stack direction="row">
                        <Text variant="caption1">
                            <span style={{ color: "#A2A7AE", marginRight: "3px" }}>Total Invested</span>
                            <span style={{ color: "303236" }}>
                                {formatHelper.currency(
                                    Math.abs(holdingData?.stockBuyValue)
                                ) || 0}
                            </span>
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}
export default MobileOnestockHolding;

