import React from "react";
import _get from "lodash.get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";

import { Chip, Popover } from "@mui/material";

import Text from "components/common/Text";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import Loader from "components/common/Loader";
import Table from "components/common/Table";
import Modal from "components/common/Modal";
import IconButton from "components/common/IconButton";
import MobileResponsive from "components/common/MobileResponsive";

import PageTitle from "components/PageTitle";
import JarvisCard from "components/JarvisCard";
import JarvisChipNew from "components/JarvisChipNew";
import JarvisButton from "components/JarvisButton";
import JarvisAutoSearch from "components/JarvisAutoSearch";
import SectionTitle from "components/SectionTitle";
import JarvisStockInfoCard from "components/JarvisStockInfoCard";
// import RiskProfileGraph from "components/Profile/RiskProfileGraph";
import RMSPortfolioSyncEditName from "components/RMSOnboarding/RMSPortfolioSyncEditName";
import RMSPortfolioSyncDelete from "components/RMSOnboarding/RMSPortfolioSyncDelete";
import RMSPortfolioSyncAddStock from "components/RMSOnboarding/RMSPortfolioSyncAddStock";
import RMSPortfolioSyncRemoveStock from "components/RMSOnboarding/RMSPortfolioSyncRemoveStock";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import { dateTimeHelper } from "helper/dateTime";
import { validationHelper } from "helper/validation";
import { masterApis } from "stores/master/masterApis";
import { jarvisRMSExistingHoldingApis } from "stores/jarvisRMS/jarvisRMSExistingHolding/jarvisRMSExistingHoldingApis";
import { config } from "config";

const PORTFOLIO_ACTION_LIST = [
    { id: 3, border: false, name: "Add Stock" },
    // { id: 2, border: false, name: "Edit Portfolio List" },
    // { id: 4, border: true, name: "Remove Stock" },
    { id: 1, border: false, name: "Edit Portfolio Name" },
    { id: 5, border: false, name: "Remove Portfolio" },
];

// const PROFILE_SCORE_THEME = [
//     { scoreCode: "LOW", riskMeterValue: 2, displayName: "Low Risk", color: "#45AC70", bgColor: "#45ac700a", bordercolor: "#45ac7021" },
//     { scoreCode: "MED", riskMeterValue: 4, displayName: "Medium Risk", color: "#E0953D", bgColor: "#e0953d0a", bordercolor: "#e0953d26" },
//     { scoreCode: "HIGH", riskMeterValue: 6, displayName: "High Risk", color: "#D44C4C", bgColor: "#d44c4c0a", bordercolor: "#d44c4c26" },
// ];

let viewStockInfoData = {};

const HandleNoStock = (props) => (
    <JarvisCard sx={{ justifyContent: "center" }}>
        <Stack justifyContent="center" alignItems="center">
            <Text sx={{ textAlign: "center" }} mb={2}>It seems that your portfolio is currently empty.</Text>
            <JarvisButton onClick={props.onAddStockClick}>Add Stock</JarvisButton>
        </Stack>
    </JarvisCard>

)

class RMSExistingHoldingContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            activePortfolioId: null,
            portfolioMenuAchorEl: null,

            masterStockList: [],

            editportfolioNameModalOpen: false,
            editPortfolioNameFormData: {
                id: "",
                name: "",
            },
            editPortfolioNameFormError: {
                name: "",
            },

            isRemovePortfolioModalOpen: false,
            removePortfolioId: null,

            addStockModalOpen: false,
            addStockForm: {
                stockId: null,
                stockName: "",
                stockQuantity: null,
                stockBuyPrice: null,
                stockBuyDate: "",
            },
            addStockFormError: {
                stockId: "",
                stockName: "",
                stockQuantity: "",
                stockBuyPrice: "",
                stockBuyDate: "",
            },

            editStockModalOpen: false,
            editStockForm: {
                portfolioStockId: null,
                stockId: null,
                stockName: "",
                stockQuantity: null,
                stockBuyPrice: null,
                stockBuyDate: "",
            },
            editStockFormError: {
                portfolioStockId: "",
                stockId: "",
                stockName: "",
                stockQuantity: "",
                stockBuyPrice: "",
                stockBuyDate: "",
            },

            deleteStockModalOpen: false,
            deleteStockForm: {
                portfolioStockId: null,
                stockId: null,
            },

            RemoveStockModalOpen: false,

            whySellModal: false,
            whySellMessage: "",

            page: 1,
            rowsPerPage: 25,
            refreshTable: false,
            dataChangedCounter: 0,

            portfolioStockListCalled: false,

            isFabListVisiable: false,
            isEditStockVisiable: false,
            editPortfolioPrice: "",
            editStockModal: false,
            viewStockInfoModal: false,
            deleteStockConfirmModal: false,
        }
    }

    componentDidMount() {
        this.props.getRMSOnobardingDataAction();
        this.props.getProductsSubscriptionDetailsAction();
        this.props.getPortfolioListAction();

        if (!this.state.masterStockList.length) {
            this.fetchMasterStockList();
        }
    }

    componentDidUpdate(prevProps) {
        const prevPortfolioId = _get(prevProps, 'match.params.portfolioId', null);
        const { portfolioId, portfolioFromRoute } = this.getActivePortfolioId();

        if (portfolioId) {
            if (Number(prevPortfolioId) !== Number(portfolioId)) {
                if (!portfolioFromRoute) {
                    this.props.history.replace(`/jarvis-rms/existing-holding/${portfolioId}`);
                }
                this.props.getStockListAction({ portfolioId, isTemp: 0 });
            }
            if (Number(prevPortfolioId) === Number(portfolioId) && !this.state.portfolioStockListCalled) {
                this.props.getStockListAction({ portfolioId, isTemp: 0 });
                this.setState({ portfolioStockListCalled: true });
            }
        }
    }

    fetchMasterStockList = async () => {
        try {
            const stockList = await masterApis.getStockList();
            const parsedStockList = stockList.list.map(s => ({
                label: s.stockName,
                value: s.stockName,
                ...s,
            }))
            this.setState({ masterStockList: parsedStockList });
        } catch (err) {
            console.error(err);
        }
    }

    getActivePortfolioId = () => {
        let portfolioId = _get(this.props, 'match.params.portfolioId', null);
        let portfolioFromRoute = portfolioId !== null;
        if (!portfolioId) {
            portfolioId = _get(this.props, 'portfolioList[0].portfolioId', null);
            portfolioFromRoute = false;
        }
        return {
            portfolioId: parseInt(portfolioId),
            portfolioFromRoute,
        };
    }

    handleAddPortfolioClick = () => {

        const { productSubscriptionDetails = [] } = this.props;
        const protectSubDetails = productSubscriptionDetails.find(p => p.productCode === config.productCodes.RMS);

        // Plan limit exceed handling
        if (protectSubDetails && protectSubDetails.portfolioLimitBalance <= 0) {
            const message = `According to your current subscription plan, you have reached the limit of adding more portfolios. If you wish to add more portfolios to your profile, we recommend upgrading your current plan.`;
            this.props.setDialog({
                open: true,
                onCloseTakeToIntercom: true,
                message,
                heading: "Exceed subscription plan limit"
            });
            return;
        }

        this.props.history.push('/jarvis-rms/existing-holding/add-portfolio', { state: { processType: "EXISTING_HOLDING" } });
    }


    handlePortfolioTabClick = ({ portfolioId }) => {
        const { portfolioId: activePortfolioId } = this.getActivePortfolioId()
        if (activePortfolioId !== portfolioId) {
            this.props.history.replace(`/jarvis-rms/existing-holding/${portfolioId}`);
            this.props.getStockListAction({ portfolioId, isTemp: 0 });
        }
    }

    handlePopoverOpenClick = (event) => {
        this.setState({ AnchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ AnchorEl: null });
    };

    handlePortfolioActionClick = (id) => {
        const { portfolioId } = this.getActivePortfolioId();
        const activePortfolioData = this.props.portfolioList.find(pl => pl.portfolioId === portfolioId);
        this.handlePopoverClose();
        switch (id) {
            case 1:
                this.handleEditPortfolioNameModalOpen({ portfolioData: activePortfolioData });
                break;
            case 2:
                this.setState({ editportfolioListModalOpen: true });
                break;

            case 3:
                this.handleAddStockModalOpen();
                break;

            case 4:
                this.setState({ RemoveStockModalOpen: true });
                break;

            case 5:
                this.handleRemovePortfolioModalOpen({ portfolioData: activePortfolioData });
                break;

            default:
                break;
        }
    }

    onPageChange(page, rowsPerPage) {
        this.setState({ page, rowsPerPage, refreshTable: false, dataChangedCounter: this.state.dataChangedCounter + 1 });
    }

    // Note: edit portfolio name modal
    handleEditPortfolioNameModalOpen = ({ portfolioData }) => {
        this.setState({
            editportfolioNameModalOpen: true,
            editPortfolioNameFormData: {
                id: portfolioData.portfolioId,
                name: portfolioData.portfolioName
            },
            editPortfolioNameFormError: {
                name: ""
            }
        });
    }

    handleEditPortfolioNameModalClose = () => {
        this.setState({
            editportfolioNameModalOpen: false,
            editPortfolioNameFormData: {
                id: "",
                name: ""
            },
            editPortfolioNameFormError: {
                name: ""
            }
        });
    }

    handleEditPortfolioNameChange = (name, value) => {
        let { editPortfolioNameFormData } = this.state;
        editPortfolioNameFormData[name] = value;
        this.setState({ editPortfolioNameFormData });
    }

    handleValidation = () => {
        const { editPortfolioNameFormData, editPortfolioNameFormError, } = this.state;

        const nameValidation = validationHelper.required(editPortfolioNameFormData.name);
        editPortfolioNameFormError.name = nameValidation.message;

        this.setState({ editPortfolioNameFormError });
        return nameValidation.isValid;
    }

    handleEditPortfolioNameSubmit = async () => {
        if (!this.handleValidation()) {
            return;
        }

        const { editPortfolioNameFormData } = this.state;
        try {
            await jarvisRMSExistingHoldingApis.updatePortfolioName({ portfolioId: editPortfolioNameFormData.id, portfolioName: editPortfolioNameFormData.name, isTemp: 0 });
            this.props.getPortfolioListAction();
            this.handleEditPortfolioNameModalClose();
        } catch (err) {
            console.error(err);
        }
    }

    // NOTE: remove portfolio modal
    handleRemovePortfolioModalOpen = ({ portfolioData }) => {
        this.setState({ isRemovePortfolioModalOpen: true, removePortfolioId: portfolioData.portfolioId });
    }

    handleRemovePortfolioModalClose = () => {
        this.setState({ isRemovePortfolioModalOpen: false, removePortfolioId: null });
    }

    handleRemovePortfolioSubmit = async () => {
        const { removePortfolioId } = this.state;
        try {
            await jarvisRMSExistingHoldingApis.removePortfolio({ portfolioId: removePortfolioId, isTemp: 0 });
            window.location.href = `/jarvis-rms/existing-holding`;
            this.handleRemovePortfolioModalClose();
        }
        catch (err) {
            console.log(err)
        }
    }

    // NOTE: add portfolio stock modal
    getSelectedValue = (data) => {
        const { stockName, stockId, ...restStockData } = this.state.addStockForm;
        this.setState({ addStockForm: { stockName: data.value, stockId: data.stockId, ...restStockData } });
    }

    handleAddStockModalOpen = () => {

        const { productSubscriptionDetails = [] } = this.props;
        const protectSubDetails = productSubscriptionDetails.find(p => p.productCode === config.productCodes.RMS);

        // Stock limit exceed handling
        if (protectSubDetails && protectSubDetails.stockLimitBalance <= 0) {
            const message = `According to your current subscription plan, you have reached the limit of adding more stocks. If you wish to add more stocks we recommend upgrading your current plan.`;
            this.props.setDialog({
                open: true,
                onCloseTakeToIntercom: true,
                message,
                heading: "Exceed subscription plan limit"
            });
            return;
        }

        this.setState({
            addStockModalOpen: true,
            addStockForm: {
                stockId: null,
                stockName: "",
                stockQuantity: null,
                stockBuyPrice: null,
                stockBuyDate: "",
            },
            addStockFormError: {
                stockId: "",
                stockName: "",
                stockQuantity: "",
                stockBuyPrice: "",
                stockBuyDate: "",
            },
        })
    }

    handleAddStockModalClose = () => {
        this.setState({
            addStockModalOpen: false,
            addStockForm: {
                stockId: null,
                stockName: "",
                stockQuantity: null,
                stockBuyPrice: null,
                stockBuyDate: "",
            },
            addStockFormError: {
                stockId: "",
                stockName: "",
                stockQuantity: "",
                stockBuyPrice: "",
                stockBuyDate: "",
            },
        });
    }

    handleAddStockFormChange = ({ name, value }) => {
        const { addStockForm } = this.state;
        addStockForm[name] = value;
        this.setState({ addStockForm })
    }

    handleAddStockValidation = () => {
        const { addStockForm, addStockFormError } = this.state;

        const stockValidate = validationHelper.required(addStockForm.stockName);
        addStockFormError.stockName = stockValidate.message;

        const stockQuantity = validationHelper.inputNumber(addStockForm.stockQuantity);
        addStockFormError.stockQuantity = stockQuantity.message;

        const stockBuyPrice = validationHelper.inputNumber(addStockForm.stockBuyPrice);
        addStockFormError.stockBuyPrice = stockBuyPrice.message;

        const stockBuyDate = validationHelper.dateOfBirth(addStockForm.stockBuyDate);
        addStockFormError.stockBuyDate = stockBuyDate.message;

        this.setState({ addStockFormError });
        return (
            stockValidate.isValid &&
            stockBuyPrice.isValid &&
            stockQuantity.isValid &&
            stockBuyDate.isValid
        );
    }

    handleAddStockSubmit = async () => {
        if (!this.handleAddStockValidation()) {
            return;
        }

        const { portfolioId } = this.getActivePortfolioId();
        const { addStockForm } = this.state;

        this.setState({ isLoading: true });
        try {
            await jarvisRMSExistingHoldingApis.addStockToPortfolio({
                portfolioId,
                isTemp: 0,
                stockId: Number(addStockForm.stockId),
                stockSymbol: addStockForm.stockName,
                stockQuantity: Number(addStockForm.stockQuantity),
                stockBuyPrice: Number(addStockForm.stockBuyPrice),
                stockBuyDate: dateTimeHelper.sipDate(addStockForm.stockBuyDate),
            });
            this.props.getStockListAction({ portfolioId, isTemp: 0 });
            setTimeout(() => {
                this.handleAddStockModalClose();
                this.setState({
                    refreshTable: false,
                    isLoading: false,
                    dataChangedCounter: this.state.dataChangedCounter + 1,
                });
            }, 1500)
        }
        catch (err) {
            console.error(err)
            this.setState({ isLoading: false });
        }
    };

    // NOTE: edit portfolio stock modal
    getEditSelectedValue = (data) => {
        const { stockName, stockId, ...restStockData } = this.state.editStockForm;
        this.setState({ editStockForm: { stockName: data.value, stockId: data.stockId, ...restStockData } });
    }

    handleEditStockModalOpen = ({ stockData }) => {
        this.setState({
            editStockModalOpen: true,
            editStockForm: {
                portfolioStockId: stockData.portfolioStockId,
                stockId: stockData.stockId,
                stockName: stockData.stockName,
                stockQuantity: stockData.stockQuantity,
                stockBuyPrice: stockData.stockBuyPrice,
                stockBuyDate: stockData.stockBuyDate,
            },
            editStockFormError: {
                portfolioStockId: "",
                stockId: "",
                stockName: "",
                stockQuantity: "",
                stockBuyPrice: "",
                stockBuyDate: "",
            },
        })
    }

    handleEditStockModalClose = () => {
        this.setState({
            editStockModalOpen: false,
            editStockForm: {
                portfolioStockId: null,
                stockId: null,
                stockName: "",
                stockQuantity: null,
                stockBuyPrice: null,
                stockBuyDate: "",
            },
            editStockFormError: {
                portfolioStockId: "",
                stockId: "",
                stockName: "",
                stockQuantity: "",
                stockBuyPrice: "",
                stockBuyDate: "",
            },
        });
    }

    handleEditStockFormChange = ({ name, value }) => {
        const { editStockForm } = this.state;
        editStockForm[name] = value;
        this.setState({ editStockForm })
    }

    handleEditStockValidation = () => {
        const { editStockForm, editStockFormError } = this.state;

        const stockQuantity = validationHelper.inputNumber(editStockForm.stockQuantity);
        editStockFormError.stockQuantity = stockQuantity.message;

        const stockBuyPrice = validationHelper.inputNumber(editStockForm.stockBuyPrice);
        editStockFormError.stockBuyPrice = stockBuyPrice.message;

        this.setState({ editStockFormError });
        return (
            stockBuyPrice.isValid &&
            stockQuantity.isValid
        );
    }

    handleEditStockSubmit = async () => {
        if (!this.handleEditStockValidation()) {
            return;
        }

        const { portfolioId } = this.getActivePortfolioId();
        const { editStockForm } = this.state;

        this.setState({ isLoading: true });
        try {
            await jarvisRMSExistingHoldingApis.editStock({
                portfolioId,
                isTemp: 0,
                editPayload: [{
                    portfolioStockId: Number(editStockForm.portfolioStockId),
                    stockId: Number(editStockForm.stockId),
                    stockQuantity: Number(editStockForm.stockQuantity),
                    stockBuyPrice: Number(editStockForm.stockBuyPrice),
                }],
            });
            this.props.getStockListAction({ portfolioId, isTemp: 0 });

            setTimeout(() => {

                this.handleEditStockModalClose();

                //update view data in modal
                viewStockInfoData.stockQuantity = editStockForm.stockQuantity;
                viewStockInfoData.stockBuyPrice = editStockForm.stockBuyPrice;

                this.setState({
                    refreshTable: false,
                    isLoading: false,
                    dataChangedCounter: this.state.dataChangedCounter + 1,
                });
            }, 1500);
        }
        catch (err) {
            console.error(err)
            this.setState({ isLoading: false });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    // NOTE: delete portfolio stock modal
    handleDeleteStockModalOpen = ({ stockData }) => {
        this.setState({
            deleteStockModalOpen: true,
            deleteStockForm: {
                portfolioStockId: stockData.portfolioStockId,
                stockId: stockData.stockId,
            },
        })
    }

    handleDeleteStockModalClose = () => {
        this.setState({
            deleteStockModalOpen: false,
            deleteStockForm: {
                portfolioStockId: null,
                stockId: null,
            },
        })
    }

    handleDeleteStockSubmit = async () => {
        const { portfolioId } = this.getActivePortfolioId();
        const { deleteStockForm } = this.state;

        this.setState({ isLoading: true });
        try {
            await jarvisRMSExistingHoldingApis.deleteStock({
                portfolioId,
                isTemp: 0,
                deletePayload: [deleteStockForm.portfolioStockId],
            });
            this.props.getStockListAction({ portfolioId, isTemp: 0 });
            setTimeout(() => {
                this.handleDeleteStockModalClose();
                this.setState({
                    refreshTable: false,
                    isLoading: false,
                    dataChangedCounter: this.state.dataChangedCounter + 1,
                });
            }, 1500)
        }
        catch (err) {
            console.error(err)
            this.setState({ isLoading: false });
        }
    }

    // NOTE: why sell message
    handleWhySellOpen = ({ whySellMessage }) => {
        this.setState({ whySellMessage, whySellModal: true })
    }

    handleWhySellClose = () => {
        this.setState({ whySellMessage: "", whySellModal: false })
    }

    onClickPlusFabIcon = () => {
        this.setState({ isFabListVisiable: !this.state.isFabListVisiable })
    }

    // NOTE: mobile view card
    handleCardClick = (data) => {
        this.setState({ viewStockInfoModal: true });
        viewStockInfoData = { ...data };
    }

    renderModalHeading = (title, type) => {
        return (
            <>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Text variant="subtitle2" >
                        {title}
                    </Text>
                    {/* <Text color="#676E76" variant="small">ITC LTD</Text> */}
                    {type === "search" ? (<JarvisAutoSearch sx={{ minWidth: "300px", mr: 4 }} />) : null}
                </Stack>
            </>
        )
    }

    render() {
        const {
            rmsIsOnboardingCompleted,
            portfolioList,
            isPortfolioListLoading,
            isPortfolioListInitialCalled,
            portfolioStockList,
            isStockListLoading,
            isStockListInitialCalled,
            classes
        } = this.props;
        const { portfolioId: activePortfolioId } = this.getActivePortfolioId();
        const activePortfolioData = portfolioList.find(pl => pl.portfolioId === activePortfolioId);

        const open = Boolean(this.state.AnchorEl);
        const id = open ? "simple-popover" : undefined;

        // const profileTheme = PROFILE_SCORE_THEME.find(c => c.scoreCode === activePortfolioData?.portfolioRisk);

        const portfolioStockColumns = [{
            field: "stockName",
            headerText: "Stock",
            bodyCellComponent: (v, d) => {
                return (
                    <Stack>
                        <Text variant="body2" sx={{ fontWeight: 400, mb: 0.25 }}>{v}</Text>
                        <Stack>
                            {d.stockSymbol ? (
                                <Text>{d.stockSymbol}</Text>
                            ) : null}

                            {d.stockMarketCap ? (
                                <Text>{d.stockMarketCap}</Text>
                            ) : null}

                            {d.stockSector ? (
                                <Text>{d.stockSector}</Text>
                            ) : null}
                        </Stack>
                    </Stack>
                )
            }
        }, {
            field: "stockBuyDate",
            headerText: "Buy Date",
            style: { width: "120px" },
            bodyCellComponent: (v, d) => {
                return (
                    <Text>
                        {dateTimeHelper.format(v, '$DD/$MM/$YYYY')}
                    </Text>
                )
            }
        }, {
            field: "stockQuantity",
            headerText: "Qty.",
            style: { width: "60px" },
        }, {
            field: "stockBuyPrice",
            headerText: "Buy Price & Value",
            style: { width: "120px" },
            bodyCellComponent: (v, d) => {
                return (
                    <Stack>
                        <Text variant="small" fontWeight="500" mb={0.3}>₹{v}</Text>
                        <Text variant="small" color="#A2A7AE">₹{d.stockBuyValue}</Text>
                    </Stack>
                )
            }
        }, {
            field: "stockCurrentPrice",
            headerText: "Current Price & Value",
            style: { width: "120px" },
            bodyCellComponent: (v, d) => {
                return (
                    <Stack>
                        <Text sx={{ whiteSpace: "nowrap" }} variant="small" fontWeight="500" mb={0.3}>₹{v}</Text>
                        <Text variant="small" color="#A2A7AE">₹{d.stockCurrentValue}</Text>
                    </Stack>
                )
            }
        }, {
            field: "stockChangeType",
            headerText: "Gain/Loss",
            style: { width: "120px" },
            bodyCellComponent: (v, d) => {
                return (
                    <Stack>
                        <Text sx={{ whiteSpace: "nowrap" }} variant="small" fontWeight="500" color={v === "GAIN" ? '#45AC70' : '#D44C4C'} mb={0.3}>{v === "GAIN" ? '+' : ''} ₹{d.stockChangePrice}</Text>
                        <Text variant="small" fontWeight="500" color={v === "GAIN" ? '#45AC70' : '#D44C4C'}>{v === "GAIN" ? '+' : ''}{d.stockChangePercentage}%</Text>
                    </Stack>
                )
            }
        }, {
            field: "rmsCallStatus",
            headerText: "Sell calls Recommends",
            style: { width: "80px" },
            bodyCellComponent: (v, d) => {
                if (!v || v === "HOLD") {
                    return <Text variant="small" sx={{ margin: "0 auto" }}>NA</Text>;
                }
                return (
                    <Stack>
                        <Chip
                            sx={{ p: "6px 16px", fontWeight: "400", backgroundColor: v === "HOLD" ? "#d44c4c14" : "rgb(212, 76, 76, 0.16)", color: v === "HOLD" ? "#676E76" : "#D44C4C" }}
                            label={v}
                        />
                        <Button variant="text" size="small"
                            onClick={() => this.handleWhySellOpen({ whySellMessage: d?.rmsCallMessage })}
                            startIcon={<InfoOutlinedIcon color="#2B70C5" />}
                            sx={{ fontSize: "0.75rem", textTransform: "capitalize", borderRadius: "50px" }}>Why Sell?
                        </Button>
                    </Stack>
                )
            }
        }, {
            field: "portfolioStockId",
            headerText: "",
            style: { width: "30px" },
            bodyCellComponent: (v, d) => {
                return (
                    <>
                        {
                            d.rmsCallStatus !== "SELL" ?
                                <Stack direction="column" alignItems="center" justifyContent="space-between">
                                    <IconButton
                                        variant="text"
                                        color="primary"
                                        onClick={() => this.handleEditStockModalOpen({ stockData: d })}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>

                                    <IconButton
                                        variant="text"
                                        color="error"
                                        onClick={() => this.handleDeleteStockModalOpen({ stockData: d })}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Stack>
                                : null
                        }
                    </>

                )
            }
        }]

        if (!rmsIsOnboardingCompleted) {
            return (
                <Text>Onboarding is not completed UI is has be implemented here...</Text>
            )
        }

        return (
            <>
                <PageTitle heading="Existing Holding" disableBreadcrumb={true} />

                <Loader loading={this.state.isLoading || isPortfolioListLoading || !isPortfolioListInitialCalled || (portfolioList.length && (!isStockListInitialCalled || isStockListLoading))} />

                {isPortfolioListInitialCalled && !portfolioList?.length ? (
                    <JarvisCard sx={{ justifyContent: "center", alignItems: "center" }}>
                        <Text variant="body2" mb={2} textAlign="center">It looks like you haven't added any portfolio yet.</Text>
                        <Button size="small" onClick={this.handleAddPortfolioClick}>
                            Add Portfolio
                        </Button>
                    </JarvisCard>
                ) : (
                    <JarvisCard sx={{ px: { xs: 0, md: 3 }, pt: { xs: 0, md: 3 }, background: config.isMobileApp ? "unset" : "#fff" }}>
                        <Stack direction={{ sm: "column", md: "row" }} justifyContent="space-between" sx={{ mb: 2.5 }}>
                            <Stack direction="row" justifyContent="space-between" width="100%">
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                    mb={{ xs: '0', md: '2.5' }}
                                    sx={{ overflow: "auto", pb: "10px" }}
                                >
                                    {portfolioList?.map((data) => {
                                        return (
                                            <JarvisChipNew
                                                key={`existing_tab_${data.portfolioId}`}
                                                label={`${data.portfolioName}`.toUpperCase()}
                                                variant="outlined"
                                                color={data.portfolioId === activePortfolioId ? "primary" : "default"}
                                                onClick={() => this.handlePortfolioTabClick(data)}
                                            />
                                        );
                                    })}
                                </Stack>
                                <Button sx={{
                                    width: "34px",
                                    borderRadius: "7px",
                                    height: "34px",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    padding: "0px",
                                    minWidth: "34px",
                                    ml: "10px",
                                    paddingBottom: "3px",
                                    display: { xs: 'flex', md: 'none' }
                                }} onClick={() => this.handleAddPortfolioClick()}> + </Button>

                                <Button sx={{ display: { xs: 'none', md: 'flex' }, width: "165px", ml:2 }} size="small" onClick={() => this.handleAddPortfolioClick()}>
                                    Add Portfolio
                                </Button>

                            </Stack>


                        </Stack>

                        <Stack direction={"row"} alignItems="center" justifyContent={{ xs: "space-between", md: "normal" }}>
                            <Stack direction="row" alignItems="baseline">
                                <Text variant="body1" fontWeight="bold">{activePortfolioData?.portfolioName}&nbsp;</Text>
                                <Text variant="body2" color="text.secondary">~ {activePortfolioData?.brokerDisplayName}</Text>
                            </Stack>

                            <div>
                                <IconButton
                                    variant="text"
                                    aria-describedby={id}
                                    onClick={(e) => this.handlePopoverOpenClick(e)}
                                >
                                    <MoreHorizIcon
                                        fontSize="small"
                                        className={classes.horizonIcon}
                                    ></MoreHorizIcon>
                                </IconButton>

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.AnchorEl}
                                    onClose={() => this.handlePopoverClose()}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >

                                    <Stack direction="column">
                                        {PORTFOLIO_ACTION_LIST.map((data) =>
                                            <Text
                                                key={data.id}
                                                sx={{
                                                    padding: "10px 15px",
                                                    cursor: "pointer", "&:hover": { color: "#2B70C5" },
                                                    borderBottom: "1px solid transparent",
                                                    borderBottomColor: `${data.border ? "#e1e1e1" : "transparent"}`
                                                }}
                                                variant="small"
                                                onClick={() => this.handlePortfolioActionClick(data.id)}>{data.name}</Text>
                                        )}
                                    </Stack>
                                </Popover>
                            </div>

                        </Stack>

                        {/* {activePortfolioData?.portfolioRiskScore && activePortfolioData?.portfolioRisk ? (
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    py: 2,
                                    mb: 1.3,
                                    backgroundColor: `${profileTheme?.bgColor}`,
                                    border: `1px solid ${profileTheme?.bordercolor}`,
                                    borderRadius: "8px",
                                    px: 4,
                                    flexDirection: { xs: "column", sm: "row" },
                                    display: { xs: 'none', md: 'flex' }
                                }}

                            >
                                <Text variant="body2" sx={{ mr: { sm: 2 }, mb: { xs: 2, sm: 0 } }}>
                                    {activePortfolioData?.portfolioName} has a score of
                                </Text>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center">

                                    <RiskProfileGraph
                                        chartWidth={80}
                                        needleWidth={4}
                                        riskProfileId={profileTheme?.riskMeterValue}
                                        sx={{ mr: 1.3 }}
                                        disableLabel={true}
                                    ></RiskProfileGraph>
                                    <Text variant="h3" sx={{ fontSize: "1.5rem", ml: 1, mr: 2 }} color={`${profileTheme?.color}`}>
                                        {activePortfolioData?.portfolioRiskScore}
                                    </Text>
                                    <Chip
                                        sx={{
                                            color: profileTheme?.color,
                                            border: `0.7px solid ${profileTheme?.bordercolor} !important`,
                                            fontSize: "0.75rem",
                                            padding: "4px 12px",
                                            fontWeight: "bold",
                                            backgroundColor: "#ffffff"
                                        }}
                                        label={profileTheme?.displayName}
                                        size="small" variant="outlined" />

                                </Stack>
                            </Stack>
                        ) : null} */}

                        <MobileResponsive
                            mobile={() => {
                                return (
                                    <>
                                        {portfolioStockList?.length ? (
                                            <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
                                                {portfolioStockList.map((item, i) => (
                                                    <JarvisStockInfoCard
                                                        key={`info_card_${i}`}
                                                        cardSX={{ mb: "5px", borderColor: item.rmsCallStatus === "SELL" ? "rgba(212, 76, 76, 1)" : "", backgroundColor: item.rmsCallStatus === "SELL" ? "rgba(212, 76, 76, 0.05)" : "" }}
                                                        data={item}
                                                        isEdit={(data) => this.getData(data)}
                                                        isClickedOnCard={(data) => this.handleCardClick(data)}
                                                    />
                                                ))}
                                            </Stack>
                                        ) : <HandleNoStock onAddStockClick={() => this.handlePortfolioActionClick(3)} />}


                                    </>
                                )
                            }}

                            web={() => {
                                return (
                                    <>
                                        {portfolioStockList?.length ? (
                                            <Stack sx={{ display: { xs: 'none', md: 'block' } }}>
                                                <Table
                                                    localPagination
                                                    paginationTop
                                                    // paginationBottom
                                                    columns={portfolioStockColumns}
                                                    data={portfolioStockList}
                                                    dataChangedCounter={this.state.dataChangedCounter}
                                                    refreshTable={this.state.refreshTable}
                                                    page={this.state.page - 1}
                                                    rowsPerPage={this.state.rowsPerPage}
                                                    onPageChange={(page, rowsPerPage) => {
                                                        this.onPageChange(page, rowsPerPage);
                                                    }}
                                                />
                                            </Stack>
                                        ) : <HandleNoStock onAddStockClick={() => this.handlePortfolioActionClick(3)} />
                                        }
                                    </>
                                )
                            }}
                        />
                    </JarvisCard>
                )}

                {/* NOTE: Edit portfolio name modal */}
                <Modal
                    disableExcapeKeyDown={true}
                    title={<SectionTitle heading="Edit Portfolio Name" />}
                    size="sm"
                    open={this.state.editportfolioNameModalOpen}
                    onClose={this.handleEditPortfolioNameModalClose}
                    sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
                >
                    <RMSPortfolioSyncEditName
                        formData={this.state.editPortfolioNameFormData}
                        formError={this.state.editPortfolioNameFormError}
                        onSubmit={this.handleEditPortfolioNameSubmit}
                        onChange={this.handleEditPortfolioNameChange}
                    />
                </Modal>

                {/* NOTE: Delete portfolio modal */}
                <Modal
                    disableExcapeKeyDown={true}
                    title={<SectionTitle heading="Remove Portfolio" />}
                    size="xs"
                    disableClose={true}
                    open={this.state.isRemovePortfolioModalOpen}
                    onClose={this.handleRemovePortfolioModalClose}
                    sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
                >
                    <RMSPortfolioSyncDelete
                        submitBtnText="Delete"
                        onClose={this.handleRemovePortfolioModalClose}
                        onSubmit={this.handleRemovePortfolioSubmit}
                    />
                </Modal>

                {/* NOTE: Add portfolio stock modal */}
                <Modal
                    disableExcapeKeyDown={true}
                    title={<SectionTitle heading={"Add Stock"} />}
                    size="md"
                    open={this.state.addStockModalOpen}
                    onClose={this.handleAddStockModalClose}
                    sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
                >
                    <RMSPortfolioSyncAddStock
                        type="select"
                        actionType="ADD"
                        stockList={this.state.masterStockList}
                        getData={this.getSelectedValue}
                        formData={this.state.addStockForm}
                        formError={this.state.addStockFormError}
                        onSubmit={this.handleAddStockSubmit}
                        onChange={this.handleAddStockFormChange}
                    />
                </Modal>

                {/* NOTE: Edit portfolio stock modal */}
                <Modal
                    disableExcapeKeyDown={true}
                    title={<SectionTitle heading={"Edit Stock"} />}
                    size="md"
                    open={this.state.editStockModalOpen}
                    onClose={this.handleEditStockModalClose}
                    sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
                >
                    <RMSPortfolioSyncAddStock
                        type="select"
                        actionType="EDIT_HOLDING"
                        editableFields={["stockBuyPrice", "stockQuantity"]}
                        stockList={this.state.masterStockList}
                        getData={this.getSelectedValue}
                        formData={this.state.editStockForm}
                        formError={this.state.editStockFormError}
                        onSubmit={this.handleEditStockSubmit}
                        onChange={this.handleEditStockFormChange}
                        loading={this.state.isLoading}
                    />
                </Modal>

                {/* NOTE: Delete portfolio stock modal */}
                <Modal
                    disableExcapeKeyDown={true}
                    title={<SectionTitle heading={"Delete Stock"} />}
                    size={"xs"}
                    open={this.state.deleteStockModalOpen}
                    onClose={this.handleDeleteStockModalClose}
                    sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
                >
                    <RMSPortfolioSyncRemoveStock
                        onSubmit={this.handleDeleteStockSubmit}
                        onClose={this.handleDeleteStockModalClose}
                    />
                </Modal>

                {/* NOTE: why sell modal */}
                <Modal
                    disableExcapeKeyDown={true}
                    title={this.renderModalHeading("Why Sell?")}
                    size="sm"
                    open={this.state.whySellModal}
                    onClose={this.handleWhySellClose}
                    sx={{ borderRadius: "10px", overflow: "hidden", py: 2, px: 2 }}
                >
                    <Text variant="small" color="#676E76" sx={{ lignHeight: "20px" }}>
                        {this.state.whySellMessage}
                    </Text>

                </Modal>

                {/* NOTE: mobile modal for view stock info */}
                <Modal
                    disableExcapeKeyDown={true}
                    title={this.renderModalHeading("View Info")}
                    size="sm"
                    open={this.state.viewStockInfoModal}
                    onClose={() => this.setState({ viewStockInfoModal: false })}
                    sx={{ borderRadius: "10px", overflow: "hidden", py: 1, px: 1 }}
                >
                    {viewStockInfoData.rmsCallStatus === 'SELL' ? (
                        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5}>
                            <Chip label={viewStockInfoData?.rmsCallStatus} sx={{ fontSize: "12px", marginRight: "5px", padding: "6px 10px", color: "#D44C4C" }} size="small" />
                        </Stack>
                    ) : null}


                    <Stack direction="row" justifyContent="space-between" alignItems="start" mb={2.5}>
                        <Text variant="small" sx={{ fontWeight: 500 }}>{viewStockInfoData?.stockName} </Text>
                        <Text variant="small" sx={{ fontWeight: 500 }} color={viewStockInfoData.stockChangeType === 'GAIN' ? "#45AC70" : viewStockInfoData.stockChangeType === 'LOSS' ? "#D44C4C" : "#A2A7AE"}>₹{viewStockInfoData?.stockCurrentPrice}({viewStockInfoData.stockChangePercentage}%)</Text>
                    </Stack>

                    <JarvisCard sx={{ border: '1px solid #EBEBEB', marginBottom: '16px' }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                            <Stack>
                                <Text variant="caption1" color="#A2A7AE;">Buy Date</Text>
                                <Text variant="small"> {dateTimeHelper.format(viewStockInfoData?.stockBuyDate, '$DD/$MM/$YYYY')}</Text>
                            </Stack>
                            <Stack>
                                <Text variant="caption1" color="#A2A7AE;">Qty.</Text>
                                <Text variant="small">{viewStockInfoData?.stockQuantity}</Text>
                            </Stack>
                            <Stack>
                                <Text variant="caption1" color="#A2A7AE;">Buy Price</Text>
                                <Text variant="small">₹{viewStockInfoData?.stockBuyPrice}</Text>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack>
                                <Text variant="caption1" color="#A2A7AE;">Buy  value</Text>
                                <Text variant="small">₹{viewStockInfoData?.stockBuyValue}</Text>
                            </Stack>
                            <Stack>
                                <Text variant="caption1" color="#A2A7AE;">Current Price</Text>
                                <Text variant="small">₹{viewStockInfoData?.stockCurrentPrice}</Text>
                            </Stack>
                            <Stack>
                                <Text variant="caption1" color="#A2A7AE;">Current Value</Text>
                                <Text variant="small">₹{viewStockInfoData?.stockCurrentValue}</Text>
                            </Stack>
                        </Stack>
                    </JarvisCard>
                    {viewStockInfoData?.scoreByAI ? (
                        <Stack direction="row" mb={2} sx={{ flexWrap: "wrap" }} justifyContent="space-between">
                            {viewStockInfoData?.scoreByAI.map((item, i) => (
                                <Chip
                                    key={`info_card_${i}`}
                                    label={`${item.label} : ${item.value}`}
                                    size="small"
                                    sx={{ minWidth: '48%', fontSize: "12px", marginRight: "5px", padding: "16px 4px", marginBottom: "6px" }} />
                            ))}
                        </Stack>
                    ) : (null)}


                    {
                        viewStockInfoData.rmsCallStatus !== 'SELL' ?
                            <Stack direction="row">
                                <JarvisButton
                                    disableEndIcon={true}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => this.handleEditStockModalOpen({ stockData: viewStockInfoData })}
                                    sx={{ borderColor: "primary.main", flex: 1, mr: 1 }}
                                >
                                    Edit
                                </JarvisButton>

                                <JarvisButton
                                    disableEndIcon={true}
                                    size="small"

                                    variant="outlined"
                                    onClick={() => this.handleDeleteStockModalOpen({ stockData: viewStockInfoData })}
                                    sx={{ borderColor: "error.main", color: "error.main", flex: 1, ml: 1 }}
                                >
                                    Delete
                                </JarvisButton>
                            </Stack>
                            : null
                    }

                    {viewStockInfoData.rmsCallStatus === 'SELL' ? (
                        <Stack mt={2}>
                            <JarvisButton size="small" disableEndIcon={true} variant="outlined" onClick={() => this.handleWhySellOpen({ whySellMessage: viewStockInfoData.rmsCallMessage })}>Why Sell?</JarvisButton>
                        </Stack>
                    ) : null}

                </Modal>
            </>
        )
    }
}

const style = (theme) => ({
    horizonIcon: {
        color: "#A2A7AE",
        [theme.breakpoints.down("md")]: {
            padding: "0px",
            textAlign: "right",
            width: "15px",
            transform: "rotate(90deg) scale(2)"
        }
    },
})

export default withStyles(style, { withTheme: true })(withRouter(RMSExistingHoldingContainer));