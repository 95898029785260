import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { onboardingActions } from "stores/onboarding/onboardingActions";

const mapStateToProps = (state) => {
  return {
    deviceId: state.common.deviceId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutAction: customerActions.logout,
      updateAuthDataAction: customerActions.updateAuthData,

      // update onboarding details of the product
      updateOnboardingDataAction: onboardingActions.updateOnboardingData,
      updateRMSOnboardingDataAction: jarvsiRMSOnboardingActions.updateRMSOnboardingData,
      updateOnestockOnboardingDataAction: jarvsiOnestockOnboardingActions.updateOnestockOnboardingData,
      setSsoCustomerDetails : customerActions.ssoCustomerDetails,
      isSsoCustomer: customerActions.isSsoCustomerFlag

    },
    dispatch
  );

const LoginStore = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);
export default LoginStore;
