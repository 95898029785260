import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { commonActions } from "stores/common/commonActions"

const mapStateToProps = (state) => {
    return {
        marketUpdatesData: state.common.marketUpdates,
        isLoading: state.common.loading.marketUpdates,
        isInitialCalled: state.common.initialCalled.marketUpdates,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getMarketUpdateAction: commonActions.getMarketUpdate
}, dispatch)

const MarketUpdateStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default MarketUpdateStore;