import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";

const mapStateToProps = (state) => {
    return {
        profile: state.customer.profile,
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getProfileDataAction: customerActions.getProfile,
            updateEmailAction: customerActions.updateEmail
        },
        dispatch
    );

const EditProfileStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default EditProfileStore;
