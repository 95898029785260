import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { thirdpartyConstants } from "./thirdpartyConstants";

const initialState = {
    subscriptionPlans: [],
    transactionHistoryList: [],
    transactionHistoryListCount: 0,
    loading: {
        subscriptionPlans: 0,
        transactionHistoryList: 0,
    },
    isInitialCalled: {
        subscriptionPlans: 0,
    },
};

export const thirdpartyReducers = persistReducer(
    {
        storage,
        key: "thirdparty",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case thirdpartyConstants.SUBSCRIPTION_PLANS_REQUEST: {
                return {
                    ...state,
                    subscriptionPlans: initialState.subscriptionPlans,
                    loading: {
                        ...state.loading,
                        subscriptionPlans: 1,
                    },
                };
            }

            case thirdpartyConstants.SUBSCRIPTION_PLANS_RESPONSE: {
                return {
                    ...state,
                    subscriptionPlans: payload,
                    loading: {
                        ...state.loading,
                        subscriptionPlans: 0,
                    },
                    isInitialCalled: {
                        ...state.isInitialCalled,
                        subscriptionPlans: 1,
                    },
                };
            }

            case thirdpartyConstants.TRANSACTION_HISTORY_LIST_REQUEST: {
                return {
                    ...state,
                    transactionHistoryList: initialState.transactionHistoryList,
                    transactionHistoryListCount: initialState.transactionHistoryListCount,
                    loading: {
                        ...state.loading,
                        transactionHistoryList: 1,
                    },
                };
            }

            case thirdpartyConstants.TRANSACTION_HISTORY_LIST_RESPONSE: {
                return {
                    ...state,
                    transactionHistoryList: payload.list,
                    transactionHistoryListCount: payload.listCount,
                    loading: {
                        ...state.loading,
                        transactionHistoryList: 0,
                    },
                };
            }

            case thirdpartyConstants.RESET_THIRDPARTY_DATA:
                return initialState;

            default:
                return state;
        }
    }
);
