import { config } from "config";
import { localStorageHelper } from "helper/localStorage";

const localStore = {};

localStore.setMobileView = (v) =>
    localStorageHelper.set(config.localStorageKeys.isMobileView, v);
localStore.getMobileView = () =>
    localStorageHelper.get(config.localStorageKeys.isMobileView);
localStore.resetMobileView = () =>
    localStorageHelper.remove(config.localStorageKeys.isMobileView);

localStore.setRevealCardStatus = (v) =>
    localStorageHelper.set(config.localStorageKeys.isRevealCardStatus, v);
localStore.getRevealCardStatus = () =>
    localStorageHelper.get(config.localStorageKeys.isRevealCardStatus);
localStore.resetRevealCardStatus = () =>
    localStorageHelper.remove(config.localStorageKeys.isRevealCardStatus);

localStore.setToken = (v) =>
    localStorageHelper.set(config.localStorageKeys.tokenKey, v);
localStore.getToken = () =>
    localStorageHelper.get(config.localStorageKeys.tokenKey);
localStore.resetToken = () =>
    localStorageHelper.remove(config.localStorageKeys.tokenKey);

localStore.setLegacyToken = (v) =>
    localStorageHelper.set(config.localStorageKeys.legacyTokenKey, v);
localStore.getLegacyToken = () =>
    localStorageHelper.get(config.localStorageKeys.legacyTokenKey);
localStore.resetLegacyToken = () =>
    localStorageHelper.remove(config.localStorageKeys.legacyTokenKey);

localStore.setGeolocation = (v) =>
    localStorageHelper.set(config.localStorageKeys.geoLocation, v);
localStore.getGeolocation = () =>
    localStorageHelper.get(config.localStorageKeys.geoLocation);
localStore.resetGeoLocation = () =>
    localStorageHelper.remove(config.localStorageKeys.geoLocation);

localStore.setAppsflyerData = (v) =>
    localStorageHelper.set(config.localStorageKeys.appsflyerData, v);
localStore.getAppsflyerData = () =>
    localStorageHelper.get(config.localStorageKeys.appsflyerData);
localStore.resetAppsflyerData = () =>
    localStorageHelper.remove(config.localStorageKeys.appsflyerData);

localStore.setTrackerConfig = (v) =>
    localStorageHelper.set(config.localStorageKeys.trackerConfig, v);
localStore.getTrackerConfig = () =>
    localStorageHelper.get(config.localStorageKeys.trackerConfig);
localStore.resetTrackerConfig = () =>
    localStorageHelper.remove(config.localStorageKeys.trackerConfig);

localStore.setWentBrokerPage = (v) =>
    localStorageHelper.set("wentBrokerPage", v);

localStore.getWentBrokerPage = () => localStorageHelper.get("wentBrokerPage");

localStore.setTakeTure = (v) =>
    localStorageHelper.set("takeToureKey", v);

localStore.getTakeTure = () => localStorageHelper.get("takeToureKey");
localStore.removeTakeTureToken = () =>
    localStorageHelper.remove("takeToureKey");


localStore.setFeatureConfig = (v) =>
    localStorageHelper.set(config.localStorageKeys.featureConfig, v);
localStore.getFeatureConfig = () =>
    localStorageHelper.get(config.localStorageKeys.featureConfig);
localStore.resetFeatureConfig = () =>
    localStorageHelper.remove(config.localStorageKeys.featureConfig);


// Active product 
localStore.setActiveProduct = (v) => {
    if (Object.keys(config.productCodes).includes(v)) {
        localStorageHelper.set(config.localStorageKeys.activeProduct, v);
    }
}
localStore.getActiveProduct = () =>
    localStorageHelper.get(config.localStorageKeys.activeProduct);
localStore.resetActiveProduct = () =>
    localStorageHelper.remove(config.localStorageKeys.activeProduct);

// Jarvis Info page should visit fzirst time [ undefine | 0 | 1 ]
localStore.setSeenJarvisInfo = (v) => {
    if ([0, 1].includes(v)) {
        localStorageHelper.set(config.localStorageKeys.seenJarvisInfo, v);
    }
}
localStore.getSeenJarvisInfo = () =>
    localStorageHelper.get(config.localStorageKeys.seenJarvisInfo);
localStore.resetSeenJarvisInfo = () =>
    localStorageHelper.remove(config.localStorageKeys.seenJarvisInfo);




localStore.setPaymentInitiated = () =>
    localStorageHelper.set(config.localStorageKeys.paymentInitiated, true);
localStore.getPaymentInitiated = () =>
    localStorageHelper.get(config.localStorageKeys.paymentInitiated);
localStore.resetPaymentInitiated = () =>
    localStorageHelper.remove(config.localStorageKeys.paymentInitiated);
    
localStore.setSsoCustomerDetails = (value)=>{
    localStorageHelper.set(config.localStorageKeys.ssoCustomerDetails,value)
}
localStore.getSsoCustomerDetails = ()=>
    localStorageHelper.get(config.localStorageKeys.ssoCustomerDetails)

localStore.setIsSsoCustomer = (value)=>{
    localStorageHelper.set(config.localStorageKeys.isSsoCustomer,value);
}
localStore.isSsoCustomer=()=>localStorageHelper.get(config.localStorageKeys.isSsoCustomer);
localStore.removeSSOCustomer = ()=>localStorageHelper.remove(config.localStorageKeys.isSsoCustomer)

localStore.setSsoClientId = (value)=>{
    localStorageHelper.set(config.localStorageKeys.ssoClientId,value);
}
localStore.getSsoClientId=()=>localStorageHelper.get(config.localStorageKeys.ssoClientId);
localStore.removeSsoClientId = ()=>localStorageHelper.remove(config.localStorageKeys.ssoClientId)


export { localStore };
