// import { appsFlyerTracker } from "../appsFlyer";
// import { mixpanelTracker } from "../mixpanel";
// import { uxcamTracker } from "../uxcam";

const facebookLoginTracker = {};

facebookLoginTracker.facebookLogin = () => {
    // const eventName = "fb_login";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

facebookLoginTracker.facebookLoginFailed = ({ error_msg }) => {
    // const eventName = "fb_login_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: {error_msg },
    // });
};

facebookLoginTracker.facebookLoginSuccess = () => {
    // const eventName = "fb_login_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

export { facebookLoginTracker };
