// import { appsFlyerTracker } from "../appsFlyer";
// import { mixpanelTracker } from "../mixpanel";
// import { uxcamTracker } from "../uxcam";

const expiryCompleteBuySellTracker = {};

expiryCompleteBuySellTracker.generateNewActions = () => {
    // const eventName = "generate_new_actions";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

expiryCompleteBuySellTracker.generateNewActionsFailed = () => {
    // const eventName = "generate_new_actions_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

expiryCompleteBuySellTracker.generateNewActionsSuccess = () => {
    // const eventName = "generate_new_actions_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

expiryCompleteBuySellTracker.exYesContinue = () => {
    // const eventName = "ex_yes,_continue";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

expiryCompleteBuySellTracker.exYesContinueFailed = () => {
    // const eventName = "ex_yes,_continue_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

expiryCompleteBuySellTracker.exYesContinueSuccess = () => {
    // const eventName = "ex_yes,_continue_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

export { expiryCompleteBuySellTracker };
