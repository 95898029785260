import React, { Component } from 'react'
import PageTitle from "components/PageTitle";
import JarvisCard from 'components/JarvisCard';
import JarvisChip from "components/JarvisChipNew";
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import { formatHelper } from 'helper/format';
import Table from 'components/common/Table';
import OnestockTransactionListFilter from 'components/JarvisOnestock/OnestockTransactionListFilter';
import { FilterList as FilterListIcon } from "@mui/icons-material"
import JarvisButton from 'components/JarvisButton';
import Button from 'components/common/Button';
import { dateTimeHelper } from 'helper/dateTime';
import Loader from 'components/common/Loader';
import NotAvailableData from 'components/JarvisOnestock/NotAvailableData';
import Pagination from "components/common/Pagination";
import MobileResponsive from "components/common/MobileResponsive";
import Image from "components/common/Image";
import MobileOnestockTransaction from 'components/JarvisOnestock/MobileOnestockTransaction';
import { jarvisOnestockTransactionApis } from 'stores/jarvisOnestock/jarvisOnestockTransaction/jarvisOnestockTransactionApis';
import { validationHelper } from 'helper/validation';


const columns = [
    {
        field: "stockName",
        headerText: "Stock",
        bodyCellComponent: (v, d) =>
            <>
                <Stack>
                    <Text variant="small">{v}</Text>
                    <Text variant="caption1" sx={{ color: "#A2A7AE" }}>{d.stockSymbol}</Text>
                </Stack>
            </>
    },
    {
        field: "buyDate",
        headerText: "Buy Date",
        bodyCellComponent: (v) => <Text variant="small">{v ? dateTimeHelper.format(v, '$DD/$MM/$YYYY') : null}</Text>
    },
    {
        field: "buyPrice",
        headerText: "Buy Price",
        // editable: (d)=>{
        //     if(d['transactionStatus'] === "OPEN"){
        //         return true
        //     }
        //     if(d['transactionStatus'] === "CLOSED"){
        //         return false;
        //     }
        // },
        bodyCellComponent: (v) =>
            <>
                {formatHelper.currency(v, {
                    config: { maximumFractionDigits: 2 },
                })}
            </>
    },
    {
        field: "stockQuantity",
        headerText: "Qty.",
        // editable:(d)=>{
        //     if(d['transactionStatus'] === "OPEN"){
        //         return true
        //     }
        //     if(d['transactionStatus'] === "CLOSED"){
        //         return false;
        //     }
        //     return false;
        // },
        
    },
    {
        field: "sellDate",
        headerText: "Sell Date",
        bodyCellComponent: (v) => <Text variant="small">{v ? dateTimeHelper.format(v, '$DD/$MM/$YYYY') : null}</Text>
    },
    {
        field: "sellPrice",
        headerText: "Sell Price",
        // editable:(d)=>{
        //     if(d['transactionStatus'] === "OPEN"){
        //         return false
        //     }
        //     if(d['transactionStatus'] === "CLOSED"){
        //         return true;
        //     }
        //     return false;
        // },
        bodyCellComponent: (v) =>
            <>
                {formatHelper.currency(v, {
                    config: { maximumFractionDigits: 2 },
                })}
            </>
    },
    {
        field: "stockGainLossPercentage",
        headerText: "Gain/Loss",
        bodyCellComponent: (v) =>
            <>
                <Text variant="small" sx={{ color: Number(v || 0) >= 0 ? "#45AC70" : "#D44C4C", fontWeight: "600" }}>
                    {Number(Number(v || 0).toFixed(3)) + "%"}
                </Text>
            </>
    },
    {
        field: 'transactionStatus',
        headerText: 'RMS Status',
        bodyCellComponent: (v) => {
            if (v === 'OPEN') {
                return <JarvisChip sx={{ padding: "15px 5px" }} size="small" label="Open" color="success" />
            }
            if (v === 'CLOSED') {
                return <JarvisChip sx={{ padding: "15px 5px" }} size="small" label="Closed" color="error" />
            }
            if (v === 'SYSTEM_EXECUTED') {
                return <JarvisChip sx={{ padding: "15px 5px" }} size="small" label="System Executed" color="warning" />
            }
            return null;
        }
    },
]

const initialFilterData = {
    fromDate: null,
    toDate: null,
    transactionStatus: null,
}
export class OnestockTransactionContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableRefreshCounter: 0,
            page: 1,
            perPage: 25,
            filterOpen: false,
            filterData: JSON.parse(JSON.stringify(initialFilterData)),
            tempFilterData: JSON.parse(JSON.stringify(initialFilterData)),
        };
    }

    componentDidMount() {
        this.fetchTransactionList()
    }

    fetchTransactionList = ({isAvoidRefresh=false}={}) => {
        const { page, perPage, filterData: { fromDate, toDate, transactionStatus } } = this.state;
        this.props.getTransactionListAction({isAvoidRefresh, page, perPage, fromDate, toDate, transactionStatus })
        setTimeout(() => {
            this.setState(prevState => ({
                tableRefreshCounter: prevState.tableRefreshCounter + 1
            }))
        }, 500)
    }

    handlePageChange = (page, perPage) => {
        this.setState({ page, perPage }, () => {
            this.fetchTransactionList();
        })
    }

    handleFilterDataChange = ({ name, value }) => {
        const { tempFilterData } = this.state;

        if (['transactionStatus'].includes(name)) {
            if (tempFilterData[name] === value) {
                tempFilterData[name] = null;
            } else {
                tempFilterData[name] = value;
            }
        } else {
            tempFilterData[name] = value;
        }

        this.setState({ tempFilterData });
    }

    handleFilterSubmit = () => {
        this.setState({
            filterOpen: false,
            filterData: this.state.tempFilterData,
        }, () => {
            this.fetchTransactionList();
        });
    }

    handleFilterReset = () => {
        this.setState({
            filterOpen: false,
            filterData: JSON.parse(JSON.stringify(initialFilterData)),
            tempFilterData: JSON.parse(JSON.stringify(initialFilterData))
        }, () => {
            this.fetchTransactionList();
        });
    }
    updateDataValidation = async (rowData) => {
        const { tableRefreshCounter } = this.state;
        const { buyPrice, sellPrice, stockQuantity, transactionStatus } = rowData;
        let errorMsg = null;
        const buyPriceError = validationHelper.positiveNumber({value:Number(buyPrice)});
        const stockQuantityError = validationHelper.positiveNumber({value:Number(stockQuantity),integer:true})

        if (transactionStatus !== 'OPEN') {
            const sellPriceError = validationHelper.positiveNumber({value:Number(sellPrice)});
            if (sellPriceError.message && !errorMsg) {
                errorMsg = sellPriceError.message
            }
        }
        else {
            if (buyPriceError.message && !errorMsg) {
                errorMsg = buyPriceError.message
            }
            if (stockQuantityError.message && !errorMsg) {
                errorMsg = stockQuantityError.message
            }
        }
        if (errorMsg) {
            this.setState({ tableRefreshCounter: tableRefreshCounter + 1 })
        }
        return errorMsg


    }
    updateTransactionData = async (updatedData,isOpenModal=null)=>{
        try {
            const [{rowData}]=updatedData;
            let error = await this.updateDataValidation(rowData);
            if(error){
                return this.props.setToast({message:error})
            }
            const res= await jarvisOnestockTransactionApis.updateTransactionData(rowData)

            //closing the Mobile UI Modal after Updating the Data.
            if(isOpenModal)isOpenModal(false)
            this.props.setToast({message:res?.message})
            this.fetchTransactionList({isAvoidRefresh:true})
        } catch (err) {
            console.error(err)
            this.fetchTransactionList({isAvoidRefresh:true})
        }
    }

    handleFilterOpen = () => {
        this.setState({ filterOpen: true });
    }

    handleFilterClose = () => {
        this.setState({ filterOpen: false, tempFilterData: this.state.filterData })
    }

    isFilterApplied = () => {
        const { filterData } = this.state;
        return filterData.fromDate || filterData.toDate || filterData.transactionStatus
    }

    render() {

        const {
            transactionCallsList,
            transactionCallsLoading,
            transactionCallsTotal,
            transactionCallsInitialCalled,
        } = this.props;

        const {
            page,
            perPage,
            filterOpen,
            tableRefreshCounter,
            tempFilterData,
        } = this.state

        return (
            <>
                <PageTitle
                    heading="Transaction"
                    disableBreadcrumb
                    endContent={() => (
                        <JarvisButton
                            disableEndIcon
                            disableStartIcon={false}
                            animate={false}
                            variant="text"
                            sx={{ color: 'black' }}
                            startIcon={<FilterListIcon />}
                            onClick={this.handleFilterOpen}
                        >
                            Filter
                        </JarvisButton>
                    )}
                />

                <Loader loading={transactionCallsLoading} />

                {transactionCallsInitialCalled && this.isFilterApplied() && !transactionCallsList.length ? (
                    <JarvisCard>
                        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
                            <Text sx={{ fontWeight: "bold" }}>No transaction found for applied filter</Text>
                            <Button size="small" onClick={this.handleFilterReset}>Clear Filter</Button>
                        </Stack>
                    </JarvisCard>
                ) : null}

                {transactionCallsInitialCalled && !this.isFilterApplied() && !transactionCallsList.length ? (
                    <NotAvailableData
                        actionType="TRANSACTIONS"
                    />
                ) : null}

                {transactionCallsInitialCalled && transactionCallsList.length ? (
                    <>
                        <MobileResponsive
                            web={() => (
                                <JarvisCard>
                                    <Table
                                        columns={columns}
                                        data={transactionCallsList}
                                        onDataChange={async (updatedData) => {
                                            await this.updateTransactionData(updatedData)
                                        }}
                                        extactUpdatedData={true}
                                        count={transactionCallsTotal}
                                        loading={transactionCallsLoading}
                                        paginationBottom
                                        paginationTop
                                        page={page - 1}
                                        rowsPerPage={perPage}
                                        dataChangedCounter={tableRefreshCounter}
                                        onPageChange={this.handlePageChange}
                                        tbl_wrapper_sx={{
                                            border: "1px solid #e3e3e3",
                                            borderRadius: "8px",
                                        }}
                                    />
                                </JarvisCard>
                            )}
                            mobile={() => (
                                <>
                                    {/* <Stack direction="row" justifyContent="flex-end">
                                        <JarvisButton
                                            disableEndIcon
                                            disableStartIcon
                                            animate={false}
                                            variant="text"
                                            sx={{ color: 'black', marginTop: "5px", marginBottom: "5px", padding: "6px 10px 6px 18px" }}
                                            onClick={this.handleFilterOpen}
                                        >
                                            <Text mr={1} variant="small" sx={{ color: "#303236" }}>Filter</Text>
                                            <Image src="/images/mobileApp/filter-icon.svg" width={15} />
                                        </JarvisButton>
                                    </Stack> */}
                                    <JarvisCard sx={{ border: "1px solid #EEF0F2", padding: "0", height: "auto" }}>
                                        {transactionCallsList?.map((data) => {
                                            return (
                                                <>
                                                    <Text sx={{ padding: "15px 10px" }}>
                                                        <MobileOnestockTransaction
                                                            transactionData={data}
                                                            updateTransactionData={this.updateTransactionData}
                                                        />
                                                    </Text>
                                                    <Text sx={{ borderBottom: "1px solid #E2E2E2", padding: "0px" }}></Text>
                                                </>
                                            );
                                        })}
                                    </JarvisCard>
                                    <Pagination
                                        defaultRowsPerPage={perPage}
                                        page={page - 1}
                                        count={transactionCallsTotal}
                                        onPageChange={this.handlePageChange}
                                    />
                                </>
                            )}
                        />
                    </>
                )
                    : null
                }
                <OnestockTransactionListFilter
                    filterData={tempFilterData}
                    open={filterOpen}
                    onClose={this.handleFilterClose}
                    onChange={this.handleFilterDataChange}
                    onReset={this.handleFilterReset}
                    onSubmit={this.handleFilterSubmit}
                    transactionStatusOptions={[{ color: 'success', label: 'Open', value: 'OPEN' }, { color: 'warning', label: 'Closed', value: 'CLOSED' },{ color: 'error', label: 'System Executed', value: 'SYSTEM_EXECUTED' }]}
                />
            </>
        )
    }
}

export default OnestockTransactionContainer