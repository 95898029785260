import React from "react";
import { withRouter } from "react-router-dom";
import _get from "lodash.get";

import Text from "components/common/Text";
import Loader from "components/common/Loader";

import JarvisCard from "components/JarvisCard";
import JarvisDialog from "components/JarvisDialog";

import { config } from "config";
import { injectHelper } from "helper/inject";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import { thirdpartyParsers } from "stores/thirdparty/thirdpartyParsers";
import { onboardingApis } from "stores/onboarding/onboardingApis";
import { onboardingParsers } from "stores/onboarding/onboardingParsers";
import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";
import { tracker } from "library/tracker";
import { jarvisRMSOnboardingParser } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingParser";
import { localStore } from "stores/localStorage";
import { jarvisOnestockOnboardingApis } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingApis";
import { jarvisOnestockOnboardingParser } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingParser";

const VERIFICATION_STATUS = {
    PENDING: "PENDING",
    SUCCEED: "SUCCEED",
    FAILED: "FAILED",
    NOT_CONFIRMED: "NOT_CONFIRMED",
    CANCELLED: "CANCELLED",
}

const REDIRECTION_URIS = {
    RMS_INITIAL: {
        SUCCEED: "/jarvis-rms/existing-holding",
        FAILED: "/jarvis-rms/onboarding/ckyc",
        CANCELLED: "/jarvis-rms/onboarding/ckyc",
    },
    PORTFOLIO_INITIAL: {
        SUCCEED: "/jarvis-portfolio/onboarding/choose-broker",
        FAILED: "/jarvis-portfolio/onboarding/ckyc",
        CANCELLED: "/jarvis-portfolio/onboarding/ckyc",
    },
    ONESTOCK_INITIAL: {
        SUCCEED: "/jarvis-onestock/dashboard",
        FAILED: "/jarvis-onestock/onboarding/ckyc",
        CANCELLED: "/jarvis-onestock/onboarding/ckyc",
    },
}

class DocumentSigningContainer extends React.Component {
    interval = null;
    constructor(props) {
        super(props);
        this.state = {
            dialogContent: {
                icon: "",
                title: "",
                content: "",
                actionText: "",
            },
            dialogOpen: false,
            isLoading: false,
            verificationStatus: "",
            isSigningInProcess: false,
            returnURI: '/',
        }
    }

    componentDidMount() {

        //event-tracker: Initial -> proceed to client agreement.
        tracker.initial.proceedToClientAgreement();
        this.initProcess();
    }

    initProcess = async () => {
        try {
            const returnURI = _get(this.props.location, 'state.returnURI', '/');
            this.setState({ isLoading: true, returnURI });
            let initSigningResponse = {};

            if (["RMS", "JRMS"].includes(this.props.location.state.productCode)) {
                initSigningResponse = await jarvisRMSOnboardingApis.initiateDocumentSigning();
            }else if (["ONESTOCK"].includes(this.props.location.state.productCode)) {
                initSigningResponse = await jarvisOnestockOnboardingApis.initiateDocumentSigning();
            }else {
                initSigningResponse = await thirdpartyApis.initiateDocumentSigning();
            }

            const { documentId, accessToken, identifier, gateway } = thirdpartyParsers.initiateDocumentSigning(initSigningResponse);

            if (gateway === config.documentSigning.gateway.digio) {
                await this.initDigio({ documentId, accessToken, identifier });
                return;
            }

            this.handleError({ reason: "Gateway not found", origin: "initProcess > not found = gateway" })
        } catch (error) {
            this.handleError({ reason: "Something went wrong! Please try again later!", origin: "initProcess > catch block", error });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    initDigio = async ({ documentId, accessToken, identifier }) => {
        try {
            await injectHelper.script({ src: config.documentSigning.digio.scriptURI });

            const digio = new window.Digio({
                environment: config.documentSigning.digio.environment,
                method: config.documentSigning.digio.method,
                logo: config.companyLogo,
                // theme : {primaryColor : "#2B70C5"}, 
                is_iframe: config.documentSigning.digio.isIframe,
                callback: (r) => {
                    if (!r.error_code) {
                        this.handleSuccess();
                        return;
                    }
                    if (r.error_code === "CANCELLED") {
                        this.handleCancel();
                        return;
                    }
                    this.handleError({ reason: r.message, origin: "initDigio > digio process failed", error: r })
                }
            });

            digio.init();
            digio.submit(documentId, identifier, accessToken);
        } catch (error) {
            this.handleError({ reason: "Something went wrong! Please try again later!", origin: "initDigio > catch block", error })
        }
    }

    handleSuccess = () => {
        let checkCount = 0;
        let { dialogContent } = this.state;
        let verificationStatus = VERIFICATION_STATUS.PENDING;
        // generate dialog content for pending state

        //event-tracker: Initial -> proceed_to_client_agreement_success.
        tracker.initial.proceedToClientAgreementSuccess();
        dialogContent = this.generateSigningStatusContent(verificationStatus);

        this.setState({
            dialogOpen: true,
            isSigningInProcess: true,
            dialogContent,
        });

        this.interval = setInterval(async () => {
            try {
                if (checkCount >= 20) {
                    verificationStatus = VERIFICATION_STATUS.NOT_CONFIRMED;
                    // generate dialog content for not confirmed state
                    dialogContent = this.generateSigningStatusContent(verificationStatus);

                    this.setState({ isSigningInProcess: false });
                    clearInterval(this.interval);
                } else {
                    if (["RMS", "JRMS"].includes(this.props.location.state.productCode)) {
                        const onboardingResponse = await jarvisRMSOnboardingApis.getOnboardingData();
                        const parsedOnboardingResponse = jarvisRMSOnboardingParser.onboardingData(onboardingResponse);
                        this.props.updateRMSOnboardingDataAction(parsedOnboardingResponse);
                        this.props.getProfileDataAction();
                        verificationStatus = parsedOnboardingResponse.isOnboardingCompleted ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
                    }else if (["ONESTOCK"].includes(this.props.location.state.productCode)) {
                        const onboardingResponse = await jarvisOnestockOnboardingApis.getOnboardingData();
                        const parsedOnboardingResponse2 = jarvisOnestockOnboardingParser.onboardingData(onboardingResponse);
                        this.props.updateOnestockOnboardingDataAction(parsedOnboardingResponse2);
                        this.props.getProfileDataAction();
                        verificationStatus = parsedOnboardingResponse2.isOnboardingCompleted ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
                    } else {
                        // validate verification status
                        const onboardingResponse = await onboardingApis.getOnboardingData();
                        const parsedOnboardingResponse = onboardingParsers.onboardingData(onboardingResponse);
                        this.props.updatePortfolioOnboardingDataAction(parsedOnboardingResponse);

                        verificationStatus = parsedOnboardingResponse.currentStage === 5 ? VERIFICATION_STATUS.SUCCEED : VERIFICATION_STATUS.PENDING;
                    }

                    // generate dialog content for pending or completed state
                    dialogContent = this.generateSigningStatusContent(verificationStatus);

                    if (verificationStatus === VERIFICATION_STATUS.SUCCEED) {
                        this.setState({ isSigningInProcess: false });
                        tracker.onboarding.documentSigning(this.props.loginProfile.mobile);
                        clearInterval(this.interval);
                    }
                }
            } catch (error) {
                this.handleError({ reason: "Something went wront! Please try again later!", origin: "handleSuccess > catch block", error })
                this.setState({ isSigningInProcess: false });
                clearInterval(this.interval);
            } finally {
                ++checkCount;
                this.setState({ dialogContent, verificationStatus })
            }
        }, 5000);
    }

    handleCancel = () => {
        //event-tracker: Initial -> proceed_to_client_agreement_error.
        tracker.initial.proceedToClientAgreementError({ error_msg: "Process was cancelled" });
        this.handleError({ reason: "Process was cancelled", verificationStatus: VERIFICATION_STATUS.CANCELLED })
    }

    handleError = ({ reason, title = "Oops!!!", origin, error, verificationStatus = VERIFICATION_STATUS.FAILED }) => {
        if (origin && error) {
            console.log(`an error occured origin => ${origin}, error =>`, error);
            //event-tracker: Initial -> proceed_to_client_agreement_error.
            tracker.initial.proceedToClientAgreementError({ error_msg: error.message });
        }

        const dialogContent = {
            icon: "/images/svgs/failed.gif",
            title,
            content: (<Text>{reason}</Text>),
            actionText: "Go Back"
        }

        this.setState({ dialogContent, verificationStatus, dialogOpen: true });
    }

    handleDialogClose = () => {
        let { verificationStatus } = this.state;
        if (!this.props.location.state.productCode) {
            this.props.location.state.productCode = 'PORTFOLIO';
        }
        const productCode = this.props.location.state.productCode;
        if(localStore.isSsoCustomer() && localStore.getSsoCustomerDetails()?.brokerName === config.broker.ssoPlatform.centrum){
        verificationStatus = verificationStatus === VERIFICATION_STATUS.NOT_CONFIRMED?VERIFICATION_STATUS.CANCELLED:verificationStatus
        }
        const redirectionURI = REDIRECTION_URIS[`${productCode}_INITIAL`][verificationStatus];

        this.setState({
            dialogOpen: false
        }, () => {    
            this.props.history.replace({
                pathname: redirectionURI,
                state: {
                    status: verificationStatus
                }
            });
        })
    }

    generateSigningStatusContent = (verificationStatus) => {
        const { dialogContent } = this.state;

        if (verificationStatus === VERIFICATION_STATUS.SUCCEED) {
            dialogContent.icon = "/images/svgs/success.gif";
            dialogContent.title = "Process Completed";
            dialogContent.content = (<Text>
                Congratulations! Document signing process is completed successfully!<br />
                {
                    (localStore.getActiveProduct() === "PORTFOLIO") ?
                    "Please proceed with linking your demat account. This is the most critical of all the steps to experiencing the full potential of Jarvis.": ""
                }
            </Text>);
            dialogContent.actionText = "Continue";
        }
        if (verificationStatus === VERIFICATION_STATUS.FAILED) {
            dialogContent.icon = "/images/svgs/failed.gif";
            dialogContent.title = "Process Failed";
            dialogContent.content = (<Text>Oops! Document signing process failed. Please try again later!</Text>);
            dialogContent.actionText = "Go Back";
        }
        if (verificationStatus === VERIFICATION_STATUS.NOT_CONFIRMED) {
            dialogContent.icon = "/images/svgs/not-confirmed.gif";
            dialogContent.title = "Status Not Confirmed";
            dialogContent.content = (<Text>Sorry! We are unable to confirm signing process status! We will update you with status update.</Text>);
            dialogContent.actionText = "Go Back";
        }
        if (verificationStatus === VERIFICATION_STATUS.PENDING) {
            dialogContent.icon = "/images/svgs/inprogress.gif";
            dialogContent.title = "Process Pending";
            dialogContent.content = (<Text>Process is not completed yet. Please wait for sometime.</Text>);
            dialogContent.actionText = "";
        }

        return dialogContent;
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        const { dialogOpen, dialogContent, isLoading, isSigningInProcess } = this.state;

        return (
            <JarvisCard>
                <Loader loading={isLoading} />

                <Text>Processing document signing...</Text>

                <JarvisDialog
                    disableCloseIcon
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    onConfirm={this.handleDialogClose}
                    title={dialogContent.title}
                    content={dialogContent.content}
                    icon={{
                        src: dialogContent.icon,
                        height: 120,
                        width: 120
                    }}
                    button={{
                        text: dialogContent.actionText,
                        disableEndIcon: true,
                        loading: isSigningInProcess
                    }}
                    enableBackdropClick={false}
                />
            </JarvisCard>
        )
    }
}

export default withRouter(DocumentSigningContainer);
