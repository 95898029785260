import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Container from '@mui/material/Container';
import Stack from 'components/common/Stack';
import Image from "components/common/Image";
import Text from 'components/common/Text';
import OnestockInfoContainer from 'containers/ProductInfo/OnestockInfo';
import { localStore } from "stores/localStorage";
import { config } from "config/index";
import JarvisCard from 'components/JarvisCard';
import JarvisButton from 'components/JarvisButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from 'components/common/Button';
import { withStyles } from "@mui/styles";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Chip } from '@mui/material';
import { alpha } from "@mui/material/styles";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TooltipHover from "components/common/TooltipHover"

const onestockProduct = {
    id: 'onestock', name: 'Jarvis One Stock', description: "To help you with short term recommendations and timely calls to book profits", icon: "/images/product-info/onestock-bag.svg", investNow: "", banner: "/images/product-info/portfolio.svg",
}

const Tabs = {
    portfolio: [
        { id: "portfolio_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "For existing and new investors who don’t have the time and expertise to manage their portfolio" },
        { id: "portfolio_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "Between 4 to 5 years regardless of market condition" },
        { id: "portfolio_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "Your demant account should have a minimum balance of Rs 30,000" },
        { id: "portfolio_4", isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: "text update here" },
    ],
    protect: [
        { id: "protect_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "Investors with underperforming stocks portfolio and don't know what to do with it" },
        { id: "protect_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "There is no minimum tenure commitment required" },
        { id: "protect_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "Your portfolio should have at least 5 stocks" },
        { id: "protect_4", isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: "text update here" },
    ],

    onestock: [
        { id: "onestock_1", isActive: true, title: "Whom", img: "/images/product-info/whom.png", text: "To book short term profits with less capital" },
        { id: "onestock_2", isActive: false, title: "Tenure", img: "/images/product-info/tenure.svg", text: "The typical holding period for a stock might vary from 15 to 30 days" },
        { id: "onestock_3", isActive: false, title: "Min. investment", img: "/images/product-info/min-inv.png", text: "There is no minimum investment required" },
        {
            id: "onestock_4", hasChipUI: true, isActive: false, title: "Benefits for you", img: "/images/product-info/benefits.png", text: ["Short-term profit booking alerts", "Personalized stock recommendations", "AI-based stock selection",
                "24x7 risk management system", "No human bias & error"]
        },
    ],
}

const onestockOnboardingStages = [
    {
        stageId: ["RISK_ASSESSMENT"],
        stageNumber: 1,
        uri: "/jarvis-onestock/onboarding/risk-assessment",
        heading: "Risk Assessment",
        subheading: "Answer these questions to know your risk profile",
    },
    {
        stageId: ["PAYMENT"],
        stageNumber: 2,
        heading: "Payment",
        uri: "/jarvis-onestock/onboarding/payment",
        subheading: "payment",
    },
    {
        stageId: ["CKYC", "DOCUMENT_SIGNING"],
        stageNumber: 3,
        heading: "CKYC & E-sign",
        uri: "/jarvis-onestock/onboarding/ckyc",
        subheading: "Complete your KYC",
    }
];
class OnestockOnboardingNotCompletedContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActivePortfolio: false,
            portfolioKnowMoreSection: true,
            protectKnowMoreSection: false,
            onestockKnowMoreSection: false,
            onestockOpenId: null,
        }
    }

    handleTabClick = (data, type) => {
        Tabs[type].forEach(element => {
            data.id == element.id ? (element.isActive = true) : (element.isActive = false);
        });
        this.setState({ isActivePortfolio: true })
    }

    handleKnowMore = (type) => {
        const { portfolioKnowMoreSection, protectKnowMoreSection, onestockKnowMoreSection } = this.state;
        if (type == "portfolio") {
            this.setState({ portfolioKnowMoreSection: !portfolioKnowMoreSection })
        }
        if (type == "protect") {
            this.setState({ protectKnowMoreSection: !protectKnowMoreSection })
        }
        if (type == "onestock") {
            this.setState({ onestockKnowMoreSection: !onestockKnowMoreSection });
        }

    }

    handleInvestNow = (type) => {
        if (type == "portfolio") {
            localStore.setActiveProduct(config.productCodes.PORTFOLIO);
            this.props.history.push(`/jarvis-portfolio/onboarding/overview`);
        }
        if (type == "protect") {
            localStore.setActiveProduct(config.productCodes.RMS);
            this.props.history.push(`/jarvis-rms/onboarding/overview`);
        }
        if (type == "onestock") {
            localStore.setActiveProduct(config.productCodes.ONESTOCK);
            this.props.history.push(`/jarvis-onestock/onboarding/overview`);
        }
    }

    // For Onestock Steps
    getActiveStageForOnestock = () => {
        const { currentStageForOnestock } = this.props;
        return onestockOnboardingStages.find((o) =>
            o.stageId.includes(currentStageForOnestock)
        )
    };

    handleOnestockTooltip = (id, type = 'open') => {
        if (type === 'open') {
            this.setState({ onestockOpenId: id });
        } else {
            this.setState({ onestockOpenId: null });
        }
    }

    render() {
        const { classes } = this.props;
        const { portfolioKnowMoreSection, onestockKnowMoreSection } = this.state;
        const activeOnestockStage = this.getActiveStageForOnestock();
        let ssoCustomerDetails = localStore.getSsoCustomerDetails();
        let isCentrumSsoCustomer = false
        if(localStore.isSsoCustomer() && ssoCustomerDetails?.broker === config?.broker?.ssoPlatform?.centrum ){
            isCentrumSsoCustomer = true
        }

        return (

            <Stack mt={3}>
                {/* <Container maxWidth="lg"> */}
                    <JarvisCard sx={{ border: "1px solid #d3d3d3", position: "relative", paddingBottom: "60px", marginBottom: "20px" }}>

                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                            <Stack sx={{ flex: { xs: "1", md: "2" } }} >
                                <Stack direction="row">
                                    <img src={onestockProduct.icon} alt="" style={{ marginRight: "18px" }} />
                                    <div>
                                        <Text variant="subtitle1" color={'#2B70C5'} sx={{ fontWeight: 500 }}>{onestockProduct.name}</Text>
                                        <Text variant="body2" color={'#676E76'}>{onestockProduct.description}</Text>
                                    </div>
                                </Stack>
                                {/* tab */}
                                <Stack alignItems="start" mt={4} direction="row" sx={{ overflow: "auto", cursor: "pointer" }}>
                                    {Tabs[onestockProduct.id].map((data) => (
                                        <Stack direction="column" alignItems="center" sx={{ flex: "1 1 auto", minWidth: "125px", borderBottom: ((data.isActive) ? '3px solid #2B70C5' : '') }} onClick={() => this.handleTabClick(data, `${onestockProduct.id}`)}>
                                            <img src={data.img} alt="" width={"36px"}></img>
                                            <Text variant="body2" mb={1} sx={{ whiteSpace: "noWrap" }}>{data.title}</Text>
                                        </Stack>
                                    )
                                    )}
                                </Stack>
                                <Stack mt={2}>
                                    {Tabs[onestockProduct.id].map((data) => {
                                        return !data.isActive ? null : data.hasChipUI ? (
                                            <>
                                                <Stack
                                                    direction="row"
                                                    sx={{
                                                        flexWrap: "wrap",
                                                        gap: "0.8rem"
                                                    }}
                                                >
                                                    {data.text.map(d => (
                                                        <Stack
                                                            className={classes.chipUi}
                                                        >
                                                            {d}
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                            </>
                                        ) : (
                                            <Text variant="body2" color={"#676E76"} >{data.text}</Text>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                            <Stack sx={{ flex: 1 }}>
                                <img src={onestockProduct.banner} alt="" width={'100%'} style={{ marginBottom: "20px" }} />
                                <JarvisButton disableEndIcon={true} onClick={() => this.handleInvestNow(onestockProduct.id)}>Invest Now</JarvisButton>
                            </Stack>
                        </Stack>

                        {
                            onestockProduct.id == "onestock" &&
                                (
                                    this.props.currentStageForOnestock === "PAYMENT" ||
                                    this.props.currentStageForOnestock === "CKYC" ||
                                    this.props.currentStageForOnestock === "DOCUMENT_SIGNING"
                                )
                                ?
                                <ClickAwayListener onClickAway={() => this.handleOnestockTooltip('', 'close')}>
                                    <Stack direction={{ xs: 'column', md: 'row' }} className={classes.toolTipss}>
                                        {this.props.onestockOverviewList.map((item, index) =><Stack mt={4} key={item.id} direction={'row'} alignItems={'center'}>
                                                <Chip label={item.id} size='small' sx={{ color: item.stageNumber < activeOnestockStage?.stageNumber ? 'white' : 'primary.dark', backgroundColor: item.stageNumber < activeOnestockStage?.stageNumber ? alpha('#2e7d32', 0.8) : alpha('#38a1ff', 0.5), fontWeight: '500', fontSize: { xs: 'caption2.fontSize', md: 'caption1.fontSize' }, maxHeight: '24px', minWidth: '24px' }} />
                                                <Text ml={0.5} sx={{ fontSize: "14px", color: '#212225', fontWeight: '400', }}>
                                                    {isCentrumSsoCustomer && item.name?.toLowerCase() === "ckyc"?"E-Sign":item.name}
                                                </Text>

                                                <TooltipHover
                                                    title={!isCentrumSsoCustomer?item.toolTipText : item.toolTipText.replaceAll('CKYC','E-Sign')}
                                                    sx={{ lineHeight: 0, ml: 0.5, mr: index === this.props.onestockOverviewList.length - 1 ? 0 : 1.5 }}
                                                >
                                                    <InfoRoundedIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                                                </TooltipHover>
                                            </Stack>
    )}
                                    </Stack>
                                </ClickAwayListener>
                                : null
                        }

                        {
                            onestockProduct.id == "onestock" && onestockKnowMoreSection ?
                                (<OnestockInfoContainer />) : null
                        }
                        <Button className={classes.btns} onClick={() => this.handleKnowMore(`${onestockProduct.id}`)} endIcon={onestockKnowMoreSection ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
                            {
                                (onestockProduct.id == "onestock" && !onestockKnowMoreSection) ? "Know Pricing"
                                    : "Hide Pricing"
                            }
                        </Button>
                    </JarvisCard>
                {/* </Container> */}

            </Stack>
        );
    }
}

const style = (theme) => ({
    btns: {
        backgroundColor: '#EEF4FA',
        color: '#2B70C5',
        fontSize: '0.875rem !important',
        fontWeight: 500,
        boxShadow: 'none',
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        bborderBottomLeftRadius: "0px",
        bborderBottomRightRadius: "0px",
        textTransform: "capitalize",
        '&:hover': {
            color: "white",
        }
    },
    chipUi: {
        color: "#2B70C5",
        border: "1px solid #DCDCDC",
        padding: "0.25rem 0.5rem",
        fontSize: "12px",
        borderRadius: "24px",
        backgroundColor: "#FFFFFF",
        fontWeight: 500,
    }

})

export default withRouter(withStyles(style, { withTheme: true })(OnestockOnboardingNotCompletedContainer));

