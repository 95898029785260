import React from "react";
import Box from "components/common/Box";
import TextField from "components/common/TextField";
import JarvisButton from "components/JarvisButton";
import Avatar from "components/common/Avatar";

const ProfileEditForm = ({
  formData = {},
  formError = {},
  loading = false,
  onSubmit,
  onChange,
}) => {
  const onProfileImageEditClick = () => {
    document.getElementById("profile-input").click();
  };

  const onProfileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    onChange("image", file);
    onChange("profilePic", url);
  };

  
  const onChangeInside = (name, event) => {
    const value = event.target.value;
    onChange(name, value)
  };

  return (
    <Box sx={{ borderRadius: "10px" }}>
      <Box p={1} mb={3}>
        <Box
          sx={{ margin: "0 auto", width: 96, cursor: "pointer" }}
          onClick={onProfileImageEditClick}
        >
          <Avatar src={formData.profilePic} title={formData.name} />
          <input
            style={{ display: "none" }}
            id="profile-input"
            type="file"
            accept="image/*"
            capture
            onChange={onProfileChange}
          />
        </Box>
      </Box>
      <TextField
        sx={{ width: "100%", mb: 2 }}
        type="text"
        label="Name"
        value={formData.fullName}
        onChange={(e) => onChangeInside("fullName", e)}
        helperText={formError.fullName}
        error={formError.fullName}
      />

      <TextField
        sx={{ width: "100%", mb: 2 }}
        disabled
        type="email"
        label="Email"
        value={formData.email}
        onChange={(e) => onChangeInside("email", e)}
        helperText={formError.email}
        error={formError.email}
      />

      <TextField
        sx={{ width: "100%", mb: 2 }}
        disabled
        type="number"
        label="Mobile"
        value={formData.mobileNo}
        onChange={(e) => onChangeInside("mobileNo", e)}
        helperText={formError.mobileNo}
        error={formError.mobileNo}
      />

      <TextField
        sx={{ width: "100%", mb: 2 }}
        type="number"
        label="Alternate Mobile"
        value={formData.alternateNo}
        onChange={(e) => onChangeInside("alternateNo", e)}
        helperText={formError.alternateNo}
        error={formError.alternateNo}
      />

      <Box textAlign="center">
        <JarvisButton
          loading={loading}
          sx={{
            margin: "0 auto",
            textTransform: "none",
            width: "200px",
          }}
          onClick={onSubmit}
        >
          Save
        </JarvisButton>
      </Box>
    </Box>
  );
};

export default ProfileEditForm;
