import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const brokerTracker = {};

brokerTracker.brokerLogin = ({ broker_name }) => {
    const eventName = "mt_login";

    appsFlyerTracker.trackEvents({
        eventName,
        eventData: { broker_name },
    });

    mixpanelTracker.trackEvents({
        eventName,
        eventData: { broker_name },
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { broker_name },
    });
};

brokerTracker.brokerLoginFailed = ({ broker_name, error_msg }) => {
    // const eventName = "mt_login_Failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });
    
    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });
};

brokerTracker.brokerLoginSuccess = ({ broker_name }) => {
    // const eventName = "mt_login_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.brokerStockList = ({ broker_name }) => {
    // const eventName = "mt_stock_list";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.brokerStockListFailed = ({ broker_name, error_msg }) => {
    // const eventName = "mt_stock_list_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });
};

brokerTracker.brokerStockListSuccess = ({ broker_name }) => {
    // const eventName = "mt_stock_list_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};
brokerTracker.brokerPlaceNewOrder = ({ broker_name }) => {
    // const eventName = "mt_place_new_order";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.brokerPlaceNewOrderFailed = ({ broker_name, error_msg }) => {
    // const eventName = "mt_place_new_order_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });
};

brokerTracker.brokerPlaceNewOrdersuccess = ({ broker_name }) => {
    // const eventName = "mt_place_new_order_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.checkOrderStatus = ({ broker_name }) => {
    // const eventName = "mt_check_order_status";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.checkOrderStatusSuccess = ({ broker_name }) => {
    // const eventName = "mt_check_order_status_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.checkOrderStatusFailed = ({ broker_name, error_msg }) => {
    // const eventName = "mt_check_order_status_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name, error_msg },
    // });
};

brokerTracker.finish = ({ broker_name }) => {
    // const eventName = "mt_finish/click_here";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.finishFailed = ({ broker_name }) => {
    // const eventName = "mtfinish/click_here_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.finishSuccess = ({ broker_name }) => {
    // const eventName = "mt_finish/click_here_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.continue = ({ broker_name }) => {
    // const eventName = "mt_continue";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.continueSuccess = ({ broker_name }) => {
    // const eventName = "mt_continue_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.continueSuccess = ({ broker_name }) => {
    // const eventName = "mt_continue_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { broker_name },
    // });
};

brokerTracker.afterMarketHoursSelectBrokerSuccess = ({ close_msg }) => {
    // const eventName = "amarket_select_broker_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { close_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { close_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { close_msg, },
    // });
};

brokerTracker.afterMarketHoursSelectBrokerFailed = ({ error_msg }) => {
    // const eventName = "amarket_select_broker_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg, },
    // });
    
};

export { brokerTracker };
