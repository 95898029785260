import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { rmsConstants } from "./rmsConstants";

const initialState = {
    executeSellCall: false,
    executeSellMsg: "",
    executeBuyCall: false,
    executeBuyMsg: "",
    rmsCalls: false,
    rmsMsg: "",
    sell: [],
    buy: [],
    isLoading: 0,
    hasRmsData: false,
    isOnBoardingCompleted:true,
    isBuyCallEnabled : "",
};

export const rmsReducers = persistReducer(
    {
        storage,
        key: "rms",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case rmsConstants.RMS_REQUEST:
                return {
                    ...initialState,
                    isLoading: 1,
                };

            case rmsConstants.RMS_RESPONSE:
                return {
                    ...state,
                    executeSellCall: payload.executeSellCall,
                    executeSellMsg: payload.executeSellMsg,
                    executeBuyCall: payload.executeBuyCall,
                    executeBuyMsg: payload.executeBuyMsg,
                    rmsCalls: payload.rmsCalls,
                    rmsMsg: payload.rmsMsg,
                    sell: payload.sell,
                    buy: payload.buy,
                    isLoading: 0,
                    hasRmsData: true,
                    isBuyCallEnabled: payload.isBuyCallEnabled
                };

            case rmsConstants.RESET_RMS_DATA: 
                return initialState;
            
            default:
                return state;
        }
    }
);
