import React from "react";
import InvestMoreContainer from "containers/InvestMore";
import PageTitle from "components/PageTitle";
import { tracker } from "library/tracker";

const PortfolioStartSIP = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);

    return (
        <>
            <PageTitle heading="Start SIP" subheading="How would you like to invest?" />
            <InvestMoreContainer investmentType="SIP" />
        </>
    )
}

export default PortfolioStartSIP;