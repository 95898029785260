import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customerActions } from "stores/customer/customerActions";

const mapStateToProps = (state) => {
  const { investmentStrategy } = state.customer.profile;
  const expectInvestmentStrategy = [0, 1];

  return {
    selectedStrategyVal: expectInvestmentStrategy.includes(investmentStrategy)
      ? investmentStrategy
      : null,
    isProfileLoading: state.customer.loading.profile,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProfileAction: customerActions.getProfile,
      updateInvestmenStrategyAction: customerActions.updateInvestmenStrategy,
    },
    dispatch
  );

const InvestmentStrategyStore = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);
export default InvestmentStrategyStore;
