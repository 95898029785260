import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { dashboardActions } from "stores/dashboard/dashboardActions";

const mapStateToProps = (state) => {
    return {
        portfolioAllocation: state.dashboard.portfolioAllocation,
        portfolioAllocationCount: state.dashboard.portfolioAllocationCount,
        portfolioAllocationLoading: state.dashboard.loading.portfolioAllocation,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getPortfolioAllocationsListAction: dashboardActions.getPortfolioAllocations,
        },
        dispatch
    );

const PortfolioAllocationStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PortfolioAllocationStore;
