import { masterConstants } from "./masterConstants";

const masterActions = {};

masterActions.getRMSRiskAssessmentQuestions = () => (
    {
        type: masterConstants.RMS_RISK_ASSESSMENT_QUESTIONS_SAGA,
    });

masterActions.getRMSRiskProfiles = () => ({
    type: masterConstants.RMS_RISK_PROFILES_SAGA,
});

masterActions.getMasterBrokers = ({ isFetchHoldingEnabled, isIntegrated, searchTerm, productCode } = {}) => ({
    type: masterConstants.MASTER_BROKER_SAGA,
    payload: { isFetchHoldingEnabled, isIntegrated, searchTerm, productCode }
})

// masterActions.getOnestockMasterBrokers = ({ isFetchHoldingEnabled, isIntegrated, searchTerm, productCode } = {}) => ({
//     type: masterConstants.ONESTOCK_MASTER_BROKER_SAGA,
//     payload: { isFetchHoldingEnabled, isIntegrated, searchTerm, productCode }
// })

masterActions.getPendingCall = () => ({
    type: masterConstants.PENDING_CALL_SAGA,
});

export { masterActions };
