import React from "react";
import _get from "lodash.get";

import { withRouter } from "react-router-dom";

import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Grid from "components/common/Grid";

import JarvisCard from "components/JarvisCard";
import JarvisDialog from "components/JarvisDialog";

import BrokerSmallCaseContainer from "./BrokerSmallCase";
import BrokerMastertrustContainer from "./BrokerMastertrust";
import BrokerSaralContainer from "./BrokerSaral";
import BrokerArham from "./BrokerArham";
import BrokerTradeCircle from "./BrokerTradeCircle";
import BrokerBigul from "./BrokerBigul";
import BrokerOdin from "./BrokerOdin";
import BrokerKleverTrade from "./BrokerKleverTrade";
import BrokerDuck from "./BrokerDuck";
import BrokerSymphony from "./BrokerSymphony";
import BrokerKambala from "./BrokerKambala";

import { config } from "config";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import { thirdpartyParsers } from "stores/thirdparty/thirdpartyParsers";
import { tracker } from "library/tracker";
import { formatHelper } from "helper/format";

const TRANSACTION_STATUS = {
  PENDING: "Pending",
  COMPLETED: "Completed",
  FAILED: "Failed",
  NOT_CONFIRMED: "NotConfirmed",
  CANCELLED: "Cancelled",
};

const BrokerPlatformContainer = ({
  location,
  history,
  onSuccess,
  onCancel,
  onSmallcaseSuccess,
  onError,
  getPreOrderData,
  preOrderData,
  updateStocksInPreOrder,
  setPostOrderData,
  getValueFromLocation,
  loginProfile,
  fetchHoldingData,
  preFetchHoldingData,
}) => {

  const brokerPlatform = getValueFromLocation("brokerPlatform");
  if (brokerPlatform === config.broker.platform.smallcase) {
    return (
      <BrokerSmallCaseContainer
        location={location}
        history={history}
        onSuccess={onSmallcaseSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        setPostOrderData={setPostOrderData}
        fetchHoldingData={fetchHoldingData}
        preFetchHoldingData={preFetchHoldingData}
      />
    );
  }

  if (brokerPlatform === config.broker.platform.mastertrust) {
    return (
      <BrokerMastertrustContainer
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        getValueFromLocation={getValueFromLocation}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        loginProfile={loginProfile}
      />
    );
  }

  if (brokerPlatform === config.broker.platform.saral) {
    return (
      <BrokerSaralContainer
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        fetchHoldingData={fetchHoldingData}
        preFetchHoldingData={preFetchHoldingData}
      />
    )
  }

  if (brokerPlatform === config.broker.platform.arham) {
    return (
      <BrokerArham
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        fetchHoldingData={fetchHoldingData}
        preFetchHoldingData={preFetchHoldingData}
      />
    )
  }

  if (brokerPlatform === config.broker.platform.tradecircle) {
    return (
      <BrokerTradeCircle
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        fetchHoldingData={fetchHoldingData}
        preFetchHoldingData={preFetchHoldingData}
      />
    )
  }

  if (brokerPlatform === config.broker.platform.symphony) {
    return (
      <BrokerSymphony
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        fetchHoldingData={fetchHoldingData}
        preFetchHoldingData={preFetchHoldingData}
        loginProfile={loginProfile}
      />
    )
  }
  if (brokerPlatform === config.broker.platform.kambala) {
    return (
      <BrokerKambala
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        fetchHoldingData={fetchHoldingData}
        preFetchHoldingData={preFetchHoldingData}
        loginProfile ={loginProfile}
      />
    )
  }

  if (brokerPlatform === config.broker.platform.bigul) {
    return (
      <BrokerBigul
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        fetchHoldingData={fetchHoldingData}
        preFetchHoldingData={preFetchHoldingData}
        loginProfile ={loginProfile}

      />
    )
  }

  if (brokerPlatform === config.broker.platform.odin) {
    return (
      <BrokerOdin
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        getValueFromLocation={getValueFromLocation}
        updateStocksInPreOrder={updateStocksInPreOrder}
        fetchHoldingData ={fetchHoldingData}
        setPostOrderData={setPostOrderData}
      />
    )
  }

  if (brokerPlatform === config.broker.platform.klevertrade) {
    return (
      <BrokerKleverTrade
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        getValueFromLocation={getValueFromLocation}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        loginProfile={loginProfile}
      />
    )
  }

  if (brokerPlatform === config.broker.platform.duck) {
    return (
      <BrokerDuck
        location={location}
        history={history}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
        getPreOrderData={getPreOrderData}
        preOrderData={preOrderData}
        getValueFromLocation={getValueFromLocation}
        updateStocksInPreOrder={updateStocksInPreOrder}
        setPostOrderData={setPostOrderData}
        loginProfile={loginProfile}
      />
    )
  }

  return null;
};

// Class Component
class BrokerContainer extends React.Component {
  interval = null;
  constructor(props) {
    super(props);
    this.state = {
      preOrderData: {
        token: null,
        accessToken: null,
        transactionId: null,
        stockDetails: [],
      },
      postOrderData: {
        totalStocks: null,
        completedStocks: null,
      },
      preFetchHoldingData: {
        tokenId: null,
        transactionId: null,
        portfolioId: null,
      },
      dialogContent: {
        icon: "",
        title: "",
        content: "",
        actionText: "Continue",
      },
      dialogOpen: false,
      isTransactionInProcess: false,
      transactionStatus: TRANSACTION_STATUS.PENDING,

      processType: "",
    };
  }

  componentDidMount = () => {
    //event-tracker: Initial -> broker_page_load
    tracker.initial.brokerPageLoad();

    this.setState({
      processType: this.getValueFromLocation("processType") ?
        this.getValueFromLocation("processType") : ""
    });

  }

  getValueFromLocation = (key) => {
    let v = _get(this.props.location.state, key, "");
    if (!v) {
      const qParams = new URLSearchParams(this.props.location.search);
      v = qParams.get(key);
    }
    return v;
  }

  fetchHoldingData = async ({ brokerName, brokerPlatform, productCode, brokerId, clientId, odinFetchHoldingToken }) => {
    try {
      this.setState({ isLoading: true });

      let fetchHoldingResponse = {};
      fetchHoldingResponse = await thirdpartyApis.fetchHolding({ brokerName, brokerPlatform, productCode, brokerId, clientId, odinFetchHoldingToken });
      fetchHoldingResponse = thirdpartyParsers.fetchHolding(fetchHoldingResponse);

      if ([config.broker.platform.smallcase].includes(brokerPlatform)) {
        this.setPreOrderData({ preFetchHoldingData: { token: fetchHoldingResponse.token, transactionId: fetchHoldingResponse.transactionId, portfolioId: fetchHoldingResponse.portfolioId } });
        return { token: fetchHoldingResponse.token, transactionId: fetchHoldingResponse.transactionId, portfolioId: fetchHoldingResponse.portfolioId };
      }

      this.handleSuccess();
      
    } catch (error) {
      this.handleError({
        reason: "Something went wrong! Please try again later!",
        origin: "initPreOrder > catch block",
        error,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getPreOrderData = async ({ brokerName, brokerPlatform, processType, onestockCallId, productCode, stockQuantity }) => {
    try {
      this.setState({ isLoading: true });

      const preOrderResponse = await thirdpartyApis.brokerPreOrder({
        selectedBroker: brokerName,
        brokerPlatform: brokerPlatform,
        type: processType,
        productCode,
        onestockCallId,
        stockQuantity,
      });
      const preOrderData = thirdpartyParsers.brokerPreOrder(preOrderResponse);

      if (
        brokerPlatform === config.broker.platform.smallcase &&
        (!preOrderData.token || !preOrderData.transactionId)
      ) {
        this.handleError({
          reason: "Something went wrong!",
          origin: "initPreOrder",
        });
        return;
      }

      this.setPreOrderData(preOrderData);
      return preOrderData;
    } catch (error) {
      this.handleError({
        reason: "Something went wrong! Please try again later!",
        origin: "initPreOrder > catch block",
        error,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  setPreOrderData = (preOrderData) => this.setState({ preOrderData });

  updateStocksInPreOrder = (stocks = []) => {
    let { preOrderData } = this.state;
    preOrderData.stockDetails = stocks;
    this.setState({ preOrderData });
  };

  setPostOrderData = (postOrderData) => this.setState({ postOrderData });

  handleSuccess = ({ platformResponse, transactionId, portfolioId } = {}) => {
    const brokerPlatform = this.getValueFromLocation('brokerPlatform');
    const productCode =this.getValueFromLocation("productCode");

    // for broker platforms which require check status in loop
    if ([config.broker.platform.smallcase, config.broker.platform.odin].includes(brokerPlatform) && productCode !== "RMS") {
      let { dialogContent, preOrderData } = this.state;
      let transactionStatus = TRANSACTION_STATUS.PENDING;
      let checkCount = 0;
      transactionId = transactionId || preOrderData.transactionId;

      // generate dialog content for pending transaction state
      dialogContent = this.generateTransactionStatusContent(
        { transactionStatus },
        platformResponse
      );

      //event-tracker: Repair Flow -> complete_your_pending_transaction
      tracker.repairFlow.completeYourPendingTransaction();

      this.setState({
        dialogOpen: true,
        isTransactionInProcess: true,
        dialogContent,
        transactionStatus,
      });

      this.interval = setInterval(async () => {
        let transactionStatus = "";
        try {
          // if max count is reached then stop checking for transaction status
          if (checkCount >= 30) {
            transactionStatus = TRANSACTION_STATUS.NOT_CONFIRMED;
            // generate dialog content for not confirmed transaction state
            dialogContent = this.generateTransactionStatusContent(
              { transactionStatus },
              platformResponse
            );

            this.setState({ isTransactionInProcess: false });
            clearInterval(this.interval);
          } else {
            if (productCode === "RMS" && brokerPlatform === config.broker.platform.smallcase) {
              const transactionStatusResponse = await thirdpartyApis.checkSmallCaseHoldingTransactinStatus({ transactionId });
              const { brokerTransactionStatus } = thirdpartyParsers.checkSmallCaseHoldingTransactinStatus(transactionStatusResponse);

              transactionStatus = brokerTransactionStatus;

              // generate dialog content for completed/pending transaction state based on api response
              dialogContent = this.generateTransactionStatusContent(
                { transactionStatus },
                platformResponse
              );
            } else {
              const stocksSymbol = preOrderData.stockDetails.map(s => s.symbol)
                let transactionStatusResponse;
                if (brokerPlatform === config.broker.platform.odin) {
                  // validate transaction status
                  transactionStatusResponse =
                    await thirdpartyApis.brokerOdinTransactionStatus({
                      customerId: this.props.loginProfile.customerId,
                      transactionId: transactionId,
                      stocks: stocksSymbol,
                      productCode:productCode,
                    });
                } else {
                  // validate transaction status
                  transactionStatusResponse =
                    await thirdpartyApis.brokerTransactionStatus({
                      customerId: this.props.loginProfile.customerId,
                      transactionId: transactionId,
                      stocks: stocksSymbol,
                      productCode,
                    });
                }
                const { brokerTransactionStatus, completedStocks, totalStocks } = thirdpartyParsers.brokerTransactionStatus(
                  transactionStatusResponse
                );

                transactionStatus = brokerTransactionStatus;

                // generate dialog content for completed/pending transaction state based on api response
                dialogContent = this.generateTransactionStatusContent(
                  { transactionStatus, completedStocks, totalStocks },
                  platformResponse
                );
            }

            // reset check cycle if transaction status is completed
            if (transactionStatus === TRANSACTION_STATUS.COMPLETED) {
              // for onboarding flow need to fetch onboarding details before redirection
              const processType = _get(
                this.props.location,
                "state.processType",
                ""
              );

              if (productCode === "RMS") {
                this.props.getRMSOnboardingDataAction();
              } else if (processType === "initial") {
                this.props.getOnboardingDataAction();
                tracker.onboarding.brokerSelection(
                  this.props.loginProfile.mobile
                );
              }

              this.setState({ isTransactionInProcess: false });
              clearInterval(this.interval);
            }
          }
        } catch (error) {
          this.handleError({
            reason: "Something went wrong! Please try again later!",
            origin: "handleSuccess > catch block",
            error,
          });
          this.setState({ isTransactionInProcess: false });
          clearInterval(this.interval);
        } finally {
          ++checkCount;
          this.setState({ dialogContent, transactionStatus });
        }
      }, 5000);

      return;
    }

    const transactionStatus = TRANSACTION_STATUS.COMPLETED;
    const { postOrderData } = this.state;

    // for onboarding flow need to fetch onboarding details before redirection
    const processType = _get(this.props.location, "state.processType", "");
    if (productCode === "RMS") {
      this.props.getRMSOnboardingDataAction();
    } else if (processType === "initial") {
      this.props.getOnboardingDataAction();
      tracker.onboarding.brokerSelection(
        this.props.loginProfile.mobile
      );
    }

    this.setState({
      dialogOpen: true,
      isTransactionInProcess: false,
      dialogContent: this.generateTransactionStatusContent(
        {
          transactionStatus,
          totalStocks: postOrderData.totalStocks,
          completedStocks: postOrderData.completedStocks
        },
        platformResponse
      ),
      transactionStatus,
    });
  };

  handleCancel = () => {
    const productCode = this.getValueFromLocation("productCode");

    if (productCode === "RMS") {
      this.props.history.replace("/jarvis-rms");
    } else if (productCode === "ONESTOCK") {
      this.props.history.replace("/jarvis-onestock");
    } else {
      this.props.history.replace("/jarvis-portfolio");
    }
  };

  // Order Placed: onError
  handleError = ({ reason, origin, error }) => {
    if (origin && error) {
      console.error(`an error occured origin => ${origin}, error =>`, error);
    }

    const dialogContent = {
      icon: ``,
      title: `Transaction Failed`,
      content: <Text>{reason}</Text>,
      actionText: "Go Back",
    };

    this.setState({ dialogContent, dialogOpen: true });
  };

  getDefaultURI = () => {
    if (this.getValueFromLocation('productCode')=== config.productCodes.ONESTOCK) {
      return "/jarvis-onestock/dashboard"
    }

    if (this.getValueFromLocation('productCode')=== config.productCodes.RMS) {
      return "/jarvis-rms/existing-holding/add-portfolio"
    }
    else{
      return '/jarvis-portfolio/dashboard'
    }
    
  }
  handleDialogClose = () => {
    //NOTE : event_tracker => broker
    tracker.broker.continue({ broker_name: "" });

    let returnURI = "";

    if (this.state.transactionStatus === TRANSACTION_STATUS.COMPLETED) {
      returnURI = _get(
        this.props.location,
        "state.returnURI",
        this.getDefaultURI()
      );

      //NOTE : event_tracker => broker
      tracker.broker.continueSuccess({ broker_name: "" });
      if (this.state.processType === "initial") {
        tracker.initial.stockTransactionSuccess();
      }

    } else {
      returnURI = _get(
        this.props.location,
        "state.goBackURI",
        this.getDefaultURI()
      );
    }

    //this.props.history.goBack();
    this.setState({ dialogOpen: false }, () => {
      this.props.history.replace({
        pathname: returnURI,
        state: {
          brokerStatus: this.state.transactionStatus,
        },
      });
    });
  };

  generateTransactionStatusContent = ({ transactionStatus, completedStocks, totalStocks }, platformResponse = {}) => {
    const productCode = _get(this.props.location, "state.productCode", "");
    const brokerPlatform = _get(this.props.location, "state.brokerPlatform", "");
    const { dialogContent } = this.state;

    // set default platformResponse if not returned
    if (![null, undefined, ""].includes(completedStocks)) {
      platformResponse['filled'] = completedStocks
    }
    if (![null, undefined, ""].includes(totalStocks)) {
      platformResponse['quantity'] = totalStocks
    }
    if (!platformResponse['status']) {
      platformResponse['status'] = transactionStatus
    }

    if (transactionStatus === TRANSACTION_STATUS.COMPLETED) {
      dialogContent.icon = "/images/svgs/success.gif";
      dialogContent.title = `Transaction Completed`;
      dialogContent.actionText = `Continue`;
      dialogContent.content = (
        <Stack>
          <Text>Congratulations! Transaction is completed successfully!</Text>
          {productCode === config.productCodes.PORTFOLIO && platformResponse ? <BaseContent content={platformResponse} /> : null}
        </Stack>
      );
    }
    if (transactionStatus === TRANSACTION_STATUS.FAILED) {
      dialogContent.icon = "/images/svgs/failed.gif";
      dialogContent.title = `Transaction Failed`;
      dialogContent.actionText = `Go Back`;
      dialogContent.content = (
        <Stack>
          <Text>Oops! Transaction failed. Please try again later!</Text>
        </Stack>
      );
    }
    if (transactionStatus === TRANSACTION_STATUS.NOT_CONFIRMED) {
      dialogContent.icon = "/images/svgs/not-confirmed.gif";
      dialogContent.title = `Transaction Not Confirmed`;
      dialogContent.actionText = `Go Back`;
      dialogContent.content = (
        <Stack>
          <Text>
            {brokerPlatform === config.broker.platform.smallcase || brokerPlatform === config.broker.platform.odin
            ?'Please wait momentarily as your transaction is currently being processed. Feel free to return in a few minutes or refresh the dashboard to check the status.' 
            :'Sorry! We are unable to confirm transaction status! We will update you with status update.'
            }
          </Text>
          {productCode === config.productCodes.PORTFOLIO && platformResponse ? <BaseContent content={platformResponse} /> : null}
        </Stack>
      );
    }
    if (transactionStatus === TRANSACTION_STATUS.PENDING) {
      dialogContent.icon = "/images/svgs/inprogress.gif";
      dialogContent.title = `Transaction Pending`;
      dialogContent.actionText = `Continue`;
      dialogContent.content = (
        <Stack>
          <Text>Transaction is in process. Please wait for sometime.</Text>
          {productCode === config.productCodes.PORTFOLIO && platformResponse ? <BaseContent content={{ ...platformResponse, status: "fetching..." }} /> : null}
        </Stack>
      );
    }
    return dialogContent;
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { dialogOpen, dialogContent, preOrderData, isTransactionInProcess, preFetchHoldingData } =
      this.state;

    return (
      <JarvisCard>
        <BrokerPlatformContainer
          location={this.props.location}
          history={this.props.history}
          loginProfile={this.props.loginProfile}
          onSuccess={this.handleSuccess}
          onCancel={this.handleCancel}
          onSmallcaseSuccess={this.handleSuccess}
          onError={this.handleError}
          getPreOrderData={this.getPreOrderData}
          preOrderData={preOrderData}
          setPostOrderData={this.setPostOrderData}
          updateStocksInPreOrder={this.updateStocksInPreOrder}
          getValueFromLocation={this.getValueFromLocation}
          preFetchHoldingData={preFetchHoldingData}
          fetchHoldingData={this.fetchHoldingData}
        />

        <JarvisDialog
          disableCloseIcon
          open={dialogOpen}
          onClose={this.handleDialogClose}
          onConfirm={this.handleDialogClose}
          title={dialogContent.title}
          content={dialogContent.content}
          icon={{
            src: dialogContent.icon,
            height: 120,
            width: 120,
          }}
          button={{
            text: dialogContent.actionText,
            disableEndIcon: true,
            loading: isTransactionInProcess,
          }}
        ></JarvisDialog>
      </JarvisCard>
    );
  }
}

const BaseContent = ({ content }) => {
  const getDataValue = (heading, d, show = false, { valueColor } = {}) =>
    d || show ? (
      <>
        <Text veriant="body2" sx={{ color: "#afafaf", fontWeight: "500" }}>
          {heading}
        </Text>
        <Text veriant="subtitle2" color={valueColor || "#1E1E1E"} mb={2}>
          {d}
        </Text>
      </>
    ) : null;

  return (
    <Stack
      alignItems="flex-start"
      textAlign="left"
      justifyContent="flex-start"
      spacing={0.25}
      mt={3}
    >
      <Grid container spacing={2} mt={2}>
        {content.batchId && (
          <Grid item xs={12} sm={8}>
            {getDataValue("Batch ID", content.batchId)}
          </Grid>
        )}
        {content.status && (
          <Grid item xs={6} sm={4}>
            {getDataValue("Status", content.status)}
          </Grid>
        )}

        {![null, undefined, ""].includes(content.quantity) && (
          <Grid item xs={6} sm={4}>
            {getDataValue("Quantity", content.quantity, true)}
          </Grid>
        )}
        {![null, undefined, ""].includes(content.filled) && (
          <Grid item xs={6} sm={4}>
            {getDataValue("Filled", content.filled, true, { valueColor: content.filled === content.quantity ? '#1E1E1E' : '#D44C4C' })}
          </Grid>
        )}
        {content.buyAmount ? (
          <Grid item xs={6} sm={4}>
            {getDataValue("Buy Amount", formatHelper.currency(content.buyAmount, { config: { maximumFractionDigits: 2 } }))}
          </Grid>
        ) : null}
        {content.sellAmount ? (
          <Grid item xs={6} sm={4}>
            {getDataValue("Sell Amount", formatHelper.currency(content.sellAmount, { config: { maximumFractionDigits: 2 } }))}
          </Grid>
        ) : null}
      </Grid>
    </Stack>
  )
};

export default withRouter(BrokerContainer);
