import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar } from "@mui/material";
import { alpha } from "@mui/system";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Text from "components/common/Text";
import InfoIcon from '@mui/icons-material/Info';
import TooltipHover from "components/common/TooltipHover";
import JarvisCard from "components/JarvisCard";

const useStyles = makeStyles((theme) => ({
}));

const DashboardDataCard = (props) => {
    const classes = useStyles();
    const {
        icon = "",
        value = "0",
        loading = 0,
        color = "#1D9BFF",
        label = "-",
        gainLoss = "",
        sx = {},
        hasInfoIcon = false,
        visibleIcon = false,
    } = props;

    return (
        <JarvisCard sx={sx}>
            {visibleIcon && <Avatar sx={{ mb:2, width:50 , height:50}} src={icon} />}
            <Text variant="small" color={color} >{gainLoss}</Text>
            <Text variant="subtitle2" color={color} fontWeight="bold">
                {loading ? <Text>Loading...</Text> : value}
            </Text>
            <Stack sx={{ position: "relative", flexFlow: "row nowrap", alignItems: "center", minHeight: "28px" }}>
                <Text
                    sx={{ typography: { xs: "small", md: "body2" }, whiteSpace: "nowrap" }}
                    color="#808591"
                >
                    {label}
                </Text>

                {
                    hasInfoIcon ?
                        <TooltipHover
                            title="The gains/ loss does not include brokerage , taxes and advisory fees."
                            sx={{ lineHeight: 0, marginLeft: "3px" }}
                        >
                            <InfoIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                        </TooltipHover>

                        : null
                }
            </Stack>
        </JarvisCard>
    );
};

export default DashboardDataCard;
