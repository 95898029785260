import { ArrowBack } from "@mui/icons-material";
import React from "react";
import Box from "../common/Box";
import Button from "../common/Button";
import Divider from "../common/Divider";
import List from "../common/List";
import ListItem from "../common/ListItem";
import Stack from "../common/Stack";
import Text from "../common/Text";

const HelpSupportQuestionList = (props) => {
  const { onBackClick, categoryTitle, categoryData, categoryID, OnListClick } =
    props;
  let questionList = [];
  if (categoryData && categoryID in categoryData) {
    questionList = categoryData[categoryID];
  }
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
      }}
      role="presentation"
    >
      <List>
        <Stack direction="row" alignItems="center" pb={1}>
          <Button variant="text" onClick={() => onBackClick()}>
            {<ArrowBack fontSize="small" />}
          </Button>
          <Text fontWeight="bold" color="#3A3A3A">
            {categoryTitle}
          </Text>
        </Stack>
        <Divider />

        {questionList.map(({ title, id }, qId) => (
          <ListItem
            button
            key={qId}
            onClick={() => OnListClick(id)}
            sx={{ padding: "0px 15px", margin: "12px 0px" }}
          >
            <Text color="#808591">{title}</Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default HelpSupportQuestionList;
