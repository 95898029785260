import React from "react";
import { makeStyles } from "@mui/styles";

import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";

import { formatHelper } from "helper/format";
import { dateTimeHelper } from "helper/dateTime";

import cx from "classnames";

const useStyles = makeStyles(() => ({
    brokerStatus: {
        color: "#D44C4C",
        whiteSpace: "nowrap",
        marginLeft: "4px",
        backgroundColor: "rgb(0, 0, 0, 0.04)",
        display: "inline-block",
        fontSize: "12px",
        height: "24px",
        width: "72px",
        textAlign: "center",
        lineHeight: "24px",
        borderRadius: "25px",
    },
    quantityWrap: {
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        alignItems: "center",
    },
    stockName: {
        color: "#202020",
        fontWeight: "600",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: "10px",
        fontSize: "18px",
    },
    totalVal: {
        fontSize: "10px",
        marginLeft: "7px",
        fontWeight: "bold",
        color: "#222B3E",
    },

    statusChip: {
        padding: "0px 10px",
        borderRadius: "10px",
        fontSize: "14px",
        textTransform: "uppercase",
        color: "#000000",
        backgroundColor: "#00000020",
    },
    statusChipPending: {
        color: "#ECB004",
        backgroundColor: "#ECB0042e",
    },
    statusChipCompleted: {
        color: "#008000",
        backgroundColor: "#0080002e",
    },
    statusChipRejected: {
        color: "#ED3833",
        backgroundColor: "#cb1f1b20",
    }

}));

const StockCard = (props) => {
    const {
        cardType, // PORTFOLIO, SELL-PORTFOLIO, BUY-PORTFOLIO, INITIAL-PORTFOLIO, MASTER-TRUST, SARAL
        sx = {},
    } = props;

    const {
        stockName,
        sector,
        quantity = 0,
        pendingActionableQty = 0,
        completedActionableQty = 0,
        profitPrice,
        profitPercentage,
        profitType,
        buyPrice,
        currentPrice,
        totalBuyPrice,
        totalCurrentPrice,

        transactionAction = "",
        transactionDate,

        allocationPercentage = 0,

        // place order
        status = "", // pending, complete, rejected, cancelled
        symbol,

        // Tranaction
        purchaseType,
        actionMsg,
        actionColorCode = "#000000de",

        // portfolio
        capCategory,

        // initial-portfolio
        hasBlureValues = false,



    } = props.stockDetails;

    const classes = useStyles();
    let cardBgColor = {};

    let printQty = (pendingActionableQty > 0 && quantity > 0 && quantity !== pendingActionableQty)
        ? `${pendingActionableQty} pending out of ${quantity}`
        : quantity;


    switch (cardType) {

        case "SELL-PORTFOLIO":
            cardBgColor = { backgroundColor: "#FDF7F7" };
            break;
        case "BUY-PORTFOLIO":
            cardBgColor = { backgroundColor: "#F0F9F4" };
            break;
        case "TRANSACTION":
            let actionText = (transactionAction.toUpperCase() === "B") ? " bought " : " sold ";
            printQty = (completedActionableQty > 0 && quantity > 0 && quantity !== completedActionableQty)
                ? `${completedActionableQty} ${actionText} out of ${quantity}`
                : quantity

            break;
        default:

    }

    return (
        <>
            <Box sx={{ padding: "16px 14px", ...cardBgColor, ...sx }}>
                {/* 1: Quantity */}
                <Stack className={classes.quantityWrap}>
                    <Box sx={{ 
                        width: "auto",
                        filter: hasBlureValues ? "blur(4px)": "" 
                    }}>
                        <Text variant="caption1" component="span" sx={{ color: "#808591" }}>
                            Qty{" "}
                        </Text>
                        <Text
                            variant="caption1"
                            component="span"
                            sx={{ color: "#222B3E", ml: "4px",
                        }}
                        >
                            <b>{printQty} </b>
                        </Text>
                        {
                            capCategory ?
                            <Text component="span" sx={{
                            marginLeft: "10px",
                            display: "inline-block",
                            fontSize: "10px",
                            color: "gray",
                            backgroundColor: "#8080801A",
                            padding: "0.5px 7px",
                            borderRadius: "50px"
                            }}> {capCategory} </Text>
                            : null
                        }
                    </Box>
                    {["PORTFOLIO", "SELL-PORTFOLIO"].includes(cardType) ? (
                        <Text
                            variant="small"
                            sx={{ color: profitType === "gain" ? "#45AC70" : "#ED3833" }}
                        >
                            {formatHelper.currency(Math.abs(profitPrice))} (
                            {Math.abs(profitPercentage)}%)
                        </Text>
                    ) : null}

                    {
                        ["TRANSACTION", "MASTER-TRUST", "SARAL"].includes(cardType) ? (
                            <Text
                                variant="small"
                                sx={{
                                    fontWeight: "600",
                                    color: transactionAction.toUpperCase() === "B" ? "#45AC70" : "#ED3833",
                                }}
                            >
                                {transactionAction.toUpperCase() === "B" ? "BUY" : "SELL"}
                            </Text>
                        ) : null
                    }

                    {
                        ["INITIAL-PORTFOLIO"].includes(cardType) ? (
                            <Text variant="small" sx={{ fontWeight: "600", color: "#45AC70", filter: hasBlureValues ? "blur(4px)": "" }}>
                                {allocationPercentage}%
                            </Text>
                        ) : null
                    }
                </Stack >

                {/* 2:Stock Name */}
                <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text className={classes.stockName} title={stockName}>
                        {stockName}
                    </Text>
                    <Text
                        variant="body1"
                        sx={{ color: "#202020", textAlign: "right", fontWeight: "600" }}
                    >
                        {cardType === "TRANSACTION"
                            ? formatHelper.currency(buyPrice, {
                                config: { maximumFractionDigits: 2, useGrouping: true },
                            })
                            : formatHelper.currency(currentPrice, {
                                config: { maximumFractionDigits: 2, useGrouping: true },
                            })}
                    </Text>
                </Stack >

                {/* 3: Total: Values, Invested: [PORTFOLIO, SELL-PORTFOLIO] */}
                {
                    ["PORTFOLIO", "SELL-PORTFOLIO"].includes(cardType) ? (
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: "auto" }}>
                                <Text
                                    component="span"
                                    variant="caption2"
                                    sx={{ color: "#808591" }}
                                >
                                    Current Value
                                </Text>
                                <Text component="span" className={classes.totalVal}>
                                    {formatHelper.currency(totalCurrentPrice, {
                                        config: { maximumFractionDigits: 0 },
                                    })}
                                </Text>
                            </Box>

                            <Box sx={{ width: "auto" }}>
                                <Text
                                    component="span"
                                    variant="caption2"
                                    sx={{ color: "#808591" }}
                                >
                                    Total Invested
                                </Text>
                                <Text component="span" className={classes.totalVal}>
                                    {formatHelper.currency(totalBuyPrice, {
                                        config: { maximumFractionDigits: 0 },
                                    })}
                                </Text>
                            </Box>
                        </Stack>
                    ) : null
                }

                {/* 3: Total: Values, Invested: [TRANSACTION] */}
                {
                    ["TRANSACTION"].includes(cardType) ? (
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: "auto" }}>
                                <Text
                                    component="span"
                                    variant="caption2"
                                    sx={{ color: "#808591" }}
                                >
                                    Total Invested
                                </Text>
                                <Text component="span" className={classes.totalVal}>
                                    {formatHelper.currency(totalBuyPrice, {
                                        config: { maximumFractionDigits: 0 },
                                    })}
                                </Text>
                            </Box>

                            <Box sx={{ width: "auto" }}>
                                <Text
                                    component="span"
                                    variant="caption2"
                                    sx={{ color: "#808591" }}
                                >
                                    Date
                                </Text>
                                <Text component="span" className={classes.totalVal}>
                                    {dateTimeHelper.displayDate(transactionDate)}
                                </Text>
                            </Box>
                        </Stack>
                    ) : null
                }

                {/* 3: sector */}
                {
                    ["INITIAL-PORTFOLIO", "BUY-PORTFOLIO"].includes(cardType) ? (
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: "auto" }}>
                                <Text
                                    component="span"
                                    variant="caption2"
                                    sx={{ color: "#808591" }}
                                >
                                    {sector}
                                </Text>
                            </Box>
                        </Stack>
                    ) : null
                }


                {/* 4: status */}
                {
                    ["MASTER-TRUST", "SARAL"].includes(cardType) && (
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ width: "auto" }}>
                                <Text
                                    variant="small"
                                    sx={{
                                        textTransform: "uppercase",
                                        color: "gray",
                                        fontSize: "14px",
                                        marginTop: "0px",
                                    }}
                                >
                                    {symbol}
                                </Text>
                            </Box>
                            {(["pending", "complete", "rejected", "cancelled"].includes(`${status}`.toLowerCase())) ? (
                                <Text
                                    className={cx({
                                        [classes.statusChip]: true,
                                        [classes.statusChipPending]: status.toLowerCase() == "pending",
                                        [classes.statusChipCompleted]: status.toLowerCase() == "complete",
                                        [classes.statusChipRejected]: status.toLowerCase() == "rejected"
                                    })}
                                >
                                    {status}
                                </Text>

                            ) : null}
                        </Stack>
                    )
                }

                {/* 4: Executed */}
                {
                    ["TRANSACTION"].includes(cardType) ? (
                    <Stack sx={{flexDirection: "row", justifyContent:"space-between"}}>
                        <Text variant="caption1">{purchaseType}</Text>
                        <Text variant="caption1" sx={{ fontWeight: "500", color: actionColorCode }}>{actionMsg}</Text>
                    </Stack>
                    ): null
                }

            </Box >
        </>
    );
};

export default React.memo(StockCard);
