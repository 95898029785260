import React from "react";

import Box from "components/common/Stack";
import Grid from "components/common/Grid";
import Loader from "components/common/Loader";

import ChangeRiskProfileCard from "components/Onboarding/ChangeRiskProfileCard";
import OfflineCheckerButton from "components/OfflineCheckerButton";

const RiskProfileForm = (props) => {
  const {
    riskProfileList,
    currentRiskProfileId,
    setRiskProfile,
    onSubmit,
    loading,
    isRiskProfileListLoading,
  } = props;

  return (
    <>
      <Box sx={{ height: "calc(75vh - 116px)", overflow: "auto" }}>
        {isRiskProfileListLoading && riskProfileList.length < 1 ? (
          <Box sx={{ position: "relative", minHeight: "300px" }}>
            <Loader position="absolute" loading={isRiskProfileListLoading} />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {riskProfileList.map((r) => (
              <Grid item sm={4} key={r.id} sx={{ display: "flex" }}>
                <ChangeRiskProfileCard
                  id={r.id}
                  title={r.name}
                  description={r.description}
                  level={r.riskLevelValue}
                  onClick={() => setRiskProfile({ id: r.id, name: r.name })}
                  isSelected={currentRiskProfileId === r.id}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Box m={2} textAlign="center">
        <OfflineCheckerButton
          offlineButtonProps={{
            text: "Confirm this risk profile",
            loading: loading,
            sx: {
              margin: "0 auto",
              textTransform: "none",
              width: "300px",
              maxWidth: "100%",
              padding: "12px 10px",
              height: "49px"
            }
          }}
          onlineButtonProps={{
            text: "Confirm this risk profile",
            loading: loading,
            sx: {
              margin: "0 auto",
              textTransform: "none",
              width: "300px",
              maxWidth: "100%",
              padding: "12px 10px",
              height: "49px"
            },
            onClick: onSubmit
          }}
        />
        
      </Box>
    </>
  );
};

export default RiskProfileForm;
