import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import { customerActions } from "stores/customer/customerActions";

const mapStateToProps = (state) => {
    return {
        loginProfile: state.customer.loginProfile,
        customerProfile : state.customer.profile,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    
    // product's onboarding data update
    updatePortfolioOnboardingDataAction: onboardingActions.updateOnboardingData,
    updateRMSOnboardingDataAction: jarvsiRMSOnboardingActions.updateRMSOnboardingData,
    updateOnestockOnboardingDataAction: jarvsiOnestockOnboardingActions.updateOnestockOnboardingData,

    // fetch profile
    getProfileDataAction: customerActions.getProfile,
}, dispatch);

const ManualDocumentSigningStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default ManualDocumentSigningStore;
