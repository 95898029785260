import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getRMSOnboardingData: jarvsiRMSOnboardingActions.getOnboardingData,
}, dispatch);

const ProtectInfoStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default ProtectInfoStore;
