import React from "react";

import InvestmentPreferenceContainer from "containers/Onboarding/InvestmentPreference";

const PortfolioInvestmentPreference = (props) => {
    return (
        <>
            <InvestmentPreferenceContainer />
        </>
    )
};

export default PortfolioInvestmentPreference;