import { axios } from "helper/axios";
import { jarvisRMSOnboardingConstants } from "./jarvisRMSOnboardingConstants";

const jarvisRMSOnboardingApis = {};

jarvisRMSOnboardingApis.getOnboardingData = () => {
    return axios.get(jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_API);
};

// TODO: move this api to master
jarvisRMSOnboardingApis.submitRiskAssessmentQuestions = ({
    questionAnswers,
}) => {
    return axios.post(jarvisRMSOnboardingConstants.RMS_RISK_ASSESSMENT_API, {
        questionAnswers,
    });
};

jarvisRMSOnboardingApis.confirmRiskProfile = ({ riskProfileId }) => {
    return axios.post(
        jarvisRMSOnboardingConstants.RMS_CONFIRM_RISK_PROFILE_API,
        {
            riskProfileId,
        }
    );
};

jarvisRMSOnboardingApis.getRMSPlanList = () => {
    return axios.get(jarvisRMSOnboardingConstants.RMS_PLAN_API);
};

jarvisRMSOnboardingApis.createRMSSubscription = ({ planId }) => {
    return axios.post(
        jarvisRMSOnboardingConstants.RMS_PAYMENT_SUBSCRIPTION_API,
        {
            planId,
        }
    );
};

jarvisRMSOnboardingApis.captureRMSSubscription = ({
    planId,
    customerSubscriptionId,
}) => {
    return axios.post(
        jarvisRMSOnboardingConstants.RMS_CAPTURE_PAYMENT_SUBSCRIPTION_API,
        {
            planId,
            customerSubscriptionId,
        }
    );
};

jarvisRMSOnboardingApis.getPortfolioDetails = ({portfolioId, isTemp}) => {
    const uri = jarvisRMSOnboardingConstants.RMS_PORTFOLIO_DETAILS_API.replace("portfolioId", portfolioId);
    return axios.get(uri, { params: { temp: isTemp } });
};

jarvisRMSOnboardingApis.getStockList = ({ portfolioId, isTemp, page, rowsPerPage }) => {
    const uri = jarvisRMSOnboardingConstants.RMS_STOCK_LIST_API.replace("portfolioId", portfolioId);
    return axios.get(uri, { params: { temp: isTemp },  payload: { page, rowsPerPage }, });
};

jarvisRMSOnboardingApis.updateSyncPortfolioName = (payload) => {
    let queryParam = `?temp=${1}`;
    return {
        portfolioId: payload.portfolioId,
        portfolioName: payload.portfolioName,
    };

    //Note: Here we have to implement api call

    //   return axios.patch(`${jarvisRMSOnboardingConstants.BROKER_LIST_API.replace("portfolioId", payload.portfolioId)}${queryParam}, {
    //     portfolioId: payload.portfolioId,
    // 	   portfolioName: payload.portfolioName,
    //   });
};

jarvisRMSOnboardingApis.updatePortfolioName = (payload) => {
    let data = {
        portfolioName: payload.portfolioName,
    };
    let queryParam = `?temp=${1}`;
    return axios.patch(
        `${jarvisRMSOnboardingConstants.RMS_EDIT_PORTFOLIO_NAME_API.replace(
            "portfolioId",
            payload.portfolioId
        )}${queryParam}`,
        data
    );
};

jarvisRMSOnboardingApis.deletePortfolio = ({portfolioId, isTemp}) => {
    const uri = jarvisRMSOnboardingConstants.RMS_DELETE_PORTFOLIO_API.replace("portfolioId", portfolioId);
    
    return axios.delete(
        uri,
        { params: { temp: isTemp } }
    );
};

jarvisRMSOnboardingApis.removeStock = ({ portfolioId, isTemp, portfolioStockIds = [] }) => {
    const uri = jarvisRMSOnboardingConstants.RMS_REMOVE_STOCK_API.replace("portfolioId", portfolioId);

    return axios.patch(
        uri,
        { portfolioStockIds },
        { params: { temp: isTemp } }
    );
};

jarvisRMSOnboardingApis.addStock = ({ portfolioId, isTemp, stockId, stockSymbol, stockQuantity, stockBuyPrice, stockBuyDate }) => {
    const uri = jarvisRMSOnboardingConstants.RMS_ADD_STOCK_API.replace("portfolioId", portfolioId);

    return axios.post(
        uri,
        { stockId, stockSymbol, stockQuantity, stockBuyPrice, stockBuyDate },
        { params: { temp: isTemp } }
    );
};

jarvisRMSOnboardingApis.editStock = ({ portfolioId, isTemp, editPayload = [] }) => {
    const uri = jarvisRMSOnboardingConstants.RMS_EDIT_STOCK_API.replace("portfolioId", portfolioId);
    return axios.put(
        uri,
        { stockData: editPayload },
        { params: { temp: isTemp } }
    );
};

jarvisRMSOnboardingApis.confirmPortfolioStock = ({ portfolioId }) => {
    return axios.post(jarvisRMSOnboardingConstants.RMS_CONFIRM_STOCK_API, {
        portfolioId,
    });
};

jarvisRMSOnboardingApis.syncPortfolioName = (payload) => {
    return {
        portfolioName: payload.portfolioName,
    };
    //   return axios.post(jarvisRMSOnboardingConstants.RMS_CONFIRM_STOCK_API, {
    //     body
    //   });
};

// jarvisRMSOnboardingApis.getBrokerList = ({isIntegrated}) => {
jarvisRMSOnboardingApis.getBrokerList = ({
    isIntegrated = null,
    searchTerm = "",
    isFetchHoldingEnabled = null,
    productCode = "",
}) => {
    const params = {};

    if (searchTerm) {
        params["search"] = searchTerm;
    }

    if (![null, undefined].includes(isIntegrated)) {
        params["isIntegrated"] = isIntegrated;
    }

    if (![null, undefined].includes(isFetchHoldingEnabled)) {
        params["isFetchHoldingEnabled"] = isFetchHoldingEnabled;
    }

    if (productCode) {
        params["productCode"] = productCode;
    }

    return axios.get(jarvisRMSOnboardingConstants.BROKER_LIST_API, { params });
};

jarvisRMSOnboardingApis.uploadPortfolio = (payload) => {
    return axios.post(
        jarvisRMSOnboardingConstants.RMS_UPLOAD_PORTFOLIO_API,
        payload
    );
};

jarvisRMSOnboardingApis.confirmRMSCKYCData = () => {
    return axios.post(jarvisRMSOnboardingConstants.RMS_CONFIRM_CKYC_DATA_API);
};

jarvisRMSOnboardingApis.initiateDocumentSigning = () => {
    return axios.post(
        jarvisRMSOnboardingConstants.RMS_INITIATE_DOCUMENT_SIGNING_API
    );
};

export { jarvisRMSOnboardingApis };
