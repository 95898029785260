import React from "react";
// import PageTitle from "components/PageTitle";
import RMSActionCenterContainer from "containers/JarvisRMS/RMSActionCenter";

const RMSActionCenter = () => {

	return (
			< RMSActionCenterContainer />
	);
};

export default RMSActionCenter;
