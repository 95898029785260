import { axios } from "helper/axios";
import { jarvisOnestockDashboardConstants } from "./jarvisOnestockDashboardConstants";

const jarvisOnestockDashboardApis = {};

jarvisOnestockDashboardApis.getDashboardStatsApi = () => {
	return axios.get(
		jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_API
	);
};
jarvisOnestockDashboardApis.getDashboardRevealApi = () => {
	return axios.get(
		jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_API
	);
};

// For Buy Call
jarvisOnestockDashboardApis.onestockStocksCallApi = ({ stockSellPrice, stockSellQuantity, stockBuyPrice, stockBuyQuantity, oneStockCallId }) => {
	const body = {
		stockSellPrice,
		stockSellQuantity,
		stockBuyPrice,
		stockBuyQuantity,
		oneStockCallId
	};
	return axios.post(jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_CALL_API, body);
};

export { jarvisOnestockDashboardApis };
