import React from "react";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Link from "components/common/Link";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import IconButton from "components/common/IconButton";
import JarvisButton from "components/JarvisButton";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import Button from "components/common/Button";

const useStyles = makeStyles(() => ({
    otpContainer: {
        height: "56px",
        width: "100%",
    },
    otpInput: {
        height: "56px",
        width: "57px",
        border: "1px solid #E0E7FF",
        flex: "1",
    },
    otpResend: {
        color: "#72aaff",
        background: "transparent !important",
        padding: "0",
        boxShadow: "none !important",
        fontSize: "12px",
        display: "inline",
        textTransform: "none",
        cursor: "pointer",
        minWidth: "auto",
        marginRight: "8px",
    },
}));

const ForgotPasswordForm = (props) => {
    const classes = useStyles();
    const {
        handleChange,
        formData,
        formError,
        handleRequestOtp,
        status,
        handleOtpVerification,
        handleChangePassword,
        isLoading,
        resendTime,
    } = props;
    return (
        <>
            <Stack
                sx={{
                    py: { xs: 3, md: 7 },
                    mx: { xs: 3, md: 6 },
                    flex: 1,
                    height: "100%",
                    position: "relative",
                }}>
                <Stack
                    direction="row"
                    justifyContent={{xs:"space-between",md:"flex-end"}}
                    alignItems="center"
                    spacing={1}>
                    <Link  sx={{display: { xs: "flex", md: "none" },}}>
                        <IconButton>
                            <ArrowBackIcon
                                sx={{ color: "black", fontSize: 16 }}
                            />
                        </IconButton>
                    </Link>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{display: { xs: "none", md: "flex" },}}>
                        <Text variant="body2">Already Have An Account?</Text>
                        <Text sx={{ pl: 1 }} variant="body2" color="#1D9BFF">
                            <Link href="/login"> Sign In</Link>
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        mt: {
                            xs: "auto",
                            md: 8,
                        },
                        pl: { xs: 0, md: 4 },
                    }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        sx={{ pb: 2, display: { xs: "none", md: "flex" } }}>
                        <Link>
                            <IconButton>
                                <ArrowBackIcon
                                    sx={{ color: "black", fontSize: 16 }}
                                />
                            </IconButton>
                        </Link>
                    </Stack>
                    <Stack>
                        <Text
                            variant="h3"
                            sx={{
                                mb: { xs: 4, md: "40px" },
                                mt: { xs: 4, md: "auto" },
                                typography: { xs: "subtitle1", md: "h3" },
                            }}>
                            Forgot Password
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        width: "100%",
                        maxWidth: { xs: "auto", md: 400 },
                        height: "100%",
                        alignItems: { xs: "center", md: "flex-start" },
                        justifyContent: { xs: "flex-start", md: "flex-start" },
                        pl: { xs: 0, md: 4 },
                    }}>
                    <Stack
                        sx={{
                            width: "100%",
                        }}
                        alignItems="center"
                        justifyContent="center">
                        {status.isOtpVerified ? (
                            <>
                                <Stack sx={{ width: "100%", mb: 3 }}>
                                    <TextField
                                        error={formError.password}
                                        type="text"
                                        label="New Password"
                                        name="email"
                                        value={formData.password}
                                        helperText={formError.password}
                                        onChange={(e) =>
                                            handleChange({
                                                name: "password",
                                                value: e.target.value,
                                            })
                                        }
                                    />
                                </Stack>
                                <Stack sx={{ width: "100%", mb: 3 }}>
                                    <TextField
                                        error={formError.confirmPassword}
                                        type="password"
                                        label="Confirm Password"
                                        name="email"
                                        value={formData.confirmPassword}
                                        helperText={formError.confirmPassword}
                                        onChange={(e) =>
                                            handleChange({
                                                name: "confirmPassword",
                                                value: e.target.value,
                                            })
                                        }
                                    />
                                </Stack>

                                {status.isOtpSend ? (
                                    <JarvisButton
                                        sx={{
                                            display: "flex",
                                            mb: 7.25,
                                        }}
                                        onClick={handleChangePassword}
                                        loading={isLoading}>
                                        Change Password
                                    </JarvisButton>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <TextField
                                    sx={{ width: "100%", mb: 3 }}
                                    disabled={status.isOtpSend}
                                    error={formError.email}
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    helperText={formError.email}
                                    onChange={(e) =>
                                        handleChange({
                                            name: "email",
                                            value: e.target.value,
                                        })
                                    }
                                />

                                {status.isOtpSend ? (
                                    <Stack
                                        sx={{
                                            display: "block",
                                            width: "100%",
                                            mb: 3,
                                        }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between">
                                            <Text variant="caption1">
                                                Enter OTP sent on
                                                {" " + formData.email}
                                            </Text>
                                            <Text
                                                variant="caption1"
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    alignSelf: "flex-end",
                                                }}>
                                                <Button
                                                    component="span"
                                                    className={
                                                        classes.otpResend
                                                    }
                                                    onClick={handleRequestOtp}
                                                    disabled={
                                                        resendTime > 0
                                                            ? true
                                                            : false
                                                    }
                                                    loading={isLoading}>
                                                    {" "}
                                                    Resend
                                                </Button>

                                                {resendTime > 0
                                                    ? `in 00:${resendTime}`
                                                    : null}
                                            </Text>
                                        </Stack>
                                        <OtpInput
                                            className={classes.otpContainer}
                                            inputStyle={classes.otpInput}
                                            value={formData.otp}
                                            numInputs={6}
                                            onChange={(otp) =>
                                                handleChange({
                                                    name: "otp",
                                                    value: otp,
                                                })
                                            }
                                            isInputNum={true}
                                            separator={<span>&nbsp;</span>}
                                        />
                                    </Stack>
                                ) : null}

                                <Stack
                                    direction="row"
                                    justifyContent={{
                                        xs: "center",
                                        md: "flex-start",
                                    }}
                                    sx={{ width: "100%" }}>
                                    {status.isOtpSend ? (
                                        <JarvisButton
                                            sx={{
                                                display: "flex",
                                                mb: 7.25,
                                            }}
                                            onClick={handleOtpVerification}
                                            loading={isLoading}
                                            disabled={
                                                formData.otp.length === 6
                                                    ? false
                                                    : true
                                            }>
                                            Verifiy OTP
                                        </JarvisButton>
                                    ) : (
                                        <JarvisButton
                                            sx={{
                                                display: "flex",
                                                mb: "60px",
                                            }}
                                            onClick={handleRequestOtp}
                                            loading={isLoading}>
                                            Request OTP
                                        </JarvisButton>
                                    )}
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Stack>
                <Box sx={{ flex: 1, justifyContent: "center" }} />
            </Stack>
        </>
    );
};

export default ForgotPasswordForm;
