import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { investMoreConstants } from "./investMoreConstants";

const initialState = {
    investmentStocks: {},
    loading: {
        investmentStocks: 0,
    },
};

export const investMoreReducers = persistReducer(
    {
        storage,
        key: "investMore",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case investMoreConstants.INVESTMENT_STOCKS_REQUEST: {
                return {
                    ...state,
                    // investmentStocks: initialState.investmentStocks,
                    loading: {
                        ...state.loading,
                        investmentStocks: 1,
                    },
                };
            }

            case investMoreConstants.INVESTMENT_STOCKS_RESPONSE: {
                return {
                    ...state,
                    investmentStocks: payload,
                    loading: {
                        ...state.loading,
                        investmentStocks: 0,
                    },
                };
            }

            case investMoreConstants.RESET_INVEST_MORE_DATA: 
                return initialState;

            default: {
                return state;
            }
        }
    }
);
