import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { customerActions } from "stores/customer/customerActions"
const mapStateToProps = (state) => {
    return {
        // transactionList: state.transaction.transactionList,
       
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setEmailVerified: customerActions.setEmailVerified,

}, dispatch)

const VerifyLinkStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default VerifyLinkStore;