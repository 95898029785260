import React from "react";
import ProfileContainer from "containers/Profile";
import { tracker } from "library/tracker";

const Profile = () => {
  React.useEffect(() => {
      tracker.pageView();
    }, []);
    return <ProfileContainer />;
};

export default Profile;
