import { jarvisRMSExistingHoldingConstants } from "./jarvisRMSExistingHoldingConstants";
const jarvisRMSExistingHoldingAction = {};
jarvisRMSExistingHoldingAction.getPortfolioList = () => ({
    type: jarvisRMSExistingHoldingConstants.EXISTING_HOLDING_SAGA,
});

jarvisRMSExistingHoldingAction.getStockList = ({ portfolioId, isTemp }) => ({
    type: jarvisRMSExistingHoldingConstants.STOCK_LIST_SAGA,
    payload: { portfolioId, isTemp }
});


export { jarvisRMSExistingHoldingAction };
