import _get from "lodash.get";

const jarvisOnestockDashboardParser = {};

jarvisOnestockDashboardParser.onestockDashboardStats = (res) => {
  if (res && res.response) {
    res = res.response;
  }

  if (!res || !Object.keys(res).length) {
    return {};
  }

  let data = {
    closedCalls: _get(res, "closedCalls", null),
    // investedAmount: _get(res, "investedAmount", null),
    openCalls: _get(res, "openCalls", null),
    profitBooked: _get(res, "profitBooked", null),
    profitBookedPercentage: _get(res, "profitBookedPercentage", null),
    totalRecommendations: _get(res, "totalRecommendations", null),
    overAllProfitAveragePercentage: _get(res,"overAllProfitAveragePercentage",null),

    investedAmount: _get(res, "investedAmount",null),
    currentValue: _get(res, "currentValue", null),
    customerTotalReturns : _get(res, "customerTotalReturns", null),
    customerTotalReturnsPercentage:  _get(res, "customerTotalReturnsPercentage", null),
    customerUnrealisedReturnsValue: _get(res,"customerUnrealisedReturnsValue", null),
    customerUnrealisedReturnsPercentage: _get(res,"customerUnrealisedReturnsPercentage", null),
    customerRealisedReturnsValue: _get(res,"customerRealisedReturnsValue", null),
    customerRealisedReturnsPercentage: _get(res,"customerRealisedReturnsPercentage", null),
    executedCalls: _get(res,"customerExecutedCalls", null),

    totalCalls: _get(res,"totalRecommendations", null),
    totalReturnsPercentage: _get(res,"totalReturnsPercentage", null),
    unrealisedReturnsPercentage: _get(res,"unrealisedReturnsPercentage", null),
    realisedReturnsPercentage: _get(res,"realisedReturnsPercentage", null),
  }
  return data;
};

jarvisOnestockDashboardParser.dashboardReveal = (res) => {
  if (res && res.response) {
    res = res.response;
  }

  if (!res || !Object.keys(res).length) {
    return {};
  }

  let data = {
    revealStatus: _get(res, "revealStatus", null),
    revealNote: _get(res, "revealNote", null),
    revealTime: _get(res, "revealTime", null),
    stockId: _get(res, "stockId", null),
    stockSymbol: _get(res, "stockSymbol", null),
    stockName: _get(res, "stockName", null),
    stockExchange: _get(res, "stockExchange", null),
    reason: _get(res, "reason", null),
    marketCap: _get(res, "marketCap", null),
    riskScore: _get(res, "riskScore", null),
    oneYearCAGRPercentage: _get(res, "oneYearCAGRPercentage", null),
    lTP: _get(res, "LTP", null),
    targetPrice: _get(res, "targetPrice", null),
    targetPricePercentage: _get(res, "targetPricePercentage", null),
    technicalScorePercentage: _get(res, "technicalScorePercentage", null),
    fundamentalScorePercentage: _get(res, "fundamentalScorePercentage", null),
    overallAIScorePercentage: _get(res, "overallAIScorePercentage", null),
    sentimentScorePercentage: _get(res, "sentimentScorePercentage", null),
    weekHigh: _get(res, "52WeekHigh", null),
    weekHighPercentage: _get(res, "52WeekHighPercentage", null),
    weekLow: _get(res, "52WeekLow", null),
    weekLowPercentage: _get(res, "52WeekLowPercentage", null),
    oneStockCallId: _get(res, "oneStockCallId", null),
    isin: _get(res,"isin",null)
  }

  return data;
};



export { jarvisOnestockDashboardParser };
