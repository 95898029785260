import PlanAndPricingContainer from "containers/PlanAndPricing";
import React from "react";

const PlanAndPricing = (props) => {
    return (
        <>        
        <PlanAndPricingContainer {...props} />
        </>

    )
}

export default PlanAndPricing;