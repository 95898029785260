import React from "react";
import cx from "classnames";
import { makeStyles, createStyles } from "@mui/styles";

import Text from "components/common/Text";
import List from "components/common/List";
import Stack from "components/common/Stack";
import ListItemIcon from "components/common/ListItemIcon";
import ListItemText from "components/common/ListItemText";
import ListItemButton from "components/common/ListItemButton";

import { CircleOutlined as CircleIcon, CheckCircle as CheckIcon } from "@mui/icons-material";

const RiskAssessmentQuestionBox = ({ question, name, options = [], onChange, value }) => {
    const classes = useStyles();

    return (
        <Stack alignItems="flex-start" sx={{ flex: 1, mb: { xs: 5, md: "unset" } }}>
            <Text className={classes.questionText}>{question}</Text>
            <List sx={{ width: "100%", maxWidth: { md: 360 } }}>
                {options.map((option, index) => (
                    <ListItemButton
                        key={`option_${index}`}
                        classes={{
                            root: cx({ [classes.listItemRoot]: true, [classes.listItemRootActive]: value === option.id })
                        }}
                        onClick={() => onChange({ name: name, value: option.id })}
                    >
                        <ListItemText
                            primary={option.name}
                            classes={{
                                primary: cx({ [classes.listItemPrimaryText]: true, [classes.listItemPrimaryTextActive]: value === option.id })
                            }}
                        />
                        <ListItemIcon
                            classes={{
                                root: cx({ [classes.listItemIconRoot]: true, [classes.listItemIconRootActive]: value === option.id })
                            }}
                        >
                            {value === option.id ? (
                                <CheckIcon sx={{ fontSize: 20 }} />
                            ) : (
                                <CircleIcon sx={{ fontSize: 20 }} />
                            )}
                        </ListItemIcon>
                    </ListItemButton>
                ))}
            </List>
        </Stack>
    )
}

const useStyles = makeStyles((theme) => createStyles({
    questionText: {
        marginBottom: 16,
        fontSize: `${theme.typography.body2.fontSize} !important`,
        fontWeight: 500,
        [theme.breakpoints.up("md")]: {
            fontSize: `${theme.typography.body2.fontSize} !important`,
        },
    },

    listItemRoot: {
        borderRadius: 20,
        marginBottom: 10,
        backgroundColor: theme.palette.background.paper,
        paddingLeft: 20,
        paddingRight: 20,
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 32,
            paddingRight: 32,
        },
    },
    listItemRootActive: {
        backgroundColor: theme.palette.grey[100],
        borderColor: theme.palette.primary.main,
    },

    listItemIconRoot: {
        minWidth: 20,
        marginLeft: 32,
        color: theme.palette.grey[400],
    },
    listItemIconRootActive: {
        color: theme.palette.primary.main,
    },

    listItemPrimaryText: {
        fontSize: `${theme.typography.small.fontSize} !important`,
        color: "#252C32 !important",
        lineHeight: 1.15,
        [theme.breakpoints.up("md")]: {
            fontSize: `${theme.typography.body2.fontSize} !important`,
        },
    },
    listItemPrimaryTextActive: {
    },
}));

export default RiskAssessmentQuestionBox;