import { call, put, takeLatest } from "@redux-saga/core/effects";
import {jarvisOnestockActionCenterConstants} from "./jarvisOnestockActionCenterConstants";
import {jarvisOnestockActionCenterApis} from "./jarvisOnestockActionCenterApis";
import {jarvisOnestockActionCenterParsers} from "./jarvisOnestockActionCenterParsers";

function* getOnestockActionCenterDataSaga() {
   try {
      yield put({ type: jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_REQUEST });
      const res = yield call(jarvisOnestockActionCenterApis.getOnestockActionCenterData);
      const payload = jarvisOnestockActionCenterParsers.getOnestockActionCenterDataParser(res);
      yield put({
          type: jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_RESPONSE,
          payload,
      });
  } catch (error) {
      yield put({
          type: jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_RESPONSE,
          payload: [],
      });
  }
}

export function* registerJarvisOnestockActionCenterSagas() {
	yield takeLatest(
		jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_SAGA,
		getOnestockActionCenterDataSaga
	);
}
