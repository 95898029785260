import React, { Component } from "react";
import _get from "lodash.get";

import Text from "components/common/Text";
import Loader from "components/common/Loader";
import Modal from "components/common/Modal";
import Stack from "components/common/Stack";
import Image from "components/common/Image";
import TextField from "components/common/TextField";

import JarvisButton from "components/JarvisButton";
import StockCard from "components/StockCard";

import { config } from "config";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import { thirdpartyParsers } from "stores/thirdparty/thirdpartyParsers";
import { validationHelper } from "helper/validation";

import { withRouter } from "react-router-dom";

import { localStore } from "stores/localStorage";

//tracker
import { tracker } from "library/tracker";

let inAppBrowserRef;
class BrokerMastertrustContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // auth related keys
      isAuthLoading: false,
      authFormModalOpen: false,
      reloginAlertModalOpen: false,
      reloginMessage: "",
      authFormData: {
        username: "",
        password: "",
        verificationCode: "",
      },
      authFormError: {
        username: "",
        password: "",
        verificationCode: "",
      },
      // stocks data related keys
      processDataModalOpen: false,
      isOrderLoading: false,
      isOrderPlaced: false,
      processData: {
        brokerName: "",
        brokerPlatform: "",
        processType: "",
        productCode: "",
        brokerId: "",
      },
    };
  }

  componentDidMount() {
    this.checkLogin();
  }

  async componentWillUnmount() {
    if (inAppBrowserRef) {
      inAppBrowserRef.removeEventListener(
        "beforeload",
        this.handleInAppBrowser
      );
      inAppBrowserRef.removeEventListener("loaderror", this.handleInAppBrowser);
      inAppBrowserRef.removeEventListener("exit", this.handleInAppBrowser);
    }
  }

  checkLogin = async () => {
    this.setState({ isLoading: true });

    const brokerName = this.props.getValueFromLocation("brokerName");
    const brokerPlatform = this.props.getValueFromLocation("brokerPlatform");
    const processType = this.props.getValueFromLocation("processType");
    const status = this.props.getValueFromLocation("status");
    const authCode = this.props.getValueFromLocation("authCode");
    const productCode = this.props.getValueFromLocation("productCode");
    const brokerId = this.props.getValueFromLocation("brokerId");

    switch (status) {
      case "0":
      case 0:
        localStore.setWentBrokerPage(false);
        this.props.onError({
          reason: "Something went wrong! Please try again later!",
          origin: "compomentDidMount > Status fail",
        });
        break;
      case "1":
      case 1:
        localStore.setWentBrokerPage(false);
        this.setState({
          processData: {
            brokerName,
            brokerPlatform,
            processType,
            productCode,
            brokerId,
          },
        });

        // generate access token with auth token
        const { status: valid, message } =
          await thirdpartyApis.brokergenerateAccessToken({
            authCode,
            brokerPlatform,
            brokerName,
          });

        if (valid) {
          await this.initOrderProcess();
        } else {
          this.setState({
            reloginMessage: message,
            reloginAlertModalOpen: true,
            isLoading: false,
          });
        }
        break;
      default:
        let fromBrokerPage = localStore.getWentBrokerPage();
        if (fromBrokerPage) {
          localStore.setWentBrokerPage(false);
          this.props.onCancel();
        } else {
          this.setProcessData();
        }
    }
  };

  // Step 1 - set process Data from location
  setProcessData = () => {
    const brokerName = this.props.getValueFromLocation("brokerName");
    const brokerPlatform = this.props.getValueFromLocation("brokerPlatform");
    const processType = this.props.getValueFromLocation("processType");
    const productCode = this.props.getValueFromLocation("productCode");
    const brokerId = this.props.getValueFromLocation("brokerId");

    if (brokerName || brokerPlatform || processType) {
      this.setState(
        {
          processData: {
            brokerName,
            brokerPlatform,
            processType,
            productCode,
            brokerId,
          },
        },
        () => {
          this.initProcess();
        }
      );
    } else {
      this.props.onError({
        reason: "No Broker selected",
        origin:
          "componentDidMount > not found = brokerName | brokerPlatform | processType",
      });
    }
  };

  handleInAppBrowser = async (eventType, e) => {
    const inAppClose = (event) => {
      if (Object.keys(event).includes("url")) {
        localStore.setWentBrokerPage(false);
      }
      let fromBrokerPage = localStore.getWentBrokerPage();

      if (event && event.type === "exit" && fromBrokerPage) {
        this.props.onCancel();
      } else {
        inAppBrowserRef.close();
      }
    };

    switch (eventType) {
      case "loaderror":
        inAppClose(e);
        break;
      case "exit":
        inAppClose(e);
        break;
    }

    if (e && Object.keys(e).includes("url")) {
      let urlParam = new URLSearchParams(e.url.slice(e.url.indexOf("?") + 1));
      let status = urlParam.get("status");
      let authCode = urlParam.get("authCode");
      let brokerName = urlParam.get("brokerName");
      let brokerPlatform = urlParam.get("brokerPlatform");
      let processType = urlParam.get("processType");

      this.setState({
        processData: { brokerName, brokerPlatform, processType },
      });
      let fromBrokerPage = localStore.getWentBrokerPage();
      if (status && fromBrokerPage) {
        inAppBrowserRef.close();
        localStore.setWentBrokerPage(false);
        const { status: valid, message } =
          await thirdpartyApis.brokergenerateAccessToken({
            authCode,
            brokerPlatform,
            brokerName,
          });

        if (valid) {
          await this.initOrderProcess();
        } else {
          this.setState({
            reloginMessage: message,
            reloginAlertModalOpen: true,
            isLoading: false,
          });
        }
      }
    }
  };

  // Step 2 - Check if broker login
  initProcess = async () => {
    try {
      const { brokerName, brokerPlatform, processType, productCode, brokerId } =
        this.state.processData;

      this.setState({ isLoading: true });
      const prevTokenResponse = await thirdpartyApis.brokerPreviousAccessToken({
        brokerPlatform,
        productCode,
        brokerId,
      });
      const { valid } = thirdpartyParsers.brokerPreviousAccessToken(
        prevTokenResponse,
        productCode
      );

      if (!valid) {
        const preLogin = await thirdpartyApis.brokerPreLogin({
          brokerName,
          brokerPlatform,
          processType,
        });
        const brokerLoginUrl = _get(
          preLogin.response,
          "broker_login_page_url",
          ""
        );
        const customerId = this.props.loginProfile.customerId;
        const callBackUrl = `${window.location.origin}/customer/broker`;
        if (!customerId) {
          this.props.onError({
            reason: "Customer id not found",
            origin: "initProcess > Customer id not found",
          });
          return;
        }
        this.setState({ isLoading: false });

        const redirectTo = `${
          config.broker.masterTrustbrokerRedirectUrl
        }?brokerName=${brokerName}&brokerPlatform=${brokerPlatform}&processType=${processType}&customerId=${customerId}&callBackUrl=${callBackUrl}&brokerLoginUrl=${encodeURIComponent(
          brokerLoginUrl
        )}`;

        localStore.setWentBrokerPage(true);
        if (config.isMobileApp) {
          const option = `location=no,closebuttoncaption=Close,closebuttoncolor=#ffffff,footercolor=#2B70C5,zoom=no`;
          inAppBrowserRef = window.cordova.InAppBrowser.open(
            redirectTo,
            "_blank",
            option
          );
          inAppBrowserRef.addEventListener("loadstart", (e) =>
            this.handleInAppBrowser("loadstart", e)
          );
          inAppBrowserRef.addEventListener("beforeload", (e) =>
            this.handleInAppBrowser("beforeload", e)
          );
          inAppBrowserRef.addEventListener("loaderror", (e) =>
            this.handleInAppBrowser("loaderror", e)
          );
          inAppBrowserRef.addEventListener("exit", (e) =>
            this.handleInAppBrowser("exit", e)
          );
        } else {
          window.location.href = redirectTo;
        }
      } else {
        await this.initOrderProcess();
      }
    } catch (error) {
      this.props.onError({
        reason: "Something went wrong! Please try again later!",
        origin: "initProcess > catch block",
        error,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  // Step 4 - After login init order process
  initOrderProcess = async () => {
    const { processData } = this.state;
    //NOTE : event_tracker => broker
    tracker.broker.brokerStockList({ broker_name: processData.brokerName });

    const brokerName = this.props.getValueFromLocation("brokerName");
    const brokerPlatform = this.props.getValueFromLocation("brokerPlatform");
    const processType = this.props.getValueFromLocation("processType");
    const productCode = this.props.getValueFromLocation("productCode");
    if (!brokerName) {
      brokerName = processData.brokerName;
    }
    if (!brokerPlatform) {
      brokerPlatform = processData.brokerPlatform;
    }
    if (!processType) {
      processType = processData.processType;
    }

    if (productCode === config.productCodes.RMS) {
      alert("Broker not integrated");
      return;
    }

    const preOrderData = await this.props.getPreOrderData({
      brokerName,
      brokerPlatform,
      processType,
    });
    //NOTE : event_tracker => broker
    tracker.broker.brokerStockListSuccess({
      broker_name: processData.brokerName,
    });

    if (!preOrderData) {
      //NOTE : event_tracker => broker
      tracker.broker.brokerStockListFailed({
        broker_name: processData.brokerName,
        error_msg: "",
      });
      return;
    }
    this.setState({ isLoading: false });

    this.setState({ processDataModalOpen: true });
    this.handleAuthFormModalClose({ process: true });
  };

  // close Stock list modal
  handleOrderdataModalClose = () => {
    this.setState({
      processDataModalOpen: false,
    });
    this.props.onCancel();
  };

  // handle auth form field value change
  handleAuthFormChange = ({ name, value }) => {
    const { authFormData } = this.state;
    authFormData[name] = value;
    this.setState({ authFormData });
  };

  // handle auth form validation
  handleAuthFormValidation = () => {
    const { authFormData, authFormError } = this.state;

    const usernameValidation = validationHelper.required(authFormData.username);
    authFormError.username = usernameValidation.message;

    const passwordValidation = validationHelper.required(authFormData.password);
    authFormError.password = passwordValidation.message;

    const verificationCodeValidation = validationHelper.required(
      authFormData.verificationCode
    );
    authFormError.verificationCode = verificationCodeValidation.message;

    this.setState({ authFormError });
    return (
      usernameValidation.isValid &&
      passwordValidation.isValid &&
      verificationCodeValidation.isValid
    );
  };

  // Step 3 - Broker Login submit
  handleAuthFormSubmit = async () => {
    //NOTE : event_tracker => borker
    tracker.broker.brokerLogin({ broker_name: "" });
    if (!this.handleAuthFormValidation()) {
      return;
    }

    const { authFormData, processData } = this.state;
    this.setState({ isAuthLoading: true });

    try {
      await thirdpartyApis.brokerLogin({
        brokerId: processData.brokerId,
        productCode: processData.productCode,
        brokerPlatform: processData.brokerPlatform,
        brokerName: processData.brokerName,
        username: authFormData.username,
        password: authFormData.password,
        verificationCode: authFormData.verificationCode,
      });
      //NOTE : event_tracker => borker
      tracker.broker.brokerLoginSuccess({
        broker_name: processData.brokerName,
      });
      await this.initOrderProcess();
    } catch (error) {
      //NOTE : event_tracker => borker
      tracker.broker.brokerLoginFailed({
        broker_name: processData.brokerName,
        error_msg: error.message,
      });
      this.props.onError({
        reason: "Something went wrong! Please try again later!",
        origin: "handleAuthFormSubmit > catch block",
        error,
      });
    } finally {
      this.setState({ isAuthLoading: false });
    }
  };

  // Clear Broker login from
  handleAuthFormModalClose = ({ process } = {}) => {
    this.setState({
      authFormModalOpen: false,
      authFormData: {
        username: "",
        password: "",
        verificationCode: "",
      },
      authFormError: {
        username: "",
        password: "",
        verificationCode: "",
      },
    });
    if (!process) {
      this.props.history.goBack();
    }
  };

  // Step 5 - Order place/ check status/ finish [onSuccess()]
  handleOrderProcessClick = async (action) => {
    const { processData } = this.state;

    let brokerName = processData.brokerName;
    let brokerPlatform = processData.brokerPlatform;
    let processType = processData.processType;

    const { updateStocksInPreOrder, preOrderData, setPostOrderData } =
      this.props;
    if (action === "PLACE_ORDER") {
      //NOTE : event_tracker => broker
      tracker.broker.brokerPlaceNewOrder({ broker_name: brokerName });
      this.setState({ isOrderLoading: true });
      try {
        const placeOrderResponse = await thirdpartyApis.brokerPlaceOrder({
          brokerPlatform: brokerPlatform,
          brokerName: brokerName,
          type: processType,
        });

        const placeOrderParsed =
          thirdpartyParsers.brokerPlaceOrder(placeOrderResponse);

        setPostOrderData({
          totalStocks: placeOrderParsed.totalStocks,
          completedStocks: placeOrderParsed.completedStocks,
        });
        updateStocksInPreOrder(placeOrderParsed.list);
        this.setState({ isOrderPlaced: true });
        //NOTE : event_tracker => broker
        tracker.broker.brokerPlaceNewOrdersuccess({ broker_name: brokerName });
      } catch (error) {
        //NOTE : event_tracker => broker
        tracker.broker.brokerPlaceNewOrderFailed({
          broker_name: brokerName,
          error_msg: error.message,
        });
        this.props.onError({
          reason: "Something went wrong! Please try again later!",
          origin: "handleOrderProcessClick > PLACE_ORDER",
          error,
        });
      } finally {
        this.setState({ isOrderLoading: false });
      }
      return;
    }

    if (action === "CHECK_ORDER_STATUS") {
      //NOTE : event_tracker => broker
      tracker.broker.checkOrderStatus({ broker_name: processData.brokerName });
      this.setState({ isOrderLoading: true });
      try {
        const stocksSymbol = preOrderData.stockDetails.map((s) => s.symbol);

        const checkOrderStatusResponse =
          await thirdpartyApis.brokerCheckOrderStatus({
            brokerPlatform: processData.brokerPlatform,
            brokerName: processData.brokerName,
            type: processData.processType,
            stocks: stocksSymbol,
          });

        const checkOrderStatusParsed = thirdpartyParsers.brokerPlaceOrder(
          checkOrderStatusResponse
        );
        //NOTE : event_tracker => broker
        tracker.broker.checkOrderStatusSuccess({
          broker_name: processData.brokerName,
        });
        setPostOrderData({
          totalStocks: checkOrderStatusParsed.totalStocks,
          completedStocks: checkOrderStatusParsed.completedStocks,
        });
        updateStocksInPreOrder(checkOrderStatusParsed.list);
      } catch (error) {
        //NOTE : event_tracker => broker
        tracker.broker.checkOrderStatusFailed({
          broker_name: processData.brokerName,
        });
        // No need of error logging
      } finally {
        this.setState({ isOrderLoading: false });
      }
      return;
    }

    if (action === "FINISH_ORDER") {
      //NOTE : event_tracker => broker
      tracker.broker.finish({ broker_name: processData.brokerName });
      const isRejected = preOrderData.stockDetails.every(
        (sd) => sd.status == "rejected"
      );

      this.setState({ processDataModalOpen: false }, () => {
        if (isRejected) {
          this.props.onError({
            reason: "Order has been rejected by platform.",
          });
          return;
        }
        this.props.onSuccess();
        //NOTE : event_tracker => broker
        tracker.broker.finishSuccess({ broker_name: processData.brokerName });
      });
    }
  };

  handleReloginAlertClose = ({ proceedRelogin = false }) => {
    this.setState({ reloginAlertModalOpen: false });
    if (proceedRelogin) {
      this.setProcessData();
    } else {
      this.props.onCancel();
    }
  };

  renderReloginAlert() {
    let {
      processData: { brokerName },
      reloginMessage,
    } = this.state;

    if (!brokerName) {
      brokerName = this.props.getValueFromLocation("brokerName");
    }
    const imageSrc = _get(config.broker, `[${brokerName}].logoUrl`, "");

    return (
      <Stack alignItems="center" justifyContent="center">
        <Stack justifyContent="center">
          <Image src={imageSrc} alt={brokerName} height="auto" width={120} />
        </Stack>
        <Text sx={{ textAlign: "center", p: "30px 0px" }}>
          {reloginMessage || "Unable to generate access token"}
        </Text>
        <Stack>
          <JarvisButton
            onClick={() =>
              this.handleReloginAlertClose({ proceedRelogin: true })
            }
          >
            Relogin
          </JarvisButton>
        </Stack>
      </Stack>
    );
  }

  render() {
    let {
      isLoading,
      isOrderLoading,
      isOrderPlaced,
      authFormModalOpen,
      processDataModalOpen,
      reloginAlertModalOpen,
      processData: { brokerName },
    } = this.state;
    const { preOrderData } = this.props;
    if (!brokerName) {
      brokerName = this.props.getValueFromLocation("brokerName");
    }

    return (
      <>
        <Loader loading={isLoading} />

        <Text>
          {brokerName
            ? `Selected broker: ${brokerName.toUpperCase()}`
            : "No Broker Selected"}
        </Text>

        {/* relogin alert popup */}
        <Modal
          disableExcapeKeyDown={true}
          size="xs"
          open={reloginAlertModalOpen}
          onClose={this.handleReloginAlertClose}
        >
          {this.renderReloginAlert()}
        </Modal>

        <Modal
          disableClose={isOrderPlaced || isOrderLoading}
          disableExcapeKeyDown={true}
          title="Order Data"
          size="lg"
          open={processDataModalOpen}
          onClose={this.handleOrderdataModalClose}
        >
          <Stack
            direction="row"
            alignItems="stretch"
            justifyContent="flex-start"
            sx={{ position: "relative", flexWrap: "wrap" }}
          >
            {preOrderData.stockDetails.map((sd, i) => (
              <Stack
                key={`stock_s_${i}`}
                sx={{ flexGrow: 0, width: { xs: 1, sm: 0.5 } }}
              >
                <StockCard
                  sx={{ border: `1px solid #e2e2e2` }}
                  stockDetails={sd}
                  cardType="SARAL"
                />
              </Stack>
            ))}
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="right"
            width={1}
            mt={3}
            spacing={2}
          >
            {isOrderPlaced ? (
              <>
                <JarvisButton
                  loading={isOrderLoading}
                  disableEndIcon
                  variant={"outlined"}
                  onClick={() =>
                    this.handleOrderProcessClick("CHECK_ORDER_STATUS")
                  }
                >
                  Check Order Status
                </JarvisButton>
                <JarvisButton
                  loading={isOrderLoading}
                  disableEndIcon
                  onClick={() => this.handleOrderProcessClick("FINISH_ORDER")}
                >
                  Finish
                </JarvisButton>
              </>
            ) : (
              <JarvisButton
                loading={isOrderLoading}
                onClick={() => this.handleOrderProcessClick("PLACE_ORDER")}
              >
                Place Order Now
              </JarvisButton>
            )}
          </Stack>
        </Modal>
      </>
    );
  }
}

export default withRouter(React.memo(BrokerMastertrustContainer));
