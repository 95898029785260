// import { appsFlyerTracker } from "../appsFlyer";
// import { mixpanelTracker } from "../mixpanel";
// import { uxcamTracker } from "../uxcam";

const actionCenterInvestMoreTracker = {};

actionCenterInvestMoreTracker.portfolioViewed = () => {
    // const eventName = "im_portfolio_viewed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });
    
    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.portfolioGenerationFailed = ({ error_msg }) => {
    // const eventName = "im_portfolio_generation_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

actionCenterInvestMoreTracker.portfolioGenerationSuccess = () => {
    // const eventName = "im_portfolio_generation_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.proceed = () => {
    // const eventName = "im_proceed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.proceedSuccess = () => {
    // const eventName = "im_proceed_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.notePopup = () => {
    // const eventName = "im_note_pop-up";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.continue = () => {
    // const eventName = "im_continue";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.continueFailed = ({ error_msg }) => {
    // const eventName = "im_continue_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.continueSuccess = () => {
    // const eventName = "im_continue_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.borkerPage = () => {
    // const eventName = "im_broker_page";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.selectBorkerFailed = ({ error_msg }) => {
    // const eventName = "select_broker_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

actionCenterInvestMoreTracker.selectBorkerSuccess = () => {
    // const eventName = "select_broker_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    // });
};

export { actionCenterInvestMoreTracker };
