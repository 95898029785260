import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction"

const mapStateToProps = (state) => {
    return {
        loginProfile: state.customer.loginProfile,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOnboardingDataAction: onboardingActions.getOnboardingData,
    getRMSOnboardingDataAction: jarvsiRMSOnboardingActions.getOnboardingData,
}, dispatch);

const BrokerStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);

export default BrokerStore;
