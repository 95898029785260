import { axios } from "helper/axios";
import { jarvisOnestockExistingHoldingConstants } from "./jarvisOnestockExistingHoldingConstants";

const jarvisOnestockExistingHoldingApis = {};

jarvisOnestockExistingHoldingApis.getExistingHoldingApi = () => {
	return axios.get(
		jarvisOnestockExistingHoldingConstants.ONESTOCK_EXISTING_HOLDING_API
	);
};

export { jarvisOnestockExistingHoldingApis };
