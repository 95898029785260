import { config } from "config";
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";

import { dataHelper } from "helper/data";
import { Capacitor } from "@capacitor/core";

const pushNotification = {};

pushNotification.init = async () => {
  // we want only in mobile
  // for now we will use only for android
  if (!config.isMobileApp) {
    return false;
  }

  // external required step
  // register for push
  await PushNotifications.requestPermissions();
  await PushNotifications.register();
  pushNotification.received();
};

pushNotification.received = async () => {
  await PushNotifications.addListener(
    "pushNotificationReceived",
    (notification) => {
      console.log("Push notification received: ", notification);
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      console.log(
        "Push notification action performed",
        notification.actionId,
        notification.inputValue
      );
    }
  );
};

pushNotification.getToken = async (cb) => {
  // we want only in mobile
  // for now we will use only for android
  if (!config.isMobileApp) {
    cb(dataHelper.generateUniqueId());
    return;
  }
  
  PushNotifications.addListener("registration", async ({ value }) => {
    let token = value; // Push token for Android, will not work for IOS

    let platform = Capacitor.getPlatform();
    // Get FCM token instead the APN one returned by Capacitor
    if (platform === "ios") {
      const { token: fcm_token } = await FCM.getToken();
      token = fcm_token; // Token for IOS device.
    }
    console.log("testing Platform ", platform, "== Token == ", token," </END>");
    // Work with FCM_TOKEN

    cb(token);
  }).catch((e) => {
    alert("reqPerm" + e);
  });
};

export { pushNotification };
