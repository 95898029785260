import { call, put, takeLatest } from "@redux-saga/core/effects";
import { dashboardApis } from "./dashboardApis";
import { dashboardParsers } from "./dashboardParsers";
import { dashboardConstants } from "./dashboardConstants";
import { rmsConstants } from "stores/rms/rmsConstants";
import { tracker } from "library/tracker";

function* portfolioAllocationSaga({ type, payload }) {
  try {
    yield put({ type: dashboardConstants.PORTFOLIO_ALLOCATIONS_REQUEST });
    const res = yield call(dashboardApis.getPortfolioAllocations);

    const data = dashboardParsers.portfolioAllocation(res);
    let count = 0;
    if (res.count) {
      count = res.count;
    }

    yield put({
      type: dashboardConstants.PORTFOLIO_ALLOCATIONS_RESPONSE,
      payload: { data, count },
    });
  } catch (error) {
    yield put({
      type: dashboardConstants.PORTFOLIO_ALLOCATIONS_RESPONSE,
      payload: { data: [], count: 0 },
    });
  }
}

function* portfolioOverviewSaga({ type, payload }) {
  try {
    yield put({ type: dashboardConstants.PORTFOLIO_OVERVIEW_REQUEST });
    const res = yield call(dashboardApis.getPortfolioOverview);

    const payload = dashboardParsers.portfolioOverview(res);
    yield put({
      type: dashboardConstants.PORTFOLIO_OVERVIEW_RESPONSE,
      payload,
    });
  } catch (error) {
    yield put({
      type: dashboardConstants.PORTFOLIO_OVERVIEW_RESPONSE,
      payload: {},
    });
  }
}

function* portfolioPerformanceSaga({ type }) {
  try {
    yield put({ type: dashboardConstants.PORTFOLIO_PERFORMANCE_REQUEST });
    const res = yield call(dashboardApis.getPortfolioPerformance);
    const payload = dashboardParsers.portfolioPerformance(res);
    yield put({
      type: dashboardConstants.PORTFOLIO_PERFORMANCE_RESPONSE,
      payload,
    });
  } catch (error) {
    yield put({
      type: dashboardConstants.PORTFOLIO_PERFORMANCE_RESPONSE,
      payload: {},
    });
  }
}

function* portfolioChartSaga({ type }) {
  try {
    yield put({ type: dashboardConstants.PORTFOLIO_CHART_REQUEST });
    const res = yield call(dashboardApis.getPortfolioChart);
    const payload = dashboardParsers.portfolioChart(res);
    yield put({ type: dashboardConstants.PORTFOLIO_CHART_RESPONSE, payload });
  } catch (error) {
    yield put({
      type: dashboardConstants.PORTFOLIO_CHART_RESPONSE,
      payload: {},
    });
  }
}

function* portfolioSectorSaga({ type }) {
  try {
    yield put({ type: dashboardConstants.PORTFOLIO_SECTOR_REQUEST });
    const res = yield call(dashboardApis.getPortfolioSector);
    const payload = dashboardParsers.portfolioSector(res);
    yield put({ type: dashboardConstants.PORTFOLIO_SECTOR_RESPONSE, payload });
  } catch (error) {
    yield put({
      type: dashboardConstants.PORTFOLIO_SECTOR_RESPONSE,
      payload: [],
    });
  }
}

function* portfolioMarketSaga({ type }) {
  try {
    yield put({ type: dashboardConstants.PORTFOLIO_MARKET_REQUEST });
    const res = yield call(dashboardApis.getPortfolioMarket);
    const payload = dashboardParsers.portfolioMarket(res);
    yield put({ type: dashboardConstants.PORTFOLIO_MARKET_RESPONSE, payload });
  } catch (error) {
    yield put({
      type: dashboardConstants.PORTFOLIO_MARKET_RESPONSE,
      payload: [],
    });
  }
}

function* customerStoriesListSaga() {
  try {
    yield put({ type: dashboardConstants.CUSTOMER_STORIES_REQUEST });
    const res = yield call(dashboardApis.getCustomerStoriesList);
    const payload = dashboardParsers.customerStoryList(res);
    yield put({ type: dashboardConstants.CUSTOMER_STORIES_RESPONSE, payload });
  } catch (error) {
    yield put({
      type: dashboardConstants.CUSTOMER_STORIES_RESPONSE,
      payload: [],
    });
  }
}

function* welcomeVideoListSaga() {
  try {
    yield put({ type: dashboardConstants.WELCOME_VIDEO_REQUEST });
    const res = yield call(dashboardApis.getWelcomeVideoList);
    const payload = dashboardParsers.welcomeVideoList(res);
    yield put({ type: dashboardConstants.WELCOME_VIDEO_RESPONSE, payload });
  } catch (error) {
    yield put({ type: dashboardConstants.WELCOME_VIDEO_RESPONSE, payload: [] });
  }
}

function* portfolioAlertSaga() {
  try {
    yield put({ type: dashboardConstants.PORTFOLIO_ALERT_REQUEST });
    const res = yield call(dashboardApis.getPortfolioAlert);
    const payload = dashboardParsers.portfolioAlert(res);
    yield put({ type: dashboardConstants.PORTFOLIO_ALERT_RESPONSE, payload });
  } catch (error) {
    yield put({
      type: dashboardConstants.PORTFOLIO_ALERT_RESPONSE,
      payload: {},
    });
  }
}

function* portfolioPendingTransactionSaga() {
  try {
    yield put({
      type: dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_REQUEST,
    });

    
    const res = yield call(dashboardApis.getPortfolioPendingTransaction);
    //event-tracker: Partial Buy/Sell -> invest_more_now_success
    tracker.partialBuySell.investMoreNowSuccess();

    const payload = dashboardParsers.portfolioPendingTransaction(res);

    yield put({
      type: dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_RESPONSE,
      payload,
    });
  } catch (error) {
    //event-tracker: Partial Buy/Sell -> invest_more_now_failed
    tracker.partialBuySell.investMoreNowFailed({error_msg:error.message});
    yield put({
      type: dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_RESPONSE,
      payload: {},
    });
  }
}

function* dashboardRefreshSaga(){
  yield put({type: rmsConstants.RESET_RMS_DATA});
  yield put({type: dashboardConstants.PORTFOLIO_MARKET_SAGA});
  yield put({type: dashboardConstants.PORTFOLIO_CHART_SAGA});
  yield put({type: dashboardConstants.PORTFOLIO_ALLOCATIONS_SAGA});
  yield put({type: dashboardConstants.PORTFOLIO_PERFORMANCE_SAGA});
  yield put({type: dashboardConstants.PORTFOLIO_SECTOR_SAGA});
  yield put({type: dashboardConstants.PORTFOLIO_OVERVIEW_SAGA});
  yield put({type: dashboardConstants.PORTFOLIO_ALERT_SAGA});
}

export function* registerDashboardSagas() {
  yield takeLatest(
    dashboardConstants.PORTFOLIO_ALLOCATIONS_SAGA,
    portfolioAllocationSaga
  );
  yield takeLatest(
    dashboardConstants.PORTFOLIO_OVERVIEW_SAGA,
    portfolioOverviewSaga
  );
  yield takeLatest(
    dashboardConstants.PORTFOLIO_PERFORMANCE_SAGA,
    portfolioPerformanceSaga
  );
  yield takeLatest(dashboardConstants.PORTFOLIO_CHART_SAGA, portfolioChartSaga);
  yield takeLatest(
    dashboardConstants.PORTFOLIO_SECTOR_SAGA,
    portfolioSectorSaga
  );
  yield takeLatest(
    dashboardConstants.PORTFOLIO_MARKET_SAGA,
    portfolioMarketSaga
  );

  yield takeLatest(
    dashboardConstants.CUSTOMER_STORIES_SAGA,
    customerStoriesListSaga
  );
  yield takeLatest(dashboardConstants.WELCOME_VIDEO_SAGA, welcomeVideoListSaga);
  yield takeLatest(dashboardConstants.PORTFOLIO_ALERT_SAGA, portfolioAlertSaga);
  yield takeLatest(
    dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_SAGA,
    portfolioPendingTransactionSaga
  );

  yield takeLatest(
    dashboardConstants.DASHBOARD_REFRESH_SAGA,
    dashboardRefreshSaga
  );
}
