import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { onboardingConstants } from "./onboardingConstants";

const initialState = {
    currentStage: 0,
    riskMasterId: null,
    riskProfileName: null,
    suggestedRiskId: null,
    investmentAmount: null,
    investmentTenure: null,
    investmentStrategy: null,
    isOnboardingCompleted: 0,
    isPaymentCompleted: 0,
    minInvestmentAmount: 30000,
    minimumAdditionalInvestAmount: 10000,
    portfolioGeneratedDate: null,
    hasOnboardingInvestmentDetailsConfirmed: 0,
    expiryDate: null,
    dateOfBirth: null,
    pan: null,
    planId: null,
    customerSubscriptionId: null,
    isMobileVerified: null,

    portfolioData: [],
    marketBreakupData: [],
    sectorBreakupData: [],
    pricingData: {},

    isInitialFetched: 0,
    loading: {
        onboardingData: 0,
        portfolioData: 0,
        pricingData: 0,
    },
};

export const onboardingReducers = persistReducer(
    {
        storage,
        key: "onboarding",
        blacklist: [
            "portfolioData",
            "marketBreakupData",
            "sectorBreakupData",
            "pricingData",
            "loading",
			"rmsOnboarding",  
			"rmsOnboardingSteps",
            "isInitialFetched",
            "isMobileVerified",
        ],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case onboardingConstants.UPDATE_ONBOARDING_DATA: {
                return {
                    ...state,
                    currentStage: payload.currentStage || state.currentStage,
                    riskMasterId: payload.riskMasterId || state.riskMasterId,
                    riskProfileName: payload.riskProfileName || state.riskProfileName,
                    suggestedRiskId: payload.suggestedRiskId || state.suggestedRiskId,
                    investmentAmount:
                        payload.investmentAmount || state.investmentAmount,
                    investmentTenure:
                        payload.investmentTenure || state.investmentTenure,
                    investmentStrategy: [undefined, null].includes(
                        payload.investmentStrategy
                    )
                        ? state.investmentStrategy
                        : payload.investmentStrategy,
                    isOnboardingCompleted:
                        payload.isOnboardingCompleted ||
                        state.isOnboardingCompleted,
                    isPaymentCompleted:
                        payload.isPaymentCompleted || state.isPaymentCompleted,
                    minInvestmentAmount:
                        payload.minInvestmentAmount ||
                        state.minInvestmentAmount,
                    minimumAdditionalInvestAmount:
                        payload.minimumAdditionalInvestAmount ||
                        state.minimumAdditionalInvestAmount,
                    portfolioGeneratedDate:
                        payload.portfolioGeneratedDate ||
                        state.portfolioGeneratedDate,
                    expiryDate: payload.expiryDate || state.expiryDate,
                    isInitialFetched:
                        payload.isInitialFetched || state.isInitialFetched,
                    planId: payload.planId || state.planId,
                    hasOnboardingInvestmentDetailsConfirmed:
                        payload.hasOnboardingInvestmentDetailsConfirmed || state.hasOnboardingInvestmentDetailsConfirmed,
                    customerSubscriptionId: payload.customerSubscriptionId || state.customerSubscriptionId,
                    isMobileVerified: payload.isMobileVerified || state.isMobileVerified,
                };
            }

            case onboardingConstants.ONBOARDING_DATA_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        onboardingData: 1,
                    },
                };
            }

            case onboardingConstants.ONBOARDING_DATA_RESPONSE: {
                return {
                    ...state,
                    isInitialFetched: 1,
                    currentStage: payload.currentStage || state.currentStage,
                    riskMasterId: payload.riskMasterId || state.riskMasterId,
                    riskProfileName: payload.riskProfileName || state.riskProfileName,
                    suggestedRiskId: payload.suggestedRiskId || state.suggestedRiskId,
                    investmentAmount:
                        payload.investmentAmount || state.investmentAmount,
                    investmentTenure:
                        payload.investmentTenure || state.investmentTenure,
                    investmentStrategy: payload.investmentStrategy,
                    isOnboardingCompleted:
                        payload.isOnboardingCompleted ||
                        state.isOnboardingCompleted,
                    isPaymentCompleted:
                        payload.isPaymentCompleted || state.isPaymentCompleted,
                    minInvestmentAmount:
                        payload.minInvestmentAmount ||
                        state.minInvestmentAmount,
                    minimumAdditionalInvestAmount:
                        payload.minimumAdditionalInvestAmount ||
                        state.minimumAdditionalInvestAmount,
                    portfolioGeneratedDate:
                        payload.portfolioGeneratedDate ||
                        state.portfolioGeneratedDate,
                    expiryDate: payload.expiryDate || state.expiryDate,
                    dateOfBirth: payload.dateOfBirth || state.dateOfBirth,
                    pan: payload.pan || state.pan,
                    loading: {
                        ...state.loading,
                        onboardingData: 0,
                    },
                    planId: payload.planId || state.planId,
                    hasOnboardingInvestmentDetailsConfirmed:
                        payload.hasOnboardingInvestmentDetailsConfirmed || state.hasOnboardingInvestmentDetailsConfirmed,
                    customerSubscriptionId: payload.customerSubscriptionId || state.customerSubscriptionId,
                    isMobileVerified: payload.isMobileVerified || state.isMobileVerified,
                };
            }

            case onboardingConstants.PORTFOLIO_DATA_REQUEST: {
                return {
                    ...state,
                    portfolioData: initialState.portfolioData,
                    marketBreakupData: initialState.marketBreakupData,
                    sectorBreakupData: initialState.sectorBreakupData,
                    loading: {
                        ...state.loading,
                        portfolioData: 1,
                    },
                };
            }

            case onboardingConstants.PORTFOLIO_DATA_RESPONSE: {
                return {
                    ...state,
                    portfolioData: payload.portfolioData || state.portfolioData,
                    marketBreakupData:
                        payload.marketBreakupData || state.marketBreakupData,
                    sectorBreakupData:
                        payload.sectorBreakupData || state.sectorBreakupData,
                    loading: {
                        ...state.loading,
                        portfolioData: 0,
                    },
                };
            }

            case onboardingConstants.PRICING_DATA_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        pricingData: 1,
                    },
                    pricingData: initialState.pricingData,
                };
            }

            case onboardingConstants.PRICING_DATA_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        pricingData: 0,
                    },
                    pricingData: payload,
                };
            }

            case onboardingConstants.RESET_ONBOARDING_DATA: {
                return initialState;
            }

            default:
                return state;
        }
    }
);
