import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvisRMSExistingHoldingAction } from "stores/jarvisRMS/jarvisRMSExistingHolding/jarvisRMSExistingHoldingAction";
import { jarvisRMSTransactionActions } from "stores/jarvisRMS/jarvisRMSTransaction/jarvisRMSTransactionActions";

const mapStateToProps = (state) => {
    return {
        transactionCallsList:
            state.jarvisRMS.rmsTransaction.transactionList.list,
        transactionCallsLoading:
            state.jarvisRMS.rmsTransaction.loading.transactionList,
        transactionCallsInitialCalled:
            state.jarvisRMS.rmsTransaction.initialCalled.transactionList,
        transactionCallsTotal:
            state.jarvisRMS.rmsTransaction.transactionList.total,
        
        portfolioList : state.jarvisRMS.rmsExistingHolding.portfolioList,
        isPortfolioListLoading: state.jarvisRMS.rmsExistingHolding.loading.portfolioList,
        isPortfolioListInitialCalled: state.jarvisRMS.rmsExistingHolding.initialCalled.portfolioList,
        
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getPortfolioListAction: jarvisRMSExistingHoldingAction.getPortfolioList,
            getRMSTransactionListAction:
                jarvisRMSTransactionActions.getRMSTransactionList,
        },
        dispatch
    );

const RMSTransactionStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSTransactionStore;
