import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvisRMSDashboardOnboardedActions } from "stores/jarvisRMS/jarvisRMSDashboard/jarvisRMSDashboardonboardedActions";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";

const mapStateToProps = (state) => {
    return {
        portfoliosStats: state.jarvisRMS.RMSDashboardOnboarded.portfoliosStats,
        isPortfolioLoading : state.jarvisRMS.RMSDashboardOnboarded.loading.portfoliosStats,
        rmsCallStats: state.jarvisRMS.RMSDashboardOnboarded.rmsStats,
        isRmsCallLoading : state.jarvisRMS.RMSDashboardOnboarded.loading.rmsStats,
    };
};
           
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getPortfolioStatsAction:
                jarvisRMSDashboardOnboardedActions.getPortfolioStatsDataAction,
            getRmsCallStatsAction : jarvisRMSDashboardOnboardedActions.getRmsStatsDataAction,
            getOnboardingDataAction:
                jarvsiRMSOnboardingActions.getOnboardingData,
        },
        dispatch
    );

const RMSDashboardOnboardedStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default RMSDashboardOnboardedStore;
