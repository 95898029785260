import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
    return {
        portfolioId : state.jarvisRMS.rmsOnboarding.portfolioId,
        isRMSOnboardingCompleted: state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const RMSCkycStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSCkycStore;
