import React from "react";

import ChooseBrokerContainer from "containers/Onboarding/ChooseBroker";

const PortfolioChooseBroker = (props) => {
    return (
        <>
            <ChooseBrokerContainer />
        </>
    )
};

export default PortfolioChooseBroker;