import { jarvisOnestockTransactionConstants } from "./jarvisOnestockTransactionConstants";

const jarvisOnestockTransactionAction = {};

jarvisOnestockTransactionAction.getTransactionList = ({
    page,
    perPage,
    fromDate: dateFrom,
    toDate: dateTo,
    transactionStatus,
    isAvoidRefresh
}) => ({
    type: jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_SAGA,
    payload: { page, perPage, dateFrom, dateTo, transactionStatus, isAvoidRefresh },
});

export { jarvisOnestockTransactionAction };
