import { config } from "config";
import { BranchDeepLinks  } from 'capacitor-branch-deep-links';


const branchio = {};

branchio.init = async () => {
  // we want only in mobile
  // for now we will use only for android
  if (!config.isMobileApp) {
    return false;
  }

  BranchDeepLinks.addListener('init', (event) => {
    // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
    // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
    console.log("BranchDeepLinks", event.referringParams);
    // alert(`branchdeeplinks ${JSON.stringify(event)}`)
  });

  BranchDeepLinks.addListener('initError', (error) => {
    console.error("BranchDeepLinks initError", error);
    // alert(`branchdeeplinks error ${JSON.stringify(error)}`)
  });
    
};

export { branchio };
