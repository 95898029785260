import { formatHelper } from "helper/format";
import _get from "lodash.get";

const jarvisRMSOnboardingParser = {};

jarvisRMSOnboardingParser.brokerList = (res) => {
    if (res && res.data) {
        res = res.data;
    }
    if (!res) {
        return [];
    }

    res.list = res.list.map((p) => ({
        brokerId: _get(p, "brokerId", null),
        brokerName: _get(p, "brokerName", null),
        brokerDisplayName: _get(p, "brokerDisplayName", null),
        brokerPlatform: _get(p, "brokerPlatform", null),
        brokerIcon: formatHelper.getLegacyImageURI(_get(p, "brokerIcon", null)),
        brokerCode: _get(p, "brokerCode", null),
    }));

    res.records = _get(res, "records", 0);
    return res;
};

jarvisRMSOnboardingParser.rmsPlanList = (res) => {
    let data = {};
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return {};
    }

    const productMap = (product) =>
        product.map((p) => ({
            productCode: _get(p, "productCode", null),
            productName: _get(p, "productName", null),
            productDescription: _get(p, "productDescription", null),
            stockLimit: _get(p, "stockLimit", null),
            portfolioLimit: _get(p, "portfolioLimit", null),
            aumLimit: _get(p, "aumLimit", null),
        }));

    const options = (op) =>
        op.map((o) => ({
            planId: _get(o, "planId", null),
            planName: _get(o, "planName", null),
            planCode: _get(o, "planCode", null),
            planChecklist: _get(o, "planChecklist", []),
            planDiscountPercentage: _get(o, "planDiscountPercentage", null),
            planFrequency: _get(o, "planFrequency", null),
            planSequence: _get(o, "planSequence", null),
            planPrice: _get(o, "planPrice", null),
            planFrequencyLabel: _get(o, "planFrequencyLabel", null),
            planDiscountPrice: _get(o, "planDiscountPrice", null),
            planDiscountedPrice: _get(o, "planDiscountedPrice", null),
            tagLabel: _get(o, "tagLabel", null),
            tagColor: _get(o, "tagColor", null),
            tagBackgroundColor: _get(o, "tagBackgroundColor", null),
            productMap: productMap(_get(o, "productMap", [])),
        }));

    Object.keys(res).forEach((k) => {
        data[k] = options(res[k]);
    });

    return data;
};

jarvisRMSOnboardingParser.onboardingData = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        currentStage: _get(res, "currentOnboardingStage", null),
        isOnboardingCompleted: _get(res, "isOnboardingCompleted", null),
        riskProfileId: _get(res, "riskProfileId", null),
        riskProfileName: _get(res, "riskProfileName", null),
        panNumber: _get(res, "panNumber", null),
        dateOfBirth: _get(res, "dateOfBirth", null),
        aadharNumber: _get(res, "aadharNumber", null),
        isManualKYC: _get(res, "isManualKYC", null),
        isPanUploaded: _get(res, "isPanUploaded", null),
        isAadhaarFrontUploaded: _get(res, "isAadhaarFrontUploaded", null),
        isAadhaarBackUploaded: _get(res, "isAadhaarBackUploaded", null),
        duplicateStages: _get(res, "duplicateStages", null),
        portfolioId: _get(res, "tempCustomerPortfolioId", null),
    };
};

jarvisRMSOnboardingParser.submitRiskAssessmentQuestion = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        riskProfileId: _get(res, "riskProfileId", null),
        riskProfileName: _get(res, "riskProfileName", null),
    };
};

jarvisRMSOnboardingParser.rmsSubscription = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        customerSubscriptionId: _get(res, "customerSubscriptionId", null),
        gatewaySubscriptionId: _get(res, "gatewaySubscriptionId", null),
        gateway: _get(res, "gateway", null),
    };
};

jarvisRMSOnboardingParser.captureRMSSubscription = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        subscriptionStatus: _get(res, "subscriptionStatus", null),
        customerSubscriptionId: _get(res, "customerSubscriptionId", null),
    };
};

jarvisRMSOnboardingParser.initiateDocumentSigning = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (res && res.result) {
        res = res.result;
    }

    if (!res || !Object.keys(res).length) {
        return {};
    }

    return {
        documentId: _get(res, "documentId", null),
        accessToken: _get(res, "accessToken", null),
        identifier: _get(res, "documentIdentifier", null),
        gateway: _get(res, "signingGateway", null),
    };
};

export { jarvisRMSOnboardingParser };
