import React from 'react';
import JarvisCard from './JarvisCard';
import Stack from './common/Stack';
import Text from './common/Text';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { formatHelper } from 'helper/format';


const JarvisStockInfoCard = ({ data, isClickedOnCard, cardSX = {} }) => {
    const {
        stockQuantity,
        stockChangeType,
        stockChangePercentage,
        stockName,
        stockBuyPrice,
        stockErrorMessage,
        stockCurrentValue,
        stockBuyValue,
        stockCurrentPrice,
    } = data;

    return (
        <>
            <JarvisCard mb={1} sx={{ border: "1px solid #E2E2E2", padding: '14px 16px', ...cardSX }} onClick={() => isClickedOnCard(data)}>
                <Stack direction="row" mb={1} justifyContent="space-between">
                    <Text color={"#A2A7AE"} variant="caption1">Qty.<span style={{ color: "#303236" }}>{stockQuantity}</span></Text>
                    <Text color={!stockChangeType ? "#A2A7AE " : stockChangeType === 'GAIN' ? '#45AC70' : "#D44C4C"} variant="caption1">{formatHelper.currency(stockCurrentPrice)}({stockChangePercentage}%)</Text>
                </Stack>
                <Stack direction="row" mb={1} justifyContent="space-between">
                    <Text color={"#212225"} variant="small" sx={{ fontWeight: 500 }}>{stockName}</Text>
                    <Text color={"#212225"} variant="small" sx={{ fontWeight: 500 }}>₹{stockBuyPrice}</Text>
                </Stack>
                <Stack direction="row" mb={stockErrorMessage ? 2 : ''} justifyContent="space-between">
                    <Text color={"#A2A7AE"} variant="caption1">Current Value <span style={{ color: "#303236" }}>{formatHelper.currency(stockCurrentValue)}</span></Text>
                    <Text color={"#A2A7AE"} variant="caption1">Total Invested <span style={{ color: "#303236" }}>{formatHelper.currency(stockBuyValue)}</span></Text>
                </Stack>
                {stockErrorMessage ? (
                    <Stack direction="row" mb={1} justifyContent="space-between" >
                        <Text sx={{ display: "flex", justifyContent: "center", alignItems: "top" }} color={"#D44C4C"} variant="caption1">
                            <InfoOutlinedIcon style={{ fontSize: "14px", marginRight: "5px", marginTop: "3px" }} />{stockErrorMessage}</Text>
                        {/* <Button size="small" variant="text" >Edit</Button> */}
                        {/* <Text color={"#1566D6"} variant="small"  onClick={() => isEdit(data)}><b>Edit</b></Text> */}
                    </Stack>
                ) : null}
            </JarvisCard>
        </>
    );
}

export default JarvisStockInfoCard;