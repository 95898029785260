import React from "react";

import JarvisCard from "components/JarvisCard";
import Text from "components/common/Text";
import Chart from "components/common/Chart";

class MarketCapDiversificationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isScreenTypeLaptop: false,
        };
    }

    componentDidMount() {
        this.props.getPortfolioMarketAction();
        window.addEventListener('resize', (event) => {
            if (window.innerWidth > 900) {
                this.setState({ isScreenTypeLaptop: true });
            } else {
                this.setState({ isScreenTypeLaptop: false });
            }
        });
    }

    render() {
        const { portfolioMarket } = this.props;
        let marketData = [];
        portfolioMarket.forEach((e) => {
            if ([undefined, null, 0].includes(e.amount)) {
                return null;
            }
            marketData.push({ name: e.name, y: e.amount || 0 });
        });

        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: "",
            },
            tooltip: {
                headerFormat: "",
                pointFormat:
                    '<span style="color:{point.color}">{point.name}</span>: <b>{point.percentage:.1f}%</b> of total<br/>',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    showInLegend: true,
                    dataLabels: {
                        enabled: this.state.isScreenTypeLaptop,
                        format: "{point.name}: {point.percentage:.1f}%",
                    },
                },
            },
            series: [
                {
                    data: marketData,
                },
            ],
        };

        return (
            <JarvisCard
                sx={{
                    border: { xs: "1px solid #EEF0F2", md: "none" },
                    marginTop: { md: 0 },
                }}>
                <Text variant="subtitle2" sx={{ mb: 2 }}>
                    Market Cap Diversification
                </Text>
                {marketData.length ? (
                    <Chart options={options} />
                ) : (
                    <Text variant="small">No data in chart</Text>
                )}
            </JarvisCard>
        );
    }
}

export default MarketCapDiversificationContainer;
