import React from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import DashboardDataCard from "components/Dashboard/DashboardDataCard";
import { formatHelper } from "helper/format";

const RMSDashboardStatsContainer = (props) => {
    const { portfolioStats, rmsCallStats, isRmsCallLoading, isPortfolioLoading } = props;
    const { totalAUM, totalStocks } = portfolioStats;
    const {
        totalRMSCalls,
        totalDownfallSaved,
        totalDownfallSavedPercentage,
        totalOpportunityLost,
        totalOpportunityLostPercentage,
    } = rmsCallStats;

    return (
        <>
            <Stack
                alignItems="center"
                direction={{ xs: "column", sm: "row" }}
                sx={{ gap: 1, height: "100%" }}
            >
                <DashboardDataCard
                    visibleIcon
                    icon={"/svgs/rupee_icon.svg"}
                    loading={isPortfolioLoading}
                    value={
                        formatHelper.currency(
                            Math.abs(totalAUM).toFixed(0)
                        ) || 0
                    }
                    // value={Math.abs(totalAUM).toFixed(2) || 0}
                    label="AUM under monitor"
                    color="#000000"
                    sx={{ flexGrow: 1 }}
                />
                <DashboardDataCard
                    visibleIcon
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/monitor.svg"}
                    loading={isPortfolioLoading}
                    value={totalStocks}
                    label="Stocks under monitor"
                    color="#000000"
                />

                <DashboardDataCard
                    visibleIcon
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/graph.svg"}
                    loading={isPortfolioLoading}
                    value={totalRMSCalls}
                    label="Total sell calls"
                    color="#000000"
                />

                <DashboardDataCard
                    visibleIcon
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/down_trend.svg"}
                    loading={isRmsCallLoading}
                    value={
                        <Stack direction="row" alignItems="center">
                            {formatHelper.currency(
                                Math.abs(totalDownfallSaved)
                            ) || 0}

                            {/* <Text
                                pl={3}
                                sx={{
                                    display: "flex",
                                }}
                            >
                                {(Math.abs(totalDownfallSavedPercentage) || 0) +
                                    "%"}
                            </Text> */}
                        </Stack>
                    }
                    label="Average downfall saved"
                    color="#45AC70"
                />

                <DashboardDataCard
                    visibleIcon
                    sx={{ flexGrow: 1 }}
                    icon={"/svgs/uptrend.svg"}
                    loading={isRmsCallLoading}
                    value={
                        <Stack direction="row" alignItems="center">
                            {formatHelper.currency(
                                Math.abs(totalOpportunityLost)
                            ) || 0}

                            {/* <Text
                                pl={3}
                                sx={{
                                    display: "flex",
                                }}
                            >
                                {(Math.abs(totalOpportunityLostPercentage) ||
                                    0) + "%"}
                            </Text> */}
                        </Stack>
                    }
                    label="Opportunity lost"
                    color="#E0953D"
                />
            </Stack>
        </>
    );
};

export default RMSDashboardStatsContainer;
