import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import React from "react";

import RMSDashboardStats from "components/RMSDashboard/RMSDashboardStats";
import MobileRMSDashboardStats from "components/RMSDashboard/MobileRMSDashboardStats";
// import RMSAMUTotal from "components/RMSDashboard/RMSAUMTotalChart";
// import RMSRiskAllocationChart from "components/RMSDashboard/RMSRiskAllocationChart";
// import DashboardChartCard from "components/Dashboard/DashboardChartCard";
import RMSPortfolioAnalyseDataContainer from "./RMSPortfolioAnalyseData";
import MobileResponsive from "components/common/MobileResponsive";

class RMSDashboardOnboardedContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
        this.props.getOnboardingDataAction();
        this.props.getPortfolioStatsAction();
        this.props.getRmsCallStatsAction();
    };

    render() {
        const {
            halfWidth = false,
            portfoliosStats,
            rmsCallStats,
            isPortfolioLoading,
            isRmsCallLoading,
        } = this.props;
        return (
            <Stack sx={{ maxWidth: "100%" }} mt={3} mb={2}>
                <MobileResponsive
                    web={() => (
                        <RMSDashboardStats
                            isPortfolioLoading={isPortfolioLoading}
                            isRmsCallLoading={isRmsCallLoading}
                            portfolioStats={portfoliosStats}
                            rmsCallStats={rmsCallStats}
                        />
                    )}
                    mobile={() => (
                        <MobileRMSDashboardStats
                            isPortfolioLoading={isPortfolioLoading}
                            isRmsCallLoading={isRmsCallLoading}
                            portfolioStats={portfoliosStats}
                            rmsCallStats={rmsCallStats}
                        />
                    )}
                />
                <Grid
                    container
                    spacing={2}
                    // mr={0}
                    mt={{ lg: 2 }}
                    mb={2}
                    width={halfWidth ? "100%" : "unset"}
                >
                    {/* <Grid item xs={12} lg={6} md={6}>
                        <DashboardChartCard
                            isLoading={isPortfolioLoading}
                            title="Total Portfolio AUM"
                            renderChart={
                                <RMSAMUTotal
                                    portfoliosStats={portfoliosStats}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                        <DashboardChartCard
                            isLoading={isPortfolioLoading}
                            title="Portfolio Risk Allocation"
                            renderChart={
                                <RMSRiskAllocationChart
                                    portfoliosStats={portfoliosStats}
                                />
                            }
                        />
                    </Grid> */}
                </Grid>
                <RMSPortfolioAnalyseDataContainer
                    portfoliosStats={portfoliosStats}
                />
            </Stack>
        );
    }
}

export default RMSDashboardOnboardedContainer;
