import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { customerActions } from "stores/customer/customerActions";
import { onboardingActions } from "stores/onboarding/onboardingActions";

const mapStateToProps = (state) => {
    return {
        currentStage: state.onboarding.currentStage,
        isOnboardingDataFetched: state.onboarding.isInitialFetched,
        isOnboardingDataLoading: state.onboarding.loading.onboardingData,
        emailId: state.customer.loginProfile.email,
        name:state.customer.loginProfile.name,
        isEmailVerified : state.customer.loginProfile.isEmailVerified,
        isOnboardingCompleted: state.onboarding.isOnboardingCompleted,
        isRMSOnboardingCompleted: state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getOnboardingDataAction: onboardingActions.getOnboardingData,
            getProfileAction: customerActions.getProfile,
            logoutAction: customerActions.logout,
            setToast: commonActions.setToast,
            updateEmailAction: customerActions.updateEmail,
        },
        dispatch
    );

const OnboardingLayoutStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnboardingLayoutStore;
