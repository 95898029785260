import { onboardingConstants } from "./onboardingConstants";

const onboardingActions = {};

onboardingActions.updateOnboardingData = ({
    currentStage,
    riskMasterId,
    riskProfileName,
    suggestedRiskId,
    investmentAmount,
    investmentTenure,
    investmentStrategy,
    isOnboardingCompleted,
    isPaymentCompleted,
    minInvestmentAmount,
    portfolioGeneratedDate,
    expiryDate,
    isLoading,
    isInitialFetched,
    planId,
    customerSubscriptionId,
    hasOnboardingInvestmentDetailsConfirmed,
}) => ({
    type: onboardingConstants.UPDATE_ONBOARDING_DATA,
    payload: {
        currentStage,
        riskMasterId,
        riskProfileName,
        suggestedRiskId,
        investmentAmount,
        investmentTenure,
        investmentStrategy,
        isOnboardingCompleted,
        isPaymentCompleted,
        minInvestmentAmount,
        portfolioGeneratedDate,
        expiryDate,
        isLoading,
        isInitialFetched,
        planId,
        customerSubscriptionId,
        hasOnboardingInvestmentDetailsConfirmed,
    },
});

onboardingActions.getOnboardingData = () => ({
    type: onboardingConstants.ONBOARDING_DATA_SAGA,
});

onboardingActions.getPortfolioData = () => ({
    type: onboardingConstants.PORTFOLIO_DATA_SAGA,
});

onboardingActions.getPricingData = () => ({
    type: onboardingConstants.PRICING_DATA_SAGA,
});

onboardingActions.getPricingDataResponse = (payload = {}) => ({
    type: onboardingConstants.PRICING_DATA_RESPONSE,
    payload
})

export { onboardingActions };
