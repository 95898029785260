/* eslint-disable no-throw-literal */
import React from "react";
import _get from "lodash.get";
import { withRouter } from "react-router-dom";
import { Checkout } from 'capacitor-razorpay';

import Text from "components/common/Text";
import Loader from "components/common/Loader";

import JarvisCard from "components/JarvisCard";
import JarvisDialog from "components/JarvisDialog";

import { config } from "config";
import { injectHelper } from "helper/inject";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import { thirdpartyParsers } from "stores/thirdparty/thirdpartyParsers";
import { tracker } from "library/tracker";
import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";
import { localStore } from "stores/localStorage";

const PAYMENT_STATUS = {
    FAILED: "FAILED",
    SUCCEED: "SUCCEED",
    CANCELLED: "CANCELLED",
    INPROGRESS: "INPROGRESS"
}

const REDIRECTION_URIS = {
    RMS_INITIAL: {
        SUCCEED: "/jarvis-rms/onboarding/ckyc",
        FAILED: "/jarvis-rms/onboarding/sync-portfolio",
        CANCELLED: "/jarvis-rms/onboarding/sync-portfolio",

    },
    RMS_RENEWAL: {
        SUCCEED: "/customer/profile",
        FAILED: "/customer/profile",
        CANCELLED: "/customer/profile",
        
    },
    RMS_UPGRADE: {
        SUCCEED: "/customer/profile",
        FAILED: "/customer/profile",
        CANCELLED: "/customer/profile",
        
    },
    PORTFOLIO_INITIAL: {
        SUCCEED: "/jarvis-portfolio/onboarding/portfolio-creation",
        FAILED: "/jarvis-portfolio/onboarding/portfolio-creation",
        CANCELLED: "/jarvis-portfolio/onboarding/portfolio-creation",

    },
    PORTFOLIO_RENEWAL: {
        SUCCEED: "/customer/profile",
        FAILED: "/customer/profile",
        CANCELLED: "/customer/profile",

    },
    PORTFOLIO_UPGRADE: {
        SUCCEED: "/customer/profile",
        FAILED: "/customer/profile",
        CANCELLED: "/customer/profile",

    },
    // TODO: to be defined for onestock product
    ONESTOCK_INITIAL: {
        SUCCEED: "/jarvis-onestock/onboarding/ckyc",
        FAILED: "/jarvis-onestock/onboarding/payment?paymentStatus=FAILED",
        CANCELLED: "/jarvis-onestock/onboarding/payment?paymentStatus=CANCELLED",
    },
    ONESTOCK_RENEWAL: {
        SUCCEED: "/customer/profile",
        FAILED: "/customer/profile",
        CANCELLED: "/customer/profile",
    },
    ONESTOCK_UPGRADE: {
        SUCCEED: "/customer/profile",
        FAILED: "/customer/profile",
        CANCELLED: "/customer/profile",
    }
}

const PAYMENT_SUCCESS_MESSAGE = {
    RMS: {
        initial: {
            title: "Congrats!!!",
            content: "Now you can view all the stocks allocated in your portfolio.",
            actionText: "Continue",
            icon: "/images/svgs/success.gif"
        },
        default: {
            title: "Congrats!!!",
            content: "Payment successfull",
            actionText: "Continue",
            icon: "/images/svgs/success.gif"
        },
        takingTime: {
            title: "In Progress",
            content: "Please wait for 5-10 minutes until the transaction is processed. If your payment is not processed contact Jarvis Support.",
            actionText: "Continue",
            icon: "/images/svgs/not-confirmed.gif"
        },
    },
    PORTFOLIO: {
        initial: {
            title: "Congrats!!!",
            content: "Now you can view all the stocks allocated in your portfolio.",
            actionText: "View Portfolio",
            icon: "/images/svgs/success.gif"
        },
        default: {
            title: "Congrats!!!",
            content: "Payment successfull",
            actionText: "Continue",
            icon: "/images/svgs/success.gif"
        },
        takingTime: {
            title: "In Progress",
            content: "Please wait for 5-10 minutes until the transaction is processed. If your payment is not processed contact Jarvis Support.",
            actionText: "Continue",
            icon: "/images/svgs/not-confirmed.gif"
        },
    },
    ONESTOCK: {
        initial: {
            title: "Congrats!!!",
            content: "Now you can view all the stocks allocated in your portfolio.",
            actionText: "View Portfolio",
            icon: "/images/svgs/success.gif"
        },
        default: {
            title: "Congrats!!!",
            content: "Payment successfull",
            actionText: "Continue",
            icon: "/images/svgs/success.gif"
        },
        takingTime: {
            title: "In Progress",
            content: "Please wait for 5-10 minutes until the transaction is processed. If your payment is not processed contact Jarvis Support.",
            actionText: "Continue",
            icon: "/images/svgs/not-confirmed.gif"
        },
    }
}

const PAYMENT_ERROR_MESSAGE = {
    RMS: {
        initial: {
            title: "Oops!!!",
            content: "Something went wrong! Please try again later",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        },
        default: {
            title: "Oops!!!",
            content: "Something went wrong! Please try again later",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        }
    },
    PORTFOLIO: {
        initial: {
            title: "Oops!!!",
            content: "Something went wrong! Please try again later",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        },
        default: {
            title: "Oops!!!",
            content: "Something went wrong! Please try again later",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        }
    },
    ONESTOCK: {
        initial: {
            title: "Oops!!!",
            content: "Something went wrong! Please try again later",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        },
        default: {
            title: "Oops!!!",
            content: "Something went wrong! Please try again later",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        }
    }
}

const PAYMENT_CANCELLED_MESSAGE = {
    RMS: {
        initial: {
            title: "Cancelled",
            content: "Please complete initial payment to proceed with profile completion",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        },
        default: {
            title: "Cancelled",
            content: "Please complete initial payment to proceed with profile completion",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        }
    },
    PORTFOLIO: {
        initial: {
            title: "Cancelled",
            content: "Please complete initial payment to proceed with profile completion",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        },
        default: {
            title: "Cancelled",
            content: "Please complete initial payment to proceed with profile completion",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        }
    },
    ONESTOCK: {
        initial: {
            title: "Cancelled",
            content: "Please complete initial payment to proceed with profile completion",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        },
        default: {
            title: "Cancelled",
            content: "Please complete initial payment to proceed with profile completion",
            actionText: "Go Back",
            icon: "/images/svgs/failed.gif"
        }
    }
}

const PAYMENT_INPROGRESS_MESSAGE = {
    RMS: {
        initial: {
            title: "In Progress",
            content: "Your payment is under process please wait for sometime",
            actionText: "",
            icon: "/images/svgs/inprogress.gif"
        },
        default: {
            title: "In Progress",
            content: "Your payment is under process please wait for sometime",
            actionText: "",
            icon: "/images/svgs/inprogress.gif"
        }
    },
    PORTFOLIO: {
        initial: {
            title: "In Progress",
            content: "Your payment is under process please wait for sometime",
            actionText: "",
            icon: "/images/svgs/inprogress.gif"
        },
        default: {
            title: "In Progress",
            content: "Your payment is under process please wait for sometime",
            actionText: "",
            icon: "/images/svgs/inprogress.gif"
        }
    },
    ONESTOCK: {
        initial: {
            title: "In Progress",
            content: "Your payment is under process please wait for sometime",
            actionText: "",
            icon: "/images/svgs/inprogress.gif"
        },
        default: {
            title: "In Progress",
            content: "Your payment is under process please wait for sometime",
            actionText: "",
            icon: "/images/svgs/inprogress.gif"
        }
    }
}

class PaymentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogContent: {
                icon: "",
                title: "",
                content: "",
                actionText: "",
            },
            dialogOpen: false,
            paymentStatus: "",
            isLoading: false,
            isPaymentInProcess: false,
            hasSameAmount: this.props.location?.state?.hasSameAmount || false,
            paymentType: this.props.location?.state?.paymentType,
        }
    }

    componentDidMount() {
        const productCode = _get(this.props.location, "state.productCode", "");
        const planId = _get(this.props.location, "state.planId", "");
        const paymentInitiated = localStore.getPaymentInitiated();

        if (productCode && planId && !paymentInitiated) {
            this.initProcess();
        } else {
            this.handleError({ reason: "Payment can not be initialized", origin: "componentDidMount > not found = productCode | planId" })
        }
    }

    // Init pay process: from didMount
    initProcess = async () => {
        const {
            planId,
            paymentMethod,
            paymentMethodOption = "",
            paymentDetails = {},
            productCode,
            offerCode,
            paymentType,
        } = this.props.location.state;
        

        try {
            this.setState({ isLoading: true });

            const initPaymentResponse = await thirdpartyApis.initiateRecurringPayment({
                planId,
                paymentMethod,
                paymentMethodOption,
                paymentMethodDetails: paymentDetails,
                productCode,
                offerCode,
                offerType: paymentType,
            });

            // Renew with amounts trackers.
            if(initPaymentResponse.hasOwnProperty('code') && initPaymentResponse.hasOwnProperty('message') && initPaymentResponse.code === "JSPM003" && initPaymentResponse.status === 200){
                let message = initPaymentResponse.message;
                let dialogContent4TimeRestriction= {
                    title: "Cancelled",
                    content: message,
                    actionText: "Go Back",
                    icon: "/images/svgs/failed.gif"
                }
                this.setState({
                    isPaymentInProcess: false,
                    dialogOpen: true,
                    paymentStatus: PAYMENT_STATUS.CANCELLED,
                    dialogContent: dialogContent4TimeRestriction,
                });
                return 

            }
            const { gatewayPayload, customerSubscriptionId, gateway } = thirdpartyParsers.initiateRecurringPayment(initPaymentResponse);

            if (gateway === config.payment.gateway.razorpay) {
                if (config.isMobileApp) {
                    await this.initMobileRazorpay({
                        gatewayPayload, customerSubscriptionId,

                    });
                } else {
                    await this.initRazorpay({
                        gatewayPayload, customerSubscriptionId,
                    });
                }
                return;
            }

            this.handleError({ reason: "Payment gateway not found", origin: "initProcess > not found = gateway" });
        } catch (error) {
            let erCode = _get(error, "response.data.code", null);
            let erMessage = _get(error, "response.data.message", "Something went wrong! Please try again later!");

            if (erCode && erCode === "JEI012") {
                this.handleError({ reason: erMessage, origin: "initProcess > catch block", error });
            } else {
                this.handleError({ reason: "Something went wrong! Please try again later!", origin: "initProcess > catch block", error });
            }

        } finally {
            this.setState({ isLoading: false })
        }
    }

    // Web: Init Razorpay 
    initRazorpay = async ({ gatewayPayload, customerSubscriptionId }) => {
        try {
            await injectHelper.script({ src: config.payment.razorpay.scriptURI });

            const rzp = new window.Razorpay({
                ...gatewayPayload,
                prefill: {
                    name: this.props.loginProfile.name,
                    email: this.props.loginProfile.email,
                    contact: this.props.loginProfile.mobile
                },
                handler: (r) => {
                    // show pending dialog and then show success popup
                    // having this timeout to allow server to process razorpay callback
                    this.setState({
                        isPaymentInProcess: true,
                        dialogOpen: true,
                        paymentStatus: PAYMENT_STATUS.INPROGRESS,
                        dialogContent: this.getProductWiseDialogContent({ paymentType: "default", paymentStatus: PAYMENT_STATUS.INPROGRESS }),
                    });

                    setTimeout(() => {
                        this.handleSuccess({
                            paymentId: r.razorpay_payment_id,
                            orderId: r.razorpay_order_id,
                            paymentSignature: r.razorpay_signature,
                            rawResponse: r,
                            customerSubscriptionId
                        });
                    }, 5000)
                },
                key: config.payment.razorpay.key,
                name: config.companyName,
                image: config.companyLogo,
                modal: {
                    escape: false,
                    confirm_close: true,
                    ondismiss: this.handleCancel,
                }
            });

            rzp.on('payment.failed', ({ error }) => this.handleError({ reason: error.reason, origin: "initRazorpay > payment failed", error }))
            rzp.open();
            localStore.setPaymentInitiated();
        } catch (error) {
            this.handleError({ reason: "Something went wrong! Please try again later!", origin: "initRazorpay > catch block", error });
        }
    }

    // App: Init Razorpay
    initMobileRazorpay = async ({ gatewayPayload, customerSubscriptionId }) => {
        try {
            const options = {
                ...gatewayPayload,
                prefill: {
                    name: this.props.loginProfile.name,
                    email: this.props.loginProfile.email,
                    contact: this.props.loginProfile.mobile
                },
                key: config.payment.razorpay.key,
                name: config.companyName,
                image: config.companyLogo,
                modal: {
                    escape: false,
                    confirm_close: true,
                    ondismiss: this.handleCancel,
                }
            }

            if (config.platform === "IOS") {
                delete options.modal.ondismiss;
            }

            // alert(`initMobileRazorpay options ${JSON.stringify(options)}`)

            const data = await Checkout.open(options);
            localStore.setPaymentInitiated();
            // alert(`initMobileRazorpay data ${JSON.stringify(data)}`)
            const r = data.response;
            this.setState({
                isPaymentInProcess: true,
                dialogOpen: true,
                paymentStatus: PAYMENT_STATUS.INPROGRESS,
                dialogContent: this.getProductWiseDialogContent({ paymentType: "default", paymentStatus: PAYMENT_STATUS.INPROGRESS }),
            });

            setTimeout(() => {
                this.handleSuccess({
                    paymentId: r.razorpay_payment_id,
                    orderId: r.razorpay_order_id,
                    paymentSignature: r.razorpay_signature,
                    rawResponse: r,
                    customerSubscriptionId,
                });
            }, 5000)
        } catch (error) {
            // alert(`initMobileRazorpay error ${JSON.stringify(error)}`)
            let res = JSON.parse(error.message);
            let message = res.error.description;
            this.handleError({
                reason: (message || "Something went wrong! Please try again later!"),
                origin: "initRazorpay > catch block", error
            });
        } finally {
            this.setState({ isLoading: false })
            await this.initRazorpay({
                gatewayPayload, customerSubscriptionId,
            });
        }
    }

    // 
    getProductWiseDialogContent = ({ paymentStatus, paymentType }) => {
        const productCode = _get(this.props.location, "state.productCode", "");
        if (paymentStatus === PAYMENT_STATUS.SUCCEED) {
            return PAYMENT_SUCCESS_MESSAGE[productCode][paymentType] || PAYMENT_SUCCESS_MESSAGE[productCode]["default"];
        }

        if (paymentStatus === PAYMENT_STATUS.FAILED) {
            return PAYMENT_ERROR_MESSAGE[productCode][paymentType] || PAYMENT_ERROR_MESSAGE[productCode]["default"];
        }

        if (paymentStatus === PAYMENT_STATUS.CANCELLED) {
            return PAYMENT_CANCELLED_MESSAGE[productCode][paymentType] || PAYMENT_CANCELLED_MESSAGE[productCode]["default"];
        }

        if (paymentStatus === PAYMENT_STATUS.INPROGRESS) {
            return PAYMENT_INPROGRESS_MESSAGE[productCode][paymentType] || PAYMENT_INPROGRESS_MESSAGE[productCode]["default"];
        }

        console.error(`${paymentStatus} is not defined`)
        return {}
    }

    // scenario: payment completed successfully
    handleSuccess = async ({ paymentId, orderId, paymentSignature, rawResponse, customerSubscriptionId }) => {

        try {
            const paymentType = _get(this.props.location, "state.paymentType", "default");
            // await thirdpartyApis.captureRecurringPayment({ orderId, paymentId, paymentSignature, rawResponse });

            const checkPayStatus = (delayTime) => {
                let statusRes;
                return new Promise(
                    (paid) => {
                        setTimeout(async () => {
                            try {
                                statusRes = await thirdpartyApis.checkRecurringPaymentStatus({ orderId: orderId, customerSubscriptionId: customerSubscriptionId });
                                statusRes = thirdpartyParsers.checkRecurringPaymentStatus(statusRes);
                                paid(statusRes);
                                if (paymentType === "RENEWAL") {
                                    const hasSameAmount = (_get(this.props.location, "state.hasSameAmount", ""));
                                    if (hasSameAmount) {
                                        //event-tracker: Renewal(R) -> renew_with_same_amount_success.
                                        tracker.renewal.renewWithSameAmountSuccess();

                                    } else {
                                        //event-tracker: Renewal(R) -> renew_with_different_amount_success.
                                        tracker.renewal.renewWithDifferentAmountSuccess();
                                    }
                                }
                            } catch (e) {
                                if (paymentType === "RENEWAL") {
                                    const hasSameAmount = (_get(this.props.location, "state.hasSameAmount", ""));
                                    if (hasSameAmount) {
                                        //event-tracker: Renewal(R) -> renew_with_same_amount_failed.
                                        tracker.renewal.renewWithSameAmountFailed({ error_msg: e.message });

                                    } else {
                                        //event-tracker: Renewal(R) -> renew_with_different_amount_failed.
                                        tracker.renewal.renewWithDifferentAmountFailed({ error_msg: e.message });
                                    }
                                }
                                paid(statusRes);
                            }

                        }, (delayTime))
                    }
                )
            }

            let isPaid = false;
            let checkResponce;
            try {
                checkResponce = await checkPayStatus(10000);
                isPaid = (checkResponce.status.toLowerCase() === "completed") ? true : false;
                if (!isPaid) {
                    checkResponce = await checkPayStatus(10000);
                    isPaid = (checkResponce.status.toLowerCase() === "completed") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(10000);
                    isPaid = (checkResponce.status.toLowerCase() === "completed") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(10000);
                    isPaid = (checkResponce.status.toLowerCase() === "completed") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(10000);
                    isPaid = (checkResponce.status.toLowerCase() === "completed") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(15000);
                    isPaid = (checkResponce.status.toLowerCase() === "completed") ? true : false;
                }
                if (!isPaid) {
                    checkResponce = await checkPayStatus(20000);
                    isPaid = (checkResponce.status.toLowerCase() === "completed") ? true : false;
                }
            } catch (e) {
                console.error("payment check API error: ", e);
            }

            // Set popup state.
            if (isPaid) {
                const productCode = _get(this.props.location, "state.productCode", "");
                // for product code rms and payment successful
                if (productCode === config.productCodes.RMS) {
                    const isOnboardingCompleted = this.props.rmsOnboardingData.isOnboardingCompleted;
                    const portfolioId = _get(this.props, 'rmsOnboardingData.portfolioId', null);
                    // if onboarding not completed map to actual portfolio
                    if (portfolioId && !isOnboardingCompleted) {
                        await jarvisRMSOnboardingApis.confirmPortfolioStock({ portfolioId });
                    }
                }

                if (productCode === config.productCodes.ONESTOCK) {
                    console.log(this.props.onestockOnboardingData);
                    this.props.getOnestockOnboardingAction();

                }

              
                this.setState({
                    isPaymentInProcess: false,
                    dialogOpen: true,
                    paymentStatus: PAYMENT_STATUS.SUCCEED,
                    dialogContent: this.getProductWiseDialogContent({ paymentType, paymentStatus: PAYMENT_STATUS.SUCCEED }),
                });
                this.props.getRMSOnboardingDataAction();
            } else {
                this.setState({
                    isPaymentInProcess: false,
                    dialogOpen: true,
                    paymentStatus: PAYMENT_STATUS.SUCCEED,
                    dialogContent: this.getProductWiseDialogContent({ paymentType: "takingTime", paymentStatus: PAYMENT_STATUS.SUCCEED }),
                });

            }
            if (paymentType === "INITIAL") {
                //event-tracker: Initial -> i_payment_completed
                tracker.initial.iPaymentCompleted();
                tracker.onboarding.portfolioCreation(this.props.loginProfile.mobile);
            }
            if (paymentType === "additionalInvestment") {
                //NOTE event_tracker => investMore
                tracker.investMore.investMorePaymentCompleted();
            }
        } catch (error) {
            this.handleError({ reason: "Something went wrong! Please try again later!", origin: "handleSuccess > catch block", error });
        } finally {
            // empty
        }
    }

    // scenario: payment failed and error raised during any phase
    handleError = ({ reason, origin, error }) => {
        if (origin && error) {
            console.error(`an error occured origin => ${origin}, error =>`, error);
        }

        const paymentType = _get(this.props.location, "state.paymentType", "default");
        if (paymentType === "INITIAL") {
            //event-tracker: Initial -> i_payment_failed
            tracker.initial.iPaymentFailed({ reason: "Something went wrong! Please try again later!" });
        }
        if (paymentType === "additionalInvestment") {
            //NOTE event_tracker => investMore
            tracker.investMore.investMorePaymentFailed({ error_msg: reason });
        }

        const dialogContent = this.getProductWiseDialogContent({ paymentType, paymentStatus: PAYMENT_STATUS.FAILED });
        if (reason) {
            dialogContent.content = (<Text>{reason}</Text>);
        }

        if (paymentType === "RENEWAL") {
            const hasSameAmount = (_get(this.props.location, "state.hasSameAmount", ""));
            if (hasSameAmount) {
                //event-tracker: Renewal(R) -> renew_with_same_amount_failed.
                tracker.renewal.renewWithSameAmountFailed({ error_msg: reason });

            } else {
                //event-tracker: Renewal(R) -> renew_with_different_amount_failed.
                tracker.renewal.renewWithDifferentAmountFailed({ error_msg: reason });
            }
        }

        this.setState({
            dialogOpen: true,
            paymentStatus: PAYMENT_STATUS.FAILED,
            dialogContent,
        })
    }

    // scenario: payment cancelled by user
    handleCancel = () => {
        const paymentType = _get(this.props.location, "state.paymentType", "default");

        this.setState({
            dialogOpen: true,
            paymentStatus: PAYMENT_STATUS.CANCELLED,
            dialogContent: this.getProductWiseDialogContent({ paymentType, paymentStatus: PAYMENT_STATUS.CANCELLED })
        })
    }

    handleDialogClose = () => {
        //event-tracker: On Cancel Payment(C Pay) -> go,back
        tracker.onCancelPayment.goBack();
        const currentStatus = this.state.paymentStatus;
        const productCode = _get(this.props.location, "state.productCode", "");
        const paymentType = _get(this.props.location, "state.paymentType", "");
        const redirectionDelay = _get(this.props.location, "state.returnDelayMiliSec", 0);

        let returnURI = "/";
        if (paymentType && productCode) {
            returnURI = REDIRECTION_URIS[`${productCode}_${paymentType}`][currentStatus];
        }
        console.log(returnURI);

        this.setState({ isPaymentInProcess: true });

        setTimeout(
            () => {
                this.setState({ dialogOpen: false, isPaymentInProcess: false }, () => {
                    this.props.history.replace({
                        pathname: returnURI,
                        state: {
                            paymentStatus: currentStatus
                        }
                    });
                });

                // Overflwo issue after cancell payment on onBoarding flow.
                setTimeout(() => {
                    document.body.style.overflow = "unset";
                }, 1500);
            }, redirectionDelay)
    }

    render() {
        const {
            dialogOpen,
            dialogContent,
            isLoading,
            isPaymentInProcess
        } = this.state;

        return (
            <JarvisCard>
                <Loader loading={isLoading} />

                <Text>Processing your payment. Please wait!!!</Text>

                <JarvisDialog
                    disableCloseIcon
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    onConfirm={this.handleDialogClose}
                    title={dialogContent.title}
                    content={dialogContent.content}
                    icon={{
                        src: dialogContent.icon,
                        height: 120,
                        width: 120
                    }}
                    button={{
                        text: dialogContent.actionText,
                        // disableEndIcon: true,
                        loading: isPaymentInProcess,
                    }}
                />
            </JarvisCard>

        )
    }
}

export default withRouter(PaymentContainer);