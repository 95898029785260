
import JarvisCard from "components/JarvisCard";
import Drawer from "components/common/Drawer";
import Grid from "components/common/Grid";
import HelpSupportQuestionList from "components/HelpSupport/HelpSupportQuestionList";
import HelpSupportCategory from "components/HelpSupport/HelpSupportCategory";
import React from "react";
import HelpSupportQuestionDetails from "components/HelpSupport/HelpSupportQuestionDetails";
import Loader from "components/common/Loader";
import { withStyles } from "@mui/styles";

class HelpSupportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      detailsDrawer: false,
      categoryTitle: "",
      categoryID: null
    };
  }
  componentDidMount() {
    this.props.getCategoryListAction();
  }

  handleQuestionsDrawer = (categoryID, title) => {
    this.setState({ drawerState: true, categoryTitle: title, categoryID });
    if(!this.props.categoryData[categoryID]) {
      this.props.getCategoryDataAction({ categoryID })
    }
  };

  handleQuestionDetailsDrawer = (questionID, categoryID) => {
    const selectedCategoryID = this.state.categoryID || categoryID;
    this.setState({ detailsDrawer: true, drawerState: true, })
    this.props.getCategoryQuestionsAction({ selectedCategoryID, questionID })
  };

  backToList = () => {
    this.setState({ drawerState: false, detailsDrawer: false, categoryID: null, categoryTitle: "" });
  };

  render() {
    return (
      <>
        <JarvisCard>
          <Loader position="absolute" loading={this.props.isLoading} />

          <Grid container spacing={2} direction="row" py={2.5} px={ {xs:0,md:3}}>
            {this.props.categoryList.map((hs, i) => (
              <Grid item md={4} sm={6} xs={12} key={i} mb={6}>
                <HelpSupportCategory
                  title={hs.category}
                  categoryList={hs.options}
                  onListClick={this.handleQuestionDetailsDrawer}
                  onViewAll={this.handleQuestionsDrawer}
                  categoryId={hs.id}
                />
              </Grid>
            ))}
          </Grid>


        </JarvisCard>
        <Drawer anchor={"right"} open={this.state.drawerState} classes={{ 
            paper: this.props.classes.drawerPaper,
            root: this.props.classes.root
            }}>
          {this.state.detailsDrawer ? (
            <HelpSupportQuestionDetails
              onBackClick={() => this.backToList()}
              questionsData={this.props.categoryQuestion}
            />
          ) : (
            <HelpSupportQuestionList
              onBackClick={() => this.backToList()}
              OnListClick={this.handleQuestionDetailsDrawer}
              categoryTitle={this.state.categoryTitle}
              categoryData={this.props.categoryData}
              categoryID={this.state.categoryID}

            />
          )}
        </Drawer>
      </>
    );
  }
}

const style = (theme) => ({
  drawerPaper: {
    width: "100%",
    top: `var(--safe-area-inset-top)`,
    [theme.breakpoints.up("lg")]: {
      width: "60%",
    },
  },
  root:{
    "& > .MuiBackdrop-root":{
      top: `var(--safe-area-inset-top)`,
    }
  }
});

export default withStyles(style, { withTheme: true })(HelpSupportContainer)


