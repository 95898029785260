import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";


// Hleper
import { CustomerRoute, OnboardingRoute } from "helper/route";

// Child navigator
import JarvisOneStockOnboardingNavigator from "./JarvisOneStockOnboardingNavigator";

// Pages
import Dashboard from "pages/JarvisOnestock/OnestockDashboard";
import ExistingHolding from "pages/JarvisOnestock/OnestockExistingHolding";
import OnestockActionCenter from "pages/JarvisOnestock/OnestockActionCenter";

import Transaction from "pages/JarvisOnestock/OnestockTransaction";

// others
import LayoutContainer from "containers/Layout";

const JarvisOneStockNavigator = (props) => {
    const { match } = props;
    // const auth = useSelector((state) => state.customer);
    const isAuthenticated = useSelector((state) => state.customer.isAuthenticated);

    // Product onboaridng completed
    const isPortfolioOnboardingCompleted = useSelector((state) => state.onboarding.isOnboardingCompleted);
    const isRMSonboardingCompleted = useSelector((state) => state.jarvisRMS.rmsOnboarding.isOnboardingCompleted);
    const isOnestockonboardingCompleted = useSelector((state) => state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted);

    const anyOnboardingCompleted = (isPortfolioOnboardingCompleted || isRMSonboardingCompleted || isOnestockonboardingCompleted);

    const redirectTo = () => {
        let restPath = "dashboard";
        if (!isOnestockonboardingCompleted) {
            restPath = "onboarding/overview";
        }

        return `${match.path}/${restPath}`;
    }


    return (
        <Switch>
            {/* 1 - Redirection to */}
            <Redirect exact from={match.path} to={redirectTo()} />

            {/* 2 -  Child Navigator */}
            <OnboardingRoute
                isAuthenticated={isAuthenticated}
                isOnboardingCompleted={isOnestockonboardingCompleted}
                redirectOnboardingIncomplete={`${match.path}/dashboard`}
                path={`${match.path}/onboarding`}
                component={JarvisOneStockOnboardingNavigator}
            />

            {/* 3 - With layout pages */}
            <LayoutContainer {...props}>
                <Switch>

                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={anyOnboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/dashboard`}
                        component={Dashboard}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isOnestockonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/action-centre`}
                        component={OnestockActionCenter}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isOnestockonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/existing-holding`}
                        component={ExistingHolding}
                    />
                    <CustomerRoute
                        exact
                        isAuthenticated={isAuthenticated}
                        isOnboardingCompleted={isOnestockonboardingCompleted}
                        redirectOnboardingCompleted={`${match.path}/onboarding`}
                        path={`${match.path}/transactions`}
                        component={Transaction}
                    />
                </Switch>
            </LayoutContainer>
            <Redirect to={`not-found`} />
        </Switch>
    );
};

export default JarvisOneStockNavigator;