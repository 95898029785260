import React from "react";
import { withRouter } from "react-router-dom";
import { config } from "config";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import TextField from "components/common/TextField";
import ButtonBase from "components/common/ButtonBase";
import InputAdornment from "components/common/InputAdornment";
import Modal from "components/common/Modal";
import Select from "components/common/Select";
import Grid from "components/common/Grid";

import SectionTitle from "components/SectionTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/common/IconButton";

import JarvisButton from "components/JarvisButton";
import StrategyCard from "components/Onboarding/StrategyCard";
import ChooseBrokerIcon from "components/Onboarding/ChooseBrokerIcon";

import { formatHelper } from "helper/format";
import { validationHelper } from "helper/validation";
import { onboardingApis } from "stores/onboarding/onboardingApis";
import { tracker } from "library/tracker";
import { intercom } from "library/tracker/intercom";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import PlanAndPricingContainer from "containers/PlanAndPricing";
import Button from "components/common/Button";


class InvestmentPreferenceContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        investmentAmount: null,
        investmentTenure: null,
        investmentStrategy: null,
        planId: null,
      },
      formError: {
        investmentAmount: "",
        investmentTenure: "",
        investmentStrategy: "",
        planId: null,
      },
      isLoading: false,
      brokerListModal:false,
      // years
      isStrategySubmited: false,
      displayYearSelect: false,
      tenurePreferences: this.props.tenurePreferences,

      // price & plan
      displayAmountInput: false,

    };
  }

  handleChange = ({ name, value, mode = "" }) => {
    let { formData,
      displayAmountInput,
      displayYearSelect
    } = this.state
    const { investmentStrategies} = this.props;
    // toggle year dropDown.
    if (name === "investmentTenure" && value === "other") {
      value = 4
      displayYearSelect = true
    } else if (name === "investmentTenure" && value !== "other" && mode === "chip") {
      displayYearSelect = false
    }

    // toggle Amount input.
    if (name === "investmentAmount" && value === "other") {
      value = 30000;
      displayAmountInput = true
    } else if (name === "investmentAmount" && value !== "other" && mode === "chip") {
      displayAmountInput = false
    }

    // save as intiger value.
    let checkNumberType = ["investmentTenure", "investmentAmount"].includes(name);
    if (checkNumberType && isNaN(value)) {
      return;
    } else if (checkNumberType) {
      value = Number(value);
    }
    
    // if([null,undefined].includes(formData["investmentStrategy"])){
    //   const pureEquityStrategy = investmentStrategies?.filter(strategy=> strategy.heading !=="Asset allocation strategy")
    //   formData["investmentStrategy"] = pureEquityStrategy?.[0]?.id
    // }

    formData[name] = value;
    this.setState({ formData, displayYearSelect, displayAmountInput });
  };

  handleValidationInvestmentHorizon = () => {
    const { formError, formData } = this.state;

    const investmentTenureValidation = validationHelper.required(
      formData.investmentTenure
    );
    formError.investmentTenure = investmentTenureValidation.isValid
      ? ""
      : "Please Select Your Tenure";

    // const investmentStrategyValidation = validationHelper.required(
    //   formData.investmentStrategy
    // );
    // formError.investmentStrategy = investmentStrategyValidation.isValid
    //   ? ""
    //   : "Please Select Your Investment Strategy";

    this.setState({ formError });
    return (
      investmentTenureValidation.isValid /* &&
      investmentStrategyValidation.isValid */
    );
  };


  handleValidation = () => {
    const { formError, formData } = this.state;

    const investmentAmountValidation = validationHelper.minInvestment(
      formData.investmentAmount,
      this.props.minInvestmentAmount
    );
    formError.investmentAmount = investmentAmountValidation.message;

    const investmentTenureValidation = validationHelper.required(
      formData.investmentTenure
    );
    formError.investmentTenure = investmentTenureValidation.isValid
      ? ""
      : "Please Select Your Tenure";

    // const investmentStrategyValidation = validationHelper.required(
    //   formData.investmentStrategy
    // );
    // formError.investmentStrategy = investmentStrategyValidation.isValid
    //   ? ""
    //   : "Please Select Your Investment Strategy";

    const planIdValidation = validationHelper.required(
      formData.planId
    );
    formError.planId = planIdValidation.isValid
      ? ""
      : "Please Select Your Investment Plan";

    this.setState({ formError });
    return (
      investmentAmountValidation.isValid &&
      investmentTenureValidation.isValid &&
      /* investmentStrategyValidation.isValid && */
      planIdValidation
    );
  };

  componentDidMount() {
    if (!this.props.brokersList.length) {
      this.props.getBrokersAction({ domain: config.broker.platform.smallcase, productCode: config.productCodes.PORTFOLIO });
    }

    const {formData} = this.state
    if (this.props.investmentStrategies.length && formData.investmentStrategy === null ) {
      // by default the pure equity investment strategy is selected
      const [pureEquityStrategy] = this.props.investmentStrategies.filter(strategy=>strategy.heading?.toLowerCase()?.includes('pure equity'));
      if(Object.keys(pureEquityStrategy).length){
        formData.investmentStrategy = pureEquityStrategy?.id || 0;
        this.setState({formData})
      }
    }

  }

  submitStrategy = () => {
    if (!this.handleValidationInvestmentHorizon()) {
      return;
    }
    this.setState({ isStrategySubmited: true });
  }

  handleSubmit = async () => {

    if (!this.handleValidation()) {
      return;
    }

    const { formData } = this.state;
    this.setState({ isLoading: true });

    try {
      //event-tracker: Investment details submitted.
      tracker.Onboarding.investmentDetailsSubmitted();
      await onboardingApis.submitInvestment(this.state.formData);
      tracker.Onboarding.investmentDetailsSubmittedSuccess();

      this.props.updateOnboardingDataAction({
        currentStage: this.props.currentStage + 1,
        investmentAmount: formData.investmentAmount,
        investmentTenure: formData.investmentTenure,
        investmentStrategy: formData.investmentStrategy,
        planId: formData.planId,
      });
      tracker.onboarding.investmentPreference(this.props.loginProfile.mobile);
      this.props.history.replace(`/jarvis-portfolio/onboarding/portfolio-creation`);
    } catch (e) {
      tracker.Onboarding.investmentDetailsSubmittedError({ error_msg: e.message });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  seeListOfBrokerHandle = () => {


  }

  handleBrokerNotFound = () => {
    this.setState({brokerListModal: false});
    intercom.show({ message: "I can't see my broker, Please help." });
  }
    

  onPlanSelection = (planData) => {
    const { formData } = this.state;

    formData.planId = planData?.planId || null;
    this.setState({ formData });

  }

  render() {

    const {
      tenurePreferencesList,
      investmentStrategies,
      lockAmountInput,

      // plan
      investmentPlanList,
      centrumReferalURL
    } = this.props;
    const centrumBroker = this.props.brokersList.filter((value) => {
      return value.name === "centrum";
    });

    const {
      formData,
      formError,
      isLoading,
      brokerListModal,
      // Year & tenure
      displayYearSelect,
      tenurePreferences,
      isStrategySubmited,

      // Amount & plan
      displayAmountInput,

    } = this.state;




    // STEP-1 Strategy selection
    if (!isStrategySubmited) {
      return (
        <Stack>
          {/* Back button */}
          <SectionTitle
              sx={{ display: { xs: "flex", md: 'none' } }}
              heading="Investment horizon and amount"
              // startIcon={
              //     <IconButton onClick={() => { this.props.history.replace('/onboarding/overview') }}>
              //         <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
              //     </IconButton>
              // }

          />

          {/* heading */}
          <Stack mb={5} mt={3}>
            <Text component="h2" variant="subtitle2" >For how many years do you intend to stay invested regardless of market movements?</Text>
            <Text component="h4" color="#676E76">(We advise you to select this response carefully because Jarvis will aim to deliver returns within the time frame you chose.)</Text>
          </Stack>

          {/* Tenure Selection */}
          <Stack mb={4}>
            <Text mb={0.5}>Recommended based on your risk profile</Text>
            <Stack sx={{ flexFlow: "row wrap", m: "0px -8px" }} spacing={{ xs: 0.5, md: 1 }}>
              {tenurePreferences.map((tenure, index) => (
                <ButtonBase
                  key={`tenure_${index}`}
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    this.handleChange({
                      name: "investmentTenure",
                      value: tenure.value,
                      mode: "chip"
                    })
                  }
                  className={
                    formData.investmentTenure === tenure.value ||  displayYearSelect && tenure.value === "other" ? "active" : ""
                  }
                  sx={{
                    fontSize: "14px !important",
                    borderColor: "#E0E7FF",
                    color: "#969ea8",
                    "&.active": {
                      backgroundColor: "#1D9BFF14 ",
                      color: "#1D9BFF",
                      borderColor: "#1D9BFF",
                    },
                    height: "35px",
                    width: "80px",
                    padding: "5px",
                    borderRadius: "50px !important",
                    m: "8px !important",
                  }}
                >
                  {tenure.label}
                </ButtonBase>
              ))}

              {
                displayYearSelect ?
                  <Select
                    disableUnderline
                    makeOptionsCenterAlign
                    variant="filled"
                    value={formData.investmentTenure || 0}
                    data={tenurePreferencesList}
                    dataKey={"year"}
                    dataValue="display"
                    onChange={(e) =>
                      this.handleChange({
                        name: "investmentTenure",
                        value: e,
                      })
                    }
                    sx={{
                      px: 1.5,
                      py: 0.25,
                      borderRadius: 5.5,
                      fontSize: `1rem !important`,
                      height: "35px",
                      width: "110px",
                      padding: "5px 15px",
                      borderRadius: "50px !important",
                      m: "8px !important",
                      fontSize: "14px !important",
                      border: "1px solid #e0e7ff",
                      color: "#969ea8",
                      backgroundColor: "transparent !important",
                      "&> select": {
                        backgroundColor: "transparent !important",
                      }
                    }}
                  >
                  </Select>
                  : null
              }
            </Stack>
            {formError.investmentTenure && <Text variant="caption" color="error.main">{formError.investmentTenure}</Text>}
          </Stack>

          {/* Strategy selection */}
          <Stack sx={{ marginBottom: { xs: "70px", md: "unset" } }}>
            <Text sx={{ color: "#212225", mb: 1 }}>Strategy</Text>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {investmentStrategies.map((strategy, index) =>{
                if(strategy.heading ==="Asset allocation strategy"){
                  return (<></>)
                }
                return (
                <StrategyCard
                  key={`strategy_${index}`}
                  heading={`${strategy.heading}`}
                  description={strategy.description}
                  descriptionSmall={strategy.descriptionSmall}
                  isSelected={formData.investmentStrategy === strategy.id}
                  showCheckbox={false}
                  showDescription={false}
                  // onClick={() =>
                  //   this.handleChange({
                  //     name: "investmentStrategy",
                  //     value: strategy.id,
                  //   })
                  // }
                />
              )})}
            </Stack>
            {formError.investmentStrategy && <Text variant="caption" color="error.main">{formError.investmentStrategy}</Text>}
          </Stack>
{/*           <Stack sx={{ marginBottom: { xs: "70px", md: "unset" } }}>
            <Text sx={{ color: "#212225", mb: 1 }}>Select a strategy</Text>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {investmentStrategies.map((strategy, index) => (
                <StrategyCard
                  key={`strategy_${index}`}
                  heading={strategy.heading}
                  description={strategy.description}
                  descriptionSmall={strategy.descriptionSmall}
                  isSelected={formData.investmentStrategy === strategy.id}
                  onClick={() =>
                    this.handleChange({
                      name: "investmentStrategy",
                      value: strategy.id,
                    })
                  }
                />
              ))}
            </Stack>
            {formError.investmentStrategy && <Text variant="caption" color="error.main">{formError.investmentStrategy}</Text>}
          </Stack> */}

          {/* strategy submit button */}
          <Stack
            sx={{
              background: "#fff",
              width: { xs: "100%", md: "unset" },
              justifyContent: { xs: "center", md: "unset" },
              alignItems: { xs: "center", md: "unset" },
            }}
          >
            <Stack
              sx={{
                position: { xs: "fixed", md: "unset" },
                bottom: { xs: "10px", md: "unset" },
                width: { xs: "90vw", md: "unset" },
                mx: { xs: 0, md: -4.5 }
              }}>
              <JarvisButton
                sx={{
                  width: { xs: "100%", md: "auto" },
                  backgroundColor: "#fff",
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                  alignSelf: "flex-end",
                  m: "10px",
                  mr: { xs: 0, md: 4.5 }
                }}
                onClick={this.submitStrategy}
                loading={isLoading}
              >
                Submit
              </JarvisButton>
            </Stack>
          </Stack>

         
        </Stack>

      )
    }

    // STEP-2: Plane selection
    if (isStrategySubmited) {
      return (
        <Stack>
          {/* Back button */}
          <SectionTitle
              sx={{ display: { xs: "flex", md: 'none' }, mb: 2 }}
              heading="Personalized Portfolio"
              startIcon={
                  <IconButton onClick={() => this.setState({isStrategySubmited: false}) }>
                      <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                  </IconButton>
              }

          />

          {/* Amount Select */}
          <Text component="h4">Jarvis needs a minimum balance of Rs. 30,000 in your demat account to build an efficient portfolio for you.</Text>
          <Stack sx={{ flexFlow: "row wrap", m: "0px -8px" }} spacing={{ xs: 0.5, md: 1 }}>
            {investmentPlanList.map((amount, index) => (
              <ButtonBase
                key={`amount_${index}`}
                variant="outlined"
                size="small"
                onClick={() =>
                  this.handleChange({
                    name: "investmentAmount",
                    value: amount,
                    mode: "chip",
                  })
                }
                className={
                  (!displayAmountInput &&
                    formData.investmentAmount === amount) ||
                  (displayAmountInput && amount === "other")
                    ? "active"
                    : ""
                }
                sx={{
                  fontSize: "14px !important",
                  borderColor: "#E0E7FF",
                  color: "#969ea8",
                  "&.active": {
                    backgroundColor: "#1D9BFF14 ",
                    color: "#1D9BFF",
                    borderColor: "#1D9BFF",
                  },
                  height: "35px",
                  padding: "5px 22px",
                  borderRadius: "50px !important",
                  m: "8px !important",
                  textTransform: "capitalize",
                }}
              >
                {isNaN(amount) ? amount : formatHelper.currency(amount)}
              </ButtonBase>
            ))}

            {displayAmountInput ? (
              <TextField
                label="Enter prefered amount"
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">₹</InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  this.handleChange({
                    name: "investmentAmount",
                    value: e.target.value,
                  })
                }
                value={formData.investmentAmount || ""}
                sx={{
                  maxWidth: 160,
                  marginTop: "8px",
                  height: "35px",
                  "&> label": {
                    fontSize: "9px !important",
                    transform: "translate(24px, 4px) scale(0.75) !important",
                    transform: "translate(17px, -4px) scale(0.75)!important",
                    backgroundColor: "#fff",
                    padding: "0px 6px",
                  },
                  "&> div": {
                    borderRadius: "50px !important",
                    overflow: "hidden",
                  },
                  "& input": {
                    padding: "0 !important",
                    fontSize: "14px !important",
                    height: "35px",
                    paddingLeft: "22px !important",
                    paddingTop: "0px !important",
                  },
                }}
                disabled={lockAmountInput}
              />
            ) : null}
          </Stack>
          {formError.investmentAmount && (
            <Text variant="caption" color="error.main">
              {formError.investmentAmount}
            </Text>
          )}

          {/* see list of broker */}
          <Stack mb={config.isMobileApp ? 0 : 6}>
            <Text variant="small" sx={{ color: "#808591" }}>
              <InfoOutlinedIcon
                sx={{
                  transform: "translateY(4px)",
                  marginRight: "7px",
                  fontSize: "18px",
                }}
              />
              Please select the amount you can afford to set aside to invest in
              stocks or if you have the same in your demant account..
              <Text
                variant="small"
                component="span"
                sx={{
                  color: "#2B70C5",
                  cursor: "pointer",
                  ml: "5px",
                  "&::selection": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={() => this.setState({ brokerListModal: true })}
              >
                See list of brokers
              </Text>
            </Text>
          </Stack>

          {/* Plan & pricing */}
          <Box mb={3}>
            <PlanAndPricingContainer
              productCode={config.productCodes.PORTFOLIO}
              onPlanSelection={this.onPlanSelection}
              investmentAmount={formData.investmentAmount}
              paymentType="INITIAL"
            />
          </Box>
          <Box flex={1} />

          {/* Continue Button */}
          <Stack
            sx={{
              mb: { md: 0 },
              mx: { xs: -2, md: -4.5 },
              position: { xs: "sticky" },
              bottom: { xs: "10px" },
            }}
          >
            <JarvisButton
              sx={{
                width: { xs: "calc(100% - 32px)", md: "auto" },
                fontSize: "16px",
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
                m: "10px",
                mr: { xs: 2, md: 4.5 },
              }}
              onClick={this.handleSubmit}
              loading={isLoading}
              disabled={!formData.planId}
            >
              Continue
            </JarvisButton>
          </Stack>

          <Modal
            open={brokerListModal}
            onClose={() => this.setState({ brokerListModal: false })}
            title={
              <Stack alignItems="flex-start">
                <Text variant="subtitle2" sx={{ fontWeight: 500 }}>
                  {" "}
                  Broker List
                </Text>
              </Stack>
            }
            size="sm"
          >
            <>
              <Grid
                container
                spacing={2.5}
                sx={{
                  justifyContent: "flex-start",
                  flex: 1,
                  alignItems: "center",
                  mb: { xs: "0.5rem", md: "0.5rem" },
                  gap: "2rem",
                  paddingBottom: "25px",
                  marginBottom: "20px",
                }}
              >
                {centrumBroker && centrumBroker.length ? (
                  centrumBroker.map((b, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={3}
                      key={index}
                    >
                      <ChooseBrokerIcon
                        image={b.imageUrl}
                        title={b.displayName}
                        onClick={() =>
                          this.setState({ brokerListModal: false })
                        }
                      />
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
                <Stack
                  direction={{ xs: "row", md: "column" }}
                  justifyContent={{ xs: "space-between", md: "flex-start" }}
                  width={{ xs: "100%", md: "fit-content" }}
                  pl={{ xs: "1.25rem", md: 0 }}
                >
                  <Text
                    variant="small"
                    sx={{ fontWeight: 700, placeSelf: "center" }}
                  >
                    Don't have a Centrum account?
                  </Text>
                  <Text
                    variant="small"
                    sx={{
                      fontWeight: 500,
                      color: "#2B70C5",
                      cursor: "pointer",
                      "&::selection": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                    onClick={() => {
                      window.open(centrumReferalURL, "_blank");
                    }}
                  >
                    Open New Account
                  </Text>
                </Stack>
                <Stack
                  direction="row"
                  ml="1.25rem"
                  width="100%"
                  border="1px solid #F5F5F5"
                ></Stack>
              </Grid>
              <Stack sx={{ maxHeight: "300px", overflowY: "auto" }}>
                <Grid
                  container
                  spacing={0.4}
                  sx={{
                    justifyContent: "flex-start",
                    flex: 1,
                    alignItems: "center",
                    mb: "20px",
                  }}
                >
                {this.props.brokersList.length ? (
                    this.props.brokersList.map((b, index) => (
                     <>{b.name === 'centrum' ? (<></>) :(
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2.4}
                        xl={2}
                        key={index}
                      >
                        <ChooseBrokerIcon
                          image={b.imageUrl}
                          title={b.displayName}
                          height={30}
                          fontSize={0.67}
                          onClick={() =>
                            this.setState({ brokerListModal: false })
                          }
                          // isSelected={b.name === selectedBroker.name}
                        />
                          
                      </Grid>)
                    }</>
                    ))
                  ) : (
                    <Grid item>
                      <Text>Brokers not found</Text>
                    </Grid>
                  )}
                </Grid>
              </Stack>
              <Stack>
                <Button
                  variant="text"
                  sx={{ textTransform: "initial", marginTop: "22px" }}
                  size="small"
                  onClick={this.handleBrokerNotFound}
                >
                  I can’t find my broker listed here
                </Button>
              </Stack>
            </>
          </Modal>
        </Stack>
      );
    }


  }

}

export default withRouter(InvestmentPreferenceContainer);
