import React from "react";
import { withRouter } from "react-router-dom";

import Table from "components/common/Table";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import MobileResponsive from "components/common/MobileResponsive";
import Loader from "components/common/Loader";
import Button from "components/common/Button";
import Pagination from "components/common/Pagination";
import TooltipHover from "components/common/TooltipHover";
import Image from "components/common/Image";

import JarvisButton from "components/JarvisButton";
import PageTitle from "components/PageTitle";
import JarvisChip from "components/JarvisChipNew";
import JarvisRMSFilter from "components/jarvisRMS/RMSTransactionListFilter";
import JarvisCard from "components/JarvisCard";
import MobileRMSTransaction from "components/RMSTransaction/MobileRMSTransaction";

import InfoIcon from '@mui/icons-material/Info';
import { FilterList as FilterListIcon } from "@mui/icons-material"
import { dateTimeHelper } from "helper/dateTime";
import { formatHelper } from "helper/format"

const initialFilterData = {
    fromDate: null,
    toDate: null,
    portfolioIds: [],
    executionStatus: null,
}

class RMSTransactionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableRefreshCounter: 0,
            page: 1,
            perPage: 25,
            filterOpen: false,
            filterData: JSON.parse(JSON.stringify(initialFilterData)),
            tempFilterData: JSON.parse(JSON.stringify(initialFilterData)),
        }
    }

    componentDidMount() {
        this.fetchTransactionList();
        if (!this.props.portfolioList.isPortfolioListInitialCalled || this.props.portfolioList.length) {
            this.props.getPortfolioListAction();
        }
    }

    setPortfolioNameFormatForFilter = () => {
        const { portfolioList } = this.props;
        let data = []
        if (portfolioList && portfolioList.length) {
            data = portfolioList.map(val => ({ label: val.portfolioName, value: val.portfolioId }))
        }
        return data;
    }

    fetchTransactionList = () => {
        const { page, perPage, filterData: { fromDate, toDate, portfolioIds, executionStatus } } = this.state;
        this.props.getRMSTransactionListAction({ page, perPage, fromDate, toDate, portfolioId: portfolioIds, executionStatus })
        setTimeout(() => {
            this.setState(prevState => ({
                tableRefreshCounter: prevState.tableRefreshCounter + 1
            }))
        }, 500)
    }

    handlePageChange = (page, perPage) => {
        this.setState({ page, perPage }, () => {
            this.fetchTransactionList();
        })
    }

    handleFilterDataChange = ({ name, value }) => {
        const { tempFilterData } = this.state;

        if (['portfolioIds'].includes(name)) {
            if (tempFilterData[name].includes(value)) {
                tempFilterData[name] = tempFilterData[name].filter(v => v !== value);
            } else {
                tempFilterData[name].push(value);
            }
        } else if (['executionStatus'].includes(name)) {
            if (tempFilterData[name] === value) {
                tempFilterData[name] = null;
            } else {
                tempFilterData[name] = value;
            }
        } else {
            tempFilterData[name] = value;
        }

        this.setState({ tempFilterData });
    }

    handleFilterSubmit = () => {
        this.setState({
            filterOpen: false,
            filterData: this.state.tempFilterData,
        }, () => {
            this.fetchTransactionList();
        });
    }

    handleFilterReset = () => {
        this.setState({
            filterOpen: false,
            filterData: JSON.parse(JSON.stringify(initialFilterData)),
            tempFilterData: JSON.parse(JSON.stringify(initialFilterData))
        }, () => {
            this.fetchTransactionList();
        });
    }

    handleFilterOpen = () => {
        this.setState({ filterOpen: true });
    }

    handleFilterClose = () => {
        this.setState({ filterOpen: false, tempFilterData: this.state.filterData })
    }

    isFilterApplied = () => {
        const { filterData } = this.state;
        return filterData.fromDate || filterData.toDate || filterData.portfolioIds.length || filterData.executionStatus
    }

    render() {
        const {
            page,
            perPage,
            filterOpen,
            tempFilterData,
            tableRefreshCounter,
        } = this.state;

        const {
            transactionCallsList,
            transactionCallsLoading,
            transactionCallsTotal,
            transactionCallsInitialCalled,
        } = this.props;

        const filterPortfolioOptions = this.setPortfolioNameFormatForFilter();

        const columns = [
            {
                field: 'stockName',
                headerText: 'Stock',
                bodyCellComponent: (v, d) => {
                    return (
                        <Stack direction="row" sx={{ justifyContent: "space-between", width: "100%" }}>
                            <Stack>
                                <Text>{v}</Text>
                                <Text variant="caption1" sx={{ color: "#808591" }}>{d.stockSymbol}</Text>
                            </Stack>
                            <JarvisChip sx={{ marginTop: "8px", padding: "0px", fontSize: "10px", height: "20px" }} label={d.portfolioName} color="default" />
                        </Stack>
                    )
                }
            },
            {
                field: 'sellDate',
                headerText: 'Sell Date',
                bodyCellComponent: (v) => <Text variant="small">{v ? dateTimeHelper.format(v, '$DD/$MM/$YYYY') : null}</Text>
            },
            {
                field: 'sellQuantity',
                headerText: 'Qty.',
                bodyCellComponent: (v) => <Text variant="small">{v < 10 ? `0${v}` : v}</Text>
            },
            {
                field: 'sellPrice',
                headerText: 'Sell Price',
                bodyCellComponent: (v) => <Text variant="small">{v ? formatHelper.currency(v, { config: { maximumFractionDigits: 2 } }) : null}</Text>
            },
            {
                field: 'changePrice',
                headerComponent: () => {
                    return (
                        <Stack direction="row">
                            <Text variant="caption" fontWeight="medium">Change in Price</Text>
                            <TooltipHover
                                title="Change in price is logged upto 30 days from call execution"
                                sx={{ lineHeight: 0, marginLeft: "3px" }}
                            >
                                <InfoIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                            </TooltipHover>
                        </Stack>
                    )
                },
                bodyCellComponent: (v) => <Text variant="small">{v ? formatHelper.currency(v, { config: { maximumFractionDigits: 2 } }) : null}</Text>
            },
            {
                field: 'valueSaved',
                headerComponent: () => {
                    return (
                        <Stack direction="row">
                            <Text variant="caption" fontWeight="medium">Value Saved</Text>
                            <TooltipHover
                                title="Calculated based on difference between sell price and change in price"
                                sx={{ lineHeight: 0, marginLeft: "3px" }}
                            >
                                <InfoIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                            </TooltipHover>
                        </Stack>
                    )
                },
                bodyCellComponent: (v, d) => {
                    const isSaved = d.transactionStatus.toUpperCase() === "EXECUTED";
                    let textColor;

                    if(v >= 0){
                        textColor="#2E7D32"
                    } else if(v < 0){
                        textColor="#D44C4C"
                    }

                    return (
                        <Stack>
                            <Stack direction="row" alignItems="center">
                                <Text variant="small" color={textColor}>{formatHelper.currency(v, { config: { maximumFractionDigits: 2 } })}</Text>
                                {/* <Text variant="caption1" color={isSaved ? 'success.main' : 'warning.main'}>&nbsp; ({d.valueSavedPercentage}%)</Text> */}
                            </Stack>
                            {/* <Text variant="caption1" sx={{ whiteSpace: "nowrap" }} color={isSaved ? 'success.main' : 'warning.main'}>{isSaved ? 'Saved' : 'Opportunity Lost'}</Text> */}
                        </Stack>
                    )
                }
            },
            {
                field: 'transactionStatus',
                // headerText: 'Transaction Status',
                headerComponent: () => {
                    return (
                        <Stack direction="row">
                            <Text variant="caption" fontWeight="medium">Transaction Status</Text>
                            <TooltipHover
                                title="Executed: Executed by you, System Executed: Executed by AI"
                                sx={{ lineHeight: 0, marginLeft: "3px" }}
                            >
                                <InfoIcon ml={1} sx={{ width: "20px", color: "#a39e9e" }} />
                            </TooltipHover>
                        </Stack>
                    )
                },
                bodyCellComponent: (v) => {
                    if (v.toUpperCase() === 'EXECUTED') {
                        return <JarvisChip sx={{ padding: "8px" }} size="small" label="Executed" color="success" />
                    }
                    if (v.toUpperCase() === 'NOT-EXECUTED') {
                        return <JarvisChip sx={{ padding: "8px" }} size="small" label="System Executed" color="warning" />
                    }
                    return null;
                }
            },
        ];

        return (
            <>
                <PageTitle
                    heading="Transactions"
                    endContent={() => (
                        <JarvisButton
                            disableEndIcon
                            disableStartIcon={false}
                            animate={false}
                            variant="text"
                            sx={{ color: 'black' }}
                            startIcon={<FilterListIcon />}
                            onClick={this.handleFilterOpen}
                        >
                            Filter
                        </JarvisButton>
                    )}
                />
                <Loader loading={transactionCallsLoading} />

                {transactionCallsInitialCalled && this.isFilterApplied() && !transactionCallsList.length ? (
                    <JarvisCard>
                        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
                            <Text sx={{ fontWeight: "bold" }}>No transaction found for applied filter</Text>
                            <Button size="small" onClick={this.handleFilterReset}>Clear Filter</Button>
                        </Stack>
                    </JarvisCard>
                ) : null}

                {transactionCallsInitialCalled && !this.isFilterApplied() && !transactionCallsList.length ? (
                    <JarvisCard>
                        <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
                            <Text sx={{ fontWeight: "bold" }}>No transactions so far!</Text>
                        </Stack>
                    </JarvisCard>
                ) : null}

                {transactionCallsInitialCalled && transactionCallsList.length ? (
                    <>
                        <MobileResponsive
                            web={() => (
                                <JarvisCard sx={{ p: 2 }}>
                                    <Table
                                        paginationBottom
                                        paginationTop
                                        columns={columns}
                                        data={transactionCallsList}
                                        count={transactionCallsTotal}
                                        loading={transactionCallsLoading}
                                        dataChangedCounter={tableRefreshCounter}
                                        page={page - 1}
                                        rowsPerPage={perPage}
                                        onPageChange={this.handlePageChange}
                                    />
                                </JarvisCard>
                            )}
                            mobile={() => (
                                <>
                                    <Stack direction="row" justifyContent="flex-end">
                                        <JarvisButton
                                            disableEndIcon
                                            disableStartIcon
                                            animate={false}
                                            variant="text"
                                            sx={{ color: 'black', marginBottom: "5px", marginTop: "5px", padding: "6px 10px 6px 18px" }}
                                            // startIcon={<Image src="/images/mobileApp/filter-icon.svg" />}
                                            onClick={this.handleFilterOpen}
                                        >
                                            <Text mr={1} variant="small" sx={{ color: "#303236" }}>Filter</Text>
                                            <Image src="/images/mobileApp/filter-icon.svg" width={15} />
                                        </JarvisButton>
                                    </Stack>
                                    <JarvisCard sx={{ border: "1px solid #EEF0F2", padding: "0", height: "auto" }}>
                                        {/* <Pagination
                                        defaultRowsPerPage={perPage}
                                        page={page - 1}
                                        count={transactionCallsTotal}
                                        onPageChange={this.handlePageChange}
                                    /> */}
                                        {transactionCallsList?.map((data) => {
                                            return (
                                                <>
                                                    <Text sx={{ padding: "15px 10px" }}>
                                                        <MobileRMSTransaction
                                                            detailsData={data}
                                                        />
                                                    </Text>
                                                    <Text sx={{ borderBottom: "1px solid #E2E2E2", padding: "0px" }}></Text>
                                                </>
                                            );
                                        })}
                                        <Pagination
                                            defaultRowsPerPage={perPage}
                                            page={page - 1}
                                            count={transactionCallsTotal}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </JarvisCard>
                                </>
                            )}
                        />
                    </>
                ) : null
                }

                <JarvisRMSFilter
                    filterData={tempFilterData}
                    open={filterOpen}
                    onClose={this.handleFilterClose}
                    onChange={this.handleFilterDataChange}
                    onReset={this.handleFilterReset}
                    onSubmit={this.handleFilterSubmit}
                    portfolioOptions={filterPortfolioOptions}
                    executionStatusOptions={[{ color: 'success', label: 'Executed', value: 'Executed' }, { color: 'warning', label: 'System Executed', value: 'Not-Executed' }]}
                />
            </>
        )
    }
}

export default withRouter(RMSTransactionsContainer);