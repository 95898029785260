import React from "react";
import _get from "lodash.get";

import JarvisButton from "components/JarvisButton";

import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Table from "components/common/Table";

import { formatHelper } from "helper/format";

class PortfolioTransationConfirmation extends React.Component {
  columns = [
    { field: "symbol", headerText: "Symbol" },
    {
      field: "quantity",
      headerText: "Qty",
      bodyCellComponent: (v, row) => {
        let print = "-";
        print = formatHelper.getQtyInSlash(v, row.suggested_quantity, true);
        return <Text variant="small">{print}</Text>;
      },
    },
    {
      field: "action",
      headerText: "Action",
      bodyCellComponent: (v) => {
        const color = v === "S" ? "#D44C4C" : "#45AC70";
        const value = v === "S" ? "Sell" : "Buy";
        return <Text variant="small" style={{ color: color }}>{value}</Text>;
      },
    },
    {
      field: "rate",
      headerText: "Considered Rate",
      editable: true,
      autoFocus: true,
      validation: (value) => {
        if (!value) {
          return true;
        }
        return false;
      },
      bodyCellComponent: (v) => {
        return <Text variant="small">{formatHelper.currency(v, { config: {maximumFractionDigits: 2} })}</Text>
      },
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      stocksList: [],
      isUpdated: false,
    };
  }

  componentDidMount() {
    if (_get(this.props, "pendingStocks", []).length) {
      this.setState({
        stocksList: JSON.parse(JSON.stringify(this.props.pendingStocks)),
      });
    }
  }

  handleInputChange = ({ index, name, value }) => {
    const { stocksList } = this.state;
    stocksList[index][name] = value;
    this.setState({ stocksList });
  };

  checkRequireSave = () => {
    let editedCount = 0;
    if (this.props.pendingStocks.length && this.state.stocksList.length) {
      this.props.pendingStocks.forEach((p, i) => {
        if (
          this.state.stocksList.length &&
          this.state.stocksList[i]["rate"] !== p["rate"]
        ) {
          editedCount++;
        }
      });
    }
    return editedCount !== 0;
  };

  render() {
    const { noteText, onSubmit, transationSubmitLoading } = this.props;
    const { stocksList } = this.state;
    const requireSave = this.checkRequireSave();
    let submitButtonText = "Submit";
    if (requireSave) {
      submitButtonText = "Save & Submit";
    }
    return (
      <Box>
        <Box
          sx={{
            textAlign: "center",
            maxWidth: "415px",
            m: "0 auto 30px auto",
          }}
        >
          <img
            src="/images/icon-transaction-confirm.png"
            alt="alert-icon"
            width="28px"
          />

          <Text
            variant="subtitle2"
            sx={{ color: "#1D9BFF", mb: "20px", fontWeight: "600" }}
          >
            Transaction Confirmation
          </Text>
        </Box>
        <Box
          sx={{
            maxHeight: "260px",
            minHeight: "70px",
            overflow: "auto",
            mb: "20px",
            border: "1px solid #e0e0e0",
            borderRadius: "6px",
          }}
        >
          <Table
            data={this.props.pendingStocks}
            columns={this.columns}
            onDataChange={(updatedData) => {
              this.setState({ stocksList: updatedData })
            }}
            td_sx={{
              fontSize: "14px",
              padding: {xs: "10px 5px", sm: "10px"},
              borderRight: "1px solid rgb(241, 241, 241)",
              borderBottom: "none",
              fontWeight: "500",
            }}
            th_sx={{
              borderBottom: "1px solid rgb(224, 224, 224)",
              textAlign: "left",
              color: "rgb(128, 133, 145)",
              background: "rgb(241, 241, 241)",
              fontSize: "12px",
              fontWeight: " unset",
              padding: {xs: "1px 5px",sm:"1px 10px"},
              whiteSpace: "nowrap",
              borderRight: "1px solid rgb(241, 241, 241)",
            }}
          />
        </Box>

        {noteText ? (
          <Stack direction="row" sx={{ mb: "20px" }}>
            <Text
              variant="body2"
              sx={{ width: "50px", lineHeight: 1, flex: "0 0 50px" }}
            >
              Note:{" "}
            </Text>
            <Text sx={{ color: "#999999", fontSize: "13px" }}>{noteText}</Text>
          </Stack>
        ) : null}

        <Box textAlign="center">
          <JarvisButton
            sx={{ fontSize: "16px", p: "8px 25px" }}
            onClick={() => onSubmit(stocksList, requireSave)}
            loading={transationSubmitLoading}
          >
            {submitButtonText}
          </JarvisButton>
        </Box>
      </Box>
    );
  }
}

export default PortfolioTransationConfirmation;
