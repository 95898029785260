import { config } from "config/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import _get from "lodash.get";

const mapStateToProps = (state) => {
    const basePrice= window.location.href.includes('bnrathi')?'1,000':"3,999"
    const basePlanPrice = "1999";
    return {
        name: state.customer.loginProfile.name,
        currentStage: state.onboarding.currentStage,
        currentStageForProtect: state.jarvisRMS.rmsOnboarding.currentStage,
        currentStageForOnestock: state.jarvisOnestock.onestockOnboarding.currentStage,
        activeProductList: _get(state.customer, 'loginProfile.activeProductList', [config.productCodes.PORTFOLIO, config.productCodes.RMS]),

        portfolioOverviewList: [
            { id: 1, stageNumber: 1, isOpen: false, name: 'Risk assessment', toolTipText: 'Jarvis will understand your risk-taking ability and will suggest an equity portfolio accordingly' },
            { id: 2, stageNumber: 2, isOpen: false, name: 'Investment horizon and amount', toolTipText: 'Jarvis will understand how long you want to stay invested, the amount, and strategy, and will suggest an equity portfolio accordingly.' },
            { id: 3, stageNumber: 3, isOpen: false, name: 'Personalised portfolio', toolTipText: 'If you are unique, then why can’t your portfolio be? Jarvis will create an exclusive and personalised portfolio based on your risk-taking capacity and your investment horizon.' },
            { id: 4, stageNumber: 3, isOpen: false, name: 'Payment ', toolTipText: `All you have to do is pay Rs. ${basePrice} to reveal the portfolio generated by Jarvis with the help of artificial intelligence.` },
            { id: 5, stageNumber: 4, isOpen: false, name: 'CKYC', toolTipText: ' An initiative by the Indian government to set up a system that will help you perform your KYC just once before interacting with different companies in the financial industry, like us.' },
            { id: 6, stageNumber: 5, isOpen: false, name: 'Link broker', toolTipText: " Now that your portfolio has been generated and CKYC is done, it's time for you to link your broking account and place the order to buy the stocks recommended by Jarvis." },
        ],
        protectOverviewList: [
            { id: 1, stageNumber: 1, isOpen: false, name: 'Risk assessment', toolTipText: 'Jarvis will understand your risk-taking ability and will suggest sell calls on your equity portfolio accordingly' },
            { id: 2, stageNumber: 2, isOpen: false, name: 'Upload or Sync Portfolio', toolTipText: 'Upload Your Portfolio.' },
            { id: 3, stageNumber: 3, isOpen: false, name: 'Payment ', toolTipText: `All you have to do is pay Rs. ${basePlanPrice}  to reveal the list of sell call recommendations on your equity portfolio generated by Jarvis with the help of artificial intelligence.` },
            { id: 4, stageNumber: 4, isOpen: false, name: 'CKYC', toolTipText: ' An initiative by the Indian government to set up a system that will help you perform your KYC just once before interacting with different companies in the financial industry, like us.' },
        ],
        onestockOverviewList: [
            { id: 1, stageNumber: 1, isOpen: false, name: 'Risk assessment', toolTipText: 'Jarvis will understand your risk-taking ability and will suggest sell calls on your equity portfolio accordingly' },
            { id: 2, stageNumber: 2, isOpen: false, name: 'Payment ', toolTipText: `To start receiving our buy and sell call recommendations, simply pay Rs. ${basePlanPrice}  and start receiving our buy and sell recommendations.` },
            { id: 3, stageNumber: 3, isOpen: false, name: 'CKYC', toolTipText: ' An initiative by the Indian government to set up a system that will help you perform your KYC just once before interacting with different companies in the financial industry, like us.' },
        ]
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPortfolioOnboardingDataAction: onboardingActions.getOnboardingData,
    getOnestockOnboardingDataAction: jarvsiOnestockOnboardingActions.getOnboardingData,
    getRMSOnboardingData: jarvsiRMSOnboardingActions.getOnboardingData,
}, dispatch);

const ProductInfoStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default ProductInfoStore;
