import React from "react";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { dateTimeHelper } from "helper/dateTime";
import { formatHelper } from "helper/format";

const planStatusMap = {
  ACTIVATED: {
    label: "Active Plan",
    color: "#45AC70",
    icon: () => <CheckCircleOutlineRounded sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Next billing date: ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
  EXPIRED: {
    label: "Expired",
    color: "#d44c4c",
    icon: () => <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Your subscription has expired on ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
  CANCELLED: {
    label: "Cancelled",
    color: "#df873a",
    icon: () => <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Your subscription has cancelled on ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
  PAUSED: {
    label: "Paused",
    color: "#df873a",
    icon: () => <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Your subscription has paused on ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
}

const ProductDetailsCard = ({
  planStatus,
  // showCheckCircle = true,
  billingStatus,
  productName,
  productAmount = 0,
  productTenure,
  actionName,
  onActionPress,
  autoPayEnabled = null,
  forProtect = false,
}) => {
  return (
    <>
      <Box
        height="100%"
        sx={{
          border: "1px solid #DEDEDE",
          borderRadius: "8px",
          height: "100%",
          backgroundColor: "#ffffff"
        }}
      >
        {/* Header */}
        <Box
          sx={{
            px: { xs: 1, sm: 2, md: 3 },
            py: 1,
            borderBottom: "1px solid #EFEFEF",
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                color: planStatusMap[planStatus] ? planStatusMap[planStatus].color : "#df873a",
              }}
            >
              {/* {showCheckCircle ? ( */}
              {/* ) : null} */}
              {planStatusMap[planStatus] ? planStatusMap[planStatus].icon() : <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />}
              <Text sx={{ fontSize: { xs: "12px", md: "1rem" }, fontWeight: 500, minWidth: "90px" }} pl={1}>
                {planStatusMap[planStatus] ? planStatusMap[planStatus].label : planStatus}
              </Text>
            </Stack>
            {
              <Text sx={{ color: "#A2A7AE", fontSize: { xs: "12px", md: "0.9rem" }, textAlign: { xs: "left", md: "center" } }}>
                {planStatusMap[planStatus] ? planStatusMap[planStatus].paymentDateInfo(billingStatus) : `Your next payment is due on ${dateTimeHelper.displayDate(billingStatus)}`}
              </Text>
            }
          </Stack>
        </Box>
        {/* Content */}
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            px: { xs: 1, sm: 2, md: 3 },
            py: { xs: 1, sm: 2, md: 3 },
            mt: { xs: 2, md: "unset" },
            mb: { xs: 1, md: "unset" },
          }}
        >
          <Box
            sx={{
              textAlign: { xs: "center", sm: "center", md: "flex-start" },
            }}
          >
            <Stack>
              <Text
                sx={{ fontWeight: 400, color: "#212225", fontSize: { xs: "14px", sm: "1rem", md: "1.5rem" } }}
              >
                {productName}
              </Text>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {autoPayEnabled === false ? (
                  <>
                    <Text
                      sx={{ fontWeight: 700, color: "#212225", fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
                    >
                      Half Yearly
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      sx={{ fontWeight: 700, color: "#212225", fontSize: { xs: "16px", sm: "1.2rem", md: "1.9rem" } }}
                    >
                      {formatHelper.currency(productAmount)}
                    </Text>
                    <Text
                      sx={{ fontWeight: 400, color: "#212225", fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
                    >
                      /{
                        productTenure === "MONTHLY" ? "month" : "half Year"
                      }
                    </Text>
                  </>
                )}
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              textAlign: { xs: "center", sm: "center", md: "flex-end" },
            }}
          >
            <Stack sx={{ pt: { xs: 0, sm: 0, md: 7 } }}>
              <Text
                variant="small"
                color="#1D9BFF"
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  mt: { xs: "20px", md: "unset" },
                  fontSize: { xs: "12px", md: "14px" }
                }}
                onClick={(e) => {
                  onActionPress(e);
                }}
              >
                {actionName}
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ProductDetailsCard;
