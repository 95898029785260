import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return {
        currentStage: state.jarvisRMS.rmsOnboarding.currentStage,
        tempCustomerPortfolioId: state.jarvisRMS.rmsOnboarding.portfolioId,
        isRMSOnboardingCompleted: state.jarvisRMS.rmsOnboarding.isOnboardingCompleted,
        panNumber: state.jarvisRMS.rmsOnboarding.panNumber,
        dateOfBirth: state.jarvisRMS.rmsOnboarding.dateOfBirth,
        isPanUploaded: state.jarvisRMS.rmsOnboarding.isPanUploaded,
        isAadhaarFrontUploaded:
            state.jarvisRMS.rmsOnboarding.isAadhaarFrontUploaded,
        isAadhaarBackUploaded:
            state.jarvisRMS.rmsOnboarding.isAadhaarBackUploaded,
        isManualKYC: state.jarvisRMS.rmsOnboarding.isManualKYC,
        loginProfile: state.customer.loginProfile,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRMSOnboardingDataAction:
                jarvsiRMSOnboardingActions.getOnboardingData,
            updateRMSOnboardingData:
                jarvsiRMSOnboardingActions.updateRMSOnboardingData,
            setToast: commonActions.setToast,
        },
        dispatch
    );

const RMSCkycStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default RMSCkycStore;
