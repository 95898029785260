import { axios } from "helper/axios";
import { investMoreConstants } from "./investMoreConstants";

const investMoreApis = {};

investMoreApis.getInvestmentPricingDetails = ({ investmentAmount }) => {
    return axios.post(investMoreConstants.INVESTMENT_PRICING_DETAILS_API, {
        investmentAmount,
    });
};

investMoreApis.submitInvestMore = ({ investmentAmount }) => {
    return axios.post(investMoreConstants.SUBMIT_INVEST_MORE_API, {
        investmentAmount,
    });
};

investMoreApis.getInvestmentStocks = () => {
    return axios.get(investMoreConstants.INVESTMENT_STOCKS_API);
};

export { investMoreApis };
