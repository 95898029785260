
const jarvisRMSExistingHoldingConstants = {
    EXISTING_HOLDING_PORTFOLIO_LIST_API: "/rms/portfolio",
    EXISTING_HOLDING_STOCK_LIST_API: "/rms/portfolio",
    EXISTING_HOLDING_UPDATE_PORTFOLIONAME_API: "/rms/portfolio",
    EXISTING_HOLDING_ADD_STOCK_TO_PORTFOLIO_API: "/rms/portfolio",
    EXISTING_HOLDING_REMOVE_STOCK_TO_PORTFOLIO_API: "/rms/portfolio",
    EXISTING_HOLDING_REMOVE_PORTFOLIO_API: "/rms/portfolio",
    EXISTING_HOLDING_EDIT_STOCK_API: "/rms/portfolio",

    JARVIS_RMS_RESET_EXISTING_HOLDING_DATA : "JARVIS_RMS_RESET_EXISTING_HOLDING_DATA",

    EXISTING_HOLDING_RESPONSE: "EXISTING_HOLDING_RESPONSE",
    EXISTING_HOLDING_REQUEST: "EXISTING_HOLDING_REQUEST",
    EXISTING_HOLDING_SAGA: "EXISTING_HOLDING_SAGA",
    EXISTING_HOLDING_API: "v1/portfolio/allocations",

	STOCK_LIST_RESPONSE: "STOCK_LIST_RESPONSE",
    STOCK_LIST_REQUEST: "STOCK_LIST_REQUEST",
    STOCK_LIST_SAGA: "STOCK_LIST_SAGA",
    STOCK_LIST_API: "v1/portfolio/allocations",

};

export { jarvisRMSExistingHoldingConstants };
