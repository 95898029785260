import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { dashboardConstants } from "./dashboardConstants";

const initialState = {
    portfolioAllocation: [],
    portfolioAllocationCount: 0,
    portfolioOverview: [],
    portfolioPerformance: {},
    portfolioChart: {},
    portfolioSector: [],
    portfolioMarket: [],

    customerStoryList: [],
    welcomeVideoList: [],

    portfolioAlert: {},
    portfolioPendingTransaction: {},

    loading: {
        portfolioAllocation: 0,
        portfolioOverview: 0,
        portfolioPerformance: 0,
        portfolioChart: 0,
        portfolioSector: 0,
        portfolioMarket: 0,
        customerStoryList: 0,
        welcomeVideoList: 0,
        portfolioPendingTransaction: 0,
    },
};

export const dashboardReducers = persistReducer(
    {
        storage,
        key: "dashboard",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case dashboardConstants.RESET_DASHBOARD_DATA: {
                return initialState;
            }

            case dashboardConstants.PORTFOLIO_ALLOCATIONS_REQUEST: {
                return {
                    ...state,
                    portfolioAllocation: [],
                    portfolioAllocationCount: 0,
                    loading: {
                        ...state.loading,
                        portfolioAllocation: 1,
                    },
                };
            }

            case dashboardConstants.PORTFOLIO_ALLOCATIONS_RESPONSE: {
                const { data, count } = payload;
                return {
                    ...state,
                    portfolioAllocation: data,
                    portfolioAllocationCount: count,
                    loading: {
                        ...state.loading,
                        portfolioAllocation: 0,
                    },
                };
            }
            case dashboardConstants.PORTFOLIO_OVERVIEW_REQUEST: {
                return {
                    ...state,
                    portfolioOverview: [],
                    loading: {
                        ...state.loading,
                        portfolioOverview: 1,
                    },
                };
            }

            case dashboardConstants.PORTFOLIO_OVERVIEW_RESPONSE: {
                return {
                    ...state,
                    portfolioOverview: payload,
                    loading: {
                        ...state.loading,
                        portfolioOverview: 0,
                    },
                };
            }

            // Performance
            case dashboardConstants.PORTFOLIO_PERFORMANCE_REQUEST: {
                return {
                    ...state,
                    portfolioPerformance: initialState.portfolioPerformance,
                    loading: {
                        ...state.loading,
                        portfolioPerformance: 1,
                    },
                };
            }

            case dashboardConstants.PORTFOLIO_PERFORMANCE_RESPONSE: {
                return {
                    ...state,
                    portfolioPerformance: payload,
                    loading: {
                        ...state.loading,
                        portfolioPerformance: 0,
                    },
                };
            }

            // Chart
            case dashboardConstants.PORTFOLIO_CHART_REQUEST: {
                return {
                    ...state,
                    portfolioChart: initialState.portfolioChart,
                    loading: {
                        ...state.loading,
                        portfolioChart: 1,
                    },
                };
            }

            case dashboardConstants.PORTFOLIO_CHART_RESPONSE: {
                return {
                    ...state,
                    portfolioChart: payload,
                    loading: {
                        ...state.loading,
                        portfolioChart: 0,
                    },
                };
            }

            // Sector
            case dashboardConstants.PORTFOLIO_SECTOR_REQUEST: {
                return {
                    ...state,
                    portfolioSector: initialState.portfolioSector,
                    loading: {
                        ...state.loading,
                        portfolioSector: 1,
                    },
                };
            }

            case dashboardConstants.PORTFOLIO_SECTOR_RESPONSE: {
                return {
                    ...state,
                    portfolioSector: payload,
                    loading: {
                        ...state.loading,
                        portfolioSector: 0,
                    },
                };
            }

            // Market Cap
            case dashboardConstants.PORTFOLIO_MARKET_REQUEST: {
                return {
                    ...state,
                    portfolioMarket: initialState.portfolioMarket,
                    loading: {
                        ...state.loading,
                        portfolioMarket: 1,
                    },
                };
            }

            case dashboardConstants.PORTFOLIO_MARKET_RESPONSE: {
                return {
                    ...state,
                    portfolioMarket: payload,
                    loading: {
                        ...state.loading,
                        portfolioMarket: 0,
                    },
                };
            }

            case dashboardConstants.CUSTOMER_STORIES_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        customerStoryList: 1,
                    },
                    customerStoryList: initialState.customerStoryList,
                };
            }

            case dashboardConstants.CUSTOMER_STORIES_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        customerStoryList: 0,
                    },
                    customerStoryList: payload,
                };
            }

            case dashboardConstants.WELCOME_VIDEO_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        welcomeVideoList: 1,
                    },
                    welcomeVideoList: initialState.welcomeVideoList,
                };
            }

            case dashboardConstants.WELCOME_VIDEO_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        welcomeVideoList: 0,
                    },
                    welcomeVideoList: payload,
                };
            }

            // Jarvis Alert Card
            case dashboardConstants.PORTFOLIO_ALERT_REQUEST: {
                return {
                    ...state,
                    portfolioAlert: initialState.portfolioAlert,
                };
            }

            case dashboardConstants.PORTFOLIO_ALERT_RESPONSE: {
                return {
                    ...state,
                    portfolioAlert: payload,
                };
            }

            // Portfolio Pending Transaction
            case dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfolioPendingTransaction: 1,
                    },
                    portfolioPendingTransaction: initialState.portfolioAlert,
                };
            }

            case dashboardConstants.PORTFOLIO_PENDING_TRANSACTION_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfolioPendingTransaction: 0,
                    },
                    portfolioPendingTransaction: payload,
                };
            }



            default:
                return state;
        }
    }
);
