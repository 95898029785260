import _get from "lodash/get";
import { formatHelper } from "helper/format";
import { config } from "config";

const commonParsers = {};

commonParsers.blogs = (res) => {
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return [];
    }

    return res.map((e) => ({
        imageUrl: _get(e, "image", null),
        title: _get(e, "title", null),
        description: _get(e, "description", ""),
        readMoreLink: _get(e, "link", ""),
        author: _get(e, "author", 1),
    }));
};

commonParsers.riskAssessmentQuestions = (res) => {
    let data = [];

    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.length) {
        return data;
    }

    const parseOptions = (options) =>
        options.map((o) => ({
            questionId: _get(o, "questionId", null),
            id: _get(o, "id", null),
            slug: _get(o, "slug", null),
            name: _get(o, "name", null),
            weightage: _get(o, "weightage", null),
        }));

    data = res.map((r) => ({
        id: _get(r, "id", null),
        slug: _get(r, "slug", null),
        name: _get(r, "name", null),
        options: parseOptions(_get(r, "options", [])),
    }));

    return data;
};

commonParsers.riskProfiles = (res) => {
    let data = [];

    if (res && res.response) {
        res = res.response;
    }

    if (!res || !res.length) {
        return data;
    }

    data = res.map((r) => ({
        id: _get(r, "id", null),
        name: _get(r, "name", null),
        riskLevelValue: _get(r, "riskLevelValue", null),
        description: _get(r, "description", null),
    }));

    return data;
};

commonParsers.brokers = (res) => {
    let data = [];

    if (res && res.response) {
        res = res.response;
    }

    if (res && res.list) {
        res = res.list;
    }

    if (!res || !res.length) {
        return data;
    }

    data = res.map((r) => ({
        name: _get(r, "BrokerName", null),
        id: _get(r, "brokerId", null),
        displayName: _get(r, "DisplayName", null),
        imageUrl: formatHelper.getLegacyImageURI(_get(r, "Icon_URL", null)),
        platform: _get(r, "BrokerPlatform", null),
        code: _get(r, "code", null),
        alertMessage: _get(r, "AlertReason", null),
        hasAlert: _get(r, "hasAlert", null),
    }));
    return data;
};

commonParsers.marketUpdate = (res) => {
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return [];
    }

    const data = res.map((e) => ({
        imageUrl: _get(e, "image", null),
        title: _get(e, "title", null),
        description: _get(e, "description", ""),
        readMoreLink: _get(e, "link", ""),
        author: _get(e, "author", 1),
    }));

    return data;
};

commonParsers.newsArticles = (res) => {
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return {};
    }
    const resData = res.data;
    const data = resData.map((e) => ({
        id: _get(e, "id", null),
        title: _get(e, "title", null),
        description: _get(e, "content", null),
        caption: _get(e, "caption", null),
        date: _get(e, "date", null),
    }));

    return {
        newsList: data,
        count: res.total
    };
};

commonParsers.appConfig = (res) => {
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return {};
    }
    const d = {
        forceUpdate: _get(res, "forceUpdate", null),
        message: _get(res, "message", null),
        status: _get(res, "status", null),
    };

    return d;
};

commonParsers.trackerConfig = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res) {
        return {};
    }

    return {
        mixpanel: {
            token: "",
            platform: [],
        },
        uxcam: {
            apiKey: "",
            platform: "",
        },
        appsflyer: {
            appId: {
                ANDROID: "",
                IOS: "",
                WEB: "",
            },
            devKey: "",
            platform: "",
        },
    };

    // return {
    //     mixpanel: {
    //         token: _get(res, "trackerConfig.mixpanel.token", ""),
    //         platform: _get(res, "trackerConfig.mixpanel.platform", []),
    //     },
    //     uxcam: {
    //         apiKey: _get(res, "trackerConfig.uxcam.apiKey", ""),
    //         platform: _get(res, "trackerConfig.uxcam.platform", ""),
    //     },
    //     appsflyer: {
    //         appId: {
    //             ANDROID: _get(res, "trackerConfig.appsflyer.appId.ANDROID", ""),
    //             IOS: _get(res, "trackerConfig.appsflyer.appId.IOS", ""),
    //             WEB: _get(res, "trackerConfig.appsflyer.appId.WEB", ""),
    //         },
    //         devKey: _get(res, "trackerConfig.appsflyer.devKey", ""),
    //         platform: _get(res, "trackerConfig.appsflyer.platform", []),
    //     },
    // };
};

commonParsers.featureConfig = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res) {
        return {};
    }

    return {
        enableAccountDeactivation: _get(res, "enableAccountDeactivation", []),
    };
}


commonParsers.getRiskAssessmentAnswers = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res) {
        return {};
    }

    return res.map(r => ({
        optionId: _get(r, "optionId", ""),
        questionId: _get(r, "questionId", ""),
        optionLabel: _get(r, "optionLabel", ""),
        questionLabel: _get(r, "questionLabel", ""),
    }))
}

commonParsers.getPaymentMethods = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res) {
        return {};
    }
    const eligibleUpiHandles = [];

    const data = {
        eligiblePaymentMethods: _get(res, "eligiblePaymentMethods", []).map(epm => ({
            label: _get(epm, "label", ""),
            value: _get(epm, "value", ""),
            helperText: _get(epm, "helperText", ""),
        })),
        eligibleAuthTypes: _get(res, "eligibleAuthTypes", []).map(eat => ({
            label: _get(eat, "label", ""),
            value: _get(eat, "value", ""),
            helperText: _get(eat, "helperText", ""),
        })),
        upiOptions: _get(res, "upiOptions", []).map(uo => {
            eligibleUpiHandles.push(...(_get(uo, "eligibleUpiOptions", [])))
            return {
                label: _get(uo, "label", ""),
                value: _get(uo, "value", ""),
                helperText: _get(uo, "helperText", ""),
                eligibleUpiOptions: _get(uo, "eligibleUpiOptions", []),
            }
        }),
        emandateOptions: _get(res, "emandateOptions", []).map(eo => ({
            label: _get(eo, "label", ""),
            value: _get(eo, "value", ""),
            helperText: _get(eo, "helperText", ""),
            supportedAuthTypes: _get(eo, "supportedAuthTypes", [])
        })),
    }
    return {
        ...data,
        eligibleUpiHandles,
    }
}

commonParsers.getFeatureConfig = (res) => {
    if (res && res.response) {
        res = res.response;
    }

    if (!res) {
        return {};
    }

    const platform = config.platform;

    return {
        enableRazorpayTPV: _get(res, "enableRazorpayTPV", []).includes(platform),
        protectPortfolioUploadTemplate: _get(res, "protectPortfolioUploadTemplate", ''),
        enableAccountDeactivation: _get(res, "enableAccountDeactivation", []).includes(platform),
        enableCouponDiscount: _get(res, "enableCouponDiscount", []).includes(platform),
        centrumReferalURL: _get(res, "centrumReferalURL", [])
    }
}

export { commonParsers };
