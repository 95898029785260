import React, { useState, useEffect } from "react";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import JarvisButton from "components/JarvisButton";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import { validationHelper } from "helper/validation";

const useStyles = makeStyles(() => ({
  otpContainer: {
    height: "56px",
    width: "100%",
  },
  otpInput: {
    height: "56px",
    width: "57px",
    border: "1px solid #E0E7FF",
    flex: "1",
  },
  otpResend: {
    color: "#72aaff",
    background: "transparent !important",
    padding: "0",
    boxShadow: "none !important",
    fontSize: "12px",
    display: "inline",
    textTransform: "none",
    cursor: "pointer",
    minWidth: "auto",
    marginRight: "8px",
  },
}));

const MobileVerification = (props) => {
  const classes = useStyles();
  const {
    formManage,
    onRequestOtp,
    onResendOtp,
    onSubmit,
    isLoading,
    hasSkipOption,
    onSkipMobile,
  } = props;

  const [resendOtpInterval, setResendOtpInterval] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [formData, setFormData] = useState({
    mobile: "",
    otp: "",
  });
  const [formError, setFormError] = useState({
    mobile: "",
    otp: "",
  });
  const [resendTime, setResendTime] = useState(0);
  const otpRequestTimer = 30;

  useEffect(() => {
    setResendTime((prev) => prev - 1);
    resendTime <= 0 && clearInterval(resendOtpInterval);
  }, [refresh]);

  const handleChange = (name, value) => {
    if (name === "mobile" && isNaN(value)) {
      return;
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRequestOtp = () => {
    const mobileValidation = validationHelper.mobile(formData.mobile);
    if (mobileValidation.isValid) {
      setFormError((prev) => ({
        ...prev,
        mobile: mobileValidation.message,
      }));
      onRequestOtp(formData.mobile, () => {
        resendTimer();
      });
    } else {
      setFormError((prev) => ({
        ...prev,
        mobile: mobileValidation.message,
      }));
    }
  };

  const handleResendOtp = () => {
    onResendOtp(formData.mobile, () => {
      resendTimer();
    });
  };

  const resendTimer = () => {
    if (resendTime > 0) {
      return;
    }
    setResendTime(otpRequestTimer);
    const interval = setInterval(() => {
      setRefresh(Math.random());
    }, 1000);
    setResendOtpInterval(interval);
  };

  return (
    <Stack alignItems="center" sx={{ px: 2, pt: 1 }}>
      {!formManage.isRequestOtp && formManage.email ? (
        <TextField
          disabled={true}
          label="Email"
          value={formManage.email}
          sx={{ width: 1, mb: "20px" }}
        />
      ) : null}

      <TextField
        disabled={formManage.isRequestOtp}
        type="number"
        label="Mobile Number"
        value={formData.mobile}
        onChange={(e) => handleChange("mobile", e.target.value)}
        helperText={formError.mobile}
        error={formError.mobile}
        sx={{ width: 1 }}
      />
      {formManage.isRequestOtp && !formManage.mobileVerified ? (
        <Stack sx={{ width: 1, mt: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Text
              variant="caption1"
              sx={{
                wordBreak: "break-word",
                marginRight: "10px",
              }}
            >
              Enter OTP sent on {formData.mobile}
            </Text>
            <Text
              variant="caption1"
              className={classes.resendText}
              sx={{
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
              }}
            >
              <Button
                component="span"
                className={classes.otpResend}
                onClick={handleResendOtp}
                disabled={resendTime > 0 ? true : false}
                loading={isLoading}
              >
                Resend
              </Button>
              {resendTime > 0 ? ` in 00:${resendTime}` : null}
            </Text>
          </Stack>
          <OtpInput
            className={classes.otpContainer}
            inputStyle={classes.otpInput}
            value={formData.otp}
            numInputs={6}
            isInputNum={true}
            onChange={(value) => handleChange("otp", value)}
            separator={<span>&nbsp;</span>}
          />
        </Stack>
      ) : null}
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%", mt: 3 }}
      >
        {formManage.isRequestOtp ? (
          <JarvisButton
            sx={{ mt: 3 }}
            onClick={() => onSubmit(formData.mobile, formData.otp)}
            loading={isLoading}
            disabled={formData?.otp?.length !== 6}
          >
            Verify Otp
          </JarvisButton>
        ) : (
          <Stack
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <JarvisButton
              sx={{ mt: 3 }}
              onClick={handleRequestOtp}
              loading={isLoading}
            >
              Request OTP
            </JarvisButton>

            {/* Skip Mobile for login */}
            {hasSkipOption ? (
              <Text
                onClick={onSkipMobile}
                sx={{
                  marginTop: "13px",
                  fontSize: "13px",
                  color: "#9e9e9e",
                  cursor: "pointer",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#737373",
                  }
                }}
              >
                Skip Mobile
              </Text>
            ) : null}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default MobileVerification;
