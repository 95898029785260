import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
    const basePrice=window.location.href.includes('bnrathi')?'1,000':'1,999'
    return {
        name: state.customer.loginProfile.name,
        currentStageForOnestock: state.jarvisOnestock.onestockOnboarding.currentStage,

        onestockOverviewList: [
            { id: 1, stageNumber: 1, isOpen: false, name: 'Risk assessment', toolTipText: 'Jarvis will understand your risk-taking ability and will suggest sell calls on your equity portfolio accordingly' },
            { id: 2, stageNumber: 2, isOpen: false, name: 'Payment ', toolTipText: `To start receiving our buy and sell call recommendations, simply pay Rs. ${basePrice} and start receiving our buy and sell recommendations.` },
            { id: 3, stageNumber: 3, isOpen: false, name: 'CKYC', toolTipText: ' An initiative by the Indian government to set up a system that will help you perform your KYC just once before interacting with different companies in the financial industry, like us.' },
        ]
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const OnestockOnboardingNotCompletedStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockOnboardingNotCompletedStore;
