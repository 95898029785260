import React from 'react'
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisChipNew from 'components/JarvisChipNew';
import { dateTimeHelper } from 'helper/dateTime';
import { formatHelper } from 'helper/format';

const MobileTransactionCards = (props) => {
    const { data } = props
    return (
        <>
            <Stack sx={{ fontWeight: "400" }}>
                <Stack mb={1} direction="row" sx={{ justifyContent: "space-between" }}>
                    <Text variant="caption2" mr={1}><span style={{ color: "#A2A7AE" }}>Transaction date&nbsp; </span>
                        {
                            data?.createdAt ?
                                dateTimeHelper.format(data?.createdAt, '$DD/$MM/$YYYY')
                                : "NA"
                        }
                    </Text>
                    {
                        <Text variant="caption2">
                            {
                                data?.paymentStatus === "COMPLETED" ?
                                    <JarvisChipNew sx={{ padding: "0px", marginTop: "-2px", fontSize: "0.65rem" }} size="small" label="Completed" color="success" />
                                    :
                                    data?.paymentStatus === "FAILED" ?
                                        <JarvisChipNew sx={{ padding: "0px", marginTop: "-2px", fontSize: "0.65rem" }} size="small" label="Failed" color="error" />
                                    :
                                    data?.paymentStatus === "REFUNDED" ?
                                    <JarvisChipNew sx={{ padding: "0px", marginTop: "-2px", fontSize: "0.65rem" }} size="small" label="Refunded" color="default" />
                                    :
                                    data?.paymentStatus === "PARTIAL" ?
                                        <JarvisChipNew sx={{ padding: "0px", marginTop: "-2px", fontSize: "0.65rem" }} size="small" label="Partial Refund" color="warning" />
                                        : "NA"
                            }
                        </Text>
                    }
                </Stack>
                <Stack mb={1} direction="row" sx={{ justifyContent: "space-between", fontWeight: "500" }}>
                    <Text variant="small" sx={{ maxWidth: "50%" }}>{data?.planName}</Text>
                    <Text variant="small">
                        {
                            data?.paymentAmount ?
                                formatHelper.currency(data?.paymentAmount)
                                :
                                formatHelper.currency(0)
                        }
                    </Text>
                </Stack>
                <Stack mb={1} direction="row" sx={{ justifyContent: "space-between" }}>
                    <Text variant="caption2" mr={1} sx={{ minWidth: "60%" }}>
                        <span style={{ color: "#A2A7AE" }}>
                            Plan status  &nbsp; <span style={{ textTransform: "capitalize", fontSize: "10px" }}> {data?.planStatus} </span>
                        </span>
                    </Text>
                </Stack>
            </Stack>
        </>
    )
}
export default MobileTransactionCards;

