// import { appsFlyerTracker } from "../appsFlyer";
// import { mixpanelTracker } from "../mixpanel";
// import { uxcamTracker } from "../uxcam";

const repairFlowTracker = {};
repairFlowTracker.completeYourPendingTransaction = () => {
    // const eventName = "complete_your_pending_transaction";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

repairFlowTracker.completeYourPendingTransactionFailed = ({ error_msg }) => {
    // const eventName = "complete_your_pending_transaction_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

repairFlowTracker.completeYourPendingTransactionSuccess = () => {
    // const eventName = "complete_your_pending_transaction_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

repairFlowTracker.repairYesContinue = () => {
    // const eventName = "repair_yes,_continue";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

repairFlowTracker.repairYesContinueFailed = ({ error_msg }) => {
    // const eventName = "repair_yes,_continue_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

repairFlowTracker.repairYesContinueSuccess = () => {
    // const eventName = "repair_yes,_continue_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

export { repairFlowTracker };
