import React from "react";
import RMSContainer from "containers/RMS";
import PageTitle from "components/PageTitle";
import { tracker } from "library/tracker";

const PortfolioActionCentre = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);

    return (
        <>
                <PageTitle
                    heading="Action Centre"
                    subheading="Rebalance alerts will appear here"
                />
                <RMSContainer />
        </>
    );
};

export default PortfolioActionCentre;
