import IconButton from 'components/common/IconButton';
import SectionTitle from 'components/SectionTitle';
import React from 'react';

import Drawer from "../common/Drawer";

import { Close as CloseIcon } from "@mui/icons-material";
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import JarvisChipNew from 'components/JarvisChipNew';
import DatePicker from 'components/common/DatePicker';
import JarvisButton from 'components/JarvisButton';

const OnestockTransactionListFilter = (props) => {
    const {
        open = false,
        onClose,
        onChange,
        onReset,
        onSubmit,
        filterData,
        transactionStatusOptions,
    } = props;

    return (
        <Drawer open={open} anchor="right" >
            <Stack justifyContent="space-between" sx={{ flex: 1, px: 2, py: 2, maxWidth: "320px" }}>
                <Stack>
                    <SectionTitle
                        heading="Filter"
                        endContent={() => <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>}
                    />

                    <Stack sx={{ mb: 2 }}>
                        <Text variant="small" fontWeight="medium" sx={{ mb: 1 }}>From Date</Text>
                        <DatePicker
                            label="From Date"
                            disableFuture={true}
                            value={filterData.fromDate}
                            onChange={(value) => onChange({ name: "fromDate", value })}
                        />
                    </Stack>

                    <Stack sx={{ mb: 2 }}>
                        <Text variant="small" fontWeight="medium" sx={{ mb: 1 }}>To Date</Text>
                        <DatePicker
                            label="To Date"
                            value={filterData.toDate}
                            disableFuture={true}
                            onChange={(value) => onChange({ name: "toDate", value })}
                        />

                    </Stack>

                    <Stack sx={{ mb: 2 }}>
                        <Text variant="small" fontWeight="medium" sx={{ mb: 1 }}>Status</Text>
                        <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap' }}>
                            {transactionStatusOptions.map((e, i) => (
                                <JarvisChipNew
                                    key={`transaction_status_${e.value}`}
                                    label={e.label}
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                    color={filterData.transactionStatus === e.value ? 'primary' : e.color}
                                    onClick={() => onChange({ name: 'transactionStatus', value: e.value })}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>

                <Stack>
                    <JarvisButton disableEndIcon sx={{ mb: 1 }} onClick={onSubmit}>Apply Filters</JarvisButton>
                    <JarvisButton disableEndIcon variant="text" onClick={onReset}>Reset All Filters</JarvisButton>
                </Stack>

            </Stack>
        </Drawer>
    )
}

export default OnestockTransactionListFilter;