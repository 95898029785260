import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisOnestockExistingHoldingConstants } from "./jarvisOnestockExistingHoldingConstants";


const initialState = {
    existingHoldingList: [],
    totalRecords: 0,
    loading: {
        existingHoldingList: 0,
    },
};

export const jarvisOnestockExistingHoldingReducer = persistReducer(
    {
        storage,
        key: "onestockExistingHolding",
        whitelist: [],
    },

    (state = initialState, { type, payload }) => {
        switch (type) {
            case jarvisOnestockExistingHoldingConstants.ONESTOCK_EXISTING_HOLDING_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        existingHoldingList: 1,
                    },
                };
            }

            case jarvisOnestockExistingHoldingConstants.ONESTOCK_EXISTING_HOLDING_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        existingHoldingList: 0,
                    },
                    existingHoldingList: payload.data,
                    totalRecords: payload.totalRecords,
                };
            }

            default:
                return state;
        }
    }
);
