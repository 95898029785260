export const dashboardConstants = {
    RESET_DASHBOARD_DATA: "RESET_DASHBOARD_DATA",

    PORTFOLIO_ALLOCATIONS_RESPONSE: "PORTFOLIO_ALLOCATIONS_RESPONSE",
    PORTFOLIO_ALLOCATIONS_REQUEST: "PORTFOLIO_ALLOCATIONS_REQUEST",
    PORTFOLIO_ALLOCATIONS_SAGA: "PORTFOLIO_ALLOCATIONS_SAGA",
    PORTFOLIO_ALLOCATIONS_API: "v1/portfolio/allocations",

    PORTFOLIO_OVERVIEW_REQUEST: "PORTFOLIO_OVERVIEW_REQUEST",
    PORTFOLIO_OVERVIEW_RESPONSE: "PORTFOLIO_OVERVIEW_RESPONSE",
    PORTFOLIO_OVERVIEW_SAGA: "PORTFOLIO_OVERVIEW_SAGA",
    PORTFOLIO_OVERVIEW_API: "v1/portfolio/overview",

    PORTFOLIO_PERFORMANCE_API: "v1/portfolio/performance",
    PORTFOLIO_PERFORMANCE_REQUEST: "PORTFOLIO_PERFORMANCE_REQUEST",
    PORTFOLIO_PERFORMANCE_RESPONSE: "PORTFOLIO_PERFORMANCE_RESPONSE",
    PORTFOLIO_PERFORMANCE_SAGA: "PORTFOLIO_PERFORMANCE_SAGA",

    PORTFOLIO_CHART_API: "v1/portfolio",
    PORTFOLIO_CHART_REQUEST: "PORTFOLIO_CHART_REQUEST",
    PORTFOLIO_CHART_RESPONSE: "PORTFOLIO_CHART_RESPONSE",
    PORTFOLIO_CHART_SAGA: "PORTFOLIO_CHART_SAGA",

    PORTFOLIO_SECTOR_API: "v1/portfolio/sector",
    PORTFOLIO_SECTOR_REQUEST: "PORTFOLIO_SECTOR_REQUEST",
    PORTFOLIO_SECTOR_RESPONSE: "PORTFOLIO_SECTOR_RESPONSE",
    PORTFOLIO_SECTOR_SAGA: "PORTFOLIO_SECTOR_SAGA",

    PORTFOLIO_MARKET_API: "v1/portfolio/market-cap",
    PORTFOLIO_MARKET_REQUEST: "PORTFOLIO_MARKET_REQUEST",
    PORTFOLIO_MARKET_RESPONSE: "PORTFOLIO_MARKET_RESPONSE",
    PORTFOLIO_MARKET_SAGA: "PORTFOLIO_MARKET_SAGA",

    CUSTOMER_STORIES_SAGA: "CUSTOMER_STORIES_SAGA",
    CUSTOMER_STORIES_REQUEST: "CUSTOMER_STORIES_REQUEST",
    CUSTOMER_STORIES_RESPONSE: "CUSTOMER_STORIES_RESPONSE",
    CUSTOMER_STORIES_API: "v1/home/testimonial",

    WELCOME_VIDEO_SAGA: "WELCOME_VIDEO_SAGA",
    WELCOME_VIDEO_REQUEST: "WELCOME_VIDEO_REQUEST",
    WELCOME_VIDEO_RESPONSE: "WELCOME_VIDEO_RESPONSE",
    WELCOME_VIDEO_API: "v1/home/welcome-youtube",

    PORTFOLIO_ALERT_API: "v1/stocks/pending-stocks-transaction",
    PORTFOLIO_ALERT_SAGA: "PORTFOLIO_ALERT_SAGA",
    PORTFOLIO_ALERT_REQUEST: "PORTFOLIO_ALERT_REQUEST",
    PORTFOLIO_ALERT_RESPONSE: "PORTFOLIO_ALERT_RESPONSE",

    PORTFOLIO_PENDING_TRANSACTION_API: "v1/stocks/pending-stocks-transaction-details",
    PORTFOLIO_PENDING_TRANSACTION_SAGA: "PORTFOLIO_PENDING_TRANSACTION_SAGA",
    PORTFOLIO_PENDING_TRANSACTION_REQUEST: "PORTFOLIO_PENDING_TRANSACTION_REQUEST",
    PORTFOLIO_PENDING_TRANSACTION_RESPONSE: "PORTFOLIO_PENDING_TRANSACTION_RESPONSE",

    PORTFOLIO_ARCHIVE_API: "v1/stocks/archive-pending-stocks-transaction",
    
    PORTFOLIO_GENERATE_API: "/v1/stocks/rms-rebalance-call",
    REPAIR_TRANSACTION_API: "/v1/stocks/repair-stocks-transaction",
    
    DASHBOARD_REFRESH_SAGA: "DASHBOARD_REFRESH_SAGA",

    FINAL_PORTFOLIO_CONFIRMATION:"v1/stocks/portfolio-confirmation",
};
