import { put, takeLatest, call } from "@redux-saga/core/effects";
import { customerConstants } from "./customerConstants";
import { onboardingConstants } from "stores/onboarding/onboardingConstants";
import { jarvisRMSOnboardingConstants } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingConstants";
import { jarvisRMSDashboardOnboardedConstants } from "stores/jarvisRMS/jarvisRMSDashboard/jarvisRMSDashboardonboardedConstants";
import { jarvisRMSExistingHoldingConstants } from "stores/jarvisRMS/jarvisRMSExistingHolding/jarvisRMSExistingHoldingConstants";
import { jarvisRMSTransactionConstants } from "stores/jarvisRMS/jarvisRMSTransaction/jarvisRMSTransactionConstants";
import { commonConstants } from "stores/common/commonConstants";
import { rmsConstants } from "stores/rms/rmsConstants";
import { investMoreConstants } from "stores/investMore/investMoreConstants";
import { dashboardConstants } from "stores/dashboard/dashboardConstants";
import { transactionConstants } from "stores/transactions/transactionConstants";
import { customerApis } from "./customerApis";
import { customerParsers } from "./customerParsers";
import { localStore } from "stores/localStorage";

// Library
import { tracker } from "library/tracker";
import { jarvisOnestockOnboardingConstants } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingConstants";

function* logoutSaga() {
    //event-tracker: On Cancel Payment(C Pay) -> logout
    // tracker.onCancelPayment.logout();
    //event-tracker: On Cancel Payment(C Pay) -> logout_success
    // tracker.onCancelPayment.logoutSuccess();
    tracker.logout();
    
    localStore.resetToken();
    localStore.resetLegacyToken();
    localStore.resetFeatureConfig();
    localStore.resetTrackerConfig();
    
    yield put({ type: customerConstants.RESET_AUTH_DATA });
    yield put({ type: onboardingConstants.RESET_ONBOARDING_DATA });
    yield put({ type: commonConstants.RESET_COMMON_DATA });
    yield put({ type: rmsConstants.RESET_RMS_DATA });
    yield put({ type: investMoreConstants.RESET_INVEST_MORE_DATA });
    yield put({ type: dashboardConstants.RESET_DASHBOARD_DATA });
    yield put({ type: transactionConstants.RESET_TRANSACTION_DATA });
    yield put({ type: jarvisRMSOnboardingConstants.RMS_RESET_ONBOARDING_DATA });
    yield put({ type: jarvisOnestockOnboardingConstants.ONESTOCK_RESET_ONBOARDING_DATA });
    yield put({ type: jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RESET_DASHBOARD_ONBOARDED_DATA });
    yield put({ type: jarvisRMSExistingHoldingConstants.JARVIS_RMS_RESET_EXISTING_HOLDING_DATA });
    yield put({ type: jarvisRMSTransactionConstants.JARVIS_RMS_RESET_TRANSACTION_DATA });
}

function* profileSaga(action) {
    try {
        yield put({ type: customerConstants.PROFILE_REQUEST });

        const res = yield call(customerApis.profile);

        const payload = customerParsers.profile(res);

        yield put({
            type: customerConstants.PROFILE_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: customerConstants.PROFILE_RESPONSE,
            payload: [],
        });
    }
}

function* getReasonDropdownForCancellationSaga(action) {
    try {
        yield put({ type: customerConstants.REASON_FOR_CANCELLATION_REQUEST });

        const res = yield call(customerApis.getReasonDropdownForCancellationApi);

        const payload = customerParsers.reasonDropdownForCancellation(res);

        yield put({
            type: customerConstants.REASON_FOR_CANCELLATION_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: customerConstants.REASON_FOR_CANCELLATION_RESPONSE,
            payload: [],
        });
    }
}

function* subscriptionListSaga() {
    try {
        yield put({ type: customerConstants.SUBSCRIPTION_LIST_REQUEST });

        const res = yield call(customerApis.getSubscriptionList);
        const payload = customerParsers.subscriptionParser(res);
        yield put({
            type: customerConstants.SUBSCRIPTION_LIST_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: customerConstants.SUBSCRIPTION_LIST_RESPONSE,
            payload: [],
        });
    }
}

function* renewalPricingDetailsSaga() {
    try {
        yield put({ type: customerConstants.RENEWAL_PRICING_DETAILS_REQUEST });
        //event-tracker: Renewal(R) -> renew
        tracker.renewal.renew();
        const res = yield call(customerApis.getRenewalPricingDetails);
        //event-tracker: Renewal(R) -> renew_success
        tracker.renewal.renewSuccess();
        const payload = customerParsers.renewalPricingDetails(res);
        yield put({
            type: customerConstants.RENEWAL_PRICING_DETAILS_RESPONSE,
            payload,
        });
    } catch (e) {
        //event-tracker: Renewal(R) -> renew_failed
        tracker.renewal.renewFailed();
        yield put({
            type: customerConstants.RENEWAL_PRICING_DETAILS_RESPONSE,
            payload: [],
        });
    }
}

function* productsSubscriptionListSaga() {
    try {
        yield put({ type: customerConstants.PRODUCT_SUBSCRIPTION_LIST_REQUEST });
        const res = yield call(customerApis.productSubscriptionListApi);
        const payload = customerParsers.productSubscriptionListParser(res);
        yield put({
            type: customerConstants.PRODUCT_SUBSCRIPTION_LIST_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: customerConstants.PRODUCT_SUBSCRIPTION_LIST_RESPONSE,
            payload: [],
        });
    }
}

function* productsSubscriptionDetailsSaga() {
    try {
        yield put({ type: customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_REQUEST });
        const res = yield call(customerApis.productSubscriptionDetailsApi);
        const parsedRes = customerParsers.productSubscriptionDetailstParser(res);
        yield put({
            type: customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_RESPONSE,
            payload: parsedRes,
        });
    } catch (e) {
        yield put({
            type: customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_RESPONSE,
            payload: [],
        });
    }
}

export function* registerCustomerSagas() {
    yield takeLatest(customerConstants.LOGOUT_SAGA, logoutSaga);
    yield takeLatest(customerConstants.PROFILE_SAGA, profileSaga);
    yield takeLatest(
        customerConstants.SUBSCRIPTION_LIST_SAGA,
        subscriptionListSaga
    );
    yield takeLatest(
        customerConstants.RENEWAL_PRICING_DETAILS_SAGA,
        renewalPricingDetailsSaga
    );
    yield takeLatest(customerConstants.REASON_FOR_CANCELLATION_SAGA, getReasonDropdownForCancellationSaga)
    yield takeLatest(customerConstants.PRODUCT_SUBSCRIPTION_LIST_SAGA, productsSubscriptionListSaga)
    yield takeLatest(customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_SAGA, productsSubscriptionDetailsSaga)
}
