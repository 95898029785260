import { axios } from "helper/axios";
import { jarvisOnestockOnboardingConstants } from "./jarvisOnestockOnboardingConstants";

const jarvisOnestockOnboardingApis = {};

jarvisOnestockOnboardingApis.getOnboardingData = () => {
    return axios.get(jarvisOnestockOnboardingConstants.ONESTOCK_ONBOARDING_DATA_API);
};

// TODO: move this api to master
jarvisOnestockOnboardingApis.submitRiskAssessmentQuestions = ({
    questionAnswers,
}) => {
    return axios.post(jarvisOnestockOnboardingConstants.ONESTOCK_RISK_ASSESSMENT_API, {
        questionAnswers,
    });
};

jarvisOnestockOnboardingApis.confirmRiskProfile = ({ riskProfileId }) => {
    return axios.post(
        jarvisOnestockOnboardingConstants.ONESTOCK_CONFIRM_RISK_PROFILE_API,
        {
            riskProfileId,
        }
    );
};

// STAGE 3

jarvisOnestockOnboardingApis.confirmOnestockCKYCData = () => {
    return axios.post(jarvisOnestockOnboardingConstants.ONESTOCK_CONFIRM_CKYC_DATA_API);
};

jarvisOnestockOnboardingApis.initiateDocumentSigning = () => {
    return axios.post(
        jarvisOnestockOnboardingConstants.ONESTOCK_INITIATE_DOCUMENT_SIGNING_API
    );
};



export { jarvisOnestockOnboardingApis };
