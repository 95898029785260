import { call, put, takeLatest } from "@redux-saga/core/effects";
import { jarvisRMSOnboardingApis } from "./jarvisRMSOnboardingApis";
import { jarvisRMSOnboardingParser } from "./jarvisRMSOnboardingParser";
import { jarvisRMSOnboardingConstants } from "./jarvisRMSOnboardingConstants";

function* getOnboardingDataSaga() {
    try {
        yield put({
            type: jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_REQUEST,
        });

        const res = yield call(jarvisRMSOnboardingApis.getOnboardingData);
        const payload = jarvisRMSOnboardingParser.onboardingData(res);

        yield put({
            type: jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_RESPONSE,
            payload: {},
        });
    }
}

function* planPricingDataSaga({ type }) {
    try {
        yield put({ type: jarvisRMSOnboardingConstants.RMS_PLAN_REQUEST });

        const res = yield call(jarvisRMSOnboardingApis.getRMSPlanList);
        const payload = jarvisRMSOnboardingParser.rmsPlanList(res);
        
        yield put({
            type: jarvisRMSOnboardingConstants.RMS_PLAN_RESPONSE,
            payload,
        });
    } catch (error) {
        console.log(`Action ${type} failed with `, error);
        yield put({
            type: jarvisRMSOnboardingConstants.RMS_PLAN_RESPONSE,
            payload: {},
        });
    }
}

function* brokerListSaga({ payload }) {
    try {
        const { isIntegrated, isFetchHoldingEnabled, searchTerm, productCode } = payload;
        yield put({
            type: jarvisRMSOnboardingConstants.BROKER_LIST_REQUEST,
        });
        const res = yield call(jarvisRMSOnboardingApis.getBrokerList, { isIntegrated, isFetchHoldingEnabled, searchTerm, productCode });
        const parsedRes = jarvisRMSOnboardingParser.brokerList(res);
        yield put({
            type: jarvisRMSOnboardingConstants.BROKER_LIST_RESPONSE,
            payload: parsedRes,
        });
    } catch (error) {
        yield put({
            type: jarvisRMSOnboardingConstants.BROKER_LIST_RESPONSE,
            payload: [],
        });
    }
}

export function* registerJarvisRMSOnboardingSagas() {
    yield takeLatest(
        jarvisRMSOnboardingConstants.BROKER_LIST_SAGA,
        brokerListSaga
    );
    
    yield takeLatest(
        jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_SAGA,
        getOnboardingDataSaga
    );
    
    yield takeLatest(
        jarvisRMSOnboardingConstants.RMS_PLAN_SAGA,
        planPricingDataSaga
    );
}
