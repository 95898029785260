import React from "react";
import { withStyles } from "@mui/styles";

import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Table from "components/common/Table";
import MobileResponsive from "components/common/MobileResponsive";

import JarvisCard from "components/JarvisCard";
import StockCard from "components/StockCard";

import { dataHelper } from "helper/data";
import { formatHelper } from "helper/format";

class PortfolioAllocationContainer extends React.Component {
  portfolioAllocation = [];

  constructor(props) {
    super(props);
    this.state = {
      isEditEnabled: false,
      isDataSet: false,
    };
  }

  componentDidMount() {
    this.props.getPortfolioAllocationsListAction();
  }

  componentDidUpdate() {
    this.setEditData();
  }

  setEditData = () => {
    if (this.props.portfolioAllocation && !this.state.isDataSet) {
      this.portfolioAllocation = dataHelper.deepCopy(
        this.props.portfolioAllocation
      );
      this.setState({ isDataSet: true });
    }
  };

  // setEditData = () => {
  //     if (this.props.portfolioAllocation && !this.state.isDataSet) {
  //         this.portfolioAllocation = dataHelper.deepCopy(
  //             this.props.portfolioAllocation
  //         );
  //         this.setState({ isDataSet: true });
  //     }
  // };

  // toggleEditMode = () => {
  //     this.setState({ isEditEnabled: !this.state.isEditEnabled });
  // };

  onValueChange = (key, value, index) => {
    if (
      this.portfolioAllocation[index] &&
      this.portfolioAllocation[index][key]
    ) {
      this.portfolioAllocation[index][key] = value;
    }
  };

  onReset = () => {
    this.portfolioAllocation = dataHelper.deepCopy(
      this.props.portfolioAllocation
    );
    this.toggleEditMode();
  };

  onSave = () => {
    // enable button loader
    // make api call
    // on success
    // disable button loader
    this.toggleEditMode();
  };

  render() {
    const { classes } = this.props;
    const columns = [
      {
        field: "stockName",
        headerText: "Shares / Stocks",
        bodyCellComponent: (v, d) => {
          return (
            <Stack sx={{ alignItems: "flex-start" }}>
              <Text variant="body1" color="#202020">
                {v}
              </Text>
              <Text variant="small" color="#808591">
                {d.shareCode}
                {
                  d.capCategory ?
                    <Text component="span" sx={{
                      marginLeft: "10px",
                      display: "inline-block",
                      fontSize: "10px",
                      color: "gray",
                      backgroundColor: "#8080801A",
                      padding: "0.5px 7px",
                      borderRadius: "50px"
                    }}> {d.capCategory} </Text>
                    : null
                }
              </Text>
            </Stack>
          );
        },
      },
      {
        field: "buyPrice",
        headerText: "Buy Price",
        // enableEdit: this.state.isEditEnabled,
        // onChange: (value, index) =>
        //     this.onValueChange("price", value, index),
        bodyCellComponent: (v) =>
          <Text variant="small">{formatHelper.currency(v, { config: { maximumFractionDigits: 2 } })}</Text>,
      },
      {
        field: "currentPrice",
        headerText: "Current Price",
        bodyCellComponent: (v, d) =>
          <Text variant="small">{formatHelper.currency(v, { config: { maximumFractionDigits: 2 } })}</Text>,
      },
      {
        field: "totalBuyPrice",
        headerText: "Total Invested",
        bodyCellComponent: (v, d) =>
          formatHelper.currency(v, { config: { maximumFractionDigits: 2 } }),
      },
      {
        field: "totalCurrentPrice",
        headerText: "Current Value",
        bodyCellComponent: (v, d) => {
          return (
            <Stack sx={{ alignItems: "flex-start" }}>
              <Text variant="body1" color="#202020">
                {formatHelper.currency(v, {
                  config: { maximumFractionDigits: 2 },
                })}
              </Text>

              {d.profitType === "gain" ? (
                <Text variant="small" color="#45AC70">
                  {formatHelper.currency(d.profitPrice)} ({d.profitPercentage}%)
                </Text>
              ) : (
                <Text variant="small" color="#D44C4C">
                  {formatHelper.currency(Math.abs(d.profitPrice))} ({Math.abs(d.profitPercentage)}%)
                </Text>
              )}
            </Stack>
          );
        },
      },
      {
        field: "quantity",
        headerText: "Qty.",
        // enableEdit: this.state.isEditEnabled,
        // onChange: (value, index) =>
        //     this.onValueChange("pendingActionableQty", value, index),
      },
      {
        field: "allocationPercentage",
        headerText: "Allocation",
        bodyCellComponent: (v) => <Text variant="small" color="#1D9BFF">{v}%</Text>,
      },
    ];

    return (
      <>
        {this.props.isPortfolioAllocationsLoading ? (
          <>Loading ...</>
        ) : (
          <JarvisCard
            sx={{
              border: { md: "none" },
              p: { xs: 0, md: 0 }, //change 4 to 0 issue card padding on portfolio dashboard. remove comment after tesing
              mb: { xs: 2, md: 0 },
              maxWidth: "100%",
              backgroundColor: "transparent"
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mb: { xs: "5px", md: 2.75 },
                minHeight: "38px",
                mt: "10px",
              }}
            >
              <Text variant="subtitle2">Portfolio</Text>
            </Stack>

            {/* Data based on screen type */}
            <MobileResponsive
              web={() => (
                <JarvisCard>
                  <Table
                    columns={columns}
                    data={this.props.portfolioAllocation}
                    count={this.props.portfolioAllocationCount}
                  />
                </JarvisCard>
              )}
              mobile={() => (
                <Stack className={classes.cardViewWrapper}>
                  {this.props.portfolioAllocation.map((l, i) => {
                    return (
                      <Stack
                        key={`stock_${i}`}
                        sx={{
                          flexGrow: 0,
                          width: { xs: 1, sm: 0.5, md: 0.33 },
                        }}
                      >
                        <StockCard
                          sx={{
                            borderBottom: `1px solid #e2e2e2`,
                            backgroundColor: "#fff",
                          }}
                          stockDetails={l}
                          cardType="PORTFOLIO"
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            />
          </JarvisCard>
        )}
      </>
    );
  }
}

const style = (theme) => ({
  cardViewWrapper: {
    position: "relative",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-start",
    borderRadius: "10px",
    overflow: "hidden",
    border: "1px solid #E2E2E2",
    backgroundColor: "#FFF",
    "& > :last-child > div": {
      borderBottom: "none !important",
    },
    "@media (max-width: 599px)": {
      "& > :nth-child(odd)": {
        backgroundColor: "#F8F8F8",
      },
    },
    "@media (min-width: 600px) AND (max-width: 900px)": {
      "& > :nth-child(4n+1)": {
        backgroundColor: "#F8F8F8",
        borderRight: "1px solid #e2e2e2",
      },
      "& > :nth-child(4n)": {
        backgroundColor: "#F8F8F8",
        borderLeft: "1px solid #e2e2e2",
      },
    },
  },
});

export default withStyles(style, { withTheme: true })(
  PortfolioAllocationContainer
);
