import { ArrowForward } from "@mui/icons-material";
import List from "components/common/List";
import ListItem from "components/common/ListItem";
import React from "react";
import Stack from "../common/Stack";
import Box from "../common/Box";
import Button from "../common/Button";
import Text from "../common/Text";

const HelpSupportCategory = (props) => {
  const { title, categoryList, onListClick, onViewAll, categoryId } = props;

  return (
    <Stack justifyContent="space-between" height="100%">
      <Box>
        <Text sx={{ mb: "12px" }} variant="subtitle2" fontWeight="bold">
          {title}
        </Text>
        <List>
          {categoryList.map(({ title, id }, index) => (
            <ListItem
              disablePadding
              disableGutters
              key={`option_${index}`}
              button
              onClick={() => onListClick(id, categoryId)}
              sx={{ mb: "8px" }}
            >
              <Text sx={{ lineHeight: 1.2 }} variant="body2" color="#808591">
                {title}
              </Text>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <Button
          sx={{ p: 0, mt: "10px" }}
          endIcon={<ArrowForward fontSize="small" />}
          onClick={() => onViewAll(categoryId, title)}
          variant="text"
        >
          <Text
            sx={{ textTransform: "none" }}
            variant="body1"
            fontWeight="bold"
          >
            View all
          </Text>
        </Button>
      </Box>
    </Stack>
  );
};

export default HelpSupportCategory;
