import React from "react";
import { withStyles } from "@mui/styles";

import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Image from "components/common/Image";

import { routesConfig } from "./routesConfig";

import cx from "classnames";
import { config } from "config";

import { withRouter } from "react-router";

import { onboardingApis } from "stores/onboarding/onboardingApis";
import { onboardingParsers } from "stores/onboarding/onboardingParsers";
import { validationHelper } from "helper/validation";
import { customerApis } from "stores/customer/customerApis";
import { tracker } from "library/tracker";
import RMSRebalanceAlert from "containers/Dashboard/DashboardOnboarded/RMSRebalanceAlert";
import PendingActionCalls from "components/PendingActionCalls";

// storage
import { localStore } from "stores/localStorage";
import { intercom } from "library/tracker/intercom";

// Static Data: Routes
let PRODUCT_ROUTES = {
    [config.productCodes.PORTFOLIO]: "jarvis-portfolio",
    [config.productCodes.RMS]: "jarvis-rms",
    [config.productCodes.ONESTOCK]: "jarvis-onestock"
}

// Child Component: 1: App Tab
const Tab = ({ config = [], handleRedirect, classes }) => (
    <Stack className={classes.tabWraper}>
        {config.map((d, i) => {
            const { tabName, isActive, isDisabled = false, icon, redirectLink } = d;
            return (
                <Stack key={`appTab_${i}`}>
                    <button
                        onClick={(e) => {
                            handleRedirect(redirectLink);
                        }}
                        disabled={isDisabled}
                    >
                        <img
                            src={icon}
                            alt="icon"
                            style={{ filter: isActive ? "grayscale(0)" : "" }}
                        />
                    </button>
                    <Text
                        component="div"
                        variant="caption2"
                        style={{ transform: isActive ? "scale(1)" : "" }}
                    >
                        {isActive ? tabName : ""}
                    </Text>
                </Stack>
            );
        })}
    </Stack>
);

// Child Component: 2: Produc Tab
const ProductTab = ({ classes, handleProductChange, productTabConfig }) => {

    return (
        productTabConfig && productTabConfig.length ?
            <Stack className={classes.productTab}>
                {
                    productTabConfig.map(t => (
                        <button 
                            className={cx({ [classes.activeProductTab]: t.isActive })} 
                            style={{ cursor: "pointer" }}
                            onClick={() => handleProductChange(t.productCode)}>
                                {t.tabName}
                        </button>
                    ))
                }
            </Stack> : null
    )
}


// Child Component: 3: App Header
const Header = ({ classes, headerText, goBack, headerDisabled, hasTab, hasProductTab, IconClickRedirection, userName }) => {
    let hasBack = !hasTab;
    let hasProfileButton = hasTab;

    return(
    headerDisabled ?
        null
        :
        <Stack className={classes.appHeader}
            style={{
                paddingTop: config.isMobileApp ? `calc(var(--safe-area-inset-top) + 16px)` : 0,
                height: config.isMobileApp ? "auto" : "",
                borderBottom: hasProductTab ? "1px solid #ebebeb" : "",
            }}
        >
            {hasBack ? (
                <img
                    src="/images/mobileApp/back-arrow.svg"
                    alt="back"
                    onClick={goBack}
                />
            ) : null}

                <Stack sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    flexFlow: "row nowrap"
                }}>
                    <Text variant="body1" fontWeight="500">{headerText}</Text>
                    <Stack direction="row">
                        <Stack
                            sx={{
                                cursor: "pointer",
                                marginRight: "10px",
                            }}
                            onClick={() => intercom.show()}>
                            <Image src="/images/msg-icon.svg" alt="icon" />
                        </Stack>
                        {
                            hasProfileButton ?
                                <button className={classes.profileButton} onClick={IconClickRedirection}>
                                    {userName?.slice(0, 1)}
                                </button> : null
                        }
                    </Stack>
                </Stack>
        </Stack>
)}

// Child Component: 4: App Update alert
const AppUpdateAlert = ({ classes, hasAppUpdate, slideDownUpdateAlert }) => (
    hasAppUpdate ?
        <Stack className={cx({
            [classes.updateAlertWrap]: true,
            [classes.slideDownAlert]: slideDownUpdateAlert
        })} >
            <Text variant="caption1" component="div">
                <img src="/images/icon-update.svg" alt="update" />
                Better, faster app version available
            </Text>
            <Button>Update</Button>
        </Stack>
        : null
)


// Temporary
// const ComingSoonMsg = () =>(
//     <Stack sx={{
//         height: "calc(100% - 90px)",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//     }}>
//         <Text>Coming Soon...</Text>
//     </Stack>
// )

// Export Component
class LayoutMobileAppContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slideDownUpdateAlert: false,

            headerText: "",
            hasTab: false,
            headerDisabled: false,
            hasProductTab: false,
            tabConfig: [],
            productTabConfig: [],
            currentPath: "",

            activeProduct: localStore.getActiveProduct() || "",

            openPendingCall: false,
            isEditEmailModal: false,
            isEditMobileModal: false,
            isVerifyMobileModal: false,
            choosePortfolioModalOpen: false,
            actionType: '',
            formData: {
                email: this.props.emailId,
                emailError: '',
                mobile: this.props.mobileNumber,
                mobileError: '',
                mobileOtp: '',
            },
        };
    }

    componentDidMount() {
        this.setLayout();
        this.toggleAppUpdateAlert();
        this.setIntercomPosition();

        // Change Active product based on initial path
        const urlPath = this.props.location.pathname;
        Object.keys(PRODUCT_ROUTES).some( p => {
            if(urlPath.search(PRODUCT_ROUTES[p]) !== -1){
                localStore.setActiveProduct(p);
                this.setState({activeProduct: p});
                console.log("PRODUCT_SET App Layout didmount...", p);
                return true;
            }
            return false;
        });

        this.props.pendingCallAction();
    }

    componentDidUpdate(prevProps, prevState) {

        // Change Active product
        if (prevState.activeProduct !== this.state.activeProduct) {
            localStore.setActiveProduct(this.state.activeProduct);  // set local Store
            this.setLayout();
        }

        // Change path
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setLayout();
        }
    }

    toggleAppUpdateAlert = () => {
        let currentScroll = 0;
        let currentState = 0;
        document.addEventListener("wheel", (e) => {
            let elm = document.getElementById("appContentBody");
            let scroll = 0;
            if (elm) {
                scroll = elm.scrollTop;
            }

            if (scroll > currentScroll) {
                if (currentState) {
                    this.setState({ slideDownUpdateAlert: true });
                }
                currentScroll = scroll;
                currentState = 0;
            } else {
                if (!currentState) {
                    this.setState({ slideDownUpdateAlert: false });
                }
                currentState = 1;
                currentScroll = scroll;
            }
        });
    }

    setProductTabItems = () => {
        const { activeProduct } = this.state;
        const { activeProductList } = this.props;
        const productTabConfig = []

        if (activeProductList.includes(config.productCodes.PORTFOLIO)) {
            productTabConfig.push({
                tabName: "Jarvis Portfolio",
                productCode: config.productCodes.PORTFOLIO,
                isActive: (activeProduct === config.productCodes.PORTFOLIO)
            })
        }
        if (activeProductList.includes(config.productCodes.RMS)) {
            productTabConfig.push({
                tabName: "Jarvis Protect",
                productCode: config.productCodes.RMS,
                isActive: (activeProduct === config.productCodes.RMS)
            })
        }
        if (activeProductList.includes(config.productCodes.ONESTOCK)) {
            productTabConfig.push({
                tabName: "One Stock",
                productCode: config.productCodes.ONESTOCK,
                isActive: (activeProduct === config.productCodes.ONESTOCK)
            })
        }

        this.setState({ productTabConfig: productTabConfig }) 
       
    }

    setTabItems = () => {
        // const { isOnboardingCompleted, RMSisOnboardingCompleted } = this.props;
        const { activeProduct } = this.state;
        const { isActiveRoute } = this;
        
        let dashboardLink = "";
        let holdingsLink = "";
        let actionCenterLink = "";
        let transactionLink = "";

        if (activeProduct) {
            switch (activeProduct.toUpperCase()) {
                case config.productCodes.PORTFOLIO:
                    dashboardLink = routesConfig.dashboard.path;
                    holdingsLink = routesConfig.holdings.path;
                    actionCenterLink = routesConfig.actionCenter.path;
                    transactionLink = routesConfig.transactions.path;
                    break;
                case config.productCodes.RMS:
                    dashboardLink = routesConfig.RMSdashboard.path;
                    holdingsLink = routesConfig.RMSholdings.path;
                    actionCenterLink = routesConfig.RMSactionCenter.path;
                    transactionLink = routesConfig.RMStransactions.path;
                    break;
                case config.productCodes.ONESTOCK:
                    dashboardLink = routesConfig.onestockDashboard.path;
                    holdingsLink = routesConfig.onestockHoldings.path;
                    actionCenterLink = routesConfig.onestockActionCenter.path;
                    transactionLink = routesConfig.onestockTransactions.path;

                    break;

                default:
            }
        }

        const tabConfig = [
            {
                tabName: "Dashboard",
                icon: "/images/mobileApp/dashboard.svg",
                redirectLink: dashboardLink,
                isActive: isActiveRoute(dashboardLink),
            },
            {
                tabName: "Holdings",
                icon: "/images/mobileApp/holdings-icon.svg",
                redirectLink: holdingsLink,
                isActive: isActiveRoute(holdingsLink),
            },
            {
                tabName: "Action Center",
                icon: "/images/mobileApp/rebalance-icon.svg",
                redirectLink: actionCenterLink,
                isActive: isActiveRoute(actionCenterLink),
                // isDisabled: !isOnboardingCompleted,
            },
            {
                tabName: "Transaction",
                icon: "/images/mobileApp/trans-icon.svg",
                redirectLink: transactionLink,
                isActive: isActiveRoute(transactionLink),
                // isDisabled: !isOnboardingCompleted,
            },

            // {
            //     tabName: "Invest More",
            //     icon: "/images/mobileApp/invest.svg",
            //     redirectLink: routesConfig.investMore.path,
            //     isActive: isActiveRoute(routesConfig.investMore.path),
            //     isDisabled: !isOnboardingCompleted,
            // },
            // {
            //   tabName: "SIP",
            //   icon: "/images/mobileApp/sip.svg",
            //   redirectLink: routesConfig.startSIP.path,
            //   isActive: isActiveRoute(routesConfig.startSIP.path),
            //   isDisabled: !isOnboardingCompleted,
            // },
            // {
            //   tabName: "Rebalance",
            //   icon: "/images/mobileApp/rebalance.svg",
            //   redirectLink: routesConfig.rmsRebalance.path,
            //   isActive: isActiveRoute(routesConfig.rmsRebalance.path),
            //   isDisabled: !isOnboardingCompleted,
            // },
            // {
            //     tabName: "More",
            //     icon: "/images/mobileApp/more.svg",
            //     redirectLink: routesConfig.more.path,
            //     isActive: isActiveRoute(routesConfig.more.path),
            // },
        ];

        this.setState({ tabConfig: tabConfig });
    };


    // set intercom position based on tab
    setIntercomPosition = (hasTab = false) => {
        let intercomElem;
        intercomElem = document.getElementsByClassName("intercom-launcher")[0];
        if (!intercomElem) {
            intercomElem = document.getElementsByClassName("intercom-launcher-frame")[0];
        }
        if (!intercomElem) {
            intercomElem = document.getElementsByClassName("intercom-lightweight-app-launcher")[0];
        }

        if(!intercomElem){
        }
        if (intercomElem && ( hasTab || this.state.hasTab )) {
            intercomElem.classList.remove("intercom-launcher-noTab");
            intercomElem.classList.add("intercom-launcher-hasTab");
        } else if (intercomElem) {
            intercomElem.classList.add("intercom-launcher-noTab");
            intercomElem.classList.remove("intercom-launcher-hasTab");
        }
    }

    setLayout = () => {
        // const { isOnboardingCompleted, RMSisOnboardingCompleted } = this.props;
        const r = Object.values(routesConfig).find((e) =>
            this.props.location.pathname.search(e.path) !== -1
        ) || {};


        // / set header for dashboard based on onboarding completed.
        // if (r.path === '/jarvis-portfolio/dashboard') {
        // r.headerDisabled = isOnboardingCompleted ? true : false;
        // }

        this.setState({
            headerText: r.header || "",
            hasTab: r.hasTab || false,
            headerDisabled: r.headerDisabled || false,
            hasProductTab: r.hasProductTab || false,
            currentPath: r.path
        }, () => {
            if (r.hasTab === true) {
                this.setTabItems();
                document.querySelector("html").classList.add("has-tab");
                document.querySelector("html").classList.remove("no-tab");
            } else {
                document.querySelector("html").classList.add("no-tab");
                document.querySelector("html").classList.remove("has-tab");
            }

            if (r.hasProductTab) {
                this.setProductTabItems();
            }

            this.setIntercomPosition(r.hasTab);
        });

    };

    isActiveRoute = (pathname) => this.props.location.pathname.search(pathname) !== -1;

    handleRedirect = (pathname) => this.props.history.push(pathname);

    goBack = () => this.props.history.goBack();

    IconClickRedirection = () => this.props.history.push("/customer/app-more");


    handleProductChange = (productType) => {
        let { activeProduct, currentPath } = this.state;
        let redirectPath = "";

        if (productType === activeProduct) {
            return;
        }

        redirectPath = currentPath.replace(PRODUCT_ROUTES[activeProduct], PRODUCT_ROUTES[productType]);
        this.setState({ activeProduct: productType }, () => {
            this.props.history.push(redirectPath);
        });
    }

    handlePendingActions = (name) => {
        if (name === "open") {
            this.setState({ openPendingCall: true })
        }
        else if (name === "close") {
            this.setState({ openPendingCall: false })
        }
        return
    }

    handleInputChange = ({ name, value }) => {
        const { formData } = this.state;
        formData[name] = value;
        this.setState({ formData });
    };

    handleEditEmailModal = (val, actionType) => {
        const { formData } = this.state
        formData.emailError = ''
        actionType === 'EDIT' ? this.setState({ isEditEmailModal: val, actionType: actionType, })
            : actionType === 'CANCEL' ? this.setState({ isEditEmailModal: val, actionType: actionType, email: this.props.emailId, formData })
                : this.setState({ actionType: actionType }, () => this.handleSendLink());
    }

    handleEmailValidation = () => {
        const { formData } = this.state;
        const emailValidation = validationHelper.email(formData.email);
        formData.emailError = emailValidation.message
        this.setState({ formData })
        return emailValidation.isValid;
    }

    handleMobileValidation = () => {
        const { formData } = this.state;
        const mobileValidation = validationHelper.mobile(formData.mobile);
        formData.mobileError = mobileValidation.message
        this.setState({ formData })
        return mobileValidation.isValid;
    }

    handleMobileModal = (action) => {
        const { formData } = this.state
        if (action === 'open') {
            this.setState({ isEditMobileModal: true })
        }
        else if (action === 'close') {
            formData.mobileError = ''
            this.setState({ isEditMobileModal: false, formData })
        }
        else if (action === 'otp-close') {
            this.setState({ isVerifyMobileModal: false })
        }
    }

    handleMobileOtp = async () => {
        const { formData } = this.state
        if (!this.handleMobileValidation()) {
            return;
        }
        try {
            const res = await customerApis.mobileRequestOtp({
                mobile: formData.mobile,
            })
            this.props.setToast({ message: res.message });
            formData.mobileError = ''
            this.setState({ isEditMobileModal: false, isVerifyMobileModal: true, formData })
        }
        catch (err) {
            this.props.setToast({ message: err.data.message });
        }
    }

    handleVerifyOtp = async () => {
        const { formData } = this.state
        try {
            const res = await customerApis.verifyMobileOtp({
                otp: formData.mobileOtp
            })
            this.props.setToast({ message: res.message });
            this.setState({ isVerifyMobileModal: false, formData })
            this.props.pendingCallAction();
        }
        catch (err) {
            this.props.setToast({ message: err.data.message });
        }
    }

    handleSendLink = async () => {
        const { formData, actionType } = this.state;
        const email = formData.email
        if (!this.handleEmailValidation()) {
            return;
        }
        try {
            const res = await onboardingApis.sendEmailVerificationLink({ actionType, email });
            const parsedRes = onboardingParsers.sendEmailVerificationLink(res);
            if (actionType === "EDIT") {
                this.props.updateEmailAction({ email })
            }
            this.props.setToast({ message: parsedRes.message });
            formData.emailError = ''
            this.setState({ isEditEmailModal: false, formData });
        }
        catch (err) {
            this.props.setToast({ message: err.data.message });
        }
    }

    toggleChoosePortfolioModal = () => {
        if (this.props.portfolioAlert.hasExpired) {
            this.setState((prev) => {
                return { choosePortfolioModalOpen: !prev.choosePortfolioModalOpen };
            });
            //event-tracker: Partial Buy/Sell -> invest_more_now
            tracker.partialBuySell.investMoreNow();
            this.props.getPortfolioPendingTransactionAction();
        } else {
            this.props.history.push("/jarvis-portfolio/action-centre");
        }
    };

    setArchiveModalClose = () => {
        this.setState({ choosePortfolioModalOpen: false })
    }

    checkIsPendingActionAllowed = () => {
        const currentRoute = this.props.history.location.pathname

        const notAllowedRoutes = [
            `/knowledge-centre/blogs`,
            `/knowledge-centre/market-updates`,
            `/knowledge-centre/news-articles`,
            `/knowledge-centre/news-articles/:articleId`,
            `/knowledge-centre/help-support`,
            `/customer/overview`,
            `/customer/refer-jarvis`,
            `/customer/profile`,
            `/customer/profile/subscription`,
            `/customer/profile/edit-profile`,
            `/customer/payment`,
            `/customer/broker`,
            `/customer/document-signing`,
            `/customer/app-more`,
        ]
        if (notAllowedRoutes.includes(currentRoute)) {
            return false
        }
        return true
    }

    // Render
    render() {
        const {
            classes,
            children,
            userName,
            pendingCallList,
            activeProductList,
            isPendingCallListLoading,
        } = this.props;

        const {
            headerText,
            hasTab,
            hasProductTab,
            tabConfig,
            productTabConfig,
            headerDisabled,
            slideDownUpdateAlert,
            openPendingCall,
            isEditEmailModal,
            formData,
            isEditMobileModal,
            isVerifyMobileModal,
        } = this.state;

        const isPendingActionsAllowed = this.checkIsPendingActionAllowed();

        return (
            <Stack className={classes.layoutWrapper}>

                <Header
                    classes={classes}
                    hasTab={hasTab}
                    headerText={headerText}
                    headerDisabled={headerDisabled}
                    hasProductTab={!hasProductTab}
                    goBack={this.goBack}
                    IconClickRedirection={this.IconClickRedirection}
                    userName={userName}
                />
                {hasProductTab ?
                    <ProductTab
                        productTabConfig={productTabConfig}
                        handleProductChange={this.handleProductChange}
                        classes={classes}
                    />
                : null}

                {
                    isPendingActionsAllowed ?
                        <Stack px={2} py={1} sx={{ maxWidth: "100%" }} >
                            <PendingActionCalls
                                handlePendingActions={this.handlePendingActions}
                                openPendingCall={openPendingCall}
                                pendingCallList={pendingCallList}
                                activeProductList={activeProductList}
                                isEditEmailModal={isEditEmailModal}
                                email={formData.email}
                                emailError={formData.emailError}
                                mobile={formData.mobile}
                                mobileError={formData.mobileError}
                                handleSendLink={this.handleSendLink}
                                handleInput={this.handleInputChange}
                                handleEditEmailModal={this.handleEditEmailModal}
                                handleMobileModal={this.handleMobileModal}
                                isEditMobileModal={isEditMobileModal}
                                isVerifyMobileModal={isVerifyMobileModal}
                                handleMobileOtp={this.handleMobileOtp}
                                handleVerifyOtp={this.handleVerifyOtp}
                                mobileOtp={formData.mobileOtp}
                                isLoading={isPendingCallListLoading}
                                handleArchiveJourney={this.toggleChoosePortfolioModal}
                            />
                            <RMSRebalanceAlert
                                toggleChoosePortfolioModal={this.toggleChoosePortfolioModal}
                                choosePortfolioModalOpen={this.state.choosePortfolioModalOpen}
                                setArchiveModalClose={this.setArchiveModalClose}
                            />
                        </Stack>
                        : null
                }

                <Box className={classes.appPageContent} id="appContentBody">
                    {children}
                    <Box sx={{ padding: "40px" }} />
                </Box>
                {hasTab ? (
                    <>
                        <AppUpdateAlert
                            classes={classes}
                            hasAppUpdate={false}
                            slideDownUpdateAlert={slideDownUpdateAlert}
                        />
                        <Tab
                            config={tabConfig}
                            classes={classes}
                            handleRedirect={(pathname) => this.handleRedirect(pathname)}
                        />
                    </>
                ) : <div style={{paddingBottom: "var(--safe-area-inset-bottom)"}}></div>}
            </Stack>
        );
    }
} // class Ends

const style = (theme) => ({
    layoutWrapper: {
        backgroundColor: "#f9f9f9",
        display: "flex",
        flexFlow: "column nowrap",
        minHeight: "calc(100vh - 0px)",
        maxWidth: "100%",
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
    },
    appHeader: {
        padding: "16px 16px",
        // borderBottom: "1px solid #E9E9E9",
        lineHeight: "1",
        fontWeight: "600",
        // position: "fixed",
        // top: "0",
        zIndex: "99",
        width: "100%",
        height: "51px",
        backgroundColor: "#fff",
        flexFlow: "row nowrap",
        alignItems: "center",
        "& img": {
            width: "24px",
            marginRight: "10px",
            cursor: "pointer",
        },
    },
    appPageContent: {
        padding: "16px 16px",
        position: "relative",
        zIndex: 2,
        overflow: "auto",
        flex: 1
    },

    appBottomTab: {
        // position: "fixed",
        zIndex: 9,
        width: "100%",
        height: "55px",
        padding: "0 16px",
        borderTop: "1px solid #EEEEEE",
        backgroundColor: "#fff",
    },

    // bottom Tab
    tabWraper: {
        flexFlow: "row nowrap",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: "#fff",
        borderTop: "1px solid #EEEEEE",
        padding: "10px 16px",
        paddingBottom: "calc(var(--safe-area-inset-bottom) + 10px) !important",
        minHeight: "60px",
        zIndex: 999,
        "& > div": {
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            lineHeight: "7px",
            flex: 1,
            "& > button": {
                padding: 0,
                margin: 0,
                border: "none",
                outline: "none",
                backgroundColor: "#fff !important",
                "& img": {
                    transition: "all 0.7s ease-in-out",
                    filter: "grayscale(100%)",
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                    "&::selection": {
                        backgroundColor: "transparent"
                    },
                },
            },
            "& > button[disabled]": {
                opacity: 0.3,
            },
            "& > button + div": {
                fontWeight: "500",
                color: "#1D9BFF",
                transform: "scale(0)",
                transition: "all 0.3s ease-in-out",
            },
        },
    },

    // Product Tabs
    productTab: {
        // position: "fixed", 
        // top: "59px", 
        flexFlow: "row nowrap",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%",
        backgroundColor: "#fff",
        padding: "0px 10px",
        height: "30px",
        borderBottom: "1px solid #EEF0F2",
        zIndex: 99,
        "&::selection": {
            backgroundColor: "transparent"
        },
        "& > button": {
            fontSize: "14px",
            color: "#9AA0A7",
            padding: "5px",
            border: "none",
            borderBottom: "2px solid transparent",
            backgroundColor: "transparent",
            "&::selection": {
                backgroundColor: "transparent"
            },
        }
    },
    activeProductTab: {
        color: "#2B70C5 !important",
        borderBottomColor: "#2B70C5 !important"
    },

    // app update alert
    updateAlertWrap: {
        position: "fixed",
        bottom: "55px",
        width: "100%",
        backgroundColor: "#45AC70",
        padding: "16px 10px",
        color: "#fff",
        justifyContent: "space-between",
        alignItems: "center",
        flexFlow: "row nowrap",
        transition: "all 0.5s ease-in-out",
        zIndex: 8,
        "& > div": {
            display: "flex",
            "& img": {
                marginRight: "6px",
            }
        },
        "& > button": {
            color: "#45AC70",
            fontSize: "12px",
            boxShadow: "none",
            backgroundColor: "#fff"
        }
    },
    slideDownAlert: {
        bottom: "-15px !important"
    },

    profileButton: {
        backgroundColor: "#7522E0", 
        color: "#fff", 
        fontWeight: "700", 
        width: "30px", 
        height: "30px", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        borderRadius: "50px", 
        fontSize: "16px",
        border: "none",
        padding: "0px",
    }
}); // css Ends

export default withStyles(style, { withTheme: true })(
    withRouter(LayoutMobileAppContainer)
);
