import { jarvisRMSOnboardingConstants } from "./jarvisRMSOnboardingConstants";

const jarvsiRMSOnboardingActions = {};

jarvsiRMSOnboardingActions.getRMSPlanList = () => {
  return{
    type:jarvisRMSOnboardingConstants.RMS_PLAN_SAGA
  }
};

jarvsiRMSOnboardingActions.updateRMSOnboardingData = ({
    currentStage,
    riskProfileId,
    panNumber,
    dateOfBirth,
    aadharNumber,
    isManualKYC,
    isPanUploaded,
    isAadhaarFrontUploaded,
    isAadhaarBackUploaded,
    portfolioId,
    isOnboardingCompleted,
}) => ({
    type: jarvisRMSOnboardingConstants.RMS_UPDATE_ONBOARDING_DATA,
    payload: {
        currentStage,
        riskProfileId,
        panNumber,
        dateOfBirth,
        aadharNumber,
        isManualKYC,
        isPanUploaded,
        isAadhaarFrontUploaded,
        isAadhaarBackUploaded,
        portfolioId,
        isOnboardingCompleted,
    },
});

jarvsiRMSOnboardingActions.getBrokerList = ({ isFetchHoldingEnabled, isIntegrated, searchTerm, productCode }) => {
  return {
    type: jarvisRMSOnboardingConstants.BROKER_LIST_SAGA,
    payload: { isFetchHoldingEnabled, isIntegrated, searchTerm, productCode }
  }
}
jarvsiRMSOnboardingActions.getOnboardingData = () => ({
  type: jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_SAGA,
});

export { jarvsiRMSOnboardingActions };
