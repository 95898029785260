import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
import { investMoreActions } from "stores/investMore/investMoreActions";
import { masterActions } from "stores/master/masterActions";

const mapStateToProps = (state) => {
    return {
        minInvestmentAmount: state.onboarding.minimumAdditionalInvestAmount,
        investmentStocksData: state.investMore.investmentStocks,
        isInvestmentStocksLoading: state.investMore.loading.investmentStocks,
        profileData: state.customer.profile,

        brokersList: state.common.brokers,
        isBrokersListLoading: state.common.loading.brokers,
        isBrokersListInitialCalled: state.common.initialCalled.brokers,
        centrumReferalURL:state.common.featureConfig.centrumReferalURL
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getInvestmentStocksAction: investMoreActions.getInvestmentStocks,
            getBrokersAction: commonActions.getBrokers,
            pendingCallAction: masterActions.getPendingCall,
        },
        dispatch
    );

const InvestMoreStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default InvestMoreStore;
