import React from "react";
import _get from "lodash.get";

import Box from "components/common/Box";
import Grid from "components/common/Grid";
import Loader from "components/common/Loader";
import Text from "components/common/Text";
import ProductDetailsCard from '../../components/Profile/ProductDetailsCard';
import PageTitle from 'components/PageTitle';


import InvestmentStrategy from "./InvestmentStrategy";
import ProfileContainer from "./Profile/";
import RiskProfileContainer from "./RiskProfile/";


import JarvisSubscriptionPlans from "components/JarvisSubscriptionPlans";
import { config } from "config";
import JarvisCard from "components/JarvisCard";
import { withRouter } from "react-router-dom";
import MobileResponsive from "components/common/MobileResponsive";


const screenTypes = {
  VIEW_PROFILE: "VIEW_PROFILE",
  SUBSCRIPTION_PLANS: "SUBSCRIPTION_PLANS",
};

const planFrequencyMessage = {
  MONTHLY: "Your Jarvis portfolio subscription auto-renews every month. <br/> You can cancel subscription any time.",
  HALF_YEARLY: "Your Jarvis portfolio subscription auto-renews every six months. <br/> You can cancel subscription any time.",
  YEARLY: "Your Jarvis portfolio subscription auto-renews every year. <br/> You can cancel subscription any time.",
  QUARTERLY: "Your Jarvis portfolio subscription auto-renews every quarter. <br/> You can cancel subscription any time."
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeScreenType: screenTypes.VIEW_PROFILE,
      selectedFrequency: null,
      selectedPlanData: null,
    }

  }

  componentDidMount() {
    if (!this.props.profile.length || _get(this.props.location, "state.paymentStatus", "") === "SUCCEED") {
      this.props.getProfileDataAction();
    }
    if (
      !this.props.subscriptionPlans.length &&
      !this.props.isSubscriptionPlansLoading &&
      !this.props.isSubscriptionPlansCalled
    ) {
      this.props.getSubscriptionPlansAction({ productCode: config.productCodes.PORTFOLIO });
    }
    this.props.getProductsSubscriptionAction()
  }


  componentDidUpdate() {
    const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();
    if (
      subscriptionFrequencies.length &&
      !this.state.selectedFrequency
    ) {
      this.handleFrequencyChange({ selectedFrequency: subscriptionFrequencies[0] })
    }
  }

  getSubscriptionPlanFrequencies = () => {
    const planFrequency = [];
    if (this.props.subscriptionPlans.length) {
      this.props.subscriptionPlans.forEach(plan => {
        if (!planFrequency.includes(plan.planFrequency)) {
          planFrequency.push(plan.planFrequency);
        }
      })
    }
    return planFrequency;
  }

  handleBackToProfile = () => {
    //event-tracker: Initial -> Portfolio viewed.
    // tracker.initial.portfolioViewed();
    this.setState({ activeScreenType: screenTypes.VIEW_PROFILE });
  };

  handleFrequencyChange = ({ selectedFrequency }) => {
    this.setState({ selectedFrequency });
  }

  handlePlanSelection = (planData) => {
    this.setState({ selectedPlanData: planData, confirmDialogOpen: true });
  };

  handlePaymentClick = () => {
    //event-tracker: Initial -> proceed to pay.
    // tracker.initial.iProceedToPay();
    this.setState({ selectedPlanData: null, confirmDialogOpen: false });
    this.props.history.push({
      pathname: "/customer/subscription",
      state: {
        returnURI: "/customer/profile",
        returnURIonSuccess: "/customer/dashboard",
        productCode: config.productCodes.PORTFOLIO,
        planId: this.state.selectedPlanData.planId,
        paymentType: "RENEWAL",
      },
    });
  }

  handlePlanAndPricingClick = () => {
    if (
      !Object.keys(this.props.renewalPricingDetails).length
    ) {
      this.props.getRenewSubscriptionAction();
    }
    this.props.history.push({
      pathname: "/customer/plan-and-pricing",
      state: {
        returnURI: "/customer/dashboard",
        paymentType: "RENEWAL",
      },
    });
  }

  handleOnActionPressForPortfolio = () => {
    this.props.history.push({
      pathname: `/customer/profile/subscription`,
      state: {
        productCode: config.productCodes.PORTFOLIO
      }
    }
    );
  }

  handleOnActionPressForProtect = () => {
    this.props.history.push({
      pathname: `/customer/profile/subscription`,
      state: {
        productCode: config.productCodes.RMS
      }
    }
    );
  }

  handleOnActionPressForOnestock = () => {
    this.props.history.push({
      pathname: `/customer/profile/subscription`,
      state: {
        productCode: config.productCodes.ONESTOCK
      }
    }
    );
  }

  render() {
    const {  subscriptionPlans, renewalPricingDetails, productSubscriptionList } = this.props;


    let { rmsData, portfolioData, onestockData } = productSubscriptionList;

    const { activeScreenType, selectedFrequency,
      selectedPlanData, confirmDialogOpen } = this.state;

    if (this.props.isProfileLoading) {
      return (
        <Loader position="absolute" loading={this.props.isProfileLoading} />
      );
    }


    if (activeScreenType === screenTypes.SUBSCRIPTION_PLANS) {
      const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();
      return (
        <JarvisCard>
          <JarvisSubscriptionPlans
            onBackClick={this.handleBackToProfile}
            subscriptionPlans={subscriptionPlans}
            subscriptionFrequencies={subscriptionFrequencies}
            onFrequencyChange={this.handleFrequencyChange}
            onPlanSelect={this.handlePlanSelection}
            selectedFrequency={selectedFrequency}
            currentPortfolioCount={renewalPricingDetails.currentPortfolioCount}
            currentStockCount={renewalPricingDetails.currentStockCount}
            currentInvestmentAmount={renewalPricingDetails.investmentAmount}
            selectedPlanData={selectedPlanData}
            confirmDialog={{
              open: confirmDialogOpen,
              title: `Selected Plan: <span style="white-space: nowrap">${selectedPlanData?.planName}</span>`,
              content: planFrequencyMessage[selectedFrequency],
              onConfirm: () => this.handlePaymentClick(),
              onCancel: () => this.setState({ confirmDialogOpen: false, selectedPlanData: null }),
            }}
          />
        </JarvisCard>
      )
    }

    return (
      
      <MobileResponsive 
      mobile={()=> 
      <Box pb={2}>
        {/* <Box py={1}> */}
        <PageTitle heading="Profile" disableBreadcrumb />
        {/* </Box> */}

        <Box
          sx={{
            backgroundColor: { xs: "unset", md: "#ffffff" },
            borderRadius: "8px",
            p: { xs: 0, md: 3 },
          }}
        >
          {/* Personal Details */}
          <Box mb={4}>
            <Text
              color="#A2A7AE"
              pb={2}
              sx={{ fontWeight: "500", fontSize: "12px" }}
            >
              Personal Details
            </Text>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ProfileContainer />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <RiskProfileContainer />
              </Grid>
            </Grid>
          </Box>

          {/* Jarvis Portfolio */}
          {portfolioData?.planName ? (
            <Box mb={4}>
              <Text
                color="#A2A7AE"
                pb={2}
                sx={{ fontWeight: "500", fontSize: "12px" }}
              >
                {portfolioData?.planName}
              </Text>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <ProductDetailsCard
                    planStatus={portfolioData?.planStatus}
                    billingStatus={portfolioData?.expiryDate}
                    actionName="View Details"
                    onActionPress={() => this.handleOnActionPressForPortfolio()}
                    productName={portfolioData?.planName}
                    productAmount={portfolioData?.planPrice}
                    productTenure={portfolioData?.paymentFrequency}
                    autoPayEnabled={portfolioData?.autoPayEnabled}
                  // showCheckCircle={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {this.props.isPortfolioOnboardingCompleted ? (
                    <InvestmentStrategy />
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <></>
          )}

          {/* Jarvis Protect */}
          {rmsData?.planName ? (
            <Box>
              <Text
                color="#A2A7AE"
                pb={2}
                sx={{ fontWeight: "500", fontSize: "12px" }}
              >
                {rmsData?.planName}
              </Text>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <ProductDetailsCard
                    planStatus={rmsData?.planStatus}
                    billingStatus={rmsData?.expiryDate}
                    actionName="View Details"
                    onActionPress={() => this.handleOnActionPressForProtect()}
                    productName={rmsData?.planName}
                    productAmount={rmsData?.planPrice}
                    productTenure={rmsData?.paymentFrequency}
                    forProtect
                  // showCheckCircle={false}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <></>
          )}

          {/* Jarvis Onestock */}
          {onestockData?.planName ? (
            <Box mt={2}>
              <Text
                color="#A2A7AE"
                pb={2}
                sx={{ fontWeight: "500", fontSize: "12px" }}
              >
                {onestockData?.planName}
              </Text>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <ProductDetailsCard
                    planStatus={onestockData?.planStatus}
                    billingStatus={onestockData?.expiryDate}
                    actionName="View Details"
                    onActionPress={() => this.handleOnActionPressForOnestock()}
                    productName={onestockData?.planName}
                    productAmount={onestockData?.planPrice}
                    productTenure={onestockData?.paymentFrequency}
                    forProtect
                  // showCheckCircle={false}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <></>
          )}

        </Box>
      </Box>
      } 

      web={()=> 
      <Box pb={2}>
      {/* <Box py={1}> */}
      <PageTitle heading="Profile" disableBreadcrumb />
      {/* </Box> */}

      <Box
        sx={{
          backgroundColor: { xs: "unset", md: "#ffffff" },
          borderRadius: "8px",
          p: { xs: 0, md: 3 },
        }}
      >
        {/* Personal Details */}
        <Box mb={4}>
          <Text
            color="#A2A7AE"
            pb={2}
            sx={{ fontWeight: "500", fontSize: "12px" }}
          >
            Personal Details
          </Text>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ProfileContainer />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <RiskProfileContainer />
            </Grid>
          </Grid>
        </Box>

        {/* Jarvis Portfolio */}
        {portfolioData?.planName ? (
          <Box mb={4}>
            <Text
              color="#A2A7AE"
              pb={2}
              sx={{ fontWeight: "500", fontSize: "12px" }}
            >
              {portfolioData?.planName}
            </Text>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ProductDetailsCard
                  planStatus={portfolioData?.planStatus}
                  billingStatus={portfolioData?.expiryDate}
                  actionName="View Details"
                  onActionPress={() => this.handleOnActionPressForPortfolio()}
                  productName={portfolioData?.planName}
                  productAmount={portfolioData?.planPrice}
                  productTenure={portfolioData?.paymentFrequency}
                  autoPayEnabled={portfolioData?.autoPayEnabled}
                // showCheckCircle={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {this.props.isPortfolioOnboardingCompleted ? (
                  <InvestmentStrategy />
                ) : null}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}

        {/* Jarvis Protect */}
        {rmsData?.planName ? (
          <Box >
            <Text
              color="#A2A7AE"
              pb={2}
              sx={{ fontWeight: "500", fontSize: "12px" }}
            >
              {rmsData?.planName}
            </Text>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ProductDetailsCard
                  planStatus={rmsData?.planStatus}
                  billingStatus={rmsData?.expiryDate}
                  actionName="View Details"
                  onActionPress={() => this.handleOnActionPressForProtect()}
                  productName={rmsData?.planName}
                  productAmount={rmsData?.planPrice}
                  productTenure={rmsData?.paymentFrequency}
                  forProtect
                // showCheckCircle={false}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}

        {/* Jarvis Onestock */}
        {onestockData?.planName ? (
          <Box mt={3}>
            <Text
              color="#A2A7AE"
              pb={2}
              sx={{ fontWeight: "500", fontSize: "12px" }}
            >
              {onestockData?.planName}
            </Text>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ProductDetailsCard
                  planStatus={onestockData?.planStatus}
                  billingStatus={onestockData?.expiryDate}
                  actionName="View Details"
                  onActionPress={() => this.handleOnActionPressForOnestock()}
                  productName={onestockData?.planName}
                  productAmount={onestockData?.planPrice}
                  productTenure={onestockData?.paymentFrequency}
                  forProtect
                // showCheckCircle={false}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}

      </Box>
    </Box>
    }  
      />
    );

  }
}

export default withRouter(Profile);
