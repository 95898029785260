import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvisRMSDashboardOnboardedActions } from "stores/jarvisRMS/jarvisRMSDashboard/jarvisRMSDashboardonboardedActions";

const mapStateToProps = (state) => {
    return {
        marketCapStats: state.jarvisRMS.RMSDashboardOnboarded.marketCapStats,
        sectorDiversificationStats:
            state.jarvisRMS.RMSDashboardOnboarded.sectorDiversificationStats,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getMarketCapDataAction:
                jarvisRMSDashboardOnboardedActions.getMarketCapAction,
            getSectorDiversificationAction:
                jarvisRMSDashboardOnboardedActions.getSectorDiversificationAction,
        },
        dispatch
    );

const RMSPortfolioAnalyseDataStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default RMSPortfolioAnalyseDataStore;
