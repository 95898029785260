import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    return {
        currentStage: state.onboarding.currentStage,
        loginProfile: state.customer.loginProfile,
        pan: state.onboarding.pan,
        dateOfBirth: state.onboarding.dateOfBirth,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getOnboardingDataAction: onboardingActions.getOnboardingData,
            setToast: commonActions.setToast,
        },
        dispatch
    );

const CKYCStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default CKYCStore;
