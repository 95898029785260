import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { useLocation } from "react-router";

import Text from "./common/Text";
import Link from "./common/Link";
import Stack from "./common/Stack";
import Breadcrumbs from "./common/Breadcrumbs";

import SeparatorIcon from "@mui/icons-material/NavigateBeforeOutlined";

import SectionTitle from "./SectionTitle";

import { config } from "config";

const breadcrumbNameMap = {
    // customer routes
    "customer/overview": "Overview",
    "customer/refer-jarvis": "Refer Jarvis",
    "customer/profile": "Profile",
    "customer/profile/subscription": "Jarvis Subscription",
    "customer/profile/subscription/:subscriptionId": "",
    "customer/profile/edit-profile": "Edit Profile",
    "customer/payment": "Payment",
    "customer/broker": "Broker",
    "customer/document-signing": "Document Signing",

    // jarvis portfolio routes
    "jarvis-portfolio": "Jarvis Portfolio",
    "jarvis-portfolio/dashboard": "Dashboard",
    "jarvis-portfolio/action-centre": "Action Centre",
    "jarvis-portfolio/invest-more": "Invest More",
    "jarvis-portfolio/start-sip": "Start SIP",
    "jarvis-portfolio/transactions": "Transactions",

    // jarvis portfolio onboarding routes
    "jarvis-portfolio/onboarding": "Onboarding",
    "jarvis-portfolio/onboarding/risk-assessment": "Risk Assessment",
    "jarvis-portfolio/onboarding/investment-preference": "Investment Preference",
    "jarvis-portfolio/onboarding/portfolio-creation": "Portfolio Creation",
    "jarvis-portfolio/onboarding/ckyc": "CKYC Verification & Agreement",
    "jarvis-portfolio/onboarding/choose-broker": "Choose Preferred Broker",

    // jarvis protect (rms) routes
    "jarvis-rms": "Jarvis Protect",
    "jarvis-rms/dashboard": "Dashboard",
    "jarvis-rms/action-centre": "Action Centre",
    "jarvis-rms/existing-holding": "Existing Holding",
    "jarvis-rms/transactions": "Transactions",
    "jarvis-rms/transactions/:transactionId": "",

    // jarvis protect (rms) onboarding routes
    "jarvis-rms/onboarding": "Onboarding",
    "jarvis-rms/onboarding/risk-assessment": "Risk Assessment",
    "jarvis-rms/onboarding/sync-portfolio": "Sync Portfolio",
    "jarvis-rms/onboarding/ckyc": "CKYC & Agreement",

    // jarvis onestock routes
    "jarvis-onestock": "Jarvis OneStock",
    "jarvis-onestock/dashboard": "Dashboard",
    "jarvis-onestock/action-centre": "Action Centre",
    "jarvis-onestock/transactions": "Transactions",

    // knowledge center routes
    "knowledge-centre": "Knowledge Centre",
    "knowledge-centre/blogs": "Blogs",
    "knowledge-centre/market-updates": "Market Updates",
    "knowledge-centre/news-articles": "News & Articles",
    "knowledge-centre/help-support": "Help & Support",
};

const PageTitle = ({ heading, subheading = "", endContent = () => { }, disableBreadcrumb = false, sx = {} }) => {
    const classes = useStyles();
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter(x => x);

    if (config.isMobileApp) {
        return <></>
    }


    return (
        <Stack sx={{ py: 2.5, ...sx }}>
            {disableBreadcrumb ? null : (
                <Breadcrumbs
                    sx={{ display: { xs: "none", md: "flex" } }}
                    separator={<SeparatorIcon sx={{ fontSize: 12 }} />}
                    aria-label="page breadcrumb"
                    classes={{
                        root: classes.breadcrumbRoot,
                        separator: classes.breadcrumbSeparator
                    }}
                >
                    {
                        pathnames.map((path, index) => {
                            const isLast = index === pathnames.length - 1;
                            let to = "";
                            if (["jarvis-portfolio", "jarvis-portfolio/onboarding", "jarvis-rms", "jarvis-rms/onboarding", "jarvis-onestock", "jarvis-onestock/onboarding", "knowledge-centre"].includes(path)) {
                                to = path;
                            } else {
                                to = pathnames.slice(0, index + 1).join("/");
                            }

                            if (["customer", "jarvis-portfolio/onboarding", "jarvis-rms/onboarding"].includes(to)) {
                                return null
                            }

                            if (isLast) {
                                return <Text color="#1D9BFF" sx={{ fontWeight: "medium" }} variant="caption1" key={to}>{breadcrumbNameMap[to]}</Text>
                            }

                            return <Link color="#808591" key={to} underline="hover" to={`/${to}`}>{breadcrumbNameMap[to]}</Link>
                        })
                    }
                </Breadcrumbs>
            )}

            <SectionTitle heading={heading} subheading={subheading} endContent={endContent} />
        </Stack>
    )
}

const useStyles = makeStyles((theme) => createStyles({
    breadcrumbRoot: {
        fontSize: `${theme.typography.caption1.fontSize} !important`,
        marginBottom: `${theme.spacing(0.5)} !important`
    },
    breadcrumbSeparator: {
        marginLeft: `${theme.spacing(0.5)} !important`,
        marginRight: `${theme.spacing(0.5)} !important`,
    },
    mobileHeaderRoot: {
        padding: `${theme.spacing(2)}`,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: "51px",
        borderBottom: `1px solid #E9E9E9`,
        zIndex: 99,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#fff",
    },
}))

export default PageTitle;