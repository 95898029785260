export const jarvisOnestockOnboardingConstants = {
    ONESTOCK_RESET_ONBOARDING_DATA: "ONESTOCK_RESET_ONBOARDING_DATA",
    ONESTOCK_UPDATE_ONBOARDING_DATA: "ONESTOCK_UPDATE_ONBOARDING_DATA",

    // TODO: move to master
    ONESTOCK_RISK_ASSESSMENT_API: "master/risk/assessment",

    // onboarding Data
    ONESTOCK_CONFIRM_RISK_PROFILE_API: "onestock/onboarding/risk-assessment",
    ONESTOCK_ONBOARDING_DATA_API: "onestock/onboarding/details",
    ONESTOCK_ONBOARDING_DATA_SAGA: "ONESTOCK_ONBOARDING_DATA_SAGA",
    ONESTOCK_ONBOARDING_DATA_REQUEST: "ONESTOCK_ONBOARDING_DATA_REQUEST",
    ONESTOCK_ONBOARDING_DATA_RESPONSE: "ONESTOCK_ONBOARDING_DATA_RESPONSE",

    // STAGE 3
    ONESTOCK_CONFIRM_CKYC_DATA_API: "/onestock/onboarding/confirm-kyc",
    ONESTOCK_INITIATE_DOCUMENT_SIGNING_API: `onestock/onboarding/initiate-signing`,


};
