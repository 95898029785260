import { jarvisOnestockDashboardConstants } from "./jarvisOnestockDashboardConstants";

const jarvisOnestockDashboardAction = {};

jarvisOnestockDashboardAction.getDashboardStats = () => ({
    type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_STATS_SAGA,
});

jarvisOnestockDashboardAction.getDashboardReveal = () => ({
    type: jarvisOnestockDashboardConstants.JARVIS_ONESTOCK_DASHBOARD_REVEAL_SAGA,
});

export { jarvisOnestockDashboardAction };
