import React, { Component } from 'react';
import { config } from "config/index";
import Loader from 'components/common/Loader';
import PlanPayment from 'containers/PlanPayment';
import { localStore } from 'stores/localStorage';
import { withRouter } from "react-router-dom";
import PlanAndPricing from 'containers/PlanAndPricing';
import _get from "lodash.get";
class OnestockPaymentContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        
    }

    // handlePaymentClick = (paymentData) => {
    //     localStore.resetPaymentInitiated();
    //     this.props.history.push({
    //         pathname: "/customer/payment",
    //         state: {
    //             productCode: config.productCodes.ONESTOCK,
    //             planId: paymentData.planId,
    //             offerCode: paymentData.offerCode,
    //             paymentMethod: paymentData.paymentMethod,
    //             paymentMethodOption: paymentData.paymentMethodOption,
    //             paymentType: 'INITIAL',
    //             paymentDetails: {
    //                 authType: paymentData.authType,
    //                 accountNumber: paymentData.accountNumber,
    //                 accountType: paymentData.accountType,
    //                 accountHolderName: paymentData.accountHolderName,
    //                 ifscCode: paymentData.ifscCode,
    //             }
    //         },
    //     });
    // }

    render() {

        return (
            <PlanAndPricing productCode={config.productCodes.ONESTOCK} paymentType="INITIAL" />
    //         <PlanPayment
    //             paymentType="INITIAL"
    //             onboardingPlanId={selectedPlan.planId}
    //             disableBackFromPaymentDetails
    //             onPayNowClick={(paymentData) => this.handlePaymentClick(paymentData)}
    //         />
        );
    }
}

export default withRouter(OnestockPaymentContainer);