import { axios } from "../../helper/axios";
import { portfolioHoldingConstants } from "./portfolioHoldingConstants";

const portfolioHoldingApis = {};

portfolioHoldingApis.getPortfolioHoldingList = (body) => {
    return axios.post(portfolioHoldingConstants.PORTFOLIO_HOLDING_API, body);

    //    return {
    //             list : [
    //                 {
    //                     stockBuyDate: "2023-01-10T12:43:48.000Z",
    //                     stockBuyPrice: 895.1083374023438,
    //                     stockBuyValue: 5370.6500244140625,
    //                     stockCurrentPrice: 897.4000244140625,
    //                     stockCurrentValue: 5384.400146484375,
    //                     stockChangePrice: 13.7501220703125,
    //                     stockChangePercentage: 0.2560234237533032,
    //                     stockChangeType: "GAIN",
    //                     stockId: 895.1083374023438,
    //                     stockName: "HATSUN AGRO PRODUCT LTD.",
    //                     stockQuantity: 6,
    //                     stockSymbol: "HATSUN",
    //                     allocationPercentage: 10.664248027115857
    //                 },
    //                 {
    //                     stockBuyDate: "2023-01-10T12:44:46.000Z",
    //                     stockBuyPrice: 1086.2813720703125,
    //                     stockBuyValue: 7603.9696044921875,
    //                     stockCurrentPrice: 1119.5999755859375,
    //                     stockCurrentValue: 7837.1998291015625,
    //                     stockChangePrice: 233.230224609375,
    //                     stockChangePercentage: 3.0672166873416997,
    //                     stockChangeType: "GAIN",
    //                     stockId: 1086.2813720703125,
    //                     stockName: "APL APOLLO TUBES LTD",
    //                     stockQuantity: 7,
    //                     stockSymbol: "APLAPOLLO",
    //                     allocationPercentage: 15.522219846565335
    //                 },
    //                 {
    //                     stockBuyDate: "2023-01-10T10:41:59.000Z",
    //                     stockBuyPrice: 55.99754333496094,
    //                     stockBuyValue: 3247.8575134277344,
    //                     stockCurrentPrice: 58.70000076293945,
    //                     stockCurrentValue: 3404.6000442504883,
    //                     stockChangePrice: 156.7425308227539,
    //                     stockChangePercentage: 4.826028548811874,
    //                     stockChangeType: "GAIN",
    //                     stockId: 55.99754333496094,
    //                     stockName: "Equitas Small Finance Bank Ltd",
    //                     stockQuantity: 58,
    //                     stockSymbol: "EQUITASBNK",
    //                     allocationPercentage: 6.743090839696045
    //                 },
    //         {
    //                     stockBuyDate: "2023-01-10T13:03:31.000Z",
    //                     stockBuyPrice: 1291.9599609375,
    //                     stockBuyValue: 2583.919921875,
    //                     stockCurrentPrice: 1300.9000244140625,
    //                     stockCurrentValue: 2601.800048828125,
    //                     stockChangePrice: 17.880126953125,
    //                     stockChangePercentage: 0.6919768217952527,
    //                     stockChangeType: "GAIN",
    //                     stockId: 1291.9599609375,
    //                     stockName: "Varun Beverages Ltd",
    //                     stockQuantity: 2,
    //                     stockSymbol: "VBL",
    //                     allocationPercentage: 5.153079318553537
    //                 },
    //                 {
    //                     stockBuyDate: "2023-01-10T12:44:46.000Z",
    //                     stockBuyPrice: 989.8963623046875,
    //                     stockBuyValue: 7919.1708984375,
    //                     stockCurrentPrice: 991.0999755859375,
    //                     stockCurrentValue: 7928.7998046875,
    //                     stockChangePrice: 9.62890625,
    //                     stockChangePercentage: 0.12158982769142967,
    //                     stockChangeType: "GAIN",
    //                     stockId: 989.8963623046875,
    //                     stockName: "Sundram Fasteners Ltd",
    //                     stockQuantity: 8,
    //                     stockSymbol: "SUNDRMFAST",
    //                     allocationPercentage: 15.703641143710945
    //                 },
    //                 {
    //                     stockBuyDate: "2023-01-10T13:03:31.000Z",
    //                     stockBuyPrice: 524.9970703125,
    //                     stockBuyValue: 8924.9501953125,
    //                     stockCurrentPrice: 513.5,
    //                     stockCurrentValue: 8729.5,
    //                     stockChangePrice: -195.4501953125,
    //                     stockChangePercentage: -2.1899303753555173,
    //                     stockChangeType: "LOSS",
    //                     stockId: 524.9970703125,
    //                     stockName: "Apollo Pipes Ltd.",
    //                     stockQuantity: 17,
    //                     stockSymbol: "APOLLOPIPE",
    //                     allocationPercentage: 17.289493837765985
    //                 },
    //                 {
    //                     stockBuyDate: "2023-01-10T13:03:32.000Z",
    //                     stockBuyPrice: 1302.9200439453125,
    //                     stockBuyValue: 1302.9200439453125,
    //                     stockCurrentPrice: 1308.199951171875,
    //                     stockCurrentValue: 1308.199951171875,
    //                     stockChangePrice: 5.2799072265625,
    //                     stockChangePercentage: 0.405236472575451,
    //                     stockChangeType: "GAIN",
    //                     stockId: 1302.9200439453125,
    //                     stockName: "Hinduja Global Solutions Ltd.",
    //                     stockQuantity: 1,
    //                     stockSymbol: "HGS",
    //                     allocationPercentage: 2.5909977655480723
    //                 },
    //                 {
    //                     stockBuyDate: "2023-01-10T10:42:00.000Z",
    //                     stockBuyPrice: 871.9164428710938,
    //                     stockBuyValue: 10462.997314453125,
    //                     stockCurrentPrice: 862.9500122070312,
    //                     stockCurrentValue: 10355.400146484375,
    //                     stockChangePrice: -107.59716796875,
    //                     stockChangePercentage: -1.0283589370716935,
    //                     stockChangeType: "LOSS",
    //                     stockId: 871.9164428710938,
    //                     stockName: "Metro Brands Ltd.",
    //                     stockQuantity: 12,
    //                     stockSymbol: "METROBRAND",
    //                     allocationPercentage: 20.50972301050949
    //                 },
    //                 {
    //                     stockBuyDate: "2023-01-10T12:43:48.000Z",
    //                     stockBuyPrice: 993.9933471679688,
    //                     stockBuyValue: 2981.9800415039062,
    //                     stockCurrentPrice: 980.0999755859375,
    //                     stockCurrentValue: 2940.2999267578125,
    //                     stockChangePrice: -41.68011474609375,
    //                     stockChangePercentage: -1.3977328542102903,
    //                     stockChangeType: "LOSS",
    //                     stockId: 993.9933471679688,
    //                     stockName: "Mold-Tek Packaging Ltd.",
    //                     stockQuantity: 3,
    //                     stockSymbol: "MOLDTKPAC",
    //                     allocationPercentage: 5.823506210534736
    //                 }
    //             ],
    //             listCount: 9
    //         }

}

export { portfolioHoldingApis };
