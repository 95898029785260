import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { dashboardActions } from "stores/dashboard/dashboardActions";

const mapStateToProps = (state) => {
    return {
        portfolioMarket: state.dashboard.portfolioMarket
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPortfolioMarketAction: dashboardActions.getPortfolioMarket
}, dispatch)

const MarketCapDiversificationStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default MarketCapDiversificationStore;