import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { dashboardActions } from "stores/dashboard/dashboardActions";

const mapStateToProps = (state) => {
   
    return {
        portfolioOverviewLoading:state.dashboard.loading.portfolioOverview,
        portfolioOverviewData: state.dashboard.portfolioOverview,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPortolioOverviewAction: dashboardActions.getPortfolioOverview,
}, dispatch)

const StatsStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default StatsStore;