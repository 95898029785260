// import { Tabs, Tab as MTab } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import React from "react";
import Box from "./Box";

const MTab = (props) => {
    const { tabs, onChange, value } = props;

    const tabText = {
        fontSize: "0.875rem",
        fontWeight: 500,
    };

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    TabIndicatorProps={{
                        style: {
                            height: 3,
                            borderRadius: "2px 2px 2px 2px",
                        },
                    }}
                    onChange={onChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    {tabs.map((item, i) => (
                        <Tab
                            value={item.portfolioId}
                            label={item.label}
                            sx={tabText}
                        />
                    ))}
                </TabList>
            </Box>
            {props.children}
        </TabContext>
    );
};

export default MTab;
