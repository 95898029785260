import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisOnestockActionCenterConstants } from "./jarvisOnestockActionCenterConstants";

const initialState = {
	actionCenterCalls: [],
    loading: {
        actionCenterCalls: 0,
    },
};

export const jarvisOnestockActionCenterReducer = persistReducer(
	{
		storage,
		key: "jarvisOnestockActionCenter",
		whiteList:[],
	},

	(state = initialState, { type, payload }) => {
		switch (type) {
			case jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_REQUEST: {
                    return{
						...state,
						loading:{
							...state.loading,
							actionCenterCalls: 1
						},
						actionCenterCalls: state.actionCenterCalls,
					};
			}
			case jarvisOnestockActionCenterConstants.GET_ONESTOCK_ACTION_CENTER_DATA_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        actionCenterCalls: 0,
                    },
                    actionCenterCalls: payload,
                };
            }
			default:
				return state;
		}
	}
);
