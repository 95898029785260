import React, { memo } from "react";

import Dialog from "components/common/Dialog";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import JarvisButton from "components/JarvisButton";

const JarvisDialog = ({
  open = false,
  title,
  content,
  note,
  openComponent,
  onConfirm,
  onClose,
  icon = {},
  disableCloseIcon = false,
  setHTML = false,
  button = {
    text: "Continue",
    variant: "contained",
    disableEndIcon: false,
    disabled: false,
    loading: false,
  },
  enableBackdropClick=true
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      icon={icon}
      title={
        setHTML ? (
          <Text
            mt={2}
            sx={{ fontWeight: 500, width: "100%", textAlign: "center" }}
            variant="h5"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        ) : (
          <Text
            mt={2}
            sx={{ fontWeight: 500, width: "100%", textAlign: "center" }}
            variant="h5"
          >
            {title}
          </Text>
        )
      }
      disableCloseIcon={disableCloseIcon}
      openComponent={openComponent}
      contentComponent={() => (
        <Stack>
          {setHTML ? (
            <Text variant="body2" color="#202020" sx={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <Text variant="body2" color="#202020" sx={{ textAlign: "center" }}>{content}</Text>
          )}
          {note && (
            <Text
              variant="small"
              mt={3}
              color="#D44C4C"
              sx={{ textAlign: "center" }}
            >
              {note}
            </Text>
          )}
        </Stack >
      )}
      actionComponent={(onClose) => {
        if (!button.text) {
          return (<Box m={2}></Box>);
        }

        return (
          <Box m={2} textAlign="center">
            <JarvisButton
              variant={button.variant}
              onClick={() => onConfirm(onClose)}
              disableEndIcon={button.disableEndIcon}
              disabled={button.disabled}
              loading={button.loading}
            >
              {button.text}
            </JarvisButton>
          </Box>
        );
      }}
      actionComponentProps={{
        sx: {
          justifyContent: "center",
          marginBottom: "1rem",
        },
      }}
      enableBackdropClick={enableBackdropClick}
    />
  );
};

export default memo(JarvisDialog);
