import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";

import { PrivateRoute } from "helper/route";

import OnboardingLayoutContainer from "containers/Onboarding/OnboardingLayout";

// pages
import RiskAssessment from "pages/JarvisPortfolio/PortfolioOnboarding/PortfolioRiskAssessment";
import InvestmentPreference from "pages/JarvisPortfolio/PortfolioOnboarding/PortfolioInvestmentPreference";
import PortfolioCreation from "pages/JarvisPortfolio/PortfolioOnboarding/PortfolioCreation";
import CKYC from "pages/JarvisPortfolio/PortfolioOnboarding/PortfolioCKYC";
import ChooseBroker from "pages/JarvisPortfolio/PortfolioOnboarding/PortfolioChooseBroker";
import Overview from "pages/JarvisPortfolio/PortfolioOnboarding/Overview";

const JarvisPortfolioOnboardingNavigator = (props) => {
    const { match } = props;
    const auth = useSelector((state) => state.customer);

    return (
        <Switch>
            {/* <Redirect exact from={match.path} to={`${match.path}`} /> */}
            <OnboardingLayoutContainer {...props}>
                <Switch>
                    <PrivateRoute
                        exact
                        auth={auth}
                        path={`${match.path}/overview`}
                        component={Overview}
                    />

                    <PrivateRoute
                        exact
                        auth={auth}
                        path={`${match.path}/risk-assessment`}
                        component={RiskAssessment}
                    />

                    <PrivateRoute
                        exact
                        auth={auth}
                        path={`${match.path}/investment-preference`}
                        component={InvestmentPreference}
                    />

                    <PrivateRoute
                        exact
                        auth={auth}
                        path={`${match.path}/portfolio-creation`}
                        component={PortfolioCreation}
                    />

                    <PrivateRoute
                        exact
                        auth={auth}
                        path={`${match.path}/ckyc`}
                        component={CKYC}
                    />

                    <PrivateRoute
                        exact
                        auth={auth}
                        path={`${match.path}/choose-broker`}
                        component={ChooseBroker}
                    />
                </Switch>
            </OnboardingLayoutContainer>
            <Redirect to={`not-found`} />
        </Switch>
    );
};

export default JarvisPortfolioOnboardingNavigator;