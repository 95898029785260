import React from "react";
import PageTitle from "components/PageTitle";
import DocumentSigningContainer from "containers/DocumentSigning";

const DocumentSigning = (props) => {
    return (
        <>
            <PageTitle heading="Document Signing" />
            <DocumentSigningContainer {...props} />
        </>
    )
}

export default DocumentSigning;