import { call, put, takeLatest } from "@redux-saga/core/effects";

import { portfolioHoldingApis } from "./portfolioHoldingApis";
import { portfolioHoldingParsers } from "./portfolioHoldingParsers";
import { portfolioHoldingConstants } from "./portfolioHoldingConstants";



function* portfolioHoldingSaga(payload) {
    try {
        yield put({ type: portfolioHoldingConstants.PORTFOLIO_HOLDING_REQUEST });
        const res = yield call(portfolioHoldingApis.getPortfolioHoldingList, payload.payload);
        const data = portfolioHoldingParsers.portfolioHoldingParser(res);
        yield put({ type: portfolioHoldingConstants.PORTFOLIO_HOLDING_RESPONSE, payload:data });

    } catch (error) {
        yield put({ type: portfolioHoldingConstants.PORTFOLIO_HOLDING_RESPONSE });
    }
}

export function* registerPortfolioHoldingSaga() {
    yield takeLatest(portfolioHoldingConstants.PORTFOLIO_HOLDING_SAGA, portfolioHoldingSaga);
}