import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { masterActions } from "stores/master/masterActions";
const mapStateToProps = (state) => {
  return {
    brokerList: state.jarvisRMS.rmsOnboarding.brokerList,
    isBrokerListLoading: state.jarvisRMS.rmsOnboarding.loading.brokerList,
    isBrokerListInitialCalled: state.jarvisRMS.rmsOnboarding.initialCalled.brokerList,

    masterBrokers: state.master.brokers,
    isMasterBrokersLoading: state.master.loading.brokers,
    isMasterBrokersInitialCalled: state.master.initialCalled.brokers,

    protectPortfolioUploadTemplate: state.common.featureConfig.protectPortfolioUploadTemplate,
    centrumReferalURL: state.common.featureConfig.centrumReferalURL,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { 
      updatePortfolioId: jarvsiRMSOnboardingActions.updateRMSOnboardingData,
      getBrokerListAction: jarvsiRMSOnboardingActions.getBrokerList,
      getMasterBrokersAction: masterActions.getMasterBrokers,
      getOnboardingDataAction: jarvsiRMSOnboardingActions.getOnboardingData,
    },
    dispatch
  );

const RMSSyncPortfolioStore = (Container) => {
  return connect(mapStateToProps, mapDispatchToProps)(Container);
};

export default RMSSyncPortfolioStore;
