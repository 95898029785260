import React from "react";

const src = "https://www.youtube.com/embed/n0IkjFvagio";

const Video = () => {
    return (
        <iframe
            style={{ marginTop: "20px" }}
            width="100%"
            height="400px"
            src={src}
            title="Jarvis Invest"
            frameborder="0"
            allowFullScreen
        />
    );
};

export default Video;