import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { masterActions } from "stores/master/masterActions";
import { jarvisOnestockDashboardAction } from "stores/jarvisOnestock/jarvisOnestockDashboard/jarvisOnestockDashboardAction";
import { jarvisOnestockExistingHoldingAction } from "stores/jarvisOnestock/jarvisOnestockExistingHolding/jarvisOnestockExistingHoldingAction";

const mapStateToProps = (state) => {
    return {
        // broker
        brokerList: state.master.brokers,
        isBrokerListLoading: state.master.loading.brokers,
        isBrokerListInitialCalled: state.master.initialCalled.brokers,

        // States
        dashboardStatsStore: state.jarvisOnestock.onestockDashboard.dashboardStats,
        dashboardStatsStoreLoader: state.jarvisOnestock.onestockDashboard.loading.dashboardStats,

        // aftere reveal
        dashboardRevealStore: state.jarvisOnestock.onestockDashboard.dashboardReveal,
        dashboardRevealStoreLoader: state.jarvisOnestock.onestockDashboard.loading.dashboardReveal,

        // holding
        existingHoldingListStore: state.jarvisOnestock.onestockExistingHolding.existingHoldingList,

        // Centrum Referal URL
        centrumReferalURL: state.common.featureConfig.centrumReferalURL
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getBrokerListAction: masterActions.getMasterBrokers,
    getDashboardStatsAction: jarvisOnestockDashboardAction.getDashboardStats,
    getExistingHoldingListAction: jarvisOnestockExistingHoldingAction.getExistingHoldingList,
    getDashboardRevealAction: jarvisOnestockDashboardAction.getDashboardReveal,
    pendingCallAction: masterActions.getPendingCall,

}, dispatch);

const OnestockDashboardOnboardedStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockDashboardOnboardedStore;
