import React from "react";
import TextField from "components/common/TextField";
import JarvisButton from "components/JarvisButton";
import Stack from "components/common/Stack";

const RMSPortfolioSyncEditName = ({
  formData = {},
  formError = {},
  onSubmit,
  onChange,
  onClose,
}) => {
  const onChangeInside = (name, event) => {
    const value = event.target.value;
    onChange(name, value);
  };

  return (
    <Stack sx={{ borderRadius: "10px" }}>
      <Stack sx={{ flex: 1 }}>
        <TextField
          sx={{ width: "100%", mb: 2 }}
          type="text"
          value={formData.name}
          onChange={(e) => onChangeInside("name", e)}
          label="Enter New Portfolio Name"
          helperText={formError.name}
          error={formError.name}
        />
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {/* <JarvisButton
          variant="outlined"
          disableEndIcon
          sx={{ width: "100px", boxShadow: "none", border: "none"}}
          onClick={onClose}
        >
          Cancel
        </JarvisButton> */}

        <JarvisButton
          disableEndIcon
          sx={{ width: "100px", boxShadow: "none" }}
          onClick={onSubmit}
        >
          Save
        </JarvisButton>
      </Stack>
    </Stack>
  );
};

export default RMSPortfolioSyncEditName;
