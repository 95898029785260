import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import JarvisButton from "components/JarvisButton";
import Table from "components/common/Table";
import React from "react";


const ConfirmationStockListForArchive = (props) => {
    const { onConfirm, submitLoading, finalData, dataLoading } = props;

   
    const columns = [
        {
            field: "symbol",
            headerText: "Name & Symbol",
            bodyCellComponent: (v, row) => (
                <Stack>
                    <Text variant="small" color="#b1b1b1">{v}</Text>
                    <Text variant="small" sx={{ fontWeight: "600" }}>{row.name}</Text>
                </Stack>
            ),
        },
        {
            field: "quantity",
            headerText: "Quantity",
        },
    ];



    return (
        <Stack>
            {/* Tops header */}
            <Box
                sx={{
                    textAlign: "center",
                    maxWidth: "540px",
                    m: "0 auto 0px auto",
                }}
            >
                <img
                    src="/images/icon-transaction-confirm.png"
                    alt="alert-icon"
                    width="28px"
                />

                <Text
                    variant="subtitle2"
                    sx={{ color: "#1D9BFF", mb: "20px", fontWeight: "600" }}
                >
                    This will be your final portfolio after executing transaction.
                </Text>
            </Box>


            {/* Table */}
            <Box
                sx={{
                    maxHeight: "250px",
                    minHeight: "110px",
                    overflow: "auto",
                    mb: "20px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "6px",
                }}
            >
                <Table
                    data={finalData}
                    columns={columns}
                    loading={dataLoading}
                    td_sx={{
                        fontSize: "14px",
                        padding: "10px",
                        borderRight: "1px solid rgb(241, 241, 241)",
                        borderBottom: "none",
                        fontWeight: "500",
                    }}
                    th_sx={{
                        borderBottom: "1px solid rgb(224, 224, 224)",
                        textAlign: "left",
                        color: "rgb(128, 133, 145)",
                        background: "rgb(241, 241, 241)",
                        fontSize: "12px",
                        fontWeight: " unset",
                        padding: "1px 10px",
                        whiteSpace: "nowrap",
                        borderRight: "1px solid rgb(241, 241, 241)",
                    }}
                />
            </Box>

            {/* Button */}
            <Box textAlign="center">
                <JarvisButton
                    sx={{ fontSize: "16px", p: "8px 25px" }}
                    onClick={() => onConfirm()}
                    loading={submitLoading}
                >
                    Confirm Transaction
                </JarvisButton>
            </Box>
        </Stack>
    )
}

export default ConfirmationStockListForArchive;