import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonActions } from "stores/common/commonActions";
// import { jarvsiRMSOnboardingActions } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingAction";
import { jarvsiOnestockOnboardingActions } from "stores/jarvisOnestock/jarvisOnestockOnboarding/jarvisOnestockOnboardingAction";
import { masterActions } from "stores/master/masterActions";

const mapStateToProps = (state) => {
    console.log(state);
    return {
        currentStage: state.jarvisOnestock.onestockOnboarding.currentStage,
        riskProfiles: state.master.riskProfiles,
        // riskAssessmentQuestions: state.master.riskAssessmentQuestions,
        riskAssessmentQuestions: state.common.riskAssessmentQuestions,
        loginProfile : state.customer.loginProfile,
        isRiskProfileLoading: state.master.loading.riskProfiles,
        isRiskAssessmentLoading: state.master.loading.riskAssessmentQuestions,
        suggestedRiskId: state.onboarding.suggestedRiskId,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateOnboardingDataAction: jarvsiOnestockOnboardingActions.updateOnestockOnboardingData,
    // getRMSRiskAssessmentQuestionsAction: masterActions.getRMSRiskAssessmentQuestions,
    getRiskAssessmentQuestionsAction: commonActions.getRiskAssessmentQuestions,
    getRMSRiskProfilesAction: masterActions.getRMSRiskProfiles,
}, dispatch);

const OnestockRiskAssessmentStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockRiskAssessmentStore;
