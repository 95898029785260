import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisRMSDashboardOnboardedConstants } from "./jarvisRMSDashboardonboardedConstants";

const initialState = {
    portfoliosStats: {
        totalAUM: 0,
        totalStocks: 0,
        portfolios: [],
    },
    rmsStats: {
        totalRMSCalls: 0,
        totalDownfallSaved: 0,
        totalDownfallSavedPercentage: 0,
        totalOpportunityLost: 0,
        totalOpportunityLostPercentage: 0,
        rmsPortfolios: [],
    },
    marketCapStats: {
        overAllStats: {},
        portfolios: {},
    },
    sectorDiversificationStats: {
        overAllStats: {},
        portfolios: {},
    },
    loading: {
        portfoliosStats: 0,
        rmsStats: 0,
        marketCapStats: 0,
        sectorDiversificationStats: 0,
    },
    isInitialCalled: {
        portfoliosStats: 0,
        rmsStats: 0,
        marketCapStats: 0,
        sectorDiversificationStats: 0,
    },
};

export const jarvisRMSDashboardonboardedReducers = persistReducer(
    {
        storage,
        key: "RMSDashboardOnboarded",
        whitelist: [],
    },
    (state = initialState, { type, payload }) => {
        switch (type) {
            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfoliosStats: 1,
                    },
                };
            }

            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        portfoliosStats: 0,
                    },
                    portfoliosStats: {
                        totalAUM:
                            payload.totalAUM || state.portfoliosStats.totalAUM,
                        totalStocks:
                            payload.totalStocks ||
                            state.portfoliosStats.totalStocks,
                        portfolios:
                            payload.portfolios ||
                            state.portfoliosStats.portfolios,
                    },
                    isInitialCalled: {
                        ...state.isInitialCalled,
                        portfoliosStats: 1,
                    },
                };
            }

            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        rmsStats: 1,
                    },
                };
            }

            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        rmsStats: 0,
                    },
                    rmsStats: {
                        ...state.rmsStats,
                        totalRMSCalls:
                            payload.totalRMSCalls ||
                            state.rmsStats.totalRMSCalls,
                        totalDownfallSaved:
                            payload.totalDownfallSaved ||
                            state.rmsStats.totalDownfallSaved,
                        totalDownfallSavedPercentage:
                            payload.totalDownfallSavedPercentage ||
                            state.rmsStats.totalDownfallSavedPercentage,
                        totalOpportunityLost:
                            payload.totalOpportunityLost ||
                            state.rmsStats.totalOpportunityLost,
                        totalOpportunityLostPercentage:
                            payload.totalOpportunityLostPercentage ||
                            state.rmsStats.totalOpportunityLostPercentage,
                        rmsPortfolios:
                            payload.rmsPortfolios ||
                            state.rmsStats.rmsPortfolios,
                    },
                    isInitialCalled: {
                        ...state.isInitialCalled,
                        rmsStats: 1,
                    },
                };
            }

            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        marketCapStats: 1,
                    },
                };
            }

            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        marketCapStats: 0,
                    },
                    marketCapStats:
                        payload.marketCapStats || state.marketCapStats,
                    isInitialCalled: {
                        ...state.isInitialCalled,
                        marketCapStats: 1,
                    },
                };
            }

            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        sectorDiversificationStats: 1,
                    },
                };
            }

            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        sectorDiversificationStats: 0,
                    },
                    sectorDiversificationStats:
                        payload.sectorDiversificationStats ||
                        state.sectorDiversificationStats,
                    isInitialCalled: {
                        ...state.isInitialCalled,
                        sectorDiversificationStats: 1,
                    },
                };
            }
            
            case jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RESET_DASHBOARD_ONBOARDED_DATA : {
                return initialState
            }

            default:
                return state;
        }
    }
);
