import PortfolioCreationContainer from "containers/Onboarding/PortfolioCreation";
import React from "react";

const PortfolioCreation = (props) => {
    return (
        <>
            <PortfolioCreationContainer />
        </>
    )
};

export default PortfolioCreation;