import React, { useState } from "react";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Link from "components/common/Link";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import IconButton from "components/common/IconButton";
import JarvisButton from "components/JarvisButton";
import InputAdornment from "components/common/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import SocialLogin from "containers/SocialLogin";
import Divider from "@mui/material/Divider";
import Image from "components/common/Image";
import { config } from "config";

import Checkbox from 'components/common/CheckboxTick';

// icons
import DoneIcon from "@mui/icons-material/Done";

const useStyles = makeStyles(() => ({
	otpContainer: {
		height: "56px",
		width: "100%",
	},
	otpInput: {
		height: "56px",
		width: "57px",
		border: "1px solid #E0E7FF",
		flex: "1",
	},
	otpResend: {
		color: "#72aaff",
		background: "transparent !important",
		padding: "0",
		boxShadow: "none !important",
		fontSize: "12px",
		display: "inline",
		textTransform: "none",
		cursor: "pointer",
		minWidth: "auto",
		marginRight: "8px",
	},
	root: {
		padding: "0px",
		paddingRight: "10px"
	}
}));

const SignUpForm = ({
	onRequestOtp,
	onSubmit,
	onChange,
	onResendOtp,
	onBackToStep1,
	formData,
	formManage,
	emailResendTime,
	mobileResendTime,
	isLoading,
	formError,
	loginUrl,
	
}) => {
	const classes = useStyles();
	const [hasAgreedConcent, setHasAgreedConcent] = useState(true);
	const [hasAgreedTnc, setHasAgreedTnc] = useState(true);

	const handleAgreementClick = (type, e) => {
		if (type === "consent") {
			setHasAgreedConcent(e.target.checked);
		} else if (type === "tnc") {
			setHasAgreedTnc(e.target.checked);
		}
		disabledToProceed();
	}

	const validateCharCode = (e) => {
		 if(e.charCode == 46) {
			e.preventDefault();
		} 
	}

	const disabledToProceed = () => {
		if (hasAgreedTnc) {
			if (hasAgreedConcent) {
				return false;
			}
		}
		return true;

	}

	return (
		<Stack
			alignItems="center"
			sx={{
				py: 3,
				minHeight: "100%",
				position: "relative",
				margin: { xs: "0 auto", md: "0 24px" },
				padding: "15px",
			}}>
			<Stack
				direction="row"
				justifyContent={
					formManage.isRequestOtp ? "space-between" : "flex-end"
				}
				alignItems="center"
				spacing={1}
				sx={{ width: "100%" }}>
				{formManage.isRequestOtp ? (
					<Stack/>
					// <IconButton onClick={onBackToStep1}>
					// 	<ArrowBackIcon sx={{ color: "black", fontSize: 16 }} />
					// </IconButton>
				) : null}
				<Stack
					direction="row"
					justifyContent="flex-end"
					sx={{ display: { xs: "none", md: "flex" } }}>
					<Text variant="body2" m="0 5px !important">
						Already Have An Account? {"  "}
					</Text>
					<Text variant="body2" color="#1D9BFF">
						<Link href={loginUrl}> Sign In</Link>
					</Text>
				</Stack>
			</Stack>

			<Stack
				sx={{ width: 330, maxWidth: "100%" }}
				mt={{ xs: 0, md: 2 }}
				alignItems="center"
				justifyContent="flex-start">
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					sx={{ 
						mb: { xs: 3, md: 3 }, 
						mt: 4,
						alignSelf: "self-start",
						fontSize: "22px"
					}}>
					<Text
						sx={{
							typography: { xs: "subtitle1", md: "h3" },
							whiteSpace: "nowrap",
							fontSize: "22px !important",
						}}>
						{!formManage.isRequestOtp? 'Create New Account' : 'OTP Verification'}
					</Text>

					<Box sx={{ display: { xs: "none", md: "none" } }}>
						<img
							style={{
								display: "inline-block",
								paddingLeft: "20px",
							}}
							src="/svgs/beta.svg"
							alt="BETA"
						/>
					</Box>
				</Stack>

				{/* Name */}
				{!formManage.isRequestOtp? 
					<TextField
						type="text"
						label="Name"
						value={formData.name}
						onChange={(e) => onChange("name", e.target.value)}
						helperText={formError.name}
						error={formError.name}
						sx={{ width: 1, mb: 2 }}
					/>
				:null}

				{/* Email */}
				{!formManage.isRequestOtp? 
					<TextField
						disabled={formManage.isRequestOtp}
						type="email"
						label="Email"
						value={formData.email}
						onChange={(e) => onChange("email", e.target.value)}
						helperText={formError.email}
						error={formError.email}
						InputProps={
							formManage.emailVerified
								? {
									endAdornment: (
										<InputAdornment position="end">
											<DoneIcon color="#03ca03" />
										</InputAdornment>
									),
								}
								: null
						}
						sx={{ width: 1, mb: 2 }}
					/>
				:null}

				{/* Email OTP*/}
				{/* NOTE: Email validate will not be asked while signup */}
				{/* {formManage.isRequestOtp && !formManage.emailVerified ? (
					<Stack sx={{ width: 1, mb: 2 }}>
						<Stack direction="row" justifyContent="space-between">
							<Text
								variant="caption1"
								sx={{
									wordBreak: "break-word",
									marginRight: "10px",
								}}>
								Enter OTP sent on {formData.email}
							</Text>
							<Text
								variant="caption1"
								sx={{
									whiteSpace: "nowrap",
									alignSelf: "flex-end",
								}}>
								<Button
									component="span"
									className={classes.otpResend}
									onClick={() => onResendOtp("email")}
									disabled={
										emailResendTime > 0 ? true : false
									}
									loading={isLoading}>
									Resend
								</Button>
								{emailResendTime > 0
									? ` in 00:${emailResendTime}`
									: null}
							</Text>
						</Stack>
						<OtpInput
							className={classes.otpContainer}
							inputStyle={classes.otpInput}
							value={formData.emailOTP}
							numInputs={6}
							isInputNum={true}
							onChange={(e) => onChange("emailOTP", e)}
							separator={<span>&nbsp;</span>}
						/>
					</Stack>
				) : null} */}

				{/* Mobile Number*/}
				{!formManage.isRequestOtp? 
					<TextField
						disabled={formManage.isRequestOtp}
						label="Mobile Number"
						type="number"
						value={formData.mobile}
						onKeyPress={(e) => validateCharCode(e)}
						onChange={(e) => onChange("mobile", e.target.value, )}
						helperText={formError.mobile}
						error={formError.mobile}
						InputProps={
							formManage.mobileVerified
								? {
									endAdornment: (
										<InputAdornment position="end">
											<DoneIcon color="#03ca03" />
										</InputAdornment>
									),
								}
								: null
						}
						sx={{ width: 1, mb: 2 }}
					/>
				:null}


				{/* Mobile OTP*/}
				{formManage.isRequestOtp ? (
					<Stack sx={{ width: 1, mb: 2 }}>
						{/* <Text component="h2" sx={{fontWeight: "500"}}>OTP Verification</Text> */}
						<Stack direction="row" justifyContent="space-between">
							<Text
								variant="caption1"
								sx={{
									wordBreak: "break-word",
									marginRight: "10px",
								}}>
								Enter OTP sent on {formData.mobile}
							</Text>

							<Button
								component="span"
								className={classes.otpResend}
								sx={{
									fontSize: "15px"
								}}
								onClick={onBackToStep1}
								disabled={
									mobileResendTime > 0 ? true : false
								}
								loading={isLoading}>
								Edit
							</Button>
						</Stack>
						<OtpInput
							className={classes.otpContainer}
							inputStyle={classes.otpInput}
							value={formData.mobileOTP}
							numInputs={6}
							isInputNum={true}
							onChange={(e) => onChange("mobileOTP", e)}
							separator={<span>&nbsp;</span>}
						/>

						{/* Resent Mobile OTP */}
						{formManage.isRequestOtp ?  
							<Stack
								variant="caption1"
								className={classes.resendText}
								sx={{
									whiteSpace: "nowrap",
									alignSelf: "flex-end",
									width: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									flexFlow: "row wrap",
									fontSize: "15px"
								}}>
								<Button
									component="span"
									className={classes.otpResend}
									sx={{
										fontSize: "15px"
									}}
									onClick={() => onResendOtp('mobile')}
									disabled={
										mobileResendTime > 0 ? true : false
									}
									loading={isLoading}>
									Resend {mobileResendTime <= 0 && 'OTP'}
								</Button>
								{mobileResendTime > 0 ? (
									<Text
										variant="caption1"
										sx={{
											wordBreak: "break-word",
										}}>
										in 00:{mobileResendTime < 10 ? `0${mobileResendTime}` : mobileResendTime}
									</Text>
								) : null}
							</Stack>
							:null
						}
					</Stack>
				) : null}

				{/* Password */}
				{/* NOTE: password based validtion is not being used. */}

				{/* <TextField
					disabled={formManage.isRequestOtp}
					type="password"
					label="Password"
					value={formData.password}
					onChange={(e) => onChange("password", e.target.value)}
					helperText={formError.password}
					error={formError.password}
					sx={{ width: 1, mb: 2 }}
				/>


				/> */}

				{formManage.isRequestOtp ? (
					<>
						{/* SignUp button */}
						<Stack
							direction="row"
							justifyContent={{ xs: "center", md: "flex-start" }}
							sx={{ width: "100%", mt: { xs: 2, md: 0 } }}>
							<JarvisButton
								sx={{ mb: 4, width: "100%" }}
								onClick={onSubmit}
								loading={isLoading}
								disabled={formData.mobileOTP.length !== 6}
								>
								Confirm OTP
							</JarvisButton>
						</Stack>
					</>
				) : (
					<>
						{/* SignUp button */}
						{/* Concent & tnc */}
						<Stack sx={{ width: "100%", mb: "25px", mt: "7px" }}>
							<Text fontSize="12px" color="#878787" mb="7px">
								<Checkbox checked={hasAgreedTnc} onChange={(e) => handleAgreementClick("tnc", e)} />
								I agree to the{" "}
								<a href={`${config.disclaimerUrl}`} target="_blank">JARVIS Disclaimer</a> and
								<a href={`${config.websiteUrl}/terms-and-conditions`} target="_blank"> T&Cs.</a>
							</Text>
							<Stack fontSize="12px" color="#878787" direction="row">
								<Checkbox checked={hasAgreedConcent} onChange={(e) => handleAgreementClick("consent", e)} />
								<Box>I give consent for communication over Call, WhatsApp, SMS and Email.</Box>
							</Stack>
						</Stack>
						<Stack
							direction="row"
							justifyContent={{ xs: "center", md: "flex-start" }}
							sx={{ width: "100%", mt: { xs: 2, md: 0 } }}>
							<JarvisButton
								sx={{ mb: 4, width: "100%" }}
								onClick={onRequestOtp}
								loading={isLoading}
								disabled={disabledToProceed()}>
								Continue
							</JarvisButton>
						</Stack>

						{
							config.platform !== "IOS" ? 
							<>
								<Box sx={{ width: "50%", mb: 3,mx: "auto"  }}>
									<Divider>
										<Text variant="body2">OR</Text>
									</Divider>
								</Box>

								{/* Social Login buttons */}
								<SocialLogin />
							</>
							:null
						}

						{/* Login Link */}
						<Box sx={{ display: { xs: "block", md: "none" } }}>
							<Text
								variant="small"
								color="#222B3E"
								sx={{ textAlign: "center", py: 1 }}>
								Already have an account? {"   "}
								<Link href="/login">Login Now</Link>
							</Text>
						</Box>

						{/* SEBI */}
						<Stack
							diretion="row"
							justifyContent="center"
							mt={4}
							sx={{
								width: "100%",
								display: { xs: "block", md: "none" },
							}}>
							<Text textAlign="center" mb={2} color="#88929B">
								SEBI Registered Investment Advisor
							</Text>
							<Image
								src="/images/sebi_mobile.png"
								height={70}
								justifyContent="center"
							/>
						</Stack>
					</>
				)}
				{/* uncomment when ios build */}
				{/* {config.platform !== "IOS" && (
					<>
						<Box sx={{ width: "100%", mb: 3 }}>
							<Divider>
								<Text variant="body2">OR</Text>
							</Divider>
						</Box>
						<SocialLogin />
					</>
				)} */}

				{/* <Box sx={{ display: { xs: "block", md: "none" } }}>
					<Text
						variant="small"
						color="#222B3E"
						sx={{ textAlign: "center", py: 1 }}>
						Already have an account? {"   "}
						<Link href="/login">Login Now</Link>
					</Text>
				</Box> */}
			</Stack>

		</Stack>
	);
};

export default SignUpForm;
