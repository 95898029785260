import React from "react";
import RMSDashboardContainer from "containers/JarvisRMS/RMSDashboard";

function Dashboard() {
	return (
		<>
			<RMSDashboardContainer />
		</>
	);
}

export default Dashboard;
