import React from "react";
import RMSExistingHolding from "containers/JarvisRMS/RMSExistingHolding";

const ExistingHolding = (props) => {

    return (
            <RMSExistingHolding />
    );
};

export default ExistingHolding;
