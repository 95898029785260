import Chart from "components/common/Chart";
import Grid from "components/common/Grid";
import Tab from "components/common/Tab";
import TabPanel from "components/common/TabPanel";
import JarvisCard from "components/JarvisCard";
import DashboardChartCard from "components/Dashboard/DashboardChartCard";
import React from "react";
import { localStore } from "stores/localStorage";

class RMSPortfolioAnalyseDataContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPortfolioId: "",
            isInitialPortfolioSet: false,
            sectorChartData: [],
            marketChartData: [],
        };
    }

    componentDidMount() {
        this.props.getMarketCapDataAction();
        this.props.getSectorDiversificationAction();
    }

    componentDidUpdate() {
        const { marketCapStats, sectorDiversificationStats } = this.props;
        const { portfolios } = this.props.portfoliosStats;

        if (
            portfolios.length &&
            marketCapStats.portfolios.length > 0 &&
            sectorDiversificationStats.portfolios.length > 0 &&
            !this.state.isInitialPortfolioSet
        ) {
            this.getFirstPortfolio();
        }
    }

    getFirstPortfolio = () => {
        const { portfolios } = this.props.marketCapStats;
        const firstPortfoloId = portfolios[0]?.portfolioId;
        this.setState(
            {
                selectedPortfolioId: firstPortfoloId,
                isInitialPortfolioSet: true,
            },
            () => {
                this.getCurrentPortfolioChartData();
            }
        );
    };

    handleTabChange = (e, portfolioId) => {
        this.setState({ selectedPortfolioId: portfolioId }, () => {
            this.getCurrentPortfolioChartData();
        });
    };

    getCurrentPortfolioChartData = () => {
        const { marketCapStats, sectorDiversificationStats } = this.props;
        const { selectedPortfolioId } = this.state;
        const currentMarketChart = marketCapStats.portfolios.find(
            (item) => item.portfolioId === selectedPortfolioId
        );

        const cuurentSectorChart = sectorDiversificationStats.portfolios.find(
            (item) => item.portfolioId === selectedPortfolioId
        );
        this.setState({
            marketChartData: currentMarketChart?.stats || [],
            sectorChartData: cuurentSectorChart?.stats || [],
        });
    };

    render() {
        const isMobileView = localStore.getMobileView();
        const getChartOptions = (title, stats) => {

            return {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                },
                title: {
                    text: "",
                },
                tooltip: {
                    pointFormat:
                        "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: !isMobileView ? true : false,
                            format: "<b>{point.name}</b>",
                        },
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        name: "Industry",
                        colorByPoint: true,
                        data: stats?.map((e) => ({
                            name: e.label,
                            y: e.value,
                            sliced: true,
                            selected: true,
                        })),
                    },
                ],
            };
        };

        const { selectedPortfolioId, sectorChartData, marketChartData } =
            this.state;

        const tabs = this.props.portfoliosStats.portfolios.map((portfolio) => ({
            label: portfolio.portfolioName,
            portfolioId: portfolio.portfolioId,
        }));

        return (
            <JarvisCard>
                <Tab
                    tabs={tabs}
                    onChange={this.handleTabChange}
                    value={selectedPortfolioId}
                >
                    {tabs.map((item, i) => (
                        <TabPanel sx={{ padding: "0" }} key={`tab_${i}`} value={item.portfolioId}>
                            <Grid container spacing={2} mr={0} mt={2}>
                                <Grid item xs={12} lg={6} md={6}>
                                    <DashboardChartCard
                                        sx={{ border: "1.5px solid #F5F5F5" }}
                                        title="Sector Diversification"
                                        renderChart={
                                            <Chart
                                                options={
                                                    sectorChartData && sectorChartData.length
                                                        ? getChartOptions(
                                                            "Sector Diversification",
                                                            sectorChartData
                                                        )
                                                        : {}
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <DashboardChartCard
                                        sx={{ border: "1.5px solid #F5F5F5" }}
                                        title="Market Cap Diversification"
                                        renderChart={
                                            <Chart
                                                options={
                                                    marketChartData && marketChartData.length
                                                        ? getChartOptions(
                                                            "Market Cap Diversification",
                                                            marketChartData
                                                        )
                                                        : {}
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    ))}
                </Tab>
            </JarvisCard>
        );
    }
}

export default RMSPortfolioAnalyseDataContainer;
