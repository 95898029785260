import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Stack from "components/common/Stack";
import CKYCauto from "components/Onboarding/CKYCauto";
import CKYCmanual from "components/Onboarding/CKYCmanual";

// import MenuItem from "components/common/MenuItem";

import { validationHelper } from "helper/validation";
import JarvisButton from "components/JarvisButton";
import { dateTimeHelper } from "helper/dateTime";
import { cameraHelper } from "helper/camera";

import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";
import { masterApis } from "stores/master/masterApis";
import { config } from "config/index";
import { localStore } from "stores/localStorage";

class RMSCkycContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        panNumber: "",
        aadharNumber: "",
        dateOfBirth: "",
        inputIdType: "C",
      },
      manageForm: {
        docType: "",
        docVerify: false,
      },
      formError: {
        aadharNumber: "",
        panNumber: "",
        dateOfBirth: "",
        panCard: "",
        aadharCardFront: "",
        aadharCardBack: "",
      },

      imagesUploadingLoader: {
        panCard: false,
        aadharCardFront: false,
        aadharCardBack: false,
      },

      isVerified: false,
      isLoading: false,

      enableManually: false,
      hasFirstAttempError: false,
    };
  }

  async componentDidMount() {
    if(localStore.isSsoCustomer() && localStore.getSsoCustomerDetails()?.brokerName === config.broker.ssoPlatform.centrum){
      this.props.history.replace({
          pathname: '/customer/document-signing',
          state: {
              returnURI: "/jarvis-rms/dashboard",
              callOrigin: "JARVIS_RMS",
              productCode: config.productCodes.RMS
          },
      });
  }
    const isSsoCustomer = localStore.isSsoCustomer();
    const { currentStage, panNumber, dateOfBirth, isPanUploaded, isAadhaarFrontUploaded, isAadhaarBackUploaded, tempCustomerPortfolioId, isRMSOnboardingCompleted } = this.props;
    if (currentStage === "CKYC" && tempCustomerPortfolioId && !isRMSOnboardingCompleted) {
      jarvisRMSOnboardingApis.confirmPortfolioStock({ portfolioId: tempCustomerPortfolioId });
    }
    if(isSsoCustomer && currentStage === "CKYC"){
      const {formData}= this.state
      formData.panNumber=panNumber;
      formData.dateOfBirth = dateOfBirth;
      this.setState({ formData /*isVerified: true*/});
      // await jarvisRMSOnboardingApis.confirmRMSCKYCData(); 
      // this.props.getRMSOnboardingDataAction();     
    }

    if (currentStage === "DOCUMENT_SIGNING") {
      this.setState({
        formData: {
          panNumber: panNumber,
          dateOfBirth: dateOfBirth,
        },
        isVerified: true,
      });
    }
    if (currentStage === "CKYC" && (isPanUploaded || isAadhaarFrontUploaded || isAadhaarBackUploaded)) {
      this.setState({ enableManually: true });
    }
  }

  toggleKYCtype = (val) => {
    this.setState({ enableManually: val });
  };

  handleChange = ({ name, value }) => {
    const { formData, manageForm } = this.state;
    if (name === "docType") {
      manageForm[name] = value;
    } else {
      formData[name] = value;
    }
    this.setState({ formData, manageForm });
  };

  handleValidation = () => {
    const { formError, formData } = this.state;

    const panValidation = validationHelper.pan(formData.panNumber);
    formError.panNumber = panValidation.message;

    const dobValidation = validationHelper.dateOfBirth(formData.dateOfBirth);
    formError.dateOfBirth = dobValidation.message;

    this.setState({ formError });
    return panValidation.isValid && dobValidation.isValid;
  };

  //Verification Click
  handleVerification = async () => {
    if (!this.handleValidation()) {
      return;
    }

    const { formData } = this.state;
    this.setState({ isLoading: true, hasFirstAttempError: false });

    try {
      const res = await masterApis.verfyAutoKYC({
        dateOfBirth: dateTimeHelper.dateOfBirth(formData.dateOfBirth),
        panNumber: formData.panNumber,
      });


      if (res.result.isVerified) {
        this.setState({ isVerified: true }, () => {
          this.props.getRMSOnboardingDataAction();
        })
        await jarvisRMSOnboardingApis.confirmRMSCKYCData();
      } else {
        this.setState({ hasFirstAttempError: true });
      }
    } catch (e) {
      this.setState({ hasFirstAttempError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleValidationReVerification = () => {
    const { formError, formData } = this.state;
    const { isPanUploaded, isAadhaarFrontUploaded, isAadhaarBackUploaded } =
      this.props;

    const panValidation = validationHelper.pan(formData.panNumber);
    formError.panNumber = panValidation.message;

    const dobValidation = validationHelper.dateOfBirth(formData.dateOfBirth);
    formError.dateOfBirth = dobValidation.message;

    const aadharValidation = validationHelper.aadhar(formData.aadharNumber);
    formError.aadharNumber = aadharValidation.message;

    formError.aadharCardFront = !isAadhaarFrontUploaded ? "Upload Aadhaar Front Image" : "";

    formError.aadharCardBack = !isAadhaarBackUploaded ? "Upload Aadhaar Back Image" : "";

    formError.panCard = !isPanUploaded ? "Upload Pan Card Image" : "";

    this.setState({ formError });
    return (
      panValidation.isValid && dobValidation.isValid && aadharValidation.isValid
    );
  };

  // Re-verification Click
  handleReVerification = async () => {
    if (!this.handleValidationReVerification()) {
      return;
    }

    const { formData } = this.state;
    this.setState({ isLoading: true, hasFirstAttempError: false });

    try {
      const res = await masterApis.verifyManualKyc({
        panNumber: formData.panNumber,
        aadhaarNumber: formData.aadharNumber,
        dateOfBirth: formData.dateOfBirth,
        productCode: config.productCodes.RMS
      });

      if (res.result.isVerified) {
        this.setState({ isVerified: res.result.isVerified, enableManually: false }, () => {
        });
        this.props.updateRMSOnboardingData({ isManualKYC: res.result.isVerified });
        this.props.getRMSOnboardingData();
        await jarvisRMSOnboardingApis.confirmRMSCKYCData();
      } else {
        this.props.setToast({ message: res.message });
      }
    } catch (e) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleProceedDocumentSigningClick = async () => {
    let pathname = "/customer/document-signing"
        if(this.props.loginProfile.isManualAgreement === 1){
            pathname = "/manual-document-signing"
        }
    this.props.history.push({
      pathname: pathname,
      state: {
        returnURI: "/jarvis-rms/existing-holding",
        callOrigin: "JARVIS_RMS",
        productCode: config.productCodes.RMS
      },
    });
  };
  readFileAsBlob= async (file)=> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        // Define onload event handler
        reader.onload = function(event) {
            const fileContent = event.target.result;
            const blob = new Blob([fileContent],{type:file.type})
            resolve(blob); // Resolve with the result when reading is complete
        };
        
        // Define onerror event handler
        reader.onerror = function(event) {
            reject(event.target.error); // Reject with the error if reading fails
        };
        
        // Start reading the file
        reader.readAsArrayBuffer(file);
    });
  }
  //   Uploading Image
  onBrowseClick = async ({type, data=null}) => {
    let fileData= data;
    if(fileData?.name){
      const fileName = fileData.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      fileData.format = fileExtension;
    }
    if(config.platform !=="WEB"){
      fileData = await cameraHelper.getPhoto();
      console.log(fileData)
    }

    if (!fileData) {
      return;
    }

    // convert to blob based on file webpath
    let file;
    if(config.platform !== "WEB"){
      file = await fetch(fileData.webPath).then((r) => r.blob());
    }
    else{
      file = await this.readFileAsBlob(fileData);
    }

    const fileKB = file.size / 1024; // Converted in KB.
    if (fileKB > 2000) {
      const fileMB = (fileKB / 1000).toFixed(2);

      // if images are more than 2mb
      this.props.setToast({
        message: `Image must be less than 2MB, your Uploaded image is ${fileMB} MB`,
      });
      return;
    }
    this.setImagesUploadingLoader(type, true);
    try {

      const res = await masterApis.verifyKycDocument({
        [type]: file,
        [`${type}_fileName`]: `${type}_${this.props.loginProfile.customerId}_${dateTimeHelper.now().getTime()}.${fileData.format}`
      });

      if (res.result) {
        const { formError } = this.state;
        formError[type] = ""
        this.setState({ formError });
        this.props.updateRMSOnboardingData({
          isPanUploaded: res.result.panCardUploaded,
          isAadhaarFrontUploaded: res.result.aadharCardFrontUploaded,
          isAadhaarBackUploaded: res.result.aadharCardBackUploaded,
        });
      }
    } catch (e) {
      console.log("error : ", e);
    } finally {
      this.setImagesUploadingLoader(type, false);
    }
  };

  setImagesUploadingLoader = (type, value) => {
    const { imagesUploadingLoader } = this.state;
    imagesUploadingLoader[type] = value;
    this.setState({ imagesUploadingLoader });
  };

  render() {
    const {
      formData,
      formError,
      isVerified,
      isLoading,
      enableManually,
      imagesUploadingLoader,
      hasFirstAttempError,
    } = this.state;

    const { isPanUploaded, isAadhaarFrontUploaded, isAadhaarBackUploaded } =
      this.props;

    const isImagesUploaded = {
      panCard: isPanUploaded,
      aadharCardFront: isAadhaarFrontUploaded,
      aadharCardBack: isAadhaarBackUploaded,
    };
    return (
      <>
        <Stack sx={{ flex: 1 }}>
          {enableManually ? (
            <CKYCmanual
              formData={formData}
              formError={formError}
              isVerified={isVerified}
              isLoading={isLoading}
              isImagesUploaded={isImagesUploaded}
              imagesUploadingLoader={imagesUploadingLoader}
              handleChange={this.handleChange}
              onBrowseClick={this.onBrowseClick}
              handleReVerification={this.handleReVerification}
              toggleKYCtype={this.toggleKYCtype}
              isPanUploaded={this.props.isPanUploaded}
              isAadhaarFrontUploaded={this.props.isAadhaarFrontUploaded}
              isAadhaarBackUploaded={this.props.isAadhaarBackUploaded}
            />
          ) : (
            // CKYC Auto
            <CKYCauto
              formData={formData}
              formError={formError}
              isVerified={isVerified}
              isLoading={isLoading}
              hasFirstAttempError={hasFirstAttempError}
              handleChange={this.handleChange}
              handleVerification={this.handleVerification}
              toggleKYCtype={this.toggleKYCtype}
            />
          )}
          {isVerified ? (
            <Stack
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              mb={3}
              mt={6}
              alignItems="center"
              sx={{ width: { xs: "100%", md: "auto" }, background: "#fff" }}
            >
              <Stack
                sx={{
                  position: { xs: "fixed", md: "unset" },
                  bottom: { xs: "calc(10px + var(--safe-area-inset-bottom)) !important", md: "unset" },
                  width: { xs: "90vw", md: "unset" },
                }}
              >
                <JarvisButton
                  loading={isLoading}
                  onClick={this.handleProceedDocumentSigningClick}
                  sx={{ width: { xs: "100%", md: "auto" } }}
                >
                  Proceed to Client Agreement
                </JarvisButton>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </>
    );
  }
}
export default withRouter(RMSCkycContainer);
