import { customerConstants } from "./customerConstants";

const customerActions = {};

customerActions.logout = () => ({
  type: customerConstants.LOGOUT_SAGA,
});

customerActions.updateAuthData = (payload) => ({
  type: customerConstants.LOGIN_DATA,
  payload,
});

customerActions.getProfile = () => ({
  type: customerConstants.PROFILE_SAGA,
});

customerActions.getReasonDropdownForCancellation = () => ({
  type: customerConstants.REASON_FOR_CANCELLATION_SAGA,
});

customerActions.updateRiskProfile = ({ riskProfileId, riskProfile }) => ({
  type: customerConstants.UPDATE_RISK_PROFILE_DATA,
  payload: {
    riskProfileId,
    riskProfile,
  },
});

customerActions.updateProfile = ({
  profilePic,
  fullName,
  email,
  mobileNo,
  alternateNo,
}) => ({
  type: customerConstants.UPDATE_PROFILE_DATA,
  payload: { profilePic, fullName, email, mobileNo, alternateNo },
});

customerActions.updateInvestmenStrategy = ({ investmentStrategy }) => ({
  type: customerConstants.UPDATE_INVESTMEN_STRATEGY_DATA,
  payload: {
    investmentStrategy,
  },
});

customerActions.getSubscriptionList = () => ({
  type: customerConstants.SUBSCRIPTION_LIST_SAGA
})

customerActions.getRenewalPricingDetails = () => ({
  type: customerConstants.RENEWAL_PRICING_DETAILS_SAGA,
})

customerActions.setDeactiveAccountData = ({ deactiveAccountEmail, deactiveAccountMessage }) => ({
  type: customerConstants.SET_DEACTIVE_ACCOUNT_DATA,
  payload: {
    deactiveAccountEmail,
    deactiveAccountMessage,
  },
});

customerActions.resetDeactiveAccountData = () => ({
  type: customerConstants.RESET_DEACTIVE_ACCOUNT_DATA,
});
customerActions.updateEmail = ({ email }) => ({
  type: customerConstants.UPDATE_LOGIN_EMAIL,
  payload: { email }
})

customerActions.setEmailVerified = ({ data }) => ({
  type: customerConstants.UPDATE_EMAIL_VERIFIED,
  payload: { data }
})

customerActions.getProductsSubscriptionList = () => ({
  type: customerConstants.PRODUCT_SUBSCRIPTION_LIST_SAGA
})

customerActions.getProductsSubscriptionDetails = () => ({
  type: customerConstants.PRODUCT_SUBSCRIPTION_DETAILS_SAGA
})

export { customerActions };
