import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "components/common/InputAdornment";
import TextField from "components/common/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import cx from "classnames";

// type = serarch , select
// optionList = [
//     {label :" " , value:""},
// ]

const JarvisAutoSearch = (props) => {
  const {
    optionList = [],
    onChangeValue = ()=> {},
    onEnterValue = ()=> {},
    onInputChange = ()=> {},
    value,
    type = "search",
    placeholder = "Search",
    label = "",
    sx = {},
    helperText,
    error= false,
    disableClearable= false
  } = props;

  // want to add filter on other key add here
  const filterOptions = createFilterOptions({
    stringify: (optionList) => optionList.label + optionList.value
  });

  const classes = useStyles();
  return (
    <>
      {type === "search" ? (
        <Autocomplete
          sx={sx}
          // inputProps={{className:classes.root}}
          className={cx({ [classes.root]: true })}
          label={props.label}
          classes={{
            option: classes.optionRoot,
          }}
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          options={optionList.map((option) => option.label)}
          value={value}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onEnterValue(e);
            }
          }}
          onInputChange={
            (e) =>{
              onInputChange(e)  
            }
          }
          onChange={(event, newValue) => 
            onChangeValue(newValue)
          }
          renderInput={(params) => (
            <TextField
              placeholder={placeholder}
              {...params}
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment sx={{ mt: "0 !important" }} position="start">
                    <SearchIcon fontSize="small" sx={{ color: `#A2A7AE` }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      ) : (
        <Autocomplete
          sx={sx}
          autoComplete={true}
          options={optionList}
          // value={value}
          filterOptions={filterOptions}
          className={cx({ [classes.select]: true })}
          onChange={(event, newValue) => onChangeValue(newValue)}
          disableClearable={true}
          placeholder={placeholder}
          renderInput={(params) => <TextField {...params} error={error ? true : false} helperText={helperText} label={props.label} />}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      backgroundColor: `#F5F5F5 !important`,
      "& .MuiAutocomplete-inputRoot": {
        padding: `4px 12px`,
        border: `0px`,
        borderRadius: `8px`,
      },
      "& 	.MuiAutocomplete-input": {
        paddingLeft: `4px !important`,
        boxShadow: "none !important",
        fontSize: `${theme.typography.small.fontSize} !important`,
        fontWeight: `400`,
      },
      "& .MuiFormControl-root": {
        boxShadow: "none !important",
      },
      "& .MuiAutocomplete-groupLabel": {
        fontSize: `${theme.typography.small.fontSize} !important`,
      },
    },

    select: {
      "& .MuiInputBase-root": {
        padding: '0px !important',
      },
      "& .MuiAutocomplete-input": {
        padding: "21px 12px 4px 24px !important",
      }
    },
    optionRoot: {
      fontSize: `${theme.typography.small.fontSize} !important`,
    },
  })
);
export default JarvisAutoSearch;
