import React, { Component } from "react";
import _get from "lodash.get";
import { config } from "config";
import { withRouter } from "react-router-dom";

import Text from "components/common/Text";
import Grid from "components/common/Grid";
import Modal from "components/common/Modal";
import Stack from "components/common/Stack";
import StockCard from "components/StockCard";
import OfflineCheckerButton from "components/OfflineCheckerButton";

import RMSSellConfirmation from "components/RMS/RMSSellConfirmation";
import RMSSoldInform from "components/RMS/RMSSoldInform";
// import RMSMessageModal from "components/RMS/RMSMessageModal";
import JarvisCard from "components/JarvisCard";
import SectionTitle from "components/SectionTitle";
import ChooseBrokerIcon from "components/Onboarding/ChooseBrokerIcon";
import Loader from "components/common/Loader";
import { thirdpartyApis } from "stores/thirdparty/thirdpartyApis";
import JarvisButton from "components/JarvisButton";
import RMSRebalanceAlertContainer from "containers/Dashboard/DashboardOnboarded/RMSRebalanceAlert";
import RMSBlockBuyCalls from "components/RMS/RMSBlockBuyCalls";

// tracker
import { tracker } from "library/tracker";

import { localStore } from "stores/localStorage";



class RMSContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalNote: false,
            modalSuccessful: false,
            enableBrokerSelection: false,
            isInitialUpdated: false,
            marketStatusNote: false,
            marketStatusMessage: "",
            // brokerEnvironment: false
        };
    }

    componentDidMount() {
        localStore.setWentBrokerPage(false);
        this.props.getRMSDetailsAction();
        // if (!this.props.hasRmsData || ["Completed", "NotConfirmed"].includes(_get(this.props.location, "state.brokerStatus", ""))) {
        // }

        this.setState({ isInitialUpdated: false });
    }

    componentDidUpdate() {
        if (!this.state.isInitialUpdated && this.props.hasRmsData) {
            // const allowToProceedRms = this.props.executeSellCall || this.props.executeBuyCall;
            // const modalSellCall = !this.props.executeSellCall && this.props.executeSellMsg;
            // const modalBuyCall = !this.props.executeBuyCall && this.props.executeBuyMsg;
            this.setState({ isInitialUpdated: true })
        }
    }

    // handleMessageModleClose = (type) => {
    //     if (type === "sell") {
    //         this.setState({ modalSellCall: false });
    //     } else if (type === "buy") {
    //         this.setState({ modalBuyCall: false });
    //     }
    // };

    handleProceed = () => {
        //NOTE : event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.proceed();
        this.setState({ modalNote: true });
        //NOTE : event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.proceedSuccess();
        //NOTE : event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.notePopup();
    };

    handleNoteContinue = () => {
        //NOTE : event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.continue();
        this.setState({ modalNote: false });
        // const brokerName = _get(this.props.profileData, "brokerName", "");
        // const brokerPlatform = _get(this.props.profileData, "brokerPlatform", "");
        // const brokerId = _get(this.props.profileData, "brokerId", "");

        // if (brokerName && brokerPlatform) {
        //     this.initBrokerFlow({ name: brokerName, platform: brokerPlatform, id: brokerId });
        // } else {
            if (!this.props.brokersList.length) {
                this.props.getBrokersAction({ domain: config.broker.platform.smallcase, productCode: config.productCodes.PORTFOLIO });
            }
            this.setState({ enableBrokerSelection: true })
            //NOTE : event_tracker => actionCenterInvestMore
            tracker.actionCenterInvestMore.continueSuccess();
            //NOTE : event_tracker => actionCenterInvestMore
            tracker.actionCenterInvestMore.borkerPage();
        // }
    };
    
    initBrokerFlow = async (broker) => {
        try {
          await thirdpartyApis.brokerSelected({ selectedBroker: broker.name })
          // this.setState({ brokerEnvironment: true })
        } catch (e) { }
        if (broker.hasAlert) {
            this.setState({ brokerStatusModal: true, brokerStatusMessage: broker.alertMessage });
            return;
        }
        //NOTE : event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.selectBorkerSuccess();
        if (config.environment !== "DEVELOPMENT") {
            try {
                const a = await thirdpartyApis.marketOpenHRStatus();
                if (a.status === false) {
                    this.setState({ marketStatusNote: true, marketStatusMessage: a.msg });
                    return;
                }
                //NOTE : event_tracker => 
                tracker.broker.afterMarketHoursSelectBrokerSuccess({ close_msg: a.msg });
            } catch (error) {
                //NOTE : event_tracker => actionCenterInvestMore
                tracker.actionCenterInvestMore.afterMarketHoursSelectBrokerFailed({ error_msg: error.message });
                console.log(error);
            }
        }
        // setTimeout(()=>{
          this.props.history.push({
            pathname: "/customer/broker",
            state: {
                goBackURI: this.props.location.pathname,
                returnURI: "/jarvis-portfolio/dashboard",
                brokerName: broker.name,
                brokerPlatform: broker.platform,
                processType: "rebalance",
                productCode: config.productCodes.PORTFOLIO,
                executionType: config.broker.executionType.TRANSACTION,
                brokerId: broker.id,
            }
        });
        // },2000)

    }

    handleSuccessfulClick = () => {
        this.props.history.push("/jarvis-portfolio/dashboard");
    };

    handleModalClose = () => {
        //NOTE : event_tracker => actionCenterInvestMore
        tracker.actionCenterInvestMore.continueFailed({ error_msg: "" });
        this.setState({ modalNote: false });
    }
    handleMarketStatusModalClose = () => {
        this.setState({ marketStatusNote: false, marketStatusMessage: "", enableBrokerSelection: false });
    }

    render() {
        const {
            sell = [],
            buy = [],
            isLoading,
            brokersList, isBrokersListInitialCalled, isBrokersListLoading,
            isBuyCallEnabled, centrumReferalURL
        } = this.props;
        const centrumBroker = brokersList.filter((value)=>{
            return value.name === 'centrum'
        });

        // if(this.state.brokerEnvironment){
        //   {/* Pop-up for showing leaving jarvis website to broker website */}
        //   return (
        //     <Modal
        //       open={this.state.brokerEnvironment}
        //       disableClose={true}
        //       size={"sm"}
        //       sx={{ height:"500px"}}
        //     >
        //     <Text sx={{ mt: {xs:"60%", sm: "35%"}, mb: 0.8, fontSize: "22px", textAlign: "center", whiteSpace: "prewrap" }}>You are leaving our environment</Text>
        //     </Modal>
        //   )
        // }

        const RenderStocksGrid = (props) => {
            if (!props.list.length) {
                return null;
            }
            return (
                <JarvisCard sx={{ px: { xs: 0, md: 3 } }} >
                    <SectionTitle
                        sx={{ mb: 1.75 }}
                        heading={(
                            <>
                                Transaction Type:{" "}
                                <Text
                                    component="span"
                                    sx={{
                                        typography: { xs: "subtitle2", md: "subtitle2" },
                                        fontWeight: "bold !important",
                                        lineHeight: "1.25",
                                        color: props.type === "SELL" ? "error.main" : "success.main",
                                    }}
                                >
                                    {props.type}
                                </Text>
                            </>
                        )}
                    />
                    <Stack direction="row" alignItems="stretch" justifyContent="flex-start" sx={{ position: "relative", flexWrap: "wrap" }}>
                        {props.list.map((l, i) => (
                            <Stack key={`stock_${props.type}_${i}`} sx={{ flexGrow: 0, width: { xs: 1, sm: 0.5, md: 0.33 } }}>
                                <StockCard
                                    sx={{
                                        border: `1px solid #e2e2e2`,
                                        filter: !props.isCallEnabled ? "blur(5px)" : "" ,
                                        minHeight:"100%" 
                                    }}
                                    stockDetails={l}
                                    cardType={props.type === "BUY" ? "BUY-PORTFOLIO" : "SELL-PORTFOLIO"}
                                />
                            </Stack>
                        ))}

                        {/* <RMSMessageModal
                            top="50%"
                            modalOpen={props.modalOpen}
                            msgText={props.modalMessage}
                            onClose={props.onModalClose}
                        /> */}
                    </Stack>
                    <RMSBlockBuyCalls
                        isCallEnabled={props.isCallEnabled}
                    />
                </JarvisCard>
            )
        }

        // show loader when broker list calling
        if (isBrokersListLoading || isLoading) {
            return (
                <JarvisCard sx={{ maxHeight: "420px", pb: 10, position: "relative", overflow: "hidden", top: { xs: "120px", md: "0px" } }}>
                    <Loader position="absolute" loading={isBrokersListLoading || isLoading} />
                </JarvisCard>
            )
        }

        // if rms calls not available then show message
        if (sell.length <= 0 && buy.length <= 0) {
            return (
                <>
                    <RMSRebalanceAlertContainer isActionCenter={true} />
                    <JarvisCard sx={{ maxHeight: 420, pb: 10, background: config.isMobileApp ? "unset" : "#fff" }}>
                        <Stack alignItems="center" justifyContent="center" sx={{ flex: 1, textAlign: "center" }} spacing={2}>
                            <Text variant="subtitle1"> Awesome! </Text>
                            <Text variant="body1" sx={{ mt: "0px !important" }}>
                                {"No Sell calls pending"}
                            </Text>
                        </Stack>
                    </JarvisCard>
                </>
            )
        }

        // enable broker selection if broker not found
        if (this.state.enableBrokerSelection) {
            return (
              <>
                <JarvisCard sx={{ px: { xs: 0, md: "20px" } }}>
                  <Text
                    variant="medium"
                    sx={{ fontWeight: 500, mb: "20px", mt: "20px" }}
                  >
                    List of Brokers
                  </Text>
                  {isBrokersListInitialCalled && centrumBroker.length ? (
                    centrumBroker.map((b, index) => (
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        width={{ xs: "100%", md: "50%" }}
                        gap={{ xs: "1rem", md: "2rem" }}
                        mb={{ xs: "1rem", md: "1.5rem" }}
                      >
                        <ChooseBrokerIcon
                          image={b.imageUrl}
                          title={b.displayName}
                          onClick={() => this.initBrokerFlow(b)}
                        />
                        <Stack
                          direction={{ xs: "row", md: "column" }}
                          justifyContent={{ xs: "space-between", md: "center" }}
                          width={{ xs: "100%", md: "fit-content" }}
                        >
                          <Text
                            variant="small"
                            sx={{ fontWeight: 700, placeSelf: "center" }}
                          >
                            Don't have a Centrum account?
                          </Text>
                          <Text
                            variant="small"
                            sx={{
                              fontWeight: 500,
                              color: "#2B70C5",
                              cursor: "pointer",
                              "&::selection": {
                                backgroundColor: "transparent !important",
                              },
                            }}
                            onClick={() => {
                              window.open(centrumReferalURL, "_blank");
                            }}
                          >
                            Open New Account
                          </Text>
                        </Stack>
                      </Stack>
                    ))
                  ) : (
                    <></>
                  )}
                  <Grid
                    container
                    spacing={2.5}
                    sx={{
                      // justifyContent: "space-around",
                      flex: 1,
                      alignItems: "center",
                      pb: "20px",
                      marginTop: "0 !important",
                      // gap:"2rem"
                    }}
                  >
                    <Stack
                      direction="row"
                      ml="1.25rem"
                      width="100%"
                      border="1px solid #F5F5F5"
                    ></Stack>
                    {isBrokersListInitialCalled && !brokersList.length ? (
                      <Grid item>
                        <Text>Brokers not found</Text>
                      </Grid>
                    ) : (
                      brokersList.map((b, index) => {
                        return (
                          <>
                            {b.name === "centrum" ? (
                              <></>
                            ) : (
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={3}
                                lg={2.4}
                                xl={2}
                                key={`broker_${index}`}
                              >
                                <ChooseBrokerIcon
                                  image={b.imageUrl}
                                  title={b.displayName}
                                  onClick={() => this.initBrokerFlow(b)}
                                />
                              </Grid>
                            )}
                          </>
                        );
                      })
                    )}
                  </Grid>
                </JarvisCard>
                {/* Modal: display market status */}
                <Modal
                  open={this.state.marketStatusNote}
                  disableClose={true}
                  size={"xs"}
                  sx={{ padding: "16px 0px" }}
                >
                  <Stack alignItems="center" width={1}>
                    <img
                      src="/images/market-closed.svg"
                      alt="market_closed"
                      width={100}
                    ></img>
                    <Text
                      sx={{
                        mt: 2,
                        mb: 0.8,
                        fontWeight: "bold",
                        fontSize: "22px",
                        textAlign: "center",
                      }}
                    >
                      {this.state.marketStatusMessage}
                    </Text>
                    <Text
                      sx={{ mb: 3, color: "#808591cc", textAlign: "center" }}
                      variant="small"
                    >
                      Try again on the next market day{" "}
                    </Text>
                    <JarvisButton
                      variant="outlined"
                      disableEndIcon="true"
                      onClick={() => this.handleMarketStatusModalClose()}
                    >
                      Okay{" "}
                    </JarvisButton>
                  </Stack>
                </Modal>

                    {/* Section: broker status modal */}
                    <Modal
                        open={this.state.brokerStatusModal}
                        disableClose={true}
                        size={"xs"}
                        sx={{ padding: "16px 0px" }}>
                        <Stack alignItems="center" width={1}>
                            <img src="/images/market-closed.svg" alt="" width={100}></img>
                            <Text sx={{ mt: 2, mb: 0.8, fontWeight: "bold", fontSize: "22px", textAlign: "center" }}>Broker Platform Alert</Text>
                            <Text sx={{ mb: 3, color: "#808591cc", textAlign: "center" }} variant="small">{this.state.brokerStatusMessage}</Text>
                            <JarvisButton variant="outlined" disableEndIcon="true" onClick={() => this.handleBorkerStatusModalClose()}>Okay </JarvisButton>
                        </Stack>
                    </Modal>
                </>

            )
        }

        // show stocks to take rms call
        return (
            <>
                <Stack spacing={2}>
                    {/* NOTE: suggestion is to remove below line as it is not required */}
                    {/* <Text variant="body1" mt={3}>
                        Stocks &amp; Weights
                    </Text> */}

                    <RenderStocksGrid
                        type="SELL"
                        list={sell}
                        isCallEnabled={true}
                    />

                    <RenderStocksGrid
                        type="BUY"
                        list={buy}
                        isCallEnabled={isBuyCallEnabled}
                    />
              <div>
                {isBuyCallEnabled || sell.length > 0 ? (
                  <OfflineCheckerButton
                    offlineButtonProps={{
                      text: "Proceed",
                    }}
                    onlineButtonProps={{
                      text: "Proceed",
                      disabled: false,
                      onClick: this.handleProceed,
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>

                    {/* Section: Note Modal */}
                    <Modal
                        open={this.state.modalNote}
                        size={"xs"}
                        onClose={() => this.handleModalClose()}>

                        <RMSSellConfirmation
                            handleContinueClick={this.handleNoteContinue}
                            hasSellCall={sell.length > 0}
                            isBuyCallEnabled={isBuyCallEnabled}
                        />
                    </Modal>

                    {/* Section: market Status Modal */}
                    <Modal
                        open={this.state.marketStatusNote}
                        disableClose={true}
                        size={"xs"}
                        sx={{ padding: "16px 0px" }}>
                        <Stack alignItems="center" width={1}>
                            <img src="/images/market-closed.svg" alt="" width={100}></img>
                            <Text sx={{ mt: 2, mb: 0.8, fontWeight: "bold", fontSize: "22px", textAlign: "center" }}>{this.state.marketStatusMessage}</Text>
                            <Text sx={{ mb: 3, color: "#808591cc", textAlign: "center" }} variant="small">Try again on the next market day </Text>
                            <JarvisButton variant="outlined" disableEndIcon="true" onClick={() => this.handleMarketStatusModalClose()} >Okay </JarvisButton>
                        </Stack>

                    </Modal>

                    {/* Section: Modal Stocks Sold Successfully */}
                    <Modal
                        open={this.state.modalSuccessful}
                        onClose={() => this.handleSuccessfulClick()}>
                        <RMSSoldInform
                            handleAlertContinueClick={this.handleSuccessfulClick}
                        />
                    </Modal>
                </Stack>
            </>
        )
    }
}

export default withRouter(RMSContainer);
