import { combineReducers } from "redux";
import { jarvisRMSDashboardonboardedReducers } from "./jarvisRMSDashboard/jarvisRMSDashboardonboardedReducers";

import { jarvisRMSOnboardingReducer } from "./jarvisRMSOnboarding/jarvisRMSOnboardingReducer";
import { jarvisRMSExistingHoldingReducer } from "./jarvisRMSExistingHolding/jarvisRMSExistingHoldingReducer";
import { jarvisRMSTransactionReducers } from "./jarvisRMSTransaction/jarvisRMSTransactionReducers";

const jarvisRMSRootReducer = combineReducers({
    rmsOnboarding: jarvisRMSOnboardingReducer,
    rmsExistingHolding: jarvisRMSExistingHoldingReducer,
    rmsTransaction: jarvisRMSTransactionReducers,
    RMSDashboardOnboarded: jarvisRMSDashboardonboardedReducers,
});

export { jarvisRMSRootReducer };
