import { call, put, takeLatest } from "@redux-saga/core/effects";

import { onboardingApis } from "./onboardingApis";
import { onboardingParsers } from "./onboardingParsers";
import { onboardingConstants } from "./onboardingConstants";

function* getOnboardingDataSaga() {
    try {
        yield put({ type: onboardingConstants.ONBOARDING_DATA_REQUEST });

        const res = yield call(onboardingApis.getOnboardingData);
        const payload = onboardingParsers.onboardingData(res);

        yield put({
            type: onboardingConstants.ONBOARDING_DATA_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: onboardingConstants.ONBOARDING_DATA_RESPONSE,
            payload: {},
        });
    }
}

function* getPortfolioDataSaga() {
    try {
        yield put({ type: onboardingConstants.PORTFOLIO_DATA_REQUEST });

        const res = yield call(onboardingApis.getPortfolioData);
        const payload = onboardingParsers.portfolioData(res);

        yield put({
            type: onboardingConstants.PORTFOLIO_DATA_RESPONSE,
            payload,
        });
    } catch (e) {
        yield put({
            type: onboardingConstants.PORTFOLIO_DATA_RESPONSE,
            payload: {},
        });
    }
}

function* getPricingDataSaga() {
    try {
        yield put({ type: onboardingConstants.PRICING_DATA_REQUEST });

        const res = yield call(onboardingApis.getPricingData);
        const payload = onboardingParsers.pricingData(res);

        yield put({
            type: onboardingConstants.PRICING_DATA_RESPONSE,
            payload,
        });
    } catch (error) {
        yield put({
            type: onboardingConstants.PRICING_DATA_RESPONSE,
            payload: {},
        });
    }
}

export function* registerOnboardingSagas() {
    yield takeLatest(
        onboardingConstants.ONBOARDING_DATA_SAGA,
        getOnboardingDataSaga
    );
    yield takeLatest(
        onboardingConstants.PORTFOLIO_DATA_SAGA,
        getPortfolioDataSaga
    );
    yield takeLatest(onboardingConstants.PRICING_DATA_SAGA, getPricingDataSaga);
}
