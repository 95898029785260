import React from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "stores/common/commonActions";
import { commonApis } from "stores/common/commonApis";
import { commonParsers } from "stores/common/commonParsers";
import { localStore } from "stores/localStorage";
import { tracker } from "./index";

const TrackerProvider = (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        const fetchTrackerConfig = async () => {
            try {
                const response = await commonApis.getTrackerConfig()
                const parsedResponse = commonParsers.trackerConfig(response);
                // set config in localstorage
                localStore.setTrackerConfig(parsedResponse);
                // update tracker files with config
                tracker.updateConfig(parsedResponse);

                tracker.initTracker();
            } catch (error) {
                console.error('unable to fetch tracker config', error)
            }
        }

        dispatch(commonActions.getFeatureConfig());
        fetchTrackerConfig();
    }, [dispatch]);

    return props.children
}

export default TrackerProvider;