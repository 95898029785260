import _get from "lodash/get";
import { formatHelper } from "helper/format";

const jarvisRMSDashboardOnboardedParsers = {};

jarvisRMSDashboardOnboardedParsers.getPortfolioStatsParser = (res) => {
    let data = {};
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return data;
    }

    const portfolioData = _get(res, "portfolios", []).map((portfolio) => {
        return {
            portfolioId: _get(portfolio, "portfolioId", ""),
            portfolioName: _get(portfolio, "portfolioName", ""),
            portfolioAUM: _get(portfolio, "portfolioAUM", 0),
            portfolioRisk: _get(portfolio, "portfolioRisk", ""),
            portfolioRiskScore: _get(portfolio, "portfolioRiskScore", 0),
        };
    });

    data = {
        totalAUM: formatHelper.parseDecimal(_get(res, "totalAUM", 0)),
        totalStocks: _get(res, "totalStocks", 0),
        portfolios: portfolioData,
    };
    return data;
};

jarvisRMSDashboardOnboardedParsers.getRmsCallStatsParser = (res) => {
    let data = {};
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return data;
    }

    const rmsPortfolios = _get(res, "portfolios", []).map((portfolio) => {
        return {
            portfolioId: _get(portfolio, "portfolioId", ""),
            portfolioName: _get(portfolio, "portfolioName", ""),
            RMScalls: _get(portfolio, "RMScalls", 0),
            downfallSaved: _get(portfolio, "downfallSaved", 0),
            downfallSavedPercentage: _get(
                portfolio,
                "downfallSavedPercentage",
                0
            ),
            opportunityLost: _get(portfolio, "portfolioId", 0),
            opportunityLostPercentage: _get(portfolio, "portfolioId", 0),
        };
    });

    data = {
        totalRMSCalls: _get(res, "totalRMSCalls", 0),
        totalDownfallSaved: _get(res, "totalDownfallSaved", 0),
        totalDownfallSavedPercentage: _get(
            res,
            "totalDownfallSavedPercentage",
            0
        ),
        totalOpportunityLost: _get(res, "totalOpportunityLost", 0),
        totalOpportunityLostPercentage: _get(
            res,
            "totalOpportunityLostPercentage",
            0
        ),
        rmsPortfolios: rmsPortfolios,
    };
    return data;
};

jarvisRMSDashboardOnboardedParsers.getMarketCapParser = (res) => {
    let data = {};
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return data;
    }

    const getOverAllStats = _get(res, "overallStats", []).map((stats) => {
        return {
            label: _get(stats, "label", ""),
            value: formatHelper.parseDecimal(_get(stats, "value", 0)),
        };
    });

    const getPortfolios = _get(res, "portfolios", []).map((item) => {
        return {
            portfolioId: _get(item, "portfolioId", ""),
            portfolioName: _get(item, "portfolioName", ""),
            stats: _get(item, "stats", []).map((statsItem) => {
                return {
                    label: _get(statsItem, "label", ""),
                    value: formatHelper.parseDecimal(_get(statsItem, "value", 0)),
                };
            }),
        };
    });

    data = {
        marketCapStats: {
            overAllStats: getOverAllStats,
            portfolios: getPortfolios,
        },
    };

    return data;
};

jarvisRMSDashboardOnboardedParsers.getSectorDiversificationParser = (res) => {
    let data = {};
    if (res && res.response) {
        res = res.response;
    }
    if (!res) {
        return data;
    }

    const getOverAllStats = _get(res, "overallStats", []).map((stats) => {
        return {
            label: _get(stats, "label", ""),
            value: formatHelper.parseDecimal(_get(stats, "value", 0)),
        };
    });

    const getPortfolios = _get(res, "portfolios", []).map((item) => {
        return {
            portfolioId: _get(item, "portfolioId", ""),
            portfolioName: _get(item, "portfolioName", ""),
            stats: _get(item, "stats", []).map((statsItem) => {
                return {
                    label: _get(statsItem, "label", ""),
                    value: formatHelper.parseDecimal(_get(statsItem, "value", 0)),
                };
            }),
        };
    });
    
    data =  {
        sectorDiversificationStats:{
            overAllStats:getOverAllStats,
            portfolios:getPortfolios,
        }
    }

    return data;
};

export { jarvisRMSDashboardOnboardedParsers };
