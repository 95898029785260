import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
    return {
        isOnboardingCompleted: state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const OnestockDashboardStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default OnestockDashboardStore;
