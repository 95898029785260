import { helpSupportConstants } from "./helpSupportConstants";

const helpSupportActions = {};

helpSupportActions.getCategoryList = () => ({
    type: helpSupportConstants.CATEGORY_LIST_SAGA,
});

helpSupportActions.getCategoryData = ({ categoryID }) => ({
    type: helpSupportConstants.CATEGORY_DATA_SAGA,
    payload: { categoryID },
});

helpSupportActions.getCategoryQuestions = ({
    selectedCategoryID,
    questionID,
}) => ({
    type: helpSupportConstants.CATEGORY_QUESTIONS_SAGA,
    payload: { selectedCategoryID, questionID },
});

export { helpSupportActions };
