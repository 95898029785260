import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import Text from "components/common/Text";
import Stack from "components/common/Stack";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      flexWrap: "wrap",
      borderRadius: "8px",
      justifyContent: "space-between",
      margin: "20px 0",
      display: "flex",
      alignItems: "center",
    },
    image: {
      height: "106px",
      width: "92px",
      [theme.breakpoints.down("md")]: {
        height: "60px",
        width: "60px !important ",
        borderRadius: "50%",
      },
    },
    heading: {
      marginRight: "20px",
    },
    
  })
);

const DashboardHeader = (props) => {
  const classes = useStyles();
  const { heading = "", subheading = "" } = props;

  return (
    <Stack className={classes.header} direction="row">
      <Stack className={classes.heading}>
        <Text
          variant="body2"
          sx={{
            typography: { lg: "subtitle2" },
            fontWeight: "bold",
            lineHeight: "1.2",
          }}
          color="#222B3E"
        >
          {heading}
        </Text>
        <Text
          variant="caption1"
          color="#808591"
          sx={{ typography: { lg: "body2" } }}
        >
          {subheading}
        </Text>
      </Stack>
    </Stack>
  );
};

export default DashboardHeader;
