import { appsFlyerTracker } from "../appsFlyer";
import { mixpanelTracker } from "../mixpanel";
import { uxcamTracker } from "../uxcam";

const investMoreTracker = {};

investMoreTracker.investMoreTab = () => {
    // const eventName = "invest_more_tab";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

investMoreTracker.investMoreTabFailed = ({ error_msg }) => {
    // const eventName = "invest_more_tab_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

investMoreTracker.investMoreSuccess = () => {
    // const eventName = "invest_more_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};
investMoreTracker.investMoreContinue = () => {
    // const eventName = "im_continue";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

investMoreTracker.investMoreContinueFailed = ({ error_msg }) => {
    // const eventName = "im_continue_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

investMoreTracker.investMoreContinueSuccess = () => {
    // const eventName = "im_continue_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

investMoreTracker.investMoreFeesCalculation = () => {
    // const eventName = "im_fees_calculation";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

investMoreTracker.investMoreFeesCalculationFailed = ({ error_msg }) => {
    // const eventName = "im_fees_calculation_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

investMoreTracker.investMoreFeesCalculationSuccess = () => {
    // const eventName = "im_fees_calculation_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

investMoreTracker.investMoreProceedtoPay = () => {
    const eventName = "im_proceed_to_pay";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { },
    });
};

investMoreTracker.investMorePaymentCompleted = () => {
    const eventName = "im_payment_completed";

    appsFlyerTracker.trackEvents({
        eventName,
    });

    mixpanelTracker.trackEvents({
        eventName,
    });

    uxcamTracker.trackEvents({
        eventName,
        eventData: { },
    });
};

investMoreTracker.investMorePaymentFailed = ({ error_msg }) => {
    // const eventName = "im_payment_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });
};

export { investMoreTracker };
