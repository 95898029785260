import React from "react";
import { config } from "config";
import { appHelper } from "helper/app";
import { localStore } from "stores/localStorage";

const AppHOC = (props) => {
    let currentScroll = 0;

    const intercomVisibilityToggler = (e) => {
        let intercom = document.getElementsByName("intercom-lightweight-app-launcher");
        let scroll = 0;
        if(config.isMobileApp){
            let appLayout = document.getElementById("appContentBody");
            if(appLayout){
                scroll = appLayout.scrollTop;
            }
        }else{
            scroll = e.currentTarget.scrollY;
        }
    
        let deviceWidth = e.currentTarget.innerWidth;

        if (!intercom[0]) {
            intercom = document.getElementsByClassName("intercom-launcher");
        }
        if (!intercom[0]) {
            intercom = document.getElementsByClassName("intercom-launcher-frame");
        }
        if (!intercom[0]) {
            return;
        }

        if (deviceWidth >= 768) {
            return;
        }
        if (scroll > currentScroll) {
            currentScroll = scroll;
            intercom[0].style.transform = "scale(0)";
        } else {
            currentScroll = scroll;
            intercom[0].style.transform = "scale(1)";
        }
    }

    const checkMobileView = () => {
        localStore.setMobileView(appHelper.isMobileView());
    }

    React.useEffect(() => {
        window.addEventListener("scroll", (e) => intercomVisibilityToggler(e));
        window.addEventListener("wheel", (e) => intercomVisibilityToggler(e));
        window.addEventListener("resize", checkMobileView)
        checkMobileView();
        
        return () => {
            window.removeEventListener("scroll", intercomVisibilityToggler);
            window.removeEventListener("wheel", intercomVisibilityToggler);
            window.removeEventListener("resize", checkMobileView)
        }
    }, [])


    return props.children
}
export default AppHOC