import { call, put, takeLatest } from "@redux-saga/core/effects";
import { investMoreApis } from "./investMoreApis";
import { investMoreParsers } from "./investMoreParsers";
import { investMoreConstants } from "./investMoreConstants";

import { tracker } from "library/tracker";

function* getInvestmentStocksSaga() {
    try {
        yield put({ type: investMoreConstants.INVESTMENT_STOCKS_REQUEST });

        const res = yield call(investMoreApis.getInvestmentStocks);
        const payload = investMoreParsers.investmentStocks(res);
        yield put({
            type: investMoreConstants.INVESTMENT_STOCKS_RESPONSE,
            payload,
        });
        // NOTE : event_tracker => investMore
        tracker.investMore.investMoreSuccess();
    } catch (error) {
        yield put({
            type: investMoreConstants.INVESTMENT_STOCKS_RESPONSE,
            payload: {},
        });
        // NOTE: event_tracker => investMore
        tracker.investMore.investMoreTabFailed({error_msg:error.messgae})
    }
}

export function* registerInvestMoreSagas() {
    yield takeLatest(
        investMoreConstants.INVESTMENT_STOCKS_SAGA,
        getInvestmentStocksSaga
    );
}
