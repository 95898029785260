import React from "react";
import DashboardContainer from "containers/Dashboard";
import { tracker } from "library/tracker";

const PortfolioDashboard = () => {
    React.useEffect(() => {
        tracker.pageView();
    }, []);
    return (
        <>
            <DashboardContainer />
        </>
    );
};

export default PortfolioDashboard;
