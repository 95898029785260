import React from "react";
import _get from "lodash.get";
import { withRouter } from "react-router-dom";
import JarvisButton from "components/JarvisButton";
import JarvisCard from "components/JarvisCard";
import SectionTitle from "components/SectionTitle";
import Stack from "components/common/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/common/IconButton";
import JarvisSubscriptionPlans from "components/JarvisSubscriptionPlans";

import PlanPayment from "containers/PlanPayment";

import { config } from "config/index";
import { localStore } from "stores/localStorage";
import { dateTimeHelper } from "helper/dateTime";

const planFrequencyMessage = {
    MONTHLY: "Your Jarvis portfolio subscription auto-renews every month. <br/> You can cancel subscription any time.",
    HALF_YEARLY: "Your Jarvis portfolio subscription auto-renews every six months. <br/> You can cancel subscription any time.",
    YEARLY: "Your Jarvis portfolio subscription auto-renews every year. <br/> You can cancel subscription any time.",
    QUARTERLY: "Your Jarvis portfolio subscription auto-renews every quarter. <br/> You can cancel subscription any time."
}

class PlanAndPricingContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: "PLAN_LIST",
            selectedFrequency: null,
            selectedPlanData: {},
            currentInvestmentAmount: null,
            disabledPlanData:{},
        }
    }

    // DidMount
    componentDidMount() {
        // this.fetchCustomerProfileData();
        const { onBoardingInvestmentAmount, investmentAmount, selectedPlanId, productCode, expiryDate } = this.props;
        const paymentType = _get(this.props, 'location.state.paymentType', this.props.paymentType);
        let selectAmount = 0;
        if (paymentType === "RENEWAL" || paymentType === "UPGRADE") {
            selectAmount = onBoardingInvestmentAmount ? Number(onBoardingInvestmentAmount) : null
        } else {
            selectAmount = investmentAmount
        }

        this.props.getSubscriptionPlansAction({ productCode: productCode });

        // Select initial frequency
        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();
        const firstFrequecy = subscriptionFrequencies[0];
        if (
            subscriptionFrequencies.length &&
            !this.state.selectedFrequency
        ) {
            this.handleFrequencyChange({ selectedFrequency: firstFrequecy })
        }
        if(paymentType === "UPGRADE"){
            this.disabledPreviousPlan({selectedPlanId, expiryDate})
        }
        // Auto select plan
        this.autoSelectPlan({
            selectedPlanId: selectedPlanId,
            selectAmount,
            selectedFreq: firstFrequecy,
            changeMode: "init"
        });
    

        // if(productCode === config.productCodes.ONESTOCK && paymentType != "RENEWAL"){
        //     this.setState({  view : 'PLAN_DETAILS' });
        //     const onestockSelectedPlanData = this.props.subscriptionPlans.find(p => p.planId === this.props.selectedPlanId);
        //     console.log("called", { onestockSelectedPlanData })
        //     this.handlePlanSelection(onestockSelectedPlanData)
        // }
    }

    // DidUpdate
    componentDidUpdate(prevProps, prevState) {
        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();
        const { selectedFrequency } = this.state;

        if (
            subscriptionFrequencies.length &&
            !this.state.selectedFrequency
        ) {
            this.handleFrequencyChange({ selectedFrequency: subscriptionFrequencies[0] });
        }
        // if (this.props.productCode === config.productCodes.ONESTOCK && this.props.subscriptionPlans.length && !Object.keys(this.state.selectedPlanData).length) {
            
        //     // this.setState({ selectedPlanData: onestockSelectedPlanData })
        // }


        // Select Plan on Frequency change
        if (prevState.selectedFrequency !== this.state.selectedFrequency && this.props.productCode !== config.productCodes.ONESTOCK) {

            let selectedPlanId = this.props.selectedPlanId
            this.disabledPreviousPlan({selectedPlanId})
            this.autoSelectPlan({
                selectAmount: this.props.investmentAmount,
                selectedFreq: this.state.selectedFrequency,
                changeMode: "frequency",
                selectedPlanId
            })
        }

        // Select Plan on Amount change

        if (prevProps.investmentAmount !== this.props.investmentAmount && this.props.productCode !== config.productCodes.ONESTOCK) {
            this.setState({ currentInvestmentAmount: this.props.investmentAmount });
            this.autoSelectPlan({
                selectAmount: this.props.investmentAmount,
                selectedFreq: selectedFrequency,
                changeMode: "amount"
            })
        }

    }

    fetchCustomerProfileData = async ()=>{
        await this.props.getProfileDataAction();
        return;
    }


    // Auto select Plan based on amount & Frequency.
    autoSelectPlan = ({ selectedPlanId = "", selectAmount, selectedFreq, changeMode = null }) => {

        const { subscriptionPlans } = this.props;
        const { selectedPlanData } = this.state;
        let autoSelectedPlan = null;


        if ( this.props.productCode === config.productCodes.ONESTOCK) {
            let selectedPlan;
            if(selectedPlanId){
                selectedPlan = subscriptionPlans.find((plan)=>plan.planId === selectedPlanId)
            }
            else{
                selectedPlan= subscriptionPlans.find((plan)=>plan.planCode === "JO0001")

            }
            if(selectedPlan){
                return this.setState({ selectedPlanData: selectedPlan });
            }
            return false;
        }
        if (!selectAmount || this.props.productCode === config.productCodes.RMS) {
            let selectedPlan;
            if(selectedPlanId && this.props.productCode === config.productCodes.RMS){
                selectedPlan = subscriptionPlans.find((plan)=>plan.planId === selectedPlanId);
                if(selectedPlan){
                    return this.setState({selectedPlanData : selectedPlan});
                }
            }
            return false
        }
        if(this.props.productCode === config.productCodes.PORTFOLIO && this.props.paymentType === 'RENEWAL' && selectedPlanId){
            let selectedPlan = subscriptionPlans.find((plan)=>plan.planId === selectedPlanId);
            if(selectedPlan){
                return this.setState({selectedPlanData:selectedPlan});
            }
        }

        if (selectedPlanData && Object.keys(selectedPlanData).length > 0 && changeMode === "amount") {
            let autoSetNotRequired = selectedPlanData.productMap.some(product => {
                if (product.productCode === config.productCodes.PORTFOLIO) {
                    if (product.aumLimit === -1 || Number(selectAmount) <= (product.aumLimit)) {
                        return true
                    }
                    return false
                }
            });

            if (autoSetNotRequired) {
                return;
            }
        }

        let freqPlans = subscriptionPlans.filter(p => p.planFrequency === selectedFreq);
        if (freqPlans && freqPlans.length > 0) {
            freqPlans.some(plan => {
                return plan.productMap.some(product => {
                    if (product.productCode === config.productCodes.PORTFOLIO || product.productCode === config.productCodes.ONESTOCK) {
                        if (product.aumLimit === -1 || Number(selectAmount) <= (product.aumLimit)) {
                            autoSelectedPlan = plan;
                            return true;
                        }
                        return false;
                    }
                })
            })
        }

        if (selectedPlanId) {
            autoSelectedPlan = subscriptionPlans.find(p => p.planId === selectedPlanId);
        }

        if (autoSelectedPlan) {
            this.handlePlanSelection(autoSelectedPlan);
        }
    }
    disabledPreviousPlan = ({selectedPlanId='', expiryDate = ''})=>{
        const { subscriptionPlans } = this.props;
        const currentDate = dateTimeHelper.sipDate(dateTimeHelper.now());
        const currentYear = dateTimeHelper.format(currentDate, "$YYYY");
        const currentMonth = dateTimeHelper.format(currentDate, "$MM");
        const customerPlanExpiryDate = dateTimeHelper.sipDate(expiryDate);
        const customerPlanExpiryMonth = dateTimeHelper.format(customerPlanExpiryDate, "$MM");
        const customerPlanExpiryYear = dateTimeHelper.format(customerPlanExpiryDate, "$YYYY");
        let selectedPlan;
        if(selectedPlanId){
            selectedPlan = subscriptionPlans.find((plan)=>plan.planId === selectedPlanId)
        }
        if(selectedPlan && (currentMonth !== customerPlanExpiryMonth || currentYear !== customerPlanExpiryYear)){
            return this.setState({ disabledPlanData: selectedPlan });
        }
        return false;
    }
    

    getSubscriptionPlanFrequencies = () => {
        const planFrequency = [];
        if (this.props.subscriptionPlans && this.props.subscriptionPlans.length) {
            this.props.subscriptionPlans.forEach(plan => {
                if (!planFrequency.includes(plan.planFrequency)) {
                    planFrequency.push(plan.planFrequency);
                }
            })
        }
        return planFrequency.reverse();
    }

    handleFrequencyChange = ({ selectedFrequency }) => {
        this.setState({ selectedFrequency });
    }

    handlePlanSelection = (planData) => {
        this.setState({ selectedPlanData: planData }, () => {
            if (this.props && this.props.onPlanSelection) {
                this.props.onPlanSelection(planData);
            }
        });
    };

    handleContinue = () => {
        const { productCode } = this.props;
        let productOverview = "";
        localStore.setActiveProduct(productCode);
        switch (productCode) {
            case config.productCodes.PORTFOLIO:
                productOverview = "/jarvis-portfolio/onboarding/overview"
                break;
            case config.productCodes.RMS:
                productOverview = "/jarvis-rms/onboarding/overview"
                break;
            case config.productCodes.ONESTOCK:
                productOverview = "/jarvis-onestock/onboarding/overview"
                break;
                default:
                    return
        }

        if (productOverview) {
            this.props.history.push(productOverview);
        }

    }

    // handlePaymentClick = (paymentData) => {
    //     this.props.history.push({
    //         pathname: "/customer/payment",
    //         state: {
    //             returnURI: "/customer/dashboard",
    //             productCode: config.productCodes.PORTFOLIO,
    //             planId: paymentData.planId,
    //             paymentMethod: paymentData.paymentMethod,
    //             paymentDetails: {
    //                 accountNumber: paymentData.accountNumber,
    //                 accountType: paymentData.accountType,
    //                 accountHolderName: paymentData.accountHolderName,
    //                 ifscCode: paymentData.ifscCode,
    //             }
    //         }
    //     });
    // }

    handlePaymentClick = (paymentData) => {
        //event-tracker: Initial -> proceed to pay.
        // tracker.initial.iProceedToPay();
        // this.setState({ selectedPlanData: null, confirmDialogOpen: false });
        
        localStore.resetPaymentInitiated();
        this.props.history.push({
            pathname:'/customer/onetime-payment',
            state: {
                productCode: this.props.productCode,
                planId: paymentData.planId,
                offerCode: paymentData.offerCode,
                paymentMethod: paymentData.paymentMethod,
                paymentMethodOption: paymentData.paymentMethodOption,
                paymentType: this.props.paymentType,
                paymentDetails: {
                    authType: paymentData.authType,
                    accountNumber: paymentData.accountNumber,
                    accountType: paymentData.accountType,
                    accountHolderName: paymentData.accountHolderName,
                    ifscCode: paymentData.ifscCode,
                }
            },
        });
    }

    handlePlanAndPricingClick = () => {
        if (
            !Object.keys(this.props.renewalPricingDetails).length
        ) {
            this.props.getRenewSubscriptionAction();
        }
        this.setState({ selectedPlanData: null, selectedFrequency: null, });
    }

    handleBackToProfile = () => {
        const returnURI = _get(this.props, 'location.state.returnURI', '/customer/dashboard');
        this.props.history.replace(returnURI)
    }

    handleBackClick = () => {
        const { screenType, productCode, paymentType } = this.props;
        let screenParam = "GO_BACK";
        if (productCode === config.productCodes.RMS && paymentType === "INITIAL") {
            screenParam = screenType.CONFIRM_PORTFOLIO;
        }
        if (productCode === config.productCodes.ONESTOCK && paymentType === "INITIAL") {
            screenParam = screenType.RISK_SUMMARY;
        }
        return this.props.handleScreenChange(screenParam);
    }

    render() {
        const { subscriptionPlans,
            currentPortfolioCount,
            currentStockCount,
            renewalPricingDetails, investmentAmount, productTitle, diasbleCircle, productButtonType = false, productButtonName, onestockData = false } = this.props;

        const { selectedFrequency, view,
            selectedPlanData, confirmDialogOpen,disabledPlanData } = this.state;
        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();

        const paymentType = _get(this.props, 'location.state.paymentType', this.props.paymentType);

        // jarvis plan and pricing padding
        let jCardPadding = "20px 24px";

        if(paymentType === "RENEWAL" || paymentType === "UPGRADE"){
            jCardPadding = "20px 0px";
        } else if(this.props.productCode === config.productCodes.RMS || this.props.productCode === config.productCodes.ONESTOCK ){
            jCardPadding = "20px 0px";
        }

        return (
            <JarvisCard
                sx={{
                    padding: jCardPadding,
                    background: paymentType === "RENEWAL" ||  paymentType === "UPGRADE" ? "unset" : "unset"
                }}
            >
                {view === "PLAN_LIST" && (
                    <Stack>
                        {this.props.productCode === config.productCodes.RMS || paymentType === "RENEWAL" || paymentType === "UPGRADE" ? (
                            <SectionTitle
                                sx={{ display: { xs: "flex", md: 'flex' }, mb: { xs: 2, md: 2 } }}
                                heading="Plans & Pricing"
                                startIcon={
                                    <IconButton onClick={this.handleBackClick}>
                                        <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                                    </IconButton>
                                }
                            />
                        ) : null}
                        
                        <JarvisSubscriptionPlans
                            onBackClick={this.handleBackToProfile}
                            subscriptionPlans={subscriptionPlans}
                            subscriptionFrequencies={subscriptionFrequencies}
                            onFrequencyChange={this.handleFrequencyChange}
                            onPlanSelect={this.handlePlanSelection}
                            selectedFrequency={selectedFrequency}
                            currentPortfolioCount={currentPortfolioCount}
                            currentStockCount={currentStockCount}
                            currentInvestmentAmount={investmentAmount || renewalPricingDetails?.investmentAmount}
                            selectedPlanData={selectedPlanData}
                            disabledPlanData ={disabledPlanData}
                            paymentType={paymentType}
                            confirmDialog={{
                                open: confirmDialogOpen,
                                title: `Selected Plan: <span style="white-space: nowrap">${selectedPlanData?.planName}</span>`,
                                content: planFrequencyMessage[selectedFrequency],
                                onConfirm: () => this.handlePaymentClick(),
                                onCancel: () => this.setState({ confirmDialogOpen: false, selectedPlanData: null }),
                            }}
                            onestockData={onestockData ? true : false}
                            productTitle={productTitle ? productTitle : 'Select the plan'}
                            diasbleCircle={diasbleCircle ? diasbleCircle : ""}
                        />
                        {
                            productButtonType ?
                                <Stack my={3} sx={{ maxWidth: { xs: "90vw", md: "unset" } }} alignItems="end"><JarvisButton sx={{ width: { xs: "100%", md: "unset" } }} onClick={() => this.handleContinue()}>{productButtonName}</JarvisButton></Stack> :
                                null
                        }
                        {
                            (this.props.productCode === config.productCodes.RMS && !productButtonType && paymentType === "INITIAL") ||
                            (this.props.productCode === config.productCodes.ONESTOCK && !productButtonType && paymentType === "INITIAL") ||
                            (this.props.productCode === config.productCodes.RMS && paymentType === "RENEWAL") ||
                            (this.props.productCode === config.productCodes.PORTFOLIO && paymentType === "RENEWAL") ||
                            (this.props.productCode === config.productCodes.PORTFOLIO && paymentType === "UPGRADE") ||
                            (this.props.productCode === config.productCodes.ONESTOCK && paymentType === "UPGRADE") ||
                            (this.props.productCode === config.productCodes.RMS && paymentType === "UPGRADE") ||
                            (this.props.productCode === config.productCodes.ONESTOCK && paymentType === "RENEWAL") ?
                                <Stack
                                    sx={{
                                        background: "unset",
                                        width: { xs: "100%", md: "unset" },
                                        justifyContent: { xs: "center", md: "unset" },
                                        alignItems: { xs: "center", md: "unset" },
                                    }}
                                >
                                    <Stack
                                        mt={3}
                                        alignItems="end"
                                        sx={{
                                            mb: { xs: "unset", md: 3 },
                                            position: { xs: "fixed", md: "unset" },
                                            bottom: { xs: "10px", md: "unset" },
                                            width: { xs: "90vw", md: "unset" },
                                            paddingBottom: { xs: "calc(var(--safe-area-inset-bottom) + 12px) !important" },
                                        }}
                                    >
                                        <JarvisButton
                                            disabled={paymentType !== "UPGRADE"?(!selectedPlanData || !Object.values(selectedPlanData).length):( Object.values(disabledPlanData).length && disabledPlanData?.planId === selectedPlanData?.planId)}
                                            sx={{ width: { xs: "100%", md: "unset" } }}
                                            onClick={() => this.setState({ view: "PLAN_DETAILS" })}
                                        >
                                            Continue
                                        </JarvisButton>
                                    </Stack>
                                </Stack>
                                :
                                null
                        }
                    </Stack>
                )}

                {view === "PLAN_DETAILS" && <PlanPayment paymentType={paymentType} onboardingPlanId={selectedPlanData.planId} navigatePreviousScreen={() => this.setState({ view: 'PLAN_LIST' })} onPayNowClick={this.handlePaymentClick} />}
            </JarvisCard>
        )
    }
}

export default withRouter(PlanAndPricingContainer);
