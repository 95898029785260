import React from "react";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import JarvisButton from "components/JarvisButton";
import { CheckCircle, CheckCircleOutlineRounded } from "@mui/icons-material";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelPlanSubscription from "components/CancelPlanSubscription";
import { dateTimeHelper } from "helper/dateTime";
import { formatHelper } from "helper/format";
import { config } from "config/index";

const PREMIMUM_PLAN_CODES= {
  PORTFOLIO:'JP0003',
  RMS:'JR0003',
  ONESTOCK:'JO0003',
}
const productStats = {
  RMS: [
    {
      label: "Portfolio Utilised",
      limitKey: "portfolioLimit",
      limitLabel: (v) => v,
      limitBalanceKey: "portfolioLimitBalance",
    },
    {
      label: "Stock Utilised",
      limitKey: "stockLimit",
      limitLabel: (v) => v,
      limitBalanceKey: "stockLimitBalance",
    },
  ],
  PORTFOLIO: [
    {
      label: "AUM Utilised",
      limitKey: "aumLimit",
      limitLabel: (v) => formatHelper.currency(v),
      limitBalanceKey: "aumLimitBalance",
    },
  ],
  ONESTOCK: [
    {
      label: "Buy Calls",
      limitKey: "callsLimit",
      limitLabel: (v) => v,
      limitBalanceKey: "callsLimitBalance",
    },
  ],
};

const planStatusMap = {
  ACTIVATED: {
    label: "Active",
    color: "#45AC70",
    icon: () => <CheckCircleOutlineRounded sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Your next payment is due on ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
  EXPIRED: {
    label: "Expired",
    color: "#d44c4c",
    icon: () => <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Your subscription has expired on ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
  CANCELLED: {
    label: "Cancelled",
    color: "#df873a",
    icon: () => <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Your subscription has cancelled on ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
  PAUSED: {
    label: "Paused",
    color: "#df873a",
    icon: () => <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />,
    paymentDateInfo: (billingDate) => billingDate ? `Your subscription has paused on ${dateTimeHelper.displayDate(billingDate)}` : "NA"
  },
}

const calculateUsage = (limit, balance) => {
  if (!limit) {
    return 0;
  }
  if (limit === -1) {
    return balance;
  }
  return limit - balance;
}

const SubscriptionDetailsCard = ({
  planStatus = "",
  showCheckCircle = true,
  billingStatus,
  productName,
  productAmount = 0,
  productTenure,
  planBenefits = [],
  onPlanUpgrade,
  onPlanRenewal,
  reasonsDropdown = [],
  customerSubscriptionId,
  mobileScreen,
  productCode,
  subscriptionDetails = {},
  planDetails = {},
  autoPayEnabled,
}) => {
       const currentDate = dateTimeHelper.sipDate(dateTimeHelper.now());
       const currentYear = dateTimeHelper.format(currentDate, "$YYYY");
       const currentMonth = dateTimeHelper.format(currentDate, "$MM");
       const customerPlanExpiryDate = dateTimeHelper.sipDate(billingStatus);
       const customerPlanExpiryMonth = dateTimeHelper.format(
         customerPlanExpiryDate,
         "$MM"
       );
       const customerPlanExpiryYear = dateTimeHelper.format(
         customerPlanExpiryDate,
         "$YYYY"
       );
  const cancelPlan = () => {
    return (
      <CancelPlanSubscription
        customerSubscriptionId={customerSubscriptionId}
        openComponent={(onOpen) => (
          <Text
            variant="small"
            color={{ xs: "#e5e5e5", md: "#1D9BFF" }}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#A2A7AE",
              fontSize: "1rem",
            }}
            onClick={onOpen}
          >
            Cancel subscription
          </Text>
        )}
        reasonsDropdown={reasonsDropdown}
      />
    );
  };

  const mobileCancelPlan = () => {
    return (
      <CancelPlanSubscription
        customerSubscriptionId={customerSubscriptionId}
        openComponent={(onOpen) => (
          <Text
            variant="small"
            color={{ xs: "#e5e5e5", md: "#1D9BFF" }}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#2B70C5",
              fontSize: ".9rem",
            }}
            onClick={onOpen}
          >
            Click here
          </Text>
        )}
        reasonsDropdown={reasonsDropdown}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #DEDEDE",
          borderRadius: "8px",
        }}
      >
        <Stack direction="row" sx={{ justifyContent: "space-between", padding: { xs: ".3rem .8rem", md: ".5rem 1.3rem" }, borderBottom: "1px solid #EFEFEF" }}>
          <Stack direction="row" sx={{ color: planStatusMap[planStatus] ? planStatusMap[planStatus].color : "#df873a" }}>
            {planStatusMap[planStatus] ? planStatusMap[planStatus].icon() : <ErrorOutlineOutlinedIcon sx={{ fontSize: { xs: "12px", sm: "16px", md: "22px" } }} />}
            <Text sx={{ fontSize: { xs: ".7rem", md: ".9rem" }, fontWeight: 500 }}>
              &nbsp;{planStatusMap[planStatus] ? planStatusMap[planStatus].label : planStatus}
            </Text>
          </Stack>
          <Text sx={{ color: "#676E76", fontSize: { xs: ".7rem", md: ".9rem" }, fontWeight: 400 }}>
            {planStatusMap[planStatus] ? planStatusMap[planStatus].paymentDateInfo(billingStatus) : `Your next payment is due on ${dateTimeHelper.displayDate(billingStatus)}`}
          </Text>
        </Stack>
        <Stack mt={2} mb={2} direction={{ xs: "column", md: "row" }} sx={{ padding: { xs: ".3rem .8rem", md: ".5rem 1.3rem" }, }}>
          <Stack pt={{ xs: 1, md: 3 }} pb={{ xs: 1, md: 2 }} sx={{ minWidth: "20%", borderRight: { xs: "none", md: "1px solid #EBEBEB" } }}>
            <Text
              sx={{
                color: "#212225",
                fontWeight: 500,
                fontSize: { xs: ".95rem", md: "1.25rem" },
              }}
            >
              {productName}
            </Text>
            <Stack direction="row">
              {autoPayEnabled === false ? (
                <Text sx={{ fontWeight: 700, color: "#212225", fontSize: { xs: "1.1rem", md: "1.38rem" } }}>Half Yearly</Text>
              ) : (
                <>
                  <Text sx={{ fontWeight: 700, color: "#212225", fontSize: { xs: "1.1rem", md: "1.38rem" } }}>{formatHelper.currency(productAmount)}</Text>
                  <Text sx={{ fontWeight: 500, color: "#212225", fontSize: { xs: ".8rem", md: "1rem" }, marginTop: { xs: "4px", md: "6px" } }}>
                    /{
                      productTenure === "MONTHLY" ? "month" : "half Year"
                    }
                  </Text>
                </>
              )}
              
            </Stack>
          </Stack>
          <Box pt={{ xs: 0, md: 3 }} pb={{ xs: 1, md: 2 }} sx={{ minWidth: "60%", visibility:productCode === config.productCodes.ONESTOCK ? "Hidden" : "visiable", borderRight: { xs: "none", md: "1px solid #EBEBEB" } }}>
            <Stack direction="column" justifyContent="center" alignItem="center" px={{ xs: 0, md: 2 }}>
              <Text mb={1.5} sx={{ flex: { xs: "unset", md: "0.5" }, color: "#676E76", fontWeight: 400, fontSize: { xs: ".7rem", md: "1rem" } }}>Plan Benefits Utilised</Text>
              {
                productStats[productCode].map((item, index) => {
                  return (
                    <>
                      <Stack
                        mb={1}
                        direction="row"
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Stack direction="row" justifyContent="flex-start">
                          <CheckCircle
                            sx={{
                              marginTop: { xs: "3px", md: "0px" },
                              marginRight: "5px",
                              fontSize: { xs: ".8rem", md: "1.25rem" },
                            }}
                            color="success"
                          />
                          <Text
                            sx={{
                              color: "#212225",
                              fontWeight: 500,
                              fontSize: { xs: ".8rem", md: "1rem" },
                            }}
                          >
                            {item.label}
                          </Text>
                        </Stack>
                        <Stack>
                          <Text
                            sx={{
                              color: "#676E76",
                              fontWeight: 400,
                              fontSize: { xs: ".7rem", md: "1rem" },
                            }}
                          >
                            {" "}
                            <span style={{ color: "#212225" }}>
                              {item.limitLabel(calculateUsage(subscriptionDetails?.[item.limitKey], subscriptionDetails?.[item.limitBalanceKey]))}
                            </span>{" "}
                            / {subscriptionDetails?.[item.limitKey] === -1 ? (`∞`) : item.limitLabel(subscriptionDetails?.[item.limitKey])}
                          </Text>
                        </Stack>
                      </Stack>
                    </>
                  );
                })
              }
            </Stack>
          </Box>
          <Stack
            sx={{
              minWidth: "20%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {planStatus === "ACTIVATED" && !Object.values(PREMIMUM_PLAN_CODES).includes(planDetails?.planCode) &&
              <JarvisButton
                onClick={() => onPlanUpgrade(productCode)}
                disableEndIcon
                variant="outlined"
                sx={{ width: { xs: "100%", md: "unset" }, marginLeft: { xs: "0", md: "23px" }, display: "block" }}
              >
                Upgrade Plan
              </JarvisButton>
            }
            {planStatus === "ACTIVATED" && 
            (currentMonth === customerPlanExpiryMonth && currentYear === customerPlanExpiryYear)
            && Object.values(PREMIMUM_PLAN_CODES).includes(planDetails?.planCode) &&
              <JarvisButton
                onClick={() => onPlanUpgrade(productCode)}
                disableEndIcon
                variant="outlined"
                sx={{ width: { xs: "100%", md: "unset" }, marginLeft: { xs: "0", md: "23px" }, display: "block" }}
              >
                Renew Plan
              </JarvisButton>
            }

             { planStatus !== 'ACTIVATED'
              &&
              <JarvisButton
                onClick={onPlanRenewal}
                disableEndIcon
                variant="outlined"
                sx={{ width: { xs: "100%", md: "unset" }, marginLeft: { xs: "0", md: "23px" } }}
              >
                Renew Plan
              </JarvisButton>
            }
            {
              !mobileScreen ?
                <Text textAlign="center" pt={1} sx={{ textDecoration: "underline", cursor: "pointer", color: "#A2A7AE", fontSize: "1rem", paddingLeft: { lg: 3.5, md: 3.5 } }}>{cancelPlan()}</Text>
                : null
            }
          </Stack>
        </Stack>
      </Box >
      {
        mobileScreen ?
          <Stack direction="row" mt={1}>
            < Text sx={{ color: "#303236", fontSize: ".9rem", marginRight: "5px" }}> To cancel plan subscription </Text >
            <Text sx={{ color: "#2B70C5", fontSize: ".9rem" }}>{mobileCancelPlan()}</Text>
          </Stack >
          : null
      }
    </>
  );
};

export default SubscriptionDetailsCard;
