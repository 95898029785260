import React from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Avatar from "components/common/Avatar";
import JarvisDialog from "components/JarvisDialog";
import { tracker } from "library/tracker";

const InvestmentStrategy = (props) => {
  const { investmentStrategyVal, onChangeClick, title = "Investment Strategy..", actionName = "Change" } = props;

  const changeMyRiskProfile = () => {
    return (
/*       <JarvisDialog
        openComponent={(onOpen) => (
          <Text
            variant="small"
            color="#1D9BFF"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              mt: { xs: "20px", md: "unset" },
              fontSize: { xs: "12px", md: "14px" }
            }}
            onClick={(e) => {
              //event-tracker: Investment Strategy -> is_change
              tracker.investmentStrategy.isChange();
              onOpen(e);
            }}
          >
            Change
          </Text>
        )}
        title={"Change Investment Strategy?"}
        content={
          <>
            <span style={{ fontWeight: 500 }}>
              Rebalancing Portfolio is needed
            </span>{" "}
            when you change your
            <br />
            current Investment Strategy to another one.
          </>
        }
        note={
          <>
            Note : You can only change Investment
            <br /> Strategy once in 6 months
          </>
        }
        onConfirm={(onClose) => onChangeClick(onClose)}
      /> */
      <></>
    );
  };
  return (
    <Box
      height="100%"
      sx={{
        border: "1px solid #DEDEDE",
        borderRadius: "8px",
        height: "100%",
        backgroundColor: "#fff"
      }}
    >
      {/* Header */}
      <Box
        sx={{
          px: { xs: 1, sm: 2, md: 3 },
          py: 1,
          borderBottom: "1px solid #EFEFEF",
        }}
      >
        <Stack direction="row">
          <Text sx={{ color: "#202020", fontSize: { xs: "14px", md: "1rem" }, fontWeight: "400" }} >{title}</Text>
        </Stack>
      </Box>
      {/* Content */}
       <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          px: { xs: 1, sm: 2, md: 3 },
          py: { xs: 1, sm: 2, md: 3 },
          mt: { xs: 2, md: "unset" },
          mb: { xs: 1, md: "unset" },
        }}
      >
        <Stack
          direction="row"
          sx={{ alignItems: "center" }}
        >
          <Stack sx={{
            padding: { xs: "0.3125rem", sm: "0.3125rem" }
          }}>
            <Avatar
              avatarVariant="square"
              src="/images/profile/investment-strategy1.png"
              sx={{
                width: { xs: 35, md: 60 },
                height: { xs: 35, md: 60 },
              }}
            />
          </Stack>
          <Stack sx={{
            padding: { xs: "0.3125rem", sm: "0.3125rem" },
            textAlign: { md: "start" }
          }}>
            <Text
              sx={{ fontSize: { xs: "15px", md: "20px" } }}
              lineHeight={1.2}
              color="#202020"
              maxWidth="200px"
            >
              {investmentStrategyVal
                ? `Asset allocation strategy`
                : `Pure equity strategy`}
            </Text>
          </Stack>
        </Stack>
        <Box sx={{
          padding: { xs: "0.3125rem", sm: "0.3125rem" }
        }}>
          <Stack
            sx={{ pt: { xs: 0, sm: 0, md: 7 } }}
          >
            {changeMyRiskProfile()}
          </Stack>
        </Box>
      </Stack>
    </Box >

  );
};

export default InvestmentStrategy;
