import React, { useState, useEffect } from "react";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Link from "components/common/Link";
import Stack from "components/common/Stack";
import IconButton from "components/common/IconButton";
import Box from "components/common/Box";
import Button from "components/common/Button";
import JarvisButton from "components/JarvisButton";
import { config } from "config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SocialLogin from "containers/SocialLogin";
import Divider from "@mui/material/Divider";
import Image from "components/common/Image";
import OtpInput from "react-otp-input";
import { validationHelper } from "helper/validation";

import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
	otpContainer: {
		height: "56px",
		width: "100%",
	},
	otpInput: {
		height: "56px",
		width: "57px",
		border: "1px solid #E0E7FF",
		flex: "1",
	},
	otpResend: {
		color: "#72aaff",
		background: "transparent !important",
		padding: "0",
		boxShadow: "none !important",
		fontSize: "12px",
		display: "inline",
		textTransform: "none",
		cursor: "pointer",
		minWidth: "auto",
		marginRight: "8px",
	},
	root: {
		padding: "0px",
		paddingRight: "10px"
	}
}));

const LoginTerms = () => {
	return (
		<Box
			sx={{
				mb: "12px",
				display: "flex",
				alignItems: "flex-end",
				justifyContent: "center",
			}}>
			<Text color="#747474" variant="small">
				By continuing, I agree to the{" "}
				<a href={config.disclaimerUrl} target="_blank">JARVIS Disclaimer</a> and <a href={config.tacUrl} target="_blank">T&amp;Cs</a>.
			</Text>
		</Box>
	)
}


const LoginForm = ({
	forgotPwdUrl,
	onSubmit,
	onConfirmOTP,
	onChange,
	isLoading = false,
	formError,
	formData,

	loginSendOtp,
	isLoginRequestOtp,
	loginOtpResendTime,
	onBackToStep1,

	viewTypes,
	onChangeViewType,
	activeViewType,
}) => {
	const classes = useStyles();

	const onEnterSendOtp = (e) => {
		// if ((e.keyCode === 13 || e.which === 13) && inputType !== 0) {
		if (e.keyCode === 13 || e.which === 13) {
			loginSendOtp();
		}
	};

	const isLoginWithPassword = activeViewType === viewTypes.LOGIN_WITH_PASSWORD;

	return (
		<Stack
			alignItems="center"
			sx={{
				pt: 3,
				pb: 3,
				minHeight: "100%",
				margin: { xs: "0 auto", md: "0 24px" },
				padding: "15px",
				minHeight: "100vh",
			}}>
				{config.environment === "DEVELOPMENT" && <span style={{ color: "#ff6262", fontSize: "18px", fontWeight: 500 }}>This is {config.environment} app for {config.platform}</span>}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={1}
				sx={{ width: "100%" }}>
				{!config.isMobileApp ?
					<a href={config.websiteUrl}>
						<IconButton>
							<ArrowBackIcon sx={{ color: "black", fontSize: 16 }} />
						</IconButton>
					</a>
					: null}
				<Stack
					direction="row"
					justifyContent="flex-end"
					sx={{ display: { xs: "none", md: "flex" } }}>
					<Text variant="body2">Don't Have An Account?</Text>
					<Text variant="body2" color="#1D9BFF" m="0 5px !important">
						<Link href="/signup"> Sign up</Link>
					</Text>
				</Stack>
			</Stack>

			<Stack
				// sx={{ maxWidth: { xs: "auto", md: 500 } }}
				sx={{ width: 330, maxWidth: "100%" }}
				mt={{ xs: 0, md: 6 }}
				alignItems="flex-start"
				justifyContent="flex-start">
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					sx={{
						mb: { xs: 3, md: 3 },
						mt: 4,
						width: "100%",
						flex: 1,
						fontSize: "22px !important"
					}}>

					<Text
						textAlign={{ xs: "center", md: "flex-start" }}
						sx={{
							typography: { xs: "subtitle1", md: "h3" },
							whiteSpace: "nowrap",
							fontSize: "22px !important",
							// width: "100%",
						}}>

						Sign In
					</Text>
					<JarvisButton onClick={() => {
						onChangeViewType(isLoginWithPassword ? viewTypes.LOGIN_WITH_OTP : viewTypes.LOGIN_WITH_PASSWORD)
					}} disableEndIcon variant="text" size="small">Login with {isLoginWithPassword ? 'OTP' : 'password'}</JarvisButton>
				</Stack>

				{isLoginWithPassword ? (
					<>
						<TextField
							type="text"
							label="Email"
							value={formData.email}
							onChange={(e) => onChange("email", e.target.value)}
							helperText={formError.email}
							error={formError.email}
							sx={{ width: 1, mb: { xs: 2, md: 2 } }}
							onKeyUp={(e) => onEnterSendOtp(e)}
						/>

						<TextField
							type="password"
							label="Password"
							value={formData.password}
							onChange={(e) => onChange("password", e.target.value)}
							helperText={formError.password}
							error={formError.password}
							sx={{ width: 1, mb: { xs: 1, md: 2 } }}
						/>
						<Stack alignItems="flex-end" sx={{ width: "100%", mb: 2 }}>
							<Link underline="none" variant="small" href={forgotPwdUrl}>
								Forgot Password?
							</Link>
						</Stack>

						<LoginTerms />

						<JarvisButton
							sx={{ mb: 3, width: "100%" }}
							onClick={onSubmit}
							loading={isLoading}
						>
							Login
						</JarvisButton>

						{config.platform !== "IOS" && (
							<>
								<Box sx={{ width: "100%", mb: 3 }}>
									<Divider>
										<Text variant="body2">OR</Text>
									</Divider>
								</Box>
								<SocialLogin />
							</>
						)}
					</>
				) : (
					<>
						{!isLoginRequestOtp ? (
							<>
								<TextField
									type="text"
									label="Email / Phone"
									value={formData.userName}
									onChange={(e) => onChange("userName", e.target.value)}
									helperText={formError.userName}
									error={formError.userName}
									sx={{ width: 1, mb: { xs: 2, md: 2 } }}
									onKeyUp={(e) => onEnterSendOtp(e)}
								/>

								<LoginTerms />
								<JarvisButton
									sx={{ mb: 3, width: "100%" }}
									onClick={() => loginSendOtp({ isFirstRequest: true })}
									loading={isLoading}
								>
									Send OTP
								</JarvisButton>

								{config.platform !== "IOS" && (
									<>
										<Box sx={{ width: "100%", mb: 3 }}>
											<Divider>
												<Text variant="body2">OR</Text>
											</Divider>
										</Box>
										<SocialLogin />
									</>
								)}
							</>
						) : (
							<Stack sx={{ width: 1, mb: 2 }}>
								<Stack direction="row" justifyContent="space-between">
									<Text
										variant="caption1"
										sx={{
											wordBreak: "break-word",
											marginRight: "10px",
										}}>
										Enter OTP sent on {formData.userName}
									</Text>
									<Button
										component="span"
										className={classes.otpResend}
										sx={{
											fontSize: "15px"
										}}
										onClick={onBackToStep1}
										disabled={
											loginOtpResendTime > 0 ? true : false
										}
										loading={isLoading}>
										Edit
									</Button>
								</Stack>

								<OtpInput
									className={classes.otpContainer}
									inputStyle={classes.otpInput}
									value={formData.loginOtp}
									numInputs={6}
									isInputNum={true}
									onChange={(e) => onChange("loginOtp", e)}
									separator={<span>&nbsp;</span>}
								/>

								{/* Resent Login OTP */}
								<Stack
									variant="caption1"
									className={classes.resendText}
									sx={{
										whiteSpace: "nowrap",
										alignSelf: "flex-end",
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										flexFlow: "row wrap",
										fontSize: "15px"
									}}>
									<Button
										component="span"
										className={classes.otpResend}
										sx={{
											fontSize: "15px"
										}}
										onClick={() => loginSendOtp()}
										disabled={
											loginOtpResendTime > 0 ? true : false
										}
										loading={isLoading}>
										Resend {loginOtpResendTime <= 0 && 'OTP'}
									</Button>
									{loginOtpResendTime > 0 ? (
										<Text
											variant="caption1"
											sx={{
												wordBreak: "break-word",
											}}>
											in 00:{loginOtpResendTime < 10 ? `0${loginOtpResendTime}` : loginOtpResendTime}
										</Text>
									) : null}
								</Stack>

								<Stack
									direction="row"
									justifyContent={{ xs: "center", md: "flex-start" }}
									sx={{ width: "100%", mt: { xs: 2, md: 0 } }}>
									<JarvisButton
										sx={{ mb: 3, width: "100%" }}
										onClick={onConfirmOTP}
										loading={isLoading}
										disabled={formData.loginOtp.length !== 6}>
										Confirm OTP
									</JarvisButton>
								</Stack>
							</Stack>
						)}
					</>
				)}

				{!isLoginRequestOtp && (
					<Stack m="10px auto 0">
						{/* Signup Link */}
						<Box
							sx={{
								display: { xs: "block", md: "none", width: "100%" },
							}}>
							<Text
								variant="small"
								color="#222B3E"
								sx={{ textAlign: "center", py: 1 }}>
								New Here?{"  "}
								<Link href="/signup">Create Your Account</Link>
							</Text>
						</Box>

						{/* SEBI */}
						<Stack
							diretion="row"
							justifyContent="center"
							mt={4}
							sx={{
								width: "100%",
								display: { xs: "block", md: "none" },
							}}>
							<Text textAlign="center" mb={2} color="#88929B">
								SEBI Registered Investment Advisor
							</Text>
							<Image
								src="/images/sebi_mobile.png"
								height={70}
							/>
						</Stack>
					</Stack>
				)}

			</Stack>
			<Box sx={{ flex: 1, display: { xs: "none", md: "block" } }} />
		</Stack>
	);
};

export default LoginForm;
