
const jarvisOnestockTransactionConstants = {

    // Transaction List

    ONESTOCK_TRANSACTION_RESPONSE: "ONESTOCK_TRANSACTION_RESPONSE",
    ONESTOCK_TRANSACTION_REQUEST: "ONESTOCK_TRANSACTION_REQUEST",
    ONESTOCK_TRANSACTION_SAGA: "ONESTOCK_TRANSACTION_SAGA",
    ONESTOCK_TRANSACTION_API: "/onestock/transaction",
    ONESTOCK_UPDATE_TRANSACTION_API: "/onestock/transaction/update"

};

export { jarvisOnestockTransactionConstants };
