import React from "react";
import { withRouter } from "react-router";

import Box from "components/common/Box";

import PreviewInvestmentDetails from "components/Onboarding/PortfolioCreation/PreviewInvestmentDetails";
import EditInvestmentDetails from "components/Onboarding/PortfolioCreation/EditInvestmentDetails";
import ViewPortfolio from "components/Onboarding/PortfolioCreation/ViewPortfolio";
import PortfolioPayment from "components/Onboarding/PortfolioCreation/PortfolioPayment";
import BackTesting from "components/Onboarding/PortfolioCreation/BackTesting";
import SubscriptionPlans from "components/JarvisSubscriptionPlans";
import PlanPayment from "containers/PlanPayment";

import { validationHelper } from "helper/validation";
import { onboardingApis } from "stores/onboarding/onboardingApis";
import { onboardingParsers } from "stores/onboarding/onboardingParsers";

import { tracker } from "library/tracker";
import { config } from "config";
import { localStore } from "stores/localStorage";

const screenTypes = {
    PREVIEW_INVESTMENT_DETAILS: "PREVIEW_INVESTMENT_DETAILS",
    EDIT_INVESTMENT_DETAILS: "EDIT_INVESTMENT_DETAILS",
    VIEW_PORTFOLIO: "VIEW_PORTFOLIO",
    BACK_TESTING: "BACK_TESTING",
    SUBSCRIPTION_PLAN_CONFIRMATION: "SUBSCRIPTION_PLAN_CONFIRMATION",
    SUBSCRIPTION_PLANS: "SUBSCRIPTION_PLANS",
    SUBSCRIPTION_PLAN_PAYMENT: "SUBSCRIPTION_PLAN_PAYMENT",
};
const couponViewTypes = {
    DEFAULT: "DEFAULT",
    EDIT: "EDIT",
    APPLIED: "APPLIED",
};
const planFrequencyMessage = {
    MONTHLY: "Your Jarvis portfolio subscription auto-renews every month. <br/> You can cancel subscription any time.",
    HALF_YEARLY: "Your Jarvis portfolio subscription auto-renews every six months. <br/> You can cancel subscription any time.",
    YEARLY: "Your Jarvis portfolio subscription auto-renews every year. <br/> You can cancel subscription any time.",
    QUARTERLY: "Your Jarvis portfolio subscription auto-renews every quarter. <br/> You can cancel subscription any time."
}

class PortfolioCreationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreenType: "",
            isPaymentLoading: false,
            couponActiveViewType: couponViewTypes.DEFAULT,
            confirmDialogOpen: false,
            couponFormData: {
                coupon: "",
            },
            couponFormError: {
                coupon: "",
            },
            selectedFrequency: null,
            selectedPlanData: null,
            // 
            formData: {
                investmentAmount: null,
                investmentTenure: null,
                investmentStrategy: null,
                planId: null,
                riskProfileId: null,
            },
            formError: {
                investmentAmount: "",
                investmentTenure: "",
                investmentStrategy: "",
                planId: "",
                riskProfileId: "",
            },
            isLoading: false,

            // years
            displayYearSelect: false,
            displayAmountInput: false,
        };
    }


    componentDidMount() {
        this.props.getSubscriptionPlansAction({ productCode: config.productCodes.PORTFOLIO });
        this.fillInvestmentForm();
        const {investmentInfo} = this.props
        const {formData} = this.state
        if(!formData.investmentStrategy){
            formData.investmentStrategy = investmentInfo.investmentStrategy;
            this.setState({ formData })
        }
        // this.fetchCustomerProfileData()
        if (!this.props.hasOnboardingInvestmentDetailsConfirmed) {
            this.setState({ activeScreenType: screenTypes.PREVIEW_INVESTMENT_DETAILS })
            if (!this.props.riskProfiles.length) {
                this.props.getRiskProfilesAction();
            }
        } else {
            this.setState({ activeScreenType: screenTypes.VIEW_PORTFOLIO })
            if (!this.props.portfolioData.length || this.props.isPaymentCompleted) {
                this.props.getPortfolioDataAction();
                this.props.getSubscriptionPlansAction({ productCode: config.productCodes.PORTFOLIO });
            }
        }
    }
    fetchCustomerProfileData = async ()=>{
        await this.props.getProfileDataAction();
        return;
    }

    componentDidUpdate() {
        if (
            this.props.portfolioData.length &&
            !this.props.isPaymentCompleted &&
            !this.props.subscriptionPlans.length &&
            !this.props.isSubscriptionPlansLoading &&
            !this.props.isSubscriptionPlansCalled
        ) {
            //event-tracker: Inital -> Portfolio generated.
            tracker.Onboarding.portfolioGenerated();
            this.props.getSubscriptionPlansAction({ productCode: config.productCodes.PORTFOLIO });
        }

        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();
        if (
            subscriptionFrequencies.length &&
            !this.state.selectedFrequency
        ) {
            this.handleFrequencyChange({ selectedFrequency: subscriptionFrequencies[0] })
        }
    }

    // reset investment form data
    handleInvestmentFormReset = () => {
        this.setState({
            displayAmountInput: false,
            displayYearSelect: false,
            formData: {
                investmentAmount: null,
                investmentTenure: null,
                investmentStrategy: null, 
                planId: null,
                riskProfileId: null,
            },
            formError: {
                investmentAmount: "",
                investmentTenure: "",
                investmentStrategy: "",
                planId: "",
                riskProfileId: "",
            },
        })
    }

    // to prefill investment form for edit
    fillInvestmentForm = () => {
        const { investmentInfo, investmentPlanList, tenurePreferences } = this.props;
        // amount
        if (investmentPlanList.filter(v => v !== "other").includes(investmentInfo.investmentAmount)) {
            this.handleInvestmentChange({ name: "investmentAmount", value: investmentInfo.investmentAmount, valueType: "chip" })
        } else {
            this.handleInvestmentChange({ name: "investmentAmount", value: investmentInfo.investmentAmount, valueType: "" })
        }

        // tenure
        if (tenurePreferences.filter(v => v.value !== "other").map(v => v.value).includes(investmentInfo.investmentTenure)) {
            this.handleInvestmentChange({ name: "investmentTenure", value: investmentInfo.investmentTenure, valueType: "chip" })
        } else {
            this.handleInvestmentChange({ name: "investmentTenure", value: investmentInfo.investmentTenure, valueType: "" })
        }

        // risk profile
        if (investmentInfo.riskProfile) {
            this.handleInvestmentChange({ name: "riskProfileId", value: investmentInfo.riskProfile })
        }

        // strategy
        if (investmentInfo.investmentStrategy == 0 || investmentInfo.investmentStrategy) {
            this.handleInvestmentChange({ name: "investmentStrategy", value: investmentInfo.investmentStrategy })
        }

        // Plan id
        if (investmentInfo.planId) {
            this.handleInvestmentChange({ name: "planId", value: investmentInfo.planId })
        }

    }

    // handle edit action of investment
    handleInvestmentEdit = () => {
        this.fillInvestmentForm();
        this.setState({
            activeScreenType: screenTypes.EDIT_INVESTMENT_DETAILS,
        })
    }

    // handle change event of each investment details field
    handleInvestmentChange = ({ name, value, valueType = "" }) => {
        let {
            formData,
        } = this.state

        let optionTenureYear = []
        optionTenureYear = this.props.tenurePreferencesList.map(v => v.year)

        // toggle year dropDown.
        if (name === "investmentTenure" && (value === "other" || optionTenureYear.includes(value))) {
            value = isNaN(value) ? 4 : value;
            this.setState({ displayYearSelect: true })
        } else if (name === "investmentTenure" && value !== "other" && valueType === "chip") {
            this.setState({ displayYearSelect: false })
        }

        // toggle Amount input.
        if (name === "investmentAmount" && (value === "other" || !this.props.investmentPlanList.includes(value))) {
            value = isNaN(value) ? 30000 : value;
            this.setState({ displayAmountInput: true })
        } else if (name === "investmentAmount" && value !== "other" && valueType === "chip") {
            this.setState({ displayAmountInput: false })
        }

        // save as intiger value.
        const checkNumberType = ["investmentTenure", "investmentAmount"].includes(name);
        if (checkNumberType && isNaN(value)) {
            return;
        } else if (checkNumberType) {
            value = Number(value);
        }

        formData[name] = value;
        this.setState({ formData });
    }

    // handle cancel event of investment details
    handleInvestmentCancel = () => {
        this.handleInvestmentFormReset();
        this.setState({
            activeScreenType: screenTypes.PREVIEW_INVESTMENT_DETAILS,
        });
    }


    handleInvestmentValidation = () => {
        const { formError, formData } = this.state;

        const investmentAmountValidation = validationHelper.minInvestment(
            formData.investmentAmount,
            this.props.minInvestmentAmount
        );
        formError.investmentAmount = investmentAmountValidation.message;

        const investmentTenureValidation = validationHelper.required(
            formData.investmentTenure
        );
        formError.investmentTenure = investmentTenureValidation.isValid
            ? ""
            : "Please Select Your Tenure";

        const investmentStrategyValidation = validationHelper.required(
            formData.investmentStrategy
        );
        formError.investmentStrategy = investmentStrategyValidation.isValid
            ? ""
            : "Please Select Your Investment Strategy";

        const planIdValidation = validationHelper.required(
            formData.planId
        );
        formError.planId = planIdValidation.isValid
            ? ""
            : "Please Select Your Investment Plan";

        this.setState({ formError });
        return (
            investmentAmountValidation.isValid &&
            investmentTenureValidation.isValid &&
            investmentStrategyValidation.isValid &&
            planIdValidation.isValid
        );
    };

    // handle confirm action of investment details
    handleInvestmentConfirm = async () => {

        if (!this.handleInvestmentValidation()) {
            return;
        }
        this.setState({ isLoading: true });

        try {
            const { formData } = this.state;
            await onboardingApis.confirmInvestmentInfo({
                riskProfileId: formData.riskProfileId,
                investmentStrategy: formData.investmentStrategy,
                investmentHorizon: formData.investmentTenure,
                investmentAmount: formData.investmentAmount,
                planId: formData.planId,
            });

            this.props.updateOnboardingDataAction({
                currentStage: this.props.isPaymentCompleted ? 3 : this.props.currentStage,
                investmentAmount: formData.investmentAmount,
                investmentTenure: formData.investmentTenure,
                investmentStrategy: formData.investmentStrategy,
                riskMasterId: formData.riskProfileId,
                planId: formData.planId,
                hasOnboardingInvestmentDetailsConfirmed: 1,
            })

            this.handleInvestmentFormReset();
            this.props.getPortfolioDataAction();
            this.setState({ activeScreenType: screenTypes.VIEW_PORTFOLIO });
        } catch (error) {
            console.error('confirm investment details failed ', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleUnlockPortfolioClick = () => {
        //event-tracker: Initial -> Unlock full portfolio
        tracker.Onboarding.unlockFullPortfolio();
        this.setState({ activeScreenType: screenTypes.SUBSCRIPTION_PLAN_PAYMENT });
        if (!this.props.subscriptionPlans.length) {
            this.props.getSubscriptionPlansAction({ productCode: config.productCodes.PORTFOLIO });
        }
    }

    handleViewFullPortfolioClick = () => {
        this.props.updateOnboardingDataAction({
            isPaymentCompleted: 1,
        });
        this.props.getPortfolioDataAction();
        this.setState({ activeScreenType: screenTypes.VIEW_PORTFOLIO });
    };

    handleStartInvestingClick = () => {
        const { currentStage, history } = this.props;
        let routeName = "";
        //event-tacker: initial -> start investing after payment.
        tracker.initial.startInvestingAfterPayment();
        if ([3, 4].includes(currentStage)) {
            routeName = "/jarvis-portfolio/onboarding/ckyc";
        }
        if (currentStage === 5) {
            routeName = "/jarvis-portfolio/onboarding/choose-broker";
        }
        history.replace(routeName);
    };

    handleBackTestingClick = () => {
        this.setState({ activeScreenType: screenTypes.BACK_TESTING });
        // TODO: make call to todo apis
    };

    handleNavigateToViewPortfolio = () => {
        //event-tracker: Initial -> Portfolio viewed.
        tracker.initial.portfolioViewed();
        this.setState({ activeScreenType: screenTypes.VIEW_PORTFOLIO });
    };

    handlePlanSelection = (planData) => {
        this.setState({ selectedPlanData: planData, confirmDialogOpen: true });
    };

    handlePaymentClick = (paymentData) => {
        localStore.resetPaymentInitiated();
        // let pathname = "/customer/payment"
        // if(this.props.customerProfile.PORTFOLIO.paymentMethod === "ONETIME"){
        //     pathname = '/customer/onetime-payment'
        // }
        this.props.history.push({
            pathname:'/customer/onetime-payment',
            state: {
                returnURI: "/jarvis-portfolio/onboarding/portfolio-creation",
                productCode: config.productCodes.PORTFOLIO,
                planId: paymentData.planId,
                offerCode: paymentData.offerCode,
                paymentType: "INITIAL",
                paymentMethod: paymentData.paymentMethod,
                paymentMethodOption: paymentData.paymentMethodOption,
                paymentDetails: {
                    authType: paymentData.authType,
                    accountNumber: paymentData.accountNumber,
                    accountType: paymentData.accountType,
                    accountHolderName: paymentData.accountHolderName,
                    ifscCode: paymentData.ifscCode,
                }
            }
        });


        // event-tracker: Initial -> proceed to pay.
        tracker.initial.iProceedToPay();
        // NOTE: Legacy logic
        // this.setState({ selectedPlanData: null, confirmDialogOpen: false });
        // this.props.history.push({
        //     pathname: "/customer/subscription",
        //     state: {
        //         returnURI: "/onboarding/portfolio-creation",
        //         productCode: config.productCodes.PORTFOLIO,
        //         planId: this.state.selectedPlanData.planId,
        //     },
        // });
    }

    handleFrequencyChange = ({ selectedFrequency }) => {
        this.setState({ selectedFrequency });
    }

    handleCouponChange = ({ name, value }) => {
        const { couponFormData } = this.state;
        couponFormData[name] = value;
        this.setState({ couponFormData });
    };

    handleCouponFormValidation = () => {
        const { couponFormData, couponFormError } = this.state;

        const couponValidation = validationHelper.required(couponFormData.coupon);
        couponFormError.coupon = couponValidation.message;

        this.setState({ couponFormError });
        return couponValidation.isValid;
    };

    handleCouponSubmit = async () => {
        // NOTE: event_tracker => coupan 
        tracker.coupon.apply();
        if (!this.handleCouponFormValidation()) {
            return;
        }

        try {
            const { couponFormData } = this.state;
            const { getPricingDataResponseAction } = this.props;

            const response = await onboardingApis.getPricingData({ couponCode: couponFormData.coupon });
            const parsedData = onboardingParsers.pricingData(response);
            getPricingDataResponseAction(parsedData);
            this.setState({ couponActiveViewType: couponViewTypes.APPLIED });
            // NOTE: event_tracker => coupan 
            tracker.coupon.applySuccess();
        } catch (error) {
            // NOTE: event_tracker => coupan 
            tracker.coupon.applyFailed({ error_msg: error.messgae });
            console.log(`Error fetching pricing data`, error);
        }
    };

    handleCouponAction = (action, payload) => {
        switch (action) {
            case "COUPON_ADD":
                // NOTE: event_tracker => coupan 
                tracker.coupon.apply();
                this.setState({
                    couponActiveViewType: couponViewTypes.EDIT,
                    couponFormData: { coupon: "" },
                    couponFormError: { coupon: "" },
                });
                break;

            case "COUPON_CLEAR":
                this.props.getPricingDataAction();
                this.setState({
                    couponActiveViewType: couponViewTypes.DEFAULT,
                    couponFormData: { coupon: "" },
                    couponFormError: { coupon: "" },
                });
                break;

            case "COUPON_SUBMIT":
                // NOTE: event_tracker => coupan 
                this.handleCouponSubmit();
                break;

            case "COUPON_CHANGE":
                this.handleCouponChange(payload);
                break;

            default:
                console.log(`${action} is not defined`);
                break;
        }
    };

    handleActiveScreenTypeChange = ({ screenType }) => {
        this.setState({ activeScreenType: screenType });
    }

    getSubscriptionPlanFrequencies = () => {
        const planFrequency = [];
        if (this.props.subscriptionPlans.length) {
            this.props.subscriptionPlans.forEach(plan => {
                if (!planFrequency.includes(plan.planFrequency)) {
                    planFrequency.push(plan.planFrequency);
                }
            })
        }
        planFrequency.reverse();
        return planFrequency;
    }

    componentWillUnmount() {
        this.setState({
            couponActiveViewType: couponViewTypes.DEFAULT,
            couponFormData: { coupon: "" },
            couponFormError: { coupon: "" },
            selectedFrequency: null,
        });
    }
    handleBackClick = () => {
        this.props.history.replace('/jarvis-portfolio/onboarding/overview')
    }
    render() {
        const {
            investmentInfo,
            riskProfiles,
            currentStage,
            investmentStrategies,
            portfolioData,
            marketBreakupData,
            sectorBreakupData,
            isPortfolioDataLoading,
            isPaymentCompleted,
            subscriptionPlans,
            isSubscriptionPlansLoading,
            minInvestmentAmount,
            tenurePreferencesList,
            tenurePreferences,
            lockAmountInput,
            investmentPlanList,
        } = this.props;
        const {
            activeScreenType,
            isPaymentLoading,
            confirmDialogOpen,
            couponActiveViewType,
            couponFormData,
            couponFormError,
            selectedFrequency,
            selectedPlanData,
            formData,
            formError,
            displayYearSelect,
            displayAmountInput,
        } = this.state;

        const selectedInvestmentStrategy = investmentStrategies.find(
            (i) => i.id === investmentInfo.investmentStrategy
        );
        const selectedRiskProfile = riskProfiles.find(
            (r) => r.id === investmentInfo.riskProfile
        );
        const selectedPlan = subscriptionPlans.find(
            (s) => s.planId === investmentInfo.planId
        )

        const subscriptionFrequencies = this.getSubscriptionPlanFrequencies();

        if (activeScreenType === screenTypes.PREVIEW_INVESTMENT_DETAILS) {
            return (
                <>

                    <PreviewInvestmentDetails
                        investmentInfo={investmentInfo}
                        selectedRiskProfile={selectedRiskProfile}
                        selectedInvestmentStrategy={selectedInvestmentStrategy}
                        selectedPlan={selectedPlan}

                        onEdit={this.handleInvestmentEdit}
                        onConfirm={this.handleInvestmentConfirm}
                        onBackClick={this.handleBackClick}
                    />
                </>
            )
        }

        if (activeScreenType === screenTypes.EDIT_INVESTMENT_DETAILS) {
            return (
                <EditInvestmentDetails
                    minInvestmentAmount={minInvestmentAmount}
                    displayYearSelect={displayYearSelect}
                    displayAmountInput={displayAmountInput}
                    lockAmountInput={lockAmountInput}
                    formData={formData}
                    formError={formError}

                    investmentAmountList={investmentPlanList}
                    tenurePreferences={tenurePreferences}
                    tenurePreferencesList={tenurePreferencesList}
                    riskProfiles={riskProfiles}
                    investmentStrategies={investmentStrategies}
                    subscriptionPlans={subscriptionPlans}

                    onSave={this.handleInvestmentConfirm}
                    onCancel={this.handleInvestmentCancel}
                    onChange={this.handleInvestmentChange}
                />
            )
        }

        if (activeScreenType === screenTypes.SUBSCRIPTION_PLAN_PAYMENT) {
            return (
                <PlanPayment
                    navigatePreviousScreen={() => this.handleActiveScreenTypeChange({ screenType: screenTypes.VIEW_PORTFOLIO })}
                    onPayNowClick={this.handlePaymentClick}
                    paymentType="INITIAL"
                />
            )
        }


        if (activeScreenType === screenTypes.SUBSCRIPTION_PLANS) {
            return (
                <SubscriptionPlans
                    onBackClick={this.handleNavigateToViewPortfolio}
                    subscriptionPlans={subscriptionPlans}
                    subscriptionFrequencies={subscriptionFrequencies}
                    onFrequencyChange={this.handleFrequencyChange}
                    onPlanSelect={this.handlePlanSelection}
                    selectedFrequency={selectedFrequency}
                    currentInvestmentAmount={investmentInfo.investmentAmount}
                    selectedPlanData={selectedPlanData}
                    confirmDialog={{
                        open: confirmDialogOpen,
                        title: `Selected Plan: <span style="white-space: nowrap">${selectedPlanData?.planName}</span>`,
                        content: planFrequencyMessage[selectedFrequency],
                        onConfirm: () => this.handlePaymentClick(),
                        onCancel: () => this.setState({ confirmDialogOpen: false, selectedPlanData: null }),
                    }}
                />
            )
        }

        if (activeScreenType === screenTypes.SUBSCRIPTION_PLAN_CONFIRMATION) {
            return (
                <PortfolioPayment
                    onBackClick={this.handleNavigateToViewPortfolio}
                    onPaymentClick={() => {
                        couponActiveViewType === "EDIT" ? this.setState({ confirmDialogOpen: true }) : this.handlePaymentClick()
                    }}
                    pricingData={{}}
                    isPaymentLoading={isPaymentLoading}
                    isPaymentCompleted={isPaymentCompleted}
                    confirmDialog={{
                        open: confirmDialogOpen,
                        message: "Would you like to continue without a coupon discount?",
                        onConfirm: () => this.handlePaymentClick(),
                        onClose: () => this.setState({ confirmDialogOpen: false }),
                    }}
                    couponCodeProps={{
                        onActionTrigger: this.handleCouponAction,
                        viewTypes: couponViewTypes,
                        activeViewType: couponActiveViewType,
                        formData: couponFormData,
                        formError: couponFormError,
                    }}
                />
            );
        }

        if (activeScreenType === screenTypes.BACK_TESTING) {
            return (
                <BackTesting
                    onBackClick={this.handleNavigateToViewPortfolio}
                    investmentInfo={investmentInfo}
                    riskProfile={selectedRiskProfile}
                    investmentStrategy={selectedInvestmentStrategy}
                />
            );
        }

        return (
            <>
                {isPortfolioDataLoading || isSubscriptionPlansLoading ? (
                    <Box sx={{ minHeight: "80vh", display: "flex", alignItems: "center", justifyContent: "center", }}>
                        <img
                            src="/images/portfolio-loader-video.gif"
                            alt="loader"
                            style={{ maxWidth: "320px", borderRadius: 3 }}
                        />
                    </Box>
                ) : (
                    <ViewPortfolio
                        onStartInvestingClick={this.handleStartInvestingClick}
                        onUnlockPortfolioClick={this.handleUnlockPortfolioClick}
                        onBackTestingClick={this.handleBackTestingClick}
                        investmentInfo={investmentInfo}
                        selectedPlan={selectedPlan}
                        portfolioData={portfolioData}
                        marketBreakupData={marketBreakupData}
                        sectorBreakupData={sectorBreakupData}
                        isPaymentCompleted={this.props.isPaymentCompleted}
                        currentStage={currentStage}
                        onBackClick={this.handleBackClick}
                    />
                )}
            </>
        );
    }
}

export default withRouter(PortfolioCreationContainer);
