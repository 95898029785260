import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onboardingActions } from "stores/onboarding/onboardingActions";
import { commonActions } from "stores/common/commonActions";

const mapStateToProps = (state) => {
    const lockAmountInput = state.onboarding.isPaymentCompleted ? true : false;

    return {
        lockAmountInput: false,
        investmentAmount: state.onboarding.investmentAmount,
        investmentTenure: state.onboarding.investmentTenure,
        investmentStrategy: state.onboarding.investmentStrategy,
        brokersList: state.common.brokers,

        loginProfile: state.customer.loginProfile,
        minInvestmentAmount: state.onboarding.minInvestmentAmount,
        currentStage: state.onboarding.currentStage,
        centrumReferalURL: state.common.featureConfig.centrumReferalURL,

        // Tenure & strategy
        tenurePreferences: [
            { id: 2, label: "2 Yr", value: 2 },
            { id: 3, label: "3 Yr", value: 3 },
            { id: 5, label: "5 Yr", value: 5 },
            { id: 7, label: "7 Yr", value: 7 },
            { id: 11, label: "Other", value: "other" },
        ],
        tenurePreferencesList: [
            { year: 1, display: "1 Year" },
            { year: 4, display: "4 Year" },
            { year: 6, display: "6 Year" },
            { year: 8, display: "8 Year" },
            { year: 9, display: "9 Year" },
            { year: 10, display: "10 Year" },
        ],
        investmentStrategies: state.common.investmentStrategies,

        // Amont & plan
        investmentPlanList: [
            30000,
            100000,
            300000,
            5000000,
            "other"
        ]


    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateOnboardingDataAction: onboardingActions.updateOnboardingData,
            getBrokersAction: commonActions.getBrokers,
        },
        dispatch
    );

const InvestmentPreferenceStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default InvestmentPreferenceStore;
