import React from "react";
import _get from "lodash.get";

import Box from "components/common/Box";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import PageTitle from 'components/PageTitle';
import JarvisRMSFilter from "components/jarvisRMS/RMSTransactionListFilter";
import SubscriptionDetailsCard from "components/Profile/SubscriptionDetailsCard";
import Table from "components/common/Table";
import JarvisCard from "components/JarvisCard";
import { dateTimeHelper } from "helper/dateTime";
import { localStore } from "stores/localStorage";

import { config } from "config";
import { withRouter } from "react-router-dom";
import MobileTransactionCards from "components/Profile/MobileTransactionCards";
import { formatHelper } from "helper/format";
import JarvisButton from "components/JarvisButton";
import { FilterList as FilterListIcon } from "@mui/icons-material"
import Pagination from "components/common/Pagination";
import Button from "components/common/Button";

import PlanAndPricingContainer from "containers/PlanAndPricing";

const screenTypes = {
  VIEW_PROFILE: "VIEW_PROFILE",
  SUBSCRIPTION_PLANS: "SUBSCRIPTION_PLANS",
};

const columns = [
  {
    field: "createdAt",
    headerText: "Transaction Date",
    bodyCellComponent: (v) => <Text variant="small">{
      v ?
        dateTimeHelper.format(v, '$DD/$MM/$YYYY')
        :
        "NA"
    }
    </Text>
  },
  {
    field: "planName",
    headerText: "Transaction Details",
    bodyCellComponent: (v, row) => {
      return <Stack display="flex" flexDirection="column">
        <Text variant="small" color="#202020" sx={{ fontWeight: 400 }}>{v}</Text>
        <Text variant="small" color="#A2A7AE" sx={{ fontWeight: 400 }}>{row.planStatus}</Text>
      </Stack>
    },
  },
  {
    field: "paymentStatus",
    headerText: "Status",
    bodyCellComponent: (v) => (
      v === "COMPLETED" ?
        <Text variant="small" sx={{ color: "#45AC70" }}>{v}</Text>
        : v === "FAILED" ?
          <Text variant="small" sx={{ color: "#D44C4C" }}>{v}</Text>
        : v === "REFUNDED" ?
          <Text variant="small" sx={{ color: "#212121" }}>{v}</Text>
        : v === "PARTIAL" ?
          <Text variant="small" sx={{ color: "#ED6C02" }}>PARTIAL REFUND</Text>
        : "NA"
    )
  },
  {
    field: "paymentAmount",
    headerText: "Subscription Fee",
    bodyCellComponent: (v) => (
      v ?
        formatHelper.currency(v)
        :
        formatHelper.currency(0)
    )
  }
]

const initialFilterData = {
  fromDate: null,
  toDate: null,
  transactionStatus: null,
}

class SubscriptionDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilter: false,
      filterData: JSON.parse(JSON.stringify(initialFilterData)),
      tempFilterData: JSON.parse(JSON.stringify(initialFilterData)),
      page: 1,
      limit: 25,
      tableRefreshCounter: 0,
      // reasonsDropdown: [],

      activeScreenType: screenTypes.VIEW_PROFILE,
      selectedFrequency: null,
      selectedPlanData: null,
      confirmDialogOpen: false,
      isPlanUpgraded: false,
    }

  }

  componentDidMount() {
    this.props.reasonsDropdownAction()
    // this.getReasonListForCancel();
    let productCodes = _get(this.props, "history.location.state.productCode", "");
    this.props.getProductsSubscriptionDetailsAction({ productCode: productCodes })
    this.props.getSubscriptionPlansAction({ productCode: productCodes });
    this.fetchTransactionList()
  }

  fetchTransactionList = () => {
    const { page, limit, filterData: { fromDate, toDate, transactionStatus } } = this.state
    let productCodes = _get(this.props, "history.location.state.productCode", "");
    this.props.transactionHistoryAction({ productCode: productCodes, page, limit, transactionStatus, fromDate, toDate, })
    setTimeout(() => {
      this.setState(prevState => ({
        tableRefreshCounter: prevState.tableRefreshCounter + 1
      }))
    }, 500)
  }

  // getReasonListForCancel = async () => {
  //   let res = await customerApis.getReasonDropdownForCancellationApi();
  //   this.setState({ reasonsDropdown: res })
  // }

  handleFilterDataChange = ({ name = null, value = "" }) => {
    const { tempFilterData } = this.state;

    if (name === "transactionStatus") {
      if (tempFilterData[name] === value) {
        tempFilterData[name] = null
      } else {
        tempFilterData[name] = value
      }
    } else {
      tempFilterData[name] = value;
    }

    this.setState({ tempFilterData });
  }

  handleFilterOpen = () => {
    this.setState({ openFilter: true });
  }

  handleFilterClose = () => {
    this.setState({ openFilter: false, tempFilterData: this.state.filterData })
  }

  handleFilterReset = () => {
    this.setState({
      openFilter: false,
      filterData: JSON.parse(JSON.stringify(initialFilterData)),
      tempFilterData: JSON.parse(JSON.stringify(initialFilterData))
    }, () => {
      this.fetchTransactionList();
    });
  }

  handleFilterSubmit = () => {
    this.setState({
      openFilter: false,
      filterData: this.state.tempFilterData,
    }, () => {
      this.fetchTransactionList();
    });
  }

  handlePageChange = (page, limit) => {
    this.setState({ page, limit }, () => {
      this.fetchTransactionList()
    })
  }

  isFilterApplied = () => {
    const { filterData } = this.state;
    return filterData.fromDate || filterData.toDate || filterData.executionStatus
  }

  onPlanUpgrade = (data) => {
    // if (data === config.productCodes.PORTFOLIO) {
    //   this.onPlanUpgradePortfolio();
    //   return;
    // }
    // if(data === config.productCodes.ONESTOCK){
    //   this.onPlanUpgradeOnestock();
    //   return;
    // }
    // let message = "If you wish to add more stocks and portfolios we recommend upgrading your current plan. Kindly visit customer support.";
    // this.props.setDialog({
    //   open: true,
    //   onCloseTakeToIntercom: true,
    //   message,
    //   heading: <Text fontSize="20px">Upgrade your plan to access higher stock and portfolio limits.</Text>
    // });
    let productCodes = _get(this.props.location, "state.productCode", "");
    if (
      !Object.keys(this.props.renewalPricingDetails).length
      && productCodes === config.productCodes.PORTFOLIO
    ) {
      this.props.getRenewSubscriptionAction();
    }
    this.setState({ activeScreenType: screenTypes.SUBSCRIPTION_PLANS, isPlanUpgraded:true });
  }

  onPlanUpgradePortfolio = () => {
    let message = "Upgrade your plan to access higher AUM limits.If you wish to invest more we recommend upgrading your current plan. Kindly visit customer support.";
    this.props.setDialog({
      open: true,
      onCloseTakeToIntercom: true,
      message,
      heading: <Text fontSize="20px">Upgrade your plan to access higher AUM limits.</Text>
    });
  }

  onPlanUpgradeOnestock = () => {
    let message = "Upgrade your plan to a half-yearly plan and save one month's subscription fee.Kindly visit customer support.";
    this.props.setDialog({
      open: true,
      onCloseTakeToIntercom: true,
      message,
      heading: <Text fontSize="20px">Upgrade your plan to a half-yearly plan and save one month's subscription fee.</Text>
    });
  }

  handlePlanRenewal = () => {
    let productCodes = _get(this.props.location, "state.productCode", "");
    if (
      !Object.keys(this.props.renewalPricingDetails).length
      && productCodes === config.productCodes.PORTFOLIO
    ) {
      this.props.getRenewSubscriptionAction();
    }
    this.setState({ activeScreenType: screenTypes.SUBSCRIPTION_PLANS });
  }

  handleScreenChange = (requiredScreen) => {
    if (requiredScreen === "GO_BACK") {
      this.setState({ activeScreenType: screenTypes.VIEW_PROFILE })
    }
  };

  render() {
    const isMobileView = localStore.getMobileView();
    const { openFilter, tempFilterData, page, limit } = this.state;

    let productCodes = _get(this.props.location, "state.productCode", "");
    const { profile } = this.props

    let customerSubscriptionId = _get(profile, `${productCodes}.customerSubscriptionId`, '');
    // const productExpiryData = _get(profile, `${productCodes}`, {});

    const {
      transactionStatusFilter: transactionStatusData,
      reasonsDropdown,
      transactionHistory,
      productSubscriptionDetails,
      transactionHistoryLoading,
      transactionHistoryCount,
    } = this.props;
    let subscriptionDetails = productSubscriptionDetails.find(p => p.productCode === productCodes);
    let planDetails = this.props.subscriptionPlans?.find(p=> p?.planId ===subscriptionDetails?.planId )
    if (!productSubscriptionDetails.length && !this.props.subscriptionPlans.length) {
      return null
    }

    if (this.state.activeScreenType === screenTypes.SUBSCRIPTION_PLANS) {
      return (
        <JarvisCard sx={{ padding: "20px 0px", background: "unset" }}>
          <PlanAndPricingContainer
            productCode={productCodes}
            currentPortfolioCount={subscriptionDetails.portfolioLimitUsage}
            currentStockCount={subscriptionDetails.stockLimitUsage}
            investmentAmount={this.props.renewalPricingDetails.investmentAmount}
            paymentType={this.state.isPlanUpgraded?"UPGRADE":"RENEWAL"}
            selectedPlanId = {subscriptionDetails.planId}
            handleScreenChange={this.handleScreenChange}
            expiryDate = {subscriptionDetails.expiryDate}
          />
        </JarvisCard>
      )
    }

    return (
      <>
        <Box>
          <PageTitle />
        </Box>
        <Box sx={{ backgroundColor: "#ffffff", borderRadius: "8px", p: { xs: 0, md: 3 } }}>
          <Box>
            <SubscriptionDetailsCard
              subscriptionDetails={subscriptionDetails}
              planStatus={subscriptionDetails?.planStatus}
              billingStatus={subscriptionDetails?.expiryDate}
              productName={subscriptionDetails?.planName}
              productAmount={subscriptionDetails?.planPrice}
              productTenure={subscriptionDetails?.paymentFrequency}
              autoPayEnabled={subscriptionDetails?.autoPayEnabled}
              productCode={productCodes}
              reasonsDropdown={reasonsDropdown}
              customerSubscriptionId={customerSubscriptionId}
              mobileScreen={isMobileView}
              planDetails ={planDetails}
              // onPlanUpgrade={(data) => this.onPlanUpgrade(data)}
              onPlanUpgrade={() => this.onPlanUpgrade()}
              onPlanRenewal={() => this.handlePlanRenewal()}
            />
          </Box>

          <Stack py={2} direction="row" alignItems="center" justifyContent="space-between">
            <Text sx={{ color: "secondary", fontWeight: 500 }}>Transaction History</Text>
            <JarvisButton
              disableEndIcon
              disableStartIcon={false}
              animate={false}
              variant="text"
              sx={{ color: 'black' }}
              startIcon={<FilterListIcon />}
              onClick={this.handleFilterOpen}
            >
              Filter
            </JarvisButton>
          </Stack>

          <JarvisRMSFilter
            filterData={tempFilterData}
            open={openFilter}
            onClose={this.handleFilterClose}
            onChange={this.handleFilterDataChange}
            onReset={this.handleFilterReset}
            onSubmit={this.handleFilterSubmit}
            screen="portfolio"
            transactionStatus={transactionStatusData}
          />
          {
            !transactionHistory.length ? (
              <>
                {this.isFilterApplied() ? (
                  <Stack alignItems={'center'} justifyContent={'center'}>
                    <Text sx={{ mb: 1 }}>No transaction record found for applied filter</Text>
                    <Button size="small" variant="outlined" onClick={this.handleFilterReset}>Clear Filter</Button>
                  </Stack>
                ) : (
                  <Stack alignItems={'center'} justifyContent={'center'}>
                    <Text sx={{ fontWeight: "bold" }}>No transaction record found</Text>
                  </Stack>
                )}

              </>
            ) : (
              <>
                {
                  !isMobileView ?
                    <Table
                      columns={columns}
                      count={transactionHistoryCount}
                      data={transactionHistory}
                      loading={transactionHistoryLoading}
                      paginationBottom
                      paginationTop
                      page={page - 1}
                      rowsPerPage={limit}
                      onPageChange={this.handlePageChange}
                      dataChangedCounter={this.state.tableRefreshCounter}
                      th_sx={{
                        p: "1px 10px",
                        backgroundColor: "#F1F1F1",
                        color: "#808591",
                        borderRight: "1px solid #F1F1F1",
                        fontWeight: "unset",
                        fontSize: "12px",
                      }}
                      td_sx={{
                        p: "10px",
                        borderRight: "1px solid #F1F1F1",
                        borderBottom: "none",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    />
                    :
                    <JarvisCard sx={{ border: "1px solid #EEF0F2", padding: "0", height: "auto" }}>
                      <Pagination
                        count={transactionHistoryCount}
                        page={page - 1}
                        rowsPerPage={limit}
                        onPageChange={this.handlePageChange}
                      />

                      {transactionHistory?.map((data) => {
                        return (
                          <>
                            <Text sx={{ padding: "15px 10px" }}>
                              <MobileTransactionCards
                                data={data}
                              />
                            </Text>
                            <Text sx={{ borderBottom: "1px solid #E2E2E2", padding: "0px" }}></Text>
                          </>
                        );
                      })}
                    </JarvisCard>
                }

              </>
            )
          }


        </Box>
      </>
    )
  }
}

export default withRouter(SubscriptionDetailsContainer);
