import React from "react";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Dialog from "components/common/Dialog";
import JarvisButton from "components/JarvisButton";

const TransationSuccessDialog = (props) => {
  const { open, onConfirm } = props;
  return (
    <Dialog
      open={open}
      title=""
      disableCloseIcon
      maxWidth="xs"
      sx={{ borderRadius: "10px" }}
      contentComponent={() => (
        <Box sx={{ maxWidth: "300px", margin: "0 auto", textAlign: "center" }}>
          <img
            src="/images/svgs/success.gif"
            width="100px"
            alt="success"
          />
          <Text
            variant="subtitle1"
            sx={{ color: "#1E1E1E", mb: "16px", lineHeight: 1.3 }}
          >
            Transaction Details Updated Successfully
          </Text>
          <Text variant="small" color="#808080">
            Your Portfolio has been updated and can be viewed from Dashboard
          </Text>
        </Box>
      )}
      actionComponent={(onClose) => {
        return (
          <Box m={2} textAlign="center">
            <JarvisButton
              onClick={() => onConfirm(onClose)}
              disableEndIcon={true}
              variant="outlined"
            >
              Awesome, Go back
            </JarvisButton>
          </Box>
        );
      }}
    />
  );
};

export default TransationSuccessDialog;
