import React from "react";
import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";

// helper
import { OnboardingRoute } from "helper/route";

// layout
import OnestockOnboardingLayout from "containers/JarvisOnestock/OnestockOnboardingLayout";

// pages
import OnestockOnboardingOverview from "pages/JarvisOnestock/OnestockOnboardingOverview";
import OnestockRiskAssessment from "pages/JarvisOnestock/OnestockOnboarding/OnestockRiskAssessment";
import OnestockPayment from "pages/JarvisOnestock/OnestockOnboarding/OnestockPayment";
import OnestockCkyc from "pages/JarvisOnestock/OnestockOnboarding/OnestockCkyc";

const JarvisOneStockOnboardingNavigator = (props) => {
	const { match } = props;
	const isAuthenticated = useSelector((state) => state.customer.isAuthenticated);
	const isOnestockOnboardingCompleted = useSelector((state) => state.jarvisOnestock.onestockOnboarding.isOnboardingCompleted);


	return (
		<Switch>
			<OnestockOnboardingLayout {...props}>
				<Switch>
					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isOnestockOnboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-onestock/dashboard`}
						path={`${match.path}/overview`}
						component={OnestockOnboardingOverview}
					/>

					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isOnestockOnboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-onestock/dashboard`}
						path={`${match.path}/risk-assessment`}
						component={OnestockRiskAssessment}
					/>
					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isOnestockOnboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-onestock/dashboard`}
						path={`${match.path}/payment`}
						component={OnestockPayment}
					/>
					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isOnestockOnboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-onestock/dashboard`}
						path={`${match.path}/ckyc`}
						component={OnestockCkyc}
					/>
				</Switch>
			</OnestockOnboardingLayout>
		</Switch>
	);
};

export default JarvisOneStockOnboardingNavigator;