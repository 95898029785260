// import { appsFlyerTracker } from "../appsFlyer";
// import { mixpanelTracker } from "../mixpanel";
// import { uxcamTracker } from "../uxcam";

const forgetPasswordTracker = {};

forgetPasswordTracker.forgetPassword = () => {
    // const eventName = "forget_password";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

forgetPasswordTracker.forgetPasswordRequestOtp = () => {
    // const eventName = "fp_request_otp";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });
};

forgetPasswordTracker.forgetPasswordVerifyotp = () => {
    // const eventName = "fp_verify_otp";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

forgetPasswordTracker.forgetPasswordVerifyotpFailed = ({ error_msg }) => {
    // const eventName = "fp_verify_otp_failed";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: {error_msg },
    // });
};

forgetPasswordTracker.forgetPasswordVerifyotpSuccess = () => {
    // const eventName = "fp_verify_otp_success";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

forgetPasswordTracker.forgetPasswordChangePassword = () => {
    // const eventName = "fp_change_password";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

forgetPasswordTracker.forgetPasswordChangePasswordError = ({ error_msg }) => {
    // const eventName = "fp_change_password_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    //     eventData: { error_msg },
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: {error_msg },
    // });
};

forgetPasswordTracker.forgetPasswordChangePasswordSuccess = () => {
    // const eventName = "fp_change_password_error";

    // appsFlyerTracker.trackEvents({
    //     eventName,
    // });

    // mixpanelTracker.trackEvents({
    //     eventName,
    // });

    // uxcamTracker.trackEvents({
    //     eventName,
    //     eventData: { },
    // });
};

export { forgetPasswordTracker };
