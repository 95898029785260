import React from "react";

import { formatHelper } from "helper/format";
import { config } from "config";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import IconButton from "components/common/IconButton";

import SectionTitle from "components/SectionTitle";
import JarvisButton from "components/JarvisButton";

import BackIcon from "@mui/icons-material/ArrowBackOutlined";
import ButtonBase from "components/common/ButtonBase";
import TextField from "components/common/TextField";
import InputAdornment from "components/common/InputAdornment";
import Select from "components/common/Select";
import Box from "components/common/Box";
import PlanAndPricingContainer from "containers/PlanAndPricing";
import Grid from "components/common/Grid";
import ChangeRiskProfileCard from "../ChangeRiskProfileCard";
import InvestmentStrategyCard from "../StrategyCard";

const EditInvestmentDetails = ({
    minInvestmentAmount,
    displayYearSelect,
    displayAmountInput,
    lockAmountInput,
    formData,
    formError,

    investmentAmountList,
    tenurePreferences,
    tenurePreferencesList,
    riskProfiles,
    investmentStrategies,

    onSave,
    onCancel,
    onChange,
}) => {

    return (
        <Stack>
            <SectionTitle
                sx={{ mb: 2 }}
                heading="Summary"
                disableBreadcrumb
                startIcon={
                    <IconButton onClick={onCancel}>
                        <BackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                    </IconButton>
                }
            />

            {/* Investment Amount */}
            <Stack sx={{ mb: 3 }}>
                <Text sx={{ mb: 1 }}>
                    Jarvis needs a minimum balance of Rs. {parseInt(minInvestmentAmount).toLocaleString('en-IN')} in your demat account to build an efficient portfolio for you.
                </Text>

                <Stack sx={{ flexFlow: "row wrap", m: "0px -8px" }} spacing={{ xs: 0.5, md: 1 }}>
                    {investmentAmountList.map((amount, index) => (
                        <ButtonBase
                            key={`amount_${index}`}
                            variant="outlined"
                            size="small"
                            onClick={() =>
                                onChange({
                                    name: "investmentAmount",
                                    value: amount,
                                    valueType: "chip"
                                })
                            }
                            className={
                                (!displayAmountInput && formData.investmentAmount === amount) ||
                                    (displayAmountInput && amount === "other") ? "active" : ""
                            }
                            sx={{
                                fontSize: "14px !important",
                                borderColor: "#E0E7FF",
                                color: "#969ea8",
                                "&.active": {
                                    backgroundColor: "#1D9BFF14 ",
                                    color: "#1D9BFF",
                                    borderColor: "#1D9BFF",
                                },
                                height: "35px",
                                padding: "5px 22px",
                                borderRadius: "50px !important",
                                m: "8px !important",
                                textTransform: "capitalize",
                            }}
                        >
                            {isNaN(amount) ?
                                amount :
                                formatHelper.currency(amount)
                            }


                        </ButtonBase>
                    ))}

                    {
                        displayAmountInput ?
                            <TextField
                                label="Enter prefered amount"
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="end">₹</InputAdornment>,
                                }}
                                onChange={(e) =>
                                    onChange({
                                        name: "investmentAmount",
                                        value: e.target.value,
                                    })
                                }
                                value={formData.investmentAmount || ""}
                                sx={{
                                    maxWidth: 160,
                                    height: "35px",
                                    "&> label": {
                                        fontSize: "9px !important",
                                        transform: "translate(17px, -4px) scale(0.75)!important",
                                        backgroundColor: "#fff",
                                        padding: "0px 6px"
                                    },
                                    "&> div": {
                                        borderRadius: "50px !important",
                                        overflow: "hidden"
                                    },
                                    "& input": {
                                        padding: "0 !important",
                                        fontSize: "14px !important",
                                        height: "35px",
                                        paddingLeft: "22px !important",
                                        paddingTop: "0px !important"
                                    }
                                }}
                                disabled={lockAmountInput}
                            />
                            : null
                    }

                </Stack>
                {formError.investmentAmount && <Text variant="caption" color="error.main">{formError.investmentAmount}</Text>}

            </Stack>

            {/* Investment Tenure */}
            <Stack sx={{ mb: 3 }}>
                <Text mb={1.6}>Recommended based on your risk profile</Text>
                <Stack sx={{ flexFlow: "row wrap", m: "0px -8px" }} spacing={{ xs: 0.5, md: 1 }}>
                    {tenurePreferences.map((tenure, index) => (
                        <ButtonBase
                            key={`tenure_${index}`}
                            variant="outlined"
                            size="small"
                            onClick={() =>
                                onChange({
                                    name: "investmentTenure",
                                    value: tenure.value,
                                    valueType: "chip"
                                })
                            }
                            className={
                                (!displayYearSelect && formData.investmentTenure === tenure.value) ||
                                    (displayYearSelect && tenure.value === "other") ? "active" : ""
                            }
                            sx={{
                                fontSize: "14px !important",
                                borderColor: "#E0E7FF",
                                color: "#969ea8",
                                "&.active": {
                                    backgroundColor: "#1D9BFF14 ",
                                    color: "#1D9BFF",
                                    borderColor: "#1D9BFF",
                                },
                                height: "35px",
                                width: "80px",
                                padding: "5px",
                                borderRadius: "50px !important",
                                m: "8px !important",
                            }}
                        >
                            {tenure.label}
                        </ButtonBase>
                    ))}

                    {displayYearSelect ?
                        <Select
                            disableUnderline
                            makeOptionsCenterAlign
                            variant="filled"
                            value={formData.investmentTenure || 0}
                            data={tenurePreferencesList}
                            dataKey={"year"}
                            dataValue="display"
                            onChange={(e) =>
                                onChange({
                                    name: "investmentTenure",
                                    value: e,
                                })
                            }
                            sx={{
                                px: 1.5,
                                py: 0.25,
                                borderRadius: 5.5,
                                fontSize: `1rem !important`,
                                height: "35px",
                                width: "110px",
                                padding: "5px 15px",
                                m: "8px !important",
                                border: "1px solid #e0e7ff",
                                color: "#969ea8",
                                backgroundColor: "transparent !important",
                                "&> select": {
                                    backgroundColor: "transparent !important",
                                }
                            }}
                        />
                        : null
                    }
                </Stack>
            </Stack>

            {/* Plan Selection */}
            <Box sx={{ mb: 3 }}>
                <PlanAndPricingContainer
                    selectedPlanId={formData.planId}
                    onPlanSelection={(d) =>
                        onChange({ name: 'planId', value: d.planId })
                    }
                    productCode={config.productCodes.PORTFOLIO} 
                    investmentAmount={formData.investmentAmount}
                    paymentType="INITIAL"
                />
            </Box>

            {/* Risk Profile */}
            <Stack sx={{ mb: 3 }}>
                <Text sx={{ mb: 1 }}>Select your investment type</Text>
                <Grid container spacing={2}>
                    {riskProfiles.map((risk, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                            key={`risk_${index}`}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <ChangeRiskProfileCard
                                id={risk.id}
                                title={risk.name}
                                description={risk.description}
                                level={1}
                                onClick={() =>
                                    onChange({ name: 'riskProfileId', value: risk.id })
                                }
                                isSelected={risk.id === formData.riskProfileId}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Stack>

            {/* Investment Strategy */}
            <Stack sx={{ mb: 3 }}>
                <Text sx={{ mb: 1 }}>Your Investment Strategy</Text>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {investmentStrategies.map((strategy, index) => (
                        <>
                        {strategy?.heading?.toLowerCase()?.includes('pure equity') ? (<InvestmentStrategyCard
                            key={`strategy_${index}`}
                            heading={strategy.heading}
                            description={strategy.description}
                            descriptionSmall={strategy.descriptionSmall}
                            isSelected={formData.investmentStrategy === strategy.id}
                            // onClick={() =>
                            //     onChange({
                            //         name: "investmentStrategy",
                            //         value: strategy.id,
                            //     })
                            // }
                        />) :<></>}
                        </>
                    ))}
                </Stack>
            </Stack>

            <Stack mb={{ xs: 0, md: 2 }} direction="row" alignItems="center" justifyContent="flex-end" sx={{position: { xs: "sticky"}, bottom:{xs:"10px"}}}>
                {/* <JarvisButton onClick={onCancel} variant="outlined" disableEndIcon sx={{ mr: 2 }}>Cancel</JarvisButton> */}
                <JarvisButton onClick={onSave} variant="contained" sx={{ width: { xs: "100%", md: "auto" } }} >Proceed</JarvisButton>
            </Stack>
        </Stack>
    )
}

export default EditInvestmentDetails;