import { all } from "redux-saga/effects";

// onestock sagas
import { registerJarvisOnestockOnboardingSagas } from "./jarvisOnestockOnboarding/jarvisOnestockOnboardingSaga";
import { registerJarvisOnestockExistingHoldingSagas } from "./jarvisOnestockExistingHolding/jarvisOnestockExistingHoldingSaga";
import { registerJarvisOnestockTransactionSagas } from "./jarvisOnestockTransaction/jarvisOnestockTransactionSaga";
import { registerJarvisOnestockActionCenterSagas } from "./jarvisOnestockActionCenter/jarvisOnestockActionCenterSagas";
import { registerJarvisOnestockDashboardSagas } from "./jarvisOnestockDashboard/jarvisOnestockDashboardSaga";

function* registerJarvisOnestockRootSaga() {
    yield all([
        registerJarvisOnestockOnboardingSagas(),
        registerJarvisOnestockExistingHoldingSagas(),
        registerJarvisOnestockTransactionSagas(),
        registerJarvisOnestockActionCenterSagas(),

        registerJarvisOnestockDashboardSagas(),
    ]);
}

export { registerJarvisOnestockRootSaga };
