import React from "react";
import PageTitle from "components/PageTitle";
import OneTimePaymentContainer from "containers/OneTimePayment";

const OneTimePayment = (props) => {
    return (
        <>
            <PageTitle heading="Payment" />
            <OneTimePaymentContainer {...props} />
        </>
    )
}

export default OneTimePayment