import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { transactionActions } from "stores/transactions/transactionActions"

const mapStateToProps = (state) => {
    return {
        transactionList: state.transaction.transactionList,
        transactionCount: state.transaction.transactionCount,
        isLoading: state.transaction.loading.transactionList,
        transactionListApiCounter: state.transaction.transactionListApiCounter,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTransactionListAction: transactionActions.getTransactionList,

}, dispatch)

const TransactionStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default TransactionStore;