import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import TextField from "components/common/TextField";
import DatePicker from "components/common/DatePicker";
import IconButton from "components/common/IconButton";
import JarvisButton from "components/JarvisButton";
import CheckIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SectionTitle from "components/SectionTitle";
import { Checkbox } from "@mui/material";

const CKYCauto = (props) => {
  const {
    formData,
    formError,
    isVerified,
    isLoading,
    hasFirstAttempError,

    handleChange,
    handleVerification,
    toggleKYCtype,
  } = props;
  const history = useHistory();

  const [isSize, setSize] = useState(window.innerHeight > 800);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);

  const updateSize= () => {
        setSize(window.innerHeight > 800);
  };

  useEffect(()=>{
    window.addEventListener("resize", updateSize);
    return () =>window.removeEventListener("resize", updateSize);
  })

  const handleCheckBoxChange = (value) =>{
      setCheckBoxStatus(value)
  }
  return (
    <Stack sx={{ pt: { xs: 2, md: 0 }, alignItems: "flex-start" }}>
      {/* Back button */}
      <SectionTitle
        sx={{ display: { xs: "flex", md: 'none' } }}
        heading="Complete CKYC"
        startIcon={
          <IconButton onClick={() => history.push({ pathname: "/customer/product-info" })}>
            <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
          </IconButton>
        }

      />

      <Text mb={2} sx={{ fontSize: { xs: 'body2.fontSize', md: 'body1.fontSize' }, fontWeight: 400 }}>
        Please keep your Aadhaar and PAN number
        handy and make sure that the mobile number
        linked to your Aadhaar number is within the
        network range, as you will get OTP to
        verify CKYC as per the SEBI 2013 regulations.
      </Text>
      <Text mb={4} sx={{ fontSize: { xs: 'body2.fontSize', md: 'body1.fontSize' }, fontWeight: 400 }}>
        An initiative by the Indian government to set up a system that will help you perform your KYC just once before interacting with different companies in the financial industry, like us.
      </Text>

      {hasFirstAttempError ? (
        <Stack direction="row" alignItems="center" mb={2}>
          <ErrorIcon sx={{ fontSize: 24, color: "#BC2B2B" }} />
          <Text variant="body2" sx={{ color: "#BC2B2B", maxWidth: "400px", ml: 0.5 }}>
            Verification Failed! We can't find your CKYC records.
          </Text>
        </Stack>
      ) : null}

      {/* PAN */}
      <TextField
        type="text"
        label="PAN No."
        value={formData.panNumber}
        onChange={(e) =>
          handleChange({
            name: "panNumber",
            value: e.target.value.toUpperCase(),
          })
        }
        helperText={formError.panNumber}
        error={formError.panNumber}
        disabled={isVerified}
        sx={{ maxWidth: 400, mb: 2 }}
        inputProps={{ maxLength: 12 }}
      />

      {/* Data*/}
      <DatePicker
        label="Date of Birth"
        value={formData.dateOfBirth}
        onChange={(value) => handleChange({ name: "dateOfBirth", value })}
        helperText={formError.dateOfBirth}
        error={formError.dateOfBirth}
        disabled={isVerified}
        textFieldProps={{
          sx: { maxWidth: 400, mb: 2 },
        }}
      />

      {/* Msg: success */}
      {isVerified ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ display: "flex", mb: { xs: 0, md: 4 } }}
        >
          <CheckIcon sx={{ fontSize: 24, color: "#18A70E" }} />
          <Text variant="body2" sx={{ color: "#18A70E" }}>
            Account verification successful
          </Text>
        </Stack>
      ) : null}

      {/* Msg: fail */}
      {hasFirstAttempError ? (
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing={1}
          sx={{
            display: isVerified || true ? "flex" : "none",
            mb: { xs: 4, md: 4 },
          }}
        >
          <Text
            component="span"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "blue",
              "&:hover": {
                color: "#e60b0b",
              },
            }}
            onClick={() => toggleKYCtype(true)}
          >
            {` `}Click here for manually CKYC
          </Text>
        </Stack>
      ) : null}

      {/* Submit: verification */}
      {!isVerified ? (
        <Stack
          direction="row"
          justifyContent={{ xs: "center", md: "flex-start" }}
          mb={{ xs: 7, md: 3 }}
          mt={6}
          alignItems="center"
          sx={{ width: { xs: "100%", md: "auto" }, background: "#fff" }}
        >
          {isSize ? (
            <Stack
              sx={{
                position: { xs: "fixed", md: "unset" },
                bottom: { xs: "10px", md: "unset" },
                width: { xs: "90vw", md: "unset" },
              }}
            >
              {/* CKYC Checkbox */}
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                marginBottom="1rem"
              >
                <Checkbox
                  sx={{ px: 1, py: 0, pl: 0 }}
                  onChange={(e)=>handleCheckBoxChange(e.target.checked)}
                  value={checkBoxStatus}
                />
                <Box>
                  <Text
                    variant="caption1"
                    sx={{
                      display: {
                        md: "block",
                      },
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "3",
                      overflow: "hidden",
                    }}
                  >
                    I hereby provide consent to Ventugrow Consultants Pvt Ltd to retrieve my KYC 
                    from <b>CKYCRR </b> 
                    and download it as per <b>SEBI (IA) Regulations, (2013), </b>
                    including all subsequent amendments.
                  </Text>
                </Box>
              </Stack>
              <JarvisButton
                sx={{ width: { xs: "100%", md: "auto" } }}
                loading={isLoading}
                disabled={!checkBoxStatus}
                onClick={handleVerification}
              >
                Submit for verification
              </JarvisButton>
            </Stack>
          ) : (
            <Stack
              sx={{
                position: { xs: "static", md: "unset" },
                bottom: { xs: "10px", md: "unset" },
                width: { xs: "90vw", md: "unset" },
              }}
            >
              {/* CKYC Checkbox */}
              <Stack
                sx={{
                  alignItems:{ xs:"flex-start", md:"center" },
                  marginBottom:{ xs: "1rem", md: "0.313rem" }
                }}
                direction="row"
                justifyContent="flex-start"
              >
                <Checkbox
                  sx={{ px: 1, py: 0, pl: 0 }}
                  onChange={(e)=>handleCheckBoxChange(e.target.checked)}
                  value={checkBoxStatus}
                />
                <Box>
                  <Text
                    variant="caption1"
                    sx={{
                      display: {
                        md: "block",
                      },
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "3",
                      overflow: "hidden",
                    }}
                  >
                    I hereby provide consent to Ventugrow Consultants Pvt Ltd to retrieve my KYC 
                    from <b>CKYCRR </b>
                    and download it as per <b>SEBI (IA) Regulations, (2013), </b>
                    including all subsequent amendments.
                  </Text>
                </Box>
              </Stack>
              <JarvisButton
                sx={{ width: { xs: "100%", md: "30%" } }}
                loading={isLoading}
                disabled={!checkBoxStatus}
                onClick={handleVerification}
              >
                Submit for verification
              </JarvisButton>
            </Stack>
          )}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default CKYCauto;
