import { axios } from "helper/axios";
import { jarvisRMSDashboardOnboardedConstants } from "./jarvisRMSDashboardonboardedConstants";

const jarvisRMSDashboardOnboardedApis = {};

jarvisRMSDashboardOnboardedApis.getPortfolioStatsApi = () => {
    return axios.get(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_PORTFOLIO_STATS_API
    );
};

jarvisRMSDashboardOnboardedApis.getRmsCallStatsApi = () => {
    return axios.get(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_RMS_CALL_STATS_API
    );
};

jarvisRMSDashboardOnboardedApis.getMarketCapApi = () => {
    return axios.get(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_MARKET_CAP_API
    );
};

jarvisRMSDashboardOnboardedApis.getSectorDiversificationApi = () => {
    return axios.get(
        jarvisRMSDashboardOnboardedConstants.JARVIS_RMS_SECTOR_DIVERSIFICATION_API
    );
};

export { jarvisRMSDashboardOnboardedApis };
