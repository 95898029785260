import React from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Divider from "components/common/Divider";
import JarvisButton from "components/JarvisButton";
import SectionTitle from "components/SectionTitle";
import IconButton from "components/common/IconButton";
import BackIcon from "@mui/icons-material/ArrowBackOutlined";
import { formatHelper } from "helper/format";

const PricingDetails = ({
    onBackClick,
    onContinueClick,
    pricingData,
}) => {
    return (
        <Stack>
            <SectionTitle
                sx={{ mb: 2, display: { xs: "none", md: "flex" } }}
                heading="You need to Pay fees to view and invest in stocks"
                startIcon={
                    <IconButton onClick={onBackClick}>
                        <BackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                    </IconButton>
                }
            />

            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Stack
                    sx={{
                        flex: 0,
                        border: { xs: "none", md: "1px solid #E8EBEE" },
                        py: { xs: 2, md: 3 },
                        px: { xs: 2, md: 4 },
                        borderRadius: 2.5,
                        bgcolor: { xs: "#EAF3FB", md: "#FFFFFF" },
                    }}>
                    <Stack sx={{ mb: { xs: 0, md: 3 } }}>
                        <Text
                            variant="small"
                            sx={{
                                fontWeight: "normal",
                                color: "#808591",
                                whiteSpace: "nowrap",
                            }}>
                            Investment Amount
                        </Text>
                        <Text variant="subtitle2" sx={{ color: "#2E384D" }}>
                            {formatHelper.currency(
                                pricingData.investmentAmount,
                                { config: { useGrouping: true } }
                            )}
                        </Text>
                    </Stack>
                </Stack>

                <Stack
                    sx={{
                        border: { xs: "none", md: "1px solid #E8EBEE" },
                        flex: 1,
                        py: { xs: 2, md: 3 },
                        px: { xs: 2, md: 4 },
                        borderRadius: 2.5,
                        bgcolor: { xs: "#EAF3FB", md: "#FFFFFF" },
                    }}>
                    <Text
                        variant="subtitle2"
                        sx={{
                            fontWeight: "normal",
                            color: "primary.main",
                            mb: 0.5,
                        }}>
                        JARVIS Fees
                    </Text>
                    <Text
                        sx={{
                            typography: { xs: "small", md: "body1" },
                            fontWeight: "bold",
                            color: "#2C2C2C",
                            mb: 2.5,
                        }}>
                        {pricingData.feesPercentageHalfYearly}% of investment value,
                        charged {`${pricingData.feesTenure}`.toLowerCase()}
                    </Text>

                    <Stack sx={{ maxWidth: { xs: "100%", md: 420 }, mb: { xs: 0, md: 3.5 } }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mb: 0.75 }}>
                            <Text
                                sx={{
                                    typography: { xs: "small", md: "body1" },
                                    color: "#515151",
                                }}>
                                Advisory Fees
                            </Text>
                            <Text
                                sx={{
                                    typography: { xs: "small", md: "body1" },
                                    color: "#515151",
                                }}>
                                {formatHelper.currency(pricingData.fees)}
                            </Text>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mb: 1.5 }}>
                            <Text
                                sx={{
                                    typography: { xs: "small", md: "body1" },
                                    color: "#515151",
                                }}>
                                GST ({pricingData.feesGSTPercentage}%)
                            </Text>
                            <Text
                                sx={{
                                    typography: { xs: "small", md: "body1" },
                                    color: "#515151",
                                }}>
                                {formatHelper.currency(pricingData.feesGST)}
                            </Text>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                            <Text
                                sx={{
                                    typography: { xs: "small", md: "body1" },
                                    color: "#515151",
                                    fontWeight: "bold",
                                }}>
                                Total Payable Amount
                            </Text>
                            <Text
                                sx={{
                                    typography: { xs: "small", md: "body1" },
                                    color: "#515151",
                                    fontWeight: "bold",
                                }}>
                                {formatHelper.currency(pricingData.feesTotal)}
                            </Text>
                        </Stack>
                    </Stack>

                    <Divider
                        sx={{ mb: 3, display: { xs: "none", md: "flex" } }}
                    />

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            maxWidth: 420,
                            display: { xs: "none", md: "flex" },
                        }}>
                        <Stack>
                            <Text
                                variant="subtitle2"
                                sx={{ color: "#515151", fontWeight: "bold" }}>
                                {formatHelper.currency(pricingData.feesTotal, {
                                    config: { useGrouping: true },
                                })}
                            </Text>
                            <Text variant="caption1" sx={{ color: "#808080" }}>
                                inclusive GST
                            </Text>
                        </Stack>
                        <JarvisButton onClick={onContinueClick}>Proceed to Pay</JarvisButton>
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                direction="row"
                justifyContent="space-between"
                borderTop="1px solid #E9E9E9"
                sx={{
                    width: "100%",
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    display: { xs: "flex", md: "none" },
                    p: 2
                }}>
                <Stack>
                    <Text
                        variant="subtitle2"
                        sx={{ color: "#515151", fontWeight: "bold" }}>
                        {formatHelper.currency(pricingData.feesTotal, {
                            config: { useGrouping: true },
                        })}
                    </Text>
                    <Text variant="caption1" sx={{ color: "#808080" }}>
                        inclusive GST
                    </Text>
                </Stack>
                <JarvisButton onClick={onContinueClick}>Proceed to Pay</JarvisButton>
            </Stack>
        </Stack>
    );
};

export default PricingDetails;
