import React from "react";
import { useSelector } from "react-redux";
import {  Switch } from "react-router-dom";

import { OnboardingRoute } from "helper/route";
import RMSRiskAssessment from "pages/JarvisRMS/RMSOnboarding/RMSRiskAssessment";
import RMSCKYC from "pages/JarvisRMS/RMSOnboarding/RMSCKYC";
import RMSPortfolioSync from "pages/JarvisRMS/RMSOnboarding/RMSPortfolioSync";
import RMSOnboardingLayoutContainer from "containers/JarvisRMS/RMSOnboardingLayout";
import RMSOnboardingOverview from "pages/JarvisRMS/RMSOnboardingOverview";


const RMSOnboardingNavigator = (props) => {
	const { match } = props;
	const isAuthenticated = useSelector((state) => state.customer.isAuthenticated);
	const isRMSonboardingCompleted = useSelector((state) => state.jarvisRMS.rmsOnboarding.isOnboardingCompleted);

	return (
		<Switch>

			<RMSOnboardingLayoutContainer {...props}>
				<Switch>
					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isRMSonboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-rms/dashboard`}
						path={`${match.path}/overview`}
						component={RMSOnboardingOverview}
					/>

					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isRMSonboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-rms/dashboard`}
						path={`${match.path}/risk-assessment`}
						component={RMSRiskAssessment}
					/>

					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isRMSonboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-rms/dashboard`}
						path={`${match.path}/sync-portfolio`}
						component={RMSPortfolioSync}
					/>
					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isRMSonboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-rms/dashboard`}
						path={`${match.path}/plan-pricing`}
						component={RMSPortfolioSync}
					/>
					<OnboardingRoute
						exact
						isAuthenticated={isAuthenticated}
						isOnboardingCompleted={isRMSonboardingCompleted}
						redirectOnboardingIncomplete={`/jarvis-rms/dashboard`}
						path={`${match.path}/ckyc`}
						component={RMSCKYC}
					/>
				</Switch>
			</RMSOnboardingLayoutContainer>
		</Switch>
	);
};

export default RMSOnboardingNavigator;
