import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { jarvisRMSOnboardingConstants } from "./jarvisRMSOnboardingConstants";

const initialState = {
    currentStage: "",
    isOnboardingCompleted: 0,
    riskProfileId: "",
    panNumber: "",
    dateOfBirth: "",
    aadharNumber: "",
    isManualKYC: 0,
    isPanUploaded: 0,
    isAadhaarFrontUploaded: 0,
    isAadhaarBackUploaded: 0,
    duplicateStages: [],
    rmsOnboardingSteps: [
        "RISK_ASSESSMENT",
        "SYNC_PORTFOLIO",
        "CKYC",
        "DOCUMENT_SIGNING",
    ],
    brokerList: [],
    brokerRecords: 0,
    productPlan: {},

    isInitialFetched: 0,
    loading: {
        onboardingData: 0,
        productPlan: 0,
        brokerList: 0,
    },
    initialCalled: {
        onboardingData: 0,
        productPlan: 0,
        brokerList: 0,
    },
    portfolioId: "",
};

export const jarvisRMSOnboardingReducer = persistReducer(
    {
        storage,
        key: "jarvisRMSOnboarding",
        blacklist: ["rmsOnboardingSteps", "brokerRecords", "brokerList", "isInitialFetched", "loading", "productPlan"],
    },

    (state = initialState, { type, payload }) => {
        switch (type) {
            case jarvisRMSOnboardingConstants.RMS_UPDATE_ONBOARDING_DATA: {
                return {
                    ...state,
                    currentStage: payload.currentStage || state.currentStage,
                    isOnboardingCompleted:
                        payload.isOnboardingCompleted ||
                        state.isOnboardingCompleted,
                    riskProfileId: payload.riskProfileId || state.riskProfileId,
                    panNumber: payload.panNumber || state.panNumber,
                    dateOfBirth: payload.dateOfBirth || state.dateOfBirth,
                    aadharNumber: payload.aadharNumber || state.aadharNumber,
                    isManualKYC: payload.isManualKYC || state.isManualKYC,
                    isPanUploaded: payload.isPanUploaded || state.isPanUploaded,
                    isAadhaarFrontUploaded:
                        payload.isAadhaarFrontUploaded ||
                        state.isAadhaarFrontUploaded,
                    isAadhaarBackUploaded:
                        payload.isAadhaarBackUploaded ||
                        state.isAadhaarBackUploaded,
                    duplicateStages:
                        payload.duplicateStages || state.duplicateStages,
                    isInitialFetched:
                        payload.isInitialFetched || state.isInitialFetched,
                    portfolioId: payload.portfolioId || null,
                };
            }

            case jarvisRMSOnboardingConstants.BROKER_LIST_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        brokerList: 1,
                    },
                };
            }

            case jarvisRMSOnboardingConstants.BROKER_LIST_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        brokerList: 0,
                    },
                    initialCalled: {
                        ...state.initialCalled,
                        brokerList: 1,
                    },
                    brokerList: payload.list,
                    brokerRecords: payload.records,
                };
            }

            case jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        onboardingData: 1,
                    },
                };
            }

            case jarvisRMSOnboardingConstants.RMS_ONBOARDING_DATA_RESPONSE: {
                return {
                    ...state,
                    isInitialFetched: 1,
                    currentStage: payload.currentStage || state.currentStage,
                    isOnboardingCompleted:
                        payload.isOnboardingCompleted ||
                        state.isOnboardingCompleted,
                    riskProfileId: payload.riskProfileId || state.riskProfileId,
                    panNumber: payload.panNumber || state.panNumber,
                    dateOfBirth: payload.dateOfBirth || state.dateOfBirth,
                    aadharNumber: payload.aadharNumber || state.aadharNumber,
                    isManualKYC: payload.isManualKYC || state.isManualKYC,
                    isPanUploaded: payload.isPanUploaded || state.isPanUploaded,
                    isAadhaarFrontUploaded:
                        payload.isAadhaarFrontUploaded ||
                        state.isAadhaarFrontUploaded,
                    isAadhaarBackUploaded:
                        payload.isAadhaarBackUploaded ||
                        state.isAadhaarBackUploaded,
                    duplicateStages:
                        payload.duplicateStages || state.duplicateStages,
                    portfolioId: payload.portfolioId || null,
                    loading: {
                        ...state.loading,
                        onboardingData: 0,
                    },
                };
            }

            case jarvisRMSOnboardingConstants.RMS_PLAN_REQUEST: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        productPlan: 1,
                    },
                };
            }

            case jarvisRMSOnboardingConstants.RMS_PLAN_RESPONSE: {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        productPlan: 0,
                    },
                    productPlan: payload,
                };
            }

            case jarvisRMSOnboardingConstants.RMS_RESET_ONBOARDING_DATA: {
                return {
                    currentStage: "",
                    isOnboardingCompleted: 0,
                    riskProfileId: "",
                    panNumber: "",
                    dateOfBirth: "",
                    aadharNumber: "",
                    isManualKYC: 0,
                    isPanUploaded: 0,
                    isAadhaarFrontUploaded: 0,
                    isAadhaarBackUploaded: 0,
                    duplicateStages: [],
                    rmsOnboardingSteps: [
                        "RISK_ASSESSMENT",
                        "SYNC_PORTFOLIO",
                        "CKYC",
                        "DOCUMENT_SIGNING",
                    ],
                    brokerList: [],
                    brokerRecords: 0,
                    productPlan: {},

                    isInitialFetched: 0,
                    loading: {
                        onboardingData: 0,
                        productPlan: 0,
                        brokerList: 0,
                    },
                    initialCalled: {
                        onboardingData: 0,
                        productPlan: 0,
                        brokerList: 0,
                    },
                    portfolioId: "",
                };
            }

            default:
                return state;
        }
    }
);
