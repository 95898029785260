import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { portfolioHoldingActions } from "stores/portfolioHolding/portfolioHoldingActions";

const mapStateToProps = (state) => {
    return {
        portfolioHoldingList : state.portfolioHolding.portfolioHoldingList,
        portfolioOverviewData: state.dashboard.portfolioOverview,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPortfolioHoldingListActions: portfolioHoldingActions.getPortfolioHoldingList,
}, dispatch);

const PortfolioHoldingStore = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);
export default PortfolioHoldingStore;