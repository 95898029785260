import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { dashboardActions } from "stores/dashboard/dashboardActions";

const mapStateToProps = (state) => {
    
    return {
        portfolioSector: state.dashboard.portfolioSector
    };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPortfolioSectorAction : dashboardActions.getPortfolioSector
}, dispatch)

const SectorDiversificationStore = (Container) => connect(mapStateToProps, mapDispatchToProps)(Container);
export default SectorDiversificationStore;