import { call, put, takeLatest } from "@redux-saga/core/effects";

import { jarvisOnestockTransactionConstants } from "./jarvisOnestockTransactionConstants";
import { jarvisOnestockTransactionParser } from "./jarvisOnestockTransactionParser"
import { jarvisOnestockTransactionApis } from "./jarvisOnestockTransactionApis";

function* transactionListSaga({ payload }) {
    const { isAvoidRefresh = false } = payload;
    try {
        if(!isAvoidRefresh){
            yield put({ type: jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_REQUEST });
        }
        const res = yield call(jarvisOnestockTransactionApis.getTransactionApi, payload);
        
        const parsedRes = jarvisOnestockTransactionParser.transactionList(res);

        yield put({
            type: jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_RESPONSE,
            payload: parsedRes,
        });
    } catch (error) {
        yield put({
            type: jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_RESPONSE,
            payload: {
                list: [],
                count: 0
            },
        });
    }
}


export function* registerJarvisOnestockTransactionSagas() {

    yield takeLatest(
        jarvisOnestockTransactionConstants.ONESTOCK_TRANSACTION_SAGA,
        transactionListSaga
    );

}
