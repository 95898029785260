
const jarvisOnestockExistingHoldingConstants = {

    // Existing Holding List

    ONESTOCK_EXISTING_HOLDING_RESPONSE: "ONESTOCK_EXISTING_HOLDING_RESPONSE",
    ONESTOCK_EXISTING_HOLDING_REQUEST: "ONESTOCK_EXISTING_HOLDING_REQUEST",
    ONESTOCK_EXISTING_HOLDING_SAGA: "ONESTOCK_EXISTING_HOLDING_SAGA",
    ONESTOCK_EXISTING_HOLDING_API: "/onestock/portfolio",

};

export { jarvisOnestockExistingHoldingConstants };
