import React from "react";
import PortfolioHoldingContainer from "containers/PortfolioHolding";

const PortfolioExistingHolding = (props) => {


    return (
        <>
            <div style={{ marginTop: "20px" }}>
                <PortfolioHoldingContainer />
            </div>
        </>
    )
}

export default PortfolioExistingHolding;