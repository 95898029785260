import React from "react";
import OverviewContainer from "containers/Overview";

const Overview = () => {
    return (
        <>
            <OverviewContainer />
        </>
    );
};

export default Overview;
