import React from "react";
import { withRouter } from "react-router";
import { Checkbox } from "@mui/material";

import Text from "components/common/Text";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import Loader from "components/common/Loader";
import LinearProgress from "components/common/LinearProgress";
import Stepper from "components/common/Stepper";
import Modal from "components/common/Modal";
import Box from 'components/common/Box';
import Button from 'components/common/Button';

import SectionTitle from "components/SectionTitle";
import JarvisButton from "components/JarvisButton";
import RiskProfileMeter from "components/Onboarding/RiskProfileMeter";
import ChangeRiskProfileCard from "components/Onboarding/ChangeRiskProfileCard";
import RiskAssessmentQuestionBox from "components/Onboarding/RiskAssessmentQuestionBox";
import JarvisNote from "components/JarvisNote";
import { localStore } from "stores/localStorage";

import { jarvisRMSOnboardingApis } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingApis";
import { jarvisRMSOnboardingParser } from "stores/jarvisRMS/jarvisRMSOnboarding/jarvisRMSOnboardingParser";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/common/IconButton";
import { commonApis } from "stores/common/commonApis";
import { commonParsers } from "stores/common/commonParsers";
import { formatHelper } from "helper/format";
import { config } from "config/index";


const screenTypes = {
    RISK_QUESTIONNAIRE: "RISK_QUESTIONNAIRE",
    RISK_PREVIEW: "RISK_PREVIEW",
    RISK_PROFILES: "RISK_PROFILES",
    RISK_SUMMARY: "RISK_SUMMARY",
};


class RiskAssessmentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreenType: screenTypes.RISK_QUESTIONNAIRE,
            selectedRiskId: null,
            riskProfileName: "",
            answers: {},
            currentQuestion: 1,
            isLoading: false,
            isDeclarationChecked: false,
            submitQusetionConfirmModal: false,
            previewRiskAssessmentQuestionsList: [],
            answersList: [],
            isRiskAccept: false,
            readMoreTextText: false,
            isQuestionAnswerLoading: false,
        };
    }

    componentDidMount() {
        if(localStore.isSsoCustomer() && this.props.riskProfiles.length){
            if(this.props.history?.location?.state?.riskProfileId && localStore.getSsoCustomerDetails()?.brokerName === config.broker.ssoPlatform.centrum){
                this.setState({ selectedRiskId: this.props.history?.location?.state?.riskProfileId },() => this.handleScreenChange(screenTypes.RISK_SUMMARY)) 
            } else {
                return this.handleScreenChange(screenTypes.RISK_PROFILES);
            }
        }
        if (!this.props.riskProfiles.length) {
            this.props.getRMSRiskProfilesAction();
        }
        if (!this.props.riskAssessmentQuestions.length) {
            // this.props.getRMSRiskAssessmentQuestionsAction();
            this.props.getRiskAssessmentQuestionsAction();
        }


        if (this.props.suggestedRiskId) {
            this.fetchAssessmentAnswers();
            this.handleScreenChange(screenTypes.RISK_PREVIEW);

        }
    }
    componentDidUpdate = (previousProps,perviousState)=>{
        if(previousProps.riskProfiles.length !== this.props.riskProfiles.length && !previousProps.riskProfiles.length){
            if(localStore.isSsoCustomer() && this.props.history?.location?.state?.riskProfileId && localStore.getSsoCustomerDetails()?.brokerName === config.broker.ssoPlatform.centrum){
                this.setState({ selectedRiskId: this.props.history?.location?.state?.riskProfileId },() => this.handleScreenChange(screenTypes.RISK_SUMMARY))
                return
            }
            if(localStore.isSsoCustomer() && this.props.riskProfiles.length){
                return this.handleScreenChange(screenTypes.RISK_PROFILES);
            }
        }
    }

    fetchAssessmentAnswers = async () => {
        try {
            this.setState({ isQuestionAnswerLoading: true });

            const res = await commonApis.getRiskAssessmentAnswers();
            const parsedRes = commonParsers.getRiskAssessmentAnswers(res);
            if (parsedRes.length) {
                this.setState({ answersList: parsedRes, activeScreenType: screenTypes.RISK_PREVIEW }, () => {
                    this.questionListModify();
                })
            }
        } catch (e) {

        } finally {
            this.setState({ isQuestionAnswerLoading: false });
        }

    }

    questionListModify = () => {
        let { previewRiskAssessmentQuestionsList, answersList } = this.state;
        previewRiskAssessmentQuestionsList = [...this.props.riskAssessmentQuestions];
        previewRiskAssessmentQuestionsList.forEach(d => {
            d['isOptionOpen'] = false;
            answersList.forEach(dum => {
                if (dum.questionId == d.options[0].questionId) {
                    d['questionId'] = dum.questionId;
                    d['optionId'] = dum.optionId;
                    d['selectedName'] = dum.optionLabel;
                }
            })

        });
        this.setState({ previewRiskAssessmentQuestionsList });
    }

    handlePreviewAnswerChange = (data) => {
        let { previewRiskAssessmentQuestionsList } = this.state;
        previewRiskAssessmentQuestionsList.forEach(d => {
            if (d.id == data.questionId) {
                d.selectedName = data.name;
                d.optionId = data.id;
                d.questionId = data.questionId;
                d.isOptionOpen = false;
            }
        })
        this.setState({ previewRiskAssessmentQuestionsList })
    }

    handleScreenChange = (screenName) => {
        this.setState({
            activeScreenType: screenName,
        });
        window.scroll({ top: 0, behavior: 'smooth' })
    };

    // questionnaire
    handleAnswerChange = ({ name, value }) => {
        let { answers, currentQuestion } = this.state;
        answers[name] = value;

        this.setState({ answers }, () => {
            setTimeout(() => {
                if (
                    currentQuestion < this.props.riskAssessmentQuestions.length
                ) {
                    currentQuestion = currentQuestion + 1;
                } else {
                    this.handleQuestionAction("SUBMIT");
                }
                this.setState({ currentQuestion });
            }, 500);
        });
    };

    // questionnaire
    calculateProgress = () => {
        const { answers } = this.state;
        const { riskAssessmentQuestions } = this.props;
        return (
            (Object.keys(answers).length / riskAssessmentQuestions.length) * 100
        );
    };

    handleQuestionAction = async (action) => {
        const { answers, currentQuestion } = this.state;

        if (action === "PREV") {
            this.setState({
                currentQuestion: currentQuestion - 1,
            });
            return;
        }

        this.setState({ isLoading: true });
        try {
            const payload = {
                questionAnswers: [],
            };

            Object.keys(answers).forEach((key) => {
                payload.questionAnswers.push({
                    questionId: key,
                    optionId: answers[key],
                });
            });

            const response =
                await jarvisRMSOnboardingApis.submitRiskAssessmentQuestions(
                    payload
                );
            this.fetchAssessmentAnswers();
            const parsedResponse =
                jarvisRMSOnboardingParser.submitRiskAssessmentQuestion(
                    response
                );

            this.props.updateOnboardingDataAction({
                suggestedRiskId: parsedResponse.riskProfileId,
            });

            this.setState({
                selectedRiskId: parsedResponse.riskProfileId,
                suggestedRiskId: parsedResponse.riskProfileId,

            });
        } catch (error) {
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleConfirmModalClose = () => {
        this.setState({ submitQusetionConfirmModal: false })
    }

    handleSubmitQuestionAnswer = async (isConfirmModalOpen) => { //debugger;
        this.setState({ isLoading: true });
        let { previewRiskAssessmentQuestionsList } = this.state;
        if (isConfirmModalOpen === true) {
            this.setState({ submitQusetionConfirmModal: true, isLoading: false });
        }
        else {
            try {
                const payload = {
                    questionAnswers: [],
                };
                previewRiskAssessmentQuestionsList.map((data) => {
                    payload.questionAnswers.push({ questionId: data.questionId, optionId: data.optionId })
                })

                const response = await jarvisRMSOnboardingApis.submitRiskAssessmentQuestions(
                    payload
                );

                const parsedResponse =
                    jarvisRMSOnboardingParser.submitRiskAssessmentQuestion(
                        response
                    );
                this.setState({ isLoading: false });

                this.setState({
                    submitQusetionConfirmModal: false,
                    selectedRiskId: parsedResponse.riskProfileId,
                    suggestedRiskId: parsedResponse.riskProfileId,
                    activeScreenType: screenTypes.RISK_SUMMARY,
                }, () => {
                    window.scrollTo(0, 0);
                });



            } catch (err) {

            } finally {
                this.setState({ isLoading: false });
            }



        }
    }

    // risk profiles
    handleRiskProfileChange = (riskProfile) => {
        this.setState({ selectedRiskId: riskProfile });
    };

    // risk profiles + risk summary
    handleRiskProfileConfirm = async () => {
        const { selectedRiskId, isDeclarationChecked } = this.state;
        this.setState({ isLoading: true });
        if (!isDeclarationChecked) {
            this.setState({ isRiskAccept: true, isLoading: false });
            return;
        }
        try {
            await jarvisRMSOnboardingApis.confirmRiskProfile({
                riskProfileId: selectedRiskId,
            });
            this.setState({ isRiskAccept: false });

            this.props.updateOnboardingDataAction({
                currentStage: "SYNC_PORTFOLIO",
                riskProfileId: selectedRiskId,
            });
            this.props.history.replace(`/jarvis-rms/onboarding/sync-portfolio`);
        } catch (error) {
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handlePreviewOption = (data, buttonType) => {
        let { previewRiskAssessmentQuestionsList } = this.state;
        previewRiskAssessmentQuestionsList.forEach(d => {
            if (d.id == data.id && buttonType == 'Change') {
                d.isOptionOpen = true;
            }
            else {
                d.isOptionOpen = false;
            }
        });

        this.setState({ previewRiskAssessmentQuestionsList });
    }



    handleCheckBoxChange = () => {
        const isDeclarationChecked = this.state.isDeclarationChecked;
        this.setState({ isDeclarationChecked: !isDeclarationChecked }, () => { !this.state.isDeclarationChecked ? this.setState({ isRiskAccept: true }) : this.setState({ isRiskAccept: false }) });
    };

    getStepperData = () => {
        const { riskAssessmentQuestions } = this.props;
        return riskAssessmentQuestions.map((item) => ({
            id: item.id,
            label: `Q.${item.id}`,
        }));
    };

    readMoreTextTextHandle = () => {
        const { readMoreTextText } = this.state;
        this.setState({ readMoreTextText: !readMoreTextText });
    }

    render() {
        const {
            activeScreenType,
            selectedRiskId,
            answers,
            currentQuestion,
            isLoading,
            previewRiskAssessmentQuestionsList,
            submitQusetionConfirmModal,
            isQuestionAnswerLoading,
            readMoreTextText,

        } = this.state;
        const {
            riskAssessmentQuestions,
            riskProfiles,
            isRiskProfileLoading,
            isRiskAssessmentLoading,
            loginProfile,
        } = this.props;
        const progress = this.calculateProgress();
        const stepperData = this.getStepperData();
        const isMobileView = localStore.getMobileView();

        const qtsAnswerd = Object.keys(answers).length;

        const totalQts = riskAssessmentQuestions.length;

        if (activeScreenType === screenTypes.RISK_QUESTIONNAIRE) {
            return (
                <>
                    <Loader
                        position="absolute"
                        loading={
                            isRiskProfileLoading || isRiskAssessmentLoading
                        }
                    />
                    <SectionTitle
                        sx={{ mt: 2 }}
                        heading="Risk Assessment"
                    />
                    <Text mb={2} variant="small" sx={{ color: "#A2A7AE" }}>
                        Answer below questions to know your risk profile.
                    </Text>
                    {isRiskAssessmentLoading || isRiskProfileLoading ? null : (
                        <Stack>
                            <JarvisNote heading="Please Note:" description="Your risk profile will remain the same for all Jarvis products, and it cannot be changed for 6 months." />

                            <Stack sx={{ display: { xs: "flex", md: "none" } }} direction="row" alignItems="center">
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={{ my: 2, mr: 2 }}
                                />
                                <Text sx={{ whiteSpace: "nowrap" }} variant="caption">{Object.keys(answers).length} of {riskAssessmentQuestions.length}</Text>
                            </Stack>
                            <Stack
                                sx={{ mb: 2, display: { xs: "none", md: "flex" }, width: { xs: "100%", md: "50%" } }}
                            >
                                <Stepper
                                    currentStepsId={currentQuestion}
                                    stepperData={stepperData}
                                />
                            </Stack>

                            {riskAssessmentQuestions.map((data, index) =>
                                data.id === currentQuestion ? (
                                    <React.Fragment key={`question_${index}`}>
                                        <RiskAssessmentQuestionBox
                                            name={data.id}
                                            question={data.name}
                                            options={data.options}
                                            onChange={this.handleAnswerChange}
                                            value={answers[data.id]}
                                        />
                                    </React.Fragment>
                                ) : null
                            )}
                            <Stack sx={{
                                background: "#fff",
                                width: { xs: "100%", md: "unset" },
                                justifyContent: { xs: "center", md: "unset" },
                                alignItems: { xs: "center", md: "unset" },
                            }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{
                                        mt: 5,
                                        flex: 1, maxWidth: { md: 360 },
                                        position: { xs: "fixed", md: "unset" },
                                        bottom: { xs: "10px", md: "unset" },
                                        width: { xs: "90vw", md: "unset" },
                                    }}
                                >
                                    <JarvisButton
                                        disableEndIcon
                                        disableStartIcon={false}
                                        disabled={currentQuestion === 1}
                                        onClick={() =>
                                            this.handleQuestionAction("PREV")
                                        }
                                        loading={isLoading}
                                        sx={{
                                            width: { xs: "100%", md: "unset" },
                                            backgroundColor:"#2b70c5",
                                            color:"white",
                                            '&:hover':{backgroundColor:"#2b70c5 !important"}
                                        }}
                                        
                                    >
                                        Previous
                                    </JarvisButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </>
            );
        }

        if (activeScreenType === screenTypes.RISK_PREVIEW) {
            return (
                <>
                    <SectionTitle
                        sx={{ mt: 1.5 }}
                        heading="Preview of risk assessment"
                    // startIcon={
                    //     <IconButton onClick={() => { this.props.history.replace('/jarvis-rms/onboarding/overview') }}>
                    //         <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                    //     </IconButton>
                    // }

                    />
                    <Loader
                        position="absolute"
                        loading={
                            isQuestionAnswerLoading
                        }
                    />
                    <Grid container spacing={4} alignItems="center" mb={{ xs: "60px", md: 2 }} mt={{ xs: 0.25, md: 0 }}>
                        {previewRiskAssessmentQuestionsList?.map((data, index) => (
                            <Grid item xs={12} md={6} keys={`key_${index}`} style={{ position: 'relative' }} >
                                <Text color="#676E76" sx={{ fontSize: { xs: 'small.fontSize', md: 'body1.fontSize' }, fontWeight: '500', mb: 1, minHeight: { xs: 'none', md: '52px' }, }} >
                                    {index + 1}. {data?.name}
                                </Text>
                                <Stack sx={{ alignItems: "center", p: 1, border: "1px solid #d3d3d3", borderRadius: '10px' }} direction={'row'} justifyContent="space-between">
                                    <Text color="#212225" sx={{ fontSize: { xs: 'small.fontSize', md: 'body1.fontSize' }, pl: { xs: 0, md: 2 } }}>
                                        {data?.selectedName}
                                    </Text>
                                    <Button
                                        variant="text"
                                        sx={{ textTransform: "capitalize", color: '#1566D6', fontWeight: '500', fontSize: { xs: 'small.fontSize', md: 'body1.fontSize' }, }}
                                        onClick={() => this.handlePreviewOption(data, data?.isOptionOpen == true ? 'Close' : 'Change')}
                                    >
                                        {(data?.isOptionOpen == true ? 'Close' : 'Change')}
                                    </Button>
                                </Stack>
                                <Box sx={{ display: (data?.isOptionOpen == false ? 'none' : 'block'), p: 2, position: 'absolute', top: '100%', maxWidth: 'calc(100% - 32px)', backgroundColor: "#ffffff", zIndex: '9', width: 1, boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px", }}>
                                    {data?.options.map((list, index) => (
                                        <Text sx={{ mb: 1, cursor: "pointer", fontSize: { xs: 'small.fontSize', md: 'body1.fontSize' } }} color={data.optionId == list.id ? '#1566D6' : '#00000094'} keys={`op_${index}`} onClick={() => this.handlePreviewAnswerChange(list)}>{list.name}</Text>
                                    ))}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    {
                        isMobileView ?
                            <Stack sx={{
                                background: "#fff",
                                width: { xs: "100%", md: "unset" },
                                justifyContent: { xs: "center", md: "unset" },
                                alignItems: { xs: "center", md: "unset" },
                            }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{
                                        mt: 5,
                                        flex: 1, maxWidth: { md: 360 },
                                        position: { xs: "fixed", md: "unset" },
                                        bottom: { xs: "10px", md: "unset" },
                                        width: { xs: "90vw", md: "unset" },
                                    }}
                                >
                                    <JarvisButton
                                        sx={{
                                            width: { xs: "100%", md: "unset" },
                                        }}
                                        disableEndIcon={true}
                                        onClick={() => this.handleSubmitQuestionAnswer(true)}
                                    >
                                        Submit my responses
                                    </JarvisButton>
                                </Stack>
                            </Stack>
                            :
                            <Stack direction={'row'} justifyContent={'flex-end'} pb={{ xs: 8, md: 0 }}>
                                <JarvisButton sx={{ maxWidth: { xs: '1', md: 'auto' }, width: { xs: '1', md: 'auto' } }} disableEndIcon={true} onClick={() => this.handleSubmitQuestionAnswer(true)}>Submit my responses</JarvisButton>
                            </Stack>
                    }

                    <Modal
                        open={submitQusetionConfirmModal}
                        size="sm"
                        onClose={this.handleConfirmModalClose}
                    >
                        <Stack alignItems={'center'} pb={2.8} pt={0} mt={-2}>
                            <Text variant="" color="#212225" sx={{ fontSize: "subtitle1.fontSize", fontWeight: "500", paddingBottom: "10px" }}>Are you sure?</Text>
                            {/* <Text variant="" color="#676E76" sx={{ fontSize: { xs: 'small.fontSize', md: 'body2.fontSize' }, textAlign: 'center', marginBottom: 2.5 }}>
                                Because the portfolio will be built based on your risk profile and you can't edit it for the next 6 months as per the SEBI regulation. Your risk profile will remain the same for all our products.
                            </Text> */}
                            <Text variant="" color="#676E76" sx={{ fontSize: { xs: 'small.fontSize', md: 'body2.fontSize' }, textAlign: 'center', marginBottom: 2.5 }}>
                            Because Jarvis will recommend based on your responses, and you will be unable to edit your responses for the next 6 months.
                            </Text>
                            <JarvisButton loading={isLoading} disableEndIcon={true} onClick={() => this.handleSubmitQuestionAnswer(false)}>Submit my responses</JarvisButton>
                        </Stack>
                    </Modal>
                </>

            )
        }

        if (activeScreenType === screenTypes.RISK_PROFILES) {
            return (
                <Stack alignItems="flex-start">
                    <SectionTitle
                        heading= {localStore.isSsoCustomer()?"Select my risk profile":"Change my risk profile"}
                        sx={{ mb: 3 }}
                    />

                    <Grid container spacing={2} sx={{ mb: { xs: 3, md: 7 } }}>
                        {riskProfiles.map((risk, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                key={`risk_${index}`}
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <ChangeRiskProfileCard
                                    id={risk.id}
                                    title={risk.name}
                                    description={risk.description}
                                    level={1}
                                    onClick={() =>
                                        this.handleRiskProfileChange(risk.id)
                                    }
                                    isSelected={risk.id === selectedRiskId}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Stack
                        direction="row"
                        pb={{ md: 0 }}
                        justifyContent={{
                            xs: "center",
                            md: "flex-end",
                            width: "100%",
                        }}
                        sx={{position: { xs: "sticky"}, bottom:{xs:"10px"}}}
                    >
                        <JarvisButton
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            onClick={() => { this.handleScreenChange(screenTypes.RISK_SUMMARY) }}
                            loading={isLoading}
                            disabled={!selectedRiskId}
                        >
                            Continue
                        </JarvisButton>
                    </Stack>
                </Stack>
            );
        }
console.log(riskProfiles, selectedRiskId,"====")
        const currentRiskProfile = riskProfiles.find(
            (item) => item.id === selectedRiskId
        );

        if (activeScreenType === screenTypes.RISK_SUMMARY) {
            return (
                <>
                    <SectionTitle
                        heading="Assessment Result"
                        sx={{ mt: 1.5 }}
                    // startIcon={
                    //     <IconButton onClick={() => { this.handleScreenChange(screenTypes.RISK_PREVIEW) }}>
                    //         <ArrowBackIcon sx={{ fontSize: 20, color: "#222B3E" }} />
                    //     </IconButton>
                    // }
                    />
                    <Text mb={1} variant="small" sx={{ color: "#A2A7AE" }}>
                        You risk profile is same throughout the product.
                    </Text>

                    <JarvisNote heading="Please Note:" description="Your risk profile will remain the same for all Jarvis products, and it cannot be changed for 6 months." />

                    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" pb={8} pt={{ xs: 4, md: 8 }}>
                        <Stack px={2} sx={{ flex: 1 }} mb={{ xs: 4, md: 0 }}>
                            <RiskProfileMeter
                                chartWidth={350}
                                riskProfiles={riskProfiles}
                                selectedRiskId={selectedRiskId}
                            />
                        </Stack>
                        <Stack justifyContent="flex-end" sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' }, }} pl={{ xs: 0, md: 6 }}>
                            <Text variant="caption1" sx={{ textTransform: "uppercase" }}>
                                Congratulations, {`${loginProfile.name} !`}
                            </Text>
                            <Text fontSize={{ sx: "body1.fontSize", md: "32px" }} mb={{ xs: 2, md: 3 }} fontWeight="500" sx={{ textTransform: "none", lineHeight: "1.4" }}>
                                You are&nbsp;
                                {formatHelper.checkVowel(currentRiskProfile?.name)
                                    ? "an"
                                    : "a"}&nbsp;
                                {currentRiskProfile?.name} investor
                            </Text>
                            <Text variant="body1" sx={{ color: "#676E76", fontSize: { xs: "caption1.fontSize", md: "body1.fontSize" } }}>
                                {currentRiskProfile?.description}
                            </Text>
                        </Stack>
                    </Stack>

                    <Stack
                        sx={{ px: { xs: 0, md: 8 }, maxWidth: "1280px" }}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="start"
                    >
                        <Checkbox
                            sx={{ px: 1, py: 0 }}
                            checked={this.state.isDeclarationChecked}
                            onChange={this.handleCheckBoxChange}
                        />

                        <Box>

                            <Text variant="caption1"
                                sx={{
                                    color: "#A2A7AE",
                                    display: {
                                        xs: `${!readMoreTextText ? "-webkit-box" : "block"}`,
                                        md: "block"
                                    },
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: "3",
                                    overflow: "hidden"
                                }}>
                                I confirm that the information as mentioned by me in the
                                preceding questionnaire is true and correct to the best
                                of my knowledge. I hereby agree, declare and confirm
                                that, JARVIS is relying on the questionnaire for the
                                purpose of Risk Profiling (assessment of my risk
                                profile). I hereby agree, declare and confirm that the
                                Portfolio of equity stocks / liquid bees / gold bees
                                proposed by JARVIS is based on the Risk Profiling done
                                by JARVIS.
                            </Text>
                            <Text variant="caption1"
                                sx={{
                                    cursor: "pointer",
                                    color: "#2B70C5",
                                    display: { xs: "inline-block", md: "none" },
                                    "&::selection": {
                                        backgroundColor: "transparent"
                                    }
                                }}
                                onClick={this.readMoreTextTextHandle}>{readMoreTextText ? "read less" : "...read more"}</Text>
                            {this.state.isRiskAccept ? (<Text my={1.2} sx={{ fontSize: '1rem', }} color={'#d32f2f'}>Please check this box if you want to proceed </Text>) : null}
                        </Box>



                    </Stack>

                    <Stack mt={4} direction={{ xs: 'column-reverse', md: 'row' }} justifyContent="flex-end" alignItems="center" sx={{ width: "100%",position: { xs: "sticky"}, bottom:{xs:"10px"} }} >
                        <JarvisButton
                            variant="text"
                            sx={{ color: "#2B70C5", width: { xs: '100%', md: 'auto' }, mb: { md: 2 }, mr: { md: 2 }, border: "1px solid #DEDEDE", backgroundColor:"white" }}
                            onClick={() =>
                                this.handleScreenChange(screenTypes.RISK_PROFILES)
                            }
                            disableEndIcon

                        >
                            Change my risk profile manually
                        </JarvisButton>
                        <JarvisButton
                            sx={{ width: { xs: '100%', md: 'auto' }, mb: 2 }}
                            // disabled={!this.state.isDeclarationChecked}
                            onClick={() => this.handleRiskProfileConfirm()}
                            disableEndIcon
                            loading={isLoading}
                        >
                            I Confirm my risk profile
                        </JarvisButton>
                    </Stack>
                    {/* <Modal
                        open={this.state.isDeclarationCheckErrorModal}
                        size="xs"
                        // onBackdropClick={this.setState({isDeclarationCheckErrorModal : false})}
                        disableClose={true}
                    >
                        <Stack alignItems={'center'} pb={2.8} pt={3.4}>
                            <Text variant="" color="#212225" sx={{ fontSize: "subtitle1.fontSize", fontWeight: "500", paddingBottom: "20px" }}>Are you sure?</Text>
                            <Text variant="" color="#676E76" sx={{ fontSize: { xs: 'small.fontSize', md: 'body2.fontSize' }, textAlign: 'center', marginBottom: 2.5 }}>
                                Because the portfolio will be built based on your responses, and you will be unable to edit your responses for the next 6 months.
                            </Text>
                            <JarvisButton loading={isLoading} disableEndIcon={true} onClick={() => this.setState({isDeclarationCheckErrorModal : false})}>Close</JarvisButton>
                        </Stack>
                    </Modal> */}
                </>
            );
        }

        // return (
        //     <Stack alignItems="center">
        //         <RiskProfileMeter
        //             riskProfiles={riskProfiles}
        //             selectedRiskId={selectedRiskId}
        //         />
        //         <Stack
        //             sx={{ px: 3, maxWidth: 530 }}
        //             direction="row"
        //             justifyContent="center"
        //             alignItems="start"
        //         >
        //             <Checkbox
        //                 sx={{ py: 0 }}
        //                 checked={this.state.isDeclarationChecked}
        //                 onChange={this.handleCheckBoxChange}
        //             />
        //             <Text variant="caption1">
        //                 I confirm that the information as mentioned by me in the
        //                 preceding questionnaire is true and correct to the best
        //                 of my knowledge. I hereby agree, declare and confirm
        //                 that, JARVIS is relying on the questionnaire for the
        //                 purpose of Risk Profiling (assessment of my risk
        //                 profile). I hereby agree, declare and confirm that the
        //                 Portfolio of equity stocks / liquid bees / gold bees
        //                 proposed by JARVIS is based on the Risk Profiling done
        //                 by JARVIS.
        //             </Text>
        //         </Stack>

        //         <JarvisButton
        //             disabled={!this.state.isDeclarationChecked}
        //             onClick={() => this.handleRiskProfileConfirm()}
        //             disableEndIcon
        //             sx={{ mt: 7, mb: 2 }}
        //             loading={isLoading}
        //         >
        //             Confirm my risk profile
        //         </JarvisButton>

        //         <JarvisButton
        //             variant="text"
        //             onClick={() =>
        //                 this.handleScreenChange(screenTypes.RISK_PROFILES)
        //             }
        //             disableEndIcon
        //         >
        //             Change my risk profile
        //         </JarvisButton>
        //     </Stack>
        // );
    }
}

export default withRouter(RiskAssessmentContainer);
