import React from 'react'
// import RMSPortfolioSyncContainer from 'containers/JarvisRMS/RMSPortfolioSync'
import RMSAddPortfolioContainer from "containers/JarvisRMS/RMSAddPortfolio";

const PortfolioSync = () => {
  return (
    <>
      <RMSAddPortfolioContainer processType="INITIAL" />
    </>
  )
}
    
export default PortfolioSync