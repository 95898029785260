import { axios } from "helper/axios";
import { dateTimeHelper } from "helper/dateTime";
import { jarvisRMSTransactionConstants } from "./jarvisRMSTransactionConstants";

const jarvisRMSTransactionApis = {};

jarvisRMSTransactionApis.getRMSTransactionList = ({
    page,
    perPage,
    dateFrom,
    dateTo,
    portfolioId,
    executionStatus,
    customerId,
}) => {
    // return {
    //     list: [
    //         {
    //             rmsCallId: 1,
    //             portfolioId: 1,
    //             portfolioName: "Portfolio 1",
    //             brokerId: 1,
    //             brokerName: "Binance",
    //             rmsCallDate: "2022-08-29T07:16:29.799Z",
    //             rmsCallExpiryDate: "2022-09-05T07:16:29.799Z",
    //             rmsCallStatus: "EXECUTED",
    //             stockQuantity: 7,
    //         },
    //         {
    //             rmsCallId: 2,
    //             portfolioId: 1,
    //             portfolioName: "Portfolio 1",
    //             brokerId: 1,
    //             brokerName: "Binance",
    //             rmsCallDate: "2022-08-29T07:16:29.799Z",
    //             rmsCallExpiryDate: "2022-09-05T07:16:29.799Z",
    //             rmsCallStatus: "NOT_EXECUTED",
    //             stockQuantity: 7,
    //         },
    //         {
    //             rmsCallId: 3,
    //             portfolioId: 1,
    //             portfolioName: "Portfolio 1",
    //             brokerId: 1,
    //             brokerName: "Binance",
    //             rmsCallDate: "2022-08-29T07:16:29.799Z",
    //             rmsCallExpiryDate: "2022-09-05T07:16:29.799Z",
    //             rmsCallStatus: "PARTIAL_EXECUTED",
    //             stockQuantity: 7,
    //         },
    //     ],
    //     records: 3,
    // };

    const params = { page, perPage };
    if (dateFrom) {
        params["fromDate"] = dateTimeHelper.format(dateFrom, '$YYYY-$MM-$DD');
    }
    if (dateTo) {
        params["toDate"] = dateTimeHelper.format(dateTo, '$YYYY-$MM-$DD');
    }
    if (portfolioId) {
        params["portfolioId"] = portfolioId;
    }
    if (executionStatus) {
        params["status"] = executionStatus;
    }

    return axios.get(jarvisRMSTransactionConstants.RMS_TRANSACTION_LIST_API, {
        params
    });
};

jarvisRMSTransactionApis.getRMSTransactionDetail = ({ payload }) => {
    return axios.get(`${jarvisRMSTransactionConstants.RMS_TRANSACTION_DETAIL_API}/${payload.rmsCallId}`);
    // return {
    //     list: [
    //         {
    //             stockId: 1,
    //             stockName: "ITC LTD.",
    //             stockSymbol: "ITC",
    //             stockQuyPrice: 110,
    //             stockBuantity: 10,
    //             stockBuyValue: 10 * 110,
    //             stockSestockSellDate: new Date(),
    //             changeInPricellPrice: 100,
    //             : 80,
    //             changeInPriceDuration: 10,
    //             valueSaved: 10 * 20,
    //             valueSavedPercentage: (80 / 100) * 100,
    //             executionStatus: "EXECUTED",
    //         },
    //         {
    //             stockId: 1,
    //             stockName: "AXIS BANK",
    //             stockSymbol: "AXIS",
    //             stockQuantity: 20,
    //             stockBuyPrice: 120,
    //             stockBuyValue: 20 * 120,
    //             stockSellPrice: 110,
    //             stockSellDate: new Date(),
    //             changeInPrice: 70,
    //             changeInPriceDuration: 10,
    //             valueSaved: 20 * 20,
    //             valueSavedPercentage: ((70 / 110) * 100).toFixed(2),
    //             executionStatus: "NOT_EXECUTED",
    //         },
    //     ],
    //     records: 3,
    // };
};

export { jarvisRMSTransactionApis };
